import * as React from "react";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { observer } from "mobx-react-lite";
import { AutoScalingDoc } from "../autoScalingDoc";
import { NGSwitch } from "../../../newcomponents/switch";
import NGAlert from "../../../newcomponents/alert";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateDefaultOptionsRaw: React.FC<Props> = ({ workloadDraft }) => {
  return (
    <>
      {workloadDraft.isStateful || workloadDraft.isCron ? null : (
        <>
          <div className="mb-4 flex items-center">
            <NGSwitch
              value={workloadDraft.capacityAI}
              onChange={workloadDraft.setCapacityAI}
              isDisabled={["cpu", "multi"].includes(workloadDraft.defaultOptions.metric.value)}
            >
              <NGLabelText>Capacity AI</NGLabelText>
            </NGSwitch>
            <InfoTooltip
              title={[
                `Enables intelligent dynamic resource allocation of CPU and Memory for each container by setting resource reservations based on usage data realtime observation.`,
                `Capacity AI can significantly reduce cost. It is well suited for workloads with steady usage characteristics and less ideally suited for workloads with significant spikes in usage.`,
              ]}
            />
          </div>
          {["cpu", "multi"].includes(workloadDraft.defaultOptions.metric.value) ? (
            <NGAlert
              className="mb-4"
              message={`Capacity AI is not available when Scaling Strategy is set to ${
                workloadDraft.defaultOptions.metric.value === "cpu" ? "CPU Utilization" : "Multi"
              }`}
            />
          ) : null}
        </>
      )}
      {workloadDraft.isCron ? null : (
        <div className="mb-4 flex items-center">
          <NGSwitch value={workloadDraft.defaultOptions.debug} onChange={workloadDraft.defaultOptions.setDebug}>
            <NGLabelText>Debug</NGLabelText>
          </NGSwitch>
          <InfoTooltip title={`Adds debug response headers when the headers "x-cpln-debug: true" is in the request.`} />
        </div>
      )}
      <div className="mb-4 flex items-center">
        <NGSwitch value={workloadDraft.defaultOptions.suspend} onChange={workloadDraft.defaultOptions.setSuspend}>
          <NGLabelText>Suspend</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={`If checked, the workload will be in a suspended mode and not running.`} />
      </div>
      {workloadDraft.isCron ? null : (
        <NGInputAdapter
          className="mb-4"
          data={workloadDraft.defaultOptions.timeoutSeconds}
          style={{ width: 450 }}
          infoContents={[
            `The maximum request duration in seconds before the workload will timeout.`,
            `This timeout amount can be reached when waiting for the workload to respond or when waiting for a new workload to become available when using Autoscaling.`,
            `The minimum value is 1 second and the maximum value is 3600 seconds (1 hour).`,
          ]}
          description={"Request to the workload will timeout when reaching this number of seconds."}
        />
      )}
      {workloadDraft.isServerless || workloadDraft.isStandard || workloadDraft.isStateful ? (
        <>
          <div className="mt-4 mb-2 flex items-center gap-2">
            <NGLabel>Auto Scaling</NGLabel>
            <AutoScalingDoc />
          </div>

          <NGLabel>{workloadDraft.defaultOptions.metric.label}</NGLabel>
          <div className="flex mb-4">
            <NGSelect
              style={{ width: 450 }}
              onChange={(value) => workloadDraft.setMetric(value as any, "default")}
              options={workloadDraft.defaultOptions.metric.options}
              value={workloadDraft.defaultOptions.metric.value}
            />
            <InfoTooltip
              className={`self-center`}
              // TODO update for workload types other than serverless
              title={[
                `Scaling is determined by a specific metric, with the target being a replica.`,
                `'Disabled' means there's only one 'Replica count'. In the API, the Min Scale and Max Scale are both set to the same specified value.`,
                `'concurrency (Concurrent Requests)' uses the number of concurrent requests to the workload.`,
                `'cpu (CPU Utilization)' uses % processor time utilized by the workload.`,
                `'memory' memory in % for the target.`,
                `'rps (Requests Per Second)' uses requests per second for the target.`,
                `'Multi' allows you to scale with both CPU and Memory.`,
                `The number of replicas for this deployment scales up/down in accordance with the chosen scaling strategy and values provided.`,
              ]}
            />
          </div>
          {workloadDraft.defaultOptions.metric.value === "disabled" ? null : (
            <>
              {workloadDraft.defaultOptions.metric.value === "latency" ? (
                <>
                  <div className="mb-4 flex items-center">
                    <div>
                      <NGLabel>{workloadDraft.defaultOptions.metricPercentile.label}</NGLabel>
                      <NGSelect
                        style={{ width: 215, marginRight: 10 }}
                        onChange={workloadDraft.defaultOptions.metricPercentile.setValue}
                        options={workloadDraft.defaultOptions.metricPercentile.options}
                        value={workloadDraft.defaultOptions.metricPercentile.value}
                      />
                    </div>
                    <div>
                      <NGInputAdapter data={workloadDraft.defaultOptions.target} style={{ width: 225 }} />
                    </div>
                  </div>
                </>
              ) : workloadDraft.defaultOptions.metric.value === "multi" ? (
                <>
                  <div className="mb-4 flex items-center">
                    <div>
                      <NGInputAdapter
                        data={workloadDraft.defaultOptions.multiCpuTarget}
                        style={{ width: 215, marginRight: 10 }}
                      />
                    </div>
                    <div>
                      <NGInputAdapter
                        data={workloadDraft.defaultOptions.multiMemoryTarget}
                        style={{ width: 215, marginLeft: 10 }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <NGInputAdapter data={workloadDraft.defaultOptions.target} className="mb-4" style={{ width: 450 }} />
                </div>
              )}
            </>
          )}
          <NGInputAdapter
            data={workloadDraft.defaultOptions.minScale}
            style={{ width: 450 }}
            className="mb-4"
            infoContents={[
              `The minimum number of replicas.`,
              `If set to zero, a new replica becomes active when a request is received, after a few seconds. It is recommended to set Min Scale to at least 2 replicas for production use cases when availability is paramount.`,
            ]}
            description="Minimum number of replicas."
          />
          {workloadDraft.defaultOptions.metric.value === "disabled" ? null : (
            <NGInputAdapter
              data={workloadDraft.defaultOptions.maxScale}
              style={{ width: 450 }}
              className="mb-4"
              infoContents={["The maximum number of replicas."]}
              description="The maximum number of replicas."
            />
          )}
          {workloadDraft.defaultOptions.metric.value === "disabled" ? null : workloadDraft.isServerless ||
            workloadDraft.isStandard ? (
            <NGInputAdapter
              data={workloadDraft.defaultOptions.scaleToZeroDelay}
              style={{ width: 450 }}
              className="mb-4"
              infoContents={[
                `The number of seconds until a scale down event occurs. For example, if the number of concurrent requests drops to a level that warrants a scale down event, the number of replicas will be adjusted after the number of seconds specified by this value.`,
                `The minimum value is 30 seconds.`,
              ]}
              description="Number of seconds (min. 30) before a scale down event."
            />
          ) : null}
          {workloadDraft.isServerless ? (
            <NGInputAdapter
              data={workloadDraft.defaultOptions.maxConcurrency}
              style={{ width: 450 }}
              className="mb-4"
              infoContents={[
                `Maximum number of concurrent requests routed to a replica.`,
                `If no replicas are available to fulfill the request, the request is queued until a replica is available. Either from existing requests having been completed, or from new replica(s) becoming available as a result of a scale up action.`,
                `0 means unlimited.`,
              ]}
              description="Maximum number of concurrent requests routed to a replica."
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export const WorkloadCreateDefaultOptions = observer(WorkloadCreateDefaultOptionsRaw);
