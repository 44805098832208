import * as React from "react";
import { StringMobx } from "../../../../mobxDataModels/stringModel";
import { AutoComplete } from "antd";
import { usaStates } from "../../addressData/usaStates";
import { addressCountries } from "../../addressData/countries";
import { observer } from "mobx-react-lite";
import { NGLabel } from "../../../../newcomponents/text/label";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";

const CheckMark = () => (
  <span className="formmark text-xs" style={{ color: "transparent", textShadow: "0 0 0 var(--color-action)" }}>
    &#10004;
  </span>
);
const RequiredMark = () => <span className="formmark color-danger">*</span>;

interface Props {
  country: StringMobx;
  state: StringMobx;
  city: StringMobx;
  postalCode: StringMobx;
  line1: StringMobx;
  line2: StringMobx;
}
const AccountCreateAddressRaw: React.FC<Props> = ({ country, state, city, postalCode, line1, line2 }) => {
  const [countryOptions, setCountryOptions] = React.useState(addressCountries.map((country) => ({ value: country })));
  const [stateOptions, setStateOptions] = React.useState(usaStates.map((state) => ({ value: state })));

  function onSearchState(search: string) {
    const defaultOptions = usaStates.map((country) => ({ value: country }));
    if (search.length < 1) {
      setStateOptions(defaultOptions);
      return;
    }

    const filteredOptions = usaStates
      .filter((state) => state.toLowerCase().includes(search.toLowerCase()))
      .map((state) => ({ value: state }));
    setStateOptions(filteredOptions);
  }

  function onBlurState() {
    if (!stateOptions.some((opt) => opt.value === state.value)) {
      state.setValue("");
    }
  }

  function onSearchCountry(search: string) {
    const defaultOptions = addressCountries.map((country) => ({ value: country }));
    if (search.length < 1) {
      setCountryOptions(defaultOptions);
      return;
    }

    const filteredOptions = addressCountries
      .filter((country) => country.toLowerCase().includes(search.toLowerCase()))
      .map((country) => ({ value: country }));
    setCountryOptions(filteredOptions);
  }

  function onBlurCountry() {
    if (!countryOptions.some((opt) => opt.value === country.value)) {
      country.setValue("United States");
    }
  }

  return (
    <div className="account-create-address">
      <NGLabel>Country {country.value ? <CheckMark /> : <RequiredMark />}</NGLabel>
      <AutoComplete
        className="mb-4 block"
        size={"large"}
        style={{ width: 450 }}
        options={countryOptions}
        value={country.value}
        onSelect={(value) => country.setValue(value)}
        onChange={(value) => country.setValue(value)}
        onSearch={onSearchCountry}
        onBlur={onBlurCountry}
      />
      {country.value === "United States" ? (
        <>
          <NGLabel>State {state.value ? <CheckMark /> : <RequiredMark />}</NGLabel>
          <AutoComplete
            placeholder={"State"}
            size={"large"}
            style={{ width: 450 }}
            className="mb-4"
            options={stateOptions}
            value={state.value}
            onSearch={onSearchState}
            onSelect={(value) => state.setValue(value)}
            onChange={(value) => state.setValue(value)}
            onBlur={onBlurState}
          />
        </>
      ) : (
        <NGFormElement
          name="state"
          label={state.label}
          value={state.value}
          onChange={state.setValue}
          required={state.isRequired}
          error={state.error}
        />
      )}
      <NGFormElement
        name="line1"
        label={line1.label}
        value={line1.value}
        onChange={line1.setValue}
        required={line1.isRequired}
        error={line1.error}
      />
      <NGFormElement
        name="line2"
        label={line2.label}
        value={line2.value}
        onChange={line2.setValue}
        required={line2.isRequired}
        error={line2.error}
      />
      <NGFormElement
        name="city"
        label={city.label}
        value={city.value}
        onChange={city.setValue}
        required={city.isRequired}
        error={city.error}
      />
      <NGFormElement
        name="postalCode"
        label={postalCode.label}
        value={postalCode.value}
        onChange={postalCode.setValue}
        required={postalCode.isRequired}
        error={postalCode.error}
      />
    </div>
  );
};

export const AccountCreateAddress = observer(AccountCreateAddressRaw);
