import { flow, types } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import {
  WithNameModel,
  WithCreatedModel,
  KindProperty,
  WithLastModifiedModel,
  WithLinksModel,
  WithTagsModel,
} from "../base";
import { quotaHelpers } from "./quota.helpers";

export const QuotaModel = types
  .compose(
    "Quota",
    WithNameModel,
    WithCreatedModel,
    WithLastModifiedModel,
    WithLinksModel,
    // To make it works nice with table logic
    WithTagsModel,
    types.model({
      id: types.identifier,
      kind: KindProperty,
      version: types.optional(types.number, 0),
      description: types.optional(types.string, ""),
      unit: types.optional(types.string, ""),
      dimensions: types.optional(types.frozen(), {}),
      max: types.optional(types.number, 0),
      current: types.optional(types.number, 0),
      origin: types.optional(types.string, "default"), // "default", "builtin"
    })
  )
  .views((self) => ({
    get quota() {
      return quotaHelpers.getQuota(self as any);
    },
    get gvcLink() {
      return quotaHelpers.getGvcLink(self as any);
    },
    get quotaRouteLink() {
      return quotaHelpers.getQuotaRouteLink(self as any);
    },
  }))
  .actions(() => {
    // Here to keep consistency of the common type, for tags
    const patch = flow(function* () {});
    return { patch };
  });

export interface QuotaMobx extends Instance<typeof QuotaModel> {}
