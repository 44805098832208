import { types, Instance } from "mobx-state-tree";
import { ValidationKeyMobxProperty, inputValidations } from "./validations";
import { TransformKeyMobxProperty, inputTransforms, TransformKey } from "./transforms";

export const StringModel = types
  .model({
    validationKey: types.late(() => ValidationKeyMobxProperty),
    validationExactValue: types.maybe(types.string),
    transformKey: types.late(() => TransformKeyMobxProperty),
    label: types.string,
    initialValue: types.optional(types.string, ""),
    value: types.optional(types.string, ""),
    examples: types.array(types.string),
    isRequired: types.optional(types.boolean, false),
    min: types.maybe(types.number),
    max: types.maybe(types.number),
    overrideError: "",
    isSensitive: types.optional(types.boolean, false),
    _isHidden: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isEmpty() {
      let res = self.value.length < 1;
      return res;
    },
    get hasValidationRules(): boolean {
      if (self.validationExactValue) return true;
      if (self.overrideError) return true;
      if (self.min !== undefined || self.max !== undefined) return true;
      if (!self.validationKey) return false;

      const validationMethods = (inputValidations as any)[self.validationKey];
      if (!Array.isArray(validationMethods) || validationMethods.length < 1) return false;

      return true;
    },
    get validationResult(): true | string {
      if (self.overrideError) return self.overrideError;
      if (self.validationExactValue) {
        if (self.value !== self.validationExactValue) return `${self.label} must be ${self.validationExactValue}`;
      }

      let result: true | string = true;
      const valueToCheck = self.value;

      const number = Number(valueToCheck);
      if (valueToCheck.length > 0 && !Number.isNaN(number)) {
        if (self.min && number < self.min) {
          return `${self.label} must be ${self.min} or higher`;
        }
        if (self.max && number > self.max) {
          return `${self.label} must be ${self.max} or fewer`;
        }
      }

      if (!self.validationKey) return result;

      const validationMethods = (inputValidations as any)[self.validationKey];
      if (!Array.isArray(validationMethods) || validationMethods.length < 1) return result;

      for (let validationMethod of validationMethods) {
        if (result === true) {
          result = validationMethod(self.label, valueToCheck);
        }
      }
      return result;
    },
  }))
  .views((self) => ({
    get isValid() {
      if (self.overrideError) return false;
      if (self.isRequired) {
        if (self.isEmpty) {
          return false;
        } else {
          if (self.validationResult === true) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (self.hasValidationRules) {
          if (self.isEmpty) {
            return true;
          } else {
            if (self.validationResult === true) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return true;
        }
      }
    },
    get invalidReason() {
      let reason = "";
      if (self.overrideError) reason = `override error - ${self.overrideError}`;
      if (self.isRequired) {
        if (self.isEmpty) {
          reason = `required and empty ${self.value}`;
        } else {
          if (self.validationResult === true) {
            // return true;
          } else {
            reason = "invalid validation result - " + self.validationResult;
          }
        }
      } else {
        if (self.hasValidationRules) {
          if (self.isEmpty) {
            // return true;
          } else {
            if (self.validationResult === true) {
              // return true;
            } else {
              reason = "invalid validation result 2 - " + self.validationResult;
            }
          }
        } else {
          // return true;
        }
      }
      return reason;
    },
    get infoMark(): "none" | "required" | "check" | "invalid" {
      if (self.isRequired) {
        if (self.isEmpty) {
          return "required";
        } else {
          if (self.validationResult === true) {
            return "check";
          } else {
            return "invalid";
          }
        }
      } else {
        if (self.hasValidationRules) {
          if (self.isEmpty) {
            return "none";
          } else {
            if (self.validationResult === true) {
              return "check";
            } else {
              return "invalid";
            }
          }
        } else {
          return "none";
        }
      }
    },
    get exampleText(): string {
      if (self.isEmpty) return "";
      if (self.validationResult !== true) {
        return self.examples.join(", ");
      }
      return "";
    },
    get isDirty() {
      return self.initialValue !== self.value;
    },
    get isDirtyReason() {
      let reason = "";
      if (self.initialValue !== self.value) reason = `initial changed (${self.initialValue} -> ${self.value})`;
      return reason;
    },
  }))
  .actions((self) => ({
    reset() {
      self.value = self.initialValue;
      self._isHidden = self.isSensitive;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setIsHidden(value: boolean) {
      self._isHidden = value;
    },
    setIsRequired(value: boolean) {
      self.isRequired = value;
    },
    setOverrideError(value: string) {
      self.overrideError = value;
    },
    clearOverrideError() {
      self.overrideError = "";
    },
    confirm() {
      self.initialValue = self.value;
    },
    setMin(min: number) {
      self.min = min;
    },
    setMax(max: number) {
      self.max = max;
    },
    setInitialValue(value: string) {
      let newValue = value;
      if (
        self.transformKey &&
        Array.isArray(inputTransforms[self.transformKey]) &&
        inputTransforms[self.transformKey].length > 0
      ) {
        for (let transformMethod of inputTransforms[self.transformKey]) {
          newValue = transformMethod(newValue);
        }
      }
      self.initialValue = newValue;
      self.reset();
    },
    setValue(value: string) {
      let newValue = value;
      if (
        self.transformKey &&
        Array.isArray(inputTransforms[self.transformKey]) &&
        inputTransforms[self.transformKey].length > 0
      ) {
        for (let transformMethod of inputTransforms[self.transformKey]) {
          newValue = transformMethod(newValue);
        }
      }
      self.value = newValue;
    },
    setTransformKey(key: TransformKey) {
      self.transformKey = key;
    },
    setLabel(value: string) {
      self.label = value;
    },
  }))
  .views((self) => ({
    get error(): string {
      if (self.overrideError) return self.overrideError;
      if (self.isEmpty && self.isRequired) return "Value is required.";
      if (!self.isEmpty && self.validationResult !== true) {
        return self.validationResult;
      }
      return "";
    },
  }));

export interface StringMobx extends Instance<typeof StringModel> {}
