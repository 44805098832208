import * as React from "react";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { observer } from "mobx-react-lite";

interface Props {
  name: StringMobx;
  description: StringMobx;
}
const GroupCreateGeneralRaw: React.FC<Props> = ({ name, description }) => {
  return (
    <>
      <NGFormElement name="name" label={name.label} value={name.value} onChange={name.setValue} />
      <NGFormElement
        name="description"
        label={description.label}
        value={description.value}
        onChange={description.setValue}
      />
    </>
  );
};

export const GroupCreateGeneral = observer(GroupCreateGeneralRaw);
