import * as React from "react";
import { SelectMobx, SelectOptionMobx } from "../../mobxDataModels/selectModel";
import { observer } from "mobx-react-lite";
import { ChevronDown } from "react-feather";
import { Tooltip } from "../Tooltip";

// TODO use checkmark icon when required and has a value
// TODO make this work either with data, or this way

interface Props extends React.PropsWithChildren {
  value: string;
  onChange: (value: string) => void;
  label: string;
  required?: boolean;
  requiredMessage?: string;
  options: SelectOptionMobx[];
  className?: string;
  style?: any;
  isDirty?: boolean;
  disabled?: boolean;
  data?: SelectMobx;
}
const SelectRaw: React.FC<Props> = ({
  value,
  onChange,
  label,
  required = false,
  requiredMessage,
  options,
  className = "",
  children,
  style = {},
  disabled = false,
  data,
}) => {
  const [selectedOptionLabel, setSelectedOptionLabel] = React.useState(() => {
    try {
      const optionWithInitialValue = options.find((opt) => opt.value === value);
      return optionWithInitialValue!.label;
    } catch (e) {
      console.error("Error select: inited with value", value, "options", options, "label", label);
      return "";
    }
  });

  React.useEffect(() => {
    try {
      const optionWithInitialValue = options.find((opt) => opt.value === value);
      setSelectedOptionLabel(optionWithInitialValue!.label);
    } catch (e) {
      console.error("select label", label, e.message);
    }
  }, [value, options]);

  const [showOptions, setShowOptions] = React.useState(false);
  const showOptionsRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    showOptionsRef.current = showOptions;
  }, [showOptions]);

  const [showMessage, setShowMessage] = React.useState(false);
  const [optionIndex, setOptionIndex] = React.useState(() => {
    for (let optionIndex in options) {
      const option = options[optionIndex];
      if (option.value === value) {
        return Number(optionIndex);
      }
    }
    return 0;
  });
  const parentRef = React.useRef<HTMLDivElement>(null as any);

  const onFocus = () => {
    if (disabled) {
      return;
    }
    setShowMessage(false);
    setShowOptions(true);
    setOptionIndex(0);
  };

  const onBlur = () => {
    if (disabled) {
      return;
    }
    setShowMessage(true);
    setShowOptions(false);
    if (!!data && !data.isTouched) {
      data.setIsTouched(true);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    const isEscapeKey = e.keyCode === 27;
    if (isEscapeKey) {
      onBlur();
      return;
    }
    const isUpArrow = e.keyCode === 38;
    const isDownArrow = e.keyCode === 40;
    if (isUpArrow) {
      setOptionIndex((index) => Math.max(0, index - 1));
      return;
    } else if (isDownArrow) {
      let optionCount = options.length;
      setOptionIndex((index) => Math.min(optionCount, index + 1));
      return;
    }
    const isAcceptKey = e.keyCode === 13 || e.keyCode === 32;
    if (isAcceptKey) {
      onChange(options[optionIndex].value);
      onBlur();
    }
  };

  const onOption = (newValue: string) => () => {
    if (disabled) {
      return;
    }
    onChange(newValue);
    const optionWithValue = options.find((opt) => opt.value === newValue);
    setSelectedOptionLabel(optionWithValue!.label);
    setTimeout(() => {
      if (parentRef.current) {
        parentRef.current.blur();
      }
    }, 0);
  };

  function optionEnter(_: React.MouseEvent, currentIndex: number) {
    if (disabled) {
      return;
    }
    setOptionIndex(currentIndex);
  }

  return (
    <div className={`${className}`} style={style}>
      <div
        ref={parentRef}
        tabIndex={0}
        className={`relative flex flex-col focus rounded`}
        onKeyDown={onKeyDown}
        onClick={() => {
          // console.log("onclick");
          // setTimeout(() => {
          //   if (showOptions && showOptionsRef.current) {
          //     setShowOptions(false);
          //   }
          // }, 300);
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <span
          aria-label={label}
          data-testid={`select-${selectedOptionLabel}`}
          className={`inline-block ${
            disabled ? "cursor-not-allowed" : "cursor-pointer"
          } flex-grow w-full rounded border truncate h-12 pt-3 ${
            required && !selectedOptionLabel && showMessage && "border-danger"
          } ${selectedOptionLabel ? "pt-5 pb-1" : ""}`}
          style={{
            paddingLeft: selectedOptionLabel ? 14.5 : 16,
            color: "var(--color-input)",
            backgroundColor: "var(--color-input-bg)",
            borderColor: "var(--color-input-border)",
          }}
        >
          {selectedOptionLabel}
        </span>
        <ChevronDown className="feather-icon absolute" style={{ right: 13, top: 17 }} />
        <span
          className={`absolute ${disabled ? "cursor-not-allowed" : "cursor-pointer"} left-4 ${
            selectedOptionLabel ? "top-1 font-medium text-xs" : "top-3 text-light"
          }`}
          onClick={(_) => {}}
        >
          <span>{label}</span>
          {required && !selectedOptionLabel ? <span className="ml-1 color-danger">*</span> : null}
          {required && selectedOptionLabel ? <span className="ml-1 color-action text-xs">&#10004;</span> : null}
        </span>
        {showOptions && (
          <span
            className="absolute flex flex-col text-sm max-h-64 overflow-y-auto top-12 shadow-lg w-full z-10 rounded border border-t-0"
            style={{
              maxHeight: 330,
              color: "var(--color-input)",
              backgroundColor: "var(--color-drop)",
              borderColor: "var(--color-input-border)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {options.map((option, index) => {
              if (option.isLabel) {
                return (
                  <span className={`pb-1 pt-2 pl-4 cursor-default border-b text-input-label text-xs font-semibold`}>
                    {option.label.toUpperCase()}
                  </span>
                );
              }

              return (
                <Tooltip key={option.value} title={option.label} open={option.label.length >= 20}>
                  <span
                    data-testid={`option-${option.label}`}
                    onMouseEnter={(e) => optionEnter(e, index)}
                    className={`py-1 px-4 border-b flex items-center justify-between hover:bg-input-hover ${
                      index === optionIndex && option.disabled === false ? "bg-input-hover" : ""
                    } ${
                      option.disabled
                        ? "bg-input-disabled hover:bg-input-disabled cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={option.disabled ? () => {} : onOption(option.value)}
                  >
                    <span className={`truncate ${option.value === value ? "font-medium" : ""}`}>{option.label}</span>
                  </span>
                </Tooltip>
              );
            })}
          </span>
        )}
        {required && !selectedOptionLabel && showMessage ? (
          <div className="pl-4 mt-1 color-danger text-sm">{requiredMessage || `${label} is required`}</div>
        ) : null}

        {children}
      </div>
    </div>
  );
};

export const Select = observer(SelectRaw);
