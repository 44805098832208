import * as React from "react";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { observer } from "mobx-react-lite";

interface Props {
  name: StringMobx;
  description: StringMobx;
}
const GVCCreateGeneralRaw: React.FC<Props> = ({ name, description }) => {
  return (
    <>
      <NGFormElement
        name={"name"}
        value={name.value}
        onChange={name.setValue}
        label={name.label}
        required={name.isRequired}
        error={name.error}
      />
      <NGFormElement
        name={"description"}
        value={description.value}
        onChange={description.setValue}
        label={description.label}
      />
    </>
  );
};

export const GVCCreateGeneral = observer(GVCCreateGeneralRaw);
