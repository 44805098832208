import { types, Instance } from "mobx-state-tree";
import { KindProperty } from "../mst/base";
import { GenericItemMobx, GenericItemModel } from "../mst/item";
import { homeLink } from "../services/cpln";

export const StringWithImageRefModel = types
  .model({
    initialValue: types.optional(types.string, ""),
    value: types.optional(types.string, ""),
    label: types.string,
    kind: KindProperty,
    resetCounter: types.optional(types.number, 1),
    itemMap: types.map(types.late(() => GenericItemModel)),
    shortcutKey: types.string,
  })
  .views((self) => ({
    get isDirty() {
      return self.initialValue !== self.value;
    },
    get items(): GenericItemMobx[] {
      return Array.from(self.itemMap.values());
    },
  }))
  .views((self) => ({
    get isDirtyReason() {
      if (!self.isDirty) {
        return self.label + "Not dirty";
      }
      return self.label + `: InitialValue -> ${self.initialValue} | Value -> ${self.value}`;
    },
  }))
  .actions((self) => ({
    reset() {
      self.value = self.initialValue;
      self.resetCounter += 1;
    },
    confirm() {
      self.initialValue = self.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setValue(value: string) {
      let _value = value;
      if (value.includes("//image/")) {
        _value = `${homeLink("image")}/`;
      }
      self.value = _value;
    },
    setInitialValue(value: string) {
      self.initialValue = value;
      self.reset();
    },
  }))
  .actions((self) => ({
    addItems(items: GenericItemMobx[]) {
      for (let item of items) {
        const currentItem = self.itemMap.get(item.id);
        if (currentItem) {
          self.itemMap.delete(currentItem.id);
        }
        self.itemMap.put(item);
      }
    },
  }));

export interface StringWithImageRefMobx extends Instance<typeof StringWithImageRefModel> {}
