import * as React from "react";
import NGAlert from "../../../newcomponents/alert";
import { observer } from "mobx-react-lite";
import { NGButton } from "../../../newcomponents/button/Button";
import { IS_DEPLOYMENT_ENV_TEST } from "../../../envVariables";
import { Helmet } from "react-helmet";
import { Footer } from "../../../layout/footer";
import "./index.scss";
import { Theme } from "../../../mobxStores/uiData/theme";

interface Props {
  devOnlyMessage?: string;
}
const ErrorPageRaw: React.FC<Props> = ({ devOnlyMessage = "" }) => {
  return (
    <>
      <Helmet>
        <title>{IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}Error - From many clouds, one™</title>
      </Helmet>
      <div className={`error-page-container`}>
        <div className="error-page-header">
          <img
            onClick={() => window.location.replace("/")}
            src={`/resources/logos/controlPlaneLogo${Theme.theme === "dark" ? "White" : ""}.svg`}
          />
        </div>
        <div className="error-page">
          <div className="error-page-content">
            <div className="error-page-content-title">
              <span className="">
                We are sorry. This error has been logged and will be investigated. Please retry later.
              </span>
            </div>
            {devOnlyMessage ? <span className="error-page-content-message">{devOnlyMessage}</span> : null}
            <NGButton variant={"primary"} onClick={() => window.location.replace("/")}>
              Go Back
            </NGButton>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export const ErrorPage = observer(ErrorPageRaw);
