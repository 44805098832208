import { ConsoleContext } from "../mobxStores/consoleContext/consoleContext";
import { homeLink, request } from "./cpln";

// Tasks that I was being invited to other orgs, as a user
export async function fetchMyOrgInvitations() {
  const { data } = await request({ url: "/task/-forme" });
  return data.items;
}

export async function acceptTask(taskId: string) {
  await request({ method: "post", url: `/task/${taskId}`, body: { answer: "accept" } });
}

// Tasks that I sent to other users, as the org owner
export async function fetchSentPendingTasks() {
  const body = {
    spec: {
      match: "all",
      terms: [
        {
          op: "=",
          property: "status",
          value: "pending",
        },
        {
          op: "=",
          property: "type",
          value: "inviteToOrg",
        },
      ],
    },
    kind: "task",
    fetch: "items",
  };
  const { data } = await request({ method: "post", url: `/org/${ConsoleContext.org}/task/-query`, body });
  return data.items;
}

// check task status
export async function checkTaskStatus(taskId: string): Promise<"pending" | "complete" | "canceled"> {
  const { data } = await request({ method: "get", url: `/org/${ConsoleContext.org}/task/${taskId}` });
  return data.status;
}

// cancel the task I sent some other user
export async function cancelSentTask(taskId: string) {
  await request({ method: "delete", url: `/org/${ConsoleContext.org}/task/${taskId}` });
}

export async function inviteUsers(emails: string[], groupLink: string) {
  if (emails.length < 1) return;
  const body: any = {
    emails,
  };
  if (groupLink !== "none") {
    body.groupLink = groupLink;
  }
  const link = `${homeLink("user")}/-invite`;
  const { data } = await request({ method: "post", url: link, body });
  return data;
}
