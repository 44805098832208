import * as React from "react";
import { ErrorPage } from "./components/system/error-page";
import * as Sentry from "@sentry/react";
import { IS_DEPLOYMENT_ENV_TEST, IS_DEVELOPMENT } from "./envVariables";

export function captureExc(e: any, state?: any) {
  if (e.message?.toLowerCase().includes("your token is expired")) {
    return;
  }
  if (e.message?.includes("context-org") || e.message?.includes("context-gvc") || e.message?.includes("context-ref")) {
    return;
  }
  if (e.message?.includes("403")) {
    return;
  }
  if (e.message === "Network Error") {
    return;
  }
  if (IS_DEPLOYMENT_ENV_TEST || IS_DEVELOPMENT) {
    console.trace("capturing exc", e.message);
  } else {
    console.error("capturing exc", e.message);
  }

  try {
    Sentry.withScope(function (scope) {
      if (state) {
        scope.setContext("state", {
          state,
        });
      }
      Sentry.captureException(e);
    });
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setTag("sentryFailed", "true");
      Sentry.captureException(e);
    });
  }
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorMessage: "", errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, _errorInfo: any) {
    console.error("caught error", { errorMessage: error.message, errorInfo: _errorInfo });
    this.setState({ errorMessage: error.message, errorInfo: _errorInfo });
    captureExc(error);
  }

  render() {
    if ((this.state as any).hasError) {
      return <ErrorPage devOnlyMessage={(this.state as any).errorMessage} />;
    }

    return this.props.children;
  }
}
