import { inputValidations } from "../../mobxDataModels/validations";
import { BillingContext } from "../../mobxStores/billingContext/billingContext";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { gvcScopedKinds } from "../kinds";

export type NGLinkTarget = "kind" | "item";

export type NGLinkType = "name" | "relative" | "gvcRelative" | "absolute";

export type NGParsedLink = {
  org: string;
  gvc: string;
  kind: string;
  name: string;

  target: NGLinkTarget;
  type: NGLinkType;

  isValid: boolean;
  errorMessage: string;

  wasRelative: boolean;
  wasGVCRelative: boolean;
  wasAbsolute: boolean;
  wasName: boolean;

  isGVCScoped: boolean;
  isKind: boolean;
  isItem: boolean;

  relative: string;
  gvcRelative: string;
  absolute: string;
};

function getDefaultParsedLink(): NGParsedLink {
  return {
    org: "",
    gvc: "",
    kind: "",
    name: "",

    target: "item",
    type: "name",

    isValid: false,
    errorMessage: "",

    wasRelative: false,
    wasGVCRelative: false,
    wasAbsolute: false,
    wasName: false,

    isGVCScoped: false,
    isKind: false,
    isItem: false,

    relative: "",
    gvcRelative: "",
    absolute: "",
  };
}

export type NgLinkParserOptions = {
  kind?: string;
  accountId?: string;
  org?: string;
  gvc?: string;
  useInputCtx?: boolean;
};

// TODO we can add an option to change the context of a link, by preventing the link to override the context
// TODO //workload/cat should return gvcRelative with gvc embedded or errors when gvc not found and it must be a gvc relative object
export function ngParseLink(value: string, _options?: NgLinkParserOptions): NGParsedLink {
  const optionsArg: NgLinkParserOptions = { useInputCtx: false };
  if (_options) {
    for (const key of Object.keys(_options)) {
      if ((_options as any)[key]) {
        (optionsArg as any)[key] = (_options as any)[key];
      }
    }
  }
  const options = Object.assign(
    { org: ConsoleContext.org, gvc: ConsoleContext.gvc },
    { accountId: BillingContext.AccountUUID },
    optionsArg,
  );
  let parsed: NGParsedLink = getDefaultParsedLink();

  if (!options.useInputCtx) {
    parsed.org = options.org;
    parsed.gvc = options.gvc;
  }

  try {
    const parts = value.split("/");
    if (!value.startsWith("/")) {
      parsed.name = value;
      parsed.wasName = true;
      if (!options.kind) {
        throw new Error("options.kind must be given when value is only a name");
      }
      parsed.kind = options.kind;
      if (gvcScopedKinds.includes(options.kind)) {
        parsed.isGVCScoped = true;
      }
    } else if (value.startsWith("//gvc")) {
      parsed.type = "gvcRelative";

      // 2 gvc | 3 gvcName | 4 kind | 5 name
      if (parts[2]) {
        const part = parts[2];
        parsed.target = "kind";
        parsed.kind = part;
      }
      if (parts[3]) {
        const part = parts[3];
        parsed.target = "item";
        if (options.useInputCtx) {
          parsed.gvc = part;
          parsed.name = part;
        } else {
          parsed.gvc = options.gvc;
          parsed.name = options.gvc;
        }
      }
      if (parts[4]) {
        const part = parts[4];
        if (!gvcScopedKinds.includes(part)) {
          throw new Error("Link cannot have a second kind as non-gvc scoped link");
        }
        parsed.isGVCScoped = true;
        parsed.wasGVCRelative = true;
        parsed.target = "kind";
        parsed.kind = part;
      }
      if (parts[5]) {
        const part = parts[5];
        parsed.target = "item";
        parsed.name = part;
      }
    } else if (value.startsWith("//")) {
      parsed.type = "relative";
      parsed.wasRelative = true;
      // 2 kind | 3 name
      if (parts[2]) {
        const part = parts[2];
        parsed.target = "kind";
        parsed.kind = part;
      }
      if (parts[3]) {
        const part = parts[3];
        parsed.target = "item";
        parsed.name = part;
      }
    } else {
      // 1 org | 2 orgName | 3 gvc | 4 gvcName | 5 kind | 6 name
      parsed.type = "absolute";
      parsed.wasAbsolute = true;
      if (parts[1] && parts[1] === "org") {
        const part = parts[1];
        parsed.target = "kind";
        parsed.kind = part;
      } else {
        throw new Error("An absolute link must start with /org.");
      }
      if (parts[2]) {
        const part = parts[2];
        parsed.target = "item";
        // Given context org overrides the link context org here
        if (options.useInputCtx) {
          parsed.org = part;
          parsed.name = part;
        } else {
          parsed.org = options.org;
          parsed.name = options.org;
        }
      }
      // TODO need to capture if this is not gvc and it still has the last 2 sections
      let isGvc = false;
      if (parts[3]) {
        const part = parts[3];
        parsed.target = "kind";
        parsed.name = "";
        parsed.kind = part;
        if (part === "gvc") {
          isGvc = true;
        }
      }
      if (parts[4]) {
        const part = parts[4];
        parsed.target = "item";
        parsed.name = part;
        if (isGvc) {
          // Context gvc overrides link gvc here
          if (options.useInputCtx) {
            parsed.gvc = part;
            parsed.name = part;
          } else {
            parsed.gvc = options.gvc;
            parsed.name = options.gvc;
          }
        }
      }
      if (!isGvc && parts[5]) {
        throw new Error("Link cannot have a second kind under org, if the first kind is not gvc");
      }
      if (isGvc && parts[5] && !gvcScopedKinds.includes(parts[5])) {
        throw new Error("Link cannot have a second kind as non-gvc scoped link");
      }
      if (parts[5]) {
        if (isGvc) {
          parsed.isGVCScoped = true;
        }
        const part = parts[5];
        parsed.target = "kind";
        parsed.name = "";
        parsed.kind = part;
      }
      if (parts[6]) {
        const part = parts[6];
        parsed.target = "item";
        parsed.name = part;
      }
    }

    // Name validation check
    let isValid = true;
    if (!["domain", "user"].includes(parsed.kind)) {
      for (const validator of inputValidations["name"]) {
        isValid = validator("label", parsed.name) === true;
      }
      if (!isValid) {
        throw new Error("Name is invalid");
      }
    }

    parsed.isValid = true;

    // Build absolute path
    if (parsed.isGVCScoped) {
      parsed.absolute = `/org/${parsed.org}/gvc/${parsed.gvc}/${parsed.kind}`;
      if (parsed.target === "item") {
        parsed.absolute += `/${parsed.name}`;
      }
    } else {
      parsed.absolute = `/org/${parsed.org}`;
      if (parsed.kind !== "org") {
        parsed.absolute += `/${parsed.kind}`;
        if (parsed.target === "item") {
          parsed.absolute += `/${parsed.name}`;
        }
      }
    }

    // Build gvc relative path
    if (parsed.isGVCScoped) {
      parsed.gvcRelative = `//gvc/${parsed.gvc}/${parsed.kind}`;
      if (parsed.target === "item") {
        parsed.gvcRelative += `/${parsed.name}`;
      }
    } else {
      parsed.gvcRelative = "";
    }

    // Build relative path
    if (parsed.isGVCScoped) {
      parsed.relative = "";
    } else {
      // Build relative path
      parsed.relative = `//${parsed.kind}`;
      if (parsed.target === "item") {
        parsed.relative += `/${parsed.name}`;
      }
    }

    parsed.isItem = parsed.target === "item";
    parsed.isKind = parsed.target === "kind";

    return parsed;
  } catch (e) {
    parsed.isValid = false;
    parsed.errorMessage = e.message;

    parsed.name = value;
    parsed.absolute = value;
    parsed.relative = value;
    parsed.gvcRelative = value;

    return parsed;
  }
}

export function convertAllLinksToRelative(value) {
  if (value === null || typeof value === "undefined") {
    return value;
  }

  // If value is a string, parse links
  if (typeof value === "string") {
    const parsed = ngParseLink(value, { useInputCtx: true });
    if (parsed.isValid) {
      value = parsed.isGVCScoped ? parsed.gvcRelative : parsed.relative;
    }
    return value;
  }

  // If value is an array, recursively convert each element
  if (Array.isArray(value)) {
    return value.map((item) => convertAllLinksToRelative(item));
  }

  // If value is an object, iterate over its properties
  if (typeof value === "object" && value !== null) {
    for (const key in value) {
      // Skip the 'links' property
      if (key === "links") {
        continue;
      }

      // Recursively process each property
      value[key] = convertAllLinksToRelative(value[key]);
    }
  }

  return value;
}
