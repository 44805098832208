import { makeAutoObservable } from "mobx";

class DebugContextMobx {
  debug: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  initalize() {
    if (localStorage.getItem("CPLN_DEBUG") === "true") {
      this.enable();
    }

    // @ts-ignore
    window.debug = {
      enable: () => this.enable(),
      disable: () => this.disable(),
      toggle: () => this.toggle(),
    };
  }

  enable() {
    this.debug = true;
  }

  disable() {
    this.debug = false;
  }

  toggle() {
    this.debug = !this.debug;
  }
}

export const DebugContext = new DebugContextMobx();
