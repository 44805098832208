import * as React from "react";
import { GroupBy, GroupByToLabel } from "./utils";

interface Props {
  chartHeight?: number;
  chartWidth?: number;
  payload?: undefined | { dataKey: string; color: string; value: string; payload: { fill: string; stroke: string } }[];
  groupBy: GroupBy;
  filters: string[];
  onClick: (value: string, reverse: boolean) => void;
  className?: string;
}
export const CustomLegend: React.FC<Props> = ({ payload, groupBy, filters, onClick, className = "" }) => {
  if (!payload) return null;

  if (groupBy === "account") return null;

  return (
    <div className="flex items-center flex-col gap-2 my-2 pt-2 rounded border">
      <div>{GroupByToLabel(groupBy)}</div>
      <div className={`p-2 flex items-center justify-center flex-wrap gap-4 ${className}`}>
        {payload.map((p) => {
          const isProjected = p.value.includes("projected");

          let label = p.value
            .replace("totalMap.", "")
            .replace("valueMap.", "")
            .replace("projectedTotalMap.", "")
            .replace("projectedValueMap.", "");
          if (isProjected) {
            label += " (Proj.)";
            return null;
          }

          const isFilteredOut = filters.length > 0 && !filters.includes(label);

          return (
            <div
              key={p.dataKey}
              onClick={(e) => {
                onClick(label, e.ctrlKey);
              }}
              className="flex items-center cursor-pointer"
            >
              <span
                className="w-4 h-2 border rounded-sm mr-1"
                style={{ backgroundColor: isFilteredOut ? "#ccc" : p.payload.fill }}
              />
              <span>{label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
