import { Domain } from "../../schema/types/domain";
import { isApex, nameOfDomainStatus } from "../../services/utils";

export const domainHelpers = {
  getIsApex(domain: Domain) {
    return isApex(domain.name);
  },
  getDomainStatusText(domain: Domain): string {
    return nameOfDomainStatus(domain.status as any);
  },
  getType(domain: Domain): string {
    let result = domain.spec?.dnsMode?.toUpperCase() || "";
    // let result = domain.spec?.dnsMode.toUpperCase() + " & ";
    // if (domain.spec?.gvcLink) {
    //   result += "Subdomain Routing";
    // } else if (domain.spec?.ports.some((p) => p.routes.length > 0)) {
    //   result += "Path Routing";
    // } else {
    //   result += "No Routing";
    // }
    return result;
  },
  getMappedGVC(domain: Domain) {
    let result = "";
    if (domain.spec?.gvcLink) {
      result = domain.spec?.gvcLink.split("/gvc/")[1];
    } else if ((domain.spec?.ports || []).some((p) => (p.routes || []).length > 0)) {
      const port = (domain.spec?.ports || []).find((p) => (p.routes || []).length > 0);
      if (port) {
        const workloadLink = (port.routes || [])[0].workloadLink;
        result = workloadLink?.split("/gvc/")[1].split("/workload/")[0]!;
      }
    }
    return result;
  },
  getEndpointCount(domain: Domain) {
    return (domain.status?.endpoints || []).length;
  },
};
