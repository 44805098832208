import * as React from "react";
import { Dropdown, Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { useDetailContext } from "./detailContext";
import { ExternalLink, Copy, ChevronDown, Lock, Unlock, RefreshCw } from "react-feather";
import { linksOf, parentLink, pluralNameOfKind, request, resourceLink } from "../../services/cpln";
import { clearItem, updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { WorkloadMobx } from "../../mst/kinds/workload";
import { Kind } from "../../mst/base";
import { useNavigate, useLocation } from "react-router-dom";
import { Apply } from "../../mobxStores/apply/apply";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { GVCCloneModal as GVCCloneModal } from "../../pages/gvc/cloneModal";
import { CloneModal } from "../modals/cloneModal";
import { UserData } from "../../mobxStores/userData/userData";
import { GVCMobx } from "../../mst/kinds/gvc";
import { DeleteModal as GVCDeleteModal } from "../../pages/gvc/deleteModal";
import { DeleteModal } from "../modals/deleteModal";
import { ServiceAccountMobx } from "../../mst/kinds/serviceaccount";
import { Value } from "../tag/value";
import { PolicyMobx } from "../../mst/kinds/policy";
import { ViewModal } from "../modals/viewModal";
import { BillingContext } from "../../mobxStores/billingContext/billingContext";
import { UIData } from "../../mobxStores/uiData/uiData";
import { ExportWithItemsModal } from "../../pages/gvc/exportWithItemsModal";
import { CommandModal_StartCronJob } from "../../pages/workload/commandModal_startCronJob";
import { NGButton } from "../../newcomponents/button/Button";
import { Theme } from "../../mobxStores/uiData/theme";
import { NGSwitch } from "../../newcomponents/switch";
import { GenericItemMobx } from "../../mst/item";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { TerraformContext } from "../../mobxStores/terraform/terraformContext";
import { CommandModal_ReplicaTermination } from "../../pages/workload/commands/commandModal_replicaTermination";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import { Tooltip } from "../Tooltip";
import { InfoTooltip } from "../InfoTooltip";
import { DateString } from "../dateString";
import { formatStrings } from "../../utils/time";
import { dateString } from "../dateStringFn";
import { Timezone } from "../../mobxStores/userData/timezone";
import { LocationMobx } from "../../mst/kinds/location";
import { InstallLocation, InstallLocationData } from "../../pages/location/installModal";
import { UninstallLocation, UninstallLocationData } from "../../pages/location/uninstallModal";
import NGAlert from "../../newcomponents/alert";
import { Deployment } from "../../schema/types/workload/deployment";
import { itemHelpers as itemHelpersBase } from "../../mst/kinds/item.helpers";
import { workloadHelpers } from "../../mst/kinds/workload.helpers";
import { TerraformImportModal } from "../modals/tfImportModal";
import { ReleaseUninstallModal } from "../../pages/marketplace/release/deleteModal";
import { MarketplaceReleaseInstance } from "../../pages/marketplace/types/release";

const noEditApply: (Kind | "account" | "marketplace-release" | "helm-release")[] = [
  "quota",
  "marketplace-release",
  "helm-release",
];
const noClone: (Kind | "account" | "marketplace-release" | "helm-release")[] = [
  "identity",
  "volumeset",
  "org",
  "agent",
  "cloudaccount",
  "domain",
  "image",
  "location",
  "quota",
  "secret",
  "auditctx",
  "serviceaccount",
  "user",
  //
  "marketplace-release",
  "helm-release",
];
const noDelete: (Kind | "account" | "marketplace-release" | "helm-release")[] = [
  "org",
  "location",
  "quota",
  "auditctx",
  "helm-release",
];
const noLock: (Kind | "account" | "marketplace-release" | "helm-release")[] = [
  ...noDelete,
  "account",
  "marketplace-release",
  "helm-release",
];

interface Props {
  deployments: Deployment[];
}
const DetailHeaderRaw: React.FC<Props> = ({ deployments }) => {
  const navigate = useNavigate();
  const { item, fetchItem } = useDetailContext();

  const isMarketplaceRelease = item.kind === "marketplace-release";
  const isHelmRelease = item.kind === "helm-release";

  const [timeKey, setTimeKey] = React.useState(Date.now());
  const [isLoading, setIsLoading] = React.useState(false);
  const [terraformImportCommand, setTerraformImportCommand] = React.useState("");
  const [action, setAction] = React.useState<
    | "view"
    | "clone"
    | "suspend"
    | "unsuspend"
    | "delete"
    | "replicaTermination"
    | "exportGVCWithItems"
    | "startCronJob"
    | "forceRedeploy"
    | "lock"
    | "tfImportCommand"
    | ""
  >("");

  const kind: any = item.kind;
  const itemHelpers = itemHelpersBase({ org: ConsoleContext.org!, gvc: ConsoleContext.gvc, kind: kind });
  let selfLink = (item as any).selfLink;
  if (!selfLink) {
    selfLink = linksOf(item as any).self!;
  }

  let showDelete = !noDelete.includes(kind);

  if (item.kind === "location" && (item as LocationMobx).provider === "byok") {
    showDelete = true;
  }
  if (item.kind === "serviceaccount" && (item as ServiceAccountMobx).origin !== "builtin") {
    showDelete = true;
  }

  function getIsLocked(): boolean {
    if (noLock.includes(kind)) {
      return false;
    }
    return (item as GenericItemMobx).tags["cpln/protected"] == "true";
  }

  const onLock = async () => {
    try {
      setIsLoading(true);
      const isLocked = getIsLocked();
      const link = resourceLink(item.kind as Kind, item.name);
      await request({ method: "patch", body: { tags: { "cpln/protected": !isLocked } }, url: link });
      notification.success({
        message: "Success",
        description: `Object was ${isLocked ? "unlocked" : "locked"} successfully`,
      });
      if (fetchItem) {
        fetchItem();
      }
      setIsLoading(false);
      setAction("");
    } catch (e) {
      setIsLoading(false);
      setAction("");
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
  };

  React.useEffect(() => {
    UIData.updateLastActivityTimestamp();
  }, [action]);

  // Workload specific
  const url =
    item.kind === "workload" && (item as WorkloadMobx).spec.type !== "cron" && (item as WorkloadMobx).status.endpoint;
  const { pathname } = useLocation();
  const [isWorkloadSuspended, setIsWorkloadSuspended] = React.useState<boolean>(null as any);
  const [installLocationData, setInstallLocationData] = React.useState<InstallLocationData | undefined>(undefined);
  const [uninstallLocationData, setUninstallLocationData] = React.useState<UninstallLocationData | undefined>(
    undefined,
  );
  const canExportToTerraform: boolean = TerraformContext.supportedKinds.includes(item.kind as Kind);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeKey(Date.now());
    }, 1 * 60 * 1_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    if (item.kind === "workload") {
      checkWorkloadSuspended();
    }
  }, [item.version]);

  React.useEffect(() => {
    if (terraformImportCommand) {
      setAction("tfImportCommand");
    }
  }, [terraformImportCommand]);

  async function checkWorkloadSuspended() {
    const value = await workloadHelpers.getAsyncIsSuspended(selfLink);
    setIsWorkloadSuspended(value);
  }

  async function changeGVCContextAfterClone(gvcName: string) {
    await UserData.requestGVCNames();
    ConsoleContext.setGVC(gvcName);
    await UserData.requestHasWorkload();
    PromptContext.setIsDisabled(true);
    ConsoleContext.setRedirect(`/console/org/${ConsoleContext.org}/gvc/${gvcName}`);
  }

  async function cloneGVC(body: any) {
    const _gvc = JSON.parse(JSON.stringify(body));

    // Make slim
    clearItem(_gvc);

    const link = parentLink("gvc");
    await request({ method: "post", body: _gvc, url: link });
  }

  async function cloneGeneric(body: any) {
    if (item.kind !== "account") {
      await request({ method: "post", url: parentLink(kind), body });
    }
  }

  async function handleActionsClick({ key }: any) {
    UIData.updateLastActivityTimestamp();
    switch (key) {
      case "suspend":
        if (isWorkloadSuspended) {
          setAction("unsuspend");
        } else {
          setAction("suspend");
        }
        break;
      case "force-redeployment":
        setAction("forceRedeploy");
        break;
      case "start-cron-job":
        setAction("startCronJob");
        break;
      case "install-location":
        const installNotificationKey: string = "install-location";
        notification.open({
          key: installNotificationKey,
          type: "info",
          message: "Install Location",
          description: "Fetching data from the server, please wait",
        });

        try {
          const url = (item as LocationMobx).links.find((location) => location.rel === "install")?.href;
          if (url) {
            const { data } = await request({
              url,
              service: "byok",
              method: "post",
            });
            setInstallLocationData(data as InstallLocationData);
          }
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed",
            description: errorMessage,
          });
        } finally {
          notification.destroy(installNotificationKey);
        }
        break;
      case "uninstall-location":
        const uninstallNotificationKey: string = "uninstall-location";
        notification.open({
          key: uninstallNotificationKey,
          type: "info",
          message: "Uninstall Location",
          description: "Fetching data from the server, please wait",
        });
        try {
          const url = (item as LocationMobx).links.find((location) => location.rel === "uninstall")?.href;
          if (url) {
            const { data } = await request({
              url,
              service: "byok",
              method: "post",
            });
            setUninstallLocationData(data as InstallLocationData);
          }
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed",
            description: errorMessage,
          });
        } finally {
          notification.destroy(uninstallNotificationKey);
        }
        break;
      case "edit":
        editApply();
        break;
      case "view":
        setAction("view");
        break;
      case "export-json":
        await itemHelpers.handleExportJSON([selfLink], false);
        break;
      case "export-yaml":
        await itemHelpers.handleExportYaml([selfLink], false);
        break;
      case "export-json-slim":
        await itemHelpers.handleExportJSON([selfLink], true);
        break;
      case "export-yaml-slim":
        await itemHelpers.handleExportYaml([selfLink], true);
        break;
      case "export-terraform":
        setTerraformImportCommand(await itemHelpers.handleExportTerraform([selfLink]));
        break;
      case "replica-termination":
        setAction("replicaTermination");
        break;
      case "export-gvc":
        setAction("exportGVCWithItems");
        break;
      case "clone":
        setAction("clone");
        break;
      case "delete":
        setAction("delete");
        break;
      default:
        break;
    }
  }

  function handleCopyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({ message: "Copied to clipboard" });
  }

  async function editApply() {
    Apply.setPrevPath(pathname);
    Apply.setItemLink(selfLink);
    navigate(`/console/apply`);
  }

  const items: ItemType<MenuItemType>[] = [];
  if (item.kind === "workload") {
    items.push(
      //
      { key: "suspend", label: isWorkloadSuspended ? "Unsuspend" : "Suspend" },
      { key: "force-redeployment", label: `Force Redeployment` },
    );
    if ((item as WorkloadMobx).isCron) {
      items.push({ key: "start-cron-job", label: `Start Cron Job` });
    }
    items.push({
      key: "replica-termination",
      label: `${(item as WorkloadMobx).spec.type === "stateful" ? "Restart" : "Stop"} Replica`,
    });
    items.push({ type: "divider" });
  }
  if (item.kind === "location" && (item as any).provider === "byok") {
    items.push(
      { key: "install-location", label: `Install` },
      { key: "uninstall-location", label: `Uninstall` },
      { type: "divider" },
    );
  }
  if (!noEditApply.includes(kind)) {
    items.push({ key: "edit", label: "Edit & Apply" });
  }
  if (!isMarketplaceRelease && !isHelmRelease) {
    items.push({ key: "view", label: "View" });
  }
  const exportItem = {
    key: "ignore2",
    label: "Export",
    children: [
      { key: "export-json", label: "JSON" },
      { key: "export-yaml", label: "YAML" },
      { key: "export-json-slim", label: "JSON Slim" },
      { key: "export-yaml-slim", label: "YAML Slim" },
    ],
  };
  if (canExportToTerraform) {
    exportItem.children.push({ key: "export-terraform", label: "Terraform" });
  }
  if (!isMarketplaceRelease && !isHelmRelease) {
    items.push(exportItem);
  }
  if (item.kind === "gvc") {
    items.push({ key: "export-gvc", label: `Export with Items` });
  }
  if (!noClone.includes(kind)) {
    items.push({ key: "clone", label: "Clone" });
  }
  if (showDelete) {
    items.push({ key: "delete", label: isMarketplaceRelease ? "Uninstall" : "Delete", danger: true });
  }

  let itemKind: string = item.kind;
  if (item.kind === "workload") {
    itemKind = `${(item as WorkloadMobx).spec.type} Workload`;
  } else if (item.kind === "policy") {
    itemKind = `Policy for ${pluralNameOfKind((item as PolicyMobx).targetKind)}`;
  } else if (isMarketplaceRelease) {
    itemKind = "Marketplace Release";
  } else if (isHelmRelease) {
    itemKind = "Helm Release";
  }

  let showByokMessage = false;
  if (item.kind === "workload") {
    const messageToCheck = "is disabled on BYOK clusters".toLowerCase();
    for (const deployment of deployments) {
      if (deployment.status?.message?.toLowerCase().includes(messageToCheck)) {
        showByokMessage = true;
      }
      for (const version of deployment.status?.versions || []) {
        if (version.message?.toLowerCase().includes(messageToCheck)) {
          showByokMessage = true;
        }
      }
    }
  }

  function viewModalOnClose() {
    setAction("");
  }

  return (
    <>
      <div className="n-detail-header border-b flex items-center justify-between pb-4 h-20 z-10">
        <div>
          <div data-testid="detail-kind" className="text-dark text-xs">
            {itemKind.toUpperCase()}
            {/* Account specific */}
            {itemKind === "account" && (item as any).company ? ` | COMPANY` : ""}
          </div>
          <div className="flex items-center">
            <div data-testid="detail-name" className="text-2xl font-semibold leading-none">
              {item.name}
              {/* Account specific */}
              {itemKind === "account" && (item as any).company ? ` | ${(item as any).company}` : ""}
            </div>
            {!!url && item.kind === "workload" && (item as WorkloadMobx).spec.type !== "cron" ? (
              <>
                <a
                  className="ml-4 ngfocus color-link"
                  href={url}
                  target={"_blank"}
                  style={{ transform: "translateY(2px)" }}
                >
                  Open
                  <ExternalLink className="ml-1 inline-block feather-icon" style={{ transform: "translateY(2px)" }} />
                </a>
                <button onClick={() => handleCopyToClipboard(url)} className="ml-3 ngfocus color-link">
                  <Copy className="feather-icon" style={{ transform: "translateY(1px)" }} />
                </button>
              </>
            ) : null}
            {item.kind === "workload" && (item as WorkloadMobx).suspendType != "notSuspended" ? (
              <span className={`ml-3 flex items-center `}>
                <NGAlert
                  size="small"
                  style={{ minWidth: "auto" }}
                  type={(item as WorkloadMobx).suspendType === "suspended" ? "error" : "warning"}
                  render={() => (
                    <div className="flex items-center">
                      <span>
                        {(item as WorkloadMobx).suspendType === "suspended" ? "Suspended" : "Partially Suspended"}
                      </span>
                      <InfoTooltip
                        title={[
                          "A workload is suspended or partially suspended depending on the defaultOptions and localOptions configuration.",
                          'Properties that decide this label are either "suspend=true", or "minScale=0" and "maxScale=0" at the same time.',
                        ]}
                        placement="bottom"
                      />
                    </div>
                  )}
                />
              </span>
            ) : null}
            {item.kind === "workload" && showByokMessage ? (
              <NGAlert
                className="ml-4"
                style={{ width: "fit-content", minWidth: "fit-content", maxWidth: "fit-content" }}
                type={"info"}
                size={"small"}
                message={`The canonical endpoint is disabled on BYOK clusters.`}
              />
            ) : null}
            {item.kind === "account" ? (
              <div className="ml-4 flex items-center">
                {item.roles.map((r) => (
                  <Value key={r} value={r} />
                ))}
              </div>
            ) : null}
            {item.kind === "account" ? (
              BillingContext.isTrialAccount ? (
                <div>
                  <Value key={"TYPE"} value={"TRIAL"} />
                </div>
              ) : null
            ) : null}
          </div>
        </div>
        <div className="flex-grow" />
        {item.kind === "account" ? null : (
          <div className="flex items-center gap-2">
            {isMarketplaceRelease || isHelmRelease ? (
              <Value value={`Revision ${item.revision}`} style={{ marginBottom: 0, marginRight: 0 }} />
            ) : (
              <Value value={`Version ${item.version}`} style={{ marginBottom: 0, marginRight: 0 }} />
            )}
            {fetchItem ? (
              <NGButton
                disabled={isLoading}
                loading={isLoading}
                onClick={() => fetchItem()}
                renderIcon={(hover, props) => <RefreshCw size={17} />}
                size="small"
                variant="secondary"
              />
            ) : null}
            {items.length > 0 ? (
              <Dropdown trigger={["click"]} menu={{ items: items, className: "menu", onClick: handleActionsClick }}>
                <NGButton
                  variant="primary"
                  outlined={Theme.theme === "light"}
                  size="small"
                  renderIcon={(hover, props) => <ChevronDown {...props} />}
                  loading={isLoading}
                  disabled={isLoading}
                  data-testid="usage-actions"
                >
                  Actions
                </NGButton>
              </Dropdown>
            ) : null}
            <>
              <Tooltip
                title={[
                  `Created: ${dateString(item.created, { format: formatStrings.humanizeWAgo })}`,
                  `Created (${Timezone.label}): ${dateString(item.created, {
                    format: formatStrings.log,
                  })}`,
                  Timezone.label !== "UTC"
                    ? `Created (UTC): ${dateString(item.created, {
                        format: formatStrings.log,
                        utc: true,
                      })}`
                    : "",
                  `Last Modified: ${dateString(item.lastModified, { format: formatStrings.humanizeWAgo })}`,
                  `Last Modified (${Timezone.label}): ${dateString(item.lastModified, {
                    format: formatStrings.log,
                  })}`,
                  Timezone.label !== "UTC"
                    ? `Last Modified (UTC): ${dateString(item.lastModified, {
                        format: formatStrings.log,
                        utc: true,
                      })}`
                    : "",
                ]}
              >
                <div key={timeKey} className="ml-2">
                  <DateString prefix={"Updated"} iso={item.lastModified} format={formatStrings.humanizeWAgo} />
                </div>
              </Tooltip>
              {noLock.includes(kind) ? null : (
                <div>
                  <Tooltip title={`Click to ${getIsLocked() ? "unlock" : "lock"} the object`} trigger={["hover"]}>
                    <NGSwitch
                      value={getIsLocked()}
                      aria-label="Toggle Lock"
                      onChange={() => setAction("lock")}
                      onIcon={() => <Lock size={8} color="var(--color-gray-600)" />}
                      offIcon={() => <Unlock size={8} color="var(--color-gray-600)" />}
                    />
                  </Tooltip>
                </div>
              )}
            </>
          </div>
        )}
      </div>
      {installLocationData ? (
        <InstallLocation installLocationData={installLocationData} onCancel={() => setInstallLocationData(undefined)} />
      ) : null}
      {uninstallLocationData ? (
        <UninstallLocation
          uninstallLocationData={uninstallLocationData}
          onCancel={() => setUninstallLocationData(undefined)}
        />
      ) : null}
      {action === "view" ? (
        <ViewModal
          kind={item.kind}
          link={(item as any).selfLink || linksOf(item as any).self!}
          visible={true}
          onClose={viewModalOnClose}
          title={item.name}
          filename={itemHelpers.getFileName()}
        />
      ) : action === "clone" ? (
        item.kind === "gvc" ? (
          <GVCCloneModal
            clone={cloneGVC}
            changeGVCContextAfterClone={changeGVCContextAfterClone}
            gvc={item as GVCMobx}
            onClose={() => setAction("")}
          />
        ) : (
          <CloneModal
            clone={cloneGeneric}
            kind={item.kind}
            link={(item as any).selfLink || linksOf(item as any).self!}
            name={item.name}
            onClose={() => setAction("")}
          />
        )
      ) : action === "exportGVCWithItems" ? (
        <ExportWithItemsModal gvc={item as GVCMobx} onClose={() => setAction("")} />
      ) : action === "replicaTermination" ? (
        <CommandModal_ReplicaTermination
          onClose={() => setAction("")}
          onDone={() => setAction("")}
          visible={action === "replicaTermination"}
        />
      ) : action === "delete" ? (
        item.kind === "gvc" ? (
          <GVCDeleteModal
            gvc={item as GVCMobx}
            onClose={() => setAction("")}
            onDone={() => setAction("")}
            visible={true}
          />
        ) : isMarketplaceRelease ? (
          <ReleaseUninstallModal
            release={item as MarketplaceReleaseInstance}
            onClose={() => setAction("")}
            onDone={() => setAction("")}
          />
        ) : (
          <DeleteModal
            kind={item.kind}
            links={[selfLink]}
            onCancel={() => setAction("")}
            onComplete={() => setAction("")}
          />
        )
      ) : action === "startCronJob" ? (
        item.kind === "workload" ? (
          <CommandModal_StartCronJob
            workloadMobx={item as WorkloadMobx}
            onDone={() => setAction("")}
            onClose={() => setAction("")}
            visible={true}
          />
        ) : null
      ) : action === "lock" ? (
        <Modal
          title={"Warning!"}
          open={true}
          onCancel={() => setAction("")}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setAction("")} disabled={isLoading}>
                Cancel
              </NGButton>
              <NGButton variant="primary" onClick={onLock} disabled={isLoading} loading={isLoading}>
                Confirm
              </NGButton>
            </div>
          }
        >
          <div>
            {getIsLocked()
              ? "Unlocking the object will allow deletion. Are you sure?"
              : "Locking the object will prevent deletion. Are you sure?"}
          </div>
        </Modal>
      ) : action === "suspend" ? (
        <Modal
          title={"Are you sure you want to suspend this workload?"}
          width={620}
          open={true}
          onCancel={() => setAction("")}
          footer={
            <div className="modal-actions">
              <NGButton disabled={isLoading} variant="secondary" onClick={() => setAction("")}>
                Cancel
              </NGButton>
              <NGButton
                disabled={isLoading}
                loading={isLoading}
                variant="primary"
                onClick={async () => {
                  setIsLoading(true);
                  const result = await workloadHelpers.handleSuspend([selfLink]);
                  if (result === true) {
                    notification.success({
                      message: "Success",
                      description: "Suspended workload",
                    });
                    window.location.reload();
                    setIsLoading(false);
                    setAction("");
                  } else {
                    setIsLoading(false);
                    setAction("");
                    notification.warning({
                      message: "Failed",
                      description: result,
                    });
                  }
                }}
              >
                Confirm
              </NGButton>
            </div>
          }
        >
          <div>
            <p>Suspending the workload will enable the "suspend" property.</p>
          </div>
        </Modal>
      ) : action === "unsuspend" ? (
        <Modal
          title={"Are you sure you want to unsuspend this workload?"}
          width={620}
          open={true}
          onCancel={() => setAction("")}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" disabled={isLoading} onClick={() => setAction("")}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                disabled={isLoading}
                loading={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  const result = await workloadHelpers.handleUnsuspend([selfLink]);
                  if (result === true) {
                    notification.success({
                      message: "Success",
                      description: "Unsuspended workload",
                    });
                    window.location.reload();
                    setIsLoading(false);
                    setAction("");
                  } else {
                    setIsLoading(false);
                    setAction("");
                    notification.warning({
                      message: "Failed",
                      description: result,
                    });
                  }
                }}
              >
                Unsuspend Workload
              </NGButton>
            </div>
          }
        >
          <div>
            <p>Unsuspending the workload will disable the "suspend" property.</p>
          </div>
        </Modal>
      ) : action === "forceRedeploy" ? (
        <Modal
          title={"Force Redeployment"}
          onCancel={() => setAction("")}
          open={true}
          footer={
            <div className="modal-actions">
              <NGButton disabled={isLoading} variant="secondary" onClick={() => setAction("")}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                loading={isLoading}
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  const result = await workloadHelpers.handleForceRedeployment([selfLink]);
                  if (result === true) {
                    await (item as WorkloadMobx).fetch();
                    notification.success({
                      message: "Success",
                      description: "Redeployment initiated",
                    });
                    updateLastDeploymentTimeOnHubspot();
                    setIsLoading(false);
                    setAction("");
                  } else {
                    setIsLoading(false);
                    setAction("");
                    notification.warning({
                      message: "Failed",
                      description: result,
                    });
                  }
                }}
              >
                Confirm
              </NGButton>
            </div>
          }
        >
          <div>Are you sure you want to force redeployment for this workload?</div>
        </Modal>
      ) : action === "tfImportCommand" ? (
        <TerraformImportModal command={terraformImportCommand} onClose={() => setAction("")} />
      ) : null}
    </>
  );
};

export const DetailHeader = observer(DetailHeaderRaw);
