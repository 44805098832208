import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { OrgMobx } from "../../mst/kinds/org";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";

interface Props {
  org: OrgMobx;
}
const ObservabilityRaw: React.FC<Props> = ({ org }) => {
  const { fetchItem } = useDetailContext();

  const logsRetentionDays = React.useRef(
    NumberModel.create({
      label: "Logs Retention Days",
      initialValue: org.spec?.observability?.logsRetentionDays,
      min: 0,
      isRequired: true,
    })
  );
  const metricsRetentionDays = React.useRef(
    NumberModel.create({
      label: "Metrics Retention Days",
      initialValue: org.spec?.observability?.metricsRetentionDays,
      min: 0,
      isRequired: true,
    })
  );
  const tracesRetentionDays = React.useRef(
    NumberModel.create({
      label: "Traces Retention Days",
      initialValue: org.spec?.observability?.tracesRetentionDays,
      min: 0,
      isRequired: true,
    })
  );

  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    setIsDirty(
      logsRetentionDays.current.isDirty || metricsRetentionDays.current.isDirty || tracesRetentionDays.current.isDirty
    );
  }, [logsRetentionDays.current.isDirty, metricsRetentionDays.current.isDirty, tracesRetentionDays.current.isDirty]);

  React.useEffect(() => {
    setIsValid(
      logsRetentionDays.current.isValid && metricsRetentionDays.current.isValid && tracesRetentionDays.current.isValid
    );
  }, [logsRetentionDays.current.isValid, metricsRetentionDays.current.isValid, tracesRetentionDays.current.isValid]);

  function reset() {
    logsRetentionDays.current.reset();
    metricsRetentionDays.current.reset();
    tracesRetentionDays.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      await org.patch({
        spec: {
          observability: {
            tracesRetentionDays: Number(tracesRetentionDays.current.value),
            logsRetentionDays: Number(logsRetentionDays.current.value),
            metricsRetentionDays: Number(metricsRetentionDays.current.value),
          },
        },
      });

      tracesRetentionDays.current.confirm();
      logsRetentionDays.current.confirm();
      metricsRetentionDays.current.confirm();

      notification.success({
        message: "Success",
        description: "Updated Observability",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Observability", e);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div style={{ width: 450 }}>
        <NGInputAdapter style={{ width: 450 }} className="mb-4" data={logsRetentionDays.current} />
        <NGInputAdapter style={{ width: 450 }} className="mb-4" data={metricsRetentionDays.current} />
        <NGInputAdapter style={{ width: 450 }} className="mb-4" data={tracesRetentionDays.current} />
        <FormButtons
          onReset={reset}
          onSave={save}
          resetDisabled={isLoading || !isDirty}
          saveDisabled={isLoading || !isDirty || !isValid}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export const Observability = observer(ObservabilityRaw);
