import { types } from "mobx-state-tree";
import { AutoscalerModel } from "./autoscaler";
import { TaintModel, UnmanagedPoolModel } from "./common";
import { NetworkingModel } from "./networking";
import { PreInstallScriptProperty } from "./preInstallScript";

const HetznerPoolModel = types.model("Hetzner Node Pool", {
  name: types.string, // regex /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/
  labels: types.maybe(types.frozen()), // ask for examples
  taints: types.array(TaintModel),
  serverType: types.string, // one of server types
  overrideImage: types.optional(types.string, ""),
  minSize: types.number, // min 0
  maxSize: types.number, // >=  minSize
});

export const HetznerProviderModel = types.model("Hetzner Provider", {
  region: types.string, // 'fsn1', 'nbg1', 'hel1', 'ash', 'hil'
  hetznerLabels: types.optional(types.frozen(), {}),
  tokenSecretLink: types.string,
  networkId: types.string,
  firewallId: types.maybe(types.string),
  nodePools: types.array(HetznerPoolModel),
  dedicatedServerNodePools: types.array(UnmanagedPoolModel),
  image: types.optional(types.string, ""),
  sshKey: types.maybe(types.string),
  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
  networking: types.optional(NetworkingModel, () => NetworkingModel.create()),
  preInstallScript: PreInstallScriptProperty,
  floatingIPSelector: types.optional(types.frozen(), {}), // If supplied, nodes will get assigned a random floating ip matching the selector
});
