const allowedCiphersForOldTLSVersions: string[] = [
  "DES-CBC3-SHA",
  "ECDHE-RSA-AES128-SHA",
  "ECDHE-RSA-AES256-SHA",
  "AES128-SHA",
  "AES256-SHA",
];

const allowedCiphersNew: string[] = [
  "ECDHE-ECDSA-AES256-GCM-SHA384",
  "ECDHE-ECDSA-CHACHA20-POLY1305",
  "ECDHE-ECDSA-AES128-GCM-SHA256",
  "ECDHE-RSA-AES256-GCM-SHA384",
  "ECDHE-RSA-CHACHA20-POLY1305",
  "ECDHE-RSA-AES128-GCM-SHA256",
  "AES256-GCM-SHA384",
  "AES128-GCM-SHA256",
];

const oldNewCipherMap: any = {
  TLS_RSA_WITH_AES_256_GCM_SHA384: "ECDHE-RSA-AES256-GCM-SHA384",
  TLS_RSA_WITH_AES_128_GCM_SHA256: "ECDHE-RSA-AES128-GCM-SHA256",
  TLS_ECDHE_RSA_WITH_CHACHA20_POLY1305_SHA256: "ECDHE-RSA-CHACHA20-POLY1305",
  TLS_ECDHE_RSA_WITH_AES_256_GCM_SHA384: "ECDHE-RSA-AES256-GCM-SHA384",
  TLS_ECDHE_RSA_WITH_AES_128_GCM_SHA256: "ECDHE-RSA-AES128-GCM-SHA256",
  TLS_CHACHA20_POLY1305_SHA256: "ECDHE-RSA-CHACHA20-POLY1305",
  TLS_AES_256_GCM_SHA384: "AES256-GCM-SHA384",
  TLS_AES_128_GCM_SHA256: "AES128-GCM-SHA256",
};

export const defaultCiphers: string[] = allowedCiphersNew;

export const allowedCiphers: string[] = [...allowedCiphersNew, ...allowedCiphersForOldTLSVersions];

export function convertCiphers(ciphers: string[]) {
  const _ciphers = JSON.parse(JSON.stringify(ciphers));
  const newCiphers: string[] = [];
  for (let cipher of _ciphers) {
    if (oldNewCipherMap[cipher]) {
      newCiphers.push(oldNewCipherMap[cipher]);
    } else {
      newCiphers.push(cipher);
    }
  }
  return newCiphers;
}
