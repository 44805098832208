import * as React from "react";
import { useLocation } from "react-router-dom";
import { ParsedLocation } from "./parsedLocation";

export const SetupParsedLocation: React.FC = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    ParsedLocation.parse(pathname);
  }, [pathname]);
  return null;
};
