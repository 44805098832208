import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { ServiceAccountMobx } from "../../mst/kinds/serviceaccount";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { ServiceAccountKeys } from "./keys";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  serviceaccount: ServiceAccountMobx;
}
const ServiceAccountDetailRaw: React.FC<Props> = ({ serviceaccount }) => {
  const basePath = useBasePath("/serviceaccount/:serviceaccount/*");

  const eventlogHook = useEventlogs(serviceaccount.selfLink);

  const [links] = React.useState([
    { label: "Info", url: "-info" },
    { label: "Keys", url: "-keys" },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ]);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info serviceaccount={serviceaccount} patch={serviceaccount.patch} />} />
        <Route
          path={`-keys`}
          element={
            <ServiceAccountKeys
              serviceaccount={serviceaccount}
              addKey={serviceaccount.addKey}
              removeKeys={serviceaccount.removeKeys}
            />
          }
        />
        <Route path={`-tags`} element={<Tags link={serviceaccount.selfLink} tags={serviceaccount.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"serviceaccount"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="serviceaccount" resourceId={serviceaccount.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={serviceaccount.selfLink} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const ServiceAccountDetail = observer(ServiceAccountDetailRaw);
