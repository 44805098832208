import * as React from "react";
import * as ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import { Affix } from "antd";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import NGAlert from "../../../newcomponents/alert";

const OrgInactiveAlertRaw: React.FC = () => {
  const { org } = ConsoleContext;
  const [isOrgInactive, setIsOrgInactive] = React.useState(false);

  React.useEffect(() => {
    fetchOrg();
  }, [org]);

  async function fetchOrg() {
    try {
      const { data: orgItemRes } = await request({ url: `/org/${org}` });
      setIsOrgInactive(orgItemRes.status.active === false);
    } catch (e) {
      setIsOrgInactive(false);
    }
  }

  if (isOrgInactive) {
    return ReactDOM.createPortal(
      <Affix offsetBottom={20}>
        <NGAlert
          type="warning"
          message={`This organization is inactive. Please contact your organization administrator to reactivate this
        organization.`}
        />
      </Affix>,
      document.getElementById("affix-root")!
    );
  }

  return null;
};

export const OrgInactiveAlert = observer(OrgInactiveAlertRaw);
