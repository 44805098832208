import { TableColumn } from "../../types";
import { DateString } from "../../../../components/dateString";
import { formatStrings } from "../../../../utils/time";
import { Timezone } from "../../../../mobxStores/userData/timezone";

interface DateStringColumnOptions {
  format?: string;
}
export function DateStringColumn<TData>(
  column: Partial<TableColumn<TData>>,
  options: DateStringColumnOptions = { format: formatStrings.date },
) {
  const preset: Partial<TableColumn<TData>> = {
    label: `Timestamp (${Timezone.label})`,
    size: 230,
    maxSize: 230,
    cell: ({ getValue }) => <DateString iso={getValue()} format={options.format} />,
  };
  return Object.assign(preset, column) as TableColumn<TData>;
}
