import * as React from "react";
import { Table } from "../../newcomponents/table/table";
import { DateString } from "../../components/dateString";
import { formatStrings } from "../../utils/time";
import { HelmReleaseInstance, ReleaseState, ReleaseStateDeployment } from "../marketplace/types/release";

interface Props {
  release: HelmReleaseInstance;
  state: ReleaseState;
}
export const ReleaseRevisions: React.FC<Props> = ({ release, state }) => {
  const releaseStateDeploymentTableItems: ReleaseStateDeployment[] = state.deployments
    .toSorted((a, b) => {
      if (a.revision > b.revision) return -1;
      if (b.revision > a.revision) return 1;
      return 0;
    })
    .map((d) => {
      return {
        ...d,
      };
    });

  return (
    //
    <>
      <Table<ReleaseStateDeployment>
        columns={[
          {
            id: "revision",
            label: "Revision",
            size: 100,
          },
          {
            id: "description",
            label: "Description",
          },
          {
            id: "status",
            label: "Status",
          },
          {
            id: "updated",
            label: "Updated",
            cell: ({ getValue }) => {
              return <DateString iso={getValue()} format={formatStrings.log} />;
            },
          },
        ]}
        data={releaseStateDeploymentTableItems}
        title={"Revisions"}
        tableId="release-revisions-update1"
        hideSettings
      />
    </>
  );
};
