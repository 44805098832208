import { observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PolicyTargetQuery } from "./target-query";
import { TabsLayout, TabsLayoutLink } from "../../components/tabsLayout/tabsLayout";
import { PolicyTargetItems } from "./target-items";
import { PolicyMobx } from "../../mst/kinds/policy";

interface Props {
  policy: PolicyMobx;
}
const PolicyTargetsRaw: React.FC<Props> = ({ policy }) => {
  const links: TabsLayoutLink[] = [
    { url: `-items`, label: "Items" },
    { url: `-query`, label: "Query" },
  ];

  return (
    <TabsLayout links={links}>
      <Routes>
        <Route index element={<Navigate to={`-items`} />} />
        <Route path={`-items/*`} element={<PolicyTargetItems policy={policy} />} />
        <Route path={`-query/*`} element={<PolicyTargetQuery policy={policy} />} />
      </Routes>
    </TabsLayout>
  );
};

export const PolicyTargets = observer(PolicyTargetsRaw);
