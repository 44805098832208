import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel, WithCreatedModel } from "../base";
import { request } from "../../services/cpln";

const ServiceaccountKeyModel = types.compose(
  "ServiceaccountKey",
  WithCreatedModel,
  types.model({
    name: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
  })
);

export interface ServiceaccountKeyMobx extends Instance<typeof ServiceaccountKeyModel> {}

export const ServiceaccountModel = types
  .compose(
    "Serviceaccount",
    BaseModel,
    types.model({
      keys: types.array(ServiceaccountKeyModel),
      origin: types.optional(types.string, "default"), // "default", "builtin"
    })
  )
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    const addKey: (description: string) => Promise<string> = flow(function* (description: string) {
      const { data }: any = yield request({ method: "post", url: self.selfLink + "/-addKey", body: { description } });
      yield self.fetch();
      return data.key;
    });
    const removeKeys: (keys: string[]) => Promise<void> = flow(function* (keys: string[]) {
      const body = Object.assign({}, { "$drop/keys": keys }, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch, addKey, removeKeys };
  });

export interface ServiceAccountMobx extends Instance<typeof ServiceaccountModel> {}
