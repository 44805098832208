import * as React from "react";
import { NGButton } from "../../button/Button";
import { RefreshCw } from "react-feather";

interface Props {
  onClick: () => Promise<void>;
}
export const TableHeaderRefreshButton: React.FC<Props> = ({ onClick }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  async function localOnClick() {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  }

  return (
    <NGButton
      variant="secondary"
      renderIcon={(_, props) => <RefreshCw {...props} />}
      onClick={localOnClick}
      loading={isLoading}
      disabled={isLoading}
    >
      Refresh
    </NGButton>
  );
};
