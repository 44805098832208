import * as React from "react";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { observer } from "mobx-react-lite";
import { FormElement } from "../../../components/forms/formElement";

interface Props {
  name: StringMobx;
}
const LocationCreateGeneralRaw: React.FC<Props> = ({ name }) => {
  return (
    <>
      <NGFormElement
        name={"name"}
        value={name.value}
        onChange={name.setValue}
        label={name.label}
        required={name.isRequired}
        error={name.error}
      />
      <div className={`w-3/12`}>
        <FormElement label={"Provider"} value={"byok"} />
      </div>
    </>
  );
};

export const LocationCreateGeneral = observer(LocationCreateGeneralRaw);
