import * as React from "react";
import { observer } from "mobx-react-lite";
import { notification, Modal } from "antd";
import { nameOfKind, request, getName, pluralNameOfKind, homeLink, linksOf } from "../../services/cpln";
import { NGButton } from "../../newcomponents/button/Button";

interface Props {
  names: string[];
  onCancel: any;
  onComplete: any;
}

const DeleteModalRaw: React.FC<Props> = ({ names, onCancel, onComplete }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  async function getImageLinksOfRepository(repository: string): Promise<string[]> {
    let images: string[] = [];

    const body = {
      kind: "image",
      spec: {
        match: "all",
        terms: [
          {
            op: "=",
            property: "repository",
            value: repository,
          },
        ],
      },
    };
    let nextLink: string | undefined = homeLink("image") + "/-query";
    while (nextLink) {
      const { data } = await request({ method: "post", url: nextLink, body: body });
      images = images.concat(data.items.map((item) => linksOf(item).self!));
      nextLink = linksOf(data).next;
    }

    return images;
  }

  async function confirmDelete() {
    try {
      setIsLoading(true);
      const promises: Promise<any>[] = [];

      for (const name of names) {
        promises.push(getImageLinksOfRepository(name));
      }
      const responses = await Promise.allSettled(promises);
      let imageLinks: string[] = [];
      const failedRepositories: string[] = [];

      for (const nameIndexStr in names) {
        const nameIndex = Number(nameIndexStr);
        const name = names[nameIndex];
        const response = responses[nameIndex];

        if (response.status === "rejected") {
          failedRepositories.push(name);
          continue;
        }

        imageLinks = imageLinks.concat(response.value);
      }

      const failedImages: string[] = [];
      for (const imageLink of imageLinks) {
        promises.push(request({ method: "delete", url: imageLink }));
      }
      const deleteResponses = await Promise.allSettled(promises);

      for (const imageLinkIndexStr in imageLinks) {
        const imageLinkIndex = Number(imageLinkIndexStr);
        const imageLink = imageLinks[imageLinkIndex];
        const response = deleteResponses[imageLinkIndex];

        if (response.status === "rejected") {
          failedImages.push(imageLink);
          continue;
        }
      }

      notification.success({
        message: "Success",
        description: `Deleted ${failedImages.length > 0 || failedRepositories.length > 0 ? "Some " : ""}Images`,
      });

      if (failedImages.length > 0 || failedRepositories.length > 0)
        notification.warning({
          message: "Failures",
          duration: 60 * 3,
          description: (
            <div className="flex flex-col">
              <div>Failed to Delete Some Images/Repositories</div>
              <div className="text-sm">
                {failedRepositories.length > 0 ? (
                  <>
                    <div>Repositories;</div>
                    {failedRepositories.map((v) => (
                      <div>{v}</div>
                    ))}
                  </>
                ) : null}
                {failedImages.length > 0 ? (
                  <>
                    <div>Images;</div>
                    {failedImages.map((v) => (
                      <div>{v}</div>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          ),
        });
      onComplete();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to delete",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title={
        names.length < 1 ? null : (
          <>
            Delete Repository:{" "}
            <span className="font-bold" style={{ color: "var(--color-danger)" }}>
              {names.length < 2 ? names[0] : `${names.length} Repositories`}
            </span>
          </>
        )
      }
      open={true}
      closable={false}
      maskClosable={!isLoading}
      onCancel={onCancel}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={onCancel} disabled={isLoading}>
            Cancel
          </NGButton>
          <NGButton variant="danger" onClick={confirmDelete} loading={isLoading} disabled={isLoading}>
            Delete
          </NGButton>
        </div>
      }
      destroyOnClose
    >
      <span>
        Are you sure you want to delete {names.length > 1 ? "these" : "this"}{" "}
        {names.length > 1 ? "repositories" : "repository"}? This action cannot be undone.
      </span>
    </Modal>
  );
};

export const DeleteModal = observer(DeleteModalRaw);
