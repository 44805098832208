import { NGButton } from "../../button/Button";
import { PlusCircle } from "react-feather";
import { _Kind } from "../../../mst/kinds";
import { useNavigate } from "react-router-dom";
import { parentLink } from "../../../services/cpln";

interface IProps {
  kind: _Kind;
}
export const TableNewItemButton = ({ kind }: IProps) => {
  const navigate = useNavigate();
  return (
    <NGButton
      variant="action"
      onClick={() => navigate(`/console${parentLink(kind)}/-create`)}
      renderIcon={(_, props) => <PlusCircle {...props} />}
    >
      New
    </NGButton>
  );
};
