import * as React from "react";
import { observer, Observer } from "mobx-react-lite";
import { useNavigate, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { Modal, notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { LinksRender } from "./createNav";
import { WorkloadCreateGeneral } from "./general";
import { WorkloadCreateContainers } from "./containers";
import { WorkloadCreateFirewall as WorkloadCreateFirewall } from "./firewall";
import { WorkloadCreateIdentity } from "./identity";
import { linksOf, parentLink, request, resourceLink } from "../../../services/cpln";
import { WorkloadCreateOptions } from "./options";
import { updateLastDeploymentTimeOnHubspot } from "../../../services/utils";
import { TagsNew } from "../../../components/create/tags";
import { WorkloadCreateJob } from "./job";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { UserData } from "../../../mobxStores/userData/userData";
import { WorkloadCreateRolloutOptions } from "./rolloutOptions";
import { WorkloadCreateSecurityOptions } from "./securityOptions";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { AuthContent } from "../../../components/detail/authContent";
import { CplnJwtDraftMobx, CplnJwtDraftModel } from "../../../mst/stores/cpln.jwt.client.draft";
import { BooleanModel } from "../../../mobxDataModels/booleanModel";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { ListOfItemsModel } from "../../../mobxDataModels/listOfItemsModel";
import { NumberModel } from "../../../mobxDataModels/numberModel";
import { Gvc } from "../../../schema/types/gvc";
import { WorkloadCreateLoadBalancer } from "./loadBalancer";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateRaw: React.FC<Props> = ({ workloadDraft }) => {
  const basePath = useBasePath("/gvc/:gvc/workload/-create/*");
  const formDataRef = React.useRef(new NGFormData());
  const navigate = useNavigate();
  const { gvc } = ConsoleContext;
  const [gvcEnv, setGvcEnv] = React.useState<{ name: string; value: string }[]>([]);
  const { pathname } = useLocation();
  const [isExampleApplied, setIsExampleApplied] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [gvcLocationNames, setGVCLocationNames] = React.useState<string[]>([]);
  const firewallCheckedRef = React.useRef<boolean>(false);
  const identityCheckedRef = React.useRef<boolean>(false);

  function setIdentityChecked() {
    identityCheckedRef.current = true;
  }

  const authDraftRef = React.useRef<CplnJwtDraftMobx>(
    CplnJwtDraftModel.create({
      enableAuthFilter: BooleanModel.create({ initialValue: false }),
      name: StringModel.create({ label: "Name", isRequired: true, initialValue: "" }),
      issuer: StringModel.create({
        label: "Issuer",
        isRequired: true,
        initialValue: "",
        validationKey: "url",
      }),
      excludedWorkloads: ListOfItemsModel.create({
        _items: [],
      }),
      audiences: ListOfItemsModel.create({
        _items: [],
      }),
      claim_to_headers: ListOfItemsModel.create({
        _items: [],
      }),
      remote_jwks_http_uri: StringModel.create({
        label: "JWKS HTTP URI",
        isRequired: true,
        initialValue: "",
        validationKey: "url",
      }),
      remote_jwks_http_uri_timeout: NumberModel.create({
        label: "Default URI Timeout (seconds)",
        min: 0,
        initialValue: "",
        isRequired: true,
      }),
      rules: ListOfItemsModel.create({
        _items: [
          {
            firstValue: "/metric",
            secondValue: "true",
          },
          {
            firstValue: "/",
            secondValue: "false",
          },
        ],
      }),
    }),
  );

  React.useEffect(() => {
    if (workloadDraft.processingIdentity) {
      return;
    }
    workloadDraft.processIdentity(workloadDraft.identityLink);
  }, [workloadDraft.identityLink, workloadDraft.secretLinks.length]);

  React.useEffect(() => {
    const startingPage = `${basePath}/-general`;
    navigate(startingPage, { replace: true });
    fetchGVCItemAndLocations();
  }, []);

  async function fetchGVCItemAndLocations() {
    try {
      const { data: gvcData } = await request<Gvc>({ url: resourceLink("gvc", gvc!) });
      setGvcEnv(gvcData.spec?.env || []);
      let _gvcLocations: string[] = [];
      _gvcLocations = (gvcData.spec?.staticPlacement?.locationLinks || []).map((x) => x.split("/")[4]);

      const _queryUrl = linksOf(gvcData).queryresult;
      if (_queryUrl) {
        const { data: queryData } = await request({ url: _queryUrl });
        _gvcLocations = _gvcLocations.concat(queryData.items.map((l: any) => l.name));
      }

      setGVCLocationNames(_gvcLocations);
    } catch (e) {
      setGVCLocationNames([]);
    }
  }

  function addContainer() {
    const containerIndex = workloadDraft.addContainer();
    navigate(`${basePath}/-containers/${containerIndex}`);
  }

  function removeContainer() {
    const containerIndex = workloadDraft.removeContainer();
    navigate(`${basePath}/-containers/${containerIndex}`);
  }

  async function tryConfirm() {
    if (!identityCheckedRef.current && ["required", "unknown", false].includes(workloadDraft.identityValid)) {
      const m = Modal.warning({
        title: "Attention: Identity",
        width: 600,
        content: (
          <div>
            {workloadDraft.identityValid === "required" ? (
              <p className="mb-2">
                You haven't selected an identity which is required because this workload references secrets.
              </p>
            ) : workloadDraft.identityValid === "unknown" ? (
              <p className="mb-2">We could not determine the validity of identity with your account's permissions.</p>
            ) : workloadDraft.identityValid === false ? (
              <p className="mb-2">
                Either identity is not valid for this workload, or your account's permissions are not enough to
                determine validity.
              </p>
            ) : null}
            <p className="mb-2">You can still continue to create this workload and fix the issue later.</p>
          </div>
        ),
        footer: (
          <div className="modal-actions">
            <NGButton
              variant="primary"
              onClick={() => {
                identityCheckedRef.current = true;
                navigate(`${basePath}/-identity`);
                m.destroy();
              }}
            >
              I Understand
            </NGButton>
          </div>
        ),
      });
    } else if (
      (workloadDraft.isServerless || workloadDraft.isStandard) &&
      !firewallCheckedRef.current &&
      !workloadDraft.firewall.external_allInboundAllowed &&
      workloadDraft.firewall.external_inboundAllowCIDR.length < 1
    ) {
      const m = Modal.confirm({
        title: "Attention: Inbound Access Blocked",
        width: 600,
        content: (
          <Observer>
            {() => (
              <>
                <p className="mb-2">Internet access to this workload is restricted.</p>
                <p className="mb-2">To have your workload accept traffic, you must allow all inbound requests.</p>
                <div className={`flex items-center`}>
                  <div>
                    <NGSwitch
                      data-testid="switch-all-inbound-allowed"
                      value={workloadDraft.firewall.external_allInboundAllowed}
                      onChange={workloadDraft.firewall.external_setAllInboundAllowed}
                    >
                      <NGLabelText>Allow All Inbound Requests</NGLabelText>
                    </NGSwitch>
                  </div>
                  <InfoTooltip title={`Sets CIDR to '0.0.0.0/0'`} />
                </div>
              </>
            )}
          </Observer>
        ),
        footer: (
          <div className="modal-actions">
            <NGButton variant="secondary" onClick={() => m.destroy()}>
              Close
            </NGButton>
            <NGButton
              variant="primary"
              onClick={() => {
                firewallCheckedRef.current = true;
                tryConfirm();
                m.destroy();
              }}
            >
              Create Workload
            </NGButton>
          </div>
        ),
      });
    } else {
      onConfirm();
    }
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      let body = workloadDraft.asObject;
      if (authDraftRef.current.enableAuthFilter.value) {
        body.spec.sidecar = { envoy: authDraftRef.current.asObject };
      }
      await request({ method: "post", body, url: parentLink("workload") });
      const itemLink = resourceLink("workload", body.name);
      notification.success({
        message: "Success",
        description: "Workload is created",
      });
      await UserData.requestHasWorkload();
      updateLastDeploymentTimeOnHubspot();
      PromptContext.setIsDisabled(true);
      navigate(`/console${itemLink}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/workload/-create");
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={() => ({ kind: "workload", ...workloadDraft.asObject })}
          next={workloadDraft.next(pathname, authDraftRef.current.enableAuthFilter.value, authDraftRef.current.isValid)}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Workload"}
          name={workloadDraft.name.value}
          canCreate={
            workloadDraft.isValid && (!authDraftRef.current.enableAuthFilter.value || authDraftRef.current.isValid)
          }
          onConfirm={tryConfirm}
          isLoading={isLoading}
          render={() => {
            return (
              <div className="flex items-center gap-4">
                {(workloadDraft.isServerless || workloadDraft.isStateful) && !workloadDraft.isServingTraffic ? (
                  <>
                    <NGAlert type={"error"} message={`At least one container must serve traffic.`} />
                    {pathname.includes("/-containers/") ? null : (
                      <NGButton onClick={() => navigate(`${basePath}/-containers`)} variant={"secondary"}>
                        View Container
                      </NGButton>
                    )}
                  </>
                ) : (workloadDraft.isServerless || workloadDraft.isStandard) && !workloadDraft.isInboundPublic ? (
                  <>
                    <NGAlert type={"info"} message={`Internet access to this workload is restricted.`} />
                    <NGButton
                      variant={"action"}
                      onClick={() => {
                        workloadDraft.firewall.external_setAllInboundAllowed(true);
                      }}
                    >
                      Make Public
                    </NGButton>
                    {pathname.includes("/-firewall") ? null : (
                      <NGButton variant={"secondary"} onClick={() => navigate(`${basePath}/-firewall`)}>
                        View Firewall
                      </NGButton>
                    )}
                  </>
                ) : workloadDraft.identityValid === "required" ? (
                  <>
                    <NGAlert type={"info"} message={`Identity needs to be set to allow access to secrets.`} />
                    {pathname.includes("/-identity") ? null : (
                      <NGButton onClick={() => navigate(`${basePath}/-identity`)} variant={"secondary"}>
                        View Identity
                      </NGButton>
                    )}
                  </>
                ) : pathname.includes("/-identity") ? null : workloadDraft.identityValid === "unknown" ||
                  workloadDraft.identityValid === false ? (
                  <>
                    <NGAlert type={"info"} message={`Identity setting needs attention.`} />
                    <NGButton onClick={() => navigate(`${basePath}/-identity`)} variant={"secondary"}>
                      View Identity
                    </NGButton>
                  </>
                ) : null}
              </div>
            );
          }}
          LinksRender={() => (
            <LinksRender
              enableAuthFilter={authDraftRef.current.enableAuthFilter.value}
              isAuthValid={authDraftRef.current.isValid}
              workloadDraft={workloadDraft}
              addContainer={addContainer}
              removeContainer={removeContainer}
            />
          )}
        >
          <div className="hidden">
            {workloadDraft.isServingTraffic}
            {workloadDraft.isInboundPublic}
          </div>
          <Routes>
            <Route index element={<Navigate to={`-general`} replace />} />
            <Route
              path={`-general`}
              element={
                <WorkloadCreateGeneral
                  workloadDraft={workloadDraft}
                  isExampleApplied={isExampleApplied}
                  setIsExampleApplied={setIsExampleApplied}
                />
              }
            />
            <Route
              path={`-containers/*`}
              element={<WorkloadCreateContainers workloadDraft={workloadDraft} gvcEnv={gvcEnv} />}
            />
            <Route
              path={`-identity`}
              element={<WorkloadCreateIdentity workloadDraft={workloadDraft} setIdentityChecked={setIdentityChecked} />}
            />
            <Route
              path={`-options/*`}
              element={<WorkloadCreateOptions workloadDraft={workloadDraft} gvcLocationNames={gvcLocationNames} />}
            />
            {workloadDraft.isStateful || workloadDraft.isStandard ? (
              <Route path={`-rollout/*`} element={<WorkloadCreateRolloutOptions workloadDraft={workloadDraft} />} />
            ) : null}
            <Route path={`-security/*`} element={<WorkloadCreateSecurityOptions workloadDraft={workloadDraft} />} />
            <Route path={`-firewall`} element={<WorkloadCreateFirewall workloadDraft={workloadDraft} />} />
            <Route path={`-loadbalancer`} element={<WorkloadCreateLoadBalancer workloadDraft={workloadDraft} />} />
            <Route path={`-auth`} element={<AuthContent kind="workload" authDraftRef={authDraftRef.current} />} />
            {workloadDraft.isCron ? (
              <Route path={`-job`} element={<WorkloadCreateJob workloadDraft={workloadDraft} />} />
            ) : null}
            <Route path={`-tags`} element={<TagsNew tags={workloadDraft.tags} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const WorkloadCreate = observer(WorkloadCreateRaw);
