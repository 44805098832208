import { TableColumn } from "../../types";
import { Secret } from "../../../../schema/types/secret";

const secretTypeLabelMap = {
  aws: "AWS",
  "azure-connector": "Azure Connector",
  "azure-sdk": "Azure SDK",
  docker: "Docker",
  dictionary: "Dictionary",
  ecr: "ECR",
  gcp: "GCP",
  keypair: "Keypair",
  "nats-account": "Nats Account",
  opaque: "Opaque",
  tls: "TLS",
  userpass: "Username & Password",
};

const typeDefault = { id: "type", label: "Type" };
export function SecretTypeColumn<TData extends Secret>(column: TableColumn<TData> = typeDefault) {
  const preset: TableColumn<TData> = {
    ...typeDefault,
    canGroupByDistinctValues: true,
    groupLabelMap: secretTypeLabelMap,
    size: 210,
    groupOrder: [
      "aws",
      "azure-connect",
      "azure-sdk",
      "docker",
      "dictionary",
      "ecr",
      "gcp",
      "keypair",
      "nats-account",
      "opaque",
      "tls",
      "userpass",
    ],
    cell: (p) => {
      const secret = p.row.original;
      return <span>{secretTypeLabelMap[secret.type]}</span>;
    },
  };
  return Object.assign(preset, column);
}
