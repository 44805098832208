import { types, flow, applySnapshot } from "mobx-state-tree";
import { BaseModel, defaultValues } from "../base";
import type { Instance } from "mobx-state-tree";
import { request } from "../../services/cpln";
import { TracingModel } from "./tracing";
import { Org, OrgStatus } from "../../schema/types/org";

const OrgStatusModel = types.model("OrgStatus", {
  accountLink: types.maybe(types.refinement(types.string, (value) => /^\/account\/[a-f0-9-]+$/.test(value))),
  active: types.optional(types.boolean, true),
});
export interface OrgStatusMobx extends Instance<typeof OrgStatusModel> {}
export function getDefaultOrgStatus(): OrgStatus {
  return {
    accountLink: "",
    active: true,
  };
}

const LoggingS3Model = types.model("LoggingS3", {
  bucket: types.optional(types.string, ""),
  region: types.optional(types.string, ""),
  prefix: types.optional(types.string, "/"),
  credentials: types.optional(types.string, ""), // aws type secret link
});
export interface LoggingS3Mobx extends Instance<typeof LoggingS3Model> {}

const LoggingCoralogixModel = types.model("LoggingCoralogix", {
  cluster: types.optional(
    types.enumeration([
      "coralogix.com",
      "coralogix.us",
      "app.coralogix.in",
      "app.eu2.coralogix.com",
      "app.coralogixsg.com",
    ]),
    defaultValues.org.logging.coralogix.cluster as any
  ),
  credentials: types.optional(types.string, ""), // secret of type opaque
  app: types.optional(types.string, ""), // validCoralogixPattern
  subsystem: types.optional(types.string, ""),
});
export interface LoggingCoralogixMobx extends Instance<typeof LoggingCoralogixModel> {}

const LoggingDatadogModel = types.model("LoggingDatadog", {
  host: types.optional(
    types.enumeration([
      "http-intake.logs.datadoghq.com",
      "http-intake.logs.us3.datadoghq.com",
      "http-intake.logs.us5.datadoghq.com",
      "http-intake.logs.datadoghq.eu",
    ]),
    defaultValues.org.logging.datadog.host as any
  ),
  credentials: types.optional(types.string, ""), // secret of type opaque
});
export interface LoggingDatadogMobx extends Instance<typeof LoggingDatadogModel> {}

const LoggingLogzioModel = types.model({
  listenerHost: types.optional(
    types.enumeration(["listener.logz.io", "listener-nl.logz.io"]),
    defaultValues.org.logging.logzio.listenerHost as any
  ),
  credentials: types.optional(types.string, ""), // secret of type opaque
});
export interface LoggingLogzioMobx extends Instance<typeof LoggingLogzioModel> {}

const LoggingElasticModel = types.model({
  aws: types.maybe(
    types.model({
      host: types.string,
      port: types.optional(types.number, 443),
      index: types.string,
      type: types.string,
      credentials: types.string, // aws
      region: types.string,
    })
  ),
  elasticCloud: types.maybe(
    types.model({
      index: types.string,
      type: types.string,
      credentials: types.string, // userpass
      cloudId: types.string,
    })
  ),
  generic: types.maybe(
    types.model({
      host: types.string,
      port: types.optional(types.number, 443),
      path: types.optional(types.string, ""), // /^\//
      index: types.string,
      type: types.string,
      credentials: types.string, // userpass
    })
  ),
});
export interface LoggingElasticMobx extends Instance<typeof LoggingElasticModel> {}

export const LoggingCloudWatchModelRetentionDaysOptions = [
  1,
  3,
  5,
  7,
  14,
  30,
  60,
  90,
  120,
  150,
  180,
  365,
  400,
  545,
  731,
  1827,
  3653,
];

export const LoggingCloudWatchModelRegionOptions = [
  "af-south-1",
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-south-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "ca-central-1",
  "eu-central-1",
  "eu-north-1",
  "eu-south-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "me-south-1",
  "sa-east-1",
  "us-east-1",
  "us-east-2",
  "us-west-1",
  "us-west-2",
];
const LoggingCloudWatchModel = types.model({
  region: types.string,
  credentials: types.string, // aws
  retentionDays: types.optional(types.number, 1), // 1, 3, 5, 7, 14, 30, 60, 90, 120, 150, 180, 365, 400, 545, 731, 1827, 3653
  groupName: types.string,
  streamName: types.string,
  extractFields: types.optional(types.frozen(), {}),
});
export interface LoggingCloudWatchMobx extends Instance<typeof LoggingCloudWatchModel> {}

export const LoggingStackdriverModelRegionOptions = [
  "us-east1",
  "us-east4",
  "us-central1",
  "us-west1",
  "europe-west4",
  "europe-west1",
  "europe-west3",
  "europe-west2",
  "asia-east1",
  "asia-southeast1",
  "asia-northeast1",
  "asia-south1",
  "australia-southeast1",
  "southamerica-east1",
  "africa-south1",
  "asia-east2",
  "asia-northeast2",
  "asia-northeast3",
  "asia-south2",
  "asia-southeast2",
  "australia-southeast2",
  "europe-central2",
  "europe-north1",
  "europe-southwest1",
  "europe-west10",
  "europe-west12",
  "europe-west6",
  "europe-west8",
  "europe-west9",
  "me-central1",
  "me-central2",
  "me-west1",
  "northamerica-northeast1",
  "northamerica-northeast2",
  "southamerica-west1",
  "us-east5",
  "us-south1",
  "us-west2",
  "us-west3",
  "us-west4",
];
const LoggingStackdriverModel = types.model({
  location: types.string,
  credentials: types.string, // gcp
});
export interface LoggingStackdriverMobx extends Instance<typeof LoggingStackdriverModel> {}

export const LoggingSyslogModelModeOptions = [
  { label: "TCP", value: "tcp" },
  { label: "TLS", value: "tls" },
  { label: "UDP", value: "udp" },
];

export const LoggingSyslogModelFormatOptions = [
  { label: "RFC3164", value: "rfc3164" },
  { label: "RFC5424", value: "rfc5424" },
];

export const LoggingSyslogModelSeverityOptions = [
  { label: "0", value: 0, info: "" },
  { label: "1", value: 1, info: "" },
  { label: "2", value: 2, info: "" },
  { label: "3", value: 3, info: "" },
  { label: "4", value: 4, info: "" },
  { label: "5", value: 5, info: "" },
  { label: "6", value: 6, info: "" },
  { label: "7", value: 7, info: "" },
];
export const LoggingSyslogModel = types.model({
  host: types.string,
  port: types.number,
  mode: types.optional(types.string, LoggingSyslogModelModeOptions[0].value),
  format: types.optional(types.string, LoggingSyslogModelFormatOptions[0].value),
  severity: types.optional(types.number, 6),
});
export interface LoggingSyslogMobx extends Instance<typeof LoggingSyslogModel> {}

const LoggingFluentdModel = types.model({
  host: types.string,
  port: types.optional(types.number, defaultValues.org.logging.fluentd.port), // between 1 and 65545
});
export interface LoggingFluentdMobx extends Instance<typeof LoggingFluentdModel> {}

const OrgLoggingModel = types.model("OrgLogging", {
  s3: types.maybe(LoggingS3Model),
  coralogix: types.maybe(LoggingCoralogixModel),
  datadog: types.maybe(LoggingDatadogModel),
  logzio: types.maybe(LoggingLogzioModel),
  elastic: types.maybe(LoggingElasticModel),
  cloudWatch: types.maybe(LoggingCloudWatchModel),
  fluentd: types.maybe(LoggingFluentdModel),
  stackdriver: types.maybe(LoggingStackdriverModel),
  syslog: types.maybe(LoggingSyslogModel),
});

export const AuthConfigModel = types.model("AuthConfig", {
  domainAutoMembers: types.array(types.string),
  samlOnly: types.optional(types.boolean, false),
});

export const ObservabilityModel = types.model("Observability", {
  logsRetentionDays: types.optional(types.number, 30),
  metricsRetentionDays: types.optional(types.number, 30),
  tracesRetentionDays: types.optional(types.number, 30),
});

export const ThreatDetectionModelSyslogTransportOptions = [
  { label: "TCP", value: "tcp" },
  { label: "UDP", value: "udp" },
];

export const ThreatDetectionModelMinimumSeverityOptions = [
  { label: "Warning", value: "warning" },
  { label: "Error", value: "error" },
  { label: "Critical", value: "critical" },
];

export const ThreatDetectionModel = types.model("ThreatDetection", {
  enabled: types.optional(types.boolean, false),
  minimumSeverity: types.optional(types.string, ThreatDetectionModelMinimumSeverityOptions[0].value),
  syslog: types.model("Syslog", {
    transport: types.optional(types.string, ThreatDetectionModelSyslogTransportOptions[0].value),
    host: types.string,
    port: types.number,
  }),
});

export const SecurityModel = types.model("Security", {
  threatDetection: types.maybe(ThreatDetectionModel),
});

const OrgSpecModel = types.model("OrgSpec", {
  logging: types.maybe(OrgLoggingModel),
  extraLogging: types.maybe(types.array(OrgLoggingModel)),
  tracing: types.maybe(TracingModel),
  sessionTimeoutSeconds: types.maybe(types.number),
  authConfig: types.maybe(AuthConfigModel),
  observability: types.maybe(ObservabilityModel),
  security: types.maybe(SecurityModel),
});

export const OrgModel = types
  .compose(
    "Org",
    BaseModel,
    types.model({
      status: types.maybe(OrgStatusModel),
      spec: types.maybe(OrgSpecModel),
    })
  )
  .actions((self) => ({
    setName(value: string) {
      self.name = value;
    },
    setDescription(value: string) {
      self.description = value;
    },
    setTags(value: { [_: string]: string }) {
      self.tags = value;
    },
  }))
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });
export interface OrgMobx extends Instance<typeof OrgModel> {}

export function getDefaultOrg(): Org {
  return {
    created: new Date().toString(),
    description: "",
    id: "",
    kind: "org",
    lastModified: new Date().toString(),
    links: [],
    name: "",
    tags: {},
    version: 1,
  };
}
