import * as React from "react";
import { PolicyMobx, BindingModel } from "../../../mst/kinds/policy";
import { observer } from "mobx-react-lite";
import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { TabsLayout, TabsLayoutLink } from "../../../components/tabsLayout/tabsLayout";
import { notification } from "antd";
import { ArrowLeft } from "react-feather";
import { useDetailContext } from "../../../components/detail/detailContext";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { NGButton } from "../../../newcomponents/button/Button";
import { useSetPromptShouldBlock } from "../../../reactHooks/useSetPromptShouldBlock";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { homeLink, request } from "../../../services/cpln";
import { Table } from "../../../newcomponents/table/table";
import NGAlert from "../../../newcomponents/alert";
import { Permission, Permissions } from "../../../schema/types/permissions";
import { permissionsHelpers } from "../../../mst/kinds/permissions.helpers";
import { NameDescriptionNoLinkColumn } from "../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { UserTable } from "../create/targetTables/user";
import { ServiceaccountTable } from "../create/targetTables/serviceaccount";
import { GroupTable } from "../create/targetTables/group";
import { IdentityTable } from "../create/targetTables/identity";

interface PermissionTableItem extends Permission {
  kind: "permission";
  description: string;
  implied: string[];
  impliedText: string;
}

interface Props {
  policy: PolicyMobx;
}
const PolicyAddBindingRaw: React.FC<Props> = ({ policy }) => {
  const basePath = React.useContext(BasePathContext);
  const bindingsPath = `${basePath}/-bindings`;
  const newBindingPath = `${bindingsPath}/new`;
  const { fetchItem } = useDetailContext();

  const bindingRef = React.useRef(BindingModel.create());

  const navigate = useNavigate();

  const [permissionsResponse, setPermissionsResponse] = React.useState<Permissions>();

  React.useEffect(() => {
    fetchPermissions();
  }, []);

  async function fetchPermissions() {
    const { data } = await request({ url: homeLink(policy.targetKind) + "/-schema/permissions" });
    setPermissionsResponse(data);
  }

  let permissionTableItems: PermissionTableItem[] = [];
  if (permissionsResponse) {
    permissionTableItems = permissionsResponse.items.map((p) => ({
      ...p,
      description: p.description || "",
      kind: "permission",
      implied: permissionsHelpers.getImplied(permissionsResponse, p),
      impliedText: permissionsHelpers.getImpliedText(permissionsResponse, p),
    }));
  }

  const [permissionSelections, setPermissionSelections] = React.useState<string[]>([]);
  const [userSelections, setUserSelections] = React.useState<string[]>([]);
  const [serviceaccountSelections, setServiceaccountSelections] = React.useState<string[]>([]);
  const [groupSelections, setGroupSelections] = React.useState<string[]>([]);
  const [identitySelections, setIdentitySelections] = React.useState<string[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [canCreate, setCanCreate] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty);
  }, [isDirty]);

  useCleanPrompt();
  useSetPromptShouldBlock(shouldBlockNavigation);

  React.useEffect(() => {
    let res = false;
    if (permissionSelections.length > 0) res = true;
    if (userSelections.length > 0) res = true;
    if (serviceaccountSelections.length > 0) res = true;
    if (groupSelections.length > 0) res = true;
    if (identitySelections.length > 0) res = true;
    setIsDirty(res);
  }, [
    permissionSelections.length,
    userSelections.length,
    serviceaccountSelections.length,
    groupSelections.length,
    identitySelections.length,
  ]);

  React.useEffect(() => {
    let res = true;
    if (permissionSelections.length < 1) res = false;
    if (
      userSelections.length + //
        serviceaccountSelections.length +
        groupSelections.length +
        identitySelections.length <
      1
    )
      res = false;
    setCanCreate(res);
  }, [
    permissionSelections.length,
    userSelections.length,
    serviceaccountSelections.length,
    groupSelections.length,
    identitySelections.length,
  ]);

  function cancel() {
    navigate(bindingsPath);
  }

  async function create() {
    try {
      setIsLoading(true);
      const bindings = policy.bindings.map((b) => b.asObject);
      bindings.push({
        permissions: permissionSelections,
        principalLinks: [
          ...userSelections, //
          ...serviceaccountSelections,
          ...groupSelections,
          ...identitySelections,
        ],
      });
      await policy.patch({ bindings });
      PromptContext.setIsDisabled(true);
      navigate(bindingsPath);
      notification.success({
        message: "Success",
        description: "Added binding",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  const links: TabsLayoutLink[] = [
    { url: `${newBindingPath}/-permissions`, label: "Permissions" },
    { url: `${newBindingPath}/-users`, label: `Users` },
    { url: `${newBindingPath}/-serviceaccounts`, label: `Service Accounts` },
    { url: `${newBindingPath}/-groups`, label: `Groups` },
    { url: `${newBindingPath}/-identities`, label: `Identities` },
  ];

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return pathname.split("-bindings").filter(Boolean).length < 2;
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <button className="ngfocus color-link" onClick={() => navigate(bindingsPath)}>
          <ArrowLeft className="mr-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          <span>Back to Bindings</span>
        </button>
      </div>
      <TabsLayout links={links}>
        <Routes>
          <Route index element={<Navigate to={`-permissions`} />} />
          <Route
            path={`-permissions`}
            element={
              <>
                <NGAlert
                  message={`A permission defines which action rights are given to the principals (users, service accounts,
                    groups, and identities).`}
                />
                <Table<PermissionTableItem>
                  tableId="policy-detail-bindings-binding-add"
                  selectMode={"multi"}
                  selectKey="name"
                  selections={permissionSelections}
                  onSelectionsChange={setPermissionSelections}
                  data={permissionTableItems}
                  columns={[
                    NameDescriptionNoLinkColumn(), //
                    { id: "impliedText", label: "Implied" },
                  ]}
                  hideSettings
                />
              </>
            }
          />
          <Route
            key={"userTable"}
            path={`-users`}
            element={<UserTable title="Users" selections={userSelections} onSelectionsChange={setUserSelections} />}
          />
          <Route
            key={"serviceaccountTable"}
            path={`-serviceaccounts`}
            element={
              <ServiceaccountTable
                title="Service Accounts"
                selections={serviceaccountSelections}
                onSelectionsChange={setServiceaccountSelections}
              />
            }
          />
          <Route
            key={"groupTable"}
            path={`-groups`}
            element={
              <GroupTable title={"Groups"} selections={groupSelections} onSelectionsChange={setGroupSelections} />
            }
          />
          <Route
            key={"identityTable"}
            path={`-identities`}
            element={
              <IdentityTable
                title={"Identities"}
                selections={identitySelections}
                onSelectionsChange={setIdentitySelections}
              />
            }
          />
        </Routes>
      </TabsLayout>
      <div className="mt-8 flex items-center">
        <NGButton style={{ width: 215, marginRight: 10 }} disabled={isLoading} variant={"danger"} onClick={cancel}>
          Cancel
        </NGButton>
        <NGButton
          style={{ width: 215, marginLeft: 10 }}
          disabled={isLoading || !isDirty || !canCreate}
          loading={isLoading}
          variant={"primary"}
          onClick={create}
        >
          Create
        </NGButton>
      </div>
    </>
  );
};

export const PolicyAddBinding = observer(PolicyAddBindingRaw);
