import React from "react";

export const useDebounce = <T = string>(
  initialValue: T,
  delayMs: number
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = React.useState(initialValue);
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delayMs);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return [debouncedValue, setValue];
};
