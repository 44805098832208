import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { LocationMobx } from "../../mst/kinds/location";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Used } from "./used";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { Tags } from "../../components/detail/tags";

interface Props {
  location: LocationMobx;
}
const LocationDetailRaw: React.FC<Props> = ({ location }) => {
  const basePath = useBasePath("/location/:location/*");

  const [links] = React.useState([
    { label: "Info", url: "-info" },
    { label: "Used in GVCs", url: "-gvcs" },
    { label: "Tags", url: "-tags" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ]);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info location={location} />} />
        <Route path={`-gvcs`} element={<Used locationLink={location.selfLink} />} />
        <Route path={`-tags`} element={<Tags link={location.selfLink} tags={location.tags} />} />
        <Route path={`-audittrail`} element={<AuditTrail kind="location" resourceId={location.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={location.selfLink} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const LocationDetail = observer(LocationDetailRaw);
