import * as React from "react";
import { ChevronDown, ChevronUp, ExternalLink } from "react-feather";
import { NavLink, useLocation } from "react-router-dom";
import "./ngNav.scss";
import clsx from "clsx";

export interface InnerNavLink {
  label: string;
  url: string;
  type?: "default" | "action" | "danger" | "externalLink" | "separator";
  disableFold?: boolean;
  externalLink?: {
    action: string;
    method?: string; // POST
    inputs: { name: string; value: string }[];
  };
  isDirty?: boolean;
  visible?: boolean; // = true;
  renderIcon?: () => React.ReactElement; // = () => null
  links?: InnerNavLink[];
}

interface Props {
  links: InnerNavLink[];
}

export const NGDetailNav: React.FC<Props> = ({ links }) => {
  const [collapsedUrls, setCollapsedUrls] = React.useState<string[]>([]);

  function toggleCollapse(url: string) {
    if (collapsedUrls.includes(url)) {
      setCollapsedUrls((x) => x.filter((xi) => xi !== url));
    } else {
      setCollapsedUrls((x) => [...x, url]);
    }
  }

  return (
    <ul className="flex flex-col text-light-600 mr-4">
      {links.map((link, index) => {
        if (link.type === "separator") {
          return <span key={index} className="border-b mb-2" />;
        }

        if (link.type === "externalLink") {
          return <FormLink key={link.url} link={link} />;
        }

        return (
          <RenderInnerNavLink
            key={link.url}
            level={0}
            link={link}
            collapsedUrls={collapsedUrls}
            toggleUrl={toggleCollapse}
          />
        );
      })}
    </ul>
  );
};

interface RenderInnerNavLinkProps {
  level?: number;
  parentPath?: string;
  link: InnerNavLink;
  collapsedUrls: string[];
  toggleUrl: (url: string) => void;
}
function RenderInnerNavLink(props: RenderInnerNavLinkProps) {
  const { pathname } = useLocation();

  const { level = 0, link, collapsedUrls, toggleUrl, parentPath = "" } = props;
  link.links = link.links || [];

  const linkUrl = [parentPath, link.url].filter(Boolean).join("/");

  const isCollapsed = !link.disableFold && collapsedUrls.includes(linkUrl);

  let backgroundMultiplier = level + 1;
  if ((level === 0 && link.links.length < 1) || isCollapsed) {
    backgroundMultiplier = 0;
  }

  // rgba(${colorPrefix} 0.05)
  // const colorPrefix = Theme.theme === "light" ? "0, 0, 0," : "255, 255, 255,";

  return (
    <>
      <div
        className={clsx("rounded", {
          "pb-1 mb-1": link.links.length > 0 && !isCollapsed && level > 1,
          ngnavitemgroup: level === 2 && !isCollapsed && link.links.length > 0,
        })}
        // style={{
        //   backgroundColor: level === 2 && !isCollapsed && link.links.length > 0 ? `rgba(${colorPrefix} 0.05)` : "",
        // }}
      >
        <div className="relative h-8 flex rounded items-center mb-1">
          <NavLink
            data-testid={`detail-${link.label.toLowerCase()}`}
            className={({ isActive }) =>
              clsx(
                "ngnavitem rounded absolute inset-0 flex items-center focus pl-2",
                {
                  "pl-4": level > 0,
                  // "pl-6": level > 1,
                  // "pl-8": level > 2,
                  "pr-8": link.links && link.links.length > 0 && (level === 2 || level === 0),
                  active: isActive,
                  disabled: pathname.includes("/-create/") && !(link as any).isActive,
                },
                link.type,
                // isAdd ? "text-action-600" : "text-link hover:text-link"
                // isAdd ? "text-action hover:text-action-400" : "hover:text-link"
                // isSub && "truncate pl-4 w-40"
              )
            }
            onClick={(e) => {
              if (pathname.includes("/-create/") && !(link as any).isActive) {
                e.preventDefault();
                return;
              }
            }}
            to={
              pathname.includes("/-create/") && !(link as any).isActive
                ? ""
                : linkUrl.startsWith("/")
                ? linkUrl
                : linkUrl
            }
            end
            // isActive={(_: any, location: any) => location.pathname.endsWith(linkUrl)}
          >
            <span className="truncate">{link.label}</span>
            {link.renderIcon ? link.renderIcon() : null}
          </NavLink>
          {!link.disableFold && link.links.length > 0 && (level === 2 || level === 0) ? (
            <button
              className="right-4 nginnerbutton"
              style={{ position: "absolute" }}
              onClick={() => toggleUrl(linkUrl)}
            >
              {isCollapsed ? <ChevronDown /> : <ChevronUp />}
            </button>
          ) : null}
        </div>
        {isCollapsed
          ? null
          : link.links?.map((subLink) => (
              <RenderInnerNavLink
                key={subLink.url}
                level={level + 1}
                link={subLink}
                parentPath={linkUrl}
                collapsedUrls={collapsedUrls}
                toggleUrl={toggleUrl}
              />
            ))}
      </div>
    </>
  );
}

interface FormLinkProps {
  link: InnerNavLink;
}
function FormLink(props: FormLinkProps) {
  const { link } = props;

  const form = link.externalLink!;

  return (
    <span key={link.url} className="rounded h-8 flex items-center mb-1 w-full cursor-pointer pl-2 ngnavitem">
      <form id={link.label} className="hidden" action={form.action} method={form.method || "POST"} target="_blank">
        {form.inputs.map((input) => (
          <input key={input.name} name={input.name} value={input.value} type="hidden" />
        ))}
        <button type="submit">{link.label}</button>
      </form>
      <a
        data-testid={`detail-${link.label.toLowerCase()}`}
        className={`w-full`}
        href={""}
        onClick={(e) => {
          e.preventDefault();
          const formEl: HTMLFormElement = document.getElementById(link.label) as HTMLFormElement;
          formEl.submit();
        }}
        target={"_blank"}
      >
        {link.label}
        <ExternalLink className="ml-1 inline-block feather-icon color-link" style={{ transform: "translateY(2px)" }} />
      </a>
    </span>
  );
}
