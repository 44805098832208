import React from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { Search, Loader } from "react-feather";
import { SelectModel } from "../../../mobxDataModels/selectModel";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { request } from "../../../services/cpln";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { Audit } from "../../auditTrail/types";
import { getAuditTrailPresets, getDefaultAuditTrailTime } from "../../auditTrail/utils";
import { ViewModal } from "../../../components/modals/viewModal";
import { AuditGridWrapper } from "../../../components/detail/auditGridWrapper";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGInput } from "../../../newcomponents/input/input";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { RangePicker } from "../../../components/antd/RangePicker";
import { Timezone } from "../../../mobxStores/userData/timezone";

const AuditTrailRaw: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { AccountUUID } = BillingContext;
  const { kind, name, subject, from, to } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const kindSelectRef = React.useRef(
    SelectModel.create({
      label: "Kind",
      initialValue: "any",
      options: [
        { label: "Any Kind", value: "any" },
        { label: "Account", value: "account" },
        { label: "Alert", value: "alert" },
        { label: "Org", value: "org" },
        { label: "Payment Method", value: "payment-method" },
        { label: "User", value: "user" },
      ],
    })
  );
  const kindSelect = kindSelectRef.current;
  const nameInputRef = React.useRef(StringModel.create({ label: "Resource Name" }));
  const nameInput = nameInputRef.current;
  const idInputRef = React.useRef(StringModel.create({ label: "Resource Id" }));
  const idInput = idInputRef.current;
  const subjectInputRef = React.useRef(StringModel.create({ label: "Subject Name" }));
  const subjectInput = subjectInputRef.current;

  // TODO support when it cannot be null
  const [fromISO, setFromISO] = React.useState<string | null>(null);
  const [toISO, setToISO] = React.useState<string | null>(null);

  const [isUrlStateProcessed, setIsUrlStateProcessed] = React.useState(false);
  const [queried, setQueried] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [audits, setAudits] = React.useState<Audit[]>([]);
  const [showDetailsOf, setShowDetailsOf] = React.useState<null | string>(null);
  const nextLinkRef = React.useRef<string | undefined>(undefined);
  const itemsRef = React.useRef<HTMLDivElement>(null as any);
  const [isIdDisabled, setIsIdDisabled] = React.useState(false);
  const [isNameDisabled, setIsNameDisabled] = React.useState(false);

  React.useEffect(() => {
    if (isUrlStateProcessed) {
      fetchData();
    }
  }, [isUrlStateProcessed]);

  React.useEffect(() => {
    if (kind) {
      kindSelect.setValue(kind.toString());
    }
    if (name) {
      nameInput.setValue(name.toString());
    }
    if (subject) {
      subjectInput.setValue(subject.toString());
    }
    if (from) {
      setFromISO(from.toString());
    } else {
      setFromISO(getDefaultAuditTrailTime());
    }
    if (to) {
      setToISO(to.toString());
    }
    setIsUrlStateProcessed(true);
  }, []);

  React.useEffect(() => {
    setIsIdDisabled(nameInputRef.current.value.length > 0);
    setIsNameDisabled(idInputRef.current.value.length > 0);
  }, [idInputRef.current.value, nameInputRef.current.value]);

  React.useEffect(() => {
    if (!queried || !isUrlStateProcessed) {
      return;
    }

    const qsObject: any = {};
    if (kindSelect.value) {
      qsObject.kind = kindSelect.value;
    }
    if (nameInput.value) {
      qsObject.name = nameInput.value;
    }
    if (subjectInput.value) {
      qsObject.subject = subjectInput.value;
    }
    if (idInput.value) {
      qsObject.id = idInput.value;
    }
    if (fromISO) {
      qsObject.from = fromISO;
    }
    if (toISO) {
      qsObject.to = toISO;
    }

    const queryString = qs.stringify(qsObject, { arrayFormat: "comma", encode: false });
    navigate({ search: `?${queryString}` });
  }, [kindSelect.value, nameInput.value, subjectInput.value, idInput.value, fromISO, toISO]);

  function onScroll_Items() {
    if (!nextLinkRef.current) return;
    const { scrollHeight, scrollTop, clientHeight } = itemsRef.current;
    const val = scrollHeight - (scrollTop + clientHeight);
    if (val < 1) {
      fetchData();
    }
  }

  function onQuery() {
    nextLinkRef.current = undefined;
    setAudits([]);
    fetchData();
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      let url = `/audit/account/${AccountUUID}`;
      if (nextLinkRef.current) {
        url = nextLinkRef.current;
      } else {
        let params: string[] = [];
        if (nameInput.value.length > 0) {
          url += `/resource/name/${nameInput.value}`;
        }
        if (idInput.value.length > 0) {
          url += `/resource/id/${idInput.value}`;
        }
        if (kindSelect.value !== "any") {
          params.push(`resourceType=${kindSelect.value}`);
        }
        if (subjectInput.value.length > 0) {
          params.push(`subjectName=${subjectInput.value}`);
        }
        if (fromISO) {
          params.push(`fromEvent=${fromISO}`);
        }
        if (toISO) {
          params.push(`toEvent=${toISO}`);
        }
        if (params.length > 0) {
          url += `?${params.join("&")}`;
        }
      }
      const { data } = await request({ service: "audit", url });
      if (nextLinkRef.current) {
        setAudits([...audits, ...data.items]);
      } else {
        setAudits(data.items);
      }
      const _nextLink = data.links.find((l: any) => l.rel === "next")?.href;
      if (data.items.length === 100) {
        nextLinkRef.current = _nextLink;
      } else {
        nextLinkRef.current = undefined;
      }
      setQueried(true);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function onClear() {
    kindSelect.setValue("any");
    nameInput.setInitialValue("");
    nameInput.setValue("");
    subjectInput.setInitialValue("");
    subjectInput.setValue("");
    idInput.setInitialValue("");
    idInput.setValue("");
    setFromISO(getDefaultAuditTrailTime());
    setToISO(null);
  }

  return (
    <>
      <div className="mb-4">Audit Trail</div>
      <div className="flex items-center mb-2">
        <NGSelect
          className="mr-2"
          style={{ width: 150 }}
          placeholder={kindSelect.label}
          onChange={kindSelect.setValue}
          options={kindSelect.options}
          value={kindSelect.value}
        />
        <NGInput
          className="mr-2"
          style={{ width: 450 }}
          value={nameInput.value}
          placeholder={nameInput.label}
          onChange={(e) => nameInput.setValue(e.target.value)}
          disabled={isNameDisabled}
        />
        <NGInput
          className="flex-grow basis-0 mr-2"
          value={subjectInput.value}
          placeholder={subjectInput.label}
          onChange={(e) => subjectInput.setValue(e.target.value)}
        />
        <NGButton style={{ width: 150 }} variant={"primary"} onClick={onQuery} disabled={isLoading} loading={isLoading}>
          Query
        </NGButton>
      </div>
      <div className="flex items-center mb-2">
        <div className="mr-2" style={{ width: 150 }} />
        <NGInput
          className="mr-2"
          style={{ width: 450 }}
          value={idInput.value}
          placeholder={idInput.label}
          onChange={(e) => idInput.setValue(e.target.value)}
          disabled={isIdDisabled}
        />
        {/* TODO either only allow utc, or show timezone */}
        <RangePicker
          className="flex-grow basis-0 mr-2"
          presets={getAuditTrailPresets()}
          size={"small"}
          allowClear={false}
          placeholder={["From", "To"]}
          showTime
          allowEmpty={[false, true]}
          fromISO={fromISO}
          setFromISO={setFromISO}
          toISO={toISO}
          setToISO={setToISO}
          timezoneValue={Timezone.value}
        />
        <NGButton
          style={{ width: 150 }}
          onClick={() => {
            onClear();
          }}
          variant={"secondary"}
          disabled={isLoading}
        >
          Clear All Filters
        </NGButton>
      </div>
      {isLoading && !nextLinkRef.current && (
        <div className="flex flex-col items-center p-4 border" style={{ borderRadius: 6 }}>
          <Loader className="mb-2 animate-spin" />
          <div className="text-center text-2xl">Loading Audit Events</div>
        </div>
      )}
      {audits.length < 1 && queried && !isLoading ? (
        <div className="flex flex-col items-center p-4 border">
          <Search className="mb-2" />
          <div className="text-center text-2xl mt-2">No Audit Events Found</div>
        </div>
      ) : null}
      <AuditGridWrapper
        audits={audits}
        diffSupport={false}
        emptySpaceValue={470}
        onDetail={(id) => setShowDetailsOf(id)}
        onScroll={onScroll_Items}
        queried={queried}
      />
      {showDetailsOf ? (
        <ViewModal
          object={audits.find((a) => a.id === showDetailsOf)}
          title={"Audit Log"}
          onClose={() => setShowDetailsOf(null)}
          visible={!!showDetailsOf}
          filename={`audit-${audits.find((a) => a.id === showDetailsOf)?.id}`}
        />
      ) : null}
    </>
  );
};

export const AuditTrail = observer(AuditTrailRaw);
