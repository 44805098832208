import * as React from "react";
import { observer } from "mobx-react-lite";
import { TabsLayout, TabsLayoutLink } from "../../components/tabsLayout/tabsLayout";
import { Routes, Route, Navigate } from "react-router-dom";
import { UserList } from "./list";
import { Invite } from "./invite";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const ListAndInviteRaw: React.FC = () => {
  const basePath = `/console/org/${ConsoleContext.org}/user/-index`;

  const links: TabsLayoutLink[] = [
    { url: `${basePath}/-list`, label: "Users" },
    { url: `${basePath}/-invite`, label: "Invite" },
  ];
  return (
    <TabsLayout links={links}>
      <Routes>
        <Route index element={<Navigate to={`-list`} />} />
        <Route path={`-list`} element={<UserList />} />
        <Route path={`-invite`} element={<Invite />} />
      </Routes>
    </TabsLayout>
  );
};

export const ListAndInvite = observer(ListAndInviteRaw);
