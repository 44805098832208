import { types, Instance } from "mobx-state-tree";
import { WorkloadDraftLoadBalancerDirectModel } from "./workload.draft.lb.direct";
import { StringModel } from "../../mobxDataModels/stringModel";

export const WorkloadDraftLoadBalancerModel = types
  .model({
    direct: types.optional(WorkloadDraftLoadBalancerDirectModel, () => WorkloadDraftLoadBalancerDirectModel.create()),
    _enabledGeoHeaders: types.optional(types.boolean, false),
    enabledGeoHeaders: types.optional(types.boolean, false),
    _geoHeaderAsn: types.maybe(types.string),
    geoHeaderAsn: types.optional(StringModel, () => StringModel.create({ label: "ASN" })),
    _geoHeaderCity: types.maybe(types.string),
    geoHeaderCity: types.optional(StringModel, () => StringModel.create({ label: "City" })),
    _geoHeaderCountry: types.maybe(types.string),
    geoHeaderCountry: types.optional(StringModel, () => StringModel.create({ label: "Country" })),
    _geoHeaderRegion: types.maybe(types.string),
    geoHeaderRegion: types.optional(StringModel, () => StringModel.create({ label: "Region" })),
  })
  .actions((self) => ({
    reset() {
      self.direct.reset();
      self.enabledGeoHeaders = self._enabledGeoHeaders;
      self.geoHeaderAsn.setInitialValue(String(self._geoHeaderAsn || ""));
      self.geoHeaderCity.setInitialValue(String(self._geoHeaderCity || ""));
      self.geoHeaderCountry.setInitialValue(String(self._geoHeaderCountry || ""));
      self.geoHeaderRegion.setInitialValue(String(self._geoHeaderRegion || ""));
    },
    setEnabledGeoHeaders(value: boolean) {
      self.enabledGeoHeaders = value;
    },
    confirm() {
      self.direct.confirm();
      self._enabledGeoHeaders = self.enabledGeoHeaders;
      self._geoHeaderAsn = self.geoHeaderAsn.value;
      self.geoHeaderAsn.confirm();
      self.geoHeaderCity.confirm();
      self.geoHeaderCountry.confirm();
      self.geoHeaderRegion.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.direct.isDirty) res = true;
      if (self._enabledGeoHeaders !== self.enabledGeoHeaders) res = true;
      if (self.enabledGeoHeaders) {
        if (self.geoHeaderAsn.isDirty) res = true;
        if (self.geoHeaderCity.isDirty) res = true;
        if (self.geoHeaderCountry.isDirty) res = true;
        if (self.geoHeaderRegion.isDirty) res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.direct.isValid) res = false;
      if (self.enabledGeoHeaders) {
        if (
          !self.geoHeaderAsn.value &&
          !self.geoHeaderCity.value &&
          !self.geoHeaderCountry.value &&
          !self.geoHeaderRegion.value
        ) {
          res = false;
        }
        const headers = [
          self.geoHeaderAsn.value,
          self.geoHeaderCity.value,
          self.geoHeaderCountry.value,
          self.geoHeaderRegion.value,
        ].filter(Boolean);
        const headersSet = new Set(headers);
        if (headers.length != headersSet.size) {
          res = false;
        }
      }
      return res;
    },
    get asObject() {
      const obj: any = { direct: self.direct.asObject };
      if (self.enabledGeoHeaders) {
        obj.geoLocation = {
          enabled: true,
        };
        const headers: any = {};
        if (self.geoHeaderAsn.value) {
          headers.asn = self.geoHeaderAsn.value;
        }
        if (self.geoHeaderCity.value) {
          headers.city = self.geoHeaderCity.value;
        }
        if (self.geoHeaderCountry.value) {
          headers.country = self.geoHeaderCountry.value;
        }
        if (self.geoHeaderRegion.value) {
          headers.region = self.geoHeaderRegion.value;
        }
        obj.geoLocation.headers = headers;
      }
      return obj;
    },
  }));
export interface WorkloadDraftLoadBalancerMobx extends Instance<typeof WorkloadDraftLoadBalancerModel> {}
