import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../../advanced/providerAutoscaler";
import { ProviderPreInstallScript } from "../../../advanced/providerPreInstallScript";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderLambdalabsAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const l = mk8sDraft.provider_lambdalabs!;

  return (
    //
    <>
      <div className="mb-4">
        <ProviderPreInstallScript provider="lambdalabs" preInstallScript={l.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderAutoscaler provider="lambdalabs" autoscaler={l.autoscaler} />
      </div>
    </>
  );
};

export const Mk8sCreateProviderLambdalabsAdvanced = observer(Mk8sCreateProviderLambdalabsAdvancedRaw);
