import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftMobx } from "../../../mst/stores/identity.draft";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";

interface Props {
  identity: IdentityDraftMobx;
}
const IdentityCreateGeneralRaw: React.FC<Props> = ({ identity }) => {
  return (
    <>
      <NGFormElement
        name="name"
        label={identity.name.label}
        value={identity.name.value}
        onChange={identity.name.setValue}
      />
      <NGFormElement
        name="description"
        label={identity.description.label}
        value={identity.description.value}
        onChange={identity.description.setValue}
      />
    </>
  );
};

export const IdentityCreateGeneral = observer(IdentityCreateGeneralRaw);
