import React from "react";
import { Modal, Menu, Dropdown } from "antd";
import { Observer, observer } from "mobx-react-lite";
import { useNavigate, useLocation } from "react-router-dom";
import { TimezoneDisplay } from "../TimezoneDisplay";
import { ParsedLocation } from "../../../../mobxStores/parsedLocation/parsedLocation";
import { User } from "../../../../mobxStores/user/user";
import { UserData } from "../../../../mobxStores/userData/userData";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";
import { flowResult } from "mobx";
import { LogOut, PlusCircle } from "react-feather";
import { UserAvatar } from "./UserAvatar";
import { Theme } from "../../../../mobxStores/uiData/theme";
import { FirebaseUserModal } from "../../../../pages/firebaseUser/firebaseUser";
import { NGButton } from "../../../../newcomponents/button/Button";
import { Divider } from "../../../../newcomponents/divider";
import { NGSwitch } from "../../../../newcomponents/switch";
import { PromptContext } from "../../../../mobxStores/prompt/prompt";
import { DOCS_URL, OPENAPI_URL } from "../../../../envVariables";
import { NGLabelText } from "../../../../newcomponents/text/labelText";

const ProfileMenuRaw: React.FC = () => {
  const navigate = useNavigate();
  const { AccountUUID } = BillingContext;
  const { pathname } = useLocation();

  const [isLogoutOpen, setIsLogoutOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [tryCreateAccount, setTryCreateAccount] = React.useState(false);
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = React.useState(false);

  function onOpenChange(flag: boolean) {
    setOpen(flag);
  }

  function handleMenuClick(e: any) {
    let hide = true;
    switch (true) {
      case e.key === "1":
        setIsUserInfoModalOpen(true);
        break;
      case e.key === "3":
      case e.key === "4":
        e.preventDefault();
        e.stopPropagation();
        hide = false;
        break;
      case e.key === "tasks":
        if (!UserData.hasOrgInvite) {
          return;
        }
        navigate("/task");
        break;
      case e.key === "openapi":
        window.open(OPENAPI_URL, "_blank");
        break;
      case e.key === "useostheme":
        Theme.respectOS();
        break;
      case e.key === "billing":
        navigate("/billing");
        break;
      case e.key === "createAccount":
        if (UserData.hasAccount) {
          setTryCreateAccount(true);
        } else {
          navigate(`/billing`);
        }
        break;
      case e.key === "documentation":
        window.open(DOCS_URL, "_blank");
        break;
      case e.key === "logout":
        setIsLogoutOpen(!isLogoutOpen);
        break;
      default:
        break;
    }
    setOpen(!hide);
  }

  let showCreateAccount = true;
  if (pathname.includes("/account/-create")) {
    showCreateAccount = false;
  }
  if (!UserData.hasAccount && pathname === "/billing") {
    showCreateAccount = false;
  }

  const menu = () => (
    <Menu selectable={false} onClick={handleMenuClick} className="user-menu">
      <Menu.Item key={"1"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <UserAvatar size={24} />
          <div className="flex flex-col" style={{ marginLeft: 8 }}>
            <span>{User.name}</span>
            <span>{User.email}</span>
          </div>
        </div>
      </Menu.Item>
      {showCreateAccount ? (
        <>
          <Divider />
          <Menu.Item key={"createAccount"}>
            <span className="user-menu-create-account">
              Create Account <PlusCircle size={18} />
            </span>
          </Menu.Item>
        </>
      ) : null}
      <Divider />
      {ParsedLocation.dashboard !== "task" ? (
        <Menu.Item key={"tasks"}>
          {UserData.hasOrgInvite
            ? `${UserData.orgInvites.length} Task${UserData.orgInvites.length > 1 ? "s" : ""}`
            : "No Tasks"}
        </Menu.Item>
      ) : null}
      <Menu.Item key={"3"}>
        <TimezoneDisplay />
      </Menu.Item>
      {Theme.isRespectingOS ? null : (
        <Menu.Item key={"useostheme"}>
          <span>Use OS Theme Preference</span>
        </Menu.Item>
      )}
      <Menu.Item key={"4"}>
        <Observer>
          {() => (
            <span className="w-full h-full block">
              <NGSwitch value={Theme.theme === "dark"} onChange={(value) => Theme.setTheme(value ? "dark" : "light")}>
                <span>Dark Theme</span>
              </NGSwitch>
            </span>
          )}
        </Observer>
      </Menu.Item>
      {User.isAdmin ? null : ParsedLocation.dashboard !== "billing" ? (
        <Menu.Item key={"billing"}>
          <span>Org Management & Billing</span>
        </Menu.Item>
      ) : null}
      <Menu.Item key={"openapi"}>
        <span>Open API</span>
      </Menu.Item>
      <Menu.Item key={"documentation"}>
        <span>Documentation</span>
      </Menu.Item>
      <Divider />
      {User.isAdmin ? null : (
        <Menu.Item key={"logout"}>
          <span className="user-menu-log-out">
            Log out
            <LogOut size={20} color="var(--color-default)" />
          </span>
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <>
      {tryCreateAccount ? (
        <Modal
          closable={false}
          open={tryCreateAccount}
          title={"Are you sure you want to create a new account?"}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setTryCreateAccount(false)}>
                Cancel
              </NGButton>
              <div style={{ flexGrow: 1 }} />
              <NGButton
                variant="primary"
                onClick={() => {
                  setTryCreateAccount(false);
                  navigate(`/billing/account/${AccountUUID}/-tryorgcreate`);
                }}
              >
                Create Org
              </NGButton>
              <NGButton
                variant="primary"
                onClick={() => {
                  setTryCreateAccount(false);
                  navigate(`/billing/account/-create`);
                }}
              >
                Create Account
              </NGButton>
            </div>
          }
        >
          <span style={{ color: "var(--color-label-secondary)" }}>
            You can create a new org under the same account. A new account requires a new payment method.
          </span>
        </Modal>
      ) : null}
      {isUserInfoModalOpen ? <FirebaseUserModal onClose={() => setIsUserInfoModalOpen(false)} /> : null}
      <div className="flex items-center h-full">
        <Dropdown
          dropdownRender={menu}
          className="flex items-center"
          trigger={["click"]}
          open={open}
          onOpenChange={onOpenChange}
        >
          <div data-testid="user-menu">
            <UserAvatar showAdditions size={32} />
          </div>
        </Dropdown>
      </div>
      <Modal
        title="Confirm Logout"
        closable={false}
        open={isLogoutOpen}
        onCancel={() => setIsLogoutOpen(!isLogoutOpen)}
        footer={
          <div className="modal-actions">
            <NGButton variant="secondary" onClick={() => setIsLogoutOpen(!isLogoutOpen)}>
              Cancel
            </NGButton>
            <NGButton
              variant="primary"
              onClick={async () => {
                PromptContext.setIsDisabled(true);
                await flowResult(User.signOut(false));
              }}
            >
              Log Out
            </NGButton>
          </div>
        }
      >
        <span style={{ color: "var(--color-label-secondary)" }}>Are you sure you want to log out of your account?</span>
      </Modal>
    </>
  );
};

export default observer(ProfileMenuRaw);
