import { makeObservable, observable } from "mobx";

class ApplyMobx {
  prevPath: string | undefined = "";
  initialContent: string = "";
  itemLink: string = "";

  constructor() {
    makeObservable(this, {
      initialContent: observable,
    });
  }

  setInitialContent(initialContent: string) {
    this.initialContent = initialContent;
  }

  setPrevPath(prevPath: string | undefined) {
    this.prevPath = prevPath;
  }

  setItemLink(value: string) {
    this.itemLink = value;
  }

  clearItemLink() {
    this.itemLink = "";
  }
}

export const Apply = new ApplyMobx();
