import * as React from "react";
import { observer } from "mobx-react-lite";
import { DomainDraftMobx } from "../../../mst/stores/domain.draft";
import { NGButton } from "../../../newcomponents/button/Button";

interface Props {
  draft: DomainDraftMobx;
  onClick?: any;
}
const NSCardRaw: React.FC<Props> = ({ draft, onClick }) => {
  return (
    <div className={`${onClick ? "border px-6 py-4" : ""} rounded flex-1`}>
      <div>NS & Subdomain Routing</div>
      <ul className="mb-2">
        <li className="list-disc text-sm ml-6">NS Records are used to delegate DNS to Control Plane</li>
        <li className="list-disc text-sm ml-6">
          Best choice when a unique DNS subdomain is required for each workload
        </li>
        <li className="list-disc text-sm ml-6">One-time Configuration</li>
        <li className="list-disc text-sm ml-6">Works for all current and future workloads in a GVC</li>
      </ul>
      {onClick ? (
        <>
          <div className="text-sm">Example Endpoints</div>
          <ul className="mb-2">
            <li className="text-sm ml-2">https://workload_one.{draft.name.value}</li>
            <li className="text-sm ml-2">https://workload_two.{draft.name.value}</li>
          </ul>
          <NGButton variant="primary" onClick={onClick}>
            Use NS Records & Subdomain Routing
          </NGButton>
        </>
      ) : null}
    </div>
  );
};

export const NSCard = observer(NSCardRaw);
