import * as React from "react";
import { Tooltip } from "../../components/Tooltip";

export const AwsSsmIcon: React.FC = () => {
  return (
    <Tooltip title={"This field supports AWS SSM"}>
      <img
        style={{ width: 20, height: 20 }} // up to 24
        className="object-contain"
        src={`/resources/logos/ssmLogo.png`}
        alt={"AWS SSM Logo"}
      />
    </Tooltip>
  );
};
