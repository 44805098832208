import * as React from "react";
import { Modal } from "antd";
import { useBlocker } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { NGButton } from "../../newcomponents/button/Button";
import { DebugContext } from "../../mobxStores/debug";

const RouteLeavingGuard: React.FC = () => {
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (DebugContext.debug) {
      console.log(`useBlocker START ----- key: ${PromptContext.key}`);
      console.log(`From: ${currentLocation.pathname} To: ${nextLocation.pathname}`);
    }

    if (!PromptContext.when) {
      if (DebugContext.debug) {
        console.log(`'when' is false, NOT blocked`);
      }
      return false;
    }

    if (currentLocation.pathname === nextLocation.pathname) {
      if (DebugContext.debug) {
        console.log(`Same path, NOT blocked`);
      }
      return false;
    }

    if (PromptContext.isDisabled) {
      if (DebugContext.debug) {
        console.log(`'isDisabled === true', NOT blocked`);
        console.log(`Will set 'isDisabled' to 'false', 'shouldBlock' to '() => true'`);
      }
      PromptContext.setIsDisabled(false);
      PromptContext.setShouldBlock(() => true);
      return false;
    }

    let shouldBlock = false;
    shouldBlock = PromptContext.shouldBlock(nextLocation, currentLocation);
    if (DebugContext.debug) {
      console.log(`shouldBlock decided to '${shouldBlock ? "BLOCK" : "NOT BLOCK"}'`);
      console.log(`useBlocker END ----- ${PromptContext.key}`);
    }
    return shouldBlock;
  });

  if (blocker.state !== "blocked") {
    return null;
  }

  return (
    <Modal
      open={true}
      title={"Warning!"}
      onCancel={() => {
        // @ts-ignore
        blocker.reset();
      }}
      width={600}
      footer={
        <div className="modal-actions flex items-center">
          <NGButton
            size={"normal"}
            style={{ paddingInline: 10 }}
            className={`basis-0 flex-grow`}
            variant={"secondary"}
            outlined
            onClick={() => {
              // @ts-ignore
              blocker.reset();
              window.open(blocker.location?.pathname, "_blank");
            }}
          >
            Open in a New Tab
          </NGButton>
          <NGButton
            size={"normal"}
            style={{ paddingInline: 10 }}
            className={`basis-0 flex-grow`}
            variant="primary"
            onClick={() => {
              // @ts-ignore
              blocker.reset();
            }}
          >
            Continue Editing
          </NGButton>
          <NGButton
            size={"normal"}
            style={{ paddingInline: 10 }}
            className={`basis-0 flex-grow`}
            variant="danger"
            onClick={() => {
              if (PromptContext.onDiscard) {
                PromptContext.onDiscard();
              }
              // @ts-ignore
              blocker.proceed();
            }}
          >
            Discard Changes
          </NGButton>
        </div>
      }
    >
      <div>There are unsaved changes.</div>
    </Modal>
  );
};

export default observer(RouteLeavingGuard);
