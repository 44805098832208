import { observer } from "mobx-react-lite";
import * as React from "react";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { getLogsSyslogData } from "./data/syslog";

interface Props {
  formPrefix: string;
  syslog: ReturnType<typeof getLogsSyslogData>;
  onRemove?: any;
}
const SyslogRaw: React.FC<Props> = ({ formPrefix, syslog, onRemove }) => {
  formPrefix += "-syslog-";

  return (
    <>
      <div style={{ width: 450 }}>
        <NGFormElement
          name={`${formPrefix}host`}
          label={syslog.host.label}
          value={syslog.host.value}
          onChange={syslog.host.setValue}
          required={syslog.host.isRequired}
          error={syslog.host.error}
        />
        <NGFormElement
          name={`${formPrefix}port`}
          label={syslog.port.label}
          value={syslog.port.value}
          onChange={syslog.port.setValue}
          required={syslog.port.isRequired}
          error={syslog.port.error}
        />
        <NGFormElement
          name={`${formPrefix}mode`}
          label={syslog.mode.label}
          value={syslog.mode.value}
          onChange={syslog.mode.setValue}
          as={"select"}
          options={syslog.mode.options}
        />
        <NGFormElement
          name={`${formPrefix}format`}
          label={syslog.format.label}
          value={syslog.format.value}
          onChange={syslog.format.setValue}
          as={"select"}
          options={syslog.format.options}
        />
        <NGFormElement
          name={`${formPrefix}severity`}
          label={syslog.severity.label}
          value={syslog.severity.value}
          onChange={syslog.severity.setValue}
          as={"select"}
          options={syslog.severity.options}
          info={[
            "Emergency (EMERG) (severity level 0)",
            `System is unusable.`,

            `Alert (ALERT) (severity level 1)`,
            `Action must be taken immediately.`,

            `Critical (CRIT) (severity level 2)`,
            `Critical conditions.`,

            `Error (ERR) (severity level 3)`,
            `Error conditions.`,

            `Warning (WARNING) (severity level 4)`,
            `Warning conditions.`,

            `Notice (NOTICE) (severity level 5)`,
            `Normal but significant conditions.`,

            `Informational (INFO) (severity level 6)`,
            `Informational messages.`,

            `Debug (DEBUG) (severity level 7)`,
            `Debug-level messages.`,
          ]}
        />
        {onRemove ? (
          <div className="flex justify-end">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const Syslog = observer(SyslogRaw);
