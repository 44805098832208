import { Instance, types } from "mobx-state-tree";

export const _KindProperty = types.enumeration("Kind", [
  "accessreport",
  "agent",
  "auditctx",
  "cloudaccount",
  "command",
  "deployment",
  "domain",
  "event",
  "group",
  "gvc",
  "identity",
  "image",
  "ipset",
  "location",
  "memcachecluster",
  "mk8s",
  "org",
  "permissions",
  "policy",
  "quota",
  "secret",
  "serviceaccount",
  "spicedbcluster",
  "task",
  "user",
  "volumeset",
  "workload",
]);
export type _Kind =
  | "accessreport"
  | "agent"
  | "auditctx"
  | "cloudaccount"
  | "command"
  | "deployment"
  | "domain"
  | "event"
  | "group"
  | "gvc"
  | "identity"
  | "image"
  | "ipset"
  | "location"
  | "memcachecluster"
  | "mk8s"
  | "org"
  | "permissions"
  | "policy"
  | "quota"
  | "secret"
  | "serviceaccount"
  | "spicedbcluster"
  | "task"
  | "user"
  | "volumeset"
  | "workload";

export const _KindWithPolicyProperty = types.enumeration("Kind", [
  "agent",
  "auditctx",
  "cloudaccount",
  "domain",
  "group",
  "gvc",
  "identity",
  "image",
  "ipset",
  "location",
  "memcachecluster",
  "mk8s",
  "org",
  "policy",
  "quota",
  "secret",
  "serviceaccount",
  "spicedbcluster",
  "task",
  "user",
  "volumeset",
  "workload",
]);

export const _WithKindModel = types.model({
  kind: _KindProperty,
});
export interface _WithKindMobx extends Instance<typeof _WithKindModel> {}
export interface _WithKind {
  kind: _Kind;
}
