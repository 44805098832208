import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { PolicyMobx } from "../../mst/kinds/policy";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { PolicyBindingsOverview } from "./bindings/bindingsOverview";
import { PolicyAddBinding } from "./bindings/bindingAdd";
import { AccessReport } from "../../components/detail/accessReport";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { PolicyTargets } from "./targets";
import { BindingEdit } from "./bindings/bindingDetail/bindingEdit";
import { Tags } from "../../components/detail/tags";

interface Props {
  policy: PolicyMobx;
}
const PolicyDetailRaw: React.FC<Props> = ({ policy }) => {
  const basePath = useBasePath("/policy/:policy/*");

  const eventlogHook = useEventlogs(policy.selfLink);

  function getLinks() {
    return [
      { label: "Info", url: "-info" },
      policy.target !== "all" ? { label: "Targets", url: "-targets" } : undefined,
      { label: "Bindings", url: "-bindings" },
      { label: "Tags", url: "-tags" },
      { label: "Events", url: "-events" },
      { label: "Audit Trail", url: `-audittrail` },
      { label: "Access Report", url: "-accessreport" },
    ].filter(Boolean) as any;
  }
  const [links, setLinks] = React.useState(getLinks());

  React.useEffect(() => {
    setLinks(getLinks());
  }, [policy.version]);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info policy={policy} />} />
        {policy.target === "all" ? null : <Route path={`-targets/*`} element={<PolicyTargets policy={policy} />} />}
        <Route path={`-bindings/new/*`} element={<PolicyAddBinding policy={policy} />} />
        <Route path={`-bindings/:indexString/*`} element={<BindingEdit policy={policy} />} />
        <Route path={`-bindings`} element={<PolicyBindingsOverview policy={policy} />} />
        <Route path={`-tags`} element={<Tags link={policy.selfLink} tags={policy.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"policy"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="policy" resourceId={policy.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={policy.selfLink} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const PolicyDetail = observer(PolicyDetailRaw);
