import { defaultValues } from "../mst/base";
import { StringModel, StringMobx } from "./stringModel";

interface Props {
  label?: string;
  isRequired?: boolean;
  value?: number | string;
}
export const PortModel = {
  create: (props?: Props): StringMobx => {
    props = props || {};
    return StringModel.create({
      label: props.label || "Port",
      isRequired: props.isRequired,
      initialValue: props.value ? String(props.value) : undefined,
      transformKey: "number",
      validationKey: "port",
      min: defaultValues.port.min,
      max: defaultValues.port.max,
    });
  },
};
