import { observer } from "mobx-react-lite";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderGeneric } from "./generic";
import { ProviderGenericNodePool } from "./nodePool";
import { ProviderGenericNodePoolAdd } from "./nodePoolAdd";
import { ProviderGenericNodePools } from "./nodePools";
import { ProviderGenericAdvanced } from "./advanced";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderGenericRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderGeneric mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderGenericNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<ProviderGenericNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<ProviderGenericNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<ProviderGenericAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderGenericRoute = observer(ProviderGenericRouteRaw);
