import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { NGButton } from "../../newcomponents/button/Button";

type RouteParams = "org";
export const OrgNotFound: React.FC = () => {
  const { org } = useParams<RouteParams>();
  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex flex-col items-center gap-4 pt-16">
      <div>You don't have access to an org named "{org}"</div>
      <NGButton
        variant="primary"
        onClick={() => {
          ConsoleContext.clearGVC();
          ConsoleContext.clearOrg();
          navigate("/");
        }}
      >
        Continue
      </NGButton>
    </div>
  );
};
