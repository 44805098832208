import * as React from "react";
import { Table } from "../../newcomponents/table/table";
import { NGLabel } from "../../newcomponents/text/label";
import { ExternalLinkColumn } from "../../newcomponents/table/columns/generic/externalLink";
import { TableProps } from "../../newcomponents/table/types";

interface TagLinksTableItem {
  key: string;
  value: string;
}
interface Props extends Omit<TableProps<TagLinksTableItem>, "title" | "data" | "columns"> {
  tags: TagLinksTableItem[];
}

export const TagLinksTable: React.FC<Props> = ({ tags, ...tableProps }) => {
  return (
    <Table<any>
      title={() => <NGLabel>Tag Links</NGLabel>}
      data={tags}
      hideSettings
      columns={[
        { id: "key", label: "Key", enableResize: true, size: 200 },
        ExternalLinkColumn({ id: "value", label: "Value", enableResize: true, size: 200 }),
      ]}
      {...tableProps}
    />
  );
};
