import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";

export const Mk8sDraftAddonAzureWorkloadIdentityModel = types
  .model({
    _isEnabled: types.optional(types.boolean, false),
    isEnabled: types.optional(types.boolean, false),
    _tenantId: types.optional(types.string, ""),
    tenantId: types.optional(StringModel, () => StringModel.create({ label: "Tenant Id", isRequired: true })),
  })
  .actions((self) => ({
    setIsEnabled(value: boolean) {
      self.isEnabled = value;
    },
  }))
  .actions((self) => ({
    reset() {
      self.isEnabled = self._isEnabled;
      self.tenantId.setInitialValue(self._tenantId);
    },
    confirm() {
      self._isEnabled = self.isEnabled;
      self.tenantId.confirm();
      self._tenantId = self.tenantId.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (self.isEnabled) {
        if (!self.tenantId.isValid) res = false;
      }
      return res;
    },
    get invalidReason() {
      let reason = "";
      // TODO
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.isEnabled !== self._isEnabled) res = true;
      if (self.isEnabled) {
        if (self.tenantId.isDirty) res = true;
      }
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.isEnabled !== self._isEnabled) reason = "enabled changed";
      if (self.isEnabled) {
        if (self.tenantId.isDirty) reason = self.tenantId.isDirtyReason;
      }
      return reason;
    },
    get asObject() {
      return {
        tenantId: self.tenantId.value,
      };
    },
  }));
