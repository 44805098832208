import * as React from "react";
import { NGLabel } from "./label";
import { observer } from "mobx-react-lite";

const CheckMark = () => (
  <span className="formmark ml-1 text-xs" style={{ color: "transparent", textShadow: "0 0 0 var(--color-action)" }}>
    &#10004;
  </span>
);
const RequiredMark = () => <span className="formmark ml-1 color-danger">*</span>;
const InvalidMark = () => <span className="formmark ml-1 color-danger transform">x</span>;

interface Props extends React.PropsWithChildren {
  name?: string;
  required?: boolean;
  invalid?: boolean;
}
const NGFormLabelRaw: React.FC<Props> = ({ children, name, required, invalid }) => {
  return (
    <>
      <div className="flex items-center">
        <NGLabel name={name}>{children}</NGLabel>
        {required ? invalid ? <RequiredMark /> : <CheckMark /> : invalid ? <InvalidMark /> : null}
      </div>
      {/* Req {String(required)} Inv {String(invalid)} */}
    </>
  );
};

export const NGFormLabel = observer(NGFormLabelRaw);
