import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { ExternalLink } from "react-feather";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { DOCS_URL } from "../../../envVariables";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  containerDraft: WorkloadDraftContainerMobx;
}
const MetricsRaw: React.FC<Props> = ({ workloadDraft, containerDraft }) => {
  return (
    <>
      <div className="mb-4">
        See documentation to{" "}
        <a className="ngfocus color-link" target={"_blank"} href={`${DOCS_URL}/reference/workload/custom-metrics`}>
          learn more <ExternalLink className="feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
        </a>
        .
      </div>
      <div style={{ width: 450 }}>
        <div className="mb-4">
          <NGSwitch
            data-testid={`switch-use-metrics`}
            value={containerDraft.metrics.useMetrics}
            onChange={containerDraft.metrics.setUseMetrics}
          >
            <NGLabelText>Enable Custom Metrics</NGLabelText>
          </NGSwitch>
        </div>
        {containerDraft.metrics.useMetrics ? (
          <>
            <NGFormElement
              name={`metricsPath`}
              label={containerDraft.metrics.path.label}
              value={containerDraft.metrics.path.value}
              onChange={containerDraft.metrics.path.setValue}
            />
            <NGFormElement
              name={`metricsPort`}
              label={containerDraft.metrics.port.label}
              value={containerDraft.metrics.port.value}
              onChange={containerDraft.metrics.port.setValue}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export const Metrics = observer(MetricsRaw);
