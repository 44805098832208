import { Row, SortingFn } from "@tanstack/react-table";
import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";
import { TableColumn } from "../../types";

export function cplnSortingFnCapacityAi(
  rowA: Row<WorkloadTableItem>,
  rowB: Row<WorkloadTableItem>,
  columnId: string,
): number {
  let a: boolean | undefined = rowA.original.capacityAi;
  let b: boolean | undefined = rowB.original.capacityAi;

  if (a === true) {
    return 1;
  }
  if (a === false && b === true) {
    return -1;
  }

  return 0;
}

const capacityAiDefault = { id: "capacityAi", label: "Capacity AI" };
export function WorkloadCapacityAiColumn<TData extends WorkloadTableItem>(
  column: TableColumn<TData> = capacityAiDefault,
) {
  const preset: TableColumn<TData> = {
    ...capacityAiDefault,
    enableHide: true,
    hiddenByDefault: true,
    enableSort: true,
    sortingFn: (cplnSortingFnCapacityAi as unknown) as SortingFn<TData>,
    size: 140,
    cell: (p) => {
      const workload = p.row.original;

      return <span>{workload.capacityAi === true ? "Enabled" : workload.capacityAi === false ? "Disabled" : ""}</span>;
    },
  };
  return Object.assign(preset, column);
}
