import * as React from "react";
import { notification } from "antd";
import { CreateLayout } from "../../components/create/layout";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { CreateFormLink } from "../org/create/create";
import { StringModel } from "../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { NameModel } from "../../mobxDataModels/nameModel";
import { homeLink, request, resourceLink } from "../../services/cpln";
import { TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../components/create/tags";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { PromptContext } from "../../mobxStores/prompt/prompt";

const AuditContextCreateRaw: React.FC = () => {
  const basePath = useBasePath("/auditctx/-create/*");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));
  const tagsRef = React.useRef(TagsNewModel.create());

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  function getAuditContextObject() {
    const body: any = { name: nameRef.current.value, tags: tagsRef.current.asObject };
    if (descriptionRef.current.value) {
      body.description = descriptionRef.current.value;
    } else {
      body.description = nameRef.current.value;
    }
    return { kind: "auditctx", ...body };
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({ method: "post", url: homeLink("auditctx"), body: getAuditContextObject() });
      notification.success({
        message: "Success",
        description: "Audit Context is created",
      });
      const link = resourceLink("auditctx", getAuditContextObject().name);
      PromptContext.setIsDisabled(true);
      navigate(`/console${link}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/auditctx/-create");
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    {
      name: "Tags",
      value: `-tags`,
      isActive: nameRef.current.isValid,
    },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("-general")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  return (
    <>
      <BasePathContext.Provider value={basePath}>
        <CreateLayout
          getPreview={getAuditContextObject}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Audit Context"}
          name={nameRef.current.value}
          canCreate={nameRef.current.isValid && tagsRef.current.isValid}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route
              path={`-general`}
              element={
                <>
                  <NGInputAdapter style={{ width: 450 }} className="mb-4" data={nameRef.current} />
                  <NGInputAdapter style={{ width: 450 }} data={descriptionRef.current} />
                </>
              }
            />
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </BasePathContext.Provider>
    </>
  );
};

export const AuditContextCreate = observer(AuditContextCreateRaw);
