import * as React from "react";
import { observer } from "mobx-react-lite";
import { getLogsS3Data } from "./data/s3";
import { OrgLogsCloudaccountModal } from "./logsCloudaccountModal";
import { BrowserServiceModal } from "../../identity/create/browserServiceModal";
import { request } from "../../../services/cpln";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import { NGInput } from "../../../newcomponents/input/input";
import { MoreHorizontal } from "react-feather";

interface Props {
  s3: ReturnType<typeof getLogsS3Data>;
  onRemove?: any;
}
const S3Raw: React.FC<Props> = ({ s3, onRemove }) => {
  return (
    <>
      {s3.browser.page === "none" ? null : s3.browser.page === "cloudaccount" ? (
        <OrgLogsCloudaccountModal browse={s3.browser} />
      ) : (
        <BrowserServiceModal
          title={`Select Bucket`}
          restrictToBucket={true}
          onClose={async (uri: any) => {
            if (uri.length < 1) {
              s3.browser.done();
              return;
            }
            const bucket = uri[0];
            const bucketName = bucket._cpln.label;
            s3.bucket.setValue(bucketName);
            const locationUrl = `${bucket._cpln.href}/-aspect/location`;
            const { data } = await request({ service: "browser", url: locationUrl });
            if (data?.data) {
              if (data.data.length > 0) {
                const _region = data.data[0];
                s3.region.setValue(_region);
              }
            }
            s3.browser.done();
          }}
          selectionType={"single"}
          browserService={s3.browser.browserService}
        />
      )}
      <div>
        <NGLabel>{s3.bucket.label}</NGLabel>
        <NGInput
          style={{ width: 450 }}
          className="mb-4"
          value={s3.bucket.value}
          onChange={(e) => s3.bucket.setValue(e.target.value)}
          buttons={[
            {
              title: "",
              onClick: () => s3.browser.setPage("cloudaccount"),
              render: () => <MoreHorizontal className="h-4" />,
            },
          ]}
        />
        <NGLabel>{s3.region.label}</NGLabel>
        <NGInput
          style={{ width: 450 }}
          className="mb-4"
          value={s3.region.value}
          onChange={(e) => s3.region.setValue(e.target.value)}
        />
        <NGLabel>{s3.prefix.label}</NGLabel>
        <NGInput
          style={{ width: 450 }}
          className="mb-4"
          value={s3.prefix.value}
          onChange={(e) => s3.prefix.setValue(e.target.value)}
        />
        <NGLabel>Credentials (AWS Secret)</NGLabel>
        <NGKindSelect
          style={{ width: 450 }}
          className="mb-4"
          kind={"secret"}
          queries={[{ property: "type", value: "aws" }]}
          value={s3.credentials.value}
          onChange={(value) => s3.credentials.setValue(value)}
        />
        {onRemove ? (
          <div className="flex justify-end">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const S3 = observer(S3Raw);
