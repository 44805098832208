import React from "react";
import { User } from "../../../../schema/types/user";
import { ServiceAccount } from "../../../../schema/types/serviceaccount";
import { Group } from "../../../../schema/types/group";
import { Identity } from "../../../../schema/types/identity";
import { Permission } from "../../../../schema/types/permissions";
import { Table } from "../../../../newcomponents/table/table";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { UserNameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/userNameDescriptionNoLinkColumn";
import { WorkloadGvcColumn } from "../../../../newcomponents/table/columns/workload/gvcColumn";

interface PermissionTableItem extends Permission {
  kind: "permission";
  description: string;
  implied: string[];
  impliedText: string;
}
interface UserTableItem extends User {
  selfLink: string;
}

interface ServiceaccountTableItem extends ServiceAccount {
  selfLink: string;
}

interface GroupTableItem extends Group {
  selfLink: string;
}

interface IdentityTableItem extends Identity {
  selfLink: string;
}

interface TableProps {
  onAddPermission: () => void;
  onRemovePermission: () => void;
  permissionItems: PermissionTableItem[];
  permissionSelections: string[];
  setPermissionSelections: (value: string[]) => void;
  onAddUser: () => void;
  onRemoveUser: () => void;
  userItems: UserTableItem[];
  userSelections: string[];
  setUserSelections: (value: string[]) => void;
  onAddServiceaccount: () => void;
  onRemoveServiceaccount: () => void;
  serviceaccountItems: ServiceaccountTableItem[];
  serviceaccountSelections: string[];
  setServiceaccountSelections: (value: string[]) => void;
  onAddGroup: () => void;
  onRemoveGroup: () => void;
  groupItems: GroupTableItem[];
  groupSelections: string[];
  setGroupSelections: (value: string[]) => void;
  onAddIdentity: () => void;
  onRemoveIdentity: () => void;
  identityItems: IdentityTableItem[];
  identitySelections: string[];
  setIdentitySelections: (value: string[]) => void;

  className?: string;
  style?: any;
}

export const BindingDetailTable: React.FC<TableProps> = ({
  onAddPermission,
  onRemovePermission,
  permissionItems,
  permissionSelections,
  setPermissionSelections,
  onAddUser,
  onRemoveUser,
  userItems,
  userSelections,
  setUserSelections,
  onAddServiceaccount,
  onRemoveServiceaccount,
  serviceaccountItems,
  serviceaccountSelections,
  setServiceaccountSelections,
  onAddGroup,
  onRemoveGroup,
  groupItems,
  groupSelections,
  setGroupSelections,
  onAddIdentity,
  onRemoveIdentity,
  identityItems,
  identitySelections,
  setIdentitySelections,
  // ---
  className = "",
  style = {},
}) => {
  return (
    <div className={`relative mb-8 ${className}`} style={{ zIndex: 2, ...style }}>
      <Table<PermissionTableItem>
        title={"Permissions"}
        classNames={{ container: "mb-8" }}
        data={permissionItems}
        tableId={`policy-detail-binding-detail-permission-items`}
        selectMode="multi"
        selectKey={"name"}
        selections={permissionSelections}
        onSelectionsChange={setPermissionSelections}
        headerRenderer={() => {
          return (
            <>
              <NGButton
                variant={"danger"}
                size={"small"}
                outlined
                disabled={permissionSelections.length < 1}
                onClick={onRemovePermission}
              >
                Remove
              </NGButton>
              <NGButton variant={"action"} size={"small"} onClick={onAddPermission}>
                Add
              </NGButton>
            </>
          );
        }}
        columns={[
          NameDescriptionNoLinkColumn(), //
          { id: "impliedText", label: "Implied" },
        ]}
      />
      <Table<UserTableItem>
        title={"Users"}
        classNames={{ container: "mb-8" }}
        data={userItems}
        tableId={`policy-detail-binding-detail-user-items`}
        selectMode="multi"
        selectKey={"selfLink"}
        selections={userSelections}
        onSelectionsChange={setUserSelections}
        headerRenderer={() => {
          return (
            <>
              <NGButton
                variant={"danger"}
                size={"small"}
                outlined
                disabled={userSelections.length < 1}
                onClick={onRemoveUser}
              >
                Remove
              </NGButton>
              <NGButton variant={"action"} size={"small"} onClick={onAddUser}>
                Add
              </NGButton>
            </>
          );
        }}
        columns={[
          UserNameDescriptionNoLinkColumn(), //
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
      />
      <Table<ServiceaccountTableItem>
        title={"Service Accounts"}
        classNames={{ container: "mb-8" }}
        data={serviceaccountItems}
        tableId={`policy-detail-binding-detail-serviceaccount-items`}
        selectMode="multi"
        selectKey={"selfLink"}
        selections={serviceaccountSelections}
        onSelectionsChange={setServiceaccountSelections}
        headerRenderer={() => {
          return (
            <>
              <NGButton
                variant={"danger"}
                size={"small"}
                outlined
                disabled={serviceaccountSelections.length < 1}
                onClick={onRemoveServiceaccount}
              >
                Remove
              </NGButton>
              <NGButton variant={"action"} size={"small"} onClick={onAddServiceaccount}>
                Add
              </NGButton>
            </>
          );
        }}
        columns={[
          NameDescriptionNoLinkColumn(), //
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
      />
      <Table<GroupTableItem>
        title={"Groups"}
        classNames={{ container: "mb-8" }}
        data={groupItems}
        tableId={`policy-detail-binding-detail-group-items`}
        selectMode="multi"
        selectKey={"selfLink"}
        selections={groupSelections}
        onSelectionsChange={setGroupSelections}
        headerRenderer={() => {
          return (
            <>
              <NGButton
                variant={"danger"}
                size={"small"}
                outlined
                disabled={groupSelections.length < 1}
                onClick={onRemoveGroup}
              >
                Remove
              </NGButton>
              <NGButton variant={"action"} size={"small"} onClick={onAddGroup}>
                Add
              </NGButton>
            </>
          );
        }}
        columns={[
          NameDescriptionNoLinkColumn(), //
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
      />
      <Table<IdentityTableItem>
        title={"Identities"}
        classNames={{ container: "mb-8" }}
        data={identityItems}
        tableId={`policy-detail-binding-detail-identity-items`}
        selectMode="multi"
        selectKey={"selfLink"}
        selections={identitySelections}
        onSelectionsChange={setIdentitySelections}
        headerRenderer={() => {
          return (
            <>
              <NGButton
                variant={"danger"}
                size={"small"}
                outlined
                disabled={identitySelections.length < 1}
                onClick={onRemoveIdentity}
              >
                Remove
              </NGButton>
              <NGButton variant={"action"} size={"small"} onClick={onAddIdentity}>
                Add
              </NGButton>
            </>
          );
        }}
        columns={[
          NameDescriptionNoLinkColumn(), //
          WorkloadGvcColumn() as any,
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
      />
    </div>
  );
};
