import { observer } from "mobx-react-lite";
import * as React from "react";

interface CommandTagItemProps {
  index: number;
  name: string;
  value: string;
  onRemove: () => void;
  onChange: (index: number, name: string, value: string) => void;
}
const CommandTagItemRaw: React.FC<CommandTagItemProps> = ({ index, name, value, onRemove, onChange }) => {
  const longText = value.length > 30;
  return (
    <>
      <div className={`relative flex items-center gap-2 focus-within:ring-1`}>
        <input
          style={{
            width: "calc((100% - 58px) / 2)",
            color: "var(--color-input)",
            backgroundColor: "var(--color-input-bg)",
            borderColor: "var(--color-input-border)",
          }}
          className="text-normal border px-2 py-1 rounded focus"
          data-testid="tag-key"
          placeholder={"Name"}
          value={name}
          onChange={(e) => onChange(index, e.target.value, value)}
        />
        <input
          style={{
            width: "calc((100% - 58px) / 2)",
            color: "var(--color-input)",
            backgroundColor: "var(--color-input-bg)",
            borderColor: "var(--color-input-border)",
          }}
          data-testid="tag-value"
          className={`text-normal border pl-2 ${longText ? "pr-8" : "pr-2"} py-1 rounded focus relative`}
          placeholder={"Value (Optional)"}
          value={String(value)}
          onChange={(e) => onChange(index, name, e.target.value)}
        />
        <button onClick={onRemove} className={`inline-block h-full ngfocus color-danger`}>
          Remove
        </button>
      </div>
    </>
  );
};

export const CommandTagItem = observer(CommandTagItemRaw);
