import { Activity } from "react-feather";
import { TableColumn } from "../../types";
import { AgentTableItem } from "../../../../mst/kinds/agent.helpers";

const lastActiveTextDefault = { id: "lastActiveText", label: "Last Active" };
export function AgentLastActiveTextColumn<TData extends AgentTableItem>(
  column: TableColumn<TData> = lastActiveTextDefault,
) {
  const preset: TableColumn<TData> = {
    ...lastActiveTextDefault,
    enableHide: true,
    enableResize: true,
    size: 230,
    cell: ({ row, getValue }) => {
      const agent = row.original;
      const value = getValue();
      return (
        <span>
          <Activity
            className={`feather-icon inline-block mr-1 ${
              value === "Checking" ? "text-normal" : agent.isActive === true ? "color-action" : "color-danger"
            }`}
            style={{ transform: "translateY(2px)" }}
          />
          {value}
        </span>
      );
    },
  };
  return Object.assign(preset, column);
}
