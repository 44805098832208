import * as React from "react";

// An enum with all the types of actions to use in our reducer
export enum RunReplicaTerminationReducerActionType {
  LOCATION = "LOCATION",
  REPLICA = "REPLICA",
  REPLICAS = "REPLICAS",
}

// An interface for our actions
interface RunReplicaTerminationReducerAction {
  type: RunReplicaTerminationReducerActionType;
  payload: any;
}

// An interface for our state
export interface ReplicaTerminationReducerState {
  location: string;
  replica: string;
  replicas: Array<{ label: string; value: string; isLabel: boolean }>;
}

// Our reducer function that uses a switch statement to handle our actions
function runReplicaTermination(state: ReplicaTerminationReducerState, action: RunReplicaTerminationReducerAction) {
  const { type, payload } = action;
  switch (type) {
    case RunReplicaTerminationReducerActionType.LOCATION:
      return {
        ...state,
        location: payload,
      };
    case RunReplicaTerminationReducerActionType.REPLICA:
      return {
        ...state,
        replica: payload,
      };
    case RunReplicaTerminationReducerActionType.REPLICAS:
      return {
        ...state,
        replicas: payload,
      };
    default:
      return state;
  }
}

export function useRunReplicaTerminationReducer() {
  const initialState: ReplicaTerminationReducerState = {
    location: "",
    replica: "",
    replicas: [],
  };
  return React.useReducer(runReplicaTermination, initialState);
}
