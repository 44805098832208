import * as React from "react";
import { request, resourceLink } from "../../services/cpln";

export interface KubernetesReadyData {
  message?: string;
  ready: boolean;
}

interface KubernetesReadinessMap {
  [_: string]: KubernetesReadyData;
}

interface ReadinessReducerType {
  state: ReadinessReducerState;
  dispatch: React.Dispatch<ReadinessReducerAction>;
  fetchReadiness: (clusterNames: string[], intervalMs?: number) => () => void;
}

export function useKubernetesReadinessReducer(): ReadinessReducerType {
  const initialState: ReadinessReducerState = {
    clusterName: "",
    requestedClusterNames: [],
    readinessMap: {},
  };
  const [state, dispatch] = React.useReducer(readinessReducer, initialState);

  async function _fetchReadiness(clusterNames: string[]) {
    const promises: Promise<any>[] = [];

    for (const clusterName of clusterNames) {
      const clusterLink = resourceLink("mk8s", clusterName);
      const readinessLink = clusterLink + "/-ready";
      promises.push(request({ url: readinessLink }));
    }

    const responses = await Promise.allSettled(promises);
    for (const indexStr in clusterNames) {
      const index = Number(indexStr);
      const clusterName = clusterNames[index];
      const response = responses[index];
      if (response.status === "rejected") {
        continue;
      }

      dispatch({
        type: ReadinessReducerActionType.SET_READINESS,
        payload: { clusterName: clusterName, readiness: response.value.data },
      });
    }
  }

  function fetchReadiness(clusterNames: string[], intervalMs = 0) {
    _fetchReadiness(clusterNames);
    if (intervalMs < 1) {
      return () => {};
    }
    const id = setInterval(() => {
      _fetchReadiness(clusterNames);
    }, intervalMs);
    return () => {
      clearInterval(id);
    };
  }

  return { state, dispatch, fetchReadiness };
}

export enum ReadinessReducerActionType {
  SET_READINESS = "SET_READINESS",
  CLEAR_READINESS = "CLEAR_READINESS",
}

interface ReadinessReducerAction {
  type: ReadinessReducerActionType;
  payload: { clusterName: string; readiness: KubernetesReadyData };
}

interface ReadinessReducerState {
  clusterName: string;
  requestedClusterNames: string[];
  readinessMap: KubernetesReadinessMap;
}

function readinessReducer(state: ReadinessReducerState, action: ReadinessReducerAction): ReadinessReducerState {
  const { type, payload } = action;

  const requestedClusterNames = [...state.requestedClusterNames];
  if (!requestedClusterNames.includes(payload.clusterName)) {
    requestedClusterNames.push(payload.clusterName);
  }

  switch (type) {
    case ReadinessReducerActionType.CLEAR_READINESS:
      return {
        ...state,
        requestedClusterNames: [],
        readinessMap: {},
      };
    case ReadinessReducerActionType.SET_READINESS:
      return {
        ...state,
        requestedClusterNames: requestedClusterNames,
        readinessMap: {
          ...state.readinessMap,
          [payload.clusterName]: payload.readiness,
        },
      };
    default:
      return state;
  }
}
