import * as React from "react";

// An enum with all the types of actions to use in our reducer
export enum RunExpandVolumeReducerActionType {
  LOCATION = "LOCATION",
  INDEX = "INDEX",
  NEW_STORAGE_CAPACITY = "NEW_STORAGE_CAPACITY",
}

// An interface for our actions
interface RunExpandVolumeReducerAction {
  type: RunExpandVolumeReducerActionType;
  payload: any;
}

// An interface for our state
export interface ExpandVolumeReducerState {
  location: string;
  volumeIndex: string;
  newStorageCapacity: string; // TODO make number
}

// Our reducer function that uses a switch statement to handle our actions
function runExpandVolume(state: ExpandVolumeReducerState, action: RunExpandVolumeReducerAction) {
  const { type, payload } = action;
  switch (type) {
    case RunExpandVolumeReducerActionType.LOCATION:
      return {
        ...state,
        location: payload,
      };
    case RunExpandVolumeReducerActionType.INDEX:
      return {
        ...state,
        volumeIndex: payload,
      };
    case RunExpandVolumeReducerActionType.NEW_STORAGE_CAPACITY:
      return {
        ...state,
        newStorageCapacity: payload,
      };
    default:
      return state;
  }
}

export function useRunExpandVolumeReducer(
  initialLocation: string | undefined,
  initialVolumeIndex: string | undefined,
  initialStorageCapacity: string | undefined // TODO make a number after a number input component
) {
  const initialState: ExpandVolumeReducerState = {
    location: initialLocation || "",
    volumeIndex: initialVolumeIndex || "",
    newStorageCapacity: initialStorageCapacity || "0",
  };
  return React.useReducer(runExpandVolume, initialState);
}
