import clsx from "clsx";
import { useToggleState } from "react-stately";
import { ReactNode, useRef } from "react";
import { AriaSwitchProps, useFocusRing, useSwitch, VisuallyHidden } from "react-aria";
import "./index.scss";

export const NGSwitch: React.FC<
  Omit<Omit<AriaSwitchProps, "value">, "isSelected"> & {
    labelPosition?: "left" | "right";
    value: boolean;
    className?: string;
    style?: React.CSSProperties;
    onIcon?: () => ReactNode;
    offIcon?: () => ReactNode;
  }
> = ({ labelPosition = "left", value, className = "", style = {}, ..._props }) => {
  const props = { ..._props, isSelected: value };
  let state = useToggleState(props);
  let ref = useRef(null);
  let { inputProps } = useSwitch(props, state, ref);
  let { isFocusVisible, focusProps } = useFocusRing();

  function handleKeyDown(event: React.KeyboardEvent) {
    if (isFocusVisible && (props.isReadOnly || props.isDisabled)) return;

    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
      props.onChange && props.onChange(!value);
    }
  }

  return (
    <label
      data-testid={props?.["data-testid"]}
      className={clsx(
        "ngswitch ngfocus transition duration-150 gap-1",
        {
          notAllowed: props.isDisabled || props.isReadOnly,
          disabled: props.isDisabled,
        },
        className
      )}
      onKeyDown={handleKeyDown}
    >
      {labelPosition === "left" && props.children}
      <div className="ngswitch-toggle">
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={ref} />
        </VisuallyHidden>
        <svg className="visual" width={32} height={24} aria-hidden="true">
          <rect
            x={0}
            y={4}
            width={32}
            height={16}
            rx={8}
            className={clsx("ngswitch-toggle-rect", { selected: state.isSelected })}
          />
          <g transform={`translate(${state.isSelected ? 24 : 8})`} style={{ transition: "transform 400ms" }}>
            <circle cx={0} cy={12} r={6} fill="white" />
            <g transform={`translate(-4, 8)`}>
              {state.isSelected ? props.onIcon && props.onIcon() : props.offIcon && props.offIcon()}
            </g>
          </g>
        </svg>
      </div>
      {labelPosition === "right" && props.children}
    </label>
  );
};
