import * as React from "react";
import { observer } from "mobx-react-lite";
import { BindingMobx } from "../../../mst/kinds/policy";
import { Tooltip } from "../../../components/Tooltip";

interface Props {
  binding: BindingMobx;
}
const BindingsOverviewTableRaw: React.FC<Props> = ({ binding }) => {
  return (
    <div className="border p-4 rounded">
      <div className="-mt-4 py-3 -mx-4 px-4 -mb-2 flex flex-col bg-table-label-drop text-table-label-text">
        <span className="text-2xl font-extralight">Permissions</span>
        <span className="leading-4 font-semibold">{binding.permissions.join(", ")}</span>
      </div>
      {binding.userCount > 0 ? (
        <div className="border-t -mx-4 px-4 mt-2">
          <div className="mt-2 text-lg">Users</div>
          <div className="flex flex-wrap text-sm font-medium">
            {binding.userLinks.map((userLink) => (
              <div key={userLink} className="w-3/12 truncate pr-2">
                <Tooltip title={userLink.split("/")[4]} open={userLink.split("/")[4].length >= 40}>
                  <span>{userLink.split("/")[4]}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {binding.groupCount > 0 ? (
        <div className="border-t -mx-4 px-4 mt-2">
          <div className="mt-2 text-lg">Groups</div>
          <div className="flex flex-wrap text-sm font-medium">
            {binding.groupLinks.map((groupLink) => (
              <div className="w-3/12 truncate pr-2">
                <Tooltip title={groupLink.split("/")[4]} open={groupLink.split("/")[4].length >= 40}>
                  <span>{groupLink.split("/")[4]}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {binding.serviceaccountCount > 0 ? (
        <div className="border-t -mx-4 px-4 mt-2">
          <div className="mt-2 text-lg">Service Accounts</div>
          <div className="flex flex-wrap text-sm font-medium">
            {binding.serviceaccountLinks.map((serviceaccountLink) => (
              <div key={serviceaccountLink} className="w-3/12 truncate pr-2">
                <Tooltip title={serviceaccountLink.split("/")[4]} open={serviceaccountLink.split("/")[4].length >= 40}>
                  <span>{serviceaccountLink.split("/")[4]}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {binding.identityCount > 0 ? (
        <div className="border-t -mx-4 px-4 mt-2">
          <div className="mt-2 text-lg">Identities</div>
          <div className="flex flex-wrap text-sm font-medium">
            {binding.identityLinks.map((identityLink) => (
              <div key={identityLink} className="w-3/12 truncate pr-2">
                <Tooltip title={identityLink.split("/")[6]} open={identityLink.split("/")[6].length >= 40}>
                  <span>
                    {identityLink.split("/")[6]} (GVC: {identityLink.split("/")[4]})
                  </span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const BindingsOverviewTable = observer(BindingsOverviewTableRaw);
