import React from "react";
import { Layout } from "../../layout";
import { notification } from "antd";
import { forceRefreshToken, request } from "../../services/cpln";
import "@reach/dialog/styles.css";
import "./modal.css";
import { Play } from "react-feather";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { VideoLightbox } from "./videoLightbox";
import { observer } from "mobx-react-lite";
import { Thumbnail, Video } from "./types";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { Loader } from "../../components/layout/loader";
import NGAlert from "../../newcomponents/alert";

const EducationalVideosRoute: React.FC = () => {
  const navigate = useNavigate();
  const { org } = ConsoleContext;
  const [videos, setVideos] = React.useState<Video[]>([]);
  const [thumbnails, setThumbnails] = React.useState<Thumbnail[]>([]);
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchVideos();
    fetchThumbnails();
  }, []);

  async function fetchVideos() {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await request({
        service: "self",
        url: IS_DEPLOYMENT_ENV_TEST
          ? `https://video-service-brhxn99xfb21a.t.cpln.app/video`
          : `https://video-service.beta.cpln.xyz/video`,
      });
      setVideos(
        data?.items.sort((a: any, b: any) => {
          const _a = a.order;
          const _b = b.order;
          if (_a < _b) {
            return -1;
          }
          if (_a > _b) {
            return 1;
          }
          return 0;
        }) || []
      );
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      notification.warning({ message: "Failed to fetch videos", description: errorMessage });
      setIsLoading(false);
      setError("");
    }
  }

  async function fetchThumbnails() {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await request({
        service: "self",
        url: IS_DEPLOYMENT_ENV_TEST
          ? `https://video-service-brhxn99xfb21a.t.cpln.app/thumbnail`
          : `https://video-service.beta.cpln.xyz/thumbnail`,
      });
      setThumbnails(data?.items || []);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      notification.warning({ message: "Failed to fetch thumbnails", description: errorMessage });
      setIsLoading(false);
      setError("");
    }
  }

  return (
    <Layout key={org}>
      {isLoading ? <Loader reason="Fetching videos" /> : null}
      {error ? <NGAlert type="error" message={`Failed to fetch videos: ${error}`} /> : null}
      {!isLoading && videos.length < 1 ? <div>No videos found</div> : null}
      {videos.length > 0 ? (
        <div className="flex flex-wrap py-6 px-4 gap-12 justify-center">
          {videos.map((video: any) => (
            <div key={video.id} className="relative">
              <div
                onClick={() => {
                  forceRefreshToken();
                  navigate(`${video.id}-${encodeURI(video.title)}`);
                }}
                className="absolute flex items-center justify-center cursor-pointer hover:opacity-90 transition-all"
                style={{ top: 0, left: 0, width: 344, height: 193 }}
              >
                <div
                  className="flex items-center justify-center px-4 py-2 rounded"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
                >
                  <Play style={{ color: "rgba(255, 255, 255, 0.8)" }} />
                </div>
              </div>
              <img
                className="cursor-pointer"
                style={{ width: 344, height: 193 }}
                src={thumbnails.find((t) => t.id === video.thumbnailId)?.data}
              />
              <div className="mt-2 font-semibold">{video.title}</div>
              <div>{video.description}</div>
            </div>
          ))}
        </div>
      ) : null}
      <Routes>
        <Route path={`:videoIdName`} element={<VideoLightbox videos={videos} />} />
      </Routes>
    </Layout>
  );
};

export default observer(EducationalVideosRoute);
