import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { UserMobx, UserModel } from "../../mst/kinds/user";
import { UserDetail } from "./detail";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { captureExc } from "../../errorBoundary";

type RouteParams = "idOrEmail";
const UserDetailRouteRaw: React.FC = () => {
  const { idOrEmail } = useParams<RouteParams>();
  const [user, setUser] = React.useState<UserMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [idOrEmail]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("user", idOrEmail) });
      setUser(UserModel.create(data));
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching user to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"user"} canCreate={false} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {user.name} - User - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: user, fetchItem }}>
        <UserDetail user={user} />
      </DetailContext.Provider>
    </>
  );
};

export const UserDetailRoute = observer(UserDetailRouteRaw);
