import React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { acceptTask, fetchMyOrgInvitations } from "../../services/task";
import { Loader } from "../../components/layout/loader";
import { Layout } from "../../layout";
import { request } from "../../services/cpln";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { UserData } from "../../mobxStores/userData/userData";
import { UIData } from "../../mobxStores/uiData/uiData";
import { flowResult } from "mobx";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { User } from "../../mobxStores/user/user";
import { Task } from "../../schema/types/task";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { Table } from "../../newcomponents/table/table";

const TaskRaw: React.FC = () => {
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [processingTasks, setProcessingTasks] = React.useState<string[]>([]);
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const { pathname, search } = useLocation();

  const [selectionsToAccept, setSelectionsToAccept] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (UIData.initialUrl.includes("/task/")) {
      UIData.clearInitialUrl();
    }

    handleNotFoundTask();
  }, []);

  React.useEffect(() => {
    let orgNameToAccept = "";
    try {
      orgNameToAccept = search.split("?complete=")[1];
    } catch (e) {
      orgNameToAccept = "";
    }

    fetchMyOrgInvitations().then((_tasks: Task[]) => {
      setTasks(_tasks);
      setIsPageReady(true);
      if (orgNameToAccept) {
        const taskToAccept = _tasks.find((task) => task.context!.orgLink === `/org/${orgNameToAccept}`);
        if (taskToAccept) {
          setSelectionsToAccept([taskToAccept.id]);
          setTimeout(() => {
            onAcceptTask();
          }, 0);
        }
      }
    });
  }, []);

  async function handleNotFoundTask() {
    const taskIdArr = pathname.split("/task/");
    const hasTaskId = taskIdArr.length > 1 && !!taskIdArr[1];
    if (!hasTaskId) {
      return;
    }
    const taskId = taskIdArr[1];
    try {
      await request({ url: `/task/${taskId}` });
    } catch (e) {
      // Redirect user to a login page if the task is not found
      PromptContext.setIsDisabled(true);
      await flowResult(User.signOut(true));
    }
  }

  async function onAcceptTask() {
    try {
      setProcessingTasks([...selectionsToAccept]);

      let acceptedOrgName = "";
      for (const taskId of selectionsToAccept) {
        const task = tasks.find((task) => task.id === taskId)!;
        const orgLink = task.context!.orgLink;
        const orgName = orgLink.split("/")[2];
        if (selectionsToAccept.length === 1 || !ConsoleContext.org) {
          acceptedOrgName = orgName;
        }
        await acceptTask(task.id);
      }
      const newTasks = await fetchMyOrgInvitations();
      setTasks(newTasks);

      setProcessingTasks([]);
      notification.success({
        message: "Success",
        description: `Accepted task${selectionsToAccept.length > 1 ? "s" : ""}`,
      });

      UIData.clearInitialUrl();
      await Promise.all([UserData.requestOrgInvites(), UserData.requestOrgNames()]);

      setSelectionsToAccept([]);

      if (acceptedOrgName) {
        await flowResult(ConsoleContext.setOrg(acceptedOrgName));
        ConsoleContext.setRedirect(`/org/${acceptedOrgName}`);
      }
    } catch (e) {
      setProcessingTasks([]);
      setSelectionsToAccept([]);

      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  if (!isPageReady) {
    return <Loader reason={"Fetching my invitation tasks"} />;
  }

  return (
    <>
      <Layout showLeftMenu={false}>
        <Table<Task>
          tableId="task-invites-for-me"
          title={"Pending Tasks"}
          selectMode={"multi"}
          selectKey={"id"}
          selections={selectionsToAccept}
          onSelectionsChange={processingTasks.length > 0 ? () => {} : setSelectionsToAccept}
          data={tasks}
          columns={[
            { id: "description", label: "Message" }, //
            CreatedColumn(),
          ]}
          headerRenderer={() => (
            <>
              <NGButton
                variant={"primary"}
                size={"small"}
                disabled={selectionsToAccept.length < 1 || processingTasks.length > 0}
                loading={processingTasks.length > 0}
                onClick={onAcceptTask}
              >
                Accept
              </NGButton>
            </>
          )}
        />
      </Layout>
    </>
  );
};

export default observer(TaskRaw);
