import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Mk8sDraftAutoscalerModel } from "./mk8s.draft.providerAutoscaler";
import { Mk8sDraftNetworkingModel } from "./mk8s.draft.providerNetworking";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import {
  Mk8sDraftProviderDigitalOceanNodePoolModel,
  Mk8sDraftProviderDigitalOceanNodePoolReadonlyModel,
} from "./mk8s.draft.provider.digitalocean.nodePool";

export const Mk8sDraftProviderDigitalOceanModel = types
  .model({
    _region: types.optional(types.string, ""),
    region: types.optional(StringModel, () => StringModel.create({ label: "Region", isRequired: true })),
    _digitalOceanTags: types.array(types.string),
    digitalOceanTags: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _reservedIPs: types.array(types.string),
    reservedIPs: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _preInstallScript: types.optional(types.string, ""),
    preInstallScript: types.optional(StringModel, () => StringModel.create({ label: "Pre Install Script" })),
    _tokenSecretLink: types.optional(types.string, ""),
    tokenSecretName: types.optional(types.string, ""),
    _vpcId: types.optional(types.string, ""),
    vpcId: types.optional(StringModel, () => StringModel.create({ label: "VPC ID", isRequired: true })),
    _image: types.optional(types.string, ""),
    image: types.optional(StringModel, () => StringModel.create({ label: "Image", isRequired: true })),
    _sshKeys: types.array(types.string),
    sshKeys: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _extraSshKeys: types.array(types.string),
    extraSshKeys: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _nodePools: types.array(Mk8sDraftProviderDigitalOceanNodePoolReadonlyModel),
    nodePools: types.array(Mk8sDraftProviderDigitalOceanNodePoolModel),
    autoscaler: types.optional(Mk8sDraftAutoscalerModel, () => Mk8sDraftAutoscalerModel.create()),
    networking: types.optional(Mk8sDraftNetworkingModel, () => Mk8sDraftNetworkingModel.create()),
    removed: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get _tokenSecretName() {
      const { name: tokenSecretName } = ngParseLink(self._tokenSecretLink, { kind: "secret" });
      return tokenSecretName;
    },
    get tokenSecretLink() {
      const { absolute } = ngParseLink(self.tokenSecretName, { kind: "secret" });
      return absolute;
    },
  }))
  .actions((self) => ({
    reset() {
      self.preInstallScript.setInitialValue(self._preInstallScript);
      self.region.setInitialValue(self._region);
      self.tokenSecretName = self._tokenSecretName;
      self.vpcId.setInitialValue(self._vpcId);
      self.image.setInitialValue(self._image);
      self.digitalOceanTags = ListOfItemsModel.create({
        _items: self._digitalOceanTags.map((value) => ({
          firstValue: String(value),
        })),
      });
      self.reservedIPs = ListOfItemsModel.create({
        _items: self._reservedIPs.map((value) => ({
          firstValue: value,
        })),
      });
      self.sshKeys = ListOfItemsModel.create({
        _items: self._sshKeys.map((value) => ({
          firstValue: value,
        })),
      });
      self.extraSshKeys = ListOfItemsModel.create({
        _items: self._extraSshKeys.map((value) => ({
          firstValue: value,
        })),
      });
      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftProviderDigitalOceanNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          }),
        );
      }
      self.removed = false;
      self.autoscaler.reset();
      self.networking.reset();
    },
    confirm() {
      self.preInstallScript.confirm();
      self._preInstallScript = self.preInstallScript.value;
      self.region.confirm();
      self._region = self.region.value;
      self._tokenSecretLink = self.tokenSecretLink;
      self.vpcId.confirm();
      self._vpcId = self.vpcId.value;
      self.image.confirm();
      self._image = self.image.value;

      self._digitalOceanTags.clear();
      for (const i of self.digitalOceanTags.items) {
        self._digitalOceanTags.push(i.firstValue);
      }
      self._reservedIPs.clear();
      for (const i of self.reservedIPs.items) {
        self._reservedIPs.push(i.firstValue);
      }
      self._sshKeys.clear();
      for (const i of self.sshKeys.items) {
        self._sshKeys.push(i.firstValue);
      }
      self._extraSshKeys.clear();
      for (const i of self.extraSshKeys.items) {
        self._extraSshKeys.push(i.firstValue);
      }

      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftProviderDigitalOceanNodePoolReadonlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
            dropletSize: nodePool.dropletSize.value,
            minSize: String(nodePool.minSize.value).length > 0 ? Number(nodePool.minSize.value) : undefined,
            maxSize: String(nodePool.maxSize.value).length > 0 ? Number(nodePool.maxSize.value) : undefined,
          }),
        );
      }
      self.removed = false;

      self.autoscaler.confirm();
      self.networking.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setTokenSecretName(value: string) {
      self.tokenSecretName = value;
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }
      self.nodePools.push(
        Mk8sDraftProviderDigitalOceanNodePoolModel.create({
          index,
          status: "added",
        }),
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.nodePools.remove(node);
    },
  }))
  .views((self) => ({
    isNodePoolNameValid(index: number) {
      let res = true;
      const node = self.nodePools.find((np) => np.index === index)!;
      const pools = [...self.nodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyNodePoolNameConflicts() {
      for (const nodePool of self.nodePools) {
        if (
          [...self.nodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value,
          )
        ) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._nodePools.length !== self.nodePools.length) res = true;
      if (self.removed) res = true;
      if (self.nodePools.some((n) => n.isDirty)) res = true;
      if (self.region.isDirty) res = true;
      if (self.digitalOceanTags.isDirty) res = true;
      if (self.reservedIPs.isDirty) res = true;
      if (self.sshKeys.isDirty) res = true;
      if (self.extraSshKeys.isDirty) res = true;
      if (self.tokenSecretName !== self._tokenSecretName) res = true;
      if (self.vpcId.isDirty) res = true;
      if (self.image.isDirty) res = true;
      if (self.autoscaler.isDirty) res = true;
      if (self.networking.isDirty) res = true;
      if (self.preInstallScript.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self._nodePools.length !== self.nodePools.length) reason = "node pool length";
      if (self.removed) reason = "removed";
      if (self.nodePools.some((n) => n.isDirty))
        reason = self.nodePools.find((n) => n.isDirty)?.dirtyReason || "node pools has dirty";
      if (self.region.isDirty) reason = "region";
      if (self.digitalOceanTags.isDirty) reason = "digitalOceanTags";
      if (self.reservedIPs.isDirty) reason = "reservedIPs";
      if (self.sshKeys.isDirty) reason = "sshKeys";
      if (self.extraSshKeys.isDirty) reason = "extraSshKeys";
      if (self.tokenSecretName !== self._tokenSecretName) reason = "token secret link";
      if (self.vpcId.isDirty) reason = "vpc id";
      if (self.image.isDirty) reason = "image";
      if (self.autoscaler.isDirty) reason = self.autoscaler.dirtyReason;
      if (self.networking.isDirty) reason = self.networking.dirtyReason;
      if (self.preInstallScript.isDirty) reason = "preInstallScript";
      return reason;
    },
    get isValid() {
      let res = true;
      if (self.anyNodePoolNameConflicts) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      if (!self.region.isValid) res = false;
      if (!self.tokenSecretName) res = false;
      if (!self.vpcId.isValid) res = false;
      if (!self.image.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      if (self.sshKeys.items.length < 1) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.anyNodePoolNameConflicts) reason = "node pool name conflict";
      if (self.nodePools.some((n) => !n.isValid)) reason = self.nodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (!self.region.isValid) reason = "region";
      if (!self.image.isValid) reason = "image";
      if (!self.autoscaler.isValid) reason = self.autoscaler.invalidReason;
      if (!self.networking.isValid) reason = self.networking.invalidReason;
      if (!self.preInstallScript.isValid) reason = "preInstallScript";
      if (self.sshKeys.items.length < 1) reason = "no sshKeys";
      return reason;
    },
    get ui_isDigitalOceanValid() {
      let res = true;
      if (!self.region.isValid) res = false;
      if (!self.tokenSecretName) res = false;
      if (!self.vpcId.isValid) res = false;
      if (!self.image.isValid) res = false;
      if (self.sshKeys.items.length < 1) res = false;
      return res;
    },
    get dryRun_digitaloceanPathList() {
      return [
        //
        "spec.provider.digitalocean.region",
        "spec.provider.digitalocean.tokenSecretLink",
        "spec.provider.digitalocean.vpcId",
        "spec.provider.digitalocean.image",
        "spec.provider.digitalocean.sshKeys",
      ];
    },
    get ui_isAdvancedValid() {
      let res = true;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      return res;
    },
    get dryRun_advancedPathList() {
      return [
        //
        "spec.provider.digitalocean.digitalOceanTags",
        "spec.provider.digitalocean.reservedIPs",
        "spec.provider.digitalocean.extraSshKeys",
        "spec.provider.digitalocean.preInstallScript",
        ...self.autoscaler.dryRun_pathList("digitalocean"),
        ...self.networking.dryRun_pathList("digitalocean"),
      ];
    },
    get asObject() {
      let obj: any = {
        region: self.region.value,
        tokenSecretLink: self.tokenSecretLink,
        vpcId: self.vpcId.value,
        autoscaler: self.autoscaler.asObject,
        networking: self.networking.asObject,
        preInstallScript: self.preInstallScript.value,
        nodePools: self.nodePools.map((n) => n.asObject),
        image: self.image.value,
      };

      if (self.digitalOceanTags.items.length > 0) {
        obj.digitalOceanTags = self.digitalOceanTags.items.map((i) => i.firstValue);
      }
      if (self.reservedIPs.items.length > 0) {
        obj.reservedIPs = self.reservedIPs.items.map((i) => i.firstValue);
      }
      if (self.sshKeys.items.length > 0) {
        obj.sshKeys = self.sshKeys.items.map((i) => i.firstValue);
      }
      if (self.extraSshKeys.items.length > 0) {
        obj.extraSshKeys = self.extraSshKeys.items.map((i) => i.firstValue);
      }
      return obj;
    },
  }));
export interface Mk8sDraftProviderDigitalOceanMobx extends Instance<typeof Mk8sDraftProviderDigitalOceanModel> {}
