import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { MoreHorizontal, PlusCircle } from "react-feather";
import { NGKindSelect } from "../../../../newcomponents/select/ngkindselect";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";
import NGAlert from "../../../../newcomponents/alert";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../newcomponents/input/input";
import { getDryRunErrorText } from "../../getDryRunErrorText";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGProviderCreateDictionarySecret } from "../../ngProviderCreateDictionarySecret";
import { NGLabel } from "../../../../newcomponents/text/label";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderOblivusRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const o = mk8sDraft.provider_oblivus!;

  const [browserKey, setBrowserKey] = React.useState("");

  const sshKeysDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.oblivus.sshKeys",
    paths: o.sshKeys.items.map((_, idx) => `spec.provider.oblivus.sshKeys[${idx}]`),
  });

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <div>
            <NGLabel>Datacenter</NGLabel>
            <div className="flex items-center mb-4">
              <div>{o.datacenter.value}</div>
            </div>
          </div>
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={o.datacenter.setValue}
              path={"spec.provider.oblivus.datacenter"}
            />
          </div>
        </div>

        <div className="mb-4">
          <NGFormLabel required invalid={!o.tokenSecretName}>
            Oblivus Token Secret
          </NGFormLabel>
          <div className="mb-2 flex items-start gap-2">
            <NGKindSelect
              style={{ width: 450 }}
              value={o.tokenSecretName}
              invalid={!o.tokenSecretName}
              onChange={(value) => o.setTokenSecretName(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "opaque" }]}
              buttons={[
                {
                  title: "Create",
                  render: () => <PlusCircle className="h-4" />,
                  onClick: () => setBrowserKey("createSecret"),
                },
              ]}
            />
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              path={"spec.provider.oblivus.tokenSecretLink"}
            />
          </div>
          <NGAlert
            style={{ width: 450 }}
            render={() => (
              <div className="flex flex-col text-sm">
                <span>A dictionary secret that contains your Oblivus key and token.</span>
                <span>It will be used to manage servers in your Oblivus account.</span>
              </div>
            )}
          />
        </div>

        <NGInputListMst
          data={o.sshKeys}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="SSH Keys"
          labelRender={() => {
            return (
              <>
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("ssh-keys")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (sshKeysDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }

            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          invalid={sshKeysDryRun.severity === "error"}
          error={sshKeysDryRun.severity === "error" ? sshKeysDryRun.message : ""}
          warning={sshKeysDryRun.severity === "error" ? "" : sshKeysDryRun.message}
        />
      </div>

      {browserKey === "createSecret" ? (
        <NGProviderCreateDictionarySecret
          onOk={(secretName: string) => o.setTokenSecretName(secretName)}
          onClose={() => setBrowserKey("")}
          title={"Create Oblivus Token Secret"}
        />
      ) : null}
      {browserKey === "ssh-keys" ? (
        <NGProviderBrowser
          title="Browse Oblivus SSH Keys"
          initialValue={o.sshKeys.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ssh-keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (o.sshKeys.items.some((i) => i.firstValue === value)) {
                continue;
              }
              o.sshKeys.add();
              const i = o.sshKeys.items[o.sshKeys.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Name"]}
          getData={(item: any) => [String(item.name), item.name]}
        />
      ) : null}
    </>
  );
};

export const ProviderOblivus = observer(ProviderOblivusRaw);
