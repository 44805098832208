import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import NGAlert from "../../../../newcomponents/alert";
import { DryRunAlert } from "../../dryRunAlert";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { ExternalLink } from "../../../../newcomponents/table/components/ExternalLink";
import { DOCS_URL } from "../../../../envVariables";
interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const CreateAddonsAWSECRRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const v = mk8sDraft.addon_awsEcr.roleArn;
  return (
    <div>
      <div className="mb-2">AWS ECR</div>
      <div className="mb-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.awsECR"}
        />
      </div>
      <NGAlert
        className="mb-4"
        type={"info"}
        title="Documentation"
        render={() => {
          return (
            <div className="flex items-end gap-1">
              <ExternalLink url={`${DOCS_URL}/mk8s/add-ons/aws_ecr`} label={`Click here`} disableCopy />
              <div>for learning how to configure the addon.</div>
            </div>
          );
        }}
      />
      <div className="flex gap-2 items-start">
        <NGFormElement name={"awsEcr.roleArn"} label={v.label} value={v.value} onChange={v.setValue} error={v.error} />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={v.setValue}
            path={"spec.addOns.awsECR.roleArn"}
          />
        </div>
      </div>
      {mk8sDraft.provider.value === "aws" ? (
        <NGAlert title="Role ARN is optional with AWS Provider, by default it will use the instance role to pull images." />
      ) : null}
    </div>
  );
};

export const CreateAddonsAWSECR = observer(CreateAddonsAWSECRRaw);
