import { types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";

export const Mk8sDraftAddonMetricsModel = types
  .model({
    _isEnabled: types.optional(types.boolean, false),
    isEnabled: types.optional(types.boolean, false),
    _coreDns: types.optional(types.boolean, false),
    coreDns: types.optional(types.boolean, false),
    _kubelet: types.optional(types.boolean, false),
    kubelet: types.optional(types.boolean, false),
    _kubeState: types.optional(types.boolean, false),
    kubeState: types.optional(types.boolean, false),
    _nodeExporter: types.optional(types.boolean, false),
    nodeExporter: types.optional(types.boolean, false),
    _apiserver: types.optional(types.boolean, false),
    apiserver: types.optional(types.boolean, false),
    _cadvisor: types.optional(types.boolean, false),
    cadvisor: types.optional(types.boolean, false),
    _scrapeAnnotated: types.optional(types.boolean, false),
    scrapeAnnotated: types.optional(types.boolean, false),
    _scrapeAnnotated_intervalSeconds: types.optional(types.number, 30),
    scrapeAnnotated_intervalSeconds: types.optional(StringModel, () =>
      NumberModel.create({ label: "Interval Seconds", min: 30, isRequired: true })
    ),
    _scrapeAnnotated_includeNamespaces: types.optional(types.string, ""),
    scrapeAnnotated_includeNamespaces: types.optional(StringModel, () =>
      StringModel.create({ label: "Include Namespaces" })
    ),
    _scrapeAnnotated_excludeNamespaces: types.optional(types.string, ""),
    scrapeAnnotated_excludeNamespaces: types.optional(StringModel, () =>
      StringModel.create({ label: "Exclude Namespaces" })
    ),
    _scrapeAnnotated_retainLabels: types.optional(types.string, ""),
    scrapeAnnotated_retainLabels: types.optional(StringModel, () => StringModel.create({ label: "Retain Labels" })),
  })
  .actions((self) => ({
    setIsEnabled(value: boolean) {
      self.isEnabled = value;
    },
    setCoreDns(value: boolean) {
      self.coreDns = value;
    },
    setKubelet(value: boolean) {
      self.kubelet = value;
    },
    setKubeState(value: boolean) {
      self.kubeState = value;
    },
    setNodeExporter(value: boolean) {
      self.nodeExporter = value;
    },
    setApiserver(value: boolean) {
      self.apiserver = value;
    },
    setCadvisor(value: boolean) {
      self.cadvisor = value;
    },
    setScrapeAnnotated(value: boolean) {
      self.scrapeAnnotated = value;
    },
  }))
  .actions((self) => ({
    reset() {
      self.isEnabled = self._isEnabled;
      self.coreDns = self._coreDns;
      self.kubelet = self._kubelet;
      self.kubeState = self._kubeState;
      self.nodeExporter = self._nodeExporter;
      self.apiserver = self._apiserver;
      self.cadvisor = self._cadvisor;
      self.scrapeAnnotated = self._scrapeAnnotated;
      self.scrapeAnnotated_intervalSeconds.setInitialValue(String(self._scrapeAnnotated_intervalSeconds));
      self.scrapeAnnotated_includeNamespaces.setInitialValue(self._scrapeAnnotated_includeNamespaces);
      self.scrapeAnnotated_excludeNamespaces.setInitialValue(self._scrapeAnnotated_excludeNamespaces);
      self.scrapeAnnotated_retainLabels.setInitialValue(self._scrapeAnnotated_retainLabels);
    },
    confirm() {
      self._isEnabled = self.isEnabled;
      self._coreDns = self.coreDns;
      self._kubelet = self.kubelet;
      self._kubeState = self.kubeState;
      self._nodeExporter = self.nodeExporter;
      self._apiserver = self.apiserver;
      self._cadvisor = self.cadvisor;
      self._scrapeAnnotated = self.scrapeAnnotated;
      self.scrapeAnnotated_intervalSeconds.confirm();
      self._scrapeAnnotated_intervalSeconds = Number(self.scrapeAnnotated_intervalSeconds.value);
      self.scrapeAnnotated_includeNamespaces.confirm();
      self._scrapeAnnotated_includeNamespaces = self.scrapeAnnotated_includeNamespaces.value;
      self.scrapeAnnotated_excludeNamespaces.confirm();
      self._scrapeAnnotated_excludeNamespaces = self.scrapeAnnotated_excludeNamespaces.value;
      self.scrapeAnnotated_retainLabels.confirm();
      self._scrapeAnnotated_retainLabels = self.scrapeAnnotated_retainLabels.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (self.isEnabled) {
        if (self.scrapeAnnotated) {
          if (!self.scrapeAnnotated_intervalSeconds.isValid) res = false;
          if (!self.scrapeAnnotated_includeNamespaces.isValid) res = false;
          if (!self.scrapeAnnotated_excludeNamespaces.isValid) res = false;
          if (!self.scrapeAnnotated_retainLabels.isValid) res = false;
        }
      }
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.isEnabled) {
        if (self.scrapeAnnotated) {
          if (!self.scrapeAnnotated_intervalSeconds.isValid) reason = "intervalSeconds";
          if (!self.scrapeAnnotated_includeNamespaces.isValid) reason = "includeNamespaces";
          if (!self.scrapeAnnotated_excludeNamespaces.isValid) reason = "excludeNamespaces";
          if (!self.scrapeAnnotated_retainLabels.isValid) reason = "retainLabels";
        }
      }
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.isEnabled !== self._isEnabled) res = true;
      if (self.isEnabled) {
        if (self.coreDns !== self._coreDns) res = true;
        if (self.kubelet !== self._kubelet) res = true;
        if (self.kubeState !== self._kubeState) res = true;
        if (self.nodeExporter !== self._nodeExporter) res = true;
        if (self.apiserver !== self._apiserver) res = true;
        if (self.cadvisor !== self._cadvisor) res = true;
        if (self.scrapeAnnotated !== self._scrapeAnnotated) res = true;
        if (self.scrapeAnnotated) {
          if (self.scrapeAnnotated_intervalSeconds.isDirty) res = true;
          if (self.scrapeAnnotated_includeNamespaces.isDirty) res = true;
          if (self.scrapeAnnotated_excludeNamespaces.isDirty) res = true;
          if (self.scrapeAnnotated_retainLabels.isDirty) res = true;
        }
      }
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.isEnabled !== self._isEnabled) reason = "enabled changed";
      if (self.isEnabled) {
        if (self.coreDns !== self._coreDns) reason = "coreDns";
        if (self.kubelet !== self._kubelet) reason = "kubelet";
        if (self.kubeState !== self._kubeState) reason = "kubeState";
        if (self.nodeExporter !== self._nodeExporter) reason = "nodeExporter";
        if (self.apiserver !== self._apiserver) reason = "apiserver";
        if (self.cadvisor !== self._cadvisor) reason = "cadvisor";
        if (self.scrapeAnnotated !== self._scrapeAnnotated) reason = "scrapeAnnotated";
        if (self.scrapeAnnotated) {
          if (self.scrapeAnnotated_intervalSeconds.isDirty) reason = "scrapeAnnotated_intervalSeconds";
          if (self.scrapeAnnotated_includeNamespaces.isDirty) reason = "scrapeAnnotated_includeNamespaces";
          if (self.scrapeAnnotated_excludeNamespaces.isDirty) reason = "scrapeAnnotated_excludeNamespaces";
          if (self.scrapeAnnotated_retainLabels.isDirty) reason = "scrapeAnnotated_retainLabels";
        }
      }
      return reason;
    },
    get asObject() {
      let res: any = {
        coreDns: self.coreDns,
        kubelet: self.kubelet,
        kubeState: self.kubeState,
        nodeExporter: self.nodeExporter,
        apiserver: self.apiserver,
        cadvisor: self.cadvisor,
      };
      if (self.scrapeAnnotated) {
        res.scrapeAnnotated = {
          intervalSeconds: Number(self.scrapeAnnotated_intervalSeconds.value),
        };
        if (self.scrapeAnnotated_includeNamespaces.value) {
          res.scrapeAnnotated.includeNamespaces = self.scrapeAnnotated_includeNamespaces.value;
        }
        if (self.scrapeAnnotated_excludeNamespaces.value) {
          res.scrapeAnnotated.excludeNamespaces = self.scrapeAnnotated_excludeNamespaces.value;
        }
        if (self.scrapeAnnotated_retainLabels.value) {
          res.scrapeAnnotated.retainLabels = self.scrapeAnnotated_retainLabels.value;
        }
      }
      return res;
    },
  }));
