import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button as AntButton, Modal, notification } from "antd";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Discovery } from "../../mobxStores/discovery/discovery";
import { Copy } from "react-feather";
import { linksOf, request } from "../../services/cpln";
import { NGButton } from "../../newcomponents/button/Button";
import { Image } from "./../../schema/types/image";
import { Table } from "../../newcomponents/table/table";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { NameColumn } from "../../newcomponents/table/columns/wellKnown/nameColumn";

// TODO check if used
// usedInWorkloads: types.array(types.string), // Workload links

interface ImageTableItem extends Image {
  selfLink: string;
}

interface Props {
  repository: string;
  images: Image[];
  refreshImages: any;
  isFetchingMore: boolean;
}
const RepositoryDetailRaw: React.FC<Props> = ({ repository, images, refreshImages, isFetchingMore }) => {
  const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);
  const [selections, setSelections] = React.useState<string[]>([]);
  const [isDeletingImages, setIsDeletingImages] = React.useState(false);

  async function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  function pullImage(image: string) {
    const { org } = ConsoleContext;
    const registry = Discovery.endpoints.registry;
    const pullCommand = `docker pull ${registry?.replace("https://", "").replace("{org}", org!)}/${image}`;

    const m = Modal.info({
      width: 700,
      title: `Docker Pull Command`,
      maskClosable: true,
      content: (
        <div>
          <div className="flex items-center">
            <input
              data-testid="step-pull"
              className="flex-grow inline-flex px-4 py-2 border"
              style={{
                width: 450,
              }}
              disabled={true}
              value={pullCommand}
            />
            <button
              onClick={() => {
                copyToClipboard(pullCommand);
              }}
              className={`ml-2 ngfocus color-link`}
            >
              <Copy className="feather-icon" />
            </button>
          </div>
        </div>
      ),
      footer: (
        <div className="modal-actions">
          <NGButton variant="primary" onClick={() => m.destroy()}>
            OK
          </NGButton>
        </div>
      ),
    });
  }

  async function confirmImagesDeletion() {
    try {
      setIsLoadingDelete(true);
      const promises: Promise<any>[] = [];
      for (let imageLink of selections) {
        promises.push(request({ url: imageLink, method: "delete" }));
      }
      await Promise.all(promises);
      notification.success({ message: "Success", description: "Deleted images" });
    } catch (e) {
      notification.success({ message: "Failed", description: "Failed to delete all images" });
    } finally {
      setSelections([]);
      refreshImages();
      setIsLoadingDelete(false);
      setIsDeletingImages(false);
    }
  }

  function cancelImagesDeletion() {
    setIsLoadingDelete(false);
    setIsDeletingImages(false);
  }

  const imageTableItems: ImageTableItem[] = images.map((item) => ({ ...item, selfLink: linksOf(item).self! }));

  return (
    <>
      <div className="n-detail">
        <div className="n-detail-header border-b flex items-center justify-between pb-4 h-20 z-10">
          <div>
            <div data-testid="detail-kind" className="text-dark text-xs">
              IMAGE
            </div>
            <div className="flex items-center">
              <div data-testid="detail-name" className="text-2xl font-semibold leading-none">
                {repository}
              </div>
            </div>
          </div>
        </div>
        <div className="n-detail-main">
          <Table<ImageTableItem>
            tableId="repository-detail"
            hideSettings={true}
            data={imageTableItems}
            selectMode="multi"
            selectKey="selfLink"
            selections={selections}
            onSelectionsChange={setSelections}
            columns={[
              NameColumn(),
              TagsColumn(),
              CreatedColumn(),
              LastModifiedColumn(),
              {
                id: "actions",
                label: "",
                cell: (props) => {
                  const image = props.row.original;
                  return (
                    <div className="flex items-center justify-end w-full">
                      <div className="flex items-center" style={{ width: 40 }}>
                        <AntButton size={"small"} onClick={() => pullImage(image.name)} type={"primary"}>
                          <span style={{ lineHeight: "1.7" }}>Pull</span>
                        </AntButton>
                      </div>
                    </div>
                  );
                },
              },
            ]}
          />
          <div className="mt-4 flex items-center justify-end">
            <NGButton
              disabled={selections.length < 1}
              loading={isLoadingDelete}
              variant={"danger"}
              outlined
              onClick={() => setIsDeletingImages(true)}
            >
              Delete Images
            </NGButton>
          </div>
        </div>
      </div>
      {isDeletingImages ? (
        <Modal
          title="Delete Images"
          open={true}
          maskClosable={!isLoadingDelete}
          destroyOnClose
          onCancel={cancelImagesDeletion}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={cancelImagesDeletion} disabled={isLoadingDelete}>
                Cancel
              </NGButton>
              <NGButton
                variant="danger"
                loading={isLoadingDelete}
                disabled={isLoadingDelete}
                onClick={confirmImagesDeletion}
              >
                Delete
              </NGButton>
            </div>
          }
        >
          Are you sure you want to delete the selected images?
        </Modal>
      ) : null}
    </>
  );
};

export const RepositoryDetail = observer(RepositoryDetailRaw);
