import { notification } from "antd";
import { Kind } from "../../schema/types/base";
import {
  fetchPages,
  homeLink,
  linksOf,
  parentLink,
  request,
  RequestParams,
  TerraformExporterResponse,
} from "../../services/cpln";
import { clearItem, yamlDumpAll, toSortedJSON } from "../../services/utils";
import { gvcScopedKinds } from "../../utils/kinds";
import { convertAllLinksToRelative } from "../../utils/linkParser/linkParser";
import { workloadHelpers } from "./workload.helpers";
import jsYaml from "js-yaml";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Group } from "../../schema/types/group";
import { User } from "../../schema/types/user";

export interface UserCsvRow {
  email: string;
  group: string;
}

export const itemHelpers = ({ org, gvc, kind }: { org: string; gvc: string | null; kind: Kind }) => {
  return {
    getFileName(): string {
      let fileName: string = org;

      if (gvcScopedKinds.includes(kind)) {
        fileName += `_${gvc}`;
      }

      fileName += `_${kind}s`;

      return fileName;
    },
    handleAutoDownloadWithElementA(blobData: any, extension: "json" | "yaml" | "tf" | "csv", filename?: string) {
      const file = new Blob([blobData], { type: `text/${extension}` });
      const href = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = (filename || this.getFileName()) + "." + extension;
      a.href = href;
      a.click();
    },
    async handleExportItems(links: string[], slim: boolean) {
      let _items: any[] = [];

      if (links && links.length > 0) {
        const itemPromises: Promise<any>[] = [];
        for (let link of links) {
          itemPromises.push(request({ url: link }));
        }
        const itemResponses: any[] = await Promise.allSettled(itemPromises);
        _items = itemResponses
          .filter((response) => response.status === "fulfilled")
          .map((response) => response.value.data);
      } else {
        let nextLink: string | undefined = parentLink(kind as any);
        while (!!nextLink) {
          const { data }: any = await request({ url: nextLink });
          _items = _items.concat(data.items);
          nextLink = linksOf(data).next;
        }
      }

      if (kind === "secret") {
        const revealPromises: Promise<any>[] = [];
        for (let _item of _items) {
          revealPromises.push(request({ url: linksOf(_item).reveal! }));
        }
        const revealedItems: any[] = await Promise.allSettled(revealPromises);

        for (let revealedRes of revealedItems) {
          if (revealedRes.status !== "fulfilled") {
            continue;
          }
          const revealed = revealedRes.value.data;
          const index = _items.findIndex((i) => i.name === revealed.name);
          if (index >= 0) {
            _items[index] = revealed;
          }
        }
      }

      if (kind === "workload") {
        const deploymentPromises: Promise<any>[] = [];
        for (let _item of _items) {
          deploymentPromises.push(request({ url: linksOf(_item).deployment! }));
        }
        const deploymentResponses: any[] = await Promise.allSettled(deploymentPromises);

        for (let deploymentRes of deploymentResponses) {
          const index = _items.findIndex((i) => linksOf(i).self === linksOf(deploymentRes.value.data).workload);
          if (index >= 0) {
            if (deploymentRes.status !== "fulfilled") {
              _items[index].status.ready = "unknown";
              _items[index].status.readyLatest = "unknown";
              continue;
            }

            const health = workloadHelpers.getHealth(deploymentRes.value.data.items, _items[index]);
            _items[index].status.ready = health.isReady;
            _items[index].status.readyLatest = health.isLatestReady;
            _items[index].status.readyCheckTimestamp = new Date().toISOString();
          }
        }
      }

      for (let _itemIndex in _items) {
        let _item = _items[_itemIndex];
        if (slim) {
          clearItem(_item);
        }
        _item = convertAllLinksToRelative(_item);
        _item = toSortedJSON(_item);
        _items[_itemIndex] = _item;
      }

      return links.length === 1 ? _items[0] : _items;
    },
    async handleExportJSON(links: string[], slim: boolean) {
      try {
        const data = await this.handleExportItems(links, slim);
        const blob = JSON.stringify(data, null, 2);
        this.handleAutoDownloadWithElementA(blob, "json");
      } catch (e) {
        notification.warning({ message: "Failed", description: e.message });
      }
    },
    async handleExportYaml(links: string[], slim: boolean) {
      try {
        const data = await this.handleExportItems(links, slim);
        const blob = yamlDumpAll(data, { indent: 2, noRefs: true });
        this.handleAutoDownloadWithElementA(blob, "yaml");
      } catch (e) {
        notification.warning({ message: "Failed", description: e.message });
      }
    },
    async handleExportTerraform(links: string[]): Promise<string> {
      const notificationKey: string = "terraform-exporter";
      notification.open({
        key: notificationKey,
        type: "info",
        message: "Terraform Exporter",
        description: "Fetching data from the server, please wait",
      });

      let importCommand: string = "";

      try {
        let params: RequestParams;

        if (links && links.length > 0) {
          params = {
            service: "terraform-exporter",
            url: `/batch?org=${ConsoleContext.org}&import=true`,
            method: "post",
            body: { links: links },
          };
        } else {
          let url = homeLink(kind as any);

          if (gvcScopedKinds.includes(kind)) {
            url = parentLink(kind as any);
          }

          url = `${url}?import=true`;

          params = { service: "terraform-exporter", url: url };
        }

        // Call Terraform exporter
        let { data } = await request<TerraformExporterResponse>(params);

        // Download Terraform resources
        this.handleAutoDownloadWithElementA(convertAllLinksToRelative(data.resources.join("\n\n")), "tf");

        // Set import command
        importCommand = data.imports.join("\n");
      } catch (e) {
        notification.warning({ message: "Failed", description: e.message });
      }

      notification.destroy(notificationKey);
      return importCommand;
    },
    async handleExportCsv(links: string[]): Promise<void> {
      let emails: string[];

      if (links.length > 0) {
        // Extract emails from links
        emails = links.map((link: string): string => {
          const parts: string[] = link.split("/");
          return parts[parts.length - 1];
        });
      } else {
        // If no links are provided, fetch the items through paginated API calls
        let items: User[] = [];
        let nextLink: string | undefined = parentLink(kind as any);

        // Fetch all users
        while (!!nextLink) {
          const { data }: any = await request({ url: nextLink });

          // Append the fetched items to the accumulated list
          items = items.concat(data.items);

          // Update nextLink to point to the next page of results, if available
          nextLink = linksOf(data).next;
        }

        // Extract emails from the users list
        emails = items.map((item: User): string => item.email);
      }

      // Skip export if there are no emails
      if (emails.length == 0) {
        notification.warning({
          message: "Export Error",
          description: "No users selected or no users available in the organization.",
        });
        return;
      }

      const builtinGroups: Group[] = [];
      const groupPriority: string[] = ["viewers", "superusers"];

      // Fetch all builtin groups
      try {
        const { data } = await request({
          method: "post",
          url: `/org/${org}/group/-query`,
          body: {
            spec: {
              match: "all",
              terms: [{ op: "=", property: "origin", value: "builtin" }],
            },
          },
        });
        await fetchPages(data);
        builtinGroups.push(...data.items);
      } catch (e) {
        // Log exception
        console.error(e);
      }

      // Export to CSV with only the email column if there were no builtin groups found
      if (builtinGroups.length === 0) {
        this.handleAutoDownloadWithElementA(`email\n${emails.join("\n")}`, "csv");
        return;
      }

      // Create a mapping of email to group for faster lookup
      const emailToGroupMap: Map<string, string> = new Map();

      // Iterate over builtin groups and start the mapping process
      for (const group of builtinGroups) {
        // Ignore group that has no member links
        if (!group.memberLinks) {
          continue;
        }

        // Iterate over member links to find emails
        for (const memberLink of group.memberLinks) {
          if (memberLink.includes("/user/")) {
            const email: string = memberLink.split("/").pop() || "";

            // Map for the first time
            if (!emailToGroupMap.has(email)) {
              emailToGroupMap.set(email, group.name);
              continue;
            }

            // Retrieve the currently mapped group for the email
            const currentGroup: string = emailToGroupMap.get(email)!;

            // If already mapped, adjust value based on group priority
            if (groupPriority.indexOf(currentGroup) > groupPriority.indexOf(group.name)) {
              // Current group has higher priority, do nothing
              continue;
            }

            // Update the group mapping
            emailToGroupMap.set(email, group.name);
          }
        }
      }

      // Build CSV rows
      const csvData: UserCsvRow[] = emails.map(
        (email: string): UserCsvRow => ({
          email,
          group: emailToGroupMap.get(email) || "",
        }),
      );

      // Generate CSV content
      const csvContent: string = "email,group\n" + csvData.map(({ email, group }) => `${email},${group}`).join("\n");

      // Trigger file download
      this.handleAutoDownloadWithElementA(csvContent, "csv");
    },
  };
};
