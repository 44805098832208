import { types, Instance } from "mobx-state-tree";
import { SelectModel } from "./selectModel";
import { NumberModel } from "./numberModel";
import { StringModel } from "./stringModel";
import { TransformKey } from "./transforms";

function parseCPU(cpu: string): { amount: number; unit: string } {
  const millicore = "m";
  if (cpu.endsWith(millicore)) {
    const amount = Number(cpu.split(millicore)[0]);
    return { amount, unit: "Millicores" };
  } else {
    return { amount: Number(cpu), unit: "Cores" };
  }
}

export const cpuUnitOptions = [
  { label: "Millicores", value: "Millicores" },
  { label: "Cores", value: "Cores" },
];
export const CpuModel = types
  .model({
    _label: types.enumeration(["Min", "Max", "Reserved"]),
    _defaultValue: types.string,
    _cpu: types.optional(types.string, ""),
    amount: types.optional(StringModel, () => NumberModel.create({ label: "CPU", allowDot: true })),
    unit: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Unit",
        initialValue: cpuUnitOptions[0].value,
        options: cpuUnitOptions,
      }),
    ),
  })
  .actions((self) => ({
    reset() {
      self.amount.setLabel(`${self._label} CPU`);
      self.amount.setIsRequired(self._label !== "Min");
      self._cpu = self._defaultValue;

      const { unit, amount } = parseCPU(self._defaultValue);
      self.unit.setInitialValue(unit);
      self.amount.setInitialValue(amount > 0 ? String(amount) : "");

      let min: number;

      if (self.unit.value === "Millicores") {
        min = 25;
        self.amount.setTransformKey("number");
      } else {
        min = 0.025;
        self.amount.setTransformKey("cpu");
      }

      self.amount.setMin(min);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .actions((self) => ({
    setUnit(newUnit: "Millicores" | "Cores") {
      self.unit.setValue(newUnit);

      let min: number;

      if (newUnit === "Millicores") {
        min = 25;
        self.amount.setTransformKey("number");
      } else {
        min = 0.025;
        self.amount.setTransformKey("cpu");
      }

      self.amount.setMin(min);
    },
    setInitialUnit(value: "Millicores" | "Cores") {
      self.unit.setInitialValue(value);

      let min: number;

      if (value === "Millicores") {
        min = 25;
        self.amount.setTransformKey("number");
      } else {
        min = 0.025;
        self.amount.setTransformKey("cpu");
      }

      self.amount.setMin(min);
    },
    setLabel(value: "Min" | "Max" | "Reserved") {
      self._label = value;
      self.amount.setLabel(`${self._label} CPU`);
      self.amount.setIsRequired(self._label !== "Min");
    },
    setInitialValue(initial: string) {
      self._cpu = initial;
      self.reset();
    },
    confirm() {
      if (self.unit.value === "Cores") {
        self._cpu = self.amount.value;
      } else {
        self._cpu = self.amount.value + "m";
      }
      self._defaultValue = self._cpu;
      self.amount.confirm();
      self.unit.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.amount.isDirty) res = true;
      if (self.unit.isDirty) res = true;
      return res;
    },
    get isValid() {
      return self.amount.isValid;
    },
    get value() {
      return self.unit.value === "Cores" ? self.amount.value : self.amount.value + "m";
    },
  }));

export interface CPUMobx extends Instance<typeof CpuModel> {}
