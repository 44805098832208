import hotjar from "@hotjar/browser";
import { HOTJAR_SITE_ID, IS_LOCAL } from "../envVariables";
import { User } from "../mobxStores/user/user";
export function setupHotjar() {
  try {
    const siteId = Number(HOTJAR_SITE_ID);
    const version = 6;
    hotjar.init(siteId, version, { debug: IS_LOCAL });
  } catch (e) {}
}

export function hotjarIdentify() {
  hotjar.identify(User.uid, {
    first_name: User.name,
    email: User.email,
  });
}

export function hotjarPageChange(page: string) {
  hotjar.stateChange(page);
}
