import { observer } from "mobx-react-lite";
import * as React from "react";
// Here for calling firebase
import axios from "axios";
import { AlertCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/layout/loader";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { UserData } from "../../mobxStores/userData/userData";
import { checkCanCreateTrialAccount } from "../../services/utils";
import { AccountTrialCreate } from "./account/trialCreate/trialCreate";
import { User } from "../../mobxStores/user/user";
import { Modal } from "antd";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NGButton } from "../../newcomponents/button/Button";
import { NGInput } from "../../newcomponents/input/input";
import { NGFormLabel } from "../../newcomponents/text/formLabel";

const onCheckTrialIntentURL = IS_DEPLOYMENT_ENV_TEST
  ? "https://us-central1-controlplane-test-268922.cloudfunctions.net/onCheckTrialIntent"
  : "https://us-central1-cpln-prod01.cloudfunctions.net/onCheckTrialIntent";

const LandingRaw: React.FC = () => {
  const navigate = useNavigate();

  const [error, setError] = React.useState("");
  const [isReady, setIsReady] = React.useState(false);
  const [showTrial, setShowTrial] = React.useState(false);
  const [isAskingFullName, setIsAskingFullName] = React.useState(false);
  const [fullName, setFullName] = React.useState("");
  const fullNameInputRef = React.useRef(StringModel.create({ label: "Full Name", isRequired: true }));
  const [contactSupport, setContactSupport] = React.useState(false);

  React.useEffect(() => {
    process();
  }, []);

  async function processFullName() {
    try {
      const checkTrialIntentRes = await axios.post(onCheckTrialIntentURL, { email: User.email });
      if (!!checkTrialIntentRes.data.fullName) {
        setFullName(checkTrialIntentRes.data.fullName);
        setIsReady(true);
      } else if (User.name) {
        setFullName(User.name);
        setIsReady(true);
      } else {
        setIsAskingFullName(true);
      }
    } catch (e) {
      setError(e.message);
    }
  }

  async function process() {
    let _showTrial = false;
    try {
      const res = await checkCanCreateTrialAccount(User.email);
      UserData.setIsWorkEmail(res.isWorkEmail);
      UserData.setCanCreateTrialAccount(res.canCreateAccount);
      const checkTrialIntentRes = await axios.post(onCheckTrialIntentURL, { email: User.email });
      _showTrial = (UserData.hereForTrial || checkTrialIntentRes.data.hasTrialIntent) && UserData.isWorkEmail;
    } catch (e) {
      setError(e.message);
    }
    setShowTrial(_showTrial);
    if (_showTrial) {
      processFullName();
    } else {
      setIsReady(true);
    }
  }

  if (!!error) {
    return (
      <div className="flex flex-col items-center justify-center gap-4">
        <div>An error happened</div>
        <div>{error}</div>
      </div>
    );
  }

  if (isAskingFullName) {
    return (
      <Modal
        open={isAskingFullName}
        title={"Required Information"}
        footer={
          <div className="modal-actions">
            <NGButton
              variant="primary"
              onClick={() => {
                setFullName(fullNameInputRef.current.value);
                setIsAskingFullName(false);
                setIsReady(true);
              }}
              disabled={!fullNameInputRef.current.value || !fullNameInputRef.current.isValid}
            >
              Confirm
            </NGButton>
          </div>
        }
        closable={false}
        maskClosable={false}
      >
        <NGFormLabel required invalid={!fullNameInputRef.current.value}>
          Full Name
        </NGFormLabel>
        <NGInput
          value={fullNameInputRef.current.value}
          onChange={(e) => fullNameInputRef.current.setValue(e.target.value)}
        />
      </Modal>
    );
  }

  if (!isReady) {
    return (
      <div className="flex flex-col items-center justify-center gap-4">
        <Loader reason="Landing process" fullScreen />
        <div>Loading. Please Wait.</div>
      </div>
    );
  }

  return (
    <div className="onboard-landing-page flex flex-col items-center">
      <div className="my-8 text-xl font-bold">Welcome to Control Plane {showTrial ? "Trial" : ""}</div>
      {contactSupport ? (
        <div className="flex flex-col items-center justify-center gap-4">
          <AlertCircle />
          <div>A team member from your company already set up a trial account.</div>
          <div>
            We received your request for another Trial Account, we will review the request and inform you with email.
          </div>
          <div>If you want, you can still create a Paying Account to use Control Plane.</div>
          <NGButton variant="action" onClick={() => navigate("/billing/account/-createfirst")}>
            Create Account
          </NGButton>
        </div>
      ) : showTrial ? (
        <div>
          <AccountTrialCreate onContactSupport={() => setContactSupport(true)} fullName={fullName} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-4">
          <div className="text-center">Start Using Control Plane by Creating an Account</div>
          <NGButton variant="action" onClick={() => navigate("/billing/account/-createfirst")}>
            Create Account
          </NGButton>
        </div>
      )}
    </div>
  );
};

export const Landing = observer(LandingRaw);
