import * as React from "react";
import { observer } from "mobx-react-lite";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { request } from "../../../../services/cpln";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { HUBSPOT_SERVICE_URL, IS_DEPLOYMENT_ENV_TEST } from "../../../../envVariables";
import { FormLabel } from "../../../../components/forms/formLabel";
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";
import { flowResult } from "mobx";
import { Theme } from "../../../../mobxStores/uiData/theme";
import { User } from "../../../../mobxStores/user/user";
import { NGCheckbox } from "../../../../newcomponents/checkbox";
import { NGButton } from "../../../../newcomponents/button/Button";

export const get_CARD_ELEMENT_OPTIONS = (isDarkTheme: boolean) => ({
  style: {
    base: {
      color: isDarkTheme ? "#fff" : "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
});

const PaymentMethodAddRaw: React.FC = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { AccountUUID, Roles, PaymentMethods } = BillingContext;
  const [isLoading, setIsLoading] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState("");
  const [clientSecretError, setClientSecretError] = React.useState("");
  const [addError, setAddError] = React.useState("");
  const [consentGiven, setConsentGiven] = React.useState(false);
  const [cardError, setCardError] = React.useState<any>(undefined);
  const [cardComplete, setCardComplete] = React.useState(false);
  const [canCreate, setCanCreate] = React.useState(false);
  const [isDefault, setIsDefault] = React.useState(false);
  const { theme } = Theme;

  React.useEffect(() => {
    if (!Roles.includes("billing_admin")) {
      navigate(`/billing/account/${AccountUUID}/payment-methods`);
      return;
    }
    getClientSecret();
  }, []);

  React.useEffect(() => {
    let res = true;
    if (!!addError) res = false;
    if (!!clientSecretError) res = false;
    if (!consentGiven) res = false;
    if (!!cardError) res = false;
    if (!cardComplete) res = false;
    setCanCreate(res);
  }, [addError, clientSecretError, consentGiven, cardError, cardComplete]);

  async function getClientSecret() {
    try {
      setIsLoading(true);
      setClientSecretError("");
      const { data } = await request({
        method: "post",
        service: "billing-ng",
        url: `/account/${AccountUUID}/payment-setup`,
      });
      setClientSecret(data.clientSecret);
    } catch (e) {
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setClientSecretError(errorMessage);
      setClientSecret("");
    } finally {
      setIsLoading(false);
    }
  }

  function reset() {
    getClientSecret();
    setAddError("");
    setClientSecretError("");
  }

  async function onConfirm() {
    if (!stripe || !elements || !clientSecret || !!clientSecretError || !cardComplete || !!cardError) {
      return;
    }
    setIsLoading(true);

    const isFirstPaymentMethod = PaymentMethods.length < 1;
    const shouldAddAsDefault = isDefault || isFirstPaymentMethod;

    try {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
        },
      });

      if (result.error) {
        setAddError(result.error.message || "Could not finalize adding payment method");
      } else {
        if (shouldAddAsDefault) {
          await request({
            method: "put",
            service: "billing-ng",
            url: `/account/${AccountUUID}/default-payment/${result.setupIntent.payment_method}`,
          });
        }
        notification.success({
          message: "Success",
          description: `Added payment method ${isDefault ? " as default" : ""}`,
        });
        await flowResult(BillingContext.fetchPaymentMethods());

        try {
          let hubspotutk = "";
          try {
            let _hubspotutk = document.cookie
              .split(" ")
              .find((c) => c.startsWith("hubspotutk"))!
              .split("=")[1];
            if (_hubspotutk.endsWith(";")) {
              _hubspotutk = _hubspotutk.split(";")[0];
            }
            hubspotutk = _hubspotutk;
          } catch (e) {}

          await request({
            service: "self",
            method: "post",
            url: `${HUBSPOT_SERVICE_URL}/addpaymentmethod`,
            body: {
              email: User.email,
              hubspotutk,
              environment: IS_DEPLOYMENT_ENV_TEST ? "test" : "production",
            },
          });
        } catch (e) {
          console.error(
            "Failed to set has payment method in hubspot",
            e.message,
            JSON.stringify(e.response?.data || {})
          );
        }

        if (BillingContext.isTrialAccount) {
          await request({
            service: "billing-ng",
            url: `/trial_accounts/${AccountUUID}/upgrade`,
            method: "post",
            body: { accountId: AccountUUID },
          });
          notification.success({ message: "Success", description: "Upgraded to paying account" });
          await BillingContext.setAccount(AccountUUID);
        }

        if (isFirstPaymentMethod) {
          navigate(`/billing/account/${AccountUUID}`);
        } else {
          navigate(`/billing/account/${AccountUUID}/payment-methods`);
        }
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setAddError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  function onCardChange(e: StripeCardElementChangeEvent) {
    setCardComplete(e.complete);
    setCardError(e.error);
  }

  return (
    <>
      <div className="mr-4 mb-4">
        <FormLabel parent>Payment Method</FormLabel>
      </div>
      {clientSecretError ? (
        <div>
          <div className="color-danger my-2 py-2 rounded">{clientSecretError}</div>
          <NGButton variant={"primary"} onClick={reset}>
            Try Again
          </NGButton>
        </div>
      ) : addError ? (
        <div>
          <div className="color-danger my-2 rounded">An error has occurred processing your payment method:</div>
          <div className="color-danger my-2 rounded">{addError}</div>
          <NGButton variant={"primary"} onClick={reset}>
            Try Again
          </NGButton>
        </div>
      ) : (
        <>
          <div style={{ width: 450 }} className="border px-4 py-2">
            <CardElement options={get_CARD_ELEMENT_OPTIONS(theme === "dark")} onChange={onCardChange} />
          </div>
          {PaymentMethods.length > 0 ? (
            <div className="mt-4 flex" style={{ width: 550 }}>
              <NGCheckbox
                id={"make-default"}
                className="mr-1"
                aria-label="Set as default payment method"
                checked={isDefault}
                onChange={(value) => setIsDefault(value)}
              />
              <label htmlFor={"make-default"} className=" ">
                Set as default payment method.
              </label>
            </div>
          ) : null}
          <div className="mt-4 flex items-start" style={{ width: 550 }}>
            <NGCheckbox
              aria-label="I agree that by providing my credit card information, I authorize Control Plane Corporation to charge my credit card for the use of services provided by Control Plane Platform. I acknowledge and agree that charges will apply, based on actual usage, and that I am responsible for all applicable charges until I cancel my account or stop running services on the Control Plane Platform."
              checked={consentGiven}
              onChange={(value) => setConsentGiven(value)}
            />
            <span className="cursor-pointer ml-1 -mt-1" onClick={() => setConsentGiven((x) => !x)}>
              I agree that by providing my credit card information, I authorize Control Plane Corporation to charge my
              credit card for the use of services provided by Control Plane Platform. I acknowledge and agree that
              charges will apply, based on actual usage, and that I am responsible for all applicable charges until I
              cancel my account or stop running services on the Control Plane Platform.
            </span>
          </div>
          <div className="flex items-center mt-4">
            {PaymentMethods.length > 0 ? (
              <NGButton
                className="mr-4"
                variant={"danger"}
                outlined
                onClick={() => navigate(`/billing/account/${AccountUUID}/payment-methods`)}
                disabled={isLoading}
              >
                Cancel
              </NGButton>
            ) : null}
            <NGButton variant={"action"} onClick={onConfirm} disabled={isLoading || !canCreate} loading={isLoading}>
              Confirm
            </NGButton>
          </div>
          {IS_DEPLOYMENT_ENV_TEST ? (
            <>
              <div className="mt-4">Test Europe 3D Secure: 4000 0025 0000 3155</div>
              <div className="mt-4">Test America: 4242 4242 4242 4242</div>
              <div className="mt-4">Failing CVC: 4000 0000 0000 0101</div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export const PaymentMethodAdd = observer(PaymentMethodAddRaw);
