import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { nameOfKind, pluralNameOfKind } from "../../../services/cpln";
import { kindMetadata } from "../../../services/kindMetadata";
import styles from "./style.module.scss";
import { NGButton } from "../../../newcomponents/button/Button";
import { gvcScopedKinds } from "../../../utils/kinds";

interface Props {
  kind: string;
  canCreate: boolean;
}
export const IncorrectItem: React.FC<Props> = ({ kind, canCreate }) => {
  const { org } = ConsoleContext;
  const { gvc } = ConsoleContext;
  const navigate = useNavigate();

  const Icon: React.ElementType = (kindMetadata as any)[kind].icon;

  return (
    <div className="w-full flex flex-col items-center py-16 border rounded-lg">
      {Icon ? <Icon className={styles.svgIconDefault} /> : null}
      <div className="mt-2 mb-6 text-lg">{nameOfKind(kind)} is not found.</div>
      <div className="flex items-center">
        <NGButton
          onClick={() => {
            const url = gvcScopedKinds.includes(kind) ? `/org/${org}/gvc/${gvc}/${kind}` : `/org/${org}/${kind}`;
            navigate(`/console${url}`);
          }}
          variant={"primary"}
        >
          Go to {pluralNameOfKind(kind)}
        </NGButton>
        {canCreate ? (
          <NGButton
            onClick={() => {
              const url = gvcScopedKinds.includes(kind)
                ? `/org/${org}/gvc/${gvc}/${kind}/-create`
                : `/org/${org}/${kind}/-create`;
              navigate(`/console${url}`);
            }}
            className="ml-4"
            variant={"action"}
          >
            Create a {nameOfKind(kind)}
          </NGButton>
        ) : null}
      </div>
    </div>
  );
};
