import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Events } from "../../components/detail/events";
import { AuditContextMobx } from "../../mst/kinds/auditContext";
import { Info } from "./info";
import { AccessReport } from "../../components/detail/accessReport";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  auditContext: AuditContextMobx;
}
const AuditContextDetailRaw: React.FC<Props> = ({ auditContext }) => {
  const basePath = useBasePath("/auditctx/:auditctx/*");

  const eventlogHook = useEventlogs(auditContext.selfLink);

  const [links] = React.useState([
    { label: "Info", url: "-info" },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ]);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info auditContext={auditContext} />} />
        <Route path={`-tags`} element={<Tags link={auditContext.selfLink} tags={auditContext.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"auditctx"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="auditctx" resourceId={auditContext.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={auditContext.selfLink} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const AuditContextDetail = observer(AuditContextDetailRaw);
