import * as React from "react";
import styles from "./../trial.module.scss";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { MARKETING_URL, STORAGE_KEY_TRIAL_ACTIVE_TAB } from "../../../../../envVariables";
import { User } from "../../../../../mobxStores/user/user";
import { observer } from "mobx-react-lite";
import { UserData } from "../../../../../mobxStores/userData/userData";
import { AlertCircle } from "react-feather";
import { MarketingSide } from "./../marketing-side/marketing-side";
import { UIData } from "../../../../../mobxStores/uiData/uiData";

const TrialSignInRaw: React.FC = () => {
  const [hasWorkEmailError, setHasWorkEmailError] = React.useState(false);

  React.useEffect(() => {
    UserData.setHereForTrial(true);
  }, []);

  React.useEffect(() => {
    const NOT_WORK = "not-work";
    const val = localStorage.getItem("trial-info");
    localStorage.removeItem("trial-info");
    setHasWorkEmailError(val === NOT_WORK);
  }, []);

  async function onLoginWithProvider(provider: "google" | "github" | "microsoft") {
    try {
      localStorage.setItem(STORAGE_KEY_TRIAL_ACTIVE_TAB, UIData.tabId);
      await User.signInByProvider(provider);
    } catch (e) {
      notification.info({ message: "Failed", description: e.message });
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <a className={styles.logo} href={`${MARKETING_URL}`} target={"_blank"}>
          <img src={`/resources/logos/controlPlaneLogo.svg`} alt={"Control Plane Logo"} />
        </a>
        <div className={styles.already}>
          <div>Don't have an account?</div>
          <Link to={"/trial"}>Sign Up</Link>
          <span className={styles.divider} />
          <a href={`mailto:support@controlplane.com`} className={styles.support}>
            Support
          </a>
        </div>
      </div>
      <div className={styles.subContainer}>
        <MarketingSide />
        <div className={styles.contentRight}>
          <div className={styles.formParent}>
            {hasWorkEmailError ? (
              <div className={styles.workEmailError}>
                <AlertCircle />
                <div>Please use a work email to sign in to Control Plane Trial</div>
              </div>
            ) : null}
            <div className={styles.header_one}>{"Sign In to Control Plane Trial"}</div>
            <div className={styles.providers}>
              <div className={styles.provider} onClick={() => onLoginWithProvider("google")}>
                <div>
                  <img src="/resources/logos/login/google.svg" />
                </div>
                <div>Google</div>
              </div>
              <div className={styles.provider} onClick={() => onLoginWithProvider("github")}>
                <div>
                  <img style={{ stroke: "red" }} src="/resources/logos/login/github-black.svg" />
                </div>
                <div>Github</div>
              </div>
              <div className={styles.provider} onClick={() => onLoginWithProvider("microsoft")}>
                <div>
                  <img src="/resources/logos/login/microsoft.svg" />
                </div>
                <div>Microsoft</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TrialSignIn = observer(TrialSignInRaw);
