export const lightThemeColors = [
  "#e6194b",
  "#3cb44b",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#f032e6",
  "#008080",
  "#9a6324",
  "#800000",
  "#808000",
  "#000075",
  "#808080",
  "#000000",
];

export const darkThemeColors = [
  "#ff0751",
  "#3cb44b", // ok
  "#4363d8",
  "#f58231",
  "#ffff00",
  "#f032e6",
  "#008080",
  "#9a6324",
  "#800000",
  "#808000",
  "#000075",
  "#808080",
  "#ffffff",
];
