import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AgentMobx, AgentModel } from "../../mst/kinds/agent";
import { AgentDetail } from "./detail";
import { request, resourceLink } from "../../services/cpln";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { AGENT_UPDATE_INTERVAL_MS, IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { Agent } from "../../schema/types/agent";
import { captureExc } from "../../errorBoundary";

type RouteParams = "agent";
const AgentDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { agent: agentName } = useParams<RouteParams>();
  const [agentMobx, setAgentMobx] = React.useState<AgentMobx>(null as any);
  const [agent, setAgent] = React.useState<Agent>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [agentName]);

  React.useEffect(() => {
    if (!agentMobx) {
      return;
    }
    let intervalId = setInterval(() => {
      agentMobx?.fetchInfo(true).then((isFound) => {
        if (!isFound) {
          setIsIncorrect(true);
          setAgentMobx(null as any);
        }
      });
    }, AGENT_UPDATE_INTERVAL_MS);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [agentMobx]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("agent", agentName) });
      setAgent(data);
      setAgentMobx(AgentModel.create(data));
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching agent to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"agent"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {agentMobx.name} - Agent - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: agentMobx, fetchItem }}>
        <NGFormContext.Provider value={formDataRef.current}>
          <AgentDetail agent={agent} agentMobx={agentMobx} />
        </NGFormContext.Provider>
      </DetailContext.Provider>
    </>
  );
};

export const AgentDetailRoute = observer(AgentDetailRouteRaw);
