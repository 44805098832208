import * as React from "react";
import { notification } from "antd";
import { DomainMobx, DomainStatus, DomainStatusEndpoint, DomainStatusLocation } from "../../mst/kinds/domain";
import { observer } from "mobx-react-lite";
import { FormElement } from "../../components/forms/formElement";
import { CircleSpinner } from "../../components/generic/circle-spinner";
import { nameOfDomainStatus, tagLinkUrlPrefixes } from "../../services/utils";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Copy, ExternalLink } from "react-feather";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { useDetailContext } from "../../components/detail/detailContext";
import clsx from "clsx";
import { NGLabel } from "../../newcomponents/text/label";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import NGAlert from "../../newcomponents/alert";

interface Props {
  status: DomainStatus | null;
  isLoadingStatus: boolean;
}
const InfoRaw: React.FC<Props> = ({ status, isLoadingStatus }) => {
  const { item } = useDetailContext();
  const domain = item as DomainMobx;

  function handleCopyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({ message: "Copied to clipboard" });
  }

  let { endpoints, locations }: { endpoints: DomainStatusEndpoint[]; locations: DomainStatusLocation[] } =
    status || ({} as any);
  if (!endpoints) {
    endpoints = [];
  }
  if (!locations) {
    locations = [];
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <div className="w-4/12">
          <FormElement label={domain.isApex ? "Apex Domain" : "Sub-Domain"} value={domain.name} />
        </div>
        {domain.name === domain.description ? null : (
          <div className="w-4/12">
            <FormElement label={"Description"} value={domain.description} />
          </div>
        )}
      </div>

      <div className="flex relative mb-4">
        <div>
          <NGLabel>Status</NGLabel>
          <div className={`${status?.status === "warning" ? "color-warning" : ""}`}>
            {nameOfDomainStatus(status as any)}
          </div>
        </div>
        <div className="absolute" style={{ left: 50 }}>
          {isLoadingStatus ? <CircleSpinner /> : null}
        </div>
      </div>
      {status?.warning ? <NGAlert className="mb-4" type={"warning"} message={status.warning} /> : null}
      {locations.length < 1 ? null : (
        <div className="mb-6">
          <NGLabel>Location Statuses</NGLabel>
          <div className="mt-2 flex table-labels px-4 py-1 border">
            <div className="w-4/12">Location</div>
            <div className="w-8/12">Status</div>
          </div>
          {locations.map((l) => (
            <div key={l.name} className="flex items-center px-4 py-1 border border-t-0">
              <div className="w-4/12">{l.name}</div>
              <div className={clsx("w-8/12", { "color-action": l.certificateStatus === "ready" })}>
                {nameOfDomainStatus({ status: l.certificateStatus } as any)}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="mb-6">
        <NGLabel>Endpoints</NGLabel>
        {endpoints.length < 1 ? (
          <div>No endpoints exist</div>
        ) : (
          <>
            <div className="mt-2 flex table-labels px-4 py-1 border">
              <div className="w-4/12">Workload</div>
              <div className="w-3/12">GVC</div>
              <div className="w-5/12">Endpoint</div>
            </div>
            {endpoints.map((e) => {
              const workloadGVC = e.workloadLink.split("/workload/")[0].split("/gvc/")[1];
              const workloadName = e.workloadLink.split("/workload/")[1];
              const isCurrent = ConsoleContext.gvc === workloadGVC;

              return (
                <div key={uuidv4()} className="flex items-center px-4 py-1 border border-t-0">
                  <div className="w-4/12">
                    {isCurrent ? (
                      <Link className="color-link font-semibold" to={`/console${e.workloadLink}`}>
                        {workloadName}
                      </Link>
                    ) : (
                      workloadName
                    )}
                  </div>
                  <div className="w-3/12">
                    {workloadGVC}
                    {isCurrent ? ` (Current)` : ""}
                  </div>
                  <div className="w-5/12">
                    <a className="ngfocus color-link" href={e.url} target={"_blank"}>
                      {e.url}
                      <ExternalLink
                        className="inline-block feather-icon ml-1"
                        style={{ transform: "translateY(2px)" }}
                      />
                    </a>
                    <button onClick={() => handleCopyToClipboard(e.url)} className="ml-3 ngfocus color-link-hover">
                      <Copy className="feather-icon" style={{ transform: "translateY(2px)" }} />
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <TagLinksTable
        tableId="domain-info-tag-links"
        tags={Object.entries(domain.tags || {})
          .map(([tagKey, tagValue]) => ({ key: tagKey, value: String(tagValue || "") }))
          .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />
    </>
  );
};

export const Info = observer(InfoRaw);
