import * as React from "react";
import { StringMobx } from "../../../../mobxDataModels/stringModel";
import { NGCheckbox } from "../../../../newcomponents/checkbox";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { observer } from "mobx-react-lite";
import { MARKETING_URL } from "../../../../envVariables";

interface Props {
  accountName: StringMobx;
  fullName: StringMobx;
  company: StringMobx;
  jobTitle: StringMobx;
  linkedin: StringMobx;
  phone: StringMobx;
  acceptedTerms: boolean;
  setAcceptedTerms: React.Dispatch<React.SetStateAction<boolean>>;
}
const AccountCreateInfoRaw: React.FC<Props> = ({
  fullName,
  accountName,
  company,
  jobTitle,
  linkedin,
  phone,
  acceptedTerms,
  setAcceptedTerms,
}) => {
  return (
    <div className="account-create-info">
      <NGFormElement
        name="fullName"
        label={fullName.label}
        value={fullName.value}
        onChange={fullName.setValue}
        required={fullName.isRequired}
        error={fullName.error}
      />
      <NGFormElement
        name="accountName"
        label={accountName.label}
        value={accountName.value}
        onChange={accountName.setValue}
      />
      <NGFormElement
        name="company"
        label={company.label}
        value={company.value}
        onChange={company.setValue}
        required={company.isRequired}
        error={company.error}
      />
      <NGFormElement
        name="jobTitle"
        label={jobTitle.label}
        value={jobTitle.value}
        onChange={jobTitle.setValue}
        required={jobTitle.isRequired}
        error={jobTitle.error}
      />
      <NGFormElement
        name="linkedin"
        label={linkedin.label}
        value={linkedin.value}
        onChange={linkedin.setValue}
        required={linkedin.isRequired}
        error={linkedin.error}
      />
      <NGFormElement
        name="phone"
        label={phone.label}
        value={phone.value}
        onChange={phone.setValue}
        required={phone.isRequired}
        error={phone.error}
      />
      <div className="flex items-center">
        <NGCheckbox
          id={"terms-of-use"}
          aria-label={"I accept the Control Plane Terms of Use"}
          data-testid="switch-capacity-ai"
          checked={acceptedTerms}
          onChange={(value) => setAcceptedTerms(value)}
        />
        <div className="ml-1">
          <label className="cursor-pointer" htmlFor={`terms-of-use`}>
            I accept the Control Plane{" "}
          </label>
          <a
            className={`underline hover:underline focus:outline-none focus-visible:underline focus-visible:ring-2 color-link`}
            target={"_blank"}
            href={`${MARKETING_URL}/terms-of-use`}
          >
            Terms of Use
          </a>
        </div>
      </div>
    </div>
  );
};

export const AccountCreateInfo = observer(AccountCreateInfoRaw);
