import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, useNavigate } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./imageInfo";
import { AccessReport } from "../../components/detail/accessReport";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { Image } from "./../../schema/types/image";
import { linksOf } from "../../services/cpln";
import { Tags } from "../../components/detail/tags";

interface Props {
  image: Image;
}
const ImageDetailRaw: React.FC<Props> = ({ image }) => {
  const basePath = useBasePath("/image/*");
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(`${image.repository}:${image.tag}/-info`, { replace: true });
  }, []);

  const [links] = React.useState([
    { label: "Info", url: `${image.repository}:${image.tag}/-info` },
    { label: "Tags", url: `${image.repository}:${image.tag}/-tags` },
    { label: "Audit Trail", url: `${image.repository}:${image.tag}/-audittrail` },
    { label: "Access Report", url: `${image.repository}:${image.tag}/-accessreport` },
  ]);

  return (
    <>
      <BasePathContext.Provider value={basePath}>
        <DetailLayout links={links}>
          <Route path={`${image.repository}:${image.tag}/-info`} element={<Info image={image} />} />
          <Route
            path={`${image.repository}:${image.tag}/-tags`}
            element={<Tags link={linksOf(image).self!} tags={image.tags} />}
          />
          <Route
            path={`${image.repository}:${image.tag}/-audittrail`}
            element={<AuditTrail kind="image" resourceId={image.id} />}
          />
          <Route
            path={`${image.repository}:${image.tag}/-accessreport`}
            element={<AccessReport itemLink={linksOf(image).self!} />}
          />
        </DetailLayout>
      </BasePathContext.Provider>
    </>
  );
};

export const ImageDetail = observer(ImageDetailRaw);
