import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderDigitalOcean } from "./digitalocean";
import { Mk8sCreateProviderDigitalOceanNodePools } from "./nodePools";
import { Mk8sCreateProviderDigitalOceanNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderDigitalOceanNodePool } from "./nodePool";
import { Mk8sCreateProviderDigitalOceanAdvanced } from "./advanced";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderDigitalOceanRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderDigitalOcean mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderDigitalOceanNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderDigitalOceanNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderDigitalOceanNodePool mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<Mk8sCreateProviderDigitalOceanAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderDigitalOceanRoute = observer(Mk8sCreateProviderDigitalOceanRouteRaw);
