import * as React from "react";
import { observer } from "mobx-react-lite";
import { StringModel, StringMobx } from "../../mobxDataModels/stringModel";
import { NameModel } from "../../mobxDataModels/nameModel";
import { notification, Modal } from "antd";
import { homeLink, nameOfKind, parentLink, request, resourceLink } from "../../services/cpln";
import { clearItem, randomName, updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { useNavigate } from "react-router-dom";
import { NGButton } from "../../newcomponents/button/Button";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { Workload } from "../../schema/types/workload/workload";
import { VolumeSet } from "../../schema/types/volumeSet";
import { IpSet } from "../../schema/types/ipset";
import { ngParseLink } from "../../utils/linkParser/linkParser";

interface Props {
  kind: string;
  link: string;
  name: string;
  onClose: () => void;
  clone: (item: any) => Promise<void>;
  nameGenerator?: () => string;
  ipsetName?: string;
}

enum ActiveModal {
  CLONE,
  CLONE_WITH_ITEMS,
}

const CloneModalRaw: React.FC<Props> = ({ kind, link, name, clone, onClose, nameGenerator = randomName }) => {
  const nameRef = React.useRef<StringMobx>(
    NameModel.create({
      value: kind === "policy" ? `${name}-copy--${nameGenerator()}` : "",
      validationKey: kind === "workload" ? "workloadName" : "name",
    }),
  );
  const descriptionRef = React.useRef<StringMobx>(StringModel.create({ label: "Description" }));

  const [isLoading, setIsLoading] = React.useState(false);
  const [activeModal, setActiveModal] = React.useState<ActiveModal>(ActiveModal.CLONE);
  const [itemToClone, setItemToClone] = React.useState([]);

  const [volumeSets, setVolumeSets] = React.useState<string[]>([]);
  const [ipset, setIpset] = React.useState<string>("");
  const [warnings, setWarnings] = React.useState<string[]>([]);

  const navigate = useNavigate();
  const pluralSuffix = volumeSets.length > 1 ? "s" : "";

  async function tryConfirmClone() {
    try {
      setIsLoading(true);
      if (!nameRef.current.isValid) return;

      let _warnings: string[] = [];

      let _itemToClone: any;
      if (kind === "secret") {
        const { data: dataSecret } = await request({ url: link + "/-reveal" });
        _itemToClone = dataSecret;
      } else {
        const { data } = await request({ url: link });
        _itemToClone = data;
      }

      setItemToClone(_itemToClone);

      // override description
      if (descriptionRef.current.value) {
        _itemToClone.description = descriptionRef.current.value;
      } else {
        _itemToClone.description = nameRef.current.value;
      }

      // override name
      _itemToClone.name = nameRef.current.value;

      clearItem(_itemToClone);

      if (_itemToClone.kind === "workload") {
        const workload = _itemToClone as Workload;

        let _volumesets: string[] = [];
        let _ipset = "";

        if (workload.spec?.type === "stateful") {
          for (const container of workload.spec.containers) {
            for (const volume of container.volumes || []) {
              if (volume.uri.includes("cpln://volumeset/")) {
                try {
                  const volumeset = volume.uri.split("cpln://volumeset/")[1];
                  try {
                    // fetch volumeset and check if it's a shared volumeset
                    const { data: volumesetData } = await request<VolumeSet>({
                      url: resourceLink("volumeset", volumeset),
                    });
                    if (volumesetData.spec.fileSystemType === "shared") {
                      continue;
                    }
                  } catch (e) {
                    // show warning that we couldn't access the volumeset
                    _warnings.push(
                      `Could not fetch the volume set "${volumeset}", if it is not with "shared" file system type, you might need to create it manually.`,
                    );
                  }
                  _volumesets.push(volumeset);
                } catch (e) {
                  // not a valid volumeset reference
                }
              }
            }
          }
        }

        if (workload.spec.loadBalancer?.direct?.ipSet) {
          _ipset = workload.spec.loadBalancer?.direct?.ipSet;
        }

        setVolumeSets(_volumesets);
        setIpset(_ipset);
        setWarnings(_warnings);
        setIsLoading(false);
        if (_volumesets.length > 0 || _ipset) {
          setActiveModal(ActiveModal.CLONE_WITH_ITEMS);
          return;
        }
      }

      await confirmClone(_itemToClone);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function cloneVolumesetsAndModifyWorkload(workload: Workload) {
    let volumesetMap: { [_: string]: string } = {};
    for (const volumeset of volumeSets) {
      volumesetMap[volumeset] = `${volumeset}-${workload.name}-${randomName()}`;
    }

    for (const volumeset of volumeSets) {
      const { data } = await request({ url: resourceLink("volumeset", volumeset) });
      const body: VolumeSet = data;
      body.name = volumesetMap[volumeset];
      await request({ method: "post", body: body, url: parentLink("volumeset") });
    }

    // TODO changes because of type
    for (const container of workload.spec?.containers || []) {
      for (const volume of container.volumes || []) {
        const volumeset = volume.uri.split("cpln://volumeset/")[1];
        const newVolumeset = volumesetMap[volumeset];
        if (newVolumeset) {
          volume.uri = `cpln://volumeset/${newVolumeset}`;
        }
      }
    }
  }

  async function cloneIpsetAndModifyWorkload(workload: Workload) {
    const { name: ipsetName } = ngParseLink(ipset, { useInputCtx: true });
    const { data } = await request({ url: ipset });
    const body: IpSet = data;
    const newIpsetName = `${ipsetName}-${workload.name}-${randomName()}`;
    body.name = newIpsetName;
    body.spec.link = resourceLink("workload", nameRef.current.value);
    await request({ method: "post", body: body, url: homeLink("ipset") });
    const ipsetLink = resourceLink("ipset", newIpsetName);

    if (workload.spec.loadBalancer?.direct) {
      workload.spec.loadBalancer.direct.ipSet = ipsetLink;
    }
  }

  async function confirmClone(itemToClone: any, withItems: boolean = false) {
    try {
      setIsLoading(true);

      if (withItems) {
        if (volumeSets.length > 0) {
          await cloneVolumesetsAndModifyWorkload(itemToClone);
        }
        if (ipset) {
          await cloneIpsetAndModifyWorkload(itemToClone);
        }
      }

      await clone(itemToClone);

      notification.success({
        message: "Success",
        description: `Cloned ${nameOfKind(kind)}`,
      });
      if (kind === "workload") {
        updateLastDeploymentTimeOnHubspot();
      }
      onClose();
      setIsLoading(false);

      navigate(`/console${parentLink(kind as any)}`);
      setTimeout(() => {
        navigate(`/console${resourceLink(kind as any, itemToClone.name)}`);
      }, 200);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  return (
    <>
      {activeModal == ActiveModal.CLONE ? (
        <Modal
          open={true}
          closable={false}
          onCancel={onClose}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={onClose} disabled={isLoading}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                data-testid="clone-modal"
                onClick={tryConfirmClone}
                loading={isLoading}
                disabled={isLoading || !nameRef.current.isValid}
              >
                Clone
              </NGButton>
            </div>
          }
          maskClosable={!isLoading}
          destroyOnClose
        >
          <div className="flex flex-col" style={{ padding: "16px 8px 0" }}>
            <NGFormElement
              required
              name="name"
              label={nameRef.current.label}
              value={nameRef.current.value}
              onChange={nameRef.current.setValue}
            />
            <NGFormElement
              name="description"
              label={descriptionRef.current.label}
              value={descriptionRef.current.value}
              onChange={descriptionRef.current.setValue}
            />
          </div>
        </Modal>
      ) : null}
      {activeModal == ActiveModal.CLONE_WITH_ITEMS ? (
        <Modal
          title="Clone Workload with Items"
          open={true}
          maskClosable={!isLoading}
          width={650}
          onCancel={() => onClose()}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => onClose()}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                loading={isLoading}
                disabled={isLoading}
                onClick={() => confirmClone(itemToClone, true)}
              >
                Confirm
              </NGButton>
            </div>
          }
        >
          <div className="flex flex-col gap-2">
            {volumeSets.length > 0 ? (
              <>
                <p>
                  This workload is associated with{" "}
                  <b>{volumeSets.length > 1 ? `${volumeSets.length} volume sets` : "a single volume set"}</b>.
                </p>
                <p>
                  Cloning the volume set{pluralSuffix} will replicate only the specifications of the original volume(s);
                  the actual data within any volume instances will <b>NOT</b> be copied.
                </p>
              </>
            ) : null}
            {ipset ? (
              <p>
                This workload is associated with <b>an ip set</b>. A new ipset will be created and will be associated
                with the workload clone.
              </p>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export const CloneModal = observer(CloneModalRaw);
