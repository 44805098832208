import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { WorkloadList } from "./list";
import { Layout } from "../../layout";
import { WorkloadDetailRoute } from "./detailRoute";
import { observer } from "mobx-react-lite";
import { WorkloadCreateRoute } from "./create/createRoute";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

//// Updates
// Workload List
//  each item fetches deployments separately for health
// Workload Detail Route
//  fetches deployments for deployment table, workload health, deployment nav item icon
// Workload Summary
//  fetches workload for updating endpoing

const WorkloadRoute: React.FC = () => {
  const { org, gvc } = ConsoleContext;

  return (
    <Layout key={org! + gvc!}>
      <Routes>
        <Route index element={<WorkloadList />} />
        <Route path={`-create/*`} element={<WorkloadCreateRoute />} />
        <Route path={`:workload/*`} element={<WorkloadDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(WorkloadRoute);
