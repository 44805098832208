import { RangePickerPresets } from "../../components/antd/RangePicker";
import { Audit } from "./types";
import moment from "moment-timezone";

export function getAuditSubject(
  audit: Audit
): { value: string; type: "user" | "serviceaccount" | "other"; link?: string } {
  if (!audit.subject) {
    return { value: "", type: "other" };
  }
  try {
    if (audit.subject.name || audit.subject.data?.principalLink) {
      let name = "";
      if (audit.subject.data?.principalLink) {
        name = audit.subject.data.principalLink.split("/")[4];
      } else if (audit.subject.name) {
        name = audit.subject.name;
      }
      let isServiceAccount = audit.subject.data?.type === "serviceaccount";
      if (isServiceAccount) {
        return { value: name, type: "serviceaccount", link: audit.subject.data?.principalLink };
      }
      let isUser = audit.subject.data?.type === "user";
      if (isUser) {
        return { value: name, type: "user", link: audit.subject.data.principalLink };
      }
      return { value: name, type: "other" };
    }
    return { value: audit.subject.email || "", type: "other" };
  } catch (e) {
    return { value: audit.subject.name || audit.subject.email || "", type: "other" };
  }
}

export function getLogsPresets(): RangePickerPresets {
  let base = moment();

  return [
    { label: "Last 5 Minutes", value: [base.clone().add(-5, "minute"), null] },
    { label: "Last 15 Minutes", value: [base.clone().add(-15, "minute"), null] },
    { label: "Last 30 Minutes", value: [base.clone().add(-30, "minute"), null] },
    { label: "Last 1 Hour", value: [base.clone().add(-1, "hour"), null] },
    { label: "Last 3 Hours", value: [base.clone().add(-3, "hour"), null] },
    { label: "Last 6 Hours", value: [base.clone().add(-6, "hour"), null] },
    { label: "Last 12 Hours", value: [base.clone().add(-12, "hour"), null] },
    { label: "Last 24 Hours", value: [base.clone().add(-24, "hour"), null] },
    { label: "Last 2 Days", value: [base.clone().add(-2, "day"), null] },
    { label: "Last 7 Days", value: [base.clone().add(-7, "day"), null] },
    { label: "Last 30 Days", value: [base.clone().add(-30, "day"), null] },
  ];
}

export function getLogsPresetsForGrafana() {
  let info = [
    { presetValue: "5m", minuteDiff: 5, hourDiff: 0, dayDiff: 0 },
    { presetValue: "15m", minuteDiff: 15, hourDiff: 0, dayDiff: 0 },
    { presetValue: "30m", minuteDiff: 30, hourDiff: 0, dayDiff: 0 },
    { presetValue: "1h", minuteDiff: 60, hourDiff: 1, dayDiff: 0 },
    { presetValue: "3h", minuteDiff: 180, hourDiff: 3, dayDiff: 0 },
    { presetValue: "6h", minuteDiff: 360, hourDiff: 6, dayDiff: 0 },
    { presetValue: "12h", minuteDiff: 720, hourDiff: 12, dayDiff: 0 },
    { presetValue: "24h", minuteDiff: 1440, hourDiff: 24, dayDiff: 1 },
    { presetValue: "2d", minuteDiff: 2880, hourDiff: 48, dayDiff: 2 },
    { presetValue: "7d", minuteDiff: 10080, hourDiff: 168, dayDiff: 7 },
    { presetValue: "30d", minuteDiff: 43200, hourDiff: 720, dayDiff: 30 },
  ];

  return getLogsPresets().map((p, idx) => ({ ...p, ...info[idx] }));
}

export function getAuditTrailPresets(): RangePickerPresets {
  let base = moment();

  return [
    { label: "Last 1 Hour", value: [base.clone().add(-60, "minute"), null] },
    { label: "Last 1 Day", value: [base.clone().add(-24, "hour"), null] },
    { label: "Last 1 Week", value: [base.clone().add(-7, "day"), null] },
    { label: "Last 2 Weeks", value: [base.clone().add(-14, "day"), null] },
    { label: "Last 1 Month", value: [base.clone().add(-1, "month"), null] },
    { label: "Last 3 Months", value: [base.clone().add(-3, "month"), null] },
  ];
}

export function getDefaultAuditTrailTime(): string {
  return moment().startOf("day").subtract(6, "day").toISOString();
}
