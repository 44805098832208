import * as React from "react";
import { Table } from "../../../../newcomponents/table/table";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { AgentLastActiveTextColumn } from "../../../../newcomponents/table/columns/agent/lastActiveText";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { AgentTableItem as AgentTableItemBase, agentHelpers } from "../../../../mst/kinds/agent.helpers";
import { linksOf } from "../../../../services/cpln";
import { Agent } from "../../../../schema/types/agent";
import { useAgentInfosReducer } from "../../../../components/detail/agentInfosReducer";

interface AgentTableItem extends AgentTableItemBase {
  selfLink: string;
}

interface Props {
  title: string;
  selections: string[];
  onSelectionsChange: (values: string[]) => void;
}
export const AgentTable: React.FC<Props> = ({ title, selections, onSelectionsChange }) => {
  const { state, fetchAgentInfos } = useAgentInfosReducer();

  const { kind, id } = useTableKindId("agent", "policy-create-target-list-agent");
  const qData = useTableItemQueryData<Agent>(kind, { fetchAllPages: true });

  React.useEffect(() => {
    const unsubscribe = fetchAgentInfos(qData.visibleItems.map((i) => i.name));
    return () => {
      unsubscribe();
    };
  }, [qData.visibleItems.length]);

  const agentTableItems: AgentTableItem[] = qData.visibleItems.map((agent) => {
    const agentInfo = state.agentInfoMap[agent.name];
    const isActive = agentHelpers.getIsActive(agent, agentInfo);
    const lastActiveText = agentHelpers.getLastActiveText(agent, agentInfo);
    const selfLink = linksOf(agent).self!;

    return {
      ...agent,
      isActive: isActive,
      lastActiveText: lastActiveText,
      selfLink: selfLink,
    };
  });

  return (
    <Table<AgentTableItem>
      title={title}
      data={agentTableItems}
      tableId={id}
      selectMode="multi"
      selectKey={"selfLink"}
      selections={selections}
      onSelectionsChange={onSelectionsChange}
      headerRenderer={() => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
            <CustomTableCPLNQuery
              tableId={id}
              kind={kind}
              onQuery={qData.setQuery}
              isLoading={qData.isLoadingQueried}
            />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionNoLinkColumn(),
        AgentLastActiveTextColumn(),
        TagsColumn(),
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};
