import { types } from "mobx-state-tree";
import { AutoscalerModel } from "./autoscaler";
import { TaintModel } from "./common";
import { NetworkingModel } from "./networking";
import { PreInstallScriptProperty } from "./preInstallScript";

const DigitalOceanPoolModel = types.model("DigitalOcean Node Pool", {
  name: types.string,
  labels: types.maybe(types.frozen()),
  taints: types.array(TaintModel),
  dropletSize: types.string,
  overrideImage: types.optional(types.string, ""),
  minSize: types.number,
  maxSize: types.number,
});

export const DigitalOceanProviderModel = types.model("DigitalOcean Provider", {
  region: types.string,
  digitalOceanTags: types.array(types.string), // /^[a-zA-Z0-9:_-]+$/ max 254
  tokenSecretLink: types.string,
  vpcId: types.string,
  nodePools: types.array(DigitalOceanPoolModel),
  image: types.string,
  sshKeys: types.array(types.string),
  extraSshKeys: types.array(types.string),
  reservedIPs: types.array(types.string), // min 1 item

  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
  networking: types.optional(NetworkingModel, () => NetworkingModel.create()),
  preInstallScript: PreInstallScriptProperty,
});
