import { DateString } from "../../../../components/dateString";
import { formatStrings } from "../../../../utils/time";
import { TableColumn } from "../../types";

const lastModifiedDefault = { id: "lastModified", label: "Last Modified" };
export function LastModifiedColumn<TData extends { lastModified: string }>(
  column: TableColumn<TData> = lastModifiedDefault,
) {
  const preset: TableColumn<TData> = {
    ...lastModifiedDefault,

    size: 180,
    enableHide: true,
    enableSort: true,
    sortingFn: "cplnDate",
    cell: ({ row }) => {
      const item = row.original;
      if (!item.lastModified) {
        return <span />;
      }
      return <DateString iso={row.original.lastModified} format={formatStrings.humanizeWAgo} />;
    },
  };
  return Object.assign(preset, column);
}
