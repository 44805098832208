import React from "react";
import { observer } from "mobx-react-lite";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { MarketplaceTemplateList } from "./template/templateList";
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "../../layout";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { MarketplaceTemplateInstall } from "./template/templateInstall";
import { MarketplaceReleaseList } from "./release/list";
import { MarketplaceReleaseDetail } from "./release/detail";
import { MarketplaceTemplateDetail } from "./template/templateDetail";

const MarketplaceRoute: React.FC = () => {
  const basePath = useBasePath("/marketplace/*");

  return (
    <BasePathContext.Provider value={basePath}>
      <Layout key={ConsoleContext.org!}>
        <div className="marketplace">
          <Routes>
            <Route index element={<Navigate to={`release`} />} />
            <Route path={`template`} element={<MarketplaceTemplateList />} />
            <Route path={`template/:template`} element={<MarketplaceTemplateDetail />} />
            <Route path={`template/:template/install`} element={<MarketplaceTemplateInstall />} />
            <Route path={`release`} element={<MarketplaceReleaseList />} />
            <Route path={`release/:release/*`} element={<MarketplaceReleaseDetail />} />
          </Routes>
        </div>
      </Layout>
    </BasePathContext.Provider>
  );
};

export default observer(MarketplaceRoute);
