import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { DryRunAlert } from "../../../dryRunAlert";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGSelect } from "../../../../../newcomponents/select/ngselect";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { getDryRunErrorText } from "../../../getDryRunErrorText";

type RouteParams = "index";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderEphemeralNodePoolRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const formData = useNGFormContext();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const e = mk8sDraft.provider_ephemeral;
  const np = e.nodePools.find((np) => np.index === index);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `ephemeral.nodePools[${index}].`;

  const labelsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.ephemeral.nodePools[${index}].labels`,
    paths: np.labels.items.map((i) => `spec.provider.ephemeral.nodePools[${index}].labels.${i.firstValue}`),
  });
  const taintsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.ephemeral.nodePools[${index}].taints`,
    paths: [
      ...np.taints.items.map((_, idx) => `spec.provider.ephemeral.nodePools[${index}].taints[${idx}]`),
      ...np.taints.items.map((_, idx) => `spec.provider.ephemeral.nodePools[${index}].taints[${idx}].key`),
      ...np.taints.items.map((_, idx) => `spec.provider.ephemeral.nodePools[${index}].taints[${idx}].value`),
      ...np.taints.items.map((_, idx) => `spec.provider.ephemeral.nodePools[${index}].taints[${idx}].effect`),
    ],
  });

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!e.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}count`}
            label={np.count.label}
            value={np.count.value}
            onChange={np.count.setValue}
            required={np.count.isRequired}
            error={np.count.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.count.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].count`}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}arch`}
            as={"select"}
            label={np.arch.label}
            options={np.arch.options}
            value={np.arch.value}
            required
            onChange={np.arch.setValue}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.arch.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].arch`}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}flavor`}
            as={"select"}
            label={np.flavor.label}
            options={np.flavor.options}
            value={np.flavor.value}
            required
            onChange={np.flavor.setValue}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.flavor.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].flavor`}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}cpu`}
            label={np.cpu.label}
            value={np.cpu.value}
            onChange={np.cpu.setValue}
            required={np.cpu.isRequired}
            error={np.cpu.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.cpu.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].cpu`}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}memory`}
            label={np.memory.label}
            value={np.memory.value}
            onChange={np.memory.setValue}
            required={np.memory.isRequired}
            error={np.memory.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.memory.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].memory`}
            />
          </div>
        </div>
        <NGInputListMst
          data={np.labels}
          className="mb-8"
          label="Labels"
          firstLabel="Key"
          firstInput={(i) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && labelsDryRun.message.includes(`.${i.firstValue}`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondLabel="Value"
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          invalid={labelsDryRun.severity === "error"}
          error={labelsDryRun.severity === "error" ? labelsDryRun.message : ""}
          warning={labelsDryRun.severity === "error" ? "" : labelsDryRun.message}
        />
        <NGInputListMst
          data={np.taints}
          className="mb-8"
          label="Taints"
          firstLabel="Key"
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && taintsDryRun.message.includes(`[${index}].key`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondLabel="Value"
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }
            if (i.secondValue && taintsDryRun.message.includes(`[${index}].value`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          thirdLabel="Effect"
          thirdInput={(i) => {
            let invalid = false;
            if (i.thirdValue && taintsDryRun.message.includes(`[${index}].effect`)) {
              invalid = true;
            }

            return (
              <NGSelect
                invalid={invalid}
                value={i.thirdValue}
                onChange={(value) => i.setThirdValue(value)}
                options={[
                  { label: "NoSchedule", value: "NoSchedule" },
                  { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                  { label: "NoExecute", value: "NoExecute" },
                ]}
              />
            );
          }}
          invalid={taintsDryRun.severity === "error"}
          error={taintsDryRun.severity === "error" ? taintsDryRun.message : ""}
          warning={taintsDryRun.severity === "error" ? "" : taintsDryRun.message}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            e.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
    </>
  );
};

export const Mk8sCreateProviderEphemeralNodePool = observer(Mk8sCreateProviderEphemeralNodePoolRaw);
