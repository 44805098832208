import * as React from "react";
import { jwtDecode } from "jwt-decode";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { getToken, request } from "../../../services/cpln";
import { ChevronDown, ChevronUp, ExternalLink } from "react-feather";
import { CodeEditor } from "./codeEditor";
import { IdentityMatcherLanguage } from "../../../mst/kinds/group";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGSelect } from "../../../newcomponents/select/ngselect";

interface Props {
  expression: string;
  setExpression: (value: string) => void;
  language: IdentityMatcherLanguage;
  setLanguage: (value: IdentityMatcherLanguage) => void;
  beforeTest?: React.ReactNode;
}
const IdentityMatcherRaw: React.FC<Props> = ({ expression, setExpression, language, setLanguage, beforeTest }) => {
  const [showTestUI, setShowTestUI] = React.useState(false);
  const [target, setTarget] = React.useState("");
  const [previousTarget, setPreviousTarget] = React.useState("");
  const [targetError, setTargetError] = React.useState("");
  const [evaluation, setEvaluation] = React.useState<{
    match: boolean;
    value: any;
    error?: string;
    logs?: string;
  } | null>(null);

  function useMyToken() {
    setTargetError("");
    setTarget("");
    setTimeout(() => {
      getToken().then((token) => {
        let _token = token;
        try {
          _token = jwtDecode(token);
          _token = JSON.stringify((_token as any).firebase, null, 2);
        } catch (e) {
          setTargetError("Failed to parse token");
        }
        setTarget(_token);
      });
    }, 100);
  }

  React.useEffect(() => {
    if (!showTestUI) {
      return;
    }
    useMyToken();
    notification.info({ message: "Using current token as target" });
  }, [showTestUI]);

  React.useEffect(() => {
    if (!target) {
      return;
    }
    if (target === previousTarget) {
      return;
    }
    setPreviousTarget(target);
    setTargetError("");
    let isJson = true;
    let _target = target;
    try {
      const obj = JSON.parse(target);
      _target = JSON.stringify(obj, null, 2);
    } catch (e) {
      isJson = false;
    }
    if (!isJson) {
      try {
        let decoded = jwtDecode(target);
        _target = JSON.stringify((decoded as any).firebase, null, 2);
      } catch (e) {
        console.error(e.message);
        setTargetError("Typed value must be a valid json.");
      }
    }
    setTarget(_target);
  }, [target]);

  async function onTest() {
    let _target = target;
    try {
      _target = JSON.parse(target);
    } catch (e) {}
    const { data: evaluationRes } = await request({
      url: "/auth/checkMatchExpression",
      method: "post",
      body: {
        target: _target,
        expression: expression,
        language,
      },
    });
    delete evaluationRes.request;
    setEvaluation(evaluationRes);
  }

  return (
    <div className="flex flex-col">
      <NGLabel className="mb-2">Identity Matcher</NGLabel>
      <div>Executes the expression against the users' claims to decide whether a user belongs to this group.</div>
      <div>This method is useful for managing the grouping of users logged-in with SAML providers.</div>
      <NGLabel className="mt-4">Language</NGLabel>
      <NGSelect
        value={language}
        onChange={setLanguage as any}
        options={[
          { label: "JMESPath", value: "jmespath", disabled: false, isLabel: false },
          { label: "JavaScript", value: "javascript", disabled: false, isLabel: false },
        ]}
        style={{ width: 450 }}
        className={`mb-2`}
      />
      {language === "jmespath" ? (
        <div className="flex items-center">
          Learn more about{" "}
          <a
            className="color-link flex items-center mx-1"
            target={"_blank"}
            referrerPolicy={"no-referrer"}
            href={`https://jmespath.org/`}
          >
            <pre className="mr-1 text-sm">jmespath</pre>
            <ExternalLink style={{ width: 15, height: 15, transform: "translateY(-1px)" }} />
          </a>{" "}
          expression language.
        </div>
      ) : (
        <div className="flex flex-col my-1">
          <div>
            Target object is accessed with <pre className="inline text-sm bg-table-label-drop px-1">$</pre> variable.
          </div>
          <div>Last line is returned as result.</div>
          <div>
            You can use <pre className="inline text-sm bg-table-label-drop px-1">console.log</pre> calls to debug.
          </div>
        </div>
      )}
      <NGLabel className="mb-2 mt-4">Expression</NGLabel>
      <div className="expression-editor">
        <CodeEditor
          value={expression}
          setValue={setExpression}
          language={"javascript"}
          options={{
            basicSetup: { lineNumbers: true, autocompletion: language === "javascript" },
            height: "120px",
            maxHeight: "120px",
          }}
        />
      </div>
      {beforeTest ? beforeTest : null}
      <button
        onClick={() => setShowTestUI((x) => !x)}
        style={{ outline: "none", width: "fit-content" }}
        className="color-link flex items-center mt-4 mb-2 ngfocus"
      >
        Test Expression {showTestUI ? <ChevronUp /> : <ChevronDown />}
      </button>
      {showTestUI ? (
        <>
          <div className="flex gap-4">
            <div className="w-1/2">
              <div className="mb-1 leading-tight">
                Use the editor below which contains your{" "}
                <button className="cursor-pointer color-link" onClick={useMyToken}>
                  current
                </button>{" "}
                token by default to test your expression.
              </div>
              <div className="mb-2 leading-tight">
                Usual javascript truth semantics hold ( null, false, 0, and "" evaluates to false). In addition to
                these, empty objects {`{ }`} and empty arrays [ ] also are considered falsey.
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-1/2">
              <CodeEditor
                value={target}
                setValue={setTarget}
                language={"json"}
                options={{ basicSetup: { lineNumbers: true, autocompletion: false }, minHeight: "400px" }}
              />
            </div>

            <div className="w-1/2">
              {targetError ? (
                <NGAlert className="my-2" style={{ maxWidth: 450 }} message={targetError} type={"info"} />
              ) : (
                <NGButton
                  className="mt-2"
                  variant="primary"
                  style={{ width: 450 }}
                  onClick={onTest}
                  disabled={expression.length < 1 || target.length < 1}
                >
                  Test
                </NGButton>
              )}
              {evaluation ? (
                <>
                  <NGLabel className="mb-2 mt-4">Test Result</NGLabel>
                  <div className="mb-4">
                    <NGLabel className="mb-1">Match</NGLabel>
                    <pre className={evaluation.match ? "text-green" : "text-red"}>{String(evaluation.match)}</pre>
                  </div>
                  {evaluation?.error ? (
                    <div className="mb-4">
                      <NGLabel className="mb-1">Error</NGLabel>
                      <NGAlert
                        style={{ width: 450 }}
                        message={"Expression Error: " + evaluation.error}
                        type={"error"}
                      />
                    </div>
                  ) : (
                    <div className="mb-4">
                      <NGLabel className="mb-1">Value</NGLabel>
                      <CodeEditor
                        value={JSON.stringify(evaluation.value, null, 2)}
                        setValue={() => {}}
                        language={"json"}
                        options={{
                          basicSetup: {
                            highlightActiveLine: false,
                            highlightActiveLineGutter: false,
                            lineNumbers: true,
                            autocompletion: false,
                            foldGutter: false,
                          },
                          readOnly: true,
                        }}
                      />
                    </div>
                  )}
                  {language === "javascript" ? (
                    <div className="mb-4">
                      <NGLabel className="mb-1">Logs</NGLabel>
                      <CodeEditor
                        value={evaluation.logs || ""}
                        setValue={() => {}}
                        language={"json"}
                        options={{
                          basicSetup: {
                            highlightActiveLine: false,
                            highlightActiveLineGutter: false,
                            lineNumbers: true,
                            autocompletion: false,
                            foldGutter: false,
                          },
                          readOnly: true,
                        }}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export const IdentityMatcher = observer(IdentityMatcherRaw);
