import * as React from "react";
import { notification, Alert } from "antd";
import { observer } from "mobx-react-lite";
import { OrgMobx } from "../../mst/kinds/org";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { User } from "../../mobxStores/user/user";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { NGLabel } from "../../newcomponents/text/label";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { NGSwitch } from "../../newcomponents/switch";
import { NGLabelText } from "../../newcomponents/text/labelText";
import NGAlert from "../../newcomponents/alert";
import { InfoTooltip } from "../../components/InfoTooltip";

interface Props {
  org: OrgMobx;
}
const AuthConfigRaw: React.FC<Props> = ({ org }) => {
  const { fetchItem } = useDetailContext();

  function getDefaultDomainAutoMembers() {
    if (org.spec?.authConfig?.domainAutoMembers && org.spec?.authConfig?.domainAutoMembers.length > 0) {
      return org.spec.authConfig.domainAutoMembers[0];
    }
    return "none";
  }

  const userSaml = User.isSaml ? User.samlDomain : null;
  const currentSaml = getDefaultDomainAutoMembers() === "none" ? null : getDefaultDomainAutoMembers();

  const domainAutoMembersRef = React.useRef(
    SelectModel.create({
      label: "Domain Auto Members",
      initialValue: getDefaultDomainAutoMembers(),
      options: [
        { label: "None", value: "none" },
        userSaml ? { label: userSaml, value: userSaml } : undefined,
        !!currentSaml && currentSaml !== userSaml ? { label: currentSaml, value: currentSaml } : undefined,
      ].filter(Boolean) as any,
    })
  );
  function getDefaultSamlOnly() {
    return org.spec?.authConfig?.samlOnly || false;
  }
  const [samlOnly, setSamlOnly] = React.useState(getDefaultSamlOnly());

  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (domainAutoMembersRef.current.isDirty) res = true;
    if (samlOnly !== getDefaultSamlOnly()) res = true;
    setIsDirty(res);
  }, [domainAutoMembersRef.current.isDirty, samlOnly, org.version]);

  function reset() {
    domainAutoMembersRef.current.reset();
    setSamlOnly(getDefaultSamlOnly());
  }

  async function save() {
    try {
      setIsLoading(true);
      const domains = domainAutoMembersRef.current.value === "none" ? [] : [domainAutoMembersRef.current.value];
      const body: any = { spec: { authConfig: { samlOnly: samlOnly } } };
      if (User.isSaml) {
        body.spec.authConfig.domainAutoMembers = domains;
      }
      await org.patch(body);
      domainAutoMembersRef.current.confirm();
      notification.success({
        message: "Success",
        description: "Updated Org Auth Config",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Org Auto Members", e);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div>
        <div className="mb-4 flex items-center">
          <NGSwitch value={samlOnly} onChange={(value) => setSamlOnly(value)}>
            <NGLabelText>Saml Only</NGLabelText>
          </NGSwitch>
          <InfoTooltip title="Allows users to only sign in with Saml" />
        </div>
        <div style={{ width: 450 }}>
          {User.isSaml ? null : (
            <NGAlert
              type={"warning"}
              className="mb-2"
              message={`Only users authenticated with SAML can change Domain Auto Members value.`}
            />
          )}
          <NGLabel>{domainAutoMembersRef.current.label}</NGLabel>
          <NGSelect
            className="mb-4"
            style={{ width: 450 }}
            disabled={!User.isSaml}
            onChange={domainAutoMembersRef.current.setValue}
            options={domainAutoMembersRef.current.options}
            value={domainAutoMembersRef.current.value}
          />
          <FormButtons
            onReset={reset}
            onSave={save}
            resetDisabled={isLoading || !isDirty}
            saveDisabled={isLoading || !isDirty}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export const AuthConfig = observer(AuthConfigRaw);
