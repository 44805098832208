import * as React from "react";
import { observer } from "mobx-react-lite";
import { TagsNewMobx } from "../../mobxDataModels/tagsNewModel";
import { TagItem } from "../detail/tagItem";
import { NGButton } from "../../newcomponents/button/Button";

interface Props {
  tags: TagsNewMobx;
}

const TagsNewRaw: React.FC<Props> = ({ tags }) => {
  return (
    <div>
      <div className="flex items-center gap-4 mb-4">
        <div className="text-2xl">Tags</div>
        <NGButton
          data-testid="add-tag"
          variant="action"
          size="small"
          disabled={!tags.isValid}
          onClick={() => tags.add()}
        >
          Add
        </NGButton>
      </div>
      {tags.editTags.filter((t) => t.status !== "removed").length < 1 ? null : (
        <div className="flex flex-col gap-2">
          <div className="flex items-center w-full gap-2">
            <div className="flex-grow basis-0">Key</div>
            <div className="flex-grow basis-0">Value (Optional)</div>
            <div style={{ width: 100, height: 1 }} />
          </div>
          {tags.editTags
            .filter((t) => t.status !== "removed")
            .map((t) => (
              <TagItem
                key={t.index}
                tag={t}
                onRemove={() => tags.remove(t.index)}
                error={t.keyConflicts ? `Tag key "${t.key}" is already being used.` : ""}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export const TagsNew = observer(TagsNewRaw);
