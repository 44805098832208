import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NameModel } from "../../mobxDataModels/nameModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { TagNewModel, TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { defaultValues } from "../base";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { VolumeSetDraftSnapshotsModel } from "./volumeset.draft.snapshots";
import {
  PerformanceClasses,
  VolumeSetMobx,
  VolumeSetSpecScalingMobx,
  VolumeSetSpecSnapshotsMobx,
} from "../kinds/volumeset";
import { VolumeSetDraftAutoscalingModel } from "./volumeset.draft.autoscaling";

export const VolumeSetDraftModel = types
  .model({
    _name: types.optional(types.string, ""),
    _description: types.optional(types.string, ""),
    _tags: types.array(TagNewModel),
    _initialCapacity: types.optional(types.number, defaultValues.volumeset.initialCapacity.default),
    _performanceClass: types.optional(types.string, defaultValues.volumeset.performanceClass.default),
    _fileSystemType: types.optional(types.string, defaultValues.volumeset.fileSystemType.default),
    _snapshots: types.frozen(),
    _storageClassSuffix: types.optional(types.string, ""),
    _autoscaling: types.frozen(),
    _hasAutoscaling: types.optional(types.boolean, false),
    overrideAutoscaling: types.optional(types.boolean, false),

    name: types.optional(StringModel, () => NameModel.create()),
    description: types.optional(StringModel, () =>
      StringModel.create({
        label: "Description",
      }),
    ),
    tags: types.optional(TagsNewModel, () => TagsNewModel.create()),
    initialCapacity: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Initial Capacity",
        isRequired: true,
        initialValue: defaultValues.volumeset.initialCapacity.default,
        min: defaultValues.volumeset.initialCapacity.min,
      }),
    ),
    performanceClass: types.optional(types.string, defaultValues.volumeset.performanceClass.default),
    fileSystemType: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "File System Type",
        initialValue: defaultValues.volumeset.fileSystemType.default,
        options: defaultValues.volumeset.fileSystemType.options.map((o) => ({ label: o, value: o })),
      }),
    ),
    storageClassSuffix: types.optional(StringModel, () =>
      StringModel.create({
        label: "Storage Class Suffix",
        validationKey: "storageClassSuffix",
      }),
    ),
    snapshots: types.optional(VolumeSetDraftSnapshotsModel, () => VolumeSetDraftSnapshotsModel.create()),
    autoscaling: types.optional(VolumeSetDraftAutoscalingModel, () => VolumeSetDraftAutoscalingModel.create()),
  })
  .actions((self) => ({
    reset() {
      self.name.setInitialValue(self._name);
      self.description.setInitialValue(self._description);
      // TODO fix for everywhere it's used
      self.tags.reset();
      self.initialCapacity.setInitialValue(String(self._initialCapacity));
      self.performanceClass = self._performanceClass;
      self.fileSystemType.setInitialValue(self._fileSystemType);
      self.storageClassSuffix.setInitialValue(self._storageClassSuffix);
      self.snapshots = getDraftVolumeSetSnapshots(self._snapshots);
      self.autoscaling = getDraftVolumeSetAutoscaling(self._autoscaling);
      self.overrideAutoscaling = self._hasAutoscaling;

      const pClass = PerformanceClasses.find((pc) => pc.name === self.performanceClass);
      let min = 10;
      let max = 16384;
      if (pClass?.minCapacity !== undefined) {
        min = pClass.minCapacity;
      }
      if (pClass) {
        max = pClass.maxCapacity;
      }

      self.initialCapacity.setMin(min);
      self.initialCapacity.setMax(max);

      min = Number(self.initialCapacity.value);

      self.autoscaling.maxCapacity.setMin(min);
      self.autoscaling.maxCapacity.setMax(max);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._name = self.name.value;
      self.name.confirm();
      self._description = self.description.value;
      self.description.confirm();
      self._initialCapacity = Number(self.initialCapacity.value);
      self.initialCapacity.confirm();
      self._performanceClass = self.performanceClass;
      self._fileSystemType = self.fileSystemType.value;
      self.fileSystemType.confirm();
      self._storageClassSuffix = self.storageClassSuffix.value;
      self.storageClassSuffix.confirm();
      self.tags.confirm();
      self._snapshots = self.snapshots.asObject;
      self.snapshots.confirm();
      self._autoscaling = self.autoscaling.asObject;
      self.autoscaling.confirm();
      self._hasAutoscaling = self.overrideAutoscaling;
    },
  }))
  .actions((self) => ({
    setPerformanceClass(value: string) {
      self.performanceClass = value;
      const pClass = PerformanceClasses.find((pc) => pc.name === value);
      if (!pClass) {
        self.initialCapacity.setMin(10);
        self.autoscaling.maxCapacity.setMin(10);
        self.initialCapacity.setValue("10");
        self.autoscaling.maxCapacity.setValue("10");
        return;
      }
      let min = 10;
      if (pClass.minCapacity !== undefined) {
        min = pClass.minCapacity;
      }

      self.initialCapacity.setMin(min);
      self.initialCapacity.setMax(pClass.maxCapacity);
      self.initialCapacity.setValue(String(min));

      self.autoscaling.maxCapacity.setMin(min);
      self.autoscaling.maxCapacity.setMax(pClass.maxCapacity);
      self.autoscaling.maxCapacity.setValue(String(min));
    },
    // NOTE Double check if it's used
    setInitialCapacity(value: string) {
      self.initialCapacity.setValue(value);

      const newValue = self.initialCapacity.value;
      self.autoscaling.maxCapacity.setMin(Number(newValue));
    },
    setOverrideAutoscaling(value: boolean) {
      self.overrideAutoscaling = value;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.name.isDirty) {
        res = true;
      }
      if (self.description.isDirty) {
        res = true;
      }
      if (self.tags.isDirty) {
        res = true;
      }
      if (self.initialCapacity.isDirty) {
        res = true;
      }
      if (self.fileSystemType.isDirty) {
        res = true;
      }
      if (self.fileSystemType.value !== "shared") {
        if (self.performanceClass !== self._performanceClass) {
          res = true;
        }
      }
      if (self.storageClassSuffix.isDirty) {
        res = true;
      }
      if (self.fileSystemType.value !== "shared") {
        if (self.snapshots.isDirty) {
          res = true;
        }
      }
      if (self.overrideAutoscaling && self.autoscaling.isDirty) {
        res = true;
      }
      if (self.overrideAutoscaling !== self._hasAutoscaling) {
        res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.name.isValid) {
        res = false;
      }
      if (!self.initialCapacity.isValid) {
        res = false;
      }
      if (self.fileSystemType.value !== "shared") {
        if (!self.snapshots.isValid) {
          res = false;
        }
      }
      if (!self.storageClassSuffix.isValid) {
        res = false;
      }
      return res;
    },
    get asPatch() {
      const obj: any = {
        name: self.name.value,
        description: self.description.value ? self.description.value : undefined,
        tags: self.tags.asObject,
        spec: {
          initialCapacity: Number(self.initialCapacity.value),
          fileSystemType: self.fileSystemType.value,
        },
      };
      if (self.fileSystemType.value !== "shared") {
        obj.spec.performanceClass = self.performanceClass;
        obj.spec["$replace/snapshots"] = self.snapshots.asObject;
      }

      if (self.overrideAutoscaling) {
        obj.spec.autoscaling = self.autoscaling.asObject;
      } else {
        obj.spec.autoscaling = null;
      }
      if (self._storageClassSuffix && !self.storageClassSuffix.value) {
        obj.spec.storageClassSuffix = null;
      } else if (self.storageClassSuffix.value) {
        obj.spec.storageClassSuffix = self.storageClassSuffix.value;
      }
      return obj;
    },
    next(pathname: string) {
      const next = {
        isActive: false,
        label: "Next",
        url: "",
      };
      if (pathname.includes("-general")) {
        if (self.name.isValid) {
          next.isActive = true;
        }
        next.label = "Next (Spec)";
        next.url = `-spec`;
      } else if (pathname.includes("-spec")) {
        if (self.name.isValid) {
          next.isActive = true;
        }
        next.label = "Next (Tags)";
        next.url = `-tags`;
      }
      return next;
    },
  }))
  .views((self) => ({
    get asObject() {
      let obj: any = self.asPatch;
      if (self.fileSystemType.value !== "shared") {
        const s = obj.spec["$replace/snapshots"];
        obj.spec.snapshots = s;
        delete obj.spec["$replace/snapshots"];
      }
      if (obj.spec.autoscaling === null) {
        delete obj.spec.autoscaling;
      }
      return obj;
    },
  }));

export interface VolumeSetDraftMobx extends Instance<typeof VolumeSetDraftModel> {}

export function getDraftVolumeSet(volumeset: VolumeSetMobx) {
  return VolumeSetDraftModel.create({
    _name: volumeset.name,
    _description: volumeset.description,
    _tags: volumeset.tagsAsKeyValueArray.map((o, idx) => ({ ...o, index: idx, status: "default" })),
    _fileSystemType: volumeset.spec.fileSystemType,
    _initialCapacity: volumeset.spec.initialCapacity,
    _performanceClass: volumeset.spec.performanceClass,
    _snapshots: volumeset.spec.snapshots ? JSON.parse(JSON.stringify(volumeset.spec.snapshots)) : undefined,
    _storageClassSuffix: volumeset.spec.storageClassSuffix,
    _autoscaling: volumeset.spec.autoscaling ? JSON.parse(JSON.stringify(volumeset.spec.autoscaling)) : undefined,
    _hasAutoscaling: !!volumeset.spec.autoscaling,
  });
}

export function getDraftVolumeSetSnapshots(snapshots?: VolumeSetSpecSnapshotsMobx) {
  if (!snapshots) {
    return VolumeSetDraftSnapshotsModel.create({
      _schedule: "",
    });
  }
  return VolumeSetDraftSnapshotsModel.create({
    _retentionDuration: snapshots.retentionDuration,
    _schedule: snapshots.schedule || "",
  });
}

export function getDraftVolumeSetAutoscaling(scaling?: VolumeSetSpecScalingMobx) {
  if (!scaling) {
    return VolumeSetDraftAutoscalingModel.create();
  }
  return VolumeSetDraftAutoscalingModel.create({
    _maxCapacity: scaling.maxCapacity,
    _minFreePercentage: scaling.minFreePercentage,
    _scalingFactor: scaling.scalingFactor,
  });
}
