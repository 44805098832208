import * as React from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars";
import { observer } from "mobx-react-lite";
import { Theme } from "../../mobxStores/uiData/theme";

interface Props extends ScrollbarProps {
  thumbStyle?: React.CSSProperties;
}

const CustomScrollbarsRaw = React.forwardRef<any, Props>(({ thumbStyle, ...props }, ref) => {
  const scrollbarColor: string = Theme.theme == "light" ? "#CCCCCC" : "var(--color-gray-1200)";
  let _thumbStyle: React.CSSProperties = {
    backgroundColor: scrollbarColor,
    borderRadius: 6,
  };

  if (thumbStyle) {
    _thumbStyle = {
      ..._thumbStyle,
      ...thumbStyle,
    };
  }

  return (
    <Scrollbars
      ref={ref}
      /* Default */
      renderThumbHorizontal={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            ..._thumbStyle,
          }}
        />
      )}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            ..._thumbStyle,
          }}
        />
      )}
      /* Specified Preferences */
      {...props}
    >
      {props.children}
    </Scrollbars>
  );
});

export const CustomScrollbars = observer(CustomScrollbarsRaw);
