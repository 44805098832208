import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { AWSInstructions, ProviderAWSInstructions } from "../../../provider_aws_instructions";
import { homeLink, request } from "../../../../../services/cpln";
import { ConsoleContext } from "../../../../../mobxStores/consoleContext/consoleContext";
import { DryRunAlert } from "../../../dryRunAlert";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGSelect } from "../../../../../newcomponents/select/ngselect";
import { NGRadioGroup } from "../../../../../newcomponents/radioGroup";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { ExternalLink, MoreHorizontal } from "react-feather";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { NGFormLabel } from "../../../../../newcomponents/text/formLabel";
import { NGError } from "../../../../../newcomponents/text/error";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { dateString } from "../../../../../components/dateStringFn";
import { formatStrings } from "../../../../../utils/time";
import { AwsSsmIcon } from "../../../awsSsmIcon";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { getDryRunErrorText } from "../../../getDryRunErrorText";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderAWSRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const a = mk8sDraft.provider_aws!;
  const formData = useNGFormContext();

  const [browserKey, setBrowserKey] = React.useState("");

  const [recommendedAmis, setRecommendedAmis] = React.useState([]);

  const [awsDeployRoleArnInstructions, setAwsDeployRoleArnInstructions] = React.useState<AWSInstructions | null>(null);
  const [awsInstructionsError, setAwsInstructionsError] = React.useState<string>("");

  React.useEffect(() => {
    fetchRecommendedAmis();
    fetchAwsInstructions();
  }, []);

  async function fetchRecommendedAmis() {
    try {
      const { data } = await request({
        service: "api",
        url: homeLink("mk8s") + "/-discover",
        method: "post",
        body: {
          objectType: "recommended-amis",
          cluster: mk8sDraft.asObject,
        },
      });
      const res = data.recommended.map((r: any) => ({ label: r.name, value: r.name }));
      setRecommendedAmis(res);
    } catch (e) {
      setRecommendedAmis([]);
    }
  }

  async function fetchAwsInstructions() {
    try {
      const { data } = await request({ url: `/org/${ConsoleContext.org}/mk8s/-instructions/aws` });
      setAwsDeployRoleArnInstructions(data);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setAwsInstructionsError(errorMessage);
    }
  }

  const securityGroupIdsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.aws.securityGroupIds",
    paths: a.securityGroupIds.items.map((_, idx) => `spec.provider.aws.securityGroupIds[${idx}]`),
  });

  return (
    <>
      <div>
        <NGFormLabel
          name={"aws.deployRoleArn"}
          required={a.deployRoleArn.isRequired}
          invalid={!a.deployRoleArn.isValid}
        >
          {a.deployRoleArn.label}
        </NGFormLabel>
        {awsInstructionsError || !awsDeployRoleArnInstructions ? null : (
          <ProviderAWSInstructions instructions={awsDeployRoleArnInstructions} draft={mk8sDraft} />
        )}
        <div className="flex items-start gap-2">
          <div className="mb-4">
            <NGInput
              name={"aws.deployRoleArn"}
              style={{ minWidth: 450 }}
              value={a.deployRoleArn.value}
              onChange={(e) => a.deployRoleArn.setValue(e.target.value)}
              invalid={!a.deployRoleArn.isValid}
            />
            {!a.deployRoleArn.value && formData.get("aws.deployRoleArn").touched ? (
              <NGError>{a.deployRoleArn.label} is required</NGError>
            ) : null}
          </div>
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={formData.get("aws.deployRoleArn").touched}
            dryRunResponse={mk8sDryRun.response}
            path={"spec.provider.aws.deployRoleArn"}
          />
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"aws.region"}
            label={a.region.label}
            value={a.region.value}
            onChange={a.region.setValue}
            required={a.region.isRequired}
            error={a.region.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("region");
                  formData.set("aws.region", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("aws.region").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={a.region.setValue}
              path={"spec.provider.aws.region"}
            />
          </div>
        </div>
        <div className="flex items-center gap-1">
          <NGFormLabel
            name={a.image.type === "recommended" ? "aws.image.recommended" : "aws.image.exact"}
            required
            invalid={
              a.image.type === "recommended" ? !a.image.recommended : !a.image.exact.value || !a.image.exact.isValid
            }
          >
            Image
          </NGFormLabel>
          <AwsSsmIcon />
        </div>
        <NGRadioGroup
          options={[
            { label: "Recommended", value: "recommended" },
            { label: "Exact", value: "exact" },
          ]}
          value={a.image.type}
          onChange={(value) => a.image.setType(value as any)}
        />
        <div className="mb-4 flex items-start gap-2">
          {a.image.type === "recommended" ? (
            <NGSelect
              name={`aws.image.recommended`}
              style={{ width: 450, minWidth: 450 }}
              value={a.image.recommended}
              invalid={!a.image.recommended}
              onChange={a.image.setRecommended}
              options={recommendedAmis}
            />
          ) : a.image.type === "exact" ? (
            <NGInput
              name={`aws.image.exact`}
              style={{ width: 450, minWidth: 450 }}
              value={a.image.exact.value}
              invalid={!a.image.exact.value || !a.image.exact.isValid}
              required
              onChange={(e) => a.image.exact.setValue(e.target.value)}
            />
          ) : null}

          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={formData.get("aws.image.recommended").touched || formData.get("aws.image.exact").touched}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.aws.image`}
            paths={[`spec.provider.aws.image.recommended`, `spec.provider.aws.image.exact`]}
          />
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"aws.vpcId"}
            label={a.vpcId.label}
            labelRender={() => <AwsSsmIcon />}
            value={a.vpcId.value}
            onChange={a.vpcId.setValue}
            error={a.vpcId.error}
            required={a.vpcId.isRequired}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("vpcId");
                  formData.set("aws.vpcId", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("aws.vpcId").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={a.vpcId.setValue}
              path={"spec.provider.aws.vpcId"}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"aws.keyPair"}
            label={a.keyPair.label}
            value={a.keyPair.value}
            onChange={a.keyPair.setValue}
            error={a.keyPair.error}
            required={a.keyPair.isRequired}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("keyPair");
                  formData.set("aws.keyPair", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("aws.keyPair").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={a.keyPair.setValue}
              path={"spec.provider.aws.keyPair"}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <div className="mb-4">
            <div className="flex items-center gap-1">
              <NGFormLabel name={"aws.diskEncryptionKeyArn"} required={false} invalid={!a.diskEncryptionKeyArn.isValid}>
                {a.diskEncryptionKeyArn.label}
              </NGFormLabel>
              <AwsSsmIcon />
            </div>
            <NGInput
              name={"aws.diskEncryptionKeyArn"}
              style={{ width: 450 }}
              value={a.diskEncryptionKeyArn.value}
              onChange={(e) => a.diskEncryptionKeyArn.setValue(e.target.value)}
              invalid={!a.diskEncryptionKeyArn.isValid}
              buttons={[
                {
                  title: "Browse",
                  onClick: () => {
                    setBrowserKey("diskEncryptionKeyArn");
                    formData.set("aws.diskEncryptionKeyArn", { touched: true });
                  },
                  render: () => <MoreHorizontal />,
                },
              ]}
            />
            {a.diskEncryptionKeyArn.error ? <NGError>{a.diskEncryptionKeyArn.error}</NGError> : null}
            <div style={{ width: 450 }}>
              This requires Autoscaler to use the
              <a
                href={
                  "https://docs.aws.amazon.com/autoscaling/ec2/userguide/key-policy-requirements-EBS-encryption.html"
                }
                target={"_blank"}
                className="color-link ml-1"
              >
                <span>KMS Key</span>
                <ExternalLink className="h-4 inline-block" style={{ transform: "translateY(1px)" }} />
              </a>
            </div>
          </div>
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("aws.diskEncryptionKeyArn").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={a.diskEncryptionKeyArn.setValue}
              path={"spec.provider.aws.diskEncryptionKeyArn"}
            />
          </div>
        </div>
        <NGInputListMst
          data={a.securityGroupIds}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="Security Group IDs"
          labelRender={() => {
            return (
              <>
                <AwsSsmIcon />
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("securityGroupId")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (securityGroupIdsDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }

            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          invalid={securityGroupIdsDryRun.severity === "error"}
          error={securityGroupIdsDryRun.severity === "error" ? securityGroupIdsDryRun.message : ""}
          warning={securityGroupIdsDryRun.severity === "error" ? "" : securityGroupIdsDryRun.message}
        />
      </div>
      {browserKey === "region" ? (
        <NGProviderBrowser
          title="Browse AWS Regions"
          initialValue={a.region.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "regions",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.regions;
          }}
          onOk={(value) => a.region.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name"]}
          getData={(item: any) => [item, item]}
        />
      ) : null}
      {browserKey === "vpcId" ? (
        <NGProviderBrowser
          title="Browse AWS VPC IDs"
          initialValue={a.vpcId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-vpcs",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.Vpcs;
          }}
          onOk={(value) => a.vpcId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "VPC ID", "CIDR Block", "Is Default"]}
          getData={(item: any) => {
            let name = "-";
            const nameTag = (item.Tags || []).find((t: any) => t.Key === "Name");
            if (nameTag) {
              name = nameTag.Value;
            }

            return [item.VpcId, name, item.VpcId, item.CidrBlock, String(item.IsDefault)];
          }}
        />
      ) : null}
      {browserKey === "keyPair" ? (
        <NGProviderBrowser
          title="Browse AWS EC2 Key Pairs"
          initialValue={a.diskEncryptionKeyArn.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-keyPairs",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.KeyPairs;
          }}
          onOk={(value) => a.keyPair.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Type", "Create Time"]}
          getData={(item: any) => [
            item.KeyName,
            item.KeyName,
            item.KeyType,
            dateString(item.CreateTime, { format: formatStrings.log }),
          ]}
        />
      ) : null}
      {browserKey === "diskEncryptionKeyArn" ? (
        <NGProviderBrowser
          title="Browse AWS KMS Keys"
          initialValue={a.diskEncryptionKeyArn.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "kms-keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.Keys;
          }}
          onOk={(value) => a.diskEncryptionKeyArn.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Arn"]}
          getData={(item: any) => [item.KeyArn, item.KeyArn]}
        />
      ) : null}
      {browserKey === "securityGroupId" ? (
        <NGProviderBrowser
          title="Browse AWS Security Groups"
          initialValue={a.securityGroupIds.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-securityGroups",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.SecurityGroups;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (a.securityGroupIds.items.some((i) => i.firstValue === value)) {
                continue;
              }
              a.securityGroupIds.add();
              const i = a.securityGroupIds.items[a.securityGroupIds.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description", "Security Group ID"]}
          getData={(item: any) => [item.GroupId, item.GroupName, item.Description, item.GroupId]}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderAWS = observer(Mk8sCreateProviderAWSRaw);
