import { Instance, types } from "mobx-state-tree";
import { IdentityDraftNetworkResourceMobx, IdentityDraftNetworkResourceModel } from "./identity.draft.networkresource";
import { IdentityNetworkResourceMobx, IdentityNetworkResource } from "../kinds/identity";

export const IdentityDraftNetworkResourcesHelperModel = types
  .model({
    _resources: types.array(IdentityDraftNetworkResourceModel),
    resources: types.array(IdentityDraftNetworkResourceModel),
  })
  .actions((self) => ({
    reset() {
      self.resources.clear();
      for (let _resource of self._resources) {
        const item = IdentityDraftNetworkResourceModel.create();
        self.resources.push(item);
        item.apply(_resource.asObject);
      }
    },
    clear() {
      self._resources.clear();
      self.resources.clear();
    },
    confirm() {
      self._resources.clear();
      for (let resource of self.resources) {
        const item = IdentityDraftNetworkResourceModel.create();
        self._resources.push(item);
        item.apply(resource.asObject);
      }
    },
  }))
  .actions((self) => ({
    apply(networkResources: IdentityNetworkResourceMobx[]) {
      self.clear();
      for (let resource of networkResources) {
        const _item = IdentityDraftNetworkResourceModel.create();
        const item = IdentityDraftNetworkResourceModel.create();
        self._resources.push(_item);
        self.resources.push(item);
        _item.apply(resource);
        item.apply(resource);
      }
    },
    add() {
      self.resources.unshift(IdentityDraftNetworkResourceModel.create());
    },
    addPreDefinedResource(resource: IdentityDraftNetworkResourceMobx) {
      self.resources.unshift(resource);
    },
    remove(index: number) {
      const resource = self.resources[index];
      if (resource) {
        self.resources.remove(resource);
      }
    },
    removeByResource(resource: IdentityDraftNetworkResourceMobx) {
      self.resources.remove(resource);
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      for (let resource of self.resources) {
        if (!resource.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      return self.resources.map((r) => {
        const res = r.asObject;
        if (res.FQDN) {
          delete res.IPs;
        }
        return res;
      });
    },
    get _resourcesForView() {
      return self._resources.map((r) => r.asObject);
    },
  }))
  .views((self) => ({
    get asPatch(): null | IdentityNetworkResource[] {
      if (self._resources.length > 0 && self.resources.length < 1) return null;
      return self.asObject;
    },
    get isDirty() {
      const sortFn = (a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      };
      const resources = self.resources
        .map((r) => r.asObject)
        .slice()
        .sort(sortFn);
      const _resources = self._resources
        .map((r) => r.asObject)
        .slice()
        .sort(sortFn);
      // check length
      if (resources.length !== _resources.length) return true;
      // check both has the same agentlinks in order
      for (let resourceIndex in resources) {
        const resource = resources[resourceIndex];
        const _resource = _resources[resourceIndex];
        if (resource.name !== _resource.name) return true;
        if (resource.agentLink !== _resource.agentLink) return true;
        if (resource.FQDN !== _resource.FQDN) return true;
        if (resource.resolverIP !== _resource.resolverIP) return true;
        if (resource.ports.length !== _resource.ports.length) return true;
        for (let portIndex in resource.ports) {
          const port = resource.ports[portIndex];
          const _port = _resource.ports[portIndex];
          if (port !== _port) return true;
        }
        if (!resource.IPs) resource.IPs = [];
        if (!_resource.IPs) _resource.IPs = [];
        if (resource.IPs.length !== _resource.IPs.length) return true;
        for (let ipIndex in resource.IPs) {
          const ip = resource.IPs[ipIndex];
          const _ip = _resource.IPs[ipIndex];
          if (ip !== _ip) return true;
        }
      }
      return false;
    },
  }));

export interface IdentityDraftNetworkResourcesHelperMobx
  extends Instance<typeof IdentityDraftNetworkResourcesHelperModel> {}
