import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderLinode } from "./linode";
import { Mk8sCreateProviderLinodeNodePools } from "./nodePools";
import { Mk8sCreateProviderLinodeNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderLinodeNodePool } from "./nodePool";
import { Mk8sCreateProviderLinodeAdvanced } from "./advanced";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderLinodeRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderLinode mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderLinodeNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderLinodeNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderLinodeNodePool mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<Mk8sCreateProviderLinodeAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderLinodeRoute = observer(Mk8sCreateProviderLinodeRouteRaw);
