import { flow, Instance, types } from "mobx-state-tree";
import { AWSIdentityDataModel } from "./identity.aws.data";
import { IdentityDraftAWSIdentityModel } from "./identity.aws.draft";
import { isEqual } from "lodash";

export const IdentityDraftAwsHelperModel = types
  .model({
    saved: types.optional(AWSIdentityDataModel, () => AWSIdentityDataModel.create()), // only active if saved earlier
    edited: types.optional(AWSIdentityDataModel, () => AWSIdentityDataModel.create()), // shown on info page

    draft: types.optional(IdentityDraftAWSIdentityModel, () => IdentityDraftAWSIdentityModel.create()), // shown on modal
    isSettingUp: false,
    isLoading: false,
    step: types.optional(types.enumeration(["general", "config"]), "general"),
  })
  .actions((self) => {
    const next = flow(function* () {
      try {
        self.isLoading = true;
        if (self.draft.method === "existing") {
          yield self.draft.fetchRoles();
        } else {
          yield self.draft.fetchPolicies();
        }
      } catch (e) {
        self.draft.roleInput.setIsRequired(true);
      } finally {
        self.step = "config";
        self.isLoading = false;
      }
    });
    const refreshPolicyList = flow(function* () {
      try {
        self.isLoading = true;
        yield self.draft.fetchPolicies();
      } catch (e) {
        self.draft.roleInput.setIsRequired(true);
      } finally {
        self.step = "config";
        self.isLoading = false;
      }
    });
    return { next, refreshPolicyList };
  })
  .actions((self) => ({
    setIsSettingUpFalse() {
      self.isSettingUp = false;
      self.draft.filterInput.reset();
      self.step = "general";
    },
  }))
  .actions((self) => ({
    done() {
      self.draft.setIsActive(true);
      self.draft.setUseManualInput(false);
      self.edited.apply(self.draft.asObject);
      self.setIsSettingUpFalse();
    },
    back() {
      self.step = "general";
    },
    remove() {
      self.draft.clear();
      self.edited.clear();
    },
    onSave() {
      self.saved.apply(self.edited.asObject);
    },
    setIsSettingUpTrue() {
      self.isSettingUp = true;
    },
  }))
  .actions((self) => {
    const cancelDraft = flow(function* () {
      self.isLoading = true;
      if (self.edited.isActive) {
        yield self.draft.apply(self.edited.asObject);
      } else {
        self.draft.clear();
      }
      self.setIsSettingUpFalse();
      self.isLoading = false;
    });
    const reset = flow(function* () {
      if (self.saved.isActive) {
        self.edited.apply(self.saved.asObject);
        yield self.draft.apply(self.saved.asObject);
      } else {
        self.edited.clear();
        self.draft.clear();
      }
    });
    return { cancelDraft, reset };
  })
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.saved.isActive !== self.edited.isActive) {
        res = true;
      } else if (self.saved.isActive && self.edited.isActive) {
        if (!isEqual(self.saved.asObject, self.edited.asObject)) {
          res = true;
        }
      }
      return res;
    },
  }))
  .views((self) => ({
    get title() {
      switch (self.step) {
        case "general":
          return "AWS Identity - General";
        case "config":
          if (self.draft.method === "existing") {
            return "AWS Identity - Select an AWS Role";
          } else {
            return "AWS Identity - Select AWS Policies";
          }
        default:
          return "AWS Identity";
      }
    },
    get isRadioDisabled() {
      return !self.draft.cloudaccountName;
    },
    get tableWidth() {
      if (self.isLoading) return undefined;
      if (self.step === "config" && self.draft.method === "new") {
        return 1000;
      }
      return undefined;
    },
  }));
export interface IdentityDraftAWSHelper extends Instance<typeof IdentityDraftAwsHelperModel> {}
