import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../../advanced/providerAutoscaler";
import { ProviderNetworking } from "../../../advanced/providerNetworking";
import { ProviderPreInstallScript } from "../../../advanced/providerPreInstallScript";
import { NGSwitch } from "../../../../../newcomponents/switch";
import { NGLabelText } from "../../../../../newcomponents/text/labelText";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { getDryRunErrorText } from "../../../getDryRunErrorText";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { homeLink, request } from "../../../../../services/cpln";
import { AwsPolicyIcon } from "../../../awsPolicyIcon";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderAWSAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const a = mk8sDraft.provider_aws!;

  const [browserKey, setBrowserKey] = React.useState("");

  const awsTagsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.aws.awsTags`,
    paths: a.awsTags.items.map((i) => `spec.provider.aws.awsTags.${i.firstValue}`),
  });
  const deployRoleChainDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.aws.deployRoleChain",
    paths: [
      "spec.provider.aws.deployRoleChain",
      ...a.deployRoleChain.items.map((_, idx) => `spec.provider.aws.deployRoleChain[${idx}]`),
      ...a.deployRoleChain.items.map((_, idx) => `spec.provider.aws.deployRoleChain[${idx}].roleArn`),
      ...a.deployRoleChain.items.map((_, idx) => `spec.provider.aws.deployRoleChain[${idx}].externalId`),
      ...a.deployRoleChain.items.map((_, idx) => `spec.provider.aws.deployRoleChain[${idx}].sessionNamePrefix`),
    ],
  });
  const extraNodePoliciesDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.aws.extraNodePolicies",
    paths: [
      "spec.provider.aws.extraNodePolicies",
      ...a.extraNodePolicies.items.map((_, idx) => `spec.provider.aws.extraNodePolicies[${idx}]`),
    ],
  });

  return (
    //
    <>
      <div>
        <NGInputListMst
          data={a.awsTags}
          className="mb-8"
          label="AWS Tags"
          firstLabel="Key"
          firstInput={(i) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && awsTagsDryRun.message.includes(`.${i.firstValue}`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondLabel="Value"
          secondInput={(i) => {
            let invalid = false;
            if (i.secondValue && awsTagsDryRun.message.includes(`.${i.secondValue}`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          invalid={awsTagsDryRun.severity === "error"}
          error={awsTagsDryRun.severity === "error" ? awsTagsDryRun.message : ""}
          warning={awsTagsDryRun.severity === "error" ? "" : awsTagsDryRun.message}
        />
        <NGInputListMst
          data={a.deployRoleChain}
          className="mb-8"
          styles={{ firstInputContainer: { flexBasis: "40%" } }}
          draggable
          label="Deploy Role Chain"
          info={[
            `A list of additional roles that are assumed, in order, after the initial login using the deployRole.`,
          ]}
          firstLabel="Role ARN"
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && deployRoleChainDryRun.message.includes(`[${index}].roleArn`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondLabel="External ID"
          secondInput={(i, index) => {
            let invalid = false;
            if (i.secondValue && deployRoleChainDryRun.message.includes(`[${index}].externalId`)) {
              invalid = true;
            }

            return (
              <NGInput invalid={invalid} value={i.secondValue} onChange={(e) => i.setSecondValue(e.target.value)} />
            );
          }}
          thirdLabel="Session Name Prefix"
          thirdInput={(i, index) => {
            let invalid = false;
            if (i.thirdValue && deployRoleChainDryRun.message.includes(`[${index}].sessionNamePrefix`)) {
              invalid = true;
            }

            return <NGInput invalid={invalid} value={i.thirdValue} onChange={(e) => i.setThirdValue(e.target.value)} />;
          }}
          invalid={deployRoleChainDryRun.severity === "error"}
          error={deployRoleChainDryRun.severity === "error" ? deployRoleChainDryRun.message : ""}
          warning={deployRoleChainDryRun.severity === "error" ? "" : deployRoleChainDryRun.message}
        />
        <NGInputListMst
          data={a.extraNodePolicies}
          className="mb-8"
          label="Extra Node Policies"
          labelRender={() => {
            return (
              <>
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("iam_policies")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && extraNodePoliciesDryRun.message.includes(`[${index}]`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          invalid={extraNodePoliciesDryRun.severity === "error"}
          error={extraNodePoliciesDryRun.severity === "error" ? extraNodePoliciesDryRun.message : ""}
          warning={extraNodePoliciesDryRun.severity === "error" ? "" : extraNodePoliciesDryRun.message}
        />
        <NGSwitch className="mb-4" value={a.skipCreateRoles} onChange={(checked) => a.setSkipCreateRoles(checked)}>
          <NGLabelText>Skip Create Roles</NGLabelText>
        </NGSwitch>
        <div className="mb-4">
          <ProviderPreInstallScript provider="aws" preInstallScript={a.preInstallScript} />
        </div>
        <div className="mb-4">
          <ProviderNetworking clusterDraft={mk8sDraft} networking={a.networking} />
        </div>
        <div>
          <ProviderAutoscaler provider="aws" autoscaler={a.autoscaler} />
        </div>
      </div>
      {browserKey === "iam_policies" ? (
        <NGProviderBrowser
          title="Browse AWS Policies"
          initialValue={a.extraNodePolicies.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "iam_policies",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.Policies;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (a.extraNodePolicies.items.some((i) => i.firstValue === value)) {
                continue;
              }
              a.extraNodePolicies.add();
              const i = a.extraNodePolicies.items[a.extraNodePolicies.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          sortable
          filteredColumns={["Name"]}
          labels={["Name", "Arn"]}
          columnRenderers={[
            {
              label: "Name",
              renderer: (_, __, itemData) => {
                if (itemData[2].includes("iam::aws:policy")) {
                  return <AwsPolicyIcon className="mr-1" />;
                }
                return null as any;
              },
            },
          ]}
          getData={(item: any) => [item.Arn, item.PolicyName, item.Arn]}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderAWSAdvanced = observer(Mk8sCreateProviderAWSAdvancedRaw);
