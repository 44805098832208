import * as React from "react";
import stringToColor from "string-to-color";
import Color from "color";
import { Theme, ThemeOption } from "../../mobxStores/uiData/theme";
import { observer } from "mobx-react-lite";
import "./tag.css";
import { Tooltip } from "../Tooltip";

interface TagProps {
  name: string;
  value: string;
  className?: string;
  style?: any;
  onClick?: any;
  disableTruncate?: boolean;
  small?: boolean;
  colorize?: boolean;
}
const TagRaw: React.FC<TagProps> = ({
  name,
  value,
  className = "",
  style = {},
  small = false,
  onClick,
  disableTruncate = false,
  colorize = false,
}) => {
  const [main, setMain] = React.useState<string | undefined>(undefined);
  const [opposite, setOpposite] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (colorize) {
      const { main, opposite } = getColors(name === "replica" ? `replica${value}` : value, Theme.theme);
      setMain(main);
      setOpposite(opposite);
    } else {
      setMain(undefined);
      setOpposite(undefined);
    }
  }, [colorize, Theme.theme]);

  return (
    <Tooltip title={`${name}=${value}`} open={!(colorize || name.length + value.length < 20)}>
      <span
        className={`tag old-value ${colorize ? "px-1 rounded-sm" : "py-1 px-2"} ${disableTruncate ? "" : "truncate"} ${
          small ? "text-xs" : "text-sm"
        } items-center max-w-full ${className} ${onClick ? "cursor-pointer" : ""}`}
        onClick={onClick}
        style={{ maxWidth: 170, color: opposite, backgroundColor: main, ...style }}
      >
        <pre className="inline proxima-nova">{name}</pre>
        {!value ? null : <span style={{ padding: "0 2px" }}>=</span>}
        <pre className="inline proxima-nova">{value}</pre>
      </span>
    </Tooltip>
  );
};
export const Tag = observer(TagRaw);

function getColors(val: string, theme: ThemeOption) {
  const c = stringToColor(val);
  let main = new Color(c);
  if (theme === "light") {
    while (main.luminosity() < 0.75) {
      main = main.lighten(0.1);
    }
  } else {
    while (main.luminosity() > 0.5) {
      main = main.darken(0.1);
    }
  }
  // if (main.saturationl() > 0.7) {
  //   main = main.saturate(0.1);
  // }
  let opposite = Color("#fff");

  let iterationLimit = 100;
  let iterationCount = 0;

  const contrastMin = 4.5;
  const contrastMax = 6;
  let contrast = opposite.contrast(main);

  while ((contrast < contrastMin || contrast > contrastMax) && iterationCount < iterationLimit) {
    iterationCount += 1;
    opposite = opposite.darken(0.1);
    contrast = opposite.contrast(main);
  }

  return { main: main.hex(), opposite: opposite.hex() };
}
