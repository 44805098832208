import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { GroupMobx } from "../../mst/kinds/group";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { IdentityMatcherDetailWrapper } from "./identityMatcher/detailWrapper";
import { Members } from "./members";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  group: GroupMobx;
}
const GroupDetailRaw: React.FC<Props> = ({ group }) => {
  const basePath = useBasePath("/group/:group/*");

  const eventlogHook = useEventlogs(group.selfLink);

  const [links] = React.useState([
    { label: "Info", url: "-info" },
    { label: "Members", url: "-members" },
    { label: "Identity Matcher", url: "-identitymatcher" },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ]);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info group={group} />} />
        <Route path={`-members/*`} element={<Members group={group} />} />
        <Route path={`-identitymatcher`} element={<IdentityMatcherDetailWrapper group={group} />} />
        <Route path={`-tags`} element={<Tags link={group.selfLink} tags={group.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"group"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="group" resourceId={group.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={group.selfLink} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const GroupDetail = observer(GroupDetailRaw);
