import * as React from "react";
import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { LoaderSmall } from "../../../components/layout/loader/small";
import { NativeResBrowseMobx } from "../../../mst/stores/identity.draft.nativenetworkresourcehelper";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  browse: NativeResBrowseMobx;
}
const NativeResCloudaccountModalRaw: React.FC<Props> = ({ browse }) => {
  return (
    <Modal
      title={"Select Cloudaccount"}
      width={500}
      open={browse.page === "cloudaccount"}
      closable={false}
      footer={
        <div className="modal-actions">
          <NGButton
            variant="secondary"
            onClick={() => {
              browse.setCloudaccountName("");
              browse.setPage("none");
            }}
          >
            Cancel
          </NGButton>
          <NGButton
            variant="primary"
            onClick={() => browse.prepare()}
            loading={browse.isCheckingReach}
            disabled={!browse.cloudaccountName || !browse.browserService.canReach || browse.isCheckingReach}
          >
            Next
          </NGButton>
        </div>
      }
    >
      {browse.isPreparing ? (
        <div className="flex items-center justify-center flex-col" style={{ height: 300 }}>
          <LoaderSmall />
          <div className="mt-2">Please wait. Loading items.</div>
        </div>
      ) : null}
      {!browse.isPreparing ? (
        <>
          {browse.provider === "aws" ? (
            <NGKindSelect
              className="mb-4"
              kind={"cloudaccount"}
              value={browse.cloudaccountName}
              onChange={browse.setCloudaccountName}
              queries={[{ property: "provider", value: "aws" }]}
            />
          ) : null}
          {browse.provider === "gcp" ? (
            <NGKindSelect
              className="mb-4"
              kind={"cloudaccount"}
              value={browse.cloudaccountName}
              onChange={browse.setCloudaccountName}
              queries={[{ property: "provider", value: "gcp" }]}
            />
          ) : null}
          {browse.prepareError ? (
            <NGAlert type={"error"} message={"This cloud account cannot reach the provider. Select another one."} />
          ) : null}
          {browse.browserService.reachError ? (
            <NGAlert type={"error"} message={browse.browserService.reachError} />
          ) : null}
        </>
      ) : null}
    </Modal>
  );
};

export const NativeResCloudaccountModal = observer(NativeResCloudaccountModalRaw);
