import * as React from "react";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { TableColumn } from "../../types";
import { gvcScopedKinds } from "../../../../utils/kinds";
import { InternalLink } from "../../components/InternalLink";

const nameDescriptionDefault = { id: "name", label: "Name" };
export function NameDescriptionColumn<TData extends { name: string; description: string; kind: string }>(
  column: Partial<TableColumn<TData>> = nameDescriptionDefault,
  options?: { shouldSkipLink?: (item: TData) => boolean; linkProperty?: string },
) {
  const preset: TableColumn<TData> = {
    ...nameDescriptionDefault,
    enableResize: true,
    enableSort: true,
    size: 200,
    cell: (p) => {
      const item = p.row.original;

      const linkProperty = options?.linkProperty || "name";
      const link = `/console/org/${ConsoleContext.org}${
        gvcScopedKinds.includes(item.kind) ? `/gvc/${ConsoleContext.gvc}` : ""
      }/${item.kind}/${item[linkProperty]}`;

      const isSame = item.name === item.description;

      let skipLink = false;
      if (options && options.shouldSkipLink) {
        skipLink = options.shouldSkipLink(item);
      }

      return React.useMemo(
        () => (
          <div className="flex flex-col justify-center" style={{ height: 56 }}>
            {skipLink ? <span>{item.name}</span> : <InternalLink label={item.name} to={link} />}
            {isSame ? null : <span className="text-sm description">{item.description}</span>}
          </div>
        ),
        [item.name, item.description],
      );
    },
  };
  return Object.assign(preset, column);
}
