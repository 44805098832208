import { observer } from "mobx-react-lite";
import * as React from "react";
import { HiddenSectionsMobx } from "../../../mobxStores/hiddenSections";
import { Theme } from "../../../mobxStores/uiData/theme";
import { CustomLogoContext } from "../../../mobxStores/consoleContext/customLogoContext";

const LogoRaw: React.FC = () => {
  const [isHovered, setIsHovered] = React.useState(false);
  const isHoveredRef = React.useRef(false);
  const [src, setSrc] = React.useState<string>(getSrc());

  React.useEffect(() => {
    setSrc(getSrc());
  }, [Theme.theme, CustomLogoContext.hasCustomLogo]);

  React.useEffect(() => {
    document.body.addEventListener("keydown", onKeyDown);
    return () => {
      document.body.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  React.useEffect(() => {
    isHoveredRef.current = isHovered;
  }, [isHovered]);

  function onKeyDown(e: KeyboardEvent) {
    if (!isHoveredRef.current) {
      return;
    }

    if (HiddenSectionsMobx.keys.includes(e.key.toLowerCase())) {
      HiddenSectionsMobx.toggle(e.key.toLowerCase());
    }
    return;
  }

  function getSrc() {
    let src = `/resources/logos/controlPlaneLogo.svg`;
    if (Theme.theme === "dark") {
      src = `/resources/logos/controlPlaneLogoWhite.svg`;
    }
    if (CustomLogoContext.hasCustomLogo) {
      src = CustomLogoContext.lightLogo.url;
      if (Theme.theme === "dark") {
        src = CustomLogoContext.darkLogo.url;
      }
    }
    return src;
  }

  return (
    <img
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="object-contain cursor-pointer relative"
      src={src}
      // style={
      //   CustomLogoContext.hasCustomLogo
      //     ? { maxWidth: 95, height: 56, transform: "translateY(-7px)" }
      //     : { maxWidth: 95, height: 35 }
      // }
      style={{ maxWidth: 95, height: 56 }}
      alt="Control Plane Logo"
    />
  );
};

export const Logo = observer(LogoRaw);
