import { flow, types, applySnapshot, Instance } from "mobx-state-tree";
import { request } from "../../../services/cpln";
import { BaseModel } from "../../base";
import { mk8sSpecModel } from "./spec";
import { mk8sStatusModel } from "./status";
import { mk8sHelpers } from "../mk8s.helpers";

export const mk8sModel = types
  .compose(
    "mk8s",
    BaseModel,
    types.model({
      spec: mk8sSpecModel,
      alias: types.optional(types.string, ""),
      status: types.maybe(mk8sStatusModel),
    })
  )
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: any) => Promise<void> = flow(function* (body: any) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      delete body.events;
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  })
  .views((self) => ({
    get provider() {
      const _self = JSON.parse(JSON.stringify(self));
      return mk8sHelpers.getProvider(_self as any);
    },
    get kubernetesVersion() {
      return mk8sHelpers.getKubernetesVersion(self as any);
    },
    get dashboardUrl() {
      return mk8sHelpers.getDashboardUrl(self as any);
    },
    get isAddonLogsEnabled() {
      return mk8sHelpers.getIsAddonLogsEnabled(self as any);
    },
  }));

export interface mk8sMobx extends Instance<typeof mk8sModel> {}
