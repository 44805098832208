import * as React from "react";
import { Table } from "../../../../newcomponents/table/table";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { linksOf } from "../../../../services/cpln";
import { Mk8sCluster } from "../../../../schema/types/mk8s";
import { Mk8sClusterTableItem as Mk8sClusterTableItemBase, mk8sHelpers } from "../../../../mst/kinds/mk8s.helpers";

interface Mk8sClusterTableItem extends Mk8sClusterTableItemBase {
  selfLink: string;
}

interface Props {
  title: string;
  selections: string[];
  onSelectionsChange: (values: string[]) => void;
}
export const KubernetesTable: React.FC<Props> = ({ title, selections, onSelectionsChange }) => {
  const { kind, id } = useTableKindId("mk8s", "policy-create-target-list-mk8s");
  const qData = useTableItemQueryData<Mk8sCluster>(kind, { fetchAllPages: true });

  const mk8sClusterTableItems: Mk8sClusterTableItem[] = qData.visibleItems.map((item) => {
    const provider = mk8sHelpers.getProvider(item);
    const kubernetesVersion = mk8sHelpers.getKubernetesVersion(item);
    const dashboardUrl = mk8sHelpers.getDashboardUrl(item);
    const isAddonLogsEnabled = mk8sHelpers.getIsAddonLogsEnabled(item);
    const selfLink = linksOf(item).self!;

    return {
      ...item,
      provider: provider,
      kubernetesVersion: kubernetesVersion,
      dashboardUrl: dashboardUrl,
      isAddonLogsEnabled: isAddonLogsEnabled,
      selfLink: selfLink,
    };
  });

  return (
    <Table<Mk8sClusterTableItem>
      title={title}
      data={mk8sClusterTableItems}
      tableId={id}
      selectMode="multi"
      selectKey={"selfLink"}
      selections={selections}
      onSelectionsChange={onSelectionsChange}
      headerRenderer={() => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
            <CustomTableCPLNQuery
              tableId={id}
              kind={kind}
              onQuery={qData.setQuery}
              isLoading={qData.isLoadingQueried}
            />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionNoLinkColumn(),
        {
          id: "provider",
          label: "Provider",
          canGroupByDistinctValues: true,
          groupLabelMap: {
            generic: "Generic",
            hetzner: "Hetzner",
            aws: "AWS",
            ephemeral: "Ephemeral",
            linode: "Linode",
            oblivus: "Oblivus",
            lambdalabs: "Lambdalabs",
            paperspace: "Paperspace",
          },
          size: 160,
        },
        { id: "kubernetesVersion", label: "Version", canGroupByDistinctValues: true, enableHide: true, size: 160 },
        TagsColumn(),
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};
