import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { request } from "../services/cpln";
import moment from "moment";

export function useEventlogs(link: string) {
  const [eventlogs, setEventlogs] = React.useState<any[]>([]);
  const requestIdRef = React.useRef("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [hasWarning, setHasWarning] = React.useState(false);
  const [itemLastModified, setItemLastModified] = React.useState("");

  React.useEffect(() => {
    fetchEventlogs();
  }, []);

  function objectToText(obj: any) {
    let textItems: string[] = [];
    for (let [key, value] of Object.entries(obj)) {
      let localValue = value;
      if (value === null) {
        localValue = "null";
      } else if (typeof value === "object") {
        localValue = objectToText(value).join(", ");
      }
      textItems.push(`${key}=${localValue}`);
    }
    return textItems;
  }

  async function fetchEventlogs() {
    const requestId = uuidv4();
    requestIdRef.current = requestId;
    try {
      setIsLoading(true);
      setEventlogs([]);
      const { data } = await request({ url: `${link}/-eventlog` });
      if (requestIdRef.current !== requestId) {
        return;
      }

      setEventlogs((_eventlogs) =>
        _eventlogs.concat(
          (data.items as any[]).map((e) => {
            const contextText = objectToText(e.context).join(", ");

            return {
              id: e.id,
              kind: e.kind,
              created: e.created,
              context: e.context,
              contextText: contextText,
              status: e.status,
            };
          }),
        ),
      );
      setError("");
      setIsLoading(false);
    } catch (e) {
      if (requestIdRef.current !== requestId) {
        return;
      }
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
    }
    checkHasWarning();
  }

  React.useEffect(() => {
    checkHasWarning();
  }, [itemLastModified]);

  function checkHasWarning() {
    const lastModifiedDate = moment(itemLastModified);
    const eventlog = eventlogs[0];
    if (!eventlog) {
      setHasWarning(false);
      return;
    }
    const eventDate = moment(eventlog.created);

    const last10MinDate = moment().subtract(10, "minute");
    if (eventDate.isAfter(lastModifiedDate) && eventDate.isAfter(last10MinDate) && eventlog.status === "errored") {
      setHasWarning(true);
      return;
    }

    setHasWarning(false);
  }

  return { eventlogs, fetchEventlogs, isLoading, error, hasWarning, setItemLastModified };
}
