import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { MoreHorizontal, PlusCircle } from "react-feather";
import { NGKindSelect } from "../../../../newcomponents/select/ngkindselect";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";
import NGAlert from "../../../../newcomponents/alert";
import { NGProviderCreateOpaqueSecret } from "../../ngProviderCreateOpaqueSecret";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { NGLabel } from "../../../../newcomponents/text/label";
import { getDryRunErrorText } from "../../getDryRunErrorText";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderDigitalOceanRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const d = mk8sDraft.provider_digitalocean!;

  const [browserKey, setBrowserKey] = React.useState("");

  const sshKeysDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.digitalocean.sshKeys`,
    paths: d.sshKeys.items.map((_, index) => `spec.provider.digitalocean.sshKeys[${index}]`),
  });

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <div>
            <NGLabel>Region</NGLabel>
            <div className="flex items-center mb-4">
              <div>{d.region.value}</div>
            </div>
          </div>
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={d.region.setValue}
              path={"spec.provider.digitalocean.region"}
            />
          </div>
        </div>

        <div className="mb-4">
          <NGFormLabel required invalid={!d.tokenSecretName}>
            Digital Ocean Token Secret
          </NGFormLabel>
          <div className="mb-2 flex items-start gap-2">
            <NGKindSelect
              name={"digitalocean.tokenSecretName"}
              style={{ width: 450 }}
              value={d.tokenSecretName}
              invalid={!d.tokenSecretName}
              onChange={(value) => d.setTokenSecretName(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "opaque" }]}
              buttons={[
                {
                  title: "Create",
                  render: () => <PlusCircle className="h-4" />,
                  onClick: () => setBrowserKey("createSecret"),
                },
              ]}
            />
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              path={"spec.provider.digitalocean.tokenSecretLink"}
            />
          </div>
          <NGAlert
            style={{ width: 450 }}
            render={() => (
              <div className="flex flex-col text-sm">
                <span>An opaque secret that contains your Digital Ocean token.</span>
                <span>It will be used to manage servers in your Digital Ocean account.</span>
              </div>
            )}
          />
        </div>

        <div className="flex gap-2 items-start">
          <div>
            <NGLabel>VPC ID</NGLabel>
            <div className="flex items-center mb-4">
              <div>{d.vpcId.value}</div>
            </div>
          </div>
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={d.vpcId.setValue}
              path={"spec.provider.digitalocean.vpcId"}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"digitalocean.image"}
            label={d.image.label}
            value={d.image.value}
            onChange={d.image.setValue}
            required={d.image.isRequired}
            error={d.image.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => setBrowserKey("image"),
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={d.image.setValue}
              path={"spec.provider.digitalocean.image"}
            />
          </div>
        </div>

        <NGInputListMst
          data={d.sshKeys}
          required={true}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="Digital SSH Keys"
          labelRender={() => {
            return (
              <>
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("ssh-keys")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && sshKeysDryRun.message.includes(`[${index}]`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          invalid={d.sshKeys.items.length < 1 ? true : sshKeysDryRun.severity === "error"}
          error={
            d.sshKeys.items.length < 1
              ? "At least 1 item is required."
              : sshKeysDryRun.severity === "error"
              ? sshKeysDryRun.message
              : ""
          }
          warning={sshKeysDryRun.severity === "error" ? "" : sshKeysDryRun.message}
        />
      </div>

      {browserKey === "createSecret" ? (
        <NGProviderCreateOpaqueSecret
          onOk={(secretName: string) => d.setTokenSecretName(secretName)}
          onClose={() => setBrowserKey("")}
          title={"Create Digital Ocean Token Secret"}
          dataLabel={"Digital Ocean Token"}
        />
      ) : null}
      {browserKey === "image" ? (
        <NGProviderBrowser
          title="Browse Digital Ocean Images"
          initialValue={d.image.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "images",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.images.sort((a: any, b: any) => {
              if (a.slug > b.slug) {
                return 1;
              }
              if (a.slug < b.slug) {
                return -1;
              }
              return 0;
            });
          }}
          onOk={(value) => d.image.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Availability", "Slug"]}
          tooltips={[
            {
              label: "Availability",
              content: `Shows if the image is available in selected region (${mk8sDraft.provider_digitalocean.region.value})`,
            },
          ]}
          getData={(item: any) => {
            const available = item.regions.includes(mk8sDraft.provider_digitalocean.region.value);

            return [item.slug, item.description, [available, available ? "Available" : "Not Available"], item.slug];
          }}
        />
      ) : null}
      {browserKey === "ssh-keys" ? (
        <NGProviderBrowser
          key={d.tokenSecretName}
          title="Browse Digital Ocean SSH Keys"
          initialValue={d.sshKeys.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ssh_keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.ssh_keys;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (d.sshKeys.items.some((i) => i.firstValue === value)) {
                continue;
              }
              d.sshKeys.add();
              const i = d.sshKeys.items[d.sshKeys.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Name", "ID"]}
          getData={(item: any) => [String(item.id), item.name, String(item.id)]}
        />
      ) : null}
    </>
  );
};

export const ProviderDigitalOcean = observer(ProviderDigitalOceanRaw);
