import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { LoaderSmall } from "../../../../components/layout/loader/small";
import { RemovableValue } from "../../../../components/tag/removableValue";
import { CheckboxWithTooltip } from "../../../../components/generic/checkboxWithTooltip";
import { FixedSizeList as List } from "react-window";
import { IdentityDraftAWSHelper } from "../../../../mst/stores/identity.aws.helper";
import { request } from "../../../../services/cpln";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGRadioGroup } from "../../../../newcomponents/radioGroup";
import NGAlert from "../../../../newcomponents/alert";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { CustomScrollbars } from "../../../../components/Scrollbar";

interface Props {
  aws: IdentityDraftAWSHelper;
}
const AWSSetupModalRaw: React.FC<Props> = ({ aws }) => {
  const draft = aws.draft;
  const [forceInfiniteList, setForceInfiniteList] = React.useState(0);
  const [roleArn, setRoleArn] = React.useState("");

  React.useEffect(() => {
    setRoleArn("");
    if (!draft.cloudaccountName) return;
    request({ url: draft.cloudaccountLink }).then((res) => {
      setRoleArn(res.data.data.roleArn);
    });
  }, [draft.cloudaccountName]);

  function onTogglePolicy(policyRef: string) {
    draft.togglePolicy(policyRef);
    setForceInfiniteList((x) => x + 1);
  }

  function getRoleTrustPolicy() {
    return `{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Principal": {
        "AWS": [
          "${roleArn}"
        ]
      },
      "Action": "sts:AssumeRole"
    }
  ]
}`;
  }

  return (
    <Modal
      title={aws.title}
      width={aws.tableWidth}
      open={aws.isSettingUp}
      closable={false}
      footer={
        <div className="modal-actions">
          <NGButton
            variant="danger"
            data-testid="action-cancel"
            outlined
            onClick={() => aws.cancelDraft()}
            disabled={aws.isLoading}
          >
            Cancel
          </NGButton>
          <div style={{ flexGrow: 1 }} />
          <NGButton
            variant="secondary"
            data-testid="action-back"
            onClick={() => aws.back()}
            disabled={aws.isLoading || aws.step === "general"}
          >
            Back
          </NGButton>
          {aws.step === "general" ? (
            <NGButton
              variant="primary"
              data-testid="action-next"
              onClick={() => aws.next()}
              disabled={aws.isLoading || !draft.isValidPage_General}
            >
              Next
            </NGButton>
          ) : (
            <NGButton
              variant="primary"
              data-testid="action-done"
              onClick={() => aws.done()}
              disabled={aws.isLoading || !draft.isValid}
            >
              Done
            </NGButton>
          )}
        </div>
      }
    >
      {aws.isLoading ? (
        <div className="flex items-center justify-center flex-col" style={{ height: 300 }}>
          <LoaderSmall />
          <div className="mt-2">Please wait. Loading {draft.method === "existing" ? "AWS Roles" : "AWS Policies"}.</div>
        </div>
      ) : null}
      {!aws.isLoading && aws.step === "general" ? (
        <div style={{ height: 300 }}>
          <NGFormElement
            name="cloudaccount"
            as="kindselect"
            kind="cloudaccount"
            label="Cloud Account"
            value={draft.cloudaccountName}
            onChange={draft.setCloudaccountName}
            kindSelectProps={{ queries: [{ property: "provider", value: "aws" }] }}
          />
          <NGRadioGroup
            isVertical
            label="Method"
            value={draft.method}
            onChange={(value) => draft.setMethod(value as "existing" | "new")}
            isDisabled={aws.isRadioDisabled}
            options={[
              { value: "existing", label: "Use an Existing AWS Role" },
              { value: "new", label: "Select Existing AWS Policies" },
            ]}
          />
        </div>
      ) : null}
      {!aws.isLoading && aws.step === "config" && draft.method === "existing" && draft.browserService!.canReach ? (
        <div style={{ height: 420 }}>
          <NGAlert
            className="mb-4"
            type={"info"}
            render={() => (
              <>
                <div>
                  Make sure the role has a <span className="font-semibold">Trust Policy</span> allowing{" "}
                  <span className="font-semibold">{roleArn}</span> to assume it.
                </div>
                <NGButton
                  variant={"primary"}
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(getRoleTrustPolicy());
                    notification.success({ message: "Copied to Clipboard" });
                  }}
                >
                  Copy Trust Policy
                </NGButton>
              </>
            )}
          />
          <div className="flex mb-2">
            <NGInput
              className="flex-grow mr-2"
              value={draft.roleInput.value}
              onChange={(e) => draft.roleInput.setValue(e.target.value)}
              disabled={!draft.useManualInput}
            />
            {draft.useManualInput ? (
              <NGButton
                variant={"primary"}
                style={{ width: 120 }}
                size={"small"}
                onClick={() => {
                  draft.setUseManualInput(false);
                }}
              >
                Confirm
              </NGButton>
            ) : (
              <NGButton
                variant={"primary"}
                style={{ width: 120 }}
                size={"small"}
                onClick={() => {
                  draft.setUseManualInput(true);
                }}
              >
                Edit Manually
              </NGButton>
            )}
          </div>
          <NGInput
            className="mb-2 w-full"
            value={draft.filterInput.value}
            placeholder={draft.filterInput.label}
            onChange={(e) => draft.filterInput.setValue(e.target.value)}
            disabled={draft.useManualInput}
          />
          <CustomScrollbars style={{ height: 180 }}>
            <NGRadioGroup
              isVertical
              value={draft.roleInput.value}
              onChange={(value) => draft.selectRole(value)}
              options={draft.filteredRoles.map((role) => ({
                value: role._cpln.ref!,
                label: role._cpln.label!,
                isDisabled: draft.useManualInput,
              }))}
            />
          </CustomScrollbars>
        </div>
      ) : null}
      {!aws.isLoading && aws.step === "config" && draft.method === "existing" && !draft.browserService!.canReach ? (
        <div style={{ height: 300 }}>
          <div className={`mb-4`}>
            <div>Failed to browse the cloud with this cloud account.</div>
            <div>
              You need to type the <span className=" ">AWS Role Name</span> manually.
            </div>
          </div>
          <NGAlert
            className="mb-4"
            type={"info"}
            render={() => (
              <>
                <div>
                  Make sure the role has a <span className=" ">Trust Policy</span> allowing{" "}
                  <span className=" ">{roleArn}</span> to assume it.
                </div>
                <NGButton
                  size="small"
                  variant={"primary"}
                  onClick={() => {
                    navigator.clipboard.writeText(getRoleTrustPolicy());
                    notification.success({ message: "Copied to Clipboard" });
                  }}
                >
                  Copy Trust Policy
                </NGButton>
              </>
            )}
          />
          <NGInput
            style={{ marginBottom: 8 }}
            value={draft.roleInput.value}
            placeholder={draft.roleInput.label}
            onChange={(e) => draft.roleInput.setValue(e.target.value)}
          />
        </div>
      ) : null}

      {!aws.isLoading &&
      aws.step === "config" &&
      draft.method === "new" &&
      draft.browserService!.canReach &&
      !draft.useManualInput ? (
        <>
          <div className={`mb-2 flex items-center justify-between`}>
            <div>You can also set the AWS Policies manually.</div>
            <NGButton
              style={{ width: 220, marginLeft: 10 }}
              variant={"action"}
              onClick={() => {
                draft.setUseManualInput(true);
              }}
            >
              Set Policies Manually
            </NGButton>
          </div>
          <div className="flex items-center mb-2">
            <NGInput
              className="flex-grow mr-2"
              value={draft.filterInput.value}
              placeholder={draft.filterInput.label}
              onChange={(e) => draft.filterInput.setValue(e.target.value)}
            />
            <NGButton
              variant={"secondary"}
              style={{ width: 180 }}
              onClick={() => {
                aws.refreshPolicyList();
              }}
            >
              Refresh List
            </NGButton>
          </div>
          <div style={{ height: 350 }} className="overflow-auto">
            <List
              itemData={draft.filteredPolicies}
              height={350}
              itemCount={draft.filteredPolicies.length}
              itemSize={22}
              width={952}
            >
              {({ data, index, style }) => {
                const policy = data[index];
                const checked = draft.policies.includes(policy._cpln.ref!);
                return (
                  <CheckboxWithTooltip
                    style={style}
                    key={policy._cpln.ref! + forceInfiniteList}
                    onChange={() => {
                      onTogglePolicy(policy._cpln.ref!);
                    }}
                    checked={checked}
                    label={policy._cpln.label!}
                  />
                );
              }}
            </List>
          </div>
        </>
      ) : null}
      {!aws.isLoading &&
      aws.step === "config" &&
      draft.method === "new" &&
      (!draft.browserService!.canReach || draft.useManualInput) ? (
        <>
          {draft.useManualInput ? (
            <div className={`mb-2 flex items-center justify-between`}>
              <div>You can select the AWS Policies from list.</div>
              <NGButton
                style={{ width: 220, marginLeft: 10 }}
                variant={"action"}
                onClick={() => {
                  draft.setUseManualInput(false);
                }}
              >
                Set Policies From List
              </NGButton>
            </div>
          ) : (
            <div className={`mb-2`}>
              <div>Failed to browse the cloud with this cloud account.</div>
              <div>
                You need to type <span className=" ">AWS Policies</span> manually.
              </div>
            </div>
          )}
          <NGAlert className="mb-6" type={"info"} message={"If the policy is AWS managed, use 'aws::' prefix"} />
          <div className="mb-2">
            {draft.policies.map((policy) => (
              <RemovableValue
                className="w-full"
                key={policy}
                value={policy}
                onRemove={() => {
                  draft.removePolicy(policy);
                }}
              />
            ))}
          </div>
          <div className="flex">
            <NGInput
              value={draft.policyInput.value}
              placeholder={draft.policyInput.label}
              onChange={(e) => draft.policyInput.setValue(e.target.value)}
              className="flex-grow mr-2"
            />
            <NGButton
              variant="action"
              disabled={draft.policyInput.value.length < 1 || !draft.policyInput.isValid}
              onClick={() => {
                draft.addPolicy();
              }}
            >
              Add
            </NGButton>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export const AWSSetupModal = observer(AWSSetupModalRaw);
