import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";
import { ItemStoreQueryMobx } from "../../../mst/query";
import { TabsLayout, TabsLayoutLink } from "../../../components/tabsLayout/tabsLayout";
import { GroupCreateUsers } from "./member-users";
import { GroupCreateServiceaccounts } from "./member-serviceaccounts";
import { GroupCreateUserQuery } from "./member-user-query";

interface Props {
  useQuery: boolean;
  setUseQuery: (value: boolean) => void;
  userQuery: ItemStoreQueryMobx;
  setUserLinks: (links: string[]) => void;
  setServiceAccountLinks: (links: string[]) => void;
}
const GroupCreateMembersRaw: React.FC<Props> = ({
  useQuery,
  setUseQuery,
  userQuery,
  setUserLinks,
  setServiceAccountLinks,
}) => {
  const links: TabsLayoutLink[] = [
    { url: `-users`, label: "Users" },
    { url: `-serviceaccounts`, label: "Service Accounts" },
    { url: `-userquery`, label: "User Query" },
  ];

  return (
    <TabsLayout links={links}>
      <Routes>
        <Route index element={<Navigate to={`-users`} />} />
        <Route path={`-users/*`} element={<GroupCreateUsers setUserLinks={setUserLinks} />} />
        <Route
          path={`-serviceaccounts/*`}
          element={<GroupCreateServiceaccounts setServiceAccountLinks={setServiceAccountLinks} />}
        />
        <Route
          path={`-userquery/*`}
          element={<GroupCreateUserQuery useQuery={useQuery} setUseQuery={setUseQuery} userQuery={userQuery} />}
        />
      </Routes>
    </TabsLayout>
  );
};

export const GroupCreateMembers = observer(GroupCreateMembersRaw);
