import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Events } from "../../components/detail/events";
import { AgentMobx } from "../../mst/kinds/agent";
import { Info } from "./info";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { Used } from "./used";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Agent } from "../../schema/types/agent";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Tags } from "../../components/detail/tags";

interface Props {
  agent: Agent;
  agentMobx: AgentMobx;
}
const AgentDetailRaw: React.FC<Props> = ({ agent, agentMobx }) => {
  const basePath = useBasePath("/agent/:agent/*");

  const eventlogHook = useEventlogs(agentMobx.selfLink);

  const [links] = React.useState([
    { label: "Info", url: "-info" },
    { label: "Used in Identities", url: "-identities" },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ]);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info agent={agentMobx} />} />
        <Route
          path={`-identities`}
          element={<Used key={ConsoleContext.gvc || ""} agent={agent} gvc={ConsoleContext.gvc || ""} />}
        />
        <Route path={`-tags`} element={<Tags link={agentMobx.selfLink} tags={agent.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"agent"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="agent" resourceId={agentMobx.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={agentMobx.selfLink} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const AgentDetail = observer(AgentDetailRaw);
