import { LoggingDatadogMobx } from "../../../../mst/kinds/org";
import { defaultValues } from "../../../../mst/base";
import { SelectModel } from "../../../../mobxDataModels/selectModel";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";

export function getLogsDatadogData(datadog: LoggingDatadogMobx) {
  function getDefaultDatadogHost() {
    return datadog.host || defaultValues.org.logging.datadog.host;
  }
  const datadog_host = SelectModel.create({
    label: "Host",
    initialValue: getDefaultDatadogHost(),
    options: [
      { label: "http-intake.logs.datadoghq.com", value: "http-intake.logs.datadoghq.com" },
      { label: "http-intake.logs.us3.datadoghq.com", value: "http-intake.logs.us3.datadoghq.com" },
      { label: "http-intake.logs.us5.datadoghq.com", value: "http-intake.logs.us5.datadoghq.com" },
      { label: "http-intake.logs.datadoghq.eu", value: "http-intake.logs.datadoghq.eu" },
    ],
  });
  function getDefaultDatadogCredentials() {
    return ngParseLink(datadog.credentials || "", { kind: "secret" }).name;
  }

  const datadog_credentials = StringModel.create({
    isRequired: true,
    label: "Credentials",
    initialValue: getDefaultDatadogCredentials(),
  });
  function isDirty() {
    let res = false;
    if (getDefaultDatadogHost() !== datadog_host.value) {
      res = true;
    }
    if (getDefaultDatadogCredentials() !== datadog_credentials.value) {
      res = true;
    }
    return res;
  }

  function isValid() {
    let res = true;
    if (datadog_credentials.value.length < 1) {
      res = false;
    }
    return res;
  }

  function asObject() {
    return {
      datadog: {
        host: datadog_host.value,
        credentials: ngParseLink(datadog_credentials.value, { kind: "secret" }).absolute,
      },
    };
  }

  return {
    logProvider: "datadog",
    host: datadog_host,
    credentials: datadog_credentials,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
