export const addressCountries: string[] = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Democratic People's Republic of Korea",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Islamic Republic of Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Federated States of Micronesia",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Republic of Korea",
  "Republic of Moldova",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Tajikistan",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United Republic of Tanzania",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  // Below are US Territories
  "Puerto Rico",
  "Guam",
  "US Virgin Islands",
  "Northern Mariana Islands",
  "American Samoa",
  "Midway Atoll",
  "Palmyra Atoll",
  "Baker Island",
  "Howland Island",
  "Jarvis Island",
  "Johnston Atoll",
  "Kingman Reef",
  "Wake Island",
  "Navassa Island",
  // Below are UK Territories
  "Anguilla",
  "Bermuda",
  "British Antarctic Territory",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Cayman Islands",
  "Falkland Islands",
  "Gibraltar",
  "Montserrat",
  "Pitcairn Island, Henderson, Ducie and Oeno Islands",
  "St Helena, Ascension and Tristan da Cunha",
  "Sovereign Base Areas",
  "South Georgia and South Sandwich Islands",
  "Turks and Caicos Islands",
  // Below are AUS Territories
  "Ashmore and Cartier Islands",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Coral Sea Islands",
  "Heard and McDonald Islands",
  "Norfolk Island",
  // Below are China Territories
  "Hong Kong",
  "Macau",
  // Below are Denmark Territories
  "Faroe Islands",
  "Greenland",
  // Below are France Territories
  "Bassas da India",
  "Clipperton Island",
  "Europa Island",
  "French Guiana	",
  "French Polynesia	",
  "French Southern and Antarctic Lands	",
  "Glorioso Islands",
  "Guadeloupe	",
  "Juan de Nova Island",
  "Martinique	",
  "Mayotte	",
  "New Caledonia	",
  "Réunion	",
  "Saint Pierre and Miquelon",
  "Tromelin Island",
  "Wallis and Futuna",
  // Below are Netherlands Territories
  "Aruba",
  "Curaçao",
  "Sint Maarten",
  "Netherlands Antilles",
  // Below are New Zealand Territories
  "Cook Islands",
  "Niue",
  "Tokelau",
  // Below are Norway Territories
  "Bouvet Island",
  "Jan Mayen (Isl)",
  "Svalbard",
];
