import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import NGAlert from "../../../newcomponents/alert";
import { ExternalLink } from "../../../newcomponents/table/components/ExternalLink";
import { DOCS_URL } from "../../../envVariables";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsLocalPathStorageRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <div>
      <div>Local Path Storage</div>
      <NGAlert
        className="mt-4"
        type={"info"}
        title="Documentation"
        render={() => {
          return (
            <div className="flex items-end gap-1">
              <ExternalLink url={`${DOCS_URL}/mk8s/add-ons/local_path_storage`} label={`Click here`} disableCopy />
              <div>for learning how to configure the addon.</div>
            </div>
          );
        }}
      />
    </div>
  );
};

export const AddonsLocalPathStorage = observer(AddonsLocalPathStorageRaw);
