import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderNetworking } from "../../../advanced/providerNetworking";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderGenericAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const g = mk8sDraft.provider_generic!;

  return (
    //
    <div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={g.networking} />
      </div>
    </div>
  );
};

export const Mk8sCreateProviderGenericAdvanced = observer(Mk8sCreateProviderGenericAdvancedRaw);
