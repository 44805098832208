import * as React from "react";
import { PolicyMobx } from "../../mst/kinds/policy";
import { StringModel } from "../../mobxDataModels/stringModel";
import { notification } from "antd";
import { pluralNameOfKind } from "../../services/cpln";
import { observer } from "mobx-react-lite";
import { FormElement } from "../../components/forms/formElement";
import { FormButtons } from "../../components/forms/formButtons";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { useDetailContext } from "../../components/detail/detailContext";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGSwitch } from "../../newcomponents/switch";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  policy: PolicyMobx;
}
const InfoRaw: React.FC<Props> = ({ policy }) => {
  const { fetchItem } = useDetailContext();
  const { org } = ConsoleContext;

  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const descriptionRef = React.useRef(StringModel.create({ label: "Description", initialValue: policy.description }));
  function getDefaultTargetAll(): boolean {
    return policy.target === "all";
  }
  const [targetAll, setTargetAll] = React.useState(getDefaultTargetAll());

  React.useEffect(() => {
    PromptContext.setWhen(descriptionRef.current.isDirty || targetAll !== getDefaultTargetAll() || isLoading);
  }, [targetAll, descriptionRef.current.isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (descriptionRef.current.isDirty) res = true;
    if (targetAll !== getDefaultTargetAll()) res = true;
    setIsDirty(res);
  }, [policy.version, targetAll, descriptionRef.current.isDirty]);

  function reset() {
    setTargetAll(getDefaultTargetAll());
    descriptionRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const body: any = {
        description: descriptionRef.current.value || null,
      };
      if (targetAll) {
        body.target = "all";
      } else if (getDefaultTargetAll()) {
        body.target = null;
        if (policy.targetLinks.length < 1) {
          body.targetLinks = [];
        }
      }
      await policy.patch(body);
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(policy.name);
      }
      notification.success({
        message: "Success",
        description: "Updated policy",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div>
        <div className="mb-4">
          {policy.origin === "builtin" ? (
            <FormElement label={"Description"} value={policy.description} />
          ) : (
            <NGInputAdapter style={{ width: 450 }} data={descriptionRef.current} />
          )}
        </div>
        {["org", "task"].includes(policy.targetKind) ? null : (
          <NGSwitch
            className="mb-4"
            value={targetAll}
            onChange={(value) => setTargetAll(value)}
            isDisabled={policy.origin === "builtin"}
          >
            <NGLabelText>Target All Items</NGLabelText>
          </NGSwitch>
        )}
        <div className="flex mb-4">
          <div className="w-3/12">
            <FormElement label={"Target Kind"} value={policy.targetKind} />
          </div>
          <div className="w-3/12">
            <FormElement
              label={"Applies To"}
              value={
                policy.targetKind === "org"
                  ? org!
                  : policy.target === "all"
                  ? `All ${pluralNameOfKind(policy.targetKind)}`
                  : `Subset of ${pluralNameOfKind(policy.targetKind)}`
              }
            />
          </div>
          <div className="w-3/12">
            <FormElement label={"Uses Query"} value={policy.targetQuery ? "Yes" : "No"} />
          </div>
          <div className="w-3/12">
            <FormElement label={"Targets All"} value={policy.target === "all" ? "Yes" : "No"} />
          </div>
        </div>
        <TagLinksTable
          tableId="policy-info-tag-links"
          tags={Object.entries(policy.tags || {})
            .map(([tagKey, tagValue]) => ({ key: tagKey, value: String(tagValue || "") }))
            .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
          styles={{ header: { marginBottom: 0 } }}
        />
        <FormButtons
          onReset={reset}
          onSave={save}
          resetDisabled={isLoading || !isDirty}
          saveDisabled={isLoading || !isDirty}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export const Info = observer(InfoRaw);
