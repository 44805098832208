import * as React from "react";
import { observer } from "mobx-react-lite";
import { FormLabel } from "../../../../components/forms/formLabel";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { InfoTooltip } from "../../../../components/InfoTooltip";
import { NGLabel } from "../../../../newcomponents/text/label";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import NGAlert from "../../../../newcomponents/alert";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderGenericRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const g = mk8sDraft.provider_generic;

  if (!g) {
    return null;
  }

  return (
    //
    <>
      <div className="flex flex-col gap-4">
        <div>
          <FormLabel>Provider</FormLabel>
          <span>Generic</span>
        </div>
        <div>
          <NGLabel>Location</NGLabel>
          <div className="flex items-center mb-4">
            <div>{g.location.value}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ProviderGeneric = observer(ProviderGenericRaw);
