import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Mk8sDraftProviderAWSAMIModel, Mk8sDraftProviderAWSAMIReadOnlyModel } from "./mk8s.draft.provider.aws.ami";
import {
  Mk8sDraftProviderAWSNodePoolsModel,
  Mk8sDraftProviderAWSNodePoolsReadonlyModel,
} from "./mk8s.draft.provider.aws.nodePool";
import { Mk8sDraftAutoscalerModel } from "./mk8s.draft.providerAutoscaler";
import { Mk8sDraftNetworkingModel } from "./mk8s.draft.providerNetworking";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { inputValidations } from "../../mobxDataModels/validations";
import { AWSProviderDeployRoleChain } from "../../schema/types/mk8sAws";

export const Mk8sDraftProviderAWSModel = types
  .model({
    _awsAccountId: types.optional(StringModel, () => StringModel.create({ label: "AWS Account ID" })),
    _instanceTypes: types.array(types.frozen()),
    _instanceTypesRequestId: types.optional(types.string, ""),
    _preInstallScript: types.optional(types.string, ""),
    preInstallScript: types.optional(StringModel, () => StringModel.create({ label: "Pre Install Script" })),
    _awsTags: types.optional(types.frozen(), {}),
    awsTags: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _skipCreateRoles: types.optional(types.boolean, false),
    skipCreateRoles: types.optional(types.boolean, false),
    _region: types.optional(types.string, ""),
    region: types.optional(StringModel, () =>
      StringModel.create({
        label: "Region",
        isRequired: true,
      }),
    ),
    _image: types.optional(Mk8sDraftProviderAWSAMIReadOnlyModel, () => Mk8sDraftProviderAWSAMIReadOnlyModel.create()),
    image: types.optional(Mk8sDraftProviderAWSAMIModel, () => Mk8sDraftProviderAWSAMIModel.create()),
    _deployRoleArn: types.optional(types.string, ""),
    deployRoleArn: types.optional(StringModel, () =>
      StringModel.create({ label: "Deploy Role ARN", isRequired: true, validationKey: "mk8sAwsDeployRoleArn" }),
    ),
    _vpcId: types.optional(types.string, ""),
    vpcId: types.optional(StringModel, () =>
      StringModel.create({ label: "VPC ID", isRequired: true, validationKey: "mk8sAwsVpcId" }),
    ),
    _keyPair: types.optional(types.string, ""),
    keyPair: types.optional(StringModel, () => StringModel.create({ label: "Key Pair" })),
    _diskEncryptionKeyArn: types.optional(types.string, ""),
    diskEncryptionKeyArn: types.optional(StringModel, () =>
      StringModel.create({ label: "Disk Encryption Key ARN", validationKey: "mk8sAwsDiskEncryptionKeyArn" }),
    ),
    securityGroupIds: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    deployRoleChain: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    extraNodePolicies: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _nodePools: types.array(Mk8sDraftProviderAWSNodePoolsReadonlyModel),
    nodePools: types.array(Mk8sDraftProviderAWSNodePoolsModel),
    autoscaler: types.optional(Mk8sDraftAutoscalerModel, () => Mk8sDraftAutoscalerModel.create()),
    networking: types.optional(Mk8sDraftNetworkingModel, () => Mk8sDraftNetworkingModel.create()),
    removed: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get awsTagsAsObject() {
      let res: any = {};
      for (let item of self.awsTags.items) {
        res[item.firstValue] = item.secondValue;
      }
      return res;
    },
  }))
  .actions((self) => ({
    reset() {
      self.preInstallScript.setInitialValue(self._preInstallScript);
      self.skipCreateRoles = self._skipCreateRoles;
      self.region.setInitialValue(self._region);

      const imageType = !!self._image.exact ? "exact" : "recommended";
      self.image = Mk8sDraftProviderAWSAMIModel.create({
        recommended: self._image.recommended,
        exact: StringModel.create({ label: "Exact Image", initialValue: self._image.exact }),
        type: imageType,
      });

      self.awsTags = ListOfItemsModel.create({
        _items: Object.entries(self._awsTags).map((entry) => ({
          firstValue: entry[0],
          secondValue: entry[1] as string,
        })),
      });

      self.deployRoleArn.setInitialValue(self._deployRoleArn);
      self.vpcId.setInitialValue(self._vpcId);
      self.keyPair.setInitialValue(self._keyPair);
      self.diskEncryptionKeyArn.setInitialValue(self._diskEncryptionKeyArn);
      self.securityGroupIds.reset();
      self.deployRoleChain.reset();
      self.extraNodePolicies.reset();

      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftProviderAWSNodePoolsModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          }),
        );
      }
      self.autoscaler.reset();
      self.networking.reset();
      self.removed = false;
    },
    confirm() {
      self._preInstallScript = self.preInstallScript.value;
      self.preInstallScript.confirm();
      self._skipCreateRoles = self.skipCreateRoles;
      self.region.confirm();
      self._region = self.region.value;
      self._image = {
        recommended: self.image.recommended,
        exact: self.image.exact.value,
      };

      self._awsTags = self.awsTagsAsObject;
      self.awsTags.confirm();

      self.deployRoleArn.confirm();
      self._deployRoleArn = self.deployRoleArn.value;
      self.keyPair.confirm();
      self._keyPair = self.keyPair.value;
      self.vpcId.confirm();
      self._vpcId = self.vpcId.value;
      self.diskEncryptionKeyArn.confirm();
      self._diskEncryptionKeyArn = self.diskEncryptionKeyArn.value;
      self.securityGroupIds.confirm();
      self.deployRoleChain.confirm();
      self.extraNodePolicies.confirm();

      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftProviderAWSNodePoolsReadonlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
            instanceTypes: nodePool.instanceTypes.items.map((i) => i.firstValue),
            overrideImage: {
              recommended: nodePool.overrideImage.recommended,
              exact: nodePool.overrideImage.exact.value,
            },
            bootDiskSize: Number(nodePool.bootDiskSize.value),
            minSize: String(nodePool.minSize.value).length > 0 ? Number(nodePool.minSize.value) : undefined,
            maxSize: String(nodePool.maxSize.value).length > 0 ? Number(nodePool.maxSize.value) : undefined,
            onDemandBaseCapacity:
              String(nodePool.onDemandBaseCapacity.value).length > 0
                ? Number(nodePool.onDemandBaseCapacity.value)
                : undefined,
            onDemandPercentageAboveBaseCapacity: Number(nodePool.onDemandPercentageAboveBaseCapacity.value),
            spotAllocationStrategy: nodePool.spotAllocationStrategy.value,
            subnetIds: nodePool.subnetIds.items.map((i) => i.firstValue),
            extraSecurityGroupIds: nodePool.extraSecurityGroupIds.items.map((i) => i.firstValue),
          }),
        );
      }
      self.autoscaler.confirm();
      self.networking.confirm();
      self.removed = false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }
      self.nodePools.push(
        Mk8sDraftProviderAWSNodePoolsModel.create({
          index,
          status: "added",
        }),
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.nodePools.remove(node);
    },
    setSkipCreateRoles(value: boolean) {
      self.skipCreateRoles = value;
    },
  }))
  .views((self) => ({
    isNodePoolNameValid(index: number) {
      let res = true;
      const node = self.nodePools.find((np) => np.index === index)!;
      const pools = [...self.nodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyNodePoolNameConflicts() {
      for (const nodePool of self.nodePools) {
        if (
          [...self.nodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value,
          )
        ) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isAwsTagsValid() {
      let res = true;
      if (self.awsTags.items.length > 10) res = false;
      for (const item of self.awsTags.items) {
        if (item.firstValue.length < 1) {
          res = false;
          break;
        }
        if (item.firstValue.length > 128) {
          res = false;
          break;
        }
        if (item.secondValue.length > 256) {
          res = false;
          break;
        }
        // TODO move to a validate function
        for (const validate of inputValidations.mk8sAwsTags) {
          const validationResKey = validate("", item.firstValue);
          const validationResValue = item.secondValue ? validate("", item.secondValue) : true;
          if (validationResKey !== true || validationResValue !== true) {
            res = false;
            break;
          }
        }
      }
      return res;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._nodePools.length !== self.nodePools.length) res = true;
      if (self.removed) res = true;
      if (self.nodePools.some((n) => n.isDirty)) res = true;
      if (self.region.isDirty) res = true;

      const initialImageType = !!self._image.exact ? "exact" : "recommended";
      if (initialImageType !== self.image.type) res = true;
      if (self.image.type === "exact") {
        if (self.image.exact.value !== self._image.exact) res = true;
      } else if (self.image.type === "recommended") {
        if (self.image.recommended !== self._image.recommended) res = true;
      }

      if (self.awsTags.isDirty) res = true;
      if (self.deployRoleArn.isDirty) res = true;
      if (self.vpcId.isDirty) res = true;
      if (self.keyPair.isDirty) res = true;
      if (self.diskEncryptionKeyArn.isDirty) res = true;
      if (self.securityGroupIds.isDirty) res = true;
      if (self.deployRoleChain.isDirty) res = true;
      if (self.extraNodePolicies.isDirty) res = true;

      if (self.preInstallScript.isDirty) res = true;
      if (self._skipCreateRoles !== self.skipCreateRoles) res = true;

      if (self.autoscaler.isDirty) res = true;
      if (self.networking.isDirty) res = true;

      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self._nodePools.length !== self.nodePools.length) reason = "node pools length is different";
      if (self.removed) reason = "removed a pool";
      if (self.nodePools.some((n) => n.isDirty)) reason = self.nodePools.find((n) => n.isDirty)?.dirtyReason || "";
      if (self.region.isDirty) reason = "region";

      const initialImageType = !!self._image.exact ? "exact" : "recommended";
      if (initialImageType !== self.image.type) reason = "image.type";
      if (self.image.type === "exact") {
        if (self.image.exact.value !== self._image.exact) reason = "image.exact";
      } else if (self.image.type === "recommended") {
        if (self.image.recommended !== self._image.recommended) reason = "image.recommended";
      }

      if (self.awsTags.isDirty) reason = "aws tags";
      if (self.deployRoleArn.isDirty) reason = "deploy role arn";
      if (self.vpcId.isDirty) reason = "vpc id";
      if (self.keyPair.isDirty) reason = "key pair";
      if (self.diskEncryptionKeyArn.isDirty) reason = "disk encryption key arn";
      if (self.securityGroupIds.isDirty) reason = "security group ids";
      if (self.deployRoleChain.isDirty) reason = "deployRoleChain";
      if (self.extraNodePolicies.isDirty) reason = "extraNodePolicies";

      if (self.preInstallScript.isDirty) reason = "pre install script arn";
      if (self._skipCreateRoles !== self.skipCreateRoles) reason = "skip create roles";

      if (self.autoscaler.isDirty) reason = self.autoscaler.dirtyReason;
      if (self.networking.isDirty) reason = self.networking.dirtyReason;

      return reason;
    },
    get isValid() {
      let res = true;
      if (self.anyNodePoolNameConflicts) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      if (!self.region.isValid) res = false;
      const anyImage = self.image.recommended || self.image.exact.value;
      if (!anyImage) res = false;

      if (!self.isAwsTagsValid) res = false;
      if (!self.deployRoleArn.isValid) res = false;
      if (!self.vpcId.isValid) res = false;
      if (!self.keyPair.isValid) res = false;
      if (!self.diskEncryptionKeyArn.isValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.anyNodePoolNameConflicts) reason = "node pool name conflict";
      if (self.nodePools.some((n) => !n.isValid)) reason = self.nodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (!self.region.isValid) reason = "region";
      const anyImage = self.image.recommended || self.image.exact.value;
      if (!anyImage) reason = "no image";
      if (!self.isAwsTagsValid) reason = "awsTags";
      if (!self.deployRoleArn.isValid) reason = "deployRoleArn";
      if (!self.vpcId.isValid) reason = "vpcId";
      if (!self.keyPair.isValid) reason = "keyPair";
      if (!self.diskEncryptionKeyArn.isValid) reason = "diskEncryptionKeyArn";
      if (!self.preInstallScript.isValid) reason = "pre install script";
      if (!self.autoscaler.isValid) reason = self.autoscaler.invalidReason;
      if (!self.networking.isValid) reason = self.networking.invalidReason;
      return reason;
    },
    get ui_isAwsValid() {
      let res = true;
      if (!self.region.isValid) res = false;
      const anyImage = self.image.recommended || self.image.exact.value;
      if (!anyImage) res = false;
      if (!self.deployRoleArn.isValid) res = false;
      if (!self.vpcId.isValid) res = false;
      if (!self.keyPair.isValid) res = false;
      if (!self.diskEncryptionKeyArn.isValid) res = false;
      return res;
    },
    get dryRun_awsPathList() {
      return [
        //
        "spec.provider.aws.region",
        "spec.provider.aws.image",
        "spec.provider.aws.image.recommended",
        "spec.provider.aws.image.exact",
        "spec.provider.aws.deployRoleArn",
        "spec.provider.aws.vpcId",
        "spec.provider.aws.keyPair",
        "spec.provider.aws.diskEncryptionKeyArn",
        "spec.provider.aws.securityGroupIds",
        ...self.securityGroupIds.items.map((_, index) => `spec.provider.aws.securityGroupIds[${index}]`),
      ];
    },
    get ui_isAdvancedValid() {
      let res = true;
      if (!self.isAwsTagsValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      return res;
    },
    get dryRun_advancedPathList() {
      return [
        //
        "spec.provider.aws.awsTags",
        "spec.provider.aws.preInstallScript",
        "spec.provider.aws.skipCreateRoles",
        ...self.autoscaler.dryRun_pathList("aws"),
        ...self.networking.dryRun_pathList("aws"),
        "spec.provider.aws.deployRoleChain",
        ...self.deployRoleChain.items.map((_, index) => `spec.provider.aws.deployRoleChain[${index}]`),
        ...self.deployRoleChain.items.map((_, index) => `spec.provider.aws.deployRoleChain[${index}].roleArn`),
        ...self.deployRoleChain.items.map((_, index) => `spec.provider.aws.deployRoleChain[${index}].externalId`),
        ...self.deployRoleChain.items.map(
          (_, index) => `spec.provider.aws.deployRoleChain[${index}].sessionNamePrefix`,
        ),
        "spec.provider.aws.extraNodePolicies",
        ...self.extraNodePolicies.items.map((_, index) => `spec.provider.aws.extraNodePolicies[${index}]`),
      ];
    },
    get asObject() {
      let obj: any = {
        region: self.region.value,
        deployRoleArn: self.deployRoleArn.value,
        vpcId: self.vpcId.value,
        securityGroupIds: self.securityGroupIds.items.map((i) => i.firstValue),
        preInstallScript: self.preInstallScript.value,
        awsTags: self.awsTagsAsObject,
        skipCreateRoles: self.skipCreateRoles,
        autoscaler: self.autoscaler.asObject,
        nodePools: self.nodePools.map((n) => n.asObject),
        networking: self.networking.asObject,
      };

      if (self.image.type === "exact") {
        obj.image = { exact: self.image.exact.value };
      } else if (self.image.type === "recommended") {
        obj.image = { recommended: self.image.recommended };
      }

      if (self.keyPair.value) {
        obj.keyPair = self.keyPair.value;
      }

      if (self.diskEncryptionKeyArn.value) {
        obj.diskEncryptionKeyArn = self.diskEncryptionKeyArn.value;
      }

      if (self.deployRoleChain.items.length > 0) {
        obj.deployRoleChain = self.deployRoleChain.items.map((i) => {
          const v: AWSProviderDeployRoleChain = {
            roleArn: i.firstValue,
          };
          if (i.secondValue) {
            v.externalId = i.secondValue;
          }
          if (i.thirdValue) {
            v.sessionNamePrefix = i.thirdValue;
          }
          return v;
        });
      }

      if (self.extraNodePolicies.items.length > 0) {
        obj.extraNodePolicies = self.extraNodePolicies.items.map((i) => i.firstValue);
      }
      return obj;
    },
  }));
export interface Mk8sDraftProviderAWSMobx extends Instance<typeof Mk8sDraftProviderAWSModel> {}
