import * as React from "react";
import { notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { CreateFormLink } from "../../org/create/create";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { NameModel } from "../../../mobxDataModels/nameModel";
import { AgentCreateGeneral } from "./general";
import { BootstrapConfigModal } from "./bootstrapConfigModal";
import { homeLink, request } from "../../../services/cpln";
import { TagsNewModel } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";

const AgentCreateRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const basePath = useBasePath("/agent/-create/*");

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [createdAgent, setCreatedAgent] = React.useState<any>(null as any);
  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));
  const tagsRef = React.useRef(TagsNewModel.create());

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  function onDone(agentLink: string) {
    PromptContext.setIsDisabled(true);
    notification.success({
      message: "Success",
      description: "Agent is created",
    });
    navigate(`/console${agentLink}`);
  }

  function getAgentObject() {
    const body: any = { name: nameRef.current.value, tags: tagsRef.current.asObject };
    if (descriptionRef.current.value) {
      body.description = descriptionRef.current.value;
    } else {
      body.description = nameRef.current.value;
    }
    return { kind: "agent", ...body };
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      const { data: agent } = await request({ method: "post", url: homeLink("agent"), body: getAgentObject() });
      setCreatedAgent(agent as any);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/agent/-create");
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    {
      name: "Tags",
      value: `-tags`,
      isActive: nameRef.current.isValid,
    },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("-general")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  return (
    <>
      <BasePathContext.Provider value={basePath}>
        <NGFormContext.Provider value={formDataRef.current}>
          <CreateLayout
            getPreview={getAgentObject}
            next={next}
            shouldBlockNavigation={shouldBlockNavigation}
            title={"Create Agent"}
            name={nameRef.current.value}
            canCreate={nameRef.current.isValid && tagsRef.current.isValid}
            onConfirm={onConfirm}
            isLoading={isLoading}
            links={links}
          >
            <Routes>
              <Route index element={<Navigate to={`-general`} />} />
              <Route
                path={`-general`}
                element={<AgentCreateGeneral name={nameRef.current} description={descriptionRef.current} />}
              />
              <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
            </Routes>
          </CreateLayout>
        </NGFormContext.Provider>
      </BasePathContext.Provider>
      {createdAgent === null ? null : (
        <BootstrapConfigModal agent={createdAgent} onDone={onDone} visible={createdAgent !== null} />
      )}
    </>
  );
};

export const AgentCreate = observer(AgentCreateRaw);
