import { Activity, AlertCircle } from "react-feather";
import { TableColumn } from "../../types";
import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";
import { Tooltip } from "../../../../components/Tooltip";

const readinessDefault = { id: "readiness", label: "Readiness" };
export function WorkloadReadinessColumn<TData extends WorkloadTableItem>(
  column: TableColumn<TData> = readinessDefault,
) {
  const preset: TableColumn<TData> = {
    ...readinessDefault,
    enableHide: true,
    size: 150,
    canGroupByDistinctValues: true,
    groupOrder: ["Not Ready", "Ready"],
    cell: (p) => {
      const workload = p.row.original;

      if (!workload.fetchedReadiness) {
        return <div>Loading</div>;
      }

      return (
        <div className="flex items-center">
          <Activity
            style={{ width: 16, marginRight: "0.25rem" }}
            className={`h-4 ${workload.health.isReady ? "color-action" : "color-danger"}`}
          />
          <span>{workload.health.isReady ? "Ready" : "Not Ready"}</span>
          {workload.health.syncFailed || (workload.health.isReady && !workload.health.isLatestReady) ? (
            <Tooltip
              title={
                <div>
                  {workload.health.syncFailed ? (
                    <div>There are stale deployments.</div>
                  ) : (
                    <>
                      <div>Latest version is not ready in all locations.</div>
                      {workload.health.currentReadyVersion !== workload.health.expectedDeploymentVersion ? (
                        <>
                          <div>Expected Version: {workload.health.expectedDeploymentVersion}</div>
                          <div>Deployed Version: {workload.health.currentReadyVersion}</div>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              }
            >
              <AlertCircle className="h-4" />
            </Tooltip>
          ) : null}
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
