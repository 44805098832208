import * as React from "react";
import { useNavigate } from "react-router-dom";
import { kindMetadata } from "../../../services/kindMetadata";
import { NGButton } from "../../../newcomponents/button/Button";

interface Props {
  message: string;
}
export const IncorrectOrg: React.FC<Props> = ({ message }) => {
  const navigate = useNavigate();

  const Icon = kindMetadata.org.icon;

  return (
    <div className="w-full flex flex-col items-center py-16 border   rounded-lg  ">
      <Icon className="h-16 w-16" />
      <div className="my-6">{message}</div>
      <div className="flex gap-4 items-center">
        <NGButton
          variant="primary"
          onClick={() => {
            navigate(`/console/org/-select`);
          }}
        >
          Select Org
        </NGButton>
        <NGButton
          variant="secondary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Retry
        </NGButton>
      </div>
    </div>
  );
};
