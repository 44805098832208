import { observer } from "mobx-react-lite";
import * as React from "react";
import { getLogsLogzioData } from "./data/logzio";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";

interface Props {
  logzio: ReturnType<typeof getLogsLogzioData>;
  onRemove?: any;
}
const LogzioRaw: React.FC<Props> = ({ logzio, onRemove }) => {
  return (
    <>
      <div style={{ width: 450 }}>
        <NGLabel>{logzio.listenerHost.label}</NGLabel>
        <NGSelect
          onChange={logzio.listenerHost.setValue}
          options={logzio.listenerHost.options}
          value={logzio.listenerHost.value}
          className="mb-4"
          style={{ width: 450 }}
        />
        <NGLabel>Credentials (Opaque Secret)</NGLabel>
        <NGKindSelect
          value={logzio.credentials.value}
          onChange={(value) => logzio.credentials.setValue(value)}
          kind={"secret"}
          queries={[{ property: "type", value: "opaque" }]}
          className="mb-4"
        />
        {onRemove ? (
          <div className="flex justify-end">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const Logzio = observer(LogzioRaw);
