import { Kind } from "../mst/base";
import {
  AccountIcon,
  UserIcon,
  VolumeSetIcon,
  WorkloadIcon,
  ServiceAccountIcon,
  SecretIcon,
  QuotaIcon,
  PolicyIcon,
  OrgIcon,
  LocationIcon,
  IdentityIcon,
  ImageIcon,
  GVCIcon,
  GroupIcon,
  DomainIcon,
  CloudAccountIcon,
  AuditContextIcon,
  AgentIcon,
  KubernetesIcon,
} from "./../components/Icons";

export const kindMetadata = {
  account: {
    icon: AccountIcon,
    kind: "account",
    name: "Account",
    pluralName: "Accounts",
    indefiniteArticle: "an",
  },
  agent: {
    icon: AgentIcon,
    kind: "agent",
    name: "Agent",
    pluralName: "Agents",
    indefiniteArticle: "an",
  },
  auditctx: {
    icon: AuditContextIcon,
    kind: "auditctx",
    name: "Audit Context",
    pluralName: "Audit Contexts",
    indefiniteArticle: "an",
  },
  cloudaccount: {
    icon: CloudAccountIcon,
    kind: "cloudaccount",
    name: "Cloud Account",
    pluralName: "Cloud Accounts",
    indefiniteArticle: "a",
  },
  domain: {
    icon: DomainIcon,
    kind: "domain",
    name: "Domain",
    pluralName: "Domains",
    indefiniteArticle: "a",
  },
  group: {
    icon: GroupIcon,
    kind: "group",
    name: "Group",
    pluralName: "Groups",
    indefiniteArticle: "a",
  },
  gvc: {
    icon: GVCIcon,
    kind: "gvc",
    name: "GVC",
    pluralName: "GVCs",
    indefiniteArticle: "a",
  },
  identity: {
    icon: IdentityIcon,
    kind: "identity",
    name: "Identity",
    pluralName: "Identities",
    indefiniteArticle: "an",
  },
  image: {
    icon: ImageIcon,
    kind: "image",
    name: "Image",
    pluralName: "Images",
    indefiniteArticle: "an",
  },
  ipset: {
    icon: ImageIcon,
    kind: "ipset",
    name: "IP Set",
    pluralName: "IP Sets",
    indefiniteArticle: "an",
  },
  location: {
    icon: LocationIcon,
    kind: "location",
    name: "Location",
    pluralName: "Locations",
    indefiniteArticle: "a",
  },
  mk8s: {
    icon: KubernetesIcon,
    kind: "mk8s",
    name: "Kubernetes",
    pluralName: "Kubernetes",
    indefiniteArticle: "a",
  },
  org: {
    icon: OrgIcon,
    kind: "org",
    name: "Org",
    pluralName: "Orgs",
    indefiniteArticle: "an",
  },
  policy: {
    icon: PolicyIcon,
    kind: "policy",
    name: "Policy",
    pluralName: "Policies",
    indefiniteArticle: "a",
  },
  quota: {
    icon: QuotaIcon,
    kind: "quota",
    name: "Quota",
    pluralName: "Quotas",
    indefiniteArticle: "a",
  },
  secret: {
    icon: SecretIcon,
    kind: "secret",
    name: "Secret",
    pluralName: "Secrets",
    indefiniteArticle: "a",
  },
  serviceaccount: {
    icon: ServiceAccountIcon,
    kind: "serviceaccount",
    name: "Service Account",
    pluralName: "Service Accounts",
    indefiniteArticle: "a",
  },
  user: {
    icon: UserIcon,
    kind: "user",
    name: "User",
    pluralName: "Users",
    indefiniteArticle: "a",
  },
  volumeset: {
    icon: VolumeSetIcon,
    kind: "volumeset",
    name: "Volume Set",
    pluralName: "Volume Sets",
    indefiniteArticle: "a",
  },
  workload: {
    icon: WorkloadIcon,
    kind: "workoad",
    name: "Workload",
    pluralName: "Workloads",
    indefiniteArticle: "a",
  },
};

export const allKinds: Kind[] = Object.keys(kindMetadata) as Kind[];
