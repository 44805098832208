import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { StringModel } from "../../mobxDataModels/stringModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { request } from "../../services/cpln";
import { Dropdown, notification } from "antd";
import { Download, Loader, Search } from "react-feather";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { getAuditTrailPresets, getDefaultAuditTrailTime } from "./utils";
import { Audit } from "./types";
import { ViewModal } from "../../components/modals/viewModal";
import { AuditGridWrapper } from "../../components/detail/auditGridWrapper";
import { ScrollParams } from "react-virtualized";
import { NGButton } from "../../newcomponents/button/Button";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInput } from "../../newcomponents/input/input";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import jsYaml from "js-yaml";
import { RangePicker } from "../../components/antd/RangePicker";
import { Timezone } from "../../mobxStores/userData/timezone";

const AuditTrailRoute: React.FC = () => {
  const { org } = ConsoleContext;
  const navigate = useNavigate();
  const location = useLocation();
  const [audits, setAudits] = React.useState<Audit[]>([]);
  const { kind, name, subject, audit, id, from, to } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const kindSelectRef = React.useRef(
    SelectModel.create({
      label: "Kind",
      initialValue: "any",
      options: [
        { label: "Any Kind", value: "any" },
        { label: "Account", value: "account" },
        { label: "Agent", value: "agent" },
        { label: "Audit Context", value: "auditctx" },
        { label: "Cloud Account", value: "cloudaccount" },
        { label: "Domain", value: "domain" },
        { label: "Group", value: "group" },
        { label: "GVC", value: "gvc" },
        { label: "Identity", value: "identity" },
        { label: "Image", value: "image" },
        { label: "Kubernetes", value: "mk8s" },
        { label: "Location", value: "location" },
        { label: "Org (Current)", value: "org" }, // TODO restrict with current org
        { label: "Policy", value: "policy" },
        { label: "Quota", value: "quota" },
        { label: "Secret", value: "secret" },
        { label: "Service Account", value: "serviceaccount" },
        { label: "Task", value: "task" },
        { label: "User", value: "user" },
        { label: "Volume Set", value: "volumeset" },
        { label: "Workload", value: "workload" },
      ].filter(Boolean) as any,
    }),
  );
  const kindSelect = kindSelectRef.current;
  const nameInputRef = React.useRef(StringModel.create({ label: "Resource Name" }));
  const nameInput = nameInputRef.current;
  const idInputRef = React.useRef(StringModel.create({ label: "Resource Id" }));
  const idInput = idInputRef.current;
  const subjectInputRef = React.useRef(StringModel.create({ label: "Subject Name" }));
  const subjectInput = subjectInputRef.current;
  const [auditContext, setAuditContext] = React.useState("cpln");

  // TODO support when it cannot be null
  const [fromISO, setFromISO] = React.useState<string | null>(null);
  const [toISO, setToISO] = React.useState<string | null>(null);

  const [isUrlStateProcessed, setIsUrlStateProcessed] = React.useState(false);
  const [queried, setQueried] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDetailsOf, setShowDetailsOf] = React.useState<null | string>(null);
  const nextLinkRef = React.useRef<string | undefined>(undefined);
  const [isIdDisabled, setIsIdDisabled] = React.useState(false);
  const [isNameDisabled, setIsNameDisabled] = React.useState(false);

  React.useEffect(() => {
    if (isUrlStateProcessed) {
      fetchData();
    }
  }, [isUrlStateProcessed]);

  React.useEffect(() => {
    if (kind) {
      kindSelect.setValue(kind.toString());
    }
    if (name) {
      nameInput.setValue(name.toString());
    }
    if (subject) {
      subjectInput.setValue(subject.toString());
    }
    if (audit) {
      setAuditContext(audit.toString());
    }
    if (id) {
      idInput.setValue(id.toString());
    }
    if (from) {
      setFromISO(from.toString());
    } else {
      setFromISO(getDefaultAuditTrailTime());
    }
    if (to) {
      setToISO(to.toString());
    }
    setIsUrlStateProcessed(true);
  }, []);

  React.useEffect(() => {
    setIsIdDisabled(nameInputRef.current.value.length > 0);
    setIsNameDisabled(idInputRef.current.value.length > 0);
  }, [idInputRef.current.value, nameInputRef.current.value]);

  React.useEffect(() => {
    if (!queried || !isUrlStateProcessed) {
      return;
    }

    const qsObject: any = {};
    if (kindSelect.value) {
      qsObject.kind = kindSelect.value;
    }
    if (nameInput.value) {
      qsObject.name = nameInput.value;
    }
    if (subjectInput.value) {
      qsObject.subject = subjectInput.value;
    }
    if (auditContext) {
      qsObject.audit = auditContext;
    }
    if (idInput.value) {
      qsObject.id = idInput.value;
    }
    if (fromISO) {
      qsObject.from = fromISO;
    }
    if (toISO) {
      qsObject.to = toISO;
    }

    const queryString = qs.stringify(qsObject, { arrayFormat: "comma", encode: false });
    navigate({ search: `?${queryString}` });
  }, [kindSelect.value, nameInput.value, subjectInput.value, auditContext, idInput.value, fromISO, toISO]);

  function onScroll_Items(params: ScrollParams) {
    if (!nextLinkRef.current) return;
    const { scrollHeight, scrollTop, clientHeight } = params;
    const val = scrollHeight - (scrollTop + clientHeight);
    if (val < 1) {
      fetchData();
    }
  }

  function onQuery() {
    nextLinkRef.current = undefined;
    setAudits([]);
    fetchData();
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      let url = `/audit/org/${org}`;
      if (nextLinkRef.current) {
        url = nextLinkRef.current;
      } else {
        let params: string[] = [];
        if (nameInput.value.length > 0) {
          url += `/resource/name/${nameInput.value}`;
        }
        if (idInput.value.length > 0) {
          url += `/resource/id/${idInput.value}`;
        }
        if (kindSelect.value !== "any") {
          params.push(`resourceType=${kindSelect.value}`);
        }
        if (subjectInput.value.length > 0) {
          params.push(`subjectName=${subjectInput.value}`);
        }
        if (!!auditContext) {
          params.push(`contextName=${auditContext}`);
        }
        if (fromISO) {
          params.push(`fromEvent=${fromISO}`);
        }
        if (toISO) {
          params.push(`toEvent=${toISO}`);
        }
        if (params.length > 0) {
          url += `?${params.join("&")}`;
        }
      }
      const { data } = await request({ service: "audit", url });
      if (nextLinkRef.current) {
        setAudits([...audits, ...data.items]);
      } else {
        setAudits(data.items);
      }
      const _nextLink = data.links.find((l: any) => l.rel === "next")?.href;
      if (data.items.length === 100) {
        nextLinkRef.current = _nextLink;
      } else {
        nextLinkRef.current = undefined;
      }
      setQueried(true);
    } catch (e) {
      const isUnathorized = e.response?.status === 403;
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      if (isUnathorized) errorMessage = "Unauthorized";
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function onClear() {
    kindSelect.setValue("any");
    nameInput.setInitialValue("");
    nameInput.setValue("");
    subjectInput.setInitialValue("");
    subjectInput.setValue("");
    idInput.setInitialValue("");
    idInput.setValue("");
    setAuditContext("cpln");
    setFromISO(getDefaultAuditTrailTime());
    setToISO(null);
  }

  async function downloadAudits(format: "json" | "yaml") {
    try {
      let blob = "";

      if (format === "json") {
        blob = JSON.stringify(audits, null, 2);
      } else if (format === "yaml") {
        blob = jsYaml.dump(audits, { indent: 2, noRefs: true });
      }

      autoDownloadWithElementA(blob, format);
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  function autoDownloadWithElementA(blobData: any, format: "json" | "yaml") {
    const file = new Blob([blobData], { type: `text/${format}` });
    const href = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style.display = "none";
    a.classList.add("cpln-temp-a");
    a.download = `audits-from-${fromISO}-to${toISO}.${format}`;
    a.href = href;
    a.click();
  }

  return (
    <>
      <Layout key={org}>
        <div className="mb-2 text-2xl font-medium">Audit Trail</div>
        <div className="flex items-center mb-2">
          <NGSelect
            className="mr-2"
            style={{ width: 150 }}
            placeholder={kindSelect.label}
            onChange={kindSelect.setValue}
            options={kindSelect.options}
            value={kindSelect.value}
          />
          <NGInput
            className="mr-2"
            style={{ width: 500 }}
            value={nameInput.value}
            placeholder={nameInput.label}
            onChange={(e) => nameInput.setValue(e.target.value)}
            disabled={isNameDisabled}
          />
          <NGInput
            className="flex-grow basis-0 mr-2"
            value={subjectInput.value}
            placeholder={subjectInput.label}
            onChange={(e) => subjectInput.setValue(e.target.value)}
          />
          <div className="flex items-center" style={{ width: 150 }}>
            <NGButton
              variant={"primary"}
              style={{ width: isLoading || audits.length < 1 ? 150 : 100 }}
              onClick={onQuery}
              disabled={isLoading}
              loading={isLoading}
            >
              Query
            </NGButton>
            {isLoading || audits.length < 1 ? null : (
              <Dropdown
                trigger={["click"]}
                menu={{
                  onClick: ({ key }: any) => {
                    switch (key) {
                      case "export-json":
                        downloadAudits("json");
                        break;
                      case "export-yaml":
                        downloadAudits("yaml");
                        break;
                      default:
                        break;
                    }
                  },
                  items: [
                    { key: "export-json", label: "JSON" },
                    { key: "export-yaml", label: "YAML" },
                  ],
                }}
              >
                <NGButton
                  style={{ width: 45, marginLeft: 5 }}
                  variant={"secondary"}
                  renderIcon={(_, props) => <Download {...props} />}
                />
              </Dropdown>
            )}
          </div>
        </div>
        <div className="flex items-center mb-4">
          <NGKindSelect
            className="mr-2"
            style={{ width: 150 }}
            kind={"auditctx"}
            value={auditContext}
            placeholder="Any Context"
            onChange={(value) => setAuditContext(value)}
          />
          <NGInput
            className="mr-2"
            style={{ width: 500 }}
            value={idInput.value}
            placeholder={idInput.label}
            onChange={(e) => idInput.setValue(e.target.value)}
            disabled={isIdDisabled}
          />

          {/* TODO either only allow utc, or show timezone */}
          <RangePicker
            className="flex-grow basis-0 mr-2"
            presets={getAuditTrailPresets()}
            size={"small"}
            allowClear={false}
            placeholder={["From", "To"]}
            showTime
            allowEmpty={[false, true]}
            fromISO={fromISO}
            setFromISO={setFromISO}
            toISO={toISO}
            setToISO={setToISO}
            timezoneValue={Timezone.value}
          />
          <NGButton variant={"secondary"} style={{ width: 150 }} onClick={() => onClear()} disabled={isLoading}>
            Clear All Filters
          </NGButton>
        </div>
        {isLoading && !nextLinkRef.current && (
          <div className="flex flex-col items-center p-4 border" style={{ borderRadius: 6 }}>
            <Loader className="animate-spin mb-2" />
            <p className="text-center text-2xl mt-2">Loading Audit Events</p>
          </div>
        )}
        {audits.length < 1 && queried && !isLoading ? (
          <div className="flex flex-col items-center p-4 border">
            <Search className="mb-2" />
            <p className="text-center text-2xl mt-2">No Audit Events Found</p>
          </div>
        ) : null}
        <AuditGridWrapper
          audits={audits}
          emptySpaceValue={366}
          onDetail={(id) => setShowDetailsOf(id)}
          onScroll={onScroll_Items}
          queried={queried}
          diffSupport={false}
        />
      </Layout>
      {showDetailsOf ? (
        <ViewModal
          object={audits.find((a) => a.id === showDetailsOf)}
          title={"Audit Log"}
          onClose={() => setShowDetailsOf(null)}
          visible={!!showDetailsOf}
          filename={`audit-${audits.find((a) => a.id === showDetailsOf)?.id}`}
        />
      ) : null}
    </>
  );
};

export default observer(AuditTrailRoute);
