import * as React from "react";
import { ExternalLink as ExternalLinkIcon, Copy } from "react-feather";
import { notification } from "antd";

export interface ExternalLinkProps {
  url: string;
  label: string;
  disableCopy?: boolean;
}
export const ExternalLink: React.FC<ExternalLinkProps> = ({ url, label, disableCopy = false }) => {
  // TODO move to utils
  function handleCopyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  return (
    <div className={`flex items-center`}>
      <a className="truncate color-link ngfocus" href={url} target={"_blank"}>
        <span>{label}</span>
        <ExternalLinkIcon className={`inline-block feather-icon ml-1`} style={{ transform: "translateY(2px)" }} />
      </a>
      {disableCopy ? null : (
        <button onClick={() => handleCopyToClipboard(url)} className="ml-3 ngfocus color-link-hover">
          <Copy className={`inline-block feather-icon`} style={{ transform: "translateY(2px)" }} />
        </button>
      )}
    </div>
  );
};
