import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftManagerModel } from "../../../mst/stores/workload.draft.manager";
import { WorkloadCreate } from "./create";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

const WorkloadCreateRouteRaw: React.FC = () => {
  const draftManagerRef = React.useRef(WorkloadDraftManagerModel.create());
  const draftManager = draftManagerRef.current;
  const navigate = useNavigate();

  React.useEffect(() => {
    draftManager.startDraftCreate().catch((e) => {
      notification.warning({ message: "Failed", description: "Something went wrong, please try again." });
      console.error(e.message);
      navigate("..");
    });
  }, []);

  if (!draftManager.draft.edit) return null;

  return <WorkloadCreate workloadDraft={draftManager.draft.edit} />;
};

export const WorkloadCreateRoute = observer(WorkloadCreateRouteRaw);
