import { Instance, types } from "mobx-state-tree";

export const NetworkingModel = types.model({
  serviceNetwork: types.optional(types.string, ""),
  podNetwork: types.optional(types.string, ""),
});
export interface NetworkingMobx extends Instance<typeof NetworkingModel> {}
export interface Networking {
  serviceNetwork: string;
  podNetwork: string;
}
