import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";

export const Mk8sDraftAddonFlexiblePropertyReadonlyModel = types.model({
  key: types.string,
  value: types.string,
});

export const Mk8sDraftAddonFlexiblePropertyModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["added", "default"]), "default"),
    _key: types.optional(types.string, ""),
    key: types.optional(StringModel, () => StringModel.create({ label: "Key", isRequired: true })),
    _value: types.optional(types.string, ""),
    value: types.optional(StringModel, () => StringModel.create({ label: "Value", isRequired: true })),
  })
  .actions((self) => ({
    reset() {
      self.key.setInitialValue(self._key);
      self.value.setInitialValue(self._value);
    },
    confirm() {
      self.key.confirm();
      self._key = self.key.value;
      self.value.confirm();
      self._value = self.value.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.key.isDirty) res = true;
      if (self.value.isDirty) res = true;
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.key.isValid) res = false;
      if (!self.value.isValid) res = false;
      return res;
    },
  }));

export const Mk8sDraftAddonFlexibleModel = types
  .model({
    _isEnabled: types.optional(types.boolean, false),
    isEnabled: types.optional(types.boolean, false),
    _properties: types.array(Mk8sDraftAddonFlexiblePropertyReadonlyModel),
    properties: types.array(Mk8sDraftAddonFlexiblePropertyModel),
    removed: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setIsEnabled(value: boolean) {
      self.isEnabled = value;
    },
    addProperty() {
      self.properties.push(
        Mk8sDraftAddonFlexiblePropertyModel.create({
          index: self.properties[self.properties.length - 1].index + 1,
          status: "added",
        })
      );
    },
    removePropertyAt(index: number) {
      if (self.properties[index].status === "default") {
        self.removed = true;
      }
      self.properties.remove(self.properties[index]);
    },
  }))
  .actions((self) => ({
    reset() {
      self.isEnabled = self._isEnabled;
      self.properties.clear();
      for (let propertyIndex in self._properties) {
        const property = self._properties[propertyIndex];
        self.properties.push(
          Mk8sDraftAddonFlexiblePropertyModel.create({
            index: Number(propertyIndex),
            _key: property.key,
            _value: property.value,
          })
        );
      }
      self.removed = false;
    },
    confirm() {
      self._isEnabled = self.isEnabled;
      for (let property of self.properties) {
        property.confirm();
      }
      self._properties.clear();
      for (let property of self.properties) {
        self._properties.push(
          Mk8sDraftAddonFlexiblePropertyReadonlyModel.create({ key: property._key, value: property._value })
        );
      }
      self.removed = false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (self.isEnabled) {
        if (self.properties.some((p) => !p.isValid)) res = false;
      }
      return res;
    },
    get invalidReason() {
      let reason = "";
      // TODO
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.isEnabled !== self._isEnabled) res = true;
      if (self.isEnabled) {
        if (self.removed) res = true;
        if (self.properties.length != self._properties.length) res = true;
        if (self.properties.some((p) => !p.isDirty)) res = true;
      }
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.isEnabled !== self._isEnabled) reason = "enabled changed";
      if (self.isEnabled) {
        if (self.removed) reason = "removed property";
        if (self.properties.length != self._properties.length) reason = "different lengths of properties";
        if (self.properties.some((p) => !p.isDirty)) reason = "has dirty property";
      }
      return reason;
    },
    get asObject() {
      const properties: { [_: string]: string } = {};
      self.properties.forEach((p) => {
        properties[p.key.value] = p.value.value;
      });
      return properties;
    },
  }));
