import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Mk8sDraftUnmanagedNodePoolModel, Mk8sDraftUnmanagedNodePoolReadOnlyModel } from "./mk8s.draft.provider.common";
import {
  Mk8sDraftProviderPaperspaceNodePoolModel,
  Mk8sDraftProviderPaperspaceNodePoolReadonlyModel,
} from "./mk8s.draft.provider.paperspace.nodepool";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { Mk8sDraftAutoscalerModel } from "./mk8s.draft.providerAutoscaler";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const Mk8sDraftProviderPaperspaceModel = types
  .model({
    _region: types.optional(types.string, ""),
    region: types.optional(StringModel, () => StringModel.create({ label: "Region", isRequired: true })),
    _nodePools: types.array(Mk8sDraftProviderPaperspaceNodePoolReadonlyModel),
    nodePools: types.array(Mk8sDraftProviderPaperspaceNodePoolModel),
    _sharedDrives: types.array(types.string),
    sharedDrives: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _tokenSecretLink: types.optional(types.string, ""),
    tokenSecretName: types.optional(types.string, ""),
    _unmanagedNodePools: types.array(Mk8sDraftUnmanagedNodePoolReadOnlyModel),
    unmanagedNodePools: types.array(Mk8sDraftUnmanagedNodePoolModel),
    removed: types.optional(types.boolean, false),
    removedUnmanaged: types.optional(types.boolean, false),
    autoscaler: types.optional(Mk8sDraftAutoscalerModel, () => Mk8sDraftAutoscalerModel.create()),
    _preInstallScript: types.optional(types.string, ""),
    preInstallScript: types.optional(StringModel, () => StringModel.create({ label: "Pre Install Script" })),
    _userIds: types.array(types.string),
    userIds: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _networkId: types.optional(types.string, ""),
    networkId: types.optional(StringModel, () => StringModel.create({ label: "Network Id", isRequired: true })),
  })
  .views((self) => ({
    get _tokenSecretName() {
      const { name: tokenSecretName } = ngParseLink(self._tokenSecretLink, { kind: "secret" });
      return tokenSecretName;
    },
    get tokenSecretLink() {
      const { absolute } = ngParseLink(self.tokenSecretName, { kind: "secret" });
      return absolute;
    },
  }))
  .actions((self) => ({
    reset() {
      self.preInstallScript.setInitialValue(self._preInstallScript);
      self.region.setInitialValue(self._region);
      self.networkId.setInitialValue(self._networkId);
      self.tokenSecretName = self._tokenSecretName;
      self.sharedDrives.setInitialItems(self._sharedDrives.map((k) => ({ firstValue: k })));
      self.sharedDrives.reset();
      self.userIds.setInitialItems(self._userIds.map((k) => ({ firstValue: k })));
      self.userIds.reset();

      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftProviderPaperspaceNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          }),
        );
      }
      self.removed = false;

      self.unmanagedNodePools.clear();
      for (let index in self._unmanagedNodePools) {
        const _nodePool = self._unmanagedNodePools[index];

        self.unmanagedNodePools.push(
          Mk8sDraftUnmanagedNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          }),
        );
      }
      self.removedUnmanaged = false;

      self.autoscaler.reset();
    },
    confirm() {
      self.preInstallScript.confirm();
      self._preInstallScript = self.preInstallScript.value;
      self.region.confirm();
      self._region = self.region.value;
      self.networkId.confirm();
      self._networkId = self.networkId.value;
      self._tokenSecretLink = self.tokenSecretLink;
      self._sharedDrives.clear();
      for (const sshKey of self.sharedDrives.items.map((i) => i.firstValue)) {
        self._sharedDrives.push(sshKey);
      }
      self.sharedDrives.confirm();
      self._userIds.clear();
      for (const sshKey of self.userIds.items.map((i) => i.firstValue)) {
        self._userIds.push(sshKey);
      }
      self.userIds.confirm();

      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftProviderPaperspaceNodePoolReadonlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
            machineType: nodePool.machineType.value,
            bootDiskSize: Number(nodePool.bootDiskSize.value),
            publicIpType: nodePool.publicIpType.value,
            minSize: String(nodePool.minSize.value).length > 0 ? Number(nodePool.minSize.value) : undefined,
            maxSize: String(nodePool.maxSize.value).length > 0 ? Number(nodePool.maxSize.value) : undefined,
          }),
        );
      }
      self.removed = false;

      self._unmanagedNodePools.clear();
      for (let nodePool of self.unmanagedNodePools) {
        nodePool.confirm();
        self._unmanagedNodePools.push(
          Mk8sDraftUnmanagedNodePoolReadOnlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
          }),
        );
      }
      self.removedUnmanaged = false;

      self.autoscaler.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setTokenSecretName(value: string) {
      self.tokenSecretName = value;
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }
      self.nodePools.push(
        Mk8sDraftProviderPaperspaceNodePoolModel.create({
          index,
          status: "added",
        }),
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.nodePools.remove(node);
    },
    addUnmanagedNodePool() {
      let index = 0;
      if (self.unmanagedNodePools.length > 0) {
        index = self.unmanagedNodePools[self.unmanagedNodePools.length - 1].index + 1;
      }
      self.unmanagedNodePools.push(
        Mk8sDraftUnmanagedNodePoolModel.create({
          index,
          status: "added",
        }),
      );
    },
    removeUnmanagedNodePoolAt(index: number) {
      const node = self.unmanagedNodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removedUnmanaged = true;
      }
      self.unmanagedNodePools.remove(node);
    },
  }))
  .views((self) => ({
    isNodePoolNameValid(index: number) {
      let res = true;
      const node = self.nodePools.find((np) => np.index === index)!;
      const pools = [...self.nodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyNodePoolNameConflicts() {
      for (const nodePool of self.nodePools) {
        if (
          [...self.nodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value,
          )
        ) {
          return true;
        }
      }
      return false;
    },
    isUnmanagedNodePoolNameValid(index: number) {
      let res = true;
      const node = self.unmanagedNodePools.find((np) => np.index === index)!;
      const pools = [...self.unmanagedNodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyUnmanagedNodePoolNameConflicts() {
      for (const nodePool of self.unmanagedNodePools) {
        if (
          [...self.unmanagedNodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value,
          )
        ) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._nodePools.length !== self.nodePools.length) res = true;
      if (self.removed) res = true;
      if (self.nodePools.some((n) => n.isDirty)) res = true;
      if (self._unmanagedNodePools.length !== self.unmanagedNodePools.length) res = true;
      if (self.removedUnmanaged) res = true;
      if (self.unmanagedNodePools.some((n) => n.isDirty)) res = true;
      if (self.region.isDirty) res = true;
      if (self.sharedDrives.isDirty) res = true;
      if (self.userIds.isDirty) res = true;
      if (self.tokenSecretName !== self._tokenSecretName) res = true;
      if (self.autoscaler.isDirty) res = true;
      if (self.preInstallScript.isDirty) res = true;
      if (self.networkId.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self._nodePools.length !== self.nodePools.length) reason = "node pool length";
      if (self.removed) reason = "removed";
      if (self.nodePools.some((n) => n.isDirty))
        reason = self.nodePools.find((n) => n.isDirty)?.dirtyReason || "node pools has dirty";
      if (self._unmanagedNodePools.length !== self.unmanagedNodePools.length) reason = "unmanaged pool length";
      if (self.removedUnmanaged) reason = "removed unmanaged";
      if (self.unmanagedNodePools.some((n) => n.isDirty))
        reason = self.unmanagedNodePools.find((n) => n.isDirty)?.dirtyReason || "unmanaged pool has dirty";
      if (self.region.isDirty) reason = "datacenter";
      if (self.sharedDrives.isDirty) reason = "shared drives";
      if (self.userIds.isDirty) reason = "user ids";
      if (self.tokenSecretName !== self._tokenSecretName) reason = "token secret link";
      if (self.autoscaler.isDirty) reason = self.autoscaler.dirtyReason;
      if (self.preInstallScript.isDirty) reason = "preInstallScript";
      if (self.networkId.isDirty) reason = "networkId";
      return reason;
    },
    get isValid() {
      let res = true;
      if (self.anyNodePoolNameConflicts) res = false;
      if (self.anyUnmanagedNodePoolNameConflicts) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      if (self.unmanagedNodePools.some((n) => !n.isValid)) res = false;
      if (!self.region.isValid) res = false;
      if (!self.tokenSecretName) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      if (!self.networkId.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.anyNodePoolNameConflicts) reason = "node pool name conflict";
      if (self.anyUnmanagedNodePoolNameConflicts) reason = "node pool name conflict";
      if (self.nodePools.some((n) => !n.isValid)) reason = self.nodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (self.unmanagedNodePools.some((n) => !n.isValid))
        reason = self.unmanagedNodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (!self.region.isValid) reason = "datacenter";
      if (!self.autoscaler.isValid) reason = self.autoscaler.invalidReason;
      if (!self.preInstallScript.isValid) reason = "preInstallScript";
      if (!self.networkId.isValid) reason = "networkId";
      return reason;
    },
    get ui_isPaperspaceValid() {
      let res = true;
      if (!self.region.isValid) res = false;
      if (!self.tokenSecretName) res = false;
      if (!self.networkId.isValid) res = false;
      return res;
    },
    get dryRun_paperspacePathList() {
      return [
        //
        "spec.provider.paperspace.region",
        "spec.provider.paperspace.tokenSecretLink",
        "spec.provider.paperspace.networkId",
        "spec.provider.paperspace.sharedDrives",
        ...self.sharedDrives.items.map((_, index) => `spec.provider.paperspace.sharedDrives[${index}]`),
        "spec.provider.paperspace.userIds",
        ...self.userIds.items.map((_, index) => `spec.provider.paperspace.userIds[${index}]`),
      ];
    },
    get ui_isAdvancedValid() {
      let res = true;
      if (!self.preInstallScript.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      return res;
    },
    get dryRun_advancedPathList() {
      return [
        //
        "spec.provider.paperspace.preInstallScript",
        ...self.autoscaler.dryRun_pathList("paperspace"),
      ];
    },
    get asObject() {
      let obj: any = {
        region: self.region.value,
        tokenSecretLink: self.tokenSecretLink,
        autoscaler: self.autoscaler.asObject,
        preInstallScript: self.preInstallScript.value,
        nodePools: self.nodePools.map((n) => n.asObject),
        unmanagedNodePools: self.unmanagedNodePools.map((n) => n.asObject),
        sharedDrives: self.sharedDrives.items.map((i) => i.firstValue),
        userIds: self.userIds.items.map((i) => i.firstValue),
        networkId: self.networkId.value,
      };
      return obj;
    },
  }));
export interface Mk8sDraftProviderPaperspaceMobx extends Instance<typeof Mk8sDraftProviderPaperspaceModel> {}
