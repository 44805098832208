import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation } from "react-router-dom";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";
import { LinkWithChange, LinkWithChangeItem } from "../LinkWithChange";
import { flowResult } from "mobx";
import { AccountIcon } from "../../../Icons";
import { AccountSummary, UserData } from "../../../../mobxStores/userData/userData";
import { useAccountEmitter } from "../../../../emitters/account";
import { sortBy } from "lodash";
import { MenuLinkProps } from "../menu";

const AccountChangeRaw: React.FC = () => {
  const { FullName, AccountName, AccountUUID } = BillingContext;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const menuLinkProps: MenuLinkProps = {
    url: `/billing/account/${AccountUUID}`,
    prefix: "Account",
    label: AccountName || FullName,
    Icon: AccountIcon,
    tooltip: {
      content: AccountName || FullName || "no account",
      disable: false,
      placement: "top",
    },
    customIsActive: isActive,
  };

  function isActive() {
    return (
      pathname === `/billing/account/${AccountUUID}` ||
      pathname.includes(`/billing/account/${AccountUUID}/-setup`) ||
      pathname.includes(`/billing/account/${AccountUUID}/-info`) ||
      pathname.includes(`/billing/account/${AccountUUID}/-address`) ||
      pathname.includes(`/billing/account/${AccountUUID}/-alert`) ||
      pathname.includes(`/billing/account/${AccountUUID}/-audittrail`)
    );
  }

  function getAccountNames(): LinkWithChangeItem[] {
    return sortBy(
      UserData.accountSummaries
        .filter((account) => account.id !== BillingContext.AccountUUID)
        .map((account) => {
          return {
            value: account.id,
            labels: [account.accountName, account.fullName, account.company].filter(Boolean) as string[],
          };
        }),
      [
        function (o: LinkWithChangeItem) {
          return o.labels[0];
        },
      ]
    );
  }

  async function selectItem(accountID: string) {
    let account: AccountSummary | null = null;
    for (const accountItem of UserData.accountSummaries) {
      if (accountItem.id === accountID) {
        account = accountItem;
        break;
      }
    }

    if (account == null) {
      return;
    }

    await flowResult(BillingContext.setAccount(account.id));
    navigate(`/billing/account/${account.id}`);
  }

  async function requestItems(): Promise<void> {
    await flowResult(UserData.requestAccountSummaries());
  }

  return (
    <LinkWithChange
      kind={"account" as any}
      mountElementId="accountChange"
      label={AccountName || FullName}
      value={AccountUUID}
      items={getAccountNames()}
      menuLinkProps={menuLinkProps}
      isActiveFunc={isActive}
      selectItem={(value) => selectItem(value)}
      useEmitter={useAccountEmitter}
      requestItems={requestItems}
      canBePinned={false}
    />
  );
};

export const AccountChange = observer(AccountChangeRaw);
