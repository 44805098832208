import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";
import { TableColumn } from "../../types";

const readyLocationsDefault = { id: "readyLocations", label: "Ready Locations" };
export function WorkloadReadyLocationsColumn<TData extends WorkloadTableItem>(
  column: TableColumn<TData> = readyLocationsDefault,
) {
  const preset: TableColumn<TData> = {
    ...readyLocationsDefault,
    enableHide: true,
    hiddenByDefault: true,
    size: 170,
    cell: (p) => {
      const workload = p.row.original;
      return (
        <div className="flex items-center">
          {workload.readyLocations}/{workload.totalLocations}
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
