import * as React from "react";
import { Table } from "../../../../newcomponents/table/table";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { linksOf } from "../../../../services/cpln";
import { Identity } from "../../../../schema/types/identity";
import { WorkloadGvcColumn } from "../../../../newcomponents/table/columns/workload/gvcColumn";

interface IdentityTableItem extends Identity {
  selfLink: string;
  gvc: string;
}

interface Props {
  title: string;
  selections: string[];
  onSelectionsChange: (values: string[], items?: IdentityTableItem[]) => void;
}
export const IdentityTable: React.FC<Props> = ({ title, selections, onSelectionsChange }) => {
  const { kind, id } = useTableKindId("identity", "policy-create-target-list-identity");
  const qData = useTableItemQueryData<Identity>(kind, { fetchAllPages: true, skipGvcScope: true });

  const identityTableItems: IdentityTableItem[] = qData.visibleItems.map((identity) => {
    const selfLink = linksOf(identity).self!;
    const gvc = selfLink.split("/")[4];
    return {
      ...identity,
      selfLink: selfLink,
      gvc: gvc,
    };
  });

  return (
    <Table<IdentityTableItem>
      title={title}
      data={identityTableItems}
      tableId={id}
      selectMode="multi"
      selectKey={"selfLink"}
      selections={selections}
      onSelectionsChange={onSelectionsChange}
      headerRenderer={() => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
            <CustomTableCPLNQuery
              tableId={id}
              kind={kind}
              onQuery={qData.setQuery}
              isLoading={qData.isLoadingQueried}
            />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionNoLinkColumn(), //
        WorkloadGvcColumn() as any,
        TagsColumn(),
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};
