import * as React from "react";
import { AgentMobx } from "../../mst/kinds/agent";
import { AuditContextMobx } from "../../mst/kinds/auditContext";
import { CloudaccountMobx } from "../../mst/kinds/cloudaccount";
import { DomainMobx } from "../../mst/kinds/domain";
import { GroupMobx } from "../../mst/kinds/group";
import { GVCMobx } from "../../mst/kinds/gvc";
import { IdentityMobx } from "../../mst/kinds/identity";
import { LocationMobx } from "../../mst/kinds/location";
import { mk8sMobx } from "../../mst/kinds/mk8s/mk8s";
import { OrgMobx } from "../../mst/kinds/org";
import { PolicyMobx } from "../../mst/kinds/policy";
import { QuotaMobx } from "../../mst/kinds/quota";
import { SecretMobx } from "../../mst/kinds/secret";
import { ServiceAccountMobx } from "../../mst/kinds/serviceaccount";
import { UserMobx } from "../../mst/kinds/user";
import { VolumeSetMobx } from "../../mst/kinds/volumeset";
import { WorkloadMobx } from "../../mst/kinds/workload";
import { IpSet } from "../../schema/types/ipset";
import { HelmReleaseInstance, MarketplaceReleaseInstance } from "../../pages/marketplace/types/release";

export type DetailContextItem =
  | AgentMobx
  | AuditContextMobx
  | CloudaccountMobx
  | DomainMobx
  | GroupMobx
  | GVCMobx
  | IdentityMobx
  | LocationMobx
  | mk8sMobx
  | OrgMobx
  | PolicyMobx
  | QuotaMobx
  | SecretMobx
  | ServiceAccountMobx
  | UserMobx
  | VolumeSetMobx
  | WorkloadMobx
  | { kind: "account"; name: string; selfLink: string; version: number; id: string; roles: string[] }
  | IpSet
  | MarketplaceReleaseInstance
  | HelmReleaseInstance;

interface DetailContextType {
  item: DetailContextItem;
  fetchItem?: () => Promise<void>;
  roles?: string[];
}

export const DetailContext = React.createContext<DetailContextType>(null as any);

export const useDetailContext = () => {
  return React.useContext(DetailContext);
};
