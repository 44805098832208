import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AuditContextMobx, AuditContextModel } from "../../mst/kinds/auditContext";
import { AuditContextDetail } from "./detail";
import { request, resourceLink } from "../../services/cpln";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { captureExc } from "../../errorBoundary";

type RouteParams = "auditctx";
const AuditContextDetailRouteRaw: React.FC = () => {
  const { auditctx: auditContextName } = useParams<RouteParams>();
  const [auditContext, setAuditContext] = React.useState<AuditContextMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [auditContextName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("auditctx", auditContextName) });
      setAuditContext(AuditContextModel.create(data));
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching audit context to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"auditctx"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {auditContext.name} - Audit Context - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: auditContext, fetchItem }}>
        <AuditContextDetail auditContext={auditContext} />
      </DetailContext.Provider>
    </>
  );
};

export const AuditContextDetailRoute = observer(AuditContextDetailRouteRaw);
