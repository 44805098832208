import React from "react";
import UserMenu from "./UserMenu";
import { observer } from "mobx-react-lite";
import { GenericCreate } from "./GenericCreate";
import { Breadcrumb } from "../../../newcomponents/breadcrumb";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Menu, ArrowRight, Terminal } from "react-feather";
import { AdminPanelSession } from "./AdminPanelSession";
import { OrgInactiveAlert } from "./OrgInactiveAlert";
import { CircularThemeSwitch } from "../../CircularThemeSwitch/CircularThemeSwitch";
import { ParsedLocation } from "../../../mobxStores/parsedLocation/parsedLocation";
import { User } from "../../../mobxStores/user/user";
import { UserData } from "../../../mobxStores/userData/userData";
import { DisallowedEmail } from "./DisallowedEmail";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { Apply } from "../../../mobxStores/apply/apply";
import { TimezoneDisplay } from "./TimezoneDisplay";
import { UIData } from "../../../mobxStores/uiData/uiData";
import { Logo } from "../sider/Logo";
import { NGButton } from "../../../newcomponents/button/Button";
import "./index.scss";
import { IS_DEPLOYMENT_ENV_TEST } from "../../../envVariables";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";

interface Props {
  isMenuShown?: boolean;
}

const HeaderRaw: React.FC<Props> = ({ isMenuShown = true }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hasOrg } = ConsoleContext;

  return (
    <div className="ngheader">
      <div className="ngheader-left-controls">
        {isMenuShown ? (
          <button className="ngfocus" onClick={() => UIData.setIsNavCollapsed(!UIData.isNavCollapsed)}>
            <Menu color="var(--color-default)" />
          </button>
        ) : null}
        <Link
          to={
            pathname.includes("/billing/") && BillingContext.AccountUUID
              ? "/billing"
              : ConsoleContext.org
              ? ConsoleContext.gvc
                ? `/console/org/${ConsoleContext.org}/gvc/${ConsoleContext.gvc}`
                : `/console/org/${ConsoleContext.org}`
              : "/"
          }
          className="ngheader-logo ngfocus"
        >
          <Logo />
        </Link>
        {User.isAdmin ? <AdminPanelSession /> : null}
        {User.isAdmin ? null : hasOrg ? <Breadcrumb /> : null}
        {hasOrg ? <OrgInactiveAlert /> : null}
        <DisallowedEmail />
      </div>
      <div className="ngheader-spacer" />
      <div className="ngheader-right-controls">
        {!ParsedLocation.createForm && ParsedLocation.dashboard !== "console" && UserData.hasOrg ? (
          <NGButton
            variant="secondary"
            outlined
            onClick={() => navigate("/console")}
            renderIcon={() => <ArrowRight color="var(--color-gray-700)" size={16} />}
          >
            Go to Console
          </NGButton>
        ) : null}
        {IS_DEPLOYMENT_ENV_TEST || User.isAdmin ? <CircularThemeSwitch /> : null}
        {User.type !== "inspect" &&
        !ParsedLocation.createForm &&
        isMenuShown &&
        ParsedLocation.dashboard === "console" &&
        hasOrg ? (
          <NGButton
            variant="secondary"
            outlined
            size={"normal"}
            onClick={() => {
              Apply.setPrevPath(pathname);
              navigate("/console/apply");
            }}
            to={"/console/apply"}
            as={"anchor"}
            renderIcon={() => <Terminal color="var(--color-gray-700)" size={16} />}
          >
            apply
          </NGButton>
        ) : null}
        {User.type !== "inspect" &&
        !ParsedLocation.createForm &&
        isMenuShown &&
        ParsedLocation.dashboard !== "task" &&
        hasOrg ? (
          <GenericCreate />
        ) : null}
        {User.type !== "inspect" ? <UserMenu /> : null}
        {User.type === "inspect" ? (
          <div className="text-xs">
            <TimezoneDisplay />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const Header = observer(HeaderRaw);
