import moment from "moment-timezone";
import { Timezone } from "../mobxStores/userData/timezone";
import { humanize } from "./dateString";

export function dateString(iso: string, { format, utc = false }: { format?: string; utc?: boolean }): string {
  function getM() {
    return moment.utc(iso);
  }
  const m = getM();

  function getMLocal() {
    return getM().clone().tz(Timezone.value);
  }
  const mLocal = getMLocal();

  if (format?.includes("humanize")) {
    return humanize(m.valueOf(), format.includes("Ago"));
  }

  if (utc) {
    return m.format(format);
  }

  return mLocal.format(format);
}
