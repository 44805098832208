import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { OrgMobx } from "../../../mst/kinds/org";
import { FormButtons } from "../../../components/forms/formButtons";
import { useDetailContext } from "../../../components/detail/detailContext";
import { TabsLayout, TabsLayoutLink } from "../../../components/tabsLayout/tabsLayout";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { getLogsCoralogixData } from "./data/coralogix";
import { getLogsDatadogData } from "./data/datadog";
import { getLogsS3Data } from "./data/s3";
import { getLogsLogzioData } from "./data/logzio";
import { getLogsElasticData } from "./data/elastic";
import { S3 } from "./s3";
import { Datadog } from "./datadog";
import { Logzio } from "./logzio";
import { Coralogix } from "./coralogix";
import { Elastic } from "./elastic";
import { SelectModel } from "../../../mobxDataModels/selectModel";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGLabel } from "../../../newcomponents/text/label";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";
import { useSetPromptShouldBlock } from "../../../reactHooks/useSetPromptShouldBlock";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { getLogsCloudWatchData } from "./data/cloudWatch";
import { getLogsFluentdData } from "./data/fluentd";
import { CloudWatch } from "./cloudwatch";
import { Fluentd } from "./fluentd";
import { getLogsStackdriverData } from "./data/stackdriver";
import { Stackdriver } from "./stackdriver";
import { getLogsSyslogData } from "./data/syslog";
import { Syslog } from "./syslog";

type LogProvider =
  | "cloudWatch"
  | "coralogix"
  | "datadog"
  | "elastic"
  | "fluentd"
  | "logzio"
  | "s3"
  | "stackdriver"
  | "syslog";
const logProviderOptions: { value: LogProvider; label: string }[] = [
  { value: "cloudWatch", label: "CloudWatch" },
  { value: "coralogix", label: "Coralogix" },
  { value: "datadog", label: "Datadog" },
  { value: "elastic", label: "Elastic" },
  { value: "fluentd", label: "Fluentd" },
  { value: "logzio", label: "Logz.io" },
  { value: "s3", label: "S3" },
  { value: "stackdriver", label: "Stackdriver" },
  { value: "syslog", label: "Syslog" },
];

interface Props {
  org: OrgMobx;
}
const LogsRaw: React.FC<Props> = ({ org }) => {
  const basePath = React.useContext(BasePathContext);
  const externalLogsPath = `${basePath}/-externallogs`;

  const { fetchItem } = useDetailContext();

  const navigate = useNavigate();

  function getDataItem(provider: LogProvider, data?: any) {
    if (!data) {
      data = { [provider]: {} };
    }
    switch (provider) {
      case "cloudWatch":
        return getLogsCloudWatchData(data);
      case "coralogix":
        return getLogsCoralogixData(data, org.name);
      case "datadog":
        return getLogsDatadogData(data);
      case "elastic":
        return getLogsElasticData(data);
      case "fluentd":
        return getLogsFluentdData(data);
      case "logzio":
        return getLogsLogzioData(data);
      case "s3":
        return getLogsS3Data(data);
      case "stackdriver":
        return getLogsStackdriverData(data);
      case "syslog":
        return getLogsSyslogData(data);
    }
  }

  function getDefaultPrimaryProvider() {
    if (!org.spec?.logging) {
      return "none";
    }
    const logging: any[] = JSON.parse(JSON.stringify(org.spec?.logging));
    const provider = Object.keys(logging)[0] as LogProvider;
    return provider;
  }
  const primaryProviderSelectRef = React.useRef(
    SelectModel.create({
      label: "Primary Provider",
      options: [
        { value: "none", label: "None" },
        { value: "cloudWatch", label: "CloudWatch" },
        { value: "coralogix", label: "Coralogix" },
        { value: "datadog", label: "Datadog" },
        { value: "elastic", label: "Elastic" },
        { value: "fluentd", label: "Fluentd" },
        { value: "logzio", label: "Logz.io" },
        { value: "s3", label: "S3" },
        { value: "stackdriver", label: "Stackdriver" },
        { value: "syslog", label: "Syslog" },
      ],
      initialValue: getDefaultPrimaryProvider(),
    })
  );
  function getDefaultPrimaryProviderData() {
    if (org.spec?.logging) {
      const logging: any[] = JSON.parse(JSON.stringify(org.spec?.logging));
      const provider = Object.keys(logging)[0] as LogProvider;
      const dataItem: any = getDataItem(provider, logging[provider as any]);
      return dataItem;
    }
    return { logProvider: "none" };
  }
  const primaryProviderDataRef = React.useRef<any>(getDefaultPrimaryProviderData());
  const temporaryPrimaryProviderDataRef = React.useRef<any>({ none: { logProvider: "none" } });

  function saveTemporaryData() {
    if (primaryProviderSelectRef.current.value !== "none") {
      const temporaryData = temporaryPrimaryProviderDataRef.current;
      temporaryData[primaryProviderSelectRef.current.value] = primaryProviderDataRef.current.asObject();
      temporaryPrimaryProviderDataRef.current = temporaryData;
    }
  }

  function getDefaultProviders() {
    const extraLogging: any[] = JSON.parse(JSON.stringify(org.spec?.extraLogging || []));
    return extraLogging.map((cfg) => Object.keys(cfg)[0]) as LogProvider[];
  }
  function getDefaultProviderData() {
    const data: any = [];
    const extraLogging: any[] = JSON.parse(JSON.stringify(org.spec?.extraLogging || []));

    for (const logging of extraLogging) {
      const provider = Object.keys(logging)[0] as LogProvider;
      const dataItem: any = getDataItem(provider, logging[provider]);
      data.push(dataItem);
    }
    return data;
  }
  const providerDataRef = React.useRef<any[]>(getDefaultProviderData());

  const [isAddingNewProvider, setIsAddingNewProvider] = React.useState<boolean>(false);
  const [providerToAdd, setProviderToAdd] = React.useState<LogProvider>("cloudWatch");

  const canAddProvider = primaryProviderSelectRef.current.value !== "none" && providerDataRef.current.length < 3;

  function addProvider(provider: LogProvider) {
    if (!canAddProvider) {
      return;
    }
    providerDataRef.current.push(getDataItem(provider));
  }

  function removeProvider(index: number) {
    providerDataRef.current.splice(index, 1);
    navigate(externalLogsPath);
    trigger();
  }

  const [triggerIndex, setTrigger] = React.useState(0);
  function trigger() {
    setTrigger((x) => x + 1);
  }

  // providers done

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();
  useSetPromptShouldBlock(shouldBlockNavigation);

  const [executedInitially, setExecutedInitially] = React.useState(false);
  React.useEffect(() => {
    if (!executedInitially) {
      setExecutedInitially(true);
      return;
    }
    const newProvider = primaryProviderSelectRef.current.value;
    if (newProvider === "none") {
      primaryProviderDataRef.current = { logProvider: "none" };
    } else {
      let data = temporaryPrimaryProviderDataRef.current[newProvider];
      if (data) {
        data = Object.values(data)[0];
      }
      primaryProviderDataRef.current = getDataItem(newProvider as any, data) as any;
    }
    trigger();
  }, [primaryProviderSelectRef.current.value]);

  React.useEffect(() => {
    navigate(`${externalLogsPath}/primary`);
  }, []);

  // dirty interval
  React.useEffect(() => {
    const process = () => {
      let res = false;
      if (primaryProviderSelectRef.current.value !== getDefaultPrimaryProvider()) {
        res = true;
      }
      if (primaryProviderSelectRef.current.value !== "none") {
        if (primaryProviderDataRef.current.isDirty()) {
          res = true;
        }
      }
      if (providerDataRef.current.length !== getDefaultProviders().length) {
        res = true;
      }
      if (providerDataRef.current.some((d) => d.isDirty())) {
        res = true;
      }
      setIsDirty(res);
    };
    process();
    const handler = setInterval(process, 200);
    return () => clearInterval(handler);
  }, [primaryProviderSelectRef.current.value, triggerIndex]);

  // valid interval
  React.useEffect(() => {
    const process = () => {
      let res = true;
      if (primaryProviderSelectRef.current.value !== "none") {
        if (!primaryProviderDataRef.current.isValid()) {
          res = false;
        }
      }
      if (providerDataRef.current.some((d) => !d.isValid())) {
        res = false;
      }
      if (providerDataRef.current.some((d) => !d.isValid())) {
        res = false;
      }
      setIsValid(res);
    };
    process();
    const handler = setInterval(process, 200);
    return () => clearInterval(handler);
  }, [primaryProviderSelectRef.current.value, triggerIndex]);

  function reset() {
    primaryProviderSelectRef.current.reset();
    primaryProviderDataRef.current = getDefaultPrimaryProviderData();
    providerDataRef.current = getDefaultProviderData();
    trigger();
    navigate(externalLogsPath);
  }

  async function save() {
    try {
      setIsLoading(true);

      const body: any = { spec: {} };

      let extraLogging: any[] = [];
      if (primaryProviderSelectRef.current.value === "none") {
        body.spec.logging = null;
      } else {
        body.spec["$replace/logging"] = primaryProviderDataRef.current.asObject();
        extraLogging = providerDataRef.current.map((d) => d.asObject());
      }

      if (extraLogging.length > 0) {
        if (!!org.spec?.extraLogging) {
          body.spec["$replace/extraLogging"] = extraLogging;
        } else {
          body.spec.extraLogging = extraLogging;
        }
      } else {
        if (!!org.spec?.extraLogging) {
          body.spec.extraLogging = null;
        }
      }

      await org.patch(body);

      if (fetchItem) {
        await fetchItem();
      }

      trigger();
      notification.success({
        message: "Success",
        description: "Updated Org External Logs",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  const links: TabsLayoutLink[] = [];

  links.push({
    url: `${externalLogsPath}/primary`,
    label: "Primary Provider",
    isActive: true,
  });
  if (primaryProviderSelectRef.current.value !== "none") {
    for (const index in providerDataRef.current) {
      const data = providerDataRef.current[index];
      links.push({
        url: `${externalLogsPath}/${String(index)}`,
        label: logProviderOptions.find((option) => option.value === data.logProvider)?.label || "not found",
        isActive: true,
      });
    }
  }
  if (canAddProvider) {
    links.push({
      onClick: () => {
        setIsAddingNewProvider(true);
      },
      url: "",
      label: "+ Add Extra Provider",
      isActive: true,
    });
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/-externallogs");
  }

  return (
    <>
      <div key={triggerIndex}>
        <TabsLayout links={links}>
          <Routes>
            <Route
              key={triggerIndex}
              path={`primary`}
              element={
                <>
                  <NGLabel>{primaryProviderSelectRef.current.label}</NGLabel>
                  <NGSelect
                    style={{ width: 450 }}
                    className="mb-4"
                    onChange={(value) => {
                      saveTemporaryData();
                      primaryProviderSelectRef.current.setValue(value);
                    }}
                    value={primaryProviderSelectRef.current.value}
                    options={primaryProviderSelectRef.current.options}
                  />
                  {primaryProviderDataRef.current.logProvider === "cloudWatch" ? (
                    <CloudWatch cloudWatch={primaryProviderDataRef.current} formPrefix="primary" />
                  ) : null}
                  {primaryProviderDataRef.current.logProvider === "coralogix" ? (
                    <Coralogix coralogix={primaryProviderDataRef.current} />
                  ) : null}
                  {primaryProviderDataRef.current.logProvider === "datadog" ? (
                    <Datadog datadog={primaryProviderDataRef.current} />
                  ) : null}
                  {primaryProviderDataRef.current.logProvider === "elastic" ? (
                    <Elastic elastic={primaryProviderDataRef.current} />
                  ) : null}
                  {primaryProviderDataRef.current.logProvider === "fluentd" ? (
                    <Fluentd fluentd={primaryProviderDataRef.current} formPrefix="primary" />
                  ) : null}
                  {primaryProviderDataRef.current.logProvider === "logzio" ? (
                    <Logzio logzio={primaryProviderDataRef.current} />
                  ) : null}
                  {primaryProviderDataRef.current.logProvider === "s3" ? (
                    <S3 s3={primaryProviderDataRef.current} />
                  ) : null}
                  {primaryProviderDataRef.current.logProvider === "stackdriver" ? (
                    <Stackdriver stackdriver={primaryProviderDataRef.current} formPrefix="primary" />
                  ) : null}
                  {primaryProviderDataRef.current.logProvider === "syslog" ? (
                    <Syslog syslog={primaryProviderDataRef.current} formPrefix="primary" />
                  ) : null}
                </>
              }
            />
            {providerDataRef.current.map((providerData, index) => {
              return (
                <Route
                  key={triggerIndex + "-" + index}
                  path={String(index)}
                  element={
                    <>
                      {providerData.logProvider === "cloudWatch" ? (
                        <CloudWatch
                          cloudWatch={providerData}
                          onRemove={() => removeProvider(index)}
                          formPrefix={`external-${index}`}
                        />
                      ) : null}
                      {providerData.logProvider === "coralogix" ? (
                        <Coralogix coralogix={providerData} onRemove={() => removeProvider(index)} />
                      ) : null}
                      {providerData.logProvider === "datadog" ? (
                        <Datadog datadog={providerData} onRemove={() => removeProvider(index)} />
                      ) : null}
                      {providerData.logProvider === "elastic" ? (
                        <Elastic elastic={providerData} onRemove={() => removeProvider(index)} />
                      ) : null}
                      {providerData.logProvider === "fluentd" ? (
                        <Fluentd
                          fluentd={providerData}
                          onRemove={() => removeProvider(index)}
                          formPrefix={`external-${index}`}
                        />
                      ) : null}
                      {providerData.logProvider === "logzio" ? (
                        <Logzio logzio={providerData} onRemove={() => removeProvider(index)} />
                      ) : null}
                      {providerData.logProvider === "s3" ? (
                        <S3 s3={providerData} onRemove={() => removeProvider(index)} />
                      ) : null}
                      {providerData.logProvider === "stackdriver" ? (
                        <Stackdriver
                          stackdriver={providerData}
                          onRemove={() => removeProvider(index)}
                          formPrefix={`external-${index}`}
                        />
                      ) : null}
                      {providerData.logProvider === "syslog" ? (
                        <Syslog
                          syslog={providerData}
                          onRemove={() => removeProvider(index)}
                          formPrefix={`external-${index}`}
                        />
                      ) : null}
                    </>
                  }
                />
              );
            })}
            <Route index element={<Navigate to={`primary`} />} />
          </Routes>
        </TabsLayout>
        <div style={{ width: 450 }}>
          <FormButtons
            onReset={reset}
            onSave={save}
            resetDisabled={isLoading || !isDirty}
            saveDisabled={isLoading || !isDirty || !isValid}
            loading={isLoading}
          />
        </div>
      </div>
      {isAddingNewProvider ? (
        <Modal
          open={isAddingNewProvider}
          title={"Add New External Log Provider"}
          onCancel={() => setIsAddingNewProvider(false)}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setIsAddingNewProvider(false)}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={() => {
                  if (providerToAdd) {
                    addProvider(providerToAdd);
                    setIsAddingNewProvider(false);
                    navigate(`${externalLogsPath}/${String(providerDataRef.current.length - 1)}`);
                  }
                }}
              >
                OK
              </NGButton>
            </div>
          }
        >
          <NGSelect
            style={{ width: 450 }}
            options={logProviderOptions}
            value={providerToAdd || undefined}
            onChange={(value) => {
              setProviderToAdd(value as any);
            }}
          />
        </Modal>
      ) : null}
    </>
  );
};

export const Logs = observer(LogsRaw);
