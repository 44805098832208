import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { DownloadOutlined } from "@ant-design/icons";
import { Copy } from "react-feather";
import { NGButton } from "../../newcomponents/button/Button";

interface Props {
  keyContent: string;
  keyPath: string;
  onDone: any;
  visible: boolean;
}

const KeyModalRaw: React.FC<Props> = ({ keyContent, keyPath, onDone, visible }) => {
  const [tookAction, setTookAction] = React.useState(false);

  async function copyToClipboard() {
    navigator.clipboard.writeText(keyContent);
    notification.success({
      message: "Copied to clipboard",
    });
    setTookAction(true);
  }

  function getHrefOfKey() {
    const file = new Blob([keyContent], { type: "text/txt" });
    return URL.createObjectURL(file);
  }

  return (
    <Modal
      title={"Service Account Key"}
      open={visible}
      closable={false}
      destroyOnClose
      footer={
        <div className="modal-actions">
          <NGButton
            variant="secondary"
            renderIcon={(hover, props) => <DownloadOutlined size={20} {...props} />}
            onClick={() => setTookAction(true)}
          >
            <a href={getHrefOfKey()} download={`${keyPath}.txt`}>
              Download Key File
            </a>
          </NGButton>
          <NGButton variant="primary" onClick={onDone} disabled={!tookAction}>
            Done
          </NGButton>
        </div>
      }
    >
      <div>
        <div>A new key has been created for this service account.</div>
        <div>Copy or download the generated key below.</div>
        <div>You will not be able to retrieve the key after you close this screen.</div>
        <div className="flex items-center mt-2">
          <input className="flex-grow h-8 px-4 py-2 border rounded" disabled={true} value={keyContent} />
          <button onClick={copyToClipboard} className={`ml-2 ngfocus color-link`}>
            <Copy data-testid="copy-key" className="inline-block feather-icon" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const KeyModal = observer(KeyModalRaw);
