import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { TableColumn } from "../../types";
import { gvcScopedKinds } from "../../../../utils/kinds";
import { InternalLink } from "../../components/InternalLink";

const nameDefault = { id: "name", label: "Name" };
export function NameColumn<TData extends { name: string; kind: string }>(
  column: Partial<TableColumn<TData>> = nameDefault,
) {
  const preset: TableColumn<TData> = {
    ...nameDefault,
    enableResize: true,
    enableSort: false,
    size: 200,
    cell: (p) => {
      const item = p.row.original;
      let link = `/console/org/${ConsoleContext.org}${
        gvcScopedKinds.includes(item.kind) ? `/gvc/${ConsoleContext.gvc}` : ""
      }/${item.kind}/${item.name}`;

      return (
        <div className="flex flex-col justify-center" style={{ height: 56 }}>
          <InternalLink label={item.name} to={link} />
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
