import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { mk8sMobx } from "../../mst/kinds/mk8s/mk8s";
import { getLocalToken, request } from "../../services/cpln";
import { KUBERNETES_READINESS_UPDATE_INTERVAL_MS, MK8S_DASHBOARD_TOKEN_UPDATE_INTERVAL_MS } from "../../envVariables";
import { FormElement } from "../../components/forms/formElement";
import { Mk8sDraftMobx } from "../../mst/stores/mk8s.draft";
import { mk8sStatus } from "../../mst/kinds/mk8s/status";
import { NGButton } from "../../newcomponents/button/Button";
import { NGLabel } from "../../newcomponents/text/label";
import { NGInput } from "../../newcomponents/input/input";
import { NGForm } from "../../newcomponents/form/ngform";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { useKubernetesReadinessReducer } from "../../components/detail/kubernetesReadinessReducer";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const InfoRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const { state, fetchReadiness } = useKubernetesReadinessReducer();

  const [isUpgrading, setIsUpgrading] = React.useState(false);
  const [isProcessingUpgrade, setIsProcessingUpgrade] = React.useState(false);
  const [newVersionOptions, setNewVersionOptions] = React.useState<any[]>([]);
  const [newVersion, setNewVersion] = React.useState(mk8s.spec.version);

  React.useEffect(() => {
    fetchSchemaData();

    const unsubscribe = fetchReadiness([mk8s.name], KUBERNETES_READINESS_UPDATE_INTERVAL_MS);
    return () => {
      unsubscribe();
    };
  }, []);

  async function fetchSchemaData() {
    // Versions
    try {
      const { data: schemaRes } = await request({ url: "/catalog/schema/mk8s" });
      const versions: string[] = schemaRes.keys.spec.keys.version.allow;
      mk8sDraft.version.setOptions(versions.map((v) => ({ label: v, value: v })));
      const _newVersionOptions = versions.filter((v) => v !== mk8s.spec.version).map((v) => ({ label: v, value: v }));
      setNewVersionOptions(_newVersionOptions);
      setNewVersion(_newVersionOptions[_newVersionOptions.length - 1].value);
    } catch (e) {
      console.error("Failed to get versions from mk8s catalog schema");
    }
  }

  const dashboardFormRef = React.useRef<HTMLFormElement>(null as any);
  const metricsFormRef = React.useRef<HTMLFormElement>(null as any);
  const logsFormRef = React.useRef<HTMLFormElement>(null as any);
  const [tokenFromLocal, setTokenFromLocal] = React.useState("");
  React.useEffect(() => {
    getLocalToken().then((res) => {
      if (res.accessToken !== "pass") {
        setTokenFromLocal(res.accessToken);
      }
    });
    const localTokenInterval = setInterval(() => {
      getLocalToken().then((res) => {
        if (res.accessToken !== "pass") {
          setTokenFromLocal(res.accessToken);
        }
      });
    }, MK8S_DASHBOARD_TOKEN_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(localTokenInterval);
    };
  }, []);

  function onDashboardButton(e: any) {
    e.preventDefault();
    if (dashboardFormRef.current) {
      dashboardFormRef.current.submit();
    }
  }

  function onMetricsButton(e: any) {
    e.preventDefault();
    if (metricsFormRef.current) {
      metricsFormRef.current.submit();
    }
  }

  function onLogsButton(e: any) {
    e.preventDefault();
    if (logsFormRef.current) {
      logsFormRef.current.submit();
    }
  }

  async function onConfirmUpgrade() {
    try {
      setIsProcessingUpgrade(true);
      const body = {
        spec: {
          version: newVersion,
        },
      };
      await mk8s.patch(body);
      mk8sDraft.confirm();
      notification.success({ message: "Success", description: "Upgraded K8s Cluster Version" });
      setIsProcessingUpgrade(false);
      setIsUpgrading(false);
    } catch (e) {
      setIsProcessingUpgrade(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      mk8sDraft.reset();
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  const status = mk8s.status as mk8sStatus;

  const formDataRef = React.useRef(new NGFormData());
  const formData = formDataRef.current;

  const readiness = state.readinessMap[mk8s.name] || { ready: false };
  const fetchedReadiness = state.requestedClusterNames.includes(mk8s.name);

  return (
    <>
      <NGForm data={formData}>
        <NGLabel name={"description"}>Description</NGLabel>
        <NGInput
          name={"description"}
          placeholder={mk8sDraft.name.value}
          value={mk8sDraft.description.value}
          onChange={(e) => mk8sDraft.description.setValue(e.target.value)}
          className="mb-4"
          style={{ width: 450 }}
          invalid={!mk8sDraft.description.isValid}
        />
      </NGForm>
      <div className="mb-4">
        <FormElement label={"Alias"} value={mk8s.alias} canCopy />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col items-start gap-1">
          <div className="flex items-center gap-2">
            <NGLabel>Kubernetes Version</NGLabel>
            <NGButton
              size={"small"}
              variant={"primary"}
              onClick={() => setIsUpgrading(true)}
              disabled={mk8s.spec.version === mk8sDraft.version.options[mk8sDraft.version.options.length - 1]?.value}
            >
              Upgrade
            </NGButton>
          </div>
          <div>
            {mk8s.spec.version}
            {mk8s.spec.version === mk8sDraft.version.options[mk8sDraft.version.options.length - 1]?.value
              ? " (Latest Supported)"
              : ""}
          </div>
        </div>
        <div className="mt-4">
          <NGLabel>Status</NGLabel>
          <span className={`${fetchedReadiness ? (readiness.ready ? "color-action" : "color-danger") : ""}`}>
            {fetchedReadiness ? (readiness.ready ? "Ready" : "Not Ready") : "Loading"}
          </span>
          {fetchedReadiness && readiness.message ? <span> | {readiness.message}</span> : null}
        </div>
        {status.addOns?.dashboard?.url ? (
          <>
            <div className="flex flex-col items-start gap-1 mt-4">
              <NGLabel>Dashboard URL</NGLabel>
              <button className="color-link ngfocus" onClick={onDashboardButton}>
                {status.addOns.dashboard.url}
              </button>
              <form
                className="hidden"
                ref={dashboardFormRef}
                action={`${status.addOns.dashboard.url}/auth/process/#/node?namespace=default`}
                method="POST"
                target="_blank"
              >
                <input name="redirect" value={status.addOns.dashboard.url} type="hidden" />
                <input name="idToken" value={tokenFromLocal} type="hidden" />
                <button type="submit">dashboard</button>
              </form>
            </div>
          </>
        ) : null}
        <TagLinksTable
          tableId="mk8s-info-tag-links"
          classNames={{ header: "mt-4" }}
          tags={mk8sDraft.tags.editTags.filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
          styles={{ header: { marginBottom: 0 } }}
        />
      </div>
      {/* {IS_LOCAL ? (
        <div>
          <div>is dirty? {String(mk8sDraft.isDirty)}</div>
          <div>dirty reason = {mk8sDraft.isDirtyReason || "none"}</div>
          <div>is valid = {mk8sDraft.isValid || "none"}</div>
          <div>invalid reason = {mk8sDraft.invalidReason || "none"}</div>
        </div> ) : null } */}
      {isUpgrading ? (
        <Modal
          title={"Upgrade Kubernetes"}
          open={true}
          onCancel={() => setIsUpgrading(false)}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setIsUpgrading(false)} disabled={isProcessingUpgrade}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                disabled={isProcessingUpgrade}
                loading={isProcessingUpgrade}
                onClick={async () => {
                  mk8sDraft.version.setValue(newVersion);
                  await onConfirmUpgrade();
                }}
              >
                Upgrade
              </NGButton>
            </div>
          }
        >
          <div className="flex flex-col gap-2">
            <div>
              Select the version to upgrade kubernetes from version <span>{mk8s.spec.version}</span>
            </div>
            <NGSelect value={newVersion} onChange={(value) => setNewVersion(value)} options={newVersionOptions} />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export const Info = observer(InfoRaw);
