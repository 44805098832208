import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";

export const Mk8sDraftAddonAWSEFSModel = types
  .model({
    _isEnabled: types.optional(types.boolean, false),
    isEnabled: types.optional(types.boolean, false),
    _roleArn: types.optional(types.string, ""),
    roleArn: types.optional(StringModel, () =>
      StringModel.create({ label: "Role ARN", validationKey: "mk8sAwsEFSRoleArn", isRequired: true })
    ),
  })
  .actions((self) => ({
    setIsEnabled(value: boolean) {
      self.isEnabled = value;
    },
  }))
  .actions((self) => ({
    reset() {
      self.isEnabled = self._isEnabled;
      self.roleArn.setInitialValue(self._roleArn);
    },
    confirm() {
      self._isEnabled = self.isEnabled;
      self.roleArn.confirm();
      self._roleArn = self.roleArn.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (self.isEnabled) {
        if (!self.roleArn.isValid) res = false;
      }
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.isEnabled) {
        if (!self.roleArn.isValid) reason = "roleArn";
      }
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.isEnabled !== self._isEnabled) res = true;
      if (self.isEnabled) {
        if (self.roleArn.isDirty) res = true;
      }
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.isEnabled !== self._isEnabled) reason = "enabled changed";
      if (self.isEnabled) {
        if (self.roleArn.isDirty) reason = self.roleArn.isDirtyReason;
      }
      return reason;
    },
    get asObject() {
      let res: any = {
        roleArn: self.roleArn.value,
      };
      return res;
    },
  }));
