import { types, Instance } from "mobx-state-tree";
import { objectToArray } from "./../services/utils";
import type { _Kind, _WithKind, _WithKindMobx } from "./kinds";
import { _KindProperty, _KindWithPolicyProperty, _WithKindModel } from "./kinds";
import { _defaultValues } from "./../services/defaults";

const LinkModel = types.model({
  href: "",
  rel: types.enumeration([
    "self",
    "next",
    "install",
    "uninstall",
    "queryresult",
    "reveal",
    "target",
    "resource",
    // Below is a copy of kind
    "accessreport",
    "account",
    "agent",
    "auditctx",
    "cloudaccount",
    "deployment",
    "domain",
    "event",
    "group",
    "gvc",
    "identity",
    "image",
    "location",
    "logsummary",
    "memcachecluster",
    "org",
    "permissions",
    "policy",
    "quota",
    "secret",
    "serviceaccount",
    "spicedbcluster",
    "task",
    "user",
    "volumeset",
    "workload",
  ]),
});
export interface LinkMobx extends Instance<typeof LinkModel> {}
export interface Link {
  href: string;
  rel:
    | "self"
    | "next"
    | "queryresult"
    | "reveal"
    // below is just kinds
    | _Kind;
}

export const WithLinksModel = types
  .model({
    links: types.array(LinkModel),
  })
  .views((self) => ({
    get selfLink(): string {
      return self.links.find((l) => l.rel === "self")?.href || "";
    },
  }));
export interface WithLinksMobx extends Instance<typeof WithLinksModel> {}
export interface WithLinks {
  links: Link[];
}

export const WithCreatedModel = types.model({
  created: "",
});
export interface WithCreatedMobx extends Instance<typeof WithCreatedModel> {}
export interface WithCreated {
  created: string;
}

export const WithLastModifiedModel = types.model({
  lastModified: "",
});
export interface WithLastModifiedMobx extends Instance<typeof WithLastModifiedModel> {}
export interface WithLastModified {
  lastModified: string;
}

export const WithJSONModel = types.model({
  json: types.frozen(),
});
export interface WithJSONMobx extends Instance<typeof WithJSONModel> {}
export interface WithJSON {
  json: any;
}

export const WithNameModel = types.model({
  name: types.string,
});
export interface WithNameMobx extends Instance<typeof WithNameModel> {}
export interface WithName {
  name: string;
}

export const WithTagsModel = types
  .model({
    tags: types.optional(types.frozen(), {}),
  })
  .views((self) => ({
    get tagsSingleArray(): string[] {
      return Object.entries(self.tags).map((entry) => entry.join("="));
    },
    get tagsArray(): string[][] {
      return objectToArray(self.tags)
        .slice()
        .sort((a, b) => {
          var keyA = a[0];
          var keyB = b[0];
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
    },
    get tagsMap(): Map<string, string> {
      const tagObj = self.tags;
      const tagMap = new Map();
      for (let key of Object.keys(tagObj)) {
        const isNumber = !Number.isNaN(String(tagObj[key]));
        tagMap.set(key, isNumber ? Number(tagObj[key]) : String(tagObj[key]));
      }
      return tagMap;
    },
  }))
  .views((self) => ({
    get tagsAsKeyValueArray(): { key: string; value: string }[] {
      const res: any = [];
      for (let tag of self.tagsArray) {
        res.push({ key: tag[0], value: String(tag[1]) });
      }
      return res;
    },
  }))
  .actions((self) => {
    function setTags(tags: object) {
      const filteredTags = { ...tags };
      for (let key of Object.keys(tags)) {
        // @ts-ignore
        if (!tags[key]) {
          // @ts-ignore
          delete filteredTags[key];
        }
      }
      self.tags = filteredTags;
    }
    function addTags(tags: object) {
      const selfTags = { ...self.tags };
      for (let key of Object.keys(tags)) {
        // @ts-ignore
        selfTags[key] = tags[key];
      }
      self.tags = selfTags;
    }
    return { setTags, addTags };
  });
export interface WithTagsMobx extends Instance<typeof WithTagsModel> {}
export interface WithTags {
  tags: { [_: string]: string | undefined | null | number };
}

export const WithDescriptionModel = types.model({
  description: "",
});
export interface WithDescriptionMobx extends Instance<typeof WithDescriptionModel> {}
export interface WithDescription {
  description: string;
}

export const WithNameAndDescriptionModel = types.compose(WithNameModel, WithDescriptionModel).views((self) => ({
  get descriptionPretty() {
    return self.name === self.description ? "" : self.description;
  },
}));
export interface WithNameAndDescriptionMobx extends Instance<typeof WithNameAndDescriptionModel> {}
export interface WithNameAndDescription extends WithName, WithDescription {}

export const WithCreatedAndLastModifiedModel = types.compose(WithCreatedModel, WithLastModifiedModel).views((self) => ({
  get updated() {
    return self.created !== self.lastModified;
  },
}));
export interface WithCreatedAndLastModifiedMobx extends Instance<typeof WithCreatedAndLastModifiedModel> {}
export interface WithCreatedAndLastModified extends WithCreated, WithLastModified {}

export const WithVersionModel = types.model({
  version: types.optional(
    types.refinement(types.number, (value) => value > 0),
    1
  ),
});
export interface WithVersionMobx extends Instance<typeof WithVersionModel> {}
export interface WithVersion {
  version: number;
}

export const WithIdentifierModel = types.model({
  id: types.identifier,
});
export interface WithIdentifierMobx extends Instance<typeof WithIdentifierModel> {}
export interface WithIdentifier {
  id: string;
}

export const BaseModel = types
  .compose(
    "Base",
    WithIdentifierModel,
    WithVersionModel,
    WithNameAndDescriptionModel,
    _WithKindModel,
    WithLinksModel,
    WithCreatedAndLastModifiedModel,
    WithTagsModel
  )
  .views((self) => ({
    get baseJson() {
      return {
        id: self.id,
        version: self.version,
        name: self.name,
        description: self.description,
        kind: self.kind,
        links: JSON.parse(JSON.stringify(self.links)),
        created: self.created,
        lastModified: self.lastModified,
        tags: self.tags,
      };
    },
  }));
export interface BaseMobx extends Instance<typeof BaseModel> {}

export type Kind = _Kind;
export interface WithKind extends _WithKind {}
export interface WithKindMobx extends _WithKindMobx {}

export const KindProperty = _KindProperty;
export const KindWithPolicyProperty = _KindWithPolicyProperty;
export const WithKindModel = _WithKindModel;
export const defaultValues = _defaultValues;

export const NameValueModel = types.model("NameValueModel", {
  name: "",
  value: "",
});
export interface NameValueMobx extends Instance<typeof NameValueModel> {}
export interface NameValue {
  name: string;
  value: string;
}
export function getDefaultNameValue(): NameValue {
  return { name: "", value: "" };
}
