import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";

export const Mk8sDraftUnmanagedNodePoolReadOnlyModel = types.model({
  name: types.optional(types.string, ""),
  labels: types.array(
    types.model({
      key: types.string,
      value: types.string,
    })
  ),
  taints: types.array(
    types.model({
      key: types.string,
      value: types.string,
      effect: types.string,
    })
  ),
});

export const Mk8sDraftUnmanagedNodePoolModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["default", "added"]), "default"),
    _pool: types.optional(Mk8sDraftUnmanagedNodePoolReadOnlyModel, () =>
      Mk8sDraftUnmanagedNodePoolReadOnlyModel.create()
    ),
    name: types.optional(StringModel, () =>
      StringModel.create({ label: "Name", validationKey: "nodePoolName", isRequired: true })
    ),
    labels: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    taints: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
  })
  .actions((self) => ({
    setStatusAdded() {
      self.status = "added";
    },
    reset() {
      self.name.setInitialValue(self._pool.name);

      self.labels = ListOfItemsModel.create({
        _items: self._pool.labels.map((l) => ({ firstValue: l.key, secondValue: l.value })),
      });
      self.taints = ListOfItemsModel.create({
        _items: self._pool.taints.map((l) => ({ firstValue: l.key, secondValue: l.value, thirdValue: l.effect })),
      });
    },
    confirm() {
      self.name.confirm();
      self.labels.confirm();
      self.taints.confirm();

      const _pool = {
        name: self.name.value, //
        labels: self.labels.items.map((l) => ({ key: l.firstValue, value: l.secondValue })),
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
      };

      self._pool = Mk8sDraftUnmanagedNodePoolReadOnlyModel.create(_pool);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isLabelsValid() {
      let res = true;
      if (self.labels.items.some((l) => !l.firstValue || !l.secondValue)) res = false;
      return res;
    },
    get isTaintsValid() {
      let res = true;
      if (self.taints.items.some((t) => !t.firstValue || !t.secondValue || !t.thirdValue)) res = false;
      return res;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.isLabelsValid) res = false;
      if (!self.isTaintsValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.name.isValid) reason = self.name.invalidReason;
      if (!self.isLabelsValid) reason = "labels invalid";
      if (!self.isTaintsValid) reason = "taints invalid";
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.labels.isDirty) res = true;
      if (self.taints.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.name.isDirtyReason) reason = self.name.isDirtyReason;
      if (self.labels.isDirty) reason = "labels dirty";
      if (self.taints.isDirty) reason = "taints dirty";
      return reason;
    },
    get asObject() {
      const labels: { [_: string]: string } = {};
      self.labels.items.forEach((l) => {
        labels[l.firstValue] = l.secondValue;
      });
      return {
        name: self.name.value,
        labels: labels,
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
      };
    },
  }));
