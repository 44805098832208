import * as React from "react";
import qs from "qs";
import { observer } from "mobx-react-lite";
import { User } from "../mobxStores/user/user";
import { Navigate } from "react-router-dom";

const TriggerSentryRaw: React.FC = () => {
  const [message, setMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    const { message } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    setMessage((message || "Testing sentry") as string);
  }, []);

  if (!User.isCplnTeam) {
    return <Navigate to={`/`} />;
  }

  if (message === null) return null;

  throw new Error(message);
};

export const TriggerSentry = observer(TriggerSentryRaw);
