import { action, computed, flow, makeObservable, observable } from "mobx";
import { ChargeableItem } from "../../pages/usage-ng/utils";
import { request } from "../../services/cpln";
import { getBillingErrorMessage } from "../../services/utils";
import { UIData } from "../uiData/uiData";
import moment from "moment-timezone";
import { sortBy } from "lodash";

interface PaymentMethod {
  id: string;
}

class BillingAlertMobx {
  amount: number = 0;
  enabled: boolean = false;

  constructor() {
    makeObservable(this, {
      amount: observable,
      enabled: observable,
      setAmount: action,
      setEnabled: action,
    });
  }

  setAmount(amount: number) {
    this.amount = amount;
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled;
  }
}

export interface BillingUser {
  roles: string[];
  email: string;
}

export interface BillingAccountType {
  name: string;
  startingAccountsPermittedPerDomain: number;
  requiresPaymentMethod: boolean;
  startingOrgsPermittedPerAccount: number;
  requiresUniqueCreatorEmail: boolean;
}

export interface BillingTrustLevel {
  id: string;
  name: string;
  description: string;
  accumulatedChargeTrigger: number;
  deactivateOnFailedCharge: boolean;
}

export interface BillingChargeableItem {
  id: string;
  name: string;
  description: string;
  consumptionTags: string;
  unit: string;
}
export interface BillingRatePlanItem {
  id: string;
  chargeRate: number;
  chargeRateDenominator: number;
  meteringThreshold: number;
  meteringGroup: string;
  chargeableItem: BillingChargeableItem;
}
export interface BillingRatePlan {
  id: string;
  name: string;
  description: string;
  currency: string;
  items: BillingRatePlanItem[];
}
export interface BillingAccountState {
  id: string;
  startTime: string;
  endTime: string | null;
  paymentMethodType: string;
  ratePlan: BillingRatePlan;
  trustLevel: BillingTrustLevel;
  type: string;
  accountType: BillingAccountType;
  active: boolean;
}

export interface BillingInvoicePeriodItem {
  id: string;
  description: string;
  quantity: number;
  charge: number;
  org: string;
  ratePlanItem: BillingRatePlanItem;
}

export interface BillingInvoicePeriod {
  id: string;
  startTime: string;
  endTime: string;
  totalCharge: number;
  exportedAt: string | null;
  createdAt: string;
  accountState: BillingAccountState;
  items: BillingInvoicePeriodItem[];
}

export interface BillingInvoice {
  createdAt: string;
  createdBy: string;
  endTime: string;
  exportedAt: string;
  id: string;
  number: number;
  periods: BillingInvoicePeriod[];
  startTime: string;
  totalCharge: number;
}

class BillingContextMobx {
  ChargeableItems: ChargeableItem[] = [];

  AccountUUID: string = "";
  AccountName: string = "";
  AccountType: string = "";
  AccountIsDisabled: boolean = false;
  BillingStart: string = "";
  TrialStart: string = "";
  TrialEnd: string = "";
  FullName: string = "";
  Company: string = "";
  Phone: string = "";
  Email: string = "";
  // TODO add type
  Address: any = {};
  OrgNames: string[] = [];
  Users: BillingUser[] = [];
  PaymentMethods: PaymentMethod[] = [];
  Roles: string[] = [];
  Invoices: BillingInvoice[] = [];
  Alert = new BillingAlertMobx();
  isTouAccepted: boolean = false;
  isLoading: boolean = false;
  isAccountInfoLoading: boolean = false;
  isAccountAddressLoading: boolean = false;
  isPaymentMethodsLoading: boolean = false;
  isOrgsLoading: boolean = false;
  isUsersLoading: boolean = false;
  isTouLoading: boolean = false;
  isRolesLoading: boolean = false;
  isInvoicesLoading: boolean = false;
  isAlertLoading: boolean = false;
  AccountError: string = "";
  OrgsError: string = "";
  UsersError: string = "";
  RolesError: string = "";
  InvoicesError: string = "";
  AlertError: string = "";
  ReferralId: string = "";

  constructor() {
    makeObservable(this, {
      ChargeableItems: observable,
      AccountUUID: observable,
      AccountName: observable,
      AccountType: observable,
      FullName: observable,
      Company: observable,
      Phone: observable,
      Email: observable,
      OrgNames: observable,
      Users: observable,
      PaymentMethods: observable,
      Roles: observable,
      isTouAccepted: observable,
      isLoading: observable,
      isAccountInfoLoading: observable,
      isAccountAddressLoading: observable,
      isPaymentMethodsLoading: observable,
      isOrgsLoading: observable,
      isUsersLoading: observable,
      isTouLoading: observable,
      isRolesLoading: observable,
      isInvoicesLoading: observable,
      isAlertLoading: observable,
      AccountError: observable,
      OrgsError: observable,
      UsersError: observable,
      RolesError: observable,
      InvoicesError: observable,
      AlertError: observable,
      ReferralId: observable,
      setAccount: flow,
      fetchAccountInfo: flow,
      fetchAccountAddress: flow,
      fetchTou: flow,
      fetchOrgs: flow,
      fetchUsers: flow,
      fetchPaymentMethods: flow,
      fetchInvoices: flow,
      fetchAlert: flow,
      fetchChargableItems: flow,
      clearAccount: action,
      reset: action,
      hasAccount: computed,
      hasOrgOrUser: computed,
      canViewAlert: computed,
      canViewInvoices: computed,
      canViewPaymentMethods: computed,
      canViewOrgs: computed,
      canViewUsers: computed,
      canCreateOrg: computed,
      ChargeableItemsFeatures: computed,
    });
  }

  setReferralId(value: string) {
    this.ReferralId = value;
  }

  *setAccount(uuid: string) {
    this.AccountUUID = uuid;
    this.isLoading = true;
    this.AccountError = "";
    this.OrgsError = "";
    this.UsersError = "";
    this.RolesError = "";
    this.InvoicesError = "";
    this.AlertError = "";
    // Fetch account
    try {
      this.isAccountInfoLoading = true;
      // TODO fix the typing for below cases
      // @ts-ignore yield
      const { data: accountRes }: any = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}` });
      this.AccountName = accountRes.accountName;
      this.AccountType = accountRes.type;
      this.AccountIsDisabled = accountRes.enabled === false;
      this.FullName = accountRes.fullName;
      this.BillingStart = accountRes.billingStart || "";
      this.TrialStart = accountRes.trialStart || "";
      this.TrialEnd = accountRes.trialEnd || "";
      this.Company = accountRes.extraInfo?.company || "";
      this.Phone = accountRes.phone;
      this.Email = accountRes.email;
      this.Address = accountRes.address || {};
      this.PaymentMethods = accountRes.paymentMethods;
      this.isAccountInfoLoading = false;
    } catch (e) {
      const errorMessage = getBillingErrorMessage(e);
      this.isAccountInfoLoading = false;
      console.error("account error", errorMessage);
      this.AccountError = errorMessage;
    }
    // Fetch Orgs
    try {
      this.isOrgsLoading = true;
      // @ts-ignore yield
      const { data: orgsRes } = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}/org` });
      this.OrgNames = orgsRes.items.map((i: any) => i.name);
      this.isOrgsLoading = false;
    } catch (e) {
      const errorMessage = getBillingErrorMessage(e);
      this.isOrgsLoading = false;
      console.error("orgs error", errorMessage);
      this.OrgsError = errorMessage;
    }
    // Fetch Users
    try {
      this.isUsersLoading = true;
      // @ts-ignore yield
      const { data: usersRes } = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}/user` });
      this.Users = usersRes.items;
      this.isUsersLoading = false;
    } catch (e) {
      const errorMessage = getBillingErrorMessage(e);
      this.isUsersLoading = false;
      console.error("users error", errorMessage);
      this.UsersError = errorMessage;
    }
    // Fetch Self Roles
    try {
      this.isRolesLoading = true;
      // @ts-ignore yield
      const { data: rolesRes } = yield request({
        service: "billing-ng",
        url: `/account/${this.AccountUUID}/user/self`,
      });
      this.Roles = rolesRes.roles || ["billing_viewer"];
      this.isRolesLoading = false;
    } catch (e) {
      const errorMessage = getBillingErrorMessage(e);
      this.isRolesLoading = false;
      console.error("roles error", errorMessage);
      this.RolesError = errorMessage;
    }
    // Fetch Tou State
    try {
      this.isTouLoading = true;
      yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}/tou` });
      this.isTouAccepted = true;
      this.isTouLoading = false;
    } catch (e) {
      this.isTouLoading = false;
      this.isTouAccepted = false;
    }

    this.Invoices = [];
    this.Alert = new BillingAlertMobx();

    this.isLoading = false;
    window.localStorage.setItem(`${UIData.tabId}-account`, uuid);
    window.localStorage.setItem(`account`, uuid);
  }

  *fetchChargableItems() {
    try {
      const { data } = yield request({ service: "billing-ng", method: "get", url: `/chargeable_items` });
      this.ChargeableItems = data;
    } catch (e) {
      console.error("Failed to fetch billing information, try again", e.message, e.response?.data?.message);
    }
  }

  *fetchAccountInfo() {
    try {
      this.isAccountInfoLoading = true;
      this.AccountError = "";
      // @ts-ignore yield
      const { data: accountRes } = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}` });
      this.AccountName = accountRes.accountName;
      this.FullName = accountRes.fullName;
      this.AccountType = accountRes.type;
      this.BillingStart = accountRes.billingStart || "";
      this.TrialStart = accountRes.trialStart || "";
      this.TrialEnd = accountRes.trialEnd || "";
      this.Company = accountRes.extraInfo?.company || "";
      this.Phone = accountRes.phone;
      this.Email = accountRes.email;
      this.isAccountInfoLoading = false;
    } catch (e) {
      this.isAccountInfoLoading = false;
      this.AccountError = getBillingErrorMessage(e);
    }
  }

  *fetchAccountAddress() {
    try {
      this.isAccountAddressLoading = true;
      this.AccountError = "";
      // @ts-ignore yield
      const { data: accountRes } = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}` });
      this.Address = accountRes.address || {};
      this.isAccountAddressLoading = false;
    } catch (e) {
      this.isAccountAddressLoading = false;
      this.AccountError = getBillingErrorMessage(e);
    }
  }

  *fetchTou() {
    try {
      this.isTouLoading = true;
      yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}/tou` });
      this.isTouAccepted = true;
      this.isTouLoading = false;
    } catch (e) {
      this.isTouLoading = false;
      this.isTouAccepted = false;
    }
  }

  *fetchOrgs() {
    try {
      this.isOrgsLoading = true;
      this.OrgsError = "";
      // @ts-ignore yield
      const { data: orgsRes } = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}/org` });
      this.OrgNames = orgsRes.items.map((i: any) => i.name);
      this.isOrgsLoading = false;
    } catch (e) {
      this.isOrgsLoading = false;
      this.OrgsError = getBillingErrorMessage(e);
    }
  }

  *fetchUsers() {
    try {
      this.isUsersLoading = true;
      this.UsersError = "";
      // @ts-ignore yield
      const { data: usersRes } = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}/user` });
      this.Users = usersRes.items;
      this.isUsersLoading = false;
    } catch (e) {
      this.isUsersLoading = false;
      this.UsersError = getBillingErrorMessage(e);
    }
  }

  *fetchPaymentMethods() {
    try {
      this.isPaymentMethodsLoading = true;
      this.AccountError = "";
      // @ts-ignore yield
      const { data: accountRes } = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}` });
      this.PaymentMethods = accountRes.paymentMethods;
      this.isPaymentMethodsLoading = false;
    } catch (e) {
      this.isPaymentMethodsLoading = false;
      this.AccountError = getBillingErrorMessage(e);
    }
  }

  *fetchInvoices(startMoment: moment.Moment, endMoment: moment.Moment) {
    try {
      this.isInvoicesLoading = true;
      this.Invoices = [];
      this.InvoicesError = "";

      // @ts-ignore yield
      const { data } = yield request({
        service: "billing-ng",
        url: `/account/${
          this.AccountUUID
        }/charges/invoices?start=${startMoment?.toISOString()}&end=${endMoment?.toISOString()}`,
      });
      this.Invoices = sortBy(data.invoices, (i) => i.startTime).reverse();
      this.isInvoicesLoading = false;
    } catch (e) {
      this.InvoicesError = getBillingErrorMessage(e);
      this.isInvoicesLoading = false;
    }
  }

  *fetchAlert() {
    try {
      this.isAlertLoading = true;
      // @ts-ignore yield
      const { data: alertRes } = yield request({ service: "billing-ng", url: `/account/${this.AccountUUID}/alert` });
      // TODO make this support float
      this.Alert.setAmount(parseInt(alertRes.amount));
      this.Alert.setEnabled(!!alertRes.enabled);
      this.AlertError = "";
      this.isAlertLoading = false;
    } catch (e) {
      this.Alert = new BillingAlertMobx();
      this.AlertError = getBillingErrorMessage(e);
      this.isAlertLoading = false;
    }
  }

  clearAccount() {
    window.localStorage.removeItem(`${UIData.tabId}-account`);
    window.localStorage.removeItem(`account`);
    this.reset();
  }

  reset() {
    this.AccountUUID = "";
    this.AccountName = "";
    this.AccountType = "";
    this.BillingStart = "";
    this.TrialStart = "";
    this.TrialEnd = "";
    this.FullName = "";
    this.OrgNames = [];
    this.Users = [];
    this.PaymentMethods = [];
    this.Roles = [];
    this.AccountError = "";
    this.OrgsError = "";
    this.UsersError = "";
    this.RolesError = "";
    this.isLoading = false;
  }

  get isTrialAccount() {
    return this.AccountType === "trial";
  }
  get hasAccount() {
    return !this.isLoading && !!this.AccountUUID;
  }
  get hasOrgOrUser() {
    return this.OrgNames.length > 0 || this.Users.length > 0;
  }
  get canViewAlert() {
    if (this.RolesError) {
      return false;
    }
    return this.hasOrgOrUser && this.Roles.includes("billing_admin");
  }
  get canViewInvoices() {
    if (this.RolesError) {
      return false;
    }
    return this.hasOrgOrUser && (this.Roles.includes("billing_admin") || this.Roles.includes("billing_viewer"));
  }
  get canViewPaymentMethods() {
    if (this.RolesError) {
      return false;
    }
    if (this.PaymentMethods.length < 1) {
      return false;
    }
    return true;
  }
  get canViewOrgs() {
    if (this.RolesError) {
      return false;
    }
    if (this.OrgNames.length < 1 && this.PaymentMethods.length < 1) {
      return false;
    }
    return this.hasOrgOrUser;
  }
  get canViewUsers() {
    if (this.RolesError) {
      return false;
    }
    return this.Roles.includes("billing_admin") || this.Roles.includes("billing_viewer");
  }
  get canViewUsage() {
    if (this.RolesError) {
      return false;
    }
    return this.Roles.includes("billing_admin") || this.Roles.includes("billing_viewer");
  }
  get canCreateOrg() {
    if (this.RolesError) {
      return false;
    }
    return (
      (this.Roles.includes("billing_admin") || this.Roles.includes("org_creator")) && this.PaymentMethods.length > 0
    );
  }
  get ChargeableItemsFeatures(): string[] {
    const features: string[] = [];
    for (const chargeableItem of this.ChargeableItems) {
      if (!chargeableItem.consumptionTags) {
        continue;
      }
      if (!chargeableItem.consumptionTags.feature) {
        continue;
      }
      const feature = chargeableItem.consumptionTags.feature;
      if (features.includes(feature)) {
        continue;
      }
      features.push(feature);
    }
    return features;
  }
}

export const BillingContext = new BillingContextMobx();
