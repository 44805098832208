import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderHetznerDedicatedServerNodePool } from "./dedicatedNodePool";
import { Mk8sCreateProviderHetznerDedicatedServerNodePoolAdd } from "./dedicatedNodePoolAdd";
import { Mk8sCreateProviderHetznerDedicatedServerNodePools } from "./dedicatedNodePools";
import { Mk8sCreateProviderHetzner } from "./hetzner";
import { Mk8sCreateProviderHetznerAdvanced } from "./advanced";
import { Mk8sCreateProviderHetznerNodePool } from "./nodePool";
import { Mk8sCreateProviderHetznerNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderHetznerNodePools } from "./nodePools";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderHetznerRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderHetzner mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderHetznerNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderHetznerNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderHetznerNodePool mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-dedicated-server-node-pools`}
        element={<Mk8sCreateProviderHetznerDedicatedServerNodePools mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-dedicated-server-node-pools/-add`}
        element={<Mk8sCreateProviderHetznerDedicatedServerNodePoolAdd mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-dedicated-server-node-pools/:index`}
        element={<Mk8sCreateProviderHetznerDedicatedServerNodePool mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-advanced`} element={<Mk8sCreateProviderHetznerAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderHetznerRoute = observer(Mk8sCreateProviderHetznerRouteRaw);
