import { types, Instance } from "mobx-state-tree";

export const BooleanModel = types
  .model({
    initialValue: types.optional(types.boolean, false),
    value: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isDirty() {
      return self.initialValue !== self.value;
    },
  }))
  .actions((self) => ({
    reset() {
      self.value = self.initialValue;
    },
    confirm() {
      self.initialValue = self.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setValue(value: boolean) {
      self.value = value;
    },
    setInitialValue(value: boolean) {
      self.initialValue = value;
      self.reset();
    },
  }));

export interface BooleanMobx extends Instance<typeof BooleanModel> {}
