import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { request } from "../../../services/cpln";
import { addressCountries } from "../addressData/countries";
import { usaStates } from "../addressData/usaStates";
import { FormElement } from "../../../components/forms/formElement";
import { FormButtons } from "../../../components/forms/formButtons";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { flowResult } from "mobx";
import { User } from "../../../mobxStores/user/user";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";

const AddressRaw: React.FC = () => {
  const { AccountUUID, AccountName, FullName, Address, Phone, Roles, Company } = BillingContext;
  const formDataRef = React.useRef(new NGFormData());
  const countryRef = React.useRef(
    StringModel.create({ label: "Country", isRequired: true, initialValue: Address.country })
  );
  const stateRef = React.useRef(StringModel.create({ label: "State", initialValue: Address.state }));
  const cityRef = React.useRef(StringModel.create({ label: "City", isRequired: true, initialValue: Address.city }));
  const postalCodeRef = React.useRef(
    StringModel.create({ label: "Zip Code", isRequired: true, initialValue: Address.postalCode })
  );
  const line1Ref = React.useRef(
    StringModel.create({ label: "Line 1", isRequired: true, initialValue: Address.address1 })
  );
  const line2Ref = React.useRef(StringModel.create({ label: "Line 2", initialValue: Address.address2 }));

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (countryRef.current.isDirty) {
      res = true;
    }
    if (stateRef.current.isDirty) {
      res = true;
    }
    if (cityRef.current.isDirty) {
      res = true;
    }
    if (postalCodeRef.current.isDirty) {
      res = true;
    }
    if (line1Ref.current.isDirty) {
      res = true;
    }
    if (line2Ref.current.isDirty) {
      res = true;
    }
    setIsDirty(res);
  }, [
    countryRef.current.isDirty,
    stateRef.current.isDirty,
    cityRef.current.isDirty,
    postalCodeRef.current.isDirty,
    line1Ref.current.isDirty,
    line2Ref.current.isDirty,
  ]);

  React.useEffect(() => {
    let res = true;
    if (!countryRef.current.isValid) {
      res = false;
    }
    if (!cityRef.current.isValid) {
      res = false;
    }
    if (!postalCodeRef.current.isValid) {
      res = false;
    }
    if (!line1Ref.current.isValid) {
      res = false;
    }
    setIsValid(res);
  }, [
    countryRef.current.isValid,
    stateRef.current.isValid,
    cityRef.current.isValid,
    postalCodeRef.current.isValid,
    line1Ref.current.isValid,
    line2Ref.current.isValid,
  ]);

  function reset() {
    countryRef.current.reset();
    stateRef.current.reset();
    cityRef.current.reset();
    postalCodeRef.current.reset();
    line1Ref.current.reset();
    line2Ref.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const accountBody = {
        email: User.email,
        accountName: AccountName,
        fullName: FullName,
        phone: Phone,
        extraInfo: {
          company: Company,
        },
        address: {
          country: countryRef.current.value,
          state: stateRef.current.value,
          city: cityRef.current.value,
          postalCode: postalCodeRef.current.value,
          address1: line1Ref.current.value,
          address2: line2Ref.current.value,
        },
      };

      // @ts-ignore
      delete accountBody.id;
      // @ts-ignore
      delete accountBody.kind;
      // @ts-ignore
      delete accountBody.links;

      await request({
        method: "put",
        service: "billing-ng",
        url: `/account/${AccountUUID}`,
        body: {
          account: accountBody,
        },
      });
      await flowResult(BillingContext.fetchAccountAddress());
      countryRef.current.confirm();
      stateRef.current.confirm();
      cityRef.current.confirm();
      postalCodeRef.current.confirm();
      line1Ref.current.confirm();
      line2Ref.current.confirm();
      notification.success({
        message: "Success",
        description: "Updated Account",
      });
    } catch (e) {
      console.error("Account Info", e);
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const isAdmin = Roles.includes("billing_admin");

  return (
    <>
      <NGFormContext.Provider value={formDataRef.current}>
        <div key={AccountName} className={`mb-4`}>
          <div>
            {isAdmin ? (
              <NGFormElement
                label={"Country"}
                name="country"
                as={"autocomplete"}
                required
                options={addressCountries.map((v) => ({ label: v, value: v }))}
                value={countryRef.current.value}
                onChange={(value) => countryRef.current.setValue(value)}
              />
            ) : (
              <FormElement label={"Country"} value={Address.country} />
            )}
            {isAdmin ? (
              countryRef.current.value === "United States" ? (
                <NGFormElement
                  label={"State"}
                  name={"stateone"}
                  as={"autocomplete"}
                  required
                  options={usaStates.map((v) => ({ label: v, value: v }))}
                  value={stateRef.current.value}
                  onChange={(value) => stateRef.current.setValue(value)}
                />
              ) : (
                <NGFormElement
                  label={"State"}
                  name={"statetwo"}
                  value={stateRef.current.value}
                  onChange={stateRef.current.setValue}
                />
              )
            ) : Address.state ? (
              <FormElement label={"State"} value={Address.state} />
            ) : null}
            {isAdmin ? (
              <NGFormElement
                name={"line1"}
                label={"Address Line 1"}
                required
                value={line1Ref.current.value}
                onChange={line1Ref.current.setValue}
                className="mb-4"
              />
            ) : (
              <FormElement label={"Address Line 1"} value={Address.address1} />
            )}
            {isAdmin ? (
              <NGFormElement
                name={"line2"}
                label={"Address Line 2"}
                value={line2Ref.current.value}
                onChange={line2Ref.current.setValue}
                className="mb-4"
              />
            ) : Address.address2 ? (
              <FormElement label={"Address Line 2"} value={Address.address2} />
            ) : null}
            {isAdmin ? (
              <NGFormElement
                name={"city"}
                label={"City"}
                required
                value={cityRef.current.value}
                onChange={cityRef.current.setValue}
                className="mb-4"
              />
            ) : (
              <FormElement label={"City"} value={Address.city} />
            )}
            {isAdmin ? (
              <NGFormElement
                name={"zipCode"}
                label={"Zip Code"}
                required
                value={postalCodeRef.current.value}
                onChange={postalCodeRef.current.setValue}
                className="mb-4"
              />
            ) : (
              <FormElement label={"Zip Code"} value={Address.postalCode} />
            )}
            {isAdmin ? (
              <FormButtons
                onReset={reset}
                onSave={save}
                loading={isLoading}
                saveDisabled={isLoading || !isDirty || !isValid}
                resetDisabled={isLoading || !isDirty}
              />
            ) : null}
          </div>
        </div>
      </NGFormContext.Provider>
    </>
  );
};

export const Address = observer(AddressRaw);
