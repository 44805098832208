import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route, Navigate } from "react-router-dom";
import { ListAndInvite } from "./listAndInvite";
import { UserDetailRoute } from "./detailRoute";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const UserRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<Navigate to={`-index`} />} />
        <Route path={`-index/*`} element={<ListAndInvite />} />
        <Route path={`:idOrEmail/*`} element={<UserDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(UserRoute);
