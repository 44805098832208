import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { DownloadOutlined } from "@ant-design/icons";
import { Copy } from "react-feather";
import { NGButton } from "../../../newcomponents/button/Button";
import { Agent } from "../../../schema/types/agent";

interface Props {
  agent: Agent;
  onDone: any;
  visible: boolean;
}

const BootstrapConfigModalRaw: React.FC<Props> = ({ agent, onDone, visible }) => {
  const [tookAction, setTookAction] = React.useState(false);

  async function copyToClipboard() {
    navigator.clipboard.writeText(JSON.stringify(agent.status?.bootstrapConfig));
    notification.success({
      message: "Copied to clipboard",
    });
    setTookAction(true);
  }

  function getHrefOfKey() {
    const file = new Blob([JSON.stringify(agent.status?.bootstrapConfig)], { type: "text/json" });
    return URL.createObjectURL(file);
  }

  function localOnDone() {
    const link = agent.links.find((l: any) => l.rel === "self")!.href;
    onDone(link);
  }

  return (
    <Modal
      title={"Agent Bootstrap Config"}
      open={visible}
      closable={false}
      destroyOnClose
      footer={
        <div className="modal-actions">
          <NGButton
            variant="primary"
            renderIcon={(hover, props) => <DownloadOutlined size={20} {...props} />}
            onClick={() => setTookAction(true)}
          >
            <div className="flex items center">
              <a className="ml-2" href={getHrefOfKey()} download={`${agent.name}-bootstrapConfig.json`}>
                Download Config File
              </a>
            </div>
          </NGButton>
          <NGButton variant="primary" onClick={localOnDone} disabled={!tookAction}>
            Done
          </NGButton>
        </div>
      }
    >
      <div>
        <div>Agent bootstrap config created.</div>
        <div>Copy or download the contents, you will not have access to it after you close this screen</div>
        <div className="flex items-center mt-2">
          <input
            className="flex-grow border rounded px-4 py-2"
            disabled={true}
            value={JSON.stringify(agent.status?.bootstrapConfig)}
          />
          <button onClick={copyToClipboard} className={"ml-2 color-link"}>
            <Copy data-testid="copy-agent" className={`feather-icon`} />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const BootstrapConfigModal = observer(BootstrapConfigModalRaw);
