import * as React from "react";
import Papa, { ParseResult } from "papaparse";
import NGAlert from "../../newcomponents/alert";
import { Modal, notification } from "antd";
import { StringModel } from "../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { cancelSentTask, checkTaskStatus, fetchSentPendingTasks, inviteUsers } from "../../services/task";
import { inputValidations } from "../../mobxDataModels/validations";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { NGButton } from "../../newcomponents/button/Button";
import { NGLabel } from "../../newcomponents/text/label";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInput } from "../../newcomponents/input/input";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { NGForm } from "../../newcomponents/form/ngform";
import { NGError } from "../../newcomponents/text/error";
import { Tooltip } from "../../components/Tooltip";
import { Table } from "../../newcomponents/table/table";
import { Task } from "../../schema/types/task";
import { Group } from "../../schema/types/group";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { fetchPages, request } from "../../services/cpln";

interface UserInvitation {
  email: string;
  group: string;
}

const InviteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { org } = ConsoleContext;

  // States //
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);
  const [invites, setInvites] = React.useState<Task[]>([]);
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [groupName, setGroupName] = React.useState(`viewers`);
  const [invitations, setInvitations] = React.useState<UserInvitation[]>([]);
  const [selectedTasks, setSelectedTasks] = React.useState<Task[]>([]);
  const [selectionsToRemove, setSelectionsToRemove] = React.useState<string[]>([]);
  const [alerts, setAlerts] = React.useState<React.ReactNode[]>([]);
  const [isDragging, setIsDragging] = React.useState(false);

  // Refs //
  const emailRef = React.useRef(
    StringModel.create({
      label: "Email",
      transformKey: "lowerCase",
      validationKey: "email",
    }),
  );

  // Effects //
  React.useEffect(() => {
    updateInvites();
    fetchGroups();
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(invitations.length > 0 || isLoading);
  }, [invitations.length, isLoading]);

  useCleanPrompt();

  // Functions //
  async function updateInvites() {
    const tasks = await fetchSentPendingTasks();
    setInvites(tasks);
  }

  async function fetchGroups(): Promise<void> {
    try {
      const { data } = await request({ method: "get", url: `/org/${org}/group` });
      await fetchPages(data);
      setGroups(data.items);
    } catch (e) {
      console.error(e);
    }
  }

  function addEmail() {
    if (invitations.some((i) => i.email === emailRef.current.value)) return;
    const _invitations = [...invitations];
    const { absolute: groupLink } = ngParseLink(groupName, { kind: "group" });
    var newInvitation = { email: emailRef.current.value, group: groupLink };
    _invitations.push(newInvitation);
    setInvitations(_invitations);
    emailRef.current.reset();
  }

  function removeEmail(email: string) {
    setInvitations((inv) => inv.filter((i) => i.email !== email));
  }

  function clearInvitations(): void {
    setAlerts([]);
    setInvitations([]);
  }

  async function onCSVChange(e: React.ChangeEvent<HTMLInputElement>) {
    // Clear previous alerts
    setAlerts([]);

    // Handle invalid input early
    if (!e.target.value || !e.target.files || e.target.files.length < 1) {
      e.target.value = "";
      notification.warning({
        message: "Failed",
        description: "No file selected or the input is invalid.",
      });
      return;
    }

    handleCSV(e.target.files[0]);
    e.target.value = "";
  }

  function handleCSV(file: File): void {
    // Check if we can work with the selected file
    if (!file) {
      notification.warning({
        message: "Failed",
        description: "Selected file is invalid.",
      });
      return;
    }

    // Define data sets
    const rawInvitations: UserInvitation[] = [];
    const invalidEmails = new Set<string>();
    const duplicateEmails = new Set<string>();
    const invalidGroups = new Set<string>();
    const invalidRows = new Set<string>();

    // Collect existing emails so we can avoid adding duplicate emails
    const existingEmails = new Set(invitations.map((i: UserInvitation): string => i.email.toLowerCase()));

    // Parse CSV file
    Papa.parse<string[]>(file, {
      delimiter: "", // Auto detect delimiter
      skipEmptyLines: true,
      complete: (results: ParseResult<string[]>): void => {
        // Ignore empty CSV
        if (results.data.length == 0) {
          notification.error({
            message: "Empty CSV File",
            description: "The selected CSV file contains no data. Please upload a non-empty CSV file.",
          });
          return;
        }

        // Assume that the first row represents the header
        const header: string[] = results.data[0];

        // Declare column positions
        let emailColumnIndex: number = -1;
        let groupColumnIndex: number = -1;

        // Attempt to identify headers
        header.forEach((field: string, index: number): void => {
          // Find "email" column
          if (field.toLowerCase() == "email" && emailColumnIndex == -1) {
            emailColumnIndex = index;
            return;
          }

          // Find "group" column
          if (field.toLowerCase() == "group" && groupColumnIndex == -1) {
            groupColumnIndex = index;
            return;
          }

          // In case there are more than one column with the label "email" / "group", we simply consider the very first one found.
        });

        // Handle data
        if (emailColumnIndex !== -1) {
          // Ignore header row and get pure rows
          const rows: string[][] = results.data.slice(1);

          // Iterate over all rows
          rows.forEach((row: string[], index: number): void => {
            const email: string | undefined = getValueAt(row, emailColumnIndex);
            let group: string = groupName;

            // Simply ignore row if the column is not found and log it later for the user
            if (!email) {
              invalidRows.add(`[Row ${index + 1}]: ${row.join(results.meta.delimiter)}`);
              return;
            }

            // Validate email
            if (!validateEmail(email)) {
              invalidEmails.add(email);
              return;
            }

            // Convert email to lowercase to ensure case-insensitive comparison
            const emailLowerCase: string = email.toLowerCase();

            // Check for duplicates
            if (existingEmails.has(emailLowerCase) || duplicateEmails.has(emailLowerCase)) {
              duplicateEmails.add(emailLowerCase);
              return;
            }

            // Find group if specified
            if (groupColumnIndex !== -1) {
              const specifiedGroup: string | undefined = getValueAt(row, groupColumnIndex);

              // Only handle a defined specified group
              if (specifiedGroup) {
                // Define specified group name so we can compare with available group names
                let specifiedGroupName: string = specifiedGroup;
                let isSpecifiedGroupValid: boolean = false;

                // Get specified group name
                if (specifiedGroupName.startsWith(`/org/${org}/group/`)) {
                  specifiedGroupName = specifiedGroupName.split("/").pop() || "";
                }

                // Attempt to find the specified group name and use it
                for (const existingGroup of groups) {
                  if (existingGroup.name === specifiedGroupName) {
                    group = existingGroup.name;
                    isSpecifiedGroupValid = true;
                    break;
                  }
                }

                // Handle when specified group is not found
                if (!isSpecifiedGroupValid) {
                  invalidGroups.add(specifiedGroup);
                }
              }
            }

            // Push valid invitation
            rawInvitations.push({ email, group: `/org/${org}/group/${group}` });
            existingEmails.add(emailLowerCase);
          });
        } else {
          // Handle when email header is not found, we are basically doing a favor for those who didn't specify headers
          results.data.forEach((row: string[], index: number): void => {
            let isRowValid: boolean = false;

            // Collect all valid emails in a specific row
            for (const column of row) {
              const possibleEmail: string = column;

              // Attempt to parse into an email, and ignore if not possible
              if (!validateEmail(possibleEmail)) {
                continue;
              }

              // Convert email to lower case
              isRowValid = true;
              const emailLowerCase: string = possibleEmail.toLowerCase();

              // Check for duplicates
              if (existingEmails.has(emailLowerCase) || duplicateEmails.has(emailLowerCase)) {
                duplicateEmails.add(emailLowerCase);
                continue;
              }

              // Push valid email with selected group as a new invitation
              rawInvitations.push({ email: possibleEmail, group: `/org/${org}/group/${groupName}` });
              existingEmails.add(emailLowerCase);
            }

            if (!isRowValid) {
              invalidRows.add(`[Row ${index + 1}]: ${row.join(results.meta.delimiter)}`);
            }
          });
        }

        // Update invitations list
        setInvitations([...invitations, ...rawInvitations]);

        // Prepare alerts
        const newAlerts: React.ReactElement[] = [];

        if (invalidEmails.size > 0) {
          newAlerts.push(
            <NGAlert
              key="invalidEmails"
              type="error"
              title="Invalid Emails"
              message={`The following emails are invalid and were ignored as a result:`}
              style={{ maxWidth: "100%", width: "100%" }}
              closable={true}
              render={() => (
                <ul className="flex flex-col gap-2 list-disc list-inside">
                  {Array.from(invalidEmails).map((emailKey, index) => (
                    <li key={emailKey + String(index)}>{emailKey}</li>
                  ))}
                </ul>
              )}
            />,
          );
        }

        if (invalidGroups.size > 0) {
          newAlerts.push(
            <NGAlert
              key="invalidGroups"
              type="error"
              title="Invalid Groups"
              message={`The following groups were ignored because they are invalid:`}
              style={{ maxWidth: "100%", width: "100%" }}
              closable={true}
              render={() => (
                <ul className="flex flex-col gap-2 list-disc list-inside">
                  {Array.from(invalidGroups).map((emailKey, index) => (
                    <li key={emailKey + String(index)}>{emailKey}</li>
                  ))}
                </ul>
              )}
            />,
          );
        }

        if (invalidRows.size > 0) {
          newAlerts.push(
            <NGAlert
              key="invalidRows"
              type="error"
              title="Invalid Rows"
              message={`The following rows were ignored because they contain invalid emails or the delimiter is unsupported:`}
              style={{ maxWidth: "100%", width: "100%" }}
              closable={true}
              render={() => (
                <ul className="flex flex-col gap-2 list-disc list-inside">
                  {Array.from(invalidRows).map((emailKey, index) => (
                    <li key={emailKey + String(index)}>{emailKey}</li>
                  ))}
                </ul>
              )}
            />,
          );
        }

        if (duplicateEmails.size > 0) {
          newAlerts.push(
            <NGAlert
              key="duplicateEmails"
              title="Duplicate Emails"
              message={`The following email addresses were ignored because they are already in the list:`}
              style={{ maxWidth: "100%", width: "100%" }}
              closable={true}
              render={() => (
                <ul className="flex flex-col gap-2 list-disc list-inside">
                  {Array.from(duplicateEmails).map((emailKey, index) => (
                    <li key={emailKey + String(index)}>{emailKey}</li>
                  ))}
                </ul>
              )}
            />,
          );
        }

        if (
          rawInvitations.length === 0 &&
          invalidEmails.size === 0 &&
          invalidGroups.size === 0 &&
          invalidRows.size === 0 &&
          duplicateEmails.size === 0
        ) {
          newAlerts.push(
            <NGAlert
              key="noInvitations"
              title="No Valid Invitations Found"
              message="The uploaded CSV file is empty or does not contain any valid email addresses for invitations."
              style={{ maxWidth: "100%", width: "100%" }}
              closable={true}
            />,
          );
        }

        setAlerts(newAlerts);
      },
      error: (error: Error) => {
        notification.error({
          message: "CSV Parsing Error",
          description: `An error occurred while processing the CSV file: ${error.message}.`,
        });
      },
    });
  }

  async function invite() {
    setAlerts([]);

    try {
      setIsLoading(true);

      const succeededEmails: string[] = [];
      const failedEmails: { email: string; message: string }[] = [];

      for (let invitation of invitations) {
        let inviteRes;
        try {
          inviteRes = await inviteUsers([invitation.email], invitation.group);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          failedEmails.push({ email: invitation.email, message: errorMessage });
          continue;
        }
        for (let entry of Object.entries(inviteRes.invitations)) {
          const email: string = entry[0];
          succeededEmails.push(email);
        }

        for (let entry of Object.entries(inviteRes.errors)) {
          const email: string = entry[0];
          const messageHolder: any = entry[1];
          failedEmails.push({ email, message: messageHolder.message.replace(/user/i, email) });
        }
      }

      if (succeededEmails.length > 0) {
        notification.success({
          duration: null,
          message: "Success",
          description: (
            <>
              <ul>
                {succeededEmails.map((email, index) => (
                  <li key={email + String(index)} className="block my-2">
                    {email} invited.
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }
      if (failedEmails.length > 0) {
        notification.warning({
          duration: null,
          message: "Failure",
          description: (
            <>
              <span>Failed Invitations:</span>
              <ul>
                {failedEmails.map(({ email, message }, index) => (
                  <li key={email + String(index)} className="my-2">
                    <span className="block text-sm">{email}</span>
                    <span className="block text-xs">
                      {message}
                      {message.endsWith(".") ? "" : "."}
                    </span>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }

      await updateInvites();

      setInvitations([]);
      emailRef.current.reset();
      setGroupName("viewers");
      setIsLoading(false);
    } catch (e) {
      console.error("Org InviteUser", e);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function onDeleteTask() {
    if (selectionsToRemove.length < 1) {
      return;
    }

    setSelectedTasks(invites.filter((task) => selectionsToRemove.includes(task.id)));
    setSelectionsToRemove([]);
  }

  function cancelTaskDeletion(): void {
    setSelectedTasks([]);
    setIsLoadingDelete(false);
  }

  async function confirmTaskDeletion(): Promise<void> {
    const promises: Promise<string | undefined>[] = [];
    const failedCancellations: string[] = [];

    setAlerts([]);
    setIsLoadingDelete(true);

    // Prepare for cancelling invites
    for (const task of selectedTasks) {
      promises.push(cancelInvitation(task));
    }

    // Cancel invitations in parallel
    const responses: (string | undefined)[] = await Promise.all(promises);

    // Iterate over responses and capture failures
    for (const response of responses) {
      if (!response) {
        continue;
      }

      failedCancellations.push(response);
    }

    if (failedCancellations.length === 0) {
      notification.success({
        message: "Success",
        description: "Selected invite(s) were cancelled.",
      });
    } else {
      setAlerts([
        <NGAlert
          key="invalidInviteCancellations"
          type="error"
          title="Unable to cancel invitation(s)"
          message={`The following invitations were not cancelled for the following errors:`}
          style={{ maxWidth: "100%", width: "100%" }}
          closable={true}
          render={() => (
            <ul className="flex flex-col gap-2 list-disc list-inside">
              {failedCancellations.map((error, index) => (
                <li key={error + String(index)}>{error}</li>
              ))}
            </ul>
          )}
        />,
      ]);
      notification.error({
        message: "Failed",
        description: `Unable to cancel invitation(s)`,
      });
    }

    // Refresh pending invites list
    await updateInvites();

    // Close modal
    setSelectedTasks([]);
    setIsLoadingDelete(false);
  }

  async function cancelInvitation(task: Task): Promise<string | undefined> {
    try {
      const status = await checkTaskStatus(task.id);

      if (status !== "pending") {
        return `${task.targetEmail} - invitation is already ${status === "canceled" ? "cancelled" : "completed"}`;
      }

      // Cancel task
      await cancelSentTask(task.id);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      return `${task.targetEmail} - ${errorMessage}`;
    }

    return undefined;
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    setIsDragging(true);
  }

  function handleDragLeave(event: React.DragEvent<HTMLDivElement>): void {
    setIsDragging(false);
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    setIsDragging(false);

    const files: FileList = event.dataTransfer.files;

    if (files.length == 0) {
      notification.error({ message: "No files were detected in the drop. Please try again." });
    }

    // Handle CSV
    handleCSV(files[0]);
  }

  function getValueAt(array: string[], index: number): string | undefined {
    return index >= 0 && index < array.length ? array[index] : undefined;
  }

  function validateEmail(email: string): boolean {
    // True until proven false
    let isValid: boolean = true;

    for (const validateEmail of inputValidations.email) {
      if (validateEmail("Email invite", email) !== true) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  return (
    <>
      <div className="flex flex-row gap-10">
        <NGFormContext.Provider value={formDataRef.current}>
          <NGForm data={formDataRef.current}>
            <div className="mb-4 text-2xl">Invite Users</div>
            <div>Invite users to this org and associate them with an optional group.</div>
            <div style={{ width: 666 }}>
              <div>Add Email to Invitation List</div>
              <div className="flex mt-2">
                <NGInput
                  name="email"
                  placeholder="Email"
                  className="mr-4"
                  style={{ width: 450 }}
                  value={emailRef.current.value}
                  onChange={(e) => emailRef.current.setValue(e.target.value)}
                />
                <NGKindSelect
                  value={groupName}
                  kind={"group"}
                  onChange={(value) => setGroupName(value)}
                  style={{ width: 200 }}
                />
              </div>
              <div className="mt-2 flex justify-between" style={{ width: 666 }}>
                {formDataRef.current.get("email").touched && !emailRef.current.isValid ? (
                  <NGError>{emailRef.current.error}</NGError>
                ) : invitations.some((i) => i.email === emailRef.current.value) ? (
                  <NGError>Email is already in the invitation list.</NGError>
                ) : (
                  <div />
                )}

                <NGButton
                  disabled={
                    !emailRef.current.value ||
                    !emailRef.current.isValid ||
                    invitations.some((i) => i.email === emailRef.current.value)
                  }
                  onClick={addEmail}
                  style={{ width: 200 }}
                  className="ml-2"
                  variant={"action"}
                >
                  Add to Invitation List
                </NGButton>
              </div>
              <div className="flex items-center justify-between mt-4 mb-2">
                <NGLabel>Invitation List</NGLabel>
                <div className="flex items-center gap-2">
                  <label
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        document.getElementById("csvFile")?.click();
                      }
                    }}
                    className="cursor-pointer ngfocus color-link text-sm"
                  >
                    Upload from CSV{" "}
                    <input
                      id={"csvFile"}
                      accept={".csv"}
                      onChange={onCSVChange}
                      type={"file"}
                      className="invisible"
                      multiple={false}
                      style={{ width: 0 }}
                    />
                  </label>
                  <NGButton
                    variant={"danger"}
                    size="small"
                    outlined={true}
                    disabled={invitations.length === 0}
                    onClick={clearInvitations}
                  >
                    Clear List
                  </NGButton>
                </div>
              </div>
              {invitations.length === 0 ? null : (
                <div
                  className="flex items-center w-full mt-2 px-4 py-1 table-labels"
                  style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                >
                  <div className={"w-6/12"}>
                    <span>Email</span>
                  </div>
                  <div className={"w-3/12"}>
                    <span>Group</span>
                  </div>
                  <div className={"w-3/12"}>
                    <span></span>
                  </div>
                </div>
              )}
              {invitations.length === 0 ? (
                <div
                  className={`relative ${isDragging ? "bg-black" : ""}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div className="border rounded p-8 mb-6">
                    <div className="text-center text-2xl">
                      {isDragging ? "Drop Here" : "Add invite or Drag & Drop CSV File"}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="overflow-y-auto" style={{ maxHeight: 565 }}>
                {invitations.map((invitation, index) => (
                  <div key={invitation.email + String(index)} className="flex items-center w-full border-b h-12 px-4">
                    <div className={"w-6/12"}>
                      <span>{invitation.email}</span>
                    </div>
                    <div className={"w-3/12"}>
                      <Tooltip title={invitation.group || "None"}>
                        <span>{invitation.group !== "none" ? invitation.group.split("/")[4] : "None"}</span>
                      </Tooltip>
                    </div>
                    <div className={"w-3/12 flex items-center justify-end"}>
                      <button className="focus color-danger" onClick={() => removeEmail(invitation.email)}>
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {invitations.length === 0 ? null : (
                <div className="flex items-center justify-end my-6">
                  <NGButton
                    style={{ width: 200 }}
                    disabled={isLoading || invitations.length < 1 || emailRef.current.value.length > 0}
                    loading={isLoading}
                    variant={"primary"}
                    onClick={invite}
                  >
                    Confirm Invitations
                  </NGButton>
                </div>
              )}
            </div>
          </NGForm>
        </NGFormContext.Provider>
        <div className="flex flex-col gap-4 w-full">{alerts}</div>
      </div>
      <Table<Task>
        tableId="task-invites"
        title={"Pending Invites"}
        selectMode={"multi"}
        selectKey={"id"}
        selections={selectionsToRemove}
        onSelectionsChange={setSelectionsToRemove}
        data={invites}
        columns={[
          { id: "description", label: "Message" }, //
          { id: "targetEmail", label: "Email" },
          CreatedColumn(),
        ]}
        headerRenderer={() => (
          <>
            <NGButton
              variant={"danger"}
              size="normal"
              outlined
              disabled={selectionsToRemove.length < 1}
              onClick={onDeleteTask}
            >
              Cancel Invitations
            </NGButton>
          </>
        )}
      />
      {selectedTasks.length > 0 ? (
        <Modal
          title="Are you sure you want to delete the selected invitation(s)?"
          open={true}
          maskClosable={!isLoadingDelete}
          destroyOnClose
          onCancel={cancelTaskDeletion}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={cancelTaskDeletion} disabled={isLoadingDelete}>
                Close
              </NGButton>
              <NGButton
                variant="danger"
                loading={isLoadingDelete}
                disabled={isLoadingDelete}
                onClick={confirmTaskDeletion}
              >
                Delete
              </NGButton>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export const Invite = observer(InviteRaw);
