import { flowResult } from "mobx";
import { ConsoleContext } from "../mobxStores/consoleContext/consoleContext";
import { UserData } from "../mobxStores/userData/userData";
import { homeLink, request, resourceLink } from "./cpln";

type CreateOrgGVCStatus = "none" | "orgCreated" | "gvcCreated";

interface CreateOrgGVCResponse {
  status: CreateOrgGVCStatus;
  message?: string;
}
export async function createOrgGVC(
  accountId: string,
  orgName: string,
  gvcName: string,
  locations: string[]
): Promise<CreateOrgGVCResponse> {
  let status: CreateOrgGVCStatus = "none";
  let message;
  try {
    // Create org
    const requestBody: any = {
      org: {
        name: orgName,
        description: `Default Org`,
      },
      invitees: [],
    };
    await request({
      method: "post",
      service: "billing-ng",
      url: `/account/${accountId}/org`,
      body: requestBody,
    });
    status = "orgCreated";

    await flowResult(UserData.requestOrgNames());
    await flowResult(ConsoleContext.setOrg(orgName));
    await flowResult(UserData.requestGVCNames());

    // Create gvc
    const { data } = await request({ url: homeLink("location") });
    const allLocationLinks = data.items.map((loc: any) => loc.links.find((link: any) => link.rel === "self").href);
    const selectedLocationLinks = locations.map((location) => `/org/${orgName}/location/${location}`);
    const locationLinks = selectedLocationLinks.length > 0 ? selectedLocationLinks : allLocationLinks;
    const body: any = {
      name: gvcName,
      description: gvcName,
      spec: { staticPlacement: { locationLinks: locationLinks } },
    };
    await request({ method: "post", url: homeLink("gvc"), body });
    status = "gvcCreated";

    await flowResult(UserData.requestGVCNames());

    ConsoleContext.setGVC(gvcName);
  } catch (e) {
    let errorMessage = e?.response?.data?.message;
    if (!errorMessage) {
      try {
        errorMessage = JSON.parse(e?.response?.data?.error).message;
      } catch (e) {}
    }
    if (!errorMessage) errorMessage = e.message;
    message = errorMessage;
    console.error("Failed to auto create org and gvc", message);
  }
  return { status, message };
}
