import clsx from "clsx";
import { NGLabelText } from "../text/labelText";
import { DefaultOption, FilterOptionsProps, OptionRendererProps } from "./types";

export function DefaultGetOptionLabel(option: DefaultOption): string {
  return option.label;
}

export function DefaultGetOptionValue(option: DefaultOption): string {
  return option.value;
}

export function DefaultOptionRenderer<T>({
  option,
  props,
  getOptionLabel,
  getOptionValue,
  isSelected,
  isHovered,
  isLabel,
  isDisabled,
}: OptionRendererProps<T>) {
  if (isLabel) {
    return (
      <li key={getOptionValue(option)}>
        <NGLabelText style={{ cursor: "not-allowed" }}>{getOptionLabel(option)}</NGLabelText>
      </li>
    );
  }

  return (
    <li
      key={getOptionValue(option)}
      {...props}
      className={clsx(`flex items-center justify-between`, {
        "option-hover": isHovered,
        "option-selected": isSelected,
        "option-label": isLabel,
        "option-disabled": isDisabled,
        "cursor-not-allowed": isLabel || isDisabled,
      })}
    >
      <span className="truncate">{getOptionLabel(option)}</span>
    </li>
  );
}

export function ComboboxDefaultFilterOptionsHandler({
  value,
  getOptionLabel,
  getOptionValue,
  options,
}: FilterOptionsProps<DefaultOption>) {
  try {
    if (!value) {
      return options;
    }
    let filtered = options.filter(
      (opt) =>
        getOptionValue(opt).toLowerCase().includes(value.toLowerCase()) ||
        getOptionLabel(opt).toLowerCase().includes(value.toLowerCase())
    );
    let result: DefaultOption[] = [];
    // by label, by value, the rest

    let addedIndexes: number[] = [];

    for (const fn of [
      (option: DefaultOption) => option.label.startsWith(value),
      (option: DefaultOption) => option.label.toLowerCase().startsWith(value),
      (option: DefaultOption) => option.value.startsWith(value),
      (option: DefaultOption) => option.value.toLowerCase().startsWith(value),
      (_: DefaultOption) => true,
    ]) {
      for (const indexStr in filtered) {
        const index = Number(indexStr);
        if (addedIndexes.includes(index)) {
          continue;
        }
        const option = filtered[index];
        if (fn(option)) {
          result.push(option);
          addedIndexes.push(index);
        }
      }
    }
    return result;
  } catch (e) {
    console.error("Failed to filter options", e.message, "value", value, "valuetype", typeof value, options);
    return [];
  }
}

export function AutocompleteDefaultFilterOptionsHandler({
  value,
  getOptionLabel,
  getOptionValue,
  options,
}: FilterOptionsProps<DefaultOption>) {
  let foundIndexes: number[] = [];

  const matchingLabel = options.filter((opt, idx) => {
    if (getOptionLabel(opt) === value && !foundIndexes.includes(idx)) {
      foundIndexes.push(idx);
      return true;
    }
    return false;
  });
  const startsWithLabel = options.filter((opt, idx) => {
    if (getOptionLabel(opt).startsWith(value) && !foundIndexes.includes(idx)) {
      foundIndexes.push(idx);
      return true;
    }
    return false;
  });
  const includesLabel = options.filter((opt, idx) => {
    if (getOptionLabel(opt).includes(value) && !foundIndexes.includes(idx)) {
      foundIndexes.push(idx);
      return true;
    }
    return false;
  });
  const includesLabelCaseInsensitive = options.filter((opt, idx) => {
    if (getOptionLabel(opt).toLowerCase().includes(value.toLowerCase()) && !foundIndexes.includes(idx)) {
      foundIndexes.push(idx);
      return true;
    }
    return false;
  });

  const matchingValue = options.filter((opt, idx) => {
    if (getOptionValue(opt) === value && !foundIndexes.includes(idx)) {
      foundIndexes.push(idx);
      return true;
    }
    return false;
  });
  const startsWithValue = options.filter((opt, idx) => {
    if (getOptionValue(opt).startsWith(value) && !foundIndexes.includes(idx)) {
      foundIndexes.push(idx);
      return true;
    }
    return false;
  });
  const includesValue = options.filter((opt, idx) => {
    if (getOptionValue(opt).includes(value) && !foundIndexes.includes(idx)) {
      foundIndexes.push(idx);
      return true;
    }
    return false;
  });
  const includesValueCaseInsensitive = options.filter((opt, idx) => {
    if (getOptionValue(opt).toLowerCase().includes(value.toLowerCase()) && !foundIndexes.includes(idx)) {
      foundIndexes.push(idx);
      return true;
    }
    return false;
  });

  let res: any[] = [
    ...matchingLabel,
    ...startsWithLabel,
    ...includesLabel,
    ...includesLabelCaseInsensitive,
    ...matchingValue,
    ...startsWithValue,
    ...includesValue,
    ...includesValueCaseInsensitive,
  ];

  return res;
}
