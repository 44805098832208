import * as React from "react";

// An enum with all the types of actions to use in our reducer
export enum RunDeleteVolumeSnapshotReducerActionType {
  LOCATION = "LOCATION",
  INDEX = "INDEX",
  NAME = "NAME",
}

// An interface for our actions
interface RunDeleteVolumeSnapshotReducerAction {
  type: RunDeleteVolumeSnapshotReducerActionType;
  payload: any;
}

// An interface for our state
export interface DeleteVolumeSnapshotReducerState {
  location: string;
  volumeIndex: string;
  snapshotName: string;
}

// Our reducer function that uses a switch statement to handle our actions
function runDeleteVolumeSnapshot(
  state: DeleteVolumeSnapshotReducerState,
  action: RunDeleteVolumeSnapshotReducerAction
) {
  const { type, payload } = action;
  switch (type) {
    case RunDeleteVolumeSnapshotReducerActionType.LOCATION:
      return {
        ...state,
        location: payload,
      };
    case RunDeleteVolumeSnapshotReducerActionType.INDEX:
      return {
        ...state,
        volumeIndex: payload,
      };
    case RunDeleteVolumeSnapshotReducerActionType.NAME:
      return {
        ...state,
        snapshotName: payload,
      };
    default:
      return state;
  }
}

export function useRunDeleteVolumeSnapshotReducer(
  initialLocation: string | undefined,
  initialVolumeIndex: string | undefined,
  initialSnapshotName: string | undefined
) {
  const initialState: DeleteVolumeSnapshotReducerState = {
    location: initialLocation || "",
    volumeIndex: initialVolumeIndex || "",
    snapshotName: initialSnapshotName || "",
  };
  return React.useReducer(runDeleteVolumeSnapshot, initialState);
}
