import * as React from "react";
import jsYaml from "js-yaml";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { notification } from "antd";
import { CreateLayout } from "../../components/create/layout";
import { CreateFormLink } from "../org/create/create";
import { NameModel } from "../../mobxDataModels/nameModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { inputValidations } from "../../mobxDataModels/validations";
import { homeLink, request, resourceLink } from "../../services/cpln";
import { NoTag } from "../../components/tag/noTag";
import { RemovableValue } from "../../components/tag/removableValue";
import { FormLabel } from "../../components/forms/formLabel";
import { TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../components/create/tags";
import { Download, Trash } from "react-feather";
import { MobxMultilineInput } from "../../components/inputs/mobxMultilineInput/mobxMultilineInput";
import { CodeEditorModal } from "../../components/modals/codeEditorModal";
import { ReadOnlyInput } from "../../components/inputs/readOnlyInput";
import { GenericInputs } from "./types";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { parseEnvFile } from "../../services/utils";
import { NGButton } from "../../newcomponents/button/Button";
import NGAlert from "../../newcomponents/alert";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { NGRadioGroup } from "../../newcomponents/radioGroup";
import { MobxInput } from "../../components/inputs/mobxInput";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { InfoTooltip } from "../../components/InfoTooltip";

const SecretCreateRaw: React.FC = () => {
  const basePath = useBasePath("/secret/-create/*");
  const formDataRef = React.useRef(new NGFormData());

  // Refs //
  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));
  const typeRef = React.useRef(
    SelectModel.create({
      label: "Secret Type",
      initialValue: "aws",
      options: [
        { label: "AWS", value: "aws" },
        { label: "Azure Connector", value: "azure-connector" },
        { label: "Azure-SDK", value: "azure-sdk" },
        { label: "Dictionary", value: "dictionary" },
        { label: "Docker", value: "docker" },
        { label: "ECR", value: "ecr" },
        { label: "GCP", value: "gcp" },
        { label: "Keypair", value: "keypair" },
        { label: "NATS Account", value: "nats-account" },
        { label: "Opaque", value: "opaque" },
        { label: "TLS", value: "tls" },
        { label: "Username & Password", value: "userpass" },
      ],
    })
  );

  const userpass_usernameRef = React.useRef(StringModel.create({ label: "Username", isRequired: true }));
  const userpass_passwordRef = React.useRef(StringModel.create({ label: "Password", isRequired: true }));

  const azureConnector_urlRef = React.useRef(
    StringModel.create({ label: "URL", isRequired: true, validationKey: "https" })
  );
  const azureConnector_codeRef = React.useRef(StringModel.create({ label: "Code", isRequired: true }));

  const aws_secretKeyRef = React.useRef(
    StringModel.create({ label: "Secret Key", isRequired: true, isSensitive: true })
  );
  const aws_accessKeyRef = React.useRef(
    StringModel.create({ label: "Access Key", validationKey: "awsAccessKey", isRequired: true })
  );
  const aws_roleArnRef = React.useRef(StringModel.create({ label: "Role Arn", validationKey: "awsRoleArn" }));
  const aws_externalIdRef = React.useRef(StringModel.create({ label: "External Id" }));

  const ecr_secretKeyRef = React.useRef(
    StringModel.create({ label: "Secret Key", isRequired: true, isSensitive: true })
  );
  const ecr_accessKeyRef = React.useRef(
    StringModel.create({ label: "Access Key", validationKey: "awsAccessKey", isRequired: true })
  );
  const ecr_roleArnRef = React.useRef(StringModel.create({ label: "Role Arn", validationKey: "awsRoleArn" }));
  const ecr_repoRef = React.useRef(StringModel.create({ label: "Repo", validationKey: "ecrRepo" }));
  const ecr_externalIdRef = React.useRef(StringModel.create({ label: "External Id" }));

  const keypair_passphraseRef = React.useRef(StringModel.create({ label: "Passphrase" }));

  const nats_accountIdRef = React.useRef(
    StringModel.create({ label: "Account Id", validationKey: "natsAccountId", isRequired: true })
  );
  const nats_privateKeyRef = React.useRef(
    StringModel.create({ label: "Private Key", validationKey: "natsPrivateKey", isRequired: true })
  );

  const dictionary_keyRef = React.useRef(StringModel.create({ label: "Key" }));
  const dictionary_valueRef = React.useRef(StringModel.create({ label: "Value" }));

  const tagsRef = React.useRef(TagsNewModel.create());

  // States //
  const [ecrRepos, setEcrRepos] = React.useState<string[]>([]);
  const [dictionary, setDictionary] = React.useState<{ [_: string]: string }>({});

  const [canBeBase64, setCanBeBase64] = React.useState(false);
  const [encoding, setEncoding] = React.useState<"plain" | "base64">("plain");

  const [isCodeEditorOpen, setIsCodeEditorOpen] = React.useState<boolean>(false);
  const [codeEditorTitle, setCodeEditorTitle] = React.useState<string>("");
  const [codeEditorInitialValue, setCodeEditorInitialValue] = React.useState<string>("");
  const [selectedFileLabelIndex, setSelectedFileLabelIndex] = React.useState<number>(0);

  const [input1Label, setInput1Label] = React.useState("");
  const [input1Text, setInput1Text] = React.useState("");
  const [input2Label, setInput2Label] = React.useState("");
  const [input2Text, setInput2Text] = React.useState("");
  const [input3Label, setInput3Label] = React.useState("");
  const [input3Text, setInput3Text] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  // General //
  let hasUncompleteAction = false;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    { name: "Tags", value: `-tags`, isActive: isValid },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("-general")) {
    if (isValid) {
      next.isActive = true;
    }
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  if (typeRef.current.value === "ecr") {
    if (ecr_repoRef.current.value.length > 0) hasUncompleteAction = true;
  } else if (typeRef.current.value === "dictionary") {
    if (dictionary_keyRef.current.value.length > 0) hasUncompleteAction = true;
    if (dictionary_valueRef.current.value.length > 0) hasUncompleteAction = true;
  }

  // Effects //
  React.useEffect(() => {
    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  React.useEffect(() => {
    if (typeRef.current.value === "opaque") {
      setCanBeBase64(inputValidations.base64[0]("", input1Text) === true);
    }
  }, [input1Text]);

  React.useEffect(() => {
    if (typeRef.current.value === "opaque") {
      const isBase64 = inputValidations.base64[0]("", input1Text) === true;
      setCanBeBase64(isBase64);
    } else if (typeRef.current.value === "userpass") {
      const val1 = userpass_usernameRef.current.value;
      const val2 = userpass_passwordRef.current.value;
      const isBase64 = inputValidations.base64[0]("", val1) === true && inputValidations.base64[0]("", val2) === true;
      setCanBeBase64(isBase64);
    }
    setEncoding("plain");
  }, [typeRef.current.value]);

  React.useEffect(() => {
    let res = true;
    if (!nameRef.current.isValid) res = false;
    if (!descriptionRef.current.isValid) res = false;
    if (typeRef.current.value === "opaque") {
      if (!canBeBase64 && encoding === "base64") res = false;
    } else if (typeRef.current.value === "userpass") {
      if (!userpass_usernameRef.current.isValid) res = false;
      if (!userpass_passwordRef.current.isValid) res = false;
    } else if (typeRef.current.value === "azure-connector") {
      if (!azureConnector_urlRef.current.isValid) res = false;
      if (!azureConnector_codeRef.current.isValid) res = false;
    } else if (typeRef.current.value === "dictionary") {
      if (Object.keys(dictionary).length < 1) res = false;
    } else if (typeRef.current.value === "aws") {
      if (!aws_accessKeyRef.current.isValid) res = false;
      if (!aws_roleArnRef.current.isValid) res = false;
      if (!aws_secretKeyRef.current.isValid) res = false;
    } else if (typeRef.current.value === "ecr") {
      if (!ecr_accessKeyRef.current.isValid) res = false;
      if (!ecr_roleArnRef.current.isValid) res = false;
      if (!ecr_secretKeyRef.current.isValid) res = false;
      if (ecrRepos.length < 1) res = false;
    } else if (typeRef.current.value === "nats-account") {
      if (!nats_accountIdRef.current.isValid) res = false;
      if (!nats_privateKeyRef.current.isValid) res = false;
    } else if (typeRef.current.value === "tls") {
      if (input1Text.length < 1 || input2Text.length < 1) res = false;
    } else {
      if (input1Text.length < 1) res = false;
    }
    setIsValid(res);
  }, [
    nameRef.current.isValid,
    descriptionRef.current.isValid,
    typeRef.current.value,
    userpass_usernameRef.current.isValid,
    userpass_passwordRef.current.isValid,
    azureConnector_urlRef.current.isValid,
    azureConnector_codeRef.current.isValid,
    aws_secretKeyRef.current.isValid,
    aws_roleArnRef.current.isValid,
    aws_accessKeyRef.current.isValid,
    ecr_secretKeyRef.current.isValid,
    ecr_roleArnRef.current.isValid,
    ecr_accessKeyRef.current.isValid,
    nats_accountIdRef.current.isValid,
    nats_privateKeyRef.current.isValid,
    encoding,
    canBeBase64,
    ecrRepos,
    dictionary,
    input1Text,
    input2Text,
    input3Text,
  ]);

  React.useEffect(() => {
    let res = false;
    if (nameRef.current.isDirty) res = true;
    if (descriptionRef.current.isDirty) res = true;
    if (typeRef.current.value === "userpass") {
      if (userpass_usernameRef.current.isDirty) res = true;
      if (userpass_passwordRef.current.isDirty) res = true;
      if (encoding !== "plain") res = true;
    } else if (typeRef.current.value === "azure-connector") {
      if (azureConnector_urlRef.current.isDirty) res = true;
      if (azureConnector_codeRef.current.isDirty) res = true;
    } else if (typeRef.current.value === "dictionary") {
      if (Object.keys(dictionary).length > 0) res = true;
    } else if (typeRef.current.value === "aws") {
      if (aws_accessKeyRef.current.isDirty) res = true;
      if (aws_roleArnRef.current.isDirty) res = true;
      if (aws_secretKeyRef.current.isDirty) res = true;
      if (aws_externalIdRef.current.isDirty) res = true;
    } else if (typeRef.current.value === "ecr") {
      if (ecr_accessKeyRef.current.isDirty) res = true;
      if (ecr_roleArnRef.current.isDirty) res = true;
      if (ecr_secretKeyRef.current.isDirty) res = true;
      if (ecrRepos.length > 0) res = true;
      if (ecr_externalIdRef.current.isDirty) res = true;
    } else if (typeRef.current.value === "nats-account") {
      if (nats_accountIdRef.current.isDirty) res = true;
      if (nats_privateKeyRef.current.isDirty) res = true;
    } else if (typeRef.current.value === "keypair") {
      if (keypair_passphraseRef.current.isDirty) res = true;
      if (input1Text.length > 0) res = true;
      if (input2Text.length > 0) res = true;
    } else if (typeRef.current.value === "tls") {
      if (input1Text.length > 0) res = true;
      if (input2Text.length > 0) res = true;
      if (input3Text.length > 0) res = true;
    } else if (typeRef.current.value === "opaque") {
      // this branch is here to keep consistent with edit page
      if (input1Text.length > 0) res = true;
    } else {
      if (input1Text.length > 0) res = true;
    }
    // setIsDirty(res);
  }, [
    nameRef.current.isDirty,
    descriptionRef.current.isDirty,
    typeRef.current.isDirty,
    userpass_usernameRef.current.isDirty,
    userpass_passwordRef.current.isDirty,
    azureConnector_urlRef.current.isDirty,
    azureConnector_codeRef.current.isDirty,
    keypair_passphraseRef.current.isDirty,
    aws_secretKeyRef.current.isDirty,
    aws_roleArnRef.current.isDirty,
    aws_accessKeyRef.current.isDirty,
    aws_externalIdRef.current.isDirty,
    ecr_secretKeyRef.current.isDirty,
    ecr_roleArnRef.current.isDirty,
    ecr_accessKeyRef.current.isDirty,
    ecr_externalIdRef.current.isDirty,
    nats_accountIdRef.current.isDirty,
    nats_privateKeyRef.current.isDirty,
    ecrRepos,
    input1Text,
    input2Text,
    input3Text,
    dictionary,
  ]);

  React.useEffect(() => {
    const url = azureConnector_urlRef.current.value;
    if (!url.includes("?code=")) {
      return;
    }
    const [newUrl, newCode] = url.split("?code=");
    if (newUrl && newCode) {
      azureConnector_urlRef.current.setValue(newUrl);
      azureConnector_codeRef.current.setValue(newCode);
    }
  }, [azureConnector_urlRef.current.value]);

  React.useEffect(() => {
    if (ecrRepos.includes(ecr_repoRef.current.value)) {
      ecr_repoRef.current.setOverrideError("This repo is already added");
    } else {
      ecr_repoRef.current.clearOverrideError();
    }
  }, [ecr_repoRef.current.value, ecrRepos]);

  React.useEffect(() => {
    const val1 = userpass_usernameRef.current.value;
    const val2 = userpass_passwordRef.current.value;
    const isBase64 = inputValidations.base64[0]("", val1) === true && inputValidations.base64[0]("", val2) === true;
    if (!isBase64) {
      setEncoding("plain");
    }
    setCanBeBase64(isBase64);
  }, [userpass_usernameRef.current.value, userpass_passwordRef.current.value]);

  // Functions //
  function addDictionaryItem() {
    const _dict = { ...dictionary };
    const key = dictionary_keyRef.current.value;
    const value = dictionary_valueRef.current.value;
    _dict[key] = value;
    dictionary_keyRef.current.reset();
    dictionary_valueRef.current.reset();
    setDictionary(_dict);
  }

  function removeDictionaryItem(key: string) {
    const _dict = { ...dictionary };
    delete _dict[key];
    setDictionary(_dict);
  }

  function addECRRepoItem() {
    const value = ecr_repoRef.current.value;
    ecr_repoRef.current.reset();
    setEcrRepos((r) => [...r, value]);
  }

  function removeECRRepo(repo: string) {
    setEcrRepos((r) => r.filter((i) => i !== repo));
  }

  function onTypeChange(value: string) {
    let label1 = "";
    let label2 = "";
    let label3 = "";
    switch (value) {
      case "opaque":
        label1 = "Opaque Secret";
        break;
      case "tls":
        label1 = "TLS Key";
        label2 = "TLS Cert";
        label3 = "TLS Chain";
        break;
      case "gcp":
        label1 = "GCP Secret";
        break;
      case "docker":
        label1 = "Docker Secret";
        break;
      case "keypair":
        label1 = "Secret Key";
        label2 = "Public Key";
        break;
      case "azure-sdk":
        label1 = "Azure Secret";
        break;
      default:
        break;
    }
    setInput1Label(label1);
    setInput2Label(label2);
    setInput3Label(label3);
    typeRef.current.setValue(value);
  }

  function onUploadDictFile(e: any) {
    const input: HTMLInputElement = e.target;
    if (!input.value || !input.files || input.files.length < 1) {
      input.value = "";
      return;
    }

    const file = input.files[0];
    if (!file) {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
      return;
    }

    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (evt) {
      const overriddenKeys: string[] = [];
      const dictionaryKeys = Object.keys(dictionary);

      let atLeastOneSucceeded = false;

      const _dict = { ...dictionary };

      // Try env file
      try {
        const content: string = evt.target?.result as string;
        const rawPairs = Object.entries(parseEnvFile(content)).map(([name, value]) => ({ name, value }));
        for (const pair of rawPairs) {
          if (dictionaryKeys.includes(pair.name)) {
            overriddenKeys.push(pair.name);
          }
          _dict[pair.name] = pair.value;
        }
        atLeastOneSucceeded = true;
      } catch (e) {
        console.error("env: " + e.message);
      }

      if (!atLeastOneSucceeded) {
        // Try yaml
        try {
          const content: string = evt.target?.result as string;
          const rawYamls: { [_: string]: string }[] = jsYaml.loadAll(content) as any;

          // Try k8s secret
          for (const rawYaml of rawYamls) {
            if (!!rawYaml.data && typeof rawYaml.data === "object" && Object.keys(rawYaml.data).length > 0) {
              const rawPairs = Object.entries(rawYaml.data as { [_: string]: string }).map(([name, value]) => ({
                name,
                value,
              }));
              for (const pair of rawPairs) {
                if (dictionaryKeys.includes(pair.name)) {
                  overriddenKeys.push(pair.name);
                }
                _dict[pair.name] = pair.value;
              }
              atLeastOneSucceeded = true;
            } else {
              const rawPairs = Object.entries(rawYaml).map(([name, value]) => ({ name, value }));
              for (const pair of rawPairs) {
                if (dictionaryKeys.includes(pair.name)) {
                  overriddenKeys.push(pair.name);
                }
                _dict[pair.name] = pair.value;
              }
              atLeastOneSucceeded = true;
            }
          }
        } catch (e) {
          console.error("yaml: " + e.message);
        }
      }

      if (!atLeastOneSucceeded) {
        // Try json
        try {
          const content: string = evt.target?.result as string;
          const rawPairs = Object.entries(JSON.parse(content) as { [_: string]: string }).map(([name, value]) => ({
            name,
            value,
          }));
          for (const pair of rawPairs) {
            if (dictionaryKeys.includes(pair.name)) {
              overriddenKeys.push(pair.name);
            }
            _dict[pair.name] = pair.value;
          }
          atLeastOneSucceeded = true;
        } catch (e) {
          console.error("json: " + e.message);
        }
      }
      if (atLeastOneSucceeded && overriddenKeys.length > 0) {
        notification.info({
          duration: null,
          message: "Some Keys Were Overridden",
          description: (
            <>
              <div>Overridden Keys Are:</div>
              <ul>
                {overriddenKeys.map((envVarKey, index) => (
                  <li key={envVarKey + String(index)} className="block my-1">
                    {envVarKey}
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }
      if (!atLeastOneSucceeded) {
        notification.warning({
          message: "Failed",
          description: "File is invalid",
        });
      } else {
        setDictionary(_dict);
      }
    };
    reader.onerror = function () {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
    };
    input.value = "";
  }

  function handleCodeEditorOpen(fileLabel: string, fileLabelIndex: GenericInputs): void {
    setCodeEditorTitle(fileLabel);
    setSelectedFileLabelIndex(fileLabelIndex);

    switch (fileLabelIndex) {
      case GenericInputs.Input1:
        setCodeEditorInitialValue(input1Text);
        break;
      case GenericInputs.Input2:
        setCodeEditorInitialValue(input2Text);
        break;
      case GenericInputs.Input3:
        setCodeEditorInitialValue(input3Text);
        break;
    }

    setIsCodeEditorOpen(true);
  }

  function handleCodeEditorOk(value: string): void {
    switch (selectedFileLabelIndex) {
      case GenericInputs.Input1:
        setInput1Text(value);
        break;
      case GenericInputs.Input2:
        setInput2Text(value);
        break;
      case GenericInputs.Input3:
        setInput3Text(value);
        break;
    }

    setIsCodeEditorOpen(false);
  }

  function getSecretObject() {
    const body: any = {
      name: nameRef.current.value,
      description: descriptionRef.current.value || nameRef.current.value,
      type: typeRef.current.value,
      tags: tagsRef.current.asObject,
    };

    if (typeRef.current.value === "aws") {
      body.data = {
        accessKey: aws_accessKeyRef.current.value,
        secretKey: aws_secretKeyRef.current.value,
      };
      if (aws_roleArnRef.current.value.length > 0) {
        body.data.roleArn = aws_roleArnRef.current.value;
      }
      if (aws_externalIdRef.current.value.length > 0) {
        body.data.externalId = aws_externalIdRef.current.value;
      }
    } else if (typeRef.current.value === "azure-connector") {
      body.data = {
        url: azureConnector_urlRef.current.value,
        code: azureConnector_codeRef.current.value,
      };
    } else if (typeRef.current.value === "ecr") {
      body.data = {
        accessKey: ecr_accessKeyRef.current.value,
        secretKey: ecr_secretKeyRef.current.value,
        repos: ecrRepos,
      };
      if (ecr_roleArnRef.current.value.length > 0) {
        body.data.roleArn = ecr_roleArnRef.current.value;
      }
      if (ecr_externalIdRef.current.value.length > 0) {
        body.data.externalId = ecr_externalIdRef.current.value;
      }
    } else if (typeRef.current.value === "dictionary") {
      body.data = dictionary;
    } else if (typeRef.current.value === "userpass") {
      body.data = {
        username: userpass_usernameRef.current.value,
        password: userpass_passwordRef.current.value,
        encoding,
      };
    } else if (typeRef.current.value === "tls") {
      body.data = {
        key: input1Text,
        cert: input2Text,
      };
      if (input3Text.length > 0) {
        body.data.chain = input3Text;
      }
    } else if (typeRef.current.value === "keypair") {
      body.data = {
        secretKey: input1Text,
      };
      if (input2Text) {
        body.data.publicKey = input2Text;
      }
      if (keypair_passphraseRef.current.value) {
        body.data.passphrase = keypair_passphraseRef.current.value;
      }
    } else if (typeRef.current.value === "nats-account") {
      body.data = {
        accountId: nats_accountIdRef.current.value,
        privateKey: nats_privateKeyRef.current.value,
      };
    } else if (typeRef.current.value === "opaque") {
      body.data = {
        payload: input1Text,
        encoding,
      };
    } else {
      body.data = input1Text;
    }
    return { kind: "secret", ...body };
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({ method: "post", url: homeLink("secret"), body: getSecretObject() });
      const itemLink = resourceLink("secret", getSecretObject().name);
      notification.success({
        message: "Success",
        description: "Secret is created",
      });
      setIsLoading(false);
      PromptContext.setIsDisabled(true);
      navigate(`/console${itemLink}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/-create");
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={getSecretObject}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Secret"}
          name={nameRef.current.value}
          canCreate={isValid && tagsRef.current.isValid && !hasUncompleteAction}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route
              path={`-general`}
              element={
                <div>
                  {isCodeEditorOpen ? (
                    <CodeEditorModal
                      title={codeEditorTitle}
                      initialValue={codeEditorInitialValue}
                      open={isCodeEditorOpen}
                      onOk={handleCodeEditorOk}
                      onClose={() => setIsCodeEditorOpen(false)}
                    />
                  ) : null}
                  <NGFormElement
                    name={"name"}
                    value={nameRef.current.value}
                    onChange={nameRef.current.setValue}
                    label={nameRef.current.label}
                    error={nameRef.current.error}
                    required={nameRef.current.isRequired}
                  />
                  <NGFormElement
                    name={"description"}
                    value={descriptionRef.current.value}
                    onChange={descriptionRef.current.setValue}
                    label={descriptionRef.current.label}
                  />
                  <NGFormElement
                    name={"type"}
                    as={"select"}
                    label={typeRef.current.label}
                    onChange={onTypeChange}
                    options={typeRef.current.options}
                    value={typeRef.current.value}
                  />
                  {typeRef.current.value === "userpass" && (
                    <>
                      <NGRadioGroup
                        label={"Encoding"}
                        className="mb-4"
                        value={encoding}
                        onChange={(value) => setEncoding(value as any)}
                        options={[
                          { label: "Base64", value: "base64", isDisabled: !canBeBase64 && encoding !== "base64" },
                          { label: "Plain Text", value: "plain" },
                        ]}
                      />
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        dataTestId="userpass_username"
                        data={userpass_usernameRef.current}
                      />
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        dataTestId="userpass_password"
                        data={userpass_passwordRef.current}
                      />
                    </>
                  )}
                  {typeRef.current.value === "azure-connector" && (
                    <>
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        data={azureConnector_urlRef.current}
                      />
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        data={azureConnector_codeRef.current}
                      />
                    </>
                  )}
                  {typeRef.current.value === "dictionary" && (
                    <div className="flex flex-col">
                      <div className="mb-2 flex items-center gap-2">
                        <span>Dictionary Key-Value Pairs</span>
                        <label
                          tabIndex={0}
                          className="cursor-pointer text-sm ngfocus color-link flex items-center"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              document.getElementById("envFile")?.click();
                            }
                          }}
                        >
                          Import <Download className="feather-icon inline-block ml-1" />
                          <input
                            className="invisible"
                            style={{ width: 0 }}
                            onChange={onUploadDictFile}
                            type={"file"}
                            multiple={false}
                          />
                        </label>
                      </div>
                      <div className="mb-3">
                        {Object.entries(dictionary).length === 0 ? (
                          <NoTag override={"No entry"} />
                        ) : (
                          <div
                            className="flex table-labels px-4 py-1 border"
                            style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                          >
                            <div className="w-5/12">Key</div>
                            <div className="w-6/12">Value</div>
                          </div>
                        )}
                        {Object.entries(dictionary).map(([key, value]) => (
                          <div key={key} className="flex items-start px-4 py-1 border border-t-0">
                            <div className="w-5/12 break-words pr-3">{key}</div>
                            <div className="w-6/12 break-words">{value}</div>
                            <div className="w-1/12">
                              <Trash
                                className="ml-auto mt-1 feather-icon color-danger cursor-pointer"
                                onClick={() => removeDictionaryItem(key)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex gap-x-2 mb-2">
                        <MobxMultilineInput className="w-full" data={dictionary_keyRef.current} />
                        <MobxMultilineInput className="w-full" data={dictionary_valueRef.current} />
                      </div>
                      <NGButton
                        className="self-end"
                        variant={"action"}
                        disabled={dictionary_keyRef.current.value.length < 1}
                        onClick={addDictionaryItem}
                      >
                        Add Dictionary Item
                      </NGButton>
                    </div>
                  )}
                  {typeRef.current.value === "aws" && (
                    <>
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        data={aws_accessKeyRef.current}
                      />
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        data={aws_secretKeyRef.current}
                      />
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        data={aws_roleArnRef.current}
                      />
                      <MobxInput style={{ width: 450 }} className="mb-4" data={aws_externalIdRef.current} />
                    </>
                  )}
                  {typeRef.current.value === "ecr" && (
                    <div style={{ width: 450 }} className="flex flex-col">
                      <MobxMultilineInput hideErrorMessage className="mb-4" data={ecr_accessKeyRef.current} />
                      <MobxMultilineInput hideErrorMessage className="mb-4" data={ecr_secretKeyRef.current} />
                      <MobxMultilineInput hideErrorMessage className="mb-4" data={ecr_roleArnRef.current} />
                      <div className="mb-2">ECR Repositories</div>
                      <div className="mb-2">
                        {ecrRepos.length === 0 ? <NoTag override={"No repos"} /> : null}
                        {ecrRepos.map((repo) => (
                          <RemovableValue
                            className="w-full border rounded"
                            value={repo}
                            key={repo}
                            isDeleteVisible={true}
                            onRemove={() => removeECRRepo(repo)}
                          />
                        ))}
                      </div>
                      <MobxMultilineInput hideErrorMessage className="mb-2" data={ecr_repoRef.current} />
                      <NGButton
                        className="self-end"
                        variant={"action"}
                        disabled={!ecr_repoRef.current.isValid || ecr_repoRef.current.value.length < 1}
                        onClick={addECRRepoItem}
                      >
                        Add Repository Item
                      </NGButton>
                      <MobxInput style={{ width: 450 }} className="my-4" data={ecr_externalIdRef.current} />
                    </div>
                  )}
                  {typeRef.current.value === "nats-account" && (
                    <>
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        data={nats_accountIdRef.current}
                      />
                      <MobxMultilineInput
                        hideErrorMessage
                        style={{ width: 450 }}
                        className="mb-4"
                        data={nats_privateKeyRef.current}
                      />
                    </>
                  )}
                  {input1Label ? (
                    <div className="mb-4" style={{ width: 450 }}>
                      {input1Text ? (
                        <div className="flex items-center gap-x-2 mt-2 w-full">
                          <ReadOnlyInput label={input1Label} value={input1Text} type="password" />
                          <NGButton
                            style={{ minWidth: 120, height: 46 }}
                            variant={"primary"}
                            onClick={() => {
                              handleCodeEditorOpen(input1Label, GenericInputs.Input1);
                            }}
                          >
                            Edit
                          </NGButton>
                        </div>
                      ) : (
                        <>
                          <FormLabel size="base">
                            {input1Label} <span className="color-danger">*</span>
                          </FormLabel>
                          <NGButton
                            className="mt-2"
                            variant="primary"
                            onClick={() => {
                              handleCodeEditorOpen(input1Label, GenericInputs.Input1);
                            }}
                          >
                            Set Data
                          </NGButton>
                        </>
                      )}
                      {typeRef.current.value === "opaque" ? (
                        <>
                          <div className="mt-2 flex items-center">
                            <NGCheckbox
                              id="base64"
                              aria-label="Base64 Decode at Runtime"
                              checked={encoding === "base64"}
                              onChange={(value) => setEncoding(value ? "base64" : "plain")}
                            />
                            <label htmlFor="base64" className="ml-1 cursor-pointer">
                              Base64 Decode at Runtime
                            </label>
                            <InfoTooltip
                              title="Checking this box will perform a base64 decode at runtime of the secret content."
                              style={{ marginLeft: 5 }}
                            />
                          </div>
                          {encoding === "base64" && !canBeBase64 ? (
                            <NGAlert
                              type="error"
                              className="mt-2"
                              message={"The provided value is not a valid Base64"}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  {input2Label ? (
                    <div className="mb-4" style={{ width: 450 }}>
                      {input2Text ? (
                        <div className="flex items-center gap-x-2 mt-2 w-full">
                          <ReadOnlyInput label={input2Label} value={input2Text} type="password" />
                          <NGButton
                            variant={"primary"}
                            style={{ minWidth: 120, height: 46 }}
                            onClick={() => {
                              handleCodeEditorOpen(input2Label, GenericInputs.Input2);
                            }}
                          >
                            Edit
                          </NGButton>
                        </div>
                      ) : (
                        <>
                          <FormLabel size="base">
                            {input2Label}{" "}
                            {typeRef.current.value === "tls" ? <span className="color-danger">*</span> : null}
                          </FormLabel>
                          <NGButton
                            className="mt-2"
                            variant="primary"
                            onClick={() => {
                              handleCodeEditorOpen(input2Label, GenericInputs.Input2);
                            }}
                          >
                            Set Data
                          </NGButton>
                        </>
                      )}
                    </div>
                  ) : null}
                  {input3Label ? (
                    <div className="mb-4" style={{ width: 450 }}>
                      {input3Text ? (
                        <div className="flex items-center gap-x-2 mt-2 w-full">
                          <ReadOnlyInput label={input3Label} value={input3Text} type="password" />
                          <NGButton
                            style={{ minWidth: 120, height: 46 }}
                            variant={"primary"}
                            onClick={() => {
                              handleCodeEditorOpen(input3Label, GenericInputs.Input3);
                            }}
                          >
                            Edit
                          </NGButton>
                        </div>
                      ) : (
                        <>
                          <FormLabel size="base">{input3Label}</FormLabel>
                          <NGButton
                            className="mt-2"
                            variant={"primary"}
                            onClick={() => {
                              handleCodeEditorOpen(input3Label, GenericInputs.Input3);
                            }}
                          >
                            Set Data
                          </NGButton>
                        </>
                      )}
                    </div>
                  ) : null}
                  {typeRef.current.value === "keypair" ? (
                    <div style={{ width: 450 }}>
                      <MobxMultilineInput className="mt-4" data={keypair_passphraseRef.current} />
                      {!keypair_passphraseRef.current.value ? (
                        <NGAlert
                          className="mt-2"
                          message={"Passphrase is required if provided secret key is encrypted."}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              }
            />
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const SecretCreate = observer(SecretCreateRaw);
