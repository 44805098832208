import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from "../Tooltip";
import { TooltipProps as AntTooltipProps } from "antd";
import { Info } from "react-feather";

interface Props {
  style?: any;
  className?: string;
}

const InfoTooltipRaw: React.FC<Props & AntTooltipProps> = (props) => {
  return (
    <Tooltip {...props}>
      <Info className={`feather-icon ${props.className}`} style={{ marginLeft: 10, ...props.style }} />
    </Tooltip>
  );
};

export const InfoTooltip = observer(InfoTooltipRaw);
