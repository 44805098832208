import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { UIData } from "../mobxStores/uiData/uiData";
import { Theme } from "../mobxStores/uiData/theme";
import { observer } from "mobx-react-lite";
import { MenuLeft } from "../components/layout/sider";
import { Header } from "../components/layout/header";
import * as React from "react";
import "../components/layout/newLayoutColors.css";
import "../components/layout/newLayout.css";
import { SelectMenu } from "../components/SelectMenu";
import { ConsoleContext } from "../mobxStores/consoleContext/consoleContext";
import { UserData } from "../mobxStores/userData/userData";
import { flowResult } from "mobx";
import { request, resourceLink } from "../services/cpln";
import { Footer } from "./footer";
import { ParsedLocation } from "../mobxStores/parsedLocation/parsedLocation";
import { Bar, Container, Section } from "@column-resizer/react";
import { PromptContext } from "../mobxStores/prompt/prompt";
import { useNMainHeight } from "../reactContexts/nMainHeightContext";

interface Props extends React.PropsWithChildren {
  showLeftMenu?: boolean;
}
const LayoutRaw: React.FC<Props> = ({ children, showLeftMenu = true }) => {
  const { org, gvc } = ConsoleContext;
  const { pathname } = useLocation();
  const { setNMainHeight } = useNMainHeight();

  // const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const onResize = () => {
      setNMainHeight(window.innerHeight);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--nav-width",
      `var(--nav-${UIData.isNavCollapsed ? "collapsed" : "expanded"}-width)`
    );
  }, [UIData.isNavCollapsed]);

  async function onGVCChange(gvcToChange: string) {
    const isWorkloadDetail = pathname.includes("/workload/");
    const isIdentityDetail = pathname.includes("/identity/");
    const isVolumeSetDetail = pathname.includes("/volumeset/");
    const isGvcScopedPage = pathname.includes("/gvc/");
    if (isGvcScopedPage) {
      ConsoleContext.setIsChangingContext(true);
    }

    const oldGVC = gvc;
    await request({ url: resourceLink("gvc", gvcToChange) });
    ConsoleContext.setGVC(gvcToChange);
    let newPathname = "/";
    if (isWorkloadDetail) {
      newPathname = `/console/org/${org}/gvc/${gvcToChange}/workload`;
    } else if (isIdentityDetail) {
      newPathname = `/console/org/${org}/gvc/${gvcToChange}/identity`;
    } else if (isVolumeSetDetail) {
      newPathname = `/console/org/${org}/gvc/${gvcToChange}/volumeset`;
    } else if (pathname.includes(`/gvc/${oldGVC}`)) {
      newPathname = pathname.replace(`/gvc/${oldGVC}/`, `/gvc/${gvcToChange}/`);
    }
    PromptContext.setIsDisabled(true);
    ConsoleContext.setRedirect(newPathname);
  }

  async function handleOrgChange(orgToChange: string) {
    ConsoleContext.setIsChangingContext(true);
    const oldOrg = org!;
    const oldGVC = gvc;
    await flowResult(ConsoleContext.setOrg(orgToChange));
    // Set GVC
    await flowResult(UserData.requestGVCNames());
    const localTabGVC = localStorage.getItem(`${UIData.tabId}-${ConsoleContext.org!}`) || "";
    const localGeneralGVC = localStorage.getItem(ConsoleContext.org!) || "";
    let newGVC = "";
    if (UserData.gvcNames.includes(localTabGVC)) {
      newGVC = localTabGVC;
    } else if (UserData.gvcNames.includes(localGeneralGVC)) {
      newGVC = localGeneralGVC;
    } else {
      newGVC = UserData.gvcNames[0];
    }
    if (newGVC) {
      ConsoleContext.setGVC(newGVC);
    } else {
      ConsoleContext.clearGVC();
    }
    // Update url
    let newPathname = pathname;
    newPathname = newPathname.replace(oldOrg, orgToChange);
    if (oldGVC && newGVC) {
      newPathname = newPathname.replace(oldGVC, newGVC);
    }
    if (newPathname.includes("-select")) {
      newPathname = newPathname.split("-select")[0];
    }
    PromptContext.setIsDisabled(true);
    ConsoleContext.setRedirect(newPathname);
  }

  function renderPinnedSection() {
    if (UIData.isGVCPinned && !UIData.isOrgPinned) {
      return (
        <SelectMenu
          key={ConsoleContext.gvc}
          style={{ height: "100%", border: "none" }}
          selectItem={(newGVC) => onGVCChange(newGVC)}
          label={gvc}
          kind={"gvc"}
          items={UserData.gvcNames.map((name) => ({ value: name, labels: [name] }))}
          value={ConsoleContext.gvc}
          canBePinned
        />
      );
    }

    if (!UIData.isGVCPinned && UIData.isOrgPinned) {
      return (
        <SelectMenu
          key={ConsoleContext.org}
          style={{ height: "100%", border: "none" }}
          selectItem={(newOrg) => handleOrgChange(newOrg)}
          label={org}
          kind={"org"}
          items={UserData.orgNames.map((name) => ({ value: name, labels: [name] }))}
          value={ConsoleContext.org}
          canBePinned
        />
      );
    }

    return (
      <Container vertical style={{ height: "100%" }}>
        <Section minSize={200} style={{ height: "100%" }}>
          <SelectMenu
            key={ConsoleContext.gvc}
            style={{ height: "100%", border: "none" }}
            selectItem={(newGVC) => onGVCChange(newGVC)}
            label={gvc}
            kind={"gvc"}
            items={UserData.gvcNames.map((name) => ({ value: name, labels: [name] }))}
            value={ConsoleContext.gvc}
            canBePinned
          />
        </Section>
        <Bar
          size={5}
          style={{
            borderTop: "1px solid var(--color-border)",
            borderBottom: "1px solid var(--color-border)",
            background: "var(--color-drop)",
            cursor: "n-resize",
          }}
        />
        <Section minSize={200} style={{ height: "100%" }}>
          <SelectMenu
            key={ConsoleContext.org}
            style={{ height: "100%", border: "none" }}
            selectItem={(newOrg) => handleOrgChange(newOrg)}
            label={org}
            kind={"org"}
            items={UserData.orgNames.map((name) => ({ value: name, labels: [name] }))}
            value={ConsoleContext.org}
            canBePinned
          />
        </Section>
      </Container>
    );
  }
  return (
    <>
      <section
        id={"page"}
        className={clsx({
          dark: Theme.theme === "dark",
          pinned: ParsedLocation.dashboard === "console" && (UIData.isGVCPinned || UIData.isOrgPinned),
          noSidebar: !showLeftMenu,
        })}
      >
        <header>
          <Header isMenuShown={showLeftMenu} />
        </header>
        {showLeftMenu ? (
          <>
            <nav className={clsx("main", { collapsed: UIData.isNavCollapsed })}>
              <MenuLeft />
            </nav>
            <nav className={clsx("pinned", { hidden: !UIData.isGVCPinned && !UIData.isOrgPinned })}>
              {renderPinnedSection()}
            </nav>
          </>
        ) : (
          ""
        )}
        <main>
          <section>{children}</section>
          <Footer />
        </main>
      </section>
    </>
  );
};

export const Layout = observer(LayoutRaw);
