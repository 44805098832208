import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderTritonAdvanced } from "./advanced";
import { ProviderTritonNodePool } from "./nodePool";
import { ProviderTritonNodePoolAdd } from "./nodePoolAdd";
import { ProviderTritonNodePools } from "./nodePools";
import { ProviderTriton } from "./triton";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderTritonRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderTriton mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderTritonNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<ProviderTritonNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<ProviderTritonNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<ProviderTritonAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderTritonRoute = observer(ProviderTritonRouteRaw);
