import * as React from "react";
import { ServiceAccountMobx } from "../../mst/kinds/serviceaccount";
import { StringModel } from "../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { FormLabel } from "../../components/forms/formLabel";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  serviceaccount: ServiceAccountMobx;
  patch: (body: any) => Promise<void>;
}
const InfoRaw: React.FC<Props> = ({ serviceaccount, patch }) => {
  const { fetchItem } = useDetailContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const descriptionRef = React.useRef(
    StringModel.create({ label: "Description", initialValue: serviceaccount.description }),
  );

  React.useEffect(() => {
    PromptContext.setWhen(descriptionRef.current.isDirty || isLoading);
  }, [descriptionRef.current.isDirty, isLoading]);

  useCleanPrompt();

  async function save() {
    try {
      setIsLoading(true);
      await patch({ description: descriptionRef.current.value || null });
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(serviceaccount.name);
      }
      notification.success({
        message: "Success",
        description: "Updated service account",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div>
        <div>
          <NGFormElement
            name="description"
            label={descriptionRef.current.label}
            value={descriptionRef.current.value}
            onChange={descriptionRef.current.setValue}
          />
          <div className="mb-4">
            <FormLabel>Keys</FormLabel>
            <div>{serviceaccount.keys.length === 0 ? "No Keys" : `${serviceaccount.keys.length} Keys`} </div>
          </div>
          <TagLinksTable
            tableId="serviceaccount-info-tag-links"
            tags={Object.entries(serviceaccount.tags || {})
              .map(([tagKey, tagValue]) => ({ key: tagKey, value: String(tagValue || "") }))
              .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
            styles={{ header: { marginBottom: 0 } }}
          />
          <FormButtons
            onReset={descriptionRef.current.reset}
            onSave={save}
            saveDisabled={isLoading || !descriptionRef.current.isDirty}
            resetDisabled={isLoading || !descriptionRef.current.isDirty}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export const Info = observer(InfoRaw);
