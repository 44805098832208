import * as React from "react";
import * as ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";
import { Checkbox, notification } from "antd";
import { Loader } from "../../../components/layout/loader";
import { FixedSizeList as List } from "react-window";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { BrowserRes, BROWSER_REL } from "../../../mst/kinds/browser";
import { request } from "../../../services/cpln";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGLabel } from "../../../newcomponents/text/label";
import { Tooltip } from "../../../components/Tooltip";

export interface BrowserRole {
  label: string;
  ref: string;
}

interface DataItemProps {
  label: string;
  onClick: any;
  isActive: boolean;
  style: any;
}
export const SelectRolesModalDataItem: React.FC<DataItemProps> = ({ label, onClick, isActive, style }) => {
  return (
    <div className="flex items-center text-sm" onClick={onClick} style={style}>
      <Checkbox checked={isActive} />
      <Tooltip title={label} open={label.length >= 63}>
        <div className={`browser-service-item ml-1 cursor-pointer   truncate ${isActive ? "   " : ""}`}>{label}</div>
      </Tooltip>
    </div>
  );
};

interface Props {
  onClose: (selections: string[]) => void;
  itemHref: string;
  currentSelections: string[];
}
const SelectRolesModalRaw: React.FC<Props> = ({ onClose, itemHref, currentSelections }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const searchRef = React.useRef(StringModel.create({ label: "Search", transformKey: "lowerCase" }));
  const [selections, setSelections] = React.useState<string[]>([...currentSelections]);
  const [error, setError] = React.useState("");
  const [_roles, setRoles] = React.useState<BrowserRole[]>([]);

  React.useEffect(() => {
    setIsLoading(true);
    request<BrowserRes>({ service: "browser", url: `${itemHref}` })
      .then(({ data }) => {
        const rolesLink = data.links.find((l) => l.rel === BROWSER_REL.roles)!.href;
        request<BrowserRes>({ service: "browser", url: `${rolesLink}` }).then(({ data }) => {
          setRoles(data.data!.map((d) => ({ label: d._cpln.label, ref: d._cpln.ref })));
          setIsLoading(false);
        });
      })
      .catch((e) => {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        setError(errorMessage);
        notification.warning({ message: "Failed", description: errorMessage });
      });
  }, []);

  function onItem(role: BrowserRole) {
    if (selections.includes(role.ref)) {
      setSelections(selections.filter((s) => s !== role.ref));
    } else {
      setSelections([...selections, role.ref]);
    }
  }

  let filteredRoles = _roles.filter((role) => role.label.toLowerCase().includes(searchRef.current.value));

  // TODO test this
  if (error) {
    console.error(error);
  }

  return ReactDOM.createPortal(
    <div
      className="fixed w-screen h-screen inset-0 flex items-center justify-center"
      style={{ backgroundColor: "rgb(0, 0, 0, 0.5)", zIndex: 1200 }}
    >
      <div
        className="text-normal"
        style={{ backgroundColor: "var(--color-drop)", padding: "20px 24px", borderRadius: "8px" }}
      >
        <NGLabel size={2} className="mb-4">
          Select Roles
        </NGLabel>
        <NGFormElement
          name="search"
          label={searchRef.current.label}
          value={searchRef.current.value}
          onChange={searchRef.current.setValue}
        />
        <div onClick={(e) => e.stopPropagation()} style={{ width: 500, height: 400 }} className="flex">
          <div style={{ width: 500 }} className="overflow-auto">
            {!isLoading ? (
              filteredRoles.length < 1 ? (
                <span>No Roles Found</span>
              ) : (
                <List itemData={filteredRoles} height={400} itemCount={filteredRoles.length} itemSize={22} width={500}>
                  {({ data, index, style }) => {
                    const role = data[index];
                    return (
                      <SelectRolesModalDataItem
                        style={style}
                        onClick={() => onItem(role)}
                        isActive={selections.includes(role.ref)}
                        label={role.label!}
                      />
                    );
                  }}
                </List>
              )
            ) : null}
          </div>
        </div>
        {isLoading ? <Loader reason={"browser service"} fullScreen={false} /> : null}
        <div className="flex justify-end mt-4">
          <NGButton variant={"danger"} outlined onClick={() => onClose(currentSelections)}>
            Cancel
          </NGButton>
          <NGButton
            variant={"action"}
            className="ml-4"
            onClick={() => onClose(selections)}
            disabled={selections.length < 1}
          >
            Confirm
          </NGButton>
        </div>
      </div>
    </div>,
    document.body!
  );
};

export const SelectRolesModal = observer(SelectRolesModalRaw);
