import { types, Instance, flow } from "mobx-state-tree";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { request, resourceLink } from "../../services/cpln";
import { defaultValues } from "../base";
import { GVCMobx, GVCModel } from "../kinds/gvc";
import { WorkloadMobx } from "../kinds/workload";
import { WorkloadDraftStoreModel } from "./workload.draft";
import { Gvc } from "../../schema/types/gvc";

export const WorkloadDraftManagerModel = types
  .model("WorkloadStore", {
    draft: types.optional(WorkloadDraftStoreModel, () =>
      WorkloadDraftStoreModel.create({
        gvcName: "notset",
        orgName: "notset",
        gvcAlias: "@runtime",
        gvcDomain: "@runtime",
        gvcLink: "@runtime",
        orgLink: "@runtime",
      })
    ),
  })
  .actions((self) => {
    const startDraftDetail = flow(function* (workload: WorkloadMobx) {
      try {
        const { gvc } = ConsoleContext;
        const { data: gvcRes } = yield request<Gvc>({ url: resourceLink("gvc", gvc!) });
        let gvcInstance: GVCMobx = null as any;
        try {
          gvcInstance = GVCModel.create(gvcRes);
        } catch (e) {
          throw e;
        }
        const gvcEnv = gvcRes.spec?.env || [];
        self.draft.setInfo(gvcInstance.spec.domain || defaultValues.domain.value, gvcInstance.alias, gvcEnv);
        self.draft.start(workload);
      } catch (e) {
        console.error(e.message);
      }
    });
    const startDraftCreate = flow(function* () {
      const { gvc } = ConsoleContext;
      const { data: gvcRes } = yield request<Gvc>({ url: resourceLink("gvc", gvc!) });
      let gvcInstance: GVCMobx = null as any;
      try {
        gvcInstance = GVCModel.create(gvcRes);
      } catch (e) {
        throw e;
      }
      const gvcEnv = gvcRes.spec?.env || [];
      self.draft.setInfo(gvcInstance.spec.domain || defaultValues.domain.value, gvcInstance.alias, gvcEnv);
      self.draft.new();
    });
    return {
      startDraftDetail,
      startDraftCreate,
    };
  });

export interface WorkloadStoreMobx extends Instance<typeof WorkloadDraftManagerModel> {}
