import { Instance, types } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { defaultCiphers } from "../kinds/domain/ciphers";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const DomainPortTLSModel = types
  .model({
    minProtocolVersion: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Min Protocol Version",
        initialValue: "TLSV1_2",
        options: [
          { label: "TLSV1_3", value: "TLSV1_3" },
          { label: "TLSV1_2", value: "TLSV1_2" },
          { label: "TLSV1_1", value: "TLSV1_1" },
          { label: "TLSV1_0", value: "TLSV1_0" },
        ],
      }),
    ),
    _forwardClientCertificate: types.optional(types.boolean, false),
    _useCustomServerCertificate: types.optional(types.boolean, false),
    cipherSuites: types.optional(ListOfItemsModel, () =>
      ListOfItemsModel.create({ _items: defaultCiphers.map((v) => ({ firstValue: v })) }),
    ),
    forwardClientCertificate: types.optional(types.boolean, false),
    useCustomServerCertificate: types.optional(types.boolean, false),
    clientCertificateSecretName: types.optional(types.string, ""),
    serverCertificateSecretName: types.optional(types.string, ""),
  })
  .views((self) => ({
    get clientCertificateSecretLink() {
      const { absolute } = ngParseLink(self.clientCertificateSecretName, { kind: "secret" });
      return absolute;
    },
    get serverCertificateSecretLink() {
      const { absolute } = ngParseLink(self.serverCertificateSecretName, { kind: "secret" });
      return absolute;
    },
  }))
  .actions((self) => ({
    setClientCertificateSecretName(value: string) {
      self.clientCertificateSecretName = value;
    },
    setServerCertificateSecretName(value: string) {
      self.serverCertificateSecretName = value;
    },
    setForwardClientCertificate(value: boolean) {
      self.forwardClientCertificate = value;
    },
    setUseCustomServerCertificate(value: boolean) {
      self.useCustomServerCertificate = value;
    },
  }))
  .views((self) => ({
    get asObject() {
      const body: any = {
        minProtocolVersion: self.minProtocolVersion.value,
        cipherSuites: self.cipherSuites.items.map((c) => c.firstValue),
        // always add but remove at the top place
        serverCertificate: {
          secretLink: self.serverCertificateSecretLink,
        },
      };
      if (self.forwardClientCertificate) {
        body.clientCertificate = {};
        if (self.clientCertificateSecretName) {
          body.clientCertificate.secretLink = self.clientCertificateSecretLink;
        }
      }
      return body;
    },
  }));
export interface DomainPortTLSMobx extends Instance<typeof DomainPortTLSModel> {}
export interface DomainPortTLS {
  minProtocolVersion: string;
  cipherSuites: string[];
  clientCertificate?: {
    secretLink?: string;
  };
  serverCertificate?: {
    secretLink?: string;
  };
}
