import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { LifecycleHook } from "./lifecycleHook";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  containerDraft: WorkloadDraftContainerMobx;
}
const LifecycleHooksRaw: React.FC<Props> = ({ workloadDraft, containerDraft }) => {
  return (
    <>
      <LifecycleHook
        type={"postStart"}
        use={containerDraft.usePostStartHook}
        setUse={containerDraft.setUsePostStartHook}
        args={containerDraft.postStartArgs}
        command={containerDraft.postStartCommand}
        setCommand={containerDraft.setPostStartCommand}
      />
      <LifecycleHook
        type={"preStop"}
        use={containerDraft.usePreStopHook}
        setUse={containerDraft.setUsePreStopHook}
        args={containerDraft.preStopArgs}
        command={containerDraft.preStopCommand}
        setCommand={containerDraft.setPreStopCommand}
      />
    </>
  );
};

export const LifecycleHooks = observer(LifecycleHooksRaw);
