import * as React from "react";
import { OrgLogsBrowseModel } from "../logsBrowser";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { LoggingS3Mobx } from "../../../../mst/kinds/org";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";

export function getLogsS3Data(s3: LoggingS3Mobx) {
  const s3_browser = OrgLogsBrowseModel.create();
  function getDefaultS3Bucket() {
    return s3.bucket || "";
  }

  const s3_bucket = StringModel.create({ label: "Bucket", initialValue: getDefaultS3Bucket(), isRequired: true });
  function getDefaultS3Region() {
    return s3.region || "";
  }
  const s3_region = StringModel.create({ label: "Region", initialValue: getDefaultS3Region(), isRequired: true });

  function getDefaultS3Prefix() {
    return s3.prefix || "";
  }
  const s3_prefix = StringModel.create({ label: "Prefix", initialValue: getDefaultS3Prefix(), isRequired: true });

  function getDefaultS3Credentials() {
    return ngParseLink(s3.credentials || "").name;
  }
  const s3_credentials = StringModel.create({
    label: "Credentials",
    initialValue: getDefaultS3Credentials(),
    isRequired: true,
  });

  function isDirty() {
    let res = false;
    if (getDefaultS3Bucket() !== s3_bucket.value) {
      res = true;
    }
    if (getDefaultS3Region() !== s3_region.value) {
      res = true;
    }
    if (getDefaultS3Prefix() !== s3_prefix.value) {
      res = true;
    }
    if (getDefaultS3Credentials() !== s3_credentials.value) {
      res = true;
    }
    return res;
  }

  function isValid() {
    let res = true;
    if (!s3_bucket.isValid) {
      res = false;
    }
    if (!s3_region.isValid) {
      res = false;
    }
    if (!s3_prefix.isValid) {
      res = false;
    }
    if (!s3_credentials.isValid) {
      res = false;
    }
    return res;
  }

  function asObject() {
    return {
      s3: {
        bucket: s3_bucket.value,
        region: s3_region.value,
        prefix: s3_prefix.value,
        credentials: ngParseLink(s3_credentials.value, { kind: "secret" }).absolute,
      },
    };
  }

  return {
    logProvider: "s3",
    browser: s3_browser,
    bucket: s3_bucket,
    region: s3_region,
    prefix: s3_prefix,
    credentials: s3_credentials,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
