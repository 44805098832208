import { ConsoleContext } from "../mobxStores/consoleContext/consoleContext";

export const gvcScopedKinds = ["workload", "identity", "volumeset"];

export function matchItemGvc(item: any, gvc?: string) {
  const itemGvc = item.links.find((l: any) => l.rel === "gvc")?.href.split("/gvc/")[1];
  return (gvc || ConsoleContext.gvc) === itemGvc;
}

export function matchItemByType(type: string) {
  return function (item: any) {
    return item.type === type;
  };
}
