import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { AxiosResponse } from "axios";
import { arraysAreEqual, linksOf, request } from "../../services/cpln";
import { PolicyMobx } from "../../mst/kinds/policy";
import { TargetTable } from "./targetTables/targetTable";
import { Base } from "../../schema/types/base";
import { ngParseLink } from "../../utils/linkParser/linkParser";

// TODO can fix initial jump
interface Props {
  policy: PolicyMobx;
}

interface TargetTableItem extends Base {
  selfLink: string;
}

const PolicyTargetItemsRaw: React.FC<Props> = ({ policy }) => {
  const { fetchItem } = useDetailContext();

  const [targets, setTargets] = React.useState<TargetTableItem[]>([]);
  React.useEffect(() => {
    fetchTargets();
  }, []);

  async function fetchTargets() {
    const promises: Promise<AxiosResponse<Base>>[] = [];
    for (const link of policy.targetLinks) {
      promises.push(request({ url: link }));
    }

    const responses = await Promise.allSettled(promises);
    setTargets((currentItems) => {
      const newItems: TargetTableItem[] = [];

      for (const responseIndexStr in responses) {
        const responseIndex = Number(responseIndexStr);
        const response = responses[responseIndex];

        // TODO fix other instances
        if (response.status === "rejected") {
          const link = policy.targetLinks[responseIndex];
          const parsedLink = ngParseLink(link, { useInputCtx: true });
          const currentItem: TargetTableItem = {
            name: parsedLink.name,
            created: "",
            description: "",
            id: responseIndexStr,
            kind: policy.targetKind,
            lastModified: "",
            links: [{ rel: "self", href: link }],
            tags: {},
            version: 0,
            selfLink: link,
          };
          newItems.push(currentItem);
          continue;
        }

        const selfLink = linksOf(response.value.data).self!;
        // TODO better handle this, improve types
        const gvc = selfLink.split("/")[4];
        const newItem = { ...response.value.data, selfLink: selfLink, gvc: gvc };
        newItems.push(newItem);
      }

      return newItems;
    });
  }

  const [selectionsToRemove, setSelectionsToRemove] = React.useState<string[]>([]);
  const [selectionsToAdd, setSelectionsToAdd] = React.useState<string[]>([]);
  const [selectionToAddItems, setSelectionToAddItems] = React.useState<TargetTableItem[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const isDirty = React.useMemo(() => {
    const links = targets.map((l) => l.selfLink);
    return !arraysAreEqual(links, [...policy.targetLinks]);
  }, [targets, policy.targetLinks]);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty);
  }, [isDirty]);

  useCleanPrompt();

  async function confirm() {
    try {
      setIsLoading(true);

      const body: any = { targetLinks: targets.map((t) => t.selfLink) };
      await request({ method: "patch", url: policy.selfLink, body: body });
      if (fetchItem) {
        fetchItem();
      }
      notification.success({
        message: "Success",
        description: "Updated Policy Targets",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  async function reset() {
    fetchTargets();
    setSelectionsToRemove([]);
    setSelectionsToAdd([]);
    setSelectionToAddItems([]);
  }

  function onOkAddModal() {
    // There is a very rare edge case here, if user makes a selection, than updates the query and loses items which were chosen, we don't add them here
    const newTargets: TargetTableItem[] = [...selectionToAddItems];
    setTargets((targets) => [...targets, ...newTargets]);
    setSelectionsToRemove([]);
    setSelectionsToAdd([]);
    setSelectionToAddItems([]);
  }

  function onRemove() {
    setTargets((targets) => targets.filter((target) => !selectionsToRemove.includes(target.selfLink)));
    setSelectionsToRemove([]);
    setSelectionsToAdd([]);
    setSelectionToAddItems([]);
  }

  return (
    <>
      <TargetTable
        kind={policy.targetKind} //
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={(links) => {
          setSelectionsToRemove(links);
        }}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={(links, items) => {
          setSelectionsToAdd(links);
          setSelectionToAddItems(items!);
        }}
        targets={targets}
        onOkAddModal={onOkAddModal}
        onRemove={onRemove}
      />
      <FormButtons
        loading={isLoading}
        resetDisabled={isLoading || !isDirty}
        saveDisabled={isLoading || !isDirty}
        onReset={reset}
        onSave={confirm}
      />
    </>
  );
};

export const PolicyTargetItems = observer(PolicyTargetItemsRaw);
