import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Modal, notification } from "antd";
import { request } from "../../../../services/cpln";
import { AccountPaymentMethod } from "../../../../mst/kinds/account";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";
import { flowResult } from "mobx";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGLabel } from "../../../../newcomponents/text/label";

const PaymentMethodsListRaw: React.FC = () => {
  const navigate = useNavigate();
  const { AccountUUID, Roles, PaymentMethods } = BillingContext;
  const [isLoading, setIsLoading] = React.useState(false);
  const [action, setAction] = React.useState<"delete" | "default">("default");
  const [processedCardId, setProcessedCardId] = React.useState("");
  const [cardIdToRemove, setCardIdToRemove] = React.useState("");

  async function makeDefault(id: string) {
    try {
      setIsLoading(true);
      setProcessedCardId(id);
      setAction("default");
      await request({
        method: "put",
        service: "billing-ng",
        url: `/account/${AccountUUID}/default-payment/${id}`,
      });
      await flowResult(BillingContext.fetchPaymentMethods());
    } catch (e) {
      notification.warning({
        message: "Failed",
        description: e.message,
      });
    } finally {
      setProcessedCardId("");
      setIsLoading(false);
    }
  }

  function deleteCard(id: string): void {
    setCardIdToRemove(id);
  }

  async function confirmCardDeletion(): Promise<void> {
    setCardIdToRemove("");

    try {
      setIsLoading(true);
      setProcessedCardId(cardIdToRemove);
      setAction("delete");
      await request({
        method: "delete",
        service: "billing-ng",
        url: `/account/${AccountUUID}/payment/${cardIdToRemove}`,
        body: {},
      });
      await flowResult(BillingContext.fetchPaymentMethods());
    } catch (e) {
      notification.warning({
        message: "Failed",
        description: e.message,
      });
    } finally {
      setProcessedCardId("");
      setIsLoading(false);
    }
  }

  if (Roles.includes("billing_admin") && PaymentMethods.length === 0) {
    navigate(`-add`);
    return null;
  }

  const pmIds = PaymentMethods.map((pm) => pm.id)
    .slice()
    .sort();

  return (
    <>
      <div className="mb-4 flex items-center">
        <NGLabel className="mr-4">Payment Methods</NGLabel>
        {Roles.includes("billing_admin") ? (
          <NGButton disabled={isLoading} size={"small"} variant={"action"} onClick={() => navigate(`-add`)}>
            Add
          </NGButton>
        ) : null}
      </div>
      <div className="flex flex-wrap gap-4">
        {pmIds
          .map((pmId: string) => PaymentMethods.find((pm) => pm.id === pmId) as AccountPaymentMethod)
          .filter(Boolean)
          .map((pm) => (
            <div key={pm.id} className="rounded border p-4" data-testid={pm.expiration} style={{ width: 350 }}>
              <div className="pb-4">
                <div>
                  {pm.brand.toUpperCase()} **** {pm.last4}
                </div>
                <div>Expires {pm.expiration}</div>
              </div>
              <div className="border-t pt-4 flex items-center justify-end">
                {pm.default ? (
                  <div>Default</div>
                ) : Roles.includes("billing_admin") ? (
                  <>
                    <NGButton
                      variant={"primary"}
                      size={"small"}
                      className="mr-2"
                      disabled={isLoading}
                      loading={action === "default" && processedCardId === pm.id}
                      onClick={() => makeDefault(pm.id)}
                    >
                      Make Default
                    </NGButton>
                    <NGButton
                      variant={"danger"}
                      size={"small"}
                      disabled={isLoading}
                      loading={action === "delete" && processedCardId === pm.id}
                      onClick={() => deleteCard(pm.id)}
                    >
                      Delete
                    </NGButton>
                  </>
                ) : null}
              </div>
            </div>
          ))}
      </div>
      {cardIdToRemove ? (
        <Modal
          title="Delete Payment Method"
          open={true}
          destroyOnClose
          onCancel={() => {
            setCardIdToRemove("");
          }}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setCardIdToRemove("")}>
                Cancel
              </NGButton>
              <NGButton variant="danger" onClick={confirmCardDeletion}>
                Delete
              </NGButton>
            </div>
          }
        >
          Are you sure you want to delete this?
        </Modal>
      ) : null}
    </>
  );
};

export const PaymentMethodsList = observer(PaymentMethodsListRaw);
