import * as React from "react";
import { EnvironmentVariables } from "../workload/container/environmentVariables";
import { EnvVarsMobx } from "../../mobxDataModels/envVarsModel";
import { GVCMobx } from "../../mst/kinds/gvc";
import { observer } from "mobx-react-lite";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";

interface Props {
  gvc: GVCMobx;
  env: EnvVarsMobx;
  isLoading: boolean;
  onReset: any;
  onSave: any;
}
const EnvVarWrapperRaw: React.FC<Props> = ({ gvc, env, isLoading, onReset, onSave }) => {
  React.useEffect(() => {
    PromptContext.setWhen(env.isDirty || isLoading);
  }, [env.isDirty, isLoading]);

  React.useEffect(() => {
    return () => {
      onReset();
    };
  }, []);

  useCleanPrompt();

  return (
    <EnvironmentVariables
      env={env}
      hasDefaultEnv={false}
      filename={`${gvc.name}-env`}
      saveForm={{
        isActive: true,
        isDirty: env.isDirty,
        isLoading: isLoading,
        onReset: onReset,
        onSave: onSave,
      }}
    />
  );
};

export const EnvVarWrapper = observer(EnvVarWrapperRaw);
