import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import {
  OrgMobx,
  ThreatDetectionModelMinimumSeverityOptions,
  ThreatDetectionModelSyslogTransportOptions,
} from "../../mst/kinds/org";
import { useDetailContext } from "../../components/detail/detailContext";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { PromptContext } from "../../mobxStores/prompt/prompt";

import { NumberModel } from "../../mobxDataModels/numberModel";
import { BooleanModel } from "../../mobxDataModels/booleanModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";

import { NGCheckbox } from "../../newcomponents/checkbox";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { FormButtons } from "../../components/forms/formButtons";
import { InfoTooltip } from "../../components/InfoTooltip";
import { NGLabel } from "../../newcomponents/text/label";
import NGAlert from "../../newcomponents/alert";

interface Props {
  org: OrgMobx;
}
const ThreatDetectionRaw: React.FC<Props> = ({ org }) => {
  const { fetchItem } = useDetailContext();

  const enabledRef = React.useRef(
    BooleanModel.create({
      initialValue: org.spec?.security?.threatDetection?.enabled,
    })
  );
  const minimumSeverityRef = React.useRef(
    SelectModel.create({
      label: "Minimum Severity",
      initialValue:
        org.spec?.security?.threatDetection?.minimumSeverity || ThreatDetectionModelMinimumSeverityOptions[0].value,
      options: ThreatDetectionModelMinimumSeverityOptions,
    })
  );
  const transportRef = React.useRef(
    SelectModel.create({
      label: "Transport",
      initialValue:
        org.spec?.security?.threatDetection?.syslog.transport || ThreatDetectionModelSyslogTransportOptions[0].value,
      options: ThreatDetectionModelSyslogTransportOptions,
    })
  );
  const hostRef = React.useRef(
    StringModel.create({
      label: "Host",
      initialValue: org.spec?.security?.threatDetection?.syslog.host,
      isRequired: true,
      validationKey: "hostname",
    })
  );
  const portRef = React.useRef(
    NumberModel.create({
      label: "Port (Must use TLS)",
      initialValue: org.spec?.security?.threatDetection?.syslog.port,
      isRequired: true,
    })
  );

  function getIsDirty() {
    return (
      enabledRef.current.isDirty ||
      minimumSeverityRef.current.isDirty ||
      transportRef.current.isDirty ||
      hostRef.current.isDirty ||
      portRef.current.isDirty
    );
  }
  const isDirty = getIsDirty();

  function getIsValid() {
    return hostRef.current.isValid && portRef.current.isValid;
  }
  const isValid = getIsValid();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  function handleReset() {
    enabledRef.current.reset();
    minimumSeverityRef.current.reset();
    transportRef.current.reset();
    hostRef.current.reset();
    portRef.current.reset();
  }

  async function handleSave() {
    try {
      setIsLoading(true);
      await org.patch({
        spec: {
          security: {
            threatDetection: {
              enabled: enabledRef.current.value,
              minimumSeverity: minimumSeverityRef.current.value,
              syslog: {
                transport: transportRef.current.value,
                host: hostRef.current.value,
                port: Number(portRef.current.value),
              },
            },
          },
        },
      });

      enabledRef.current.confirm();
      minimumSeverityRef.current.confirm();
      transportRef.current.confirm();
      hostRef.current.confirm();
      portRef.current.confirm();

      notification.success({
        message: "Success",
        description: "Updated Threat Detection",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Threat Detection Failed to Save", e);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div style={{ width: 450 }}>
        <NGAlert
          className="mb-4"
          message="Control Plane provided Falco-based threat detection and notification system. Enabling this capability will detect threats in real time and cause event shipping of security-related events to a Syslog endpoint."
        />
        <div className="flex items-center mb-4">
          <NGCheckbox onChange={(value) => enabledRef.current.setValue(value)} checked={enabledRef.current.value}>
            <NGLabelText>Forward Detected Threats</NGLabelText>
          </NGCheckbox>
          <InfoTooltip title="When checked, detected threats within the org cause events to be sent to a Syslog endpoint for analysis and alerting." />
        </div>
        <NGFormElement
          as="select"
          info={["Only events of the specified severity level or higher will be sent to the Syslog endpoint."]}
          options={minimumSeverityRef.current.options}
          label={minimumSeverityRef.current.label}
          name={"minimumSeverity"}
          value={minimumSeverityRef.current.value}
          onChange={minimumSeverityRef.current.setValue}
        />
        <div className="flex items-center mb-4">
          <NGLabel>Syslog Endpoint</NGLabel>
        </div>
        <NGFormElement
          as="select"
          options={transportRef.current.options}
          label={transportRef.current.label}
          name={"transport"}
          value={transportRef.current.value}
          onChange={transportRef.current.setValue}
        />
        <NGFormElement
          info={["Configuration for syslog forwarding.", "The hostname to send syslog messages to."]}
          required={hostRef.current.isRequired}
          label={hostRef.current.label}
          name={"host"}
          value={hostRef.current.value}
          onChange={hostRef.current.setValue}
          error={hostRef.current.error}
        />
        <NGFormElement
          required={portRef.current.isRequired}
          label={portRef.current.label}
          name={"port"}
          value={portRef.current.value}
          onChange={portRef.current.setValue}
          error={portRef.current.error}
        />
        <FormButtons
          onReset={handleReset}
          onSave={handleSave}
          resetDisabled={isLoading || !isDirty}
          saveDisabled={isLoading || !isDirty || !isValid}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export const ThreatDetection = observer(ThreatDetectionRaw);
