import { Quota } from "../../schema/types/quota";
import { linksOf } from "../../services/cpln";

export interface QuotaTableItem extends Quota {
  quota: string;
  gvcLink: string;
  quotaRouteLink: string;
}

export const quotaHelpers = {
  getQuota(quota: Quota) {
    return `${quota.current}/${quota.max}`;
  },
  getGvcLink(quota: Quota) {
    return quota.dimensions?.gvcLink || "";
  },
  getQuotaRouteLink(quota: Quota) {
    const selfLink = linksOf(quota).self!;
    return `${selfLink}:s:${quota.name}`;
  },
};
