import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { DetailLayout } from "../../../components/detail/layout";
import { Info } from "./info";
import { Address } from "./address";
import { SetupPaymentMethod } from "./setupPaymentMethod";
import { SetupCreateOrg } from "./setupCreateOrg";
import { Alert } from "./alert";
import { AuditTrail } from "./auditTrail";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { DetailContext } from "../../../components/detail/detailContext";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";

const AccountDetail: React.FC = () => {
  const basePath = useBasePath("/account/:accountUUID/*", true, "billing");
  const {
    AccountName,
    FullName,
    PaymentMethods,
    Roles,
    OrgNames,
    Users,
    isLoading,
    AccountUUID,
    Company,
  } = BillingContext;
  const item = {
    kind: "account" as "account",
    name: AccountName || FullName,
    company: Company,
    selfLink: "",
    version: 0,
    id: AccountUUID,
    roles: Roles,
  };

  const links = [
    { label: "Info", url: "-info" },
    { label: "Address", url: "-address" },
  ];

  if (Roles.includes("billing_admin")) {
    links.push({ label: "Alert", url: `-alert` });
  }

  if (Roles.includes("billing_admin") || Roles.includes("billing_viewer")) {
    links.push({ label: "Audit Trail", url: `-audittrail` });
  }

  let setupView: "none" | "paymentMethod" | "createOrg" = "none";
  if (!BillingContext.isTrialAccount) {
    if (Roles.includes("billing_admin") && PaymentMethods.length < 1) {
      setupView = "paymentMethod";
    } else if (
      (Roles.includes("billing_admin") || Roles.includes("org_creator")) &&
      OrgNames.length < 1 &&
      Users.length < 2
    ) {
      setupView = "createOrg";
    }
  }

  if (setupView !== "none") {
    links.unshift({ label: "Set Up", url: "-setup" });
  }

  if (isLoading) {
    return null;
  }

  return (
    <DetailContext.Provider value={{ item }}>
      <BasePathContext.Provider value={basePath}>
        <DetailLayout links={links} defaultPath={setupView !== "none" ? "-setup" : "-info"}>
          {setupView !== "none" ? (
            <Route
              path={`-setup`}
              element={
                setupView === "paymentMethod" ? (
                  <SetupPaymentMethod />
                ) : setupView === "createOrg" ? (
                  <SetupCreateOrg />
                ) : null
              }
            />
          ) : null}
          <Route path={`-info`} element={<Info />} />
          <Route path={`-address`} element={<Address />} />
          {Roles.includes("billing_admin") ? <Route path={`-alert`} element={<Alert />} /> : null}
          {Roles.includes("billing_admin") || Roles.includes("billing_viewer") ? (
            <Route path={`-audittrail`} element={<AuditTrail />} />
          ) : null}
        </DetailLayout>
      </BasePathContext.Provider>
    </DetailContext.Provider>
  );
};

export default observer(AccountDetail);
