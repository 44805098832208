import * as React from "react";
import { Kind } from "../../../mst/base";
import { homeLink, linksOf, parentLink, request } from "../../../services/cpln";
import { gvcScopedKinds } from "../../../utils/kinds";
import { List } from "../../../schema/types/base";
import { Query } from "../../../schema/types/query";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";

export interface UseTableItemDataOptions {
  fetchOnInit?: boolean;
  fetchAllPages?: boolean;
  query?: Query;
  skipGvcScope?: boolean;
}
export function useTableItemData<T>(kind: Kind, opts: UseTableItemDataOptions) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState<T[]>([]);

  async function fetchItems(automated: boolean = false) {
    try {
      if (!automated) {
        setIsLoading(true);
        setItems([]);
      }

      let url = homeLink(kind);
      if (gvcScopedKinds.includes(kind) && !opts.skipGvcScope) {
        url = parentLink(kind);
      }
      if (opts.query) {
        url = homeLink(kind) + "/-query";
        if (gvcScopedKinds.includes(kind) && !opts.skipGvcScope) {
          opts.query.spec?.terms?.push({ rel: "gvc", op: "=", value: ConsoleContext.gvc! });
        }
      }
      const method = opts.query ? "post" : "get";

      const { data } = await request<List<T>>({ method: method, url: url, body: opts.query });
      let allItems = data.items;
      let nextLink = linksOf(data).next;
      if (opts.fetchAllPages) {
        while (nextLink) {
          const { data } = await request({ url: nextLink });
          nextLink = linksOf(data).next;
          allItems.splice(allItems.length, 0, ...data.items);
        }
      }
      allItems.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (b.name < a.name) return 1;
        return 0;
      });
      setItems(allItems);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      console.error(`Failed to fetch items of ${kind}`, errorMessage);
    }
  }
  React.useEffect(() => {
    if (opts.fetchOnInit) {
      fetchItems();
    }
  }, []);

  const [initialCheck, setInitialCheck] = React.useState(false);
  React.useEffect(() => {
    if (!initialCheck) {
      setInitialCheck(true);
      return;
    }
    fetchItems();
  }, [opts.query]);

  return { isLoading, items, fetchItems };
}
