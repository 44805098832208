import { LoggingCloudWatchMobx, LoggingCloudWatchModelRegionOptions } from "../../../../mst/kinds/org";
import { SelectModel } from "../../../../mobxDataModels/selectModel";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";
import { ListOfItemsModel } from "../../../../mobxDataModels/listOfItemsModel";
import { isEqual } from "lodash";

export function getLogsCloudWatchData(cloudWatch: LoggingCloudWatchMobx) {
  function getDefaultCloudWatchRegion() {
    return cloudWatch.region || LoggingCloudWatchModelRegionOptions[0];
  }
  const cloudWatch_region = SelectModel.create({
    label: "Region",
    initialValue: getDefaultCloudWatchRegion(),
    options: LoggingCloudWatchModelRegionOptions.map((v) => ({ label: v, value: v })),
  });

  function getDefaultCloudWatchCredentials() {
    return ngParseLink(cloudWatch.credentials || "", { kind: "secret" }).name;
  }
  const cloudWatch_credentials = StringModel.create({
    isRequired: true,
    label: "Credentials",
    initialValue: getDefaultCloudWatchCredentials(),
  });

  function getDefaultCloudWatchRetentionDays() {
    return String(cloudWatch.retentionDays || 1);
  }
  const cloudWatch_retentionDays = StringModel.create({
    label: "Retention Days",
    initialValue: getDefaultCloudWatchRetentionDays(),
    isRequired: true,
    validationKey: "cloudWatchRetentionDays",
  });

  function getDefaultCloudWatchGroupName() {
    return cloudWatch.groupName || "";
  }
  const cloudWatch_groupName = StringModel.create({
    label: "Group Name",
    initialValue: getDefaultCloudWatchGroupName(),
    isRequired: true,
  });

  function getDefaultCloudWatchStreamName() {
    return cloudWatch.streamName || "";
  }
  const cloudWatch_streamName = StringModel.create({
    label: "Stream Name",
    initialValue: getDefaultCloudWatchStreamName(),
    isRequired: true,
  });

  function getDefaultCloudWatchExtractFields() {
    return Object.entries(cloudWatch.extractFields || {}).map(([key, value]) => ({
      firstValue: key,
      secondValue: String(value),
    }));
  }
  const cloudWatch_extractFields = ListOfItemsModel.create({ _items: getDefaultCloudWatchExtractFields() });

  function getExtractFieldsAsArray() {
    const extractFields: any = {};
    cloudWatch_extractFields.items.map((i) => {
      extractFields[i.firstValue] = i.secondValue;
    });
    return extractFields;
  }

  function isDirty() {
    let res = false;
    if (getDefaultCloudWatchRegion() !== cloudWatch_region.value) {
      res = true;
    }
    if (getDefaultCloudWatchCredentials() !== cloudWatch_credentials.value) {
      res = true;
    }
    if (getDefaultCloudWatchRetentionDays() !== cloudWatch_retentionDays.value) {
      res = true;
    }
    if (getDefaultCloudWatchGroupName() !== cloudWatch_groupName.value) {
      res = true;
    }
    if (getDefaultCloudWatchStreamName() !== cloudWatch_streamName.value) {
      res = true;
    }
    if (!isEqual(cloudWatch.extractFields, getExtractFieldsAsArray())) {
      res = true;
    }
    return res;
  }

  function isValid() {
    let res = true;
    if (!cloudWatch_credentials.isValid) {
      res = false;
    }
    if (!cloudWatch_retentionDays.isValid) {
      res = false;
    }
    if (!cloudWatch_groupName.isValid) {
      res = false;
    }
    if (!cloudWatch_streamName.isValid) {
      res = false;
    }
    return res;
  }

  function asObject() {
    return {
      cloudWatch: {
        region: cloudWatch_region.value,
        credentials: ngParseLink(cloudWatch_credentials.value, { kind: "secret" }).absolute,
        retentionDays: Number(cloudWatch_retentionDays.value),
        groupName: cloudWatch_groupName.value,
        streamName: cloudWatch_streamName.value,
        extractFields: getExtractFieldsAsArray(),
      },
    };
  }

  return {
    logProvider: "cloudWatch",
    region: cloudWatch_region,
    credentials: cloudWatch_credentials,
    retentionDays: cloudWatch_retentionDays,
    groupName: cloudWatch_groupName,
    streamName: cloudWatch_streamName,
    extractFields: cloudWatch_extractFields,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
