import { Instance, types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { defaultValues } from "../base";
import { InvalidReason } from "./domain.draft";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";

export const DomainPortCorsModel = types
  .model({
    allowOrigins: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    allowMethods: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    allowHeaders: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    exposeHeaders: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    maxAgeInput: types.optional(StringModel, () =>
      StringModel.create({ label: "Max Age", isRequired: true, initialValue: defaultValues.domain.cors.maxAge })
    ),
    allowCredentials: types.optional(types.boolean, defaultValues.domain.cors.allowCredentials),
  })
  .actions((self) => ({
    setMaxAge(value: string) {
      self.maxAgeInput.setInitialValue(value);
    },
  }))
  .views((self) => ({
    get allowOriginsValid() {
      if (self.allowOrigins.items.length < 1) {
        return true;
      }
      return !self.allowOrigins.items.map((i) => i.firstValue).includes("");
    },
    get allowMethodsValid() {
      if (self.allowMethods.items.length < 1) {
        return true;
      }
      return !self.allowMethods.items.map((i) => i.firstValue).includes("");
    },
    get allowHeadersValid() {
      if (self.allowHeaders.items.length < 1) {
        return true;
      }
      return !self.allowHeaders.items.map((i) => i.firstValue).includes("");
    },
    get exposeHeadersValid() {
      if (self.exposeHeaders.items.length < 1) {
        return true;
      }
      return !self.exposeHeaders.items.map((i) => i.firstValue).includes("");
    },
  }))
  .actions((self) => ({
    setAllowCredentials(value: boolean) {
      self.allowCredentials = value;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.maxAgeInput.isValid) {
        res = false;
      }
      return res;
    },
    get invalidReason(): InvalidReason {
      if (!self.maxAgeInput.isValid) {
        return { code: 1, type: "error", message: "Max Age is invalid." };
      }
      return { code: 0, type: "info", message: "" };
    },
    get asObject() {
      return {
        allowOrigins: [...self.allowOrigins.items.map((i) => ({ exact: i.firstValue }))],
        allowMethods: [...self.allowMethods.items.map((i) => i.firstValue)],
        allowHeaders: [...self.allowHeaders.items.map((i) => i.firstValue)],
        exposeHeaders: [...self.exposeHeaders.items.map((i) => i.firstValue)],
        maxAge: self.maxAgeInput.value,
        allowCredentials: self.allowCredentials,
      };
    },
  }));
export interface DomainPortCorsMobx extends Instance<typeof DomainPortCorsModel> {}
export interface DomainPortCors {
  allowOrigins: { exact: string }[];
  allowMethods: string[];
  allowHeaders: string[];
  exposeHeaders: string[];
  maxAge: string;
  allowCredentials: boolean;
}
