import * as React from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { X } from "react-feather";
import clsx from "clsx";

interface WorkloadNavSubLinkProps {
  isActive: boolean;
  url: string;
  title: string;
  showRemove: boolean;
  onRemove: () => void;
}
const WorkloadNavSubLinkRaw: React.FC<WorkloadNavSubLinkProps> = ({ isActive, url, title, showRemove, onRemove }) => {
  if (isActive) {
    return (
      <div className="flex items-center" style={{ width: 193, paddingLeft: 43, marginTop: 10 }}>
        <NavLink
          to={url}
          style={{
            width: 150,
          }}
          className={({ isActive }) =>
            clsx("create-link create-link__inactive focus", { "create-link__active": !!isActive })
          }
        >
          <span className="content">{title}</span>
        </NavLink>
        {showRemove ? (
          <button onClick={onRemove} className={`ngfocus color-danger`}>
            <X className="feather-icon" />
          </button>
        ) : null}
      </div>
    );
  }

  return <div style={{ width: 193, paddingLeft: 43, marginTop: 10, opacity: 0.7 }}>{title}</div>;
};

export const WorkloadNavSubLink = observer(WorkloadNavSubLinkRaw);
