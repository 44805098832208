import { Permissions, Permission } from "../../schema/types/permissions";

export const permissionsHelpers = {
  getImplied(permissions: Permissions, permission: Permission): string[] {
    if (!permissions || !permissions.implied) return [];
    const impliedResult = permissions.implied[permission.name];
    if (!impliedResult) return [];
    if (!Array.isArray(impliedResult)) return [impliedResult];
    return impliedResult;
  },
  getImpliedText(permissions: Permissions, permission: Permission) {
    const implied = this.getImplied(permissions, permission);
    return implied.join(", ");
  },
};
