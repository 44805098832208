import * as React from "react";
import { Alert as AntAlert, Modal } from "antd";
import { observer } from "mobx-react-lite";
import { LoaderSmall } from "../../../../components/layout/loader/small";
import { IdentityDraftNGSHelper } from "../../../../mst/stores/identity.ngs.helper";
import { NoTag } from "../../../../components/tag/noTag";
import { RemovableValue } from "../../../../components/tag/removableValue";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGInputAdapter } from "../../../../newcomponents/input/inputAdapter";
import { NGLabel } from "../../../../newcomponents/text/label";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGKindSelect } from "../../../../newcomponents/select/ngkindselect";

interface Props {
  ngs: IdentityDraftNGSHelper;
}
const NGSSetupModalRaw: React.FC<Props> = ({ ngs }) => {
  const draft = ngs.draft;

  return (
    <Modal
      title={ngs.title}
      open={ngs.isSettingUp}
      closable={false}
      footer={
        <div className="modal-actions">
          <div style={{ flexGrow: 1 }} />
          <NGButton variant="secondary" onClick={() => ngs.cancel()} disabled={ngs.isLoading}>
            Cancel
          </NGButton>
          <NGButton variant="primary" onClick={() => ngs.done()} disabled={!draft.isValid || ngs.isLoading}>
            Done
          </NGButton>
        </div>
      }
    >
      {ngs.isLoading ? (
        <div className="flex items-center justify-center flex-col" style={{ height: 300 }}>
          <LoaderSmall />
          <div className="mt-2">Please wait. Loading.</div>
        </div>
      ) : null}
      {!ngs.isLoading ? (
        <div>
          <NGKindSelect
            className="mb-4"
            kind={"cloudaccount"}
            value={draft.cloudaccountName}
            onChange={draft.setCloudaccountName}
            queries={[{ property: "provider", value: "ngs" }]}
          />
          <div className="mb-4">
            <div className="mb-2">
              <NGLabel>Pub Allow</NGLabel>
            </div>
            {draft.pubAllow.length < 1 ? <NoTag override="Not Set" /> : null}
            {draft.pubAllow.map((p) => (
              <RemovableValue key={p} onRemove={() => draft.removePubAllow(p)} value={p} />
            ))}
            <div className="flex gap-2">
              <NGInput
                size={"small"}
                className="flex-grow"
                value={draft.pubAllowInput.value}
                onChange={(e) => draft.pubAllowInput.setValue(e.target.value)}
              />
              <NGButton variant={"action"} onClick={draft.addPubAllow} disabled={!draft.canAddPubAllow}>
                Add
              </NGButton>
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-2">
              <NGLabel>Pub Deny</NGLabel>
            </div>
            {draft.pubDeny.length < 1 ? <NoTag override="Not Set" /> : null}
            {draft.pubDeny.map((p) => (
              <RemovableValue key={p} onRemove={() => draft.removePubDeny(p)} value={p} />
            ))}
            <div className="flex gap-2">
              <NGInput
                size={"small"}
                className="flex-grow"
                value={draft.pubDenyInput.value}
                onChange={(e) => draft.pubDenyInput.setValue(e.target.value)}
              />
              <NGButton variant={"action"} onClick={draft.addPubDeny} disabled={!draft.canAddPubDeny}>
                Add
              </NGButton>
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-2">
              <NGLabel>Sub Allow</NGLabel>
            </div>
            {draft.subAllow.length < 1 ? <NoTag override="Not Set" /> : null}
            {draft.subAllow.map((p) => (
              <RemovableValue key={p} onRemove={() => draft.removeSubAllow(p)} value={p} />
            ))}
            <div className="flex gap-2">
              <NGInput
                size={"small"}
                className="flex-grow"
                value={draft.subAllowInput.value}
                onChange={(e) => draft.subAllowInput.setValue(e.target.value)}
              />
              <NGButton variant={"action"} onClick={draft.addSubAllow} disabled={!draft.canAddSubAllow}>
                Add
              </NGButton>
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-2">
              <NGLabel>Sub Deny</NGLabel>
            </div>
            {draft.subDeny.length < 1 ? <NoTag override="Not Set" /> : null}
            {draft.subDeny.map((p) => (
              <RemovableValue key={p} onRemove={() => draft.removeSubDeny(p)} value={p} />
            ))}
            <div className="flex gap-2">
              <NGInput
                size={"small"}
                className="flex-grow"
                value={draft.subDenyInput.value}
                onChange={(e) => draft.subDenyInput.setValue(e.target.value)}
              />
              <NGButton variant={"action"} onClick={draft.addSubDeny} disabled={!draft.canAddSubDeny}>
                Add
              </NGButton>
            </div>
          </div>
          <AntAlert className="mb-2" type={"info"} message={"-1 means unlimited for the inputs below"} />
          <div className="mb-4 flex gap-2">
            <div>
              <NGLabel>Resp Max</NGLabel>
              <NGInputAdapter hideLabel className="flex-grow basis-0" data={draft.resp_Max} />
            </div>
            <div>
              <NGLabel>Resp TTL</NGLabel>
              <NGInputAdapter hideLabel className="flex-grow basis-0" data={draft.resp_TTL} />
            </div>
          </div>
          <div className="mb-4 flex gap-2">
            <div>
              <NGLabel>Subs</NGLabel>
              <NGInputAdapter hideLabel className="flex-grow basis-0" data={draft.subs} />
            </div>
            <div>
              <NGLabel>Data (bytes)</NGLabel>
              <NGInputAdapter hideLabel className="flex-grow basis-0" data={draft.data} />
            </div>
            <div>
              <NGLabel>Payload (bytes)</NGLabel>
              <NGInputAdapter hideLabel className="flex-grow basis-0" data={draft.payload} />
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

export const NGSSetupModal = observer(NGSSetupModalRaw);
