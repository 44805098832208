import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";
import { TableColumn } from "../../types";

const suspendedDefault = { id: "suspended", label: "Suspended" };
export function WorkloadSuspendedColumn<TData extends WorkloadTableItem>(
  column: TableColumn<TData> = suspendedDefault,
) {
  const preset: TableColumn<TData> = {
    ...suspendedDefault,
    enableHide: true,
    hiddenByDefault: false,
    enableSort: true,
    size: 130,
    cell: (p) => {
      const workload = p.row.original;

      const text =
        workload.suspended === "notSuspended"
          ? ""
          : workload.suspended === "suspended"
          ? "Suspended"
          : workload.suspended === "partiallySuspended"
          ? "Partially Suspended"
          : "";

      return <span>{text}</span>;
    },
  };
  return Object.assign(preset, column);
}
