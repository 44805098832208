import * as React from "react";
import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { LoaderSmall } from "../../../../components/layout/loader/small";
import { BrowserServiceModal } from "../browserServiceModal";
import { SelectRolesModal } from "../selectRolesModal";
import { IdentityDraftAzureHelper } from "../../../../mst/stores/identity.azure.helper";
import { RemovableValue } from "../../../../components/tag/removableValue";
import { getRoot } from "mobx-state-tree";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGKindSelect } from "../../../../newcomponents/select/ngkindselect";
import NGAlert from "../../../../newcomponents/alert";

interface Props {
  azure: IdentityDraftAzureHelper;
}
const AzureSetupModalRaw: React.FC<Props> = ({ azure }) => {
  const draft = azure.draft;

  async function selectRoles(roleAssignmentIndex: number) {
    const roleAssignment = draft.roleAssignments[roleAssignmentIndex];
    if (!roleAssignment) return;
    if (!roleAssignment.forEdit.scopeIsManual) {
      roleAssignment.forEdit.selectRoles();
      return;
    }
    if (!draft.browserService.canReach) {
      const m = Modal.info({
        title: "Select Roles",
        content: (
          <div>
            <p>Failed to browse the cloud with this cloud account.</p>
          </div>
        ),
        footer: (
          <div className="modal-actions">
            <NGButton variant="secondary" onClick={() => m.destroy()}>
              Close
            </NGButton>
          </div>
        ),
      });
    }

    if (azure.saved.isActive) {
      await roleAssignment.forEdit.reverseLookup(draft.cloudaccountLink);
    }

    if (roleAssignment.forEdit.scopeDataItem) {
      roleAssignment.forEdit.selectRoles();
    } else {
      const m = Modal.info({
        title: "Select Roles",
        content: (
          <div>
            <p>You can't browse roles for a manually typed resource.</p>
          </div>
        ),
        footer: (
          <div className="modal-actions">
            <NGButton variant="primary" onClick={() => m.destroy()}>
              OK
            </NGButton>
          </div>
        ),
      });
    }
  }

  return (
    <Modal
      title={azure.title}
      open={azure.isSettingUp}
      width={azure.modalWidth}
      closable={false}
      footer={
        <div className="modal-actions">
          <NGButton variant="danger" outlined onClick={() => azure.cancel()} disabled={azure.isLoading}>
            Cancel
          </NGButton>
          <div style={{ flexGrow: 1 }} />
          <NGButton
            variant="secondary"
            onClick={() => azure.back()}
            disabled={azure.step === "general" || azure.isLoading}
          >
            Back
          </NGButton>
          {azure.step === "general" ? (
            <NGButton
              variant="primary"
              onClick={() => azure.next()}
              disabled={!draft.isValidPage_General || azure.isLoading}
            >
              Next
            </NGButton>
          ) : (
            <NGButton variant="primary" onClick={() => azure.done()} disabled={!draft.isValid || azure.isLoading}>
              Done
            </NGButton>
          )}
        </div>
      }
    >
      {azure.isLoading ? (
        <div className="flex items-center justify-center flex-col" style={{ height: 300 }}>
          <LoaderSmall />
          <div className="mt-2">Please wait. Loading Azure Scopes.</div>
        </div>
      ) : null}

      {!azure.isLoading && azure.step === "general" ? (
        <NGKindSelect
          className="mb-4"
          kind={"cloudaccount"}
          value={draft.cloudaccountName}
          onChange={draft.setCloudaccountName}
          queries={[{ property: "provider", value: "azure" }]}
        />
      ) : null}

      {!azure.isLoading && azure.step === "config" ? (
        <div style={{ height: 500 }} className="overflow-auto">
          <div className={`mb-4 flex items-center border p-4`}>
            <div className="flex-grow">
              {draft.browserService.canReach ? null : (
                <div>
                  Failed to browse the cloud with this cloud account. You need to type scope and roles manually.
                </div>
              )}
              <div>You can add a role assignment when current role assigments are valid.</div>
            </div>
            <NGButton
              disabled={!draft.canAddRoleAssignment}
              style={{ width: 160, marginLeft: 10 }}
              variant={"action"}
              onClick={() => {
                draft.addRoleAssignment();
              }}
            >
              Add Assignment
            </NGButton>
          </div>
          {draft.roleAssignments.map((roleAssignment, index) => (
            <div key={index}>
              <div className="mt-4 mb-8 p-4 border">
                {roleAssignment.forEdit.scopeInput.value ? (
                  roleAssignment.forEdit.roles.length === 0 ? (
                    <NGAlert className="mb-2" message="At least 1 role is required." type="error" />
                  ) : null
                ) : (
                  <NGAlert className="mb-2" message="Scope selection is required" type="error" />
                )}
                <div className="flex">
                  <NGInput
                    className="flex-grow"
                    value={roleAssignment.forEdit.scopeInput.value}
                    placeholder={roleAssignment.forEdit.scopeInput.label}
                    onChange={(e) => roleAssignment.forEdit.scopeInput.setValue(e.target.value)}
                  />
                  {draft.browserService.canReach ? (
                    <NGButton
                      variant={"primary"}
                      style={{ width: 180, marginLeft: 10 }}
                      onClick={() => {
                        roleAssignment.forEdit.selectScope();
                      }}
                    >
                      Select Scope
                    </NGButton>
                  ) : null}
                </div>
                <div>
                  <div className="flex flex-col mt-4">
                    {roleAssignment.forEdit.roles.length === 0 ? null : <div className="mr-2">Selected Roles</div>}
                    {roleAssignment.forEdit.roles.map((role) => (
                      <RemovableValue
                        value={role}
                        key={role}
                        onRemove={() => {
                          roleAssignment.forEdit.removeRole(role);
                        }}
                      />
                    ))}
                  </div>
                  <div className="flex">
                    <NGInput
                      disabled={!roleAssignment.forEdit.scopeInput.value}
                      className="flex-grow mr-2"
                      value={roleAssignment.forEdit.roleInput.value}
                      placeholder={roleAssignment.forEdit.roleInput.label}
                      onChange={(e) => roleAssignment.forEdit.roleInput.setValue(e.target.value)}
                    />
                    <NGButton
                      disabled={
                        !roleAssignment.forEdit.scopeInput.value ||
                        !roleAssignment.forEdit.roleInput.isValid ||
                        roleAssignment.forEdit.roleInput.value.length < 1
                      }
                      style={{ width: draft.browserService.canReach ? 77 : undefined }}
                      variant={"action"}
                      onClick={() => {
                        roleAssignment.forEdit.addRole();
                      }}
                    >
                      Add
                    </NGButton>
                    {draft.browserService.canReach ? (
                      <NGButton
                        disabled={!roleAssignment.forEdit.scopeInput.value}
                        style={{ width: 180, marginLeft: 10 }}
                        onClick={() => selectRoles(index)}
                        variant={"primary"}
                      >
                        Select Roles
                      </NGButton>
                    ) : null}
                  </div>
                </div>
                {draft.roleAssignments.length > 1 ? (
                  <div className="mt-2 flex justify-end">
                    <NGButton
                      style={{ width: 140 }}
                      variant={"danger"}
                      onClick={() => {
                        draft.removeRoleAssignment(index);
                      }}
                    >
                      Remove
                    </NGButton>
                  </div>
                ) : null}
              </div>
              {roleAssignment.forEdit.isSelectingScope ? (
                <BrowserServiceModal
                  title={"Select Scope"}
                  forAzureScope={{ isActive: true, cloudaccountLink: draft.cloudaccountLink }}
                  restrictToParent={true}
                  onClose={(selections) => {
                    roleAssignment.forEdit.doneSelectScope(selections);
                  }}
                  selectionType={"single"}
                  browserService={(getRoot(draft) as any).browserService}
                />
              ) : null}
              {roleAssignment.forEdit.isSelectingRoles ? (
                roleAssignment.forEdit.scopeDataItem ? (
                  <SelectRolesModal
                    onClose={(selections) => {
                      roleAssignment.forEdit.doneSelectRoles(selections);
                    }}
                    itemHref={roleAssignment.forEdit.scopeDataItem.href}
                    currentSelections={[...roleAssignment.forEdit.roles]}
                  />
                ) : null
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </Modal>
  );
};

export const AzureSetupModal = observer(AzureSetupModalRaw);
