import { types, Instance } from "mobx-state-tree";
import { NameValue } from "../mst/base";
import { EnvVarMobx, EnvVarModel } from "./envVarModel";

export const EnvVarReadonlyModel = types.model({
  name: types.string,
  value: types.string,
});
export interface EnvVarReadonlyMobx extends Instance<typeof EnvVarReadonlyModel> {}

export const EnvVarsModel = types
  .model({
    vars: types.array(EnvVarReadonlyModel),
    editVars: types.array(EnvVarModel),
    index: types.optional(types.number, 0),
  })
  .views((self) => ({
    get addedVars() {
      return self.editVars.filter((e) => e.status === "added");
    },
  }))
  .actions((self) => ({
    getNewIndex() {
      self.index += 1;
      return self.index;
    },
  }))
  .actions((self) => ({
    reset() {
      const secretPrefix = "cpln://secret/";
      const fieldRefPrefix = "cpln://reference/";

      self.editVars.clear();
      for (let _varIndexStr in self.vars) {
        const _varIndex = Number(_varIndexStr);
        const _var = self.vars[_varIndex];
        let valuePrefix = "text";
        let valueBody = _var.value;
        let valueSuffix = "";

        if (valueBody.startsWith(secretPrefix)) {
          valuePrefix = secretPrefix;
          const afterPrefix = valueBody.split(secretPrefix)[1];

          if (afterPrefix.includes(".")) {
            valueBody = afterPrefix.split(".")[0];
            valueSuffix = afterPrefix.split(".").slice(1).join(".");
          } else {
            valueBody = afterPrefix;
          }
        }

        if (valueBody.startsWith(fieldRefPrefix)) {
          valuePrefix = fieldRefPrefix;
          const afterPrefix = valueBody.split(fieldRefPrefix)[1];
          valueBody = afterPrefix;
        }

        self.editVars.push({ index: _varIndex, name: _var.name, valuePrefix, valueBody, valueSuffix });
      }
      self.index = self.editVars.length;
    },
    confirm() {
      let newVars = JSON.parse(JSON.stringify(self.editVars.map((ev) => ({ ...ev, value: ev.value }))));

      self.vars.clear();
      self.editVars.clear();

      for (let indexStr in newVars) {
        const index = Number(indexStr);
        const newVar = newVars[index];
        const newVarReadonly = { index: index, name: newVar.name, value: newVar.value };
        const newVarEdit = {
          index: index,
          name: newVar.name,
          valuePrefix: newVar.valuePrefix,
          valueBody: newVar.valueBody,
          valueSuffix: newVar.valueSuffix,
        };

        self.vars.push(newVarReadonly as any);
        self.editVars.push(newVarEdit as any);
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    new() {
      return EnvVarModel.create({
        index: self.getNewIndex(),
        status: "added",
      });
    },
    addEnvVar(envVar: EnvVarMobx) {
      self.editVars.push(envVar);
    },
    remove(index: number) {
      const toRemove = self.editVars.find((t) => t.index === index);
      if (!toRemove) return;
      self.editVars.remove(toRemove);
    },
    addFromFile(envVars: NameValue[]): string[] {
      const secretPrefix = "cpln://secret/";
      const fieldRefPrefix = "cpln://reference/";

      const overriddenNames: string[] = [];
      for (let envVar of envVars) {
        let valuePrefix = "text";
        let valueBody = envVar.value;
        let valueSuffix = "";

        if (valueBody.startsWith(secretPrefix)) {
          valuePrefix = secretPrefix;
          const afterPrefix = valueBody.split(secretPrefix)[1];

          if (afterPrefix.includes(".")) {
            valueBody = afterPrefix.split(".")[0];
            valueSuffix = afterPrefix.split(".")[1];
          } else {
            valueBody = afterPrefix;
          }
        }

        if (valueBody.startsWith(fieldRefPrefix)) {
          valuePrefix = fieldRefPrefix;
          const afterPrefix = valueBody.split(fieldRefPrefix)[1];
          valueBody = afterPrefix;
        }

        const editVar = self.editVars.find((t) => t.name === envVar.name);
        if (!!editVar) {
          editVar.setValuePrefix(valuePrefix);
          editVar.setValueBody(valueBody);
          editVar.setValueSuffix(valueSuffix);
          overriddenNames.push(envVar.name);
        } else {
          self.editVars.push(
            EnvVarModel.create({
              index: self.editVars.length,
              status: "added",
              name: envVar.name,
              valuePrefix,
              valueBody,
              valueSuffix,
            }),
          );
        }
      }
      return overriddenNames;
    },
  }))
  .views((self) => ({
    get hasVars() {
      return self.editVars.length > 0;
    },
    get asObject() {
      let res: { [_: string]: string } = {};
      for (let _var of self.editVars) {
        res[_var.name] = _var.value;
      }
      return res;
    },
    get asArray() {
      let res: { name: string; value: string }[] = [];
      for (let _var of self.editVars) {
        res.push({ name: _var.name, value: _var.value });
      }
      return res;
    },
    get isDirty() {
      if (self.vars.length !== self.editVars.length) {
        return true;
      }
      let res = false;
      for (let _var of self.vars) {
        if (!self.editVars.find((e) => e.name === _var.name && e.value === _var.value)) {
          res = true;
          break;
        }
      }
      return res;
    },
    get isValid() {
      for (let _var of self.editVars) {
        if (_var.value.includes("cpln://secret/") && _var.value.includes(" ")) {
          return false;
        }
        if (!_var.isValid) {
          return false;
        }
      }

      return true;
    },
  }));

export interface EnvVarsMobx extends Instance<typeof EnvVarsModel> {}
