import * as React from "react";
import { AccessReportMobx, AccessReportModel } from "../../mst/kinds/accessReport";
import { request } from "../../services/cpln";
import { Loader } from "../layout/loader";
import { Link } from "react-router-dom";
import { notification } from "antd";
import {
  Settings as FeSettings,
  User as FeUser,
  Users as FeUsers,
  Tool as FeTool,
  AlertTriangle,
  Shield,
} from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { NGTabs } from "../../newcomponents/tabs";
import { Item } from "react-stately";
import { NGLabelText } from "../../newcomponents/text/labelText";

interface Props {
  itemLink: string;
}
export const AccessReport: React.FC<Props> = ({ itemLink }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [report, setReport] = React.useState<AccessReportMobx>(null as any);
  const [hideBuiltin, setHideBuiltin] = React.useState(true);
  const [hasNoAccess, setHasNoAccess] = React.useState(false);

  React.useEffect(() => {
    request({ url: itemLink + "/-accessreport" })
      .then((res) => {
        setReport(AccessReportModel.create(res.data));
        setIsLoading(false);
      })
      .catch((e) => {
        const isPermissionError = e?.response?.status === 403;
        if (isPermissionError) {
          setHasNoAccess(true);
        } else {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({ message: "Failed", description: errorMessage });
        }
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader reason={"Fetching access report"} />;
  }

  if (hasNoAccess) {
    return (
      <div className="inline-flex items-center border px-8 py-4">
        <AlertTriangle className="mr-1" />
        <span className="text-lg">You don't have permissions to view this report</span>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.625rem" /* 10px */ }}>
      <NGCheckbox onChange={(value) => setHideBuiltin(value)} checked={hideBuiltin}>
        <NGLabelText>Hide Built-in Policies & Principals</NGLabelText>
      </NGCheckbox>
      <NGTabs>
        <Item key="byPrincipal" title="By Principal">
          {hideBuiltin ? (
            report.hasCustomByPrincipal ? null : (
              <div className="flex flex-col items-center border p-4">
                <Shield className="mb-2" />
                <div className="text-center text-lg">No Custom Access</div>
              </div>
            )
          ) : null}
          <div>
            {Object.keys(report.getByPrincipal(hideBuiltin).users).length > 0 ? (
              <div className="mb-6">
                <div className="-mb-4 text-2xl">Users</div>
                {Object.entries(report.getByPrincipal(hideBuiltin).users).map(([principal, data]) => (
                  <div key={principal + data.map((d) => d.granted + d.permission + d.policy).join()}>
                    <Link className={`inline-block mt-4 ngfocus`} to={`/console${principal}`}>
                      {principal.split("/")[4]}
                    </Link>
                    <div
                      className={`flex items-center border py-1 px-4 h-8 table-labels`}
                      style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                    >
                      <div className="w-4/12">Permission</div>
                      <div className="w-4/12">Policy</div>
                      <div className="w-4/12">Granted</div>
                    </div>
                    {data.map((dataItem, dataItemIndex) => {
                      const lastItem = dataItemIndex === data.length - 1;
                      return (
                        <div
                          key={dataItem.granted + dataItem.permission + dataItem.policy}
                          className="flex items-center border border-t-0 py-1 px-4 text-sm"
                          style={lastItem ? { borderBottomLeftRadius: 6, borderBottomRightRadius: 6 } : {}}
                        >
                          <div className="w-4/12">{dataItem.permission}</div>
                          <div className="w-4/12">
                            <Link className={`inline-block ngfocus`} to={`/console${dataItem.policy}`}>
                              {dataItem.policy.split("/")[4]}
                            </Link>
                          </div>
                          <div className="w-4/12">{dataItem.granted}</div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : null}
            {Object.keys(report.getByPrincipal(hideBuiltin).groups).length > 0 ? (
              <div className="mb-6">
                <div className="-mb-4 text-2xl">Groups</div>
                {Object.entries(report.getByPrincipal(hideBuiltin).groups).map(([principal, data]) => (
                  <div key={principal + data.map((d) => d.granted + d.permission + d.policy).join()}>
                    {principal.includes("-allmembers") ? (
                      <div className="mt-4">allmembers</div>
                    ) : (
                      <Link className={`inline-block mt-4 ngfocus color-link`} to={`/console${principal}`}>
                        {principal.split("/")[4]}
                      </Link>
                    )}
                    <div
                      className={`flex items-center border h-8 py-1 px-4 table-labels`}
                      style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                    >
                      <div className="w-4/12">Permission</div>
                      <div className="w-4/12">Policy</div>
                      <div className="w-4/12">Granted</div>
                    </div>
                    {data.map((dataItem, dataItemIndex) => {
                      const lastItem = dataItemIndex === data.length - 1;
                      return (
                        <div
                          key={dataItem.granted + dataItem.permission + dataItem.policy}
                          className="flex items-center border border-t-0 px-4 py-1 text-sm"
                          style={lastItem ? { borderBottomLeftRadius: 6, borderBottomRightRadius: 6 } : {}}
                        >
                          <div className="w-4/12">{dataItem.permission}</div>
                          <div className="w-4/12">
                            <Link className={`inline-block ngfocus color-link`} to={`/console${dataItem.policy}`}>
                              {dataItem.policy.split("/")[4]}
                            </Link>
                          </div>
                          <div className="w-4/12">{dataItem.granted}</div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : null}
            {Object.keys(report.getByPrincipal(hideBuiltin).serviceaccounts).length > 0 ? (
              <div className="mb-6">
                <div className="-mb-4 text-2xl">Service Accounts</div>
                {Object.entries(report.getByPrincipal(hideBuiltin).serviceaccounts).map(([principal, data]) => (
                  <div key={principal + data.map((d) => d.granted + d.permission + d.policy).join()}>
                    <Link className={`inline-block mt-4 ngfocus color-link`} to={`/console${principal}`}>
                      {principal.split("/")[4]}
                    </Link>
                    <div
                      className={`flex items-center border h-8 py-1 px-4 table-labels`}
                      style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                    >
                      <div className="w-4/12">Permission</div>
                      <div className="w-4/12">Policy</div>
                      <div className="w-4/12">Granted</div>
                    </div>
                    {data.map((dataItem, dataItemIndex) => {
                      const lastItem = dataItemIndex === data.length - 1;
                      return (
                        <div
                          key={dataItem.granted + dataItem.permission + dataItem.policy}
                          className="flex items-center border border-t-0 px-4 py-1 text-sm"
                          style={lastItem ? { borderBottomLeftRadius: 6, borderBottomRightRadius: 6 } : {}}
                        >
                          <div className="w-4/12">{dataItem.permission}</div>
                          <div className="w-4/12">
                            <Link className={`inline-block ngfocus color-link`} to={`/console${dataItem.policy}`}>
                              {dataItem.policy.split("/")[4]}
                            </Link>
                          </div>
                          <div className="w-4/12">{dataItem.granted}</div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : null}
            {Object.keys(report.getByPrincipal(hideBuiltin).identities).length > 0 ? (
              <div className="mb-6">
                <div className="-mb-4 text-2xl">Identities</div>
                {Object.entries(report.getByPrincipal(hideBuiltin).identities).map(([principal, data]) => (
                  <div key={principal + data.map((d) => d.granted + d.permission + d.policy).join()}>
                    <Link className={`inline-block mt-4 ngfocus color-link`} to={`/console${principal}`}>
                      {principal.split("/")[6]}
                    </Link>
                    <div
                      className={`flex items-center border h-8 py-1 px-4 table-labels`}
                      style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                    >
                      <div className="w-4/12">Permission</div>
                      <div className="w-4/12">Policy</div>
                      <div className="w-4/12">Granted</div>
                    </div>
                    {data.map((dataItem, dataItemIndex) => {
                      const lastItem = dataItemIndex === data.length - 1;

                      return (
                        <div
                          key={dataItem.granted + dataItem.permission + dataItem.policy}
                          className="flex items-center border border-t-0 px-4 py-1 text-sm"
                          style={lastItem ? { borderBottomLeftRadius: 6, borderBottomRightRadius: 6 } : {}}
                        >
                          <div className="w-4/12">{dataItem.permission}</div>
                          <div className="w-4/12">
                            <Link className={`inline-block ngfocus color-link`} to={`/console${dataItem.policy}`}>
                              {dataItem.policy.split("/")[4]}
                            </Link>
                          </div>
                          <div className="w-4/12">{dataItem.granted}</div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </Item>
        <Item key="byPermission" title="By Permission">
          <div>
            {hideBuiltin ? (
              report.hasCustomByPermission ? null : (
                <div className="flex flex-col items-center border p-4">
                  <Shield className="mb-2" />
                  <div className="text-center text-lg">No Custom Access</div>
                </div>
              )
            ) : null}
            {report.getByPermission(hideBuiltin).map((permission) => (
              <div key={permission.name} className="mb-4">
                <div className="text-2xl">{permission.name}</div>
                <div>
                  <div
                    className={`flex items-center border h-8 p-4 table-labels`}
                    style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                  >
                    <div className="w-4/12">Principal</div>
                    <div className="w-4/12">Policy</div>
                    <div className="w-4/12">Granted</div>
                  </div>
                  {permission.bindings.map((binding, bindingIndex) => {
                    const lastItem = bindingIndex === permission.bindings.length - 1;

                    return (
                      <div
                        // key={binding.grantedPermissions.join() + binding.grantingPolicyLink + binding.principalLink}
                        key={uuidv4()}
                        className="flex items-center border border-t-0 text-sm"
                        style={{
                          padding: "5px 15px",
                          borderBottomLeftRadius: lastItem ? 6 : 0,
                          borderBottomRightRadius: lastItem ? 6 : 0,
                        }}
                      >
                        {binding.principalLink.includes("-allmembers") ? (
                          <div className="w-4/12">allmembers</div>
                        ) : (
                          <div className="w-4/12 flex items-center">
                            {binding.principalLink.length === 7 ? (
                              <FeSettings className="mr-2 w-4 pb-1" />
                            ) : binding.principalLink.split("/")[3] === "user" ? (
                              <FeUser className="mr-2 w-4 pb-1" />
                            ) : binding.principalLink.split("/")[3] === "group" ? (
                              <FeUsers className="mr-2 w-4 pb-1" />
                            ) : (
                              <FeTool className="mr-2 w-4 pb-1" />
                            )}
                            <Link className={`inline-block ngfocus color-link`} to={`/console${binding.principalLink}`}>
                              {binding.principalLink.split("/").length === 7
                                ? binding.principalLink.split("/")[6]
                                : binding.principalLink.split("/")[4]}
                            </Link>
                          </div>
                        )}
                        <div className="w-4/12">
                          <Link
                            className={`inline-block ngfocus color-link`}
                            to={`/console${binding.grantingPolicyLink}`}
                          >
                            {binding.grantingPolicyLink.split("/")[4]}
                          </Link>
                        </div>
                        <div className="w-4/12">{binding.grantedPermissions.join(", ")}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </Item>
      </NGTabs>
    </div>
  );
};
