import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { getAuth } from "firebase/auth";
import { Query } from "../mst/query";
import { Kind } from "../mst/base";
import { isEmpty, isEqual, xorWith } from "lodash";
import { Discovery } from "../mobxStores/discovery/discovery";
import { User } from "../mobxStores/user/user";
import { ConsoleContext } from "../mobxStores/consoleContext/consoleContext";
import { kindMetadata } from "./kindMetadata";
import { UIData } from "../mobxStores/uiData/uiData";
import { MARKETPLACE_SERVICE_URL, REFERRAL_SERVICE_PARTNER_URL, STORAGE_KEY_LOGGED_OUT_STATE } from "../envVariables";
import { PromptContext } from "../mobxStores/prompt/prompt";
import { gvcScopedKinds } from "../utils/kinds";
import { GenericItem } from "../mst/item";
import { TerraformContext } from "../mobxStores/terraform/terraformContext";
import { baseURL } from "./baseUrl";
import { Command } from "../mst/kinds/command";
import { captureExc } from "../errorBoundary";

function getExponentialBackoffDelay(retryCount: number): number {
  const baseDelay = 1000; // Initial delay in ms
  const maxDelay = 9000; // Maximum delay in ms
  const jitter = Math.random() * 500; // Random jitter in ms
  const delay = Math.min(baseDelay * Math.pow(2, retryCount) + jitter, maxDelay);
  return delay;
}

axiosRetry(axios, {
  retries: 3,
  retryCondition: (error: AxiosError) => {
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      [429, 500, 502, 503, 504].includes(error.response?.status ?? 0)
    );
  },
  retryDelay: (retryCount: number) => {
    return getExponentialBackoffDelay(retryCount);
  },
});

export interface TerraformExporterResponse {
  imports: string[];
  resources: string[];
  providerSnippet: string;
}

// TODO use same marks as mobxinput
export interface GenericResponse {
  items: GenericItem[];
  links: Link[];
}

export function getCancelToken() {
  return axios.CancelToken.source();
}

export interface RequestParams {
  service?:
    | "self"
    | "api"
    | "billing"
    | "billing-ng"
    | "metrics"
    | "metering"
    | "console"
    | "registry"
    | "browser"
    | "hub"
    | "audit"
    | "logs"
    | "grafana"
    | "terraform-exporter"
    | "byok"
    | "referral"
    | "marketplace";
  method?: "delete" | "get" | "post" | "put" | "patch";
  url: string;
  body?: any;
  cancelToken?: any;
  timeout?: number;
}

export async function request<T = any>(params: RequestParams): Promise<AxiosResponse<T>> {
  try {
    let token = "";
    if (User.isAdmin) {
      token = User.key;
    } else {
      UIData.updateLastActivityTimestamp();
      token = await getToken();
    }

    return requestCommon<T>({ ...params, token });
  } catch (e) {
    throw e;
  }
}

export async function automatedRequest<T = any>(params: RequestParams): Promise<AxiosResponse<T>> {
  try {
    const { accessToken } = await getLocalToken();
    if (accessToken === "pass") {
      throw new Error("Could not access local token");
    }
    const token = accessToken;

    return requestCommon<T>({ ...params, token });
  } catch (e) {
    throw e;
  }
}

async function requestCommon<T = any>({
  service = "api",
  url,
  method = "get",
  timeout,
  cancelToken,
  body,
  token,
}: RequestParams & { token: string }): Promise<AxiosResponse<T>> {
  let axiosBaseURL = baseURL;
  if (Discovery) {
    axiosBaseURL = service === "self" ? "" : service === "api" ? baseURL : Discovery.endpoints[service];
    if (["referral", "marketplace"].includes(service)) {
      switch (service) {
        case "referral":
          axiosBaseURL = REFERRAL_SERVICE_PARTNER_URL;
          break;
        case "marketplace":
          axiosBaseURL = MARKETPLACE_SERVICE_URL;
          break;
      }
    }
  }

  const cfg: AxiosRequestConfig = {
    maxContentLength: 128 * 1024,
    url: `${axiosBaseURL}${url}`,
    method,
    timeout,
  };
  if (User.isLoggedIn) {
    cfg.headers = { Authorization: `Bearer ${token}` };
  }
  if (body) {
    cfg.data = body;
  }
  if (cancelToken) {
    cfg.cancelToken = cancelToken;
  }
  return await axios(cfg);
}

export async function getTokenExpired() {
  const VALID_THRESHOLD_SEC = 10; // prod value
  // const VALID_THRESHOLD_SEC = 59 * 60 + 30; // test value
  try {
    const { expirationTime } = await getLocalToken();
    const currentTimeInMS = new Date().getTime();
    const remaining_MS = expirationTime - currentTimeInMS;
    const remaining_SEC = Math.round(remaining_MS / 1000);
    // const diff = remainingSec - VALID_THRESHOLD_SEC;
    // console.debug("periodic valid check | diff", diff, " | remainingSec | ", remainingSec);

    return remaining_SEC <= VALID_THRESHOLD_SEC;
  } catch (e) {
    // console.debug("periodic valid check FAILED | ", e.message);
    return true;
  }
}

export async function isTokenInLast5Mins() {
  try {
    const { accessToken, expirationTime } = await getLocalToken();
    if (accessToken === "pass") {
      return false;
    }
    const remaining_MS = expirationTime - new Date().getTime();
    const remaining_SEC = Math.round(remaining_MS / 1000);
    const _FIVE_MIN_AS_SEC = 5 * 60;
    return remaining_SEC < _FIVE_MIN_AS_SEC;
  } catch (e) {
    return false;
  }
}

export async function getTerraformSupportedKinds(): Promise<void> {
  try {
    let { data } = await request({ service: "terraform-exporter", url: "/supportedKinds" });
    TerraformContext.setSupportedKinds(data);
  } catch (e) {
    console.error(e);
  }
}

interface LinkContext {
  org: string | null;
  gvc: string | null;
}

// relative to org
export function homeLink(kind: Kind, ctx?: LinkContext) {
  if (kind === "org") {
    return "/org";
  }
  let res = resourceLink("org", undefined, ctx);
  return res + "/" + kind;
}

// can be relative to gvc
export function parentLink(kind: Kind, ctx?: LinkContext) {
  let link = resourceLink(kind, "item", ctx);
  let i = link.lastIndexOf("/");
  return link.substring(0, i);
}

export function resourceLink(kind: Kind, ref: string | undefined, ctx?: LinkContext) {
  let orgLink;

  const contextOrg = ctx?.org || ConsoleContext.org;
  const contextGvc = ctx?.gvc || ConsoleContext.gvc;

  if (contextOrg) {
    orgLink = "/org/" + contextOrg;
  }

  if (kind === "org") {
    if (ref) {
      return "/org/" + ref;
    } else if (contextOrg) {
      return "/org/" + contextOrg;
    } else {
      console.error(
        "Throwing from resourceLink with context - 1",
        JSON.stringify({ org: contextOrg, gvc: contextGvc }),
      );
      throw new Error("context-org");
    }
  }

  if (!orgLink) {
    console.error("Throwing from resourceLink with context - 2", JSON.stringify({ org: contextOrg, gvc: contextGvc }));
    throw new Error("context-org");
  }

  let gvcLink;

  if (kind === "gvc") {
    if (ref) {
      return orgLink + "/gvc/" + ref;
    } else if (contextGvc) {
      return orgLink + "/gvc/" + contextGvc;
    } else {
      console.error(
        "Throwing from resourceLink with context - 3",
        JSON.stringify({ org: contextOrg, gvc: contextGvc }),
      );
      throw new Error("context-gvc");
    }
  }

  if (!contextGvc) {
    gvcLink = undefined;
  } else {
    gvcLink = orgLink + "/gvc/" + contextGvc;
  }

  if (!ref) {
    console.error("Throwing from resourceLink with context - 4", JSON.stringify({ org: contextOrg, gvc: contextGvc }));
    throw new Error("context-ref");
  }

  // Move gvc scoped resources to a const and use it everywhere
  if (gvcScopedKinds.includes(kind)) {
    if (!gvcLink) {
      console.error(
        "Throwing from resourceLink with context - 5",
        JSON.stringify({ org: contextOrg, gvc: contextGvc }),
      );
      throw new Error("context-gvc");
    } else {
      return gvcLink + "/" + kind + "/" + ref;
    }
  }

  return orgLink + "/" + kind + "/" + ref;
}

export function resourceLinkWithCtx(kind: Kind, ref: string | undefined, context: { org: string; gvc?: string }) {
  let orgLink;

  if (context.org) {
    orgLink = "/org/" + context.org;
  }

  if (kind === "org") {
    if (ref) {
      return "/org/" + ref;
    } else if (context.org) {
      return "/org/" + context.org;
    } else {
      console.error("Throwing from resourceLink with context", JSON.stringify(context));
      throw new Error("context-org");
    }
  }

  if (!orgLink) {
    console.error("Throwing from resourceLink with context", JSON.stringify(context));
    throw new Error("context-org");
  }

  let gvcLink;

  if (kind === "gvc") {
    if (ref) {
      return orgLink + "/gvc/" + ref;
    } else if (context.gvc) {
      return orgLink + "/gvc/" + context.gvc;
    } else {
      console.error("Throwing from resourceLink with context", JSON.stringify(context));
      throw new Error("context-gvc");
    }
  }

  if (!context.gvc) {
    gvcLink = undefined;
  } else {
    gvcLink = orgLink + "/gvc/" + context.gvc;
  }

  if (!ref) {
    console.error("Throwing from resourceLink with context", JSON.stringify(context));
    throw new Error("context-ref");
  }

  // Move gvc scoped resources to a const and use it everywhere
  if (gvcScopedKinds.includes(kind)) {
    if (!gvcLink) {
      console.error("Throwing from resourceLink with context", JSON.stringify(context));
      throw new Error("context-gvc");
    } else {
      return gvcLink + "/" + kind + "/" + ref;
    }
  }

  return orgLink + "/" + kind + "/" + ref;
}

export function indefiniteArticleOfKind(kind: string) {
  switch (kind) {
    case "account":
    case "accessreport":
    case "agent":
    case "auditctx":
    case "event":
    case "identity":
    case "image":
    case "org":
      return "an";
    case "command":
    case "cloudaccount":
    case "deployment":
    case "domain":
    case "group":
    case "gvc":
    case "location":
    case "memcachecluster":
    case "mk8s":
    case "permissions":
    case "policy":
    case "quota":
    case "secret":
    case "serviceaccount":
    case "spicedbcluster":
    case "task":
    case "user":
    case "volumeset":
    case "workload":
      return "a";
    default:
      return `a`;
  }
}

export function formatGroupHeader(value: string) {
  switch (value) {
    case "aws":
      return "AWS";
    case "azure-sdk":
      return "Azure SDK";
    case "azure-connector":
      return "Azure Connector";
    case "ecr":
      return "ECR";
    case "gcp":
      return "GCP";
    case "ngs":
      return "NGS";
    case "tls":
      return "TLS";
    default:
      return nameOfKind(value);
  }
}

export function nameOfCloudaccountProvider(value: string) {
  switch (value) {
    case "aws":
      return "AWS";
    case "azure":
      return "Azure";
    case "gcp":
      return "GCP";
    case "ngs":
      return "NGS";
    default:
      return value;
  }
}

export function nameOfKind(kind: string) {
  const name = (kindMetadata as any)[kind]?.name;
  return name || kind.slice(0, 1).toUpperCase() + kind.slice(1);
}

export function pluralNameOfKind(kind: string) {
  // TODO fix
  if (kind === "repository") {
    return "Images";
  }

  const pluralName = (kindMetadata as any)[kind]?.pluralName;
  return pluralName || `${kind.slice(0, 1).toUpperCase()}${kind.slice(1)}s`;
}

export const validate = {
  since: (value: string) => {
    if (value.length === 0) return true;
    const allowedChars = ["s", "m", "w", "h"];
    const charCounts = { s: 0, m: 0, w: 0, h: 0 };
    // check if letters are allowed
    for (let char of value) {
      if (!Number.isNaN(Number(char))) {
        continue;
      }
      if (!allowedChars.includes(char)) {
        return "Since (Use numbers and `s`, `m`, `w`, `h` letters only)";
      }
      // @ts-ignore
      charCounts[char] += 1;
    }
    if (charCounts.s > 1 || charCounts.m > 1 || charCounts.w > 1 || charCounts.h > 1) {
      return "Since (`s`, `m`, `w`, `h` letters can be used once)";
    }
    return true;
  },
};

export const arraysAreEqualLodash = (x: any, y: any) => isEmpty(xorWith(x, y, isEqual));

export function arraysAreEqual<T>(first: T[], second: T[]): boolean {
  if (first.length !== second.length) return false;
  for (let firstItem of first) {
    if (!second.includes(firstItem)) return false;
  }
  for (let secondItem of second) {
    if (!first.includes(secondItem)) return false;
  }
  return true;
}

export function arraysAreEqualInOrder<T>(first: T[], second: T[]): boolean {
  if (first.length !== second.length) return false;
  for (let index in first) {
    const itemFirst = first[index];
    const itemSecond = second[index];
    if (typeof itemFirst === "string") {
      if (itemFirst !== itemSecond) return false;
    }
    if (typeof itemFirst === "object") {
      if (!isEqual(itemFirst, itemSecond)) return false;
    }
  }
  return true;
}

export interface Link {
  rel: string;
  href: string;
}
export function getName(link: string): string | undefined {
  return link.split("/").pop();
}

export function linksOf(linked?: {
  links?: Link[];
}): {
  self?: string;
  org?: string;
  gvc?: string;
  workload?: string;
  target?: string;
  next?: string;
  queryresult?: string;
  deployment?: string;
  reveal?: string;
  install?: string;
  uninstall?: string;
  [_: string]: string | undefined;
} {
  let res: any = {};
  for (const link of linked?.links || []) {
    res[link.rel] = link.href;
  }
  return res;
}

export async function evaluateQuery(kind: Kind, query: Query) {
  try {
    const { data } = await request({ method: "post", url: `${homeLink(kind)}/-query`, body: query });
    return data.items;
  } catch (e) {
    console.error("evaluate query failed", e.message);
    return [];
  }
}

// let testTick = 0;

export async function doPeriodicTokenExpirationCheck(orgTimeoutSec: number | null) {
  // test code
  // const now = new Date().getTime();
  // if (testTick === 0) {
  //   testTick = now;
  //   return;
  // }
  // if (now - testTick > 5000) {
  //   console.debug("signing out");
  //   navigationStoreInstance.disablePrompt();
  //   // main.signOut();
  //   setTimeout(() => {
  //     main.signOut();
  //   }, 0);
  // }

  // prod code
  const isConnectOpen = window.location.hash.includes("socket=opened");
  if (isConnectOpen) {
    const user = getAuth(User.getFirebaseApp()).currentUser;
    if (user) {
      await user.getIdToken(true);
    }
    return;
  }

  const defaultTimeoutSec = 15 * 60; // 15 MIN as SECONDS
  const timeoutSec = orgTimeoutSec || defaultTimeoutSec;

  const idleTimeSec = Math.round((new Date().getTime() - UIData.lastActivityTimestamp) / 1000);
  const timedout = timeoutSec < idleTimeSec;

  const isTokenExpired = await getTokenExpired();

  if (isTokenExpired && !timedout) {
    const user = getAuth(User.getFirebaseApp()).currentUser;
    if (user) {
      await user.getIdToken(true);
    }
    return;
  }

  if (timedout) {
    PromptContext.setIsDisabled(true);
    setTimeout(() => {
      localStorage.setItem(STORAGE_KEY_LOGGED_OUT_STATE, "true");
      User.signOut(true);
    }, 0);
  }
}

export async function getLocalToken(): Promise<{ accessToken: string; expirationTime: number }> {
  if (User.isAdmin) {
    return { accessToken: User.key, expirationTime: new Date().getTime() * 2 };
  }
  const res = await getLocalTokenIndexedDB();
  if (res.accessToken === "pass") {
    return getLocalTokenStorage();
  }
  return res;
}

export async function getLocalTokenIndexedDB(): Promise<{ accessToken: string; expirationTime: number }> {
  return new Promise((resolve) => {
    const conn = window.indexedDB.open("firebaseLocalStorageDb");
    conn.onsuccess = function () {
      const db = conn.result;
      const objectStoreName = db.objectStoreNames[0];
      var tx = db.transaction([objectStoreName], "readonly");
      tx.onerror = function () {
        resolve({ accessToken: "pass", expirationTime: new Date().getTime() * 2 });
      };
      var objectStore = tx.objectStore(objectStoreName);

      const getMainAppRequest = objectStore.get(`firebase:authUser:${Discovery.firebase.apiKey}:main`);
      getMainAppRequest.onsuccess = function () {
        try {
          const { accessToken, expirationTime } = getMainAppRequest.result.value.stsTokenManager;
          resolve({ accessToken, expirationTime });
        } catch (_) {
          resolve({ accessToken: "pass", expirationTime: new Date().getTime() * 2 });
        }
      };
      getMainAppRequest.onerror = function () {
        resolve({ accessToken: "pass", expirationTime: new Date().getTime() * 2 });
      };
    };

    conn.onerror = function () {
      resolve({ accessToken: "pass", expirationTime: new Date().getTime() * 2 });
    };
  });
}

export function getLocalTokenStorage(): { accessToken: string; expirationTime: number } {
  try {
    const key = `firebase:authUser:${Discovery.firebase.apiKey}:main`;
    const valueStr = window.localStorage.getItem(key) as string;
    const value = JSON.parse(valueStr);
    const { accessToken, expirationTime } = (value as any).stsTokenManager;
    return { accessToken, expirationTime };
  } catch (e) {
    return { accessToken: "pass", expirationTime: new Date().getTime() * 2 };
  }
}

export async function getToken() {
  if (User.isAdmin) {
    return User.key;
  }
  const shouldRefresh = await isTokenInLast5Mins();
  const user = getAuth(User.getFirebaseApp()).currentUser;
  let firebaseToken = "";
  if (user) {
    firebaseToken = await user.getIdToken(shouldRefresh);
  }
  return firebaseToken;
}

export async function forceRefreshToken() {
  const user = getAuth(User.getFirebaseApp()).currentUser;
  if (user) {
    await user.getIdToken(true);
  }
}

export function nameOfSecretType(type: string) {
  switch (type) {
    case "aws":
      return "AWS";
    case "azure-sdk":
      return "Azure SDK";
    case "azure-connector":
      return "Azure Connector";
    case "docker":
      return "Docker";
    case "dictionary":
      return "Dictionary";
    case "ecr":
      return "ECR";
    case "gcp":
      return "GCP";
    case "keypair":
      return "Keypair";
    case "nats-account":
      return "Nats Account";
    case "opaque":
      return "Opaque";
    case "tls":
      return "TLS";
    case "userpass":
      return "Userpass";
    default:
      return type;
  }
}

export async function fetchPages(list: GenericResponse): Promise<void> {
  let nextLink: string | undefined = linksOf(list).next;

  while (true) {
    if (!nextLink) {
      break;
    }

    // Fetch next page
    const { data } = await request<GenericResponse>({ url: nextLink });

    // Add new items to the list
    list.items.splice(list.items.length, 0, ...data.items);

    // Attempt to get next link
    nextLink = linksOf(data).next;

    // Keep track of the new links
    list.links = data.links;
  }
}

export async function listAndDelete(kind: Kind, ctx: LinkContext) {
  const listLink: string = parentLink(kind, ctx);

  // Request kind list
  const { data } = await request<GenericResponse>({ url: listLink });

  // Handle next if exists
  await fetchPages(data);

  // Prepare delete promises
  const promises = data.items.map((item: any) => {
    // Resolve self link
    const selfLink: string = resourceLink(kind, item.name, ctx);

    // Return delete promise
    return request({ method: "delete", url: selfLink });
  });

  await Promise.allSettled(promises);
}

export function falseOnly(value?: boolean): boolean | undefined {
  if (value === false) {
    return false;
  }

  return undefined;
}

export async function fetchCommandsOf(selfLink?: string): Promise<Command[]> {
  let _commands: Command[] = [];
  if (!selfLink) {
    return _commands;
  }

  try {
    let _nextLink: undefined | string = `${selfLink}/-command`;
    while (!!_nextLink) {
      const { data } = await request({ url: _nextLink });
      _commands = _commands.concat(data.items);
      _nextLink = linksOf(data).next;
    }
  } catch (e) {
    _commands = [];
    captureExc(e);
  }

  return _commands;
}

export const CPLN_HELM_RELEASE_PREFIX = "cpln-release-";
