import * as React from "react";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { NGFormData } from "../../mobxStores/ngFormData";

interface Props extends React.PropsWithChildren {
  data: NGFormData;
}

export const NGForm: React.FC<Props> = ({ data, children }) => {
  // TODO create id and prefix for each form with the data mobx instance

  return (
    <NGFormContext.Provider value={data}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {children}
      </form>
    </NGFormContext.Provider>
  );
};
