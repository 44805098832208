import { observer } from "mobx-react-lite";
import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { DryRunAlert } from "../dryRunAlert";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsNvidiaRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <div>
      <div className="mb-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.nvidia"}
          paths={["spec.addOns.nvidia.taintGPUNodes"]}
        />
      </div>
      <div className="mb-2">Nvidia</div>
      <NGSwitch
        value={mk8sDraft.addon_nvidia.taintGPUNodes}
        onChange={(value) => mk8sDraft.addon_nvidia.setTaintGPUNodes(value)}
      >
        <NGLabelText>Taint GPU Nodes</NGLabelText>
      </NGSwitch>
    </div>
  );
};

export const AddonsNvidia = observer(AddonsNvidiaRaw);
