import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { Table } from "../../newcomponents/table/table";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { NameDescriptionColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { Quota } from "../../schema/types/quota";
import { quotaHelpers } from "../../mst/kinds/quota.helpers";

export interface QuotaTableItem extends Quota {
  quota: string;
  idName: string;
  gvcLink: string;
  quotaRouteLink: string;
}

const QuotaListRaw: React.FC = () => {
  const { kind, id } = useTableKindId("quota", "quota-list");
  const qData = useTableItemQueryData<Quota>(kind, { fetchAllPages: true });

  const quotaTableItems: QuotaTableItem[] = qData.visibleItems.map((item) => {
    const idName = item.id + ":s:" + item.name;
    const quota = quotaHelpers.getQuota(item);
    const gvcLink = quotaHelpers.getGvcLink(item);
    const quotaRouteLink = quotaHelpers.getQuotaRouteLink(item);

    return { ...item, idName: idName, quota: quota, gvcLink: gvcLink, quotaRouteLink: quotaRouteLink };
  });

  return (
    <Table<QuotaTableItem>
      title={"Quotas"}
      data={quotaTableItems}
      tableId={id}
      headerRenderer={(table) => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionColumn(undefined, { linkProperty: "idName" }),
        { id: "current", label: "Current", size: 200 },
        { id: "max", label: "Max", size: 200 },
        {
          id: "gvcLink",
          label: "GVC",
          enableResize: true,
          canGroupByDistinctValues: true,
          groupLabelMap: { "": "Org Level Quotas" },
          enableSort: true,
        },
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};

export const QuotaList = observer(QuotaListRaw);
