import * as React from "react";
import clsx from "clsx";
import "./index.scss";

interface IProps {
  variant?: "success" | "warning" | "error" | "info";
  style?: React.CSSProperties | undefined;
  size?: "normal" | "small";
  label?: string;
  className?: string | undefined;
}

export const NGChip: React.FC<IProps> = ({ variant, size = "normal", label, style, className }) => {
  return (
    <span className={clsx("ngchip", variant, size, className)} style={style}>
      {label}
    </span>
  );
};
