import * as React from "react";
import { ContainerSpecMobx, WorkloadMobx } from "../../mst/kinds/workload";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { tagLinkUrlPrefixes, updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { FormButtons } from "../../components/forms/formButtons";
import { DEPLOYMENT_UPDATE_INTERVAL_MS } from "../../envVariables";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { DomainStatusEndpoint } from "../../mst/kinds/domain";
import { DomainContext } from "../../mobxStores/kinds/domainContext";
import { Info as FeatherInfo, Activity } from "react-feather";
import { useDetailContext } from "../../components/detail/detailContext";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import NGAlert from "../../newcomponents/alert";
import { NGButton } from "../../newcomponents/button/Button";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { NGLabel } from "../../newcomponents/text/label";
import { NGSwitch } from "../../newcomponents/switch";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { Tooltip } from "../../components/Tooltip";
import { InfoTooltip } from "../../components/InfoTooltip";
import { ImageIcon } from "../../components/Icons";
import { Table } from "../../newcomponents/table/table";
import { ExternalLink } from "../../newcomponents/table/components/ExternalLink";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { workloadHelpers } from "../../mst/kinds/workload.helpers";
import { Deployment } from "../../schema/types/workload/deployment";

interface DomainEndpoint extends DomainStatusEndpoint {
  domainName: string;
}
interface Endpoint {
  label: string;
  url: string;
  internal: boolean;
}

interface Props {
  workload: WorkloadMobx;
  deployments: Deployment[];
  workloadDraft: WorkloadDraftMobx;
  hasDedicatedLB: boolean;
}
const InfoRaw: React.FC<Props> = ({ workload, deployments, workloadDraft, hasDedicatedLB }) => {
  const { fetchItem } = useDetailContext();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { org, gvc } = ConsoleContext;

  const [isLoading, setIsLoading] = React.useState(false);
  const [domainEndpoints, setDomainEndpoints] = React.useState<DomainEndpoint[]>([]);

  const description = workloadDraft.description;

  React.useEffect(() => {
    PromptContext.setWhen(workloadDraft.isDirty || isLoading);
  }, [workloadDraft.isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    workloadDraft.reset();
    workload.updateStatus(false);

    let intervalId: any = null;
    intervalId = setInterval(async () => {
      workload.updateStatus(true);
    }, DEPLOYMENT_UPDATE_INTERVAL_MS);

    return () => {
      workloadDraft.reset();
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, []);

  React.useEffect(() => {
    if (DomainContext.isLoading) {
      return;
    }
    const _domains = DomainContext.items.filter((d) =>
      d.status.endpoints.some((e) => e.workloadLink === workload.selfLink),
    );
    const _endpoints: DomainEndpoint[] = [];
    for (let d of _domains) {
      for (let e of d.status.endpoints) {
        if (e.workloadLink === workload.selfLink) {
          _endpoints.push({ ...e, domainName: d.name });
        }
      }
    }
    setDomainEndpoints(_endpoints);
  }, [DomainContext.items.length, DomainContext.isLoading]);

  function reset() {
    workloadDraft.generalReset();
  }

  async function save() {
    try {
      setIsLoading(true);
      await workload.patch(workloadDraft.asPatch);
      workloadDraft.confirm();
      notification.success({
        message: "Success",
        description: "Updated workload",
      });
      updateLastDeploymentTimeOnHubspot();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  function handleCopyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  let showCanonical = !!workload.status.canonicalEndpoint;
  if (workload.spec.type === "serverless" && hasDedicatedLB) {
    showCanonical = false;
  }
  let showGlobal = !!workload.status.endpoint;
  if (
    workload.status.endpoint === workload.status.canonicalEndpoint &&
    !(workload.spec.type === "serverless" && hasDedicatedLB)
  ) {
    showGlobal = false;
  }

  const endpoints: Endpoint[] = [];
  if (!!workload.status.internalName) {
    endpoints.push({ url: workload.status.internalName, label: "Internal Name", internal: true });
  }
  if (!!workload.status.endpoint) {
    endpoints.push({ url: workload.status.endpoint, label: "GVC Domain Endpoint", internal: false });
  }
  if (!!workload.status.canonicalEndpoint) {
    endpoints.push({ url: workload.status.canonicalEndpoint, label: "Canonical Endpoint (Global)", internal: false });
  }
  for (const domainEndpoint of domainEndpoints) {
    endpoints.push({ url: domainEndpoint.url, label: domainEndpoint.domainName, internal: false });
  }
  if (workload.status.loadBalancer.length > 1) {
    // for (const indexStr in workload.status.loadBalancer || []) {
    //   const index = Number(indexStr);
    //   if (index === 0) {
    //     continue;
    //   }
    //   const loadBalancerEndpoint = workload.status.loadBalancer[index];
    //   endpoints.push({ url: loadBalancerEndpoint.url, label: loadBalancerEndpoint.origin, internal: false });
    // }
    for (const lbEndpoint of workload.status.loadBalancer || []) {
      endpoints.push({ url: lbEndpoint.url, label: lbEndpoint.origin, internal: false });
    }
  }

  const health = workloadHelpers.getHealth(deployments, workload as any);

  return (
    <>
      {workload.status.resolvedImages?.errorMessages && workload.status.resolvedImages?.errorMessages.length > 0 ? (
        <div className="flex items-center gap-4 mb-4">
          <NGAlert type={"error"} message={workload.status.resolvedImages?.errorMessages[0]} />
          <NGButton
            variant={"secondary"}
            onClick={() => navigate(`/console/org/${org}/gvc/${gvc}/workload/${workload.name}/container`)}
          >
            View Containers
          </NGButton>
        </div>
      ) : null}
      {(workloadDraft.isServerless || workloadDraft.isStandard) &&
      !workloadDraft.firewall.external_allInboundAllowed &&
      !pathname.includes("/-firewall") ? (
        <div className="flex items-center gap-4 mb-4">
          <NGAlert type={"info"} message={`Internet access to this workload is restricted.`} />
          <NGButton
            variant={"secondary"}
            onClick={() => navigate(`/console/org/${org}/gvc/${gvc}/workload/${workload.name}/-firewall`)}
          >
            View Firewall
          </NGButton>
        </div>
      ) : null}
      <NGFormElement
        label={"Description"}
        // TODO try sluggifying label to use as name
        name={"description"}
        value={description.value}
        onChange={description.setValue}
      />
      <Table<ContainerSpecMobx>
        styles={{ header: { marginBottom: 0 } }}
        classNames={{ container: "mb-4" }}
        data={workload.spec.containers}
        hideSettings
        tableId={"workload-info-containers"}
        columns={[
          {
            id: "name-image",
            label: "Container Name & Image",
            size: 500,
            enableResize: true,
            cell: ({ row }) => {
              const container = row.original;

              return (
                <div>
                  <div className="truncate">
                    <Link
                      className={`ngfocus color-link`}
                      to={`/console${workload.selfLink}/container/${container.name}`}
                    >
                      {container.name}
                    </Link>
                  </div>

                  <div className="flex items-center gap-1">
                    {container.image.startsWith(`/org/${org}/image/`) ? (
                      <>
                        <img
                          className="object-contain"
                          style={{ width: 16, height: 16 }}
                          src={`/resources/logos/controlPlaneLogoOnly.svg`}
                        />
                        <Link className={`truncate ngfocus color-link`} to={`/console${container.image}`}>
                          {container.image.replace(`/org/${org}/image/`, "")}
                        </Link>
                      </>
                    ) : (
                      <>
                        <ImageIcon className="container-image-icon" style={{ width: 16, height: 16 }} />
                        <div className="truncate">{container.image}</div>
                      </>
                    )}
                  </div>
                </div>
              );
            },
          },
          { id: "cpu", label: workloadDraft.capacityAI ? "Max CPU" : "Reserved CPU" },
          { id: "memory", label: workloadDraft.capacityAI ? "Max Memory" : "Reserved Memory" },
          {
            id: "container-ports",
            label: "Ports",
            cell: ({ row }) => {
              const container = row.original;
              return (
                <div>
                  {workloadDraft.isServerless
                    ? container.port
                    : container.ports.length > 0
                    ? container.ports[0]
                      ? `${container.ports[0].protocol.toUpperCase()} ${container.ports[0].number}`
                      : ""
                    : ""}
                </div>
              );
            },
          },
        ]}
      />
      <div className="mb-4 flex items-center">
        <div className="w-4/12">
          <NGLabel>Workload Health</NGLabel>
          <div>
            <Activity
              className={`inline-block feather-icon mr-1 ${health.isReady ? "color-action" : "color-danger"}`}
              style={{ transform: "translateY(2px)" }}
            />
            <span>{health.text}</span>
            {health.isReady && !health.isLatestReady ? (
              <Tooltip title={["Not all locations are at latest version."]}>
                <FeatherInfo className={`inline-block feather-icon ml-1`} style={{ transform: "translateY(2px)" }} />
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div className="w-4/12">
          <NGLabel>Type</NGLabel>
          <span>
            {workload.spec.type.slice(0, 1).toUpperCase()}
            {workload.spec.type.slice(1)}
          </span>
        </div>
      </div>

      <div className={`mb-4 flex items-center`}>
        <NGSwitch
          value={workloadDraft.supportDynamicTags}
          onChange={(value: boolean) => workloadDraft.setSupportDynamicTags(value)}
        >
          <NGLabelText>Support Dynamic Tags</NGLabelText>
        </NGSwitch>
        <InfoTooltip title="If this is enabled, Control Plane will automatically redeploy your workload when one of the container images is updated in the container registry." />
      </div>

      {workload.spec.type === "cron" ? null : (
        <Table<Endpoint>
          title={() => <NGLabel>Endpoints</NGLabel>}
          data={endpoints}
          tableId={"workload-info-endpoints"}
          classNames={{ container: "mb-4" }}
          hideSettings
          columns={[
            {
              id: "label",
              label: "Origin",
            },
            {
              id: "url",
              label: "URL",
              cell: ({ getValue }) => {
                const value = getValue();

                if (value.startsWith("http") && value.includes("://")) {
                  return <ExternalLink label={value} url={value} />;
                }

                return <div>{value}</div>;
              },
            },
          ]}
          styles={{ header: { marginBottom: 0 } }}
        />
      )}

      <TagLinksTable
        tableId="workload-info-tag-links"
        tags={workloadDraft.tags.editTags.filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />

      <FormButtons
        onReset={reset}
        onSave={save}
        saveDisabled={!workloadDraft.isDirty || isLoading}
        resetDisabled={!workloadDraft.isDirty || isLoading}
        loading={isLoading}
      />
    </>
  );
};

export const Info = observer(InfoRaw);
