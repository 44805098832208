import * as React from "react";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "./envVariables";
import { observer } from "mobx-react-lite";
import { User } from "./mobxStores/user/user";
import { Navigate } from "react-router-dom";
import { SetupParsedLocation } from "./mobxStores/parsedLocation/setupParsedLocation";

interface Props extends React.PropsWithChildren {
  title: string;
}
const PrivateRouteRaw: React.FC<Props> = ({ children, title = "Control Plane" }) => {
  if (!User.isLoggedIn) {
    return <Navigate to={"/signin"} />;
  }

  return (
    <>
      <SetupParsedLocation />
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {title} - From many clouds, one™
        </title>
      </Helmet>
      {children}
    </>
  );
};

export const PrivateRoute = observer(PrivateRouteRaw);
