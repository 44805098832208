import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { AxiosResponse } from "axios";
import { arraysAreEqual, linksOf, request } from "../../services/cpln";
import { Table } from "../../newcomponents/table/table";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../newcomponents/table/components/TableCplnQuery";
import { TableModal } from "../../components/antd/TableModal";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { sortBy } from "lodash";
import { PlusCircle } from "react-feather";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { User } from "../../schema/types/user";
import { GroupMobx } from "../../mst/kinds/group";
import { UserNameDescriptionNoLinkColumn } from "../../newcomponents/table/columns/wellKnown/userNameDescriptionNoLinkColumn";

interface UserTableItem extends User {
  selfLink: string;
}
interface Props {
  group: GroupMobx;
}

const GroupUsersRaw: React.FC<Props> = ({ group }) => {
  const { kind, id } = useTableKindId("user", "group-user-add");
  const qData = useTableItemQueryData<User>(kind, { fetchAllPages: true });

  const { fetchItem } = useDetailContext();

  const [isInited, setIsInited] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  function getDefaultLinks(): string[] {
    return [...group.memberLinks.filter((l) => l.includes("/user/"))];
  }
  function getDefaultCurrentItems(): UserTableItem[] {
    return getDefaultLinks().map((link) => ({
      id: "",
      name: "",
      kind: "user",
      version: 0,
      tags: {},
      created: "",
      lastModified: "",
      links: [{ rel: "self", href: link }],
      email: ngParseLink(link, { useInputCtx: true }).name,
      selfLink: link,
    }));
  }
  const [currentItems, setCurrentItems] = React.useState<UserTableItem[]>(getDefaultCurrentItems());
  function getCurrentLinks(): string[] {
    return currentItems.map((i) => i.selfLink);
  }

  React.useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    try {
      setIsLoading(true);
      const promises: Promise<AxiosResponse<User>>[] = [];
      for (const link of getDefaultLinks()) {
        promises.push(request({ url: link }));
      }
      const responses = await Promise.allSettled(promises);
      setCurrentItems((currentItems) => {
        const newItems: UserTableItem[] = [];

        for (const responseIndexStr in responses) {
          const responseIndex = Number(responseIndexStr);
          const response = responses[responseIndex];

          if (response.status === "rejected") {
            const currentItem = currentItems[responseIndex];
            newItems.push(currentItem);
            continue;
          }

          const newItem = { ...response.value.data, selfLink: linksOf(response.value.data).self! };
          newItems.push(newItem);
        }

        return newItems;
      });
      setIsLoading(false);
      setIsInited(true);
    } catch (e) {
      setIsLoading(false);
    }
  }

  const [selectionsToRemove, setSelectionsToRemove] = React.useState<string[]>([]);
  const [selectionsToAdd, setSelectionsToAdd] = React.useState<string[]>([]);
  const [selectionItemsToAdd, setSelectionItemsToAdd] = React.useState<UserTableItem[]>([]);
  const [isAddingItem, setIsAddingItem] = React.useState(false);

  const isDirty = React.useMemo(() => {
    return !arraysAreEqual(getCurrentLinks(), getDefaultLinks());
  }, [currentItems.length, group.memberLinks.length]);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty);
  }, [isDirty]);
  useCleanPrompt();

  async function handleSave() {
    try {
      setIsLoading(true);
      const allMemberLinks = [...(group.memberLinks || [])];
      const noUserMembers = allMemberLinks.filter((link) => !link.includes("/user/"));
      const body: any = { memberLinks: [...noUserMembers, ...getCurrentLinks()] };
      await request({ method: "patch", url: group.selfLink, body: body });
      if (fetchItem) {
        fetchItem();
      }
      notification.success({
        message: "Success",
        description: "Updated Group Members",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  async function handleReset() {
    fetchUsers();
    setSelectionsToRemove([]);
    setSelectionsToAdd([]);
    setSelectionItemsToAdd([]);
  }

  function onRemove() {
    setCurrentItems((items) => items.filter((item) => !selectionsToRemove.includes(item.selfLink)));
    setSelectionsToRemove([]);
  }

  function onOkAddModal() {
    setCurrentItems((items) => {
      const newItems: UserTableItem[] = [...items];
      const currentLinks = items.map((i) => i.selfLink);

      for (const itemToAdd of selectionItemsToAdd) {
        if (currentLinks.includes(itemToAdd.selfLink)) {
          continue;
        }

        newItems.push(itemToAdd);
      }

      return sortBy(newItems, "email");
    });

    setSelectionsToAdd([]);
    setSelectionItemsToAdd([]);
  }

  const userTableItems: UserTableItem[] = qData.visibleItems
    .map((item) => ({
      ...item,
      selfLink: linksOf(item).self!,
    }))
    .filter((item) => !getCurrentLinks().includes(item.selfLink));

  return (
    <>
      <Table<UserTableItem>
        tableId={"group-users"}
        title={"Users"}
        data={currentItems}
        selectMode={"multi"}
        selectKey="selfLink"
        selections={selectionsToRemove}
        onSelectionsChange={setSelectionsToRemove}
        columns={[
          UserNameDescriptionNoLinkColumn(), //
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
        headerRenderer={() => (
          <>
            <NGButton
              variant={"danger"}
              size={"small"}
              outlined
              disabled={selectionsToRemove.length < 1}
              onClick={onRemove}
            >
              Remove
            </NGButton>
            <NGButton variant={"action"} size={"small"} onClick={() => setIsAddingItem(true)}>
              Add
            </NGButton>
          </>
        )}
        noItemsFoundRenderer={(table) => (
          <NGButton
            variant="primary"
            onClick={() => setIsAddingItem(true)}
            renderIcon={(_, props) => <PlusCircle {...props} />}
          >
            Add Users
          </NGButton>
        )}
      />
      <FormButtons
        loading={isLoading}
        resetDisabled={!isInited || isLoading || !isDirty}
        saveDisabled={!isInited || isLoading || !isDirty}
        onReset={handleReset}
        onSave={handleSave}
      />
      <TableModal
        open={isAddingItem}
        onCancel={() => setIsAddingItem(false)}
        onOk={() => {
          onOkAddModal();
          setIsAddingItem(false);
        }}
      >
        <Table<UserTableItem>
          tableId={id}
          title={"Select Users to Add"}
          selectMode="multi"
          selectKey="selfLink"
          selections={selectionsToAdd}
          onSelectionsChange={(keys, items) => {
            setSelectionsToAdd(keys);
            setSelectionItemsToAdd(items!);
          }}
          data={userTableItems}
          headerRenderer={() => {
            return (
              <>
                <TableHeaderRefreshButton onClick={qData.fetchItems} />
                <CustomTableCPLNQuery
                  tableId={id}
                  kind={kind}
                  onQuery={qData.setQuery}
                  isLoading={qData.isLoadingQueried}
                />
              </>
            );
          }}
          enableFilter
          isLoading={qData.isLoading}
          columns={[
            UserNameDescriptionNoLinkColumn(), //
            TagsColumn(),
            CreatedColumn(),
            LastModifiedColumn(),
          ]}
        />
      </TableModal>
    </>
  );
};

export const GroupUsers = observer(GroupUsersRaw);
