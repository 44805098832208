import * as React from "react";
import { observer } from "mobx-react-lite";
import { Theme } from "../../../mobxStores/uiData/theme";
import { newDomainColors } from "./colors";
import { NGButton } from "../../../newcomponents/button/Button";

interface Props extends React.PropsWithChildren {
  sub?: boolean;
  onEdit?: any;
}
const DomainCreateNewSectionLabelRaw: React.FC<Props> = ({ children, sub = false, onEdit }) => {
  const { theme } = Theme;
  const isDark = theme === "dark";
  const background = isDark ? newDomainColors.darkBlue : "#ccc";

  return (
    <div
      className={`mb-4 -mx-4 px-4 ${sub ? "text-base" : "text-lg"} font-bold uppercase`}
      style={{ background, height: 48 }}
    >
      <div className="flex items-center gap-4 w-full h-full">
        <div className="uppercase">{children}</div>
        {onEdit ? (
          <NGButton size={"small"} variant="primary" onClick={onEdit}>
            Edit
          </NGButton>
        ) : null}
      </div>
    </div>
  );
};

export const DomainCreateNewSectionLabel = observer(DomainCreateNewSectionLabelRaw);
