import * as React from "react";
import { useParams } from "react-router-dom";
import { WorkloadDetail } from "./detail";
import { WorkloadMobx, WorkloadModel } from "../../mst/kinds/workload";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { fetchCommandsOf, getToken, linksOf, request, resourceLink } from "../../services/cpln";
import { WorkloadDraftManagerModel } from "../../mst/stores/workload.draft.manager";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import {
  DEPLOYMENT_UPDATE_INTERVAL_MS,
  IS_DEPLOYMENT_ENV_PROD,
  IS_DEPLOYMENT_ENV_TEST,
  STORAGE_KEY_LOG_TABLE_LABELS,
  STORAGE_KEY_LOG_TABLE_TIMESTAMP,
  STORAGE_KEY_LOG_TABLE_WRAPPED_LINES,
} from "../../envVariables";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { OrgMobx, OrgModel } from "../../mst/kinds/org";
import { CommandsContext } from "../../components/detail/commandsContext";
import { Command } from "../../mst/kinds/command";
import { Workload } from "../../schema/types/workload/workload";
import { IdentityMobx, IdentityModel } from "../../mst/kinds/identity";
import { Gvc } from "../../schema/types/gvc";
import { Deployment } from "../../schema/types/workload/deployment";
import { DeploymentsReducerActionType, useDeploymentsReducer } from "../../components/detail/deploymentsReducer";
import { captureExc } from "../../errorBoundary";

type RouteParams = "workload";
const WorkloadDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { gvc } = ConsoleContext;
  const { workload: workloadName } = useParams<RouteParams>();
  const [workload, setWorkload] = React.useState<Workload>(null as any);
  const [workloadMobx, setWorkloadMobx] = React.useState<WorkloadMobx>(null as any);

  const { state, fetchDeployments, dispatch } = useDeploymentsReducer();

  const [org, setOrg] = React.useState<OrgMobx>(null as any);
  const [hasDedicatedLB, setHasDedicatedLB] = React.useState<boolean>(false);
  const [gvcEnv, setGVCEnv] = React.useState<{ name: string; value: string }[]>([]);
  const [gvcLocationNames, setGVCLocationNames] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);
  const [identityMobx, setIdentityMobx] = React.useState<IdentityMobx | null>(null);

  const websocketRef = React.useRef<WebSocket>(null as any);
  const [websocketIsActive, setWebsocketIsActive] = React.useState(false);
  const websocketIsActiveRef = React.useRef<boolean>(false);
  React.useEffect(() => {
    websocketIsActiveRef.current = websocketIsActive;
  }, [websocketIsActive]);

  const [commands, setCommands] = React.useState<Command[]>([]);
  const [commandTriggerer, setCommandTriggerer] = React.useState(0);
  React.useEffect(() => {
    fetchCommands();
    let timeoutId = setTimeout(() => setCommandTriggerer((x) => x + 1), 10000);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [workload, commandTriggerer]);

  async function fetchCommands() {
    const selfLink = linksOf(workload).self;
    const _commands = await fetchCommandsOf(selfLink);
    setCommands(_commands);
  }

  const draftManagerRef = React.useRef(WorkloadDraftManagerModel.create());
  const draftManager = draftManagerRef.current;

  React.useEffect(() => {
    // initialize log table timestamp and labels preferences if doesnt exist as true
    if (!window.localStorage.getItem(STORAGE_KEY_LOG_TABLE_TIMESTAMP)) {
      window.localStorage.setItem(STORAGE_KEY_LOG_TABLE_TIMESTAMP, "true");
    }
    if (!window.localStorage.getItem(STORAGE_KEY_LOG_TABLE_LABELS)) {
      window.localStorage.setItem(STORAGE_KEY_LOG_TABLE_LABELS, "true");
    }
    if (!window.localStorage.getItem(STORAGE_KEY_LOG_TABLE_WRAPPED_LINES)) {
      window.localStorage.setItem(STORAGE_KEY_LOG_TABLE_WRAPPED_LINES, "true");
    }
  }, []);

  React.useEffect(() => {
    fetchItem();
    fetchOrg();
  }, [workloadName]);

  const [websocketTriggerer, setWebsocketTriggerer] = React.useState(0);
  React.useEffect(() => {
    let dispose: any;
    if (workloadMobx) {
      setWebsocketTriggerer((x) => x + 1);
      dispose = fetchDeployments([workloadMobx.selfLink], DEPLOYMENT_UPDATE_INTERVAL_MS);
    }
    return () => {
      if (dispose) {
        dispose();
      }
    };
  }, [workloadMobx]);
  // Deployments websocket for mobx
  React.useEffect(() => {
    if (workloadMobx) {
      initWebsocket();
    }

    let timeoutId = setTimeout(() => {
      setWebsocketTriggerer((x) => x + 1);
    }, 25 * 60 * 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, [websocketTriggerer]);

  // for mobx
  async function initWebsocket() {
    try {
      const url = `wss://workload-status.${IS_DEPLOYMENT_ENV_PROD ? "" : "test."}cpln.io:443/register`;
      const token = await getToken();
      let ws = new WebSocket(url);
      websocketRef.current = ws;

      ws.addEventListener("open", () => {
        ws.send(
          JSON.stringify({
            token: token,
            interests: [
              {
                org: ConsoleContext.org!,
                gvc: ConsoleContext.gvc!,
                workload: workloadName,
              },
            ],
          }),
        );
        setWebsocketIsActive(true);
      });

      ws.addEventListener("message", (event) => {
        workloadMobx.updateVersion();
        const data = JSON.parse(event.data.toString());

        let deployment: Deployment = {} as any;
        try {
          if (!!data.eventType) {
            deployment = data.data.deployment;
          } else {
            deployment = data;
          }
          dispatch({
            type: DeploymentsReducerActionType.UPDATE_DEPLOYMENT,
            payload: { link: workloadMobx.selfLink, deployments: [deployment] },
          });
        } catch (e) {
          console.error("caught in ws listener", e.message, "deployment", deployment);
        }
      });

      ws.addEventListener("error", (event) => {
        console.error("Deployment WS Errored");
      });
      ws.addEventListener("close", (event) => {
        setWebsocketIsActive(true);
      });
    } catch (e) {
      console.error("Deployment WS Init Failed", e.message);
    }
  }

  React.useEffect(() => {
    fetchIdentity();
  }, [draftManager.draft.edit!?.identityLink]);

  async function fetchIdentity() {
    if (draftManager.draft.edit!?.identityLink) {
      try {
        const identityLink = draftManager.draft.edit!.identityLink;
        const identityRes = await request({ url: identityLink });
        setIdentityMobx(IdentityModel.create(identityRes.data));
      } catch (e) {}
      return;
    }
  }

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data: workloadRes } = await request({ url: resourceLink("workload", workloadName) });
      setWorkload(workloadRes);
      const workloadInstance = WorkloadModel.create(workloadRes);
      setWorkloadMobx(workloadInstance);
      const { data: gvcRes } = await request<Gvc>({ url: resourceLink("gvc", gvc!) });
      setHasDedicatedLB(gvcRes.spec?.loadBalancer?.dedicated || false);
      setGVCEnv(gvcRes.spec?.env || []);

      let _gvcLocationNames: string[] = [];
      _gvcLocationNames = gvcRes.spec?.staticPlacement?.locationLinks?.map((x) => x.split("/")[4]) || [];

      const _queryUrl = linksOf(gvcRes).queryresult;
      if (_queryUrl) {
        const { data: queryData } = await request({ url: _queryUrl });
        _gvcLocationNames = _gvcLocationNames.concat(queryData.items.map((l: any) => l.name));
      }

      setGVCLocationNames(_gvcLocationNames);
      await draftManager.startDraftDetail(workloadInstance);
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  async function fetchOrg() {
    try {
      const { data } = await request({ url: resourceLink("org", ConsoleContext.org!) });
      setOrg(OrgModel.create(data));
    } catch (e) {}
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching workload to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"workload"} canCreate={true} />;
  }

  if (!draftManager.draft.edit) return null;

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {workload.name} - Workload - From many clouds, one™
        </title>
      </Helmet>
      <NGFormContext.Provider value={formDataRef.current}>
        <CommandsContext.Provider value={{ commands, fetchCommands }}>
          <DetailContext.Provider value={{ item: workloadMobx, fetchItem }}>
            <WorkloadDetail
              org={org}
              identityMobx={identityMobx}
              workloadMobx={workloadMobx}
              workloadDraft={draftManager.draft.edit}
              deployments={state.deploymentsMap[workloadMobx.selfLink]}
              gvcLocationNames={gvcLocationNames}
              gvcEnv={gvcEnv}
              hasDedicatedLB={hasDedicatedLB}
              startDraft={draftManager.startDraftDetail}
            />
          </DetailContext.Provider>
        </CommandsContext.Provider>
      </NGFormContext.Provider>
    </>
  );
};

export const WorkloadDetailRoute = observer(WorkloadDetailRouteRaw);
