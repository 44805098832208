import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftMobx } from "../../../mst/stores/identity.draft";
import { IdentityManageNetworkResources } from "./manageNetworkResources";
import { homeLink, request } from "../../../services/cpln";
import { Loader } from "../../../components/layout/loader";
import { RequirementToCreate } from "../../../components/generic/requirementToCreate";

interface Props {
  identity: IdentityDraftMobx;
}

const IdentityCreateNetworkResourcesRaw: React.FC<Props> = ({ identity }) => {
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [agentExists, setAgentExists] = React.useState(false);

  React.useEffect(() => {
    request({ url: homeLink("agent") })
      .then(({ data }) => {
        if (data.items.length > 0) {
          setAgentExists(true);
        } else {
          setAgentExists(false);
        }
      })
      .catch(() => {
        setAgentExists(false);
      })
      .finally(() => {
        setIsPageReady(true);
      });
  }, []);

  if (!isPageReady) {
    return <Loader reason={"Checking agents"} />;
  }

  if (!agentExists) {
    return <RequirementToCreate label={"network resource for an identity"} requiredKind={"agent"} />;
  }

  return <IdentityManageNetworkResources identity={identity} />;
};

export const IdentityCreateNetworkResources = observer(IdentityCreateNetworkResourcesRaw);
