import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { DryRunAlert } from "../../../dryRunAlert";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import NGAlert from "../../../../../newcomponents/alert";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderEphemeralRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const e = mk8sDraft.provider_ephemeral!;
  const formData = useNGFormContext();

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"ephemeral.location"}
            as={"select"}
            label={e.location.label}
            value={e.location.value}
            options={e.location.options}
            onChange={e.location.setValue}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("ephemeral.location").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={e.location.setValue}
              path={"spec.provider.ephemeral.location"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const Mk8sCreateProviderEphemeral = observer(Mk8sCreateProviderEphemeralRaw);
