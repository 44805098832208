import moment from "moment-timezone";
import { makeAutoObservable } from "mobx";

class TimezoneMobx {
  STORAGE_KEY: string = "timezone";
  DEFAULT_VALUE: string = moment.tz.guess();
  value: string = this.DEFAULT_VALUE;
  override: string | null = null;
  options: string[] = moment.tz.names();

  constructor() {
    makeAutoObservable(this);
    let localValue = localStorage.getItem(this.STORAGE_KEY);
    if (
      !localValue ||
      !this.options.includes(localValue) ||
      (localValue !== "Etc/UTC" && localValue !== this.DEFAULT_VALUE)
    ) {
      localStorage.setItem(this.STORAGE_KEY, this.DEFAULT_VALUE);
      localValue = this.DEFAULT_VALUE;
    }

    this.value = localValue;
    moment.tz.setDefault(this.value);
  }

  setValue(value: string) {
    moment.tz.setDefault(value);
    this.value = value || this.DEFAULT_VALUE;
    localStorage.setItem(this.STORAGE_KEY, this.value);
  }

  resetValue() {
    this.setValue(this.DEFAULT_VALUE);
  }

  setOverride(value: string) {
    moment.tz.setDefault(value);
    this.override = value;
  }

  clearOverride() {
    moment.tz.setDefault(this.value);
    this.override = null;
  }

  get isLocal() {
    return this.value === this.DEFAULT_VALUE;
  }

  get isUTC() {
    return this.value === "Etc/UTC";
  }

  get label() {
    return this.isLocal ? "Local" : "UTC";
  }
}

export const Timezone = new TimezoneMobx();
