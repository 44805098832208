import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { Select } from "../../../components/inputs/select";
import { MobxInput } from "../../../components/inputs/mobxInput";
import { firewallRequirementsForVolumes } from "../../../mst/kinds/workload";
import { FormLabel } from "../../../components/forms/formLabel";
import { Value } from "../../../components/tag/value";
import { Download } from "react-feather";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
// calls ihazip
import * as axios from "axios";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";
import NGAlert from "../../../newcomponents/alert";
import { WorkloadCreateFirewallWorkload } from "./firewall-workload";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateFirewallRaw: React.FC<Props> = ({ workloadDraft }) => {
  const firewall = workloadDraft.firewall;
  const [myIP, setMyIP] = React.useState<string>(null as any);

  const firewallRecommendations: string[] = [];

  for (const container of [...workloadDraft.allContainers, workloadDraft.newContainer]) {
    if (workloadDraft.firewall.external_allOutboundAllowed) {
      continue;
    }
    for (const volume of container.volumes.asArray) {
      for (const prefix of Object.keys(firewallRequirementsForVolumes)) {
        if (volume.uri.startsWith(prefix)) {
          // @ts-ignore
          for (const recommendation of firewallRequirementsForVolumes[prefix]) {
            if (firewallRecommendations.includes(recommendation)) {
              continue;
            }
            if (workloadDraft.firewall.external_outboundAllowHostname.includes(recommendation)) {
              continue;
            }
            firewallRecommendations.push(recommendation);
          }
          continue;
        }
      }
    }
  }

  React.useEffect(() => {
    populateIp();
  }, []);

  async function populateIp() {
    let ip = "";
    try {
      const { data: ipRes } = await axios.default.get("https://icanhazip.com/");
      ip = ipRes.trim();
      setMyIP(ip);
    } catch (e) {
      // notification.warning({ message: "Failed", description: "Failed to get your IP address." });
      return;
    }
  }

  async function toggleMyIp(): Promise<void> {
    if (!myIP) {
      return;
    }

    if (workloadDraft.firewall.external_allInboundAllowed) {
      return;
    }

    if (workloadDraft.firewall.external_inboundAllowCIDR.includes(myIP)) {
      workloadDraft.firewall.external_removeInboundAllowCIDR(myIP);
    } else {
      workloadDraft.firewall.external_inboundAllowCIDRInput.setValue(myIP);
      workloadDraft.firewall.external_addInboundAllowCIDR();
    }
  }

  function onUploadInboundCIDRAllowList(e: any) {
    const input: HTMLInputElement = e.target;
    if (!input.value || !input.files || input.files.length < 1) {
      input.value = "";
      return;
    }
    const file = input.files[0];
    if (!file) {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
      return;
    }
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async function (evt) {
      try {
        let content: string = evt.target?.result as string;
        if (content.includes("rtf")) {
          // TODO
          notification.warning({ message: "RTF files are not supported" });
        } else {
          content = content.replaceAll("\n", " ");
          content = content.replaceAll("\r", " ");
        }
        const lines = content.split(" ");
        for (let line of lines) {
          firewall.external_inboundAllowCIDRInput.setValue(line);
          if (firewall.external_inboundAllowCIDRInput.isValid) {
            firewall.external_addInboundAllowCIDR();
          }
          firewall.external_inboundAllowCIDRInput.reset();
        }
      } catch (e) {
        notification.warning({
          message: "Failed",
          description: "File is invalid",
        });
        input.value = "";
      }
    };
    reader.onerror = function () {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
    };
    input.value = "";
  }

  function onUploadOutboundCIDRAllowList(e: any) {
    const input: HTMLInputElement = e.target;
    if (!input.value || !input.files || input.files.length < 1) {
      input.value = "";
      return;
    }
    const file = input.files[0];
    if (!file) {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
      return;
    }
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async function (evt) {
      try {
        let content: string = evt.target?.result as string;
        if (content.includes("rtf")) {
          // TODO
          notification.warning({ message: "RTF files are not supported" });
        } else {
          content = content.replaceAll("\n", " ");
          content = content.replaceAll("\r", " ");
        }
        const lines = content.split(" ");
        for (let line of lines) {
          firewall.external_outboundAllowCIDRInput.setValue(line);
          if (firewall.external_outboundAllowCIDRInput.isValid) {
            firewall.external_addOutboundAllowCIDR();
          }
          firewall.external_outboundAllowCIDRInput.reset();
        }
      } catch (e) {
        notification.warning({
          message: "Failed",
          description: "File is invalid",
        });
        input.value = "";
      }
    };
    reader.onerror = function () {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
    };
    input.value = "";
  }

  return (
    <>
      <div className="mb-4 flex items-center">
        <FormLabel size={"large"}>External</FormLabel>
        <InfoTooltip title="The external firewall is used to control access to and from the public internet." />
      </div>
      {workloadDraft.isCron ? null : (
        <>
          <div className={`${!firewall.external_allInboundAllowed ? "mb-2" : ""} flex items-center`}>
            <NGSwitch
              data-testid="switch-all-inbound-allowed"
              value={firewall.external_allInboundAllowed}
              onChange={firewall.external_setAllInboundAllowed}
            >
              <NGLabelText>Allow All Inbound Requests</NGLabelText>
            </NGSwitch>
            <InfoTooltip title={`Sets CIDR to '0.0.0.0/0'`} />
          </div>
          {!firewall.external_allInboundAllowed && (
            <>
              <div className={`flex items-center gap-2 ${firewall.external_inboundAllowCIDR.length > 0 && "mb-2"}`}>
                <FormLabel>Inbound CIDR Allow List</FormLabel>
                <label
                  tabIndex={0}
                  className="cursor-pointer text-sm color-link ngfocus"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("envFile")?.click();
                    }
                  }}
                >
                  Import <Download className="feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
                  <input
                    className="invisible"
                    style={{ width: 0 }}
                    onChange={onUploadInboundCIDRAllowList}
                    type={"file"}
                    multiple={false}
                  />
                </label>
                {!myIP || workloadDraft.firewall.external_allInboundAllowed ? null : (
                  <NGButton size={"small"} variant={"secondary"} onClick={toggleMyIp}>
                    {workloadDraft.firewall.external_inboundAllowCIDR.includes(myIP) ? "Remove " : "Add "}My IP
                  </NGButton>
                )}
              </div>
              <div className="flex flex-wrap mb-1">
                {firewall.external_inboundAllowCIDR.map((ip) => (
                  <div style={{ width: 450 }} key={ip} className="flex items-center mb-1 mr-4">
                    <Value className="flex-grow" style={{ margin: 0 }} value={ip} />
                    <NGButton
                      className="ml-2"
                      size={"small"}
                      variant={"danger"}
                      onClick={() => firewall.external_removeInboundAllowCIDR(ip)}
                    >
                      X
                    </NGButton>
                  </div>
                ))}
              </div>
              <div className="flex mb-4">
                <div className="flex">
                  <MobxInput
                    dataTestId={"inbound-cidr"}
                    style={{ marginRight: 5, width: 450 }}
                    className="flex-grow"
                    data={firewall.external_inboundAllowCIDRInput}
                    hideErrorMessage
                  />
                  <NGButton
                    style={{ marginLeft: 5, width: 73 }}
                    onClick={firewall.external_addInboundAllowCIDR}
                    disabled={
                      firewall.external_inboundAllowCIDRInput.value.length < 1 ||
                      !firewall.external_inboundAllowCIDRInput.isValid
                    }
                    variant={"action"}
                  >
                    Add
                  </NGButton>
                </div>
                <InfoTooltip
                  title={[
                    `The list of ipv4/ipv6 addresses or cidr blocks that are allowed to access this workload.`,
                    `No external access is allowed by default.`,
                    `Specify '0.0.0.0/0' to allow access to the public internet.`,
                  ]}
                  style={{ marginTop: 15 }}
                />
              </div>
            </>
          )}
        </>
      )}
      <div className={`flex items-center mb-2 ${firewall.external_allInboundAllowed && "mt-4"}`}>
        <NGSwitch
          data-testid="switch-all-outbound-allowed"
          value={firewall.external_allOutboundAllowed}
          onChange={firewall.external_setAllOutboundAllowed}
        >
          <NGLabelText>Allow All Outbound Requests</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={`Sets CIDR to '0.0.0.0/0'`} />
      </div>
      {!firewall.external_allOutboundAllowed && (
        <>
          <div className={`${firewall.external_outboundAllowHostname.length > 0 ? "mb-2" : ""}`}>
            <FormLabel>Outbound Hostname Allow List</FormLabel>
          </div>
          {firewallRecommendations.length > 0 ? (
            <NGAlert
              className="my-4"
              render={() => (
                <div>
                  <div className="mb-2">Allow hostname list below in external firewall to make volumes work.</div>
                  <ul className="flex flex-col gap-1 pl-4">
                    {firewallRecommendations.map((r) => (
                      <li className="list-disc">{r}</li>
                    ))}
                  </ul>
                  <NGButton
                    className="mt-4"
                    size={"small"}
                    variant={"primary"}
                    onClick={() => {
                      for (const recommendation of firewallRecommendations) {
                        workloadDraft.firewall.external_outboundAllowHostnameInput.setValue(recommendation);
                        workloadDraft.firewall.external_addOutboundAllowHostname();
                      }
                      workloadDraft.firewall.external_outboundAllowHostnameInput.setValue("");
                    }}
                  >
                    Fix
                  </NGButton>
                </div>
              )}
              type={"warning"}
            />
          ) : null}
          <div className="flex flex-wrap mb-1">
            {firewall.external_outboundAllowHostname.map((hostname) => (
              <div style={{ width: 450 }} key={hostname} className="flex items-center mb-1 mr-4">
                <Value className="flex-grow" style={{ margin: 0 }} value={hostname} />
                <NGButton
                  className="ml-2"
                  size={"small"}
                  variant={"danger"}
                  onClick={() => firewall.external_removeOutboundAllowHostname(hostname)}
                >
                  X
                </NGButton>
              </div>
            ))}
          </div>
          <div className="flex mb-4">
            <div className="flex">
              <MobxInput
                dataTestId={"outbound-hostname"}
                style={{ marginRight: 5, width: 450 }}
                className="flex-grow"
                data={firewall.external_outboundAllowHostnameInput}
                hideErrorMessage
              />
              <NGButton
                style={{ marginLeft: 5, width: 73 }}
                onClick={firewall.external_addOutboundAllowHostname}
                disabled={
                  firewall.external_outboundAllowHostnameInput.value.length < 1 ||
                  !firewall.external_outboundAllowHostnameInput.isValid
                }
                variant={"action"}
              >
                Add
              </NGButton>
            </div>
            <InfoTooltip
              title={[
                `The list of public hostnames that this workload is allowed to reach.`,
                `No outbound access is allowed by default.`,
                `Use 'outboundAllowCIDR' to allow access to all external websites (not recommended).`,
              ]}
              style={{ marginTop: 15 }}
            />
          </div>
          <div className={`flex items-center gap-2 ${firewall.external_outboundAllowCIDR.length > 0 ? "mb-2" : ""}`}>
            <FormLabel>Outbound CIDR Allow List</FormLabel>
            <label
              tabIndex={0}
              className="cursor-pointer text-sm color-link ngfocus"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("envFile")?.click();
                }
              }}
            >
              Import <Download className="feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
              <input
                className="invisible"
                style={{ width: 0 }}
                onChange={onUploadOutboundCIDRAllowList}
                type={"file"}
                multiple={false}
              />
            </label>
          </div>
          <div className="flex flex-wrap mb-1">
            {firewall.external_outboundAllowCIDR.map((ip) => (
              <div style={{ width: 450 }} key={ip} className="flex items-center mb-1 mr-4">
                <Value className="flex-grow" style={{ margin: 0 }} value={ip} />
                <NGButton
                  className="ml-2"
                  size={"small"}
                  variant={"danger"}
                  onClick={() => firewall.external_removeOutboundAllowCIDR(ip)}
                >
                  X
                </NGButton>
              </div>
            ))}
          </div>
          <div className="flex mb-4">
            <div className="flex">
              <MobxInput
                dataTestId={"outbound-cidr"}
                style={{ marginRight: 5, width: 450 }}
                className="flex-grow"
                data={firewall.external_outboundAllowCIDRInput}
                hideErrorMessage
              />
              <NGButton
                style={{ marginLeft: 5, width: 73 }}
                onClick={firewall.external_addOutboundAllowCIDR}
                disabled={
                  firewall.external_outboundAllowCIDRInput.value.length < 1 ||
                  !firewall.external_outboundAllowCIDRInput.isValid
                }
                variant={"action"}
              >
                Add
              </NGButton>
            </div>
            <InfoTooltip
              title={[
                `The list of ipv4/ipv6 addresses or cidr blocks that this workload is allowed reach.`,
                `No outbound access is allowed by default.`,
                `Specify '0.0.0.0/0' to allow outbound access to the public internet (not recommended)`,
                `This list overrides the 'outboundAllowHostname`,
              ]}
              style={{ marginTop: 15 }}
            />
          </div>
        </>
      )}
      {workloadDraft.isCron ? null : (
        <>
          <div className={`flex items-center ${firewall.external_outboundAllowPort.length > 0 ? "mb-2" : ""}`}>
            <div className="mr-2">
              <FormLabel>Outbound Port Allow List</FormLabel>
            </div>
            <NGButton
              style={{ width: 73 }}
              size={"small"}
              onClick={firewall.external_addOutboundAllowPort}
              disabled={firewall.external_outboundAllowPort.some((p) => !p.isValid)}
              variant={"action"}
            >
              Add
            </NGButton>
            <InfoTooltip title="Allow outbound access to specific ports and protocols. When not specified, communication to address ranges in outboundAllowCIDR is allowed on all ports and communication to names in outboundAllowHostname is allowed on ports 80/443." />
          </div>
          <div className="flex flex-col gap-1 mb-1">
            {firewall.external_outboundAllowPort.map((port) => (
              <div className="flex items-center gap-2">
                <div key={port.id} style={{ width: 450 }} className="flex items-center mb-1 gap-2">
                  <MobxInput className="flex-grow" data={port.number} hideErrorMessage />
                  <NGSelect
                    options={port.protocol.options}
                    value={port.protocol.value}
                    onChange={port.protocol.setValue}
                    size={"toRemoveLarge"}
                    style={{ width: 230 }}
                  />
                </div>
                <NGButton
                  size={"small"}
                  variant={"danger"}
                  onClick={() => firewall.external_removeOutboundAllowPort(port.id)}
                >
                  X
                </NGButton>
              </div>
            ))}
          </div>
        </>
      )}
      {workloadDraft.isCron ? null : (
        <>
          <div className="mb-4 mt-6 flex items-center">
            <FormLabel size={"large"}>Internal</FormLabel>
            <InfoTooltip title="The internal firewall is used to control access between workloads." />
          </div>
          <div className="w-max mb-2">
            <NGAlert
              type={"info"}
              render={() => (
                <div>
                  Allow access to this workload internally on{" "}
                  <span className="font-semibold">
                    {workloadDraft.name.value}.{ConsoleContext.gvc}.cpln.local
                  </span>
                </div>
              )}
            />
          </div>
          <div className="mb-4 relative flex items-center">
            <Select
              className="relative"
              label={firewall.internal_inboundAllowType.label}
              style={{ width: 450, zIndex: 3 }}
              onChange={firewall.internal_inboundAllowType.setValue}
              options={firewall.internal_inboundAllowType.options}
              value={firewall.internal_inboundAllowType.value as any}
            />
            <InfoTooltip
              title={[
                `Used to control the internal firewall configuration and mutual tls.`,
                `'none': no access is allowed between this workload and other workloads on Control Plane.`,
                `'same-gvc': workloads running on the same Global Virtual Cloud are allowed to access this workload internally.`,
                `'same-org': workloads running on the same Control Plane Organization are allowed to access this workload internally.`,
                `'workload-list': specific workloads provided in the 'inboundAllowWorkload' array are allowed to access this workload internally.`,
                `'same-gvc-and-workload-list': running on the same Global Virtual Cloud, as well as specific workloads listed in the 'inboundAllowWorkload' array, are permitted to access this workload internally.`,
              ]}
            />
          </div>

          {["workload-list", "same-gvc-and-workload-list"].includes(firewall.internal_inboundAllowType.value) && (
            <div className="mb-4">
              <div className="flex items-center mb-4">
                <NGSwitch
                  data-testid="switch-inbound-allow-itself"
                  value={firewall.internal_inboundAllowItself}
                  onChange={firewall.internal_setInboundAllowItself}
                >
                  <NGLabelText>Allow to Access Itself</NGLabelText>
                </NGSwitch>
                <InfoTooltip title="Enables the replicas of this workload to access itself" />
              </div>
              <WorkloadCreateFirewallWorkload
                filterOutSameGVC={firewall.internal_inboundAllowType.value.includes("same-gvc")}
                firewall={firewall}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export const WorkloadCreateFirewall = observer(WorkloadCreateFirewallRaw);
