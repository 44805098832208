import { types, Instance, getParent } from "mobx-state-tree";
import { InvalidReason } from "../mst/stores/domain.draft";
import { isEqual } from "lodash";

export const TagNewModel = types
  .model({
    index: types.number,
    key: types.string,
    value: types.string,
    status: types.enumeration(["default", "added", "changed"]),
  })
  .views((self) => ({
    get keyConflicts(): boolean {
      // Used when is under TagsNewModel
      const parent: TagNewMobx[] = getParent(self); // .editTags
      if (!parent || !Array.isArray(parent)) {
        return false;
      }

      for (let tag of parent) {
        if (tag.index === self.index) {
          continue;
        }
        if (tag.key === self.key) {
          return self.index < tag.index;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isValid() {
      if (self.keyConflicts) {
        return false;
      }
      if (self.key.length < 1) {
        return false;
      }
      return true;
    },
  }))
  .actions((self) => ({
    setStatus(value: "default" | "added" | "changed") {
      self.status = value;
    },
  }))
  .actions((self) => ({
    setKey(value: string) {
      self.key = value;
      if (self.status === "added") {
        return;
      }
      self.setStatus("changed");
    },
    setValue(value: string) {
      self.value = value;
      if (self.status === "added") {
        return;
      }
      self.setStatus("changed");
    },
    clearValue() {
      self.value = "";
      if (self.status === "added") {
        return;
      }
      self.setStatus("changed");
    },
  }));
export interface TagNewMobx extends Instance<typeof TagNewModel> {}

export const TagsNewModel = types
  .model({
    tags: types.array(TagNewModel),
    editTags: types.array(TagNewModel),
    index: types.optional(types.number, 0),
  })
  .views((self) => ({
    get addedTags() {
      return self.editTags.filter((t) => t.status === "added");
    },
  }))
  .actions((self) => ({
    getNewIndex() {
      self.index += 1;
      return self.index;
    },
  }))
  .actions((self) => ({
    reset() {
      self.editTags.clear();
      for (let tag of self.tags) {
        self.editTags.push({ ...tag });
      }
      self.index = self.editTags.length;
    },
    confirm(tags?: any) {
      self.tags.clear();
      let newTags = JSON.parse(JSON.stringify(self.editTags));
      self.editTags.clear();
      if (tags) {
        newTags = Object.entries(tags)
          .sort((a, b) => {
            if (a[0] < b[0]) return -1;
            if (a[0] > b[0]) return 1;
            return 0;
          })
          .map(([key, value], index) => ({ index, key, value: String(value) }));
      }
      for (let tag of newTags) {
        const newTag = { index: tag.index, status: "default", key: tag.key, value: tag.value };
        self.tags.push(newTag as any);
        self.editTags.push(newTag as any);
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setInitialTags(tags: TagNewMobx[]) {
      self.tags.clear();
      for (let tag of tags) {
        self.tags.push({ ...tag });
      }
      self.reset();
    },
    add() {
      const newIndex = self.getNewIndex();
      self.editTags.push(
        TagNewModel.create({
          index: newIndex,
          key: "",
          value: "",
          status: "added",
        })
      );
      return newIndex;
    },
    remove(index: number) {
      const toRemove = self.editTags.find((t) => t.index === index);
      if (!toRemove) return;
      self.editTags.remove(toRemove);
      return;
    },
  }))
  .views((self) => ({
    get asObject() {
      let res: { [_: string]: string | number | null | undefined } = {};

      for (let tag of self.editTags) {
        if (!!tag.value && !Number.isNaN(Number(tag.value))) {
          res[tag.key] = Number(tag.value);
        } else {
          res[tag.key] = String(tag.value);
        }
      }

      return res;
    },
    get isValid() {
      for (let tag of self.editTags) {
        if (!tag.isValid) {
          return false;
        }
      }
      return true;
    },
    get invalidReason(): InvalidReason {
      for (let tag of self.editTags) {
        if (!tag.isValid) {
          return { code: 1, type: "error", message: "A tag at least needs the key" };
        }
      }
      return { code: 0, type: "info", message: "" };
    },
  }))
  .views((self) => ({
    get isDirty() {
      const tagsObject: { [_: string]: any } = {};
      for (const tag of self.tags) {
        tagsObject[tag.key] = tag.value;
      }
      const newTagsObject = self.asObject;
      for (const [key, value] of Object.entries(newTagsObject)) {
        newTagsObject[key] = String(value);
      }

      return !isEqual(tagsObject, newTagsObject);

      // if (self.tags.length !== self.editTags.length) {
      //   return true;
      // }
      // if (self.editTags.some((t) => t.status !== "default")) {
      //   return true;
      // }
      // let res = false;
      // for (let tag of self.tags) {
      //   if (!self.editTags.find((t) => t.key === tag.key && t.value === tag.value)) {
      //     res = true;
      //     break;
      //   }
      // }

      // return res;
    },
  }));

export interface TagsNewMobx extends Instance<typeof TagsNewModel> {}
