import * as React from "react";
import { Dropdown, notification } from "antd";
import { NGButton } from "../../button/Button";
import { ChevronDown } from "react-feather";

import { _Kind } from "../../../mst/kinds";
import { gvcScopedKinds } from "../../../utils/kinds";

import { TerraformContext } from "../../../mobxStores/terraform/terraformContext";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";

import { clearItem, toSortedJSON, yamlDumpAll } from "../../../services/utils";
import { convertAllLinksToRelative } from "../../../utils/linkParser/linkParser";
import { linksOf, request } from "../../../services/cpln";

import jsYaml from "js-yaml";
import { workloadHelpers } from "../../../mst/kinds/workload.helpers";
import { TerraformImportModal } from "../../../components/modals/tfImportModal";
import { itemHelpers as itemHelpersBase } from "../../../mst/kinds/item.helpers";

interface IProps {
  kind: _Kind;
  selfLinks: string[];
}
export const TableExportButton = ({ kind, selfLinks }: IProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [terraformImportCommand, setTerraformImportCommand] = React.useState("");

  const canExportToTerraform: boolean = TerraformContext.supportedKinds.includes(kind);
  const itemHelpers = itemHelpersBase({ org: ConsoleContext.org!, gvc: ConsoleContext.gvc, kind: kind });

  async function handleExportMenuClick(e: any) {
    setIsLoading(true);
    switch (e.key) {
      case "json":
        await exportJSON(false);
        break;
      case "yaml":
        await exportYaml(false);
        break;
      case "json-slim":
        await exportJSON(true);
        break;
      case "yaml-slim":
        await exportYaml(true);
        break;
      case "terraform":
        await exportTerraform();
        break;
    }
    setIsLoading(false);
  }

  function getFileName(): string {
    let fileName: string = ConsoleContext.org as string;

    if (gvcScopedKinds.includes(kind)) {
      fileName += `_${ConsoleContext.gvc as string}`;
    }

    fileName += `_${kind}s`;

    return fileName;
  }

  function autoDownloadWithElementA(blobData: any, extension: "json" | "yaml" | "tf", filename?: string) {
    const file = new Blob([blobData], { type: `text/${extension}` });
    const href = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style.display = "none";
    a.classList.add("cpln-temp-a");
    a.download = (filename || getFileName()) + "." + extension;
    a.href = href;
    a.click();
  }

  async function exportItems(slim: boolean) {
    try {
      let _items: any[] = [];
      for (let selfLink of selfLinks) {
        const { data }: any = await request({ url: selfLink });
        _items = _items.concat(data);
      }

      if (kind === "secret") {
        const revealPromises: Promise<any>[] = [];
        for (let _item of _items) {
          revealPromises.push(request({ url: linksOf(_item).reveal! }));
        }
        const revealedItems: any[] = await Promise.allSettled(revealPromises);

        for (let revealedRes of revealedItems) {
          if (revealedRes.status !== "fulfilled") {
            continue;
          }
          const revealed = revealedRes.value.data;
          const index = _items.findIndex((i) => i.name === revealed.name);
          if (index >= 0) {
            _items[index] = revealed;
          }
        }
      }

      if (kind === "workload") {
        const deploymentPromises: Promise<any>[] = [];
        for (let _item of _items) {
          deploymentPromises.push(request({ url: linksOf(_item).deployment! }));
        }
        const deploymentResponses: any[] = await Promise.allSettled(deploymentPromises);

        for (let deploymentRes of deploymentResponses) {
          const index = _items.findIndex((i) => linksOf(i).self === linksOf(deploymentRes.value.data).workload);
          if (index >= 0) {
            if (deploymentRes.status !== "fulfilled") {
              _items[index].status.ready = "unknown";
              _items[index].status.readyLatest = "unknown";
              continue;
            }

            const health = workloadHelpers.getHealth(deploymentRes.value.data.items, _items[index]);
            _items[index].status.ready = health.isReady;
            _items[index].status.readyLatest = health.isLatestReady;
            _items[index].status.readyCheckTimestamp = new Date().toISOString();
          }
        }
      }

      for (let _itemIndex in _items) {
        let _item = _items[_itemIndex];
        if (slim) {
          clearItem(_item);
        }
        _item = convertAllLinksToRelative(_item);
        _item = toSortedJSON(_item);
        _items[_itemIndex] = _item;
      }

      return _items;
    } catch (e) {
      return false;
    }
  }

  async function exportJSON(slim: boolean) {
    try {
      const data = await exportItems(slim);
      const blob = JSON.stringify(data, null, 2);
      autoDownloadWithElementA(blob, "json");
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  async function exportYaml(slim: boolean) {
    try {
      const data = await exportItems(slim);
      const blob = yamlDumpAll(data, { indent: 2, noRefs: true });
      autoDownloadWithElementA(blob, "yaml");
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  async function exportTerraform() {
    setTerraformImportCommand(await itemHelpers.handleExportTerraform([]));
  }

  const exportMenuItems = [
    { key: "json", label: "JSON" },
    { key: "yaml", label: "YAML" },
    { key: "json-slim", label: "JSON Slim" },
    { key: "yaml-slim", label: "YAML Slim" },
  ];
  if (canExportToTerraform) {
    exportMenuItems.push({ key: "terraform", label: "Terraform" });
  }

  return (
    <>
      <Dropdown menu={{ onClick: handleExportMenuClick, items: exportMenuItems }} placement={"top"} trigger={["click"]}>
        <NGButton
          loading={isLoading}
          size={"normal"}
          variant="secondary"
          renderIcon={(hover, props) => <ChevronDown {...props} size={18} />}
        >
          Export
        </NGButton>
      </Dropdown>
      {terraformImportCommand ? (
        <TerraformImportModal command={terraformImportCommand} onClose={() => setTerraformImportCommand("")} />
      ) : null}
    </>
  );
};
