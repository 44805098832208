import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGLabel } from "../../../../newcomponents/text/label";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateAddonsInternalDeveloperPlatformRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <div>
      <NGLabel>Internal Developer Platform</NGLabel>
    </div>
  );
};

export const Mk8sCreateAddonsInternalDeveloperPlatform = observer(Mk8sCreateAddonsInternalDeveloperPlatformRaw);
