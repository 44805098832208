import * as Sentry from "@sentry/react";
import { DEPLOYMENT_ENV, DISABLE_SENTRY_ON_LOCAL, IS_DEPLOYMENT_ENV_PROD, IS_LOCAL } from "../envVariables";

export function setupSentry() {
  if (DISABLE_SENTRY_ON_LOCAL) {
    return;
  }

  const environment = IS_LOCAL ? "local" : DEPLOYMENT_ENV;
  try {
    // @ts-ignore
    const about = require("./../about.json");
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      sampleRate: 1.0,
      attachStacktrace: true,
      debug: !IS_DEPLOYMENT_ENV_PROD,
      release: about.version,
      maxBreadcrumbs: 30,
      normalizeDepth: 5,
      environment,
      autoSessionTracking: false,
      beforeBreadcrumb(crumb) {
        if (crumb.category === "console") return null;
        if (crumb.category === "xhr" && crumb.data?.status_code < 400) return null;
        if (crumb.category === "xhr" && crumb.data?.status_code === 403) return null;
        if (crumb.category === "xhr" && crumb.data?.status_code === 404) return null;
        if (crumb.category === "ui.click") return null;
        if (crumb.category === "ui.input") return null;
        return crumb;
      },
    });
  } catch (e) {
    console.error("Failed to initialize Sentry", e.message);
  }
}
