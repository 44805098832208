import { observer } from "mobx-react-lite";
import * as React from "react";
import { getLogsDatadogData } from "./data/datadog";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";

interface Props {
  datadog: ReturnType<typeof getLogsDatadogData>;
  onRemove?: any;
}
const DatadogRaw: React.FC<Props> = ({ datadog, onRemove }) => {
  return (
    <>
      <div style={{ width: 450 }}>
        <NGLabel>{datadog.host.label}</NGLabel>
        <NGSelect
          onChange={datadog.host.setValue}
          options={datadog.host.options}
          value={datadog.host.value}
          className="mb-4"
          style={{ width: 450 }}
        />
        <NGLabel>Credentials (Opaque Secret)</NGLabel>
        <NGKindSelect
          value={datadog.credentials.value}
          onChange={(value) => datadog.credentials.setValue(value)}
          kind={"secret"}
          queries={[{ property: "type", value: "opaque" }]}
          className="mb-4"
        />
        {onRemove ? (
          <div className="flex justify-end">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const Datadog = observer(DatadogRaw);
