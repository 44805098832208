import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { Table } from "../../newcomponents/table/table";
import { TableNewItemButton } from "../../newcomponents/table/components/tableNewButton";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { Mk8sCluster } from "../../schema/types/mk8s";
import { getLocalToken, linksOf } from "../../services/cpln";
import { Mk8sClusterTableItem, mk8sHelpers } from "../../mst/kinds/mk8s.helpers";
import { TableActionsDropdown } from "../../newcomponents/table/components/tableActionsDropdown";
import { DeleteModal } from "../../components/modals/deleteModal";
import { useKubernetesReadinessReducer } from "../../components/detail/kubernetesReadinessReducer";
import { KUBERNETES_READINESS_UPDATE_INTERVAL_MS, MK8S_DASHBOARD_TOKEN_UPDATE_INTERVAL_MS } from "../../envVariables";
import { InfoTooltip } from "../../components/InfoTooltip";
import { ExternalLinkButton } from "../../newcomponents/table/components/ExternalLinkButton";

const Mk8sListRaw: React.FC = () => {
  const { state, fetchReadiness } = useKubernetesReadinessReducer();

  // dashboard start
  const dashboardFormRef = React.useRef<HTMLFormElement>(null as any);
  const [dashboardUrl, setDashboardUrl] = React.useState("");
  const [tokenFromLocal, setTokenFromLocal] = React.useState("");
  React.useEffect(() => {
    getLocalToken().then((res) => {
      if (res.accessToken !== "pass") {
        setTokenFromLocal(res.accessToken);
      }
    });
    const localTokenInterval = setInterval(() => {
      getLocalToken().then((res) => {
        if (res.accessToken !== "pass") {
          setTokenFromLocal(res.accessToken);
        }
      });
    }, MK8S_DASHBOARD_TOKEN_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(localTokenInterval);
    };
  }, []);
  function onDashboardButton(url: string) {
    setDashboardUrl(url);
  }
  React.useEffect(() => {
    if (!dashboardUrl) {
      return;
    }
    if (dashboardFormRef.current) {
      dashboardFormRef.current.submit();
    }
    setDashboardUrl("");
  }, [dashboardUrl]);
  // dashboard end

  const [selectedLinks, setSelectedLinks] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<string>("");

  const { kind, id } = useTableKindId("mk8s", "mk8s-list-update1");
  const qData = useTableItemQueryData<Mk8sCluster>(kind, { fetchAllPages: true });

  React.useEffect(() => {
    const unsubscribe = fetchReadiness(
      qData.visibleItems.map((i) => i.name),
      KUBERNETES_READINESS_UPDATE_INTERVAL_MS,
    );
    return () => {
      unsubscribe();
    };
  }, [qData.visibleItems.length]);

  const mk8sClusterTableItems: Mk8sClusterTableItem[] = qData.visibleItems.map((item) => {
    const selfLink = linksOf(item).self!;
    const readiness = state.readinessMap[item.name] || { ready: false };
    const provider = mk8sHelpers.getProvider(item);
    const kubernetesVersion = mk8sHelpers.getKubernetesVersion(item);
    const dashboardUrl = mk8sHelpers.getDashboardUrl(item);
    const isAddonLogsEnabled = mk8sHelpers.getIsAddonLogsEnabled(item);

    return {
      ...item,
      selfLink: selfLink,
      readiness: readiness,
      provider: provider,
      kubernetesVersion: kubernetesVersion,
      dashboardUrl: dashboardUrl,
      isAddonLogsEnabled: isAddonLogsEnabled,
    };
  });

  const columns = [
    NameDescriptionColumn(),
    {
      id: "provider",
      label: "Provider",
      canGroupByDistinctValues: true,
      groupLabelMap: {
        generic: "Generic",
        hetzner: "Hetzner",
        aws: "AWS",
        ephemeral: "Ephemeral",
        linode: "Linode",
        oblivus: "Oblivus",
        lambdalabs: "Lambdalabs",
        paperspace: "Paperspace",
      },
      size: 160,
    },
    { id: "kubernetesVersion", label: "Version", canGroupByDistinctValues: true, enableHide: true, size: 160 },
    {
      id: "dashboardUrl",
      label: "Dashboard",
      enableHide: true,
      size: 160,
      cell: ({ getValue }) => {
        const value = getValue();
        if (!value) {
          return null;
        }
        return <ExternalLinkButton onClick={() => onDashboardButton(value)} label="Open" />;
      },
    },
    {
      id: "readiness",
      label: "Readiness",
      enableHide: true,
      size: 150,
      cell: (p) => {
        const item = p.row.original;

        if (!item.readiness || !state.requestedClusterNames.includes(item.name)) {
          return <div>Loading</div>;
        }

        return (
          <div className="flex items-center">
            <span className={`${item.readiness.ready ? "color-action" : "color-danger"}`}>
              {item.readiness.ready ? "Ready" : "Not Ready"}
            </span>
            {!item.readiness.ready && item.readiness.message ? <InfoTooltip title={item.readiness.message} /> : null}
          </div>
        );
      },
    },
    TagsColumn(),
    CreatedColumn(),
    LastModifiedColumn(),
  ];

  return (
    <>
      <Table<Mk8sClusterTableItem>
        title={"Kubernetes"}
        data={mk8sClusterTableItems}
        selectKey="selfLink"
        selectMode="multi"
        selections={selectedLinks}
        onSelectionsChange={setSelectedLinks}
        tableId={id}
        headerRenderer={() => {
          return (
            <>
              <TableNewItemButton kind={kind} />
              <TableActionsDropdown
                kind={kind}
                selectionLinks={selectedLinks}
                options={[
                  {
                    key: "delete",
                    label: "Delete",
                    disabled: selectedLinks.length < 1,
                    danger: true,
                    callback: () => setAction("delete"),
                  },
                ]}
              />
              <TableHeaderRefreshButton onClick={qData.fetchItems} />
              <CustomTableCPLNQuery
                tableId={id}
                kind={kind}
                onQuery={qData.setQuery}
                isLoading={qData.isLoadingQueried}
              />
            </>
          );
        }}
        enableFilter
        isLoading={qData.isLoading}
        columns={columns as any}
      />
      {action === "delete" ? (
        <DeleteModal
          kind={kind}
          onCancel={() => setAction("")}
          onComplete={() => {
            setAction("");
            setSelectedLinks([]);
            qData.fetchItems();
          }}
          links={selectedLinks}
        />
      ) : null}
      <form
        className="hidden"
        ref={dashboardFormRef}
        action={`${dashboardUrl}/auth/process/#/node?namespace=default`}
        method="POST"
        target="_blank"
      >
        <input name="redirect" value={dashboardUrl} type="hidden" />
        <input name="idToken" value={tokenFromLocal} type="hidden" />
        <button type="submit">dashboard</button>
      </form>
    </>
  );
};

export const Mk8sList = observer(Mk8sListRaw);
