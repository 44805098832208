import React from "react";
import { observer } from "mobx-react-lite";
import { UserData } from "../../mobxStores/userData/userData";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Navigate, useNavigate } from "react-router-dom";
import { flowResult } from "mobx";
import { NGButton } from "../../newcomponents/button/Button";
import { NGAutocomplete } from "../../newcomponents/select/ngautocomplete";
import { NGRadioGroup } from "../../newcomponents/radioGroup";

const GvcSelectRaw: React.FC = () => {
  const navigate = useNavigate();
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [selectedGVC, setSelectedGVC] = React.useState("");

  React.useEffect(() => {
    prepare();
  }, []);

  React.useEffect(() => {
    if (UserData.gvcNames.length === 1) {
      handleSelectConfirm(UserData.gvcNames[0]);
    }
  }, [UserData.gvcNames]);

  async function prepare() {
    await flowResult(UserData.requestGVCNames());
    setIsPageReady(true);
  }

  async function handleSelectConfirm(overrideGVC?: string) {
    const gvcToSelect = overrideGVC || selectedGVC;
    ConsoleContext.setGVC(gvcToSelect);
    await UserData.requestHasWorkload();
    ConsoleContext.setRedirect(`/console/org/${ConsoleContext.org}/gvc/${ConsoleContext.gvc}`);
  }

  function createGvc() {
    navigate(`/console/org/${ConsoleContext.org}/gvc/-create`);
  }

  if (!isPageReady) {
    return null;
  }

  if (!ConsoleContext.hasOrg) {
    return <Navigate to={"/console/org/-select"} />;
  }

  if (!UserData.hasGVC) {
    return (
      <div className="flex flex-col items-center">
        <div className="text-lg mt-8 mb-4">No GVCs found for this org.</div>
        <div className="mb-8">You need to create a GVC to start deploying workloads.</div>
        <NGButton variant={"action"} onClick={() => navigate(`/console/org/${ConsoleContext.org}/gvc/-create`)}>
          Create GVC
        </NGButton>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center mt-16">
      <p className="text-center text-lg">Select a GVC</p>
      <p className="text-center">You can change this preference later</p>
      <div style={{ width: 800 }} className={"mt-8 flex flex-col items-center"}>
        {UserData.gvcNames.length > 5 ? (
          <NGAutocomplete
            size={"normal"}
            options={UserData.gvcNames.map((gvcName) => ({ label: gvcName, value: gvcName }))}
            disabled={UserData.isLoadingHasWorkload}
            value={selectedGVC}
            placeholder={"Select a GVC"}
            onChange={(value) => setSelectedGVC(value)}
            style={{ width: 450 }}
          />
        ) : (
          <NGRadioGroup
            isDisabled={UserData.isLoadingHasWorkload}
            value={selectedGVC}
            options={UserData.gvcNames.map((gvcName) => ({ label: gvcName, value: gvcName }))}
            onChange={(value) => setSelectedGVC(value)}
          />
        )}
      </div>
      <div className="flex">
        <NGButton
          disabled={UserData.isLoadingHasWorkload || !selectedGVC}
          className="mt-8 mr-4"
          variant={"primary"}
          onClick={() => handleSelectConfirm(undefined)}
          loading={UserData.isLoadingHasWorkload}
        >
          Continue
        </NGButton>
      </div>
    </div>
  );
};

export const GVCSelect = observer(GvcSelectRaw);
