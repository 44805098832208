import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { GroupList } from "./list";
import { GroupDetailRoute } from "./detailRoute";
import { GroupCreate } from "./create/create";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const GroupRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<GroupList />} />
        <Route path={`-create/*`} element={<GroupCreate />} />
        <Route path={`:group/*`} element={<GroupDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(GroupRoute);
