import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "../../layout";
import { observer } from "mobx-react-lite";
import { IdentityList } from "./list";
import { IdentityDetailRoute } from "./detailRoute";
import { IdentityCreateRoute } from "./create/createRoute";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const IdentityRoute: React.FC = () => {
  const { org } = ConsoleContext;
  const { gvc } = ConsoleContext;

  return (
    <Layout key={org! + gvc!}>
      <Routes>
        <Route index element={<IdentityList />} />
        <Route path={`-create/*`} element={<IdentityCreateRoute />} />
        <Route path={`:identity/*`} element={<IdentityDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(IdentityRoute);
