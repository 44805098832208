import * as React from "react";
import { observer } from "mobx-react-lite";
import { GroupMobx } from "../../mst/kinds/group";
import { TabsLayout, TabsLayoutLink } from "../../components/tabsLayout/tabsLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import { GroupUsers } from "./member-users";
import { GroupServiceaccounts } from "./member-serviceaccounts";
import { GroupUserQuery } from "./member-user-query";

interface Props {
  group: GroupMobx;
}
const MembersRaw: React.FC<Props> = ({ group }) => {
  const links: TabsLayoutLink[] = [
    { url: `-users`, label: "Users" },
    { url: `-serviceaccounts`, label: "Service Accounts" },
    { url: `-userquery`, label: "User Query" },
  ];

  return (
    <TabsLayout links={links}>
      <Routes>
        <Route index element={<Navigate to={`-users`} />} />
        <Route path={`-users/*`} element={<GroupUsers group={group} />} />
        <Route path={`-serviceaccounts/*`} element={<GroupServiceaccounts group={group} />} />
        <Route path={`-userquery/*`} element={<GroupUserQuery group={group} />} />
      </Routes>
    </TabsLayout>
  );
};

export const Members = observer(MembersRaw);
