import React from "react";
import styles from "./style.module.scss";
import { observer } from "mobx-react-lite";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { Eye, EyeOff } from "react-feather";

const initialComponentHeight: string = "3rem";

const CheckMark = () => (
  <span className="formmark ml-1 text-xs" style={{ color: "transparent", textShadow: "0 0 0 var(--color-action)" }}>
    &#10004;
  </span>
);
const RequiredMark = () => <span className="formmark ml-1 color-danger">*</span>;
const InvalidMark = () => (
  <span className="formmark ml-1 color-danger transform" style={{ transform: "translateY(-5px)" }}>
    x
  </span>
);

interface Props {
  data: StringMobx;
  dataTestId?: string;
  idSuffix?: string;
  className?: string;
  style?: any;
  disabled?: boolean;
  autoFocus?: boolean;
  hideErrorMessage?: boolean;
  disableFocusRing?: boolean;
  setValue?: (value: string) => void;
  onClear?: Function;
}

const MobxMultilineInputRaw: React.FC<Props> = ({
  data,
  dataTestId,
  idSuffix = "",
  className = "",
  style = {},
  disabled = false,
  autoFocus = false,
  hideErrorMessage = false,
  disableFocusRing = false,
  setValue,
  onClear = () => {},
}) => {
  const hasInput = !!data.value;
  const isInvalid = !!data.error && !data.isValid;

  // Refs //
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);

  // Effects //
  React.useEffect(() => {
    const activeRef = getActiveRef();
    if (autoFocus && activeRef && activeRef.current) {
      activeRef.current.focus();
    }
  }, [inputRef, textAreaRef, autoFocus]);

  React.useEffect(() => {
    if (data.value.length < 1) {
      onClear();
    }

    adjustTextAreaSize();
  }, [data.value, data._isHidden]);

  // Functions //
  function getActiveRef(): React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null> {
    return data.isSensitive && data._isHidden ? inputRef : textAreaRef;
  }

  function handleLabelClick(): void {
    const activeRef = getActiveRef();
    if (activeRef && activeRef.current) {
      activeRef.current.focus();
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    // Remove new lines from the target value
    const value: string = event.target.value.replace(/[\r\n]+/g, "");
    setValue ? setValue(value) : data.setValue(value);
  }

  function handleTextAreaKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>): void {
    // Ignore new lines
    if (event.key == "Enter") {
      event.preventDefault();
    }
  }

  function adjustTextAreaSize(): void {
    if (textAreaRef.current) {
      // Reset the height of the text area to the initial value
      textAreaRef.current.style.height = initialComponentHeight;

      // Adjust textarea height accordingly
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }

  return (
    <div className={`relative ${className}`} style={style}>
      <div className={`relative flex flex-col rounded ${isInvalid && "invalid"}`}>
        {data.isSensitive && data._isHidden ? (
          <input
            data-testid={dataTestId ? dataTestId + "-masked" : undefined}
            type="password"
            ref={inputRef}
            disabled={disabled}
            id={`${data.label.toLowerCase()}${idSuffix}-masked`}
            className={`cpln-input ${
              disableFocusRing ? "focus:outline-none" : "ngfocus"
            } inline-block placeholder-light-600 border rounded flex-grow h-12 w-full pr-4 ${
              hasInput ? "pt-5 pb-1" : ""
            } ${disabled ? "cursor-not-allowed" : "cursor-text"} ${isInvalid && "border-danger"}`}
            style={{
              paddingLeft: 16,
              color: "var(--color-input)",
              backgroundColor: "var(--color-input-bg)",
              borderColor: "var(--color-input-border)",
            }}
            onChange={handleChange}
            value={data.value}
          />
        ) : (
          <textarea
            data-testid={dataTestId}
            ref={textAreaRef}
            disabled={disabled}
            id={`${data.label.toLowerCase()}${idSuffix}`}
            className={`${styles.noResizeTextArea} cpln-input ${
              disableFocusRing ? "focus:outline-none" : "ngfocus"
            } inline-block placeholder-light-600 border rounded flex-grow h-12 w-full pr-4 text-base ${
              disabled ? "cursor-not-allowed" : "cursor-text"
            } ${isInvalid && "border-danger"}`}
            style={{
              paddingTop: hasInput ? 20 : 12,
              paddingBottom: hasInput ? 8 : 10,
              paddingLeft: hasInput ? 14.5 : 16,
              minHeight: initialComponentHeight,
              lineHeight: 1.25,
              color: "var(--color-input)",
              backgroundColor: "var(--color-input-bg)",
              borderColor: "var(--color-input-border)",
            }}
            onChange={handleChange}
            value={data.value}
            onKeyDown={handleTextAreaKeyDown}
          />
        )}
        <label
          htmlFor={`${data.label.toLowerCase()}${idSuffix}`}
          className={`flex absolute left-4 transition-all ${hasInput ? "top-1" : "top-3"} ${
            disabled ? "cursor-not-allowed" : "cursor-text"
          }`}
          onClick={handleLabelClick}
        >
          <span className={`${hasInput ? "font-medium text-xs" : "text-light"} truncate`}>{data.label}</span>
          {data.infoMark === "check" && <CheckMark />}
          {data.infoMark === "required" && <RequiredMark />}
          {data.infoMark === "invalid" && <InvalidMark />}
        </label>
        {data.isSensitive ? (
          <button
            type={"button"}
            className={`focus absolute top-4`}
            style={{ right: -24 }}
            onClick={() => {
              data.setIsHidden(!data._isHidden);
            }}
          >
            {data._isHidden ? (
              <Eye className="feather-icon" data-testid="toggle-visible" />
            ) : (
              <EyeOff className="feather-icon" data-testid="toggle-visible" />
            )}
          </button>
        ) : null}
      </div>
      {!hideErrorMessage && data.error ? (
        <div className="pl-4 mt-1 color-danger text-sm">{data.error}</div>
      ) : data.examples.length > 0 ? (
        <div className="pl-4 mt-1 text-sm">
          Example{data.examples.length > 1 ? "s" : ""}: {data.examples.join(", ")}
        </div>
      ) : null}
    </div>
  );
};

export const MobxMultilineInput = observer(MobxMultilineInputRaw);
