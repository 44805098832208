import { observer } from "mobx-react-lite";
import * as React from "react";
import { Tooltip } from "../../components/Tooltip";

interface Props {
  label: { key: string; value: string };
}
const NodePoolLabelRaw: React.FC<Props> = ({ label: { key, value } }) => {
  return (
    <Tooltip title={`${key}=${value}`}>
      <div className="truncate mr-2">
        {key}={value}
      </div>
    </Tooltip>
  );
};

export const NodePoolLabel = observer(NodePoolLabelRaw);
