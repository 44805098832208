import * as React from "react";
import { observer } from "mobx-react-lite";
import { QueryUI } from "../../../components/query/queryUI";
import { ItemStoreQueryMobx } from "../../../mst/query";
import { objectToArray } from "../../../services/utils";

interface Props {
  useQuery: boolean;
  setUseQuery: (value: boolean) => void;
  userQuery: ItemStoreQueryMobx;
}
const GroupCreateUserQueryRaw: React.FC<Props> = ({ useQuery, setUseQuery, userQuery }) => {
  return (
    <QueryUI
      idPrefix={`group-detail-member`}
      kind={"user"}
      query={userQuery}
      useQuery={useQuery}
      setUseQuery={setUseQuery}
      evaluatedItemFn={(item: any) => ({
        id: item.id,
        name: item.email,
        description: item.name,
        tags: objectToArray(item.tags),
      })}
    />
  );
};

export const GroupCreateUserQuery = observer(GroupCreateUserQueryRaw);
