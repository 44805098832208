import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { VolumesProviderModal } from "./volumesProviderModal";
import { BrowserServiceModal } from "../../identity/create/browserServiceModal";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { VolumeItem } from "./volumeItem";
import { firewallRequirementsForVolumes } from "../../../mst/kinds/workload";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  containerDraft: WorkloadDraftContainerMobx;
}

const VolumesRaw: React.FC<Props> = ({ workloadDraft, containerDraft }) => {
  const firewallRecommendations: string[] = [];

  for (const container of [...workloadDraft.allContainers, workloadDraft.newContainer]) {
    if (workloadDraft.firewall.external_allOutboundAllowed) {
      continue;
    }
    for (const volume of container.volumes.asArray) {
      for (const prefix of Object.keys(firewallRequirementsForVolumes)) {
        if (volume.uri.startsWith(prefix)) {
          // @ts-ignore
          for (const recommendation of firewallRequirementsForVolumes[prefix]) {
            if (firewallRecommendations.includes(recommendation)) {
              continue;
            }
            if (workloadDraft.firewall.external_outboundAllowHostname.includes(recommendation)) {
              continue;
            }
            firewallRecommendations.push(recommendation);
          }
          continue;
        }
      }
    }
  }

  const browse = containerDraft.volumes.browse;

  return (
    <>
      {browse.page === "none" ? null : browse.page === "provider" ? (
        <VolumesProviderModal browse={browse} />
      ) : (
        <BrowserServiceModal
          title={`Select ${browse.provider === "azure" ? "Container" : "Bucket"}`}
          restrictToBucket={true}
          onClose={(uri) => {
            const volume = containerDraft.volumes.editVolumes.find((v) => v.id === browse.volumeId);
            if (volume && uri.length > 0) {
              let mountUri = uri[0]._cpln.mountUri;
              if (mountUri) {
                const uriPrefix = mountUri.split("://")[0] + "://";
                const uriValue = mountUri.split("://")[1];
                volume.setUriPrefix(uriPrefix);
                volume.setUriValue(uriValue);
              }
            }
            browse.done();
          }}
          selectionType={"single"}
          browserService={browse.browserService}
        />
      )}

      <div>
        <div className="mb-2 flex items-center">
          <div className="text-2xl mr-4">Volumes</div>
          <NGButton
            size={"small"}
            disabled={!containerDraft.volumes.canAdd}
            onClick={() => containerDraft.volumes.add()}
            variant={"action"}
          >
            Add
          </NGButton>
          <InfoTooltip title="Mount object store buckets as file system." />
        </div>
        {firewallRecommendations.length > 0 ? (
          <NGAlert
            className="my-4"
            render={() => (
              <div>
                <div className="mb-2">Allow hostname list below in external firewall to make volumes work.</div>
                <ul className="flex flex-col gap-1 pl-4">
                  {firewallRecommendations.map((r) => (
                    <li className="list-disc">{r}</li>
                  ))}
                </ul>
                <NGButton
                  className="mt-4"
                  size={"small"}
                  variant={"primary"}
                  onClick={() => {
                    for (const recommendation of firewallRecommendations) {
                      workloadDraft.firewall.external_outboundAllowHostnameInput.setValue(recommendation);
                      workloadDraft.firewall.external_addOutboundAllowHostname();
                    }
                    workloadDraft.firewall.external_outboundAllowHostnameInput.setValue("");
                  }}
                >
                  Fix
                </NGButton>
              </div>
            )}
            type={"warning"}
          />
        ) : null}
        {containerDraft.volumes.asArray.length < 1 ? (
          <div className="text-lg mt-4">No Volumes Found</div>
        ) : (
          <div className="flex flex-col gap-2">
            <div className="flex items-center">
              <div style={{ width: "calc((100% - 90px) / 3 * 2 / 12 * 3)" }}>URI Type</div>
              <div style={{ width: "calc((100% - 90px) / 3 * 2 / 12 * 9)" }}>URI Value</div>
              <div className="ml-2" style={{ width: "calc((100% - 90px) / 3)" }}>
                Path
              </div>
            </div>
            {containerDraft.volumes.editVolumes.map((volume) => (
              <VolumeItem
                key={volume.id}
                workloadDraft={workloadDraft}
                containerDraft={containerDraft}
                volume={volume}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export const Volumes = observer(VolumesRaw);
