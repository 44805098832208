import * as React from "react";
import { observer } from "mobx-react-lite";
import { BindingTable } from "./createBindingTable";
import { useNavigate } from "react-router-dom";
import { Modal, notification } from "antd";
import { nameOfKind } from "../../../../services/cpln";
import { NGButton } from "../../../../newcomponents/button/Button";
import { Binding } from "../../../../schema/types/policy";

interface Props {
  rootUrl: string;
  targetKind: string;
  bindings: Binding[];
  setBindings: React.Dispatch<React.SetStateAction<Binding[]>>;
}
const PolicyCreateBindingsOverviewRaw: React.FC<Props> = ({ rootUrl, bindings, setBindings, targetKind }) => {
  const navigate = useNavigate();

  async function onDeleteBinding(index: number) {
    try {
      const m = Modal.confirm({
        title: "Do you want to remove this binding?",
        footer: (
          <div className="modal-actions">
            <NGButton variant="secondary" onClick={() => m.destroy()}>
              Cancel
            </NGButton>
            <NGButton
              variant="danger"
              onClick={() => {
                try {
                  let newBindings: Binding[] = [];
                  for (let bindingIndex in bindings) {
                    if (index === Number(bindingIndex)) {
                      continue;
                    }
                    newBindings.push(bindings[bindingIndex]);
                  }
                  setBindings(newBindings);
                  notification.success({
                    message: "Removed Binding",
                  });
                } catch (e) {
                  let errorMessage = e?.response?.data?.message;
                  if (!errorMessage) errorMessage = e.message;
                  notification.warning({
                    message: "Failed",
                    description: errorMessage,
                  });
                }
                m.destroy();
              }}
            >
              Remove
            </NGButton>
          </div>
        ),
        onCancel() {},
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  return (
    <div>
      <div className={`mb-8 flex items-center justify-between border p-4 rounded`}>
        <div>
          A binding gives specific {nameOfKind(targetKind)} permissions to a selection of users, service accounts,
          groups, and identities, for the items this policy targets.
        </div>
        <NGButton style={{ width: 220, marginLeft: 10 }} variant={"action"} onClick={() => navigate(`${rootUrl}/new`)}>
          Add Binding
        </NGButton>
      </div>
      {bindings.map((binding, index) => (
        <div className="mb-8" key={index}>
          <BindingTable binding={binding} />
          <div className="flex justify-end items-center mt-4">
            <NGButton
              style={{ width: 220, marginRight: 10 }}
              onClick={() => navigate(`${rootUrl}/${String(index)}`)}
              variant={"secondary"}
            >
              Edit
            </NGButton>
            <NGButton style={{ width: 220 }} onClick={() => onDeleteBinding(index)} variant={"danger"}>
              Remove
            </NGButton>
          </div>
        </div>
      ))}
    </div>
  );
};

export const PolicyCreateBindingsOverview = observer(PolicyCreateBindingsOverviewRaw);
