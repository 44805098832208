import * as React from "react";
import { Table } from "../../../newcomponents/table/table";
import { useTableKindId } from "../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionNoLinkColumn } from "../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { linksOf } from "../../../services/cpln";
import { Domain } from "../../../schema/types/domain";
import clsx from "clsx";
import { Base } from "../../../schema/types/base";
import { Modal } from "antd";
import { NGButton } from "../../../newcomponents/button/Button";

interface DomainTableItem extends Domain {
  selfLink: string;
}

interface TargetTableItem extends Base {
  selfLink: string;
}

interface Props {
  title: string;
  selectionsToRemove: string[];
  onSelectionsToRemoveChange: (values: string[], items?: TargetTableItem[]) => void;
  selectionsToAdd: string[];
  onSelectionsToAddChange: (values: string[], items?: TargetTableItem[]) => void;
  targets: TargetTableItem[];
  onRemove: () => void;
  onOkAddModal: () => void;
}
export const DomainTable: React.FC<Props> = ({
  title, //
  selectionsToRemove,
  onSelectionsToRemoveChange,
  selectionsToAdd,
  onSelectionsToAddChange,
  targets,
  onRemove,
  onOkAddModal,
}) => {
  const [isAddingItem, setIsAddingItem] = React.useState(false);

  const { kind, id } = useTableKindId("domain", "policy-detail-target-list-domain");
  const qData = useTableItemQueryData<Domain>(kind, { fetchAllPages: true });

  const targetLinks = targets.map((t) => t.selfLink);
  const domainTableItems = qData.visibleItems //
    .map((domain) => {
      const selfLink = linksOf(domain).self!;

      return {
        ...domain,
        selfLink: selfLink,
      };
    })
    .filter((identity) => {
      return !targetLinks.includes(identity.selfLink);
    });

  return (
    <>
      <Table<TargetTableItem>
        title={title}
        data={targets}
        tableId={`${id}-targets`}
        selectMode="multi"
        selectKey={"selfLink"}
        selections={selectionsToRemove}
        onSelectionsChange={onSelectionsToRemoveChange}
        headerRenderer={() => {
          return (
            <>
              <NGButton
                variant={"danger"}
                size={"small"}
                outlined
                disabled={selectionsToRemove.length < 1}
                onClick={onRemove}
              >
                Remove
              </NGButton>
              <NGButton variant={"action"} size={"small"} onClick={() => setIsAddingItem(true)}>
                Add
              </NGButton>
            </>
          );
        }}
        columns={[
          NameDescriptionNoLinkColumn(), //
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
      />
      {isAddingItem ? (
        <Modal
          width={"90vw"}
          styles={{ body: { height: "70vh", padding: 0 } }}
          open={isAddingItem}
          onCancel={() => setIsAddingItem(false)}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setIsAddingItem(false)}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={() => {
                  onOkAddModal();
                  setIsAddingItem(false);
                }}
              >
                OK
              </NGButton>
            </div>
          }
          destroyOnClose={true}
          closable={false}
          maskClosable={false}
        >
          <div className="h-full overflow-auto p-1">
            <Table<DomainTableItem>
              title={title}
              data={domainTableItems}
              tableId={id}
              selectMode="multi"
              selectKey={"selfLink"}
              selections={selectionsToAdd}
              onSelectionsChange={onSelectionsToAddChange}
              headerRenderer={() => {
                return (
                  <>
                    <TableHeaderRefreshButton onClick={qData.fetchItems} />
                    <CustomTableCPLNQuery
                      tableId={id}
                      kind={kind}
                      onQuery={qData.setQuery}
                      isLoading={qData.isLoadingQueried}
                    />
                  </>
                );
              }}
              enableFilter
              isLoading={qData.isLoading}
              columns={[
                NameDescriptionNoLinkColumn(),
                {
                  id: "domainStatusText",
                  label: "Status",
                  canGroupByDistinctValues: true,
                  cell: ({ getValue }) => {
                    const value = getValue();
                    return (
                      <span
                        className={clsx("truncate pr-2", {
                          "color-action": value === "Ready",
                          "color-warning": value === "Warning",
                        })}
                      >
                        {value}
                      </span>
                    );
                  },
                },
                { id: "type", label: "Type", canGroupByDistinctValues: true },
                { id: "mappedGVC", label: "GVC" },
                { id: "endpointCount", label: "Endpoints" },
                TagsColumn(),
                CreatedColumn(),
                LastModifiedColumn(),
              ]}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};
