import * as React from "react";
import { Table } from "../../../../newcomponents/table/table";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { linksOf } from "../../../../services/cpln";
import { ServiceAccount } from "../../../../schema/types/serviceaccount";

interface ServiceaccountTableItem extends ServiceAccount {
  selfLink: string;
}

interface Props {
  title: string;
  selections: string[];
  onSelectionsChange: (values: string[], items?: ServiceaccountTableItem[]) => void;
}
export const ServiceaccountTable: React.FC<Props> = ({ title, selections, onSelectionsChange }) => {
  const { kind, id } = useTableKindId("serviceaccount", "policy-create-target-list-serviceaccount");
  const qData = useTableItemQueryData<ServiceAccount>(kind, { fetchAllPages: true });

  const serviceaccountTableItems = qData.visibleItems.map((serviceaccount) => {
    const selfLink = linksOf(serviceaccount).self!;

    return {
      ...serviceaccount,
      selfLink: selfLink,
    };
  });

  return (
    <Table<ServiceaccountTableItem>
      title={title}
      data={serviceaccountTableItems}
      tableId={id}
      selectMode="multi"
      selectKey={"selfLink"}
      selections={selections}
      onSelectionsChange={onSelectionsChange}
      headerRenderer={() => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
            <CustomTableCPLNQuery
              tableId={id}
              kind={kind}
              onQuery={qData.setQuery}
              isLoading={qData.isLoadingQueried}
            />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionNoLinkColumn(),
        {
          id: "origin",
          label: "Origin",
          canGroupByDistinctValues: true,
          groupLabelMap: {
            default: "Default",
            builtin: "Builtin",
          },
          size: 160,
        },
        TagsColumn(),
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};
