import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGInput } from "../../../newcomponents/input/input";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { InfoTooltip } from "../../../components/InfoTooltip";
import cronstrue from "cronstrue";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateJobRaw: React.FC<Props> = ({ workloadDraft }) => {
  const job = workloadDraft.job;

  return (
    <>
      <div className="mb-4">
        <NGLabel>Schedule (UTC)</NGLabel>
        <NGInput
          className="mb-2"
          style={{ width: 450 }}
          onChange={(e) => {
            job.schedule.setValue(e.target.value);
          }}
          value={job.schedule.value}
        />
        {job.schedule.value.length > 0 && job.schedule.isValid ? (
          <div>
            {cronstrue.toString(job.schedule.value, {
              throwExceptionOnParseError: false,
              use24HourTimeFormat: true,
            })}
          </div>
        ) : null}
      </div>
      <NGLabel>{job.concurrencyPolicy.label}</NGLabel>
      <div className="mb-4 relative flex items-center">
        <NGSelect
          style={{ width: 450, zIndex: 3 }}
          onChange={job.concurrencyPolicy.setValue}
          options={job.concurrencyPolicy.options}
          value={job.concurrencyPolicy.value as any}
        />
        <InfoTooltip
          title={`Either 'Forbid' or 'Replace'. This determines what Control Plane will do when the schedule requires a job to start, while a prior instance of the job is still running.`}
        />
      </div>
      <NGInputAdapter
        data={job.historyLimit}
        className="mb-4"
        style={{ width: 450 }}
        infoContents={[
          `The maximum number of completed job instances to display. This should be an integer between 1 and 10.`,
        ]}
      />
      <NGLabel>{job.restartPolicy.label}</NGLabel>
      <div className="mb-4 relative flex items-center">
        <NGSelect
          className="relative"
          style={{ width: 450, zIndex: 3 }}
          onChange={job.restartPolicy.setValue}
          options={job.restartPolicy.options}
          value={job.restartPolicy.value as any}
        />
        <InfoTooltip
          title={`Either 'OnFailure' or 'Never'. This determines what Control Plane will do when a job instance fails.`}
        />
      </div>
      <NGInputAdapter
        data={job.activeDeadlineSeconds}
        className="mb-4"
        style={{ width: 450 }}
        infoContents={[
          `The maximum number of seconds Control Plane will wait for the job to complete. If a job does not succeed or fail in the allotted time, Control Plane will stop the job, moving it into the Removed status.`,
        ]}
      />
    </>
  );
};

export const WorkloadCreateJob = observer(WorkloadCreateJobRaw);
