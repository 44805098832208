import { types, Instance } from "mobx-state-tree";

export const SelectOptionModel = types.model({
  label: types.string,
  value: types.string,
  disabled: types.optional(types.boolean, false),
  isLabel: types.optional(types.boolean, false),
});
export interface SelectOptionMobx extends Instance<typeof SelectOptionModel> {}
export interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
  isLabel?: boolean;
}

export const SelectModel = types
  .model({
    options: types.array(SelectOptionModel),
    initialValue: types.string,
    value: types.optional(types.string, ""),
    label: types.string,
    _isTouched: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isTouched() {
      return self._isTouched;
    },
    get option() {
      return self.options.find((o) => o.value === self.value)!;
    },
    get isDirty() {
      return self.initialValue !== self.value;
    },
  }))
  .views((self) => ({
    get isDirtyReason() {
      if (!self.isDirty) {
        return self.label + "Not dirty";
      }
      return self.label + `: InitialValue -> ${self.initialValue} | Value -> ${self.value}`;
    },
  }))
  .actions((self) => ({
    setIsTouched(value: boolean) {
      self._isTouched = value;
    },
    reset() {
      self.value = self.initialValue;
    },
    confirm() {
      self.initialValue = self.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setValue(value: string) {
      if (!self.options.map((o) => o.value).includes(value)) return;
      self.value = value;
    },
    setInitialValue(value: string) {
      self.initialValue = value;
      self.reset();
    },
    setOptions(options: SelectOption[]) {
      self.options.clear();
      for (let option of options) {
        self.options.push(option);
      }
    },
  }));

export interface SelectMobx extends Instance<typeof SelectModel> {}
