import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";
import { TableColumn } from "../../types";

const maxScaleDefault = { id: "maxScale", label: "Max Scale" };
export function WorkloadMaxScaleColumn<TData extends WorkloadTableItem>(column: TableColumn<TData> = maxScaleDefault) {
  const preset: TableColumn<TData> = {
    ...maxScaleDefault,
    enableHide: true,
    hiddenByDefault: true,
    enableSort: true,
    size: 130,
    cell: (p) => {
      const workload = p.row.original;

      return <span>{workload.maxScale === undefined ? "" : workload.maxScale}</span>;
    },
  };
  return Object.assign(preset, column);
}
