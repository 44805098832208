import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NodePoolLabel } from "../../nodePoolLabel";
import { NodePoolTaint } from "../../nodePoolTaint";
import { Dropdown, Modal, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { request, resourceLink } from "../../../../services/cpln";
import { Mk8sScriptModal } from "../../Mk8sScriptModal";
import { NGLabel } from "../../../../newcomponents/text/label";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { Theme } from "../../../../mobxStores/uiData/theme";
import { ChevronDown } from "react-feather";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderOblivusUnmanagedNodePoolsRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const navigate = useNavigate();
  const o = mk8sDraft.provider_oblivus!;

  const [joinScriptIndex, setJoinScriptIndex] = React.useState<number>(-1);
  const [joinIpAddress, setJoinIpAddress] = React.useState<string>("");
  const [joinScript, setJoinScript] = React.useState<string>("");
  const [isJoinScriptLoading, setIsJoinScriptLoading] = React.useState<boolean>(false);

  return (
    <>
      <NGLabel>Unmanaged Node Pools</NGLabel>
      {o.unmanagedNodePools.length > 0 ? (
        <div
          className="flex items-center table-labels px-2 py-1"
          style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
        >
          <div className="w-2/12">Name</div>
          <div className="w-4/12">Labels</div>
          <div className="w-4/12">Taints</div>
          <div className="w-2/12"></div>
        </div>
      ) : (
        <div
          className="border py-8 flex flex-col gap-4 mt-4 items-center justify-center"
          style={{ borderRadius: 6, width: 450 }}
        >
          <div className="text-lg">No unmanaged node pools are added.</div>
          <NGButton variant={"action"} onClick={() => navigate("-add")}>
            Add an Unmanaged Node Pool
          </NGButton>
        </div>
      )}
      {o.unmanagedNodePools.length > 0 ? (
        <div className="border border-t-0" style={{ borderBottomRightRadius: 6, borderBottomLeftRadius: 6 }}>
          {o.unmanagedNodePools.map((np, npIndex) => {
            const lastNodePool = npIndex === o.unmanagedNodePools.length - 1;
            return (
              <>
                <div key={np.index} className={`flex p-2 ${lastNodePool ? "" : "border-b"}`}>
                  <div className="w-2/12">
                    <Link to={`${np.index}`}>{np.name.value}</Link>
                  </div>
                  <div className="w-4/12">
                    {np.labels.items.map((l) => (
                      <NodePoolLabel label={{ key: l.firstValue, value: l.secondValue }} />
                    ))}
                  </div>
                  <div className="w-4/12">
                    {np.taints.items.map((l) => (
                      <NodePoolTaint taint={{ key: l.firstValue, value: l.secondValue, effect: l.thirdValue }} />
                    ))}
                  </div>
                  <div className="w-2/12 flex  justify-end gap-2">
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        onClick: async function ({ key }: any) {
                          switch (key) {
                            case "remove":
                              o.removeUnmanagedNodePoolAt(np.index);
                              break;
                            case "join":
                              if (mk8sDraft.isDirty) {
                                Modal.warning({
                                  title: "Unmanaged Pool Join Script",
                                  content: "Please save your changes before trying to create the join script.",
                                });
                                return;
                              }
                              setJoinScriptIndex(np.index);
                              break;
                            default:
                              break;
                          }
                        },
                        items: [
                          { key: "join", label: "Download Join Script" },
                          { key: "remove", label: "Remove", danger: true },
                        ],
                      }}
                    >
                      <NGButton
                        variant="primary"
                        outlined={Theme.theme === "light"}
                        size="small"
                        renderIcon={(hover, props) => <ChevronDown {...props} />}
                        data-testid="usage-more"
                      >
                        More
                      </NGButton>
                    </Dropdown>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      ) : null}
      {!!joinScript || joinScriptIndex === -1 ? null : (
        <Modal
          open={joinScriptIndex !== -1}
          title={"Unmanaged Node Pool Join Script"}
          footer={
            <div className="modal-actions">
              <NGButton
                variant="secondary"
                onClick={() => {
                  setJoinScriptIndex(-1);
                  setJoinIpAddress("");
                  setJoinScript("");
                }}
                disabled={isJoinScriptLoading}
              >
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={async () => {
                  try {
                    setIsJoinScriptLoading(true);
                    const {
                      data: { script },
                    } = await request({
                      method: "post",
                      url: resourceLink("mk8s", mk8sDraft._name) + "/-join",
                      body: {
                        nodePoolName: o.unmanagedNodePools.find((np) => np.index === joinScriptIndex)!.name.value,
                        ipAddress: joinIpAddress,
                      },
                    });
                    setIsJoinScriptLoading(false);
                    setJoinIpAddress("");
                    setJoinScript(script);
                  } catch (e) {
                    let errorMessage = e?.response?.data?.message;
                    if (!errorMessage) errorMessage = e.message;
                    notification.warning({ message: "Failed to create join script", description: errorMessage });
                    setIsJoinScriptLoading(false);
                  }
                }}
                disabled={isJoinScriptLoading || !joinIpAddress}
                loading={isJoinScriptLoading}
              >
                OK
              </NGButton>
            </div>
          }
          onCancel={() => {
            setJoinScriptIndex(-1);
            setJoinIpAddress("");
            setJoinScript("");
          }}
        >
          <div className="flex flex-col">
            <NGFormElement
              name="ipAddress"
              label={"IP Address"}
              value={joinIpAddress}
              onChange={(value) => setJoinIpAddress(value)}
            />
          </div>
        </Modal>
      )}
      {!!joinScript ? (
        <Mk8sScriptModal
          clusterName={mk8sDraft._name}
          nodePoolName={o.unmanagedNodePools.find((np) => np.index === joinScriptIndex)!.name.value}
          script={joinScript}
          poolType={"Unmanaged Node Pool"}
          scriptName={"Join Script"}
          extension={"sh"}
          onDone={() => {
            setJoinScriptIndex(-1);
            setJoinIpAddress("");
            setJoinScript("");
          }}
          visible={!!joinScript}
        />
      ) : null}
    </>
  );
};

export const ProviderOblivusUnmanagedNodePools = observer(ProviderOblivusUnmanagedNodePoolsRaw);
