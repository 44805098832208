import * as React from "react";
import { observer } from "mobx-react-lite";
import { homeLink, Link, nameOfKind, request, pluralNameOfKind, getCancelToken, linksOf } from "../../../services/cpln";
import { v4 as uuidv4 } from "uuid";
import { useDebounce } from "../../table/useDebounce";
import { fuzzySearchQuery } from "../../../mst/query";
import { CancelTokenSource } from "axios";
import { GenericItem, GenericItemMobx } from "../../../mst/item";
import { execOnEnterKey, getMobxModel } from "../../../services/utils";
import { StringWithImageRefMobx } from "../../../mobxDataModels/stringWithImageRefModel";
import { Loader, Search } from "react-feather";
import { Theme } from "../../../mobxStores/uiData/theme";

const CheckMark = () => (
  <span className="formmark ml-1 text-xs" style={{ color: "transparent", textShadow: "0 0 0 var(--color-action)" }}>
    &#10004;
  </span>
);
const RequiredMark = () => <span className="formmark ml-1 color-danger">*</span>;

export interface GenericResponse {
  items: GenericItem[];
  links: Link[];
}

interface Option {
  value: string;
  label: string;
  type: string;
}

interface Props {
  data: StringWithImageRefMobx;
  className?: string;
  style?: any;
  onEnter?: () => void;
  setValueOverride: (value: string) => void;
  onBlur?: () => void;
}
const StringWithImageRefRaw: React.FC<Props> = ({
  data,
  onEnter = () => {},
  className = "",
  style = {},
  setValueOverride,
  onBlur,
}) => {
  const elementIdRef = React.useRef(uuidv4());
  const elIdClass = `el-${elementIdRef.current}`;

  const prefixRef = React.useRef(`${homeLink("image")}/`);
  const prefix = prefixRef.current;
  const cancelTokenRef = React.useRef<CancelTokenSource | undefined>(undefined);
  const [_options, setOptions] = React.useState<Option[]>([]);
  const [dataVersion, setDataVersion] = React.useState(uuidv4());
  const [nextLink, setNextLink] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [showImages, setShowImages] = React.useState(false);
  const [optionIndex, setOptionIndex] = React.useState(() => getOptionIndex());

  const [search, setSearch] = React.useState("");
  const [debouncedSearch, setDebouncedSearch] = useDebounce("", 200);

  React.useEffect(() => {
    if (!data.value.startsWith(prefix)) return;
    fetchData();
  }, [debouncedSearch]);

  React.useEffect(() => {
    setNextLink(undefined);
    setDebouncedSearch(search);
  }, [search]);

  const parentRef = React.useRef<HTMLDivElement>(null as any);
  const inputRef = React.useRef<HTMLInputElement>(null as any);
  const imageOptionsRef = React.useRef<HTMLInputElement>(null as any);

  // syncing showImages
  React.useEffect(() => {
    try {
      const isAlsoOnlyOption = _options.length === 1 && _options[0].value === data.value;
      if (data.value.startsWith(prefix) && isFocused && !isAlsoOnlyOption && _options.length > 0) {
        setOptionIndex(getOptionIndex());
        setShowImages(true);
      } else {
        setShowImages(false);
      }
    } catch (e) {
      setShowImages(false);
    }
  }, [data.value, isFocused, _options]);

  // syncing search for images
  React.useEffect(() => {
    if (!showImages) {
      setSearch("");
    }
    const secondPart = data.value.split(prefix)[1];
    setSearch(secondPart);
  }, [showImages, data.value]);

  // handling outside click
  React.useEffect(() => {
    const fn = (e: any) => {
      const elementIdMatch = (e.target as HTMLElement).classList.contains(elIdClass);
      if (!elementIdMatch) {
        setIsFocused(false);
      }
    };
    document.addEventListener("click", fn);
    return () => document.removeEventListener("click", fn);
  }, []);

  // get option index for image options
  function getOptionIndex() {
    for (let optionIndex in getOptions()) {
      const option = getOptions()[optionIndex];
      if (option.value === data.value) {
        return Number(optionIndex);
      }
    }
    return 0;
  }

  async function fetchData() {
    try {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Cancelled");
      }
      cancelTokenRef.current = getCancelToken();
      const cancelToken = cancelTokenRef.current.token;
      const loadingTimeout = setTimeout(() => {
        setIsLoading(true);
      }, 200);
      const link = nextLink || (search ? `${homeLink(data.kind)}/-query` : homeLink(data.kind));
      const method = link.includes("query") ? "post" : "get";
      const { data: res } = await request<GenericResponse>({
        method,
        url: link,
        cancelToken,
        body: search ? fuzzySearchQuery(data.kind, search) : undefined,
      });
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
      const newItems = res.items.map((i: GenericItem) => ({
        value: `${prefix}${i.name}`,
        label: i.name,
        type: (i as any).type,
      }));
      const newOptions = nextLink ? [..._options, ...newItems] : [...newItems];
      const itemInstances: GenericItemMobx[] = [];
      for (let item of res.items) {
        try {
          // @ts-ignore
          itemInstances.push(getMobxModel(data.kind).create(item));
        } catch (e) {}
      }
      data.addItems(itemInstances);
      setDataVersion(uuidv4());
      setOptions(newOptions);
      setNextLink(linksOf(res).next);
    } catch (e) {
      console.error("fetch data fail", e.message);
    } finally {
      setIsLoading(false);
    }
  }

  function onClick_Parent(e: any) {
    e.stopPropagation();
    setIsFocused(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }

  const onKeyDown_Parent = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    const isEscapeKey = e.keyCode === 27;
    if (isEscapeKey) {
      if (parentRef.current) {
        setIsFocused(false);
        inputRef.current.blur();
      }
      return;
    }
    const isUpArrow = e.keyCode === 38;
    const isDownArrow = e.keyCode === 40;
    if (isUpArrow) {
      e.preventDefault();
      optionCycleUpward();
      return;
    } else if (isDownArrow) {
      e.preventDefault();
      optionCycleDownward();
      return;
    }
    const isAcceptKey = e.keyCode === 13 || e.keyCode === 32;
    if (isAcceptKey) {
      onClick_Option(getOptions()[optionIndex])(e);
    }
  };

  function optionCycleUpward() {
    if (!imageOptionsRef.current) return;
    const scrollTop = imageOptionsRef.current.scrollTop;
    if (scrollTop > 33) {
      imageOptionsRef.current.scrollTo(0, scrollTop - 33);
    }
    setOptionIndex((index) => Math.max(0, index - 1));
  }

  function optionCycleDownward() {
    if (!imageOptionsRef.current) return;
    let maxOptionIndex = getOptions().length - 1;
    const nextOptionIndex = Math.min(maxOptionIndex, optionIndex + 1);

    const scrollTop = imageOptionsRef.current.scrollTop;
    if (nextOptionIndex > 4) {
      imageOptionsRef.current.scrollTo(0, scrollTop + 33);
    }

    setOptionIndex(nextOptionIndex);
  }

  function onClick_Option(option: Option) {
    return function (e: any) {
      e?.stopPropagation();
      if (option.value === data.initialValue) {
        setValueOverride(data.initialValue);
        setOptions([option]);
      } else if (option.value !== data.value) {
        setValueOverride(option.value);
        setOptions([option]);
      }
    };
  }

  function onMouseEnter_Option(_: any, index: number) {
    setOptionIndex(index);
  }

  function onScroll_Options() {
    if (!nextLink) return;
    const { scrollHeight, scrollTop, clientHeight } = imageOptionsRef.current;
    const val = scrollHeight - (scrollTop + clientHeight);
    if (val < 1) {
      fetchData();
    }
  }

  function getOptions() {
    const options = [..._options];
    return options;
  }

  return (
    <div
      style={style}
      ref={parentRef}
      className={`relative flex flex-col cursor-pointer ${className} ${elIdClass}`}
      onKeyDown={onKeyDown_Parent}
      onClick={onClick_Parent}
    >
      <label
        htmlFor={`${data.label.toLowerCase()}`}
        className={`absolute left-4 transition-all cursor-text truncate ${
          data.value.length > 0 ? "top-1 font-medium text-xs" : "top-3 text-light"
        } ${elIdClass}`}
      >
        {isFocused
          ? data.value.includes(prefix)
            ? data.label
            : `${data.label} (Press CTRL+I for Images)`
          : data.label}
        {!data.value ? <RequiredMark /> : <CheckMark />}
        {/* {data.infoMark === "invalid" && <InvalidMark />} */}
      </label>

      <input
        ref={inputRef}
        id={`${data.label.toLowerCase()}`}
        className={`focus inline-block placeholder-light-600 border rounded flex-grow h-12 w-full ${
          data.value.length > 0 ? "pt-5 pb-1" : ""
        } ${elIdClass}`}
        autoComplete={"off"}
        onFocus={(_e) => setIsFocused(true)}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        onKeyDown={(e) => {
          if (e.ctrlKey && (e.key === data.shortcutKey || e.key.toUpperCase() === data.shortcutKey.toUpperCase())) {
            e.stopPropagation();
            e.preventDefault();
            setValueOverride(prefix);
          } else {
            if (!showImages) {
              execOnEnterKey(e)(onEnter);
            }
          }
        }}
        style={{
          paddingLeft: data.value.length > 0 ? 14.5 : 16,
          color: "var(--color-input)",
          backgroundColor: "var(--color-input-bg)",
          borderColor: "var(--color-input-border)",
        }}
        tabIndex={0}
        type={"text"}
        onChange={(e) => setValueOverride(e.target.value)}
        value={data.value}
      />

      {isFocused && showImages ? (
        <span
          data-testid="options"
          ref={imageOptionsRef}
          className={`absolute flex flex-col h-auto overflow-y-auto w-full top-12 text-sm border border-t-0 rounded z-10 max-h-64 ${elIdClass}`}
          onScroll={onScroll_Options}
          style={{
            color: "var(--color-input)",
            backgroundColor: "var(--color-drop)",
            borderColor: "var(--color-input-border)",
          }}
        >
          {!isLoading && getOptions().length < 1 ? (
            <div className="flex flex-col items-center py-8">
              <Search className="feather-icon mb-2" />
              <span className=" ">No {nameOfKind(data.kind)} Found</span>
            </div>
          ) : null}
          {getOptions().map((option, index) => (
            <span
              data-testid={`option-${option.label}`}
              key={option.value + index}
              onMouseEnter={(e) => onMouseEnter_Option(e, index)}
              className={`px-4 py-1 border-b flex items-center justify-between ${elIdClass}`}
              style={{
                backgroundColor:
                  index === optionIndex
                    ? Theme.theme === "light"
                      ? "var(--color-gray-200)"
                      : "var(--color-gray-1200)"
                    : "",
              }}
              onClick={onClick_Option(option)}
            >
              <span className={`${option.value === data.value ? " " : ""} truncate ${elIdClass}`}>{option.label}</span>
              {option.type ? <span>{option.type}</span> : null}
            </span>
          ))}
          {isLoading ? (
            <div key={dataVersion} className="flex flex-col items-center py-4">
              <Loader className="feather-icon mb-2" />
              <span className=" ">
                {search ? "Searching" : "Loading"} More {pluralNameOfKind(data.kind)}
              </span>
            </div>
          ) : nextLink ? (
            <div key={dataVersion} className="flex flex-col items-center py-4">
              <Search className="feather-icon mb-2" />
              <span className=" ">
                {search ? "Search" : "Load"} More {pluralNameOfKind(data.kind)}
              </span>
            </div>
          ) : null}
        </span>
      ) : null}
    </div>
  );
};

export const StringWithImageRef = observer(StringWithImageRefRaw);
