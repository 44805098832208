import * as React from "react";
import { Table } from "../../../../newcomponents/table/table";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { linksOf } from "../../../../services/cpln";
import { Quota } from "../../../../schema/types/quota";
import { QuotaTableItem as QuotaTableItemBase, quotaHelpers } from "../../../../mst/kinds/quota.helpers";

interface QuotaTableItem extends QuotaTableItemBase {
  selfLink: string;
}

interface Props {
  title: string;
  selections: string[];
  onSelectionsChange: (values: string[]) => void;
}
export const QuotaTable: React.FC<Props> = ({ title, selections, onSelectionsChange }) => {
  const { kind, id } = useTableKindId("quota", "policy-create-target-list-quota");
  const qData = useTableItemQueryData<Quota>(kind, { fetchAllPages: true });

  const quotaTableItems: QuotaTableItem[] = qData.visibleItems.map((item) => {
    const quota = quotaHelpers.getQuota(item);
    const gvcLink = quotaHelpers.getGvcLink(item);
    const quotaRouteLink = quotaHelpers.getQuotaRouteLink(item);
    const selfLink = linksOf(item).self!;

    return { ...item, quota: quota, gvcLink: gvcLink, quotaRouteLink: quotaRouteLink, selfLink: selfLink };
  });

  return (
    <Table<QuotaTableItem>
      title={title}
      data={quotaTableItems}
      tableId={id}
      selectMode="multi"
      selectKey={"selfLink"}
      selections={selections}
      onSelectionsChange={onSelectionsChange}
      headerRenderer={() => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
            <CustomTableCPLNQuery
              tableId={id}
              kind={kind}
              onQuery={qData.setQuery}
              isLoading={qData.isLoadingQueried}
            />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionNoLinkColumn(),
        { id: "current", label: "Current", size: 200 },
        { id: "max", label: "Max", size: 200 },
        {
          id: "gvcLink",
          label: "GVC",
          enableResize: true,
          canGroupByDistinctValues: true,
          groupLabelMap: { "": "Org Level Quotas" },
          enableSort: true,
        },
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};
