import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel } from "../base";
import { request } from "../../services/cpln";
import { Secret } from "../../schema/types/secret";

const EncodingProperty = types.optional(types.enumeration("encoding", ["plain", "base64"]), "plain");

const OpaqueDataModel = types.model("Opaque", {
  payload: "",
  encoding: EncodingProperty,
});
export interface OpaqueDataMobx extends Instance<typeof OpaqueDataModel> {}

const AzureConnectorDataModel = types.model("AzureConnector", {
  url: "",
  code: "",
});
export interface AzureConnectorDataMobx extends Instance<typeof AzureConnectorDataModel> {}

const AWSDataModel = types.model("AwsKey", {
  secretKey: types.refinement(types.string, (value) => /^AKIA.*/.test(value)),
  accessKey: "",
  roleArn: types.refinement(types.string, (value) => /^arn:.*/.test(value)),
  externalId: types.maybe(types.string),
});
export interface AWSDataMobx extends Instance<typeof AWSDataModel> {}

const ECRDataModel = types.model("ECR", {
  secretKey: types.refinement(types.string, (value) => /^AKIA.*/.test(value)),
  accessKey: "",
  roleArn: types.refinement(types.string, (value) => /^arn:.*/.test(value)),
  repos: types.array(types.string),
  externalId: types.maybe(types.string),
});
export interface ECRDataMobx extends Instance<typeof ECRDataModel> {}

const UserpassDataModel = types.model("Userpass", {
  username: "",
  password: "",
});
export interface UserpassDataMobx extends Instance<typeof UserpassDataModel> {}

const TLSDataModel = types.model("Tls", {
  key: "",
  cert: "",
  chain: "",
});
export interface TLSDataMobx extends Instance<typeof TLSDataModel> {}

const KeyPairDataModel = types.model("KeyPair", {
  secretKey: "",
  publicKey: "",
  passphrase: types.maybe(types.string),
});
export interface KeyPairDataMobx extends Instance<typeof KeyPairDataModel> {}

const NatsAccountDataModel = types.model("KeyPair", {
  accountId: "", // /^A[A-Z0-9]{55}$/
  privateKey: "", // /^SA[A-Z0-9]{56}$/
});
export interface NatsAccountDataMobx extends Instance<typeof NatsAccountDataModel> {}

export const SecretModel = types
  .compose(
    "Secret",
    BaseModel,
    types.model({
      type: types.enumeration("type", [
        "aws",
        "azure-connector",
        "azure-sdk",
        "docker",
        "dictionary",
        "ecr",
        "gcp",
        "keypair",
        "nats-account",
        "opaque",
        "tls",
        "userpass",
      ]),
      data: types.optional(types.frozen(), {}),
    })
  )
  .views((self) => ({
    get typePretty() {
      switch (self.type) {
        case "aws":
          return "AWS";
        case "azure-sdk":
          return "Azure SDK";
        case "azure-connector":
          return "Azure Connector";
        case "docker":
          return "Docker";
        case "dictionary":
          return "Dictionary";
        case "ecr":
          return "ECR";
        case "gcp":
          return "GCP";
        case "keypair":
          return "Keypair";
        case "nats-account":
          return "NATS Account";
        case "opaque":
          return "Opaque";
        case "tls":
          return "TLS";
        case "userpass":
          return "Username & Password";
        default:
          return "";
      }
    },
  }))
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    const reveal: () => Promise<Secret> = flow(function* () {
      const { data }: any = yield request({ url: self.selfLink + "/-reveal" });
      return data;
    });
    return { reveal, fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface SecretMobx extends Instance<typeof SecretModel> {}
