import * as React from "react";
import { observer } from "mobx-react-lite";
import { Dropdown, Menu } from "antd";
import { MoreHorizontal } from "react-feather";
import {
  IdentityDraftNetworkResourceMobx,
  IdentityDraftNetworkResourceModel,
} from "../../../mst/stores/identity.draft.networkresource";
import { RemovableValue } from "../../../components/tag/removableValue";
import {
  NetworkResourcesTable,
  NetworkResourcesTableHeaderItem,
  NetworkResourceModalType,
} from "../../../components/networkResources/networkResourcesTable";
import { IdentityDraftMobx } from "../../../mst/stores/identity.draft";
import { NetworkResourceTableItem } from "./networkResourceTableItem";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGCheckbox } from "../../../newcomponents/checkbox";
import NGAlert from "../../../newcomponents/alert";
import { NGInput } from "../../../newcomponents/input/input";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  type: "fqdn" | "ip";
  identity: IdentityDraftMobx;
}

const NetworkResourcesBaseRaw: React.FC<Props> = ({ type, identity }) => {
  const [isTableMenuOpen, setIsTableMenuOpen] = React.useState<boolean>(false);
  const [isViewResolverIP, setIsViewResolverIP] = React.useState<boolean>(false);

  const tableMenu = () => (
    <Menu className="menu">
      <Menu.Item key={"action"} data-testid="action-view">
        <NGCheckbox className="select-none" checked={isViewResolverIP} onChange={onViewResolverIpChanged}>
          <span>View Resolver IP</span>
        </NGCheckbox>
      </Menu.Item>
    </Menu>
  );

  function isNameConflicting(resource: IdentityDraftNetworkResourceMobx) {
    if (identity.networkResourceNames.filter((n) => n === resource.resourceName).length > 1) {
      return true;
    }
    return false;
  }

  function isFqdnConflicting(resource: IdentityDraftNetworkResourceMobx) {
    if (resource.discover === "fqdn") {
      if (identity.networkResourceFqdns.filter((f) => f === resource.fqdnInput.value).length > 1) {
        return true;
      }
    }
    return false;
  }

  function isResourceValid(resource: IdentityDraftNetworkResourceMobx) {
    let res = resource.isValid;
    if (isNameConflicting(resource)) res = false;
    if (isFqdnConflicting(resource)) res = false;
    return res;
  }

  // Use Effects //
  React.useEffect(() => {
    const value = localStorage.getItem("view-resolver-ip");
    if (value === null) {
      return;
    }
    setIsViewResolverIP(value === "true");
  }, []);

  function onViewResolverIpChanged(value: boolean): void {
    localStorage.setItem(`view-resolver-ip`, value ? "true" : "false");
    setIsViewResolverIP(value);
  }

  function onOpenModalForAddition(setItemToCreate: (resource: IdentityDraftNetworkResourceMobx) => void): void {
    const resource = IdentityDraftNetworkResourceModel.create();
    resource.setDiscover(type);
    setItemToCreate(resource);
  }

  function onOpenModalForEdit(
    setItemToCreate: (resource: IdentityDraftNetworkResourceMobx) => void,
    resourceToEdit: IdentityDraftNetworkResourceMobx,
  ): void {
    const newResource = IdentityDraftNetworkResourceModel.create();
    copyResource(resourceToEdit, newResource);
    setItemToCreate(newResource);
  }

  function copyResource(
    sourceResource: IdentityDraftNetworkResourceMobx,
    targetResource: IdentityDraftNetworkResourceMobx,
  ): void {
    targetResource.clear();
    targetResource.setDiscover(sourceResource.discover as any);
    targetResource.setNameInp(sourceResource.resourceName);
    targetResource.fqdnInput.setValue(sourceResource.fqdnInput.value);
    targetResource.setAgentName(sourceResource.agentName);
    targetResource.resolverIpInput.setValue(sourceResource.resolverIpInput.value);

    for (const ip of sourceResource.IPs) {
      targetResource.ipInput.setValue(ip);
      targetResource.addIP();
    }

    for (const port of sourceResource.ports) {
      targetResource.portInput.setValue(port);
      targetResource.addPort();
    }
  }

  function onConfirmModal(
    modalState: NetworkResourceModalType,
    resource: IdentityDraftNetworkResourceMobx,
    resourceToEdit: IdentityDraftNetworkResourceMobx | undefined,
  ) {
    switch (modalState) {
      case "edit":
        copyResource(resource, resourceToEdit!);
        break;
      default:
        identity.networkResources.addPreDefinedResource(resource);
        break;
    }
  }

  function isSearchedItemFound(resource: IdentityDraftNetworkResourceMobx, searchContent: string): boolean {
    return type === "fqdn"
      ? resource.fqdnInput.value.includes(searchContent)
      : resource.resourceName.includes(searchContent);
  }

  function buildTableHeader(): NetworkResourcesTableHeaderItem[] {
    let result: NetworkResourcesTableHeaderItem[] = [];
    switch (type) {
      case "fqdn":
        result = [
          {
            widthProportion: "3/12",
            content: <span>Name</span>,
          },
          {
            widthProportion: isViewResolverIP ? "2/12" : "3/12",
            content: <span>Agent</span>,
          },
          {
            widthProportion: isViewResolverIP ? "2/12" : "3/12",
            content: <span>FQDN</span>,
          },
          {
            widthProportion: "2/12",
            content: <span>Resolver IP</span>,
            isHidden: !isViewResolverIP,
          },
          {
            widthProportion: "2/12",
            content: <span>Ports</span>,
          },
          {
            widthProportion: "1/12",
            content: (
              <Dropdown
                className="ml-auto ngfocus color-link-hover cursor-pointer"
                open={isTableMenuOpen}
                onOpenChange={setIsTableMenuOpen}
                dropdownRender={tableMenu}
              >
                <MoreHorizontal size={24} />
              </Dropdown>
            ),
          },
        ];
        break;
      case "ip":
        result = [
          {
            widthProportion: "3/12",
            content: <span>Name</span>,
          },
          {
            widthProportion: "3/12",
            content: <span>Agent</span>,
          },
          {
            widthProportion: "3/12",
            content: <span>IP (v4)</span>,
          },
          {
            widthProportion: "2/12",
            content: <span>Ports</span>,
          },
        ];
        break;
    }
    return result;
  }

  function buildModal(resource: IdentityDraftNetworkResourceMobx): React.ReactNode {
    const fqdnConflicts = isFqdnConflicting(resource);
    const nameConflicts = isNameConflicting(resource);

    return (
      <div>
        {type === "fqdn" ? (
          <>
            <NGFormLabel required invalid={fqdnConflicts || !resource.fqdnInput.isValid}>
              {resource.fqdnInput.label}
            </NGFormLabel>
            <div className="mb-2 w-full">
              <NGInput value={resource.fqdnInput.value} onChange={(e) => resource.fqdnInput.setValue(e.target.value)} />
              {fqdnConflicts ? (
                <NGAlert
                  className="mt-2"
                  message="FQDN must be unique across all types of network resources"
                  size="small"
                  type={"error"}
                />
              ) : null}
            </div>
          </>
        ) : null}
        <>
          <NGFormLabel required invalid={!resource.isResourceNameValid}>
            Name
          </NGFormLabel>
          <div className="w-full mb-2">
            <NGInput
              value={resource.nameInp}
              placeholder={resource.resourceName}
              onChange={(e) => resource.setNameInp(e.target.value)}
            />
            {nameConflicts ? (
              <NGAlert
                className="mt-2"
                message="Name must be unique across all types of network resources"
                type={"error"}
                size={"small"}
              />
            ) : null}
          </div>
        </>
        <NGFormLabel required invalid={!resource.agentName}>
          Agent
        </NGFormLabel>
        <NGKindSelect
          placeholder=" "
          className="mb-2"
          kind={"agent"}
          value={resource.agentName}
          onChange={resource.setAgentName}
        />
        {type === "fqdn" /*&& isViewModalResolverIP*/ ? (
          <div className="flex items-center mb-2">
            <div className="w-full">
              <NGFormLabel invalid={!resource.resolverIpInput.isValid}>Resolver IP</NGFormLabel>
              <NGInput
                className="flex-grow"
                value={resource.resolverIpInput.value}
                onChange={(e) => resource.resolverIpInput.setValue(e.target.value)}
              />
            </div>
            <InfoTooltip
              className="mt-6"
              title="Optionally resolve the FQDN of the server using the provided DNS resolver"
            />
          </div>
        ) : null}
        {type === "ip" ? (
          <>
            <NGFormLabel required invalid={resource.IPs.length < 1}>
              IPs
            </NGFormLabel>
            {resource.IPs.length === 0 ? (
              <NGAlert
                style={{ width: 450 }}
                className="mt-1 mb-2"
                type={"warning"}
                size={"small"}
                message={"At least 1 IP is required"}
              />
            ) : null}
            <div className={`mt-1 ${resource.IPs.length > 4 ? "mb-2" : ""}`}>
              {resource.IPs.map((ip: string) => (
                <RemovableValue key={ip} value={ip} className="mb-2 inline" onRemove={() => resource.removeIP(ip)} />
              ))}
            </div>
            {resource.IPs.length < 5 ? (
              <div className={`flex mb-2`}>
                <NGInput
                  className="flex-grow mr-2"
                  value={resource.ipInput.value}
                  placeholder={resource.ipInput.label}
                  onChange={(e) => resource.ipInput.setValue(e.target.value)}
                />
                <NGButton
                  variant={"action"}
                  style={{ width: 150 }}
                  onClick={resource.addIP}
                  disabled={!resource.canAddIP}
                >
                  Add IP
                </NGButton>
              </div>
            ) : null}
          </>
        ) : null}
        <NGFormLabel required invalid={resource.ports.length < 1}>
          Ports
        </NGFormLabel>
        {resource.ports.length === 0 ? (
          <NGAlert className="mt-1 mb-2" message={"At least 1 port is required"} type={"warning"} size={"small"} />
        ) : null}
        <div className={`flex items-center mt-1 ${resource.ports.length > 4 ? "mb-2" : ""}`}>
          {resource.ports.map((port: string) => (
            <RemovableValue key={port} value={port} onRemove={() => resource.removePort(port)} />
          ))}
        </div>
        {resource.ports.length < 5 ? (
          <div className={`flex`}>
            <NGInput
              className="flex-grow mr-2"
              value={resource.portInput.value}
              placeholder={resource.portInput.label}
              onChange={(e) => resource.portInput.setValue(e.target.value)}
            />
            <NGButton
              variant={"action"}
              style={{ width: 150 }}
              onClick={resource.addPort}
              disabled={!resource.canAddPort}
            >
              Add Port
            </NGButton>
          </div>
        ) : null}
        {/* {type === "fqdn" ? (
          <NGCheckbox className="mt-2" checked={isViewModalResolverIP} onChange={onViewModalResolverIpChanged}>
            View Resolver IP
          </NGCheckbox>
        ) : null} */}
      </div>
    );
  }

  function buildItem(resource: IdentityDraftNetworkResourceMobx, ـ: number): React.ReactNode {
    return <NetworkResourceTableItem type={type} resource={resource} isViewResolverIP={isViewResolverIP} />;
  }

  function onItemRemoval(resource: IdentityDraftNetworkResourceMobx): void {
    identity.networkResources.removeByResource(resource);
  }

  return (
    <NetworkResourcesTable
      typeName={type === "fqdn" ? "FQDN" : "IP"}
      filterPlaceholder={`Search by ${type === "fqdn" ? "FQDN" : "Name"}`}
      header={buildTableHeader()}
      items={identity.networkResources.resources.filter((resource) => resource.discover === type)}
      onOpenModalForAddition={onOpenModalForAddition}
      onOpenModalForEdit={onOpenModalForEdit}
      onConfirmModal={onConfirmModal}
      isOkButtonDisabled={(resource: IdentityDraftNetworkResourceMobx) => !isResourceValid(resource)}
      isSearchedItemFound={isSearchedItemFound}
      buildModal={buildModal}
      buildItem={buildItem}
      onItemRemoval={onItemRemoval}
    />
  );
};

export const NetworkResourcesBase = observer(NetworkResourcesBaseRaw);
