import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";
import { TableColumn } from "../../types";

const readyReplicasDefault = { id: "readyReplicas", label: "Ready Replicas" };
export function WorkloadReadyReplicasColumn<TData extends WorkloadTableItem>(
  column: TableColumn<TData> = readyReplicasDefault,
) {
  const preset: TableColumn<TData> = {
    ...readyReplicasDefault,
    enableHide: true,
    hiddenByDefault: true,
    size: 170,
    cell: (p) => {
      const workload = p.row.original;

      return (
        <span>
          {workload.readyReplicas}/{workload.totalReplicas}
        </span>
      );
    },
  };
  return Object.assign(preset, column);
}
