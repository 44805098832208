import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderOblivus } from "./oblivus";
import { Mk8sCreateProviderOblivusNodePools } from "./nodePools";
import { Mk8sCreateProviderOblivusNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderOblivusNodePool } from "./nodePool";
import { Mk8sCreateProviderOblivusUnmanagedNodePools } from "./unmanagedNodePools";
import { Mk8sCreateProviderOblivusUnmanagedNodePoolAdd } from "./unmanagedNodePoolAdd";
import { Mk8sCreateProviderOblivusUnmanagedNodePool } from "./unmanagedNodePool";
import { Mk8sCreateProviderOblivusAdvanced } from "./advanced";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderOblivusRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderOblivus mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderOblivusNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderOblivusNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderOblivusNodePool mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-unmanaged-node-pools`}
        element={<Mk8sCreateProviderOblivusUnmanagedNodePools mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/-add`}
        element={<Mk8sCreateProviderOblivusUnmanagedNodePoolAdd mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/:index`}
        element={<Mk8sCreateProviderOblivusUnmanagedNodePool mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-advanced`} element={<Mk8sCreateProviderOblivusAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderOblivusRoute = observer(Mk8sCreateProviderOblivusRouteRaw);
