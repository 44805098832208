import * as React from "react";

export const ContainersLabel: React.FC = () => {
  return (
    <div
      className="flex items-center w-full table-labels text-sm px-4 py-2"
      style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
    >
      <div className={"w-2/12"}>
        <span>Container Name</span>
      </div>
      <div className={"w-3/12"}>
        <span>Image</span>
      </div>
      <div className={"w-1/12"}>
        <span>Status</span>
      </div>
      <div className={"w-2/12 pl-4"}>
        <span>CPU Reserved</span>
      </div>
      <div className={"w-2/12"}>
        <span>Memory Reserved</span>
      </div>
      <div className={"w-2/12"}>
        <span>Replicas</span>
      </div>
    </div>
  );
};
