import { types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { inputValidations } from "../../mobxDataModels/validations";

export const Mk8sDraftProviderTritonNodePoolReadonlyModel = types.model({
  name: types.optional(types.string, ""),
  labels: types.array(
    types.model({
      key: types.string,
      value: types.string,
    }),
  ),
  taints: types.array(
    types.model({
      key: types.string,
      value: types.string,
      effect: types.string,
    }),
  ),
  tritonTags: types.array(
    types.model({
      key: types.string,
      value: types.string,
    }),
  ),
  packageId: types.optional(types.string, ""),
  overrideImageId: types.optional(types.string, ""),
  publicNetworkId: types.optional(types.string, ""),
  privateNetworkIds: types.array(types.string),
  minSize: types.optional(types.number, 0),
  maxSize: types.optional(types.number, 0),
});

export const Mk8sDraftProviderTritonNodePoolModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["default", "added"]), "default"),
    _pool: types.optional(Mk8sDraftProviderTritonNodePoolReadonlyModel, () =>
      Mk8sDraftProviderTritonNodePoolReadonlyModel.create(),
    ),
    _name: types.optional(types.string, ""),
    name: types.optional(StringModel, () =>
      StringModel.create({ label: "Name", validationKey: "nodePoolName", isRequired: true }),
    ),
    _overrideImageId: types.optional(types.string, ""),
    overrideImageId: types.optional(StringModel, () => StringModel.create({ label: "Override Image Id" })),
    _publicNetworkId: types.optional(types.string, ""),
    publicNetworkId: types.optional(StringModel, () => StringModel.create({ label: "Public Network Id" })),
    privateNetworkIds: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    labels: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    taints: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    tritonTags: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _packageId: types.optional(types.string, ""),
    packageId: types.optional(StringModel, () => StringModel.create({ label: "Package Id", isRequired: true })),
    _minSize: types.optional(types.string, ""),
    minSize: types.optional(StringModel, () => NumberModel.create({ label: "Min Size", isRequired: true, min: 0 })),
    _maxSize: types.optional(types.string, ""),
    maxSize: types.optional(StringModel, () => NumberModel.create({ label: "Max Size", isRequired: true, min: 0 })),
  })
  .views((self) => ({
    get tritonTagsAsObject() {
      const tritonTags: { [_: string]: string } = {};
      self.tritonTags.items.forEach((l) => {
        tritonTags[l.firstValue] = l.secondValue;
      });
      return tritonTags;
    },
  }))
  .actions((self) => ({
    setStatusAdded() {
      self.status = "added";
    },
    reset() {
      self.name.setInitialValue(self._pool.name);
      self.overrideImageId.setInitialValue(self._pool.overrideImageId);
      self.publicNetworkId.setInitialValue(self._pool.publicNetworkId);
      self.privateNetworkIds.setInitialItems(self._pool.privateNetworkIds.map((n) => ({ firstValue: n })));
      self.privateNetworkIds.reset();
      self.labels = ListOfItemsModel.create({
        _items: self._pool.labels.map((l) => ({ firstValue: l.key, secondValue: l.value })),
      });
      self.taints = ListOfItemsModel.create({
        _items: self._pool.taints.map((l) => ({ firstValue: l.key, secondValue: l.value, thirdValue: l.effect })),
      });
      self.tritonTags = ListOfItemsModel.create({
        _items: self._pool.tritonTags.map((l) => ({ firstValue: l.key, secondValue: l.value })),
      });
      self.packageId.setInitialValue(self._pool.packageId);
      self.minSize.setInitialValue(String(self._pool.minSize).length > 0 ? String(self._pool.minSize) : "");
      self.maxSize.setInitialValue(String(self._pool.maxSize).length > 0 ? String(self._pool.maxSize) : "");
    },
    confirm() {
      self.name.confirm();
      self.overrideImageId.confirm();
      self.publicNetworkId.confirm();
      self.privateNetworkIds.confirm();
      self.labels.confirm();
      self.taints.confirm();
      self.tritonTags.confirm();
      self.packageId.confirm();
      self.minSize.confirm();
      self.maxSize.confirm();

      const _pool: any = {
        name: self.name.value, //
        overrideImageId: self.overrideImageId.value,
        publicNetworkId: self.publicNetworkId.value,
        privateNetworkIds: self.privateNetworkIds.items.map((i) => i.firstValue),
        // labels: self.labels.map((l) => ({ key: l.key.value, value: l.value.value })),
        // taints: self.taints.map((t) => ({ key: t.key.value, value: t.value.value, effect: t.effect.value })),
        // tritonTags: self.tritonTagsAsObject,
        packageId: self.packageId.value,
        minSize: String(self.minSize.value).length > 0 ? Number(self.minSize.value) : undefined,
        maxSize: String(self.maxSize.value).length > 0 ? Number(self.maxSize.value) : undefined,
      };

      self._pool = Mk8sDraftProviderTritonNodePoolReadonlyModel.create(_pool);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isLabelsValid() {
      let res = true;
      if (self.labels.items.some((l) => !l.firstValue || !l.secondValue)) res = false;
      return res;
    },
    get isTaintsValid() {
      let res = true;
      if (self.taints.items.some((t) => !t.firstValue || !t.secondValue || !t.thirdValue)) res = false;
      return res;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.isLabelsValid) res = false;
      if (!self.isTaintsValid) res = false;
      if (!self.overrideImageId.isValid) res = false;
      if (!self.publicNetworkId.isValid) res = false;
      if (!self.packageId.isValid) res = false;
      if (!self.minSize.isValid) res = false;
      if (!self.maxSize.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.name.isValid) reason = "name is invalid";
      if (!self.isLabelsValid) reason = "labels not valid";
      if (!self.isTaintsValid) reason = "taints not valid";
      if (!self.overrideImageId.isValid) reason = "overrideImageId is invalid";
      if (!self.publicNetworkId.isValid) reason = "publicNetworkId is invalid";
      if (!self.packageId.isValid) reason = "packageId is invalid";
      if (!self.minSize.isValid) reason = self.minSize.invalidReason;
      if (!self.maxSize.isValid) reason = self.maxSize.invalidReason;
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.overrideImageId.isDirty) res = true;
      if (self.publicNetworkId.isDirty) res = true;
      if (self.privateNetworkIds.isDirty) res = true;
      if (self.labels.isDirty) res = true;
      if (self.taints.isDirty) res = true;
      if (self.tritonTags.isDirty) res = true;
      if (self.packageId.isDirty) res = true;
      if (self.minSize.isDirty) res = true;
      if (self.maxSize.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.name.isDirty) reason = self.name.isDirtyReason;
      if (self.overrideImageId.isDirty) reason = self.overrideImageId.isDirtyReason;
      if (self.publicNetworkId.isDirty) reason = self.publicNetworkId.isDirtyReason;
      if (self.privateNetworkIds.isDirty) reason = "privateNetworkIds are dirty";
      if (self.labels.isDirty) reason = "labels are dirty";
      if (self.taints.isDirty) reason = "taints are dirty";
      if (self.tritonTags.isDirty) reason = "tritonTags are dirty";
      if (self.packageId.isDirty) reason = self.packageId.isDirtyReason;
      if (self.minSize.isDirty) reason = self.minSize.isDirtyReason;
      if (self.maxSize.isDirty) reason = self.maxSize.isDirtyReason;
      return reason;
    },
    get asObject() {
      const labels: { [_: string]: string } = {};
      self.labels.items.forEach((l) => {
        labels[l.firstValue] = l.secondValue;
      });

      const obj: any = {
        name: self.name.value,
        labels: labels,
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
        tritonTags: self.tritonTagsAsObject,
        packageId: self.packageId.value,
        minSize: Number(self.minSize.value),
        maxSize: Number(self.maxSize.value),
      };
      if (self.overrideImageId.value) {
        obj.overrideImageId = self.overrideImageId.value;
      }
      if (self.publicNetworkId.value) {
        obj.publicNetworkId = self.publicNetworkId.value;
      }
      if (self.privateNetworkIds.items.length > 0) {
        obj.privateNetworkIds = self.privateNetworkIds.items.map((i) => i.firstValue);
      }
      return obj;
    },
  }));
