import * as React from "react";
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { NameModel } from "../../../mobxDataModels/nameModel";
import { GroupCreateGeneral } from "./general";
import { ItemStoreQueryModel } from "../../../mst/query";
import { homeLink, request, resourceLink } from "../../../services/cpln";
import { CreateFormLink } from "../../org/create/create";
import { TagsNewModel } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { IdentityMatcher } from "../identityMatcher/identityMatcher";
import { IdentityMatcherLanguage } from "../../../mst/kinds/group";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { GroupCreateMembers } from "./members";

const GroupCreateRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const basePath = useBasePath("/group/-create/*");

  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));
  const [language, setLanguage] = React.useState<IdentityMatcherLanguage>("jmespath");
  const [expression, setExpression] = React.useState("");
  const tagsRef = React.useRef(TagsNewModel.create());
  const [userLinks, setUserLinks] = React.useState<string[]>([]);
  const [serviceaccountLinks, setServiceAccountLinks] = React.useState<string[]>([]);
  const userQueryRef = React.useRef(ItemStoreQueryModel.create());
  const [useQuery, setUseQuery] = React.useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  function getGroupObject() {
    let body: any = {
      name: nameRef.current.value,
      description: descriptionRef.current.value || nameRef.current.value,
      memberLinks: [...userLinks, ...serviceaccountLinks],
      tags: tagsRef.current.asObject,
    };
    if (expression.length > 0) {
      body.identityMatcher = {
        language,
        expression,
      };
    }
    if (useQuery && userQueryRef.current.model.spec.terms.length > 0) {
      body.memberQuery = userQueryRef.current.model.toQueryObj;
    }
    return { kind: "group", ...body };
  }

  async function onConfirm() {
    try {
      setIsLoading(true);

      await request({ method: "post", url: homeLink("group"), body: getGroupObject() });
      const itemLink = resourceLink("group", getGroupObject().name);
      notification.success({
        message: "Success",
        description: "Group is created",
      });
      PromptContext.setIsDisabled(true);
      navigate(`/console${itemLink}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    let res = true;
    const allowedList = [
      "-general",
      "-tags",
      "-members",
      "-members/-users",
      "-members/-serviceaccounts",
      "-members/-userquery",
      "-identitymatcher",
    ];
    for (let allowItem of allowedList) {
      if (pathname.includes(allowItem)) res = false;
    }
    return res;
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    { name: "Members", value: `-members`, isActive: nameRef.current.isValid },
    { name: "Identity Matcher", value: `-identitymatcher`, isActive: nameRef.current.isValid },
    { name: "Tags", value: `-tags`, isActive: nameRef.current.isValid },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("/-general")) {
    next.isActive = nameRef.current.isValid;
    next.label = `Next (Members)`;
    next.url = `-members`;
  } else if (pathname.includes("/-members")) {
    next.isActive = nameRef.current.isValid;
    next.label = `Next (Identity Matcher)`;
    next.url = `-identitymatcher`;
  } else if (pathname.includes("/-identitymatcher")) {
    next.isActive = nameRef.current.isValid;
    next.label = `Next (Tags)`;
    next.url = `-tags`;
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={getGroupObject}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Group"}
          name={nameRef.current.value}
          canCreate={nameRef.current.isValid && tagsRef.current.isValid}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route
              path={`-general`}
              element={<GroupCreateGeneral name={nameRef.current} description={descriptionRef.current} />}
            />
            <Route
              path={`-members/*`}
              element={
                <GroupCreateMembers
                  useQuery={useQuery}
                  setUseQuery={setUseQuery}
                  userQuery={userQueryRef.current}
                  setUserLinks={setUserLinks}
                  setServiceAccountLinks={setServiceAccountLinks}
                />
              }
            />
            <Route
              path={`-identitymatcher`}
              element={
                <IdentityMatcher
                  expression={expression}
                  setExpression={setExpression}
                  language={language}
                  setLanguage={setLanguage}
                />
              }
            />
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const GroupCreate = observer(GroupCreateRaw);
