import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { homeLink, request } from "../../services/cpln";
import { SecretMobx } from "../../mst/kinds/secret";
import { NGButton } from "../../newcomponents/button/Button";
import { NGInput } from "../../newcomponents/input/input";
import { NGLabel } from "../../newcomponents/text/label";

interface Props {
  onOk: (secretName: string, secretMobx?: SecretMobx) => void;
  title: string;
  dataLabel: string;
  onClose: () => void;
}
const NGProviderCreateOpaqueSecretRaw: React.FC<Props> = ({ onOk, title, dataLabel, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [secretData, setSecretData] = React.useState("");
  const [secretName, setSecretName] = React.useState("");

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({
        method: "post",
        url: homeLink("secret"),
        body: { name: secretName, data: { payload: secretData, encoding: "plain" }, type: "opaque" },
      });
      setIsLoading(false);
      onOk(secretName);
      setSecretData("");
      setSecretName("");
      notification.success({ message: "Success", description: "Created new secret" });
      onClose();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });
      setIsLoading(false);
    }
  }

  return (
    <>
      <Modal
        width={850}
        open={true}
        maskClosable={false}
        onCancel={() => {
          setSecretData("");
          setSecretName("");
          onClose();
        }}
        footer={
          <div className="modal-actions">
            <NGButton
              variant="secondary"
              onClick={() => {
                setSecretData("");
                setSecretName("");
                onClose();
              }}
              disabled={isLoading}
            >
              Cancel
            </NGButton>
            <NGButton
              variant="primary"
              onClick={() => onConfirm()}
              loading={isLoading}
              disabled={isLoading || !secretData || !secretName}
            >
              Create Secret
            </NGButton>
          </div>
        }
        title={title}
      >
        <div style={{ maxHeight: "60vh" }} className="overflow-y-auto p-4">
          <div className="mb-2">
            <NGLabel>Name</NGLabel>
            <NGInput value={secretName} onChange={(e) => setSecretName(e.target.value)} />
          </div>
          <div>
            <NGLabel>{dataLabel}</NGLabel>
            <NGInput value={secretData} onChange={(e) => setSecretData(e.target.value)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export const NGProviderCreateOpaqueSecret = observer(NGProviderCreateOpaqueSecretRaw);
