import { types } from "mobx-state-tree";

export const PreInstallScriptProperty = types.optional(types.string, "");

// TODO

// if (!value.startsWith('#!')) {
//   throw new Error('Script must start with #!');
// }
// return value;
