import * as React from "react";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { Routes, Route, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { WorkloadCreateContainer } from "./container";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  gvcEnv: any;
}
const WorkloadCreateContainersRaw: React.FC<Props> = ({ workloadDraft, gvcEnv }) => {
  return (
    <Routes>
      <Route index element={<Navigate to={`1`} />} />
      <Route
        path={`1/*`}
        element={
          <WorkloadCreateContainer
            workloadDraft={workloadDraft}
            containerDraft={workloadDraft.container1}
            containerIndex={1}
            gvcEnv={gvcEnv}
          />
        }
      />
      {workloadDraft.container2 ? (
        <Route
          path={`2/*`}
          element={
            <WorkloadCreateContainer
              workloadDraft={workloadDraft}
              containerDraft={workloadDraft.container2}
              containerIndex={2}
              gvcEnv={gvcEnv}
            />
          }
        />
      ) : null}
      {workloadDraft.container3 ? (
        <Route
          path={`3/*`}
          element={
            <WorkloadCreateContainer
              workloadDraft={workloadDraft}
              containerDraft={workloadDraft.container3}
              containerIndex={3}
              gvcEnv={gvcEnv}
            />
          }
        />
      ) : null}
      {workloadDraft.container4 ? (
        <Route
          path={`4/*`}
          element={
            <WorkloadCreateContainer
              workloadDraft={workloadDraft}
              containerDraft={workloadDraft.container4}
              containerIndex={4}
              gvcEnv={gvcEnv}
            />
          }
        />
      ) : null}
      {workloadDraft.container5 ? (
        <Route
          path={`5/*`}
          element={
            <WorkloadCreateContainer
              workloadDraft={workloadDraft}
              containerDraft={workloadDraft.container5}
              containerIndex={5}
              gvcEnv={gvcEnv}
            />
          }
        />
      ) : null}
      {workloadDraft.container6 ? (
        <Route
          path={`6/*`}
          element={
            <WorkloadCreateContainer
              workloadDraft={workloadDraft}
              containerDraft={workloadDraft.container6}
              containerIndex={6}
              gvcEnv={gvcEnv}
            />
          }
        />
      ) : null}
      {workloadDraft.container7 ? (
        <Route
          path={`7/*`}
          element={
            <WorkloadCreateContainer
              workloadDraft={workloadDraft}
              containerDraft={workloadDraft.container7}
              containerIndex={7}
              gvcEnv={gvcEnv}
            />
          }
        />
      ) : null}
      {workloadDraft.container8 ? (
        <Route
          path={`8/*`}
          element={
            <WorkloadCreateContainer
              workloadDraft={workloadDraft}
              containerDraft={workloadDraft.container8}
              containerIndex={8}
              gvcEnv={gvcEnv}
            />
          }
        />
      ) : null}
    </Routes>
  );
};

export const WorkloadCreateContainers = observer(WorkloadCreateContainersRaw);
