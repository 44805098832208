import * as React from "react";
import { PromptContext } from "../mobxStores/prompt/prompt";

export function useCleanPrompt(ignore = false) {
  React.useEffect(() => {
    if (ignore) {
      return () => {};
    }
    return () => {
      PromptContext.setWhen(false);
      PromptContext.setIsDisabled(false);
      PromptContext.setShouldBlock(() => true);
      PromptContext.setOnDiscard(undefined);
    };
  }, []);
}
