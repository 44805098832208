import * as React from "react";
import moment from "moment-timezone";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { BillingContext, BillingInvoice } from "../../../mobxStores/billingContext/billingContext";
import { flowResult } from "mobx";
import NGAlert from "../../../newcomponents/alert";
import { NGButton } from "../../../newcomponents/button/Button";
import { Table } from "../../../newcomponents/table/table";
import { getLocalToken } from "../../../services/cpln";
import { Discovery } from "../../../mobxStores/discovery/discovery";
import { notification } from "antd";
import { DatePicker } from "../../../components/antd/DatePicker";
import { NGLabel } from "../../../newcomponents/text/label";
import { DateString } from "../../../components/dateString";
import { formatStrings } from "../../../utils/time";

const BillingAccountInvoicesRaw: React.FC = () => {
  const navigate = useNavigate();
  const { AccountUUID, Roles, Invoices, InvoicesError, isInvoicesLoading } = BillingContext;
  const [isLoading, setIsLoading] = React.useState(false);
  const [startMoment, setStartMoment] = React.useState<moment.Moment>(moment().startOf("month").subtract(1, "month"));
  const [endMoment, setEndMoment] = React.useState<moment.Moment>(moment().startOf("month").add(1, "month"));
  const [isLoadingData, setIsLoadingData] = React.useState(true);

  React.useEffect(() => {
    setIsLoadingData(isInvoicesLoading);
  }, [isInvoicesLoading]);

  React.useEffect(() => {
    if (!Roles.includes("billing_admin") && !Roles.includes("billing_viewer")) {
      navigate(`/billing/account/${AccountUUID}`);
      return;
    }
    BillingContext.fetchInvoices(startMoment, endMoment);
  }, [startMoment, endMoment]);

  async function viewInvoicePdf(invoiceId: string) {
    try {
      setIsLoading(true);

      const { accessToken } = await getLocalToken();

      const headers = new Headers();
      headers.append("Content-Type", "application/pdf");
      headers.append("Authorization", `Bearer ${accessToken}`);

      const requestOptions: any = {
        method: "GET",
        headers,
      };

      const response = await fetch(
        `${Discovery.endpoints["billing-ng"]}/account/${AccountUUID}/charges/invoices/${invoiceId}`,
        requestOptions,
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const newTab = window.open();
      if (newTab) {
        newTab.location.href = url;
      } else {
        notification.warning({
          message: "Failed to open the invoice pdf",
        });
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.warning({
        message: "Failed to open the invoice pdf",
        description: e.message,
      });
    }
  }

  if (InvoicesError) {
    return (
      <div>
        <NGAlert className="mb-4" type={"error"} message={InvoicesError} />
        <NGButton
          variant="primary"
          onClick={async () => {
            await flowResult(BillingContext.fetchInvoices(startMoment, endMoment));
          }}
        >
          Retry
        </NGButton>
      </div>
    );
  }

  return (
    <>
      <Table<BillingInvoice>
        title={() => {
          return (
            <div className="flex items-center gap-4">
              <NGLabel>Invoices</NGLabel>

              <div className="flex items-center gap-2">
                <label>Start</label>
                <DatePicker
                  value={startMoment}
                  onChange={(value) => {
                    if (!value) {
                      return;
                    }
                    setStartMoment(value);
                  }}
                />
              </div>
              <div className="flex items-center gap-2">
                <label>End</label>
                <DatePicker
                  value={endMoment}
                  onChange={(value) => {
                    if (!value) {
                      return;
                    }
                    setEndMoment(value);
                  }}
                />
              </div>
            </div>
          );
        }}
        data={Invoices}
        tableId={"account-invoices-update3"}
        enableFilter={false}
        isLoading={isLoadingData}
        hideSettings
        columns={[
          {
            id: "startTime",
            label: "Start Time",
            enableResize: true,
            size: 300,
            cell: ({ row }) => {
              const invoice = row.original;
              return (
                <div>
                  <DateString iso={invoice.startTime} format={formatStrings.log} />
                </div>
              );
            },
          },
          {
            id: "endTime",
            label: "End Time",
            enableResize: true,
            size: 300,
            cell: ({ row }) => {
              const invoice = row.original;
              return (
                <div>
                  <DateString iso={invoice.endTime} format={formatStrings.log} />
                </div>
              );
            },
          },
          {
            id: "totalCharge",
            label: "Total Charge",
            size: 140,
            cell: ({ getValue, row }) => {
              const invoice = row.original;
              const value = getValue() / 100;
              const currency = invoice.periods[0].accountState.ratePlan.currency;
              return (
                <div>
                  {currency === "USD" ? "$" : ""}
                  {`${value} ${currency === "usd" ? "" : currency}`}
                </div>
              );
            },
          },
          {
            id: "pdf",
            label: "PDF",
            size: 160,
            cell: (p) => {
              return (
                <NGButton
                  variant="secondary"
                  onClick={() => viewInvoicePdf(p.row.original.id)}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  View Invoice
                </NGButton>
              );
            },
          },
        ]}
      />
    </>
  );
};

export const BillingAccountInvoices = observer(BillingAccountInvoicesRaw);
