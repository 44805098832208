import * as React from "react";
import { VolumeSetDraftMobx } from "../../../mst/stores/volumeset.draft";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { observer } from "mobx-react-lite";

interface Props {
  draft: VolumeSetDraftMobx;
}
const GeneralRaw: React.FC<Props> = ({ draft }) => {
  return (
    <>
      <NGInputAdapter style={{ width: 450 }} className="mb-4" data={draft.name} />
      <NGInputAdapter style={{ width: 450 }} className="mb-4" data={draft.description} />
    </>
  );
};

export const General = observer(GeneralRaw);
