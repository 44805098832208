import * as React from "react";
import { X } from "react-feather";
import { Tooltip } from "../Tooltip";

interface Props {
  value: string;
  onRemove: any;
  className?: string;
  style?: any;
  allowSpaces?: boolean;
  isDeleteVisible?: boolean;
}
export const RemovableValue: React.FC<Props> = ({
  value,
  onRemove,
  className = "",
  allowSpaces = false,
  isDeleteVisible = false,
  style = {},
}) => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  function onEnter(_: React.MouseEvent) {
    setIsHovered(true);
  }
  function onLeave(_: React.MouseEvent) {
    setIsHovered(false);
  }

  return (
    <Tooltip title={value} open={value.length >= 20}>
      <div
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        className={`relative old-value py-1 pl-2 pr-4 inline-flex text-sm items-center mr-2 mb-2 ${className}`}
        style={{ ...style, borderRadius: 6 }}
      >
        {allowSpaces ? (
          <pre className="w-full inline proxima-nova">{value}</pre>
        ) : (
          <span className="w-full truncate">{value}</span>
        )}
        <button
          data-testid={`close-${value}`}
          onClick={onRemove}
          className={`color-danger ml-1 ${!isHovered && !isDeleteVisible ? "invisible" : ""}`}
        >
          <X className={`absolute feather-icon`} style={{ right: 3, top: 6 }} />
        </button>
      </div>
    </Tooltip>
  );
};
