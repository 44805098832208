import clsx from "clsx";
import { NGCheckbox } from "../../../newcomponents/checkbox";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { NGLabel } from "../../../newcomponents/text/label";
import { LoaderSmall } from "../../../components/layout/loader/small";
import { Kind } from "../../../mst/base";
import { pluralNameOfKind } from "../../../services/cpln";
import { Tooltip } from "../../../components/Tooltip";
import { CustomScrollbars } from "../../../components/Scrollbar";

interface IProps {
  kind: Kind;
  items: { value: string; disabled?: boolean; checked: boolean; onChange?: () => void }[];
  toggleAllItems: () => void;
  selectedItemsLength: number;
}

export const ExportWithItemsColumn = ({ kind, items, toggleAllItems, selectedItemsLength }: IProps) => (
  <div className="export-with-items-modal-column">
    <NGLabel size={3} className="export-with-items-modal-column-title">
      {pluralNameOfKind(kind)} ({selectedItemsLength})
      {items.length > 5 ? (
        <NGCheckbox
          labelPosition="left"
          onChange={() => toggleAllItems()}
          checked={selectedItemsLength === items.length}
          indeterminate={selectedItemsLength > 0 && selectedItemsLength !== items.length}
        >
          <NGLabelText>All</NGLabelText>
        </NGCheckbox>
      ) : null}
    </NGLabel>
    <div className={clsx("export-with-items-modal-column-list", { centerItems: items.length < 0 })}>
      {items.length > 0 ? (
        <CustomScrollbars>
          <div style={{ padding: "0.375rem 0.75rem" }}>
            {items.map((item) => (
              <NGCheckbox key={item.value} isDisabled={item.disabled} onChange={item.onChange} checked={item.checked}>
                <Tooltip title={item.value} open={item.value.length >= 30}>
                  <NGLabelText>{item.value}</NGLabelText>
                </Tooltip>
              </NGCheckbox>
            ))}
          </div>
        </CustomScrollbars>
      ) : (
        <span className="export-with-items-modal-column-no-items-found">No {pluralNameOfKind(kind)} Yet.</span>
      )}
    </div>
  </div>
);
