import * as React from "react";
import jsYaml from "js-yaml";
import { WorkloadMobx, WorkloadModel } from "../../../mst/kinds/workload";
import { VolumeSetMobx, VolumeSetModel } from "../../../mst/kinds/volumeset";
import { observer } from "mobx-react-lite";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { NGCheckbox } from "../../../newcomponents/checkbox";
import { NGButton } from "../../../newcomponents/button/Button";
import { nameOfKind, parentLink, request, resourceLink, TerraformExporterResponse } from "../../../services/cpln";
import { Modal, notification } from "antd";
import { IdentityMobx, IdentityModel } from "../../../mst/kinds/identity";
import { GVCMobx } from "../../../mst/kinds/gvc";
import { ExportWithItemsColumn } from "./column";
import { DomainMobx, DomainModel } from "../../../mst/kinds/domain";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { clearItem, yamlDumpAll, k8sKeySort, toSortedJSON } from "../../../services/utils";
import { LoaderSmall } from "../../../components/layout/loader/small";
import "./index.scss";
import { convertAllLinksToRelative } from "../../../utils/linkParser/linkParser";
import { CloudaccountModel } from "../../../mst/kinds/cloudaccount";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { TerraformImportModal } from "../../../components/modals/tfImportModal";
import { Tooltip } from "../../../components/Tooltip";
import { WhatIsSlim } from "../../../utils/constants";

interface Props {
  gvc: GVCMobx;
  onClose: () => void;
}

const ExportWithItemsModalRaw: React.FC<Props> = ({ gvc, onClose }) => {
  const { org } = ConsoleContext;

  const [exportConfirmed, setExportConfirmed] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [format, setFormat] = React.useState<"json" | "yaml" | "tf">("yaml");
  const [slim, setSlim] = React.useState<boolean>(true);

  const [workloads, setWorkloads] = React.useState<WorkloadMobx[]>([]);
  const [workloadsFetched, setWorkloadsFetched] = React.useState<boolean>(false);
  const [workloadResponses, setWorkloadResponses] = React.useState<any[]>([]);
  const [selectedWorkloadLinks, setSelectedWorkloadLinks] = React.useState<string[]>([]);

  const [identities, setIdentities] = React.useState<IdentityMobx[]>([]);
  const [identitiesFetched, setIdentitiesFetched] = React.useState<boolean>(false);
  const [identityResponses, setIdentityResponses] = React.useState<any[]>([]);
  const [selectedIdentityLinks, setSelectedIdentityLinks] = React.useState<string[]>([]);
  const [requiredIdentityLinks, setRequiredIdentityLinks] = React.useState<string[]>([]);

  const [volumesets, setVolumesets] = React.useState<VolumeSetMobx[]>([]);
  const [volumesetsFetched, setVolumesetsFetched] = React.useState<boolean>(false);
  const [volumesetResponses, setVolumesetResponses] = React.useState<any[]>([]);
  const [selectedVolumesetLinks, setSelectedVolumesetLinks] = React.useState<string[]>([]);
  const [requiredVolumesetLinks, setRequiredVolumesetLinks] = React.useState<string[]>([]);

  const [domains, setDomains] = React.useState<DomainMobx[]>([]);
  const [domainsFetched, setDomainsFetched] = React.useState<boolean>(false);

  const [includeReferenceItems, setIncludeReferenceItems] = React.useState<boolean>(false);
  const [includeProviderSnippet, setIncludeProviderSnippet] = React.useState(true);

  const [terraformImportCommand, setTerraformImportCommand] = React.useState<string>("");

  const selectedAndRequiredIdentityLinks = [...selectedIdentityLinks];
  for (let requiredIdentityLink of requiredIdentityLinks) {
    if (!selectedAndRequiredIdentityLinks.includes(requiredIdentityLink)) {
      selectedAndRequiredIdentityLinks.push(requiredIdentityLink);
    }
  }

  const selectedAndRequiredVolumesetLinks = [...selectedVolumesetLinks];
  for (let requiredVolumesetLink of requiredVolumesetLinks) {
    if (!selectedAndRequiredVolumesetLinks.includes(requiredVolumesetLink)) {
      selectedAndRequiredVolumesetLinks.push(requiredVolumesetLink);
    }
  }

  React.useEffect(() => {
    if (exportConfirmed && terraformImportCommand.trim().length == 0) {
      onClose();
    }
  }, [exportConfirmed, terraformImportCommand]);

  React.useEffect(() => {
    Promise.all([getAllWorkloads(), getAllIdentities(), getAllVolumesets(), getAllDomains()]);
  }, []);

  React.useEffect(() => {
    const _requiredIdentityLinks: string[] = [];
    const _requiredVolumesetLinks: string[] = [];
    for (let workloadLink of selectedWorkloadLinks) {
      const workload = workloads.find((w) => w.selfLink === workloadLink);
      if (!workload) {
        continue;
      }
      if (workload.spec.identityLink && identities.some((i) => i.selfLink === workload.spec.identityLink)) {
        _requiredIdentityLinks.push(workload.spec.identityLink);
      }
      for (const container of workload.spec.containers) {
        for (const volume of container.volumes) {
          if (volume.uri.includes("cpln://volumeset/")) {
            let volumeName = "";
            try {
              volumeName = volume.uri.split("cpln://volumeset/")[1];
            } catch (e) {}
            if (volumeName) {
              _requiredVolumesetLinks.push(resourceLink("volumeset", volumeName));
            }
          }
        }
      }
    }

    setRequiredIdentityLinks(_requiredIdentityLinks);
    setRequiredVolumesetLinks(_requiredVolumesetLinks);
  }, [selectedWorkloadLinks]);

  function toggleWorkloadAll() {
    if (selectedWorkloadLinks.length !== workloads.length) {
      setSelectedWorkloadLinks(workloads.map((w) => w.selfLink));
    } else {
      setSelectedWorkloadLinks([]);
    }
  }

  function toggleWorkloadLink(link: string) {
    const isChecked = selectedWorkloadLinks.includes(link);
    if (isChecked) {
      setSelectedWorkloadLinks((links) => links.filter((_link) => _link !== link));
    } else {
      setSelectedWorkloadLinks((links) => [...links, link]);
    }
  }

  function toggleIdentityAll() {
    if (selectedAndRequiredIdentityLinks.length !== identities.length) {
      setSelectedIdentityLinks(identities.map((w) => w.selfLink));
    } else {
      setSelectedIdentityLinks([...requiredIdentityLinks]);
    }
  }

  function toggleIdentityLink(link: string) {
    const isChecked = selectedIdentityLinks.includes(link);
    if (isChecked) {
      setSelectedIdentityLinks((links) => links.filter((_link) => _link !== link));
    } else {
      setSelectedIdentityLinks((links) => [...links, link]);
    }
  }

  function toggleVolumesetAll() {
    if (selectedAndRequiredVolumesetLinks.length !== volumesets.length) {
      setSelectedVolumesetLinks(volumesets.map((w) => w.selfLink));
    } else {
      setSelectedVolumesetLinks([...requiredVolumesetLinks]);
    }
  }

  function toggleVolumesetLink(link: string) {
    const isChecked = selectedVolumesetLinks.includes(link);
    if (isChecked) {
      setSelectedVolumesetLinks((links) => links.filter((_link) => _link !== link));
    } else {
      setSelectedVolumesetLinks((links) => [...links, link]);
    }
  }

  async function getAllWorkloads() {
    let nextLink = parentLink("workload");
    const _workloads: WorkloadMobx[] = [];
    const _workloadResponses: any[] = [];
    while (nextLink) {
      const { data: workloadsRes } = await request({ url: nextLink });
      for (let item of workloadsRes.items) {
        _workloads.push(WorkloadModel.create(item));
        _workloadResponses.push(item);
      }
      nextLink = workloadsRes.links.find((link: any) => link.rel === "next")?.href;
    }
    setWorkloads(_workloads);
    setWorkloadResponses(_workloadResponses);
    setWorkloadsFetched(true);
  }

  async function getReferenceItems() {
    if (!includeReferenceItems) return [];
    const agentLinks: string[] = [];
    const cloudaccountLinks: string[] = [];
    const domainLinks: string[] = [];
    const secretLinks: string[] = [];
    function extractAndSetSecretFromLink(link: string) {
      let secretName = "";
      try {
        const parts = link.split("/");
        const secretIndex = parts.indexOf("secret");
        if (secretIndex !== -1 && secretIndex < parts.length - 1) {
          let potentialSecretName = parts[secretIndex + 1];
          const dotIndex = potentialSecretName.indexOf(".");
          if (dotIndex !== -1) {
            secretName = potentialSecretName.substring(0, dotIndex);
          } else {
            secretName = potentialSecretName;
          }
        }
      } catch (e) {}
      if (secretName) {
        secretLinks.push(resourceLink("secret", secretName));
      }
    }
    for (let identityLink of selectedAndRequiredIdentityLinks) {
      const identity = identities.find((w) => w.selfLink === identityLink);
      if (!identity) {
        continue;
      }
      if (identity.aws) {
        cloudaccountLinks.push(identity.aws.cloudAccountLink);
      }
      if (identity.azure) {
        cloudaccountLinks.push(identity.azure.cloudAccountLink);
      }
      if (identity.ngs) {
        cloudaccountLinks.push(identity.ngs.cloudAccountLink);
      }
      for (const networkResource of identity.networkResources) {
        agentLinks.push(networkResource.agentLink);
      }
    }
    for (let cloudaccountLink of cloudaccountLinks) {
      try {
        const { data: cloudaccountRes } = await request({ url: cloudaccountLink });
        const cloudaccount = CloudaccountModel.create(cloudaccountRes);
        if (cloudaccount.data?.secretLink) {
          extractAndSetSecretFromLink(cloudaccount.data.secretLink);
        }
      } catch (e) {
        continue;
      }
    }
    for (let domain of domains) {
      if (domain.spec) {
        if (domain.spec.gvcLink === `/org/${org}/gvc/${gvc.name}`) {
          domainLinks.push(domain.selfLink);
        }
      }
    }
    for (let workloadLink of selectedWorkloadLinks) {
      const workload = workloads.find((w) => w.selfLink === workloadLink);
      if (!workload) {
        continue;
      }
      for (const container of workload.spec.containers) {
        for (const volume of container.volumes) {
          if (volume.uri.includes("cpln://secret/")) {
            extractAndSetSecretFromLink(volume.uri);
          }
        }
        for (const env of container.env) {
          if (env.value.includes("cpln://secret/")) {
            extractAndSetSecretFromLink(env.value);
          }
        }
      }
      for (let domain of domains) {
        if (domain.spec) {
          if (domain.spec.gvcLink === `/org/${org}/gvc/${gvc.name}`) {
            domainLinks.push(domain.selfLink);
          }
          for (let port of domain.spec.ports) {
            for (let route of port.routes) {
              if (route.workloadLink === workloadLink) domainLinks.push(domain.selfLink);
            }
          }
        }
      }
    }
    for (let env of gvc.spec.env) {
      extractAndSetSecretFromLink(env.value);
    }
    for (let link of gvc.spec.pullSecretLinks) {
      extractAndSetSecretFromLink(link);
    }
    return Array.from(
      new Set([...agentLinks, ...cloudaccountLinks, ...secretLinks.map((s) => s + "/-reveal"), ...domainLinks]),
    );
  }

  async function getAllIdentities() {
    let nextLink = parentLink("identity");
    const _identities: IdentityMobx[] = [];
    const _identityResponses: any[] = [];
    while (nextLink) {
      const { data: identitiesRes } = await request({ url: nextLink });
      for (let item of identitiesRes.items) {
        _identities.push(IdentityModel.create(item));
        _identityResponses.push(item);
      }
      nextLink = identitiesRes.links.find((link: any) => link.rel === "next")?.href;
    }
    setIdentities(_identities);
    setIdentityResponses(_identityResponses);
    setIdentitiesFetched(true);
  }

  async function getAllVolumesets() {
    let nextLink = parentLink("volumeset");
    const _volumesets: VolumeSetMobx[] = [];
    const _volumesetResponses: any[] = [];
    while (nextLink) {
      const { data: volumesetsRes } = await request({ url: nextLink });
      for (let item of volumesetsRes.items) {
        _volumesets.push(VolumeSetModel.create(item));
        _volumesetResponses.push(item);
      }
      nextLink = volumesetsRes.links.find((link: any) => link.rel === "next")?.href;
    }
    setVolumesets(_volumesets);
    setVolumesetResponses(_volumesetResponses);
    setVolumesetsFetched(true);
  }

  async function getAllDomains() {
    let nextLink = parentLink("domain");
    const _domains: DomainMobx[] = [];
    while (nextLink) {
      const { data: domainsRes } = await request({ url: nextLink });
      for (let item of domainsRes.items) {
        const modelItem = DomainModel.create(item);
        _domains.push(modelItem);
      }
      nextLink = domainsRes.links.find((link: any) => link.rel === "next")?.href;
    }
    setDomains(_domains);
    setDomainsFetched(true);
  }

  async function confirmExport() {
    try {
      setIsLoading(true);

      let items: any = [];

      if (format === "tf") {
        // Determine request option
        const queryParameters: string[] = ["type=withItems", "import=true"];

        if (includeReferenceItems) {
          queryParameters.push("dependencies=true");
        }

        // Determine request URL
        const url: string = `${resourceLink("gvc", gvc.name)}?${queryParameters.join("&")}`;

        // Determine request body
        const body: any = {
          workloads: workloads.filter((w) => selectedWorkloadLinks.includes(w.selfLink)).map((w) => w.name),
          identities: identities.filter((i) => selectedIdentityLinks.includes(i.selfLink)).map((i) => i.name),
          volumesets: volumesets.filter((v) => selectedVolumesetLinks.includes(v.selfLink)).map((v) => v.name),
        };

        const { data } = await request({ service: "terraform-exporter", method: "post", url, body });
        exportTerraform(data);
      } else {
        const { data: gvcItem } = await request({ service: "api", url: resourceLink("gvc", gvc.name) });
        const referenceItems = await getReferenceItems();
        items.push(gvcItem);
        for (const itemLink of [
          ...selectedWorkloadLinks,
          ...selectedAndRequiredIdentityLinks,
          ...selectedAndRequiredVolumesetLinks,
          ...referenceItems,
        ]) {
          const { data } = await request({ service: "api", url: itemLink });
          items.push(data);
        }

        if (slim) {
          for (const item of items) {
            clearItem(item);
          }
        }
        if (format === "json") {
          await exportJSON(items);
        }
        if (format === "yaml") {
          await exportYaml(items);
        }

        notification.success({
          message: "Success",
          description: `Exported ${nameOfKind(gvc.kind)}`,
        });
      }

      setIsLoading(false);

      if (format === "tf") {
        setExportConfirmed(true);
      } else {
        onClose();
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function exportJSON(data: any) {
    try {
      const blob = JSON.stringify(toSortedJSON(convertAllLinksToRelative(data)), null, 2);
      autoDownloadWithElementA(blob, "json");
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  async function exportYaml(items: any) {
    try {
      const blob = yamlDumpAll(convertAllLinksToRelative(items), {
        indent: 2,
        noRefs: true,
        sortKeys: k8sKeySort,
      });
      autoDownloadWithElementA(blob, "yaml");
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  function exportTerraform(data: TerraformExporterResponse) {
    const output: string[] = [];

    // Include provider snippet if specified
    if (includeProviderSnippet) {
      output.push(data.providerSnippet);
    }

    // Appead Terraform resources to the output
    output.push(data.resources.join("\n\n"));

    setTerraformImportCommand(data.imports.join("\n"));
    autoDownloadWithElementA(convertAllLinksToRelative(output.join("\n")), "tf");
  }

  function autoDownloadWithElementA(blobData: any, extension: "json" | "yaml" | "tf", filename?: string) {
    const file = new Blob([blobData], { type: `text/${extension}` });
    const href = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style.display = "none";
    a.classList.add("cpln-temp-a");
    a.download = (filename || `${ConsoleContext.org}_${gvc.name}-with-items`) + "." + extension;
    a.href = href;
    a.click();
  }

  if (exportConfirmed) {
    return <TerraformImportModal command={terraformImportCommand} onClose={onClose} />;
  }

  return (
    <Modal
      title={
        <div className="flex items-center justify-between">
          <div className="mr-4 text-lg">Export GVC with Items</div>
          <div className="flex items-center gap-3 text-sm">
            <Tooltip title={WhatIsSlim} placement="bottomLeft">
              <div className={`flex items-center`}>
                <NGSwitch
                  value={slim && format != "tf"}
                  isDisabled={format === "tf"}
                  onChange={(checked) => setSlim(checked)}
                >
                  <span>Slim</span>
                </NGSwitch>
              </div>
            </Tooltip>
            <NGSelect
              value={format}
              options={[
                { value: "json", label: "JSON" },
                { value: "yaml", label: "YAML" },
                { value: "tf", label: "Terraform" },
              ]}
              onChange={(value) => setFormat(value as any)}
              size="normal"
              style={{ width: 120 }}
            />
          </div>
        </div>
      }
      open={true}
      closable={false}
      onCancel={onClose}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </NGButton>
          <NGButton
            variant="primary"
            onClick={confirmExport}
            loading={isLoading || !workloadsFetched || !identitiesFetched || !volumesetsFetched}
            disabled={isLoading || !workloadsFetched || !identitiesFetched || !volumesetsFetched}
            data-testid="export-modal"
          >
            Export
          </NGButton>
        </div>
      }
      maskClosable={!isLoading}
      destroyOnClose
      width={900}
    >
      {workloadsFetched && identitiesFetched && volumesetsFetched ? (
        <div className={"export-with-items-modal flex flex-col gap-3"}>
          {/* Checkboxes */}
          <div className="flex gap-5">
            {/* Include Referenced Items */}
            <div className="flex items-center">
              <NGCheckbox
                checked={includeReferenceItems}
                onChange={setIncludeReferenceItems}
                isDisabled={!domainsFetched}
              >
                <NGLabelText>Include referenced items</NGLabelText>
              </NGCheckbox>
              <InfoTooltip
                title={[
                  "Secrets, Agents, Cloud Accounts, Domains",
                  !domainsFetched ? "Fetching reference items..." : "",
                ]}
              />
            </div>

            {/* Include Provider Snippet */}
            {format == "tf" ? (
              <NGCheckbox
                checked={includeProviderSnippet}
                onChange={setIncludeProviderSnippet}
                isDisabled={!domainsFetched}
              >
                <NGLabelText>Include Provider Snippet</NGLabelText>
              </NGCheckbox>
            ) : null}
          </div>

          {/* Items to Export */}
          <div className="flex flex-col gap-2">
            <NGLabelText style={{ margin: "0.25rem 0" }}>Select GVC items to export.</NGLabelText>
            <div className="export-with-items-modal-columns">
              <ExportWithItemsColumn
                items={workloads.map((workload) => ({
                  value: workload.name,
                  checked: selectedWorkloadLinks.includes(workload.selfLink),
                  onChange: () => toggleWorkloadLink(workload.selfLink),
                }))}
                kind="workload"
                selectedItemsLength={selectedWorkloadLinks.length}
                toggleAllItems={toggleWorkloadAll}
              />
              <ExportWithItemsColumn
                items={identities.map((identity) => {
                  const disabled = requiredIdentityLinks.includes(identity.selfLink);
                  const onChange = disabled ? undefined : () => toggleIdentityLink(identity.selfLink);
                  return {
                    value: identity.name,
                    disabled: disabled,
                    checked: selectedAndRequiredIdentityLinks.includes(identity.selfLink),
                    onChange: onChange,
                  };
                })}
                kind="identity"
                selectedItemsLength={selectedAndRequiredIdentityLinks.length}
                toggleAllItems={toggleIdentityAll}
              />
              <ExportWithItemsColumn
                items={volumesets.map((volumeset) => {
                  const disabled = requiredVolumesetLinks.includes(volumeset.selfLink);
                  const onChange = disabled ? undefined : () => toggleVolumesetLink(volumeset.selfLink);
                  return {
                    value: volumeset.name,
                    disabled: disabled,
                    checked: selectedAndRequiredVolumesetLinks.includes(volumeset.selfLink),
                    onChange: onChange,
                  };
                })}
                kind="volumeset"
                selectedItemsLength={selectedAndRequiredVolumesetLinks.length}
                toggleAllItems={toggleVolumesetAll}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: "100%", height: "360px", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <LoaderSmall />
        </div>
      )}
    </Modal>
  );
};

export const ExportWithItemsModal = observer(ExportWithItemsModalRaw);
