import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";

export const Mk8sDraftAddonAzureACRModel = types
  .model({
    _isEnabled: types.optional(types.boolean, false),
    isEnabled: types.optional(types.boolean, false),
    _clientId: types.optional(types.string, ""),
    clientId: types.optional(StringModel, () => StringModel.create({ label: "Client Id", isRequired: true })),
  })
  .actions((self) => ({
    setIsEnabled(value: boolean) {
      self.isEnabled = value;
    },
  }))
  .actions((self) => ({
    reset() {
      self.isEnabled = self._isEnabled;
      self.clientId.setInitialValue(self._clientId);
    },
    confirm() {
      self._isEnabled = self.isEnabled;
      self.clientId.confirm();
      self._clientId = self.clientId.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (self.isEnabled) {
        if (!self.clientId.isValid) res = false;
      }
      return res;
    },
    get invalidReason() {
      let reason = "";
      // TODO
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.isEnabled !== self._isEnabled) res = true;
      if (self.isEnabled) {
        if (self.clientId.isDirty) res = true;
      }
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.isEnabled !== self._isEnabled) reason = "enabled changed";
      if (self.isEnabled) {
        if (self.clientId.isDirty) reason = self.clientId.isDirtyReason;
      }
      return reason;
    },
    get asObject() {
      return {
        clientId: self.clientId.value,
      };
    },
  }));
