import { action, makeObservable, observable } from "mobx";

type ParsedLocationDashboard = "unknown" | "console" | "billing" | "task";

class ParsedLocationMobx {
  dashboard: ParsedLocationDashboard = "unknown";
  createForm = false;

  constructor(location: string) {
    makeObservable(this, {
      dashboard: observable,
      createForm: observable,
      parse: action,
    });
    this.parse(location);
  }

  parse(location: string) {
    if (!location) {
      return;
    }
    // Parse dashboard
    let dashboard: ParsedLocationDashboard = "unknown";
    if (location.startsWith("/console")) {
      dashboard = "console";
    }
    if (location.startsWith("/billing")) {
      dashboard = "billing";
    }
    if (location.startsWith("/task")) {
      dashboard = "task";
    }
    this.dashboard = dashboard;
    // Parse create form
    this.createForm = location.includes("/-create/") || location.includes("/-createfirst/");
  }

  parseDashboardResult(location: string) {
    if (!location) {
      return;
    }
    // Parse dashboard
    let dashboard: ParsedLocationDashboard = "unknown";
    if (location.startsWith("/console")) {
      dashboard = "console";
    }
    if (location.startsWith("/billing")) {
      dashboard = "billing";
    }
    if (location.startsWith("/task")) {
      dashboard = "task";
    }
    return dashboard;
  }
}

// const l = window.location.href.split(window.location.origin)[1];

export const ParsedLocation = new ParsedLocationMobx("");
