import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "./../../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "./../../../../../services/cpln";
import { DryRunAlert } from "./../../../dryRunAlert";
import { MoreHorizontal, PlusCircle } from "react-feather";
import { NGKindSelect } from "./../../../../../newcomponents/select/ngkindselect";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { NGFormLabel } from "../../../../../newcomponents/text/formLabel";
import NGAlert from "../../../../../newcomponents/alert";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";
import { getDryRunErrorText } from "../../../getDryRunErrorText";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGProviderCreateOpaqueSecret } from "../../../ngProviderCreateOpaqueSecret";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderPaperspaceRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const p = mk8sDraft.provider_paperspace!;
  const formData = useNGFormContext();

  const [browserKey, setBrowserKey] = React.useState("");

  const sharedDrivesDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.paperspace.sharedDrives",
    paths: p.sharedDrives.items.map((_, idx) => `spec.provider.paperspace.sharedDrives[${idx}]`),
  });
  const userIdsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.paperspace.userIds",
    paths: p.userIds.items.map((_, idx) => `spec.provider.paperspace.userIds[${idx}]`),
  });

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"paperspace.region"}
            label={p.region.label}
            value={p.region.value}
            onChange={p.region.setValue}
            required={p.region.isRequired}
            error={p.region.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("regions");
                  formData.set("paperspace.region", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("paperspace.region").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={p.region.setValue}
              path={"spec.provider.paperspace.region"}
            />
          </div>
        </div>

        <div className="mb-4">
          <NGFormLabel required invalid={!p.tokenSecretName}>
            Paperspace Token Secret
          </NGFormLabel>
          <div className="mb-2 flex items-start gap-2">
            <NGKindSelect
              name={"paperspace.tokenSecretName"}
              style={{ width: 450 }}
              value={p.tokenSecretName}
              invalid={!p.tokenSecretName}
              onChange={(value) => p.setTokenSecretName(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "opaque" }]}
              buttons={[
                {
                  title: "Create",
                  render: () => <PlusCircle className="h-4" />,
                  onClick: () => setBrowserKey("createSecret"),
                },
              ]}
            />
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("paperspace.tokenSecretName").touched}
              dryRunResponse={mk8sDryRun.response}
              path={"spec.provider.paperspace.tokenSecretLink"}
            />
          </div>
          <NGAlert
            style={{ width: 450 }}
            render={() => (
              <div className="flex flex-col text-sm">
                <span>An opaque secret that contains your Paperspace token.</span>
                <span>It will be used to manage servers in your Paperspace account.</span>
              </div>
            )}
          />
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"paperspace.networkId"}
            label={p.networkId.label}
            value={p.networkId.value}
            onChange={p.networkId.setValue}
            required={p.networkId.isRequired}
            error={p.networkId.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("networks");
                  formData.set("paperspace.networkId", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("paperspace.networkId").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={p.networkId.setValue}
              path={"spec.provider.paperspace.networkId"}
            />
          </div>
        </div>

        <NGInputListMst
          data={p.sharedDrives}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="Shared Drives"
          labelRender={() => {
            return (
              <>
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("shared-drives")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (sharedDrivesDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }

            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          invalid={sharedDrivesDryRun.severity === "error"}
          error={sharedDrivesDryRun.severity === "error" ? sharedDrivesDryRun.message : ""}
          warning={sharedDrivesDryRun.severity === "error" ? "" : sharedDrivesDryRun.message}
        />
        <NGInputListMst
          data={p.userIds}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="User Ids"
          labelRender={() => {
            return (
              <>
                <NGButton className="ml-1" size={"small"} variant={"secondary"} onClick={() => setBrowserKey("users")}>
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (userIdsDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }

            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          invalid={userIdsDryRun.severity === "error"}
          error={userIdsDryRun.severity === "error" ? userIdsDryRun.message : ""}
          warning={userIdsDryRun.severity === "error" ? "" : userIdsDryRun.message}
        />
      </div>

      {browserKey === "createSecret" ? (
        <NGProviderCreateOpaqueSecret
          onOk={(secretName: string) => p.setTokenSecretName(secretName)}
          onClose={() => setBrowserKey("")}
          title={"Create Paperspace Secret"}
          dataLabel={"Paperspace Token"}
        />
      ) : null}
      {browserKey === "regions" ? (
        <NGProviderBrowser
          title="Browse Paperspace Regions"
          initialValue={p.region.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "regions",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => p.region.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description"]}
          getData={(item: any) => [item.name, item.name, item.description]}
        />
      ) : null}
      {browserKey === "networks" ? (
        <NGProviderBrowser
          title="Browse Paperspace Networks"
          initialValue={p.networkId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "networks",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.items;
          }}
          onOk={(value) => p.networkId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Id", "Name"]}
          getData={(item: any) => [item.id, item.id, item.name]}
        />
      ) : null}
      {browserKey === "shared-drives" ? (
        <NGProviderBrowser
          title="Browse Paperspace Shared Drives"
          initialValue={p.sharedDrives.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "shared-drives",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.items;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (p.sharedDrives.items.some((i) => i.firstValue === value)) {
                continue;
              }
              p.sharedDrives.add();
              const i = p.sharedDrives.items[p.sharedDrives.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Name"]}
          getData={(item: any) => [String(item.name), item.name]}
        />
      ) : null}
      {browserKey === "users" ? (
        <NGProviderBrowser
          title="Browse Paperspace Users"
          initialValue={p.userIds.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "users",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.items;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (p.userIds.items.some((i) => i.firstValue === value)) {
                continue;
              }
              p.userIds.add();
              const i = p.userIds.items[p.userIds.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Id", "Email", "Full Name"]}
          getData={(item: any) => [
            String(item.user.id),
            item.user.id,
            item.user.email,
            item.user.firstName + " " + item.user.lastName,
          ]}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderPaperspace = observer(Mk8sCreateProviderPaperspaceRaw);
