import { types } from "mobx-state-tree";

export const Mk8sDraftAddonNvidiaModel = types
  .model({
    _isEnabled: types.optional(types.boolean, false),
    isEnabled: types.optional(types.boolean, false),
    _taintGPUNodes: types.optional(types.boolean, false),
    taintGPUNodes: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setIsEnabled(value: boolean) {
      self.isEnabled = value;
    },
    setTaintGPUNodes(value: boolean) {
      self.taintGPUNodes = value;
    },
  }))
  .actions((self) => ({
    reset() {
      self.isEnabled = self._isEnabled;
      self.taintGPUNodes = self._taintGPUNodes;
    },
    confirm() {
      self._isEnabled = self.isEnabled;
      self._taintGPUNodes = self.taintGPUNodes;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      return res;
    },
    get invalidReason() {
      let reason = "";
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.isEnabled !== self._isEnabled) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.isEnabled !== self._isEnabled) reason = "enabled changed";
      return reason;
    },
    get asObject() {
      let res: any = { taintGPUNodes: true };
      return res;
    },
  }));
