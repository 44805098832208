import * as React from "react";
import { useParams } from "react-router-dom";
import { LocationDetail } from "./detail";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { LocationMobx, LocationModel } from "../../mst/kinds/location";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { captureExc } from "../../errorBoundary";

type RouteParams = "location";
const LocationDetailRouteRaw: React.FC = () => {
  const { location: locationName } = useParams<RouteParams>();
  const [location, setLocation] = React.useState<LocationMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [locationName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("location", locationName) });
      setLocation(LocationModel.create(data));
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching location to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"location"} canCreate={false} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {location.name} - Location - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: location, fetchItem }}>
        <LocationDetail location={location} />
      </DetailContext.Provider>
    </>
  );
};

export const LocationDetailRoute = observer(LocationDetailRouteRaw);
