import { types, Instance } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { defaultValues } from "../base";

export const VolumeSetDraftSnapshotsModel = types
  .model({
    _retentionDuration: types.optional(
      types.string,
      defaultValues.volumeset.snapshots.retentionDurationAmount.default +
        defaultValues.volumeset.snapshots.retentionDurationUnit.default
    ),
    _schedule: types.optional(types.string, ""),
    retentionDurationAmount: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Snapshot Retention Duration",
        initialValue: defaultValues.volumeset.snapshots.retentionDurationAmount.default,
        min: defaultValues.volumeset.snapshots.retentionDurationAmount.min,
        isRequired: true,
      })
    ),
    retentionDurationUnit: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Unit",
        initialValue: defaultValues.volumeset.snapshots.retentionDurationUnit.default,
        options: [
          { label: "Days", value: "d" },
          { label: "Minutes", value: "m" },
          { label: "Hours", value: "h" },
        ],
      })
    ),
    schedule: types.optional(StringModel, () =>
      StringModel.create({ label: "Snapshot Schedule (UTC)", validationKey: "volumesetSchedule" })
    ),
  })
  .views((self) => ({
    get _retentionDurationAmount() {
      if (!self._retentionDuration) {
        return defaultValues.volumeset.snapshots.retentionDurationAmount.default;
      }
      return self._retentionDuration.slice(0, self._retentionDuration.length - 1);
    },
    get _retentionDurationUnit() {
      if (!self._retentionDuration) {
        return defaultValues.volumeset.snapshots.retentionDurationUnit.default;
      }
      return self._retentionDuration.slice(self._retentionDuration.length - 1);
    },
  }))
  .actions((self) => ({
    reset() {
      self.retentionDurationAmount.setInitialValue(self._retentionDurationAmount);
      self.retentionDurationUnit.setInitialValue(self._retentionDurationUnit);
      self.schedule.setInitialValue(self._schedule);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._retentionDuration = self.retentionDurationAmount.value + self.retentionDurationUnit.value;
      self._schedule = self.schedule.value;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._retentionDurationAmount !== self.retentionDurationAmount.value) {
        res = true;
      }
      if (self._retentionDurationUnit !== self.retentionDurationUnit.value) {
        res = true;
      }
      if (self._schedule !== self.schedule.value) {
        res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.retentionDurationAmount.isValid) {
        res = false;
      }
      return res;
    },
    get asObject() {
      let res: any = {
        retentionDuration: self.retentionDurationAmount.value + self.retentionDurationUnit.value,
      };
      if (!!self.schedule.value) {
        res.schedule = self.schedule.value;
      }
      return res;
    },
  }));
export interface VolumeSetDraftSnapshotsMobx extends Instance<typeof VolumeSetDraftSnapshotsModel> {}
