import { TableColumn } from "../../types";
import { Tag } from "../../../../components/tag/tag";
import { Tooltip } from "../../../../components/Tooltip";

const tagsDefault = { id: "tags", label: "Tags" };
export function TagsColumn<TData extends { tags: { [_: string]: any } }>(
  column: Partial<TableColumn<TData>> = tagsDefault,
) {
  const preset: TableColumn<TData> = {
    ...tagsDefault,
    enableResize: true,
    enableHide: true,
    size: 300,
    cell: (p) => {
      const item = p.row.original;
      const tags = item.tags || {};
      return (
        <div>
          {Object.values(tags).length > 0 ? (
            <Tooltip
              title={() => {
                return (
                  <div className="flex flex-wrap gap-1">
                    {Object.entries(tags).map(([key, value]) => (
                      <Tag style={{ width: "fit-content" }} key={key} name={key} value={String(value || "")} />
                    ))}
                  </div>
                );
              }}
            >
              <div className="inline color-link mr-2">{Object.values(tags).length} Tags</div>
            </Tooltip>
          ) : (
            <span>No Tags</span>
          )}
          {Object.entries(tags).map(([key, value]) => (
            <Tag className="mr-2" style={{ minWidth: 60 }} key={key} name={key} value={String(value || "")} />
          ))}
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
