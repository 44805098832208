import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button as AntButton, Dropdown, Modal, notification, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { request, resourceLink } from "../../../../services/cpln";
import { NodePoolLabel } from "../../nodePoolLabel";
import { NodePoolTaint } from "../../nodePoolTaint";
import { DownOutlined } from "@ant-design/icons";
import { Mk8sScriptModal } from "../../Mk8sScriptModal";
import { NGLabel } from "../../../../newcomponents/text/label";
import { NGButton } from "../../../../newcomponents/button/Button";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderGenericNodePoolsRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const navigate = useNavigate();

  const [joinScriptIndex, setJoinScriptIndex] = React.useState<number>(-1);
  const [joinScript, setJoinScript] = React.useState<string>("");

  const [cloudinitScriptIndex, setCloudinitScriptIndex] = React.useState<number>(-1);
  const [cloudinitScript, setCloudinitScript] = React.useState<string>("");

  const g = mk8sDraft.provider_generic;
  if (!g) {
    return null;
  }

  async function handleJoinScript(index: number) {
    const notificationKey: string = "joinScript";
    notification.open({
      key: notificationKey,
      type: "info",
      message: "Join Script",
      description: "Fetching data from the server, please wait",
    });

    try {
      setJoinScriptIndex(index);
      const {
        data: { script },
      } = await request({
        method: "post",
        url: resourceLink("mk8s", mk8sDraft._name) + "/-join",
        body: {
          nodePoolName: g.nodePools.find((np) => np.index === index)!.name.value,
        },
      });
      setJoinScript(script);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to create join script", description: errorMessage });
    }
    notification.destroy(notificationKey);
  }

  async function handleCloudinitScript(index: number) {
    const notificationKey: string = "cloudinitScript";
    notification.open({
      key: notificationKey,
      type: "info",
      message: "Cloud Init Script",
      description: "Fetching data from the server, please wait",
    });

    try {
      setCloudinitScriptIndex(index);
      const {
        data: { script },
      } = await request({
        method: "post",
        url: resourceLink("mk8s", mk8sDraft._name) + "/-cloudinit",
        body: {
          nodePoolName: g.nodePools.find((np) => np.index === index)!.name.value,
        },
      });
      setCloudinitScript(script);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to create cloud init script", description: errorMessage });
    }
    notification.destroy(notificationKey);
  }

  return (
    //
    <div>
      <div>
        <NGLabel className="mb-2">Node Pools</NGLabel>
        <div>
          {g.nodePools.length > 0 ? (
            <div className="flex items-center table-labels px-2 py-1">
              <div className="w-2/12">Name</div>
              <div className="w-4/12">Labels</div>
              <div className="w-4/12">Taints</div>
              <div className="w-2/12"></div>
            </div>
          ) : (
            <div
              className="border py-8 flex flex-col gap-4 mt-4 items-center justify-center"
              style={{ borderRadius: 6, width: 450 }}
            >
              <div className="text-lg">No node pools are added.</div>
              <NGButton variant={"action"} onClick={() => navigate("-add")}>
                Add a Node Pool
              </NGButton>
            </div>
          )}
          {g.nodePools.length > 0 ? (
            <div>
              {g.nodePools.map((np, npIndex) => {
                const lastNodePool = npIndex === g.nodePools.length - 1;
                return (
                  <>
                    <div key={np.index} className={`flex p-2 ${lastNodePool ? "" : "border-b"}`}>
                      <div className="w-2/12">
                        <Link className="color-link" to={`${np.index}`}>
                          {np.name.value}
                        </Link>
                      </div>
                      <div className="w-4/12">
                        {np.labels.items.map((l) => (
                          <NodePoolLabel label={{ key: l.firstValue, value: l.secondValue }} />
                        ))}
                      </div>
                      <div className="w-4/12">
                        {np.taints.items.map((l) => (
                          <NodePoolTaint taint={{ key: l.firstValue, value: l.secondValue, effect: l.thirdValue }} />
                        ))}
                      </div>
                      <div className="w-2/12 flex justify-end gap-2">
                        <Dropdown
                          trigger={["click"]}
                          menu={{
                            onClick: async function ({ key }: any) {
                              switch (key) {
                                case "remove":
                                  g.removeNodePoolAt(np.index);
                                  break;
                                case "join":
                                  if (mk8sDraft.isDirty) {
                                    Modal.warning({
                                      title: "Node Pool Join Script",
                                      content: "Please save your changes before trying to create the join script.",
                                    });
                                    return;
                                  }

                                  handleJoinScript(np.index);
                                  break;
                                case "cloudinit":
                                  if (mk8sDraft.isDirty) {
                                    Modal.warning({
                                      title: "Node Pool Cloud Init Script",
                                      content:
                                        "Please save your changes before trying to create the cloud init script.",
                                    });
                                    return;
                                  }
                                  handleCloudinitScript(np.index);
                                  break;
                                default:
                                  break;
                              }
                            },
                            items: [
                              { key: "join", label: "Generate Join Script" },
                              { key: "cloudinit", label: "Generate Cloud Init Script" },
                              { key: "remove", label: "Remove", danger: true },
                            ],
                          }}
                        >
                          <AntButton size="small" type={"primary"}>
                            <Space>
                              More
                              <DownOutlined />
                            </Space>
                          </AntButton>
                        </Dropdown>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          ) : null}
          {!!joinScript ? (
            <Mk8sScriptModal
              clusterName={mk8sDraft._name}
              nodePoolName={g.nodePools.find((np) => np.index === joinScriptIndex)!.name.value}
              script={joinScript}
              poolType={"Node Pool"}
              scriptName={"Join Script"}
              extension={"sh"}
              onDone={() => {
                setJoinScriptIndex(-1);
                setJoinScript("");
              }}
              visible={!!joinScript}
            />
          ) : null}
          {!!cloudinitScript ? (
            <Mk8sScriptModal
              clusterName={mk8sDraft._name}
              nodePoolName={g.nodePools.find((np) => np.index === cloudinitScriptIndex)!.name.value}
              script={cloudinitScript}
              poolType={"Node Pool"}
              scriptName={"Cloud Init Script"}
              extension={"yaml"}
              onDone={() => {
                setCloudinitScriptIndex(-1);
                setCloudinitScript("");
              }}
              visible={!!cloudinitScript}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const ProviderGenericNodePools = observer(ProviderGenericNodePoolsRaw);
