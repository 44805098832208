import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { MoreHorizontal, PlusCircle } from "react-feather";
import { NGKindSelect } from "../../../../newcomponents/select/ngkindselect";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";
import NGAlert from "../../../../newcomponents/alert";
import { NGProviderCreateOpaqueSecret } from "../../ngProviderCreateOpaqueSecret";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { getDryRunErrorText } from "../../getDryRunErrorText";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGLabel } from "../../../../newcomponents/text/label";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderLambdalabsRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const l = mk8sDraft.provider_lambdalabs!;

  const [browserKey, setBrowserKey] = React.useState("");

  const fileSystemsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.lambdalabs.fileSystems",
    paths: l.fileSystems.items.map((_, idx) => `spec.provider.lambdalabs.fileSystems[${idx}]`),
  });

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <div>
            <NGLabel>Region</NGLabel>
            <div className="flex items-center mb-4">
              <div>{l.region.value}</div>
            </div>
          </div>
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={l.region.setValue}
              path={"spec.provider.lambdalabs.region"}
            />
          </div>
        </div>

        <div className="mb-4">
          <NGFormLabel required invalid={!l.tokenSecretName}>
            Lambda Labs Token Secret
          </NGFormLabel>
          <div className="mb-2 flex items-start gap-2">
            <NGKindSelect
              style={{ width: 450 }}
              value={l.tokenSecretName}
              invalid={!l.tokenSecretName}
              onChange={(value) => l.setTokenSecretName(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "opaque" }]}
              buttons={[
                {
                  title: "Create",
                  render: () => <PlusCircle className="h-4" />,
                  onClick: () => setBrowserKey("createSecret"),
                },
              ]}
            />
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              path={"spec.provider.lambdalabs.tokenSecretLink"}
            />
          </div>
          <NGAlert
            style={{ width: 450 }}
            render={() => (
              <div className="flex flex-col text-sm">
                <span>An opaque secret that contains your Lambda Labs token.</span>
                <span>It will be used to manage servers in your Lambda Labs account.</span>
              </div>
            )}
          />
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"lambdalabs.sshKey"}
            label={l.sshKey.label}
            value={l.sshKey.value}
            onChange={l.sshKey.setValue}
            required={l.sshKey.isRequired}
            error={l.sshKey.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("ssh-keys"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={l.sshKey.setValue}
              path={"spec.provider.lambdalabs.sshKey"}
            />
          </div>
        </div>
      </div>

      <NGInputListMst
        data={l.fileSystems}
        styles={{ container: { width: 450 } }}
        labelRender={() => {
          return (
            <NGButton
              className="ml-1"
              size={"small"}
              variant={"secondary"}
              onClick={() => setBrowserKey("file-systems")}
            >
              Browse
            </NGButton>
          );
        }}
        firstInput={(i, index) => {
          let invalid = false;
          if (i.firstValue && fileSystemsDryRun.message.includes(`fileSystems[${index}]`)) {
            invalid = true;
          }
          return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
        }}
        label="File Systems"
        invalid={fileSystemsDryRun.severity === "error"}
        error={fileSystemsDryRun.severity === "error" ? fileSystemsDryRun.message : ""}
        warning={fileSystemsDryRun.severity === "error" ? "" : fileSystemsDryRun.message}
      />

      {browserKey === "createSecret" ? (
        <NGProviderCreateOpaqueSecret
          onOk={(secretName: string) => l.setTokenSecretName(secretName)}
          onClose={() => setBrowserKey("")}
          title={"Create Lambda Labs Token Secret"}
          dataLabel={"Lambda Labs Token"}
        />
      ) : null}
      {browserKey === "ssh-keys" ? (
        <NGProviderBrowser
          key={l.tokenSecretName}
          title="Browse Lambda Labs SSH Keys"
          initialValue={l.sshKey.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ssh-keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => l.sshKey.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name"]}
          getData={(item: any) => [String(item.name), item.name]}
        />
      ) : null}
      {browserKey === "file-systems" ? (
        <NGProviderBrowser
          title="Browse Lambda Labs File Systems"
          initialValue={l.fileSystems.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "file-systems",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (l.fileSystems.items.some((i) => i.firstValue === value)) {
                continue;
              }
              l.fileSystems.add();
              const i = l.fileSystems.items[l.fileSystems.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Region", "Status", "Created By (Email)"]}
          getData={(item: any) => [
            String(item.name),
            item.name,
            item.region?.name || "",
            item.created_by?.status || "",
            item.created_by?.email || "",
          ]}
        />
      ) : null}
    </>
  );
};

export const ProviderLambdalabs = observer(ProviderLambdalabsRaw);
