import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel } from "../base";
import { QueryModel } from "../query";
import { request } from "../../services/cpln";

const GroupIdentityMatcherModel = types.model({
  expression: types.optional(types.string, ""),
  language: types.optional(types.string, "jmespath"),
});

export const GroupModel = types
  .compose(
    "Group",
    BaseModel,
    types.model({
      memberLinks: types.array(types.string),
      memberQuery: types.maybe(types.late(() => QueryModel)),
      identityMatcher: types.maybe(GroupIdentityMatcherModel),
      origin: types.optional(types.string, "default"), // "synthetic", "default", "builtin"
    })
  )
  .views((self) => ({
    get userLinks() {
      return self.memberLinks.filter((link) => link.includes("/user/"));
    },
    get serviceaccountLinks() {
      return self.memberLinks.filter((link) => link.includes("/serviceaccount/"));
    },
  }))
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface GroupMobx extends Instance<typeof GroupModel> {}

export type IdentityMatcherLanguage = "jmespath" | "javascript";

export function createGroupInstance(data: any) {
  if (!!data.memberQuery && !data.memberQuery.spec) {
    delete data.memberQuery;
  }
  return GroupModel.create(data);
}
