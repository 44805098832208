import { types } from "mobx-state-tree";

export interface AWSIdentityDataSnapshot {
  cloudAccountLink: string;
  roleName: string;
  policyRefs: string[];
}

export const AWSIdentityDataModel = types
  .model({
    isActive: false,
    cloudaccountLink: types.optional(types.string, ""),
    method: types.optional(types.enumeration(["existing", "new"]), "existing"),
    role: types.maybe(types.string),
    policies: types.array(types.string),
  })
  .actions((self) => ({
    clear() {
      self.isActive = false;
      self.cloudaccountLink = "";
      self.method = "existing";
      self.role = undefined;
      self.policies.clear();
    },
    apply(obj: AWSIdentityDataSnapshot) {
      self.isActive = true;
      self.cloudaccountLink = obj.cloudAccountLink;
      if (obj.roleName) {
        self.method = "existing";
        self.role = obj.roleName;
        self.policies.clear();
      } else {
        self.method = "new";
        self.role = undefined;
        self.policies.clear();
        for (let policy of obj.policyRefs) {
          self.policies.push(policy);
        }
      }
    },
  }))
  .views((self) => ({
    get asObject(): AWSIdentityDataSnapshot {
      return {
        cloudAccountLink: self.cloudaccountLink,
        roleName: self.method === "existing" ? self.role || "" : "",
        policyRefs: self.method === "new" ? [...self.policies] : [],
      };
    },
    get asObjectForHTTP() {
      const body: any = {
        cloudAccountLink: self.cloudaccountLink,
      };
      if (self.method === "existing") {
        body.roleName = self.role || "";
      } else {
        body.policyRefs = [...self.policies];
      }
      return body;
    },
  }));
