import { observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { UserData } from "../../mobxStores/userData/userData";
import { NGButton } from "../../newcomponents/button/Button";

const GVCRouteIndexRaw: React.FC = () => {
  const navigate = useNavigate();

  if (!UserData.hasOrg) {
    return <Navigate to={"/billing"} />;
  }

  if (!ConsoleContext.hasOrg) {
    return <Navigate to={"/console/org/-select"} />;
  }

  if (!UserData.hasGVC) {
    return (
      <div className="flex flex-col items-center">
        <div className="  mt-8 mb-4">No GVCs found for this org.</div>
        <div className="  mb-8">You need to create a GVC to start deploying workloads.</div>
        <NGButton
          variant={"action"}
          onClick={() => {
            navigate(`/console/org/${ConsoleContext.org}/gvc/-create`);
          }}
        >
          Create GVC
        </NGButton>
      </div>
    );
  }

  if (!ConsoleContext.hasGVC) {
    return <Navigate to={`/console/org/${ConsoleContext.org}/gvc/-select`} />;
  }

  return <Navigate to={`/console/org/${ConsoleContext.org}/gvc/${ConsoleContext.gvc}`} />;
};

export const GVCRouteIndex = observer(GVCRouteIndexRaw);
