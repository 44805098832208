import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderEphemeral } from "./ephemeral";
import { Mk8sCreateProviderEphemeralNodePool } from "./nodePool";
import { Mk8sCreateProviderEphemeralNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderEphemeralNodePools } from "./nodePools";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderEphemeralRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderEphemeral mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderEphemeralNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderEphemeralNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderEphemeralNodePool mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderEphemeralRoute = observer(Mk8sCreateProviderEphemeralRouteRaw);
