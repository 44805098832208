import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderHetznerDedicatedServerNodePool } from "./dedicatedNodePool";
import { ProviderHetznerDedicatedServerNodePoolAdd } from "./dedicatedNodePoolAdd";
import { ProviderHetznerDedicatedServerNodePools } from "./dedicatedNodePools";
import { ProviderHetzner } from "./hetzner";
import { ProviderHetznerAdvanced } from "./advanced";
import { ProviderHetznerNodePool } from "./nodePool";
import { ProviderHetznerNodePoolAdd } from "./nodePoolAdd";
import { ProviderHetznerNodePools } from "./nodePools";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderHetznerRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderHetzner mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderHetznerNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<ProviderHetznerNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<ProviderHetznerNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-dedicated-server-node-pools`}
        element={<ProviderHetznerDedicatedServerNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-dedicated-server-node-pools/-add`}
        element={<ProviderHetznerDedicatedServerNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-dedicated-server-node-pools/:index`}
        element={<ProviderHetznerDedicatedServerNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-advanced`} element={<ProviderHetznerAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderHetznerRoute = observer(ProviderHetznerRouteRaw);
