import stringToColor from "string-to-color";
import Color from "color";
import * as React from "react";
import { User as UserIcon } from "react-feather";
import { User } from "../../../../mobxStores/user/user";
import { observer } from "mobx-react-lite";
import { FastAverageColor } from "fast-average-color";
import { Badge } from "antd";
import { LockTwoTone } from "@ant-design/icons";
import { UserData } from "../../../../mobxStores/userData/userData";

interface Props {
  size: number;
  // show tasks count and isSaml icon
  showAdditions?: boolean;
}
const UserAvatarRaw: React.FC<Props> = ({ size = 32, showAdditions = false }) => {
  const [avatarColor, setAvatarColor] = React.useState(stringToColor(User.email.split("@")[0]));
  const [imageAverageColor, setImageAverageColor] = React.useState<string | null>(null);

  React.useEffect(() => {
    let iteration = 0;
    let maxIteration = 10;
    let color = Color(avatarColor);
    while (color.luminosity() > 0.3 && iteration < maxIteration) {
      iteration += 1;
      color = color.darken(0.1);
    }
    setAvatarColor(color.hex());
  }, []);

  React.useEffect(() => {
    getAverageColor();
  }, []);

  async function getAverageColor() {
    try {
      const fac = new FastAverageColor();
      const color = await fac.getColorAsync(User.photoURL);
      setImageAverageColor(color.hex);
    } catch (e) {
      setImageAverageColor(null);
    }
  }

  return (
    <Badge count={showAdditions && UserData.hasOrgInvite ? UserData.orgInvites.length : undefined} size="small">
      <div className="nguser-avatar-container">
        <div className="nguser-avatar" style={{ backgroundColor: avatarColor, width: size, height: size }}>
          {User.photoURL ? (
            <img className="nguser-avatar-image" src={User.isAdmin ? undefined : User.photoURL} />
          ) : User.avatarInitials ? (
            <span
              className={`nguser-avatar-initials ${
                User.avatarInitials.length > 2 ? "nguser-avatar-initials-large" : ""
              }`}
            >
              {User.avatarInitials}
            </span>
          ) : (
            <UserIcon />
          )}
        </div>
        {User.isSaml && showAdditions ? (
          <LockTwoTone
            className="nguser-avatar-lock"
            color={imageAverageColor || avatarColor}
            style={{
              right: -7,
              top: 20,
              width: 15,
              height: 15,
            }}
          />
        ) : null}
      </div>
    </Badge>
  );
};

export const UserAvatar = observer(UserAvatarRaw);
