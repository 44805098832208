import * as React from "react";

interface Props extends React.PropsWithChildren {
  size?: "xlarge" | "large" | "base" | "small" | "xsmall";
  parent?: boolean;
}
export const FormLabel: React.FC<Props> = ({ children, size = "small", parent = false }) => {
  if (parent && size === "small") {
    size = "base";
  }
  let sizeClass = "text-base";
  switch (size) {
    case "xlarge":
      sizeClass = "text-xl";
      break;
    case "large":
      sizeClass = "text-lg";
      break;
    case "base":
      sizeClass = "text-base";
      break;
    case "small":
      sizeClass = "text-sm";
      break;
    case "xsmall":
      sizeClass = "text-xs";
      break;
  }
  return <div className={`font-medium tracking-wide text-light-600 ${sizeClass}`}>{children}</div>;
};
