import { TableColumn } from "../../types";

const nameDefault = { id: "name", label: "Name" };
export function NameNoLinkColumn<TData extends { name: string; kind: string }>(
  column: Partial<TableColumn<TData>> = nameDefault,
) {
  const preset: TableColumn<TData> = {
    ...nameDefault,
    enableResize: true,
    enableSort: false,
    size: 200,
    cell: (p) => {
      const item = p.row.original;

      return (
        <div className="flex flex-col justify-center" style={{ height: 56 }}>
          {item.name}
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
