import * as React from "react";
import { ServiceAccountMobx } from "../../mst/kinds/serviceaccount";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Modal, Checkbox, notification } from "antd";
import { KeyModal } from "../../components/modals/keyModal";
import { useDetailContext } from "../../components/detail/detailContext";
import { NGButton } from "../../newcomponents/button/Button";
import { NGLabel } from "../../newcomponents/text/label";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { Tooltip } from "../../components/Tooltip";

interface Props {
  serviceaccount: ServiceAccountMobx;
  addKey: (description: string) => Promise<string>;
  removeKeys: (keys: string[]) => Promise<void>;
}
const ServiceAccountKeysRaw: React.FC<Props> = ({ serviceaccount, addKey, removeKeys }) => {
  const { fetchItem } = useDetailContext();

  const [isLoadingAdd, setIsLoadingAdd] = React.useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = React.useState(false);
  const descriptionRef = React.useRef(StringModel.create({ label: "Key Description", isRequired: true }));
  const [createdKey, setCreatedKey] = React.useState("");
  const [keyPath, setKeyPath] = React.useState("");
  const [keysToRemove, setKeysToRemove] = React.useState<string[]>([]);
  const [isRemovingKeys, setIsRemovingKeys] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(descriptionRef.current.isDirty || isLoadingAdd || isLoadingRemove);
  }, [descriptionRef.current.isDirty, isLoadingAdd, isLoadingRemove]);

  useCleanPrompt();

  async function addKeyConfirm() {
    try {
      setIsLoadingAdd(true);
      const keyContent = await addKey(descriptionRef.current.value);
      setKeyPath(`${serviceaccount.name}-${descriptionRef.current.value}`);
      descriptionRef.current.reset();
      notification.success({
        message: "Success",
        description: "Updated service account",
      });
      setIsLoadingAdd(false);
      setCreatedKey(keyContent);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoadingAdd(false);
    }
  }

  function onToggleKeyToRemove(name: string, value: boolean) {
    if (value) {
      if (!keysToRemove.includes(name)) {
        setKeysToRemove([...keysToRemove, name]);
      }
    } else {
      if (keysToRemove.includes(name)) {
        setKeysToRemove((k) => k.filter((_k) => _k !== name));
      }
    }
  }

  async function removeKeysConfirm() {
    setIsRemovingKeys(true);
  }

  async function onKeysRemoval(): Promise<void> {
    try {
      setIsLoadingRemove(true);
      await removeKeys(keysToRemove);
      setKeysToRemove([]);
      notification.success({
        message: "Success",
        description: "Removed keys from service account",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        setKeysToRemove([]);
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    } finally {
      setIsRemovingKeys(false);
      setIsLoadingRemove(false);
    }
  }

  function cancelKeysRemoval(): void {
    setIsRemovingKeys(false);
    setIsLoadingRemove(false);
  }

  return (
    <>
      <div>
        <div style={{ width: 450 }}>
          <NGFormElement
            name="description"
            label={descriptionRef.current.label}
            value={descriptionRef.current.value}
            onChange={descriptionRef.current.setValue}
          />
          <div className="mt-4 mb-8 flex items-center">
            <NGButton
              style={{ width: 215 }}
              disabled={isLoadingAdd || !descriptionRef.current.isValid}
              loading={isLoadingAdd}
              variant={"primary"}
              onClick={addKeyConfirm}
            >
              Add
            </NGButton>
          </div>
          <NGLabel>Remove Keys</NGLabel>
          <div className="border">
            <div className="flex table-labels px-4 py-1">
              <div style={{ width: 260 }}>Name</div>
              <div style={{ width: 300 }}>Description</div>
            </div>
            {serviceaccount.keys.length === 0 ? (
              <div className="flex items-center border-t px-4 py-1">No Keys found</div>
            ) : null}
            {serviceaccount.keys.map((key) => (
              <div className="flex items-center border-t px-4 py-1" key={key.name}>
                <Checkbox
                  checked={keysToRemove.includes(key.name)}
                  onChange={(e) => onToggleKeyToRemove(key.name, e.target.checked)}
                />
                <div className="ml-2" style={{ width: 230 }}>
                  {key.name}
                </div>
                <Tooltip title={key.description} open={key.description.length >= 21}>
                  <div className="truncate" style={{ width: 300 }}>
                    {key.description}
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
          <NGButton
            style={{ width: 215 }}
            disabled={isLoadingAdd || isRemovingKeys || keysToRemove.length < 1}
            variant={"danger"}
            onClick={removeKeysConfirm}
            className="mt-4"
            data-testid="action-remove"
          >
            Remove Key{keysToRemove.length > 1 ? "s" : ""}
          </NGButton>
        </div>
      </div>
      {createdKey.length > 0 ? (
        <KeyModal
          keyContent={createdKey}
          keyPath={keyPath}
          onDone={() => setCreatedKey("")}
          visible={createdKey.length > 0}
        />
      ) : null}
      {isRemovingKeys ? (
        <Modal
          title={`Do you want to remove the selected ${keysToRemove.length > 1 ? "keys" : "key"}?`}
          open={true}
          maskClosable={!isLoadingRemove}
          destroyOnClose
          onCancel={cancelKeysRemoval}
          footer={
            <div className="modal-actions">
              <NGButton
                data-testid="modal-action-cancel"
                variant="secondary"
                onClick={cancelKeysRemoval}
                disabled={isLoadingRemove}
              >
                Cancel
              </NGButton>
              <NGButton
                data-testid="modal-action-remove"
                variant="danger"
                loading={isLoadingRemove}
                disabled={isLoadingRemove}
                onClick={onKeysRemoval}
              >
                Remove
              </NGButton>
            </div>
          }
        >
          This action is irreversible.
        </Modal>
      ) : null}
    </>
  );
};

export const ServiceAccountKeys = observer(ServiceAccountKeysRaw);
