import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftContainerProbeMobx } from "../../../mst/stores/workload.draft.container.probe";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { HealthCheckDefault } from "./healthCheckDefault";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGTextArea } from "../../../newcomponents/textarea";
import { NGInput } from "../../../newcomponents/input/input";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  type: "readiness" | "liveness";
  container: WorkloadDraftContainerMobx;
  probe?: WorkloadDraftContainerProbeMobx;
  useProbe: boolean;
  setUseProbe: (value: boolean) => void;
}
const HealthCheckRaw: React.FC<Props> = ({ type, container, probe, useProbe, setUseProbe }) => {
  return (
    <>
      {(type === "readiness" && container.readinessProbeDefault) ||
      (type === "liveness" && container.livenessProbeDefault && container.readinessProbe?.method.value === "tcp") ||
      (useProbe && probe?.method.value == "tcp") ? (
        <NGAlert
          className="mb-1"
          style={{ maxWidth: "100%", width: "100%" }}
          type="warning"
          title="Unreliable Health Check"
          message="Workloads configured with basic health probes may experience temporary disruptions during the rollout and scaling activities."
        />
      ) : null}

      <div style={{ width: 450 }}>
        <div className="flex items-center" style={{ height: 50 }}>
          <NGSwitch
            data-testid={`switch-use-${type === "readiness" ? "readiness" : "liveness"}-probe`}
            value={useProbe}
            onChange={setUseProbe}
          >
            <NGLabelText>Override {type === "readiness" ? "Readiness Probe" : "Liveness Probe"}</NGLabelText>
          </NGSwitch>
          <InfoTooltip
            title={[
              type === "readiness"
                ? "A Probe to check if the workload is ready to receive additional traffic."
                : "A Probe to check if the workload is healthy or should be restarted.",
            ]}
          />
        </div>
        {useProbe ? (
          probe ? (
            <>
              <div className="mb-4">
                <NGLabel>{probe.method.label}</NGLabel>
                <NGSelect
                  className="flex-grow"
                  onChange={probe.method.setValue}
                  options={probe.method.options}
                  value={probe.method.value as any}
                />
              </div>
              <>
                {probe.method.value === "command" ? (
                  <div>
                    <NGFormElement
                      name={`${type}execCommand`}
                      label={"Command"}
                      className="flex-grow"
                      value={probe.exec_command}
                      onChange={probe.setExecCommand}
                    />
                    <NGInputListMst
                      label={"Arg List"}
                      data={probe.exec_args}
                      firstInput={(item) => (
                        <NGTextArea
                          value={item.firstValue}
                          onChange={(e) => item.setFirstValue(e.target.value)}
                          placeholder="Multiline Input"
                          invalid={!item.firstValue}
                        />
                      )}
                      invalid={probe.exec_args.items.some((i) => !i.firstValue)}
                      error={probe.exec_args.items.some((i) => !i.firstValue) ? "Items cannot be empty" : ""}
                    />
                  </div>
                ) : null}
                {probe.method.value === "tcp" ? (
                  <NGFormElement
                    name={`${type}tcp_port`}
                    label={probe.tcp_port.label}
                    value={probe.tcp_port.value}
                    onChange={probe.tcp_port.setValue}
                  />
                ) : null}
                {probe.method.value === "grpc" ? (
                  <NGFormElement
                    name={`${type}grpc_port`}
                    label={probe.grpc_port.label}
                    value={probe.grpc_port.value}
                    onChange={probe.grpc_port.setValue}
                  />
                ) : null}
                {probe.method.value === "http" ? (
                  <>
                    <div className="flex items-center">
                      <NGFormElement
                        style={{ width: 100, marginRight: 10 }}
                        name={`${type}httpScheme`}
                        label={probe.http_scheme.label}
                        as={"select"}
                        onChange={(value) => {
                          probe.http_scheme.setValue(value);
                        }}
                        options={probe.http_scheme.options}
                        value={probe.http_scheme.value as any}
                      />
                      <NGFormElement
                        name={`${type}httpPath`}
                        style={{ width: 230 }}
                        label={probe.http_path.label}
                        className="flex-grow"
                        value={probe.http_path.value}
                        onChange={probe.http_path.setValue}
                      />
                      <NGFormElement
                        name={`${type}httpPort`}
                        label={probe.http_port.label}
                        style={{ width: 100, marginLeft: 10 }}
                        value={probe.http_port.value}
                        onChange={probe.http_port.setValue}
                      />
                    </div>
                    <NGInputListMst
                      label={"HTTP Headers"}
                      data={probe.http_headers}
                      firstLabel="Name"
                      firstInput={(item) => (
                        <NGInput
                          value={item.firstValue}
                          onChange={(e) => item.setFirstValue(e.target.value)}
                          invalid={!item.firstValue}
                        />
                      )}
                      secondLabel="Value"
                      secondInput={(item) => (
                        <NGInput value={item.secondValue} onChange={(e) => item.setSecondValue(e.target.value)} />
                      )}
                      invalid={probe.http_headers.items.some((i) => !i.firstValue)}
                      error={probe.http_headers.items.some((i) => !i.firstValue) ? "Key cannot be empty" : ""}
                    />
                  </>
                ) : null}

                <NGFormElement
                  name={`${type}probe.initialDelaySeconds`}
                  label={probe.initialDelaySeconds.label}
                  value={probe.initialDelaySeconds.value}
                  onChange={probe.initialDelaySeconds.setValue}
                />
                <NGFormElement
                  name={`${type}probe.periodSeconds`}
                  label={probe.periodSeconds.label}
                  value={probe.periodSeconds.value}
                  onChange={probe.periodSeconds.setValue}
                />
                <NGFormElement
                  name={`${type}probe.timeoutSeconds`}
                  label={probe.timeoutSeconds.label}
                  value={probe.timeoutSeconds.value}
                  onChange={probe.timeoutSeconds.setValue}
                />

                <NGFormElement
                  name={`${type}probe.successThreshold`}
                  label={probe.successThreshold.label}
                  value={probe.successThreshold.value}
                  onChange={probe.successThreshold.setValue}
                />
                <NGFormElement
                  name={`${type}probe.failureThreshold`}
                  label={probe.failureThreshold.label}
                  value={probe.failureThreshold.value}
                  onChange={probe.failureThreshold.setValue}
                />
              </>
            </>
          ) : null
        ) : null}

        {type === "readiness" && container.readinessProbeDefault ? (
          <HealthCheckDefault defaultObject={container.readinessProbeDefault} />
        ) : type === "liveness" && container.livenessProbeDefault ? (
          <HealthCheckDefault defaultObject={container.livenessProbeDefault} />
        ) : null}
      </div>
    </>
  );
};

export const HealthCheck = observer(HealthCheckRaw);
