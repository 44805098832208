import { LoggingCoralogixMobx } from "../../../../mst/kinds/org";
import { defaultValues } from "../../../../mst/base";
import { SelectModel } from "../../../../mobxDataModels/selectModel";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";

export function getLogsCoralogixData(coralogix: LoggingCoralogixMobx, orgName: string) {
  function getDefaultCoralogixCluster() {
    return coralogix.cluster || defaultValues.org.logging.coralogix.cluster;
  }
  const coralogix_cluster = SelectModel.create({
    label: "Cluster",
    initialValue: getDefaultCoralogixCluster(),
    options: [
      { label: "coralogix.com", value: "coralogix.com" },
      { label: "coralogix.us", value: "coralogix.us" },
      { label: "app.coralogix.in", value: "app.coralogix.in" },
      { label: "app.eu2.coralogix.com", value: "app.eu2.coralogix.com" },
      { label: "app.coralogixsg.com", value: "app.coralogixsg.com" },
    ],
  });
  function getDefaultCoralogixCredentials() {
    return ngParseLink(coralogix.credentials || "").name;
  }

  const coralogix_credentials = StringModel.create({
    isRequired: true,
    label: "Credentials",
    initialValue: getDefaultCoralogixCredentials(),
  });
  function getDefaultCoralogixApp() {
    return coralogix.app || "";
  }
  const coralogix_app = StringModel.create({
    label: "App",
    validationKey: "coralogix",
    initialValue: getDefaultCoralogixApp(),
  });
  coralogix_app.setValue("cpln");

  function getDefaultCoralogixSubsystem() {
    return coralogix.subsystem || "";
  }

  const coralogix_subsystem = StringModel.create({
    label: "Subsystem",
    validationKey: "coralogix",
    initialValue: getDefaultCoralogixSubsystem(),
  });
  coralogix_subsystem.setValue(orgName);

  function isDirty() {
    let res = false;
    if (getDefaultCoralogixCluster() !== coralogix_cluster.value) {
      res = true;
    }
    if (getDefaultCoralogixCredentials() !== coralogix_credentials.value) {
      res = true;
    }
    if (getDefaultCoralogixApp() !== coralogix_app.value) {
      res = true;
    }
    if (getDefaultCoralogixSubsystem() !== coralogix_subsystem.value) {
      res = true;
    }
    return res;
  }

  function isValid() {
    let res = true;
    if (coralogix_credentials.value.length < 1) {
      res = false;
    }
    if (!coralogix_app.isValid) {
      res = false;
    }
    if (!coralogix_subsystem.isValid) {
      res = false;
    }
    return res;
  }

  function asObject() {
    const coralogix: any = {
      cluster: coralogix_cluster.value,
      credentials: ngParseLink(coralogix_credentials.value, { kind: "secret" }).absolute,
    };
    if (coralogix_app.value.length > 0) {
      coralogix.app = coralogix_app.value;
    }
    if (coralogix_subsystem.value.length > 0) {
      coralogix.subsystem = coralogix_subsystem.value;
    }
    return {
      coralogix: coralogix,
    };
  }

  return {
    logProvider: "coralogix",
    cluster: coralogix_cluster,
    credentials: coralogix_credentials,
    app: coralogix_app,
    subsystem: coralogix_subsystem,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
