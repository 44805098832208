import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, notification } from "antd";
import { BindingDetailTable } from "./bindingDetailTable";
import { ArrowLeft } from "react-feather";
import { useDetailContext } from "../../../../components/detail/detailContext";
import { BasePathContext } from "../../../../reactContexts/basePathContext";
import { NGButton } from "../../../../newcomponents/button/Button";
import { PromptContext } from "../../../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../../../reactHooks/useCleanPrompt";
import { useSetPromptShouldBlock } from "../../../../reactHooks/useSetPromptShouldBlock";
import { PolicyMobx } from "../../../../mst/kinds/policy";
import { Permissions, Permission } from "../../../../schema/types/permissions";
import { arraysAreEqual, homeLink, linksOf, request } from "../../../../services/cpln";
import { User } from "../../../../schema/types/user";
import { ServiceAccount } from "../../../../schema/types/serviceaccount";
import { Group } from "../../../../schema/types/group";
import { Identity } from "../../../../schema/types/identity";
import { permissionsHelpers } from "../../../../mst/kinds/permissions.helpers";
import { AxiosResponse } from "axios";
import { UserTable } from "../../create/targetTables/user";
import { ServiceaccountTable } from "../../create/targetTables/serviceaccount";
import { GroupTable } from "../../create/targetTables/group";
import { IdentityTable } from "../../create/targetTables/identity";
import { Binding } from "../../../../schema/types/policy";
import { Table } from "../../../../newcomponents/table/table";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { Loader } from "../../../../components/layout/loader";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";

// TODO can share these
interface PermissionTableItem extends Permission {
  kind: "permission";
  description: string;
  implied: string[];
  impliedText: string;
}

interface UserTableItem extends User {
  selfLink: string;
}

interface ServiceaccountTableItem extends ServiceAccount {
  selfLink: string;
}

interface GroupTableItem extends Group {
  selfLink: string;
}

interface IdentityTableItem extends Identity {
  selfLink: string;
}

type RouteParams = "indexString";

interface Props {
  policy: PolicyMobx;
}
const BindingEditRaw: React.FC<Props> = ({ policy }) => {
  const basePath = React.useContext(BasePathContext);
  const bindingsPath = `${basePath}/-bindings`;

  const { indexString } = useParams<RouteParams>();

  const [isPageReady, setIsPageReady] = React.useState(false);

  const bindingRef = React.useRef<{ permissions: string[]; principalLinks: string[] }>(
    Number.isNaN(Number(indexString)) || !policy.bindings[Number(indexString)]
      ? { permissions: [], principalLinks: [] }
      : JSON.parse(JSON.stringify(policy.bindings[Number(indexString)])),
  );

  const { fetchItem } = useDetailContext();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!Number.isNaN(Number(indexString)) && !!policy.bindings[Number(indexString)]) {
      setIsPageReady(true);
    }
  }, []);

  const [permissionsResponse, setPermissionsResponse] = React.useState<Permissions>();
  let permissionTableItems: PermissionTableItem[] = [];
  if (permissionsResponse) {
    permissionTableItems = permissionsResponse.items.map((p) => ({
      ...p,
      description: p.description || "",
      kind: "permission",
      implied: permissionsHelpers.getImplied(permissionsResponse, p),
      impliedText: permissionsHelpers.getImpliedText(permissionsResponse, p),
    }));
  }

  function getDefaultPermissions() {
    return [...bindingRef.current.permissions];
  }
  function getDefaultUsers() {
    return [...bindingRef.current.principalLinks.filter((l) => l.includes("/user/"))];
  }
  function getDefaultServiceaccounts() {
    return [...bindingRef.current.principalLinks.filter((l) => l.includes("/serviceaccount/"))];
  }
  function getDefaultGroups() {
    return [...bindingRef.current.principalLinks.filter((l) => l.includes("/group/"))];
  }
  function getDefaultIdentities() {
    return [...bindingRef.current.principalLinks.filter((l) => l.includes("/identity/"))];
  }

  const [permissionItems, setPermissionItems] = React.useState<PermissionTableItem[]>([]);
  const [userItems, setUserItems] = React.useState<UserTableItem[]>([]);
  const [serviceAccountItems, setServiceaccountItems] = React.useState<ServiceaccountTableItem[]>([]);
  const [groupItems, setGroupItems] = React.useState<GroupTableItem[]>([]);
  const [identityItems, setIdentityItems] = React.useState<IdentityTableItem[]>([]);

  function getCurrentPermissions() {
    return permissionItems.map((p) => p.name);
  }
  function getCurrentUsers() {
    return userItems.map((item) => item.selfLink);
  }
  function getCurrentServiceaccounts() {
    return serviceAccountItems.map((item) => item.selfLink);
  }
  function getCurrentGroups() {
    return groupItems.map((item) => item.selfLink);
  }
  function getCurrentIdentities() {
    return identityItems.map((item) => item.selfLink);
  }

  const [permissionSelectionsToRemove, setPermissionSelectionsToRemove] = React.useState<string[]>([]);
  const [userSelectionsToRemove, setUserSelectionsToRemove] = React.useState<string[]>([]);
  const [serviceaccountSelectionsToRemove, setServiceaccountSelectionsToRemove] = React.useState<string[]>([]);
  const [groupSelectionsToRemove, setGroupSelectionsToRemove] = React.useState<string[]>([]);
  const [identitySelectionsToRemove, setIdentitySelectionsToRemove] = React.useState<string[]>([]);

  const [permissionSelectionsToAdd, setPermissionSelectionsToAdd] = React.useState<string[]>([]);
  const [userSelectionsToAdd, setUserSelectionsToAdd] = React.useState<string[]>([]);
  const [serviceaccountSelectionsToAdd, setServiceaccountSelectionsToAdd] = React.useState<string[]>([]);
  const [groupSelectionsToAdd, setGroupSelectionsToAdd] = React.useState<string[]>([]);
  const [identitySelectionsToAdd, setIdentitySelectionsToAdd] = React.useState<string[]>([]);

  const [userItemsToAdd, setUserItemsToAdd] = React.useState<UserTableItem[]>([]);
  const [serviceaccountItemsToAdd, setServiceaccountItemsToAdd] = React.useState<ServiceaccountTableItem[]>([]);
  const [groupItemsToAdd, setGroupItemsToAdd] = React.useState<GroupTableItem[]>([]);
  const [identityItemsToAdd, setIdentityItemsToAdd] = React.useState<IdentityTableItem[]>([]);

  const [isAddingPermission, setIsAddingPermission] = React.useState(false);
  const [isAddingUser, setIsAddingUser] = React.useState(false);
  const [isAddingServiceaccount, setIsAddingServiceaccount] = React.useState(false);
  const [isAddingGroup, setIsAddingGroup] = React.useState(false);
  const [isAddingIdentity, setIsAddingIdentity] = React.useState(false);

  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    fetchUserItems();
    fetchServiceaccountItems();
    fetchGroupItems();
    fetchIdentityItems();
  }, []);

  React.useEffect(() => {
    if (!permissionsResponse) {
      return;
    }

    setPermissionItems(permissionTableItems.filter((p) => getDefaultPermissions().includes(p.name)));
  }, [permissionsResponse, bindingRef.current.permissions]);

  React.useEffect(() => {
    let isDirty = false;
    if (!arraysAreEqual(getDefaultPermissions(), getCurrentPermissions())) isDirty = true;
    if (!arraysAreEqual(getDefaultUsers(), getCurrentUsers())) isDirty = true;
    if (!arraysAreEqual(getDefaultServiceaccounts(), getCurrentServiceaccounts())) isDirty = true;
    if (!arraysAreEqual(getDefaultGroups(), getCurrentGroups())) isDirty = true;
    if (!arraysAreEqual(getDefaultIdentities(), getCurrentIdentities())) isDirty = true;
    setIsDirty(isDirty);
  }, [permissionItems.length, userItems.length, serviceAccountItems.length, groupItems.length, identityItems.length]);

  React.useEffect(() => {
    let isValid = true;
    if (getCurrentPermissions().length < 1) isValid = false;
    if (
      getCurrentUsers().length +
        getCurrentServiceaccounts().length +
        getCurrentGroups().length +
        getCurrentIdentities().length <
      1
    )
      isValid = false;
    setIsValid(isValid);
  }, [permissionItems.length, userItems.length, serviceAccountItems.length, groupItems.length, identityItems.length]);

  async function fetchUserItems() {
    const userLinks = bindingRef.current.principalLinks.filter((l) => l.includes("/user/"));
    const promises: Promise<AxiosResponse<User>>[] = userLinks.map((i) => request<User>({ url: i }));
    const responses = await Promise.allSettled(promises);

    setUserItems((currentItems) => {
      const newItems: UserTableItem[] = [];

      for (const responseIndexStr in responses) {
        const responseIndex = Number(responseIndexStr);
        const userLink = userLinks[responseIndex];
        const response = responses[responseIndex];

        if (response.status === "rejected") {
          const currentItem = currentItems[responseIndex];
          if (currentItem) {
            newItems.push(currentItem);
          } else {
            const { name } = ngParseLink(userLink);
            const defaultUser: UserTableItem = {
              created: Date().toString(),
              email: name,
              id: name,
              kind: "user",
              lastModified: Date().toString(),
              links: [{ href: userLink, rel: "self" }],
              name: name,
              selfLink: userLink,
              tags: {},
              version: 1,
            };
            newItems.push(defaultUser);
          }
          continue;
        }

        const newItem = { ...response.value.data, selfLink: linksOf(response.value.data).self! };
        newItems.push(newItem);
      }

      return newItems;
    });
  }

  async function fetchServiceaccountItems() {
    const serviceAccountLinks = bindingRef.current.principalLinks.filter((l) => l.includes("/serviceaccount/"));
    const promises: Promise<AxiosResponse<ServiceAccount>>[] = serviceAccountLinks.map((i) =>
      request<ServiceAccount>({ url: i }),
    );
    const responses = await Promise.allSettled(promises);

    setServiceaccountItems((currentItems) => {
      const newItems: ServiceaccountTableItem[] = [];

      for (const responseIndexStr in responses) {
        const responseIndex = Number(responseIndexStr);
        const serviceAccountLink = serviceAccountLinks[responseIndex];
        const response = responses[responseIndex];

        if (response.status === "rejected") {
          const currentItem = currentItems[responseIndex];
          if (currentItem) {
            newItems.push(currentItem);
          } else {
            const { name } = ngParseLink(serviceAccountLink);
            const defaultServiceAccount: ServiceaccountTableItem = {
              created: Date().toString(),
              description: name,
              id: name,
              kind: "serviceaccount",
              lastModified: Date().toString(),
              links: [{ href: serviceAccountLink, rel: "self" }],
              name: name,
              selfLink: serviceAccountLink,
              tags: {},
              version: 1,
            };
            newItems.push(defaultServiceAccount);
          }
          continue;
        }

        const newItem = { ...response.value.data, selfLink: linksOf(response.value.data).self! };
        newItems.push(newItem);
      }

      return newItems;
    });
  }

  async function fetchGroupItems() {
    const groupLinks = bindingRef.current.principalLinks.filter((l) => l.includes("/group/"));
    const promises: Promise<AxiosResponse<Group>>[] = [];
    const responses = await Promise.allSettled(promises);

    setGroupItems((currentItems) => {
      const newItems: GroupTableItem[] = [];

      for (const responseIndexStr in responses) {
        const responseIndex = Number(responseIndexStr);
        const groupLink = groupLinks[responseIndex];
        const response = responses[responseIndex];

        if (response.status === "rejected") {
          const currentItem = currentItems[responseIndex];
          if (currentItem) {
            newItems.push(currentItem);
          } else {
            const { name } = ngParseLink(groupLink);
            const defaultGroup: GroupTableItem = {
              created: Date().toString(),
              description: name,
              id: name,
              kind: "group",
              lastModified: Date().toString(),
              links: [{ href: groupLink, rel: "self" }],
              name: name,
              selfLink: groupLink,
              tags: {},
              version: 1,
            };
            newItems.push(defaultGroup);
          }
          continue;
        }

        const newItem = { ...response.value.data, selfLink: linksOf(response.value.data).self! };
        newItems.push(newItem);
      }

      return newItems;
    });
  }

  async function fetchIdentityItems() {
    const identityLinks = bindingRef.current.principalLinks.filter((l) => l.includes("/identity/"));
    const promises: Promise<AxiosResponse<Identity>>[] = identityLinks.map((i) => request<Identity>({ url: i }));
    const responses = await Promise.allSettled(promises);

    setIdentityItems((currentItems) => {
      const newItems: IdentityTableItem[] = [];

      for (const responseIndexStr in responses) {
        const responseIndex = Number(responseIndexStr);
        const identityLink = identityLinks[responseIndex];
        const response = responses[responseIndex];

        if (response.status === "rejected") {
          const currentItem = currentItems[responseIndex];
          if (currentItem) {
            newItems.push(currentItem);
          } else {
            const { name } = ngParseLink(identityLink);
            const defaultIdentity: IdentityTableItem = {
              created: Date().toString(),
              id: name,
              description: name,
              kind: "user",
              lastModified: Date().toString(),
              links: [{ href: identityLink, rel: "self" }],
              name: name,
              selfLink: identityLink,
              tags: {},
              version: 1,
            };
            newItems.push(defaultIdentity);
          }
          continue;
        }

        const newItem = {
          ...response.value.data,
          selfLink: linksOf(response.value.data).self!,
          gvc: linksOf(response.value.data).self!.split("/")[4],
        };
        newItems.push(newItem);
      }

      return newItems;
    });
  }

  React.useEffect(() => {
    let res = true;
    if (getCurrentPermissions().length < 1) res = false;
    if (
      getCurrentUsers().length + //
        getCurrentServiceaccounts().length +
        getCurrentGroups().length +
        getCurrentIdentities().length <
      1
    )
      res = false;
    setIsValid(res);
  }, [permissionItems.length, userItems.length, serviceAccountItems.length, groupItems.length, identityItems.length]);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty);
  }, [isDirty]);

  useCleanPrompt();
  useSetPromptShouldBlock(shouldBlockNavigation);

  React.useEffect(() => {
    fetchPermissions();
  }, []);

  async function fetchPermissions() {
    const { data } = await request({ url: homeLink(policy.targetKind) + "/-schema/permissions" });
    setPermissionsResponse(data);
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return pathname.split("-bindings").filter(Boolean).length < 2;
  }

  function onAdd() {
    if (isAddingPermission) {
      setPermissionItems((items) => {
        const currentPermissionNames = items.map((i) => i.name);
        const newItems = [...items];
        for (const permissionNameToAdd of permissionSelectionsToAdd) {
          if (currentPermissionNames.includes(permissionNameToAdd)) {
            continue;
          }
          const permissionItem = permissionTableItems.find((p) => p.name === permissionNameToAdd)!;
          newItems.push(permissionItem);
        }
        return newItems;
      });
      setPermissionSelectionsToAdd([]);
      return;
    }
    if (isAddingUser) {
      setUserItems((items) => {
        const currentLinks = items.map((i) => i.selfLink);
        const newItems = [...items];
        for (const itemToAdd of userItemsToAdd) {
          if (currentLinks.includes(itemToAdd.selfLink)) {
            continue;
          }
          newItems.push(itemToAdd);
        }
        return newItems;
      });
      setUserSelectionsToAdd([]);
      setUserItemsToAdd([]);
      return;
    }
    if (isAddingServiceaccount) {
      setServiceaccountItems((items) => {
        const currentLinks = items.map((i) => i.selfLink);
        const newItems = [...items];
        for (const itemToAdd of serviceaccountItemsToAdd) {
          if (currentLinks.includes(itemToAdd.selfLink)) {
            continue;
          }
          newItems.push(itemToAdd);
        }
        return newItems;
      });
      setServiceaccountSelectionsToAdd([]);
      setServiceaccountItemsToAdd([]);
      return;
    }
    if (isAddingGroup) {
      setGroupItems((items) => {
        const currentLinks = items.map((i) => i.selfLink);
        const newItems = [...items];
        for (const itemToAdd of groupItemsToAdd) {
          if (currentLinks.includes(itemToAdd.selfLink)) {
            continue;
          }
          newItems.push(itemToAdd);
        }
        return newItems;
      });
      setGroupSelectionsToAdd([]);
      setGroupItemsToAdd([]);
      return;
    }
    if (isAddingIdentity) {
      setIdentityItems((items) => {
        const currentLinks = items.map((i) => i.selfLink);
        const newItems = [...items];
        for (const itemToAdd of identityItemsToAdd) {
          if (currentLinks.includes(itemToAdd.selfLink)) {
            continue;
          }
          newItems.push(itemToAdd);
        }
        return newItems;
      });
      setIdentitySelectionsToAdd([]);
      setIdentityItemsToAdd([]);
      return;
    }
  }

  function onRemovePermission() {
    setPermissionItems((items) => items.filter((item) => !permissionSelectionsToRemove.includes(item.name)));
    setPermissionSelectionsToRemove([]);
  }

  function onRemoveUser() {
    setUserItems((items) => items.filter((item) => !userSelectionsToRemove.includes(item.selfLink)));
    setUserSelectionsToRemove([]);
  }

  function onRemoveServiceaccount() {
    setServiceaccountItems((items) =>
      items.filter((item) => !serviceaccountSelectionsToRemove.includes(item.selfLink)),
    );
    setServiceaccountSelectionsToRemove([]);
  }

  function onRemoveGroup() {
    setGroupItems((items) => items.filter((item) => !groupSelectionsToRemove.includes(item.selfLink)));
    setGroupSelectionsToRemove([]);
  }

  function onRemoveIdentity() {
    setIdentityItems((items) => items.filter((item) => !identitySelectionsToRemove.includes(item.selfLink)));
    setIdentitySelectionsToRemove([]);
  }

  async function onReset() {
    setIsLoading(true);
    setPermissionItems(permissionTableItems.filter((p) => getDefaultPermissions().includes(p.name)));
    const promises: Promise<any>[] = [
      fetchUserItems(),
      fetchServiceaccountItems(),
      fetchGroupItems(),
      fetchIdentityItems(),
    ];

    setPermissionSelectionsToRemove([]);
    setPermissionSelectionsToAdd([]);
    setUserSelectionsToRemove([]);
    setUserSelectionsToAdd([]);
    setServiceaccountSelectionsToRemove([]);
    setServiceaccountSelectionsToAdd([]);
    setGroupSelectionsToRemove([]);
    setGroupSelectionsToAdd([]);
    setIdentitySelectionsToRemove([]);
    setIdentitySelectionsToAdd([]);
    await Promise.all(promises);
    setIsLoading(false);
  }

  async function onSave() {
    try {
      setIsLoading(true);

      const bindings = JSON.parse(JSON.stringify(policy.bindings.map((b) => b.asObject)));
      const binding: Binding = {
        permissions: getCurrentPermissions(),
        principalLinks: [
          ...getCurrentUsers(),
          ...getCurrentServiceaccounts(),
          ...getCurrentGroups(),
          ...getCurrentIdentities(),
        ],
      };
      bindings[Number(indexString)] = binding;
      await policy.patch({ "$replace/bindings": bindings });
      setIsDirty(false);
      setIsLoading(false);
      notification.success({
        message: "Success",
        description: "Updated binding",
      });
      PromptContext.setWhen(false);
      setTimeout(() => {
        navigate(bindingsPath);
      }, 0);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  if (!isPageReady) {
    return <Loader reason={"fetching permissions"} />;
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <button className="ngfocus color-link" onClick={() => navigate(bindingsPath)}>
          <ArrowLeft className="mr-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          <span>Back to Bindings</span>
        </button>
      </div>
      <BindingDetailTable //
        onAddPermission={() => setIsAddingPermission(true)}
        onRemovePermission={onRemovePermission}
        permissionItems={permissionItems}
        permissionSelections={permissionSelectionsToRemove}
        setPermissionSelections={setPermissionSelectionsToRemove}
        //
        onAddUser={() => setIsAddingUser(true)}
        onRemoveUser={onRemoveUser}
        userItems={userItems}
        userSelections={userSelectionsToRemove}
        setUserSelections={setUserSelectionsToRemove}
        //
        onAddServiceaccount={() => setIsAddingServiceaccount(true)}
        onRemoveServiceaccount={onRemoveServiceaccount}
        serviceaccountItems={serviceAccountItems}
        serviceaccountSelections={serviceaccountSelectionsToRemove}
        setServiceaccountSelections={setServiceaccountSelectionsToRemove}
        //
        onAddGroup={() => setIsAddingGroup(true)}
        onRemoveGroup={onRemoveGroup}
        groupItems={groupItems}
        groupSelections={groupSelectionsToRemove}
        setGroupSelections={setGroupSelectionsToRemove}
        //
        onAddIdentity={() => setIsAddingIdentity(true)}
        onRemoveIdentity={onRemoveIdentity}
        identityItems={identityItems}
        identitySelections={identitySelectionsToRemove}
        setIdentitySelections={setIdentitySelectionsToRemove}
      />
      {isAddingPermission || isAddingUser || isAddingServiceaccount || isAddingGroup || isAddingIdentity ? (
        <Modal
          width={"90vw"}
          styles={{ body: { height: "70vh", padding: 0 } }}
          open={isAddingPermission || isAddingUser || isAddingServiceaccount || isAddingGroup || isAddingIdentity}
          onCancel={() => {
            setIsAddingPermission(false);
            setIsAddingUser(false);
            setIsAddingServiceaccount(false);
            setIsAddingGroup(false);
            setIsAddingIdentity(false);
          }}
          footer={
            <div className="modal-actions">
              <NGButton
                variant="secondary"
                onClick={() => {
                  setIsAddingPermission(false);
                  setIsAddingUser(false);
                  setIsAddingServiceaccount(false);
                  setIsAddingGroup(false);
                  setIsAddingIdentity(false);
                }}
              >
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={() => {
                  onAdd();
                  setIsAddingPermission(false);
                  setIsAddingUser(false);
                  setIsAddingServiceaccount(false);
                  setIsAddingGroup(false);
                  setIsAddingIdentity(false);
                }}
              >
                OK
              </NGButton>
            </div>
          }
          destroyOnClose={true}
          closable={false}
          maskClosable={false}
        >
          <div className="h-full overflow-auto p-1">
            {isAddingPermission ? (
              <Table<PermissionTableItem>
                tableId="policy-detail-bindings-detail-permission-add"
                selectMode="multi"
                selectKey="name"
                selections={permissionSelectionsToAdd}
                onSelectionsChange={setPermissionSelectionsToAdd}
                data={permissionTableItems}
                columns={[
                  NameDescriptionNoLinkColumn(), //
                  { id: "impliedText", label: "Implied" },
                ]}
              />
            ) : null}
            {isAddingUser ? (
              <UserTable
                // TODO can override id
                title="Users"
                selections={userSelectionsToAdd}
                onSelectionsChange={(keys, items) => {
                  setUserSelectionsToAdd(keys);
                  setUserItemsToAdd(items!);
                }}
              />
            ) : null}
            {isAddingServiceaccount ? (
              <ServiceaccountTable
                title="Service Accounts"
                selections={serviceaccountSelectionsToAdd}
                onSelectionsChange={(keys, items) => {
                  setServiceaccountSelectionsToAdd(keys);
                  setServiceaccountItemsToAdd(items!);
                }}
              />
            ) : null}
            {isAddingGroup ? (
              <GroupTable
                title="Groups"
                selections={groupSelectionsToAdd}
                onSelectionsChange={(keys, items) => {
                  setGroupSelectionsToAdd(keys);
                  setGroupItemsToAdd(items!);
                }}
              />
            ) : null}
            {isAddingIdentity ? (
              <IdentityTable
                title="Identities"
                selections={identitySelectionsToAdd}
                onSelectionsChange={(keys, items) => {
                  setIdentitySelectionsToAdd(keys);
                  setIdentityItemsToAdd(items!);
                }}
              />
            ) : null}
          </div>
        </Modal>
      ) : null}
      <div className="mt-8 flex items-center">
        <NGButton
          style={{ width: 215, marginRight: 10 }}
          disabled={isLoading || !isDirty}
          variant={"danger"}
          outlined
          onClick={onReset}
        >
          Reset
        </NGButton>
        <NGButton
          style={{ width: 215, marginLeft: 10 }}
          disabled={isLoading || !isDirty || !isValid}
          loading={isLoading}
          variant={"primary"}
          onClick={onSave}
        >
          Save
        </NGButton>
      </div>
    </>
  );
};

export const BindingEdit = observer(BindingEditRaw);
