import * as React from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { WorkloadDraftJobMobx } from "../../mst/stores/workload.draft.job";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { useDetailContext } from "../../components/detail/detailContext";
import { NGButton } from "../../newcomponents/button/Button";
import { NGLabel } from "../../newcomponents/text/label";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { NGInput } from "../../newcomponents/input/input";
import { InfoTooltip } from "../../components/InfoTooltip";
import cronstrue from "cronstrue";

interface Props {
  draft: WorkloadDraftMobx;
  job: WorkloadDraftJobMobx;
  patch: (body: any) => Promise<void>;
}
const JobRaw: React.FC<Props> = ({ draft, patch, job }) => {
  const { fetchItem } = useDetailContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    job.reset();
    return () => {
      job.reset();
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();

  React.useEffect(() => {
    setPromptWhen(job.isDirty);
  }, [job.isDirty]);

  function reset() {
    job.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      await patch(draft.asPatch);
      job.confirm();
      notification.success({
        message: "Success",
        description: "Workload is updated",
      });
      updateLastDeploymentTimeOnHubspot();
      setPromptWhen(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
      setPromptWhen(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div>
        <div className="mb-4">
          <NGLabel>Schedule (UTC)</NGLabel>
          <NGInput
            style={{ width: 450 }}
            className="mb-2"
            value={job.schedule.value}
            onChange={(e) => job.schedule.setValue(e.target.value)}
          />
          {job.schedule.value.length > 0 && job.schedule.isValid ? (
            <div>
              {cronstrue.toString(job.schedule.value, {
                throwExceptionOnParseError: false,
                use24HourTimeFormat: true,
              })}
            </div>
          ) : null}
        </div>
        <NGLabel>{job.concurrencyPolicy.label}</NGLabel>
        <div className="mb-4 relative flex items-center">
          <NGSelect
            style={{ width: 450, zIndex: 3 }}
            onChange={job.concurrencyPolicy.setValue}
            options={job.concurrencyPolicy.options}
            value={job.concurrencyPolicy.value as any}
          />
          <InfoTooltip
            title={`Either 'Forbid' or 'Replace'. This determines what Control Plane will do when the schedule requires a job to start, while a prior instance of the job is still running.`}
          />
        </div>
        <NGInputAdapter
          data={job.historyLimit}
          className="mb-4"
          style={{ width: 450 }}
          infoContents={[
            `The maximum number of completed job instances to display. This should be an integer between 1 and 10.`,
          ]}
        />
        <NGLabel>{job.restartPolicy.label}</NGLabel>
        <div className="mb-4 relative flex items-center">
          <NGSelect
            className="relative"
            style={{ width: 450, zIndex: 3 }}
            onChange={job.restartPolicy.setValue}
            options={job.restartPolicy.options}
            value={job.restartPolicy.value as any}
          />
          <InfoTooltip
            title={`Either 'OnFailure' or 'Never'. This determines what Control Plane will do when a job instance fails.`}
          />
        </div>
        <NGInputAdapter
          data={job.activeDeadlineSeconds}
          className="mb-4"
          style={{ width: 450 }}
          infoContents={[
            `The maximum number of seconds Control Plane will wait for the job to complete. If a job does not succeed or fail in the allotted time, Control Plane will stop the job, moving it into the Removed status.`,
          ]}
        />
        <div className="mt-8 flex items-center">
          <NGButton
            disabled={!job.isDirty || isLoading}
            style={{ width: 215, marginRight: 10 }}
            onClick={reset}
            variant={"danger"}
            outlined
          >
            Reset
          </NGButton>
          <NGButton
            variant={"primary"}
            disabled={!job.isDirty || isLoading || !job.isValid}
            loading={isLoading}
            style={{ width: 215, marginLeft: 10 }}
            onClick={save}
          >
            Save
          </NGButton>
        </div>
      </div>
    </>
  );
};

export const Job = observer(JobRaw);
