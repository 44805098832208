import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "@reach/dialog";
import { observer } from "mobx-react-lite";

type RouteParams = "videoIdName";
interface Props {
  videos: any[];
}
const VideoLightboxRaw: React.FC<Props> = ({ videos }) => {
  const navigate = useNavigate();
  const { videoIdName } = useParams<RouteParams>();
  const [videoId, setVideoId] = React.useState("");

  React.useEffect(() => {
    if (!videoIdName) {
      return;
    }
    setVideoId(videoIdName.split("-")[0]);
  }, [videoIdName]);

  function onDismiss() {
    navigate(`/console/educational-videos`);
  }

  const video = videos.find((v) => v.id === videoId);
  if (!video) {
    return null;
  }

  let iframeUrl = "";
  if (!!videoId) {
    iframeUrl = `https://player.vimeo.com/video/${video.url.split("/").slice(-2).join("?h=")}&autoplay=1`;
  }

  if (!iframeUrl) {
    return null;
  }

  return (
    <Dialog isOpen={!!videoId} onDismiss={onDismiss}>
      <iframe src={iframeUrl} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />
    </Dialog>
  );
};

export const VideoLightbox = observer(VideoLightboxRaw);
