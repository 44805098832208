import { request } from "../../services/cpln";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { ReleaseStateDeploymentResource } from "./types/release";
import { TemplateVersion, Template } from "./types/template";

export async function fetchReleaseItems(resources: ReleaseStateDeploymentResource[]): Promise<any[]> {
  const promises = resources.map((r) =>
    request({ url: r.link })
      .then((r) => r.data)
      .catch((e) => {
        if (e.response?.status === 404) {
          const { name } = ngParseLink(r.link, { useInputCtx: true });
          return { id: r.id, name: name, link: r.link, kind: r.kind, version: r.version, notFound: true };
        }
        throw e;
      }),
  );
  const promiseResults = await Promise.all(promises);
  return promiseResults;
}

export function getVersions(template: Template) {
  if (!template) {
    return [];
  }
  return Object.keys(template.versions);
}

export function getTemplateVersion(template: Template, version: string): TemplateVersion | null {
  if (!template) {
    return null;
  }
  const versions = getVersions(template);
  if (!versions.includes(version)) {
    return null;
  }
  return template.versions[version];
}
