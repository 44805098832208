import { flow, getRoot, Instance, types } from "mobx-state-tree";
import { KindSelectModel } from "../../mobxDataModels/kindSelectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { BrowserServiceMobx, BrowserDataMobx } from "../kinds/browser";
import { GCPBindingModel } from "../kinds/identity";
import { IdentityDraftMobx } from "./identity.draft";
import { GCPIdentityDataSnapshot, GCPIdentityDataSnapshotBinding } from "./identity.gcp.data";
import { notification } from "antd";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const IdentityDraftGCPIdentityModel = types
  .model("Identity Draft GCP Link", {
    isActive: false,
    useManualInput: false,
    cloudaccountName: types.optional(types.string, ""),
    method: types.maybe(types.enumeration(["existing", "new"])),
    serviceaccountInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Service Account",
        isRequired: false,
        validationKey: "gcpServiceAccount",
      })
    ),
    bindings: types.array(GCPBindingModel),
    filterInput: types.optional(StringModel, () => StringModel.create({ label: "Search", transformKey: "lowerCase" })),
  })
  .views((self) => ({
    get cloudaccountLink() {
      const { absolute } = ngParseLink(self.cloudaccountName, { kind: "cloudaccount" });
      return absolute;
    },
  }))
  .actions((self) => ({
    clear() {
      self.isActive = false;
      self.useManualInput = false;
      self.cloudaccountName = "";
      self.method = undefined;
      self.serviceaccountInput.reset();
      self.bindings.clear();
      self.bindings.push(GCPBindingModel.create());
      self.filterInput.reset();
    },
  }))
  .views((self) => ({
    get browserService(): BrowserServiceMobx {
      const root = getRoot(self) as IdentityDraftMobx;
      return root.browserService;
    },
    get canAddBinding() {
      let res = true;
      for (let binding of self.bindings) {
        if (!binding.forEdit.isValid) res = false;
      }
      return res;
    },
  }))
  .actions((self) => ({
    setBrowserCloudaccount() {
      const browser = self.browserService;
      browser.setCloudaccountLink(self.cloudaccountLink);
    },
  }))
  .actions((self) => {
    const fetchServiceaccounts: () => Promise<void> = flow(function* () {
      try {
        const browser = self.browserService;
        browser.setCloudaccountLink(self.cloudaccountLink);

        // Get services
        yield browser.fetchServices();

        // Select iam service
        const iamServiceHref = browser.servicesSection.items.find((i) => i.href.endsWith("/iam"))!.href;
        browser.servicesSection.setSelectedHref(iamServiceHref);

        // Get regions
        yield browser.fetchRegions();

        // Select global region
        const globalRegionHref = browser.regionsSection.items.find((i) => i.href.endsWith("/global"))!.href;
        browser.regionsSection.setSelectedHref(globalRegionHref);

        // Get type
        yield browser.fetchTypes();

        // Select serviceaccount type
        const serviceaccountTypeHref = browser.typesSection.items.find((i) => i.href.endsWith("/serviceaccount"))!.href;
        browser.typesSection.setSelectedHref(serviceaccountTypeHref);

        yield browser.fetchDataItems();

        self.browserService.setCanReach(true);
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) {
          errorMessage = e.message;
        }
        notification.warning({ message: "Browser Service Failed", description: errorMessage });
        self.browserService.setCanReach(false);
      }
    });
    const fetchServices: () => Promise<void> = flow(function* () {
      try {
        const browser = self.browserService;
        browser.setCloudaccountLink(self.cloudaccountLink);
        yield browser.fetchServices();
        self.browserService.setCanReach(true);
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) {
          errorMessage = e.message;
        }
        notification.warning({ message: "Browser Service Failed", description: errorMessage });
        self.browserService.setCanReach(false);
      }
    });
    return { fetchServiceaccounts, fetchServices };
  })
  .actions((self) => ({
    setUseManualInput(value: boolean) {
      self.useManualInput = value;
      self.serviceaccountInput.setIsRequired(value);
    },
    setCloudaccountName(name: string) {
      const oldName = self.cloudaccountName;
      if (name !== oldName) {
        self.serviceaccountInput.setInitialValue("");
        self.bindings.clear();
        self.bindings.push(GCPBindingModel.create());
      }
      self.cloudaccountName = name;
    },
    selectServiceAccount(value: string) {
      value = value.split("/serviceAccounts/")[1];
      self.serviceaccountInput.setValue(value);
    },
    addBinding() {
      if (!self.canAddBinding) return;
      self.bindings.unshift(GCPBindingModel.create());
    },
    removeBinding(index: number) {
      if (self.bindings.length - 1 < index) return;
      const item = self.bindings[index];
      self.bindings.remove(item);
    },
    setMethod(value: "existing" | "new") {
      self.method = value;
    },
  }))
  .views((self) => ({
    get isValidPage_General() {
      let res = true;
      if (!self.cloudaccountName) res = false;
      if (!self.method) res = false;
      return res;
    },
    get isValidPage_Configuration() {
      if (!self.cloudaccountName) {
        return false;
      }
      let res = true;
      if (self.method === "existing") {
        // Forces to click confirm when using manual input
        if (self.useManualInput) return false;
        res = self.serviceaccountInput.value.length > 0 && self.serviceaccountInput.isValid;
      } else if (self.method === "new") {
        if (self.bindings.length < 1) res = false;
        for (let binding of self.bindings) {
          if (!binding.forEdit.isValid) res = false;
        }
      }
      return res;
    },
    get filteredServiceaccounts(): BrowserDataMobx[] {
      if (self.method !== "existing") return [];
      const browser = self.browserService;
      return browser.dataSection.items.filter((d) => d._cpln.label?.toLowerCase().includes(self.filterInput.value));
    },
  }))
  .views((self) => ({
    get isValid() {
      return self.isValidPage_General && self.isValidPage_Configuration;
    },
    get asObject(): GCPIdentityDataSnapshot {
      return {
        cloudAccountLink: self.cloudaccountLink,
        serviceAccount: self.method === "existing" ? self.serviceaccountInput.value : "",
        bindings:
          self.method === "new"
            ? self.bindings.map((binding) => {
                const item: GCPIdentityDataSnapshotBinding = {
                  resource: binding.forEdit.resourceInput.value,
                  roles: [...binding.forEdit.roles],
                };
                if (binding.forEdit.resourceDataItem) {
                  item.resourceDataItem = {
                    href: binding.forEdit.resourceDataItem.href,
                    ref: binding.forEdit.resourceDataItem.ref,
                  };
                }
                return item;
              })
            : [],
      };
    },
  }))
  .actions((self) => {
    const apply = flow(function* (obj: GCPIdentityDataSnapshot) {
      self.isActive = true;
      const { name: cloudaccountName } = ngParseLink(obj.cloudAccountLink, { kind: "cloudaccount" });
      self.cloudaccountName = cloudaccountName;
      if (obj.serviceAccount) {
        self.method = "existing";
        self.serviceaccountInput.setInitialValue(obj.serviceAccount!);
      } else {
        self.method = "new";
        self.bindings.clear();
        for (let binding of obj.bindings) {
          const instance = GCPBindingModel.create();
          instance.apply(binding);
          self.bindings.push(instance);
        }
      }
    });
    function setIsActive(value: boolean) {
      self.isActive = value;
    }
    return { apply, setIsActive };
  });

export interface IdentityGCPDraftMobx extends Instance<typeof IdentityDraftGCPIdentityModel> {}
