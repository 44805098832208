import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel } from "../base";
import { request } from "../../services/cpln";
import { Secret } from "../../schema/types/secret";

const CloudaccountDataModel = types
  .model("CloudaccountDataModel", {
    // aws
    roleArn: types.optional(types.string, ""),
    // gcp
    numericProjectId: types.maybe(types.string),
    projectId: types.optional(types.string, ""),
    // azure and ngs
    secretLink: types.optional(types.string, ""),
    // azure
    subscriptionId: types.maybe(types.string),
  })
  .actions((self) => {
    const fetchSubscriptionId = flow(function* () {
      try {
        const { data: secret } = yield request<Secret>({ url: self.secretLink });
        self.subscriptionId = secret.tags["cpln/azure/subscriptionId"]
          ? String(secret.tags["cpln/azure/subscriptionId"])
          : "";
      } catch (e) {
        self.subscriptionId = undefined;
      }
    });
    return { fetchSubscriptionId };
  });

export interface CloudaccountDataMobx extends Instance<typeof CloudaccountDataModel> {}
export interface CloudaccountData {
  roleArn: string;
  numericProjectId?: string;
  projectId: string;
  secretLink: string;
  subscriptionId?: string;
}

export const CloudaccountModel = types
  .compose(
    "Cloudaccount",
    BaseModel,
    types.model({
      provider: types.optional(types.string, ""), // aws gcp azure ngs,
      data: types.maybe(CloudaccountDataModel),
    })
  )
  .actions((self) => {
    const afterCreate = () => {
      if (self.provider !== "azure") {
        return;
      }
      self.data?.fetchSubscriptionId();
    };
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { afterCreate, fetch };
  })
  .actions((self) => {
    const patch: (body: any) => Promise<void> = flow(function* (body: any) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      delete body.deployments;
      delete body.events;
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface CloudaccountMobx extends Instance<typeof CloudaccountModel> {}
