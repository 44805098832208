import * as React from "react";

interface MediaQueryContextType {
  max_w_1024: boolean;
}

export function useInitMediaQueryContext(): MediaQueryContextType {
  const q = window.matchMedia("(max-width: 1024px)");

  const [max_w_1024, setMaxW1024] = React.useState(false);

  React.useEffect(() => {
    q.addEventListener("change", function () {
      checkQueryMatch();
    });
    checkQueryMatch();
  }, []);

  function checkQueryMatch() {
    setMaxW1024(q.matches);
  }

  return { max_w_1024 };
}

export const MediaQueryContext = React.createContext<MediaQueryContextType>(null as any);

export function useMediaQuerycontext() {
  return React.useContext(MediaQueryContext);
}
