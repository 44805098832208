import { types, Instance, flow } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NameModel } from "../../mobxDataModels/nameModel";
import { IdentityDraftAwsHelperModel } from "./identity.aws.helper";
import { IdentityDraftAzureHelperModel } from "./identity.azure.helper";
import { IdentityDraftGcpHelperModel } from "./identity.gcp.helper";
import { IdentityMobx } from "../kinds/identity";
import { AWSIdentityDataSnapshot } from "./identity.aws.data";
import { AzureIdentityDataSnapshot } from "./identity.azure.data";
import { GCPIdentityDataSnapshot } from "./identity.gcp.data";
import { IdentityDraftNetworkResourcesHelperModel } from "./identity.draft.networkresourcehelper";
import { BrowserServiceModel } from "../kinds/browser";
import { IdentityDraftNativeNetworkResourcesHelperModel } from "./identity.draft.nativenetworkresourcehelper";
import { TagNewModel, TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { IdentityDraftNGSHelperModel } from "./identity.ngs.helper";
import { NGSIdentityDataSnapshot } from "./identity.ngs.data";

export const IdentityDraftModel = types
  .model({
    _name: types.optional(types.string, ""),
    _description: types.optional(types.string, ""),
    _tags: types.array(TagNewModel),
    name: types.optional(StringModel, () => NameModel.create()),
    description: types.optional(StringModel, () =>
      StringModel.create({
        label: "Description",
      })
    ),
    aws: types.optional(IdentityDraftAwsHelperModel, () => IdentityDraftAwsHelperModel.create()),
    azure: types.optional(IdentityDraftAzureHelperModel, () => IdentityDraftAzureHelperModel.create()),
    gcp: types.optional(IdentityDraftGcpHelperModel, () => IdentityDraftGcpHelperModel.create()),
    ngs: types.optional(IdentityDraftNGSHelperModel, () => IdentityDraftNGSHelperModel.create()),
    tags: types.optional(TagsNewModel, () => TagsNewModel.create()),
    networkResources: types.optional(IdentityDraftNetworkResourcesHelperModel, () =>
      IdentityDraftNetworkResourcesHelperModel.create()
    ),
    nativeNetworkResources: types.optional(IdentityDraftNativeNetworkResourcesHelperModel, () =>
      IdentityDraftNativeNetworkResourcesHelperModel.create()
    ),
    browserService: types.optional(BrowserServiceModel, () => BrowserServiceModel.create()),
  })
  .actions((self) => {
    const reset = flow(function* () {
      try {
        self.name.setInitialValue(self._name);
        self.description.setInitialValue(self._description);
        self.tags.setInitialTags(self._tags);
        yield self.aws.reset();
        yield self.azure.reset();
        yield self.gcp.reset();
        yield self.ngs.reset();
        self.networkResources.reset();
        self.nativeNetworkResources.reset();
      } catch (e) {
        console.error("fail at identity.draft.reset", e.message);
      }
    });
    return { reset };
  })
  .actions((self) => ({
    confirm() {
      self._name = self.name.value;
      self.name.confirm();
      self._description = self.description.value;
      self.description.confirm();
      if (!self.description.value) {
        self.description.setInitialValue(self._name);
      }
      if (self.aws.edited.isActive) {
        self.aws.saved.apply(self.aws.edited.asObject);
      } else {
        self.aws.saved.clear();
      }
      if (self.azure.edited.isActive) {
        self.azure.saved.apply(self.azure.edited.asObject);
      } else {
        self.azure.saved.clear();
      }
      if (self.gcp.edited.isActive) {
        self.gcp.saved.apply(self.gcp.edited.asObject);
      } else {
        self.gcp.saved.clear();
      }
      if (self.ngs.edited.isActive) {
        self.ngs.saved.apply(self.ngs.edited.asObject);
      } else {
        self.ngs.saved.clear();
      }
      self.networkResources.confirm();
      self.nativeNetworkResources.confirm();
    },
  }))
  .actions((self) => {
    const setAsNew = flow(function* () {
      self._name = "";
      self._description = "";
      self._tags.clear();
      self.aws.saved.clear();
      self.aws.edited.clear();
      self.aws.draft.clear();
      self.azure.saved.clear();
      self.azure.edited.clear();
      self.azure.draft.clear();
      self.gcp.saved.clear();
      self.gcp.edited.clear();
      self.gcp.draft.clear();
      self.ngs.saved.clear();
      self.ngs.edited.clear();
      self.ngs.draft.clear();
      self.networkResources.clear();
      self.nativeNetworkResources.clear();
      yield self.reset();
    });
    const setAsDraft = flow(function* (identity: IdentityMobx) {
      self._name = identity.name;
      self._description = identity.description;
      self._tags.clear();
      for (let index in identity.tagsArray) {
        const t = identity.tagsArray[index];
        self._tags.push({ index: Number(index), key: t[0], value: t[1], status: "default" });
      }
      if (identity.aws) {
        const data: AWSIdentityDataSnapshot = {
          cloudAccountLink: identity.aws.cloudAccountLink,
          roleName: identity.aws.roleName || "",
          policyRefs: [...identity.aws.policyRefs],
        };
        self.aws.saved.apply(data);
        self.aws.edited.apply(data);
        yield self.aws.draft.apply(data);
      } else {
        self.aws.saved.clear();
        self.aws.edited.clear();
        self.aws.draft.clear();
      }
      if (identity.azure) {
        const data: AzureIdentityDataSnapshot = {
          cloudAccountLink: identity.azure.cloudAccountLink,
          roleAssignments: identity.azure.roleAssignments.map((roleAssignment) => ({
            scope: roleAssignment.scope,
            roles: [...roleAssignment.roles],
          })),
        };
        self.azure.saved.apply(data);
        self.azure.edited.apply(data);
        yield self.azure.draft.apply(data);
      } else {
        self.azure.saved.clear();
        self.azure.edited.clear();
        self.azure.draft.clear();
      }
      if (identity.gcp) {
        const data: GCPIdentityDataSnapshot = {
          cloudAccountLink: identity.gcp.cloudAccountLink,
          serviceAccount: identity.gcp.serviceAccount || "",
          bindings: identity.gcp.bindings.map((binding) => ({
            resource: binding.resource,
            roles: [...binding.roles],
          })),
        };
        self.gcp.saved.apply(data);
        self.gcp.edited.apply(data);
        yield self.gcp.draft.apply(data);
      } else {
        self.gcp.saved.clear();
        self.gcp.edited.clear();
        self.gcp.draft.clear();
      }
      if (identity.ngs) {
        const data: NGSIdentityDataSnapshot = {
          cloudAccountLink: identity.ngs.cloudAccountLink,
        };
        if (
          identity.ngs.pub !== undefined &&
          identity.ngs.pub.allow !== undefined &&
          identity.ngs.pub.allow.length > 0
        ) {
          if (!data.pub) {
            data.pub = {};
          }
          data.pub.allow = identity.ngs.pub.allow;
        }
        if (identity.ngs.pub !== undefined && identity.ngs.pub.deny !== undefined && identity.ngs.pub.deny.length > 0) {
          if (!data.pub) {
            data.pub = {};
          }
          data.pub.deny = identity.ngs.pub.deny;
        }
        if (
          identity.ngs.sub !== undefined &&
          identity.ngs.sub.allow !== undefined &&
          identity.ngs.sub.allow.length > 0
        ) {
          if (!data.sub) {
            data.sub = {};
          }
          data.sub.allow = identity.ngs.sub.allow;
        }
        if (identity.ngs.sub !== undefined && identity.ngs.sub.deny !== undefined && identity.ngs.sub.deny.length > 0) {
          if (!data.sub) {
            data.sub = {};
          }
          data.sub.deny = identity.ngs.sub.deny;
        }
        if (identity.ngs.resp?.max !== undefined) {
          if (!data.resp) {
            data.resp = {};
          }
          data.resp.max = identity.ngs.resp.max;
        }
        if (identity.ngs.resp?.ttl !== undefined) {
          if (!data.resp) {
            data.resp = {};
          }
          data.resp.ttl = identity.ngs.resp.ttl;
        }
        if (identity.ngs.subs !== undefined) {
          data.subs = identity.ngs.subs;
        }
        if (identity.ngs.data !== undefined) {
          data.data = identity.ngs.data;
        }
        if (identity.ngs.payload !== undefined) {
          data.payload = identity.ngs.payload;
        }
        self.ngs.saved.apply(data);
        self.ngs.edited.apply(data);
        yield self.ngs.draft.apply(data);
      } else {
        self.ngs.saved.clear();
        self.ngs.edited.clear();
        self.ngs.draft.clear();
      }
      self.networkResources.apply(identity.networkResources);
      self.nativeNetworkResources.apply(identity.nativeNetworkResources);
      yield self.reset();
    });
    return { setAsDraft, setAsNew };
  })
  .views((self) => ({
    get networkResourceNames() {
      let names: string[] = [];
      for (const resource of self.networkResources.resources) {
        names.push(resource.resourceName);
      }
      return names;
    },
    get networkResourceFqdns() {
      let fqdns: string[] = [];
      for (const resource of self.networkResources.resources) {
        if (resource.discover === "fqdn") {
          fqdns.push(resource.resourceName);
        }
      }
      return fqdns;
    },
    get nativeNetworkResourceNames() {
      let names: string[] = [];
      for (const resource of self.nativeNetworkResources.resources) {
        names.push(resource.resourceName);
      }
      return names;
    },
    get nativeNetworkResourceFqdns() {
      let fqdns: string[] = [];
      for (const resource of self.nativeNetworkResources.resources) {
        fqdns.push(resource.resourceName);
      }
      return fqdns;
    },
    get isDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.description.isDirty) res = true;
      if (self.tags.isDirty) res = true;
      if (self.aws.isDirty) res = true;
      if (self.azure.isDirty) res = true;
      if (self.gcp.isDirty) res = true;
      if (self.ngs.isDirty) res = true;
      if (self.networkResources.isDirty) res = true;
      if (self.nativeNetworkResources.isDirty) res = true;
      return res;
    },
    get isValidForTags() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.networkResources.isValid) res = false;
      if (!self.nativeNetworkResources.isValid) res = false;
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.networkResources.isValid) res = false;
      if (!self.nativeNetworkResources.isValid) res = false;
      if (!self.tags.isValid) res = false;
      return res;
    },
    get asObject() {
      const body: any = {
        name: self.name.value,
        description: self.description.value || self.name.value,
        tags: self.tags.asObject,
      };
      if (self.aws.edited.isActive) {
        body.aws = self.aws.edited.asObjectForHTTP;
      }
      if (self.azure.edited.isActive) {
        body.azure = self.azure.edited.asObjectForHTTP;
      }
      if (self.gcp.edited.isActive) {
        body.gcp = self.gcp.edited.asObjectForHTTP;
      }
      if (self.ngs.edited.isActive) {
        body.ngs = self.ngs.edited.asObject;
      }
      if (self.networkResources.resources.length > 0) {
        body.networkResources = self.networkResources.asObject;
      }
      if (self.nativeNetworkResources.resources.length > 0) {
        body.nativeNetworkResources = self.nativeNetworkResources.asObject;
      }
      return body;
    },
    get asPatch() {
      const toDrop: string[] = [];
      const body: any = {};
      if (self.description.isDirty) {
        body.description = self.description.value || null;
      }
      if (self.aws.isDirty) {
        if (self.aws.edited.isActive) {
          body["$replace/aws"] = self.aws.edited.asObjectForHTTP;
        } else {
          toDrop.push("aws");
        }
      }
      if (self.azure.isDirty) {
        if (self.azure.edited.isActive) {
          body["$replace/azure"] = self.azure.edited.asObjectForHTTP;
        } else {
          toDrop.push("azure");
        }
      }
      if (self.gcp.isDirty) {
        if (self.gcp.edited.isActive) {
          body["$replace/gcp"] = self.gcp.edited.asObjectForHTTP;
        } else {
          toDrop.push("gcp");
        }
      }
      if (self.ngs.isDirty) {
        if (self.ngs.edited.isActive) {
          body["$replace/ngs"] = self.ngs.edited.asObject;
        } else {
          toDrop.push("ngs");
        }
      }
      if (self.networkResources.isDirty) {
        body.networkResources = self.networkResources.asPatch;
      }
      if (self.nativeNetworkResources.isDirty) {
        body.nativeNetworkResources = self.nativeNetworkResources.asPatch;
      }
      if (toDrop.length > 0) {
        body["$drop"] = toDrop;
      }
      return body;
    },
  }));
export interface IdentityDraftMobx extends Instance<typeof IdentityDraftModel> {}
