import { observer } from "mobx-react-lite";
import * as React from "react";
import { Tooltip } from "../../components/Tooltip";

interface Props {
  taint: { key: string; value: string; effect: string };
}
const NodePoolTaintRaw: React.FC<Props> = ({ taint: { key, value, effect } }) => {
  return (
    <Tooltip title={`${key}=${value}:${effect}`}>
      <div className="truncate mr-2">
        {key}={value}:{effect}
      </div>
    </Tooltip>
  );
};

export const NodePoolTaint = observer(NodePoolTaintRaw);
