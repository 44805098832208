import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from "antd";
import { Theme } from "../../mobxStores/uiData/theme";
import { RenderFunction } from "antd/es/_util/getRenderPropValue";
import { falseOnly } from "../../services/cpln";

const TooltipRaw: React.FC<AntTooltipProps> = (props) => {
  const title: React.ReactNode | RenderFunction = Array.isArray(props.title) ? (
    <div className="flex flex-col gap-y-1 mx-2 my-1">
      {props.title.map((value, index) => (
        <span key={index}>{value}</span>
      ))}
    </div>
  ) : (
    props.title
  );

  return (
    <AntTooltip
      /* Default */
      color={Theme.theme == "light" ? "white" : "#1e1e1e"}
      overlayStyle={{ maxWidth: 550 }}
      overlayInnerStyle={{
        color: Theme.theme == "light" ? "#2a2d31" : "white",
        fontFamily: "proxima-nova, sans-serif",
      }}
      /* Specified Preferences */
      {...props}
      /* Override Specified Preferences */
      title={title}
      open={falseOnly(props.open)}
    >
      {props.children}
    </AntTooltip>
  );
};

export const Tooltip = observer(TooltipRaw);
