import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbsContext, useInitBreadcrumbs } from "./reactContexts/breadcrumbContext";
import { TaskChecker } from "./mobxStores/task/taskChecker";
import { notification } from "antd";
import { UIData } from "./mobxStores/uiData/uiData";
import RouteLeavingGuard from "./components/generic/routeLeavingGuard";
import { ErrorBoundary } from "./errorBoundary";
import { ConsoleContext } from "./mobxStores/consoleContext/consoleContext";
import { observer } from "mobx-react-lite";
import { getAnalytics, logEvent } from "firebase/analytics";
import { User } from "./mobxStores/user/user";
import { hotjarPageChange } from "./tools/hotjar";
import { DomainContext } from "./mobxStores/kinds/domainContext";
import { ALL_DOMAINS_UPDATE_INTERVAL_MS } from "./envVariables";
import { request } from "./services/cpln";
import { Loader } from "./components/layout/loader";
import { OptimalViewingModal } from "./optimalViewingModal";

const GlobalElementRaw: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    UIData.updateLastActivityTimestamp();
    if (User.isAdmin) {
      return;
    }
    hotjarPageChange(pathname);
  }, [pathname]);

  React.useEffect(() => {
    if (!User.firebaseInitialized) {
      return;
    }
    if (User.isAdmin) {
      return;
    }
    logEvent(getAnalytics(User.getFirebaseApp()), "page_view");
  }, [pathname, User.firebaseInitialized]);

  React.useEffect(() => {
    if (ConsoleContext.redirect) {
      setTimeout(() => {
        navigate("/redirect");
      }, 0);
    }
  }, [ConsoleContext.redirect]);

  React.useEffect(() => {
    if (!ConsoleContext.org) {
      ConsoleContext.setLinkedAccount(null);
      return;
    }
    fetchLinkedAccount();
  }, [ConsoleContext.org]);

  async function fetchLinkedAccount() {
    try {
      const { data } = await request({ service: "billing-ng", url: `/org/${ConsoleContext.org}/account` });
      ConsoleContext.setLinkedAccount(data.id);
    } catch (e) {
      ConsoleContext.setLinkedAccount(null);
    }
  }

  React.useEffect(() => {
    if (!TaskChecker.newInviteCounter) {
      return;
    }

    notification.info({
      message: "New Task",
      description: (
        <div>
          <span style={{ color: "var(--color-link)", cursor: "pointer" }} onClick={() => navigate("/task")}>
            Click
          </span>{" "}
          to see tasks
        </div>
      ),
    });
  }, [TaskChecker.newInviteCounter]);

  React.useEffect(() => {
    const id = setTimeout(() => {
      TaskChecker.checkTasks();
    }, TaskChecker.intervalMs);
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [TaskChecker.requestCounter]);

  // Fetch all domain items for current domain endpoints, at the initialization of the app
  React.useEffect(() => {
    if (!ConsoleContext.hasOrg) {
      return;
    }
    DomainContext.requestItems();
  }, [ConsoleContext.hasOrg]);

  // Fetch all domain items for current domain endpoints, reschedule it after each request is completed
  React.useEffect(() => {
    if (!ConsoleContext.hasOrg) {
      return;
    }
    const id = setTimeout(() => {
      DomainContext.requestItems();
    }, ALL_DOMAINS_UPDATE_INTERVAL_MS);
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [ConsoleContext.hasOrg, DomainContext.requestCounter]);

  const breadcrumbsHook = useInitBreadcrumbs();

  return (
    <BreadcrumbsContext.Provider value={breadcrumbsHook}>
      <ErrorBoundary>
        <RouteLeavingGuard />
        <Outlet />
        <OptimalViewingModal />
        {ConsoleContext.isChangingContext ? (
          <div
            style={{
              position: "absolute",
              inset: 0,
              zIndex: 10_000,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
            }}
          >
            <Loader reason={"Changing context"} />
            <div
              style={{ top: "15%", color: "white" }}
              className="absolute inset-0 flex flex-col gap-2 items-center justify-center"
            >
              <span>Processing</span>
              <span>Please wait...</span>
            </div>
          </div>
        ) : null}
      </ErrorBoundary>
    </BreadcrumbsContext.Provider>
  );
};

export const GlobalElement = observer(GlobalElementRaw);
