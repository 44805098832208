import * as React from "react";
import { isAuditDiffable } from "../../services/utils";
import { Audit } from "../../pages/auditTrail/types";
import { getAuditSubject } from "../../pages/auditTrail/utils";
import { kindMetadata } from "../../services/kindMetadata";
import { Link } from "react-router-dom";
import { ArrowUpRight } from "react-feather";
import {
  CellMeasurer as _CellMeasurer,
  CellMeasurerCache,
  Grid as _Grid,
  GridProps,
  CellMeasurerProps,
  ScrollParams,
} from "react-virtualized";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { Timezone } from "../../mobxStores/userData/timezone";
import { DateString } from "../dateString";
import { formatStrings } from "../../utils/time";

const Grid = (_Grid as unknown) as React.FC<GridProps>;
const CellMeasurer = (_CellMeasurer as unknown) as React.FC<CellMeasurerProps>;

interface Props {
  cache: CellMeasurerCache;
  audits: Audit[];
  width: number;
  height: number;
  onScroll: (params: ScrollParams) => void;
  onDetail: (id: string) => void;
  diffSupport:
    | false
    | {
        selectedAudits: string[];
        setSelectedAudits: React.Dispatch<React.SetStateAction<string[]>>;
      };
}

export const AuditGrid: React.FC<Props> = ({ cache, audits, width, height, onScroll, onDetail, diffSupport }) => {
  const fakeGridRef = React.useRef<typeof Grid>(null as any);

  function toggleAllAudits() {
    if (diffSupport === false) {
      return;
    }
    if (diffSupport.selectedAudits.length > 0) {
      diffSupport.setSelectedAudits([]);
    } else {
      diffSupport.setSelectedAudits(audits.filter((a) => isAuditDiffable(a) && a.id !== "label").map((a) => a.id));
    }
  }

  function toggleSelectedAudit(auditId: string, value: boolean) {
    if (diffSupport === false) {
      return;
    }

    const { selectedAudits, setSelectedAudits } = diffSupport;

    if (value && selectedAudits.includes(auditId)) {
      return;
    }
    if (!value && !selectedAudits.includes(auditId)) {
      return;
    }
    if (value) {
      setSelectedAudits((audits) => [...audits, auditId]);
    } else {
      setSelectedAudits(selectedAudits.filter((id) => id !== auditId));
    }
  }

  const FakeCellRenderer: React.FC<any> = ({ rowIndex, columnIndex, key, parent, style }) => {
    const isFirst = rowIndex === 0;
    const isOdd = rowIndex % 2 === 1;
    return (
      <div key={key} style={style} className={`${isFirst ? "table-labels" : isOdd ? "log-odd-bg" : "log-even-bg"}`}>
        <div style={{ width: width, height: 30 }}></div>
      </div>
    );
  };

  const CellRenderer: React.FC<any> = ({ rowIndex, columnIndex, key, parent, style }) => {
    const diffableAudits = audits.filter((a) => isAuditDiffable(a) && a.id !== "label");
    const hasAnyAuditSupportingDiff = diffableAudits.length > 0;
    const audit = audits[rowIndex];
    const isOdd = rowIndex % 2 === 1;

    // eventTime
    if (columnIndex === 0) {
      if (audit.id === "label") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style} className={`flex items-center px-2 table-labels`}>
              <div className="flex items-center gap-2">
                {diffSupport === false ? null : (
                  <NGCheckbox
                    aria-label={"select all"}
                    indeterminate={
                      diffSupport.selectedAudits.length > 0 &&
                      diffSupport.selectedAudits.length !== diffableAudits.length
                    }
                    checked={
                      diffSupport.selectedAudits.length === diffableAudits.length &&
                      diffSupport.selectedAudits.length > 0
                    }
                    onChange={toggleAllAudits}
                    isDisabled={!hasAnyAuditSupportingDiff}
                  />
                )}
                <div className="whitespace-nowrap">Timestamp ({Timezone.label})</div>
              </div>
            </div>
          </CellMeasurer>
        );
      }

      const preventDiff = !isAuditDiffable(audit);

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style} className={`flex items-center px-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}>
            <div className={`flex items-center gap-2`}>
              {diffSupport === false ? null : (
                <NGCheckbox
                  aria-label={`audit-${audit.id}`}
                  isDisabled={preventDiff}
                  checked={diffSupport.selectedAudits.includes(audit.id)}
                  onChange={(value) => toggleSelectedAudit(audit.id, value)}
                />
              )}
              <div className="whitespace-nowrap">
                <DateString iso={audit.eventTime} format={formatStrings.log} />
              </div>
            </div>
          </div>
        </CellMeasurer>
      );
    }

    // name
    if (columnIndex === 1) {
      if (audit.id === "label") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style} className={`flex items-center px-2 table-labels`}>
              <div className="whitespace-nowrap">Name</div>
            </div>
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style} className={`flex items-center px-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}>
            <div className={`whitespace-nowrap`}>
              {/** */}
              {audit.resource.name}
            </div>
          </div>
        </CellMeasurer>
      );
    }

    // type
    if (columnIndex === 2) {
      if (audit.id === "label") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style} className={`flex items-center px-2 table-labels`}>
              <div className="whitespace-nowrap">Type</div>
            </div>
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style} className={`flex items-center px-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}>
            <div className={`whitespace-nowrap`}>
              {/** */}
              {audit.resource.type}
            </div>
          </div>
        </CellMeasurer>
      );
    }

    // version
    if (columnIndex === 3) {
      if (audit.id === "label") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style} className={`flex items-center px-2 table-labels`}>
              <div className="whitespace-nowrap">Version</div>
            </div>
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style} className={`flex items-center px-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}>
            <div className={`whitespace-nowrap`}>
              {/** */}
              {audit.resource.data?.version}
            </div>
          </div>
        </CellMeasurer>
      );
    }

    // status
    if (columnIndex === 4) {
      if (audit.id === "label") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style} className={`flex items-center px-2 table-labels`}>
              <div className="whitespace-nowrap">Status</div>
            </div>
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style} className={`flex items-center px-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}>
            <div className={`whitespace-nowrap`}>
              {/** */}
              {audit.result?.status}
            </div>
          </div>
        </CellMeasurer>
      );
    }

    // message
    if (columnIndex === 5) {
      if (audit.id === "label") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style} className={`flex items-center px-2 table-labels`}>
              <div className="whitespace-nowrap">Message</div>
            </div>
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style} className={`flex items-center px-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}>
            <div className={`whitespace-nowrap`}>{audit.result?.message}</div>
          </div>
        </CellMeasurer>
      );
    }

    // subject
    if (columnIndex === 6) {
      const subject = getAuditSubject(audit);

      if (audit.id === "label") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style} className={`flex items-center px-2 pr-4 table-labels`}>
              <div className="whitespace-nowrap">Subject</div>
            </div>
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style} className={`flex items-center px-2 ${isOdd ? "log-odd-bg" : "log-even-bg"} pr-4`}>
            <div className={`flex items-center`}>
              {/* Subject name and icon */}
              <span className="flex items-center justify-between pr-2">
                {subject.type === "user" ? (
                  <span className="flex items-center">
                    {audit.subject?.claims?.picture ? (
                      <img
                        src={audit.subject?.claims?.picture}
                        className="rounded-full"
                        style={{ height: 20, width: 20 }}
                      />
                    ) : (
                      <>
                        {
                          <kindMetadata.user.icon
                            style={{
                              width: 18,
                              height: 18,
                              fill: "var(--color-default)",
                              stroke: "var(--color-default)",
                            }}
                          />
                        }
                      </>
                    )}
                    <Link to={`/console${subject.link!}`} className={"ml-1 ngfocus color-link whitespace-nowrap"}>
                      {subject.value}
                    </Link>
                  </span>
                ) : subject.type === "serviceaccount" ? (
                  <span className="flex items-center">
                    <>
                      {
                        <kindMetadata.serviceaccount.icon
                          style={{
                            width: 18,
                            height: 18,
                            fill: "var(--color-default)",
                            stroke: "var(--color-default)",
                          }}
                        />
                      }
                    </>
                    <Link to={`/console${subject.link!}`} className={"ml-1 ngfocus color-link whitespace-nowrap"}>
                      {subject.value}
                    </Link>
                  </span>
                ) : subject.value === "<root>" ? (
                  <span className="flex items-center">
                    <>
                      {
                        <kindMetadata.serviceaccount.icon
                          style={{
                            width: 18,
                            height: 18,
                            fill: "var(--color-default)",
                            stroke: "var(--color-default)",
                          }}
                        />
                      }
                    </>
                    <span className="ml-1">Root</span>
                  </span>
                ) : (
                  <span className="whitespace-nowrap">{subject.value}</span>
                )}
              </span>
              {/* Audit details button */}
              <span className="flex items-center justify-start">
                <button onClick={() => onDetail(audit.id)} className={`ngfocus color-link`}>
                  <ArrowUpRight className="feather-icon" />
                </button>
              </span>
            </div>
          </div>
        </CellMeasurer>
      );
    }

    return null;
  };

  return (
    <div className="relative">
      <div className="absolute inset-0">
        <Grid
          id="auditGridFake"
          ref={fakeGridRef}
          className="border"
          style={{ overflowX: "hidden" }}
          width={width}
          height={height}
          columnCount={1}
          columnWidth={width}
          estimatedColumnSize={width}
          rowCount={audits.length}
          rowHeight={30}
          estimatedRowSize={audits.length * 30}
          cellRenderer={FakeCellRenderer}
        />
      </div>
      <Grid
        id="auditGrid"
        className={"text-sm border rounded"}
        width={width}
        height={height}
        onScroll={(params: ScrollParams) => {
          onScroll(params);
          if (!fakeGridRef.current) {
            return;
          }
          (fakeGridRef.current as any).scrollToPosition({ scrollLeft: 0, scrollTop: params.scrollTop });
        }}
        columnCount={7}
        columnWidth={cache.columnWidth}
        estimatedColumnSize={width * 3}
        rowCount={audits.length}
        rowHeight={30}
        estimatedRowSize={audits.length * 30}
        deferredMeasurementCache={cache}
        cellRenderer={CellRenderer}
      />
    </div>
  );
};
