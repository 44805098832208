import * as React from "react";
import { observer } from "mobx-react-lite";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { Theme } from "../../../mobxStores/uiData/theme";
import { DomainDraftMobx } from "../../../mst/stores/domain.draft";
import { newDomainColors } from "./colors";
import { CreateNewData } from "./create_new_mobx";
import { NGButton } from "../../../newcomponents/button/Button";

interface Props {
  draft: DomainDraftMobx;
  data: CreateNewData;
}
const DomainCreateNewSectionPortLabelRaw: React.FC<Props> = ({ draft, data }) => {
  const { theme } = Theme;
  const isDark = theme === "dark";
  const background = isDark ? newDomainColors.darkBlue : "#ccc";

  const port = draft.ports.find((p) => p.id === data.selectedPortId);
  if (!port) {
    return null;
  }

  async function handleActionsClick({ key }: any) {
    data.setSelectedPortId(key);
  }

  return (
    <div className={`mb-4 -mx-4 px-4 text-lg font-bold uppercase`} style={{ background, height: 48 }}>
      <div className="flex items-center gap-4 w-full h-full">
        <div className="uppercase">
          Port {port.number.value}/{port.protocol.value}
        </div>
        {draft.ports.length > 1 ? (
          <Dropdown
            trigger={["click"]}
            menu={{
              onClick: handleActionsClick,
              className: "menu",
              items: draft.ports
                .filter((p) => p.number.value !== port.number.value)
                .map((p) => ({ key: p.id, label: `Port ${p.number.value}` })),
            }}
          >
            <NGButton
              style={{ paddingInline: 10 }}
              size={"small"}
              variant={"primary"}
              renderIcon={(_, props) => <DownOutlined {...props} />}
            />
          </Dropdown>
        ) : null}
        <NGButton
          variant="primary"
          onClick={() => {
            const id = draft.addPort();
            data.setSelectedPortId(id);
          }}
          size={"small"}
        >
          Add Port
        </NGButton>
        {draft.ports.length < 2 ? null : (
          <NGButton
            variant="danger"
            onClick={() => {
              data.setSelectedPortId("");
              draft.removePort(port.id);
              const firstPort = draft.ports[0];
              if (firstPort) {
                data.setSelectedPortId(firstPort.id);
              }
            }}
            size={"small"}
          >
            Remove
          </NGButton>
        )}
      </div>
    </div>
  );
};

export const DomainCreateNewSectionPortLabel = observer(DomainCreateNewSectionPortLabelRaw);
