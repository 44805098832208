import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel } from "../base";
import { QueryModel, Query } from "../query";
import { request } from "../../services/cpln";
import { KindWithPolicyProperty } from "../base";

export const BindingModel = types
  .model("PolicyBinding", {
    permissions: types.array(types.string),
    principalLinks: types.array(types.string),
  })
  .views((self) => ({
    get asObject() {
      return {
        permissions: [...self.permissions],
        principalLinks: [...self.principalLinks],
      };
    },
    get userLinks() {
      return self.principalLinks.filter((l) => l.includes("/user/"));
    },
    get serviceaccountLinks() {
      return self.principalLinks.filter((l) => l.includes("/serviceaccount/"));
    },
    get groupLinks() {
      return self.principalLinks.filter((l) => l.includes("/group/"));
    },
    get identityLinks() {
      return self.principalLinks.filter((l) => l.includes("/identity/"));
    },
  }))
  .views((self) => ({
    get userCount() {
      return self.userLinks.length;
    },
    get serviceaccountCount() {
      return self.serviceaccountLinks.length;
    },
    get groupCount() {
      return self.groupLinks.length;
    },
    get identityCount() {
      return self.identityLinks.length;
    },
    get hasUsers() {
      return self.userLinks.length > 0;
    },
    get hasServiceaccounts() {
      return self.serviceaccountLinks.length > 0;
    },
    get hasGroups() {
      return self.groupLinks.length > 0;
    },
    get hasIdentities() {
      return self.identityLinks.length > 0;
    },
    get serviceaccountNames() {
      return self.serviceaccountLinks.map((link) => link.split("/")[4]).join(", ");
    },
    get groupNames() {
      return self.groupLinks.map((link) => link.split("/")[4]).join(", ");
    },
    get identityNames() {
      return self.identityLinks.map((link) => link.split("/")[6]).join(", ");
    },
  }));
export interface BindingMobx extends Instance<typeof BindingModel> {}

export const PolicyModel = types
  .compose(
    "Policy",
    BaseModel,
    types.model({
      targetKind: KindWithPolicyProperty,
      targetLinks: types.array(types.string),
      targetQuery: types.maybe(types.late(() => QueryModel)),
      target: types.maybe(types.string), // all
      origin: types.optional(types.string, "default"), // "default", "builtin"
      bindings: types.array(BindingModel),
    })
  )
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  })
  .views((self) => ({
    get allPrincipalLinks() {
      const links: string[] = [];
      for (let binding of self.bindings) {
        for (let link of binding.principalLinks) {
          if (!links.includes(link)) {
            links.push(link);
          }
        }
      }
      return links;
    },
  }));

export interface PolicyMobx extends Instance<typeof PolicyModel> {}
