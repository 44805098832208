import { observer } from "mobx-react-lite";
import * as React from "react";
import { getLogsCoralogixData } from "./data/coralogix";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  coralogix: ReturnType<typeof getLogsCoralogixData>;
  onRemove?: any;
}
const CoralogixRaw: React.FC<Props> = ({ coralogix, onRemove }) => {
  return (
    <>
      <div style={{ width: 450 }}>
        <NGLabel>{coralogix.cluster.label}</NGLabel>
        <NGSelect
          onChange={coralogix.cluster.setValue}
          options={coralogix.cluster.options}
          value={coralogix.cluster.value}
          className="mb-4"
          style={{ width: 450 }}
        />
        <NGLabel>Credentials (Opaque Secret)</NGLabel>
        <NGKindSelect
          className="mb-4"
          kind={"secret"}
          queries={[{ property: "type", value: "opaque" }]}
          value={coralogix.credentials.value}
          onChange={(value) => coralogix.credentials.setValue(value)}
        />
        <NGAlert
          className="mb-4"
          render={() => (
            <div className="flex flex-col gap-1">
              <div>Supported variables for App and Subsystem are:</div>
              <div>
                {"{"}org{"}"}, {"{"}gvc{"}"}, {"{"}workload{"}"}, {"{"}location{"}"}
              </div>
            </div>
          )}
        />
        <NGInputAdapter data={coralogix.app} style={{ width: 450 }} className="mb-4" />
        <NGInputAdapter data={coralogix.subsystem} style={{ width: 450 }} className="mb-4" />
        {onRemove ? (
          <div className="flex justify-end">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const Coralogix = observer(CoralogixRaw);
