import { types, Instance } from "mobx-state-tree";
import { KindProperty } from "../mst/base";
import { GenericItemMobx, GenericItemModel } from "../mst/item";
import { v4 as uuidv4 } from "uuid";

export const KindSelectModel = types
  .model({
    initialValue: types.optional(types.string, ""),
    value: types.optional(types.string, ""),
    label: types.string,
    kind: KindProperty,
    isRequired: types.boolean,
    valueType: types.optional(types.enumeration(["link", "name"]), "link"),
    resetCounter: types.optional(types.number, 1),
    itemMap: types.map(types.late(() => GenericItemModel)),
    fetchTrigger: types.optional(types.string, () => uuidv4()),
  })
  .actions((self) => ({
    setFetchTrigger() {
      self.fetchTrigger = uuidv4();
    },
  }))
  .views((self) => ({
    get isDirty() {
      return self.initialValue !== self.value;
    },
    get items(): GenericItemMobx[] {
      return Array.from(self.itemMap.values());
    },
  }))
  .views((self) => ({
    get isDirtyReason() {
      if (!self.isDirty) {
        return self.label + "Not dirty";
      }
      return self.label + `: InitialValue -> ${self.initialValue} | Value -> ${self.value}`;
    },
  }))
  .actions((self) => ({
    increaseResetCounter() {
      self.resetCounter += 1;
    },
    reset() {
      self.value = self.initialValue;
      self.resetCounter += 1;
    },
    confirm() {
      self.initialValue = self.value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setValue(value: string) {
      self.value = value;
    },
    setInitialValue(value: string) {
      self.initialValue = value;
      self.reset();
    },
  }))
  .actions((self) => ({
    addItems(items: GenericItemMobx[]) {
      for (let item of items) {
        const currentItem = self.itemMap.get(item.id);
        if (currentItem) {
          self.itemMap.delete(currentItem.id);
        }
        self.itemMap.put(item);
      }
    },
  }));

export interface KindSelectMobx extends Instance<typeof KindSelectModel> {}
