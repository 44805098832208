import moment from "moment-timezone";

export function getOffsetMinuteToHourMap() {
  return {
    "-720": "-12:00",
    "-660": "-11:00",
    "-600": "-10:00",
    "-570": "-09:30",
    "-540": "-09:00",
    "-480": "-08:00",
    "-420": "-07:00",
    "-360": "-06:00",
    "-300": "-05:00",
    "-240": "-04:00",
    "-210": "-03:30",
    "-180": "-03:00",
    "-120": "-02:00",
    "-60": "-01:00",
    "0": "+00:00",
    "60": "+01:00",
    "120": "+02:00",
    "180": "+03:00",
    "210": "+03:30",
    "240": "+04:00",
    "270": "+04:30",
    "300": "+05:00",
    "330": "+05:30",
    "345": "+05:45",
    "360": "+06:00",
    "390": "+06:30",
    "420": "+07:00",
    "480": "+08:00",
    "525": "+08:45",
    "540": "+09:00",
    "570": "+09:30",
    "600": "+10:00",
    "630": "+10:30",
    "660": "+11:00",
    "720": "+12:00",
    "765": "+12:45",
    "780": "+13:00",
    "840": "+14:00",
  };
}

// TODO check how can we handle daylight savings? Create a test that passes manually and fails automatically.
export function getPrioritizedOffsetHourToTimezoneMap() {
  return {
    "-12:00": "Etc/GMT+12",
    "-11:00": "Pacific/Samoa",
    "-10:00": "Pacific/Honolulu",
    "-09:30": "Pacific/Marquesas",
    "-09:00": "Pacific/Gambier",
    "-08:00": "US/Alaska",
    "-07:00": "America/Phoenix",
    "-06:00": "America/Denver",
    "-05:00": "America/Chicago",
    "-04:00": "America/New_York",
    "-03:30": "",
    "-03:00": "America/Sao_Paulo",
    "-02:00": "Atlantic/South_Georgia",
    "-01:00": "Atlantic/Cape_Verde",
    "+00:00": "Etc/UTC",
    "+01:00": "Europe/London",
    "+02:00": "Europe/Amsterdam",
    "+03:00": "Europe/Istanbul",
    "+03:30": "Asia/Tehran",
    "+04:00": "Asia/Baku",
    "+04:30": "Asia/Kabul",
    "+05:00": "Asia/Samarkand",
    "+05:30": "Asia/Kolkata",
    "+05:45": "Asia/Katmandu",
    "+06:00": "Asia/Bishkek",
    "+06:30": "Indian/Cocos",
    "+07:00": "Asia/Bangkok",
    "+08:00": "Asia/Singapore",
    "+08:45": "Australia/Eucla",
    "+09:00": "Asia/Tokyo",
    "+09:30": "Australia/Adelaide",
    "+10:00": "Australia/Sydney",
    "+10:30": "Australia/LHI",
    "+11:00": "Etc/GMT-11",
    "+12:00": "Pacific/Auckland",
    "+12:45": "Pacific/Chatham",
    "+13:00": "Pacific/Kanton",
    "+14:00": "Pacific/Kiritimati",
  };
}

export function getPrioritizedTimezones() {
  return [
    // Etc
    // +00:00
    "Etc/UTC",
    // +11:00
    "Etc/GMT-11",
    // -12:00
    "Etc/GMT+12",
    // -03:30
    "",

    // US
    // -07:00
    "America/Phoenix",
    // -06:00
    "America/Denver",
    "America/Monterrey",
    // -05:00
    "America/Chicago",
    // -04:00
    "America/New_York",
    // -03:00
    "America/Sao_Paulo",
    // -02:00
    "Atlantic/South_Georgia",
    // -01:00
    "Atlantic/Cape_Verde",

    // EU
    // +01:00
    "Europe/London",
    "Europe/Lisbon",
    "Europe/Dublin",
    "Europe/Belfast",
    // +02:00
    "Europe/Amsterdam",
    "Europe/Berlin",
    "Europe/Copenhagen",
    "Europe/Madrid",
    "Europe/Rome",
    "Europe/Stockholm",
    "Europe/Vienna",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zurich",
    // +03:00
    "Europe/Athens",
    "Europe/Helsinki",
    "Europe/Istanbul",
    "Europe/Sofia",
    "Europe/Tallinn",
    // +04:00
    "Europe/Samara",

    // Asia
    // +03:00
    "Asia/Tel_Aviv",
    "Asia/Beirut",
    "Asia/Istanbul",
    "Asia/Jerusalem",
    // +03:30
    "Asia/Tehran",
    // +04:00
    "Asia/Baku",
    "Asia/Dubai",
    // +04:30
    "Asia/Kabul",
    // +05:00
    "Asia/Ashgabat",
    "Asia/Samarkand",
    "Asia/Tashkent",
    // +05:30
    "Asia/Kolkata",
    // +05:45
    "Asia/Katmandu",
    // +06:00
    "Asia/Bishkek",
    // +06:30
    "Indian/Cocos",
    // +07:00
    "Asia/Bangkok",
    "Asia/Ho_Chi_Minh",
    "Asia/Jakarta",
    "Asia/Phnom_Penh",
    // +08:00
    "Asia/Brunei",
    "Asia/Hong_Kong",
    "Asia/Kuala_Lumpur",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Taipei",
    // +09:00
    "Asia/Seoul",
    "Asia/Tokyo",
    // +10:00
    "Asia/Vladivostok",

    // AUS/Pacific
    // +08:00
    "Australia/Perth",
    // +08:45
    "Australia/Eucla",
    // +09:30
    "Australia/Adelaide",
    // +10:00
    "Australia/Sydney",
    "Australia/Melbourne",
    "Australia/Brisbane",
    "Australia/Canberra",
    "Australia/Victoria",
    // +10:30
    "Australia/LHI",
    // +12:00
    "Pacific/Auckland",
    // +12:45
    "Pacific/Chatham",
    // +13:00
    "Pacific/Kanton",
    // +14:00
    "Pacific/Kiritimati",
    // -11:00
    "Pacific/Samoa",
    // -10:00
    "Pacific/Honolulu",
    // -09:30
    "Pacific/Marquesas",
    // -09:00
    "Pacific/Gambier",
    // -08:00
    "US/Alaska",

    // Africa
    // +00:00
    "Africa/Dakar",
    // +01:00
    "Africa/Casablanca",
    // +02:00
    "Africa/Cairo",
    // +03:00
    "Africa/Addis_Ababa",
    "Africa/Nairobi",
    "Africa/Mogadishu",
  ];
}

export function getBrowserOffsetMinute() {
  return new Date().getTimezoneOffset();
}

export function offsetHourToTimezones(offsetHour: string, defaultTimezone: string = "Etc/UTC") {
  const result = moment.tz.names().filter((name) => {
    return offsetHour === moment.tz(name).format("Z");
  });
  if (!result) {
    // TODO inform sentry
  }
  return result || [defaultTimezone];
}

export function offsetHourToTimezone(offsetHour: string, defaultTimezone: string = "Etc/UTC") {
  return offsetHourToTimezones(offsetHour, defaultTimezone)[0];
}

export function offsetMinuteToTimezones(offsetMinute: number | string, defaultTimezone: string = "Etc/UTC") {
  const offsetHour = getOffsetMinuteToHourMap()[String(offsetMinute)];
  return offsetHourToTimezones(offsetHour, defaultTimezone);
}

export function offsetMinuteToTimezone(offsetMinute: number | string, defaultTimezone: string = "Etc/UTC") {
  return offsetMinuteToTimezones(offsetMinute, defaultTimezone)[0];
}

export const formatStrings = {
  humanize: "humanize",
  humanizeWAgo: "humanizeWAgo",
  ms: "D MMM YYYY HH:mm:ss.SSS",
  sec: "D MMM YYYY HH:mm:ss",
  min: "D MMM YYYY HH:mm",
  date: "D MMM YYYY",
  dateFullMonth: "D MMMM YYYY",
  log: "YYYY-MM-DD HH:mm:ss.SSS",
};
