import { observer } from "mobx-react-lite";
import * as React from "react";
import { QueryUI } from "../../components/query/queryUI";
import { ItemStoreQueryMobx, ItemStoreQueryModel } from "../../mst/query";
import { objectToArray } from "../../services/utils";
import { FormButtons } from "../../components/forms/formButtons";
import { notification } from "antd";
import { useDetailContext } from "../../components/detail/detailContext";
import { PolicyMobx } from "../../mst/kinds/policy";

interface Props {
  policy: PolicyMobx;
}
const PolicyTargetQueryRaw: React.FC<Props> = ({ policy }) => {
  const { fetchItem } = useDetailContext();

  const [isLoading, setIsLoading] = React.useState(false);

  function getDefaultUseQuery() {
    return !!policy.targetQuery && policy.targetQuery.spec.terms.length > 0;
  }
  const [useQuery, setUseQuery] = React.useState(getDefaultUseQuery());

  function getDefaultQueryModel() {
    if (!policy.targetQuery) {
      return;
    }

    return {
      kind: policy.targetKind,
      spec: JSON.parse(JSON.stringify(policy.targetQuery.spec)),
    };
  }
  const [query, setQuery] = React.useState<ItemStoreQueryMobx>(
    ItemStoreQueryModel.create({ previousModel: getDefaultQueryModel() as any, model: getDefaultQueryModel() as any }),
  );

  async function handleSave() {
    try {
      setIsLoading(true);
      const body = {
        targetQuery: useQuery ? query.model.toQueryObj : null,
      };
      await policy.patch(body);
      notification.success({ message: "Success", description: "Updated policy target query" });
      setIsLoading(false);
      handleReset();
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });

      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  async function handleReset() {
    setUseQuery(getDefaultUseQuery());
    setQuery(
      ItemStoreQueryModel.create({
        previousModel: getDefaultQueryModel() as any,
        model: getDefaultQueryModel() as any,
      }),
    );
  }

  let isDirty = false;
  if (useQuery !== getDefaultUseQuery()) isDirty = true;
  if (query.isDirty) isDirty = true;

  // function reset() {
  //   setUseQuery(!!policy.targetQuery);
  //   applySnapshot(targetQuery.model, getSnapshot(targetQuery.previousModel));
  // }

  return (
    <div>
      <QueryUI
        idPrefix={`policy-detail-target-query`}
        kind={policy.targetKind}
        query={query}
        useQuery={useQuery}
        // TODO do we need this?
        // onReset={reset}
        setUseQuery={setUseQuery}
        evaluatedItemFn={(item: any) => {
          const obj: any = {
            id: item.id,
            name: policy.targetKind === "user" ? item.email : item.name,
            description: policy.targetKind === "user" ? item.name : item.description || "",
            tags: objectToArray(item.tags),
          };

          if (["workload", "identity", "volumeset"].includes(policy.targetKind)) {
            obj.gvc = item.links
              .find((l: any) => l.rel === "gvc")!
              .href.split("/")
              .pop();
          }

          return obj;
        }}
      />
      <FormButtons
        loading={isLoading}
        onReset={handleReset}
        onSave={handleSave}
        resetDisabled={!isDirty || isLoading}
        saveDisabled={!isDirty || isLoading}
      />
    </div>
  );
};

export const PolicyTargetQuery = observer(PolicyTargetQueryRaw);
