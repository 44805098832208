import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, Route } from "react-router-dom";
import { OrgMobx } from "../../mst/kinds/org";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { AccountSummary } from "../../mobxStores/userData/userData";
import { AuditTrail } from "../../components/detail/auditTrail";
import { Tracing } from "./tracing";
import { Observability } from "./observability";
import { AuthConfig } from "./authConfig";
import { Logs } from "./logs/logs";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { ThreatDetection } from "./threatDetection";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  org: OrgMobx;
  account: AccountSummary | undefined;
  updateAccount: () => Promise<void>;
}
const OrgDetail: React.FC<Props> = ({ org, account, updateAccount }) => {
  const basePath = useBasePath("/*");

  const eventlogHook = useEventlogs(org.selfLink);

  const links = [
    { label: "Info", url: "-info" },
    { label: "Auth Config", url: "-auth-config" },
    { label: "External Logs", url: "-externallogs" },
    { label: "Tracing", url: "-tracing" },
    { label: "Observability", url: "-observability" },
    { label: "Threat Detection", url: "-threat-detection" },
    { label: "Tags", url: "-tags" },
    { label: "Metrics", url: "-metrics-org" },
    org.spec?.tracing?.provider?.controlplane ? { label: "Traces", url: "-traces-org" } : undefined,
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: `-accessreport` },
  ].filter(Boolean);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links as any}>
        <Route path={`-info`} element={<Info org={org} account={account} updateAccount={updateAccount} />} />
        <Route path={`-auth-config`} element={<AuthConfig org={org} />} />
        <Route path={`-externallogs/*`} element={<Logs org={org} />} />
        <Route path={`-tracing`} element={<Tracing org={org} />} />
        <Route path={`-observability`} element={<Observability org={org} />} />
        <Route path={`-threat-detection`} element={<ThreatDetection org={org} />} />
        <Route path={`-tags`} element={<Tags link={org.selfLink} tags={org.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"org"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="org" resourceId={org.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={org.selfLink} />} />
        <Route element={<Navigate to={"/console"} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export default observer(OrgDetail);
