import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderPaperspaceNodePools } from "./nodePools";
import { Mk8sCreateProviderPaperspaceNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderPaperspaceNodePool } from "./nodePool";
import { Mk8sCreateProviderPaperspaceUnmanagedNodePools } from "./unmanagedNodePools";
import { Mk8sCreateProviderPaperspaceUnmanagedNodePoolAdd } from "./unmanagedNodePoolAdd";
import { Mk8sCreateProviderPaperspaceUnmanagedNodePool } from "./unmanagedNodePool";
import { Mk8sCreateProviderPaperspaceAdvanced } from "./advanced";
import { Mk8sCreateProviderPaperspace } from "./paperspace";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderPaperspaceRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderPaperspace mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderPaperspaceNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderPaperspaceNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderPaperspaceNodePool mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-unmanaged-node-pools`}
        element={<Mk8sCreateProviderPaperspaceUnmanagedNodePools mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/-add`}
        element={<Mk8sCreateProviderPaperspaceUnmanagedNodePoolAdd mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/:index`}
        element={<Mk8sCreateProviderPaperspaceUnmanagedNodePool mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-advanced`} element={<Mk8sCreateProviderPaperspaceAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderPaperspaceRoute = observer(Mk8sCreateProviderPaperspaceRouteRaw);
