import * as React from "react";
import { Tag } from "../tag/tag";
import AnsiToHTML from "ansi-to-html";
import DOMPurify from "dompurify";
import { LogEntry } from "../../pages/logs/types";
import htmlToFormattedText from "html-to-formatted-text";
import { ChevronDown, ChevronUp } from "react-feather";
import {
  CellMeasurer as _CellMeasurer,
  CellMeasurerCache,
  Grid as _Grid,
  GridProps,
  CellMeasurerProps,
  ScrollParams,
} from "react-virtualized";
import { Tooltip } from "../Tooltip";
import { DateString } from "../dateString";
import { Timezone } from "../../mobxStores/userData/timezone";
import { formatStrings } from "../../utils/time";

const Grid = (_Grid as unknown) as React.FC<GridProps>;
const CellMeasurer = (_CellMeasurer as unknown) as React.FC<CellMeasurerProps>;

const ansiConverter = new AnsiToHTML();
interface Props {
  cache: CellMeasurerCache;
  logs: LogEntry[];
  timestamp: boolean;
  labels: boolean;
  width: number;
  height: number;
  setQueryKeyValue: any;
  orderBy: "asc" | "desc";
  setOrderBy: (value: "asc" | "desc") => void;
}

export const LogGrid: React.FC<Props> = ({
  cache,
  logs,
  timestamp,
  labels,
  width,
  height,
  setQueryKeyValue,
  orderBy,
  setOrderBy,
}) => {
  const fakeGridRef = React.useRef<typeof Grid>(null as any);

  const COLUMN_WIDTH_TIMESTAMP = 185;

  const FakeCellRenderer: React.FC<any> = ({ rowIndex, columnIndex, key, parent, style }) => {
    const isFirst = rowIndex === 0;
    const isOdd = rowIndex % 2 === 1;
    return (
      <div key={key} style={style} className={`${isFirst ? "table-labels" : isOdd ? "log-odd-bg" : "log-even-bg"}`}>
        <div style={{ width: width, height: 30 }}></div>
      </div>
    );
  };

  const CellRenderer: React.FC<any> = ({ rowIndex, columnIndex, key, parent, style }) => {
    let log = logs[rowIndex];
    if (log.id !== "labels" && orderBy === "asc") {
      log = logs[logs.length - rowIndex];
    }
    const isOdd = rowIndex % 2 === 1;

    if (timestamp && columnIndex === 0) {
      if (log.id === "labels") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style}>
              <div style={{ minWidth: COLUMN_WIDTH_TIMESTAMP }} className={`flex items-center table-labels py-1 pl-2`}>
                <span>Timestamp ({Timezone.label})</span>
                {setOrderBy ? (
                  <Tooltip title={orderBy === "asc" ? "Ascending (Oldest First)" : "Descending (Newest First)"}>
                    <button
                      onClick={() => setOrderBy(orderBy === "desc" ? "asc" : "desc")}
                      className={`ngfocus color-link`}
                    >
                      {orderBy === "desc" ? <ChevronDown /> : <ChevronUp />}
                    </button>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style}>
            <div
              className={`text-xs flex items-center h-full font-mono pl-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}
              style={{ minWidth: COLUMN_WIDTH_TIMESTAMP }}
            >
              <DateString iso={new Date(log.timestamp).toISOString()} format={formatStrings.log} />
            </div>
          </div>
        </CellMeasurer>
      );
    }

    if ((labels && !timestamp && columnIndex === 0) || (labels && timestamp && columnIndex === 1)) {
      if (log.id === "labels") {
        return (
          <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
            <div style={style}>
              <div className={`flex items-center table-labels py-1 pl-2`}>Labels</div>
            </div>
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style}>
            <div className={`flex items-center text-xs h-full min-w-min pl-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}>
              {log.labels.map(([key, value]: any) => (
                <Tag
                  key={key + value}
                  onClick={(e: React.MouseEvent) => setQueryKeyValue(key, value, e.altKey)}
                  name={key}
                  value={value}
                  small
                  style={{ marginRight: 2, maxWidth: 300 }}
                  colorize
                />
              ))}
            </div>
          </div>
        </CellMeasurer>
      );
    }

    if (log.id === "labels") {
      return (
        <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
          <div style={style}>
            <div className={`flex items-center table-labels py-1 pl-2`}>Log</div>
          </div>
        </CellMeasurer>
      );
    }

    const toFormatted = htmlToFormattedText(log.log);
    const toHtml = ansiConverter.toHtml(toFormatted);
    const toSanitizedEl = DOMPurify.sanitize(toHtml, { RETURN_DOM: true });
    const __html = toSanitizedEl.outerHTML.replaceAll("<body>", "").replaceAll("</body>", "");

    return (
      <CellMeasurer key={key} parent={parent} cache={cache} rowIndex={rowIndex} columnIndex={columnIndex}>
        <div style={style}>
          <div className={`flex items-center h-full pl-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`}>
            <div
              className={`font-mono whitespace-nowrap text-xs`}
              dangerouslySetInnerHTML={{
                __html,
              }}
            />
          </div>
        </div>
      </CellMeasurer>
    );
  };

  let columnCount = 1;
  if (timestamp) {
    columnCount += 1;
  }
  if (labels) {
    columnCount += 1;
  }

  return (
    <div className="relative">
      <div className="absolute inset-0">
        <Grid
          id="logGridFake"
          style={{ overflowX: "hidden" }}
          ref={fakeGridRef}
          width={width}
          height={height}
          columnCount={1}
          columnWidth={width}
          estimatedColumnSize={width}
          rowCount={logs.length}
          rowHeight={33}
          estimatedRowSize={logs.length * 33}
          cellRenderer={FakeCellRenderer}
        />
      </div>
      <Grid
        width={width}
        height={height}
        columnCount={columnCount}
        columnWidth={cache.columnWidth}
        onScroll={(params: ScrollParams) => {
          if (!fakeGridRef.current) {
            return;
          }
          (fakeGridRef.current as any).scrollToPosition({ scrollLeft: 0, scrollTop: params.scrollTop });
        }}
        estimatedRowSize={logs.length * 33}
        estimatedColumnSize={width * 3}
        deferredMeasurementCache={cache}
        rowCount={logs.length}
        rowHeight={33}
        cellRenderer={CellRenderer}
      />
    </div>
  );
};
