import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateSecurityOptionsRaw: React.FC<Props> = ({ workloadDraft }) => {
  const options = workloadDraft.securityOptions;

  return (
    <>
      <NGInputAdapter
        style={{ width: 450 }}
        className="mb-4"
        data={options.filesystemGroupId}
        infoContents={["The group id assigned to any mounted volume."]}
      />
    </>
  );
};

export const WorkloadCreateSecurityOptions = observer(WorkloadCreateSecurityOptionsRaw);
