import { types, getParent } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { WithLinksModel, KindProperty } from "../base";
import { permissionsHelpers } from "./permissions.helpers";

const PermissionsItemModel = types
  .model("Permission", {
    name: types.optional(types.string, ""), // /^[a-z][a-zA-Z0-9]*/
    description: types.optional(types.string, ""),
  })
  .views((self) => ({
    get implied(): string[] {
      const parent = getParent(self, 2) as PermissionsMobx;
      return permissionsHelpers.getImplied(parent as any, self as any);
    },
    get impliedText() {
      const parent = getParent(self, 2) as PermissionsMobx;
      return permissionsHelpers.getImpliedText(parent as any, self as any);
    },
  }));
export interface PermissionsItemMobx extends Instance<typeof PermissionsItemModel> {}

export const PermissionsModel = types
  .compose(
    "Permissions",
    WithLinksModel,
    types.model({
      kind: "permissions",
      targetKind: types.late(() => KindProperty),
      items: types.array(PermissionsItemModel),
      implied: types.frozen(),
    })
  )
  .views((self) => ({
    permissionByName(name: string) {
      return self.items.find((item) => item.name === name);
    },
  }));

export interface PermissionsMobx extends Instance<typeof PermissionsModel> {}
