import * as React from "react";

interface Props {
  item: string;
}
export const IPorPort: React.FC<Props> = ({ item }) => {
  return (
    <div className="py-1 px-2 old-value rounded-sm text-sm" key={item}>
      {item}
    </div>
  );
};
