import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { Location } from "./../../schema/types/location";
import { TableNewItemButton } from "../../newcomponents/table/components/tableNewButton";
import { TableExportButton } from "../../newcomponents/table/components/tableExportButton";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { linksOf, request } from "../../services/cpln";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { Table } from "../../newcomponents/table/table";
import { ProviderColumn } from "../../newcomponents/table/columns/wellKnown/providerColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { NGButton } from "../../newcomponents/button/Button";
import { AxiosResponse } from "axios";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { TableColumn } from "../../newcomponents/table/types";

interface LocationTableItem extends Location {
  uiMeta: {
    wasEnabled: boolean;
    enabled: boolean;
  };
}

const LocationListRaw: React.FC = () => {
  const { kind, id } = useTableKindId("location", "location-list");
  const qData = useTableItemQueryData<Location>(kind, { fetchAllPages: true });

  const [isLoadingEnableChanges, setIsLoadingEnableChanges] = React.useState(false);
  const [enableChanges, setEnableChanges] = React.useState<{ [_: string]: boolean }>({});

  const locations = React.useMemo<LocationTableItem[]>(
    () =>
      qData.visibleItems.map((i) => {
        const wasEnabled = i.spec?.enabled || false;
        let enabled = wasEnabled;
        const change = Object.entries(enableChanges).find(([name, value]) => name === i.name);
        if (change) {
          const [_, value] = change;
          enabled = value;
        }
        return { ...i, uiMeta: { wasEnabled: wasEnabled, enabled: enabled } };
      }),
    [qData.visibleItems, enableChanges],
  );

  const isDirty = React.useMemo(() => locations.find((i) => i.uiMeta.wasEnabled !== i.uiMeta.enabled), [locations]);

  async function onSaveEnabledChanges() {
    try {
      const promises: Promise<AxiosResponse>[] = [];

      for (const change of Object.entries(enableChanges)) {
        const [changeName, changeValue] = change;
        const l = qData.items.find((i) => i.name === changeName);
        if (!l) {
          continue;
        }
        const lValue = l.spec?.enabled || false;
        const value = changeValue;
        if (lValue !== value) {
          promises.push(request({ method: "patch", url: linksOf(l).self!, body: { spec: { enabled: value } } }));
        }
      }

      if (promises.length > 0) {
        setIsLoadingEnableChanges(true);
      }

      await Promise.all(promises);
      await qData.fetchQueriedItems();
      await qData.fetchItems();

      setIsLoadingEnableChanges(false);

      notification.success({
        message: "Success",
        description: "Updated location enabled changes",
      });
    } catch (e) {
      setIsLoadingEnableChanges(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  const columns = React.useMemo<TableColumn<LocationTableItem>[]>(
    () => [
      NameDescriptionColumn(),
      ProviderColumn(),
      TagsColumn(),
      {
        id: "spec",
        label: "",
        cell: (p) => {
          const location = p.row.original;

          return (
            <div className="flex items-center justify-end w-full pr-4">
              <NGCheckbox
                checked={location.uiMeta.enabled}
                onChange={(newValue) => {
                  // const enableChangesClone = JSON.parse(JSON.stringify(enableChanges));
                  // enableChangesClone[location.name] = newValue;
                  // setEnableChanges(enableChangesClone);

                  setEnableChanges((_enableChanges) => {
                    const enableChangesClone = JSON.parse(JSON.stringify(_enableChanges));
                    enableChangesClone[location.name] = newValue;
                    return enableChangesClone;
                  });
                }}
              >
                <NGLabelText>Enabled</NGLabelText>
              </NGCheckbox>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Table<LocationTableItem>
        tableId={id}
        title={"Locations"}
        data={locations}
        headerRenderer={(table) => {
          const currentVisibleItemLinks = table.getRowModel().rows.map((row) => linksOf(row.original).self!);
          return (
            <>
              <NGButton
                onClick={() => setEnableChanges({})}
                variant="danger"
                outlined
                disabled={!isDirty || isLoadingEnableChanges}
              >
                Reset Changes
              </NGButton>
              <NGButton
                onClick={onSaveEnabledChanges}
                variant="primary"
                disabled={!isDirty || isLoadingEnableChanges}
                loading={isLoadingEnableChanges}
              >
                Save Changes
              </NGButton>
              <TableNewItemButton kind={kind} />
              <TableExportButton selfLinks={currentVisibleItemLinks} kind={kind} />
              <TableHeaderRefreshButton onClick={qData.fetchItems} />
              <CustomTableCPLNQuery
                tableId={id}
                kind={kind}
                onQuery={qData.setQuery}
                isLoading={qData.isLoadingQueried}
              />
            </>
          );
        }}
        enableFilter
        isLoading={qData.isLoading}
        columns={columns}
      />
    </>
  );
};

export const LocationList = observer(LocationListRaw);
