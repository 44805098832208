import { flow, Instance, types } from "mobx-state-tree";
import {
  IdentityDraftNativeNetworkResourceMobx,
  IdentityDraftNativeNetworkResourceModel,
} from "./identity.draft.nativenetworkresource";
import { IdentityNativeNetworkResourceMobx, IdentityNativeNetworkResource } from "../kinds/identity";
import { BrowserServiceModel } from "../kinds/browser";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const NativeResBrowseModel = types
  .model({
    browserService: types.optional(BrowserServiceModel, () => BrowserServiceModel.create()),
    page: types.optional(types.enumeration(["none", "cloudaccount", "item"]), "none"),
    provider: types.optional(types.enumeration(["aws", "gcp"]), "aws"),
    cloudaccountName: types.optional(types.string, ""),
    isCheckingReach: types.optional(types.boolean, false),
    isPreparing: types.optional(types.boolean, false),
    prepareError: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setPage(value: "none" | "cloudaccount" | "item") {
      self.page = value;
    },
    setPageAndIndex(value: "none" | "cloudaccount" | "item") {
      self.page = value;
    },
  }))
  .actions((self) => {
    const prepare = flow(function* () {
      try {
        self.isPreparing = true;
        self.prepareError = "";
        const browserService = self.browserService;
        const provider = self.provider;
        yield browserService.fetchServices();
        if (provider === "aws") {
          const service_vpc = browserService.servicesSection.items.find((service) => service.href.includes("/vpc"));
          if (!service_vpc) {
            self.isPreparing = false;
            self.prepareError = "No VPC service is found";
            return;
          }
          browserService.servicesSection.setSelectedHref(service_vpc.href);
        } else if (provider === "gcp") {
          const service_compute = browserService.servicesSection.items.find((service) =>
            service.href.includes("/compute")
          );
          if (!service_compute) {
            self.isPreparing = false;
            self.prepareError = "No Compute service is found";
            return;
          }
          browserService.servicesSection.setSelectedHref(service_compute.href);
        }
        yield browserService.fetchRegions();
        self.isPreparing = false;
        self.prepareError = "";
        self.setPage("item");
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        self.prepareError = errorMessage;
        self.isPreparing = false;
      }
    });
    return { prepare };
  })
  .actions((self) => ({
    setProvider(provider: "aws" | "gcp") {
      self.prepareError = "";
      self.provider = provider;
      self.cloudaccountName = "";
      self.browserService.setCloudaccountLink("");
      self.browserService.setCanReach(false);
      self.browserService.clearReachError();
    },
  }))
  .actions((self) => {
    const setCloudaccountName = flow(function* (name: string) {
      self.prepareError = "";
      self.cloudaccountName = name;
      if (name) {
        const { absolute } = ngParseLink(self.cloudaccountName, { kind: "cloudaccount" });
        self.browserService.setCloudaccountLink(absolute);
        self.isCheckingReach = true;
        yield self.browserService.checkCanReach();
        self.isCheckingReach = false;
      } else {
        self.browserService.clearReachError();
      }
    });
    return { setCloudaccountName };
  })
  .actions((self) => ({
    done() {
      self.setPage("none");
    },
  }));
export interface NativeResBrowseMobx extends Instance<typeof NativeResBrowseModel> {}

export const IdentityDraftNativeNetworkResourcesHelperModel = types
  .model({
    browse: types.optional(NativeResBrowseModel, () => NativeResBrowseModel.create()),
    _resources: types.array(IdentityDraftNativeNetworkResourceModel),
    resources: types.array(IdentityDraftNativeNetworkResourceModel),
  })
  .actions((self) => ({
    reset() {
      self.resources.clear();
      for (let _resource of self._resources) {
        const item = IdentityDraftNativeNetworkResourceModel.create();
        self.resources.push(item);
        item.apply(_resource.asObject);
      }
    },
    clear() {
      self._resources.clear();
      self.resources.clear();
    },
    confirm() {
      self._resources.clear();
      for (let resource of self.resources) {
        const item = IdentityDraftNativeNetworkResourceModel.create();
        self._resources.push(item);
        item.apply(resource.asObject);
      }
    },
  }))
  .actions((self) => ({
    apply(networkResources: IdentityNativeNetworkResourceMobx[]) {
      self.clear();
      for (let resource of networkResources) {
        const _item = IdentityDraftNativeNetworkResourceModel.create();
        const item = IdentityDraftNativeNetworkResourceModel.create();
        self._resources.push(_item);
        self.resources.push(item);
        _item.apply(resource);
        item.apply(resource);
      }
    },
    add() {
      self.resources.unshift(IdentityDraftNativeNetworkResourceModel.create());
    },
    addPreDefinedResource(resource: IdentityDraftNativeNetworkResourceMobx) {
      self.resources.unshift(resource);
    },
    remove(index: number) {
      const resource = self.resources[index];
      if (resource) {
        self.resources.remove(resource);
      }
    },
    removeByResource(resource: IdentityDraftNativeNetworkResourceMobx) {
      self.resources.remove(resource);
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      for (let resource of self.resources) {
        if (!resource.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      return self.resources.map((r) => r.asObject);
    },
    get _resourcesForView() {
      return self._resources.map((r) => r.asObject);
    },
  }))
  .views((self) => ({
    get asPatch(): null | IdentityNativeNetworkResource[] {
      if (self._resources.length > 0 && self.resources.length < 1) return null;
      return self.asObject;
    },
    get isDirty() {
      const sortFn = (a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      };
      const resources = self.resources
        .map((r) => r.asObject)
        .slice()
        .sort(sortFn);
      const _resources = self._resources
        .map((r) => r.asObject)
        .slice()
        .sort(sortFn);
      // check length
      if (resources.length !== _resources.length) return true;
      // check both has the same agentlinks in order
      for (let resourceIndex in resources) {
        const resource = resources[resourceIndex];
        const _resource = _resources[resourceIndex];
        if (resource.name !== _resource.name) return true;
        if (resource.FQDN !== _resource.FQDN) return true;
        if (resource.ports.length !== _resource.ports.length) return true;
        for (let portIndex in resource.ports) {
          const port = resource.ports[portIndex];
          const _port = _resource.ports[portIndex];
          if (port !== _port) return true;
        }
        if (resource.awsPrivateLink && _resource.awsPrivateLink) {
          if (resource.awsPrivateLink.endpointServiceName !== _resource.awsPrivateLink.endpointServiceName) return true;
        }
        if (resource.gcpServiceConnect && _resource.gcpServiceConnect) {
          if (resource.gcpServiceConnect.targetService !== _resource.gcpServiceConnect.targetService) return true;
        }
      }
      return false;
    },
  }));
