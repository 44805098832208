export const deploymentHelpers = {
  getWorkloadLink(workload: any) {
    return workload.links.find((l) => l.rel === "workload")?.href;
  },
  getWorkloadName(workload: any) {
    return workload.links
      .find((l) => l.rel === "workload")
      ?.href.split("/")
      .pop();
  },
};
