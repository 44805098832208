import { RadioChangeEvent } from "antd/lib/radio";
import * as React from "react";
import { Select as AntSelect, Radio as AntRadio, Spin } from "antd";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { sortBy } from "lodash";
import { AccountSummary, UserData } from "../../../mobxStores/userData/userData";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { UIData } from "../../../mobxStores/uiData/uiData";
import { observer } from "mobx-react-lite";
import { IS_DEPLOYMENT_ENV_TEST } from "../../../envVariables";
import { flowResult } from "mobx";
import { NGButton } from "../../../newcomponents/button/Button";
import qs from "qs";

const BillingAccountSelectRaw: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [selectedAccountId, setSelectedAccountId] = React.useState("");
  const { isLoading, AccountUUID } = BillingContext;

  React.useEffect(() => {
    if (!UserData.hasAccount) {
      return;
    }

    if (AccountUUID && search.includes("action=tryCreateOrg")) {
      setSelectedAccountId(AccountUUID);
      return;
    }

    let queryParamAccountId = "";
    try {
      const queryString = qs.parse(search.replaceAll("?", ""));
      queryParamAccountId = queryString.id as string;
    } catch (e) {}

    let givenAccountId = "";
    try {
      if (UIData.initialUrl.includes("/billing/account/")) {
        givenAccountId = UIData.initialUrl.split("/billing/account/")[1].split("/")[0];
      }
    } catch (e) {}

    const localTabAccountId = localStorage.getItem(`${UIData.tabId}-account`);
    const localAccountId = localStorage.getItem("account");

    const accountIdToSelect = queryParamAccountId || givenAccountId || localTabAccountId || localAccountId;

    if (accountIdToSelect && UserData.accountSummaries.some((acc) => acc.id === accountIdToSelect)) {
      handleSelectConfirm(UserData.accountSummaries.find((acc) => acc.id === accountIdToSelect));
      return;
    }

    if (UserData.accountSummaries.length === 1) {
      handleSelectConfirm(UserData.accountSummaries[0]);
      return;
    }
    BillingContext.clearAccount();
  }, []);

  function onSelectedOrgChangeRadio(e: RadioChangeEvent) {
    setSelectedAccountId(e.target.value);
  }

  function onSelectedOrgChangeSelect(value: string) {
    setSelectedAccountId(value);
  }

  async function handleSelectConfirm(accountSummary?: AccountSummary) {
    if (!accountSummary && selectedAccountId) {
      accountSummary = UserData.accountSummaries.find((acc) => acc.id === selectedAccountId)!;
    }
    if (!accountSummary) {
      return;
    }

    if (AccountUUID !== accountSummary.id) {
      await flowResult(BillingContext.setAccount(accountSummary.id));
    }

    if (UIData.initialUrl.startsWith("/billing/account/")) {
      const redirectUrl = UIData.initialUrl;
      UIData.clearInitialUrl();
      navigate(redirectUrl);
    } else if (search.includes("action=tryCreateOrg")) {
      navigate(`/billing/account/${accountSummary.id}/-tryorgcreate`);
    } else {
      navigate(`/billing/account`);
    }
  }

  if (!UserData.hasAccount) {
    return <Navigate to={"/billing"} />;
  }

  const accountSummaries = sortBy(UserData.accountSummaries, [
    function (o: any) {
      return (o.accountName || o.fullName || "noname").toLowerCase();
    },
  ]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <Spin />
        {IS_DEPLOYMENT_ENV_TEST ? <div className="mt-4 text-lg">Selecting Account</div> : null}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center mt-16">
      <p className="text-center text-lg">Select an account</p>
      <p className="text-center">You can change this preference later</p>
      <div style={{ width: 800 }} className={"mt-8 flex flex-col items-center"}>
        {UserData.accountSummaries.length > 5 ? (
          <AntSelect
            showSearch
            filterOption={(input, option) => option!.title!.toLowerCase().includes(input.toLowerCase())}
            // below is manual, above is from ant docs
            value={selectedAccountId}
            placeholder={"Select an account"}
            onChange={onSelectedOrgChangeSelect}
            style={{ width: 450 }}
          >
            {accountSummaries.map((account) => (
              <AntSelect.Option
                key={account.id}
                value={account.id}
                title={`${account.accountName || ""} ${account.fullName || ""} ${account.company || ""}`}
              >
                {account.accountName || account.fullName || ""}
                {account.company ? ` | ${account.company}` : ""}
              </AntSelect.Option>
            ))}
          </AntSelect>
        ) : (
          <AntRadio.Group value={selectedAccountId} onChange={onSelectedOrgChangeRadio}>
            {accountSummaries.map((account) => (
              <AntRadio.Button key={account.id} value={account.id}>
                {account.accountName || account.fullName || ""}
              </AntRadio.Button>
            ))}
          </AntRadio.Group>
        )}
      </div>
      <NGButton
        className="mt-8"
        onClick={() => handleSelectConfirm()}
        variant={"primary"}
        disabled={!selectedAccountId}
      >
        Continue
      </NGButton>
    </div>
  );
};

export const BillingAccountSelect = observer(BillingAccountSelectRaw);
