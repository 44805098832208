import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, useParams } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { QuotaMobx, QuotaModel } from "../../mst/kinds/quota";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";

type RouteParams = "idName";
const QuotaDetail: React.FC = () => {
  const basePath = useBasePath("/quota/:quota/*");

  const { idName: _idName } = useParams<RouteParams>();
  const idName = _idName!;
  const id = idName.split(":s:")[0];
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);
  const [quota, setQuota] = React.useState<QuotaMobx>(null as any);

  React.useEffect(() => {
    if (idName.split(":s:").length < 2 || idName.split(":s:")[1].length < 1) {
      setIsIncorrect(true);
      setIsLoading(false);
      return;
    }
    request({ url: resourceLink("quota", id) })
      .then(({ data: _quota }) => {
        try {
          setQuota(QuotaModel.create(_quota));
        } catch (e) {
          setIsIncorrect(true);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsIncorrect(true);
        setIsLoading(false);
      });
  }, []);

  const [links] = React.useState([{ label: "Info", url: "-info" }]);

  if (isLoading) {
    return <Loader fullScreen reason={"fetching quota to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"quota"} canCreate={false} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {quota.name} - Quota - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: quota }}>
        <BasePathContext.Provider value={basePath}>
          <DetailLayout links={links}>
            <Route path={`-info`} element={<Info quota={quota} />} />
          </DetailLayout>
        </BasePathContext.Provider>
      </DetailContext.Provider>
    </>
  );
};

export default observer(QuotaDetail);
