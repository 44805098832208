import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { SelectModel } from "../../mobxDataModels/selectModel";

export const Mk8sDraftProviderPaperspaceNodePoolReadonlyModel = types.model({
  name: types.optional(types.string, ""),
  publicIpType: types.optional(types.string, ""),
  labels: types.array(
    types.model({
      key: types.string,
      value: types.string,
    }),
  ),
  taints: types.array(
    types.model({
      key: types.string,
      value: types.string,
      effect: types.string,
    }),
  ),
  minSize: types.maybe(types.number),
  maxSize: types.maybe(types.number),
  bootDiskSize: types.optional(types.number, 50),
  machineType: types.optional(types.string, ""),
});

export const Mk8sDraftProviderPaperspaceNodePoolModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["default", "added"]), "default"),
    _pool: types.optional(Mk8sDraftProviderPaperspaceNodePoolReadonlyModel, () =>
      Mk8sDraftProviderPaperspaceNodePoolReadonlyModel.create(),
    ),
    name: types.optional(StringModel, () =>
      StringModel.create({ label: "Name", validationKey: "nodePoolName", isRequired: true }),
    ),
    publicIpType: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Public IP Type",
        options: [
          { label: "None", value: "none" },
          { label: "Dynamic", value: "dynamic" },
          { label: "Static", value: "static" },
        ],
        initialValue: "none",
      }),
    ),
    labels: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    taints: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    minSize: types.optional(StringModel, () => NumberModel.create({ label: "Min Size" })),
    maxSize: types.optional(StringModel, () => NumberModel.create({ label: "Max Size" })),
    bootDiskSize: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Boot Disk Size (Gi)",
        options: [
          { label: "50", value: "50" },
          { label: "100", value: "100" },
          { label: "250", value: "250" },
          { label: "500", value: "500" },
          { label: "1000", value: "1000" },
          { label: "2000", value: "2000" },
        ],
        initialValue: "50",
      }),
    ),
    machineType: types.optional(StringModel, () => StringModel.create({ label: "Machine Type", isRequired: true })),
  })
  .actions((self) => ({
    setStatusAdded() {
      self.status = "added";
    },
    reset() {
      self.name.setInitialValue(self._pool.name);
      self.labels = ListOfItemsModel.create({
        _items: self._pool.labels.map((l) => ({ firstValue: l.key, secondValue: l.value })),
      });
      self.taints = ListOfItemsModel.create({
        _items: self._pool.taints.map((l) => ({ firstValue: l.key, secondValue: l.value, thirdValue: l.effect })),
      });

      self.minSize.setInitialValue(String(self._pool.minSize).length > 0 ? String(self._pool.minSize) : "");
      self.maxSize.setInitialValue(String(self._pool.maxSize).length > 0 ? String(self._pool.maxSize) : "");
      self.bootDiskSize.setInitialValue(String(self._pool.bootDiskSize));
      self.publicIpType.setInitialValue(String(self._pool.publicIpType));
      self.machineType.setInitialValue(self._pool.machineType);
    },
    confirm() {
      self.name.confirm();

      self.labels.confirm();

      self.taints.confirm();
      self.minSize.confirm();
      self.maxSize.confirm();
      self.bootDiskSize.confirm();
      self.publicIpType.confirm();
      self.machineType.confirm();

      const _pool: any = {
        name: self.name.value, //
        minSize: String(self.minSize.value).length > 0 ? Number(self.minSize.value) : undefined,
        maxSize: String(self.maxSize.value).length > 0 ? Number(self.maxSize.value) : undefined,
        bootDiskSize: Number(self.bootDiskSize.value),
        machineType: self.machineType.value,
        publicIpType: self.publicIpType.value,
      };

      self._pool = Mk8sDraftProviderPaperspaceNodePoolReadonlyModel.create(_pool);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isLabelsValid() {
      let res = true;
      if (self.labels.items.some((l) => !l.firstValue || !l.secondValue)) res = false;
      return res;
    },
    get isTaintsValid() {
      let res = true;
      if (self.taints.items.some((t) => !t.firstValue || !t.secondValue || !t.thirdValue)) res = false;
      return res;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.isLabelsValid) res = false;
      if (!self.isTaintsValid) res = false;
      if (!self.minSize.isValid) res = false;
      if (!self.maxSize.isValid) res = false;
      // if (!self.bootDiskSize.isValid) res = false;
      // if (!self.publicIpType.isValid) res = false;
      if (!self.machineType.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.name.isValid) reason = "name is invalid";
      if (!self.isLabelsValid) reason = "labels not valid";
      if (!self.isTaintsValid) reason = "taints not valid";
      if (!self.minSize.isValid) reason = self.minSize.invalidReason;
      if (!self.maxSize.isValid) reason = self.maxSize.invalidReason;
      // if (!self.bootDiskSize.isValid) reason = "bootDiskSize is invalid";
      // if (!self.publicIpType.isValid) reason = "public ip type is invalid";
      if (!self.machineType.isValid) reason = "machineType is invalid";
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.labels.isDirty) res = true;
      if (self.taints.isDirty) res = true;
      if (self.minSize.isDirty) res = true;
      if (self.maxSize.isDirty) res = true;
      if (self.bootDiskSize.isDirty) res = true;
      if (self.publicIpType.isDirty) res = true;
      if (self.machineType.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.name.isDirty) reason = self.name.isDirtyReason;
      if (self.labels.isDirty) reason = "labels are dirty";
      if (self.taints.isDirty) reason = "taints are dirty";
      if (self.minSize.isDirty) reason = self.minSize.isDirtyReason;
      if (self.maxSize.isDirty) reason = self.maxSize.isDirtyReason;
      if (self.bootDiskSize.isDirty) reason = self.bootDiskSize.isDirtyReason;
      if (self.publicIpType.isDirty) reason = self.publicIpType.isDirtyReason;
      if (self.machineType.isDirty) reason = self.machineType.isDirtyReason;
      return reason;
    },
    get asObject() {
      const labels: { [_: string]: string } = {};
      self.labels.items.forEach((l) => {
        labels[l.firstValue] = l.secondValue;
      });

      const obj: any = {
        name: self.name.value,
        labels: labels,
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
        minSize: Number(self.minSize.value),
        maxSize: Number(self.maxSize.value),
        bootDiskSize: Number(self.bootDiskSize.value),
        publicIpType: self.publicIpType.value,
        machineType: self.machineType.value,
      };
      return obj;
    },
  }));
