import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/layout/loader";
import { observer } from "mobx-react-lite";
import { FormLabel } from "../../../components/forms/formLabel";
import { UserData } from "../../../mobxStores/userData/userData";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { flowResult } from "mobx";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { Table } from "../../../newcomponents/table/table";
import { InternalLink } from "../../../newcomponents/table/components/InternalLink";

const BillingAccountOrgsRaw: React.FC = () => {
  const navigate = useNavigate();
  const { OrgNames, OrgsError, isOrgsLoading } = BillingContext;
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    BillingContext.fetchOrgs();
  }, []);

  async function onOrg(org: string) {
    setIsLoading(true);
    await flowResult(ConsoleContext.setOrg(org));
    await flowResult(UserData.requestGVCNames());
    ConsoleContext.setRedirect(`/console/org/${org}`);
  }

  if (isOrgsLoading) {
    return <Loader reason={"Fetching orgs of this account"} fullScreen />;
  }

  if (OrgsError) {
    return (
      <div>
        <NGAlert className="mb-4" type={"error"} message={OrgsError} />
        <NGButton
          variant={"primary"}
          onClick={async () => {
            BillingContext.fetchOrgs();
          }}
        >
          Retry
        </NGButton>
      </div>
    );
  }

  return (
    <div>
      {OrgNames.length === 0 ? (
        <div className="flex flex-col items-center">
          <div className="text-lg mt-8 mb-4">No org found for this account</div>
          <div className="mb-8">You need to create an org to start using Control Plane</div>
          <NGButton
            variant={"action"}
            onClick={() => navigate(`/billing/org/-create${OrgNames.length < 1 ? "first" : ""}`)}
          >
            Create Org
          </NGButton>
        </div>
      ) : (
        <>
          <Table<{ name: string }>
            title={"Orgs"}
            data={BillingContext.OrgNames.map((v) => ({ name: v }))}
            tableId={"account-orgs"}
            enableFilter
            isLoading={isLoading}
            hideSettings
            columns={[
              {
                id: "name",
                label: "Name",
                cell: ({ getValue }) => {
                  const org = getValue();
                  return <InternalLink onClick={() => onOrg(org)} to={``} label={org} />;
                },
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export const BillingAccountOrgs = observer(BillingAccountOrgsRaw);
