import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { DryRunAlert } from "../../dryRunAlert";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const CreateAddonsAzureACRRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const v = mk8sDraft.addon_azureAcr.clientId;
  return (
    <div>
      <div className="mb-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.azureACR"}
        />
      </div>
      <div className="mb-2">Azure Container Registry (ACR)</div>
      <div className="flex gap-2 items-start">
        <NGFormElement
          name={"azureAcr.clientId"}
          label={v.label}
          value={v.value}
          onChange={v.setValue}
          error={v.error}
        />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={v.setValue}
            path={"spec.addOns.azureACR.clientId"}
          />
        </div>
      </div>
    </div>
  );
};

export const CreateAddonsAzureACR = observer(CreateAddonsAzureACRRaw);
