import { flow, makeObservable } from "mobx";
import { TOKEN_EXP_CHECK_INTERVAL_MS } from "../../envVariables";
import { doPeriodicTokenExpirationCheck } from "../../services/cpln";

class TokenCheckerMobx {
  intervalMs: number = TOKEN_EXP_CHECK_INTERVAL_MS;

  constructor() {
    makeObservable(this, {
      checkToken: flow,
    });
  }

  *checkToken(orgTimeoutSeconds: number | null) {
    yield doPeriodicTokenExpirationCheck(orgTimeoutSeconds);
  }
}

export const TokenChecker = new TokenCheckerMobx();
