import * as React from "react";
import clsx from "clsx";
import "./index.scss";
import { useNGFormContext } from "../../reactContexts/ngFormContext";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  size?: "normal" | "small";
  invalid?: boolean;
}

export const NGTextArea: React.FC<Props> = ({
  // --
  style = {},
  className,
  onChange: _onChange,
  size = "normal",
  name,
  invalid = false,
  onFocus,
  onBlur,
  disabled,
  ...inputProps
}) => {
  /* Form Data START */
  const formData = useNGFormContext();
  const [focused, setFocused] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [visited, setVisited] = React.useState(false);
  function localOnFocus(e: React.FocusEvent<HTMLTextAreaElement>) {
    if (onFocus) {
      onFocus(e);
    }
    setFocused(true);
  }
  function localOnBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
    if (onBlur) {
      onBlur(e);
    }
    setFocused(false);
  }
  React.useEffect(() => {
    if (focused) {
      if (name && formData) {
        formData.set(name, { focused: true });
      }
      if (!visited) {
        setVisited(true);
        if (name && formData) {
          formData.set(name, { visited: true });
        }
      }
      return;
    }

    if (visited && !touched) {
      setTouched(true);
      if (name && formData) {
        formData.set(name, { focused: false, touched: true });
      }
    }
  }, [focused]);
  /* Form Data END */

  const { value: _value } = inputProps;
  const value: string = (_value as any) || "";

  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    setAutoHeight();
  }, []);

  const MIN_HEIGHT = size === "small" ? 29 : 35;

  function setAutoHeight() {
    if (textareaRef.current) {
      textareaRef.current.style.height = "0";
      textareaRef.current.style.height = Math.max(textareaRef.current.scrollHeight, MIN_HEIGHT) + "px";
    }
  }

  function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (_onChange) {
      _onChange(e);
    }

    setAutoHeight();
  }

  return (
    <textarea
      className={clsx("ngfocus ngtextarea", className, {
        hoveringfocus: !disabled,
        small: size === "small",
        invalid: invalid && !focused && touched,
        warning: invalid && !focused,
      })}
      style={{ ...style }}
      value={value}
      onChange={onChange}
      name={name}
      ref={textareaRef}
      onFocus={localOnFocus}
      onBlur={localOnBlur}
      disabled={disabled}
      {...inputProps}
    />
  );
};
