import * as React from "react";
import "./value.css";
import { Tooltip } from "../Tooltip";

interface Props {
  danger?: boolean;
  value: string;
  className?: string;
  style?: any;
  allowSpaces?: boolean;
  disableTooltip?: boolean;
}
export const Value: React.FC<Props> = ({
  value,
  danger = false,
  className = "",
  allowSpaces = false,
  style = {},
  disableTooltip = false,
}) => {
  let _style: any = { ...style };
  if (danger) {
    // TODO handle for dark theme
    _style = { color: "white", backgroundColor: "#ED4F32" };
  }
  return (
    <Tooltip title={value} open={!(disableTooltip || value.length < 20)}>
      {allowSpaces ? (
        <pre
          className={`py-1 px-2 inline-flex items-center old-value text-sm mr-2 mb-2 proxima-nova ${className}`}
          style={{ ..._style, borderRadius: 6 }}
        >
          {value}
        </pre>
      ) : (
        <span
          className={`py-1 px-2 inline-flex items-center old-value text-sm mr-2 mb-2 ${className}`}
          style={{ ..._style, borderRadius: 6 }}
        >
          {value}
        </span>
      )}
    </Tooltip>
  );
};
