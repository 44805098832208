import * as React from "react";
import { ChargeableItemMetricToLabel as ChargeableItemUnitToGraphLabel, formatXLabel } from "./utils";
import { GroupBy, PageView, TimeStep, ChartData } from "./utils";
import { Theme } from "../../mobxStores/uiData/theme";
import { BillingContext } from "../../mobxStores/billingContext/billingContext";
import { AlertCircle } from "react-feather";

interface TooltipProps {
  active?: boolean;
  payload?: {
    color: string;
    dataKey: string;
    fill: string;
    formatter: undefined | any;
    name: string;
    payload: any;
    stroke: string;
    type: undefined | any;
    unit: undefined | any;
    value: number;
  }[];
  label?: string;
  timeStep: TimeStep;
  groupBy: GroupBy;
  pageView: PageView;
  chartData: ChartData;
  isMiB: boolean;
  setIsDisplayingTooltip?: (value: boolean) => void;
}
export const CustomTooltip: React.FC<TooltipProps> = ({
  active,
  payload,
  label,
  timeStep,
  groupBy,
  pageView,
  chartData,
  isMiB,
  setIsDisplayingTooltip,
}) => {
  React.useEffect(() => {
    if (setIsDisplayingTooltip) {
      setIsDisplayingTooltip(active || false);
    }
  }, [active]);

  if (!active) {
    return null;
  }
  if (!payload) {
    return null;
  }
  if (!payload.length) {
    return null;
  }

  function formatName(dataKey: string) {
    dataKey = dataKey
      .replace("totalMap.", "")
      .replace("valueMap.", "")
      .replace("projectedTotalMap.", "")
      .replace("projectedValueMap.", "");
    const unit = chartData.units.find((unit) => unit.dataKey === dataKey);
    return unit?.label || dataKey;
  }

  function formatUnit(dataKey: string) {
    dataKey = dataKey
      .replace("totalMap.", "")
      .replace("valueMap.", "")
      .replace("projectedTotalMap.", "")
      .replace("projectedValueMap.", "");
    const unit = chartData.units.find((unit) => unit.dataKey === dataKey);
    if (!unit || !unit.metric) {
      return "";
    }
    const cItem = BillingContext.ChargeableItems.find((c) => c.consumptionTags.metric === unit.metric);
    if (!cItem) {
      return "";
    }
    return ChargeableItemUnitToGraphLabel(cItem.unit);
  }

  function getValueWithProjected(dataKey: string) {
    const main = payload?.find((p) => p.dataKey === dataKey)!;
    const projected = payload?.find((p) => p.dataKey.toLowerCase() === `projected${dataKey.toLowerCase()}`);

    let text = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
      main.value
    );
    if (projected && projected.value > 0.01) {
      const projectedText = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(main.value + projected.value);
      text += ` (${projected.unit}${projectedText})`;
    }

    return text;
  }

  const hasAnyProjection = payload?.some((p) => {
    if (!p.dataKey.toLowerCase().startsWith("projected")) {
      return false;
    }
    if (!p?.value) {
      return false;
    }
    return p.value > 0.01;
  });

  return (
    <div className={`rounded border p-2 bg-layout`} style={{ backgroundColor: "var(--color-drop)" }}>
      <p className={`mb-2`}>{formatXLabel({ value: label!, timeStep: timeStep })}</p>
      {hasAnyProjection ? (
        <div className="flex items-center">
          <AlertCircle className="h-4 color-primary" style={{ transform: "translateY(-1px)" }} />
          <span>Projections are in brackets</span>
        </div>
      ) : null}
      {payload
        .filter((p) => !p.dataKey.includes("projected"))
        .sort((a, b) => {
          if (a.dataKey.includes("replicas")) return 1;
          if (a.value > b.value) return -1;
          if (a.value < b.value) return 1;
          return 0;
        })
        .map((p) => (
          <div key={p.dataKey} className="flex flex-col">
            <div className="flex items-center mb-1">
              <span style={{ color: p.fill }}>{formatName(p.dataKey)}</span>
              <span className="ml-1" style={{ color: p.fill }}>
                {p.unit}
              </span>
              <span style={{ color: p.fill }}>{getValueWithProjected(p.dataKey)}</span>
              {pageView === "cost" ? null : (
                <span className="ml-1" style={{ color: p.fill }}>
                  {formatUnit(p.dataKey)}
                </span>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};
