import React from "react";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../mobxDataModels/stringModel";
import { request } from "../../services/cpln";
import { Dropdown, notification } from "antd";
import { Download, Loader, Search } from "react-feather";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Audit } from "../../pages/auditTrail/types";
import { getAuditTrailPresets, getDefaultAuditTrailTime } from "../../pages/auditTrail/utils";
import { AuditTrailDiffView } from "../../pages/auditTrail/diffView";
import { ViewModal } from "../modals/viewModal";
import { ScrollParams } from "react-virtualized";
import { AuditGridWrapper } from "./auditGridWrapper";
import { isAuditDiffable, stringToHash } from "../../services/utils";
import { NGButton } from "../../newcomponents/button/Button";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInput } from "../../newcomponents/input/input";
import { UserPreferences } from "../../mobxStores/userData/userPreferences";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import jsYaml from "js-yaml";
import { RangePicker } from "../antd/RangePicker";
import { Timezone } from "../../mobxStores/userData/timezone";
import { useDetailContext } from "./detailContext";

interface Props {
  kind: string;
  resourceId: string;
}
const AuditTrailRaw: React.FC<Props> = ({ kind, resourceId }) => {
  const { org } = ConsoleContext;
  const navigate = useNavigate();
  const { item } = useDetailContext();
  const location = useLocation();
  const { subject, audit, from, to, audits: auditsQs, splitView, viewDiff: viewDiffQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [viewDiff, setViewDiff] = React.useState(false);
  const subjectInputRef = React.useRef(StringModel.create({ label: "Subject Name" }));
  const subjectInput = subjectInputRef.current;
  const [auditContext, setAuditContext] = React.useState("cpln");

  // TODO support when it cannot be null
  const [fromISO, setFromISO] = React.useState<string | null>(null);
  const [toISO, setToISO] = React.useState<string | null>(null);

  const [isUrlStateProcessed, setIsUrlStateProcessed] = React.useState(false);
  const [queried, setQueried] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [audits, setAudits] = React.useState<Audit[]>([]);
  const [selectedAudits, setSelectedAudits] = React.useState<string[]>([]);
  const [showDetailsOf, setShowDetailsOf] = React.useState<null | string>(null);
  const nextLinkRef = React.useRef<string | undefined>(undefined);

  React.useEffect(() => {
    if (isUrlStateProcessed) {
      fetchData();
    }
  }, [isUrlStateProcessed]);

  React.useEffect(() => {
    if (queried) {
      if (viewDiffQS) {
        setViewDiff(viewDiffQS.toString() === "true" ? true : false);
      }
      if (typeof auditsQs === "string") {
        const selectedAuditsQSArray = auditsQs.split(",").map((a) => Number(a));
        const auditsIds = audits.map((a) => a.id);
        const matchedIds = auditsIds.filter((id) => selectedAuditsQSArray.includes(stringToHash(id)));
        setSelectedAudits(matchedIds);
      }
    }
  }, [queried]);

  React.useEffect(() => {
    if (subject) {
      subjectInput.setValue(subject.toString());
    }
    if (splitView) {
      UserPreferences.setUseSplitViewComparison(splitView === "true" ? true : false);
    }
    if (audit) {
      setAuditContext(audit.toString());
    }
    if (from) {
      setFromISO(from.toString());
    } else {
      setFromISO(getDefaultAuditTrailTime());
    }
    if (to) {
      setToISO(to.toString());
    }
    setIsUrlStateProcessed(true);
  }, []);

  React.useEffect(() => {
    if (!queried || !isUrlStateProcessed) {
      return;
    }

    const qsObject: any = {};
    if (subjectInput.value) {
      qsObject.subject = subjectInput.value;
    }
    if (auditContext) {
      qsObject.audit = auditContext;
    }
    if (fromISO) {
      qsObject.from = fromISO;
    }
    if (toISO) {
      qsObject.to = toISO;
    }

    const queryString = qs.stringify(qsObject, { arrayFormat: "comma", encode: false });
    navigate({ search: `?${queryString}` });
  }, [
    subjectInput.value,
    auditContext,
    fromISO,
    toISO,
    UserPreferences.useSplitViewComparison,
    selectedAudits,
    viewDiff,
    isLoading,
  ]);

  function onScroll_Items(params: ScrollParams) {
    if (isLoading) {
      return;
    }
    if (!nextLinkRef.current) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = params;
    const val = scrollHeight - (scrollTop + clientHeight);
    if (val < 1) {
      fetchData();
    }
  }

  function onQuery() {
    nextLinkRef.current = undefined;
    setAudits([]);
    fetchData();
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      let url = `/audit/org/${org}`;
      if (nextLinkRef.current) {
        url = nextLinkRef.current;
      } else {
        let params: string[] = [`resourceType=${kind}`];
        url += `/resource/id/${resourceId}`;
        if (subjectInput.value.length > 0) {
          params.push(`subjectName=${subjectInput.value}`);
        }
        if (auditContext) {
          params.push(`contextName=${auditContext}`);
        }
        if (fromISO) {
          params.push(`fromEvent=${fromISO}`);
        }
        if (toISO) {
          params.push(`toEvent=${toISO}`);
        }
        if (params.length > 0) {
          url += `?${params.join("&")}`;
        }
      }
      const { data } = await request({ service: "audit", url });
      if (nextLinkRef.current) {
        setAudits([...audits, ...data.items]);
      } else {
        setAudits(data.items);
      }
      const _nextLink = data.links.find((l: any) => l.rel === "next")?.href;
      if (data.items.length === 100) {
        nextLinkRef.current = _nextLink;
      } else {
        nextLinkRef.current = undefined;
      }
      setQueried(true);
    } catch (e) {
      const isUnathorized = e.response?.status === 403;
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      if (isUnathorized) errorMessage = "Unauthorized";
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function onClear() {
    subjectInput.setInitialValue("");
    subjectInput.setValue("");
    setAuditContext("cpln");
    setFromISO(getDefaultAuditTrailTime());
    setToISO(null);
  }

  async function downloadAudits(format: "json" | "yaml") {
    try {
      let blob = "";

      if (format === "json") {
        blob = JSON.stringify(audits, null, 2);
      } else if (format === "yaml") {
        blob = jsYaml.dump(audits, { indent: 2, noRefs: true });
      }

      autoDownloadWithElementA(blob, format);
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  function autoDownloadWithElementA(blobData: any, format: "json" | "yaml") {
    const file = new Blob([blobData], { type: `text/${format}` });
    const href = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style.display = "none";
    a.classList.add("cpln-temp-a");
    a.download = `${kind}-${item.name}-audits-from-${fromISO}-to${toISO}.${format}`;
    a.href = href;
    a.click();
  }

  if (viewDiff) {
    return (
      <AuditTrailDiffView
        audits={
          selectedAudits.length > 0
            ? audits.filter((a) => a.id && selectedAudits.includes(a.id))
            : audits.filter((a) => isAuditDiffable(a))
        }
        onClose={() => setViewDiff(false)}
      />
    );
  }

  return (
    <>
      <div className="mb-2 text-2xl font-medium">Audit Trail</div>
      <div className="flex items-center mb-2">
        <NGKindSelect
          placeholder="Any Context"
          className="mr-2"
          style={{ width: 150 }}
          kind={"auditctx"}
          value={auditContext}
          onChange={(value) => setAuditContext(value)}
        />
        <NGInput
          className="flex-grow basis-0 mr-2"
          value={subjectInput.value}
          onChange={(e) => subjectInput.setValue(e.target.value)}
          placeholder={subjectInput.label}
        />
        <div className="flex items-center" style={{ width: 170 }}>
          <NGButton
            variant={"primary"}
            style={{ width: isLoading || audits.length < 1 ? 170 : 120 }}
            onClick={onQuery}
            disabled={isLoading}
            loading={isLoading}
          >
            Query
          </NGButton>
          {isLoading || audits.length < 1 ? null : (
            <Dropdown
              trigger={["click"]}
              menu={{
                onClick: ({ key }: any) => {
                  switch (key) {
                    case "export-json":
                      downloadAudits("json");
                      break;
                    case "export-yaml":
                      downloadAudits("yaml");
                      break;
                    default:
                      break;
                  }
                },
                items: [
                  { key: "export-json", label: "JSON" },
                  { key: "export-yaml", label: "YAML" },
                ],
              }}
            >
              <NGButton
                style={{ width: 45, marginLeft: 5 }}
                variant={"secondary"}
                renderIcon={(_, props) => <Download {...props} />}
              />
            </Dropdown>
          )}
        </div>
      </div>
      <div className="flex items-center mb-4">
        {/* TODO either only allow utc, or show timezone */}
        <RangePicker
          className="mr-2"
          style={{ width: 500 }}
          presets={getAuditTrailPresets()}
          size={"small"}
          allowClear={false}
          placeholder={["From", "To"]}
          showTime
          allowEmpty={[false, true]}
          fromISO={fromISO}
          setFromISO={setFromISO}
          toISO={toISO}
          setToISO={setToISO}
          timezoneValue={Timezone.value}
        />
        <NGButton
          variant="secondary"
          style={{ width: 200 }}
          onClick={() => setViewDiff(true)}
          disabled={
            isLoading ||
            (selectedAudits.length > 0 && selectedAudits.length < 2) ||
            audits.filter(isAuditDiffable).length < 2
          }
        >
          View Diff {selectedAudits.length > 1 ? "For Selected" : "For All"}
        </NGButton>
        <div className="flex-grow" />
        <NGButton variant="secondary" style={{ width: 150 }} onClick={() => onClear()} disabled={isLoading}>
          Clear All Filters
        </NGButton>
      </div>
      {isLoading && !nextLinkRef.current && (
        <div className="flex flex-col items-center p-4 border" style={{ borderRadius: 6 }}>
          <Loader className="mb-2 animate-spin" />
          <p className="text-center text-2xl mt-2">Loading Audit Events</p>
        </div>
      )}
      {audits.length < 1 && queried && !isLoading ? (
        <div className="flex flex-col items-center p-4 border">
          <Search />
          <p className="text-center text-2xl mt-2">No Audit Events Found</p>
        </div>
      ) : null}
      <AuditGridWrapper
        audits={audits}
        emptySpaceValue={478}
        onDetail={(id) => setShowDetailsOf(id)}
        onScroll={onScroll_Items}
        queried={queried}
        diffSupport={{ selectedAudits, setSelectedAudits }}
      />
      {showDetailsOf ? (
        <ViewModal
          object={audits.find((a) => a.id === showDetailsOf)}
          title={"Audit Log"}
          onClose={() => setShowDetailsOf(null)}
          visible={!!showDetailsOf}
          filename={`audit-${audits.find((a) => a.id === showDetailsOf)?.id}`}
        />
      ) : null}
    </>
  );
};

export const AuditTrail = observer(AuditTrailRaw);
