import { StringModel, StringMobx } from "./stringModel";

interface Props {
  value?: string;
  label?: string;
  validationKey?: "name" | "workloadName" | "containerName";
}
export const NameModel = {
  create: (props?: Props): StringMobx => {
    if (!props) {
      props = {};
    }
    if (!props.validationKey) {
      props.validationKey = "name";
    }
    return StringModel.create({
      label: props.label || "Name",
      examples: ["foo", "bar-123"],
      isRequired: true,
      initialValue: props.value,
      validationKey: props.validationKey,
      transformKey: "lowerCase",
    });
  },
};
