import * as React from "react";
import { matchRoutes, useLocation } from "react-router-dom";

type BasePathContextType = string;

export const BasePathContext = React.createContext<BasePathContextType>("");

export function useBasePath(
  path: string,
  overridePath: boolean = false,
  dashboard: "console" | "billing" = "console"
): BasePathContextType {
  const location = useLocation();
  const calculatedPath = overridePath ? `/${dashboard}${path}` : `/${dashboard}/org/:org${path}`;
  const someRoutes = [{ path: calculatedPath }];
  const matches = matchRoutes(someRoutes, location);
  let basePath = "";
  try {
    basePath = matches![0].pathnameBase;
  } catch (e) {}
  return basePath;
}
