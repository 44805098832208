import { SelectModel } from "../../../../mobxDataModels/selectModel";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { LoggingStackdriverMobx, LoggingStackdriverModelRegionOptions } from "../../../../mst/kinds/org";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";

export function getLogsStackdriverData(stackdriver: LoggingStackdriverMobx) {
  function getDefaultStackdriverLocation() {
    return stackdriver.location || LoggingStackdriverModelRegionOptions[0];
  }
  const stackdriver_location = SelectModel.create({
    label: "Location",
    initialValue: getDefaultStackdriverLocation(),
    options: LoggingStackdriverModelRegionOptions.sort().map((v) => ({ label: v, value: v })),
  });

  function getDefaultStackdriverCredentials() {
    return ngParseLink(stackdriver.credentials || "", { kind: "secret" }).name;
  }
  const stackdriver_credentials = StringModel.create({
    isRequired: true,
    label: "Credentials",
    initialValue: getDefaultStackdriverCredentials(),
  });

  function isDirty() {
    let res = false;
    if (getDefaultStackdriverLocation() !== stackdriver_location.value) {
      res = true;
    }
    if (getDefaultStackdriverCredentials() !== stackdriver_credentials.value) {
      res = true;
    }
    return res;
  }

  function isValid() {
    let res = true;
    if (!stackdriver_credentials.isValid) {
      res = false;
    }
    return res;
  }

  function asObject() {
    return {
      stackdriver: {
        location: stackdriver_location.value,
        credentials: ngParseLink(stackdriver_credentials.value, { kind: "secret" }).absolute,
      },
    };
  }

  return {
    logProvider: "stackdriver",
    location: stackdriver_location,
    credentials: stackdriver_credentials,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
