import React from "react";
import { useBreadcrumbs } from "../../reactContexts/breadcrumbContext";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import "./index.css";

/* Breadcrumb Section */
interface BreadcrumbSectionProps {
  breadcrumb: BreadcrumbValueProps;
  preArrow: boolean;
}

const BreadcrumbSection: React.FC<BreadcrumbSectionProps> = ({ breadcrumb, preArrow }) => (
  <>
    {preArrow && <span className="ngbreadcrumb-divider">/</span>}
    <BreadcrumbValue {...breadcrumb} />
  </>
);

/* Breadcrumb Value */
interface BreadcrumbValueProps {
  content: string;
  url?: string;
  debug: { index: number };
}

const DEBUG = false;
const BreadcrumbValue: React.FC<BreadcrumbValueProps> = ({ content, url, debug: { index } }) => {
  if (!content) {
    return null;
  }
  let _content = content;
  if (content.includes(":s:") && content.split(":s:").length > 1) {
    _content = content.replace(/:s:/g, "/");
  }
  if (!!url) {
    return (
      <Link className="ngbreadcrumb-link" to={`/console${url}`}>
        {_content}
        {DEBUG ? ` | (${index})` : ""}
      </Link>
    );
  }

  return (
    <span>
      {_content}
      {DEBUG ? ` | (${index})` : ""}
    </span>
  );
};

/* Breadcrumb Raw */
const BreadcrumbRaw: React.FC = () => {
  const { pathname } = useLocation();
  const [breadcrumbs] = useBreadcrumbs();

  if (breadcrumbs.length < 1) {
    return null;
  }

  return (
    <div className={clsx("ngbreadcrumb", { billing: pathname.startsWith("/billing") })}>
      {breadcrumbs.map((section, index) => (
        <BreadcrumbSection key={`${section.content}-${index}`} breadcrumb={section} preArrow={index !== 0} />
      ))}
    </div>
  );
};

export const Breadcrumb = observer(BreadcrumbRaw);
