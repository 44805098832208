import * as React from "react";
import * as moment from "moment-timezone";

// An enum with all the types of actions to use in our reducer
export enum RunCreateVolumeSnapshotReducerActionType {
  LOCATION = "LOCATION",
  INDEX = "INDEX",
  NAME = "NAME",
  DATE = "DATE",
  ADD_TAG = "ADD_TAG",
  REMOVE_TAG = "REMOVE_TAG",
  CHANGE_TAG = "CHANGE_TAG",
}

// An interface for our actions
interface RunCreateVolumeSnapshotReducerAction {
  type: RunCreateVolumeSnapshotReducerActionType;
  payload: any;
}

// An interface for our state
export interface CreateVolumeSnapshotReducerState {
  location: string;
  volumeIndex: string;
  snapshotName: string;
  snapshotExpirationDate: string | null;
  snapshotTags: { index: number; name: string; value: string }[];
}

// Our reducer function that uses a switch statement to handle our actions
function runCreateVolumeSnapshot(
  state: CreateVolumeSnapshotReducerState,
  action: RunCreateVolumeSnapshotReducerAction,
) {
  const { type, payload } = action;
  switch (type) {
    case RunCreateVolumeSnapshotReducerActionType.LOCATION:
      return {
        ...state,
        location: payload,
      };
    case RunCreateVolumeSnapshotReducerActionType.INDEX:
      return {
        ...state,
        volumeIndex: payload,
      };
    case RunCreateVolumeSnapshotReducerActionType.NAME:
      return {
        ...state,
        snapshotName: payload,
      };
    case RunCreateVolumeSnapshotReducerActionType.DATE:
      return {
        ...state,
        snapshotExpirationDate: payload,
      };
    case RunCreateVolumeSnapshotReducerActionType.ADD_TAG:
      return {
        ...state,
        snapshotTags: [...state.snapshotTags, { index: state.snapshotTags.length, name: "", value: "" }],
      };
    case RunCreateVolumeSnapshotReducerActionType.REMOVE_TAG:
      return {
        ...state,
        snapshotTags: state.snapshotTags.filter((e) => e.index !== payload),
      };
    case RunCreateVolumeSnapshotReducerActionType.CHANGE_TAG:
      return {
        ...state,
        snapshotTags: state.snapshotTags.map((tag) => {
          if (tag.index === payload.index) {
            return payload;
          }
          return tag;
        }),
      };
    default:
      return state;
  }
}

export function useRunCreateVolumeSnapshotReducer(
  initialLocation: string | undefined,
  initialVolumeIndex: string | undefined,
) {
  const initialState: CreateVolumeSnapshotReducerState = {
    location: initialLocation || "",
    volumeIndex: initialVolumeIndex || "",
    snapshotName: "",
    snapshotExpirationDate: null,
    snapshotTags: [],
  };
  return React.useReducer(runCreateVolumeSnapshot, initialState);
}

export function getExpirationDateFromRetentionDuration(retentionDuration?: string): moment.Moment | null {
  let expirationDate: moment.Moment | null = null;
  if (retentionDuration) {
    const amount = retentionDuration.slice(0, retentionDuration.length - 1);
    const unit = retentionDuration.slice(retentionDuration.length - 1);
    const duration = moment.duration(Number(amount), unit as any);
    const now = moment.utc();
    expirationDate = now.add(duration);
  }
  return expirationDate;
}
