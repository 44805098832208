import { types } from "mobx-state-tree";
import { TaintModel } from "./common";
import { AutoscalerModel } from "./autoscaler";
import { NetworkingModel } from "./networking";
import { PreInstallScriptProperty } from "./preInstallScript";

export const LinodeProviderNodePoolModel = types.model("Linode Provider Node Pool", {
  name: types.string,
  labels: types.frozen(),
  taints: types.array(TaintModel),
  serverType: types.string,
  overrideImage: types.optional(types.string, ""),
  minSize: types.number,
  maxSize: types.number,
  subnetId: types.string,
});

export const LinodeProviderModel = types.model("Linode Provider", {
  region: types.string,
  tokenSecretLink: types.string,
  firewallId: types.maybe(types.string),
  image: types.string,
  authorizedUsers: types.array(types.string),
  authorizedKeys: types.array(types.string),
  vpcId: types.string,

  nodePools: types.array(LinodeProviderNodePoolModel),

  networking: types.optional(NetworkingModel, () => NetworkingModel.create()),
  preInstallScript: PreInstallScriptProperty,
  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
});
