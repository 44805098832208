import { types } from "mobx-state-tree";
import { AutoscalerModel } from "./autoscaler";
import { TaintModel } from "./common";
import { NetworkingModel } from "./networking";
import { PreInstallScriptProperty } from "./preInstallScript";

const AmiModel = types.model("Ami Model", {
  recommended: types.maybe(types.string), //
  exact: types.maybe(types.string),
});

const AWSProviderNodePoolModel = types.model("AWS Provider Node Pool", {
  name: types.string,
  labels: types.frozen(),
  taints: types.array(TaintModel),
  instanceTypes: types.array(types.string), // min 1
  overrideImage: types.optional(AmiModel, () => AmiModel.create()), // ami
  bootDiskSize: types.optional(types.number, 20),
  minSize: types.number,
  maxSize: types.number,
  onDemandBaseCapacity: types.maybe(types.number),
  onDemandPercentageAboveBaseCapacity: types.optional(types.number, 0), // min 0 max 100
  spotAllocationStrategy: types.optional(types.string, "lowest-price"), // 'lowest-price', 'capacity-optimized', 'capacity-optimized-prioritized', 'price-capacity-optimized'
  subnetIds: types.array(types.string), // min 1, /^subnet-[a-f0-9]+$/
  extraSecurityGroupIds: types.array(types.string), // /^sg-[a-f0-9]+$/
});

export const AWSProviderDeployRoleChainModel = types.model("AWS Provider Deploy Role Chain", {
  roleArn: types.string, // /^arn:(aws):iam::[0-9]+:role\/.+$/
  externalId: types.maybe(types.string),
  sessionNamePrefix: types.maybe(types.string),
  // https://docs.aws.amazon.com/STS/latest/APIReference/API_AssumeRole.html
  // /[\w+=,.@-]*/
  // max 50 chars
});

export const AWSProviderModel = types.model("AWS Provider", {
  region: types.string, // 'af-south-1', 'ap-east-1', 'ap-northeast-1', 'ap-northeast-2', 'ap-northeast-3', 'ap-south-1', 'ap-south-2', 'ap-southeast-1', 'ap-southeast-2', 'ap-southeast-3', 'ap-southeast-4', 'ca-central-1', 'eu-central-1', 'eu-central-2', 'eu-north-1', 'eu-south-1', 'eu-south-2', 'eu-west-1', 'eu-west-2', 'eu-west-3', 'me-central-1', 'me-south-1', 'sa-east-1', 'us-east-1', 'us-east-2', 'us-west-1', 'us-west-2'
  image: AmiModel,
  awsTags: types.optional(types.frozen(), {}),
  skipCreateRoles: types.optional(types.boolean, false),
  deployRoleArn: types.string, // /^arn:(aws):iam::[0-9]+:role\/.+$/
  deployRoleChain: types.array(AWSProviderDeployRoleChainModel),
  vpcId: types.optional(types.string, ""), // /^vpc-[a-f0-9]+$/
  keyPair: types.optional(types.string, ""),
  diskEncryptionKeyArn: types.optional(types.string, ""),
  securityGroupIds: types.array(types.string),
  nodePools: types.array(AWSProviderNodePoolModel),
  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
  networking: types.optional(NetworkingModel, () => NetworkingModel.create()),
  preInstallScript: PreInstallScriptProperty,
  extraNodePolicies: types.array(types.string), // iam_policies
});
