import { flow, makeObservable, observable } from "mobx";
import { automatedRequest, linksOf } from "../../services/cpln";
import { cplnLog } from "../../services/utils";
import { OrgInvite, UserData } from "../userData/userData";

class TaskCheckerMobx {
  requestCounter: number = 0;
  newInviteCounter: number = 0;
  intervalMs: number = 45 * 1000;

  constructor() {
    makeObservable(this, {
      requestCounter: observable,
      newInviteCounter: observable,
      checkTasks: flow,
    });
  }

  *checkTasks() {
    try {
      cplnLog(`Checking tasks | ${new Date().toISOString()} | Request Counter: ${this.requestCounter}`);
      let nextLink: string | undefined = "/task/-forme";
      const invites: OrgInvite[] = [];
      while (nextLink) {
        // @ts-ignore yield
        const { data } = yield automatedRequest({ url: nextLink });
        for (let item of data.items) {
          invites.push(item as any);
        }
        nextLink = linksOf(data).next;
      }
      const newInviteIds = invites.map((i) => i.id);
      const oldInviteIds = UserData.orgInvites.map((i) => i.id);
      let foundNewInvite = false;
      for (let newId of newInviteIds) {
        if (!oldInviteIds.includes(newId)) {
          foundNewInvite = true;
        }
      }

      if (foundNewInvite) {
        this.newInviteCounter += 1;
        yield UserData.requestOrgInvites();
      }
      this.requestCounter += 1;
    } catch (e) {}
  }
}

export const TaskChecker = new TaskCheckerMobx();
