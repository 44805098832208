import * as React from "react";
import { Loader } from "../../../components/layout/loader";
import { arraysAreEqual, request } from "../../../services/cpln";
import { Select as AntSelect, notification, Modal } from "antd";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { Value } from "../../../components/tag/value";
import { useNavigate } from "react-router-dom";
import { FormLabel } from "../../../components/forms/formLabel";
import { User } from "../../../mobxStores/user/user";
import { BillingContext, BillingUser } from "../../../mobxStores/billingContext/billingContext";
import { flowResult } from "mobx";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGInput } from "../../../newcomponents/input/input";
import NGAlert from "../../../newcomponents/alert";
import { Table } from "../../../newcomponents/table/table";

const BillingAccountUsersRaw: React.FC = () => {
  const navigate = useNavigate();
  const { AccountUUID, Roles: selfRoles, Users, isUsersLoading, UsersError } = BillingContext;
  const [isLoadingAdd, setIsLoadingAdd] = React.useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = React.useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState("");
  const [selectedUserRoles, setSelectedUserRoles] = React.useState<string[]>([]);
  const [isEditingRoles, setIsEditingRoles] = React.useState(false);
  const [isRemovingUser, setIsRemovingUser] = React.useState(false);

  const emailRef = React.useRef(
    StringModel.create({ label: "Email", transformKey: "lowerCase", validationKey: "email" })
  );
  const [roles, setRoles] = React.useState(["billing_viewer"]);

  React.useEffect(() => {
    if (!selfRoles.includes("billing_admin") && !selfRoles.includes("billing_viewer")) {
      navigate(`/billing/account/${AccountUUID}`);
      return;
    }
    BillingContext.fetchUsers();
  }, []);

  async function addUser() {
    if (roles.length < 1) {
      notification.warning({
        message: "Failed",
        description: "You need to give at least 1 permission to the user.",
      });
      return;
    }
    try {
      setIsLoadingAdd(true);
      const body = {
        user: {
          email: emailRef.current.value,
          roles,
        },
      };
      await request({ method: "post", service: "billing-ng", url: `/account/${AccountUUID}/user`, body });
      notification.success({ message: "Success", description: "Added User" });
      emailRef.current.reset();
      setRoles(["billing_viewer"]);
      await flowResult(BillingContext.fetchUsers());
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoadingAdd(false);
    }
  }

  function editUser(email: string) {
    const _user = Users.find((user) => user.email === email);
    if (!_user) {
      return;
    }
    setSelectedUser(email);
    setSelectedUserRoles(_user.roles);
    setIsEditingRoles(true);
    setIsRemovingUser(false);
    setRoles(_user.roles);
  }

  function removeUser(email: string) {
    setSelectedUser(email);
    setIsEditingRoles(false);
    setIsRemovingUser(true);
  }

  function cancelUserRemoval(): void {
    setSelectedUser("");
    setIsRemovingUser(false);
  }

  async function confirmUserRemoval(): Promise<void> {
    try {
      setIsLoadingRemove(true);
      await request({
        method: "delete",
        service: "billing-ng",
        url: `/account/${AccountUUID}/user/email/${selectedUser}`,
      });
      notification.success({ message: "Success", description: "Removed user" });
      await flowResult(BillingContext.fetchUsers());
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setSelectedUser("");
      setIsLoadingRemove(false);
      setIsRemovingUser(false);
    }
  }

  if (isUsersLoading) {
    return <Loader reason={"Fetching users of this account"} fullScreen />;
  }

  if (UsersError) {
    return (
      <div>
        <NGAlert className="mb-4" type={"error"} message={UsersError} />
        <NGButton
          variant="primary"
          onClick={async () => {
            await flowResult(BillingContext.fetchUsers());
          }}
        >
          Retry
        </NGButton>
      </div>
    );
  }

  return (
    <>
      <div>
        {selfRoles.includes("billing_admin") ? (
          <>
            <div className="mb-2">
              <NGLabel>Add User</NGLabel>
            </div>
            <div className="mb-4" style={{ width: 450 }}>
              <NGLabel>Email</NGLabel>
              <NGInput
                className="mb-4"
                value={emailRef.current.value}
                onChange={(e) => emailRef.current.setValue(e.target.value)}
              />
              <NGLabel>Roles</NGLabel>
              <AntSelect
                className="mb-4 w-full"
                mode={"multiple"}
                options={[
                  { label: "billing_admin", value: "billing_admin" },
                  { label: "billing_viewer", value: "billing_viewer" },
                  { label: "org_creator", value: "org_creator" },
                ]}
                value={roles}
                size={"middle"}
                onChange={(newValues) => setRoles(newValues)}
              />
              <div className="flex justify-end">
                <NGButton
                  variant={"action"}
                  onClick={addUser}
                  disabled={isLoadingAdd || emailRef.current.value.length < 1 || !emailRef.current.isValid}
                  loading={isLoadingAdd}
                >
                  Add User
                </NGButton>
              </div>
            </div>
          </>
        ) : null}
        <Table<BillingUser>
          title={selfRoles.includes("billing_admin") ? "Current Users" : "Users"}
          data={Users}
          tableId={"account-users"}
          enableFilter
          isLoading={BillingContext.isUsersLoading}
          hideSettings
          columns={[
            {
              id: "email",
              label: "Email",
              enableResize: true,
            },
            {
              id: "roles",
              label: "Roles",
              enableResize: true,
              cell: ({ getValue, row }) => {
                return (
                  <div className="py-2">
                    {getValue()
                      .slice()
                      .sort()
                      .map((role) => (
                        <Value className={"text-sm"} key={role} value={role} style={{ marginBottom: 0 }} />
                      ))}
                  </div>
                );
              },
            },
            {
              id: "actions",
              label: "Actions",
              cell: ({ row }) => {
                const user = row.original;
                return (
                  <div>
                    {!selfRoles.includes("billing_admin") || user.email === User.email ? null : (
                      <div className={`flex justify-end`}>
                        <NGButton
                          disabled={isEditingRoles}
                          size={"small"}
                          variant={"secondary"}
                          className="mr-2"
                          onClick={() => editUser(user.email)}
                        >
                          Edit
                        </NGButton>
                        <NGButton size={"small"} onClick={() => removeUser(user.email)} variant={"danger"}>
                          Remove
                        </NGButton>
                      </div>
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
      {selectedUser && isEditingRoles ? (
        <Modal
          open={isEditingRoles && !!selectedUser}
          onCancel={() => {
            setSelectedUser("");
            setIsEditingRoles(false);
          }}
          footer={
            <div className="modal-actions">
              <NGButton
                variant="secondary"
                onClick={() => {
                  setSelectedUser("");
                  setIsEditingRoles(false);
                }}
                disabled={isLoadingEdit}
              >
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={async () => {
                  try {
                    setIsLoadingEdit(true);
                    await request({
                      method: "put",
                      service: "billing-ng",
                      url: `/account/${AccountUUID}/user/email/${selectedUser}`,
                      body: {
                        user: {
                          email: selectedUser,
                          roles: roles,
                        },
                      },
                    });
                    await flowResult(BillingContext.fetchUsers());
                  } catch (e) {
                    let errorMessage = e?.response?.data?.error;
                    if (!errorMessage) errorMessage = e.message;
                    notification.warning({
                      message: "Failed",
                      description: errorMessage,
                    });
                  } finally {
                    setSelectedUser("");
                    setIsEditingRoles(false);
                    setIsLoadingEdit(false);
                    setRoles(["billing_viewer"]);
                  }
                }}
                loading={isLoadingEdit}
                disabled={isLoadingEdit || arraysAreEqual(selectedUserRoles, roles) || roles.length < 1}
              >
                Confirm
              </NGButton>
            </div>
          }
        >
          <div className="mx-2">Roles</div>
          <AntSelect
            mode={"multiple"}
            options={[
              { label: "billing_admin", value: "billing_admin" },
              { label: "billing_viewer", value: "billing_viewer" },
              { label: "org_creator", value: "org_creator" },
            ]}
            value={roles}
            style={{ width: 400 }}
            onChange={(newValues) => setRoles(newValues)}
          />
        </Modal>
      ) : null}
      {selectedUser && isRemovingUser ? (
        <Modal
          title="Remove User"
          open={true}
          maskClosable={!isLoadingRemove}
          destroyOnClose
          onCancel={cancelUserRemoval}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={cancelUserRemoval}>
                Cancel
              </NGButton>
              <NGButton
                variant="danger"
                onClick={confirmUserRemoval}
                loading={isLoadingRemove}
                disabled={isLoadingRemove}
              >
                Remove
              </NGButton>
            </div>
          }
        >
          This user will lose access to this account, but will still be part of orgs of that account.
        </Modal>
      ) : null}
    </>
  );
};

export const BillingAccountUsers = observer(BillingAccountUsersRaw);
