import { makeAutoObservable, observable } from "mobx";
import { NGFormElementState } from "../reactContexts/ngFormContext";
import { v4 as uuidv4 } from "uuid";

const defaultElementData: NGFormElementState = {
  focused: false,
  visited: false,
  touched: false,
};

export class NGFormData {
  id: string;
  data = observable.map<string, NGFormElementState>({});

  constructor(id?: string) {
    makeAutoObservable(this);
    this.id = id || uuidv4();
  }

  get(name: string): NGFormElementState {
    let elementData = this.data.get(name);
    if (!elementData) {
      return defaultElementData;
    }
    return elementData;
  }

  set(name: string, state: Partial<NGFormElementState>) {
    if (!this.data.has(name)) {
      this.data.set(name, defaultElementData);
    }

    let elementData = this.get(name);

    elementData = Object.assign(elementData, state) as any;

    this.data.set(name, elementData);
  }

  reset() {
    this.data.clear();
  }
}
