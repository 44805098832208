import * as React from "react";
import { observer } from "mobx-react-lite";
import { AlertCircle, ArrowUpRight, RefreshCw } from "react-feather";
import { ViewModal } from "../modals/viewModal";
import { NGButton } from "../../newcomponents/button/Button";
import { formatStrings } from "../../utils/time";
import { Table } from "../../newcomponents/table/table";
import { DateStringColumn } from "../../newcomponents/table/columns/generic/dateString";
import { Kind } from "../../mst/base";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";

interface Props {
  kind: Kind;
  eventlogs: any[];
  isLoading: boolean;
  fetchEventlogs: any;
}
const EventsRaw: React.FC<Props> = ({ kind, eventlogs, isLoading, fetchEventlogs }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [event, setEvent] = React.useState({});

  return (
    <>
      <Table<any>
        title={"Events"}
        data={eventlogs}
        tableId={`${kind}-eventlogs`}
        headerRenderer={() => {
          return (
            <>
              <TableHeaderRefreshButton onClick={fetchEventlogs} />
            </>
          );
        }}
        enableFilter
        hideSettings
        isLoading={isLoading}
        columns={[
          {
            id: "status",
            label: "Status",
            cell: ({ getValue, row }) => {
              const event = row.original;
              const value = getValue();
              return (
                <div
                  className={`flex items-center color-link cursor-pointer pr-2`}
                  onClick={() => {
                    setEvent(event);
                    setIsModalVisible(true);
                  }}
                  style={{ width: 200 }}
                >
                  {value}
                  {value === "errored" ? <AlertCircle className="color-danger h-4" /> : null}
                </div>
              );
            },
          },
          DateStringColumn({ id: "created" }, { format: formatStrings.log }),
          {
            id: "contextText",
            label: "Context",
            size: 600,
            enableResize: true,
          },
        ]}
      />

      {isModalVisible && (
        <ViewModal
          title={"Event"}
          object={event}
          onClose={() => setIsModalVisible(false)}
          visible={isModalVisible}
          filename={`event-${(event as any).id}`}
        />
      )}
    </>
  );
};

export const Events = observer(EventsRaw);
