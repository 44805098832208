import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { MobxInput } from "../../../components/inputs/mobxInput";
import { Button as AntButton, Modal } from "antd";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { FormLabel } from "../../../components/forms/formLabel";
import styles from "./resource.module.scss";
import clsx from "clsx";
import { Theme } from "../../../mobxStores/uiData/theme";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { NGInput } from "../../../newcomponents/input/input";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGError } from "../../../newcomponents/text/error";
import { useNGFormContext } from "../../../reactContexts/ngFormContext";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { StringMobx } from "../../../mobxDataModels/stringModel";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  containerDraft: WorkloadDraftContainerMobx;
}
const ResourceRaw: React.FC<Props> = ({ workloadDraft, containerDraft }) => {
  const formData = useNGFormContext();

  // Effects //
  // Calculate Max Value for Min CPU
  React.useEffect(() => {
    setMaxAmount(
      containerDraft.cpu.amount,
      containerDraft.minCpu.amount,
      containerDraft.cpu.unit.value,
      containerDraft.minCpu.unit.value,
    );
  }, [containerDraft.cpu.amount.value, containerDraft.cpu.unit.value, containerDraft.minCpu.unit.value]);

  // Calculate Max Value for Min Memory
  React.useEffect(() => {
    if (containerDraft.memory.unit.value === "Other" || containerDraft.minMemory.unit.value === "Other") {
      containerDraft.minMemory.amount.setMax(1000 * 1000);
    } else {
      setMaxAmount(
        containerDraft.memory.amount,
        containerDraft.minMemory.amount,
        containerDraft.memory.unit.value,
        containerDraft.minMemory.unit.value,
      );
    }
  }, [containerDraft.memory.amount.value, containerDraft.memory.unit.value, containerDraft.minMemory.unit.value]);

  // Override amount error for Min and Max CPU
  React.useEffect(() => {
    // Max / Reserved
    overrideErrorMessage(containerDraft.cpu.amount, containerDraft.cpu.unit.value);

    // Min
    overrideErrorMessage(containerDraft.minCpu.amount, containerDraft.minCpu.unit.value, containerDraft.cpu.amount);
  }, [
    containerDraft.cpu.amount.value,
    containerDraft.cpu.unit.value,
    containerDraft.minCpu.amount.value,
    containerDraft.minCpu.unit.value,
  ]);

  // Override amount error for Min and Max Memory
  React.useEffect(() => {
    // Max / Reserved
    overrideErrorMessage(containerDraft.memory.amount, containerDraft.memory.unit.value);

    // Min
    overrideErrorMessage(
      containerDraft.minMemory.amount,
      containerDraft.minMemory.unit.value,
      containerDraft.memory.amount,
      containerDraft.memory.unit.value,
    );
  }, [
    containerDraft.memory.amount.value,
    containerDraft.memory.unit.value,
    containerDraft.minMemory.amount.value,
    containerDraft.minMemory.unit.value,
  ]);

  React.useEffect(() => {
    containerDraft.updateMinResourceUnits(false);
  }, [containerDraft.memory.unit.value, containerDraft.cpu.unit.value]);

  // Functions //
  function overrideErrorMessage(
    amount: StringMobx,
    unit: string,
    referenceAmount?: StringMobx,
    referenceUnit?: string,
  ) {
    const value: number = Number(amount.value);

    if (!amount.value || isNaN(value)) {
      amount.clearOverrideError();
      return;
    }

    let errorMessage: string = "";

    if (amount.min !== undefined && value < amount.min) {
      errorMessage = `${amount.label} must be at least ${amount.min} ${unit}`;
    } else if (amount.max !== undefined && value > amount.max) {
      if (referenceAmount) {
        if (referenceUnit && unit != referenceUnit) {
          errorMessage = `${amount.label} (${amount.value} ${unit}) cannot be greater than ${referenceAmount.label} (${referenceAmount.value} ${referenceUnit}). Please adjust values or units`;
        } else {
          errorMessage = `${amount.label} (${amount.value} ${unit}) cannot be higher than ${referenceAmount.label} (${amount.max} ${unit})`;
        }
      } else {
        errorMessage = `${amount.label} (${amount.value} ${unit}) cannot be higher than ${amount.max} ${unit}`;
      }
    }

    if (errorMessage) {
      amount.setOverrideError(errorMessage);
    } else {
      amount.clearOverrideError();
    }
  }

  function setMaxAmount(source: StringMobx, target: StringMobx, sourceUnit: string, targetUnit: string) {
    const sourceAmount: number = Number(source.value);

    if (!isNaN(sourceAmount)) {
      target.setMax(convertAmount(sourceAmount, sourceUnit, targetUnit));
    } else {
      target.setMax(1000 * 1000);
    }
  }

  function convertAmount(amount: number, fromUnit: string, toUnit: string): number {
    if (fromUnit === toUnit) {
      return amount;
    }

    if (fromUnit === "Millicores" && toUnit === "Cores") {
      return amount / 1000;
    }

    if (fromUnit === "Cores" && toUnit === "Millicores") {
      return amount * 1000;
    }

    if (fromUnit === "Mi" && toUnit === "Gi") {
      return amount / 1024;
    }
    if (fromUnit === "Gi" && toUnit === "Mi") {
      return amount * 1024;
    }

    return amount;
  }

  return (
    <>
      <div className="mb-4">
        <div className="flex items-center mb-2">
          <div className="flex items-center gap-2" style={{ width: 450 }}>
            <div className="w-1/2">
              <NGFormLabel name="containerCPUAmount" required invalid={!containerDraft.cpu.amount.isValid}>
                {containerDraft.cpu.amount.label}
              </NGFormLabel>
              <NGInput
                name="containerCPUAmount"
                required
                invalid={!containerDraft.cpu.amount.isValid}
                value={containerDraft.cpu.amount.value}
                onChange={(e) => containerDraft.cpu.amount.setValue(e.target.value)}
              />
            </div>
            <div className="w-1/2">
              <NGFormLabel name="containerCPUUnit">{containerDraft.cpu.unit.label}</NGFormLabel>
              <NGSelect
                name="containerCPUUnit"
                value={containerDraft.cpu.unit.value}
                options={containerDraft.cpu.unit.options}
                onChange={(value) => containerDraft.cpu.setUnit(value as any)}
              />
            </div>
          </div>
          <InfoTooltip
            className="mt-5"
            title={[
              "Reserved CPU of the workload when capacityAI is disabled.",
              "Maximum CPU when CapacityAI is enabled.",
            ]}
          />
        </div>
        {formData.get("containerCPUAmount").touched ||
        (formData.get("containerCPUUnit").touched && !containerDraft.cpu.amount.isValid) ? (
          <NGError>{containerDraft.cpu.amount.error}</NGError>
        ) : null}
      </div>
      <div className="mb-4">
        {/* TODO check distance with input vs ngerror */}
        <div className="flex items-center mb-2">
          <div className="flex items-center gap-2" style={{ width: 450 }}>
            {containerDraft.memory.unit.value === "Other" ? (
              <div className="w-1/2">
                <NGFormLabel name="containerMemory" required invalid={!containerDraft.memory.memory.isValid}>
                  {containerDraft.memory.memory.label}
                </NGFormLabel>
                <NGInput
                  name={"containerMemory"}
                  required
                  invalid={!containerDraft.memory.memory.isValid}
                  value={containerDraft.memory.memory.value}
                  onChange={(e) => containerDraft.memory.memory.setValue(e.target.value)}
                />
              </div>
            ) : (
              <div className="w-1/2">
                <NGFormLabel name="containerMemoryAmount" required invalid={!containerDraft.memory.amount.isValid}>
                  {containerDraft.memory.amount.label}
                </NGFormLabel>
                <NGInput
                  name={"containerMemoryAmount"}
                  required
                  invalid={!containerDraft.memory.amount.isValid}
                  value={containerDraft.memory.amount.value}
                  onChange={(e) => containerDraft.memory.amount.setValue(e.target.value)}
                />
              </div>
            )}
            <div className="w-1/2">
              <NGFormLabel name="containerMemoryUnit">{containerDraft.memory.unit.label}</NGFormLabel>
              <NGSelect
                name={"containerMemoryUnit"}
                value={containerDraft.memory.unit.value}
                onChange={(value) => containerDraft.memory.setUnit(value)}
                options={containerDraft.memory.unit.options}
              />
            </div>
          </div>
          <InfoTooltip
            className="mt-5"
            title={[
              "Reserved memory of the workload when capacityAI is disabled.",
              "Maximum memory when CapacityAI is enabled.",
              "Custom_Memory_1",
            ]}
          />
        </div>
        {/* {(containerDraft.memory.unit.value === "Other" && formData.get("containerMemory").touched) ||
        (formData.get("containerMemoryUnit").touched && !containerDraft.memory.memory.isValid) ? (
          <NGError>{containerDraft.memory.memory.error}</NGError>
        ) : null} */}
        {(containerDraft.memory.unit.value !== "Other" && formData.get("containerMemoryAmount").touched) ||
        (formData.get("containerMemoryUnit").touched && !containerDraft.memory.amount.isValid) ? (
          <NGError>{containerDraft.memory.amount.error}</NGError>
        ) : null}
      </div>

      {!containerDraft.cpu.amount.isValid ||
      (containerDraft.memory.unit.value === "Other" && !containerDraft.memory.memory.isValid) ||
      (containerDraft.memory.unit.value !== "Other" && !containerDraft.memory.amount.isValid) ? null : containerDraft
          .isValidMemoryCpuRatio.value ? null : (
        <NGAlert
          type={"error"}
          className={"mb-4"}
          render={() => (
            <div>
              The ratio between CPU to Memory can be at most 1/8. The current CPU value must be at least{" "}
              {containerDraft.isValidMemoryCpuRatio.suggestion}m.
              <AntButton
                className="ml-2"
                type={"primary"}
                onClick={() => {
                  containerDraft.cpu.setUnit("Millicores");
                  containerDraft.cpu.amount.setValue(String(containerDraft.isValidMemoryCpuRatio.suggestion));
                }}
              >
                Fix
              </AntButton>
            </div>
          )}
        />
      )}

      {workloadDraft.capacityAI ? (
        <>
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <div className="flex items-center gap-2" style={{ width: 450 }}>
                <div className="w-1/2">
                  <NGFormLabel name="containerMinCPUAmount" invalid={!containerDraft.minCpu.amount.isValid}>
                    {containerDraft.minCpu.amount.label}
                  </NGFormLabel>
                  <NGInput
                    name="containerMinCPUAmount"
                    invalid={!containerDraft.minCpu.amount.isValid}
                    value={containerDraft.minCpu.amount.value}
                    onChange={(e) => containerDraft.minCpu.amount.setValue(e.target.value)}
                  />
                </div>
                <div className="w-1/2">
                  <NGFormLabel name="containerMinCPUUnit">{containerDraft.minCpu.unit.label}</NGFormLabel>
                  <NGSelect
                    name="containerMinCPUUnit"
                    value={containerDraft.minCpu.unit.value}
                    options={containerDraft.minCpu.unit.options}
                    onChange={(value) => containerDraft.minCpu.setUnit(value as any)}
                  />
                </div>
              </div>
              <InfoTooltip className="mt-5" title={["Minimum CPU when CapacityAI is enabled."]} />
            </div>
            {formData.get("containerMinCPUAmount").touched ||
            (formData.get("containerMinCPUUnit").touched && !containerDraft.minCpu.amount.isValid) ? (
              <NGError>{containerDraft.minCpu.amount.error}</NGError>
            ) : null}
          </div>
          <div className="mb-4">
            {/* TODO check distance with input vs ngerror */}
            <div className="flex items-center mb-2">
              <div className="flex items-center gap-2" style={{ width: 450 }}>
                {containerDraft.minMemory.unit.value === "Other" ? (
                  <div className="w-1/2">
                    <NGFormLabel name="containerMinMemory" invalid={!containerDraft.minMemory.memory.isValid}>
                      {containerDraft.minMemory.memory.label}
                    </NGFormLabel>
                    <NGInput
                      name={"containerMinMemory"}
                      invalid={!containerDraft.minMemory.memory.isValid}
                      value={containerDraft.minMemory.memory.value}
                      onChange={(e) => containerDraft.minMemory.memory.setValue(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className="w-1/2">
                    <NGFormLabel name="containerMinMemoryAmount" invalid={!containerDraft.minMemory.amount.isValid}>
                      {containerDraft.minMemory.amount.label}
                    </NGFormLabel>
                    <NGInput
                      name={"containerMinMemoryAmount"}
                      invalid={!containerDraft.minMemory.amount.isValid}
                      value={containerDraft.minMemory.amount.value}
                      onChange={(e) => containerDraft.minMemory.amount.setValue(e.target.value)}
                    />
                  </div>
                )}
                <div className="w-1/2">
                  <NGFormLabel name="containerMinMemoryUnit">{containerDraft.minMemory.unit.label}</NGFormLabel>
                  <NGSelect
                    name={"containerMinMemoryUnit"}
                    value={containerDraft.minMemory.unit.value}
                    onChange={(value) => containerDraft.minMemory.setUnit(value)}
                    options={containerDraft.minMemory.unit.options}
                  />
                </div>
              </div>
              <InfoTooltip
                className="mt-5"
                title={[
                  "Reserved memory of the workload when capacityAI is disabled.",
                  "Maximum memory when CapacityAI is enabled.",
                  "Custom_Memory_1",
                ]}
              />
            </div>
            {(containerDraft.minMemory.unit.value === "Other" && formData.get("containerMinMemory").touched) ||
            (formData.get("containerMinMemoryUnit").touched && !containerDraft.minMemory.memory.isValid) ? (
              <NGError>{containerDraft.minMemory.memory.error}</NGError>
            ) : null}
            {(containerDraft.minMemory.unit.value !== "Other" && formData.get("containerMinMemoryAmount").touched) ||
            (formData.get("containerMinMemoryUnit").touched && !containerDraft.minMemory.amount.isValid) ? (
              <NGError>{containerDraft.minMemory.amount.error}</NGError>
            ) : null}
          </div>
        </>
      ) : null}

      <div className="flex mb-2">
        <FormLabel size="base">GPU</FormLabel>
      </div>
      <div className="flex mb-2">
        <FormLabel>Vendor</FormLabel>
      </div>
      <div className="flex flex-wrap gap-4 mb-4">
        <button
          onClick={() => workloadDraft.setGPUNone(containerDraft.name.value)}
          className={clsx(
            `flex flex-col items-center justify-center border p-4 rounded focus:outline-none`,
            styles.vendorTile,
            styles.tile,
            {
              [styles.tileLight]: Theme.theme !== "dark",
              [styles.tileDark]: Theme.theme === "dark",
              [styles.tileActive]: !containerDraft.useGPU,
            },
          )}
        >
          <div>None</div>
        </button>
        <button
          onClick={() => {
            if (!containerDraft.useGPU && workloadDraft.containerWithGPU) {
              const m = Modal.confirm({
                width: 500,
                title: "Enable GPU",
                content: (
                  <div>
                    <div>A workload can have only one container access a GPU.</div>
                    <div>Do you want the GPU to be allocated to this container?</div>
                  </div>
                ),
                footer: (
                  <div className="modal-actions">
                    <NGButton variant="secondary" onClick={() => m.destroy()}>
                      Close
                    </NGButton>
                    <NGButton
                      variant="primary"
                      onClick={() => {
                        workloadDraft.setGPUNvidia(containerDraft.name.value);
                        m.destroy();
                      }}
                    >
                      Confirm
                    </NGButton>
                  </div>
                ),
              });
            } else {
              workloadDraft.setGPUNvidia(containerDraft.name.value);
            }
          }}
          className={clsx(
            `flex flex-col items-center justify-center border p-4 rounded focus:outline-none`,
            styles.vendorTile,
            styles.tile,
            {
              [styles.tileLight]: Theme.theme !== "dark",
              [styles.tileDark]: Theme.theme === "dark",
              [styles.tileActive]: containerDraft.useGPU,
            },
          )}
        >
          <div>NVIDIA</div>
        </button>
      </div>
      {containerDraft.useGPU && (
        <>
          <div className="flex mb-2">
            <FormLabel>Model</FormLabel>
          </div>
          <div className="flex flex-wrap gap-4 mb-4">
            <button
              onClick={() => containerDraft.gpu.setNvidiaModel("t4")}
              className={clsx(
                `flex flex-col items-center justify-center border p-4 rounded focus:outline-none`,
                styles.modelTile,
                styles.tile,
                {
                  [styles.tileLight]: Theme.theme !== "dark",
                  [styles.tileDark]: Theme.theme === "dark",
                  [styles.tileActive]: containerDraft.useGPU && containerDraft.gpu.nvidia_model.value === "t4",
                },
              )}
            >
              <div>T4</div>
            </button>
            <button
              onClick={() => containerDraft.gpu.setNvidiaModel("a10g")}
              className={clsx(
                `flex flex-col items-center justify-center border p-4 rounded focus:outline-none`,
                styles.modelTile,
                styles.tile,
                {
                  [styles.tileLight]: Theme.theme !== "dark",
                  [styles.tileDark]: Theme.theme === "dark",
                  [styles.tileActive]: containerDraft.useGPU && containerDraft.gpu.nvidia_model.value === "a10g",
                },
              )}
            >
              <div>A10G</div>
            </button>
          </div>
          <MobxInput style={{ width: 220 }} data={containerDraft.gpu.nvidia_quantity} />
          {Number(containerDraft.gpu.nvidia_quantity.value) > 4 ? (
            <NGAlert
              type={"error"}
              className={"my-4"}
              message={`GPU is a preview feature, quantity is limited to ${containerDraft.gpu.nvidia_quantity.max} cards only.`}
            />
          ) : !containerDraft.isValidMemoryCpuForGpu.value ? (
            <div className="flex items-center gap-2 my-4">
              <NGAlert type={"error"} message={containerDraft.isValidMemoryCpuForGpu.message} />
              <AntButton
                className="ml-2"
                type={"primary"}
                onClick={() => {
                  const cpuSuggestion = containerDraft.isValidMemoryCpuForGpu.cpuSuggestion;
                  const memorySuggestion = containerDraft.isValidMemoryCpuForGpu.memorySuggestion;
                  if (cpuSuggestion) {
                    containerDraft.cpu.setUnit("Millicores");
                    containerDraft.cpu.amount.setValue(String(cpuSuggestion));
                  }
                  if (memorySuggestion) {
                    containerDraft.memory.setUnit("Gi");
                    containerDraft.memory.amount.setValue(String(memorySuggestion));
                  }
                }}
              >
                Fix
              </AntButton>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export const Resource = observer(ResourceRaw);
