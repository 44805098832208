import * as React from "react";
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { observer } from "mobx-react-lite";
import { PolicyCreateGeneral } from "./general";
import { Warning } from "../warning";
import { homeLink, pluralNameOfKind, request, resourceLink } from "../../../services/cpln";
import { ItemStoreQueryModel } from "../../../mst/query";
import { TabsLayout } from "../../../components/tabsLayout/tabsLayout";
import { PolicyCreateTargetQuery } from "./targetQuery";
import { PolicyCreateBindingsOverview } from "./bindings/createBindingsOverview";
import { PolicyCreateBindingDetail } from "./bindings/createBindingDetail";
import { CreateFormLink } from "../../org/create/create";
import { TagsNewModel } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { TargetTable } from "./targetTables/targetTable";
import { NameModel } from "../../../mobxDataModels/nameModel";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { SelectModel } from "../../../mobxDataModels/selectModel";
import { Binding } from "../../../schema/types/policy";

const PolicyCreateRaw: React.FC = () => {
  const basePath = useBasePath("/policy/-create/*");

  const { pathname } = useLocation();

  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));
  const targetKindRef = React.useRef(
    SelectModel.create({
      label: "Target Kind",
      initialValue: "agent",
      options: [
        { label: "Agent", value: "agent" },
        { label: "Audit Context", value: "auditctx" },
        { label: "Cloud Account", value: "cloudaccount" },
        { label: "Domain", value: "domain" },
        { label: "Group", value: "group" },
        { label: "GVC", value: "gvc" },
        { label: "Identity", value: "identity" },
        { label: "Image", value: "image" },
        { label: "IP Set", value: "ipset" },
        { label: "Kubernetes", value: "mk8s" },
        { label: "Location", value: "location" },
        { label: "Org", value: "org" },
        { label: "Policy", value: "policy" },
        { label: "Quota", value: "quota" },
        { label: "Secret", value: "secret" },
        { label: "Service Account", value: "serviceaccount" },
        { label: "Task", value: "task" },
        { label: "User", value: "user" },
        { label: "Volume Set", value: "volumeset" },
        { label: "Workload", value: "workload" },
      ],
    }),
  );
  const [isAll, setIsAll] = React.useState(false);
  const tagsRef = React.useRef(TagsNewModel.create());

  const [useQuery, setUseQuery] = React.useState(false);
  const targetQueryRef = React.useRef(ItemStoreQueryModel.create());
  const [selections, setSelections] = React.useState<string[]>([]);
  const [bindings, setBindings] = React.useState<Binding[]>([]);
  const [canAddBinding, setCanAddBinding] = React.useState(false);
  const [canCreate, setCanCreate] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  React.useEffect(() => {
    if (["org", "task"].includes(targetKindRef.current.value) && !isAll) {
      setIsAll(true);
    }
    if (!nameRef.current.isValid) {
      setCanAddBinding(false);
      return;
    }
    setCanAddBinding(true);
  }, [
    nameRef.current.isValid,
    isAll,
    useQuery,
    targetKindRef.current.value,
    selections.length,
    targetQueryRef.current.model.spec.terms.length,
  ]);

  React.useEffect(() => {
    let res = true;
    if (!canAddBinding) res = false;
    setCanCreate(res);
  }, [canAddBinding]);

  React.useEffect(() => {
    setSelections([]);
  }, [targetKindRef.current.value]);

  const [isLoading, setIsLoading] = React.useState(false);

  function getPolicyObject() {
    let body: any = {
      name: nameRef.current.value,
      description: descriptionRef.current.value || nameRef.current.value,
      targetKind: targetKindRef.current.value,
      bindings: bindings,
      tags: tagsRef.current.asObject,
    };
    if (isAll || ["org", "task"].includes(targetKindRef.current.value)) {
      body.target = "all";
    } else {
      body.targetLinks = selections;
      if (useQuery) {
        body.targetQuery = targetQueryRef.current.model.toQueryObj;
      }
    }
    return { kind: "policy", ...body };
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({ method: "post", url: homeLink("policy"), body: getPolicyObject() });
      const itemLink = resourceLink("policy", getPolicyObject().name);
      notification.success({
        message: "Success",
        description: "Policy is created",
      });
      PromptContext.setIsDisabled(true);
      navigate(`/console${itemLink}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname: nextPathname } = nextLocation;
    let res = true;
    const allowedList = ["-general", "-targets", "-tags", "-bindings"];
    for (let allowItem of allowedList) {
      if (nextPathname.includes(allowItem)) res = false;
    }
    return res;
  }

  const links: CreateFormLink[] = [{ name: "General", value: `-general`, isActive: true }];
  if (!["org", "task"].includes(targetKindRef.current.value) && !isAll) {
    links.push({
      name: pluralNameOfKind(targetKindRef.current.value),
      value: `-targets`,
      isActive: nameRef.current.isValid,
    });
    links.push({ name: "Bindings", value: `-bindings`, isActive: canAddBinding });
  } else {
    links.push({ name: "Bindings", value: `-bindings`, isActive: nameRef.current.isValid });
  }
  links.push({ name: "Tags", value: `-tags`, isActive: canCreate });

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("/-general")) {
    next.isActive = nameRef.current.isValid;
    if (!["org", "task"].includes(targetKindRef.current.value) && !isAll) {
      next.label = `Next (${pluralNameOfKind(targetKindRef.current.value)})`;
      next.url = `-targets`;
    } else {
      next.label = `Next (Bindings)`;
      next.url = `-bindings`;
    }
  } else if (pathname.includes("/-targets")) {
    next.isActive = canAddBinding;
    next.label = `Next (Bindings)`;
    next.url = `-bindings`;
  } else if (pathname.includes("/-bindings")) {
    next.isActive = canCreate;
    next.label = `Next (Tags)`;
    next.url = `-tags`;
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <CreateLayout
        getPreview={getPolicyObject}
        next={next}
        shouldBlockNavigation={shouldBlockNavigation}
        title={`Create Policy for ${pluralNameOfKind(targetKindRef.current.value)}`}
        name={nameRef.current.value}
        canCreate={canCreate && tagsRef.current.isValid}
        onConfirm={onConfirm}
        isLoading={isLoading}
        links={links}
        hideButtons={pathname.includes("-bindings") && !pathname.includes("overview")}
      >
        <Routes>
          <Route index element={<Navigate to={`-general`} />} />
          <Route
            path={`-general`}
            element={
              <PolicyCreateGeneral
                name={nameRef.current}
                description={descriptionRef.current}
                targetKind={targetKindRef.current}
                isAll={isAll}
                setIsAll={setIsAll}
              />
            }
          />
          <Route
            path={`-targets/*`}
            element={
              <TabsLayout
                links={[
                  { url: `${basePath}/-targets/-items`, label: "Items" },
                  { url: `${basePath}/-targets/-query`, label: "Query" },
                ]}
              >
                <Routes>
                  <Route index element={<Navigate to={`-items`} />} />
                  <Route
                    path={`-items`}
                    element={
                      isAll ? (
                        <Warning type={"items"} targetKind={targetKindRef.current.value} />
                      ) : (
                        <TargetTable
                          kind={targetKindRef.current.value as any}
                          selections={selections}
                          onSelectionsChange={setSelections}
                        />
                      )
                    }
                  />
                  <Route
                    path={`-query`}
                    element={
                      isAll ? (
                        <Warning type={"query"} targetKind={targetKindRef.current.value} />
                      ) : (
                        <PolicyCreateTargetQuery
                          query={targetQueryRef.current}
                          targetKind={targetKindRef.current.value}
                          useQuery={useQuery}
                          setUseQuery={setUseQuery}
                        />
                      )
                    }
                  />
                </Routes>
              </TabsLayout>
            }
          />
          <Route
            path={`-bindings/*`}
            element={
              <Routes>
                <Route index element={<Navigate to={`overview`} replace />} />
                <Route
                  path={`overview`}
                  element={
                    <PolicyCreateBindingsOverview
                      rootUrl={`${basePath}/-bindings`}
                      bindings={bindings}
                      setBindings={setBindings}
                      targetKind={targetKindRef.current.value}
                    />
                  }
                />
                <Route
                  path={`:indexString/*`}
                  element={
                    <PolicyCreateBindingDetail
                      rootUrl={`${basePath}/-bindings`}
                      bindings={bindings}
                      setBindings={setBindings}
                      targetKind={targetKindRef.current.value}
                    />
                  }
                />
              </Routes>
            }
          />
          <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
        </Routes>
      </CreateLayout>
    </BasePathContext.Provider>
  );
};

export const PolicyCreate = observer(PolicyCreateRaw);
