import * as React from "react";
import { Checkbox, notification } from "antd";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { request } from "../../../services/cpln";
import { FormElement } from "../../../components/forms/formElement";
import { FormButtons } from "../../../components/forms/formButtons";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { flowResult } from "mobx";
import { User } from "../../../mobxStores/user/user";
import { useNavigate } from "react-router-dom";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";
import NGAlert from "../../../newcomponents/alert";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGInput } from "../../../newcomponents/input/input";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { DateString } from "../../../components/dateString";
import { formatStrings } from "../../../utils/time";
import { MARKETING_URL } from "../../../envVariables";

const InfoRaw: React.FC = () => {
  const navigate = useNavigate();

  const {
    AccountUUID,
    Company,
    Phone,
    Email,
    isTouLoading,
    isTouAccepted,
    Roles,
    FullName,
    AccountName,
    Address,
    AccountIsDisabled,
  } = BillingContext;

  const fullNameRef = React.useRef(
    StringModel.create({ label: "Full Name", initialValue: FullName, isRequired: true })
  );
  const accountNameRef = React.useRef(
    StringModel.create({
      label: "Account Name",
      initialValue: AccountName,
      isRequired: true,
    })
  );
  const companyRef = React.useRef(StringModel.create({ label: "Company", initialValue: Company, isRequired: true }));
  const phoneRef = React.useRef(
    StringModel.create({
      label: "Phone",
      initialValue: Phone,
      validationKey: "phone",
      transformKey: "phone",
      isRequired: true,
    })
  );

  const [isTermsAccepted, setIsTermsAccepted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (fullNameRef.current.isDirty) {
      res = true;
    }
    if (accountNameRef.current.isDirty) {
      res = true;
    }
    if (companyRef.current.isDirty) {
      res = true;
    }
    if (phoneRef.current.isDirty) {
      res = true;
    }
    setIsDirty(res);
  }, [
    fullNameRef.current.isDirty,
    accountNameRef.current.isDirty,
    companyRef.current.isDirty,
    phoneRef.current.isDirty,
  ]);

  React.useEffect(() => {
    let res = true;
    if (!fullNameRef.current.isValid) {
      res = false;
    }
    if (!accountNameRef.current.isValid) {
      res = false;
    }
    if (!companyRef.current.isValid) {
      res = false;
    }
    if (!phoneRef.current.isValid) {
      res = false;
    }
    setIsValid(res);
  }, [
    fullNameRef.current.isValid,
    accountNameRef.current.isValid,
    companyRef.current.isValid,
    phoneRef.current.isValid,
  ]);

  function reset() {
    fullNameRef.current.reset();
    accountNameRef.current.reset();
    companyRef.current.reset();
    phoneRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const accountBody = {
        email: User.email,
        fullName: fullNameRef.current.value,
        accountName: accountNameRef.current.value || fullNameRef.current.value,
        phone: phoneRef.current.value,
        extraInfo: {
          company: companyRef.current.value,
        },
        address: Address,
      };

      await request({
        method: "put",
        service: "billing-ng",
        url: `/account/${AccountUUID}`,
        body: {
          account: accountBody,
        },
      });
      await flowResult(BillingContext.fetchAccountInfo());
      fullNameRef.current.confirm();
      accountNameRef.current.confirm();
      phoneRef.current.confirm();
      companyRef.current.confirm();
      notification.success({
        message: "Success",
        description: "Updated Account",
      });
    } catch (e) {
      console.error("Account Info", e);
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function onConfirmTou() {
    if (!isTermsAccepted) {
      return;
    }
    try {
      await request({
        method: "post",
        service: "billing-ng",
        url: `/account/${AccountUUID}/tou`,
        body: {
          tou: {
            customerName: FullName,
            accepted: isTermsAccepted,
          },
        },
      });
      await flowResult(BillingContext.fetchTou());
    } catch (e) {
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      console.error("Failed to succeed on tou", errorMessage);
      notification.warning({
        message: "Failed to accept terms of service",
        description: errorMessage,
      });
    }
  }

  const isAdmin = Roles.includes("billing_admin");

  return (
    <>
      <div key={AccountName} className={`mb-4`}>
        {AccountIsDisabled ? (
          <NGAlert
            className="mb-2"
            type={"info"}
            render={() => (
              <div>
                Your account is not activated yet. Please wait until customer support enables your account, or contact
                support at <a href={"mailto:support@controlplane.com"}>support@controlplane.com</a> if you need further
                help.
              </div>
            )}
          />
        ) : null}
        <div style={{ width: 450 }}>
          {BillingContext.isTrialAccount ? (
            <div className="flex flex-col gap-4 border rounded p-4 mb-6">
              <div className="font-bold text-lg">Trial Period</div>
              <div>You are on Trial Account, upgrade to a Paying Account to remove all limitations.</div>
              {BillingContext.TrialEnd ? (
                <div>
                  Your trial period will end on{" "}
                  <DateString
                    className="font-semibold"
                    iso={BillingContext.TrialEnd}
                    format={formatStrings.dateFullMonth}
                  />
                </div>
              ) : null}
              <NGButton
                onClick={() => navigate(`/billing/account/${BillingContext.AccountUUID}/payment-methods/-add`)}
                variant="action"
              >
                Upgrade to Paying Account
              </NGButton>
            </div>
          ) : null}
          {isAdmin && isTouAccepted === false ? (
            <div className="mb-4 pb-4 border-b">
              <NGLabel>Terms of Use Agreement</NGLabel>
              <p>You have not yet accepted the Control Plane Terms of Use.</p>
              <p className="mb-2">You cannot create organizations until you do accept.</p>
              <div className="flex items-center justify-end mb-2">
                <Checkbox
                  data-testid="switch-capacity-ai"
                  checked={isTermsAccepted}
                  onChange={(e) => setIsTermsAccepted(e.target.checked)}
                />
                <div className="ml-2">
                  I accept the Control Plane{" "}
                  <a className={`underline color-link`} target={"_blank"} href={`${MARKETING_URL}/terms-of-use`}>
                    Terms of Use
                  </a>
                </div>
              </div>
              <div className={`flex items-center justify-end`}>
                <NGButton
                  variant={"primary"}
                  loading={isTouLoading}
                  disabled={!isTermsAccepted || isTouLoading || isLoading}
                  onClick={onConfirmTou}
                >
                  Confirm
                </NGButton>
              </div>
            </div>
          ) : null}
          <div className="mb-4">
            <FormElement label={"Account Id"} value={AccountUUID} canCopy />
          </div>
          {isAdmin ? (
            <>
              <NGFormLabel required={fullNameRef.current.isRequired} invalid={!fullNameRef.current.isValid}>
                {fullNameRef.current.label}
              </NGFormLabel>
              <NGInput
                value={fullNameRef.current.value}
                onChange={(e) => fullNameRef.current.setValue(e.target.value)}
                className="mb-4"
                style={{ width: 450 }}
              />
            </>
          ) : (
            <div className="mb-4">
              <FormElement label={"Full Name"} value={FullName} />
            </div>
          )}
          {isAdmin ? (
            <>
              <NGFormLabel required={accountNameRef.current.isRequired} invalid={!accountNameRef.current.isValid}>
                {accountNameRef.current.label}
              </NGFormLabel>
              <NGInput
                value={accountNameRef.current.value}
                onChange={(e) => accountNameRef.current.setValue(e.target.value)}
                className="mb-4"
                style={{ width: 450 }}
              />
            </>
          ) : (
            <div className="mb-4">
              <FormElement label={"Account Name"} value={AccountName} />
            </div>
          )}
          <div className="mb-4">
            <FormElement label={"Email"} value={Email} />
          </div>
          {isAdmin ? (
            <>
              <NGFormLabel required={phoneRef.current.isRequired} invalid={!phoneRef.current.isValid}>
                {phoneRef.current.label}
              </NGFormLabel>
              <NGInput
                value={phoneRef.current.value}
                onChange={(e) => phoneRef.current.setValue(e.target.value)}
                className="mb-4"
                style={{ width: 450 }}
              />
            </>
          ) : (
            <div className="mb-4">
              <FormElement label={"Phone"} value={Phone} />
            </div>
          )}
          {isAdmin ? (
            <>
              <NGFormLabel required={companyRef.current.isRequired} invalid={!companyRef.current.isValid}>
                {companyRef.current.label}
              </NGFormLabel>
              <NGInput
                value={companyRef.current.value}
                onChange={(e) => companyRef.current.setValue(e.target.value)}
                className="mb-4"
                style={{ width: 450 }}
              />
            </>
          ) : (
            <div>
              <FormElement label={"Company"} value={Company} />
            </div>
          )}
          {isAdmin ? (
            <FormButtons
              onReset={reset}
              onSave={save}
              saveDisabled={isLoading || !isDirty || !isValid}
              resetDisabled={isLoading || !isDirty}
              loading={isLoading}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export const Info = observer(InfoRaw);
