import * as React from "react";

export const VolumeSnapshotsTableLabel: React.FC = () => {
  return (
    <div className="flex items-center px-4 py-2 table-labels">
      <div className="w-3/12">Name</div>
      <div className="w-2/12">Size</div>
      <div className="w-3/12">Expires (UTC)</div>
      <div className="w-2/12">Tags</div>
      <div className="w-2/12"></div>
    </div>
  );
};
