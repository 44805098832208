import * as React from "react";
import clsx from "clsx";
import "./label.css";

export interface NGLabelProps extends React.HTMLProps<HTMLLabelElement> {
  name?: string;
}

export const NGLabelText: React.FC<NGLabelProps> = ({ children, className, name, ...props }) => {
  return (
    <span className={clsx("nglabel", `nglabel-size-3`, "cursor-pointer", className)} {...props}>
      {children}
    </span>
  );
};
