import { flow, Instance, types } from "mobx-state-tree";
import { isEqual } from "lodash";
import { NGSIdentityData } from "./identity.ngs.data";
import { IdentityDraftNGSIdentityModel } from "./identity.ngs.draft";

export const IdentityDraftNGSHelperModel = types
  .model({
    saved: types.optional(NGSIdentityData, () => NGSIdentityData.create()),
    edited: types.optional(NGSIdentityData, () => NGSIdentityData.create()),

    draft: types.optional(IdentityDraftNGSIdentityModel, () => IdentityDraftNGSIdentityModel.create()),
    isSettingUp: false,
    isLoading: false,
  })
  .actions((self) => ({
    setIsSettingUpFalse() {
      self.isSettingUp = false;
    },
  }))
  .actions((self) => ({
    done() {
      self.draft.setIsActive(true);
      self.edited.apply(self.draft.asObject);
      self.setIsSettingUpFalse();
    },
    remove() {
      self.draft.clear();
      self.edited.clear();
    },
    onSave() {
      self.saved.apply(self.edited.asObject);
    },
    setup() {
      self.isSettingUp = true;
    },
  }))
  .actions((self) => {
    const cancel = flow(function* () {
      self.isLoading = true;
      if (self.edited.isActive) {
        yield self.draft.apply(self.edited.asObject);
      } else {
        self.draft.clear();
      }
      self.setIsSettingUpFalse();
      self.isLoading = false;
    });
    const reset = flow(function* () {
      if (self.saved.isActive) {
        self.edited.apply(self.saved.asObject);
        yield self.draft.apply(self.saved.asObject);
      } else {
        self.edited.clear();
        self.draft.clear();
      }
    });
    return { cancel, reset };
  })
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.saved.isActive !== self.edited.isActive) {
        res = true;
      } else if (self.saved.isActive && self.edited.isActive) {
        if (!isEqual(self.saved.asObject, self.edited.asObject)) {
          res = true;
        }
      }
      return res;
    },
  }))
  .views(() => ({
    get title() {
      return "NGS Identity";
    },
  }));
export interface IdentityDraftNGSHelper extends Instance<typeof IdentityDraftNGSHelperModel> {}
