import { notification } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useDetailContext } from "../../../components/detail/detailContext";
import { FormButtons } from "../../../components/forms/formButtons";
import { GroupMobx, IdentityMatcherLanguage } from "../../../mst/kinds/group";
import { IdentityMatcher } from "./identityMatcher";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";

interface Props {
  group: GroupMobx;
}
const IdentityMatcherDetailWrapperRaw: React.FC<Props> = ({ group }) => {
  const { fetchItem } = useDetailContext();

  function getDefaultLanguage(): IdentityMatcherLanguage {
    let defaultValue: IdentityMatcherLanguage = "jmespath";
    if (group.identityMatcher) {
      defaultValue = group.identityMatcher.language as IdentityMatcherLanguage;
    }
    return defaultValue;
  }
  const [language, setLanguage] = React.useState(getDefaultLanguage());

  function getDefaultExpression() {
    let defaultValue = "";
    if (group.identityMatcher) {
      defaultValue = group.identityMatcher.expression;
    }
    return defaultValue;
  }
  const [expression, setExpression] = React.useState(getDefaultExpression());

  const isDirty = getDefaultExpression() !== expression || getDefaultLanguage() !== language;

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, expression, language]);

  useCleanPrompt();

  function reset() {
    setExpression(getDefaultExpression());
    setLanguage(getDefaultLanguage());
  }

  async function save() {
    try {
      setIsLoading(true);
      const body: any = {};
      if (!expression) {
        body.identityMatcher = null;
      } else {
        body.identityMatcher = {
          expression,
          language,
        };
      }
      await group.patch(body);
      setLanguage(getDefaultLanguage());
      setExpression(getDefaultExpression());
      notification.success({ message: "Success", description: "Updated identity matcher" });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <IdentityMatcher
        expression={expression}
        setExpression={setExpression}
        language={language}
        setLanguage={setLanguage}
        beforeTest={
          <FormButtons
            onReset={reset}
            onSave={save}
            resetDisabled={isLoading || !isDirty}
            saveDisabled={isLoading || !isDirty}
            loading={isLoading}
          />
        }
      />
    </>
  );
};

export const IdentityMatcherDetailWrapper = observer(IdentityMatcherDetailWrapperRaw);
