import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { Mk8sCreate } from "./create";
import { Mk8sDraftManagerModel } from "../../../mst/stores/mk8s.draft.manager";

const Mk8sCreateRouteRaw: React.FC = () => {
  const draftManagerRef = React.useRef(Mk8sDraftManagerModel.create());
  const draftManager = draftManagerRef.current;
  const navigate = useNavigate();

  React.useEffect(() => {
    draftManager.startDraftCreate().catch((e) => {
      notification.warning({ message: "Failed", description: "Something went wrong, please try again." });
      console.error(e.message);
      navigate("..");
    });
  }, []);

  if (!draftManager.draft.edit) return null;

  return <Mk8sCreate mk8sDraft={draftManager.draft.edit} />;
};

export const Mk8sCreateRoute = observer(Mk8sCreateRouteRaw);
