import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const Mk8sDraftProviderTritonConnectionModel = types
  .model({
    _url: types.optional(types.string, ""),
    url: types.optional(StringModel, () => StringModel.create({ label: "URL", isRequired: true })),
    _account: types.optional(types.string, ""),
    account: types.optional(StringModel, () => StringModel.create({ label: "Account Owner", isRequired: true })),
    _user: types.optional(types.string, ""),
    user: types.optional(StringModel, () => StringModel.create({ label: "User" })),
    _tokenSecretLink: types.optional(types.string, ""),
    tokenSecretName: types.optional(types.string, ""),
  })
  .views((self) => ({
    get _tokenSecretName() {
      const { name: tokenSecretName } = ngParseLink(self._tokenSecretLink, { kind: "secret" });
      return tokenSecretName;
    },
    get tokenSecretLink() {
      const { absolute } = ngParseLink(self.tokenSecretName, { kind: "secret" });
      return absolute;
    },
  }))
  .actions((self) => ({
    setTokenSecretName(value: string) {
      self.tokenSecretName = value;
    },
    reset() {
      self.url.setInitialValue(self._url);
      self.account.setInitialValue(self._account);
      self.user.setInitialValue(self._user);
      self.tokenSecretName = self._tokenSecretName;
    },
    confirm() {
      self.url.confirm();
      self.account.confirm();
      self.user.confirm();
      self._tokenSecretLink = self.tokenSecretLink;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.url.isValid) res = false;
      if (!self.account.isValid) res = false;
      if (!self.user.isValid) res = false;
      if (!self.tokenSecretName) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.url.isValid) reason = "url is invalid";
      if (!self.account.isValid) reason = "account is invalid";
      if (!self.user.isValid) reason = "user is invalid";
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.url.isDirty) res = true;
      if (self.account.isDirty) res = true;
      if (self.user.isDirty) res = true;
      if (self.tokenSecretName !== self._tokenSecretName) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.url.isDirty) reason = self.url.isDirtyReason;
      if (self.account.isDirty) reason = self.account.isDirtyReason;
      if (self.user.isDirty) reason = self.user.isDirtyReason;
      if (self.tokenSecretName !== self._tokenSecretName) reason = "token";
      return reason;
    },
    get asObject() {
      const obj: any = {
        url: self.url.value,
        account: self.account.value,
        privateKeySecretLink: self.tokenSecretLink,
      };
      if (self.user.value) {
        obj.user = self.user.value;
      }
      return obj;
    },
  }));
