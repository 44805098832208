import { TableColumn } from "../../types";

const originLabelMap = {
  default: "Default",
  builtin: "Builtin",
  custom: "Custom",
  "": "",
};
const originDefault = { id: "origin", label: "Origin" };
export function OriginColumn<TData extends { origin?: string }>(column: TableColumn<TData> = originDefault) {
  const preset: TableColumn<TData> = {
    ...originDefault,
    canGroupByDistinctValues: true,
    enableResize: false,
    enableHide: true,
    groupLabelMap: originLabelMap,
    groupOrder: ["builtin", "default", "custom"],
    size: 170,
    cell: (p) => {
      const item = p.row.original;
      return <span>{originLabelMap[item.origin || ""]}</span>;
    },
  };
  return Object.assign(preset, column);
}
