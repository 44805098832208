import * as React from "react";
import { useParams } from "react-router-dom";
import OrgDetail from "./detail";
import { OrgMobx, OrgModel } from "../../mst/kinds/org";
import { observer } from "mobx-react-lite";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { IncorrectOrg } from "../../components/generic/IncorrectItem/incorrectOrg";
import { AccountSummary } from "../../mobxStores/userData/userData";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { captureExc } from "../../errorBoundary";

type RouteParams = "org";
const OrgDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());

  const { org: orgName } = useParams<RouteParams>();
  const [org, setOrg] = React.useState<OrgMobx>(null as any);
  const [account, setAccount] = React.useState<AccountSummary | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    fetchItem();
    updateAccount();
  }, [orgName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("org", orgName) });
      setOrg(OrgModel.create(data));
      setError("");
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
    }
  }

  async function updateAccount() {
    try {
      const { data } = await request({ service: "billing-ng", url: `/org/${orgName}/account` });
      setAccount({
        id: data.id,
        fullName: data.fullName,
        accountName: data.accountName,
        company: data.extraInfo?.company,
      });
    } catch (e) {
      setAccount(undefined);
    }
  }

  if (error) {
    return <IncorrectOrg message={error} />;
  }

  if (isLoading) return <Loader fullScreen reason="fetching org to show detail" />;

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <DetailContext.Provider value={{ item: org, fetchItem }}>
        <OrgDetail org={org} account={account} updateAccount={updateAccount} />
      </DetailContext.Provider>
    </NGFormContext.Provider>
  );
};

export const OrgDetailRoute = observer(OrgDetailRouteRaw);
