import { types, Instance, flow } from "mobx-state-tree";
import { BrowserServiceModel } from "../../../mst/kinds/browser";
import { ngParseLink } from "../../../utils/linkParser/linkParser";

export const OrgLogsBrowseModel = types
  .model({
    browserService: types.optional(BrowserServiceModel, () => BrowserServiceModel.create()),
    page: types.optional(types.enumeration(["none", "cloudaccount", "bucket"]), "none"),
    cloudaccountName: types.optional(types.string, ""),
    isCheckingReach: types.optional(types.boolean, false),
    isPreparing: types.optional(types.boolean, false),
    prepareError: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setPage(value: "none" | "cloudaccount" | "bucket") {
      self.page = value;
    },
  }))
  .actions((self) => {
    const prepare = flow(function* () {
      try {
        self.isPreparing = true;
        self.prepareError = "";
        const browserService = self.browserService;
        yield browserService.fetchServices();

        const service_s3 = browserService.servicesSection.items.find((service) => service.href.includes("/s3"));
        if (!service_s3) {
          self.isPreparing = false;
          self.prepareError = "No S3 service is found";
          return;
        }
        browserService.servicesSection.setSelectedHref(service_s3.href);

        yield browserService.fetchRegions();
        const region_global = browserService.regionsSection.items.find((service) => service.href.includes("/global"));
        if (!region_global) {
          self.isPreparing = false;
          self.prepareError = "No global region is found";
          return;
        }
        browserService.regionsSection.setSelectedHref(region_global.href);
        yield browserService.fetchTypes();

        const type_bucket = browserService.typesSection.items.find((service) => service.href.includes("/bucket"));
        if (!type_bucket) {
          self.isPreparing = false;
          self.prepareError = "No bucket type is found";
          return;
        }
        browserService.typesSection.setSelectedHref(type_bucket.href);

        yield browserService.fetchDataItems();
        self.isPreparing = false;
        self.prepareError = "";
        self.setPage("bucket");
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        self.prepareError = errorMessage;
        self.isPreparing = false;
      }
    });
    return { prepare };
  })
  .actions((self) => {
    const setCloudaccountName = flow(function* (name: string) {
      self.prepareError = "";
      self.cloudaccountName = name;
      if (self.cloudaccountName) {
        const { absolute: cloudaccountLink } = ngParseLink(self.cloudaccountName, { kind: "cloudaccount" });
        self.browserService.setCloudaccountLink(cloudaccountLink);
        self.isCheckingReach = true;
        yield self.browserService.checkCanReach();
        self.isCheckingReach = false;
      } else {
        self.browserService.clearReachError();
      }
    });
    return { setCloudaccountName };
  })
  .actions((self) => ({
    done() {
      self.setPage("none");
    },
  }));
export interface OrgLogsBrowseMobx extends Instance<typeof OrgLogsBrowseModel> {}
