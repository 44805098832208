import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { WithTagsModel, WithLinksModel, WithCreatedAndLastModifiedModel, KindProperty } from "../base";
import { request } from "../../services/cpln";

export const UserModel = types
  .compose(
    "User",
    WithCreatedAndLastModifiedModel,
    WithTagsModel,
    WithLinksModel,
    types.model({
      id: types.identifier,
      name: types.optional(types.string, ""),
      kind: KindProperty,
      version: types.refinement(types.number, (value) => value > 0),
      idp: types.optional(types.string, ""),
      email: types.optional(types.string, ""),
    })
  )
  .views((self) => ({
    // to satisfiy table item data interface
    get description() {
      return self.name;
    },
  }))
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface UserMobx extends Instance<typeof UserModel> {}
