import * as React from "react";
import { Audit } from "./types";
import ReactDiff from "react-diff-viewer";
import { observer } from "mobx-react-lite";
import { X, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "react-feather";
import { Theme } from "../../mobxStores/uiData/theme";
import { getAuditSubject } from "./utils";
import jsYaml from "js-yaml";
import { UserPreferences } from "../../mobxStores/userData/userPreferences";
import { NGButton } from "../../newcomponents/button/Button";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { NGLabelText } from "../../newcomponents/text/labelText";

interface Props {
  audits: Audit[];
  onClose: () => void;
}
const AuditTrailDiffViewRaw: React.FC<Props> = ({ audits, onClose }) => {
  const { theme } = Theme;
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const previousIndex = currentIndex + 1;

  function bringOlder() {
    setCurrentIndex(Math.min(currentIndex + 1, audits.length - 2));
  }

  function bringOldest() {
    setCurrentIndex(audits.length - 2);
  }

  function bringNewer() {
    setCurrentIndex(Math.max(currentIndex - 1, 0));
  }

  function bringNewest() {
    setCurrentIndex(0);
  }

  const canBringOlder = currentIndex < audits.length - 2;
  const canBringNewer = currentIndex > 0;
  const isOldest = currentIndex === audits.length - 2;
  const isNewest = currentIndex === 0;

  const currentAudit = audits[currentIndex];
  const current = currentAudit.resource.data;
  const previous = audits[previousIndex].resource.data;
  const subject = getAuditSubject(currentAudit);

  let title = "";
  if (previous.version && current.version) {
    title = `Version ${previous.version} - Version ${current.version}`;
  } else if (current.version) {
    title = `Version ${current.version}`;
  } else if (previous.version) {
    title = `Version ${previous.version}`;
  }

  return (
    <>
      <div className="flex items-center mb-4 gap-4">
        <NGButton
          variant={"secondary"}
          outlined
          className="basis-0 flex-grow"
          onClick={() => onClose()}
          renderIcon={(_, props) => <X {...props} />}
        >
          Close Diff View
        </NGButton>
        <NGButton
          variant={"primary"}
          className="basis-0 flex-grow"
          disabled={isOldest}
          onClick={bringOldest}
          renderIcon={(_, props) => <ChevronsLeft {...props} />}
          iconPosition={"left"}
        >
          Oldest
        </NGButton>
        <NGButton
          variant={"primary"}
          className="basis-0 flex-grow"
          disabled={!canBringOlder}
          onClick={bringOlder}
          renderIcon={(_, props) => <ChevronLeft {...props} />}
          iconPosition={"left"}
        >
          Previous
        </NGButton>
        <NGButton
          variant={"primary"}
          className="basis-0 flex-grow"
          disabled={!canBringNewer}
          onClick={bringNewer}
          renderIcon={(_, props) => <ChevronRight {...props} />}
        >
          Next
        </NGButton>
        <NGButton
          variant={"primary"}
          className="basis-0 flex-grow"
          disabled={isNewest}
          onClick={bringNewest}
          renderIcon={(_, props) => <ChevronsRight {...props} />}
        >
          Newest
        </NGButton>
      </div>
      <div className="text-sm diff-container">
        <div className="flex justify-between mb-4 text-base text-normal">
          <div className="flex gap-6">
            <div>{title}</div>
            <NGCheckbox
              checked={UserPreferences.useSplitViewComparison}
              onChange={(value) => UserPreferences.setUseSplitViewComparison(value)}
            >
              <NGLabelText>Use Split View</NGLabelText>
            </NGCheckbox>
          </div>
          <div>{subject.value}</div>
        </div>
        <div className="border" style={{ overflow: "auto" }}>
          <ReactDiff
            splitView={UserPreferences.useSplitViewComparison}
            oldValue={jsYaml.dump(previous, { indent: 2 })}
            newValue={jsYaml.dump(current, { indent: 2 })}
            useDarkTheme={theme === "dark"}
          />
        </div>
      </div>
    </>
  );
};

export const AuditTrailDiffView = observer(AuditTrailDiffViewRaw);
