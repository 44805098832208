import { Instance, types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";

export const WorkloadDraftSecurityOptionsModel = types
  .model({
    _filesystemGroupId: types.maybe(types.number),
    filesystemGroupId: types.optional(StringModel, () =>
      NumberModel.create({ label: "File System Group ID", min: 1, max: 65534, allowDot: false }),
    ),
  })
  .actions((self) => ({
    reset() {
      self.filesystemGroupId.setInitialValue(String(self._filesystemGroupId || ""));
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._filesystemGroupId = Number(self.filesystemGroupId.value);
      self.filesystemGroupId.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.filesystemGroupId.isDirty) res = true;
      return res;
    },
    get isDirtyReason() {
      let reason = "";
      if (self.filesystemGroupId.isDirty) reason = "filesystemGroupId";
      return reason;
    },
    get isValid() {
      let res = true;
      if (!self.filesystemGroupId.isValid) res = false;
      return res;
    },
    get asObject() {
      const obj: any = {};
      if (!!self.filesystemGroupId.value) {
        obj.filesystemGroupId = Number(self.filesystemGroupId.value);
      }
      return obj;
    },
  }));
export interface WorkloadDraftSecurityOptionsMobx extends Instance<typeof WorkloadDraftSecurityOptionsModel> {}
