import * as React from "react";
import { Dropdown } from "antd";
import { observer } from "mobx-react-lite";
import { DryRunIssue, DryRunResponse } from "../../services/utils";
import { NGButton } from "../../newcomponents/button/Button";
import NGAlert from "../../newcomponents/alert";
import { ChevronDown } from "react-feather";

interface Props {
  dryRunResponse: DryRunResponse;
  path: string;
  paths?: string[];
  onFix?: (value: string) => void;
  canShow: boolean;
}

const DryRunAlertRaw: React.FC<Props> = ({ dryRunResponse: response, path, paths = [], canShow, onFix }) => {
  let issues: DryRunIssue[] = response?.details?.issues || [];

  let filteredIssues = (issues || []).filter((i) => {
    let p = i.path;
    if (!p) {
      return false;
    }
    return p === path || paths.includes(p);
  });

  if (filteredIssues.length < 1) {
    return null;
  }

  const issue = filteredIssues[0];

  let hasRecommendation = false;
  let hasMultipleRecommendations = false;
  if (Array.isArray(issue.recommendation) && issue.recommendation.length > 0) {
    hasRecommendation = true;
    hasMultipleRecommendations = true;
  } else if (!!issue.recommendation) {
    hasRecommendation = true;
  }

  if (!canShow) {
    return null;
  }

  return (
    <>
      <NGAlert
        type={(issue.severity || "error") as any}
        render={() => (
          <div>
            <span>{issue.message}</span>
            {hasRecommendation && !!onFix && hasMultipleRecommendations ? (
              <Dropdown
                trigger={["click"]}
                menu={{
                  onClick: async function handleActionsClick({ key }: any) {
                    onFix(String(issue.recommendation![key]));
                  },
                  items: (issue.recommendation as string[]).map((recommendation: any) => ({
                    key: String(recommendation),
                    label: recommendation,
                  })),
                }}
              >
                <NGButton
                  className="ml-2"
                  size="small"
                  variant={"primary"}
                  renderIcon={(_, props) => <ChevronDown {...props} />}
                >
                  Fix
                </NGButton>
              </Dropdown>
            ) : hasRecommendation && !!onFix && !hasMultipleRecommendations ? (
              <NGButton
                className="ml-2"
                size="small"
                variant="primary"
                onClick={() => onFix(String(issue.recommendation))}
              >
                Fix
              </NGButton>
            ) : null}
          </div>
        )}
      />
    </>
  );
};

export const DryRunAlert = observer(DryRunAlertRaw);
