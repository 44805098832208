import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from "../../../../components/Tooltip";
import { Binding } from "../../../../schema/types/policy";

interface Props {
  binding: Binding;
}
const BindingTableRaw: React.FC<Props> = ({ binding }) => {
  function userLinks() {
    return binding.principalLinks.filter((l) => l.includes("/user/"));
  }

  function groupLinks() {
    return binding.principalLinks.filter((l) => l.includes("/group/"));
  }

  function serviceaccountLinks() {
    return binding.principalLinks.filter((l) => l.includes("/serviceaccount/"));
  }

  function identityLinks() {
    return binding.principalLinks.filter((l) => l.includes("/identity/"));
  }

  return (
    <div className="border p-4 rounded">
      <div className="-mt-4 py-3 -mx-4 px-4 -mb-2 flex flex-col bg-table-label-drop text-table-label-text">
        <span className={"text-2xl font-extralight"}>Permissions</span>
        <span className="leading-4 font-semibold">{binding.permissions.join(", ")}</span>
      </div>
      {userLinks().length > 0 ? (
        <div className="border-t -mx-4 px-4 mt-2">
          <div className="mt-2 text-lg">Users</div>
          <div className="flex flex-wrap text-sm font-medium">
            {userLinks().map((userLink) => (
              <div className="w-3/12 truncate pr-2">
                <Tooltip title={userLink.split("/")[4]} open={userLink.split("/")[4].length >= 25}>
                  <span>{userLink.split("/")[4]}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {groupLinks().length > 0 ? (
        <div className="border-t -mx-4 px-4 mt-2">
          <div className="mt-2 text-lg">Groups</div>
          <div className="flex flex-wrap text-sm font-medium">
            {groupLinks().map((groupLink) => (
              <div className="w-3/12 truncate pr-2">
                <Tooltip title={groupLink.split("/")[4]} open={groupLink.split("/")[4].length >= 25}>
                  <span>{groupLink.split("/")[4]}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {serviceaccountLinks().length > 0 ? (
        <div className="border-t -mx-4 px-4 mt-2">
          <div className="mt-2 text-lg">Service Accounts</div>
          <div className="flex flex-wrap text-sm font-medium">
            {serviceaccountLinks().map((serviceaccountLink) => (
              <div className="w-3/12 truncate pr-2">
                <Tooltip title={serviceaccountLink.split("/")[4]} open={serviceaccountLink.split("/")[4].length >= 25}>
                  <span>{serviceaccountLink.split("/")[4]}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {identityLinks().length > 0 ? (
        <div className="border-t -mx-4 px-4 mt-2">
          <div className="mt-2 text-lg">Identities</div>
          <div className="flex flex-wrap text-sm font-medium">
            {identityLinks().map((identityLink) => (
              <div className="w-3/12 truncate pr-2">
                <Tooltip title={identityLink.split("/")[6]} open={identityLink.split("/")[6].length >= 25}>
                  <span>{identityLink.split("/")[6]}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const BindingTable = observer(BindingTableRaw);
