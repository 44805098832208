import { notification } from "antd";
import * as React from "react";
import { Copy } from "react-feather";

interface Props {
  content: string;
}
export const CopyButton: React.FC<Props> = ({ content }) => {
  function handleCopyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({ message: "Copied to clipboard" });
  }

  return (
    <button className="color-link ngfocus" onClick={() => handleCopyToClipboard(content)}>
      <Copy className="h-4" />
    </button>
  );
};
