import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { DetailNavLink } from "../../components/detail/nav";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { IpSet } from "../../schema/types/ipset";
import { linksOf } from "../../services/cpln";
import { Spec } from "./spec";
import { Tags } from "../../components/detail/tags";

interface Props {
  ipset: IpSet;
}
const IpSetDetailRaw: React.FC<Props> = ({ ipset }) => {
  const basePath = useBasePath("/ipset/:ipset/*");

  const eventlogHook = useEventlogs(linksOf(ipset).self!);

  const [links] = React.useState<DetailNavLink[]>([
    { label: "Info", url: "-info" },
    { label: "Spec", url: "-spec" },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ]);

  return (
    <>
      <BasePathContext.Provider value={basePath}>
        <DetailLayout links={links}>
          <Route path={`-info`} element={<Info ipset={ipset} />} />
          <Route path={`-spec`} element={<Spec ipset={ipset} />} />
          <Route path={`-tags`} element={<Tags link={linksOf(ipset).self!} tags={ipset.tags} />} />
          <Route
            path={`-events`}
            element={
              <Events
                kind={"ipset"}
                eventlogs={eventlogHook.eventlogs}
                isLoading={eventlogHook.isLoading}
                fetchEventlogs={eventlogHook.fetchEventlogs}
              />
            }
          />
          <Route path={`-audittrail`} element={<AuditTrail kind="ipset" resourceId={ipset.id} />} />
          <Route path={`-accessreport`} element={<AccessReport itemLink={linksOf(ipset).self!} />} />
        </DetailLayout>
      </BasePathContext.Provider>
    </>
  );
};

export const IpSetDetail = observer(IpSetDetailRaw);
