import * as React from "react";
import { observer } from "mobx-react-lite";
import { FormLabel } from "../forms/formLabel";
import { notification } from "antd";
import { ItemStoreQueryMobx, QueryModel, QueryTerm } from "../../mst/query";
import { NoTag } from "../tag/noTag";
import { RemovableTag } from "../tag/removableTag";
import { RemovableValue } from "../tag/removableValue";
import { StringModel } from "../../mobxDataModels/stringModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { Kind } from "../../mst/base";
import { homeLink, pluralNameOfKind, request } from "../../services/cpln";
import { OPERATORS_REQUIRING_TWO_VALUES, queryProperties } from "../../services/utils";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import { NGButton } from "../../newcomponents/button/Button";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { NGLabel } from "../../newcomponents/text/label";
import { NGSwitch } from "../../newcomponents/switch";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { InfoTooltip } from "../InfoTooltip";
import { Table } from "../../newcomponents/table/table";
import { NameDescriptionNoLinkColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { QueryTableItem } from "../../newcomponents/table/components/TableCplnQuery";
import { Edit2, MinusCircle, X } from "react-feather";
import { NGInput } from "../../newcomponents/input/input";

interface Props {
  idPrefix: string;
  kind: Kind;
  query: ItemStoreQueryMobx;
  useQuery: boolean;
  setUseQuery: (value: boolean) => void;
  evaluatedItemFn: (item: any) => any;
  onReset?: () => void;
  hasCustomColumns?: boolean;
}

const QueryUIRaw: React.FC<Props> = ({
  idPrefix,
  kind,
  query,
  useQuery,
  setUseQuery,
  onReset = null,
  evaluatedItemFn,
  hasCustomColumns = false,
}) => {
  const [evaluatedItems, setEvaluatedItems] = React.useState([]);
  const [isEvaluated, setIsEvaluated] = React.useState(false);
  const [isQueryLoading, setIsQueryLoading] = React.useState(false);
  const [updateId, setUpdateId] = React.useState<string | undefined>(undefined);

  const allowedProperties: string[] = (queryProperties as any)[kind] || [];
  const hasAllowedProperties = allowedProperties.length > 0;

  // Refs
  const propertyKeyRef = React.useRef(StringModel.create({ label: "Property Key" }));
  const propertyValueRef = React.useRef(StringModel.create({ label: "Property Value" }));
  const relKeyRef = React.useRef(StringModel.create({ label: "Rel Key" }));
  const relValueRef = React.useRef(StringModel.create({ label: "Rel Value" }));
  const tagKeyRef = React.useRef(StringModel.create({ label: "Tag Key" }));
  const tagValueRef = React.useRef(StringModel.create({ label: "Tag Value" }));
  const operatorRef = React.useRef(
    SelectModel.create({
      label: "Op",
      initialValue: "=",
      options: [
        { label: "Equals", value: "=" },
        { label: "Not Equals", value: "!=" },
        { label: "Exists", value: "!!" },
        { label: "Not Exists", value: "!" },
        { label: "Contains", value: "~" },
        { label: "Greater", value: ">" },
        { label: "Greater or Equals", value: ">=" },
        { label: "Less", value: "<" },
        { label: "Less or Equals", value: "<=" },
      ],
    }),
  );
  const operatorRequiresTwoValues = OPERATORS_REQUIRING_TWO_VALUES.includes(operatorRef.current.value);
  const propertyKeySelectRef = React.useRef(
    SelectModel.create({
      label: "Property Key",
      options: allowedProperties.map((p) => ({ label: p, value: p })),
      initialValue: allowedProperties[0] || "",
    }),
  );
  const termRef = React.useRef(
    SelectModel.create({
      label: "Term",
      initialValue: "tag",
      options: [
        { label: "Tag", value: "tag" },
        { label: "Property", value: "property" },
        { label: "Rel", value: "rel" },
      ],
    }),
  );

  const isActionDisabled = getActionDisabled();
  const isQueryDirty = getIsQueryDirty();

  // Effects
  React.useEffect(() => {
    applySnapshot(query.previousModel, getSnapshot(query.model));

    if (useQuery && query.model.spec.terms.length > 0) {
      evaluate();
    }
  }, []);

  React.useEffect(() => {
    const ids = query.model.spec.terms.map((term) => term.id);
    if (updateId && !ids.includes(updateId)) {
      setUpdateId(undefined);
      termRef.current.reset();
      formReset();
    }
  }, [query.model.spec.terms.length]);

  // Functions
  function addTerm() {
    switch (termRef.current.value) {
      case "tag":
        if (tagKeyRef.current.value.length < 1) return;
        if (operatorRequiresTwoValues && tagValueRef.current.value.length < 1) return;
        query.model.spec.addTagTerm(
          tagKeyRef.current.value,
          operatorRef.current.value as any,
          tagValueRef.current.value,
        );
        tagKeyRef.current.reset();
        tagValueRef.current.reset();
        break;
      case "property":
        if (hasAllowedProperties) {
          if (propertyKeySelectRef.current.value.length < 1) return;
          if (operatorRequiresTwoValues && propertyKeySelectRef.current.value.length < 1) return;
          query.model.spec.addPropertyTerm(
            propertyKeySelectRef.current.value,
            operatorRef.current.value as any,
            propertyValueRef.current.value,
          );
        } else {
          if (propertyKeyRef.current.value.length < 1) return;
          if (operatorRequiresTwoValues && propertyValueRef.current.value.length < 1) return;
          query.model.spec.addPropertyTerm(
            propertyKeyRef.current.value,
            operatorRef.current.value as any,
            propertyValueRef.current.value,
          );
        }
        propertyKeySelectRef.current.reset();
        propertyKeyRef.current.reset();
        propertyValueRef.current.reset();
        break;
      case "rel":
        if (relKeyRef.current.value.length < 1) return;
        if (operatorRequiresTwoValues && relValueRef.current.value.length < 1) return;
        query.model.spec.addRelTerm(
          relKeyRef.current.value,
          operatorRef.current.value as any,
          relValueRef.current.value,
        );
        relKeyRef.current.reset();
        relValueRef.current.reset();
        break;
    }
    termRef.current.reset();
    formReset();
  }

  async function evaluate() {
    try {
      setIsQueryLoading(true);
      const { data } = await request({ method: "post", url: `${homeLink(kind)}/-query`, body: query.model.toQueryObj });
      setEvaluatedItems(data.items);
      setIsQueryLoading(false);
      setIsEvaluated(true);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to evaluate query",
        description: errorMessage,
      });
      setIsQueryLoading(false);
    }
  }

  function getActionDisabled() {
    let res = false;
    switch (termRef.current.value) {
      case "tag":
        if (tagKeyRef.current.value.length < 1 || (operatorRequiresTwoValues && tagValueRef.current.value.length < 1)) {
          res = true;
        }
        break;
      case "property":
        if (
          (hasAllowedProperties && propertyKeySelectRef.current.value.length < 1) ||
          (!hasAllowedProperties && propertyKeyRef.current.value.length < 1) ||
          (operatorRequiresTwoValues && propertyValueRef.current.value.length < 1)
        ) {
          res = true;
        }
        break;
      case "rel":
        if (relKeyRef.current.value.length < 1 || (operatorRequiresTwoValues && relValueRef.current.value.length < 1)) {
          res = true;
        }
        break;
    }
    return res;
  }

  function getIsQueryDirty(): boolean {
    if (
      query.previousModel.spec.terms.length < 1 &&
      query.model.spec.terms.length < 1 &&
      query.model.spec.sort.by === ""
    ) {
      return false;
    }
    let res = false;
    if (query.model.spec.match !== query.previousModel.spec.match) res = true;
    if (query.model.spec.terms.length !== query.previousModel.spec.terms.length) res = true;
    if (query.model.spec.sort.by !== query.previousModel.spec.sort.by) res = true;
    if (query.model.spec.sort.order !== query.previousModel.spec.sort.order) res = true;
    for (let term of query.model.spec.terms) {
      const terms = query.previousModel.spec.terms;
      if (!terms.some((t) => t.tag === term.tag && t.op === term.op && t.value === term.value)) {
        res = true;
      }
    }
    for (let term of query.previousModel.spec.terms) {
      const terms = query.model.spec.terms;
      if (!terms.some((t) => t.tag === term.tag && t.op === term.op && t.value === term.value)) {
        res = true;
      }
    }
    return res;
  }

  function formReset() {
    tagKeyRef.current.reset();
    tagValueRef.current.reset();
    propertyKeyRef.current.reset();
    propertyKeySelectRef.current.reset();
    propertyValueRef.current.reset();
    relKeyRef.current.reset();
    relValueRef.current.reset();
  }

  function onUpdateTerm(term: QueryTableItem) {
    setUpdateId(term.id);
    termRef.current.setValue(term.term);
    operatorRef.current.setValue(term.operator);

    switch (term.term) {
      case "property":
        if (hasAllowedProperties) {
          propertyKeySelectRef.current.setValue(term.key as string);
        } else {
          propertyKeyRef.current.setValue(term.key as string);
        }
        propertyValueRef.current.setValue(term.value as string);
        break;
      case "rel":
        relKeyRef.current.setValue(term.key as string);
        relValueRef.current.setValue(term.value as string);
        break;
      case "tag":
        tagKeyRef.current.setValue(term.key as string);
        tagValueRef.current.setValue(term.value as string);
        break;
    }
  }

  function updateTerm() {
    if (!updateId) return;

    const updatedTerm: Partial<QueryTerm> = {
      op: operatorRef.current.value as "=" | ">" | ">=" | "<" | "<=" | "!=" | "exists" | "!exists" | "~",
    };

    switch (termRef.current.value) {
      case "tag":
        updatedTerm.tag = tagKeyRef.current.value;
        updatedTerm.value = tagValueRef.current.value;
        break;
      case "property":
        updatedTerm.value = propertyValueRef.current.value;
        if (hasAllowedProperties) {
          updatedTerm.property = propertyKeySelectRef.current.value;
        } else {
          updatedTerm.property = propertyKeyRef.current.value;
        }
        break;
      case "rel":
        updatedTerm.rel = relKeyRef.current.value;
        updatedTerm.value = relValueRef.current.value;
        break;
    }

    if (operatorRef.current.value === "!!") {
      updatedTerm.op = "exists";
    } else if (operatorRef.current.value === "!") {
      updatedTerm.op = "!exists";
    }

    termRef.current.reset();
    formReset();
    query.model.spec.updateTerm(updateId, updatedTerm);
    setUpdateId(undefined);
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <NGSwitch onChange={(val) => setUseQuery(val)} value={useQuery}>
          <NGLabelText>Use Query</NGLabelText>
        </NGSwitch>
      </div>
      {useQuery ? (
        <>
          <div className="flex flex-col">
            <div className="flex items-center mb-2">
              <NGLabel>Match Terms By</NGLabel>
              <InfoTooltip
                style={{ color: "var(--color-label)" }}
                title={[
                  `'All' means all terms should match`,
                  `'Any' means any of the terms should match`,
                  `'None' means none of these terms should match`,
                ]}
              />
            </div>
            <div className="flex items-center">
              <NGButton
                size={"small"}
                variant={query.model.spec.match === "all" ? "primary" : "secondary"}
                onClick={() => query.model.spec.setMatch("all")}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              >
                All
              </NGButton>
              <NGButton
                size={"small"}
                variant={query.model.spec.match === "any" ? "primary" : "secondary"}
                onClick={() => query.model.spec.setMatch("any")}
                style={{ borderRadius: 0 }}
              >
                Any
              </NGButton>
              <NGButton
                size={"small"}
                variant={query.model.spec.match === "none" ? "primary" : "secondary"}
                onClick={() => query.model.spec.setMatch("none")}
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              >
                None
              </NGButton>
            </div>
          </div>
          <NGLabel className="mt-4">Terms</NGLabel>
          <Table<QueryTableItem>
            hideSettings
            data={[
              ...query.model.spec.tagTerms.map((i) => ({
                id: i.id,
                term: "tag",
                key: i.tag || "",
                operator: i.op || "<",
                value: (i.value as string | undefined) || "",
              })),
              ...query.model.spec.propertyTerms.map((i) => ({
                id: i.id,
                term: "property",
                key: i.property || "",
                operator: i.op || "<",
                value: (i.value as string | undefined) || "",
              })),
              ...query.model.spec.relTerms.map((i) => ({
                id: i.id,
                term: "rel",
                key: i.rel || "",
                operator: i.op || "<",
                value: (i.value as string | undefined) || "",
              })),
            ]}
            tableId={"query"}
            columns={[
              {
                id: "term",
                label: "Term",
                size: 100,
                cell: (cellProps) => {
                  const value = cellProps.row.original.term;
                  if (!value) return value;
                  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
                },
              },
              {
                id: "key",
                label: "Key",
              },
              {
                id: "operator",
                label: "Operator",
                size: 100,
              },
              {
                id: "value",
                label: "Value",
              },
              {
                id: "actions",
                label: "Actions",
                size: 120,
                cell: (cellProps) => (
                  <div className="flex gap-2 py-1">
                    <NGButton
                      variant="secondary"
                      text
                      renderIcon={(_, props) => <Edit2 {...props} />}
                      onClick={() => onUpdateTerm(cellProps.row.original)}
                    />
                    <NGButton
                      variant="danger"
                      text
                      renderIcon={(_, props) => <MinusCircle {...props} />}
                      onClick={() => query.model.spec.removeTerm(cellProps.row.original.id)}
                    />
                  </div>
                ),
              },
            ]}
          />
          <NGLabel className="mt-4 mb-2">{updateId ? "Editing" : "Add"} Term</NGLabel>
          <div className="flex gap-2">
            <NGSelect
              className="basis-0 flex-grow"
              placeholder={termRef.current.label}
              onChange={(value) => {
                termRef.current.setValue(value);
                formReset();
              }}
              options={termRef.current.options}
              value={termRef.current.value}
              style={{ width: 120, minWidth: 120, maxWidth: 120 }}
            />
            {termRef.current.value === "tag" ? (
              <>
                <NGInput
                  className="basis-0 flex-grow"
                  placeholder={tagKeyRef.current.label}
                  value={tagKeyRef.current.value}
                  onChange={(e) => tagKeyRef.current.setValue(e.target.value)}
                />
                <NGSelect
                  className="basis-0 flex-grow"
                  placeholder={operatorRef.current.label}
                  onChange={(value) => operatorRef.current.setValue(value)}
                  options={operatorRef.current.options}
                  value={operatorRef.current.value}
                />
                {operatorRequiresTwoValues && (
                  <NGInput
                    className="basis-0 flex-grow"
                    placeholder={tagValueRef.current.label}
                    value={tagValueRef.current.value}
                    onChange={(e) => tagValueRef.current.setValue(e.target.value)}
                  />
                )}
              </>
            ) : null}
            {termRef.current.value === "property" ? (
              <>
                {hasAllowedProperties ? (
                  <NGSelect
                    className="basis-0 flex-grow"
                    placeholder={propertyKeySelectRef.current.label}
                    onChange={(value) => propertyKeySelectRef.current.setValue(value)}
                    options={propertyKeySelectRef.current.options}
                    value={propertyKeySelectRef.current.value}
                  />
                ) : (
                  <NGInput
                    className="basis-0 flex-grow"
                    placeholder={propertyKeyRef.current.label}
                    value={propertyKeyRef.current.value}
                    onChange={(e) => propertyKeyRef.current.setValue(e.target.value)}
                  />
                )}
                <NGSelect
                  className="basis-0 flex-grow"
                  placeholder={operatorRef.current.label}
                  onChange={(value) => operatorRef.current.setValue(value)}
                  options={operatorRef.current.options}
                  value={operatorRef.current.value}
                />
                {operatorRequiresTwoValues && (
                  <NGInput
                    className="basis-0 flex-grow"
                    placeholder={propertyValueRef.current.label}
                    value={propertyValueRef.current.value}
                    onChange={(e) => propertyValueRef.current.setValue(e.target.value)}
                  />
                )}
              </>
            ) : null}
            {termRef.current.value === "rel" ? (
              <>
                <NGInput
                  className="basis-0 flex-grow"
                  placeholder={relKeyRef.current.label}
                  value={relKeyRef.current.value}
                  onChange={(e) => relKeyRef.current.setValue(e.target.value)}
                />
                <NGSelect
                  className="basis-0 flex-grow"
                  placeholder={operatorRef.current.label}
                  onChange={(value) => operatorRef.current.setValue(value)}
                  options={operatorRef.current.options}
                  value={operatorRef.current.value}
                />
                {operatorRequiresTwoValues && (
                  <NGInput
                    className="basis-0 flex-grow"
                    placeholder={relValueRef.current.label}
                    value={relValueRef.current.value}
                    onChange={(e) => relValueRef.current.setValue(e.target.value)}
                  />
                )}
              </>
            ) : null}
            <NGButton
              disabled={isActionDisabled}
              variant={updateId ? "primary" : "action"}
              outlined
              onClick={updateId ? updateTerm : addTerm}
              className={`w-16`}
            >
              {updateId ? "Save" : "Add"}
            </NGButton>
            {updateId && (
              <NGButton
                variant="secondary"
                outlined
                renderIcon={(_, props) => <X {...props} />}
                onClick={() => {
                  setUpdateId(undefined);
                  termRef.current.reset();
                  formReset();
                }}
              />
            )}
          </div>
          <div className="flex mt-8 mb-4">
            {onReset ? (
              <NGButton
                disabled={!isQueryDirty || isQueryLoading}
                style={{ width: 235, marginRight: 10 }}
                variant={"secondary"}
                onClick={onReset}
              >
                Reset Query
              </NGButton>
            ) : null}
            <NGButton
              variant={"primary"}
              disabled={isQueryLoading || query.model.spec.terms.length < 1}
              loading={isQueryLoading}
              style={{ width: 220 }}
              onClick={evaluate}
            >
              Evaluate Query
            </NGButton>
          </div>

          {isEvaluated ? (
            <Table<any>
              tableId={`${idPrefix}-query-ui`}
              title={`Evaluated ${pluralNameOfKind(kind)}`}
              data={evaluatedItems.map(evaluatedItemFn)}
              columns={[
                NameDescriptionNoLinkColumn(),
                // TODO fix
                // ...(hasCustomColumns ? targetTableGetCustomColumns(kind) : []),
                TagsColumn(),
              ]}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export const QueryUI = observer(QueryUIRaw);
