import { types, Instance } from "mobx-state-tree";
import {
  Mk8sDraftProviderEphemeralNodePoolsModel,
  Mk8sDraftProviderEphemeralNodePoolsReadonlyModel,
} from "./mk8s.draft.provider.ephemeral.nodePool";
import { SelectModel } from "../../mobxDataModels/selectModel";

export const Mk8sDraftProviderEphemeralModel = types
  .model({
    _location: types.optional(types.string, "aws-eu-central-1"),
    location: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Location",
        options: [
          { label: "aws-eu-central-1", value: "aws-eu-central-1" },
          { label: "aws-us-east-2", value: "aws-us-east-2" },
          { label: "aws-us-west-2", value: "aws-us-west-2" },
          { label: "gcp-me-west1", value: "gcp-me-west1" },
          { label: "gcp-us-east1", value: "gcp-us-east1" },
        ],
        initialValue: "aws-eu-central-1",
      }),
    ),
    _nodePools: types.array(Mk8sDraftProviderEphemeralNodePoolsReadonlyModel),
    nodePools: types.array(Mk8sDraftProviderEphemeralNodePoolsModel),
    removed: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    reset() {
      self.location.setInitialValue(self._location);

      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftProviderEphemeralNodePoolsModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          }),
        );
      }
      self.removed = false;
    },
    confirm() {
      self.location.confirm();
      self._location = self.location.value;

      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftProviderEphemeralNodePoolsReadonlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
            count: Number(nodePool.count.value),
            arch: nodePool.arch.value,
            flavor: nodePool.flavor.value,
            cpu: nodePool.cpu.value,
            memory: nodePool.memory.value,
          }),
        );
      }
      self.removed = false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }
      self.nodePools.push(
        Mk8sDraftProviderEphemeralNodePoolsModel.create({
          index,
          status: "added",
        }),
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.nodePools.remove(node);
    },
  }))
  .views((self) => ({
    isNodePoolNameValid(index: number) {
      let res = true;
      const node = self.nodePools.find((np) => np.index === index)!;
      const pools = [...self.nodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyNodePoolNameConflicts() {
      for (const nodePool of self.nodePools) {
        if (
          [...self.nodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value,
          )
        ) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._nodePools.length !== self.nodePools.length) res = true;
      if (self.removed) res = true;
      if (self.nodePools.some((n) => n.isDirty)) res = true;
      if (self.location.isDirty) res = true;

      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self._nodePools.length !== self.nodePools.length) reason = "node pools length is different";
      if (self.removed) reason = "removed a pool";
      if (self.nodePools.some((n) => n.isDirty)) reason = self.nodePools.find((n) => n.isDirty)?.dirtyReason || "";
      if (self.location.isDirty) reason = "location";

      return reason;
    },
    get isValid() {
      let res = true;
      if (self.anyNodePoolNameConflicts) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.anyNodePoolNameConflicts) reason = "node pool name conflict";
      if (self.nodePools.some((n) => !n.isValid)) reason = self.nodePools.find((n) => !n.isValid)?.invalidReason || "";
      return reason;
    },
    get ui_isEphemeralValid() {
      let res = true;
      if (!self.location.value) res = false;
      return res;
    },
    get dryRun_ephemeralPathList() {
      return [
        //
        "spec.provider.ephemeral.location",
      ];
    },
    get asObject() {
      let obj: any = {
        location: self.location.value,
        nodePools: self.nodePools.map((n) => n.asObject),
      };
      return obj;
    },
  }));
export interface Mk8sDraftProviderEphemeralMobx extends Instance<typeof Mk8sDraftProviderEphemeralModel> {}
