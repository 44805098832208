import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NGButton } from "../../../newcomponents/button/Button";
import { useRemark } from "react-remark";
import "./markdown.css";
import { NGChip } from "../../../newcomponents/chip";
import { NGLabel } from "../../../newcomponents/text/label";
import { ExternalLink } from "react-feather";
import { Template } from "../types/template";
import { request } from "../../../services/cpln";
import { Loader } from "../../../components/layout/loader";
import NGAlert from "../../../newcomponents/alert";
import { getTemplateVersion, getVersions } from "../utils";
import { MARKETPLACE_BRANCH, MARKETPLACE_SERVICE_URL, MARKETPLACE_TEMPLATE_REPO } from "../../../envVariables";
import { Theme } from "../../../mobxStores/uiData/theme";

type RouteParams = "template";
export const MarketplaceTemplateDetail: React.FC = () => {
  const { template: templateName } = useParams<RouteParams>();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [template, setTemplate] = React.useState<Template>(null as any);
  const versions = getVersions(template);

  React.useEffect(() => {
    fetchTemplate();
  }, []);

  async function fetchTemplate() {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await request({ service: "marketplace", url: `/template/${templateName}` });
      setTemplate(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setError(errorMessage);
    }
  }

  const navigate = useNavigate();

  const [version, setVersion] = React.useState("");
  const [readme, setMarkdown] = useRemark();
  const templateVersion = getTemplateVersion(template, version);

  React.useEffect(() => {
    if (!template) {
      return;
    }

    setVersion(versions[versions.length - 1]);
  }, [template]);

  React.useEffect(() => {
    if (!template) {
      return;
    }
    if (!versions.includes(version)) {
      return;
    }

    setMarkdown(template.versions[version].readme);
  }, [version]);

  if (isLoading) {
    return <Loader reason={"Fetching template"} />;
  }

  if (!template || !version || !readme) {
    return null;
  }

  if (error) {
    return <NGAlert type={"error"} title="Error" message={error} />;
  }

  return (
    <div className="template-detail">
      <div className="template-detail-card rounded p-4">
        <div className="flex justify-between items-start mb-8">
          <div
            className="flex items-center px-4 py-1 rounded"
            style={{ color: "var(--color-gray-1600)", backgroundColor: "white" }}
          >
            <div style={{ width: 50, height: "100%" }}>
              <img className="object-fit" src={`${MARKETPLACE_SERVICE_URL}/icon/${templateName}`} />
            </div>
            <div className="flex flex-col ml-4">
              <span className="leading-none text-lg font-semibold">{template.name}</span>
              <span className="leading-none text-sm">{templateVersion?.category}</span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div>
              {/* <NGLabelText size={4}>Template</NGLabelText> */}
              {/* TODO make a select */}
              <NGChip size={"small"} variant="info" label={`Template ${versions[versions.length - 1]}`} />
            </div>
            <div>
              {/* <NGLabelText size={4}>Application</NGLabelText> */}
              <NGChip size={"small"} variant="info" label={`App v${templateVersion?.appVersion}`} />
            </div>
          </div>
        </div>
        <div className="markdown">{readme}</div>
      </div>
      <div className="template-detail-card rounded p-4">
        <NGButton className="mb-4" variant="primary" onClick={() => navigate("install")}>
          Create App
        </NGButton>
        <div className="divider" />
        <NGLabel className="mt-2">App Info</NGLabel>
        <a
          className="flex items-center gap-2 color-link"
          target={"_blank"}
          href={`${MARKETPLACE_TEMPLATE_REPO}/${MARKETPLACE_BRANCH}/${template.name}`}
        >
          Repository <ExternalLink className="h-4" />
        </a>
      </div>
    </div>
  );
};
