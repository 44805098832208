import { observer } from "mobx-react-lite";
import * as React from "react";
import { getLogsElasticData } from "./data/elastic";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";

interface Props {
  elastic: ReturnType<typeof getLogsElasticData>;
  onRemove?: any;
}
const ElasticRaw: React.FC<Props> = ({ elastic, onRemove }) => {
  return (
    <>
      <div style={{ width: 450 }}>
        <NGLabel>{elastic.provider.label}</NGLabel>
        <NGSelect
          value={elastic.provider.value}
          options={elastic.provider.options}
          onChange={elastic.provider.setValue}
          style={{ width: 450 }}
          className="mb-4"
        />
        {elastic.provider.value === "aws" ? (
          <>
            <NGInputAdapter data={elastic.aws_type} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.aws_host} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.aws_port} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.aws_index} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.aws_region} style={{ width: 450 }} className="mb-4" />
            <NGFormLabel required={elastic.aws_credentials.isRequired} invalid={!!elastic.aws_credentials.error}>
              Credentials (AWS Secret)
            </NGFormLabel>
            <NGKindSelect
              value={elastic.aws_credentials.value}
              onChange={(value) => elastic.aws_credentials.setValue(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "aws" }]}
            />
          </>
        ) : elastic.provider.value === "elasticcloud" ? (
          <>
            <NGInputAdapter data={elastic.elasticCloud_type} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.elasticCloud_index} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.elasticCloud_cloudId} style={{ width: 450 }} className="mb-4" />
            <NGFormLabel
              required={elastic.elasticCloud_credentials.isRequired}
              invalid={!!elastic.elasticCloud_credentials.error}
            >
              Credentials (Userpass Secret)
            </NGFormLabel>
            <NGKindSelect
              value={elastic.elasticCloud_credentials.value}
              onChange={(value) => elastic.elasticCloud_credentials.setValue(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "userpass" }]}
            />
          </>
        ) : elastic.provider.value === "generic" ? (
          <>
            <NGInputAdapter data={elastic.generic_type} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.generic_host} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.generic_index} style={{ width: 450 }} className="mb-4" />
            <NGFormLabel
              required={elastic.generic_credentials.isRequired}
              invalid={!!elastic.generic_credentials.error}
            >
              Credentials (Userpass Secret)
            </NGFormLabel>
            <NGKindSelect
              className="mb-4"
              value={elastic.generic_credentials.value}
              onChange={(value) => elastic.generic_credentials.setValue(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "userpass" }]}
            />
            <NGInputAdapter data={elastic.generic_path} style={{ width: 450 }} className="mb-4" />
            <NGInputAdapter data={elastic.generic_port} style={{ width: 450 }} />
          </>
        ) : null}
        {onRemove ? (
          <div className="flex justify-end mt-4">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const Elastic = observer(ElasticRaw);
