import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from "../Tooltip";
import { Copy } from "react-feather";
import { notification } from "antd";
import clsx from "clsx";

interface CopyableTruncateTooltipProps {
  value: string;
  overrideValueToCopy?: (value: string) => string;
  isCopyDisabled?: boolean;
}

const CopyableTruncateTooltipRaw: React.FC<CopyableTruncateTooltipProps> = ({
  value,
  overrideValueToCopy,
  isCopyDisabled,
}) => {
  let valueToCopy: string = value;

  if (overrideValueToCopy) {
    valueToCopy = overrideValueToCopy(value);
  }

  // States
  const [isTruncated, setIsTruncated] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  // Refs
  const spanRef: React.MutableRefObject<HTMLSpanElement | null> = React.useRef(null);

  // Effects
  React.useEffect(() => {
    const element = spanRef.current;

    if (!element) {
      return;
    }

    // Initial truncation check
    checkTruncation();

    // Create a ResizeObserver to monitor changes to the span's size
    const observer = new ResizeObserver(() => {
      checkTruncation();
    });

    // Observe the span element
    observer.observe(element);

    // Cleanup the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [value]);

  // Functions
  function checkTruncation(): void {
    const element: HTMLSpanElement | null = spanRef.current;

    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }

  function handleCopyToClipboard(value: string): void {
    navigator.clipboard.writeText(value);
    notification.success({ message: "Copied to clipboard" });
  }

  // Computed
  const valueElement: JSX.Element = (
    <Tooltip title={value} placement="topRight" open={isTruncated ? undefined : false}>
      <span ref={spanRef} className="truncate">
        {String(value)}
      </span>
    </Tooltip>
  );

  if (!valueToCopy || isCopyDisabled) {
    return valueElement;
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="cursor-pointer color-link-hover flex items-center gap-1"
      onClick={() => handleCopyToClipboard(valueToCopy)}
    >
      {valueElement}
      <Copy className={clsx(`h-4 flex-shrink-0 transition-all`, { "opacity-0": !isHovered })} />
    </div>
  );
};

export const CopyableTruncateTooltip = observer(CopyableTruncateTooltipRaw);
