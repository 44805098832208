import { observer } from "mobx-react-lite";
import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { DryRunAlert } from "../dryRunAlert";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsAzureWorkloadIdentityRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const v = mk8sDraft.addon_azureWorkloadIdentity.tenantId;

  return (
    <div>
      <div className="mb-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.azureWorkloadIdentity"}
        />
      </div>
      <div className="mb-2">Azure Workload Identity</div>
      <div className="flex gap-2 items-start">
        <NGFormElement
          name={"azureWorkloadIdentity.tenantId"}
          label={v.label}
          value={v.value}
          onChange={v.setValue}
          error={v.error}
        />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={v.setValue}
            path={"spec.addOns.azureWorkloadIdentity.tenantId"}
          />
        </div>
      </div>
    </div>
  );
};

export const AddonsAzureWorkloadIdentity = observer(AddonsAzureWorkloadIdentityRaw);
