import * as React from "react";
import { observer } from "mobx-react-lite";
import { Dropdown, notification } from "antd";
import { homeLink, linksOf, request } from "../../services/cpln";
import { ChevronDown, ExternalLink } from "react-feather";
import { DOCS_URL } from "../../envVariables";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { Table } from "../../newcomponents/table/table";
import NGAlert from "../../newcomponents/alert";
import { Link } from "react-router-dom";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { ImageSummaryRepository } from "../../schema/types/image";
import { NGButton } from "../../newcomponents/button/Button";
import { Theme } from "../../mobxStores/uiData/theme";
import { DeleteModal } from "./deleteModal";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const RepositoryListRaw: React.FC = () => {
  const { id } = useTableKindId("image", "image-repository-list");
  const [isLoading, setIsLoading] = React.useState(false);
  const [repositories, setRepositories] = React.useState<ImageSummaryRepository[]>([]);

  const [selectedNames, setSelectedNames] = React.useState<string[]>([]);
  const [actionKey, setActionKey] = React.useState<string>("");

  React.useEffect(() => {
    fetchRepositories();
  }, []);

  async function fetchRepositories() {
    try {
      setIsLoading(true);
      setRepositories([]);
      let repositories: ImageSummaryRepository[] = [];
      let url: string | undefined = homeLink("image") + "/-summary";
      while (url) {
        const { data } = await request({ url: url });
        url = linksOf(data).next;
        repositories = repositories.concat(data.repositories);
      }
      setRepositories(repositories);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to fetch repositories", description: errorMessage });
      setIsLoading(false);
    }
  }

  function handleActionsMenuClick({ key }) {
    if (key === "delete") {
      setActionKey("delete");
    }
  }

  return (
    <>
      <div className={`mb-4 flex items-center justify-between rounded p-8 image-info-box`}>
        <div>You can push images to your organization's private registry. See the documentation to learn more.</div>
        <a
          className={"ngfocus color-link"}
          style={{ width: 220, marginLeft: 10 }}
          href={`${DOCS_URL}/guides/push-image`}
          target={"_blank"}
        >
          How to Push an Image?
          <ExternalLink className="feather-icon ml-1 inline-block" style={{ transform: "translateY(2px)" }} />
        </a>
      </div>

      <Table<ImageSummaryRepository>
        title={"Images"}
        data={repositories}
        tableId={id}
        selectMode="multi"
        selectKey="name"
        selections={selectedNames}
        onSelectionsChange={setSelectedNames}
        headerRenderer={() => {
          return (
            <>
              <NGAlert
                size={"small"}
                type={"info"}
                render={() => (
                  <>
                    <span className="text-sm leading-none">
                      Your images are available for use as soon as you push them.
                    </span>
                    <span className="text-sm leading-none">
                      It may take up to 5 minutes for them to appear in this list.
                    </span>
                  </>
                )}
              />
              <TableHeaderRefreshButton onClick={fetchRepositories} />
              <Dropdown
                menu={{
                  onClick: handleActionsMenuClick,
                  items: [
                    {
                      key: "delete",
                      label: "Delete",
                      danger: true,
                      disabled: selectedNames.length < 1,
                    },
                  ],
                }}
                placement={"bottom"}
                trigger={["click"]}
                disabled={isLoading}
              >
                <NGButton
                  loading={isLoading}
                  disabled={isLoading}
                  size={"normal"}
                  outlined={Theme.theme === "light"}
                  variant="primary"
                  renderIcon={(hover, props) => <ChevronDown {...props} size={18} />}
                >
                  Actions
                </NGButton>
              </Dropdown>
            </>
          );
        }}
        enableFilter
        hideSettings
        isLoading={isLoading}
        columns={[
          {
            id: "name",
            label: "Name",
            cell: ({ getValue }) => {
              return (
                <Link className="color-link" to={`/console/org/${ConsoleContext.org}/repository/${getValue()}`}>
                  {getValue()}
                </Link>
              );
            },
          },
          {
            id: "tagsCount",
            label: "Tag Count",
          },
        ]}
      />
      {actionKey === "delete" ? (
        <DeleteModal
          names={selectedNames}
          onCancel={() => {
            setActionKey("");
            setSelectedNames([]);
          }}
          onComplete={() => {
            setActionKey("");
            setSelectedNames([]);
          }}
        />
      ) : null}
    </>
  );
};

export const RepositoryList = observer(RepositoryListRaw);
