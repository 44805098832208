import { TableColumn } from "../../types";
import { Links } from "../../../../schema/types/base";
import { linksOf } from "../../../../services/cpln";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";

const gvcDefault = { id: "gvc", label: "GVC" };
export function GvcColumn<TData extends { links: Links }>(column: TableColumn<TData> = gvcDefault) {
  const preset: TableColumn<TData> = {
    ...gvcDefault,
    size: 180,
    enableResize: true,
    enableHide: true,
    cell: ({ row }) => {
      const item = row.original;
      if (!item.links || !Array.isArray(item.links)) {
        return <span />;
      }

      const gvcLink = linksOf(item).gvc;
      if (!gvcLink) {
        return <span />;
      }

      const { name } = ngParseLink(gvcLink, { useInputCtx: true });
      return <span>{name}</span>;
    },
  };
  return Object.assign(preset, column);
}
