import { types, Instance } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { defaultValues } from "../base";

export const VolumeSetDraftAutoscalingModel = types
  .model({
    _maxCapacity: types.optional(types.number, defaultValues.volumeset.autoscaling.maxCapacity.default),
    _minFreePercentage: types.optional(types.number, defaultValues.volumeset.autoscaling.minFreePercentage.default),
    _scalingFactor: types.optional(types.number, defaultValues.volumeset.autoscaling.scalingFactor.default),
    maxCapacity: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Max Capacity",
        min: defaultValues.volumeset.autoscaling.maxCapacity.min,
        initialValue: defaultValues.volumeset.autoscaling.maxCapacity.default,
      })
    ),
    minFreePercentage: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Min Free Percentage",
        min: defaultValues.volumeset.autoscaling.minFreePercentage.min,
        max: defaultValues.volumeset.autoscaling.minFreePercentage.max,
        initialValue: defaultValues.volumeset.autoscaling.minFreePercentage.default,
      })
    ),
    scalingFactor: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Scaling Factor",
        allowDot: true,
        min: defaultValues.volumeset.autoscaling.scalingFactor.min,
        initialValue: defaultValues.volumeset.autoscaling.scalingFactor.default,
      })
    ),
  })
  .actions((self) => ({
    reset() {
      self.maxCapacity.setInitialValue(String(self._maxCapacity));
      self.minFreePercentage.setInitialValue(String(self._minFreePercentage));
      self.scalingFactor.setInitialValue(String(self._scalingFactor));
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._maxCapacity = Number(self.maxCapacity.value);
      self._minFreePercentage = Number(self.minFreePercentage.value);
      self._scalingFactor = Number(self.scalingFactor.value);
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._maxCapacity !== Number(self.maxCapacity.value)) {
        res = true;
      }
      if (self._minFreePercentage !== Number(self.minFreePercentage.value)) {
        res = true;
      }
      if (self._scalingFactor !== Number(self.scalingFactor.value)) {
        res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.maxCapacity.isValid) {
        res = false;
      }
      if (!self.minFreePercentage.isValid) {
        res = false;
      }
      if (!self.scalingFactor.isValid) {
        res = false;
      }
      return res;
    },
    get asObject() {
      return {
        maxCapacity: Number(self.maxCapacity.value),
        minFreePercentage: Number(self.minFreePercentage.value),
        scalingFactor: Number(self.scalingFactor.value),
      };
    },
  }));
export interface VolumeSetDraftAutoscalingMobx extends Instance<typeof VolumeSetDraftAutoscalingModel> {}
