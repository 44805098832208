import * as React from "react";

interface Props {
  chartHeight?: number;
  chartWidth?: number;
  payload?: any;
  setPayload: (payload?: any) => void;
}
export const LegendPayloadSaver: React.FC<Props> = ({ payload, setPayload }) => {
  setPayload(payload);

  return null;
};
