import * as React from "react";
import { Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import { Tooltip } from "../Tooltip";

interface Props {
  onChange: () => void;
  checked: boolean;
  label: string;
  style: any;
}
const CheckboxWithTooltipRaw: React.FC<Props> = ({ onChange, checked, label, style }) => {
  return (
    <Checkbox style={{ marginLeft: 0, ...style }} className="mb-2 truncate" onChange={onChange} checked={checked}>
      <Tooltip title={label} open={label.length >= 120}>
        <span>{label}</span>
      </Tooltip>
    </Checkbox>
  );
};

export const CheckboxWithTooltip = observer(CheckboxWithTooltipRaw);
