import { Instance, types } from "mobx-state-tree";
import { AWSProviderModel } from "./aws";
import { GenericProviderModel } from "./generic";
import { HetznerProviderModel } from "./hetzner";
import { LambdaLabsProviderModel } from "./lambdalabs";
import { LinodeProviderModel } from "./linode";
import { OblivusProviderModel } from "./oblivus";
import { PaperspaceProviderModel } from "./paperspace";
import { EphemeralProviderModel } from "./ephemeral";
import { TritonProviderModel } from "./triton";
import { DigitalOceanProviderModel } from "./digitalocean";

const mk8sSpecFirewallItemModel = types.model({
  sourceCIDR: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
});
export interface mk8sSpecFirewallItemMobx extends Instance<typeof mk8sSpecFirewallItemModel> {}
export interface mk8sSpecFirewallItem {
  sourceCIDR: string;
  description: string;
}

export const mk8sSpecModel = types.model({
  version: types.optional(types.string, "1.30.3"),
  firewall: types.optional(types.array(mk8sSpecFirewallItemModel), () => [
    {
      sourceCIDR: "0.0.0.0/0",
      description: "Default allow-all rule",
    },
  ]),
  provider: types.model({
    aws: types.maybe(AWSProviderModel),
    digitalocean: types.maybe(DigitalOceanProviderModel),
    ephemeral: types.maybe(EphemeralProviderModel),
    generic: types.maybe(GenericProviderModel),
    hetzner: types.maybe(HetznerProviderModel),
    lambdalabs: types.maybe(LambdaLabsProviderModel),
    linode: types.maybe(LinodeProviderModel),
    oblivus: types.maybe(OblivusProviderModel),
    paperspace: types.maybe(PaperspaceProviderModel),
    triton: types.maybe(TritonProviderModel),
  }),
  addOns: types.maybe(
    types.model({
      dashboard: types.maybe(types.frozen()),
      azureWorkloadIdentity: types.maybe(
        types.model({
          tenantId: types.optional(types.string, ""),
        }),
      ),
      awsWorkloadIdentity: types.maybe(types.frozen()),
      localPathStorage: types.maybe(types.frozen()),
      metrics: types.maybe(
        types.model({
          coreDns: types.optional(types.boolean, false),
          kubelet: types.optional(types.boolean, false),
          kubeState: types.optional(types.boolean, false),
          nodeExporter: types.optional(types.boolean, false),
          apiserver: types.optional(types.boolean, false),
          cadvisor: types.optional(types.boolean, false),
          scrapeAnnotated: types.maybe(
            types.model({
              intervalSeconds: types.optional(types.number, 30), // int, min 30
              includeNamespaces: types.optional(types.string, ""), // regexp
              excludeNamespaces: types.optional(types.string, ""), // regexp
              retainLabels: types.optional(types.string, ""), // regexp
            }),
          ),
        }),
      ),
      logs: types.maybe(
        types.model({
          auditEnabled: types.optional(types.boolean, false),
          includeNamespaces: types.optional(types.string, ""), // regexp
          excludeNamespaces: types.optional(types.string, ""), // regexp
        }),
      ),
      nvidia: types.maybe(
        types.model({
          taintGPUNodes: types.optional(types.boolean, false),
        }),
      ),
      awsEFS: types.maybe(
        types.model({
          roleArn: types.optional(types.string, ""),
        }),
      ),
      awsECR: types.maybe(
        types.model({
          roleArn: types.optional(types.string, ""),
        }),
      ),
      awsELB: types.maybe(
        types.model({
          roleArn: types.optional(types.string, ""),
        }),
      ),
      azureACR: types.maybe(
        types.model({
          clientId: types.optional(types.string, ""),
        }),
      ),
      sysbox: types.maybe(types.frozen()),
    }),
  ),
});

export interface mk8sSpecMobx extends Instance<typeof mk8sSpecModel> {}
export interface mk8sSpec {
  version: string;
  firewall: mk8sSpecFirewallItem[];
  provider: {
    aws?: any;
    ephemeral?: any;
    generic?: any;
    hetzner?: any;
    lambdalabs?: any;
    linode?: any;
    oblivus?: any;
    paperspace?: any;
    triton?: any;
  };
  addOns?: {
    dashboard?: any;
    azureWorkloadIdentity?: {
      tenantId: string;
    };
    awsWorkloadIdentity?: any;
    localPathStorage?: any;
    metrics?: {
      coreDns: boolean;
      kubelet: boolean;
      kubeState: boolean;
      nodeExporter: boolean;
      apiserver: boolean;
      cadvisor: boolean;
      scrapeAnnotated?: {
        intervalSeconds: number;
        includeNamespaces: string;
        excludeNamespaces: string;
        retainLabels: string;
      };
    };
    logs?: {
      auditEnabled: boolean;
      includeNamespaces: string;
      excludeNamespaces: string;
    };
    nvidia?: {
      taintGPUNodes: boolean;
    };
    awsEFS?: {
      roleArn: string;
    };
    awsECR?: {
      roleArn: string;
    };
    awsELB?: {
      roleArn: string;
    };
    azureACR?: {
      clientId: string;
    };
    sysbox?: any;
  };
}
