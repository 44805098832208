import { Row } from "@tanstack/react-table";
import moment from "moment-timezone";
import { TableColumn } from "./types";

// Filter
export function cplnFilterFnFuzzy<TData>(row: Row<TData>, columnId: string, value: any): boolean {
  const itemValue = row.getValue(columnId);
  return String(itemValue)
    .toLowerCase()
    .includes((value || "").toLowerCase());
}

// Sorting
export function cplnSortingFnDate<TData>(rowA: Row<TData>, rowB: Row<TData>, columnId: string): number {
  let dateA: moment.Moment = moment(rowA.original[columnId] as any);
  let dateB: moment.Moment = moment(rowB.original[columnId] as any);

  if (dateA.isBefore(dateB)) return 1;
  if (dateA.isSame(dateB)) return 0;
  return -1;
}

// Config
export function valueIsAColumn(id: string, columns: TableColumn<any>[]): boolean {
  return columns.some((c) => c.id === id);
}

const TABLE_STORAGE_KEY_GROUPBY = `table-config-{tableId}-group-by`;
export function getTableStorageKeyGroupBy(tableId: string) {
  return TABLE_STORAGE_KEY_GROUPBY.replace("{tableId}", tableId);
}

const TABLE_STORAGE_KEY_COLLAPSEDGROUPS = `table-config-{tableId}-collapsed-groups`;
export function getTableStorageKeyCollapsedGroups(tableId: string) {
  return TABLE_STORAGE_KEY_COLLAPSEDGROUPS.replace("{tableId}", tableId);
}

const TABLE_STORAGE_KEY_COLUMNSETTINGS = `table-config-{tableId}-column-settings`;
export function getTableStorageKeyColumnSettings(tableId: string) {
  return TABLE_STORAGE_KEY_COLUMNSETTINGS.replace("{tableId}", tableId);
}

const TABLE_STORAGE_KEY_SORTING = `table-config-{tableId}-sorting`;
export function getTableStorageKeySorting(tableId: string) {
  return TABLE_STORAGE_KEY_SORTING.replace("{tableId}", tableId);
}

const TABLE_STORAGE_KEY_GLOBALFILTER = `table-config-{tableId}-global-filter`;
export function getTableStorageKeyGlobalFilter(tableId: string) {
  return TABLE_STORAGE_KEY_GLOBALFILTER.replace("{tableId}", tableId);
}

const TABLE_STORAGE_KEY_COLUMNSIZING = `table-config-{tableId}-column-sizing`;
export function getTableStorageKeyColumnSizing(tableId: string) {
  return TABLE_STORAGE_KEY_COLUMNSIZING.replace("{tableId}", tableId);
}

// Defaults
export const TABLE_DEFAULT_COLUMN_SIZE = 200;
export const TABLE_DEFAULT_RESIZE = false;
export const TABLE_DEFAULT_HIDE = false;
export const TABLE_DEFAULT_SORT = false;
export const TABLE_DEFAULT_COLUMN_FILTER = false;
export const TABLE_DEFAULT_COLUMN_FILTER_TYPE = "multi";
export const TABLE_DEFAULT_FILTER = false;

/* TODO
// Clear column filter and column sort if column is not visible
*/

// Notes
// Row model order
// getCoreRowModel -> getFilteredRowModel -> getGroupedRowModel -> getSortedRowModel -> getExpandedRowModel -> getPaginationRowModel -> getRowModel
