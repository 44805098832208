import * as React from "react";
import { Layout } from "../../layout";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { OrgSelect } from "./select";
import { OrgDetailRoute } from "./detailRoute";
import { observer } from "mobx-react-lite";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { OrgNotFound } from "./notfound";

const OrgRoute: React.FC = () => {
  const { pathname } = useLocation();
  const { org, hasOrg } = ConsoleContext;

  const [showLeftMenu, setShowLeftMenu] = React.useState(true);

  React.useEffect(() => {
    let res = true;
    if (pathname.includes("/org/-create") || pathname.includes("/org/-select")) {
      res = false;
    }
    setShowLeftMenu(res);
  }, [org, pathname]);

  return (
    <Layout key={org} showLeftMenu={hasOrg && showLeftMenu}>
      <Routes key={org}>
        <Route index element={<Navigate to={`${org}`} />} />
        <Route path={`-select`} element={<OrgSelect />} />
        <Route path={`:org/notfound`} element={<OrgNotFound />} />
        <Route path={`:org/*`} element={<OrgDetailRoute key={ConsoleContext.org} />} />
      </Routes>
    </Layout>
  );
};

export default observer(OrgRoute);
