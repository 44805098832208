import { types } from "mobx-state-tree";
import { GCPBindingModel } from "../kinds/identity";

export interface GCPIdentityDataSnapshotBinding {
  resource: string;
  resourceDataItem?: { href: string; ref: string };
  roles: string[];
}

export interface GCPIdentityDataSnapshot {
  cloudAccountLink: string;
  bindings: GCPIdentityDataSnapshotBinding[];
  serviceAccount: string;
}

export const GCPIdentityData = types
  .model({
    isActive: false,
    // Below was cloud account instance reference
    cloudaccountLink: types.optional(types.string, ""),
    method: types.optional(types.enumeration(["existing", "new"]), "existing"),
    serviceaccount: types.maybe(types.string),
    bindings: types.array(types.late(() => GCPBindingModel)),
  })
  .actions((self) => ({
    clear() {
      self.isActive = false;
      self.cloudaccountLink = "";
      self.method = "existing";
      self.serviceaccount = undefined;
      self.bindings.clear();
    },
    apply(obj: GCPIdentityDataSnapshot) {
      self.isActive = true;
      self.cloudaccountLink = obj.cloudAccountLink;
      if (obj.serviceAccount) {
        self.method = "existing";
        self.serviceaccount = obj.serviceAccount;
        self.bindings.clear();
      } else {
        self.method = "new";
        self.serviceaccount = undefined;
        self.bindings.clear();
        for (let binding of obj.bindings) {
          const instance = GCPBindingModel.create();
          instance.apply(binding);
          self.bindings.push(instance);
        }
      }
    },
  }))
  .views((self) => ({
    // asObject is used for applying data, asObjectForHTTP is used for making requests to data service
    get asObject(): GCPIdentityDataSnapshot {
      return {
        cloudAccountLink: self.cloudaccountLink,
        serviceAccount: self.method === "existing" ? self.serviceaccount || "" : "",
        bindings:
          self.method === "new"
            ? self.bindings.map((binding) => {
                const item: GCPIdentityDataSnapshotBinding = {
                  resource: binding.forEdit.resourceInput.value,
                  roles: [...binding.forEdit.roles],
                };
                if (binding.forEdit.resourceDataItem) {
                  item.resourceDataItem = {
                    href: binding.forEdit.resourceDataItem.href,
                    ref: binding.forEdit.resourceDataItem.ref,
                  };
                }
                return item;
              })
            : [],
      };
    },
    get asObjectForHTTP(): GCPIdentityDataSnapshot {
      const body: any = { cloudAccountLink: self.cloudaccountLink };
      if (self.method === "existing") {
        body.serviceAccount = self.serviceaccount || "";
      } else {
        body.bindings = self.bindings.map((binding) => ({
          resource: binding.forEdit.resourceInput.value,
          roles: [...binding.forEdit.roles],
        }));
      }
      return body;
    },
  }));
