import { observer } from "mobx-react-lite";
import * as React from "react";
import { NGButton } from "../../../newcomponents/button/Button";
import { getLogsCloudWatchData } from "./data/cloudWatch";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { request } from "../../../services/cpln";
import NGAlert from "../../../newcomponents/alert";
import { CodeEditor } from "../../group/identityMatcher/codeEditor";
import { notification } from "antd";
import { ExternalLink } from "react-feather";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../newcomponents/input/input";

interface Props {
  formPrefix: string;
  cloudWatch: ReturnType<typeof getLogsCloudWatchData>;
  onRemove?: any;
}
const CloudWatchRaw: React.FC<Props> = ({ formPrefix, cloudWatch, onRemove }) => {
  const [regionOptions, setRegionOptions] = React.useState<string[]>([]);
  async function fetchRegionOptions() {
    const { data } = await request({ url: "/catalog/schema/org" });
    const options = data.keys.spec.keys.logging.keys.cloudWatch.keys.region.allow;
    setRegionOptions(options.sort().map((o) => ({ label: o, value: o })));
  }
  React.useEffect(() => {
    fetchRegionOptions();
  }, []);

  formPrefix += "-cloudwatch-";

  const regionOptionsToUse = regionOptions.length > 0 ? regionOptions : cloudWatch.region.options;

  const policy = `{
  "Version": "2012-10-17",
  "Statement": [{
    "Effect": "Allow",
    "Action": [
      "logs:CreateLogStream",
      "logs:CreateLogGroup",
      "logs:PutLogEvents",
      "logs:PutRetentionPolicy"
    ],
    "Resource": "*"
  }]
}`;

  return (
    <>
      <div style={{ width: 450 }}>
        <NGFormElement
          name={`${formPrefix}region`}
          label={cloudWatch.region.label}
          as={"select"}
          value={cloudWatch.region.value}
          onChange={cloudWatch.region.setValue}
          options={regionOptionsToUse}
          required={true}
        />
        {cloudWatch.region.value ? (
          <div className="-mt-2 mb-4">
            <a
              className="color-link"
              href={`https://${cloudWatch.region.value}.console.aws.amazon.com/cloudwatch/home?#logsV2:log-groups`}
              target={"_blank"}
            >
              <span>Explore CloudWatch</span>
              <ExternalLink className="h-4 inline-block" style={{ transform: "translateY(1px)" }} />
            </a>
          </div>
        ) : null}
        <NGFormElement
          name={`${formPrefix}credentials`}
          label={`Credentials (AWS Secret)`}
          as={"kindselect"}
          value={cloudWatch.credentials.value}
          onChange={(value) => cloudWatch.credentials.setValue(value)}
          kind={"secret"}
          kindSelectProps={{ queries: [{ property: "type", value: "aws" }] }}
          required={cloudWatch.credentials.isRequired}
          error={cloudWatch.credentials.error}
        />
        <div className="mb-4 -mt-2">
          <NGAlert className="mb-2" message="Policy attached to the user in IAM must at least look like this:" />
          <NGButton
            className="mb-2"
            variant={"secondary"}
            onClick={() => {
              navigator.clipboard.writeText(policy);
              notification.success({ message: `Copied policy to clipboard` });
            }}
          >
            Copy Policy
          </NGButton>
          <CodeEditor language={"json"} options={{ readOnly: true }} value={policy} setValue={() => {}} />
        </div>
        <NGFormElement
          name={`${formPrefix}retentionDays`}
          label={cloudWatch.retentionDays.label}
          value={cloudWatch.retentionDays.value}
          onChange={cloudWatch.retentionDays.setValue}
          required={cloudWatch.retentionDays.isRequired}
          error={cloudWatch.retentionDays.error}
        />
        <NGFormElement
          name={`${formPrefix}groupName`}
          label={cloudWatch.groupName.label}
          value={cloudWatch.groupName.value}
          onChange={cloudWatch.groupName.setValue}
          required={cloudWatch.groupName.isRequired}
          error={cloudWatch.groupName.error}
          info={[
            `Use $stream, $location, $provider, $replica, $workload, $gvc, $org, $container, $version to template.`,
            "Any metadata can be used that’s associated with the workload.",
            "Metadata can be associated as a comma separated key-value pair under the tag “cpln/logMetadata” on the workloads.",
            `Example Tag; cpln/logMetadata=”metadataKeyA=metadataValueA,metadataKeyB=metadataValueB”`,
          ]}
        />
        <NGFormElement
          name={`${formPrefix}streamName`}
          label={cloudWatch.streamName.label}
          value={cloudWatch.streamName.value}
          onChange={cloudWatch.streamName.setValue}
          required={cloudWatch.streamName.isRequired}
          error={cloudWatch.streamName.error}
          info={[
            `Use $stream, $location, $provider, $replica, $workload, $gvc, $org, $container, $version to template.`,
            "Any metadata can be used that’s associated with the workload.",
            "Metadata can be associated as a comma separated key-value pair under the tag “cpln/logMetadata” on the workloads.",
            `Example Tag; cpln/logMetadata=”metadataKeyA=metadataValueA,metadataKeyB=metadataValueB”`,
          ]}
        />
        <NGInputListMst
          label="Extract Fields"
          data={cloudWatch.extractFields}
          firstLabel="Key"
          firstInput={(i) => <NGInput value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />}
          secondLabel="Value"
          secondInput={(i) => <NGInput value={i.secondValue} onChange={(e) => i.setSecondValue(e.target.value)} />}
        />
        {onRemove ? (
          <div className="flex justify-end">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const CloudWatch = observer(CloudWatchRaw);
