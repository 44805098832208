import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../advanced/providerAutoscaler";
import { ProviderNetworking } from "../../advanced/providerNetworking";
import { ProviderPreInstallScript } from "../../advanced/providerPreInstallScript";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { getDryRunErrorText } from "../../getDryRunErrorText";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderLinodeAdvancedRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const l = mk8sDraft.provider_linode!;

  const authorizedKeysDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.linode.authorizedKeys",
    paths: l.authorizedKeys.items.map((_, idx) => `spec.provider.linode.authorizedKeys[${idx}]`),
  });

  return (
    //
    <>
      <NGInputListMst
        data={l.authorizedKeys}
        className="mb-8"
        styles={{ header: { width: "unset" } }}
        label="Authorized Keys"
        firstInput={(i, index) => {
          let invalid = false;
          if (authorizedKeysDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
            invalid = true;
          }

          return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
        }}
        invalid={authorizedKeysDryRun.severity === "error"}
        error={authorizedKeysDryRun.severity === "error" ? authorizedKeysDryRun.message : ""}
        warning={authorizedKeysDryRun.severity === "error" ? "" : authorizedKeysDryRun.message}
      />
      <div className="mb-4">
        <ProviderPreInstallScript provider="linode" preInstallScript={l.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={l.networking} readOnly />
      </div>
      <div className="mb-4">
        <ProviderAutoscaler provider="linode" autoscaler={l.autoscaler} />
      </div>
    </>
  );
};

export const ProviderLinodeAdvanced = observer(ProviderLinodeAdvancedRaw);
