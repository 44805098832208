import { TableColumn } from "../../types";
import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";

const gvcDefault = { id: "gvc", label: "GVC" };
export function WorkloadGvcColumn<TData extends WorkloadTableItem>(column: TableColumn<TData> = gvcDefault) {
  const preset: TableColumn<TData> = {
    ...gvcDefault,
    size: 190,
    enableResize: true,
    enableHide: true,
  };
  return Object.assign(preset, column);
}
