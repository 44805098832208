import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "./../../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "./../../../../../services/cpln";
import { DryRunAlert } from "./../../../dryRunAlert";
import { MoreHorizontal, PlusCircle } from "react-feather";
import { NGKindSelect } from "./../../../../../newcomponents/select/ngkindselect";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { NGFormLabel } from "../../../../../newcomponents/text/formLabel";
import { NGProviderCreateOpaqueSecret } from "../../../ngProviderCreateOpaqueSecret";
import NGAlert from "../../../../../newcomponents/alert";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderHetznerRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const h = mk8sDraft.provider_hetzner!;
  const formData = useNGFormContext();

  const [browserKey, setBrowserKey] = React.useState("");

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"hetzner.region"}
            label={h.region.label}
            value={h.region.value}
            onChange={h.region.setValue}
            required={h.region.isRequired}
            error={h.region.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("region");
                  formData.set("hetzner.region", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("hetzner.region").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={h.region.setValue}
              path={"spec.provider.hetzner.region"}
            />
          </div>
        </div>

        <div className="mb-4">
          <NGFormLabel required invalid={!h.tokenSecretName}>
            Hetzner Token Secret
          </NGFormLabel>
          <div className="mb-2 flex items-start gap-2">
            <NGKindSelect
              name={"hetzner.tokenSecretName"}
              style={{ width: 450 }}
              value={h.tokenSecretName}
              invalid={!h.tokenSecretName}
              onChange={(value) => h.setTokenSecretName(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "opaque" }]}
              buttons={[
                {
                  title: "Create",
                  render: () => <PlusCircle className="h-4" />,
                  onClick: () => setBrowserKey("createSecret"),
                },
              ]}
            />
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("hetzner.tokenSecretName").touched}
              dryRunResponse={mk8sDryRun.response}
              path={"spec.provider.hetzner.tokenSecretLink"}
            />
          </div>
          <NGAlert
            style={{ width: 450 }}
            render={() => (
              <div className="flex flex-col text-sm">
                <span>An opaque secret that contains your Hetzner token.</span>
                <span>It will be used to manage servers in your Hetzner account.</span>
              </div>
            )}
          />
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"hetzner.networkId"}
            label={h.networkId.label}
            value={h.networkId.value}
            onChange={h.networkId.setValue}
            required={h.networkId.isRequired}
            error={h.networkId.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("networkId");
                  formData.set("hetzner.networkId", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("hetzner.networkId").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={h.networkId.setValue}
              path={"spec.provider.hetzner.networkId"}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"hetzner.firewallId"}
            label={h.firewallId.label}
            value={h.firewallId.value}
            onChange={h.firewallId.setValue}
            required={h.firewallId.isRequired}
            error={h.firewallId.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("firewallId");
                  formData.set("hetzner.firewallId", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("hetzner.firewallId").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={h.firewallId.setValue}
              path={"spec.provider.hetzner.firewallId"}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"hetzner.image"}
            label={h.image.label}
            value={h.image.value}
            onChange={h.image.setValue}
            required={h.image.isRequired}
            error={h.image.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("image");
                  formData.set("hetzner.image", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("hetzner.image").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={h.image.setValue}
              path={"spec.provider.hetzner.image"}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"hetzner.sshKey"}
            label={h.sshKey.label}
            value={h.sshKey.value}
            onChange={h.sshKey.setValue}
            required={h.sshKey.isRequired}
            error={h.sshKey.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("sshKey");
                  formData.set("hetzner.sshKey", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("hetzner.sshKey").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={h.sshKey.setValue}
              path={"spec.provider.hetzner.sshKey"}
            />
          </div>
        </div>
      </div>

      {browserKey === "createSecret" ? (
        <NGProviderCreateOpaqueSecret
          onOk={(secretName: string) => h.setTokenSecretName(secretName)}
          onClose={() => setBrowserKey("")}
          title={"Create Hetzner Token Secret"}
          dataLabel={"Hetzner Token"}
        />
      ) : null}
      {browserKey === "region" ? (
        <NGProviderBrowser
          title="Browse Hetzner Regions"
          initialValue={h.region.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "locations",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.locations;
          }}
          onOk={(value) => h.region.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Location"]}
          getData={(item: any) => [item.name, item.name, `${item.city}, ${item.country}`]}
        />
      ) : null}
      {browserKey === "networkId" ? (
        <NGProviderBrowser
          key={h.tokenSecretName}
          title="Browse Hetzner Networks"
          initialValue={h.networkId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "networks",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.networks;
          }}
          onOk={(value) => h.networkId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "ID"]}
          getData={(item: any) => [String(item.id), item.name, String(item.id)]}
        />
      ) : null}
      {browserKey === "firewallId" ? (
        <NGProviderBrowser
          key={h.tokenSecretName}
          title="Browse Hetzner Firewalls"
          initialValue={h.firewallId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "firewalls",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.firewalls;
          }}
          onOk={(value) => h.firewallId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "ID"]}
          getData={(item: any) => [String(item.id), item.name, String(item.id)]}
        />
      ) : null}
      {browserKey === "image" ? (
        <NGProviderBrowser
          title="Browse Hetzner Images"
          initialValue={h.image.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "images",
                cluster: mk8sDraft.asObject,
              },
            });
            const _images = data.images.sort((a: any, b: any) => {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
              return 0;
            });

            const images: any[] = [];

            for (const image of _images) {
              const foundImage = images.find((i) => i.name === image.name);
              if (foundImage) {
                if (!foundImage.architecture.includes(image.architecture)) {
                  foundImage.architecture.push(image.architecture);
                }
              } else {
                image.architecture = [image.architecture];
                images.push(image);
              }
            }
            return images;
          }}
          onOk={(value) => h.image.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description", "Disk Size", "Architecture"]}
          getData={(item: any) => [
            item.name,
            item.name,
            item.description,
            `${item.disk_size}Gi`,
            item.architecture.join(", "),
          ]}
        />
      ) : null}
      {browserKey === "sshKey" ? (
        <NGProviderBrowser
          key={h.tokenSecretName}
          title="Browse Hetzner SSH Keys"
          initialValue={h.sshKey.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ssh_keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.ssh_keys;
          }}
          onOk={(value) => h.sshKey.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "ID"]}
          getData={(item: any) => [String(item.id), item.name, String(item.id)]}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderHetzner = observer(Mk8sCreateProviderHetznerRaw);
