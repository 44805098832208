import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderLinode } from "./linode";
import { ProviderLinodeNodePools } from "./nodePools";
import { ProviderLinodeNodePoolAdd } from "./nodePoolAdd";
import { ProviderLinodeNodePool } from "./nodePool";
import { ProviderLinodeAdvanced } from "./advanced";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderLinodeRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderLinode mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderLinodeNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<ProviderLinodeNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<ProviderLinodeNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<ProviderLinodeAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderLinodeRoute = observer(ProviderLinodeRouteRaw);
