import * as React from "react";
import { observer } from "mobx-react-lite";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { request } from "../../../../services/cpln";
import { notification } from "antd";
import { HUBSPOT_SERVICE_URL, IS_DEPLOYMENT_ENV_TEST } from "../../../../envVariables";
import { get_CARD_ELEMENT_OPTIONS } from "../paymentMethods/add";
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { Theme } from "../../../../mobxStores/uiData/theme";
import { User } from "../../../../mobxStores/user/user";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGCheckbox } from "../../../../newcomponents/checkbox";

interface Props {
  accountId: string;
  isConfirmed: boolean;
  setCanCreatePaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onDone: () => void;
  onError: () => void;
}
const AccountCreatePaymentMethodRaw: React.FC<Props> = ({
  accountId,
  isConfirmed,
  setCanCreatePaymentMethod,
  setIsLoading,
  onDone,
  onError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = React.useState("");
  const [clientSecretError, setClientSecretError] = React.useState("");
  const [addError, setAddError] = React.useState("");
  const [consentGiven, setConsentGiven] = React.useState(false);
  const [cardError, setCardError] = React.useState<any>(undefined);
  const [cardComplete, setCardComplete] = React.useState(false);
  const { theme } = Theme;

  React.useEffect(() => {
    getClientSecret();
  }, []);

  React.useEffect(() => {
    let canCreate = true;
    if (!!addError) canCreate = false;
    if (!!clientSecretError) canCreate = false;
    if (!consentGiven) canCreate = false;
    if (!!cardError) canCreate = false;
    if (!cardComplete) canCreate = false;
    setCanCreatePaymentMethod(canCreate);
  }, [addError, clientSecretError, consentGiven, cardError, cardComplete]);

  React.useEffect(() => {
    if (!isConfirmed) {
      return;
    }
    onConfirm();
  }, [isConfirmed]);

  async function getClientSecret() {
    try {
      setIsLoading(true);
      setClientSecretError("");
      const { data } = await request({
        method: "post",
        service: "billing-ng",
        url: `/account/${accountId}/payment-setup`,
      });
      setClientSecret(data.clientSecret);
    } catch (e) {
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setClientSecretError(errorMessage);
      setClientSecret("");
    } finally {
      setIsLoading(false);
    }
  }

  function reset() {
    getClientSecret();
    setAddError("");
    setClientSecretError("");
  }

  async function onConfirm() {
    if (!stripe || !elements || !clientSecret || !!clientSecretError || !cardComplete || !!cardError) {
      return;
    }
    setIsLoading(true);

    try {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
        },
      });

      if (result.error) {
        setAddError(result.error.message || "Could not finalize adding payment method");
        onError();
      } else {
        await request({
          method: "put",
          service: "billing-ng",
          url: `/account/${accountId}/default-payment/${result.setupIntent.payment_method}`,
        });
        notification.success({
          message: "Success",
          description: `Added payment method as default`,
        });

        try {
          let hubspotutk = "";
          try {
            let _hubspotutk = document.cookie
              .split(" ")
              .find((c) => c.startsWith("hubspotutk"))!
              .split("=")[1];
            if (_hubspotutk.endsWith(";")) {
              _hubspotutk = _hubspotutk.split(";")[0];
            }
            hubspotutk = _hubspotutk;
          } catch (e) {}

          await request({
            service: "self",
            method: "post",
            url: `${HUBSPOT_SERVICE_URL}/addpaymentmethod`,
            body: {
              email: User.email,
              hubspotutk,
              environment: IS_DEPLOYMENT_ENV_TEST ? "test" : "production",
            },
          });
        } catch (e) {
          console.error(
            "Failed to set has payment method in hubspot",
            e.message,
            JSON.stringify(e.response?.data || {})
          );
        }

        onDone();
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setAddError(errorMessage);
      onError();
    } finally {
      setIsLoading(false);
    }
  }

  function onCardChange(e: StripeCardElementChangeEvent) {
    setCardComplete(e.complete);
    setCardError(e.error);
  }

  return (
    <div className="account-create-payment">
      <div className="mr-4 mb-4">Card Information</div>
      {clientSecretError ? (
        <div>
          <div className="color-danger my-2 py-2 rounded">{clientSecretError}</div>
          <NGButton variant={"primary"} onClick={reset}>
            Try Again
          </NGButton>
        </div>
      ) : addError ? (
        <div>
          <div className="color-danger my-2 rounded">An error has occurred processing your payment method:</div>
          <div className="color-danger my-2 rounded">{addError}</div>
          <NGButton variant={"primary"} onClick={reset}>
            Try Again
          </NGButton>
        </div>
      ) : (
        <>
          <div style={{ width: 450 }} className="cc border px-4 py-2">
            <CardElement options={get_CARD_ELEMENT_OPTIONS(theme === "dark")} onChange={onCardChange} />
          </div>
          <div className="consent mt-4 flex items-start" style={{ width: 550 }}>
            <NGCheckbox
              aria-label="I agree that by providing my credit card information, I authorize Control Plane Corporation to charge my credit card for the use of services provided by Control Plane Platform. I acknowledge and agree that charges will apply, based on actual usage, and that I am responsible for all applicable charges until I cancel my account or stop running services on the Control Plane Platform."
              checked={consentGiven}
              onChange={(value) => setConsentGiven(value)}
            />
            <span className="cursor-pointer ml-1 -mt-1" onClick={() => setConsentGiven((x) => !x)}>
              I agree that by providing my credit card information, I authorize Control Plane Corporation to charge my
              credit card for the use of services provided by Control Plane Platform. I acknowledge and agree that
              charges will apply, based on actual usage, and that I am responsible for all applicable charges until I
              cancel my account or stop running services on the Control Plane Platform.
            </span>
          </div>
          {IS_DEPLOYMENT_ENV_TEST ? (
            <>
              <div className="mt-4">Europe 3D Secure: 4000 0025 0000 3155</div>
              <div className="mt-4">America: 4242 4242 4242 4242</div>
              <div className="mt-4">Failing CVC: 4000 0000 0000 0101</div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export const AccountCreatePaymentMethod = observer(AccountCreatePaymentMethodRaw);
