import * as React from "react";
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { NameModel } from "../../../mobxDataModels/nameModel";
import { ServiceAccountCreateGeneral } from "./general";
import { homeLink, request, resourceLink } from "../../../services/cpln";
import { CreateFormLink } from "../../org/create/create";
import { TagsNewModel } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { ngParseLink } from "../../../utils/linkParser/linkParser";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { Group } from "../../../schema/types/group";

const ServiceAccountCreateRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const basePath = useBasePath("/serviceaccount/-create/*");

  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));
  const tagsRef = React.useRef(TagsNewModel.create());
  const [groupName, setGroupName] = React.useState("viewers");

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  React.useEffect(() => {
    let res = false;
    if (nameRef.current.isDirty) res = true;
    if (descriptionRef.current.isDirty) res = true;
    if (tagsRef.current.isDirty) res = true;
  }, [nameRef.current.isDirty, descriptionRef.current.isDirty, tagsRef.current.isDirty]);

  function getServiceAccountObject() {
    let body: any = {
      name: nameRef.current.value,
      description: descriptionRef.current.value || nameRef.current.value,
      tags: tagsRef.current.asObject,
    };
    return { kind: "serviceaccount", ...body };
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({ method: "post", url: homeLink("serviceaccount"), body: getServiceAccountObject() });
      const itemLink = resourceLink("serviceaccount", getServiceAccountObject().name);

      if (!!groupName) {
        try {
          const groupLink = ngParseLink(groupName, { kind: "group" }).absolute;
          const { data: groupItem } = await request<Group>({ method: "get", url: groupLink });
          if (!groupItem.memberLinks) {
            groupItem.memberLinks = [];
          }
          groupItem.memberLinks.push(itemLink);
          await request({ method: "patch", url: groupLink, body: groupItem });
        } catch (e) {
          notification.success({
            message: "Failed to add to the group.",
            description: "Service Account is created but could not be added to the selected group.",
          });
        }
      }

      notification.success({
        message: "Success",
        description: "Service Account is created",
      });
      PromptContext.setIsDisabled(true);
      navigate(`/console${itemLink}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    let res = true;
    const allowedList = ["-general", "-tags"];
    for (let allowItem of allowedList) {
      if (pathname.includes(allowItem)) res = false;
    }
    return res;
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    { name: "Tags", value: `-tags`, isActive: nameRef.current.isValid },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("/-general")) {
    next.isActive = nameRef.current.isValid;
    next.label = `Next (Tags)`;
    next.url = `-tags`;
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={getServiceAccountObject}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Service Account"}
          name={nameRef.current.value}
          canCreate={nameRef.current.isValid && tagsRef.current.isValid}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route
              path={`-general`}
              element={
                <ServiceAccountCreateGeneral
                  name={nameRef.current}
                  description={descriptionRef.current}
                  group={groupName}
                  setGroup={setGroupName}
                />
              }
            />
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const ServiceAccountCreate = observer(ServiceAccountCreateRaw);
