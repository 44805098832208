import * as React from "react";
import { Kind } from "../../../mst/base";

export function useTableKindId(kind: Kind, id: string): { kind: Kind; id: string } {
  const kindString = React.useRef<string>(kind);
  const idString = React.useRef<string>(id);
  React.useEffect(() => {
    kindString.current = kind;
  }, [kind]);
  React.useEffect(() => {
    idString.current = id;
  }, [id]);
  return {
    kind: kindString.current as Kind,
    id: idString.current,
  };
}
