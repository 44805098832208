import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderAWS } from "./aws";
import { ProviderAWSAdvanced } from "./advanced";
import { ProviderAWSNodePool } from "./nodePool";
import { ProviderAWSNodePoolAdd } from "./nodePoolAdd";
import { ProviderAWSNodePools } from "./nodePools";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderAWSRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderAWS mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderAWSNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<ProviderAWSNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<ProviderAWSNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<ProviderAWSAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderAWSRoute = observer(ProviderAWSRouteRaw);
