import { types, Instance, getParent } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { MemoryModel } from "../../mobxDataModels/memoryModel";
import { CpuModel } from "../../mobxDataModels/cpuModel";
import { EnvVarsModel } from "../../mobxDataModels/envVarsModel";
import { WorkloadDraftContainerProbeModel } from "./workload.draft.container.probe";
import { WorkloadDraftMobx, WorkloadDraftStoreMobx } from "./workload.draft";
import { defaultValues } from "../base";
import { StringWithImageRefModel } from "../../mobxDataModels/stringWithImageRefModel";
import { VolumesModel } from "./workload.draft.container.volume";
import { WorkloadDraftContainerMetricsModel } from "./workload.draft.container.metrics";
import { convertMemoryStringToNumber, convertCPUStringToNumber } from "../../services/utils";
import { WorkloadDraftContainerGPUModel } from "./workload.draft.container.gpu";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { ContainerPortModel, ContainerPortReadOnly } from "./workload.draft.container.port";
import { NameModel } from "../../mobxDataModels/nameModel";

export const WorkloadDraftContainerModel = types
  .model({
    _capacityAI: types.optional(types.boolean, true),
    _name: types.optional(types.string, ""),
    _image: types.optional(types.string, ""),
    _ports: types.array(ContainerPortReadOnly),
    _command: types.optional(types.string, ""),
    _postStartCommand: types.optional(types.string, ""),
    _preStopCommand: types.optional(types.string, ""),
    _workingDir: types.optional(types.string, ""),
    _inheritEnv: types.optional(types.boolean, false),
    name: types.optional(StringModel, () =>
      NameModel.create({
        validationKey: "containerName",
      }),
    ),
    isNameManuallyChanged: types.optional(types.boolean, false),
    image: types.optional(StringWithImageRefModel, () =>
      StringWithImageRefModel.create({
        label: "Image",
        kind: "image",
        shortcutKey: "i",
      }),
    ),
    _servesTraffic: false,
    servesTraffic: false,
    ports: types.array(ContainerPortModel),
    command: types.optional(types.string, ""),
    overrideCommand: types.optional(types.boolean, false),
    args: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    usePostStartHook: types.optional(types.boolean, false),
    postStartCommand: types.optional(types.string, ""),
    postStartArgs: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    usePreStopHook: types.optional(types.boolean, false),
    preStopCommand: types.optional(types.string, ""),
    preStopArgs: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    workingDir: types.optional(StringModel, () =>
      StringModel.create({ label: "Working Dir", validationKey: "workingDir" }),
    ),
    overrideWorkingDir: types.optional(types.boolean, false),
    memory: types.optional(MemoryModel, () =>
      MemoryModel.create({ _label: "Max", _defaultValue: defaultValues.memory }),
    ),
    cpu: types.optional(CpuModel, () => CpuModel.create({ _label: "Max", _defaultValue: defaultValues.cpu })),
    minMemory: types.optional(MemoryModel, () => MemoryModel.create({ _label: "Min", _defaultValue: "0Mi" })),
    minCpu: types.optional(CpuModel, () => CpuModel.create({ _label: "Min", _defaultValue: "0m" })),
    inheritEnv: types.optional(types.boolean, false),
    env: types.optional(EnvVarsModel, () => EnvVarsModel.create()),
    volumes: types.optional(VolumesModel, () => VolumesModel.create()),
    _useReadinessProbe: types.optional(types.boolean, false),
    useReadinessProbe: types.optional(types.boolean, false),
    readinessProbe: types.maybe(WorkloadDraftContainerProbeModel),
    _useLivenessProbe: types.optional(types.boolean, false),
    useLivenessProbe: types.optional(types.boolean, false),
    livenessProbe: types.maybe(WorkloadDraftContainerProbeModel),
    _useGPU: types.optional(types.boolean, false),
    useGPU: types.optional(types.boolean, false),
    gpu: types.optional(WorkloadDraftContainerGPUModel, () => WorkloadDraftContainerGPUModel.create()),
    metrics: types.optional(WorkloadDraftContainerMetricsModel, () => WorkloadDraftContainerMetricsModel.create()),
  })
  .views((self) => ({
    get isValidMemoryCpuRatio() {
      const memoryNumber = convertMemoryStringToNumber(self.memory.value);
      const cpuNumber = convertCPUStringToNumber(self.cpu.value);
      if (memoryNumber / cpuNumber > 8) {
        return { value: false, suggestion: Math.ceil(memoryNumber / 8) };
      }
      return { value: true, suggestion: 0 };
    },
    get isValidMemoryCpuForGpu() {
      if (!self.useGPU) {
        return { value: true, cpuSuggestion: 0, memorySuggestion: 0, message: "" };
      }
      if (!self.gpu.isValid) {
        return { value: true, cpuSuggestion: 0, memorySuggestion: 0, message: "" };
      }

      const memoryNumber = convertMemoryStringToNumber(self.memory.value);
      const cpuNumber = convertCPUStringToNumber(self.cpu.value);

      if (self.gpu.vendor.value === "nvidia") {
        const gpuQuantity = Number(self.gpu.nvidia_quantity.value);
        let minCpu = 2000;
        let maxCpu = 8000 * gpuQuantity;
        let minMemoryGB = 7 * gpuQuantity;
        let maxMemoryGB = 31 * gpuQuantity;

        const nvidiaModel = self.gpu.nvidia_model.value;

        if (nvidiaModel == "t4") {
          if (gpuQuantity > 1 || memoryNumber > 14000) {
            minCpu = gpuQuantity * 4000;
          }
        }

        if (nvidiaModel == "a10g") {
          minCpu = 6000 * gpuQuantity;
          minMemoryGB = 8;
          maxMemoryGB = 62;
        }

        // only for nvidia t4
        let result = { value: true, cpuSuggestion: 0, memorySuggestion: 0, message: "" };

        let cpuWords = "";
        if (cpuNumber < minCpu) {
          result.value = false;
          result.cpuSuggestion = minCpu;
          cpuWords = "at least";
        }
        if (cpuNumber > maxCpu) {
          result.value = false;
          result.cpuSuggestion = maxCpu;
          cpuWords = "at most";
        }

        let memoryWords = "";
        if (memoryNumber < minMemoryGB * 1024) {
          result.value = false;
          result.memorySuggestion = minMemoryGB;
          memoryWords = "at least";
        }
        if (memoryNumber > maxMemoryGB * 1024) {
          result.value = false;
          result.memorySuggestion = maxMemoryGB;
          memoryWords = "at most";
        }

        if (result.cpuSuggestion && result.memorySuggestion) {
          result.message = `GPU requires this container to have ${cpuWords} ${(result.cpuSuggestion / 1000).toFixed(
            2,
          )} CPU Cores and ${memoryWords} ${result.memorySuggestion} Gi RAM`;
        } else if (result.cpuSuggestion) {
          result.message = `GPU requires this container to have ${cpuWords} ${(result.cpuSuggestion / 1000).toFixed(
            2,
          )} CPU Cores`;
        } else if (result.memorySuggestion) {
          result.message = `GPU requires this container to have ${memoryWords} ${result.memorySuggestion} Gi RAM`;
        }

        return result;
      }

      return { value: true, cpuSuggestion: 0, memorySuggestion: 0, message: "" };
    },
    get envCPLN_GVC(): string {
      // gvc link
      const draft: WorkloadDraftStoreMobx = getParent(self, 2);
      return draft.gvcLink.split("/")[4];
    },
    get envCPLN_ORG(): string {
      // org link
      const draft: WorkloadDraftStoreMobx = getParent(self, 2);
      return draft.orgLink.split("/")[2];
    },
    get envCPLN_WORKLOAD(): string {
      // workload link
      const draft: WorkloadDraftStoreMobx = getParent(self, 2);
      return `${draft.gvcLink}/workload/${draft.edit!.name.value}`;
    },
    get envCPLN_GVC_ALIAS(): string {
      // gvc alias
      const draft: WorkloadDraftStoreMobx = getParent(self, 2);
      return draft.gvcAlias;
    },
  }))
  .actions((self) => ({
    setIsNameManuallyChanged(value: boolean) {
      self.isNameManuallyChanged = value;
    },
    setServerlessProtocolValue(value: string) {
      self.ports[0].procotol.setValue(value);
    },
    setInheritEnv(value: boolean) {
      self.inheritEnv = value;
    },
    resetPorts() {
      self.ports.clear();
      for (let _port of self._ports) {
        const portInstance = ContainerPortModel.create();
        portInstance.number.setInitialValue(String(_port.number));
        portInstance.procotol.setInitialValue(_port.protocol);
        self.ports.push(portInstance);
      }
    },
    updateMinResourceUnits(asDefault: boolean) {
      if (!self.minCpu.amount.value) {
        if (asDefault) {
          self.minCpu.setInitialUnit(self.cpu.unit.value as any);
        } else {
          self.minCpu.setUnit(self.cpu.unit.value as any);
        }
      }
      if (!self.minMemory.amount.value) {
        if (asDefault) {
          self.minMemory.setInitialUnit(self.memory.unit.value);
        } else {
          self.minMemory.setUnit(self.memory.unit.value);
        }
      }
    },
  }))
  .actions((self) => ({
    reset() {
      self.name.setInitialValue(self._name);
      self.name.clearOverrideError();
      self.image.setInitialValue(self._image);
      self.servesTraffic = self._servesTraffic;
      self.resetPorts();
      self.command = self._command;
      self.overrideCommand = self._command.length > 0;
      self.args.reset();
      self.postStartCommand = self._postStartCommand;
      self.usePostStartHook = self._postStartCommand.length > 0;
      self.postStartArgs.reset();
      self.preStopCommand = self._preStopCommand;
      self.usePreStopHook = self._preStopCommand.length > 0;
      self.preStopArgs.reset();
      self.workingDir.setInitialValue(self._workingDir);
      self.workingDir.reset();
      self.overrideWorkingDir = self._workingDir.length > 0;
      self.memory.reset();
      self.cpu.reset();
      self.minMemory.reset();
      self.minCpu.reset();
      self.updateMinResourceUnits(true);
      self.inheritEnv = self._inheritEnv;
      self.env.reset();
      self.volumes.reset();
      self.useReadinessProbe = self._useReadinessProbe;
      if (self._useReadinessProbe) {
        self.readinessProbe?.reset();
      }
      self.useLivenessProbe = self._useLivenessProbe;
      if (self._useLivenessProbe) {
        self.livenessProbe?.reset();
      }
      self.useGPU = self._useGPU;
      if (self._useGPU) {
        self.gpu.reset();
      }
      self.metrics.reset();
    },
  }))
  // Standard Workload Port Commands
  .actions((self) => ({
    addPort() {
      self.ports.push(ContainerPortModel.create());
    },
    removePort(index: number) {
      self.ports.remove(self.ports[index]);
    },
  }))
  .actions((self) => ({
    setUseReadinessProbe(value: boolean) {
      self.useReadinessProbe = value;
      if (value && !self.readinessProbe) {
        self.readinessProbe = WorkloadDraftContainerProbeModel.create();
      }
      if (self.readinessProbe) {
        self.readinessProbe.updateMethodOptions();
      }
    },
    setUseLivenessProbe(value: boolean) {
      self.useLivenessProbe = value;
      if (value && !self.livenessProbe) {
        self.livenessProbe = WorkloadDraftContainerProbeModel.create();
      }
      if (self.livenessProbe) {
        self.livenessProbe.updateMethodOptions();
      }
    },
    setUseGPU(value: boolean) {
      self.useGPU = value;
      if (value && !self.gpu) {
        self.gpu = WorkloadDraftContainerGPUModel.create();
      }
    },
    resetUseGPU() {
      const initialValue = self._useGPU;
      self.useGPU = initialValue;
      if (initialValue) {
        self.gpu.reset();
      }
    },
    setUsePostStartHook(value: boolean) {
      self.usePostStartHook = value;
    },
    setUsePreStopHook(value: boolean) {
      self.usePreStopHook = value;
    },
  }))
  .actions((self) => ({
    manualAfterAttach() {
      const parent: WorkloadDraftMobx = getParent(self);
      if (parent.isServerless) {
        const servesTraffic = self._ports.length === 1;
        self._servesTraffic = servesTraffic;
        self.ports.push(ContainerPortModel.create());
        self.ports[0].number.setInitialValue(
          servesTraffic ? String(self._ports[0].number) : String(defaultValues.port.default),
        );
      } else if (parent.isStandard || parent.isStateful) {
        self.ports.clear();
        for (let _port of self._ports) {
          const portInstance = ContainerPortModel.create();
          portInstance.number.setInitialValue(String(_port.number));
          portInstance.procotol.setInitialValue(_port.protocol);
          self.ports.push(portInstance);
        }
      }
      if (self.readinessProbe) self._useReadinessProbe = true;
      if (self.livenessProbe) self._useLivenessProbe = true;
      self.reset();
    },
    setCapacityAI(value: boolean) {
      self._capacityAI = value;
      self.cpu.setLabel(value ? "Max" : "Reserved");
      self.memory.setLabel(value ? "Max" : "Reserved");
    },
    setServesTraffic(value: boolean) {
      self.servesTraffic = value;
      if (self.ports.length < 1) {
        self.ports.push(ContainerPortModel.create());
      }
    },
    resetServesTraffic() {
      const initialValue = self._servesTraffic;
      self.servesTraffic = initialValue;
      if (initialValue) {
        self.resetPorts();
      }
    },
    setCommand(value: string) {
      self.command = value;
    },
    setOverrideCommand(value: boolean) {
      self.overrideCommand = value;
    },
    setPostStartCommand(value: string) {
      self.postStartCommand = value;
    },
    setPreStopCommand(value: string) {
      self.preStopCommand = value;
    },
    setOverrideWorkingDir(value: boolean) {
      self.overrideWorkingDir = value;
    },
    // TODO can try to auto handle // -m myMode -c "hello world"
    confirm() {
      self._name = self.name.value;
      self.name.confirm();
      self._image = self.image.value;
      self.image.confirm();
      const parent: WorkloadDraftMobx = getParent(self);
      if (parent.isServerless) {
        self._servesTraffic = self.servesTraffic;
        self._ports.clear();
        if (self.servesTraffic) {
          self._ports.push(self.ports[0].asObject);
          self.ports[0].confirm();
        }
      } else if (parent.isStandard || parent.isStateful) {
        self._ports.clear();
        for (let port of self.ports) {
          self._ports.push(port.asObject);
        }
        for (let port of self.ports) {
          port.confirm();
        }
      }

      self._command = self.overrideCommand ? self.command : "";
      self.args.confirm();

      self._postStartCommand = self.usePostStartHook ? self.postStartCommand : "";
      self.postStartArgs.confirm();

      self._preStopCommand = self.usePreStopHook ? self.preStopCommand : "";
      self.preStopArgs.confirm();

      self._workingDir = self.overrideWorkingDir ? self.workingDir.value : "";
      self.workingDir.confirm();
      self.memory.confirm();
      self.cpu.confirm();
      self.minMemory.confirm();
      self.minCpu.confirm();
      self._inheritEnv = self.inheritEnv;
      self.env.confirm();
      self.volumes.confirm();
      self._useReadinessProbe = self.useReadinessProbe;
      if (self.useReadinessProbe && self.readinessProbe) {
        self.readinessProbe.confirm();
      }
      self._useLivenessProbe = self.useLivenessProbe;
      if (self.useLivenessProbe && self.livenessProbe) {
        self.livenessProbe.confirm();
      }
      self._useGPU = self.useGPU;
      if (self.useGPU && self.gpu) {
        self.gpu.confirm();
      }
      self.metrics.confirm();
    },
  }))
  .views((self) => ({
    // command and args are separate properties
    get argsArray() {
      return self.args.items.map((i) => i.firstValue);
    },
    // command and args are same properties where the first element is the command
    get postStartCommandsArray() {
      return [self.postStartCommand, ...self.postStartArgs.items.map((i) => i.firstValue)];
    },
    // command and args are same properties where the first element is the command
    get preStopCommandsArray() {
      return [self.preStopCommand, ...self.preStopArgs.items.map((i) => i.firstValue)];
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      const parent: WorkloadDraftMobx = getParent(self);
      if (self.name.isDirty) res = true;
      if (self.image.isDirty) res = true;
      if (parent.isServerless) {
        if (self.servesTraffic !== self._servesTraffic) res = true;
        if (self.servesTraffic && self.ports[0].isDirty) res = true;
      } else if (parent.isStandard || parent.isStateful) {
        if (self._ports.length !== self.ports.length) res = true;
        for (let port of self.ports) {
          if (port.isDirty) res = true;
        }
      }

      const _overrideCommand = self._command.length > 0;
      if (_overrideCommand !== self.overrideCommand) res = true;
      if (self.overrideCommand) {
        if (self._command !== self.command) res = true;
      }
      if (self.args.isDirty) res = true;

      const _usePostStartHook = self._postStartCommand.length > 0;
      if (_usePostStartHook !== self.usePostStartHook) res = true;
      if (self.usePostStartHook) {
        if (self._postStartCommand !== self.postStartCommand) res = true;
        if (self.postStartArgs.isDirty) res = true;
      }

      const _usePreStopHook = self._preStopCommand.length > 0;
      if (_usePreStopHook !== self.usePreStopHook) res = true;
      if (self.usePreStopHook) {
        if (self._preStopCommand !== self.preStopCommand) res = true;
        if (self.preStopArgs.isDirty) res = true;
      }

      if (self.memory.isDirty) res = true;
      if (self.cpu.isDirty) res = true;
      if (self._capacityAI) {
        if (self.minMemory.isDirty) res = true;
        if (self.minCpu.isDirty) res = true;
      }
      if (self.inheritEnv !== self._inheritEnv) res = true;
      if (self.env.isDirty) res = true;

      const _overrideWorkingDir = self._workingDir.length > 0;
      if (_overrideWorkingDir !== self.overrideWorkingDir) res = true;
      if (self.overrideWorkingDir) {
        if (self.workingDir.isDirty) res = true;
      }

      if (self.volumes.isDirty) res = true;
      if (self._useReadinessProbe !== self.useReadinessProbe) res = true;
      if (self.useReadinessProbe && self.readinessProbe?.isDirty) res = true;
      if (self._useLivenessProbe !== self.useLivenessProbe) res = true;
      if (self.useLivenessProbe && self.livenessProbe?.isDirty) res = true;
      if (self._useGPU !== self.useGPU) res = true;
      if (self.useGPU && self.gpu.isDirty) res = true;
      if (self.metrics.isDirty) res = true;
      return res;
    },
    get isValid() {
      const parent: WorkloadDraftMobx = getParent(self);
      let res = true;
      if (!self.name.isValid) res = false;
      if (self.image.value.length < 1) res = false;
      if (parent.isServerless) {
        if (self.servesTraffic && !self.ports[0].isValid) res = false;
      } else if (parent.isStandard || parent.isStateful) {
        for (let port of self.ports) {
          if (!port.number.isValid) res = false;
        }
      }
      if (!self.memory.isValid) res = false;
      if (!self.cpu.isValid) res = false;
      if (self._capacityAI) {
        if (!self.minMemory.isValid) res = false;
        if (!self.minCpu.isValid) res = false;
      }

      if (self.overrideCommand) {
        if (!self.command) res = false;
      }
      if (self.args.items.some((arg) => !arg.firstValue)) res = false;
      if (self.usePostStartHook) {
        if (!self.postStartCommand) res = false;
        if (self.postStartArgs.items.some((arg) => !arg.firstValue)) res = false;
      }
      if (self.usePreStopHook) {
        if (!self.preStopCommand) res = false;
        if (self.preStopArgs.items.some((arg) => !arg.firstValue)) res = false;
      }

      if (!self.env.isValid) res = false;
      if (!self.volumes.isValid) res = false;
      if (self.overrideWorkingDir) {
        if (!self.workingDir.isValid) res = false;
      }
      if (!parent.isCron) {
        if (self.useReadinessProbe && !self.readinessProbe?.isValid) res = false;
        if (self.useLivenessProbe && !self.livenessProbe?.isValid) res = false;
      }
      if (self.useGPU) {
        if (!self.gpu.isValid) res = false;
        if (!self.isValidMemoryCpuForGpu) res = false;
      }
      if (!self.metrics.isValid) res = false;
      if (!self.isValidMemoryCpuRatio.value) res = false;
      if (!self.isValidMemoryCpuForGpu.value) res = false;
      return res;
    },
    get invalidReason() {
      const parent: WorkloadDraftMobx = getParent(self);
      let reason = "";
      if (!self.name.isValid) reason = "name";
      if (self.image.value.length < 1) reason = "image";
      if (parent.isServerless) {
        if (self.servesTraffic && !self.ports[0].isValid) reason = "serve traffic port";
      } else if (parent.isStandard || parent.isStateful) {
        for (let port of self.ports) {
          if (!port.number.isValid) reason = "port number";
        }
      }
      if (!self.memory.isValid) reason = "memory";
      if (!self.cpu.isValid) reason = "cpu";
      if (self._capacityAI) {
        if (!self.minMemory.isValid) reason = "memory";
        if (!self.minCpu.isValid) reason = "cpu";
      }

      if (self.overrideCommand) {
        if (!self.command) reason = "empty command";
      }
      if (self.args.items.some((arg) => !arg.firstValue)) reason = "empty arg";
      if (self.usePostStartHook) {
        if (!self.postStartCommand) reason = "empty post start command";
        if (self.postStartArgs.items.some((arg) => !arg.firstValue)) reason = "empty post start arg";
      }
      if (self.usePreStopHook) {
        if (!self.preStopCommand) reason = "empty pre stop command";
        if (self.preStopArgs.items.some((arg) => !arg.firstValue)) reason = "empty pre stop arg";
      }

      if (!self.env.isValid) reason = "env";
      if (!self.volumes.isValid) reason = "volumes";
      if (self.overrideWorkingDir) {
        if (!self.workingDir.isValid) reason = self.workingDir.invalidReason;
      }
      if (!parent.isCron) {
        if (self.useReadinessProbe && !self.readinessProbe?.isValid) reason = "readiness";
        if (self.useLivenessProbe && !self.livenessProbe?.isValid) reason = "liveness";
      }
      if (self.useGPU) {
        if (!self.gpu.isValid) reason = "gpu res";
        if (!self.isValidMemoryCpuForGpu) reason = "invalid memory cpu for gpu";
      }
      if (!self.metrics.isValid) reason = "metrics";
      if (!self.isValidMemoryCpuRatio.value) reason = "invalid memory cpu ratio";
      if (!self.isValidMemoryCpuForGpu.value) reason = "invalid memory cpu for gpu";
      return reason;
    },
    get asObject() {
      const res: any = {
        name: self.name.value,
        image: self.image.value,
        memory: self.memory.value,
        cpu: self.cpu.value,
        inheritEnv: self.inheritEnv,
      };
      if (self._capacityAI) {
        if (self.minMemory.value) {
          res.minMemory = self.minMemory.value;
        }
        if (self.minCpu.amount.value) {
          res.minCpu = self.minCpu.value;
        }
      }
      if (self.overrideCommand) {
        res.command = self.command;
      }
      if (self.argsArray.length > 0) {
        res.args = self.argsArray;
      }
      if (self.usePostStartHook) {
        res.lifecycle = {
          postStart: {
            exec: {
              command: self.postStartCommandsArray,
            },
          },
        };
      }

      if (self.usePreStopHook) {
        if (!res.lifecycle) {
          res.lifecycle = {};
        }
        res.lifecycle.preStop = {
          exec: {
            command: self.preStopCommandsArray,
          },
        };
      }

      if (self.overrideWorkingDir && self.workingDir.value) {
        res.workingDir = self.workingDir.value;
      }
      if (self.env.asArray.length > 0) {
        res.env = self.env.asArray;
      }
      if (self.volumes.asArray.length > 0) {
        res.volumes = self.volumes.asArray;
      }

      const parent: WorkloadDraftMobx = getParent(self);
      if (parent.isServerless) {
        if (self.servesTraffic) {
          res.ports = [self.ports[0].asObject];
        }
      } else if (parent.isStateful || parent.isStandard) {
        res.ports = self.ports.map((p) => p.asObject);
      }
      if (self.useReadinessProbe) {
        res.readinessProbe = self.readinessProbe!.asObject;
      }
      if (self.useLivenessProbe) {
        res.livenessProbe = self.livenessProbe!.asObject;
      }
      if (self.useGPU) {
        res.gpu = self.gpu.asObject;
      }
      if (self.metrics.useMetrics) {
        res.metrics = self.metrics.asObject;
      }
      if (parent.isCron) {
        delete res.port;
        delete res.ports;
        delete res.readinessProbe;
        delete res.livenessProbe;
      }
      return res;
    },
  }))
  .views((self) => ({
    get readinessProbeDefault() {
      if (self.useReadinessProbe) {
        return;
      }
      const parent: WorkloadDraftMobx = getParent(self);
      if (parent.isCron || parent.isStandard || parent.isStateful) {
        return;
      }

      if (!self.servesTraffic) {
        return;
      }

      return {
        type: "readiness",
        tcpSocket: {
          port: self.ports[0].number.value,
        },
        initialDelaySeconds: 0,
        periodSeconds: 10,
        timeoutSeconds: 1,
        successThreshold: 1,
        failureThreshold: 3,
      };
    },
  }))
  .views((self) => ({
    // liveness defaults to readiness
    // default for both, for serverless is tcp check
    // initial wait period is 60
    get livenessProbeDefault() {
      if (self.useLivenessProbe) {
        return;
      }
      if (self.useReadinessProbe && self.readinessProbe) {
        const liveness = JSON.parse(JSON.stringify(self.readinessProbe.asObject));
        liveness.initialDelaySeconds = 60;
        liveness.type = "liveness";
        return liveness;
      }

      if (!self.readinessProbeDefault) {
        return;
      }

      const liveness = JSON.parse(JSON.stringify(self.readinessProbeDefault));
      liveness.initialDelaySeconds = 60;
      liveness.type = "liveness";
      return liveness;
    },
  }))
  .views((self) => ({
    get isDirtyReason() {
      let reason = "";
      const parent: WorkloadDraftMobx = getParent(self);
      if (self.name.isDirty) reason = self.name.isDirtyReason;
      if (self.image.isDirty) reason = self.image.isDirtyReason;
      if (parent.isServerless) {
        if (self.servesTraffic !== self._servesTraffic) reason = "serves traffic";
        if (self.servesTraffic && self.ports[0].isDirty) reason = "first port dirty";
      } else if (parent.isStandard || parent.isStateful) {
        if (self._ports.length !== self.ports.length) reason = "ports length";
        for (let port of self.ports) {
          if (port.isDirty) reason = "port dirty";
        }
      }

      const _overrideCommand = self._command.length > 0;
      if (_overrideCommand !== self.overrideCommand) reason = "override command";
      if (self.overrideCommand) {
        if (self._command !== self.command) reason = "command";
      }
      if (self.args.isDirty) reason = "args";

      const _usePostStartHook = self._postStartCommand.length > 0;
      if (_usePostStartHook !== self.usePostStartHook) reason = "use post start";
      if (self.usePostStartHook) {
        if (self._postStartCommand !== self.postStartCommand) reason = "post start command";
        if (self.postStartArgs.isDirty) reason = "post start args";
      }

      const _usePreStopHook = self._preStopCommand.length > 0;
      if (_usePreStopHook !== self.usePreStopHook) reason = "use pre stop";
      if (self.usePreStopHook) {
        if (self._preStopCommand !== self.preStopCommand) reason = "pre stop command";
        if (self.preStopArgs.isDirty) reason = "pre stop args";
      }

      if (self.memory.isDirty) reason = "memory";
      if (self.cpu.isDirty) reason = "cpu";
      if (self._capacityAI) {
        if (self.minMemory.isDirty) reason = "minMemory";
        if (self.minCpu.isDirty) reason = "minCpu";
      }
      if (self.inheritEnv !== self._inheritEnv) reason = "inherit env";
      if (self.env.isDirty) reason = "env";

      const _overrideWorkingDir = self._workingDir.length > 0;
      if (_overrideWorkingDir !== self.overrideWorkingDir) reason = "override working dir";
      if (self.overrideWorkingDir) {
        if (self.workingDir.isDirty) reason = "working dir";
      }

      if (self.volumes.isDirty) reason = "volumes";
      if (self._useReadinessProbe !== self.useReadinessProbe) reason = "use readiness";
      if (self.useReadinessProbe && self.readinessProbe?.isDirty) reason = "readiness";
      if (self._useLivenessProbe !== self.useLivenessProbe) reason = "use liveness";
      if (self.useLivenessProbe && self.livenessProbe?.isDirty) reason = "liveness";
      if (self._useGPU !== self.useGPU) reason = "use gpu";
      if (self.useGPU && self.gpu.isDirty) reason = "gpu";
      if (self.metrics.isDirty) reason = "metrics";
      return reason;
    },
  }));
export interface WorkloadDraftContainerMobx extends Instance<typeof WorkloadDraftContainerModel> {}
