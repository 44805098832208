import * as React from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { WorkloadDraftRolloutOptionsMobx } from "../../mst/stores/workload.draft.rolloutOptions";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { useDetailContext } from "../../components/detail/detailContext";
import { NGSwitch } from "../../newcomponents/switch";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";

interface Props {
  draft: WorkloadDraftMobx;
  rolloutOptions: WorkloadDraftRolloutOptionsMobx;
  patch: (body: any) => Promise<void>;
}
const RolloutOptionsRaw: React.FC<Props> = ({ draft, rolloutOptions: options, patch }) => {
  const { fetchItem } = useDetailContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    options.reset();
    return () => {
      options.reset();
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();

  React.useEffect(() => {
    setPromptWhen(options.isDirty);
  }, [options.isDirty]);

  async function save() {
    try {
      setIsLoading(true);
      await patch(draft.asPatch);
      options.confirm();
      notification.success({
        message: "Success",
        description: "Workload is updated",
      });
      updateLastDeploymentTimeOnHubspot();
      setPromptWhen(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
      setPromptWhen(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div>
        <div className="mb-4 flex items-center">
          <NGSwitch
            data-testid="switch-capacity-ai"
            value={draft.rolloutOptions.configure}
            onChange={draft.rolloutOptions.setConfigure}
          >
            <NGLabelText>Configure Rollout Options</NGLabelText>
          </NGSwitch>
          {/* <ReachTooltip
            contents={[
              "Enables intelligent dynamic resource allocation of CPU and Memory for each container by setting resource reservations using analysis of historical usage data.",
              "This can significantly reduce cost but may cause temporary performance issues for workloads with sudden spikes in usage.",
            ]}
            info
          /> */}
        </div>
        {draft.rolloutOptions.configure ? (
          <>
            <NGFormElement
              label={options.minReadySeconds.label}
              name={`minReadySeconds`}
              value={options.minReadySeconds.value}
              onChange={options.minReadySeconds.setValue}
            />
            <NGFormElement
              label={options.maxSurgeReplicas.label}
              name={`maxSurgeReplicas`}
              value={options.maxSurgeReplicas.value}
              onChange={options.maxSurgeReplicas.setValue}
            />
            <NGFormElement
              name={"scalingPolicy"}
              as={"select"}
              label={options.scalingPolicy.label}
              options={options.scalingPolicy.options}
              value={options.scalingPolicy.value}
              onChange={options.scalingPolicy.setValue}
            />
          </>
        ) : null}
        <div className="mt-8 flex items-center">
          <NGButton
            disabled={!options.isDirty || isLoading}
            style={{ width: 215, marginRight: 10 }}
            onClick={options.reset}
            variant={"danger"}
            outlined
          >
            Reset
          </NGButton>
          <NGButton
            disabled={!options.isDirty || !options.isValid || isLoading}
            loading={isLoading}
            style={{ width: 215, marginLeft: 10 }}
            onClick={save}
            variant={"primary"}
          >
            Save
          </NGButton>
        </div>
      </div>
    </>
  );
};

export const RolloutOptions = observer(RolloutOptionsRaw);
