import * as React from "react";
import { observer } from "mobx-react-lite";
import { DomainDraftMobx } from "../../../mst/stores/domain.draft";
import { NGButton } from "../../../newcomponents/button/Button";

interface Props {
  draft: DomainDraftMobx;
  onClick?: any;
}
const CNAMECardRaw: React.FC<Props> = ({ draft, onClick }) => {
  return (
    <div className={`${onClick ? "border px-6 py-4" : ""} rounded flex-1`}>
      <div>CNAME & Path Routing</div>
      <ul className="mb-2">
        <li className="list-disc text-sm ml-6">A CNAME record is configured in DNS for each domain</li>
        <li className="list-disc text-sm ml-6">Best choice when Path Based Routing is required</li>
        <li className="list-disc text-sm ml-6">Maintain full control over DNS</li>
        <li className="list-disc text-sm ml-6">CDN/WAF Compatible</li>
      </ul>
      {onClick ? (
        <>
          <div className="text-sm">Example Endpoints</div>
          <ul className="mb-2">
            <li className="text-sm ml-2">https://{draft.name.value}/</li>
            <li className="text-sm ml-2">https://{draft.name.value}/workload_one</li>
            <li className="text-sm ml-2">https://{draft.name.value}/workload_two</li>
            <li className="text-sm ml-2">
              https://{draft.name.value}/{`<any>`}
            </li>
          </ul>
          <NGButton variant="primary" onClick={onClick}>
            Use CNAME Record & Path Routing
          </NGButton>
        </>
      ) : null}
    </div>
  );
};

export const CNAMECard = observer(CNAMECardRaw);
