import * as React from "react";
import { Modal } from "antd";
import { request, resourceLink } from "../../services/cpln";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { NGButton } from "../../newcomponents/button/Button";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGCombobox } from "../../newcomponents/select/ngcombobox";
import { Workload } from "../../schema/types/workload/workload";

interface SelectWorkloadModalProps {
  onClose: () => void;
  kind: "gvc" | "org";
  endpoint: string;
  setEndpoint: (value: string) => void;
}

// TODO fix type here

export const SelectWorkloadModal: React.FC<SelectWorkloadModalProps> = ({ onClose, kind, endpoint, setEndpoint }) => {
  const [workloadInstance, setWorkloadInstance] = React.useState<Workload | null>(null);
  const [portOptions, setPortOptions] = React.useState<{ label: string; value: string }[]>([]);
  const [gvc, setGvc] = React.useState(ConsoleContext.gvc || "");
  const [workload, setWorkload] = React.useState("");
  const [port, setPort] = React.useState("");

  React.useEffect(() => {
    const result = parseEndpoint(endpoint);
    if (result) {
      const { endpointWorkload, endpointGVC, endpointPort } = result;
      if (endpointWorkload && endpointGVC) fetchWorkload(endpointWorkload, endpointGVC);
      setGvc(endpointGVC || "");
      setWorkload(endpointWorkload || "");
      setPort(endpointPort || "");
    }
  }, []);

  React.useEffect(() => {
    const portOptions: { label: string; value: string }[] = [];
    for (const container of workloadInstance?.spec?.containers || []) {
      if (container.ports && Array.isArray(container.ports) && container.ports.length > 0) {
        for (const port of container.ports) {
          portOptions.push({ label: port.number.toString(), value: port.number.toString() });
        }
      } else if (container.port) {
        portOptions.push({ label: container.port.toString(), value: container.port.toString() });
      }
    }
    setPortOptions(portOptions);
  }, [workloadInstance]);

  function parseEndpoint(input: string): { endpointWorkload: string; endpointGVC: string; endpointPort?: string } {
    const regex = /([^.]+)\.([^.]+)\.cpln\.local(:\d+)?/;
    const match = input.match(regex);

    if (!match) {
      return { endpointWorkload: "", endpointGVC: "", endpointPort: "" };
    } else {
      const [_, endpointWorkload, endpointGVC, endpointPort] = match;

      return { endpointWorkload, endpointGVC, endpointPort: endpointPort ? endpointPort.slice(1) : undefined };
    }
  }

  async function fetchWorkload(workload: string, gvc: string) {
    try {
      let { data } = await request({ url: resourceLink("workload", workload, { org: null, gvc }) });
      setWorkloadInstance(data);
    } catch (e) {
      setWorkloadInstance(null);
    }
  }

  return (
    <Modal
      open={true}
      title={"Select Workload"}
      onCancel={() => onClose()}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" size="normal" onClick={() => onClose()}>
            Cancel
          </NGButton>
          <NGButton
            variant="primary"
            size="normal"
            onClick={() => {
              setEndpoint(`${workload}.${ConsoleContext.gvc}.cpln.local${port && `:${port}`}`);
              onClose();
            }}
            disabled={!workload}
          >
            Save
          </NGButton>
        </div>
      }
    >
      <div className="flex flex-col space-y-4">
        {kind === "org" && (
          <NGKindSelect
            value={gvc}
            kind="gvc"
            onChange={(value) => {
              if (value !== gvc) {
                setWorkload("");
                setPort("");
              }
              setGvc(value);
            }}
          />
        )}
        <NGKindSelect
          value={workload}
          kind="workload"
          fetchAll
          onChange={(value) => {
            if (value !== workload) {
              setPort("");
              fetchWorkload(value, ConsoleContext.gvc || "");
            }
            setWorkload(value);
          }}
          queries={[{ rel: "gvc", value: kind === "org" ? gvc : ConsoleContext.gvc! }]}
        />
        {workload && (
          <NGCombobox
            value={port}
            placeholder="Port (Optional)"
            options={portOptions}
            onChange={(value) => setPort(value)}
          />
        )}
      </div>
    </Modal>
  );
};
