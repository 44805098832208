import * as React from "react";
import { observer } from "mobx-react-lite";
import { CopyableTruncateTooltip } from "../../../components/CopyableTruncateTooltip";
import { EnvVarReadonlyMobx } from "../../../mobxDataModels/envVarsModel";
import { NGLabel } from "../../../newcomponents/text/label";

interface OtherEnvironmentVariablesProps {
  env: EnvVarReadonlyMobx[];
  ignoreCopyOfValue?: string;
}

const OtherEnvironmentVariablesRaw: React.FC<OtherEnvironmentVariablesProps> = ({ env, ignoreCopyOfValue }) => {
  return (
    <div>
      {/* Header */}
      <div className="grid grid-cols-2 gap-2 mb-1">
        <div>
          <NGLabel>Name</NGLabel>
        </div>
        <div>
          <NGLabel>Value</NGLabel>
        </div>
      </div>

      {/* Rows */}
      {env.map((e) => (
        <div key={e.name} className="grid grid-cols-2 gap-2 h-8 border-b">
          <CopyableTruncateTooltip value={e.name} overrideValueToCopy={() => `$(${e.name})`} />
          <CopyableTruncateTooltip value={e.value} isCopyDisabled={e.value === ignoreCopyOfValue} />
        </div>
      ))}
    </div>
  );
};

export const OtherEnvironmentVariables = observer(OtherEnvironmentVariablesRaw);
