import * as React from "react";
import { Layout } from "../../layout";
import { Route, Routes } from "react-router-dom";
import { GVCSelect } from "./select";
import { GVCCreate } from "./create/create";
import { GVCDetailRoute } from "./detailRoute";
import { GVCRouteIndex } from "./routeIndex";
import { observer } from "mobx-react-lite";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const GVCRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<GVCRouteIndex />} />
        <Route path={`-create/*`} element={<GVCCreate />} />
        <Route path={`-select`} element={<GVCSelect />} />
        <Route path={`:gvc/*`} element={<GVCDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(GVCRoute);
