import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel } from "../base";
import { request } from "../../services/cpln";
import { LocationSpec, LocationStatus } from "../../schema/types/location";

const LocationStatusModel = types.model("LocationStatus", {
  geo: types.maybe(
    types.model("LocationStatus.Geo", {
      continent: types.optional(types.string, ""),
      country: types.optional(types.string, ""),
      lat: types.optional(types.number, 0),
      lon: types.optional(types.number, 0),
      state: types.optional(types.string, ""),
      city: types.optional(types.string, ""),
    })
  ),
  ipRanges: types.array(types.string),
});
export interface LocationStatusMobx extends Instance<typeof LocationStatusModel> {}

export function getDefaultLocationStatus(): LocationStatus {
  return {
    geo: {
      continent: "",
      country: "",
      lat: 0,
      lon: 0,
      state: "",
      city: "",
    },
  };
}

const LocationSpecModel = types.model("LocationSpec", {
  enabled: types.optional(types.boolean, false),
});
export interface LocationSpecMobx extends Instance<typeof LocationSpecModel> {}

export function getDefaultLocationSpec(): LocationSpec {
  return { enabled: true };
}

export const LocationModel = types
  .compose(
    "Location",
    BaseModel,
    types.model({
      origin: types.optional(types.string, "default"),
      provider: types.optional(types.string, ""), // aws azure gcp byok linode vultr
      region: types.optional(types.string, ""),
      spec: types.optional(LocationSpecModel, () => LocationSpecModel.create()),
      status: types.optional(LocationStatusModel, () => LocationStatusModel.create()),
    })
  )
  .actions((self) => ({
    fetch: flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    }),
  }))
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface LocationMobx extends Instance<typeof LocationModel> {}
