import type { AriaCheckboxProps } from "@react-types/checkbox";
import * as React from "react";
import clsx from "clsx";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useToggleState } from "@react-stately/toggle";
import { useFocusRing } from "@react-aria/focus";
import { useCheckbox } from "@react-aria/checkbox";
import { mergeProps } from "@react-aria/utils";
import "./index.scss";

interface NGCheckboxProps extends React.PropsWithChildren, Omit<AriaCheckboxProps, "isIndeterminate"> {
  indeterminate?: boolean;
  checked?: boolean;
  labelPosition?: "left" | "right";
  className?: string;
  style?: React.CSSProperties;
}
export const NGCheckbox: React.FC<NGCheckboxProps> = ({
  indeterminate,
  checked,
  labelPosition = "right",
  className,
  style,
  ..._props
}) => {
  const props = {
    ..._props,
    isIndeterminate: indeterminate,
    isSelected: checked,
  };

  let state = useToggleState(props);
  let ref = React.useRef<HTMLInputElement>(null);
  let { inputProps } = useCheckbox(props, state, ref);
  let { focusProps, isFocused } = useFocusRing();

  function handleKeyDown(event: React.KeyboardEvent) {
    if (isFocused && (props.isReadOnly || props.isDisabled)) return;
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
      props.onChange && props.onChange(!props.isSelected);
    }
  }

  return (
    <label
      className={clsx(
        "ngcheckbox ngfocus gap-1",
        {
          disabled: props.isDisabled,
          notAllowed: props.isReadOnly || props.isDisabled,
        },
        className
      )}
      data-testid={_props?.["data-testid"]}
      onKeyDown={handleKeyDown}
      style={style}
    >
      {labelPosition === "left" && <span className={"label"}>{props.children}</span>}
      <div className={clsx("box", { labelLeft: labelPosition === "left" })}>
        <VisuallyHidden>
          <input {...mergeProps(inputProps, focusProps)} ref={ref} />
        </VisuallyHidden>
        <div
          className={clsx("box-inner", {
            disabled: props.isDisabled,
            checked: state.isSelected,
            indeterminate: props.isIndeterminate,
          })}
          aria-hidden="true"
        >
          {props.isSelected || !props.isIndeterminate ? (
            <svg className="checkmark" viewBox="0 0 18 18">
              <polyline
                points="1 9 7 14 15 4"
                fill="none"
                strokeWidth={3}
                strokeDasharray={22}
                strokeDashoffset={state.isSelected ? 44 : 66}
              />
            </svg>
          ) : (
            <svg className="horizontal-line" viewBox="0 0 18 18">
              <polyline
                points="1 9 17 9"
                fill="none"
                strokeWidth={3}
                strokeDasharray={18}
                strokeDashoffset={!state.isSelected ? 36 : 54}
              />
            </svg>
          )}
        </div>
      </div>
      {labelPosition === "right" && <span className={"label"}>{props.children}</span>}
    </label>
  );
};
