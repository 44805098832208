import * as React from "react";
import "./inputGroup.css";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

const DEFAULT_COL_SPANS = [3, 6, 3];

interface Props extends React.PropsWithChildren {
  colSpans?: number[];
  className?: string;
  style?: React.CSSProperties;
}
const InputGroupRaw: React.FC<Props> = ({ children, colSpans = DEFAULT_COL_SPANS, className = "", style = {} }) => {
  const totalColSpans = colSpans[0] + colSpans[1] + colSpans[2];
  if (totalColSpans > 12) {
    console.error("InpuGroup colSpans cannot amount to more than 12");
    colSpans = DEFAULT_COL_SPANS;
  }
  const childCount = React.Children.map(children, (child) => !(child === null))?.filter(Boolean).length;
  colSpans =
    childCount === 3
      ? colSpans
      : childCount === 2
      ? [colSpans[0], colSpans[1] + colSpans[2]]
      : [colSpans[0] + colSpans[1] + colSpans[2]];

  return (
    <>
      <div
        className={clsx(
          `inputGroup flex items-center`,
          `colSpan-first-${colSpans[0]}`,
          `colSpan-second-${colSpans[1]}`,
          `colSpan-third-${colSpans[2]}`,
          className
        )}
        style={style}
      >
        {children}
      </div>
    </>
  );
};

export const InputGroup = observer(InputGroupRaw);
