import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { UserMobx } from "../../mst/kinds/user";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  user: UserMobx;
}
const UserDetailRaw: React.FC<Props> = ({ user }) => {
  const basePath = useBasePath("/user/:idOrEmail/*");

  const eventlogHook = useEventlogs(user.selfLink);

  const [links] = React.useState([
    { label: "Info", url: "-info" },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
  ]);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info user={user} />} />
        <Route path={`-tags`} element={<Tags link={user.selfLink} tags={user.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"user"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="user" resourceId={user.id} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const UserDetail = observer(UserDetailRaw);
