import * as React from "react";
import { observer } from "mobx-react-lite";
import { useDetailContext } from "../../components/detail/detailContext";
import { FormElement } from "../../components/forms/formElement";
import { DomainMobx } from "../../mst/kinds/domain";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Tabs } from "./tabs";
import { Value } from "../../components/tag/value";
import { NGLabel } from "../../newcomponents/text/label";
import { Link } from "react-router-dom";

const DomainSpecRaw: React.FC = () => {
  const { item } = useDetailContext();
  const domain = item as DomainMobx;

  const [activePortIndex, setActivePortIndex] = React.useState(0);

  const spec = domain.spec!;

  return (
    <>
      <div className="flex items-center mb-4">
        <div className="w-4/12">
          <FormElement label="DNS Mode" value={spec.dnsMode.toUpperCase()} />
        </div>
        <div className="w-4/12">
          <FormElement
            label="Routing Mode"
            value={
              spec.routingMode === "none"
                ? "None"
                : spec.routingMode === "subdomain"
                ? "Subdomain Routing"
                : "Path Routing"
            }
          />
        </div>
        {spec.gvcLink ? (
          <div className="w-4/12">
            <NGLabel>GVC for Subdomain Routing</NGLabel>
            {spec.gvcLink.split("/gvc/")[1] === ConsoleContext.gvc ? (
              <Link className="color-link" to={`/console${spec.gvcLink}`}>
                {spec.gvcLink.split("/gvc/")[1]}
              </Link>
            ) : (
              <span>{spec.gvcLink.split("/gvc/")[1]}</span>
            )}
          </div>
        ) : null}
      </div>
      <NGLabel>Ports</NGLabel>
      <Tabs
        tabs={
          spec.ports.map((p, index) => ({
            label: `${p.number}/${p.protocol.toUpperCase()}`,
            isActive: index === activePortIndex,
            invalid: false,
            hide: false,
            onClick: () => setActivePortIndex(index),
          })) as any
        }
      />

      <div>
        {spec.ports.length < 1 ? <span>No Ports Found</span> : null}
        {spec.ports.map((port, index) => {
          if (index !== activePortIndex) {
            return null;
          }

          const hasRoutes = port.routes.length > 0;
          const hasHostPrefix = port.routes.some((r) => !!r.hostPrefix);

          return (
            <div key={port.number}>
              {hasRoutes ? (
                <div className="mb-6">
                  <div className="mb-2">
                    <NGLabel>Routes</NGLabel>
                  </div>
                  <div
                    className="flex items-center table-labels px-2 py-1 border border-b-0"
                    style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                  >
                    <div className="flex-1 flex-grow">Prefix</div>
                    <div className="flex-1 flex-grow">Regex</div>
                    <div className="flex-1 flex-grow">Replace Prefix</div>
                    {hasHostPrefix ? <div className="flex-1 flex-grow">Host Prefix</div> : null}
                    <div className="w-6/12">Workload</div>
                    <div className="flex-1 flex-grow">Port</div>
                  </div>
                  {port.routes.map((route, index) => (
                    <div
                      key={index}
                      className={`flex items-center px-2 py-1 border ${
                        port.routes.length - 1 !== index ? "border-b-0" : ""
                      }`}
                    >
                      <div className="flex-1 flex-grow">{route.prefix}</div>
                      <div className="flex-1 flex-grow">{route.regex}</div>
                      <div className="flex-1 flex-grow">{route.replacePrefix}</div>
                      {hasHostPrefix ? <div className="flex-1 flex-grow">{route.hostPrefix}</div> : null}
                      <div className="w-6/12">{route.workloadLink}</div>
                      <div className="flex-1 flex-grow">{route.port}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              {port.tls ? (
                <div className={`mb-4 ${port.cors ? "border-bcancel pb-2" : ""}`}>
                  <div className="mb-2">
                    <NGLabel>TLS</NGLabel>
                  </div>
                  <div className="flex items-center mb-2">
                    <div className="w-4/12">
                      <FormElement label={"Min Protocol Version"} value={port.tls.minProtocolVersion} />
                    </div>
                    {port.tls.clientCertificate ? (
                      <div className="w-4/12">
                        {/* TODO show secrets as a link */}
                        <FormElement
                          label={"Forward Client Certificate"}
                          value={
                            port.tls.clientCertificate?.secretLink
                              ? `All Matching Certificate Authority ${
                                  "" // port.tls.clientCertificate?.secretLink.split("/secret/")[1]
                                }`
                              : "All"
                          }
                        />
                      </div>
                    ) : null}
                    {port.tls.serverCertificate ? (
                      <div className="w-4/12">
                        <FormElement
                          label={"Forward Server Certificate"}
                          value={
                            port.tls.serverCertificate?.secretLink
                              ? `All Matching Certificate Authority ${
                                  "" // port.tls.serverCertificate?.secretLink.split("/secret/")[1]
                                }`
                              : "All"
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <NGLabel>Cipher Suites</NGLabel>
                    {port.tls.cipherSuites.length < 1 ? (
                      <div>No Items</div>
                    ) : (
                      <div className="flex flex-wrap gap-1 mt-1">
                        {port.tls.cipherSuites.map((i) => (
                          <Value key={i} value={i} style={{ margin: 0 }} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {port.cors ? (
                <div className="mt-2">
                  <div className="mb-2">
                    <NGLabel>CORS</NGLabel>
                  </div>
                  <div className="flex items-center mb-2">
                    <div className="w-4/12">
                      <FormElement label={"Allow Credentials"} value={port.cors.allowCredentials ? "Yes" : "No"} />
                    </div>
                    <div className="w-4/12">
                      <FormElement label={"Max Age"} value={port.cors.maxAge} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <NGLabel>Allow Origins</NGLabel>
                    {port.cors.allowOrigins.length < 1 ? (
                      <div>No Items</div>
                    ) : (
                      <div className="flex flex-wrap gap-1 mt-1">
                        {port.cors.allowOrigins.map((i) => (
                          <Value key={i.exact} value={i.exact} style={{ margin: 0 }} />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="mb-2">
                    <NGLabel>Allow Methods</NGLabel>
                    {port.cors.allowMethods.length < 1 ? (
                      <div>No Items</div>
                    ) : (
                      <div className="flex flex-wrap gap-1 mt-1">
                        {port.cors.allowMethods.map((i) => (
                          <Value key={i} value={i} style={{ margin: 0 }} />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="mb-2">
                    <NGLabel>Allow Headers</NGLabel>
                    {port.cors.allowHeaders.length < 1 ? (
                      <div>No Items</div>
                    ) : (
                      <div className="flex flex-wrap gap-1 mt-1">
                        {port.cors.allowHeaders.map((i) => (
                          <Value key={i} value={i} style={{ margin: 0 }} />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="mb-2">
                    <NGLabel>Expose Headers</NGLabel>
                    {port.cors.exposeHeaders.length < 1 ? (
                      <div>No Items</div>
                    ) : (
                      <div className="flex flex-wrap gap-1 mt-1">
                        {port.cors.exposeHeaders.map((i) => (
                          <Value key={i} value={i} style={{ margin: 0 }} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
};

export const DomainSpec = observer(DomainSpecRaw);
