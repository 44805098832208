import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderOblivus } from "./oblivus";
import { ProviderOblivusNodePools } from "./nodePools";
import { ProviderOblivusNodePoolAdd } from "./nodePoolAdd";
import { ProviderOblivusNodePool } from "./nodePool";
import { ProviderOblivusUnmanagedNodePools } from "./unmanagedNodePools";
import { ProviderOblivusUnmanagedNodePoolAdd } from "./unmanagedNodePoolAdd";
import { ProviderOblivusUnmanagedNodePool } from "./unmanagedNodePool";
import { ProviderOblivusAdvanced } from "./advanced";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderOblivusRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderOblivus mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderOblivusNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<ProviderOblivusNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<ProviderOblivusNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-unmanaged-node-pools`}
        element={<ProviderOblivusUnmanagedNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/-add`}
        element={<ProviderOblivusUnmanagedNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/:index`}
        element={<ProviderOblivusUnmanagedNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-advanced`} element={<ProviderOblivusAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderOblivusRoute = observer(ProviderOblivusRouteRaw);
