import { types, Instance } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NumberModel } from "../../mobxDataModels/numberModel";

export const WorkloadDraftContainerGPUModel = types
  .model({
    _vendor: types.optional(types.string, "nvidia"),
    _nvidia_model: types.optional(types.string, "t4"),
    _nvidia_quantity: types.optional(types.number, 1),
    vendor: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Vendor",
        options: [{ label: "NVidia", value: "nvidia" }],
        initialValue: "nvidia",
      })
    ),
    nvidia_model: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Model",
        initialValue: "t4",
        options: [
          { label: "t4", value: "t4" },
          { label: "a10g", value: "a10g" },
        ],
      })
    ),
    nvidia_quantity: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Quantity",
        initialValue: 1,
        min: 0,
        max: 4,
        isRequired: true,
      })
    ),
  })
  .actions((self) => ({
    reset() {
      self.vendor.setInitialValue(self._vendor);
      self.nvidia_model.setInitialValue(self._nvidia_model);
      self.nvidia_quantity.setInitialValue(String(self._nvidia_quantity));
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setNvidiaModel(value: "t4" | "a10g") {
      let maxValue = 1;
      if (value === "t4") {
        maxValue = 4;
      }
      self.nvidia_quantity.setMax(maxValue);
      self.nvidia_model.setValue(value);
    },
    confirm() {
      self._vendor = self.vendor.value as any;
      self.vendor.confirm();
      self._nvidia_model = self.nvidia_model.value;
      self.nvidia_model.confirm();
      self._nvidia_quantity = Number(self.nvidia_quantity.value);
      self.nvidia_quantity.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.vendor.isDirty) res = true;
      if (self.vendor.value === "nvidia") {
        if (self.nvidia_model.isDirty) res = true;
        if (self.nvidia_quantity.isDirty) res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (self.vendor.value === "nvidia") {
        if (!self.nvidia_quantity.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      let res: any = {};
      if (self.vendor.value === "nvidia") {
        res.nvidia = { model: self.nvidia_model.value, quantity: Number(self.nvidia_quantity.value) };
      }
      return res;
    },
  }));
export interface WorkloadDraftContainerGPUMobx extends Instance<typeof WorkloadDraftContainerGPUModel> {}
