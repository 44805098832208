import * as React from "react";
import { observer } from "mobx-react-lite";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { getLocalToken } from "../../../services/cpln";
import { METRICS_TOKEN_UPDATE_INTERVAL_MS } from "../../../envVariables";
import { MenuLink } from "./menu/link";
import { MetricsIcon } from "../../Icons";
import { getMetricsUrl } from "../../../services/utils";
import { Discovery } from "../../../mobxStores/discovery/discovery";

export const MetricsMenuLink: React.FC = observer(() => {
  const { org } = ConsoleContext;
  const [metricsTokenFromLocal, setMetricsTokenFromLocal] = React.useState("");
  const formRef = React.useRef<HTMLFormElement>(null as any);

  const onLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  React.useEffect(() => {
    getLocalToken().then((res) => {
      if (res.accessToken !== "pass" && formRef.current) {
        setMetricsTokenFromLocal(res.accessToken);
      }
    });
    const localTokenInterval = setInterval(() => {
      getLocalToken().then((res) => {
        if (res.accessToken !== "pass" && formRef.current) {
          setMetricsTokenFromLocal(res.accessToken);
        }
      });
    }, METRICS_TOKEN_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(localTokenInterval);
    };
  }, []);

  return (
    <>
      <form
        className="hidden"
        ref={formRef}
        action={`${Discovery.endpoints.grafana.replace("{org}", org!)}/auth/process`}
        method="POST"
        target="_blank"
      >
        <input name="redirect" value={getMetricsUrl("sider")} type="hidden" />
        <input name="idToken" value={metricsTokenFromLocal} type="hidden" />
        <button type="submit">metrics</button>
      </form>
      <MenuLink
        url=""
        isExternal={true}
        Icon={MetricsIcon}
        customIsActive={() => false}
        onClick={onLinkClick}
        label={"Metrics"}
      />
    </>
  );
});
