import clsx from "clsx";
import * as React from "react";
import { MoreVertical, Trash2 } from "react-feather";
import { NGButton } from "../button/Button";
import { NGCheckbox } from "../checkbox";
import { NGLabel } from "../text/label";
import { ReactSortable } from "react-sortablejs";
import "./inputList.css";
import { InputListMobx } from "../../mobxStores/inputList";
import { observer } from "mobx-react-lite";
import { NGFormLabel } from "../text/formLabel";
import { NGError } from "../text/error";

export interface NGInputListMobxProps<T extends { id: string; chosen: boolean }> {
  label: string;
  data: InputListMobx<T>;
  firstInput: (item: T) => React.ReactElement;
  secondInput?: (item: T) => React.ReactElement;
  thirdInput?: (item: T) => React.ReactElement;
  itemExtra?: (item: T) => React.ReactElement | null;
  draggable?: boolean;
  noItemLabel?: string;
  required?: boolean;
  invalid?: boolean;
  error?: string;
  canAdd?: () => boolean;
  className?: string;
  style?: React.CSSProperties;
}

function NGInputListMobxRaw<T extends { id: string; chosen: boolean }>({
  label,
  data,
  firstInput,
  secondInput,
  thirdInput,
  itemExtra,
  draggable = false,
  noItemLabel,
  required,
  invalid,
  error,
  canAdd = () => true,
  className = "",
  style = {},
}: NGInputListMobxProps<T>) {
  return (
    <div className={clsx("flex flex-col mb-4", className)} style={style}>
      <div className={clsx("flex items-center gap-1 mb-2")} style={{ width: 450 }}>
        <NGCheckbox
          aria-label={`input-list-selectall-${label}`}
          checked={data.isAllSelected}
          isDisabled={data.items.length < 1}
          indeterminate={!data.isAllSelected && data.selections.length > 0}
          onChange={data.toggleAllSelect}
        />
        <NGFormLabel required={required} invalid={invalid}>
          {label}
        </NGFormLabel>
        <div className="flex-grow" />
        <NGButton
          size={"small"}
          variant={"danger"}
          outlined
          renderIcon={() => <Trash2 className="h-4" />}
          disabled={data.selections.length < 1}
          onClick={() => data.removeSelections()}
        >
          Remove
        </NGButton>
        <NGButton disabled={canAdd() === false} size={"small"} variant={"action"} onClick={() => data.add()}>
          Add
        </NGButton>
      </div>
      <div>
        {error ? null : data.items.length === 0 ? <div>{noItemLabel || `No ${label} Items`}</div> : null}
        <ReactSortable handle=".drag-handle" className="flex flex-col gap-2" list={data.items} setList={data.setItems}>
          {data.items.map((item: T) => {
            return (
              <div>
                <div className={clsx("flex items-center relative")}>
                  {draggable ? <DragHandle /> : null}
                  <NGCheckbox
                    aria-label={`input-list-select-${item.id}`}
                    checked={data.selections.includes(item.id)}
                    onChange={(value) => data.toggleSelect(item.id)}
                  />
                  <div className={`basis-0 flex-grow ml-1`}>{firstInput(item)}</div>
                  {secondInput ? <div className={`basis-0 flex-grow ml-2`}>{secondInput(item)}</div> : null}
                  {thirdInput ? <div className="basis-0 flex-grow ml-2">{thirdInput(item)}</div> : null}
                </div>
                {itemExtra ? (
                  <div className="mt-1" style={{ marginLeft: 27 }}>
                    {itemExtra(item)}
                  </div>
                ) : null}
              </div>
            );
          })}
        </ReactSortable>
        {error ? <NGError>{error}</NGError> : null}
      </div>
    </div>
  );
}

export const NGInputListMobx = observer(NGInputListMobxRaw);

export function DragHandle() {
  return (
    <div
      className="absolute cursor-move drag-handle"
      style={{
        width: 16,
        height: 30,
        left: 0,
        borderRadius: 3,
        transform: "translateX(-20px)",
        color: "gray",
      }}
    >
      <MoreVertical className="absolute left-0" style={{ left: 2, top: 3, width: 18 }} />
      <MoreVertical className="absolute" style={{ left: -4, top: 3, width: 18 }} />
    </div>
  );
}
