import * as React from "react";
import { Kind } from "../../../../schema/types/base";
import { AgentTable } from "./agent";
import { AuditContextTable } from "./auditctx";
import { CloudaccountTable } from "./cloudaccount";
import { DomainTable } from "./domain";
import { GroupTable } from "./group";
import { GvcTable } from "./gvc";
import { IdentityTable } from "./identity";
import { ImageTable } from "./image";
import { KubernetesTable } from "./mk8s";
import { LocationTable } from "./location";
import { PolicyTable } from "./policy";
import { QuotaTable } from "./quota";
import { SecretTable } from "./secret";
import { ServiceaccountTable } from "./serviceaccount";
import { UserTable } from "./user";
import { VolumesetTable } from "./volumeset";
import { WorkloadTable } from "./workload";

interface Props {
  kind: Kind;
  selections: string[];
  onSelectionsChange: (values: string[]) => void;
}
export const TargetTable: React.FC<Props> = ({ kind, selections, onSelectionsChange }) => {
  const title = "Target Items";

  if (kind === "agent") {
    return <AgentTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "auditctx") {
    return <AuditContextTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "cloudaccount") {
    return <CloudaccountTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "domain") {
    return <DomainTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "group") {
    return <GroupTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "gvc") {
    return <GvcTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "identity") {
    return <IdentityTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "image") {
    return <ImageTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "mk8s") {
    return <KubernetesTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "location") {
    return <LocationTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "policy") {
    return <PolicyTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "quota") {
    return <QuotaTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "secret") {
    return <SecretTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "serviceaccount") {
    return <ServiceaccountTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "user") {
    return <UserTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "volumeset") {
    return <VolumesetTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }
  if (kind === "workload") {
    return <WorkloadTable title={title} selections={selections} onSelectionsChange={onSelectionsChange} />;
  }

  return <span>Not supported</span>;
};
