import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../advanced/providerAutoscaler";
import { ProviderPreInstallScript } from "../../advanced/providerPreInstallScript";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderPaperspaceAdvancedRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const p = mk8sDraft.provider_paperspace!;

  return (
    //
    <>
      <div className="mb-4">
        <ProviderPreInstallScript provider="paperspace" preInstallScript={p.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderAutoscaler provider="paperspace" autoscaler={p.autoscaler} />
      </div>
    </>
  );
};

export const ProviderPaperspaceAdvanced = observer(ProviderPaperspaceAdvancedRaw);
