import { types, Instance, flow } from "mobx-state-tree";
import { BrowserServiceModel } from "../kinds/browser";
import { v4 as uuidv4 } from "uuid";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const VOLUME_URI = {
  SECRET: {
    prefix: "cpln://secret/",
    label: "Secret",
    value: "{secret}[.{property}]",
  },
  VOLUMESET: {
    prefix: "cpln://volumeset/",
    label: "Volume Set",
    value: "{volumeset}",
  },
  S3: {
    prefix: "s3://",
    label: "AWS S3 Bucket",
    value: "{bucket}",
  },
  GS: {
    prefix: "gs://",
    label: "Google Bucket",
    value: "{bucket}",
  },
  AZUREBLOB: {
    prefix: "azureblob://",
    label: "Azure Blob",
    value: "{storageAccount}/{container}",
  },
  AZUREFS: {
    prefix: "azurefs://",
    label: "Azure File Share",
    value: "{storageAccount}/{share}",
  },
  SCRATCH: {
    prefix: "scratch://",
    label: "Scratch",
    value: "{name}",
  },
};

export const VolumeLimit = 15;

export const VolumesBrowseModel = types
  .model({
    volumeId: types.optional(types.string, ""),
    browserService: types.optional(BrowserServiceModel, () => BrowserServiceModel.create()),
    page: types.optional(types.enumeration(["none", "provider", "bucket"]), "none"),
    provider: types.optional(types.enumeration(["aws", "azure", "gcp"]), "aws"),
    cloudaccountName: types.optional(types.string, ""),
    isCheckingReach: types.optional(types.boolean, false),
    isPreparing: types.optional(types.boolean, false),
    prepareError: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setVolumeId(value: string) {
      self.volumeId = value;
    },
    setProvider(provider: "aws" | "azure" | "gcp") {
      self.provider = provider;
    },
    setPage(value: "none" | "provider" | "bucket") {
      self.page = value;
    },
  }))
  .actions((self) => {
    const prepare = flow(function* () {
      try {
        self.isPreparing = true;
        self.prepareError = "";
        const browserService = self.browserService;
        const provider = self.provider;
        yield browserService.fetchServices();
        if (provider === "aws") {
          const service_s3 = browserService.servicesSection.items.find((service) => service.href.includes("/s3"));
          if (!service_s3) {
            self.isPreparing = false;
            self.prepareError = "No S3 service is found";
            return;
          }
          browserService.servicesSection.setSelectedHref(service_s3.href);
        }
        if (provider === "gcp" || provider === "azure") {
          const service_storage = browserService.servicesSection.items.find((service) =>
            service.href.includes("/storage"),
          );
          if (!service_storage) {
            self.isPreparing = false;
            self.prepareError = "No storage service is found";
            return;
          }
          browserService.servicesSection.setSelectedHref(service_storage.href);
        }

        yield browserService.fetchRegions();
        const region_global = browserService.regionsSection.items.find((service) => service.href.includes("/global"));
        if (!region_global) {
          self.isPreparing = false;
          self.prepareError = "No global region is found";
          return;
        }
        browserService.regionsSection.setSelectedHref(region_global.href);
        yield browserService.fetchTypes();
        if (provider === "aws" || provider === "gcp") {
          const type_bucket = browserService.typesSection.items.find((service) => service.href.includes("/bucket"));
          if (!type_bucket) {
            self.isPreparing = false;
            self.prepareError = "No bucket type is found";
            return;
          }
          browserService.typesSection.setSelectedHref(type_bucket.href);
        }
        if (provider === "azure") {
          const type_account = browserService.typesSection.items.find((service) => service.href.includes("/account"));
          if (!type_account) {
            self.isPreparing = false;
            self.prepareError = "No account type is found";
            return;
          }
          browserService.typesSection.setSelectedHref(type_account.href);
        }
        yield browserService.fetchDataItems();
        self.isPreparing = false;
        self.prepareError = "";
        self.setPage("bucket");
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        self.prepareError = errorMessage;
        self.isPreparing = false;
      }
    });
    return { prepare };
  })
  .actions((self) => ({
    setProvider(value: "aws" | "gcp" | "azure" | "volumeset" | "secret") {
      self.prepareError = "";
      self.provider = value;
      self.cloudaccountName = "";
      self.browserService.setCloudaccountLink("");
      self.browserService.setCanReach(false);
      self.browserService.clearReachError();
    },
  }))
  .actions((self) => {
    const setCloudaccountName = flow(function* (name: string) {
      self.prepareError = "";
      self.cloudaccountName = name;
      if (self.cloudaccountName) {
        const { absolute: cloudaccountLink } = ngParseLink(self.cloudaccountName, { kind: "cloudaccount" });
        self.browserService.setCloudaccountLink(cloudaccountLink);
        self.isCheckingReach = true;
        yield self.browserService.checkCanReach();
        self.isCheckingReach = false;
      } else {
        self.browserService.clearReachError();
      }
    });
    return { setCloudaccountName };
  })
  .actions((self) => ({
    done() {
      self.setPage("none");
      self.setProvider("aws");
    },
  }));
export interface VolumesBrowseMobx extends Instance<typeof VolumesBrowseModel> {}

export const PathURIReadonlyModel = types.model({
  path: types.optional(types.string, ""),
  uri: types.optional(types.string, ""),
  recoveryPolicy: types.optional(types.string, "retain"),
});
export interface PathUriReadonlyMobx extends Instance<typeof PathURIReadonlyModel> {}

export const PathURIModel = types
  .model({
    id: types.optional(types.string, () => uuidv4()),
    path: types.optional(types.string, ""),
    uriPrefix: types.optional(types.string, ""),
    uriValue: types.optional(types.string, ""),
    uriSuffix: types.optional(types.string, ""),
    recoveryPolicy: types.optional(types.string, "retain"),
    metadata: types.optional(types.frozen(), {}),
  })
  .actions((self) => ({
    setMetadata(obj: any) {
      self.metadata = obj;
    },
    setUriPrefix(value: string) {
      self.uriPrefix = value;
      self.uriValue = "";
      self.uriSuffix = "";
    },
    setUriValue(value: string) {
      self.uriValue = value;
      self.uriSuffix = "";
    },
    setUriSuffix(value: string) {
      self.uriSuffix = value;
    },
    setPath(value: string) {
      self.path = value;
    },
  }))
  .views((self) => ({
    get uri() {
      let result = self.uriPrefix + self.uriValue;
      if ((self.uriPrefix.includes("secret") || self.uriPrefix.includes("volumeset")) && self.uriSuffix) {
        result += `.${self.uriSuffix}`;
      }
      return result;
    },
  }));
export interface PathUriMobx extends Instance<typeof PathURIModel> {}

export const VolumesModel = types
  .model({
    volumes: types.array(PathURIReadonlyModel),
    editVolumes: types.array(PathURIModel),
    browse: types.optional(VolumesBrowseModel, () => VolumesBrowseModel.create()),
  })
  .actions((self) => ({
    reset() {
      self.editVolumes.clear();
      for (let _volume of self.volumes) {
        let uriPrefix = _volume.uri.split("://")[0] + "://";
        let uriAfterPrefix = _volume.uri.split("://")[1];

        if (_volume.uri.startsWith("cpln://")) {
          let itemKind = _volume.uri.split("cpln://")[1].split("/")[0];
          uriPrefix = `cpln://` + itemKind + "/";
          uriAfterPrefix = _volume.uri.split("cpln://")[1].split("/")[1];
          // when volume references third item but has no name, should be fixed in backend
          if (!uriAfterPrefix) {
            uriPrefix = _volume.uri;
            uriAfterPrefix = "";
          }
        }

        let uriValue = "";
        let uriSuffix = "";
        if (uriAfterPrefix.includes(".")) {
          uriValue = uriAfterPrefix.split(".")[0];
          uriSuffix = uriAfterPrefix.split(".").slice(1).join(".");
        } else {
          uriValue = uriAfterPrefix;
        }
        self.editVolumes.push({
          uriPrefix,
          uriValue,
          uriSuffix,
          path: _volume.path,
          recoveryPolicy: _volume.recoveryPolicy,
        });
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    remove(id: string) {
      const toRemove = self.editVolumes.find((v) => v.id === id);
      if (!toRemove) return;
      self.editVolumes.remove(toRemove);
    },
    confirm() {
      self.volumes.clear();
      for (let _volume of self.editVolumes) {
        self.volumes.push({ uri: _volume.uri, path: _volume.path, recoveryPolicy: _volume.recoveryPolicy });
      }
    },
  }))
  .views((self) => ({
    get asArray() {
      return self.editVolumes.map((v) => ({ path: v.path, uri: v.uri, recoveryPolicy: v.recoveryPolicy }));
    },
    get hasVolumesetChange() {
      let res = false;
      const volumes = self.volumes.filter((v) => v.uri.startsWith(VOLUME_URI.VOLUMESET.prefix));
      const editVolumes = self.editVolumes.filter((v) => v.uriPrefix === VOLUME_URI.VOLUMESET.prefix);
      if (volumes.length !== editVolumes.length) res = true;
      for (let volume of volumes) {
        if (!editVolumes.find((editVolume) => editVolume.path === volume.path && editVolume.uri === volume.uri)) {
          res = true;
          break;
        }
      }
      for (let editVolume of editVolumes) {
        if (!volumes.find((volume) => volume.path === editVolume.path && volume.uri === editVolume.uri)) {
          res = true;
          break;
        }
      }
      return res;
    },
    get isDirty() {
      let res = false;
      if (self.volumes.length !== self.editVolumes.length) res = true;
      for (let volume of self.volumes) {
        if (!self.editVolumes.find((editVolume) => editVolume.path === volume.path && editVolume.uri === volume.uri)) {
          res = true;
          break;
        }
      }
      for (let editVolume of self.editVolumes) {
        if (!self.volumes.find((volume) => volume.path === editVolume.path && volume.uri === editVolume.uri)) {
          res = true;
          break;
        }
      }
      return res;
    },
    get isValid() {
      let res = true;
      for (const volume of self.editVolumes) {
        if (!volume.path) res = false;
        if (!volume.uri) res = false;
      }
      return res;
    },
  }))
  .views((self) => ({
    get canAdd() {
      let res = true;
      if (!self.isValid) res = false;
      if (self.editVolumes.length >= VolumeLimit) res = false;
      return res;
    },
  }))
  .actions((self) => ({
    add() {
      if (!self.canAdd) return;
      const id = uuidv4();
      self.editVolumes.push(
        PathURIModel.create({
          id: id,
          recoveryPolicy: "retain",
        }),
      );
      return id;
    },
  }));

export interface EnvVarsMobx extends Instance<typeof PathURIModel> {}
