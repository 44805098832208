import * as React from "react";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { observer } from "mobx-react-lite";

interface Props {
  name: StringMobx;
  description: StringMobx;
  group: string;
  setGroup: (value: string) => void;
}
const ServiceAccountCreateGeneralRaw: React.FC<Props> = ({ name, description, group, setGroup }) => {
  return (
    <>
      <NGFormElement name="name" label={name.label} value={name.value} onChange={name.setValue} />
      <NGFormElement
        name="description"
        label={description.label}
        value={description.value}
        onChange={description.setValue}
      />
      <NGFormElement
        name="group"
        kind={"group"}
        as="kindselect"
        label={"Assign to Group"}
        value={group}
        onChange={(value) => setGroup(value)}
      />
    </>
  );
};

export const ServiceAccountCreateGeneral = observer(ServiceAccountCreateGeneralRaw);
