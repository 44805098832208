import * as React from "react";
import { ItemStoreQueryMobx } from "../../../mst/query";
import { objectToArray } from "../../../services/utils";
import { observer } from "mobx-react-lite";
import { QueryUI } from "../../../components/query/queryUI";

interface Props {
  query: ItemStoreQueryMobx;
  useQuery: boolean;
  setUseQuery: (value: boolean) => void;
  targetKind: string;
}
const PolicyCreateTargetQueryRaw: React.FC<Props> = ({ query, useQuery, setUseQuery, targetKind }) => {
  return (
    <QueryUI
      key={targetKind}
      idPrefix="policy-create-target"
      kind={targetKind as any}
      useQuery={useQuery}
      setUseQuery={setUseQuery}
      query={query}
      evaluatedItemFn={(item: any) => {
        const obj: any = {
          id: item.id,
          name: item.name,
          description: item.description || "",
          tags: objectToArray(item.tags || {}),
        };

        // TODO fix
        // const customDatas = targetQueryGetCustomData(targetKind as any, item);
        // for (const customData of customDatas) {
        //   obj[customData.id] = customData.value;
        // }

        return obj;
      }}
      hasCustomColumns={true}
    />
  );
};

export const PolicyCreateTargetQuery = observer(PolicyCreateTargetQueryRaw);
