import ReactDOM from "react-dom";
import clsx from "clsx";
import * as React from "react";
import { useOutsideClick } from "../../../../reactHooks/useOutsideClick";
import { UIData } from "../../../../mobxStores/uiData/uiData";
import { SelectMenu } from "../../../SelectMenu";
import { observer } from "mobx-react-lite";
import { MenuLink, MenuLinkProps } from "../menu/link";
import { Kind } from "../../../../mst/base";
import { Emitter } from "mitt";
import { ChevronRight } from "react-feather";
import "./index.scss";
import { SidebarContext } from "../../../../mobxStores/uiData/sidebarContext";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";

export interface LinkWithChangeItem {
  labels: string[];
  value: string;
}

interface LinkWithChangeProps {
  kind: Kind;
  mountElementId: string;
  label: string | null;
  value?: string | null;
  items: LinkWithChangeItem[];
  menuLinkProps: MenuLinkProps;
  isActiveFunc: () => boolean;
  selectItem: (name: string) => void;
  createItem?: () => void;
  useEmitter: () => Emitter<any>;
  requestItems: () => Promise<void>;
  canBePinned: boolean;
}

const LinkWithChangeRaw: React.FC<LinkWithChangeProps> = ({
  kind,
  mountElementId,
  label,
  value,
  items,
  menuLinkProps,
  isActiveFunc,
  selectItem,
  createItem,
  useEmitter,
  requestItems,
  canBePinned,
}) => {
  // Definitions
  const { scrollTop } = SidebarContext;
  const isActive = isActiveFunc();
  const emitter = useEmitter();
  const isPinned = (kind === "org" && UIData.isOrgPinned) || (kind === "gvc" && UIData.isGVCPinned);

  /*** Use State ***/
  const [portalRendered, setPortalRendered] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);

  /*** Use Ref ***/
  const dropdownRef = React.useRef<HTMLDivElement>(null as any);
  const dropdownButtonRef = React.useRef<HTMLButtonElement>(null as any);

  /*** Use Effect ***/
  React.useEffect(() => {
    const dropdownButtonRect = dropdownButtonRef?.current?.getBoundingClientRect();
    const selectMenu = document.getElementById(mountElementId);
    if (dropdownButtonRect && selectMenu && dropdownButtonRect.top > 70) {
      selectMenu.style.top = `${dropdownButtonRect.top}px`;
      selectMenu.style.left = UIData.isNavCollapsed ? `90px` : `270px`;
    }
  }, [scrollTop, UIData.isNavCollapsed]);

  React.useEffect(() => {
    emitter.on("*", requestItems);
    return () => {
      emitter.off("*", requestItems);
    };
  }, []);

  React.useEffect(() => {
    if (showDropdown && !portalRendered) {
      setPortalRendered(true);
    }
  }, [showDropdown, portalRendered]);

  React.useEffect(() => {
    if (!showDropdown) {
      return;
    }
    requestItems();
  }, [showDropdown]);

  /*** Execute ***/
  useOutsideClick([dropdownRef, dropdownButtonRef], () => {
    setShowDropdown(false);
  });

  return (
    <div className={clsx("link-with-change", kind)}>
      <MenuLink
        showArrow={!isPinned}
        url={menuLinkProps.url}
        tooltip={menuLinkProps.tooltip}
        style={{ width: isPinned ? "100%" : UIData.isNavCollapsed ? "40px" : "189px" }}
        prefix={menuLinkProps.prefix}
        label={menuLinkProps.label}
        Icon={menuLinkProps.Icon}
        customIsActive={menuLinkProps.customIsActive}
      />
      {!isPinned && (
        <button
          data-testid={`${kind}-change-dropdown`}
          ref={dropdownButtonRef}
          onClick={(e) => {
            e.stopPropagation();
            /* setSearchContent(""); */
            setShowDropdown(!showDropdown);
          }}
          className={clsx("link-with-change-arrow", { selected: isActive, collapsed: UIData.isNavCollapsed })}
        >
          <ChevronRight size={20} />
        </button>
      )}
      {ReactDOM.createPortal(
        <SelectMenu
          key={`${ConsoleContext.gvc}-${ConsoleContext.org}-${BillingContext.AccountUUID}`}
          items={items}
          kind={kind}
          label={label}
          value={value}
          createItem={createItem}
          dropdownButtonRef={dropdownButtonRef}
          dropdownRef={dropdownRef}
          portalRendered={portalRendered}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          selectItem={(name) => {
            setShowDropdown(false);
            selectItem(name);
          }}
          canBePinned={canBePinned}
        />,
        document.getElementById(mountElementId)!
      )}
    </div>
  );
};

export const LinkWithChange = observer(LinkWithChangeRaw);
