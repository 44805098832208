import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

type _InternalLinkProps = LinkProps & React.RefAttributes<HTMLAnchorElement>;
export interface InternalLinkProps extends _InternalLinkProps {
  label?: string;
}
export const InternalLink: React.FC<InternalLinkProps> = ({ label, className, ...props }) => {
  return (
    <Link className={`inline-block truncate color-link ngfocus ${className}`} {...props}>
      {label}
    </Link>
  );
};
