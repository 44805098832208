import * as React from "react";
import { observer } from "mobx-react-lite";
import { NGLabel } from "../../../../../newcomponents/text/label";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { DryRunAlert } from "../../../dryRunAlert";
import { Mk8sDraftProviderTritonLoadBalancerModelMobx } from "../../../../../mst/stores/mk8s.draft.provider.triton.loadBalancer";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { MoreHorizontal } from "react-feather";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { homeLink, request } from "../../../../../services/cpln";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
  lb: Mk8sDraftProviderTritonLoadBalancerModelMobx;
}
const TritonLoadBalancerRaw: React.FC<Props> = ({ mk8sDraft, lb }) => {
  const formData = useNGFormContext();

  const formPrefix = `triton.loadBalancer.`;

  const [browserKey, setBrowserKey] = React.useState("");

  return (
    <>
      <div className="p-4 mb-4 border rounded">
        <div className="mb-4">
          <NGLabel size={2}>Load Balancer</NGLabel>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${formPrefix}method`}
            label={lb.method.label}
            value={lb.method.value}
            onChange={lb.method.setValue}
            as={"select"}
            options={lb.method.options}
          />
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.triton.loadBalancer`}
          />
        </div>

        {lb.method.value === "gateway" ? (
          <>
            <div className="mt-2">
              <DryRunAlert
                key={mk8sDryRun.key}
                canShow={true}
                dryRunResponse={mk8sDryRun.response}
                path={`spec.provider.triton.loadBalancer.gateway`}
              />
            </div>
          </>
        ) : null}
        {lb.method.value === "manual" ? (
          <>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.packageId`}
                label={lb.manual.packageId.label}
                value={lb.manual.packageId.value}
                onChange={lb.manual.packageId.setValue}
                required={lb.manual.packageId.isRequired}
                error={lb.manual.packageId.error}
                innerButtons={[
                  {
                    title: "Browse",
                    onClick: () => {
                      setBrowserKey("packages");
                      formData.set(`${formPrefix}manual.packageId`, { touched: true });
                    },
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.packageId.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.packageId`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.imageId`}
                label={lb.manual.imageId.label}
                value={lb.manual.imageId.value}
                onChange={lb.manual.imageId.setValue}
                required={lb.manual.imageId.isRequired}
                error={lb.manual.imageId.error}
                innerButtons={[
                  {
                    title: "Browse",
                    onClick: () => {
                      setBrowserKey("lb_images");
                      formData.set(`${formPrefix}manual.imageId`, { touched: true });
                    },
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.imageId.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.imageId`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.publicNetworkId`}
                label={lb.manual.publicNetworkId.label}
                value={lb.manual.publicNetworkId.value}
                onChange={lb.manual.publicNetworkId.setValue}
                required={lb.manual.publicNetworkId.isRequired}
                error={lb.manual.publicNetworkId.error}
                innerButtons={[
                  {
                    title: "Browse",
                    onClick: () => {
                      setBrowserKey("public-networks");
                      formData.set(`${formPrefix}manual.publicNetworkId`, { touched: true });
                    },
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.publicNetworkId.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.publicNetworkId`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.count`}
                label={lb.manual.count.label}
                value={lb.manual.count.value}
                onChange={lb.manual.count.setValue}
                required={lb.manual.count.isRequired}
                error={lb.manual.count.error}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.count.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.count`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.cnsInternalDomain`}
                label={lb.manual.cnsInternalDomain.label}
                value={lb.manual.cnsInternalDomain.value}
                onChange={lb.manual.cnsInternalDomain.setValue}
                required={lb.manual.cnsInternalDomain.isRequired}
                error={lb.manual.cnsInternalDomain.error}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.cnsInternalDomain.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.cnsInternalDomain`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.cnsPublicDomain`}
                label={lb.manual.cnsPublicDomain.label}
                value={lb.manual.cnsPublicDomain.value}
                onChange={lb.manual.cnsPublicDomain.setValue}
                required={lb.manual.cnsPublicDomain.isRequired}
                error={lb.manual.cnsPublicDomain.error}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.cnsPublicDomain.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.cnsPublicDomain`}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      {browserKey === "public-networks" ? (
        <NGProviderBrowser
          title="Browse Triton Public Networks"
          initialValue={lb.manual.publicNetworkId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "public-networks",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => lb.manual.publicNetworkId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          sortable
          labels={["Name", "Id", "Description", "Subnet", "VLAN Id"]}
          getData={(item: any) => [item.id, item.name, item.id, item.description, item.subnet, item.vlan_id]}
        />
      ) : null}
      {browserKey === "lb_images" ? (
        <NGProviderBrowser
          title="Browse Triton Images"
          initialValue={lb.manual.imageId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "lb_images",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => lb.manual.imageId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          sortable
          distinctFilters={["Type", "Brand"]}
          filteredColumns={["Name"]}
          labels={["Name", "Id", "Size", "Type", "Brand", "Version"]}
          getData={(item: any) => [
            item.id,
            item.name,
            item.id,
            [item.image_size || 0, item.image_size ? `${item.image_size / 1024}Gi` : "-"],
            item.type,
            item.requirements?.brand,
            item.version,
          ]}
        />
      ) : null}
      {browserKey === "packages" ? (
        <NGProviderBrowser
          title="Browse Triton Packages"
          initialValue={lb.manual.packageId.value}
          fetchData={async () => {
            // TODOMK8S paginate
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "packages",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => lb.manual.packageId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          sortable
          labels={["Name", "Id", "Brand", "Version", "Cores", "Memory", "Disk", "Description"]}
          distinctFilters={["Brand", "Cores", "Memory", "Disk"]}
          getData={(item: any) => {
            const memoryGi = item.memory / 1024;
            const diskGi = item.disk / 1024;

            return [
              //
              item.id,
              item.name,
              item.id,
              item.brand,
              item.version,
              item.vcpus,
              [memoryGi, `${memoryGi}Gi`],
              [diskGi, `${diskGi}Gi`],
              item.description,
            ];
          }}
        />
      ) : null}
    </>
  );
};

export const TritonLoadBalancer = observer(TritonLoadBalancerRaw);
