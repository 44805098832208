import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityMobx } from "../../mst/kinds/identity";
import { IdentityDraftMobx } from "../../mst/stores/identity.draft";
import { notification } from "antd";
import { FormButtons } from "../../components/forms/formButtons";
import { FormElement } from "../../components/forms/formElement";
import { useDetailContext } from "../../components/detail/detailContext";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  identity: IdentityMobx;
  identityDraft: IdentityDraftMobx;
}
const InfoRaw: React.FC<Props> = ({ identity, identityDraft }) => {
  const { fetchItem } = useDetailContext();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    identityDraft.reset();
    return () => {
      identityDraft.reset();
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(identityDraft.description.isDirty || isLoading);
  }, [identityDraft.description.isDirty, isLoading]);

  useCleanPrompt();

  async function reset() {
    identityDraft.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      await identity.patch(identityDraft.asPatch);
      identityDraft.confirm();
      notification.success({
        message: "Success",
        description: "Updated identity",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <NGFormElement
        name="description"
        label={identityDraft.description.label}
        value={identityDraft.description.value}
        onChange={identityDraft.description.setValue}
      />
      {identity.status.objectName ? (
        <div className="mb-4">
          <FormElement label={"Object Name"} value={identity.status.objectName} canCopy />
        </div>
      ) : null}
      <TagLinksTable
        tableId="identity-info-tag-links"
        tags={identityDraft.tags.editTags.filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />
      <FormButtons
        onReset={reset}
        onSave={save}
        resetDisabled={isLoading || !identityDraft.isDirty}
        saveDisabled={isLoading || !identityDraft.isDirty}
        loading={isLoading}
      />
    </>
  );
};

export const Info = observer(InfoRaw);
