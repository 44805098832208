import { Instance, types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";

export const Mk8sDraftFirewallItemReadOnlyModel = types.model({
  sourceCIDR: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
});

export const Mk8sDraftFirewallItemModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["default", "added"]), "default"),
    _item: types.optional(Mk8sDraftFirewallItemReadOnlyModel, () => Mk8sDraftFirewallItemReadOnlyModel.create()),
    sourceCIDR: types.optional(StringModel, () => StringModel.create({ label: "Source CIDR", isRequired: true })),
    description: types.optional(StringModel, () => StringModel.create({ label: "Description" })),
  })
  .actions((self) => ({
    setStatusAdded() {
      self.status = "added";
    },
  }))
  .actions((self) => ({
    reset() {
      self.sourceCIDR.setInitialValue(self._item.sourceCIDR);
      self.description.setInitialValue(self._item.description);
    },
    confirm() {
      self.sourceCIDR.confirm();
      self.description.confirm();
      const _item = {
        sourceCIDR: self.sourceCIDR.value, //
        description: self.description.value,
      };

      self._item = Mk8sDraftFirewallItemReadOnlyModel.create(_item);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.sourceCIDR.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.sourceCIDR.isValid) reason = self.sourceCIDR.invalidReason;
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.sourceCIDR.isDirty) res = true;
      if (self.description.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.sourceCIDR.isDirtyReason) reason = self.sourceCIDR.isDirtyReason;
      if (self.description.isDirtyReason) reason = self.description.isDirtyReason;
      return reason;
    },
    get asObject() {
      return {
        sourceCIDR: self.sourceCIDR.value,
        description: self.description.value,
      };
    },
  }));

export interface Mk8sDraftFirewallItemMobx extends Instance<typeof Mk8sDraftFirewallItemModel> {}
