import { observer } from "mobx-react-lite";
import * as React from "react";
import { TagsNewMobx, TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { WithTagsMobx } from "../../mst/base";
import { FormButtons } from "../forms/formButtons";
import { notification } from "antd";
import { useLocation } from "react-router-dom";
import { updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { TagItem } from "./tagItem";
import { useDetailContext } from "./detailContext";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";

interface ItemForProps extends WithTagsMobx {
  patch: (body: any) => Promise<void>;
  selfLink: string;
}
interface Props {
  item: ItemForProps;
  tagsMobx?: TagsNewMobx;
  kindPlural: string;
  disabled?: boolean;
  // TODO support this method on all detail pages
  hideButtons?: boolean;
}

const TagsNewRaw: React.FC<Props> = ({ item, tagsMobx, hideButtons }) => {
  const { fetchItem } = useDetailContext();
  const { org, gvc } = ConsoleContext;
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);

  const tagsRef = React.useRef(
    TagsNewModel.create({
      tags: item.tagsAsKeyValueArray.map((t, i) => ({
        index: Number(i),
        key: t.key,
        value: t.value,
        status: "default",
      })),
    })
  );
  const tagsInstance = tagsMobx || tagsRef.current;

  React.useEffect(() => {
    if (!hideButtons) {
      PromptContext.setWhen(tagsInstance.isDirty || isLoading);
    }
  }, [tagsInstance.isDirty, isLoading]);

  useCleanPrompt(hideButtons);

  async function onSave() {
    try {
      setIsLoading(true);
      await item.patch({ "$replace/tags": tagsInstance.asObject });
      tagsInstance.confirm();
      notification.success({
        message: "Success",
        description: `Updated tags`,
      });
      try {
        if (pathname.includes(`/${org}/gvc/${gvc}/workload/`)) {
          updateLastDeploymentTimeOnHubspot();
        }
      } catch (e) {
        console.warn("Tag update was not a workload probably.");
      }
      setIsLoading(false);
      if (fetchItem) {
        fetchItem();
      }
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  function onReset() {
    tagsInstance.reset();
  }

  return (
    <>
      <div className="flex items-center gap-4 mb-4">
        <div className="text-2xl">Tags</div>
        <NGButton
          data-testid="add-tag"
          variant="action"
          size="small"
          disabled={!tagsInstance.isValid}
          onClick={() => {
            const index = tagsInstance.add();
            setTimeout(() => {
              const tagValueInput = document.querySelector<HTMLInputElement>(`[data-tagindex="${index}"]`);
              if (tagValueInput) {
                tagValueInput.focus();
              }
            }, 100);
          }}
        >
          Add
        </NGButton>
      </div>
      <div className="mb-4">
        {tagsInstance.editTags.filter((t) => t.status !== "removed").length < 1 ? (
          <div className={"no-items-found"}>
            <span>No Tags Found</span>
          </div>
        ) : (
          <>
            {/* TODO move to a new component? */}
            <div className="flex items-center gap-2 mb-2">
              <div style={{ width: "calc((100% - 110px) / 2)" }}>Key</div>
              <div style={{ width: "calc((100% - 110px) / 2)" }}>Value (Optional)</div>
            </div>
            <div className="flex flex-col gap-2">
              {tagsInstance.editTags
                .filter((t) => t.status !== "removed")
                .slice()
                .sort((a, b) => {
                  if (a.index < b.index) return -1;
                  if (a.index > b.index) return 1;
                  return 0;
                })
                .map((t, index) => (
                  <TagItem
                    key={t.index}
                    tag={t}
                    onRemove={() => tagsInstance.remove(t.index)}
                    error={t.keyConflicts ? `Tag name "${t.key}" is already being used.` : ""}
                  />
                ))}
            </div>
          </>
        )}
      </div>
      {hideButtons ? null : (
        <FormButtons
          loading={isLoading}
          onReset={onReset}
          onSave={onSave}
          resetDisabled={!tagsInstance.isDirty || isLoading}
          saveDisabled={!tagsInstance.isValid || !tagsInstance.isDirty || isLoading}
        />
      )}
    </>
  );
};

export const TagsNew = observer(TagsNewRaw);
