/*** Default Icons ***/
// ORG //
export { ReactComponent as OrgIcon } from "./icons/default/org.svg";
export { ReactComponent as ImageIcon } from "./icons/default/container.svg";
export { ReactComponent as LocationIcon } from "./icons/default/locations.svg";
export { ReactComponent as DomainIcon } from "./icons/default/domains.svg";
export { ReactComponent as AgentIcon } from "./icons/default/agents.svg";
export { ReactComponent as SecretIcon } from "./icons/default/secrets.svg";
export { ReactComponent as CloudAccountIcon } from "./icons/default/cloud-accounts.svg";

// GVC //
export { ReactComponent as GVCIcon } from "./icons/default/gvc.svg";
export { ReactComponent as GVCIconAdd } from "./icons/default/gvc-add.svg";
export { ReactComponent as WorkloadIcon } from "./icons/default/layer.svg";
export { ReactComponent as WorkloadIconAdd } from "./icons/default/layer-add.svg";
export { ReactComponent as IdentityIcon } from "./icons/default/identities.svg";
export { ReactComponent as VolumeSetIcon } from "./icons/default/volumesets.svg";

// Analysis //
export { ReactComponent as LogsIcon } from "./icons/default/logs.svg";
export { ReactComponent as MetricsIcon } from "./icons/default/metrics.svg";
export { ReactComponent as UsageIcon } from "./icons/default/usage.svg";
export { ReactComponent as QuotaIcon } from "./icons/default/quotas.svg";

// Services //
export { ReactComponent as KubernetesIcon } from "./icons/default/kubernetes.svg";

// PNG Icons
const pngIconStyle = { width: 20, height: 20 };
import { observer } from "mobx-react-lite";
import { Theme } from "../../mobxStores/uiData/theme";

import AppsIconImageWhite from "./icons/default/appsWhite.png";
import AppsIconImageLight from "./icons/default/appsLight.png";
import AppsIconImageDark from "./icons/default/appsDark.png";

export const AppsIcon = observer((props) => {
  return (
    <img
      src={
        (props as any).className.includes("menu-link-icon-active")
          ? AppsIconImageWhite
          : Theme.theme === "dark"
          ? AppsIconImageDark
          : AppsIconImageLight
      }
      alt="description"
      style={pngIconStyle}
    />
  );
});

import MarketplaceIconImageWhite from "./icons/default/marketplaceWhite.png";
import MarketplaceIconImageLight from "./icons/default/marketplaceLight.png";
import MarketplaceIconImageDark from "./icons/default/marketplaceDark.png";
export const MarketplaceIcon = observer((props) => {
  return (
    <img
      src={
        (props as any).className.includes("menu-link-icon-active")
          ? MarketplaceIconImageWhite
          : Theme.theme === "dark"
          ? MarketplaceIconImageDark
          : MarketplaceIconImageLight
      }
      alt="description"
      style={pngIconStyle}
    />
  );
});

import IpSetIconImageWhite from "./icons/default/ipsetWhite.png";
import IpSetIconImageLight from "./icons/default/ipsetLight.png";
import IpSetIconImageDark from "./icons/default/ipsetDark.png";

export const IpSetIcon = observer((props) => {
  return (
    <img
      src={
        (props as any).className.includes("menu-link-icon-active")
          ? IpSetIconImageWhite
          : Theme.theme === "dark"
          ? IpSetIconImageDark
          : IpSetIconImageLight
      }
      alt="description"
      style={pngIconStyle}
    />
  );
});

// Access Control //
export { ReactComponent as UserIcon } from "./icons/default/users.svg";
export { ReactComponent as GroupIcon } from "./icons/default/groups.svg";
export { ReactComponent as ServiceAccountIcon } from "./icons/default/service-accounts.svg";
export { ReactComponent as PolicyIcon } from "./icons/default/policies.svg";
export { ReactComponent as AuditTrailIcon } from "./icons/default/audit-trail.svg";
export { ReactComponent as AuditContextIcon } from "./icons/default/audit-context.svg";

// Billing //
export { ReactComponent as AccountIcon } from "./icons/default/account.svg";
export { ReactComponent as InvoiceIcon } from "./icons/default/invoice.svg";
export { ReactComponent as PaymentMethodIcon } from "./icons/default/payment-method.svg";

//Alert//
export { ReactComponent as AlertSuccess } from "./icons/default/alert-success.svg";
export { ReactComponent as AlertError } from "./icons/default/alert-error.svg";
export { ReactComponent as AlertWarning } from "./icons/default/alert-waning.svg";
export { ReactComponent as AlertInfo } from "./icons/default/alert-info.svg";
export { ReactComponent as Notification } from "./icons/default/notification.svg";
