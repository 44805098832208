import { observer } from "mobx-react-lite";
import * as React from "react";
import { FormLabel } from "../../../components/forms/formLabel";

interface Props {
  defaultObject: any;
}
const HealthCheckDefaultRaw: React.FC<Props> = ({ defaultObject: o }) => {
  return (
    <div>
      <FormLabel size="base">Currently Used Default Value</FormLabel>
      {o.httpGet ? (
        <div className="mt-2 mb-4">
          <div className="flex items-center mb-4">
            <div className="w-3/12">
              <FormLabel size="small">Type</FormLabel>
              <div>HTTP</div>
            </div>
            <div className="w-3/12">
              <FormLabel size="small">Scheme</FormLabel>
              <div>{o.httpGet.scheme}</div>
            </div>

            <div className="w-3/12">
              <FormLabel size="small">Port</FormLabel>
              <div>{o.httpGet.port || "None"}</div>
            </div>
          </div>
          <div className="mb-4">
            <div className="w-full">
              <FormLabel size="small">Path</FormLabel>
              <div>{o.httpGet.path}</div>
            </div>
          </div>
          <div>
            <FormLabel>Headers</FormLabel>
            <div>
              {o.httpGet.httpHeaders.length < 1
                ? "No Headers"
                : o.httpGet.httpHeaders.map((header: any) => (
                    <div className="text-sm my-1 leading-tight">
                      <div className="font-semibold">{header.name}</div>
                      <div>{header.value}</div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      ) : o.exec ? (
        <div className="mt-2 mb-4">
          <div className="mb-4">
            <FormLabel size="small">Type</FormLabel>
            <div>Command</div>
          </div>
          <div className="mb-4">
            <FormLabel size="small">Command</FormLabel>
            <div>{o.exec.command[0]}</div>
          </div>
          <div>
            <FormLabel>Arguments</FormLabel>
            <div>
              {o.exec.command.slice(1).length < 1
                ? "No Arguments"
                : o.exec.command.slice(1).map((command: string) => <div>{command}</div>)}
            </div>
          </div>
        </div>
      ) : o.tcpSocket ? (
        <div className="mt-2 mb-4">
          <div className="mb-4">
            <FormLabel size="small">Type</FormLabel>
            <div>TCP</div>
          </div>
          <div className="mb-4">
            <FormLabel size="small">Port</FormLabel>
            <div>{o.tcpSocket.port}</div>
          </div>
        </div>
      ) : o.grpc ? (
        <div className="mt-2 mb-4">
          <div className="mb-4">
            <FormLabel size="small">Type</FormLabel>
            <div>GRPC</div>
          </div>
          <div className="mb-4">
            <FormLabel size="small">Port</FormLabel>
            <div>{o.grpc.port}</div>
          </div>
        </div>
      ) : null}
      <div className="mb-4 flex items-center" style={{ width: 800 }}>
        <div className="w-3/12">
          <FormLabel>Initial Delay Seconds</FormLabel>
          <div>{o.initialDelaySeconds}</div>
        </div>
        <div className="w-3/12">
          <FormLabel>Period Seconds</FormLabel>
          <div>{o.periodSeconds}</div>
        </div>
        <div className="w-3/12">
          <FormLabel>Timeout Seconds</FormLabel>
          <div>{o.timeoutSeconds}</div>
        </div>
      </div>
      <div className="mb-4 flex items-center" style={{ width: 800 }}>
        <div className="w-3/12">
          <FormLabel>Success Threshold</FormLabel>
          <div>{o.successThreshold}</div>
        </div>
        <div className="w-3/12">
          <FormLabel>Failure Threshold</FormLabel>
          <div>{o.failureThreshold}</div>
        </div>
      </div>
    </div>
  );
};

export const HealthCheckDefault = observer(HealthCheckDefaultRaw);
