import * as React from "react";
import { ExternalLink } from "react-feather";
import { useNavigate } from "react-router-dom";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { DOCS_URL } from "../../../envVariables";

export const SetupPaymentMethod: React.FC = () => {
  const { AccountUUID, AccountIsDisabled } = BillingContext;
  const navigate = useNavigate();

  return (
    <div className="mb-10">
      <div className="mb-4 text-2xl">Thank you for creating an account.</div>
      {AccountIsDisabled ? (
        <NGAlert
          className="mb-2"
          render={() => (
            <div>
              Your account is not activated yet. Please wait until customer support enables your account, or contact
              support at <a href={"mailto:support@controlplane.com"}>support@controlplane.com</a> if you need further
              help.
            </div>
          )}
        />
      ) : null}
      <div>An account:</div>
      <ul>
        <li className="ml-8 list-disc">Is responsible for the billing</li>
        <li className="ml-8 list-disc">
          Can contain multiple{" "}
          <a href={`${DOCS_URL}/concepts/org`} target={"_blank"} className={`ngfocus color-link`}>
            orgs
            <ExternalLink className="feather-icon inline-block ml-1" style={{ transform: "translateY(2px)" }} />
          </a>
        </li>
        <li className="ml-8 list-disc">Can be managed by multiple users</li>
        <li className="ml-8 list-disc">
          Requires a valid payment method to create an{" "}
          <a href={`${DOCS_URL}/concepts/org`} target={"_blank"} className={`ngfocus color-link`}>
            org
            <ExternalLink className="feather-icon inline-block ml-1" style={{ transform: "translateY(2px)" }} />
          </a>
        </li>
      </ul>
      <div className="mt-2  ">
        Refer to the{" "}
        <a className="color-link" href={DOCS_URL} target={"_blank"}>
          documentation
          <ExternalLink className="feather-icon inline-block ml-1" style={{ transform: "translateY(2px)" }} />
        </a>{" "}
        for more information.
      </div>
      <NGButton
        className="mt-6"
        variant={"action"}
        onClick={() => navigate(`/billing/account/${AccountUUID}/payment-methods/-add`)}
      >
        Add a payment method
      </NGButton>
    </div>
  );
};
