import { types } from "mobx-state-tree";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { NumberModel } from "../../../../mobxDataModels/numberModel";

// NOTE determining the duration state for the creation of the model
export function handleDetermineNanosInDuration(duration: string | { nanos: number; seconds: number }) {
  let includeNanos: boolean = false;
  if ((duration as any)?.nanos || (duration as any)?.seconds) {
    includeNanos = true;
  }
  return includeNanos;
}

export const DurationDraft = types
  .model({
    _includeNanos: types.optional(types.boolean, false),
    includeNanos: types.optional(types.boolean, false),
    _seconds: types.optional(types.string, ""),
    seconds: types.optional(StringModel, () => NumberModel.create({ label: "Seconds", min: 0, isRequired: true })),
    _nanos: types.optional(types.string, ""),
    nanos: types.optional(StringModel, () => NumberModel.create({ label: "Nanos", min: 0 })),
  })
  .actions((self) => ({
    setIncludeNanos(value: boolean) {
      self.includeNanos = value;
    },
  }))
  .actions((self) => ({
    reset() {
      self.includeNanos = self._includeNanos;
      self.seconds.setInitialValue(self._seconds);
      self.nanos.setInitialValue(self._nanos);
    },
    confirm() {
      self._includeNanos = self.includeNanos;
      self.seconds.confirm();
      self.nanos.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.includeNanos !== self._includeNanos) res = true;
      if (self.seconds.isDirty) res = true;
      if (self.includeNanos) {
        if (self.nanos.isDirty) res = true;
      } else {
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.seconds.isValid) res = false;
      if (self.includeNanos) {
        if (!self.nanos.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      let res: any = {};
      if (self.includeNanos) {
        res.seconds = Number(self.seconds.value);
        res.nanos = Number(self.nanos.value);
      } else {
        res = `${self.seconds.value}s`;
      }
      return res;
    },
  }));
