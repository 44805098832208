import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";
import { TabsLayout, TabsLayoutLink } from "../../../../components/tabsLayout/tabsLayout";
import { homeLink, request } from "../../../../services/cpln";
import { Loader } from "../../../../components/layout/loader";
import { Kind } from "../../../../mst/base";
import { AlertCircle } from "react-feather";
import { NGButton } from "../../../../newcomponents/button/Button";
import { Binding } from "../../../../schema/types/policy";
import { Permission, Permissions } from "../../../../schema/types/permissions";
import { UserTable } from "../targetTables/user";
import { ServiceaccountTable } from "../targetTables/serviceaccount";
import { GroupTable } from "../targetTables/group";
import { IdentityTable } from "../targetTables/identity";
import { permissionsHelpers } from "../../../../mst/kinds/permissions.helpers";
import { Table } from "../../../../newcomponents/table/table";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";

interface PermissionTableItem extends Permission {
  kind: "permission";
  description: string;
  implied: string[];
  impliedText: string;
}

type RouteParams = "indexString";

interface Props {
  bindings: Binding[];
  setBindings: React.Dispatch<React.SetStateAction<Binding[]>>;
  rootUrl: string;
  targetKind: string;
}
const PolicyCreateBindingDetailRaw: React.FC<Props> = ({ bindings, setBindings, rootUrl, targetKind }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { indexString } = useParams<RouteParams>();

  const [isPageReady, setIsPageReady] = React.useState(false);

  const bindingRef = React.useRef<{ permissions: string[]; principalLinks: string[] }>(
    indexString === "new"
      ? { permissions: [], principalLinks: [] }
      : JSON.parse(JSON.stringify(bindings[Number(indexString)])),
  );

  const [permissionsResponse, setPermissionsResponse] = React.useState<Permissions>();

  const [permissionSelections, setPermissionSelections] = React.useState<string[]>([]);
  const [userSelections, setUserSelections] = React.useState<string[]>([]);
  const [serviceaccountSelections, setServiceaccountSelections] = React.useState<string[]>([]);
  const [groupSelections, setGroupSelections] = React.useState<string[]>([]);
  const [identitySelections, setIdentitySelections] = React.useState<string[]>([]);

  const [canAdd, setCanAdd] = React.useState(false);

  React.useEffect(() => {
    let res = true;
    if (permissionSelections.length < 1) res = false;
    if (
      userSelections.length + serviceaccountSelections.length + groupSelections.length + identitySelections.length <
      1
    )
      res = false;
    setCanAdd(res);
  }, [
    permissionSelections.length,
    userSelections.length,
    serviceaccountSelections.length,
    groupSelections.length,
    identitySelections.length,
  ]);

  React.useEffect(() => {
    setPermissionSelections([...bindingRef.current.permissions]);
    setUserSelections([...bindingRef.current.principalLinks.filter((l) => l.includes("/user/"))]);
    setServiceaccountSelections([...bindingRef.current.principalLinks.filter((l) => l.includes("/serviceaccount/"))]);
    setGroupSelections([...bindingRef.current.principalLinks.filter((l) => l.includes("/group/"))]);
    setIdentitySelections([...bindingRef.current.principalLinks.filter((l) => l.includes("/identity/"))]);
  }, []);

  React.useEffect(() => {
    fetchPermissions();
  }, [targetKind]);

  async function fetchPermissions() {
    const { data } = await request({ url: homeLink(targetKind as Kind) + "/-schema/permissions" });
    setPermissionsResponse(data);
    setIsPageReady(true);
  }

  function cancel() {
    navigate(`${rootUrl}/overview`);
  }

  async function addOrEdit() {
    const newBinding = {
      permissions: permissionSelections,
      principalLinks: [...userSelections, ...serviceaccountSelections, ...groupSelections, ...identitySelections],
    };
    if (indexString === "new") {
      setBindings([...bindings, newBinding]);
    } else {
      const newBindings: Binding[] = [];
      for (let bindingIndex in bindings) {
        const binding = bindings[bindingIndex];
        if (bindingIndex === indexString) {
          newBindings.push(newBinding);
        } else {
          newBindings.push(binding);
        }
      }
      setBindings(newBindings);
    }
    navigate(`${rootUrl}/overview`);
  }

  const links: TabsLayoutLink[] = [
    { url: `${rootUrl}/${indexString}/-permissions`, label: "Permissions" },
    { url: `${rootUrl}/${indexString}/-users`, label: `Users` },
    { url: `${rootUrl}/${indexString}/-serviceaccounts`, label: `Service Accounts` },
    { url: `${rootUrl}/${indexString}/-groups`, label: `Groups` },
    { url: `${rootUrl}/${indexString}/-identities`, label: `Identities` },
  ];

  let permissionTableItems: PermissionTableItem[] = [];
  if (permissionsResponse) {
    permissionTableItems = permissionsResponse.items.map((p) => ({
      ...p,
      description: p.description || "",
      kind: "permission",
      implied: permissionsHelpers.getImplied(permissionsResponse, p),
      impliedText: permissionsHelpers.getImpliedText(permissionsResponse, p),
    }));
  }

  if (!isPageReady) {
    return <Loader reason={"Fetching permissions for target kind: " + targetKind} />;
  }

  return (
    <>
      <TabsLayout links={links}>
        <Routes>
          <Route index element={<Navigate to={`-permissions`} />} />
          <Route
            path={`-permissions`}
            element={
              <>
                <div className={`mb-4 flex p-4 border rounded`}>
                  <AlertCircle className="mr-2" />
                  <div>
                    A permission defines which action rights are given to the principals (users, service accounts,
                    groups, and identities).
                  </div>
                </div>
                <Table<PermissionTableItem>
                  tableId="policy-create-binding-permission"
                  selectMode="multi"
                  selectKey={"name"}
                  selections={permissionSelections}
                  onSelectionsChange={setPermissionSelections}
                  data={permissionTableItems}
                  columns={[
                    NameDescriptionNoLinkColumn(), //
                    { id: "impliedText", label: "Implied" },
                  ]}
                  hideSettings
                />
              </>
            }
          />
          <Route
            key={"userTable"}
            path={`-users`}
            element={<UserTable title="Users" selections={userSelections} onSelectionsChange={setUserSelections} />}
          />
          <Route
            key={"serviceaccountTable"}
            path={`-serviceaccounts`}
            element={
              <ServiceaccountTable
                title="Service Accounts"
                selections={serviceaccountSelections}
                onSelectionsChange={setServiceaccountSelections}
              />
            }
          />
          <Route
            key={"groupTable"}
            path={`-groups`}
            element={
              <GroupTable title={"Groups"} selections={groupSelections} onSelectionsChange={setGroupSelections} />
            }
          />
          <Route
            key={"identityTable"}
            path={`-identities`}
            element={
              <IdentityTable
                title={"Identities"}
                selections={identitySelections}
                onSelectionsChange={setIdentitySelections}
              />
            }
          />
        </Routes>
      </TabsLayout>
      <div className="mt-8 flex items-center">
        <NGButton style={{ width: 215, marginRight: 10 }} variant={"danger"} onClick={cancel}>
          Cancel
        </NGButton>
        <NGButton style={{ width: 215, marginLeft: 10 }} disabled={!canAdd} variant={"primary"} onClick={addOrEdit}>
          {pathname.includes("new") ? "Add" : "Edit"}
        </NGButton>
      </div>
    </>
  );
};

export const PolicyCreateBindingDetail = observer(PolicyCreateBindingDetailRaw);
