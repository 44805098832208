import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGSwitch } from "../../../../newcomponents/switch";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGLabelText } from "../../../../newcomponents/text/labelText";
import { DryRunAlert } from "../../dryRunAlert";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const CreateAddonsLogsRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <div>
      <div className="mb-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.logs"}
        />
      </div>
      <div className="mb-2">Logs</div>
      <div className="flex gap-2 items-start">
        <NGFormElement
          name={`logs.includeNamespaces`}
          label={mk8sDraft.addon_logs.includeNamespaces.label}
          placeholder="Regex"
          value={mk8sDraft.addon_logs.includeNamespaces.value}
          required={mk8sDraft.addon_logs.includeNamespaces.isRequired}
          error={mk8sDraft.addon_logs.includeNamespaces.error}
          onChange={mk8sDraft.addon_logs.includeNamespaces.setValue}
        />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={mk8sDraft.addon_logs.includeNamespaces.setValue}
            path={"spec.addOns.logs.includeNamespaces"}
          />
        </div>
      </div>

      <div className="flex gap-2 items-start">
        <NGFormElement
          name={`logs.excludeNamespaces`}
          label={mk8sDraft.addon_logs.excludeNamespaces.label}
          placeholder="Regex"
          value={mk8sDraft.addon_logs.excludeNamespaces.value}
          required={mk8sDraft.addon_logs.excludeNamespaces.isRequired}
          error={mk8sDraft.addon_logs.excludeNamespaces.error}
          onChange={mk8sDraft.addon_logs.excludeNamespaces.setValue}
        />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={mk8sDraft.addon_logs.excludeNamespaces.setValue}
            path={"spec.addOns.logs.excludeNamespaces"}
          />
        </div>
      </div>
      <div className="flex items-center">
        <NGSwitch
          value={mk8sDraft.addon_logs.auditEnabled}
          onChange={(checked) => mk8sDraft.addon_logs.setAuditEnabled(checked)}
        >
          <NGLabelText>Enable Audit</NGLabelText>
        </NGSwitch>
      </div>
      <div className="mt-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.logs.auditEnabled"}
        />
      </div>
    </div>
  );
};

export const CreateAddonsLogs = observer(CreateAddonsLogsRaw);
