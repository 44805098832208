import * as React from "react";
import "./circle-spinner.css";

interface Props {
  color?: "gray" | "gray-outline" | "red" | "blue" | "red" | "green" | "green-outline" | "danger";
}
export const CircleSpinner: React.FC<Props> = ({ color = "gray" }) => {
  let colorToPass = color;
  if (color === "gray-outline" || color === "green-outline") {
    colorToPass = "gray";
  }
  return (
    <div className="lds-ring">
      <div className={colorToPass}></div>
      <div className={colorToPass}></div>
      <div className={colorToPass}></div>
      <div className={colorToPass}></div>
    </div>
  );
};
