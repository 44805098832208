import { types, Instance } from "mobx-state-tree";
import { WithIdentifier, WithNameAndDescription, WithLinks, WithTags, WithKind, WithVersion } from "./base";
import { AgentModel } from "./kinds/agent";
import { CloudaccountModel } from "./kinds/cloudaccount";
import { DeploymentModel } from "./kinds/deployment";
import { DomainModel } from "./kinds/domain";
import { createGroupInstance, GroupModel } from "./kinds/group";
import { IdentityModel } from "./kinds/identity";
import { LocationModel } from "./kinds/location";
import { OrgModel } from "./kinds/org";
import { PermissionsModel } from "./kinds/permissions";
import { PolicyModel } from "./kinds/policy";
import { QuotaModel } from "./kinds/quota";
import { SecretModel } from "./kinds/secret";
import { ServiceaccountModel } from "./kinds/serviceaccount";
import { UserModel } from "./kinds/user";
import { WorkloadModel } from "./kinds/workload";
import { GVCModel } from "./kinds/gvc";
import { AuditContextModel } from "./kinds/auditContext";
import { VolumeSetModel } from "./kinds/volumeset";
import { v4 as uuidv4 } from "uuid";
import { mk8sModel } from "./kinds/mk8s/mk8s";

// TODO need to remove
export const GenericItemModel = types.union(
  types.late(() => AgentModel),
  types.late(() => AuditContextModel),
  types.late(() => CloudaccountModel),
  types.late(() => DeploymentModel),
  types.late(() => DomainModel),
  types.late(() => GroupModel),
  types.late(() => GVCModel),
  types.late(() => IdentityModel),
  types.late(() => LocationModel),
  types.late(() => mk8sModel),
  types.late(() => OrgModel),
  types.late(() => PermissionsModel),
  types.late(() => PolicyModel),
  types.late(() => QuotaModel),
  types.late(() => SecretModel),
  types.late(() => ServiceaccountModel),
  types.late(() => UserModel),
  types.late(() => VolumeSetModel),
  types.late(() => WorkloadModel)
);

export const CreateGenericItem = (data: WithKind) => {
  switch (data.kind) {
    case "agent":
      try {
        return AgentModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "auditctx":
      try {
        return AuditContextModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "cloudaccount":
      try {
        return CloudaccountModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "deployment":
      try {
        const dataWithId = Object.assign({ id: uuidv4() }, data);
        return DeploymentModel.create(dataWithId as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "domain":
      try {
        return DomainModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "group":
      try {
        return createGroupInstance(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "gvc":
      try {
        return GVCModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "identity":
      try {
        return IdentityModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "location":
      try {
        return LocationModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "mk8s":
      try {
        return mk8sModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "org":
      try {
        return OrgModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "policy":
      try {
        return PolicyModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "quota":
      try {
        return QuotaModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "secret":
      try {
        return SecretModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "serviceaccount":
      try {
        return ServiceaccountModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "user":
      try {
        return UserModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "volumeset":
      try {
        return VolumeSetModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    case "workload":
      try {
        return WorkloadModel.create(data as any) as GenericItemMobx;
      } catch (e) {
        throw e;
      }
    default:
      try {
        return data as GenericItemMobx;
      } catch (e) {
        throw e;
      }
  }
};

export interface GenericItemMobx extends Instance<typeof GenericItemModel> {}
export interface GenericItem
  extends WithKind,
    WithIdentifier,
    WithNameAndDescription,
    WithLinks,
    WithTags,
    WithVersion {}
