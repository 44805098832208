import { action, makeObservable, observable } from "mobx";

export type ThemeOption = "light" | "dark";
class ThemeMobx {
  theme: ThemeOption = "light";
  isRespectingOS: boolean = false;

  constructor() {
    makeObservable(this, {
      theme: observable,
      setTheme: action,
      respectOS: action,
      setToInitialTheme: action,
      isRespectingOS: observable,
      setIsRespectingOS: action,
    });
    this.setIsRespectingOS();
  }

  setTheme(newTheme: ThemeOption, skipPersisting = false) {
    this.theme = newTheme;
    if (!skipPersisting) {
      localStorage.setItem("theme", newTheme);
    }

    if (this.theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    this.setIsRespectingOS();
  }

  respectOS() {
    localStorage.removeItem("theme");
    this.setToInitialTheme();
    this.setIsRespectingOS();
  }

  setToInitialTheme() {
    const localTheme = localStorage.getItem("theme");
    const osPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const initial = localTheme === "dark" || (!localTheme && osPrefersDark) ? "dark" : "light";
    this.setTheme(initial, true);
  }

  setIsRespectingOS() {
    this.isRespectingOS = !localStorage.getItem("theme");
  }
}

export const Theme = new ThemeMobx();
