import * as React from "react";
import { VERSION_CHECK_INTERVAL_MS } from "../../envVariables";
import { Popover } from "antd";
import about from "./../../about.json";
import { request } from "../../services/cpln";

export const VersionCopyright: React.FC = () => {
  React.useEffect(() => {
    const id = setInterval(checkVersion, VERSION_CHECK_INTERVAL_MS);
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, []);

  async function checkVersion() {
    const { data: aboutRes } = await request({ service: "console", url: "/about.json" });
    const onlineVersion = aboutRes.version;
    const selfVersion = about.version;
    if (onlineVersion !== selfVersion) {
      window.location.reload();
    }
  }

  return (
    <Popover
      content={
        <div className="flex flex-col">
          {Object.entries(about).map(([key, value]) => (
            <span key={key + value}>
              {key}: {value}
            </span>
          ))}
        </div>
      }
      trigger={["click"]}
    >
      <span>©</span>
    </Popover>
  );
};
