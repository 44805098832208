import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { VolumeSetList } from "./list";
import { VolumeSetCreate } from "./create/create";
import { VolumeSetDetailRoute } from "./detailRoute";

const VolumeSetRoute: React.FC = () => {
  const { org } = ConsoleContext;
  const { gvc } = ConsoleContext;

  return (
    <Layout key={org! + gvc!}>
      <Routes>
        <Route index element={<VolumeSetList />} />
        <Route path={`-create/*`} element={<VolumeSetCreate />} />
        <Route path={`:volumeset/*`} element={<VolumeSetDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(VolumeSetRoute);
