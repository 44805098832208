import jsYaml from "js-yaml";

type Language = "json" | "yaml" | "javascript" | "shell" | "markdown";

export function guessFileTextLanguage(
  value: string,
  defaultValue: Language = "yaml"
): { result: Language; error: string } {
  let language = defaultValue;
  let errorMessage = "";

  if (!value) {
    return { result: language, error: errorMessage };
  }

  if (value.trim().startsWith("{")) {
    language = "json";
    try {
      JSON.parse(value);
    } catch (e) {
      errorMessage = e.message;
    }
  } else {
    try {
      // @ts-ignore
      jsYaml.loadAll(value, { json: true });
      language = "yaml";
    } catch (e) {
      errorMessage = e.message;
    }
  }
  return { result: language, error: errorMessage };
}
