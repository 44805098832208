export const tagHelpers = (tags: { key: string; value: string }[]) => {
  return {
    getDuplicateKeyData(): { hasDuplicates: boolean; index: number } {
      const allKeys = tags.map((t) => t.key);
      const foundKeys: string[] = [];
      for (const indexStr in tags) {
        const index = Number(indexStr);
        const key = allKeys[index];
        if (foundKeys.includes(key)) {
          return { hasDuplicates: true, index: index };
        }
        foundKeys.push(key);
        // const value = tags[key];
      }
      return { hasDuplicates: false, index: -1 };
    },
  };
};
