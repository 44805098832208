import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { CloudaccountList } from "./list";
import { Routes, Route } from "react-router-dom";
import { CloudaccountCreate } from "./create/create";
import { CloudaccountDetailRoute } from "./detailRoute";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const CloudaccountRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<CloudaccountList />} />
        <Route path={`-create/*`} element={<CloudaccountCreate />} />
        <Route path={`:cloudaccount/*`} element={<CloudaccountDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(CloudaccountRoute);
