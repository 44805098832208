import * as React from "react";
import { observer } from "mobx-react-lite";
import { VolumeSetDraftModel } from "../../../mst/stores/volumeset.draft";
import { Navigate, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { parentLink, request, resourceLink } from "../../../services/cpln";
import { notification } from "antd";
import { CreateFormLink } from "../../org/create/create";
import { CreateLayout } from "../../../components/create/layout";
import { TagsNew } from "../../../components/create/tags";
import { General } from "./general";
import { Spec } from "./spec";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";

const VolumeSetCreateRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const basePath = useBasePath("/gvc/:gvc/volumeset/-create/*");

  const draftRef = React.useRef(VolumeSetDraftModel.create({ _hasAutoscaling: false }));
  const draft = draftRef.current;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const startingPage = `${basePath}/-general`;
    navigate(startingPage, { replace: true });
  }, []);

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({ method: "post", url: parentLink("volumeset"), body: draft.asObject });
      draft.confirm();
      const itemLink = resourceLink("volumeset", draft.name.value);
      notification.success({
        message: "Success",
        description: "Volume Set is created",
      });
      setIsLoading(false);
      PromptContext.setIsDisabled(true);
      navigate(`/console${itemLink}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/volumeset/-create");
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    { name: "Spec", value: `-spec`, isActive: draft.name.isValid },
    {
      name: "Tags",
      value: `-tags`,
      isActive: draft.name.isValid,
    },
  ];

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={() => ({ kind: "volumeset", ...draft.asObject })}
          next={draft.next(pathname)}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Volume Set"}
          name={draft.name.value}
          canCreate={draft.isValid}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} replace />} />
            <Route path={`-general`} element={<General draft={draft} />} />
            <Route path={`-spec`} element={<Spec draft={draft} />} />
            <Route path={`-tags`} element={<TagsNew tags={draft.tags} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const VolumeSetCreate = observer(VolumeSetCreateRaw);
