import * as React from "react";
import { Table } from "../../../../newcomponents/table/table";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { linksOf, nameOfKind } from "../../../../services/cpln";
import { Policy } from "../../../../schema/types/policy";
import { allKinds } from "../../../../services/kindMetadata";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";

interface PolicyTableItem extends Policy {
  selfLink: string;
}
interface Props {
  title: string;
  selections: string[];
  onSelectionsChange: (values: string[]) => void;
}
export const PolicyTable: React.FC<Props> = ({ title, selections, onSelectionsChange }) => {
  const { kind, id } = useTableKindId("policy", "policy-create-target-list-policy");
  const qData = useTableItemQueryData<Policy>(kind, { fetchAllPages: true });

  const policyTableItems = qData.visibleItems.map((policy) => {
    const selfLink = linksOf(policy).self!;

    return {
      ...policy,
      selfLink: selfLink,
    };
  });

  return (
    <Table<PolicyTableItem>
      title={title}
      data={policyTableItems}
      tableId={id}
      selectMode="multi"
      selectKey={"selfLink"}
      selections={selections}
      onSelectionsChange={onSelectionsChange}
      headerRenderer={() => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
            <CustomTableCPLNQuery
              tableId={id}
              kind={kind}
              onQuery={qData.setQuery}
              isLoading={qData.isLoadingQueried}
            />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionNoLinkColumn(), //
        {
          id: "targetKind",
          label: "Target Kind",
          canGroupByDistinctValues: true,
          groupLabelMap: allKinds
            .map((k) => ({ kind: k, label: nameOfKind(k) }))
            .reduce((acc, item) => {
              acc[item.kind] = item.label;
              return acc;
            }, {}),
          enableHide: true,
        },
        {
          id: "origin",
          label: "Origin",
          canGroupByDistinctValues: true,
          groupLabelMap: {
            builtin: "Builtin",
            default: "Default",
          },
          enableHide: true,
        },
        TagsColumn(),
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};
