import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftMobx } from "../../../mst/stores/identity.draft";
import { TabsLayout, TabsLayoutLink } from "../../../components/tabsLayout/tabsLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import { NetworkResourcesBase } from "./networkResourcesBase";
import { BasePathContext } from "../../../reactContexts/basePathContext";

interface Props {
  identity: IdentityDraftMobx;
}

const IdentityManageNetworkResourcesRaw: React.FC<Props> = ({ identity }) => {
  const basePath = React.useContext(BasePathContext);
  const networkResourcesPath = `${basePath}/-networkresources`;

  const links: TabsLayoutLink[] = [
    {
      url: `${networkResourcesPath}/-fqdn`,
      label: `FQDN Resources (${identity.networkResources.resources.filter((r) => r.discover === "fqdn").length})`,
    },
    {
      url: `${networkResourcesPath}/-ip`,
      label: `IP Resources (${identity.networkResources.resources.filter((r) => r.discover === "ip").length})`,
    },
  ];

  return (
    <TabsLayout links={links}>
      <Routes>
        <Route index element={<Navigate to={`-fqdn`} />} />
        <Route path={`-fqdn`} element={<NetworkResourcesBase type="fqdn" identity={identity} />} />
        <Route path={`-ip`} element={<NetworkResourcesBase type="ip" identity={identity} />} />
      </Routes>
    </TabsLayout>
  );
};

export const IdentityManageNetworkResources = observer(IdentityManageNetworkResourcesRaw);
