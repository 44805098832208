import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderHetznerDedicatedServerNodePoolAddRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isDone, setIsDone] = React.useState(false);

  React.useEffect(() => {
    if (isDone) {
      navigate(
        `${pathname.replace("-add", "")}${
          mk8sDraft.provider_hetzner.dedicatedServerNodePools[
            mk8sDraft.provider_hetzner.dedicatedServerNodePools.length - 1
          ].index
        }`
      );
      return;
    }

    mk8sDraft.provider_hetzner.addDedicatedServerNodePool();
    setIsDone(true);
  }, [isDone]);

  return null;
};

export const Mk8sCreateProviderHetznerDedicatedServerNodePoolAdd = observer(
  Mk8sCreateProviderHetznerDedicatedServerNodePoolAddRaw
);
