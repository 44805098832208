import { makeAutoObservable } from "mobx";

const sectionKeyMap = {
  debug: "d",
};

type SECTION = keyof typeof sectionKeyMap;

class HiddenSections {
  revealedSections: SECTION[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  isRevealed(section: SECTION) {
    return this.revealedSections.includes(section);
  }

  toggle(key: string) {
    for (const [_section, value] of Object.entries(sectionKeyMap)) {
      const section = _section as SECTION;
      if (value !== key) {
        continue;
      }
      if (this.revealedSections.includes(section)) {
        const index = this.revealedSections.indexOf(section);
        this.revealedSections.splice(index, 1);
      } else {
        this.revealedSections.push(section);
      }
    }
  }

  reveal(section: SECTION) {
    if (this.isRevealed(section)) {
      return;
    }
    const key = sectionKeyMap[section];
    if (!key) {
      console.error(`Section "${section}" is not found in HiddenSections`);
      return;
    }
    this.toggle(key);
  }

  hide(section: SECTION) {
    if (!this.isRevealed(section)) {
      return;
    }
    const key = sectionKeyMap[section];
    if (!key) {
      console.error(`Section "${section}" is not found in HiddenSections`);
      return;
    }
    this.toggle(key);
  }

  get keys() {
    return Object.values(sectionKeyMap);
  }
}

export const HiddenSectionsMobx = new HiddenSections();
