import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation } from "react-router-dom";
import { UserData } from "../../../../mobxStores/userData/userData";
import { LinkWithChange } from "../LinkWithChange";
import { GVCIcon } from "../../../Icons";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { request, resourceLink } from "../../../../services/cpln";
import { flowResult } from "mobx";
import { useGVCEmitter } from "../../../../emitters/gvc";
import { PromptContext } from "../../../../mobxStores/prompt/prompt";
import { MenuLinkProps } from "../menu";

const GvcChangeRaw: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { org, gvc } = ConsoleContext;
  const menuLinkProps: MenuLinkProps = {
    url: `/console/org/${org}/gvc/${gvc}`,
    prefix: "GVC",
    label: gvc || "",
    Icon: GVCIcon,
    tooltip: {
      content: gvc || "no gvc",
      disable: (gvc || "").length < 16,
      placement: "top",
    },
    customIsActive: isActive,
  };

  function isActive(): boolean {
    if (pathname.includes("/workload") || pathname.includes("/identity") || pathname.includes("/volumeset")) {
      return false;
    }
    if (pathname.includes("/gvc/") && pathname.includes("/org/")) {
      return true;
    }
    return false;
  }

  async function selectItem(value: string) {
    try {
      const isWorkloadDetail = pathname.includes("/workload/");
      const isIdentityDetail = pathname.includes("/identity/");
      const isVolumeSetDetail = pathname.includes("/volumeset/");
      const isGVCScopedPage = pathname.includes("/gvc/");
      if (isGVCScopedPage) {
        ConsoleContext.setIsChangingContext(true);
      }

      const oldGVC = gvc;
      await request({ url: resourceLink("gvc", value) });
      ConsoleContext.setGVC(value);
      PromptContext.setIsDisabled(true);
      if (isWorkloadDetail) {
        ConsoleContext.setRedirect(`/console/org/${org}/gvc/${value}/workload`);
      } else if (isIdentityDetail) {
        ConsoleContext.setRedirect(`/console/org/${org}/gvc/${value}/identity`);
      } else if (isVolumeSetDetail) {
        ConsoleContext.setRedirect(`/console/org/${org}/gvc/${value}/volumeset`);
      } else if (pathname.includes(`/gvc/${oldGVC}`)) {
        ConsoleContext.setRedirect(pathname.replace(`/gvc/${oldGVC}/`, `/gvc/${value}/`));
      }
    } catch (_) {
      await flowResult(UserData.requestGVCNames());
      ConsoleContext.setIsChangingContext(false);
    }
  }

  function createItem(): void {
    navigate(`/console/org/${org}/gvc/-create`);
  }

  async function requestItems(): Promise<void> {
    await flowResult(UserData.requestGVCNames());
  }

  return (
    <LinkWithChange
      kind="gvc"
      mountElementId="gvcChange"
      label={gvc}
      items={UserData.gvcNames.map((name) => ({ value: name, labels: [name] }))}
      value={ConsoleContext.gvc}
      menuLinkProps={menuLinkProps}
      isActiveFunc={isActive}
      selectItem={(value) => selectItem(value)}
      createItem={createItem}
      useEmitter={useGVCEmitter}
      requestItems={requestItems}
      canBePinned={true}
    />
  );
};

export const GvcChange = observer(GvcChangeRaw);
