import { makeObservable, observable, flow } from "mobx";
import { BASE_URL } from "../../envVariables";
import { getErrorMessage } from "../../services/utils";

interface Firebase {
  apiKey: string;
  authDomain: string;
  projectId: string;
}

interface Endpoints {
  billing: string;
  "billing-ng": string;
  metrics: string;
  metering: string;
  console: string;
  registry: string;
  browser: string;
  hub: string;
  audit: string;
  logs: string;
  grafana: string;
  "terraform-exporter": string;
  byok: string;
}

class DiscoveryMobx {
  requested: boolean = false;
  requesting: boolean = false;
  error: string = "";
  firebase: Firebase = {} as any;
  endpoints: Endpoints = {} as any;

  constructor() {
    makeObservable(this, {
      firebase: observable,
      endpoints: observable,
      requested: observable,
      requesting: observable,
      error: observable,
      request: flow,
    });
    this.request();
  }

  *request() {
    try {
      this.requesting = true;
      this.error = "";
      // @ts-ignore yield
      const res = yield fetch(`${BASE_URL}/discovery`);
      // @ts-ignore yield
      const resJson = yield res.json();
      this.firebase = resJson.firebase;
      this.endpoints = resJson.endpoints;
      this.requested = true;
    } catch (e) {
      this.error = getErrorMessage(e);
    } finally {
      this.requesting = false;
    }
  }
}

export const Discovery = new DiscoveryMobx();
