import * as React from "react";
import { Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { GVCMobx } from "../../mst/kinds/gvc";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { GVCPullSecrets } from "./pullSecrets";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { AuditTrail } from "../../components/detail/auditTrail";
import { Tracing } from "./tracing";
import { EnvVarsModel } from "../../mobxDataModels/envVarsModel";
import { notification } from "antd";
import { useDetailContext } from "../../components/detail/detailContext";
import { LoadBalancer } from "./loadBalancer";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { EnvVarWrapper } from "./envVarWrapper";
import { GVCLocations } from "./gvc-locations";
import { Auth } from "../../components/detail/auth";
import { OrgMobx } from "../../mst/kinds/org";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  org?: OrgMobx;
  gvc: GVCMobx;
}
const GvcDetailRaw: React.FC<Props> = ({ org, gvc }) => {
  const basePath = useBasePath("/gvc/:gvc/*");
  const { fetchItem } = useDetailContext();

  const eventlogHook = useEventlogs(gvc.selfLink);

  const env = React.useRef(
    EnvVarsModel.create({
      vars: (gvc.spec.env || []).map((env, idx) => ({
        name: env.name,
        value: env.value,
      })),
    }),
  );
  const [envLoading, setEnvLoading] = React.useState(false);
  function onEnvReset() {
    env.current.reset();
  }
  async function onEnvSave() {
    try {
      setEnvLoading(true);
      await gvc.patch({ spec: { "$replace/env": env.current.asArray } });
      env.current.confirm();
      setEnvLoading(false);
      notification.success({
        message: "Success",
        description: "Updated GVC Environment Variables",
      });
    } catch (e) {
      setEnvLoading(false);
      notification.warning({
        message: "Failed",
        description: "Failed to Update GVC Environment Variables",
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  const links = [
    { label: "Info", url: "-info" },
    { label: "GVC Locations", url: "-locations" },
    { label: "Pull Secrets", url: "-pullsecrets" },
    { label: "Load Balancer", url: "-loadbalancer" },
    { label: "Env Variables", url: "-env" },
    { label: "Auth", url: "-auth" },
    { label: "Tracing", url: "-tracing" },
    { label: "Tags", url: "-tags" },
    { label: "Metrics", url: "-metrics-gvc" },
    org?.spec?.tracing?.provider?.controlplane ? { label: "Traces", url: "-traces-gvc" } : undefined,
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ].filter(Boolean);

  return (
    <>
      <BasePathContext.Provider value={basePath}>
        <DetailLayout links={links as any}>
          <Route path={`-info`} element={<Info gvc={gvc} />} />
          <Route path={`-locations/*`} element={<GVCLocations gvc={gvc} />} />
          <Route path={`-pullsecrets/*`} element={<GVCPullSecrets gvc={gvc} />} />
          <Route path={`-loadbalancer`} element={<LoadBalancer gvc={gvc} />} />
          <Route
            path={`-env`}
            element={
              <EnvVarWrapper
                gvc={gvc}
                env={env.current}
                onSave={onEnvSave}
                onReset={onEnvReset}
                isLoading={envLoading}
              />
            }
          />
          <Route path={`-auth`} element={<Auth kind="gvc" patch={gvc.patch} envoy={gvc.spec.sidecar?.envoy} />} />
          <Route path={`-tracing`} element={<Tracing gvc={gvc} />} />
          <Route path={`-tags`} element={<Tags link={gvc.selfLink} tags={gvc.tags} />} />
          <Route path={`-metrics`} element={<div>Metrics</div>} />
          <Route
            path={`-events`}
            element={
              <Events
                kind={"gvc"}
                eventlogs={eventlogHook.eventlogs}
                isLoading={eventlogHook.isLoading}
                fetchEventlogs={eventlogHook.fetchEventlogs}
              />
            }
          />
          <Route path={`-audittrail`} element={<AuditTrail kind="gvc" resourceId={gvc.id} />} />
          <Route path={`-accessreport`} element={<AccessReport itemLink={gvc.selfLink} />} />
        </DetailLayout>
      </BasePathContext.Provider>
    </>
  );
};

export const GvcDetail = observer(GvcDetailRaw);
