import clsx from "clsx";
import * as React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./tabsLayout.css";

export interface TabsLayoutLink {
  url: string;
  onClick?: () => void;
  label: string;
  isActive?: boolean;
  group?: string;
  index?: string;
}

interface LinkProps {
  link: TabsLayoutLink;
  style?: any;
}
export const TabLink: React.FC<LinkProps> = ({ link, style = {} }) => {
  if (link.onClick) {
    return <AddLocalOptionTabLink link={link} />;
  }

  if (link.isActive === false) {
    return (
      <div
        data-testid={link.label}
        className="custom-tab truncate"
        style={{ marginRight: 22, height: 50, lineHeight: "48px", marginBottom: -2, opacity: 0.5, ...style }}
      >
        {link.label}
      </div>
    );
  }

  return (
    <NavLink
      data-testid={link.label}
      to={link.url}
      className={clsx(`custom-tab truncate ngfocus color-link-hover`)}
      style={{
        marginRight: 22,
        height: 50,
        lineHeight: "48px",
        marginBottom: -2,
        ...style,
      }}
    >
      {link.label}
    </NavLink>
  );
};

interface Props extends React.PropsWithChildren {
  links: TabsLayoutLink[];
  hide?: boolean;
}

export const TabsLayout: React.FC<Props> = ({ children, links, hide }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [dropdownValue, setDropdownValue] = React.useState("label");
  const hasDropdown = links.filter((l) => l.group).length > 3;

  React.useEffect(() => {
    const res = dropdownIsActive();
    setDropdownValue(res.value);
  }, [pathname]);

  function dropdownIsActive() {
    let isActive = false;
    let value = "label";
    for (let link of links.filter((l) => l.group)) {
      if (pathname.includes(`/-options/${link.index}`)) {
        isActive = true;
        value = link.url;
      }
    }
    return { isActive, value };
  }

  if (hide) {
    return <>{children}</>;
  }

  if (!hasDropdown) {
    return (
      <div>
        <div className="flex w-full mb-8 border-b-2">
          {links.map((link) => (
            <TabLink key={link.url} link={link} />
          ))}
        </div>
        {children}
      </div>
    );
  }

  // with dropdown, first and last is shown as regular, all other are dropdown, this is not a generic solution but specific to the local options of a worklaod
  return (
    <div>
      <div className="flex w-full mb-8 border-b-2">
        <TabLink style={{ marginRight: 35 }} link={links[0]} />
        <select
          className={`custom-tab truncate ngfocus ${dropdownIsActive().isActive ? "active" : ""}`}
          style={{
            marginRight: 35,
            height: 48.5,
            marginBottom: -2,
          }}
          value={dropdownValue}
          onChange={(e) => {
            navigate(e.target.value);
          }}
        >
          <option value={"label"} className="hidden text-normal bg-layout-light">
            &nbsp;&nbsp;Local Options
          </option>
          {links
            .filter((l) => l.group)
            .map((link) => (
              <option value={link.url} className={`appearance-none py-2 focus text-normal bg-layout-light`}>
                &nbsp;&nbsp;{link.label}
              </option>
            ))}
        </select>
        {!links[links.length - 1].group ? <TabLink link={links[links.length - 1]} /> : null}
      </div>
      {children}
    </div>
  );
};

interface AddLocalOptionTabLinkProps {
  link: TabsLayoutLink;
}
const AddLocalOptionTabLink: React.FC<AddLocalOptionTabLinkProps> = ({ link }) => {
  return (
    <button
      data-testid={link.label}
      onClick={link.isActive ? link.onClick : () => {}}
      disabled={!link.isActive}
      className={`custom-tab truncate focus ${link.isActive ? "enabled" : "disabled"}`}
      style={{
        marginRight: 22,
        height: 50,
        lineHeight: "48px",
        marginBottom: -2,
      }}
    >
      {link.label}
    </button>
  );
};
