import { observer } from "mobx-react-lite";
import * as React from "react";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { TabsLayout, TabsLayoutLink } from "../../components/tabsLayout/tabsLayout";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { DefaultOptions } from "./defaultOptions";
import { LocalOptionsItem } from "./localOptionsItem";
import { Modal, notification } from "antd";
import { updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { useDetailContext } from "../../components/detail/detailContext";
import { BasePathContext } from "../../reactContexts/basePathContext";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { useSetPromptShouldBlock } from "../../reactHooks/useSetPromptShouldBlock";
import { FormButtons } from "../../components/forms/formButtons";

interface Props {
  draft: WorkloadDraftMobx;
  gvcLocationNames: string[];
  patch: (body: any) => Promise<void>;
}
const OptionsRaw: React.FC<Props> = ({ draft, patch, gvcLocationNames }) => {
  const basePath = React.useContext(BasePathContext);
  const optionsPath = `${basePath}/-options`;
  const { fetchItem } = useDetailContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLocalOption, setIsLocalOption] = React.useState(
    draft.localOptions.length > 0 && !pathname.includes("-default")
  );
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    draft.defaultOptionsReset();
    draft.localOptionsReset();
    return () => {
      draft.defaultOptionsReset();
      draft.localOptionsReset();
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();
  useSetPromptShouldBlock(shouldBlockNavigation);

  React.useEffect(() => {
    setIsLocalOption(draft.localOptions.length > 0 && !pathname.includes("-default"));
  }, [draft.localOptions.length, pathname]);

  React.useEffect(() => {
    setPromptWhen(draft.isAnyOptionsDirty);
  }, [draft.isAnyOptionsDirty]);

  const links: TabsLayoutLink[] = [{ url: `${optionsPath}/-default`, label: "Default", isActive: true }];
  for (let localOptionsItemIndex in draft.localOptions) {
    const localOptionsItem = draft.localOptions[localOptionsItemIndex];
    // below can be changed for label, when we have more location with different urls
    links.push({
      url: `${optionsPath}/${localOptionsItemIndex}`,
      label: localOptionsItem.location.value || "Select Location",
      isActive: true,
      group: "Local Options",
      index: localOptionsItemIndex,
    });
  }

  function onClickAddLocalOption() {
    draft.addLocalOptionsItem();
    const localOptionsItemPath = `${optionsPath}/${String(draft.localOptions.length - 1)}`;
    navigate(localOptionsItemPath);
  }

  if (draft.localOptions.length < gvcLocationNames.length) {
    links.push({
      onClick: onClickAddLocalOption,
      url: "",
      label: "+ Add Local Options",
      isActive: draft.isLocalOptionsValid,
    });
  }

  function handleReset() {
    draft.defaultOptionsReset();
    draft.localOptionsReset();
    navigate(optionsPath);
  }

  async function handleSave() {
    try {
      setIsLoading(true);
      await patch(draft.asPatch);
      draft.confirm();
      notification.success({
        message: "Success",
        description: "Workload is updated",
      });
      updateLastDeploymentTimeOnHubspot();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete() {
    const m = Modal.confirm({
      title: `Delete Local Options`,
      content: (
        <div>
          <p>Are you sure you want to delete?</p>
          <p>This change is not gonna be persisted yet. You still need to save these changes.</p>
        </div>
      ),
      footer: (
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={() => m.destroy()}>
            Close
          </NGButton>
          <NGButton
            variant="danger"
            onClick={() => {
              try {
                const index = pathname.split("/-options/")[1];
                draft.removeLocalOptionsItemAt(Number(index));
                navigate(optionsPath);
              } catch (e) {
                let errorMessage = e?.response?.data?.message;
                if (!errorMessage) {
                  errorMessage = e.message;
                }
                notification.warning({
                  message: "Failed",
                  description: errorMessage,
                });
              }
              m.destroy();
            }}
          >
            Delete
          </NGButton>
        </div>
      ),
    });
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/-options");
  }

  return (
    <>
      <TabsLayout links={links}>
        <Routes>
          <Route path={`-default`} element={<DefaultOptions draft={draft} />} />
          {draft.localOptions.map((_localOptionsItem, index) => (
            <Route
              key={index}
              path={String(index)}
              element={<LocalOptionsItem draft={draft} gvcLocations={gvcLocationNames} />}
            />
          ))}
          <Route index element={<Navigate to={`-default`} replace />} />
        </Routes>
        <div className="flex items-center">
          {isLocalOption ? (
            <NGButton
              disabled={isLoading}
              style={{ width: 215, marginRight: 20, marginTop: "2rem" }}
              onClick={handleDelete}
              size="toRemoveLarge"
              className="mt-8"
              variant={"danger"}
            >
              Delete
            </NGButton>
          ) : null}
          <FormButtons
            onReset={handleReset}
            onSave={handleSave}
            saveDisabled={
              !draft.isAnyOptionsDirty || isLoading || !draft.defaultOptions.isValid || !draft.isLocalOptionsValid
            }
            resetDisabled={!draft.isAnyOptionsDirty || isLoading}
            loading={isLoading}
          />
        </div>
      </TabsLayout>
    </>
  );
};

export const Options = observer(OptionsRaw);
