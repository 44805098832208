import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams, Navigate } from "react-router-dom";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";

type RouteParams = "id";

interface Props {}
const SignUpWithIdRaw: React.FC<Props> = () => {
  const { id } = useParams<RouteParams>();
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    if (id) {
      BillingContext.setReferralId(id);
    }
    setIsReady(true);
  }, []);

  if (!isReady) {
    return null;
  }

  return <Navigate to={`/signup`} />;
};

export const SignUpWithId = observer(SignUpWithIdRaw);
