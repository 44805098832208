import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftAWSHelper } from "../../../../mst/stores/identity.aws.helper";
import { Link } from "react-router-dom";
import { CloudaccountMobx, CloudaccountModel } from "../../../../mst/kinds/cloudaccount";
import { request } from "../../../../services/cpln";
import { LoadingOutlined } from "@ant-design/icons";
import { FormLabel } from "../../../../components/forms/formLabel";
import { Theme } from "../../../../mobxStores/uiData/theme";
import { NGButton } from "../../../../newcomponents/button/Button";
import { Tooltip } from "../../../../components/Tooltip";
import { IdentityStatusProviderMobx } from "../../../../mst/kinds/identity";
import NGAlert from "../../../../newcomponents/alert";
import { NGChip } from "../../../../newcomponents/chip";

interface Props {
  aws: IdentityDraftAWSHelper;
  providerStatus?: IdentityStatusProviderMobx;
  infoOnly: boolean;
}
const AWSIdentityInfoRaw: React.FC<Props> = ({ aws, providerStatus, infoOnly }) => {
  const { theme } = Theme;
  const [awsLogoPath, setAwsLogoPath] = React.useState(() => getAwsLogoPath());
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [cloudaccount, setCloudaccount] = React.useState<CloudaccountMobx>(null as any);
  const cloudaccountLink = aws.edited.cloudaccountLink;

  React.useEffect(() => {
    setAwsLogoPath(getAwsLogoPath());
  }, [theme]);

  React.useEffect(() => {
    if (!aws.edited.isActive) return;
    request({ url: aws.edited.cloudaccountLink })
      .then(({ data }) => {
        setCloudaccount(CloudaccountModel.create(data));
        setIsPageReady(true);
      })
      .catch(() => {
        setIsPageReady(true);
      });
  }, [aws.edited.isActive, aws.edited.cloudaccountLink]);

  function getAwsLogoPath() {
    return `/resources/logos/awsLogo${theme === "dark" ? "Dark" : ""}.png`;
  }

  if (!aws.edited.isActive) {
    if (infoOnly) return null;
    return (
      <button
        data-testid="aws-none"
        onClick={() => {
          aws.setIsSettingUpTrue();
        }}
        className={`mb-8 p-4 hover:shadow-lg bg-identity-aws-logo-drop border w-full flex flex-col items-center focus-big`}
      >
        <div className="py-2" style={{ height: 88 }}>
          <img style={{ height: "100%" }} src={awsLogoPath} />
        </div>
      </button>
    );
  }

  if (!isPageReady) {
    return (
      <div
        data-testid="aws-loading"
        className="mb-8 p-4 border cursor-wait w-full flex flex-col items-center relative bg-identity-aws-logo-drop"
        style={{ height: 122 }}
      >
        <span
          className="absolute flex items-center justify-center"
          style={{ width: 20, height: 20, left: "calc(50% - 10px)", top: "calc(75% - 10px)" }}
        >
          <LoadingOutlined />
        </span>
        <div className="py-2" style={{ height: 44 }}>
          <img style={{ height: "100%" }} src={awsLogoPath} />
        </div>
      </div>
    );
  }

  const hasCloudaccount = cloudaccount !== null;
  const cloudaccountName = cloudaccount?.name || cloudaccountLink;

  if (aws.edited.method === "existing") {
    return (
      <div data-testid="aws-existing" className="mb-8 border    w-full">
        <div className="p-4 pt-5 mb-4 border-b-4 border-identity-aws-logo-accent">
          <img style={{ height: 30 }} src={awsLogoPath} />
        </div>
        <div className="mb-2 px-4 flex">
          <div className="w-4/12 pr-4">
            <FormLabel>Cloud Account</FormLabel>
            <Tooltip title={aws.edited.cloudaccountLink} open={cloudaccountName.length >= 25}>
              {hasCloudaccount ? (
                <Link
                  className={`w-full inline-block truncate font-medium ngfocus color-link`}
                  data-testid="aws-ca"
                  to={`/console${aws.edited.cloudaccountLink}`}
                >
                  {cloudaccountName}
                </Link>
              ) : (
                <span className="w-full inline-block truncate">{cloudaccountName}</span>
              )}
            </Tooltip>
          </div>
          {hasCloudaccount ? (
            <div className="w-8/12">
              <FormLabel>Role Arn</FormLabel>
              <div data-testid="aws-role-arn" className="font-mono truncate">
                {cloudaccount.data?.roleArn}
              </div>
            </div>
          ) : null}
        </div>
        <div className="mb-2 px-4">
          <FormLabel>Selected AWS Role</FormLabel>
          <div data-testid="aws-role" className="font-mono truncate">
            {aws.edited.role!}
          </div>
        </div>
        {infoOnly ? null : (
          <div className="flex items-center justify-end px-4 pb-4">
            <NGButton
              variant={"primary"}
              style={{ width: 77, marginRight: 10 }}
              className="mt-2"
              onClick={() => {
                aws.setIsSettingUpTrue();
              }}
            >
              Edit
            </NGButton>
            <NGButton
              variant={"danger"}
              style={{ width: 77, marginLeft: 10 }}
              className="mt-2"
              onClick={() => {
                aws.remove();
              }}
            >
              Remove
            </NGButton>
          </div>
        )}
      </div>
    );
  }

  return (
    <div data-testid="aws-new" className="mb-8 border w-full">
      <div className="flex gap-4 items-center p-4 pt-5 mb-4 bg-identity-aws-logo-drop border-b-4 border-identity-aws-logo-accent">
        <img style={{ height: 30 }} src={awsLogoPath} />
        {providerStatus ? (
          <NGChip
            size="small"
            variant={providerStatus.usable ? "success" : "error"}
            label={providerStatus.usable ? "Usable" : "Unusable"}
          />
        ) : null}
      </div>
      <div className="mb-2 flex px-4">
        <div className="w-4/12 pr-4">
          <FormLabel>Cloud Account</FormLabel>
          <Tooltip title={aws.edited.cloudaccountLink} open={cloudaccountName.length >= 25}>
            {hasCloudaccount ? (
              <Link
                className={`w-full inline-block truncate font-medium ngfocus color-link`}
                data-testid="aws-ca"
                to={`/console${aws.edited.cloudaccountLink}`}
              >
                {cloudaccountName}
              </Link>
            ) : (
              <span className="w-full inline-block truncate">{cloudaccountName}</span>
            )}
          </Tooltip>
        </div>
        {hasCloudaccount ? (
          <div className="w-8/12">
            <FormLabel>Role Arn</FormLabel>
            <div data-testid="aws-role-arn" className="font-mono truncate">
              {cloudaccount.data?.roleArn}
            </div>
          </div>
        ) : null}
      </div>
      <div className="mb-2 px-4">
        <FormLabel>Selected AWS Policies</FormLabel>
        <ul className="pl-6">
          {aws.edited.policies.map((policy) => (
            <li key={policy} className="list-disc">
              <div data-testid={`aws-policy-${policy}`} className="font-mono truncate">
                {policy}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {providerStatus?.lastError ? (
        <NGAlert type={"error"} message={providerStatus.lastError} className="ml-4 mt-3" />
      ) : null}
      {infoOnly ? null : (
        <div className="flex items-center justify-end px-4 pb-4">
          <NGButton
            style={{ width: 77, marginRight: 10 }}
            className="mt-2"
            variant="primary"
            onClick={() => {
              aws.setIsSettingUpTrue();
            }}
          >
            Edit
          </NGButton>
          <NGButton
            variant={"danger"}
            outlined
            style={{ width: 77, marginLeft: 10 }}
            className="mt-2"
            onClick={() => {
              aws.remove();
            }}
          >
            Remove
          </NGButton>
        </div>
      )}
    </div>
  );
};

export const AWSIdentityInfo = observer(AWSIdentityInfoRaw);
