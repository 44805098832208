import { flow, Instance, types } from "mobx-state-tree";
import { AzureIdentityData } from "./identity.azure.data";
import { IdentityDraftAzureIdentityModel } from "./identity.azure.draft";
import { isEqual } from "lodash";

export const IdentityDraftAzureHelperModel = types
  .model({
    saved: types.optional(AzureIdentityData, () => AzureIdentityData.create()),
    edited: types.optional(AzureIdentityData, () => AzureIdentityData.create()),

    draft: types.optional(IdentityDraftAzureIdentityModel, () => IdentityDraftAzureIdentityModel.create()),
    isSettingUp: false,
    isLoading: false,
    step: types.optional(types.enumeration(["general", "config"]), "general"),
  })
  .actions((self) => {
    const next = flow(function* () {
      try {
        self.isLoading = true;
        yield self.draft.fetchDataItems();
      } catch (e) {
        console.error("failed at azure helper", e.message);
      } finally {
        self.step = "config";
        self.isLoading = false;
      }
    });
    return { next };
  })
  .actions((self) => ({
    setIsSettingUpFalse() {
      self.isSettingUp = false;
      self.draft.filterInput.reset();
      self.step = "general";
    },
  }))
  .actions((self) => ({
    done() {
      self.draft.setIsActive(true);
      self.draft.setUseManualInput(false);
      self.edited.apply(self.draft.asObject);
      self.setIsSettingUpFalse();
    },
    back() {
      self.step = "general";
    },
    remove() {
      self.draft.clear();
      self.edited.clear();
    },
    onSave() {
      self.saved.apply(self.edited.asObject);
    },
    setup() {
      self.isSettingUp = true;
    },
  }))
  .actions((self) => {
    const cancel = flow(function* () {
      self.isLoading = true;
      if (self.edited.isActive) {
        yield self.draft.apply(self.edited.asObject);
      } else {
        self.draft.clear();
      }
      self.setIsSettingUpFalse();
      self.isLoading = false;
    });
    const reset = flow(function* () {
      if (self.saved.isActive) {
        self.edited.apply(self.saved.asObject);
        yield self.draft.apply(self.saved.asObject);
      } else {
        self.edited.clear();
        self.draft.clear();
      }
    });
    return { cancel, reset };
  })
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.saved.isActive !== self.edited.isActive) {
        res = true;
      } else if (self.saved.isActive && self.edited.isActive) {
        if (!isEqual(self.saved.asObject, self.edited.asObject)) {
          res = true;
        }
      }
      return res;
    },
  }))
  .views((self) => ({
    get title() {
      switch (self.step) {
        case "general":
          return "Azure Identity - General";
        case "config":
          return "Azure Identity - Role Assignments";
        default:
          return "Azure Identity";
      }
    },
    get modalWidth() {
      return self.step === "general" ? undefined : 1000;
    },
  }));
export interface IdentityDraftAzureHelper extends Instance<typeof IdentityDraftAzureHelperModel> {}
