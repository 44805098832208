import * as React from "react";

// An enum with all the types of actions to use in our reducer
export enum RunDeleteVolumeReducerActionType {
  LOCATION = "LOCATION",
  INDEX = "INDEX",
}

// An interface for our actions
interface RunDeleteVolumeReducerAction {
  type: RunDeleteVolumeReducerActionType;
  payload: any;
}

// An interface for our state
export interface DeleteVolumeReducerState {
  location: string;
  volumeIndex: string;
}

// Our reducer function that uses a switch statement to handle our actions
function runDeleteVolume(state: DeleteVolumeReducerState, action: RunDeleteVolumeReducerAction) {
  const { type, payload } = action;
  switch (type) {
    case RunDeleteVolumeReducerActionType.LOCATION:
      return {
        ...state,
        location: payload,
      };
    case RunDeleteVolumeReducerActionType.INDEX:
      return {
        ...state,
        volumeIndex: payload,
      };
    default:
      return state;
  }
}

export function useRunDeleteVolumeReducer(initialLocation: string | undefined, initialVolumeIndex: string | undefined) {
  const initialState: DeleteVolumeReducerState = {
    location: initialLocation || "",
    volumeIndex: initialVolumeIndex || "",
  };
  return React.useReducer(runDeleteVolume, initialState);
}
