import { TableColumn } from "../../types";

const userNameDescriptionDefault = { id: "email", label: "Email" };
export function UserNameDescriptionNoLinkColumn<TData extends { email: string; name: string; kind: string }>(
  column: Partial<TableColumn<TData>> = userNameDescriptionDefault,
) {
  const preset: TableColumn<TData> = {
    ...userNameDescriptionDefault,
    enableResize: true,
    enableSort: true,
    size: 200,
    cell: (p) => {
      const item = p.row.original;

      return (
        <div className="flex flex-col justify-center" style={{ height: 56 }}>
          <span>{item.email}</span>
          <span className="text-sm description">{item.name}</span>
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
