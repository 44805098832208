import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGSwitch } from "../../../../newcomponents/switch";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGLabelText } from "../../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../../components/InfoTooltip";
import { DryRunAlert } from "../../dryRunAlert";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";

// TODO
// includeNamespaces: RegularExpression, // means "all" if missing
// excludeNamespaces: RegularExpression, // means non if missing

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const CreateAddonsMetricsRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <div>
      <div className="mb-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.metrics"}
          paths={[
            "spec.addOns.metrics.coreDns",
            "spec.addOns.metrics.kubelet",
            "spec.addOns.metrics.kubeState",
            "spec.addOns.metrics.nodeExporter",
            "spec.addOns.metrics.apiserver",
            "spec.addOns.metrics.cadvisor",
            "spec.addOns.metrics.scrapeAnnotated",
          ]}
        />
      </div>
      <div className="mb-2">Metrics</div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.coreDns}
          onChange={(checked) => mk8sDraft.addon_metrics.setCoreDns(checked)}
        >
          <NGLabelText>Core DNS</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable scraping of core-dns service"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.kubelet}
          onChange={(checked) => mk8sDraft.addon_metrics.setKubelet(checked)}
        >
          <NGLabelText>Kubelet</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable scraping kubelet stats"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.kubeState}
          onChange={(checked) => mk8sDraft.addon_metrics.setKubeState(checked)}
        >
          <NGLabelText>Kube State</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable kube-state metrics"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.nodeExporter}
          onChange={(checked) => mk8sDraft.addon_metrics.setNodeExporter(checked)}
        >
          <NGLabelText>Node Exporter</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable collecting node-level stats (disk, network, filesystem, etc)"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.apiserver}
          onChange={(checked) => mk8sDraft.addon_metrics.setApiserver(checked)}
        >
          <NGLabelText>API Server</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable scraping apiserver stats"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.cadvisor}
          onChange={(checked) => mk8sDraft.addon_metrics.setCadvisor(checked)}
        >
          <NGLabelText>cAdvisor</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable CNI-level container stats"]} />
      </div>
      <div className="p-4 border rounded" style={{ width: 484 }}>
        <NGSwitch
          className="mb-4"
          value={mk8sDraft.addon_metrics.scrapeAnnotated}
          onChange={(checked) => mk8sDraft.addon_metrics.setScrapeAnnotated(checked)}
        >
          <NGLabelText>Scrape Annotated</NGLabelText>
        </NGSwitch>
        <div className="mb-2">Scrape pods annotated with prometheus.io/scrape=true</div>
        {mk8sDraft.addon_metrics.scrapeAnnotated ? (
          <>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`metrics.scrapeAnnotated.intervalSeconds`}
                label={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.label}
                value={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.value}
                required={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.isRequired}
                error={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.error}
                onChange={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.setValue}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.setValue}
                  path={"spec.addOns.metrics.scrapeAnnotated.intervalSeconds"}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`metrics.scrapeAnnotated.includeNamespaces`}
                label={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.label}
                placeholder="Regex"
                value={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.value}
                required={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.isRequired}
                error={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.error}
                onChange={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.setValue}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.setValue}
                  path={"spec.addOns.metrics.scrapeAnnotated.includeNamespaces"}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`metrics.scrapeAnnotated.excludeNamespaces`}
                label={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.label}
                placeholder="Regex"
                value={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.value}
                required={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.isRequired}
                error={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.error}
                onChange={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.setValue}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.setValue}
                  path={"spec.addOns.metrics.scrapeAnnotated.excludeNamespaces"}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`metrics.scrapeAnnotated.retainLabels`}
                label={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.label}
                placeholder="Regex"
                value={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.value}
                required={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.isRequired}
                error={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.error}
                onChange={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.setValue}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.setValue}
                  path={"spec.addOns.metrics.scrapeAnnotated.retainLabels"}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export const CreateAddonsMetrics = observer(CreateAddonsMetricsRaw);
