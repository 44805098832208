import { observer } from "mobx-react-lite";
import * as React from "react";
import { GVCMobx } from "../../mst/kinds/gvc";
import { Navigate, Route, Routes } from "react-router-dom";
import { GvcLocationsItems } from "./gvc-locations-items";
import { GVCLocationQuery } from "./gvc-location-query";
import { TabsLayout, TabsLayoutLink } from "../../components/tabsLayout/tabsLayout";

interface Props {
  gvc: GVCMobx;
}
const GVCLocationsRaw: React.FC<Props> = ({ gvc }) => {
  const links: TabsLayoutLink[] = [
    { url: `-items`, label: "Items" },
    { url: `-query`, label: "Query" },
  ];

  return (
    <TabsLayout links={links}>
      <Routes>
        <Route index element={<Navigate to={`-items`} />} />
        <Route path={`-items/*`} element={<GvcLocationsItems gvc={gvc} />} />
        <Route path={`-query/*`} element={<GVCLocationQuery gvc={gvc} />} />
      </Routes>
    </TabsLayout>
  );
};

export const GVCLocations = observer(GVCLocationsRaw);
