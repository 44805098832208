import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { NGSelect } from "../../../../../newcomponents/select/ngselect";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { DryRunAlert } from "../../../dryRunAlert";
import { NGError } from "../../../../../newcomponents/text/error";
import { NGFormLabel } from "../../../../../newcomponents/text/formLabel";
import { request } from "../../../../../services/cpln";
import NGAlert from "../../../../../newcomponents/alert";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderGenericRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const g = mk8sDraft.provider_generic;
  const form = useNGFormContext();

  React.useEffect(() => {
    fetchSchemaData();
  }, []);

  async function fetchSchemaData() {
    // Locations
    try {
      const { data: schemaRes } = await request({ url: "/catalog/schema/mk8s" });
      const locations: string[] = schemaRes.keys.spec.keys.provider.keys.generic.keys.location.allow;
      g.location.setOptions(locations.map((v) => ({ label: v, value: v })));
    } catch (e) {
      console.error("Failed to get locations from mk8s catalog schema");
    }
  }

  if (!g) {
    return null;
  }

  return (
    <>
      <NGFormLabel name="generic.location" required invalid={!g.location.value}>
        Location
      </NGFormLabel>
      <div className="flex items-start gap-2">
        <div className="mb-4">
          <NGSelect
            name="generic.location"
            invalid={!g.location.value}
            style={{ width: 450, minWidth: 450 }}
            value={g.location.value}
            options={g.location.options}
            onChange={g.location.setValue}
            allowEmpty={false}
          />
          {!g.location.value && form.get("generic.location").touched ? (
            <NGError className="mt-2">Location is required</NGError>
          ) : null}
        </div>
        <DryRunAlert
          canShow={form.get("generic.location").touched}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.generic.location"}
        />
      </div>
    </>
  );
};

export const Mk8sCreateProviderGeneric = observer(Mk8sCreateProviderGenericRaw);
