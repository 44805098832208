import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { Table } from "../../newcomponents/table/table";
import { TableNewItemButton } from "../../newcomponents/table/components/tableNewButton";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { allKinds } from "../../services/kindMetadata";
import { linksOf, nameOfKind } from "../../services/cpln";
import { Query } from "../../schema/types/query";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { Policy } from "../../schema/types/policy";
import { TableActionsDropdown } from "../../newcomponents/table/components/tableActionsDropdown";
import { DeleteModal } from "../../components/modals/deleteModal";

const STORAGE_KEY_POLICY_LIST_HIDE_BUILTIN = "STORAGE_KEY_POLICY_LIST_HIDE_BUILTIN";

interface PolicyTableItem extends Policy {
  selfLink: string;
}

const PolicyListRaw: React.FC = () => {
  const [selectedLinks, setSelectedLinks] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<string>("");

  const { kind, id } = useTableKindId("policy", "policy-list");

  function getDefaultHideBuiltin() {
    const localValue = localStorage.getItem(STORAGE_KEY_POLICY_LIST_HIDE_BUILTIN);
    if (!localValue) {
      return true;
    }
    return localValue === "true" ? true : false;
  }
  const [hideBuiltin, setHideBuiltin] = React.useState(getDefaultHideBuiltin());
  React.useEffect(() => {
    localStorage.setItem(STORAGE_KEY_POLICY_LIST_HIDE_BUILTIN, String(hideBuiltin));
  }, [hideBuiltin]);

  const builtinQuery: Query = React.useMemo(
    () => ({
      kind: "policy",
      spec: {
        match: "all",
        terms: [
          {
            property: "origin",
            op: "!=",
            value: "builtin",
          },
        ],
      },
    }),
    [],
  );
  const query = hideBuiltin ? builtinQuery : undefined;

  const qData = useTableItemQueryData<Policy>(kind, { query: query, fetchAllPages: true });

  const policyTableItems: PolicyTableItem[] = qData.visibleItems.map((item) => {
    const selfLink = linksOf(item).self!;
    return { ...item, selfLink: selfLink };
  });

  return (
    <>
      <Table<PolicyTableItem>
        title={"Policies"}
        data={policyTableItems}
        selectKey="selfLink"
        selectMode="multi"
        selections={selectedLinks}
        onSelectionsChange={setSelectedLinks}
        tableId={id}
        headerRenderer={() => {
          return (
            <>
              <NGCheckbox isDisabled={!!qData.query} checked={hideBuiltin} onChange={setHideBuiltin}>
                <NGLabelText>Hide Built-in Policies</NGLabelText>
              </NGCheckbox>
              <TableNewItemButton kind={kind} />
              <TableActionsDropdown
                kind={kind}
                selectionLinks={selectedLinks}
                options={[
                  {
                    key: "delete",
                    label: "Delete",
                    disabled: selectedLinks.length < 1,
                    danger: true,
                    callback: () => setAction("delete"),
                  },
                ]}
              />
              <TableHeaderRefreshButton onClick={qData.fetchItems} />
              <CustomTableCPLNQuery
                tableId={id}
                kind={kind}
                onQuery={qData.setQuery}
                isLoading={qData.isLoadingQueried}
              />
            </>
          );
        }}
        enableFilter
        isLoading={qData.isLoading}
        columns={[
          NameDescriptionColumn(),
          {
            id: "targetKind",
            label: "Target Kind",
            canGroupByDistinctValues: true,
            groupLabelMap: allKinds
              .map((k) => ({ kind: k, label: nameOfKind(k) }))
              .reduce((acc, item) => {
                acc[item.kind] = item.label;
                return acc;
              }, {}),
            enableHide: true,
          },
          {
            id: "origin",
            label: "Origin",
            canGroupByDistinctValues: true,
            groupLabelMap: {
              builtin: "Builtin",
              default: "Default",
            },
            enableHide: true,
          },
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
      />
      {action === "delete" ? (
        <DeleteModal
          kind={kind}
          onCancel={() => setAction("")}
          onComplete={() => {
            setAction("");
            setSelectedLinks([]);
            // TODO tablefix
            // When user queries and then deletes, this might be a problem
            qData.fetchItems();
          }}
          links={selectedLinks}
        />
      ) : null}
    </>
  );
};

export const PolicyList = observer(PolicyListRaw);
