import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useNavigate } from "react-router-dom";
import { UserData } from "../../mobxStores/userData/userData";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { flowResult } from "mobx";
import { NGButton } from "../../newcomponents/button/Button";
import { NGAutocomplete } from "../../newcomponents/select/ngautocomplete";
import { NGRadioGroup } from "../../newcomponents/radioGroup";

const OrgSelectRaw: React.FC = () => {
  const navigate = useNavigate();

  const [selectedOrg, setSelectedOrg] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  async function handleSelectConfirm() {
    setIsLoading(true);
    // TODO
    /**
     * Here we don't see the loading state because the org route is keyed by context org
     * After the org is selected, loading is set to true, but since right after that, we change the org in context
     * this page is remounted and loading state is lost
     */
    await flowResult(ConsoleContext.setOrg(selectedOrg));
    setIsLoading(false);
    if (!ConsoleContext.gvc && UserData.hasGVC) {
      ConsoleContext.setRedirect(`/console/org/${selectedOrg}/gvc/-select`);
    } else {
      ConsoleContext.setRedirect(`/console/org/${selectedOrg}`);
    }
  }

  if (!UserData.hasOrg) {
    return <Navigate to={"/billing"} />;
  }

  return (
    <div className="flex flex-col items-center mt-16">
      <p className="text-center text-lg">Select an org</p>
      <p className="text-center">You can change this preference later</p>
      <div style={{ width: 800 }} className={"mt-8 flex flex-col items-center"}>
        {UserData.orgNames.length > 5 ? (
          <NGAutocomplete
            disabled={isLoading}
            placeholder={"Select an org"}
            onChange={(value) => setSelectedOrg(value)}
            value={selectedOrg}
            style={{ width: 450 }}
            options={UserData.orgNames.map((orgName) => ({ label: orgName, value: orgName }))}
          />
        ) : (
          <NGRadioGroup
            isDisabled={isLoading}
            options={UserData.orgNames.map((orgName) => ({ label: orgName, value: orgName }))}
            value={selectedOrg}
            onChange={(value) => setSelectedOrg(value)}
          />
        )}
      </div>
      <NGButton
        variant={"primary"}
        className="mt-8"
        onClick={handleSelectConfirm}
        disabled={!selectedOrg || isLoading}
        loading={isLoading}
      >
        Continue
      </NGButton>
    </div>
  );
};

export const OrgSelect = observer(OrgSelectRaw);
