import * as React from "react";
import { nameOfKind } from "../../services/cpln";
import { useNavigate } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { XCircle } from "react-feather";
import { NGButton } from "../../newcomponents/button/Button";

interface Props {
  kind?: string;
  label?: string;
  requiredKind: string;
}
export const RequirementToCreate: React.FC<Props> = ({ kind, label, requiredKind }) => {
  const { org } = ConsoleContext;
  const navigate = useNavigate();

  if (!label && !kind) {
    console.error("Label or kind must be given");
    return null;
  }

  return (
    <div className="w-full flex flex-col items-center py-16 border   rounded-lg  ">
      <XCircle className="text-2xl" />
      <div className="mt-6 mb-2 text-2xl">Prerequisite to create {label ? label : nameOfKind(kind!)} is not met.</div>
      <div className="my-4 text-lg">You need at least 1 {nameOfKind(requiredKind)}.</div>
      <div className="my-4 flex items-center">
        {/** Below needs update if we use it for workload or identity ever */}
        <NGButton
          variant={"action"}
          className="ml-4"
          onClick={() => navigate(`/console/org/${org}/${requiredKind}/-create`)}
        >
          Create {nameOfKind(requiredKind)}
        </NGButton>
      </div>
    </div>
  );
};
