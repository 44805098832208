import { observer } from "mobx-react-lite";
import * as React from "react";
import { TagNewMobx } from "../../mobxDataModels/tagsNewModel";
import { NGInput } from "../../newcomponents/input/input";
import { NGButton } from "../../newcomponents/button/Button";
import NGAlert from "../../newcomponents/alert";

interface TagItemProps {
  tag: TagNewMobx;
  onRemove: any;
  label?: "Name" | "Key";
  secondaryLabel?: string;
  error?: string;
}
const TagItemRaw: React.FC<TagItemProps> = ({
  tag,
  onRemove,
  label = "Key",
  secondaryLabel = "Value (Optional)",
  error = "",
}) => {
  return (
    <>
      <div className={`relative flex items-center gap-2`} style={{ width: "100%" }}>
        <NGInput
          value={tag.key}
          placeholder={label}
          onChange={(e) => tag.setKey(e.target.value)}
          data-testid="tag-key"
          className="flex-grow basis-0"
          data-tagindex={tag.index}
          showClear={false}
        />
        <NGInput
          value={String(tag.value)}
          placeholder={secondaryLabel}
          className="flex-grow basis-0"
          onChange={(e) => tag.setValue(String(e.target.value))}
          data-testid="tag-value"
        />
        <NGButton
          data-testid="remove-tag"
          variant={"danger"}
          outlined
          style={{ marginTop: "auto", width: 100 }}
          onClick={onRemove}
        >
          Remove
        </NGButton>
      </div>
      {error ? <NGAlert type="error" className="mb-2" message={error} /> : null}
    </>
  );
};

export const TagItem = observer(TagItemRaw);
