import * as React from "react";
import { Navigate, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Loader } from "../../../components/layout/loader";
import { BillingAccountInvoices } from "./invoices";
import { BillingAccountOrgs } from "./orgs";
import { BillingAccountPaymentMethods } from "./paymentmethods";
import { BillingAccountUsers } from "./users";
import AccountDetail from "./detail";
import { TryCreateOrg } from "../tryCreateOrg";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { observer } from "mobx-react-lite";
import BillingDashboardUsage from "../../usage-ng/billingDashboardUsage";
import NGAlert from "../../../newcomponents/alert";
import { NGButton } from "../../../newcomponents/button/Button";

const AccountRouteRaw: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hasAccount, AccountUUID, RolesError, FullName, AccountName, AccountError, isLoading } = BillingContext;

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!AccountUUID) {
      return;
    }
    if (pathname.split("/").filter(Boolean).length < 3) {
      return;
    }
    const accountIdInPath = pathname.split("/").filter(Boolean)[2];
    if (AccountUUID !== accountIdInPath) {
      navigate(`/billing/account/${AccountUUID}`);
    }
  }, [isLoading, pathname, AccountUUID]);

  if (isLoading && !AccountError) {
    return <Loader reason={"Loading selected account"} fullScreen />;
  }

  if (!!AccountError) {
    return (
      <div>
        <NGAlert
          type={"error"}
          className="mb-4"
          message={`Something went wrong when fetching the account ${
            AccountName || FullName || AccountUUID
          }, please make sure you have access to account and try again later.`}
        />
        <NGButton variant={"action"} onClick={() => window.location.reload()}>
          Retry
        </NGButton>
      </div>
    );
  }

  if (!!RolesError) {
    return (
      <div>
        <NGAlert
          type={"error"}
          className="mb-4"
          message={`Something went wrong when fetching the user roles, please try again later.`}
        />
        <NGButton variant={"action"} onClick={() => window.location.reload()}>
          Retry
        </NGButton>
      </div>
    );
  }

  // Has no account in context
  if (!hasAccount) {
    return <Navigate to={"/billing"} />;
  }

  return (
    <Routes>
      <Route index element={<Navigate to={AccountUUID} />} />
      <Route path={`:accountId/-tryorgcreate`} element={<TryCreateOrg />} />
      <Route path={`:accountId/orgs`} element={<BillingAccountOrgs />} />
      <Route path={`:accountId/usage`} element={<BillingDashboardUsage />} />
      <Route path={`:accountId/users`} element={<BillingAccountUsers />} />
      <Route path={`:accountId/payment-methods/*`} element={<BillingAccountPaymentMethods />} />
      <Route path={`:accountId/invoices`} element={<BillingAccountInvoices />} />
      <Route path={`:accountId/*`} element={<AccountDetail />} />
    </Routes>
  );
};

export const AccountRoute = observer(AccountRouteRaw);
