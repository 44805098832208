import React from "react";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import "./select.css";
import { observer } from "mobx-react-lite";
import { ChevronDown, ChevronUp, X } from "react-feather";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { InnerButtonProps } from "../input/input";
import { Tooltip } from "../../components/Tooltip";
import { FilterOptionsProps, OptionRendererProps } from "./types";
import {
  ComboboxDefaultFilterOptionsHandler,
  DefaultGetOptionLabel,
  DefaultGetOptionValue,
  DefaultOptionRenderer,
} from "./utils";

export type NGComboboxProps<T> = {
  value: string;
  onChange: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  options: T[];
  disabled?: boolean;
  invalid?: boolean;
  name?: string;
  // --
  renderOption?: (props: OptionRendererProps<T>) => React.ReactElement;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => string;
  filterOptions?: (props: FilterOptionsProps<T>) => T[];
  filteredOptions?: T[];
  buttons?: InnerButtonProps[];
  showClear?: boolean;
  showNoItems?: boolean;
  size?: "toRemoveLarge" | "normal" | "small";
  onOptionsScrolled?: () => void;
  //
  style?: React.CSSProperties;
  className?: string;
  // emphasizeEmptyStringOnEnds?: boolean;
};

const PREVENT_BLUR_DURATION = 400;

function NGComboboxRaw<T>({
  value,
  onChange,
  onKeyDown,
  placeholder = "",
  options,
  disabled = false,
  invalid = false,
  name,
  // --
  renderOption = DefaultOptionRenderer,
  getOptionLabel = DefaultGetOptionLabel as any,
  getOptionValue = DefaultGetOptionValue as any,
  filterOptions = ComboboxDefaultFilterOptionsHandler as any,
  filteredOptions: overrideFilteredOptions,
  buttons = [],
  showClear = false,
  showNoItems = true,
  size = "normal",
  onOptionsScrolled,
  //
  style = {},
  className = "",
}: // emphasizeEmptyStringOnEnds = false,
NGComboboxProps<T>) {
  /* Form state START */
  const formData = useNGFormContext();
  const [isFocused, setIsFocused] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [visited, setVisited] = React.useState(false);
  React.useEffect(() => {
    if (isFocused) {
      if (name && formData) {
        formData.set(name, { focused: true });
      }
      if (!visited) {
        setVisited(true);
        if (name && formData) {
          formData.set(name, { visited: true });
        }
      }
      return;
    }

    if (visited && !touched) {
      setTouched(true);
      if (name && formData) {
        formData.set(name, { focused: false, touched: true });
      }
    }
  }, [isFocused]);
  /* Form state END */

  const comboboxRef = React.useRef<HTMLDivElement>(null as any);
  const inputRef = React.useRef<HTMLInputElement>(null as any);
  const buttonRef = React.useRef<HTMLButtonElement>(null as any);
  const listboxRef = React.useRef<HTMLUListElement>(null as any);

  React.useEffect(() => {
    const onScroll = () => {
      const el = listboxRef.current;
      const isFullyScrolled = el.scrollTop + el.clientHeight >= el.scrollHeight;
      if (isFullyScrolled && onOptionsScrolled) {
        onOptionsScrolled();
      }
    };
    if (listboxRef.current) {
      listboxRef.current.addEventListener("scroll", onScroll);
    }
    return () => {
      if (listboxRef.current) {
        listboxRef.current.removeEventListener("scroll", onScroll);
      }
    };
  }, [onOptionsScrolled]);

  const idPrefixRef = React.useRef(uuidv4());
  const [localFilteredOptions, setLocalFilteredOptions] = React.useState<T[]>([]);
  const [hoveredOption, setHoveredOption] = React.useState<string>("");
  const [listboxOpen, setListboxOpen] = React.useState(false);
  const [listboxInitialOpen, setListboxInitialOpen] = React.useState(false);
  // TODO fix
  const [listboxPosition, setListboxPosition] = React.useState<"bottom" | "top">("bottom");
  /* Retains focus for PREVENT_BLUR_DURATION ms when clicked on listbox items */
  const preventedBlurRef = React.useRef(false);

  const filteredOptions =
    listboxInitialOpen || !overrideFilteredOptions ? localFilteredOptions : overrideFilteredOptions;

  /* #region - Manage listbox initial open */
  React.useEffect(() => {
    if (!listboxOpen) {
      return;
    }
    setListboxInitialOpen(true);
  }, [listboxOpen]);
  React.useEffect(() => {
    if (!listboxInitialOpen) {
      return;
    }
    setListboxInitialOpen(false);
  }, [value]);
  /* #endregion */

  /* #region - Set filtered options */
  React.useEffect(() => {
    if (listboxInitialOpen) {
      setLocalFilteredOptions(options);
      return;
    }
    setLocalFilteredOptions(filterOptions({ value, getOptionLabel, getOptionValue, options }));
  }, [listboxInitialOpen, value, options.length]);
  /* #endregion */

  /* #region - Handle vertical placement of listbox */
  // START - Handle vertical placement of listbox
  React.useEffect(() => {
    if (listboxOpen) {
      checkBoundingBox();
    } else {
      setListboxPosition("bottom");
    }
  }, [listboxOpen, filteredOptions]);

  function checkBoundingBox() {
    let bounds = listboxRef.current.getBoundingClientRect();
    checkVerticalBounding(bounds);
  }

  function checkVerticalBounding(bounds: DOMRect) {
    let windowHeight = window.innerHeight;
    if (bounds.bottom > windowHeight && bounds.top < 0) {
      return;
    }
    if (bounds.bottom > windowHeight) {
      setListboxPosition("top");
    }
    if (bounds.top < 0) {
      setListboxPosition("bottom");
    }
  }
  // END - Handle placement of listbox
  /* #endregion */

  React.useEffect(() => {
    const optionWithValue = filteredOptions.find((opt) => getOptionValue(opt) === value);
    if (optionWithValue) {
      setHoveredOption(getOptionValue(optionWithValue));
      return;
    }

    if (filteredOptions.length < 1) {
      setHoveredOption("");
      return;
    }

    setHoveredOption(getOptionValue(filteredOptions[0]));
  }, [value, filteredOptions.length]);

  // Handle outside click
  React.useEffect(() => {
    const onDocumentPointerUp = (e: PointerEvent) => {
      const target = e.target as HTMLElement;
      let contains = false;
      if (inputRef.current.contains(target)) {
        contains = true;
      }
      if (buttonRef.current?.contains(target)) {
        contains = true;
      }
      if (listboxRef.current.contains(target)) {
        contains = true;
      }
      if (!contains) {
        setIsFocused(false);
      } else {
        inputRef.current.focus();
        preventedBlurRef.current = true;
        setTimeout(() => {
          preventedBlurRef.current = false;
        }, PREVENT_BLUR_DURATION);
      }
    };

    document.addEventListener("pointerup", onDocumentPointerUp);
    return () => {
      document.removeEventListener("pointerup", onDocumentPointerUp);
    };
  }, []);

  React.useEffect(() => {
    setListboxOpen(isFocused);
  }, [isFocused]);

  /* #region Scroll selected or hovered option into view */
  React.useEffect(() => {
    if (!hoveredOption) {
      return;
    }
    const node = document.getElementById(`${idPrefixRef.current}-option-${hoveredOption}`);
    if (!node) {
      return;
    }
    node.scrollIntoView({ behavior: "auto", block: "nearest" });
  }, [hoveredOption]);
  React.useEffect(() => {
    if (!listboxOpen) {
      return;
    }
    setTimeout(() => {
      const node = document.getElementById(`${idPrefixRef.current}-option-${value}`);
      if (!node) {
        return;
      }
      node.scrollIntoView({ behavior: "auto", block: "nearest" });
    }, 0);
  }, [listboxOpen]);
  /* #endregion */

  function onEmptyClick() {
    preventedBlurRef.current = true;
    setTimeout(() => {
      preventedBlurRef.current = false;
    }, PREVENT_BLUR_DURATION);

    onChange("");
    setListboxOpen(false);
    inputRef.current.focus();
  }

  function onInputClick() {
    // if not focused yet, it will open, if already focused, toggle
    if (!isFocused) {
      setListboxOpen(true);
    } else {
      setListboxOpen((val) => !val);
    }
  }

  function onListboxButtonClick() {
    onInputClick();
    inputRef.current.focus();
  }

  function onInputFocus() {
    // TODO why delay this?
    setTimeout(() => {
      setIsFocused(true);
    }, 100);
  }

  // TODO why 200 here instead of 400?
  function onInputBlur(_e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      if (!preventedBlurRef.current) {
        setIsFocused(false);
      }
      preventedBlurRef.current = false;
    }, 200);
  }

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (onKeyDown) {
      onKeyDown(e);
    }
    switch (e.code) {
      case "Enter":
        if (!listboxOpen) {
          setListboxOpen(true);
        } else if (hoveredOption) {
          onChange(hoveredOption);
          setListboxOpen(false);
        }
        break;
      case "Down":
      case "ArrowDown":
        if (filteredOptions.length < 1 && !showNoItems) {
          return;
        }
        setListboxOpen(true);
        if (filteredOptions.length < 1) {
          return;
        }
        if (!listboxOpen) {
          return;
        }
        if (!hoveredOption) {
          setHoveredOption(getOptionValue(filteredOptions[0]));
        } else {
          const hoveredIndex = filteredOptions.findIndex((o) => getOptionValue(o) === hoveredOption);
          if (hoveredIndex === filteredOptions.length - 1) {
            setHoveredOption(getOptionValue(filteredOptions[0]));
          } else {
            setHoveredOption(getOptionValue(filteredOptions[hoveredIndex + 1]));
          }
        }
        break;
      case "Up":
      case "ArrowUp":
        if (filteredOptions.length < 1 && !showNoItems) {
          return;
        }
        setListboxOpen(true);
        if (filteredOptions.length < 1) {
          return;
        }
        if (!listboxOpen) {
          return;
        }
        const lastIndex = filteredOptions.length - 1;
        if (!hoveredOption) {
          setHoveredOption(getOptionValue(filteredOptions[lastIndex]));
        } else {
          const hoveredIndex = filteredOptions.findIndex((o) => getOptionValue(o) === hoveredOption);
          if (hoveredIndex === 0) {
            setHoveredOption(getOptionValue(filteredOptions[lastIndex]));
          } else {
            setHoveredOption(getOptionValue(filteredOptions[hoveredIndex - 1]));
          }
        }
        break;
      case "Escape":
      case "Esc":
        if (listboxOpen) {
          setListboxOpen(false);
        } else {
          onChange("");
        }
        break;
      default:
        break;
    }
  }

  function onOptionClick(_: React.MouseEvent<HTMLLIElement, MouseEvent>, option: T) {
    const optionValue = getOptionValue(option);
    onChange(optionValue);
    setListboxOpen(false);
  }

  /* 
  Emphasize whitespace
  */
  const buttonsWidth = buttons.length * 24 + 48;
  const baseFontSize = 16; // TODO connect this to css
  const charSize = baseFontSize * 0.25; // 0.25rem
  const widthCalculatorRef = React.useRef<HTMLDivElement>(null);
  // let leadingSpace = value.length - value.trimLeft().length;
  // let trailingSpace = value.trimLeft().length === 0 ? 0 : value.length - value.trimRight().length;

  const [widthCalClientWidth, setWidthCalClientWidth] = React.useState(0);
  const [clientWidth, setClientWidth] = React.useState(0);
  React.useEffect(() => {
    const widthCalClientWidth = widthCalculatorRef.current?.clientWidth || 0;
    setWidthCalClientWidth(widthCalClientWidth);

    setClientWidth(inputRef.current?.clientWidth || 0);
    setScrollWidth(inputRef.current!.scrollWidth || 0);
  }, [value]);
  const spaceCount = value.trim().length - value.trim().replaceAll(" ", "").length;
  const spaceSize = spaceCount * charSize;
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const [scrollWidth, setScrollWidth] = React.useState(0);
  const scrollableAmount = scrollWidth - clientWidth;
  const scrolled = scrollableAmount - scrollLeft < 1;
  const scrollable = widthCalClientWidth > clientWidth;
  const spacerSize = spaceSize + widthCalClientWidth - buttonsWidth;

  // let startsWithEmphasizeWidth = 0;
  // if (scrollable && scrollLeft > charSize * leadingSpace) {
  //   startsWithEmphasizeWidth = 0;
  // } else if (scrollable) {
  //   startsWithEmphasizeWidth = charSize * leadingSpace - scrollLeft;
  // } else {
  //   startsWithEmphasizeWidth = charSize * leadingSpace;
  // }

  // let endsWithEmphasizeWidth = 0;
  // if (!scrollable || (scrollable && scrolled)) {
  //   endsWithEmphasizeWidth = charSize * trailingSpace;
  // } else if (scrollable && scrollableAmount - scrollLeft < charSize * trailingSpace) {
  //   endsWithEmphasizeWidth = charSize * trailingSpace - (scrollableAmount - scrollLeft);
  // }
  // TODO simplify the calculation, make it work with all whitespace input too

  React.useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const onScroll = () => {
      setScrollLeft(inputRef.current!.scrollLeft);
    };
    inputRef.current.addEventListener("scroll", onScroll);
    return () => inputRef.current?.removeEventListener("scroll", onScroll);
  }, []);
  /* END Emphasize Whitespace */

  return (
    <>
      <div
        className={clsx("ngselect", className, {
          hoveringfocus: !disabled,
          large: size === "toRemoveLarge",
          normal: size === "normal",
          small: size === "small",
          invalid: invalid && !isFocused && touched,
          warning: invalid && !isFocused,
        })}
        style={style}
      >
        <div ref={comboboxRef}>
          <div className={clsx("ngfocus")}>
            <input
              id={`${idPrefixRef.current}-autocomplete`} // automate this id creation
              name={name}
              ref={inputRef}
              disabled={disabled}
              value={value}
              onChange={(e) => {
                if (disabled) {
                  return;
                }
                onChange(e.target.value);
              }}
              onClick={onInputClick}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              onKeyDown={onInputKeyDown}
              className={clsx("main-input", {
                // hasOption: filteredOptions.length > 0,
              })}
              type={"text"}
              role={"combobox"}
              placeholder={placeholder}
              aria-autocomplete={"list"}
              aria-expanded={listboxOpen ? "true" : "false"}
              aria-controls={`${idPrefixRef.current}-listbox`}
              aria-activedescendant={value ? `${idPrefixRef.current}-option-${value}` : ""}
              autoComplete={"off"}
            />
            {/* <div ref={widthCalculatorRef} className={clsx("width-calculator")}>
                {value.trim().replaceAll(" ", "")}
              </div>
              <div
                className={clsx("clone", {
                  hidden: !(
                    !!value &&
                    value.length > 0 &&
                    (value.startsWith(" ") || value?.endsWith(" ")) 
                    // && emphasizeEmptyStringOnEnds
                  ),
                })}
                style={{ paddingRight: 1.2 * baseFontSize + buttonsWidth }}
              >
                <div className="startsWith">
                  <div className="emphasizeIndicator" style={{ width: startsWithEmphasizeWidth }} />
                </div>
                <div className="spacer" style={{ width: spacerSize }} />
                <div className="endsWith">
                  <div className="emphasizeIndicator" style={{ width: endsWithEmphasizeWidth }} />
                </div>
              </div> */}
            {buttons?.map(({ render, onClick, title }) => (
              <Tooltip key={title} title={title}>
                <button
                  key={title}
                  // id={`${idPrefixRef.current}-custombutton`}
                  disabled={disabled}
                  // TODO
                  // aria-label={"custombutton"}
                  onClick={onClick}
                  className={clsx("ngfocus nginnerbutton", `innerbutton-${title.toLowerCase()}`)}
                >
                  {render()}
                </button>
              </Tooltip>
            ))}
            {showClear && value.length > 0 ? (
              <Tooltip title="Clear">
                <button
                  id={`${idPrefixRef.current}-custombutton`}
                  disabled={disabled}
                  aria-label={"custombutton"}
                  onClick={onEmptyClick}
                  className={clsx("ngfocus nginnerbutton innerbutton-clear")}
                >
                  <X className="h-4" />
                </button>
              </Tooltip>
            ) : null}
            {!showNoItems && filteredOptions.length < 1 ? null : (
              <Tooltip title={listboxOpen ? "Close" : "Open"}>
                <button
                  id={`${idPrefixRef.current}-button`}
                  disabled={disabled}
                  ref={buttonRef}
                  tabIndex={-1}
                  // aria-label={accessibilityLabel} // also was on ngselect and ngautocomplete
                  aria-expanded={listboxOpen ? "true" : "false"}
                  aria-controls={`${idPrefixRef.current}-listbox`}
                  onClick={onListboxButtonClick}
                  className={clsx("ngfocus nginnerbutton innerbutton-listbox", {
                    // noOptions: filteredOptions.length < 1,
                    // open: listboxOpen,
                  })}
                >
                  {listboxOpen ? <ChevronUp className="h-4" /> : <ChevronDown className="h-4" />}
                </button>
              </Tooltip>
            )}
          </div>
          <ul
            id={`${idPrefixRef.current}-listbox`}
            ref={listboxRef}
            role={"listbox"}
            className={clsx("max-h-64 overflow-y-auto", {
              open: listboxOpen && (filteredOptions.length > 0 || showNoItems),
              top: listboxPosition === "top",
            })}
          >
            {listboxOpen && filteredOptions.length < 1 && showNoItems ? (
              <li className="px-2 py-1">No items found</li>
            ) : null}
            {listboxOpen &&
              filteredOptions.map((option) => {
                const optionValue = getOptionValue(option);
                const isSelected = value === optionValue;
                const isHovered = hoveredOption === optionValue;

                const props = {
                  id: `${idPrefixRef.current}-option-${optionValue}`,
                  role: "option",
                  "aria-selected": isSelected ? "true" : "false",
                  "data-cy": optionValue,
                  onClick: (e: any) => onOptionClick(e, option),
                  onMouseOver: () => setHoveredOption(optionValue),
                };

                return renderOption({
                  option,
                  props,
                  getOptionLabel,
                  getOptionValue,
                  isSelected,
                  isHovered,
                });
              })}
          </ul>
        </div>
      </div>
    </>
  );
}

export const NGCombobox = observer(NGComboboxRaw);
