import { Instance, types } from "mobx-state-tree";

export const mk8sStatusModel = types
  .model("mk8sStatus", {
    oidcProviderUrl: types.maybe(types.string), // uri
    serverUrl: types.maybe(types.string), // uri
    addOns: types.maybe(
      types.model({
        dashboard: types.maybe(
          types.model({
            url: types.string,
          })
        ),
        awsWorkloadIdentity: types.maybe(
          types.model({
            oidcProviderConfig: types.maybe(
              types.model({
                providerUrl: types.maybe(types.string), // uri
                audience: types.maybe(types.string),
              })
            ),
            trustPolicy: types.maybe(types.frozen()),
          })
        ),
        metrics: types.maybe(
          types.model({
            prometheusEndpoint: types.maybe(types.string),
            remoteWriteConfig: types.maybe(types.frozen()),
          })
        ),
        logs: types.maybe(
          types.model({
            lokiAddress: types.maybe(types.string),
          })
        ),
      })
    ),
  })
  .views((self) => ({
    get hasAddOns() {
      let res = false;
      if (self.addOns?.dashboard) res = true;
      if (self.addOns?.awsWorkloadIdentity) res = true;
      return res;
    },
  }));

export interface mk8sStatusMobx extends Instance<typeof mk8sStatusModel> {}
export interface mk8sStatus {
  oidcProviderUrl?: string;
  serverUrl?: string;
  addOns?: {
    dashboard?: {
      url?: string;
    };
    awsWorkloadIdentity?: {
      oidcProviderConfig?: {
        providerUrl?: string;
        audience?: string;
      };
      trustPolicy?: any;
    };
    metrics: {
      prometheusEndpoint?: string;
      remoteWriteConfig?: any;
    };
    logs: {
      lokiAddress?: string;
    };
  };
}
