import * as React from "react";

// An enum with all the types of actions to use in our reducer
export enum FilesReducerActionType {
  SET_DEFAULTS = "SET_DEFAULTS",
  SET_INITIALS = "SET_INITIALS",
  RESET_TO_DEFAULTS = "RESET_TO_DEFAULTS",
  RESET_TO_INITIALS = "RESET_TO_INITIALS",
  SET_FILE = "SET_FILE",
  CLEAR_FILE = "CLEAR_FILE",
  SET_FILES = "SET_FILES",
  ADD_FILE = "ADD_FILE",
  UPDATE_FILE = "UPDATE_FILE",
  REMOVE_FILE = "REMOVE_FILE",
}

// An interface for our actions
interface FilesReducerAction {
  type: FilesReducerActionType;
  payload: {
    property: string;
    content: string | string[];
    defaults?: { [_: string]: string };
    initials?: { [_: string]: string };
    index?: number;
  };
}

// An interface for our state
interface State {
  initials: {
    [_: string]: any;
  };
  defaults: {
    [_: string]: any;
  };
  current: {
    [_: string]: any;
  };
}

// Our reducer function that uses a switch statement to handle our actions
function filesReducer(state: State, action: FilesReducerAction) {
  const { type, payload } = action;
  let fileState: any;
  switch (type) {
    case FilesReducerActionType.SET_DEFAULTS:
      return { ...state, defaults: payload.defaults || {} };
    case FilesReducerActionType.SET_INITIALS:
      return { ...state, initials: payload.defaults || {} };
    case FilesReducerActionType.RESET_TO_DEFAULTS:
      return { ...state, current: state.defaults };
    case FilesReducerActionType.RESET_TO_INITIALS:
      return { ...state, current: state.initials };
    case FilesReducerActionType.SET_FILE:
    case FilesReducerActionType.SET_FILES:
      return {
        ...state,
        current: {
          ...state.current,
          [payload.property]: payload.content,
        },
      };
    case FilesReducerActionType.CLEAR_FILE:
      delete state.current[payload.property];
      return state;
    case FilesReducerActionType.ADD_FILE:
      fileState = state[payload.property];
      fileState.push(payload.content);
      return {
        ...state,
        current: {
          ...state.current,
          [payload.property]: fileState,
        },
      };
    case FilesReducerActionType.REMOVE_FILE:
      if (payload.index === undefined) {
        console.log('FilesReducerActionType.REMOVE_FILE action needs "index"');
        return state;
      }
      fileState = state[payload.property];
      (fileState as string[]).splice(payload.index, 1);
      return {
        ...state,
        current: {
          ...state.current,
          [payload.property]: fileState,
        },
      };
    case FilesReducerActionType.UPDATE_FILE:
      if (payload.index === undefined) {
        console.log('FilesReducerActionType.REMOVE_FILE action needs "index"');
        return state;
      }
      fileState = state[payload.property];
      fileState[payload.index] = payload.content;

      return {
        ...state,
        current: {
          ...state.current,
          [payload.property]: fileState,
        },
      };
    default:
      return state;
  }
}

export function useFilesReducer(files?: string[]) {
  // TODO might support initialState from previous files, or files' default state
  const initialState: State = { initials: {}, defaults: {}, current: {} };

  return React.useReducer(filesReducer, initialState);
}
