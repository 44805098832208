import * as React from "react";
import { Navigate } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { UserData } from "../../mobxStores/userData/userData";

export const ConsoleRoute: React.FC = () => {
  if (!UserData.hasOrg) {
    return <Navigate to={"/billing"} />;
  }

  if (!ConsoleContext.hasOrg) {
    return <Navigate to={"/console/org/-select"} />;
  }

  if (!UserData.hasGVC) {
    return <Navigate to={`/console/org/${ConsoleContext.org}`} />;
  }

  if (UserData.hasGVC && !ConsoleContext.hasGVC) {
    return <Navigate to={`/console/org/${ConsoleContext.org}/gvc/-select`} />;
  }

  return <Navigate to={`/console/org/${ConsoleContext.org}/gvc/${ConsoleContext.gvc}`} />;
};
