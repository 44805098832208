import { types } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { Kind, KindProperty, WithLinks } from "./base";
import { OrgModel } from "./kinds/org";
import { GVCModel } from "./kinds/gvc";
import { GroupModel } from "./kinds/group";
import { CloudaccountModel } from "./kinds/cloudaccount";
import { PolicyModel } from "./kinds/policy";
import { QuotaModel } from "./kinds/quota";
import { ServiceaccountModel } from "./kinds/serviceaccount";
import { UserModel } from "./kinds/user";
import { WorkloadModel } from "./kinds/workload";
import { LocationModel } from "./kinds/location";
import { DomainModel } from "./kinds/domain";
import { IdentityModel } from "./kinds/identity";
import { SecretModel } from "./kinds/secret";
import { v4 as uuidv4 } from "uuid";

const QueryTermModel = types
  .model("QueryTerm", {
    id: types.optional(types.identifier, () => uuidv4()),
    op: types.optional(types.enumeration("op", ["=", ">", ">=", "<", "<=", "!=", "exists", "!exists", "~"]), "="),
    property: types.maybe(types.string),
    rel: types.maybe(types.string),
    tag: types.maybe(types.string),
    value: types.maybe(types.union(types.string, types.number, types.boolean)),
  })
  .views((self) => ({
    get componentType(): "tag" | "value" {
      if (self.op === "exists") return "value";
      if (self.op === "!exists") return "value";
      return "tag";
    },
    get textValue() {
      if (self.tag) {
        if (self.op === "exists") return `${self.tag} Exists`;
        if (self.op === "!exists") return `${self.tag} Not Exists`;
        return `${self.tag}=${self.value}`;
      } else if (self.property) {
        if (self.op === "exists") return `${self.property} Exists`;
        if (self.op === "!exists") return `${self.property} Not Exists`;
        return `${self.property}=${self.value}`;
      } else if (self.rel) {
        if (self.op === "exists") return `${self.rel} Exists`;
        if (self.op === "!exists") return `${self.rel} Not Exists`;
        return `${self.rel}=${self.value}`;
      }
      return "";
    },
    get toTermObj() {
      const term: QueryTerm = { op: self.op } as any;
      if (self.property) term.property = self.property;
      else if (self.rel) term.rel = self.rel;
      else if (self.tag) term.tag = self.tag;
      if (self.value) term.value = self.value as any;
      return term;
    },
  }));
export interface QueryTermMobx extends Instance<typeof QueryTermModel> {}
export interface QueryTerm {
  op: "=" | ">" | ">=" | "<" | "<=" | "!=" | "exists" | "!exists" | "~";
  property?: string;
  rel?: string;
  tag?: string;
  value?: string;
}

const QuerySortModel = types
  .model("QuerySort", {
    by: types.optional(types.string, ""),
    order: types.optional(types.enumeration("order", ["asc", "desc"]), "asc"),
  })
  .actions((self) => {
    function setBy(value: string) {
      self.by = value;
    }
    function setOrder(value: "asc" | "desc") {
      self.order = value;
    }
    return { setBy, setOrder };
  })
  .views((self) => ({
    get toSortObj() {
      const sort: QuerySort = { order: self.order as "asc" | "desc", by: self.by };
      return sort;
    },
  }));

export interface QuerySortMobx extends Instance<typeof QuerySortModel> {}
export interface QuerySort {
  by: string;
  order: "asc" | "desc";
}

const QuerySpecModel = types
  .model("QuerySpec", {
    match: types.optional(types.enumeration("match", ["all", "any", "none"]), "all"),
    terms: types.array(QueryTermModel),
    sort: types.optional(QuerySortModel, () => QuerySortModel.create()),
  })
  .views((self) => ({
    get tagTerms() {
      return self.terms.filter((t) => !!t.tag);
    },
    get propertyTerms() {
      return self.terms.filter((t) => !!t.property);
    },
    get relTerms() {
      return self.terms.filter((t) => !!t.rel);
    },
  }))
  .actions((self) => {
    function setMatch(value: "all" | "any" | "none") {
      self.match = value;
    }
    function addTagTerm(key: string, op: "=" | "!" | "!!", value?: string) {
      if (op === "!") op = "!exists" as any;
      if (op === "!!") op = "exists" as any;
      if (self.terms.some((t) => t.value === value && t.op === op && t.tag === key)) return;
      const newTerm = QueryTermModel.create({ tag: key, op: op as any, value });
      self.terms.push(newTerm);
    }
    function addPropertyTerm(key: string, op: "=" | "!" | "!!", value?: string) {
      if (op === "!") op = "!exists" as any;
      if (op === "!!") op = "exists" as any;
      if (self.terms.some((t) => t.value === value && t.op === op && t.property === key)) return;
      const newTerm = QueryTermModel.create({ property: key, op: op as any, value });
      self.terms.push(newTerm);
    }
    function addRelTerm(key: string, op: "=" | "!" | "!!", value?: string) {
      if (op === "!") op = "!exists" as any;
      if (op === "!!") op = "exists" as any;
      if (self.terms.some((t) => t.value === value && t.op === op && t.rel === key)) return;
      const newTerm = QueryTermModel.create({ rel: key, op: op as any, value });
      self.terms.push(newTerm);
    }
    function removeTerm(id: string) {
      const term = self.terms.find((t) => t.id === id);
      if (term) {
        self.terms.remove(term);
      }
    }
    function updateTerm(id: string, updatedTerm: Partial<QueryTerm>) {
      const term = self.terms.find((t) => t.id === id);
      if (term) {
        if (updatedTerm.op !== undefined) {
          term.op = updatedTerm.op;
        }
        if (updatedTerm.value !== undefined) {
          if (updatedTerm.op === "!exists" || updatedTerm.op === "exists") {
            term.value = "";
          } else {
            term.value = updatedTerm.value as any;
          }
        }
        if (updatedTerm.property !== undefined) {
          term.property = updatedTerm.property;
          term.tag = undefined;
          term.rel = undefined;
        } else if (updatedTerm.tag !== undefined) {
          term.tag = updatedTerm.tag;
          term.property = undefined;
          term.rel = undefined;
        } else if (updatedTerm.rel !== undefined) {
          term.rel = updatedTerm.rel;
          term.property = undefined;
          term.tag = undefined;
        }
      }
    }
    return { setMatch, addTagTerm, addPropertyTerm, addRelTerm, removeTerm, updateTerm };
  })
  .views((self) => ({
    get toSpecObj(): QuerySpec {
      const spec: QuerySpec = { match: self.match as any, terms: [] };
      for (let term of self.terms) {
        spec.terms.push(term.toTermObj);
      }
      if (self.sort.by) {
        spec.sort = self.sort.toSortObj;
      }
      return spec;
    },
  }));
export interface QuerySpecMobx extends Instance<typeof QuerySpecModel> {}
export interface QuerySpec {
  match: "all" | "any" | "none";
  terms: QueryTerm[];
  sort?: QuerySort;
}

export const QueryModel = types
  .model("Query", {
    kind: types.maybe(KindProperty),
    // context: types.frozen(),
    // fetch: types.optional(types.enumeration('fetch', ['links', 'items']), 'items'),
    spec: types.optional(QuerySpecModel, () => QuerySpecModel.create()),
  })
  .actions((self) => {
    function clear() {
      self.kind = undefined;
      self.spec = QuerySpecModel.create();
    }
    return { clear };
  })
  .views((self) => ({
    get toQueryObj() {
      const query: Query = { spec: self.spec.toSpecObj } as any;
      if (self.kind) {
        query.kind = self.kind as any;
      }
      return query;
    },
  }));
export interface QueryMobx extends Instance<typeof QueryModel> {}
export interface Query {
  kind?: Kind;
  // context: any,
  // fetch: 'links' | 'items',
  spec: QuerySpec;
}

export const QueryResultModel = types.model("QueryResult", {
  kind: "queryresult",
  itemKind: KindProperty,
  items: types.array(
    types.maybe(
      types.union(
        types.late(() => CloudaccountModel),
        types.late(() => DomainModel),
        types.late(() => GroupModel),
        types.late(() => GVCModel),
        types.late(() => IdentityModel),
        types.late(() => LocationModel),
        types.late(() => OrgModel),
        types.late(() => PolicyModel),
        types.late(() => QuotaModel),
        types.late(() => SecretModel),
        types.late(() => ServiceaccountModel),
        types.late(() => UserModel),
        types.late(() => WorkloadModel)
      )
    )
  ),
  links: types.frozen(),
  query: QueryModel,
});
export interface QueryResultMobx extends Instance<typeof QueryResultModel> {}
export interface QueryResult extends WithLinks {
  kind: "queryresult";
  itemKind: Kind;
  items: any[];
  query: Query;
}

export const ItemStoreQueryModel = types
  .model("Store Model Query", {
    isApplied: false,
    nextPageLink: types.maybe(types.string),
    currentPage: 0,
    isOpen: false,
    model: types.optional(
      types.late(() => QueryModel),
      () => QueryModel.create()
    ),
    previousModel: types.optional(
      types.late(() => QueryModel),
      () => QueryModel.create()
    ),
  })
  .views((self) => ({
    get isDirty() {
      // if (self.model.spec.terms.length < 1) return false;
      let res = false;
      if (self.model.spec.match !== self.previousModel.spec.match) res = true;
      if (self.model.spec.sort.by !== self.previousModel.spec.sort.by) res = true;
      if (self.model.spec.sort.order !== self.previousModel.spec.sort.order) res = true;
      if (self.model.spec.terms.length !== self.previousModel.spec.terms.length) res = true;
      for (let term of self.model.spec.terms) {
        if (
          !self.previousModel.spec.terms.some((t) => t.tag === term.tag && t.op === term.op && t.value === term.value)
        ) {
          res = true;
        }
      }
      for (let term of self.previousModel.spec.terms) {
        if (!self.model.spec.terms.some((t) => t.tag === term.tag && t.op === term.op && t.value === term.value)) {
          res = true;
        }
      }
      return res;
    },
  }))
  .actions((self) => {
    function toggleIsOpen() {
      self.isOpen = !self.isOpen;
    }
    function setIsOpen(value: boolean) {
      self.isOpen = value;
    }
    function clear() {
      self.isApplied = false;
      self.nextPageLink = undefined;
      self.currentPage = 0;
      self.model.clear();
      self.previousModel.clear();
      self.isOpen = false;
    }
    return { toggleIsOpen, setIsOpen, clear };
  });

export interface ItemStoreQueryMobx extends Instance<typeof ItemStoreQueryModel> {}

export function fuzzySearchQuery(kind: string, search: string) {
  return {
    kind,
    spec: {
      match: "any",
      terms: [
        {
          op: "~",
          property: "*",
          value: search,
        },
        {
          op: "~",
          tag: "*",
          value: search,
        },
      ],
    },
  };
}

export interface FilterTerm {
  property?: string;
  rel?: string;
  value: string;
}
export function kindQuery(kind: string, filters: FilterTerm[], filter?: string) {
  const terms = filters.map((f) => {
    if (f.property) {
      return { op: "=", property: f.property, value: f.value };
    }
    return { op: "=", rel: f.rel, value: f.value };
  });
  if (filter) {
    terms.push({
      op: "~",
      property: "*",
      value: filter,
    });
  }
  return {
    kind,
    spec: {
      match: "all",
      terms,
    },
  };
}
