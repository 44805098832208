import { observer } from "mobx-react-lite";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { UserData } from "../../../mobxStores/userData/userData";
import { GVCIconAdd, GVCIcon } from "../../Icons";
import { GvcChange } from "./GvcChange";
import { MenuLink } from "./menu/link";

export const GVCMenuLink: React.FC = observer(() => {
  const { org } = ConsoleContext;

  if (UserData.hasGVC && !ConsoleContext.hasGVC) {
    return <MenuLink url={`/console/org/${org}/gvc/-select?skip=true`} Icon={GVCIcon} label={"Select GVC"} />;
  }
  if (UserData.hasGVC || UserData.isLoadingGVCNames) {
    return <GvcChange />;
  }

  return <MenuLink url={`/console/org/${org}/gvc/-create`} Icon={GVCIconAdd} isAdd={true} label={"Create a GVC"} />;
});
