import * as React from "react";
import { observer } from "mobx-react-lite";
import { Secret } from "../../../../schema/types/secret";
import { Table } from "../../../../newcomponents/table/table";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";
import { linksOf } from "../../../../services/cpln";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { NameDescriptionColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";

interface SecretTableItem extends Secret {
  selfLink: string;
}

interface Props {
  selections: string[];
  onSelectionsChange: (value: string[]) => void;
}
const CloudaccountCreateNgsConfigurationRaw: React.FC<Props> = ({ selections, onSelectionsChange }) => {
  const { kind, id } = useTableKindId("secret", "cloudaccount-create-ngs-secret-list");
  // TODO if there is predefined query, do not allow custom query because of match: 'all'
  const query = React.useMemo(
    () => ({
      kind: "secret" as any,
      spec: { match: "all" as any, terms: [{ property: "type", op: "=" as any, value: "nats-account" }] },
    }),
    []
  );
  const qData = useTableItemQueryData<Secret>(kind, {
    fetchAllPages: true,
    query: query,
  });

  const secretTableItems: SecretTableItem[] = qData.visibleItems.map((item) => ({
    ...item,
    selfLink: linksOf(item).self!,
  }));

  return (
    <Table<SecretTableItem>
      title={"Secrets"}
      data={secretTableItems}
      tableId={id}
      selectMode="single"
      selectKey="selfLink"
      selections={selections}
      onSelectionsChange={onSelectionsChange}
      headerRenderer={() => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionColumn(),
        {
          id: "type",
          label: "Type",
          groupLabelMap: {
            aws: "AWS",
            "azure-connector": "Azure Connector",
            "azure-sdk": "Azure SDK",
            docker: "Docker",
            dictionary: "Dictionary",
            ecr: "ECR",
            gcp: "GCP",
            keypair: "Keypair",
            "nats-account": "Nats Account",
            opaque: "Opaque",
            tls: "TLS",
            userpass: "Username & Password",
          },
          size: 200,
        },
        TagsColumn(),
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};

export const CloudaccountCreateNgsConfiguration = observer(CloudaccountCreateNgsConfigurationRaw);
