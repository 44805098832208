import React from "react";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import "./select.css";
import { observer } from "mobx-react-lite";
import { ChevronDown, ChevronUp, X } from "react-feather";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { InnerButtonProps } from "../input/input";
import { Tooltip } from "../../components/Tooltip";
import { FilterOptionsProps, OptionRendererProps } from "./types";
import {
  AutocompleteDefaultFilterOptionsHandler,
  DefaultGetOptionLabel,
  DefaultGetOptionValue,
  DefaultOptionRenderer,
} from "./utils";

export type NGAutocompleteProps<T> = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  options: T[];
  disabled?: boolean;
  invalid?: boolean;
  name?: string;
  // --
  renderOption?: (props: OptionRendererProps<T>) => React.ReactElement;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => string;
  filterOptions?: (props: FilterOptionsProps<T>) => T[];
  // TODO support to keep focus on click, or focusing back to this after modal
  buttons?: InnerButtonProps[];
  allowEmpty?: boolean;
  showNoItems?: boolean;
  size?: "toRemoveLarge" | "normal" | "small";
  //
  style?: React.CSSProperties;
  className?: string;
};

// TODO can show all items on first listboxOpen

function NGAutocompleteRaw<T>({
  value,
  onChange,
  placeholder = "",
  options,
  disabled = false,
  invalid = false,
  name,
  // --
  renderOption = DefaultOptionRenderer,
  getOptionLabel = DefaultGetOptionLabel as any,
  getOptionValue = DefaultGetOptionValue as any,
  filterOptions = AutocompleteDefaultFilterOptionsHandler as any,
  buttons = [],
  allowEmpty = false,
  showNoItems = true,
  size = "normal",
  //
  style = {},
  className = "",
}: NGAutocompleteProps<T>) {
  /* Form state START */
  const formData = useNGFormContext();
  const [isFocused, setIsFocused] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [visited, setVisited] = React.useState(false);
  React.useEffect(() => {
    if (isFocused) {
      if (name && formData) {
        formData.set(name, { focused: true });
      }
      if (!visited) {
        setVisited(true);
        if (name && formData) {
          formData.set(name, { visited: true });
        }
      }
      return;
    }

    if (visited && !touched) {
      setTouched(true);
      if (name && formData) {
        formData.set(name, { focused: false, touched: true });
      }
    }
  }, [isFocused]);
  /* Form state END */

  const option = options.find((o) => getOptionValue(o) === value);
  const label = option ? getOptionLabel(option) : value;

  const comboboxRef = React.useRef<HTMLDivElement>(null as any);
  const inputRef = React.useRef<HTMLInputElement>(null as any);
  const buttonRef = React.useRef<HTMLButtonElement>(null as any);
  const listboxRef = React.useRef<HTMLUListElement>(null as any);
  // const idPrefixRef = React.useRef(idPrefix || uuidv4());
  const idPrefixRef = React.useRef(uuidv4());

  const preventedBlurRef = React.useRef(false);

  const [visualValue, setVisualValue] = React.useState(label);
  const [filteredOptions, setFilteredOptions] = React.useState<T[]>([]);

  const [hoveredOption, setHoveredOption] = React.useState<string>("");
  const [listboxOpen, setListboxOpen] = React.useState(false);

  // TODO fix
  const [listboxPosition, setListboxPosition] = React.useState<"bottom" | "top">("bottom");

  React.useEffect(() => {
    setFilteredOptions(filterOptions({ value: visualValue, getOptionLabel, getOptionValue, options }));
  }, [visualValue, options]);

  /* #region - Handle vertical placement of listbox */
  // START - Handle vertical placement of listbox
  React.useEffect(() => {
    if (listboxOpen) {
      checkBoundingBox();
    } else {
      setListboxPosition("bottom");
      setHoveredOption("");
    }
  }, [listboxOpen, filteredOptions.length]);

  function checkBoundingBox() {
    let bounds = listboxRef.current.getBoundingClientRect();
    checkVerticalBounding(bounds);
  }

  function checkVerticalBounding(bounds: DOMRect) {
    let windowHeight = window.innerHeight;
    if (bounds.bottom > windowHeight && bounds.top < 0) {
      return;
    }
    if (bounds.bottom > windowHeight) {
      setListboxPosition("top");
    }
    if (bounds.top < 0) {
      setListboxPosition("bottom");
    }
  }
  // END - Handle placement of listbox
  /* #endregion */

  // Handle outside click
  React.useEffect(() => {
    const onDocumentPointerUp = (e: PointerEvent) => {
      const target = e.target as HTMLElement;
      let contains = false;
      if (inputRef.current.contains(target)) {
        contains = true;
      }
      if (buttonRef.current?.contains(target)) {
        contains = true;
      }
      if (listboxRef.current.contains(target)) {
        contains = true;
      }
      if (!contains) {
        setIsFocused(false);
      } else {
        inputRef.current.focus();
        preventedBlurRef.current = true;
        setTimeout(() => {
          preventedBlurRef.current = false;
        }, 400);
      }
    };

    document.addEventListener("pointerup", onDocumentPointerUp);
    return () => {
      document.removeEventListener("pointerup", onDocumentPointerUp);
    };
  }, []);

  React.useEffect(() => {
    setListboxOpen(isFocused);
  }, [isFocused]);

  React.useEffect(() => {
    if (filteredOptions.length < 1) {
      setHoveredOption("");
      return;
    }

    if (!hoveredOption || !filteredOptions.some((opt) => getOptionValue(opt) === hoveredOption)) {
      setHoveredOption(getOptionValue(filteredOptions[0]));
    }
  }, [filteredOptions.length]);

  // Scroll navigated option into view
  React.useEffect(() => {
    if (!hoveredOption) {
      return;
    }
    const node = document.getElementById(`${idPrefixRef.current}-option-${hoveredOption}`);
    if (!node) {
      return;
    }
    node.scrollIntoView({ behavior: "auto", block: "nearest" });
  }, [hoveredOption]);

  function onEmptyClick() {
    preventedBlurRef.current = true;
    setTimeout(() => {
      preventedBlurRef.current = false;
    }, 400);

    if (!allowEmpty) {
      return;
    }
    setVisualValue("");
    onChange("");
    setListboxOpen(false);
    inputRef.current.focus();
  }

  function onInputClick() {
    // if not focused yet, it will open, if already focused, toggle
    if (!isFocused) {
      setListboxOpen(true);
    } else {
      setListboxOpen((val) => !val);
    }
  }

  function onListboxButtonClick() {
    onInputClick();
    inputRef.current.focus();
  }

  function onInputFocus() {
    setTimeout(() => {
      setIsFocused(true);
    }, 100);
  }

  function onInputBlur(_e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      if (!preventedBlurRef.current) {
        // Currently setting to first filtered option, but we can also switch to exact label value
        const firstOption = !!visualValue ? filteredOptions[0] : undefined;

        // set to searched option if found, or set to empty if allowed
        if (firstOption) {
          onChange(getOptionValue(firstOption));
          setVisualValue(getOptionLabel(firstOption));
        } else if (visualValue === "" && allowEmpty) {
          onChange("");
          setVisualValue("");
        } else {
          // default inner value to last selected valid value
          setVisualValue(label);
        }

        setIsFocused(false);
      }
      preventedBlurRef.current = false;
    }, 200);
  }

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.code) {
      case "Enter":
        if (!listboxOpen) {
          setListboxOpen(true);
        } else if (hoveredOption) {
          const hoveredOptionItem = filteredOptions.find((o) => getOptionValue(o) === hoveredOption);
          const hoveredOptionLabel = hoveredOptionItem ? getOptionLabel(hoveredOptionItem) : "";
          if (!hoveredOptionLabel) {
            console.error("There must have been an hovered option label");
          }
          setVisualValue(hoveredOptionLabel);
          onChange(hoveredOption);
          setListboxOpen(false);
        }
        break;
      case "Down":
      case "ArrowDown":
        if (filteredOptions.length < 1 && !showNoItems) {
          return;
        }
        setListboxOpen(true);
        if (filteredOptions.length < 1) {
          return;
        }
        if (!hoveredOption) {
          setHoveredOption(getOptionValue(filteredOptions[0]));
        } else {
          const hoveredIndex = filteredOptions.findIndex((o) => getOptionValue(o) === hoveredOption);
          if (hoveredIndex === filteredOptions.length - 1) {
            setHoveredOption(getOptionValue(filteredOptions[0]));
          } else {
            setHoveredOption(getOptionValue(filteredOptions[hoveredIndex + 1]));
          }
        }
        break;
      case "Up":
      case "ArrowUp":
        if (filteredOptions.length < 1 && !showNoItems) {
          return;
        }
        setListboxOpen(true);
        if (filteredOptions.length < 1) {
          return;
        }
        const lastIndex = filteredOptions.length - 1;
        if (!hoveredOption) {
          setHoveredOption(getOptionValue(filteredOptions[lastIndex]));
        } else {
          const hoveredIndex = filteredOptions.findIndex((o) => getOptionValue(o) === hoveredOption);
          if (hoveredIndex === 0) {
            setHoveredOption(getOptionValue(filteredOptions[lastIndex]));
          } else {
            setHoveredOption(getOptionValue(filteredOptions[hoveredIndex - 1]));
          }
        }
        break;
      case "Escape":
      case "Esc":
        if (listboxOpen) {
          setListboxOpen(false);
        } else if (allowEmpty) {
          setVisualValue("");
          onChange("");
        }
        break;
      default:
        break;
    }
  }

  function onOptionClick(_: React.MouseEvent<HTMLLIElement, MouseEvent>, option: T) {
    const optionValue = getOptionValue(option);
    const optionLabel = getOptionLabel(option);
    setVisualValue(optionLabel);
    onChange(optionValue);
    setListboxOpen(false);
  }

  // TODO do not open list on mouse click if filtered options are length 1
  // TODO check case when filtered options are empty and user is pressing key Enter
  // TODO check case when hovered is empty and user is pressing key Enter

  return (
    <>
      <div
        className={clsx("ngselect", className, {
          hoveringfocus: !disabled,
          large: size === "toRemoveLarge",
          normal: size === "normal",
          small: size === "small",
          invalid: invalid && !isFocused && touched,
        })}
        style={style}
      >
        <div ref={comboboxRef}>
          <div className={clsx("ngfocus")}>
            <input
              id={`${idPrefixRef.current}-autocomplete`}
              ref={inputRef}
              name={name}
              disabled={disabled}
              value={visualValue}
              onChange={(e) => {
                if (disabled) {
                  return;
                }
                setVisualValue(e.target.value);
              }}
              onClick={onInputClick}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              onKeyDown={onInputKeyDown}
              className={clsx("", {
                // hasOption: filteredOptions.length > 0,
              })}
              type={"text"}
              role={"combobox"}
              placeholder={placeholder}
              aria-autocomplete={"list"}
              aria-expanded={listboxOpen ? "true" : "false"}
              aria-controls={`${idPrefixRef.current}-listbox`}
              aria-activedescendant={value ? `${idPrefixRef.current}-option-${value}` : ""}
              autoComplete={"off"}
            />
            {buttons?.map(({ render, onClick, title }) => (
              <Tooltip key={title} title={title}>
                <button
                  // id={`${idPrefixRef.current}-custombutton`}
                  disabled={disabled}
                  // TODO
                  // aria-label={"custombutton"}
                  onClick={onClick}
                  className={clsx("ngfocus nginnerbutton", `innerbutton-${title.toLowerCase()}`)}
                >
                  {render()}
                </button>
              </Tooltip>
            ))}
            {allowEmpty && label.length > 0 ? (
              <Tooltip title="Clear">
                <button
                  id={`${idPrefixRef.current}-custombutton`}
                  disabled={disabled}
                  aria-label={"custombutton"}
                  onClick={onEmptyClick}
                  className={clsx("ngfocus nginnerbutton innerbutton-clear")}
                >
                  <X className="h-4" />
                </button>
              </Tooltip>
            ) : null}
            <Tooltip title={listboxOpen ? "Close" : "Open"}>
              <button
                id={`${idPrefixRef.current}-button`}
                disabled={disabled}
                ref={buttonRef}
                tabIndex={-1}
                aria-expanded={listboxOpen ? "true" : "false"}
                aria-controls={`${idPrefixRef.current}-listbox`}
                onClick={onListboxButtonClick}
                className={clsx("ngfocus nginnerbutton", "innerbutton-listbox", {
                  // noOptions: filteredOptions.length < 1,
                  // open: listboxOpen,
                })}
              >
                {listboxOpen ? <ChevronUp className="h-4" /> : <ChevronDown className="h-4" />}
              </button>
            </Tooltip>
          </div>
          <ul
            id={`${idPrefixRef.current}-listbox`}
            ref={listboxRef}
            role={"listbox"}
            className={clsx("max-h-64 overflow-y-auto", {
              open: listboxOpen && (filteredOptions.length > 0 || showNoItems),
              top: listboxPosition === "top",
            })}
          >
            {listboxOpen && filteredOptions.length < 1 && showNoItems ? (
              <li className="px-2 py-1">No items found</li>
            ) : null}
            {listboxOpen &&
              filteredOptions.map((option) => {
                const optionValue = getOptionValue(option);
                const isSelected = value === optionValue;
                const isHovered = hoveredOption === optionValue;

                const props = {
                  id: `${idPrefixRef.current}-option-${optionValue}`,
                  role: "option",
                  "aria-selected": isSelected ? "true" : "false",
                  "data-cy": optionValue,
                  onClick: (e: any) => onOptionClick(e, option),
                  onMouseOver: () => setHoveredOption(optionValue),
                  onMouseOut: () => {
                    setHoveredOption((oldOptionValue) => {
                      if (oldOptionValue === optionValue) {
                        return "";
                      }
                      return oldOptionValue;
                    });
                  },
                };

                return renderOption({
                  option,
                  props,
                  getOptionLabel,
                  getOptionValue,
                  isSelected,
                  isHovered,
                });
              })}
          </ul>
        </div>
      </div>
    </>
  );
}

export const NGAutocomplete = observer(NGAutocompleteRaw);
