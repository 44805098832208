import * as React from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { IdentityDraftMobx } from "../../../mst/stores/identity.draft";
import { NativeNetworkResourcesBase } from "./nativeNetworkResourcesBase";
import { useDetailContext } from "../../../components/detail/detailContext";
import { NGButton } from "../../../newcomponents/button/Button";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";

interface Props {
  identityDraft: IdentityDraftMobx;
  patch: (body: any) => Promise<void>;
}

const NativeNetworkResourcesViewRaw: React.FC<Props> = ({ identityDraft, patch }) => {
  const { fetchItem } = useDetailContext();
  const [isLoading, setIsLoading] = React.useState(false);

  // Use Effects //
  React.useEffect(() => {
    identityDraft.reset();
    return () => {
      identityDraft.reset();
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(identityDraft.nativeNetworkResources.isDirty || isLoading);
  }, [identityDraft.nativeNetworkResources.isDirty, isLoading]);

  useCleanPrompt();

  // Functions //
  function reset() {
    identityDraft.nativeNetworkResources.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      await patch({
        nativeNetworkResources: identityDraft.nativeNetworkResources.asPatch,
      });
      identityDraft.confirm();
      notification.success({
        message: "Success",
        description: "Updated Identity",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <NativeNetworkResourcesBase identity={identityDraft} />
      <div className="mt-8 flex items-center">
        <NGButton
          style={{ width: 215, marginRight: 10 }}
          disabled={isLoading || !identityDraft.nativeNetworkResources.isDirty}
          variant={"danger"}
          outlined
          onClick={reset}
        >
          Reset
        </NGButton>
        <NGButton
          style={{ width: 215, marginLeft: 10 }}
          disabled={
            isLoading || !identityDraft.nativeNetworkResources.isDirty || !identityDraft.nativeNetworkResources.isValid
          }
          loading={isLoading}
          onClick={save}
          variant={"primary"}
        >
          Save
        </NGButton>
      </div>
    </>
  );
};

export const NativeNetworkResourcesView = observer(NativeNetworkResourcesViewRaw);
