import { observer } from "mobx-react-lite";
import * as React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { TabsLayout, TabsLayoutLink } from "../../../components/tabsLayout/tabsLayout";
import { WorkloadCreateDefaultOptions } from "./defaultOptions";
import { WorkloadCreateLocalOptionsItem } from "./localOptionsItem";
import { BasePathContext } from "../../../reactContexts/basePathContext";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  gvcLocationNames: string[];
}
const WorkloadCreateOptionsRaw: React.FC<Props> = ({ workloadDraft, gvcLocationNames }) => {
  const basePath = React.useContext(BasePathContext);
  const optionsPath = `${basePath}/-options`;

  const navigate = useNavigate();
  const draft = workloadDraft;

  const links: TabsLayoutLink[] = [{ url: `${optionsPath}/-default`, label: "Default", isActive: true }];
  for (let localOptionsItemIndex in draft.localOptions) {
    const localOptionsItem = draft.localOptions[localOptionsItemIndex];
    // below can be changed for label, when we have more location with different urls
    links.push({
      url: `${optionsPath}/${localOptionsItemIndex}`,
      label: localOptionsItem.location.value || "Select Location",
      isActive: true,
      group: "Local Options",
      index: localOptionsItemIndex,
    });
  }

  function onClickAddLocalOption() {
    draft.addLocalOptionsItem();
    const localOptionsItemPath = `${optionsPath}/${String(draft.localOptions.length - 1)}`;
    navigate(localOptionsItemPath);
  }

  if (draft.localOptions.length < gvcLocationNames.length) {
    links.push({
      onClick: onClickAddLocalOption,
      url: "",
      label: "+ Add Local Options",
      isActive: draft.isLocalOptionsValid,
    });
  }

  return (
    <TabsLayout links={links}>
      <Routes>
        <Route path={`-default`} element={<WorkloadCreateDefaultOptions workloadDraft={draft} />} />
        {draft.localOptions.map((_, index) => (
          <Route
            key={index}
            path={`${index}`}
            element={<WorkloadCreateLocalOptionsItem draft={draft} gvcLocationNames={gvcLocationNames} />}
          />
        ))}
        <Route index element={<Navigate to={`-default`} />} />
      </Routes>
    </TabsLayout>
  );
};

export const WorkloadCreateOptions = observer(WorkloadCreateOptionsRaw);
