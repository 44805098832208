import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { AlertCircle, ChevronDown, ChevronUp, PlusCircle } from "react-feather";
import { FormLabel } from "../../components/forms/formLabel";
import { Loader } from "../../components/layout/loader";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { mk8sMobx } from "../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../mst/stores/mk8s.draft";
import { homeLink, request, resourceLink } from "../../services/cpln";
import { randomName } from "../../services/utils";
import { getTemplate } from "./kubeConfigTemplate";
import { NGButton } from "../../newcomponents/button/Button";
import NGAlert from "../../newcomponents/alert";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInput } from "../../newcomponents/input/input";
import { NGLabel } from "../../newcomponents/text/label";
import { ExternalLink } from "../../newcomponents/table/components/ExternalLink";
import { DOCS_URL } from "../../envVariables";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const KubeConfigRaw: React.FC<Props> = ({ mk8s }) => {
  const [serviceAccName, setServiceAccName] = React.useState("");
  const [newServiceAccName, setNewServiceAccName] = React.useState("");
  const [isCreatingServiceAccount, setIsCreatingServiceAccount] = React.useState(false);
  const [profileName, setProfileName] = React.useState("default");
  const [isLoading, setIsLoading] = React.useState(false);

  const [method, setMethod] = React.useState<"sa" | "pr" | null>(null);

  async function getCaCert() {
    try {
      const { data } = await request({ url: mk8s.selfLink + "/-cacerts" });
      return data.cacerts;
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to get cacert", description: errorMessage });
    }
  }

  async function getKey(serviceAccountName: string, keyDescription: string) {
    try {
      const { data }: any = await request({
        method: "post",
        url: resourceLink("serviceaccount", serviceAccountName) + "/-addKey",
        body: { description: keyDescription },
      });
      return data.key;
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to get service account key", description: errorMessage });
      return "";
    }
  }

  async function createKubeConfig() {
    try {
      setIsLoading(true);
      const serviceAccountKey = await getKey(serviceAccName, randomName());
      if (!serviceAccountKey) {
        setIsLoading(false);
        return;
      }

      const caCert = await getCaCert();

      const _kubeConfig = getTemplate(
        ConsoleContext.org || "",
        mk8s.name,
        mk8s.alias,
        serviceAccName,
        serviceAccountKey,
        caCert,
        mk8s.status?.serverUrl || "",
      );

      downloadKubeConfig(_kubeConfig);
      setIsLoading(false);

      notification.success({
        message: "Success",
        description: "Generated & Downloaded kubeconfig",
      });
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  async function createKubeConfigForProfile() {
    try {
      setIsLoading(true);

      const { data } = await request({
        method: "post",
        url: mk8s.selfLink + "/-kubeconfig",
        body: { profile: profileName || "default" },
      });

      downloadKubeConfig(data.kubeconfig, data.fileName);
      setIsLoading(false);

      notification.success({
        message: "Success",
        description: "Downloaded kubeconfig",
      });
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  function downloadKubeConfig(blob: string, filename?: string) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.classList.add("cpln-temp-a");
    a.download = `${mk8s.name}-kubeconfig.yaml`;
    if (filename) {
      a.download = filename;
    }
    const file = new Blob([blob], { type: "text/yaml" });
    const href = URL.createObjectURL(file);
    a.href = href;
    a.click();
  }

  return (
    <div>
      {/* // TODO remove discrepancy with formlabel size and label size */}
      <FormLabel size={"large"}>kubeconfig</FormLabel>
      <div
        className="mt-4 color-link cursor-pointer flex items-center gap-1"
        onClick={() => setMethod((v) => (v === "sa" ? null : "sa"))}
      >
        <FormLabel size={"base"}>Continue with Service Account</FormLabel>
        {method === "sa" ? (
          <ChevronUp style={{ width: 16, height: 16 }} />
        ) : (
          <ChevronDown style={{ width: 16, height: 16 }} />
        )}
      </div>
      {method === "sa" ? (
        <>
          <div className="flex items-center gap-2 mt-4">
            <NGKindSelect
              style={{ width: 450 }}
              kind={"serviceaccount"}
              value={serviceAccName}
              onChange={(value) => setServiceAccName(value)}
              buttons={[
                {
                  title: "Create",
                  render: () => <PlusCircle className="h-4" />,
                  onClick: () => setIsCreatingServiceAccount(true),
                },
              ]}
            />
          </div>
          <div className="flex items-center gap-1 text-sm mt-2 mb-4">
            <AlertCircle className={`feather-icon color-primary`} />
            <div>Create or select a service account. A new key will be created to be used for the kubeconfig file.</div>
          </div>
          <div className="flex items-center gap-2">
            <NGButton
              disabled={!serviceAccName || isLoading}
              loading={isLoading}
              variant="primary"
              style={{ width: 450 }}
              onClick={() => createKubeConfig()}
            >
              Download kubeconfig
            </NGButton>
          </div>
        </>
      ) : null}
      <div
        className={`${method === "sa" ? "mt-8" : "mt-2"} color-link cursor-pointer flex items-center gap-1`}
        onClick={() => setMethod((v) => (v === "pr" ? null : "pr"))}
      >
        <FormLabel size={"base"}>Continue with CPLN CLI Profile</FormLabel>
        {method === "pr" ? (
          <ChevronUp style={{ width: 16, height: 16 }} />
        ) : (
          <ChevronDown style={{ width: 16, height: 16 }} />
        )}
      </div>
      {method === "pr" ? (
        <>
          <div className="flex flex-col gap-2 my-4">
            <NGLabel>CPLN CLI Profile Name</NGLabel>
            <NGInput
              style={{ width: 450 }}
              placeholder="default"
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
            />
          </div>
          <div className="flex items-center gap-1 text-sm my-2">
            <AlertCircle className={`feather-icon color-primary`} />
            <div>CPLN command line tool needs to be installed for this method to work.</div>
          </div>
          <ExternalLink label="How to Install CPLN CLI?" url={`${DOCS_URL}/reference/cli`} disableCopy />
          <div className="flex items-center gap-2 mt-4">
            <NGButton
              disabled={isLoading}
              loading={isLoading}
              variant="primary"
              style={{ width: 450 }}
              onClick={() => createKubeConfigForProfile()}
            >
              Download kubeconfig
            </NGButton>
          </div>
        </>
      ) : null}
      {isCreatingServiceAccount ? (
        <Modal
          open={isCreatingServiceAccount}
          title={"Create a Service Account"}
          onCancel={() => {
            setIsCreatingServiceAccount(false);
            setNewServiceAccName("");
          }}
          footer={
            <div className="modal-actions">
              <NGButton
                variant="secondary"
                onClick={() => {
                  setIsCreatingServiceAccount(false);
                  setNewServiceAccName("");
                }}
              >
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    await request({
                      url: homeLink("serviceaccount"),
                      body: { name: newServiceAccName },
                      method: "post",
                    });
                  } catch (e) {
                    let errorMessage = e?.response?.data?.message;
                    if (!errorMessage) errorMessage = e.message;
                    notification.warning({ message: "Failed to create service account", description: errorMessage });
                  }
                  setIsCreatingServiceAccount(false);
                  setServiceAccName(newServiceAccName);
                  setIsLoading(false);
                }}
                disabled={!newServiceAccName}
              >
                Create
              </NGButton>
            </div>
          }
        >
          <div>
            <NGLabel>New Service Account Name</NGLabel>
            <NGInput value={newServiceAccName} onChange={(e) => setNewServiceAccName(e.target.value)} />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export const KubeConfig = observer(KubeConfigRaw);
