import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../../advanced/providerAutoscaler";
import { ProviderNetworking } from "../../../advanced/providerNetworking";
import { ProviderPreInstallScript } from "../../../advanced/providerPreInstallScript";
import { TritonLoadBalancer } from "./loadBalancer";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderTritonAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const t = mk8sDraft.provider_triton!;

  return (
    //
    <div>
      <div className="mb-4">
        <ProviderPreInstallScript provider="triton" preInstallScript={t.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={t.networking} />
      </div>
      <div>
        <ProviderAutoscaler provider="triton" autoscaler={t.autoscaler} />
      </div>
      <div>
        <TritonLoadBalancer mk8sDraft={mk8sDraft} lb={t.loadBalancer} />
      </div>
    </div>
  );
};

export const Mk8sCreateProviderTritonAdvanced = observer(Mk8sCreateProviderTritonAdvancedRaw);
