import * as React from "react";
import { ContainersLabel } from "./containers-labels";

export const ContainersWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="mt-2">
      <ContainersLabel />
      {children}
    </div>
  );
};
