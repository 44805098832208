import { types } from "mobx-state-tree";

export interface NGSIdentityDataSnapshot {
  cloudAccountLink: string;
  pub?: {
    allow?: string[];
    deny?: string[];
  };
  sub?: {
    allow?: string[];
    deny?: string[];
  };
  resp?: {
    max?: number;
    ttl?: string;
  };
  subs?: number;
  data?: number;
  payload?: number;
}

export const NGSIdentityData = types
  .model({
    isActive: false,
    cloudaccountLink: types.optional(types.string, ""),
    pubAllow: types.array(types.string),
    pubDeny: types.array(types.string),
    subAllow: types.array(types.string),
    subDeny: types.array(types.string),
    resp: types.maybe(
      types.model({
        max: types.maybe(types.number),
        ttl: types.maybe(types.string),
      })
    ),
    subs: types.maybe(types.number),
    data: types.maybe(types.number),
    payload: types.maybe(types.number),
  })
  .actions((self) => ({
    clear() {
      self.isActive = false;
      self.cloudaccountLink = "";
      self.pubAllow.clear();
      self.pubDeny.clear();
      self.subAllow.clear();
      self.subDeny.clear();
      self.resp = undefined;
      self.subs = undefined;
      self.data = undefined;
      self.payload = undefined;
    },
    apply(obj: NGSIdentityDataSnapshot) {
      self.isActive = true;
      self.cloudaccountLink = obj.cloudAccountLink;

      self.pubAllow.clear();
      if (obj.pub !== undefined && obj.pub.allow !== undefined && obj.pub.allow.length > 0) {
        for (let pubAllow of obj.pub.allow) {
          self.pubAllow.push(pubAllow);
        }
      }
      self.pubDeny.clear();
      if (obj.pub !== undefined && obj.pub.deny !== undefined && obj.pub.deny.length > 0) {
        for (let pubDeny of obj.pub.deny) {
          self.pubDeny.push(pubDeny);
        }
      }
      self.subAllow.clear();
      if (obj.sub !== undefined && obj.sub.allow !== undefined && obj.sub.allow.length > 0) {
        for (let subAllow of obj.sub.allow) {
          self.subAllow.push(subAllow);
        }
      }
      self.subDeny.clear();
      if (obj.sub !== undefined && obj.sub.deny !== undefined && obj.sub.deny.length > 0) {
        for (let subDeny of obj.sub.deny) {
          self.subDeny.push(subDeny);
        }
      }

      self.resp = undefined;
      if (obj.resp && obj.resp.max !== undefined) {
        if (!self.resp) {
          self.resp = { max: undefined, ttl: undefined };
        }
        self.resp.max = obj.resp.max;
      }
      if (obj.resp && obj.resp.ttl !== undefined) {
        if (!self.resp) {
          self.resp = { max: undefined, ttl: undefined };
        }
        self.resp.ttl = obj.resp.ttl;
      }

      self.subs = undefined;
      if (obj.subs !== undefined) {
        self.subs = obj.subs;
      }
      self.data = undefined;
      if (obj.data !== undefined) {
        self.data = obj.data;
      }
      self.payload = undefined;
      if (obj.payload !== undefined) {
        self.payload = obj.payload;
      }
    },
  }))
  .views((self) => ({
    get asObject(): NGSIdentityDataSnapshot {
      const res: NGSIdentityDataSnapshot = {
        cloudAccountLink: self.cloudaccountLink,
      } as any;
      if (self.pubAllow.length > 0) {
        if (!res.pub) {
          res.pub = {};
        }
        res.pub.allow = self.pubAllow;
      }
      if (self.pubDeny.length > 0) {
        if (!res.pub) {
          res.pub = {};
        }
        res.pub.deny = self.pubDeny;
      }
      if (self.subAllow.length > 0) {
        if (!res.sub) {
          res.sub = {};
        }
        res.sub.allow = self.subAllow;
      }
      if (self.subDeny.length > 0) {
        if (!res.sub) {
          res.sub = {};
        }
        res.sub.deny = self.subDeny;
      }

      if (self.resp !== undefined && self.resp.max) {
        if (!res.resp) {
          res.resp = {};
        }
        res.resp.max = self.resp.max;
      }
      if (self.resp !== undefined && self.resp.ttl) {
        if (!res.resp) {
          res.resp = {};
        }
        res.resp.ttl = self.resp.ttl;
      }
      if (self.subs !== undefined) {
        res.subs = self.subs;
      }
      if (self.data !== undefined) {
        res.data = self.data;
      }
      if (self.payload !== undefined) {
        res.payload = self.payload;
      }
      return res;
    },
  }));
