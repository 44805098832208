import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderDigitalOcean } from "./digitalocean";
import { ProviderDigitalOceanNodePools } from "./nodePools";
import { ProviderDigitalOceanNodePoolAdd } from "./nodePoolAdd";
import { ProviderDigitalOceanNodePool } from "./nodePool";
import { ProviderDigitalOceanAdvanced } from "./advanced";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderDigitalOceanRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderDigitalOcean mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderDigitalOceanNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-node-pools/-add`}
        element={<ProviderDigitalOceanNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-node-pools/:index`} element={<ProviderDigitalOceanNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<ProviderDigitalOceanAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderDigitalOceanRoute = observer(ProviderDigitalOceanRouteRaw);
