import { types, Instance, flow, applySnapshot } from "mobx-state-tree";
import { BaseModel } from "../base";
import { automatedRequest, request } from "../../services/cpln";
import { AGENT_CHECKING, agentHelpers } from "./agent.helpers";

export const AgentInfoModel = types
  .model("Agent Info", {
    lastActive: types.optional(types.string, AGENT_CHECKING),
    env: types.optional(types.frozen({}), () => ({})),
    peerCount: types.optional(types.number, 0),
    serviceCount: types.optional(types.number, 0),
  })
  .actions((self) => ({
    resetLastActive() {
      self.lastActive = "";
    },
  }));

export const AgentModel = types
  .compose(
    "Agent",
    BaseModel,
    types.model({
      status: types.frozen({}),
      info: types.optional(AgentInfoModel, () => AgentInfoModel.create()),
      isInfoLoading: types.optional(types.boolean, true),
    })
  )
  .views((self) => ({
    get envText() {
      return agentHelpers.getEnvText(self as any, self.info);
    },
    get lastActiveText() {
      return agentHelpers.getLastActiveText(self as any, self.info);
    },
    get lastActiveTextTable() {
      return agentHelpers.getLastActiveTextTable(self as any, self.info);
    },
  }))
  .views((self) => ({
    get isActive() {
      return agentHelpers.getIsActive(self as any, self.info);
    },
  }))
  .actions((self) => {
    const fetchInfo: (automated: boolean) => Promise<boolean> = flow(function* (automated: boolean) {
      try {
        self.isInfoLoading = true;
        const url = self.selfLink + "/-info";
        const requestToAwait = automated ? automatedRequest({ url }) : request({ url });
        const { data } = yield requestToAwait;
        self.info.resetLastActive();
        if (Object.keys(data).length > 0) {
          applySnapshot(self.info, data);
        }
        self.isInfoLoading = false;
        return true;
      } catch (e) {
        self.isInfoLoading = false;
        if (e.response?.status === 404) {
          return false;
        }
        return true;
      }
    });
    return { fetchInfo };
  })
  .actions((self) => {
    const afterCreate = flow(function* () {
      self.fetchInfo(false);
    });
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
      self.fetchInfo(false);
    });
    return { afterCreate, fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface AgentMobx extends Instance<typeof AgentModel> {}
