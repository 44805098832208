import clsx from "clsx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { ArrowLeft, ArrowRight, CheckCircle, Circle } from "react-feather";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuerycontext } from "../../../../components/detail/mediaQueryContext";
import { PromptContext } from "../../../../mobxStores/prompt/prompt";
import { NGButton } from "../../../../newcomponents/button/Button";
import { BasePathContext } from "../../../../reactContexts/basePathContext";
import { useCleanPrompt } from "../../../../reactHooks/useCleanPrompt";
import { useSetPromptShouldBlock } from "../../../../reactHooks/useSetPromptShouldBlock";
import { CreateFormLink } from "../../../org/create/create";

interface Props extends React.PropsWithChildren {
  links: (CreateFormLink & { isCompleted: boolean })[];
  title: string;
  isLoading: boolean;
  onCancel?: () => void;
  next: {
    isActive: boolean;
    label: string;
    url: string;
  };
  create: {
    isActive: boolean;
    label?: string;
    onConfirm: () => void;
  };
}
const CreateAccountLayoutRaw: React.FC<Props> = ({ title, links, isLoading, children, onCancel, next, create }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const basePath = React.useContext(BasePathContext);
  function localOnCancel() {
    if (onCancel) {
      onCancel();
    }
    PromptContext.setIsDisabled(true);
    if (basePath.includes("/billing/")) {
      navigate("/billing");
    } else {
      navigate(basePath.split("/-create")[0]);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    let res = true;
    const allowedList = ["-accountinfo", "-address", "-paymentmethod", "-orggvc"];
    for (let allowItem of allowedList) {
      if (pathname.includes(allowItem)) res = false;
    }
    return res;
  }

  React.useEffect(() => {
    PromptContext.setWhen(true);
  }, []);

  useCleanPrompt();
  useSetPromptShouldBlock(shouldBlockNavigation);

  return (
    <>
      <div data-testid="create-form" className={`create-layout relative account-create`}>
        <div className="flex n-detail-body">
          <div className="n-detail-nav-parent create-nav-parent mr-4" style={{ width: 200 }}>
            {links.map((link, index) => (
              <div className={clsx("rounded")}>
                <div className="relative h-8 flex rounded items-center mb-1">
                  <NavLink
                    key={link.value}
                    data-testid={`create-${link.name.toLowerCase()}`}
                    className={({ isActive, isPending }) =>
                      clsx("ngnavitem rounded absolute inset-0 flex items-center gap-2 focus pl-2", {
                        disabled: !link.isActive,
                        completed: link.isCompleted,
                        inprogress: isActive || location.pathname.includes(link.value),
                        active: isActive || location.pathname.includes(link.value),
                        enabled: link.isActive || location.pathname.includes(link.value),
                      })
                    }
                    onClick={(e) => {
                      if (!link.isActive) {
                        e.preventDefault();
                        return;
                      }
                    }}
                    to={link.isActive ? `${basePath}/${link.value}` : ""}
                    end
                  >
                    {link.isCompleted === undefined ? null : link.isCompleted ? <CheckCircle /> : <Circle />}
                    <span className="truncate">
                      {Number(index) + 1}. {link.name}
                    </span>
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
          <div className="n-detail-content">
            <div className="create-form">
              <div className="create-form-content">
                <div style={{ minHeight: 48 }} className="create-form-header">
                  {title}
                </div>
                {children}
              </div>
              <div className="create-layout-buttons flex items-center mt-8 gap-3" style={{ width: 450 }}>
                {pathname.endsWith("-accountinfo") ? (
                  <NGButton
                    variant={"danger"}
                    style={{ width: 264 }}
                    size={"toRemoveLarge"}
                    onClick={localOnCancel}
                    disabled={isLoading}
                    data-testid="form-cancel"
                  >
                    Cancel
                  </NGButton>
                ) : null}
                {pathname.endsWith("-address") ? (
                  <NGButton
                    variant={"secondary"}
                    style={{ width: 264 }}
                    size={"toRemoveLarge"}
                    renderIcon={(_, props) => <ArrowLeft {...props} />}
                    onClick={() => navigate(pathname.replace("-address", "-accountinfo"))}
                    iconPosition="left"
                    disabled={isLoading}
                    data-testid="form-next"
                  >
                    Back (Account Info)
                  </NGButton>
                ) : null}
                {location.pathname.includes("-accountinfo") ? (
                  <NGButton
                    variant={"primary"}
                    style={{ width: 264 }}
                    size={"toRemoveLarge"}
                    renderIcon={(_, props) => <ArrowRight {...props} />}
                    onClick={() => navigate(`${basePath}/${next.url}`)}
                    loading={isLoading}
                    disabled={isLoading || !next.isActive}
                    data-testid="form-next"
                  >
                    {next.label}
                  </NGButton>
                ) : (
                  <NGButton
                    variant={"action"}
                    style={{ width: 264 }}
                    size={"toRemoveLarge"}
                    onClick={() => create.onConfirm()}
                    loading={isLoading}
                    disabled={isLoading || !create.isActive}
                  >
                    {location.pathname.includes("-paymentmethod")
                      ? "Add & Continue"
                      : location.pathname.includes("-address")
                      ? "Create & Continue"
                      : "Create & Finish"}
                  </NGButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const CreateAccountLayout = observer(CreateAccountLayoutRaw);
