import { Location } from "../../../../schema/types/location";
import { TableColumn } from "../../types";

const enabledDefault = { id: "enabled", label: "Enabled" };
export function LocationEnabledColumn<TData extends Location>(column: TableColumn<TData> = enabledDefault) {
  const preset: TableColumn<TData> = {
    ...enabledDefault,
    size: 130,
    enableHide: true,
    cell: (p) => {
      const location = p.row.original;
      const hasEnabled = location.spec?.enabled !== undefined;
      const enabled = location.spec?.enabled === true;
      if (!hasEnabled) {
        return <span />;
      }
      return <span className={`${enabled ? "color-action" : "color-danger"}`}>{enabled ? "True" : "False"}</span>;
    },
  };
  return Object.assign(preset, column);
}
