import { Value } from "../../../../components/tag/value";
import { Workload } from "../../../../schema/types/workload/workload";
import { TableColumn } from "../../types";

const containersDefault = { id: "containers", label: "Container/Image" };
export function WorkloadContainersColumn<TData extends Workload>(column: TableColumn<TData> = containersDefault) {
  const preset: TableColumn<TData> = {
    ...containersDefault,
    enableHide: true,
    size: 170,
    hiddenByDefault: true,
    enableResize: true,
    cell: (p) => {
      const workload = p.row.original;
      return (
        <div className="flex gap-1">
          {workload.spec?.containers.map((c) => (
            <Value style={{ minWidth: "fit-content" }} value={`${c.name}=${c.image}`} />
          ))}
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
