import * as React from "react";
import { ExternalLink as ExternalLinkIcon } from "react-feather";

export interface ExternalLinkButtonProps {
  onClick: () => void;
  label: string;
}
export const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({ onClick, label }) => {
  return (
    <div className={`flex items-center`}>
      <button className="truncate color-link ngfocus" onClick={onClick}>
        <span>{label}</span>
        <ExternalLinkIcon className={`inline-block feather-icon ml-1`} style={{ transform: "translateY(2px)" }} />
      </button>
    </div>
  );
};
