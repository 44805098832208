import * as React from "react";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

interface WorkloadNavLinkProps {
  isActive: boolean;
  url: string;
  number: number;
  title: string;
}

const WorkloadNavLinkRaw: React.FC<WorkloadNavLinkProps> = ({ isActive, url, number, title }) => {
  if (isActive) {
    return (
      <NavLink
        to={url}
        className={({ isActive }) =>
          clsx("create-link create-link__inactive focus", { "create-link__active": !!isActive })
        }
      >
        <div className="number">
          <span>{number}</span>
        </div>
        <div className="content">{title}</div>
      </NavLink>
    );
  }

  return (
    <span className={`create-link create-link__inactive create-link__disabled`}>
      <div className="number">
        <span>{number}</span>
      </div>
      <div className="content">{title}</div>
    </span>
  );
};

export const WorkloadNavLink = observer(WorkloadNavLinkRaw);
