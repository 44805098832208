import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { CreateLayout } from "../../../components/create/layout";
import { CreateFormLink } from "../../org/create/create";
import { NameModel } from "../../../mobxDataModels/nameModel";
import { LocationCreateGeneral } from "./general";
import { request } from "../../../services/cpln";
import { TagsNewModel } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";

const LocationCreateRouteRaw: React.FC = () => {
  const basePath = useBasePath("/location/-create/*");

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);

  const formDataRef = React.useRef(new NGFormData());
  const nameRef = React.useRef(NameModel.create());
  const tagsRef = React.useRef(TagsNewModel.create());

  React.useEffect(() => {
    const startingPage = pathname.split("/-create")[0] + "/-create/-general";
    navigate(startingPage, { replace: true });
  }, []);

  function getLocationObject() {
    const name = nameRef.current.value;
    const tags = tagsRef.current.asObject;
    return { kind: "location", name, provider: "byok", tags };
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({
        url: `/org/${ConsoleContext.org}/location`,
        method: "post",
        body: getLocationObject(),
      });
      notification.success({
        message: "Success",
        description: "Created Location",
      });
      setIsLoading(false);
      PromptContext.setIsDisabled(true);
      navigate(`/console/org/${ConsoleContext.org}/location/${nameRef.current.value}/-info`);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.error({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/-create");
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    {
      name: "Tags",
      value: `-tags`,
      isActive: nameRef.current.isValid,
    },
  ].filter(Boolean) as any;

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("-general")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  let canCreate = nameRef.current.isValid && tagsRef.current.isValid;

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={getLocationObject}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Location"}
          name={nameRef.current.value}
          canCreate={canCreate}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route path={`-general`} element={<LocationCreateGeneral name={nameRef.current} />} />
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const LocationCreateRoute = observer(LocationCreateRouteRaw);
