import * as React from "react";
import { Command } from "../../mst/kinds/command";

interface CommandsContextType {
  commands: Command[];
  fetchCommands: () => Promise<void>;
}

export const CommandsContext = React.createContext<CommandsContextType>(null as any);

export const useCommandsContext = () => {
  return React.useContext(CommandsContext);
};
