import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftFirewallItemMobx } from "../../mst/stores/mk8s.draft.firewall.item";
import { NGInput } from "../../newcomponents/input/input";
import { NGButton } from "../../newcomponents/button/Button";

interface FirewallItemProps {
  item: Mk8sDraftFirewallItemMobx;
  onRemove: any;
}
const FirewallItemRaw: React.FC<FirewallItemProps> = ({ item, onRemove }) => {
  const longText = item.sourceCIDR.value.length > 30;
  return (
    <>
      <div className={`relative flex items-center gap-1`}>
        <NGInput
          size={"small"}
          style={{
            width: "calc((100% - 92px) / 2)",
          }}
          placeholder={"Source CIDR (IPv4, IPv6)"}
          value={item.sourceCIDR.value}
          onChange={(e) => item.sourceCIDR.setValue(e.target.value)}
          showClear={false}
        />
        <NGInput
          size={"small"}
          style={{
            width: "calc((100% - 92px) / 2)",
          }}
          placeholder={"Description (Optional)"}
          value={item.description.value}
          onChange={(e) => item.description.setValue(e.target.value)}
        />
        <NGButton size={"small"} onClick={onRemove} variant={"danger"} text>
          Remove
        </NGButton>
      </div>
    </>
  );
};

export const FirewallItem = observer(FirewallItemRaw);
