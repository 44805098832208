import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { SecretMobx } from "../../mst/kinds/secret";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { DetailNavLink } from "../../components/detail/nav";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { Edit } from "./edit";
import { ViewModal } from "../../components/modals/viewModal";
import { notification } from "antd";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  secret: SecretMobx;
}
const SecretDetailRaw: React.FC<Props> = ({ secret }) => {
  const basePath = useBasePath("/secret/:secret/*");

  const eventlogHook = useEventlogs(secret.selfLink);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [revealedSecret, setRevealedSecret] = React.useState<any>(null as any);

  async function onReveal() {
    try {
      const res = await secret.reveal();
      setRevealedSecret(res as any);
      setIsModalVisible(true);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;

      if (e?.response?.status == 403) {
        errorMessage = "You don't have permissions to Reveal this secret.";
      }

      notification.warning({
        message: "Failed to reveal",
        description: errorMessage,
      });
    }
  }

  const [links] = React.useState<DetailNavLink[]>([
    { label: "Info", url: "-info" },
    { label: "Reveal & Edit", url: "-edit" },
    {
      label: "Reveal & View",
      url: "ignore",
      icon: { isActive: true, color: " ", class: "eye" },
      onClick: onReveal,
    },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
  ]);

  return (
    <>
      <BasePathContext.Provider value={basePath}>
        <DetailLayout links={links}>
          <Route path={`-info`} element={<Info secret={secret} />} />
          <Route path={`-edit`} element={<Edit secret={secret} />} />
          <Route path={`-tags`} element={<Tags link={secret.selfLink} tags={secret.tags} />} />
          <Route
            path={`-events`}
            element={
              <Events
                kind={"secret"}
                eventlogs={eventlogHook.eventlogs}
                isLoading={eventlogHook.isLoading}
                fetchEventlogs={eventlogHook.fetchEventlogs}
              />
            }
          />
          <Route path={`-audittrail`} element={<AuditTrail kind="secret" resourceId={secret.id} />} />
          <Route path={`-accessreport`} element={<AccessReport itemLink={secret.selfLink} />} />
        </DetailLayout>
      </BasePathContext.Provider>
      {isModalVisible && revealedSecret && (
        <ViewModal
          kind={"secret"}
          filename={revealedSecret.name}
          title={`Revealed Secret - ${revealedSecret.name}`}
          object={revealedSecret}
          onClose={() => setIsModalVisible(false)}
          visible={isModalVisible}
        />
      )}
    </>
  );
};

export const SecretDetail = observer(SecretDetailRaw);
