import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { DomainList } from "./list";
import { DomainDetailRoute } from "./detailRoute";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { DomainCreateNewRoute } from "./create_new/create_new_route";

const DomainRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<DomainList />} />
        <Route path={`-create/*`} element={<DomainCreateNewRoute />} />
        <Route path={`:domain/*`} element={<DomainDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(DomainRoute);
