import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { CloudaccountMobx, CloudaccountModel } from "../../mst/kinds/cloudaccount";
import { CloudaccountDetail } from "./detail";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { captureExc } from "../../errorBoundary";

type RouteParams = "cloudaccount";
const CloudaccountDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { cloudaccount: cloudaccountName } = useParams<RouteParams>();
  const [cloudaccount, setCloudaccount] = React.useState<CloudaccountMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [cloudaccountName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("cloudaccount", cloudaccountName) });
      setCloudaccount(CloudaccountModel.create(data));
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching cloudaccount to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"cloudaccount"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {cloudaccount.name} - Cloud Account - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: cloudaccount, fetchItem }}>
        <NGFormContext.Provider value={formDataRef.current}>
          <CloudaccountDetail cloudaccount={cloudaccount} />
        </NGFormContext.Provider>
      </DetailContext.Provider>
    </>
  );
};

export const CloudaccountDetailRoute = observer(CloudaccountDetailRouteRaw);
