import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGLabel } from "../../../../newcomponents/text/label";
import { IS_DEPLOYMENT_ENV_PROD } from "../../../../envVariables";
import { DetailedRadioGroup } from "../../../../newcomponents/detailedRadio/detailedRadioGroup";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderSelectRaw: React.FC<Props> = ({ mk8sDraft }) => {
  React.useEffect(() => {
    mk8sDraft.addon_awsEcr.setRoleArnRequired(mk8sDraft.provider.value !== "aws");
    mk8sDraft.addon_awsElb.setRoleArnRequired(mk8sDraft.provider.value !== "aws");
  }, [mk8sDraft.provider.value]);

  // test - staging
  let options = [
    { title: "AWS", value: "aws", description: "" },
    { title: "Digital Ocean", value: "digitalocean", description: "" },
    { title: "Ephemeral", value: "ephemeral", description: "" },
    { title: "Generic", value: "generic", description: "" },
    { title: "Hetzner", value: "hetzner", description: "" },
    { title: "Lambda Labs", value: "lambdalabs", description: "" },
    { title: "Linode", value: "linode", description: "" },
    { title: "Oblivus", value: "oblivus", description: "" },
    { title: "Paperspace", value: "paperspace", description: "" },
    { title: "Triton", value: "triton", description: "" },
    //
    { title: "Alibaba Cloud", value: "alibaba", description: "", disabled: true },
    { title: "Azure", value: "azure", description: "", disabled: true },
    { title: "GCP", value: "gcp", description: "", disabled: true },
    { title: "Oracle", value: "oracle", description: "", disabled: true },
  ];

  if (IS_DEPLOYMENT_ENV_PROD) {
    options = [
      { title: "AWS", value: "aws", description: "" },
      { title: "Digital Ocean", value: "digitalocean", description: "" },
      { title: "Generic", value: "generic", description: "" },
      { title: "Hetzner", value: "hetzner", description: "" },
      { title: "Lambda Labs", value: "lambdalabs", description: "" },
      { title: "Linode", value: "linode", description: "" },
      { title: "Oblivus", value: "oblivus", description: "" },
      { title: "Paperspace", value: "paperspace", description: "" },
      { title: "Triton", value: "triton", description: "" },
      //
      { title: "Alibaba Cloud", value: "alibaba", description: "", disabled: true },
      { title: "Azure", value: "azure", description: "", disabled: true },
      { title: "Ephemeral", value: "ephemeral", description: "", disabled: true },
      { title: "GCP", value: "gcp", description: "", disabled: true },
      { title: "Oracle", value: "oracle", description: "", disabled: true },
    ];
  }

  /**
   * aws
   * ephemeral
   * generic
   * hetzner
   * lambda labs
   * linode
   * oblivus
   * paperspace
   * triton
   * -----
   * alibaba cloud
   * azure
   * gcp
   * oracle
   */

  return (
    <div>
      <NGLabel className="mb-2">Infrastructure Provider</NGLabel>
      <DetailedRadioGroup
        className="mb-4"
        groupName="createMk8sProvider"
        selectedValue={mk8sDraft.provider.value}
        options={options}
        onChange={mk8sDraft.provider.setValue}
      />
    </div>
  );
};

export const Mk8sCreateProviderSelect = observer(Mk8sCreateProviderSelectRaw);
