import React from "react";
import { observer } from "mobx-react-lite";
import { Terminal } from "xterm";
import { useDebounce } from "../../components/table/useDebounce";
import { Helmet } from "react-helmet";
import { Loader } from "react-feather";
import { Buffer } from "buffer";
import { NGButton } from "../../newcomponents/button/Button";
import { request } from "../../services/cpln";

interface DetachedConnectData {
  org: string;
  gvc: string;
  replica: string;
  remoteHost: string;
  token: string;
  command: string;
  container: string;
  location: string;
  workload: string;
}

interface SocketMessage {
  type: string;
  buffer: number[];
  width: number;
  height: number;
}

const DetachedConnectRaw: React.FC = () => {
  const [data, setData] = React.useState<DetachedConnectData | null>(null);
  const [counter, _setCounter] = React.useState(0);
  const [disconnected, setDisconnected] = React.useState(false);
  const [isReplicaChanged, setIsReplicaChanged] = React.useState(false);
  const [isReconnecting, setIsReconnecting] = React.useState(false);
  const [isConnecting, setIsConnecting] = React.useState(false);
  const socketRef = React.useRef<WebSocket | null>(null);
  const [debouncedCounter, setDebouncedCounter] = useDebounce("1", 250);
  const terminalRef = React.useRef<Terminal>(getTerminal());
  const [timer, setTimer] = React.useState(0);

  React.useEffect(() => {
    let hash = window.location.hash;
    window.location.hash = "done";
    hash = hash.slice(1);

    const dataFromHash: any = {};

    for (let part of hash.split("&")) {
      const key = part.split("=")[0];
      const value = part.split("=")[1];
      dataFromHash[key] = value;
    }

    setData(dataFromHash);

    const resizeObserver = new ResizeObserver(() => {
      incrementDebouncedCounter();
    });

    const terminalParent = document.getElementById("terminal-parent");
    if (terminalParent) {
      resizeObserver.observe(terminalParent);
    }

    return () => {
      if (terminalParent) {
        resizeObserver.unobserve(terminalParent);
      }
    };
  }, []);

  React.useEffect(() => {
    if (data !== null) {
      onConnect();
    }
  }, [data]);

  React.useEffect(() => {
    fit();
  }, [debouncedCounter]);

  React.useEffect(() => {
    if (isReplicaChanged === false) {
      return;
    }
    setTimer(5);
    setTimeout(() => {
      setTimer(4);
    }, 1000);
    setTimeout(() => {
      setTimer(3);
    }, 2000);
    setTimeout(() => {
      setTimer(2);
    }, 3000);
    setTimeout(() => {
      setTimer(1);
    }, 4000);
    setTimeout(() => {
      window.close();
    }, 5000);
  }, [isReplicaChanged]);

  function incrementDebouncedCounter() {
    setDebouncedCounter((_d) => String(Number(_d) + 1));
  }

  function setCounter() {
    _setCounter((x) => x + 1);
  }

  function getTerminal(): Terminal {
    return new Terminal({ cursorBlink: true, cursorStyle: "underline", cursorWidth: 5 });
  }

  // Socket and Terminal Handling Functions

  function setSocketListeners() {
    if (!socketRef.current) {
      return;
    }

    socketRef.current.onopen = async () => {
      // Generate new terminal instance
      terminalRef.current = getTerminal();
      setCounter();

      const dimensions = getDimensions();

      // Initiate connection
      socketRef.current!.send(
        JSON.stringify({
          token: data!.token,
          org: data!.org,
          gvc: data!.gvc,
          pod: data!.replica,
          container: data!.container,
          shell: data!.command,
          width: dimensions.cols,
          height: dimensions.rows,
          requestVersion: "v1",
        })
      );

      // Mount xTerm
      terminalRef.current.open(document.getElementById("terminal")!);
      setIsConnecting(true);
      setTimeout(() => {
        terminalRef.current.focus();
      }, 100);
      incrementDebouncedCounter();

      // Feed input from xTerm to socket
      terminalRef.current.onData((val) => {
        if (socketRef.current) {
          const message: SocketMessage = {
            type: "data",
            buffer: Array.from(Buffer.from(val)),
            width: terminalRef.current.cols,
            height: terminalRef.current.rows,
          };

          socketRef.current.send(JSON.stringify(message));
        }
      });
    };

    socketRef.current.onmessage = async (event) => {
      setIsConnecting(false);

      try {
        let msg = event.data;

        // Feed socket response to xTerm
        const arrayBuffer = await (msg as Blob).arrayBuffer();
        var string = new TextDecoder().decode(arrayBuffer);
        if (terminalRef.current) {
          terminalRef.current.write(string);
        }
      } catch (e) {
        console.error("onmessage error", e.message);
      }
    };

    socketRef.current.onclose = () => {
      setIsConnecting(false);
      socketOnClose(false);
    };

    socketRef.current.onerror = () => {
      setIsConnecting(false);
      socketOnClose(false);
    };
  }

  function clearSocketListeners() {
    if (!socketRef.current) {
      return;
    }

    socketRef.current.onopen = () => {};
    socketRef.current.onmessage = () => {};
    socketRef.current.onclose = () => {};
    socketRef.current.onerror = () => {};
  }

  function socketOnClose(forced: boolean) {
    if (forced) {
      if (terminalRef.current) {
        terminalRef.current.dispose();
      }
      terminalRef.current = null as any;
      const terminalElement = document.getElementById("terminal");
      if (terminalElement) {
        const child = terminalElement.firstChild;
        if (child) {
          terminalElement.removeChild(child);
        }
      }
    }
    clearSocketListeners();
    socketRef.current = null;
    setCounter();
    setDisconnected(!forced);
  }

  async function fetchReplicas(): Promise<string[]> {
    try {
      const url = `${data!.remoteHost}/replicas/org/${data!.org}/gvc/${data!.gvc}/workload/${data!.workload}`;
      const res = await request({ service: "self", url: url });
      return res.data.items;
    } catch (e) {
      return [];
    }
  }

  async function onConnect() {
    setDisconnected(false);
    socketOnClose(true);
    const client = new WebSocket(`${data!.remoteHost.replace("https://", "wss://")}/remote`);
    socketRef.current = client;
    setSocketListeners();
  }

  async function onReconnect() {
    try {
      setIsReconnecting(true);
      const replicas = await fetchReplicas();
      if (!replicas.includes(data!.replica)) {
        setIsReplicaChanged(true);
      } else {
        onConnect();
      }
      setIsReconnecting(false);
    } catch (e) {
      setIsReconnecting(false);
    }
  }

  function fit() {
    if (!terminalRef.current) {
      return;
    }
    const dimensions = getDimensions();

    if (terminalRef.current.rows !== dimensions.rows || terminalRef.current.cols !== dimensions.cols) {
      terminalRef.current.resize(dimensions.cols, dimensions.rows);

      if (socketRef.current) {
        const message: SocketMessage = {
          type: "resize",
          buffer: [],
          width: terminalRef.current.cols,
          height: terminalRef.current.rows,
        };

        socketRef.current.send(JSON.stringify(message));
      }
    }
  }

  function getDimensions() {
    const terminal = terminalRef.current;
    const currentDimensions = { cols: terminal.cols, rows: terminal.rows };

    if (!terminal.element || !terminal.element.parentElement) {
      return currentDimensions;
    }

    const core = (terminal as any)._core;

    if (core._renderService.dimensions.actualCellWidth === 0 || core._renderService.dimensions.actualCellHeight === 0) {
      return currentDimensions;
    }

    const parentElementStyle = window.getComputedStyle(terminal.element.parentElement);
    const parentElementHeight = parseInt(parentElementStyle.getPropertyValue("height"));
    const parentElementWidth = Math.max(0, parseInt(parentElementStyle.getPropertyValue("width")));
    const elementStyle = window.getComputedStyle(terminal.element);
    const elementPadding = {
      top: parseInt(elementStyle.getPropertyValue("padding-top")),
      bottom: parseInt(elementStyle.getPropertyValue("padding-bottom")),
      right: parseInt(elementStyle.getPropertyValue("padding-right")),
      left: parseInt(elementStyle.getPropertyValue("padding-left")),
    };
    const elementPaddingVer = elementPadding.top + elementPadding.bottom;
    const elementPaddingHor = elementPadding.right + elementPadding.left;
    const availableHeight = parentElementHeight - elementPaddingVer;
    const availableWidth = parentElementWidth - elementPaddingHor - core.viewport.scrollBarWidth;
    const geometry = {
      cols: Math.max(2, Math.floor(availableWidth / core._renderService.dimensions.actualCellWidth)),
      rows: Math.max(2, Math.floor(availableHeight / core._renderService.dimensions.actualCellHeight)),
    };
    return geometry;
  }

  const title = `${data?.location} - ${data?.workload} - ${data?.container}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="w-screen h-screen relative">
        <div id="terminal-parent" className={`  w-full h-full`}>
          <div id={`terminal`} className="w-full h-full  " />
        </div>
        <div className="absolute invisible" style={{ left: -999 }}>
          {counter}
        </div>
        {isConnecting ? (
          <div
            className="absolute p-2 rounded    "
            style={{ left: "50%", top: "50%", zIndex: 10, transform: "translateX(-50%) translateY(-50%)" }}
          >
            <Loader className="feather-icon animate-spin" />
          </div>
        ) : null}
        {disconnected ? (
          <div
            className="absolute p-4 rounded     flex flex-col"
            style={{ zIndex: 10, left: "50%", top: "50%", transform: "translateX(-50%) translateY(-50%)" }}
          >
            {isReconnecting ? (
              <span className=" ">Reconnecting...</span>
            ) : isReplicaChanged ? null : (
              <span className=" ">Disconnected</span>
            )}
            {isReplicaChanged ? (
              <span className=" ">Replica is not found. Closing this window in {timer} seconds.</span>
            ) : null}
            {isReconnecting ? null : isReplicaChanged ? null : (
              <NGButton className="mt-2 mx-auto" size={"small"} variant={"primary"} onClick={onReconnect}>
                Reconnect
              </NGButton>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export const DetachedConnect = observer(DetachedConnectRaw);
