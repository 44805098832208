import { MoreVertical } from "react-feather";

export function ColumnResizeHandle() {
  return (
    <div
      className="relative cursor-col-resize"
      style={{
        height: 30,
        right: 0,
        borderRadius: 3,
      }}
    >
      <MoreVertical className="absolute left-0" style={{ left: -1, top: -1, width: 18 }} />
      <MoreVertical className="absolute" style={{ left: -7, top: -1, width: 18 }} />
    </div>
  );
}
