import { types, Instance, getParent } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { Mk8sDraftProviderTritonLoadBalancerManualModel } from "./mk8s.draft.provider.triton.loadBalancer.manual";
import { Mk8sDraftMobx } from "./mk8s.draft";

export const Mk8sDraftProviderTritonLoadBalancerModel = types
  .model({
    _method: types.optional(types.enumeration(["gateway", "manual"]), "gateway"),
    method: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Mode",
        initialValue: "gateway",
        options: [
          { label: "Gateway", value: "gateway" },
          { label: "Manual", value: "manual" },
        ],
      }),
    ),
    manual: types.optional(Mk8sDraftProviderTritonLoadBalancerManualModel, () =>
      Mk8sDraftProviderTritonLoadBalancerManualModel.create(),
    ),
  })
  .actions((self) => ({
    reset() {
      self.method.setInitialValue(self._method);
      self.manual.reset();
    },
    confirm() {
      self._method = self.method.value;
      self.method.confirm();
      self.manual.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.method.isDirty) res = true;
      if (self.method.value === "manual") {
        if (self.manual.isDirty) res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (self.method.value === "manual") {
        if (!self.manual.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      const parent: Mk8sDraftMobx = getParent(self, 2);
      const isNew = parent._new;
      let obj: any = {};
      if (self.method.value === "gateway") {
        obj.gateway = {};
        if (!isNew && self._method !== "gateway") {
          obj.manual = null;
        }
      } else {
        obj.manual = self.manual.asObject;
        if (!isNew && self._method !== "manual") {
          obj.gateway = null;
        }
      }
      return obj;
    },
  }));
export interface Mk8sDraftProviderTritonLoadBalancerModelMobx
  extends Instance<typeof Mk8sDraftProviderTritonLoadBalancerModel> {}
