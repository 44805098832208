import { observer } from "mobx-react-lite";

interface Props {
  label: string;
  value: string;
  type?: "text" | "password";
}

const CheckMark = () => (
  <span className="formmark ml-1 text-xs" style={{ color: "transparent", textShadow: "0 0 0 var(--color-action)" }}>
    &#10004;
  </span>
);

const ReadOnlyInputRaw: React.FC<Props> = ({ label, value, type = "text" }) => {
  return (
    <div className="relative w-full">
      <input
        id={label.toLowerCase()}
        type={type}
        disabled={true}
        className={`flex-grow h-12 w-full pt-5 pb-1 pr-4 border rounded focus inline-block cursor-not-allowed`}
        style={{ paddingLeft: 14.5 }}
        value={value}
      />
      <label htmlFor={label.toLowerCase()} className={`flex absolute top-1 left-4 transition-all cursor-not-allowed`}>
        <span className={`font-medium text-xs truncate`}>{label}</span>
        <CheckMark />
      </label>
    </div>
  );
};

export const ReadOnlyInput = observer(ReadOnlyInputRaw);
