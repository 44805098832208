import * as React from "react";
import { Radio } from "antd";
import { observer } from "mobx-react-lite";
import { Tooltip } from "../Tooltip";

interface Props {
  onChange: () => void;
  checked: boolean;
  label: string;
  disabled: boolean;
}
const RadioWithTooltipRaw: React.FC<Props> = ({ onChange, checked, label, disabled }) => {
  return (
    <Radio className="truncate block flex items-center" onChange={onChange} checked={checked} disabled={disabled}>
      <Tooltip title={label} open={label.length >= 57}>
        <span>{label}</span>
      </Tooltip>
    </Radio>
  );
};

export const RadioWithTooltip = observer(RadioWithTooltipRaw);
