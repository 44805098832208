import * as React from "react";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "./envVariables";
import { observer } from "mobx-react-lite";
import { User } from "./mobxStores/user/user";
import { Navigate } from "react-router-dom";
import { SetupParsedLocation } from "./mobxStores/parsedLocation/setupParsedLocation";

interface Props extends React.PropsWithChildren {
  title: string;
  onMount?: () => void;
  onClear?: (onMountRes: any) => void;
}
const PrivateRouteDelayedRaw: React.FC<Props> = ({ children, title = "Control Plane", onMount, onClear }) => {
  const [counter, setCounter] = React.useState(0);
  const isReady = counter >= 3;

  React.useEffect(() => {
    let onMountRes: any = null;
    if (onMount) {
      onMountRes = onMount();
    }
    setCounter(1);

    return () => {
      if (onClear) {
        onClear(onMountRes);
      }
    };
  }, []);

  React.useEffect(() => {
    if (counter === 0) {
      return;
    }
    if (isReady) {
      return;
    }
    setCounter(counter + 1);
  }, [counter]);

  if (!isReady) {
    return null;
  }

  if (!User.isLoggedIn) {
    return <Navigate to={"/signin"} />;
  }

  return (
    <>
      <SetupParsedLocation />
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {title} - From many clouds, one™
        </title>
      </Helmet>
      {children}
    </>
  );
};

export const PrivateRouteDelayed = observer(PrivateRouteDelayedRaw);
