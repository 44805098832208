import { notification } from "antd";
import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { User } from "../../../../mobxStores/user/user";
import { UserData } from "../../../../mobxStores/userData/userData";
import { request } from "../../../../services/cpln";
import { slug } from "github-slugger";
import { randomName } from "../../../../services/utils";
import { Loader } from "../../../../components/layout/loader";
import { STORAGE_KEY_TRIAL_ACTIVE_TAB } from "../../../../envVariables";
import { UIData } from "../../../../mobxStores/uiData/uiData";

interface Props {
  onContactSupport: () => void;
  fullName: string;
}
const AccountTrialCreateRaw: React.FC<Props> = ({ onContactSupport, fullName }) => {
  const isProcessingElsewhere =
    !!localStorage.getItem(STORAGE_KEY_TRIAL_ACTIVE_TAB) &&
    localStorage.getItem(STORAGE_KEY_TRIAL_ACTIVE_TAB) !== UIData.tabId;

  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (isProcessingElsewhere) {
      return;
    }
    TryCreateTrialAccount();
  }, []);

  async function TryCreateTrialAccount() {
    const id = randomName();
    let accountId = "";
    const orgName = `org-${slug(fullName)}-${randomName()}`;
    try {
      setIsLoading(true);

      let hubspotutk = "";
      try {
        let _hubspotutk = document.cookie
          .split(" ")
          .find((c) => c.startsWith("hubspotutk"))!
          .split("=")[1];
        if (_hubspotutk.endsWith(";")) {
          _hubspotutk = _hubspotutk.split(";")[0];
        }
        hubspotutk = _hubspotutk;
      } catch (e) {}

      const body = {
        fullName,
        orgName,
        company: User.email.split("@")[1],
        hubspotToken: hubspotutk,
      };

      // TODO test new billing-ng
      const { data } = await request({ service: "billing-ng", url: "/trial_accounts", method: "post", body });
      accountId = data.id;
    } catch (_e: any) {
      setIsLoading(false);
      const e: AxiosError<any> = _e;
      if (e.response?.status === 400 && e.response?.data?.error?.toLowerCase()?.includes("unable")) {
        onContactSupport();
        return;
      }
      setError(e.message);
      notification.warning({ message: "Failed to complete", description: e.message });
      localStorage.setItem("isProcessingTrialAccount", "false");
      return;
    }

    // Create GVC
    let gvcCreated = false;
    try {
      const locationLinks = [
        //
        `/org/${orgName}/location/aws-us-west-2`,
        `/org/${orgName}/location/aws-us-east-2`,
      ];

      await request({
        service: "api",
        method: "post",
        url: `/org/${orgName}/gvc`,
        body: { name: "default-gvc", spec: { staticPlacement: { locationLinks } } },
      });
      gvcCreated = true;
    } catch (e) {
      console.error(`${id} - Failed to create gvc, error: ${e.message}`);
    }

    try {
      await UserData.requestAccountSummaries();
      await BillingContext.setAccount(accountId);
      await UserData.requestOrgNames();
      await ConsoleContext.setOrg(orgName);

      if (gvcCreated) {
        await UserData.requestGVCNames();
        ConsoleContext.setGVC("default-gvc");
      }
    } catch (e) {}

    setIsLoading(false);

    localStorage.setItem("isProcessingTrialAccount", "false");
    setTimeout(() => {
      navigate(`/`);
    }, 100);
  }

  if (isProcessingElsewhere) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 mb-4" style={{ width: 450 }}>
        <div>Another browser tab is processing your request. Please close this tab.</div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 mb-4" style={{ width: 450 }}>
        <div>Please Wait</div>
        <div>We Are Setting Up Your Account</div>
        <Loader reason="trial auto create" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 mb-4" style={{ width: 450 }}>
        <div>Failed to Complete</div>
        <div>{error}</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-4 mb-4" style={{ width: 450 }}>
      <div>Created Trial Account Successfully</div>
    </div>
  );
};

export const AccountTrialCreate = observer(AccountTrialCreateRaw);
