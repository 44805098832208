import { makeObservable, observable, flow, computed, action } from "mobx";
import { linksOf, request } from "../../services/cpln";
import { ConsoleContext } from "../consoleContext/consoleContext";

export interface AccountSummary {
  fullName?: string;
  accountName?: string;
  company?: string;
  id: string;
}

export interface OrgInvite {
  id: string;
  context?: {
    orgLink?: string;
  };
}

class UserDataMobx {
  hereForTrial: boolean = false;
  canCreateTrialAccount: boolean = false;
  isWorkEmail: boolean = false;
  orgNames: string[] = [];
  isLoadingOrgNames: boolean = false;
  gvcNames: string[] = [];
  isLoadingGVCNames: boolean = false;
  hasWorkload: boolean = true;
  isLoadingHasWorkload: boolean = false;
  accountSummaries: AccountSummary[] = [];
  isLoadingAccountSummaries: boolean = false;
  orgInvites: OrgInvite[] = [];
  isLoadingOrgInvites: boolean = false;

  constructor() {
    makeObservable(this, {
      hereForTrial: observable,
      canCreateTrialAccount: observable,
      isWorkEmail: observable,
      orgNames: observable,
      isLoadingOrgNames: observable,
      gvcNames: observable,
      isLoadingGVCNames: observable,
      hasWorkload: observable,
      isLoadingHasWorkload: observable,
      accountSummaries: observable,
      isLoadingAccountSummaries: observable,
      orgInvites: observable,
      isLoadingOrgInvites: observable,
      setCanCreateTrialAccount: action,
      setIsWorkEmail: action,
      setOrgNames: action,
      setHasWorkload: action,
      requestAccountSummaries: flow,
      requestOrgInvites: flow,
      requestOrgNames: flow,
      requestGVCNames: flow,
      requestHasWorkload: flow,
      hasOrg: computed,
      hasGVC: computed,
      hasAccount: computed,
      hasOrgInvite: computed,
    });
  }

  setHereForTrial(value: boolean) {
    this.hereForTrial = value;
  }

  setCanCreateTrialAccount(value: boolean) {
    this.canCreateTrialAccount = value;
  }

  setIsWorkEmail(value: boolean) {
    this.isWorkEmail = value;
  }

  // Used when impersonating or inspecting
  setOrgNames(org: string) {
    this.orgNames = [org];
  }

  setHasWorkload(value: boolean) {
    this.hasWorkload = value;
  }

  *requestAccountSummaries() {
    try {
      this.isLoadingAccountSummaries = true;
      const newAccounts: AccountSummary[] = [];
      // @ts-ignore yield
      const { data } = yield request({ service: "billing-ng", url: `/account`, timeout: 2000 });
      for (let item of data.items) {
        newAccounts.push({
          accountName: item.accountName,
          fullName: item.fullName,
          id: item.id,
          company: item.extraInfo?.company,
        });
      }
      this.accountSummaries = [];
      for (const account of newAccounts) {
        this.accountSummaries.push(account);
      }
      this.isLoadingAccountSummaries = false;
    } catch (e) {
      this.isLoadingAccountSummaries = false;
      this.accountSummaries = [];
    }
  }

  *requestOrgNames() {
    try {
      this.isLoadingOrgNames = true;
      const newOrgNames: string[] = [];
      let nextLink = `/org`;
      while (nextLink) {
        // @ts-ignore yield
        const { data } = yield request({ url: nextLink });
        for (let item of data.items) {
          newOrgNames.push(item.name);
        }
        nextLink = data.links.find((link: any) => link.rel === "next")?.href;
      }
      this.orgNames = [];
      for (const orgName of newOrgNames) {
        this.orgNames.push(orgName);
      }
      this.isLoadingOrgNames = false;
    } catch (e) {
      this.isLoadingOrgNames = false;
      this.orgNames = [];
    } finally {
    }
  }

  *requestGVCNames() {
    if (!ConsoleContext.hasOrg) {
      this.gvcNames = [];
      return;
    }
    try {
      this.isLoadingGVCNames = true;
      const newGVCNames: string[] = [];
      let nextLink = `/org/${ConsoleContext.org}/gvc`;
      while (nextLink) {
        // @ts-ignore yield
        const { data } = yield request({ url: nextLink });
        for (let item of data.items) {
          newGVCNames.push(item.name);
        }
        nextLink = data.links.find((link: any) => link.rel === "next")?.href;
      }
      this.gvcNames = [];
      for (const gvcName of newGVCNames) {
        this.gvcNames.push(gvcName);
      }
      this.isLoadingGVCNames = false;
    } catch (e) {
      this.isLoadingGVCNames = false;
      this.gvcNames = [];
    }
  }

  *requestHasWorkload() {
    if (!ConsoleContext.hasOrg) {
      this.hasWorkload = true;
      return;
    }
    if (!ConsoleContext.hasGVC) {
      this.hasWorkload = true;
      return;
    }
    try {
      this.isLoadingHasWorkload = true;
      let url = `/org/${ConsoleContext.org}/gvc/${ConsoleContext.gvc}/workload`;
      // @ts-ignore yield
      const { data } = yield request({ url });
      this.hasWorkload = data.items.length > 0;
      this.isLoadingHasWorkload = false;
    } catch (e) {
      this.isLoadingHasWorkload = false;
      this.hasWorkload = true;
    }
  }

  *requestOrgInvites() {
    try {
      this.isLoadingOrgInvites = true;
      this.orgInvites = [];
      let nextLink: string | undefined = "/task/-forme";
      while (nextLink) {
        // @ts-ignore yield
        const { data } = yield request({ url: nextLink });
        for (let invite of data.items) {
          this.orgInvites.push(invite);
        }
        nextLink = linksOf(data).next;
      }
      this.isLoadingOrgInvites = false;
    } catch (e) {
      this.isLoadingOrgInvites = false;
      this.orgInvites = [];
    } finally {
    }
  }

  get hasOrg() {
    return this.orgNames.length > 0;
  }
  get hasGVC() {
    return this.gvcNames.length > 0;
  }
  get hasAccount() {
    return this.accountSummaries.length > 0;
  }
  get hasOrgInvite() {
    return this.orgInvites.length > 0;
  }
}

export const UserData = new UserDataMobx();
