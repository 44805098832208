import { makeAutoObservable } from "mobx";
import { Kind } from "../../mst/base";

class TerraformContextMobx {
  supportedKinds: Kind[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public setSupportedKinds(value: Kind[]) {
    this.supportedKinds = value;
  }
}

export const TerraformContext = new TerraformContextMobx();
