import { TableColumn } from "../../types";
import { ExternalLink, ExternalLinkProps } from "../../components/ExternalLink";

export function ExternalLinkColumn<TData>(column: TableColumn<TData>, options: Partial<ExternalLinkProps> = {}) {
  const preset: Partial<TableColumn<TData>> = {
    cell: ({ getValue }) => {
      const value = getValue();
      const url = options.url || value;
      const label = options.label || value;
      return <ExternalLink url={url} label={label} />;
    },
  };
  return Object.assign(preset, column);
}
