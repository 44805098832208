import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../../services/cpln";
import { DryRunAlert } from "../../../dryRunAlert";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { MoreHorizontal } from "react-feather";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGSelect } from "../../../../../newcomponents/select/ngselect";
import { getDryRunErrorText } from "../../../getDryRunErrorText";

type RouteParams = "index";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderOblivusNodePoolRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [browserKey, setBrowserKey] = React.useState("");
  const formData = useNGFormContext();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const o = mk8sDraft.provider_oblivus;
  const np = o.nodePools.find((np) => np.index === index);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `oblivus.nodePools[${index}].`;

  const labelsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.oblivus.nodePools[${index}].labels`,
    paths: np.labels.items.map((i) => `spec.provider.oblivus.nodePools[${index}].labels.${i.firstValue}`),
  });
  const taintsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.oblivus.nodePools[${index}].taints`,
    paths: [
      ...np.taints.items.map((_, idx) => `spec.provider.oblivus.nodePools[${index}].taints[${idx}]`),
      ...np.taints.items.map((_, idx) => `spec.provider.oblivus.nodePools[${index}].taints[${idx}].key`),
      ...np.taints.items.map((_, idx) => `spec.provider.oblivus.nodePools[${index}].taints[${idx}].value`),
      ...np.taints.items.map((_, idx) => `spec.provider.oblivus.nodePools[${index}].taints[${idx}].effect`),
    ],
  });

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!o.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}flavor`}
            label={np.flavor.label}
            value={np.flavor.value}
            onChange={np.flavor.setValue}
            required={np.flavor.isRequired}
            error={np.flavor.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("flavor");
                  formData.set(`${pre}flavor`, { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}flavor`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.flavor.setValue}
              path={`spec.provider.oblivus.nodePools[${index}].flavor`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}minSize`}
            label={np.minSize.label}
            value={np.minSize.value}
            onChange={np.minSize.setValue}
            required={np.minSize.isRequired}
            error={np.minSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}minSize`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.minSize.setValue}
              path={`spec.provider.oblivus.nodePools[${index}].minSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}maxSize`}
            label={np.maxSize.label}
            value={np.maxSize.value}
            onChange={np.maxSize.setValue}
            required={np.maxSize.isRequired}
            error={np.maxSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}maxSize`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.maxSize.setValue}
              path={`spec.provider.oblivus.nodePools[${index}].maxSize`}
            />
          </div>
        </div>
        <NGInputListMst
          data={np.labels}
          className="mb-8"
          label="Labels"
          firstLabel="Key"
          firstInput={(i) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && labelsDryRun.message.includes(`.${i.firstValue}`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondLabel="Value"
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          invalid={labelsDryRun.severity === "error"}
          error={labelsDryRun.severity === "error" ? labelsDryRun.message : ""}
          warning={labelsDryRun.severity === "error" ? "" : labelsDryRun.message}
        />
        <NGInputListMst
          data={np.taints}
          className="mb-8"
          label="Taints"
          firstLabel="Key"
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && taintsDryRun.message.includes(`[${index}].key`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondLabel="Value"
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }
            if (i.secondValue && taintsDryRun.message.includes(`[${index}].value`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          thirdLabel="Effect"
          thirdInput={(i) => {
            let invalid = false;
            if (i.thirdValue && taintsDryRun.message.includes(`[${index}].effect`)) {
              invalid = true;
            }

            return (
              <NGSelect
                invalid={invalid}
                value={i.thirdValue}
                onChange={(value) => i.setThirdValue(value)}
                options={[
                  { label: "NoSchedule", value: "NoSchedule" },
                  { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                  { label: "NoExecute", value: "NoExecute" },
                ]}
              />
            );
          }}
          invalid={taintsDryRun.severity === "error"}
          error={taintsDryRun.severity === "error" ? taintsDryRun.message : ""}
          warning={taintsDryRun.severity === "error" ? "" : taintsDryRun.message}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            o.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
      {browserKey === "flavor" ? (
        <NGProviderBrowser
          // TODO pagination for all discovery endpoints with pages
          title="Browse Oblivus Flavors"
          initialValue={np.flavor.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "instance-types-flat",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          sortable
          onOk={(value) => np.flavor.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={[
            "Flavor",
            "Cores",
            "Memory",
            "Root Storage",
            "Ephemeral Storage",
            "GPUs",
            "Network",
            "Cost per Month",
          ]}
          rightAlign={["Cost per Month"]}
          distinctFilters={["Cores", "Memory", "Root Storage", "Ephemeral Storage", "GPUs", "Network"]}
          getData={(item: any) => {
            const pricePerDay = Number(item.hourlyCost || 0) * 24;
            const pricePerMonth = pricePerDay * 30;
            const cost = pricePerMonth.toLocaleString("en-US", { style: "currency", currency: "USD" });

            return [
              //
              item.flavor,
              item.flavor,
              item.cores,
              [item.memory, `${item.memory}Gi`],
              [item.rootStorage, `${item.rootStorage}Gi`],
              [item.ephemeralStorage, `${item.ephemeralStorage}Gi`],
              item.gpus,
              [item.network, `${item.network}GbE`],
              [pricePerMonth, cost],
            ];
          }}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderOblivusNodePool = observer(Mk8sCreateProviderOblivusNodePoolRaw);
