import * as React from "react";
import { UserData } from "../../mobxStores/userData/userData";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { Table } from "../../newcomponents/table/table";
import { TableNewItemButton } from "../../newcomponents/table/components/tableNewButton";
import { CustomTableCPLNQuery } from "../../newcomponents/table/components/TableCplnQuery";
import { WorkloadColumnGroup } from "../../newcomponents/table/columnGroups/workload";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { Workload } from "../../schema/types/workload/workload";
import { linksOf } from "../../services/cpln";
import { WorkloadTableItem } from "../../schema/types/workload/workloadTableItem";
import { workloadHelpers } from "../../mst/kinds/workload.helpers";
import { useDeploymentsReducer } from "../../components/detail/deploymentsReducer";
import { DEPLOYMENT_UPDATE_INTERVAL_MS } from "../../envVariables";
import { TableActionsDropdown } from "../../newcomponents/table/components/tableActionsDropdown";
import { Modal, notification } from "antd";
import { NGButton } from "../../newcomponents/button/Button";
import { updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { DeleteModal } from "../../components/modals/deleteModal";
import { NGChip } from "../../newcomponents/chip";

interface WorkloadsStateSummary {
  notReady: number;
  suspended: number;
  ready: number;
}

const actionTextMap = {
  suspend: "suspend",
  unsuspend: "unsuspend",
  "force-redeployment": "force redeploy",
};

export const WorkloadList: React.FC = () => {
  const { state, fetchDeployments } = useDeploymentsReducer();

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedLinks, setSelectedLinks] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<string>("");

  const { kind, id } = useTableKindId("workload", "workload-list-update1");
  const qData = useTableItemQueryData<Workload>(kind, { fetchAllPages: true, skipGvcScope: false });

  React.useEffect(() => {
    if (qData.items.length > 0 && !UserData.hasWorkload) {
      UserData.setHasWorkload(true);
    }
  }, [qData.items.length]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      qData.fetchQueriedItems(true);
      qData.fetchItems(true);
    }, DEPLOYMENT_UPDATE_INTERVAL_MS * 2);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  React.useEffect(() => {
    if (qData.visibleItems.length < 1) {
      return;
    }
    const unsubscribe = fetchDeployments(
      qData.visibleItems.map((i) => linksOf(i).self!),
      DEPLOYMENT_UPDATE_INTERVAL_MS,
    );
    return () => {
      unsubscribe();
    };
  }, [qData.visibleItems.length]);

  function getWorkloadsStateSummary(): WorkloadsStateSummary {
    let notReady = 0;
    let suspended = 0;
    let ready = 0;

    for (const workload of qData.items) {
      const selfLink = linksOf(workload).self!;
      const deployments = state.deploymentsMap[selfLink] || [];

      // Count suspended
      if (workloadHelpers.getSuspendType(workload) == "suspended") {
        suspended++;
        continue;
      }

      // Count ready
      if (workloadHelpers.getHealth(deployments, workload).isReady) {
        ready++;
        continue;
      }

      // Count not ready
      notReady++;
    }

    return { notReady, suspended, ready };
  }

  const { notReady, suspended, ready } = getWorkloadsStateSummary();
  const workloadTableItems: WorkloadTableItem[] = qData.visibleItems.map((workload) => {
    const selfLink = linksOf(workload).self!;
    const deployments = state.deploymentsMap[selfLink] || [];
    const health = workloadHelpers.getHealth(deployments, workload);
    const readyReplicas = workloadHelpers.getReadyReplicasOnly(deployments);
    const totalReplicas = workloadHelpers.getTotalReplicas(deployments);
    const readyLocations = workloadHelpers.getReadyLocationsOnly(deployments);
    const suspended = workloadHelpers.getSuspendType(workload);
    const capacityAi = workloadHelpers.getCapacityAi(workload);
    const metric = workloadHelpers.getMetric(workload);
    const minScale = workloadHelpers.getMinScale(workload);
    const maxScale = workloadHelpers.getMaxScale(workload);
    const gvc = selfLink.split("/")[4];
    const fetchedReadiness = state.requestedLinks.includes(selfLink);

    const res = {
      ...workload,
      deployments: deployments,
      health: health,
      readiness: health.text,
      fetchedReadiness: fetchedReadiness,
      type: workload.spec!.type,
      readyReplicas: readyReplicas,
      totalReplicas: totalReplicas,
      readyLocations: readyLocations,
      totalLocations: deployments.length,
      selfLink: selfLink,
      gvc: gvc,
      suspended: suspended,
      capacityAi: capacityAi,
      metric: metric,
      minScale: minScale,
      maxScale: maxScale,
    };

    return res;
  });

  return (
    <>
      <Table<WorkloadTableItem>
        title={(className) => {
          return (
            <div className="flex gap-2">
              <div className={className}>Workloads</div>
              {state.requestedLinks.length > 0 ? (
                <div className="flex flex-wrap gap-1">
                  <NGChip variant="error" label={`${notReady} Not Ready`} />
                  <NGChip variant="warning" label={`${suspended} Suspended`} />
                  <NGChip variant="success" label={`${ready} Ready`} />
                </div>
              ) : null}
            </div>
          );
        }}
        data={workloadTableItems}
        selectKey="selfLink"
        selectMode="multi"
        selections={selectedLinks}
        onSelectionsChange={setSelectedLinks}
        tableId={id}
        headerRenderer={() => {
          return (
            <>
              <TableNewItemButton kind={kind} />
              <TableActionsDropdown
                kind={kind}
                selectionLinks={selectedLinks}
                options={[
                  {
                    key: "suspend",
                    group: "Suspension",
                    label: "Suspend",
                    disabled: selectedLinks.length < 1,
                    callback: () => setAction("suspend"),
                  },
                  {
                    key: "unsuspend",
                    group: "Suspension",
                    label: "Unsuspend",
                    disabled: selectedLinks.length < 1,
                    callback: () => setAction("unsuspend"),
                  },
                  {
                    key: "force-redeployment",
                    label: "Force Redeployment",
                    disabled: selectedLinks.length < 1,
                    callback: () => setAction("force-redeployment"),
                  },
                  {
                    key: "delete",
                    label: "Delete",
                    disabled: selectedLinks.length < 1,
                    danger: true,
                    callback: () => setAction("delete"),
                  },
                ]}
              />
              <TableHeaderRefreshButton onClick={qData.fetchItems} />
              <CustomTableCPLNQuery
                tableId={id}
                kind={kind}
                onQuery={qData.setQuery}
                isLoading={qData.isLoadingQueried}
              />
            </>
          );
        }}
        enableFilter
        isLoading={qData.isLoading}
        columns={WorkloadColumnGroup}
      />
      {["suspend", "unsuspend"].includes(action as any) ? (
        <Modal
          title={`Are you sure you want to ${actionTextMap[action as any]} selected workloads?`}
          width={620}
          open={true}
          onCancel={() => setAction("")}
          footer={
            <div className="modal-actions">
              <NGButton disabled={isLoading} variant="secondary" onClick={() => setAction("")}>
                Cancel
              </NGButton>
              <NGButton
                disabled={isLoading}
                loading={isLoading}
                variant="primary"
                onClick={async () => {
                  setIsLoading(true);
                  let result = true;
                  if (action === "suspend") {
                    result = await workloadHelpers.handleSuspend(selectedLinks);
                  } else if (action === "unsuspend") {
                    result = await workloadHelpers.handleUnsuspend(selectedLinks);
                  }

                  if (result === true) {
                    notification.success({
                      message: "Success",
                    });
                    setIsLoading(false);
                    setAction("");
                    setSelectedLinks([]);
                  } else {
                    setIsLoading(false);
                    setAction("");
                    notification.warning({
                      message: "Failed",
                      description: result,
                    });
                  }
                }}
              >
                Confirm
              </NGButton>
            </div>
          }
        >
          <div>
            <p>
              Adjusting the suspend status of workloads will automatically update the "suspend" property in both the
              default and local options within the workload specification.
            </p>
          </div>
        </Modal>
      ) : null}
      {action === "force-redeployment" ? (
        <Modal
          title={"Force Redeployment"}
          onCancel={() => setAction("")}
          open={true}
          footer={
            <div className="modal-actions">
              <NGButton disabled={isLoading} variant="secondary" onClick={() => setAction("")}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                loading={isLoading}
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  const result = await workloadHelpers.handleForceRedeployment(selectedLinks);
                  if (result === true) {
                    notification.success({
                      message: "Success",
                      description: "Redeployment initiated",
                    });
                    updateLastDeploymentTimeOnHubspot();
                    setIsLoading(false);
                    setAction("");
                    setSelectedLinks([]);
                  } else {
                    setIsLoading(false);
                    setAction("");
                    notification.warning({
                      message: "Failed",
                      description: result,
                    });
                  }
                }}
              >
                Confirm
              </NGButton>
            </div>
          }
        >
          <div>Are you sure you want to force redeployment for selected workloads?</div>
        </Modal>
      ) : null}
      {action === "delete" ? (
        <DeleteModal
          kind={kind}
          onCancel={() => setAction("")}
          onComplete={() => {
            setAction("");
            setSelectedLinks([]);
            qData.fetchItems();
          }}
          links={selectedLinks}
        />
      ) : null}
    </>
  );
};
