import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Mk8sDraftAutoscalerModel } from "./mk8s.draft.providerAutoscaler";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import {
  Mk8sDraftProviderLinodeNodePoolModel,
  Mk8sDraftProviderLinodeNodePoolReadonlyModel,
} from "./mk8s.draft.provider.linode.nodepool";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { Mk8sDraftNetworkingModel } from "./mk8s.draft.providerNetworking";

export const Mk8sDraftProviderLinodeModel = types
  .model({
    _region: types.optional(types.string, ""),
    region: types.optional(StringModel, () => StringModel.create({ label: "Region", isRequired: true })),
    _tokenSecretLink: types.optional(types.string, ""),
    tokenSecretName: types.optional(types.string, ""),
    _firewallId: types.optional(types.string, ""),
    firewallId: types.optional(StringModel, () => StringModel.create({ label: "Firewall Id" })),
    _image: types.optional(types.string, ""),
    image: types.optional(StringModel, () => StringModel.create({ label: "Image", isRequired: true })),
    _authorizedUsers: types.array(types.string),
    authorizedUsers: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _authorizedKeys: types.array(types.string),
    authorizedKeys: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _vpcId: types.optional(types.string, ""),
    vpcId: types.optional(StringModel, () => StringModel.create({ label: "Vpc Id", isRequired: true })),
    _preInstallScript: types.optional(types.string, ""),
    preInstallScript: types.optional(StringModel, () => StringModel.create({ label: "Pre Install Script" })),
    _nodePools: types.array(Mk8sDraftProviderLinodeNodePoolReadonlyModel),
    nodePools: types.array(Mk8sDraftProviderLinodeNodePoolModel),
    networking: types.optional(Mk8sDraftNetworkingModel, () => Mk8sDraftNetworkingModel.create()),
    autoscaler: types.optional(Mk8sDraftAutoscalerModel, () => Mk8sDraftAutoscalerModel.create()),
    removed: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get _tokenSecretName() {
      const { name: tokenSecretName } = ngParseLink(self._tokenSecretLink, { kind: "secret" });
      return tokenSecretName;
    },
    get tokenSecretLink() {
      const { absolute } = ngParseLink(self.tokenSecretName, { kind: "secret" });
      return absolute;
    },
  }))
  .actions((self) => ({
    reset() {
      self.region.setInitialValue(self._region);
      self.tokenSecretName = self._tokenSecretName;
      self.firewallId.setInitialValue(self._firewallId);
      self.image.setInitialValue(self._image);

      self.authorizedUsers.setInitialItems(self._authorizedUsers.map((v) => ({ firstValue: v })));
      self.authorizedUsers.reset();
      self.authorizedKeys.setInitialItems(self._authorizedKeys.map((v) => ({ firstValue: v })));
      self.authorizedKeys.reset();

      self.vpcId.setInitialValue(self._vpcId);

      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftProviderLinodeNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          }),
        );
      }
      self.removed = false;

      self.networking.reset();
      self.preInstallScript.setInitialValue(self._preInstallScript);
      self.autoscaler.reset();
    },
    confirm() {
      self.region.confirm();
      self._region = self.region.value;
      self._tokenSecretLink = self.tokenSecretLink;
      self.firewallId.confirm();
      self._firewallId = self.firewallId.value;
      self.image.confirm();
      self._image = self.image.value;

      self._authorizedUsers.clear();
      for (const user of self.authorizedUsers.items.map((i) => i.firstValue)) {
        self._authorizedUsers.push(user);
      }
      self.authorizedUsers.confirm();

      self._authorizedKeys.clear();
      for (const key of self.authorizedKeys.items.map((i) => i.firstValue)) {
        self._authorizedKeys.push(key);
      }
      self.authorizedKeys.confirm();

      self.vpcId.confirm();
      self._vpcId = self.vpcId.value;

      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftProviderLinodeNodePoolReadonlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
            serverType: nodePool.serverType.value,
            minSize: String(nodePool.minSize.value).length > 0 ? Number(nodePool.minSize.value) : undefined,
            maxSize: String(nodePool.maxSize.value).length > 0 ? Number(nodePool.maxSize.value) : undefined,
            overrideImage: nodePool.asObject.overrideImage,
            subnetId: nodePool.asObject.subnetId,
          }),
        );
      }
      self.removed = false;

      self.networking.confirm();

      self.preInstallScript.confirm();
      self._preInstallScript = self.preInstallScript.value;

      self.autoscaler.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setTokenSecretName(value: string) {
      self.tokenSecretName = value;
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }
      self.nodePools.push(
        Mk8sDraftProviderLinodeNodePoolModel.create({
          index,
          status: "added",
        }),
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.nodePools.remove(node);
    },
  }))
  .views((self) => ({
    isNodePoolNameValid(index: number) {
      let res = true;
      const node = self.nodePools.find((np) => np.index === index)!;
      const pools = [...self.nodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyNodePoolNameConflicts() {
      for (const nodePool of self.nodePools) {
        if (
          [...self.nodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value,
          )
        ) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._nodePools.length !== self.nodePools.length) res = true;
      if (self.removed) res = true;
      if (self.nodePools.some((n) => n.isDirty)) res = true;
      if (self.region.isDirty) res = true;
      if (self.tokenSecretName !== self._tokenSecretName) res = true;
      if (self.firewallId.isDirty) res = true;
      if (self.image.isDirty) res = true;
      if (self.authorizedUsers.isDirty) res = true;
      if (self.authorizedKeys.isDirty) res = true;
      if (self.vpcId.isDirty) res = true;
      if (self.networking.isDirty) res = true;
      if (self.autoscaler.isDirty) res = true;
      if (self.preInstallScript.isDirty) res = true;
      return res;
    },
    get isValid() {
      let res = true;
      if (self.anyNodePoolNameConflicts) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      if (!self.region.isValid) res = false;
      if (!self.tokenSecretName) res = false;
      if (!self.image.isValid) res = false;
      if (!self.vpcId.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      if (!self.firewallId.isValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.anyNodePoolNameConflicts) reason = "anyNodePoolNameConflicts";
      if (self.nodePools.some((n) => !n.isValid)) reason = "nodePools";
      if (!self.region.isValid) reason = "region";
      if (!self.tokenSecretName) reason = "tokenSecretName";
      if (!self.autoscaler.isValid) reason = "autoscaler";
      if (!self.preInstallScript.isValid) reason = "preInstallScript";
      return reason;
    },
    get ui_isLinodeValid() {
      let res = true;
      if (!self.region.isValid) res = false;
      if (!self.tokenSecretName) res = false;
      if (!self.image.isValid) res = false;
      if (!self.vpcId.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      if (!self.firewallId.isValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      return res;
    },
    get dryRun_linodePathList() {
      return [
        //
        "spec.provider.linode.region",
        "spec.provider.linode.tokenSecretLink",
        "spec.provider.linode.image",
        "spec.provider.linode.vpcId",
        "spec.provider.linode.firewallId",
        "spec.provider.linode.authorizedUsers",
        ...self.authorizedUsers.items.map((_, index) => `spec.provider.linode.authorizedUsers[${index}]`),
      ];
    },
    get ui_isAdvancedValid() {
      let res = true;
      if (!self.preInstallScript.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      return res;
    },
    get dryRun_advancedPathList() {
      return [
        //
        "spec.provider.linode.authorizedKeys",
        ...self.authorizedKeys.items.map((_, index) => `spec.provider.linode.authorizedKeys[${index}]`),
        "spec.provider.linode.preInstallScript",
        ...self.autoscaler.dryRun_pathList("linode"),
        ...self.networking.dryRun_pathList("linode"),
      ];
    },
    get asObject() {
      let obj: any = {
        region: self.region.value,
        tokenSecretLink: self.tokenSecretLink,
        image: self.image.value,
        vpcId: self.vpcId.value,
        autoscaler: self.autoscaler.asObject,
        networking: self.networking.asObject,
        preInstallScript: self.preInstallScript.value,
        nodePools: self.nodePools.map((n) => n.asObject),
      };
      if (self.firewallId.value) {
        obj.firewallId = self.firewallId.value;
      }
      if (self.authorizedUsers.items.length > 0) {
        obj.authorizedUsers = self.authorizedUsers.items.map((i) => i.firstValue);
      }
      if (self.authorizedKeys.items.length > 0) {
        obj.authorizedKeys = self.authorizedKeys.items.map((i) => i.firstValue);
      }
      return obj;
    },
  }));
export interface Mk8sDraftProviderLinodeMobx extends Instance<typeof Mk8sDraftProviderLinodeModel> {}
