import { TableColumn } from "../../types";
import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";
import { ExternalLink } from "../../components/ExternalLink";

const typeDefault = { id: "type", label: "Type" };
export function WorkloadTypeColumn<TData extends WorkloadTableItem>(column: TableColumn<TData> = typeDefault) {
  const preset: TableColumn<TData> = {
    ...typeDefault,
    size: 230,
    canGroupByDistinctValues: true,
    groupLabelMap: {
      serverless: "Serverless",
      standard: "Standard",
      stateful: "Stateful",
      cron: "Cron",
    },
    groupOrder: ["cron", "serverless", "standard", "stateful"],
    cell: (p) => {
      const workload = p.row.original;
      return (
        <div className="flex items-center w-full justify-between">
          <div>
            {workload.type.slice(0, 1).toUpperCase()}
            {workload.type.slice(1)}
          </div>
          {workload.status?.canonicalEndpoint ? (
            <ExternalLink label="Open" url={workload.status.canonicalEndpoint} />
          ) : null}
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
