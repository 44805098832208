import React, { useRef } from "react";
import { AriaTabListOptions, AriaTabPanelProps, useTab, useTabList, useTabPanel } from "react-aria";
import { useTabListState } from "react-stately";
import { TabListState, TabListStateOptions } from "@react-stately/tabs";
import "./index.scss";
import clsx from "clsx";

export const NGTabs = ({
  orientation = "horizontal",
  ...props
}: (TabListStateOptions<object> | AriaTabListOptions<object>) & { orientation?: "vertical" | "horizontal" }) => {
  const state = useTabListState(props);
  const ref = useRef(null);
  const { tabListProps } = useTabList(props, state, ref);
  return (
    <div className={clsx("ngtabs-container", { orientation })}>
      <div className="ngtabs" {...tabListProps} ref={ref}>
        {[...(state.collection as any)].map((item) => (
          <Tab key={item.key} item={item} state={state} />
        ))}
      </div>
      <TabPanel key={state.selectedItem?.key} state={state} />
    </div>
  );
};

const Tab = (props: { item: { key: any; rendered: any }; state: TabListState<unknown> }) => {
  const { key, rendered } = props.item;
  const ref = useRef(null);
  const { tabProps, isSelected, isDisabled } = useTab({ key }, props.state, ref);
  return (
    <div className={clsx("ngtabs-tab", { selected: isSelected, disabled: isDisabled })} {...tabProps} ref={ref}>
      {rendered}
    </div>
  );
};

const TabPanel = ({ state, ...props }: AriaTabPanelProps & { state: any }) => {
  const ref = useRef(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);
  return (
    <div {...tabPanelProps} ref={ref}>
      {state.selectedItem?.props.children}
    </div>
  );
};
