import * as React from "react";
import * as ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";
import { Checkbox, Radio, notification } from "antd";
import { Loader } from "../../../components/layout/loader";
import { BrowserData, BrowserDataMobx, BrowserServiceMobx } from "../../../mst/kinds/browser";
import { FormLabel } from "../../../components/forms/formLabel";
import { NGButton } from "../../../newcomponents/button/Button";
import { Tooltip } from "../../../components/Tooltip";

interface ItemProps {
  label: string;
  onClick: any;
  isActive: boolean;
  disabledTooltip?: boolean;
  isClickable?: boolean;
}
const Item: React.FC<ItemProps> = ({ label, onClick, isActive, disabledTooltip = false, isClickable = true }) => {
  if (disabledTooltip) {
    return (
      <button
        className={`browser-service-item ngfocus block text-sm truncate ${
          isActive ? "color-link" : "color-link-hover"
        }`}
        disabled={!isClickable}
        onClick={onClick}
      >
        {label}
      </button>
    );
  }

  return (
    <Tooltip title={label} open={label.length >= 23}>
      <button
        className={`browser-service-item ngfocus block text-sm truncate ${
          isActive ? "color-link" : "color-link-hover"
        }`}
        disabled={!isClickable}
        onClick={onClick}
      >
        {label}
      </button>
    </Tooltip>
  );
};

interface DataItemProps {
  item: BrowserDataMobx;
  isActive: boolean;
  onClick: any;
  selectionType: "none" | "multi" | "single";
  canBeSelectedAsParent: boolean;
  isInSelections: boolean;
}
const DataItem: React.FC<DataItemProps> = observer(
  ({ item, onClick, selectionType, isInSelections, isActive, canBeSelectedAsParent }) => {
    const label = item._cpln.label;
    const hasChild = !!item._cpln.hasChildren;

    return (
      <div className={`flex items-center text-sm ${isInSelections || isActive ? "color-link" : "color-link-hover"}`}>
        {hasChild && !canBeSelectedAsParent ? null : selectionType === "multi" ? (
          <Checkbox id={`${label}-${item._cpln.href}`} checked={isInSelections} onClick={onClick} />
        ) : selectionType === "single" ? (
          <Radio id={`${label}-${item._cpln.href}`} checked={isInSelections} onClick={onClick} />
        ) : null}
        <Tooltip title={label} open={label.length >= 45}>
          <label
            onClick={(hasChild && !canBeSelectedAsParent) || selectionType === "none" ? onClick : undefined}
            htmlFor={`${label}-${item._cpln.href}`}
            className={`browser-service-item cursor-pointer truncate`}
          >
            {label}
          </label>
        </Tooltip>
      </div>
    );
  }
);

interface ForGCPAzureProps {
  isActive: boolean;
  cloudaccountLink: string;
}

interface Props {
  title: string;
  selectionType: "none" | "multi" | "single";
  onClose: (selections: BrowserData[]) => void;
  forGcpResource?: ForGCPAzureProps;
  forAzureScope?: ForGCPAzureProps;
  browserService: BrowserServiceMobx;
  restrictToBucket?: boolean;
  restrictToParent?: boolean;
  restrictToVPCEndpointService?: boolean;
  dataTypeRestrictions?: string[];
}
const BrowserServiceModalRaw: React.FC<Props> = ({
  title,
  selectionType,
  onClose,
  forGcpResource = { isActive: false, cloudaccountLink: "" },
  forAzureScope = { isActive: false, cloudaccountLink: "" },
  browserService,
  restrictToBucket = false,
  restrictToParent = false,
  restrictToVPCEndpointService = false,
  dataTypeRestrictions = [],
}) => {
  // TODO fix this isLoading to get its data from the browser service
  const [isLoading, setIsLoading] = React.useState(false);
  const [selections, setSelections] = React.useState<BrowserData[]>([]);

  React.useEffect(() => {
    if (!restrictToBucket && !restrictToVPCEndpointService) {
      setIsLoading(true);
      browserService
        .fetchServices()
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed",
            description: errorMessage,
          });
          setIsLoading(false);
        });
    }
  }, []);

  function clearSelections() {
    setSelections([]);
  }

  function onItem(item: BrowserData, canBeSelectedAsParent: boolean) {
    if (item._cpln.hasChildren && !canBeSelectedAsParent) return;
    if (selectionType === "single") {
      if (selections.find((d) => d._cpln.ref === item._cpln.ref)) {
        // Can we get rid of selections array and rely on browser service for this?
        setSelections([]);
      } else {
        setSelections([item]);
      }
    } else if (selectionType === "multi") {
      if (selections.find((d) => d._cpln.ref === item._cpln.ref)) {
        setSelections(selections.filter((d) => d._cpln.ref !== item._cpln.ref));
      } else {
        setSelections([...selections, item]);
      }
    }
  }

  async function act(fn: any) {
    try {
      setIsLoading(true);
      await fn();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  return ReactDOM.createPortal(
    <div
      className="fixed w-screen h-screen inset-0 flex items-center justify-center"
      style={{ backgroundColor: "rgb(0, 0, 0, 0.5)", zIndex: 1000 }}
    >
      <div className="p-8" style={{ width: 1200, backgroundColor: "var(--color-drop)" }}>
        <div className="mb-4">{title}</div>
        <div
          onClick={(e) => e.stopPropagation()}
          style={{ height: 500, backgroundColor: "var(--color-drop)", borderColor: "var(--color-border)" }}
          className="browser-service-sections-container   border    flex flex-nowrap w-full overflow-auto"
        >
          {/* Services List */}
          <div className="border-l p-4 h-full flex-grow basis-0 flex flex-col" style={{ minWidth: 240, maxWidth: 240 }}>
            <FormLabel>Service</FormLabel>
            <div className="overflow-auto flex-grow">
              {browserService.servicesSection.items.map((service) => {
                const isActive = service.href === browserService.servicesSection.selectedHref;

                if (restrictToBucket && !isActive) {
                  return null;
                }

                return (
                  <Item
                    key={service.href}
                    disabledTooltip={true}
                    isClickable={!restrictToBucket && !restrictToVPCEndpointService}
                    label={service.label!}
                    isActive={isActive}
                    onClick={() => {
                      act(async () => {
                        clearSelections();
                        browserService.servicesSection.setSelectedHref(service.href);
                        await browserService.fetchRegions();
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>
          {/* Regions List */}

          <div className="border-l p-4 h-full flex-grow basis-0 flex flex-col" style={{ minWidth: 240, maxWidth: 240 }}>
            <FormLabel>Region</FormLabel>
            <div className="overflow-auto flex-grow">
              {browserService.regionsSection.items.map((region) => (
                <Item
                  key={region.href}
                  isClickable={!restrictToBucket}
                  isActive={region.href === browserService.regionsSection.selectedHref}
                  label={region.label!}
                  onClick={() => {
                    act(async () => {
                      clearSelections();
                      browserService.regionsSection.setSelectedHref(region.href);
                      await browserService.fetchTypes();
                    });
                  }}
                />
              ))}
            </div>
          </div>
          {/* Type List */}
          <div className="border-l p-4 h-full flex-grow basis-0 flex flex-col" style={{ minWidth: 240, maxWidth: 240 }}>
            <FormLabel>Type</FormLabel>
            <div className="overflow-auto flex-grow">
              {browserService.typesSection.items
                .filter((item) => {
                  if (dataTypeRestrictions.length < 1) {
                    return true;
                  }
                  let res = false;
                  for (let restriction of dataTypeRestrictions) {
                    res = item.href.includes(restriction);
                  }
                  return res;
                })
                .filter((type) => {
                  if (forGcpResource.isActive) {
                    return !type.href.includes("role");
                  }
                  return true;
                })
                .map((type) => (
                  <Item
                    key={type.href}
                    disabledTooltip={true}
                    isClickable={!restrictToBucket}
                    isActive={type.href === browserService.typesSection.selectedHref}
                    label={type.label!}
                    onClick={() => {
                      act(async () => {
                        clearSelections();
                        browserService.typesSection.setSelectedHref(type.href);
                        await browserService.fetchDataItems();
                      });
                    }}
                  />
                ))}
            </div>
          </div>
          {/* Data Item List */}
          <div className="border-l p-4 h-full flex-grow basis-0 flex flex-col" style={{ minWidth: 240 }}>
            <FormLabel>{forAzureScope.isActive ? "Scope" : "Resource"}</FormLabel>
            {!isLoading && browserService.typesSection.selectedHref ? (
              browserService.dataSection.items.length < 1 ? (
                <span className="text-sm">No Item Found</span>
              ) : null
            ) : null}
            <div className="overflow-auto flex-grow">
              {browserService.dataSection.items.map((item) => (
                <DataItem
                  key={item._cpln.href}
                  item={item}
                  selectionType={selectionType}
                  canBeSelectedAsParent={!restrictToBucket}
                  isInSelections={selections.some((s) => s._cpln.href === item._cpln.href)}
                  isActive={
                    selections.some((s) => s._cpln.href === item._cpln.href) ||
                    (!!item._cpln.hasChildren && browserService.dataSection.itemSelection === item._cpln.href)
                  }
                  onClick={() => {
                    act(async () => {
                      onItem(item.asObject, !restrictToBucket);
                      await browserService.dataSection.onSelectDataItem(item._cpln.href!);
                    });
                  }}
                />
              ))}
            </div>
          </div>
          {/* Children items list */}
          {!restrictToParent && browserService.dataSection.childItems.length > 0 ? (
            <div
              className="border-l p-4 h-full flex-grow basis-0 flex flex-col"
              style={{ minWidth: 240, maxWidth: 240 }}
            >
              <FormLabel>Subtype</FormLabel>
              {/* TODO Check if parent data item is selected and there is no child, and show here no child is found */}
              <div className="overflow-auto flex-grow">
                {browserService.dataSection.childItems.map((item) => (
                  <Item
                    key={item.href}
                    isActive={item.href === browserService.dataSection.childSelection}
                    label={item.label!}
                    onClick={() => {
                      act(async () => {
                        clearSelections();
                        await browserService.dataSection.onSelectLinkItem(item.href);
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {/* Data item of first children list */}
          {!restrictToParent && browserService.dataSection.childSelection ? (
            <div
              className="border-l p-4 h-full flex-grow basis-0 flex flex-col"
              style={{ minWidth: 240, maxWidth: 240 }}
            >
              <FormLabel>Resource</FormLabel>
              {!isLoading && browserService.dataSection.childSelection ? (
                browserService.dataSection.items_2.length < 1 ? (
                  <div>No Item Found</div>
                ) : null
              ) : null}
              <div className="overflow-auto flex-grow">
                {browserService.dataSection.items_2.map((item) => (
                  <DataItem
                    key={item._cpln.href}
                    item={item}
                    selectionType={selectionType}
                    canBeSelectedAsParent={!restrictToBucket}
                    isInSelections={selections.some((s) => s._cpln.href === item._cpln.href)}
                    isActive={selections.some((s) => s._cpln.href === item._cpln.href)}
                    onClick={() => {
                      act(async () => {
                        onItem(item.asObject, !restrictToBucket);
                        await browserService.dataSection.onSelectDataItem(item._cpln.href!);
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
        {isLoading ? <Loader reason={"browser service"} fullScreen={false} /> : null}
        {selectionType === "none" ? (
          <div className="flex justify-end mt-4">
            <NGButton variant={"primary"} onClick={() => onClose([])}>
              Done
            </NGButton>
          </div>
        ) : (
          <div className="flex justify-end mt-4">
            <NGButton variant={"danger"} onClick={() => onClose([])}>
              Cancel
            </NGButton>
            <NGButton
              variant={"action"}
              loading={browserService.isConfirmLoading}
              className="ml-4"
              onClick={async () => {
                browserService.setIsConfirmLoading(true);
                await onClose(selections);
                browserService.setIsConfirmLoading(false);
              }}
              disabled={selections.length < 1 || browserService.isConfirmLoading}
            >
              Confirm
            </NGButton>
          </div>
        )}
      </div>
    </div>,
    document.body!
  );
};

export const BrowserServiceModal = observer(BrowserServiceModalRaw);
