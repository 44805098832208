import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { request, resourceLink } from "../../services/cpln";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { DomainMobx } from "../../mst/kinds/domain";
import { useDetailContext } from "../../components/detail/detailContext";
import { RoadblockDetails } from "./create_new/types";
import { DNSValue } from "./create_new/create_new_dns_value";
import { Loader } from "../../components/layout/loader";

const DomainConfigurationRaw: React.FC = () => {
  const { item } = useDetailContext();
  const domainItem = item as DomainMobx;
  const { org } = ConsoleContext;
  const [orgId, setOrgId] = React.useState("");
  const [gvcAlias, setGVCAlias] = React.useState("<gvcAlias>");
  const [details, setDetails] = React.useState<RoadblockDetails | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [unauthorized, setUnauthorized] = React.useState<boolean>(false);

  React.useEffect(() => {
    request({ url: resourceLink("org", org!) })
      .then(({ data: orgItem }) => {
        setOrgId(orgItem.id);
      })
      .catch(() => {});

    try {
      request({ url: domainItem.spec?.ports[0].routes[0].workloadLink.split("/workload")[0]! })
        .then(({ data: gvcItem }) => {
          setGVCAlias(gvcItem.alias);
        })
        .catch(() => {});
    } catch (e) {}
  }, []);

  React.useEffect(() => {
    try {
      setIsLoading(true);
      setUnauthorized(false);
      const body: any = { ...domainItem.json, description: domainItem.json.description + domainItem.json.description };
      delete body.id;
      delete body.version;
      request({
        method: "patch",
        url: domainItem.selfLink + "?dryRun",
        body,
      })
        .then(({ data }) => {
          const hasDetails = data.details && (data.details.dnsConfig || data.details.ownershipConfig);
          if (hasDetails) {
            setDetails(data.details);
          } else {
            setDetails(null);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setDetails(e?.response?.data.details);
          setIsLoading(false);
          if (e.response?.status === 403) {
            setUnauthorized(true);
          }
        });
    } catch (e) {
      setDetails(e?.response?.data?.details || null);
      setIsLoading(false);
      if (e.response?.status === 403) {
        setUnauthorized(true);
      }
    }
  }, []);

  let csvData = ``;
  // if (domainItem.isApex) {
  //   csvData = `domain,record,type,ttl,value
  //     ${domain},${host_txt},${type_txt},${ttl_txt},${orgId}`;
  //   if (domainItem.spec?.routingMode === "pathBased") {
  //     csvData = `domain,record,type,ttl,value
  //       ${domain},${host_txt},${type_txt},${ttl_txt},${orgId}
  //       ${domain},${host_cname},${type_cname},${ttl_cname},${cname_value}`;
  //   }
  // } else {
  //   csvData = `domain,record,type,ttl,value
  //     ${domain},${host_txt},${type_txt},${ttl_txt},${orgId}`;
  //   if (domainItem.spec?.dnsMode === "cname") {
  //     csvData = `domain,record,type,ttl,value
  //     ${domain},${host_txt},${type_txt},${ttl_txt},${orgId}`;
  //     if (domainItem.spec?.routingMode === "pathBased") {
  //       csvData = `domain,record,type,ttl,value
  //       ${domain},${host_txt},${type_txt},${ttl_txt},${orgId}
  //       ${domain},${domainSub},${type_cname},${ttl_cname},${cname_value}`;
  //     }
  //   } else if (domainItem.spec?.dnsMode === "ns") {
  //     csvData = `domain,record,type,ttl,value
  //     ${domain},${host_txt},${type_txt},${ttl_txt},${orgId}
  //     ${domain},${host_ns},${type_ns},${ttl_ns},${ns1}
  //     ${domain},${host_ns},${type_ns},${ttl_ns},${ns2}
  //     ${domain},${host_ns},${type_ns},${ttl_ns},${ns3}
  //     ${domain},${host_ns},${type_ns},${ttl_ns},${ns4}`;
  //   }
  // }

  function onExportCSV() {
    try {
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = `${name}-configuration.csv`;
      const file = new Blob([csvData], { type: "text/csv" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: "Try again" });
    }
  }

  if (isLoading) {
    return <Loader reason="domain dns" />;
  }

  if (unauthorized) {
    return <div>You do not have permissions to view this information.</div>;
  }

  if (!details || details.dnsConfig.length < 1) {
    return <div>The required DNS records will be available once routing is configured.</div>;
  }

  return (
    <>
      <div className="mb-2">Create the following DNS records:</div>
      <div className="flex flex-col gap-1 mb-1">
        <div>
          If you have already made the change to DNS, please wait a few minutes. It takes time for DNS propagation. Try
          again soon
        </div>
      </div>
      {details ? (
        <div className="mb-4">
          {details &&
          ((details.ownershipConfig && details.ownershipConfig.length > 0) ||
            (details.dnsConfig && details.dnsConfig.length > 0)) ? (
            <>
              <div className="flex items-center gap-2 border px-2 py-1 table-labels">
                <div className="w-5/12">Host</div>
                <div className="w-1/12">TTL</div>
                <div className="w-1/12">Type</div>
                <div className="w-5/12">Value</div>
              </div>
            </>
          ) : null}
          {details &&
            details.ownershipConfig &&
            details.ownershipConfig.map((r, index) => (
              <div key={index} className="flex items-center gap-2 border px-2 py-1">
                <DNSValue column={`w-5/12`} value={r.host === "@" ? domainItem.name : r.host} />
                <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                <DNSValue column={`w-1/12`} value={r.type} />
                <DNSValue column={`w-5/12`} value={r.value.replace("<gvcAlias>", gvcAlias)} />
              </div>
            ))}
          {details &&
            details.dnsConfig &&
            details.dnsConfig.map((r, index) => (
              <div key={index} className="flex items-center gap-2 border px-2 py-1">
                <DNSValue column={`w-5/12`} value={r.host === "@" ? domainItem.name : r.host} />
                <DNSValue column={`w-1/12`} value={r.ttl.toString()} />
                <DNSValue column={`w-1/12`} value={r.type} />
                <DNSValue column={`w-5/12`} value={r.value.replace("<gvcAlias>", gvcAlias)} />
              </div>
            ))}
        </div>
      ) : null}
      <div className="mr-2">
        After creating the above entries, it will take a few minutes for the changes to propagate across the Internet.
      </div>
    </>
  );
};

export const DomainDNS = observer(DomainConfigurationRaw);
