import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftMobx } from "../../../mst/stores/identity.draft";
import { AWSSetupModal } from "./aws/setupModal";
import { GCPSetupModal } from "./gcp/setupModal";
import { AzureSetupModal } from "./azure/setupModal";
import { AWSIdentityInfo } from "./aws/info";
import { GCPIdentityInfo } from "./gcp/info";
import { AzureIdentityInfo } from "./azure/info";
import { NGSIdentityInfo } from "./ngs/info";
import { NGSSetupModal } from "./ngs/setupModal";

interface Props {
  identity: IdentityDraftMobx;
}
const IdentityCreateCloudaccessRaw: React.FC<Props> = ({ identity }) => {
  return (
    <>
      <AWSIdentityInfo aws={identity.aws} infoOnly={false} />
      {identity.aws.isSettingUp ? <AWSSetupModal aws={identity.aws} /> : null}
      <AzureIdentityInfo azure={identity.azure} infoOnly={false} />
      {identity.azure.isSettingUp ? <AzureSetupModal azure={identity.azure} /> : null}
      <GCPIdentityInfo gcp={identity.gcp} infoOnly={false} />
      {identity.gcp.isSettingUp ? <GCPSetupModal gcp={identity.gcp} /> : null}
      <NGSIdentityInfo ngs={identity.ngs} infoOnly={false} />
      {identity.ngs.isSettingUp ? <NGSSetupModal ngs={identity.ngs} /> : null}
    </>
  );
};

export const IdentityCreateCloudaccess = observer(IdentityCreateCloudaccessRaw);
