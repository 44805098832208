import { Agent, AgentInfo } from "../../schema/types/agent";
import moment from "moment-timezone";
import { dateString } from "../../components/dateStringFn";
import { formatStrings } from "../../utils/time";

export const AGENT_CHECKING = "Checking";

export const agentHelpers = {
  getEnvText(agent: Agent, info: AgentInfo) {
    if (info.env && Object.keys(info.env).length > 0) {
      const text: string[] = [];
      for (let key of Object.keys(info.env).slice().sort()) {
        const value = (info.env as any)[key];
        if (key === "accountId") {
          text.push(`${key}: '${value}'`);
        } else {
          text.push(`${key}: ${value}`);
        }
      }
      return text;
    }
    return "Env not found";
  },
  getLastActiveText(agent: Agent, info: AgentInfo) {
    try {
      if (info.lastActive !== undefined && info.lastActive !== AGENT_CHECKING && info.lastActive.length > 0) {
        return dateString(info.lastActive, { format: formatStrings.humanizeWAgo });
      }
      throw new Error("no last active found");
    } catch (e) {
      return "This agent was never active";
    }
  },
  getLastActiveTextTable(agent: Agent, info: AgentInfo) {
    if (info.lastActive === AGENT_CHECKING) return AGENT_CHECKING;
    try {
      if (info.lastActive !== undefined && info.lastActive.length > 0) {
        return dateString(info.lastActive, { format: formatStrings.humanizeWAgo });
      }
      throw new Error("no last active found");
    } catch (e) {
      return "Never";
    }
  },
  getIsActive(agent: Agent, info: AgentInfo) {
    const lastActiveText = this.getLastActiveText(agent, info);
    if (lastActiveText.includes("never")) return false;
    try {
      const lastActiveDate = moment(info.lastActive);
      const nowDate = moment();
      const diff = nowDate.diff(lastActiveDate, "seconds");
      return diff <= 60;
    } catch (e) {
      return false;
    }
  },
};

export interface AgentTableItem extends Agent {
  selfLink: string;
  isActive: boolean;
  lastActiveText: string;
}
