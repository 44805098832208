import * as React from "react";
import { observer } from "mobx-react-lite";
import { Timezone } from "../../../../mobxStores/userData/timezone";
import { NGAutocomplete } from "../../../../newcomponents/select/ngautocomplete";
import { NGSelect } from "../../../../newcomponents/select/ngselect";

interface Props {
  showAll?: boolean;
}
const TimezoneDisplayRaw: React.FC<Props> = ({ showAll }) => {
  return (
    <div className="user-menu-date-format">
      <span>Timezone</span>
      {showAll ? (
        <NGAutocomplete
          size={"small"}
          value={Timezone.value}
          onChange={(value) => Timezone.setValue(value)}
          options={Timezone.options.map((option) => ({ label: option, value: option }))}
        />
      ) : (
        <NGSelect
          size={"small"}
          value={Timezone.value}
          onChange={(value) => Timezone.setValue(value)}
          options={[
            { label: `Local (${Timezone.DEFAULT_VALUE})`, value: Timezone.DEFAULT_VALUE },
            { label: "UTC", value: "Etc/UTC" },
          ]}
        />
      )}
    </div>
  );
};

export const TimezoneDisplay = observer(TimezoneDisplayRaw);
