import clsx from "clsx";
import styles from "./testimonial-horizontal.module.scss";

interface Props {
  quote: string;
  photo?: string;
  name?: string;
  company?: string;
}

export const TestimonialHorizontal: React.FC<Props> = ({ quote, name, photo, company }) => {
  return (
    <figure className={clsx("testimonial", styles.testimonial)}>
      <blockquote>
        <p className={styles.quote}>“{quote}”</p>
      </blockquote>
      {name && (
        <figcaption className={styles.name}>
          {photo && <img className={styles.photo} src={photo} alt={name} />}
          <div className={styles.content}>
            <span>{name}</span>
            {company && <cite>{company}</cite>}
          </div>
        </figcaption>
      )}
    </figure>
  );
};
