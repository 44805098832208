import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { Modal, notification } from "antd";
import { slug } from "github-slugger";
import { v4 as uuidv4 } from "uuid";
import { homeLink, parentLink, request } from "../../../services/cpln";
import jsYaml from "js-yaml";
import { k8sKeySort } from "../../../services/utils";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { NGButton } from "../../../newcomponents/button/Button";
import { ngParseLink } from "../../../utils/linkParser/linkParser";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import NGAlert from "../../../newcomponents/alert";
import { NGInput } from "../../../newcomponents/input/input";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { DOCS_URL } from "../../../envVariables";
import { ExternalLink } from "react-feather";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  setIdentityChecked: any;
}
const WorkloadCreateIdentityRaw: React.FC<Props> = ({ workloadDraft, setIdentityChecked }) => {
  const { org, gvc } = ConsoleContext;
  const [isFixing, setIsFixing] = React.useState(false);
  const [fixLoading, setFixLoading] = React.useState<boolean>(false);
  const [fixPermissionError, setFixPermissionError] = React.useState<boolean>(false);
  const identityNameManualRef = React.useRef(
    StringModel.create({ label: "Identity Name", validationKey: "name", isRequired: true }),
  );
  const identityNameRef = React.useRef<string>("");
  const identityBodyRef = React.useRef<any>(null as any);
  const policyNameManualRef = React.useRef(
    StringModel.create({ label: "Policy Name", validationKey: "name", isRequired: true }),
  );
  const policyNameRef = React.useRef<string>("");
  const policyBodyRef = React.useRef<any>(null as any);
  const workloadBodyRef = React.useRef<any>(null as any);

  function getIdentityLink() {
    return `/org/${org}/gvc/${gvc}/identity/${identityNameRef.current}`;
  }

  function getShortIdentityLink() {
    return `//gvc/${gvc}/identity/${identityNameRef.current}`;
  }

  function onFix() {
    var identityValue = !!workloadDraft.identityLink
      ? workloadDraft.identityLink.split("/").pop()!
      : `${slug(workloadDraft.name.value)}-${uuidv4().slice(0, 6)}`;
    setIsFixing(true);
    identityNameManualRef.current.setInitialValue(identityValue);
    policyNameManualRef.current.setInitialValue(`${slug(workloadDraft.name.value)}-${uuidv4().slice(0, 6)}`);
  }

  async function onFixConfirm() {
    try {
      setIdentityChecked();
      setFixLoading(true);
      identityNameRef.current = identityNameManualRef.current.value;
      identityBodyRef.current = {
        name: identityNameRef.current,
        kind: "identity",
        description: `Auto-managed identity for the workload "${workloadDraft.name.value}"`,
      };
      policyNameRef.current = policyNameManualRef.current.value;
      policyBodyRef.current = {
        name: policyNameRef.current,
        kind: "policy",
        description: `Auto-created policy for the workload "${workloadDraft.name.value}"`,
        targetKind: "secret",
        targetLinks: workloadDraft.secretLinks.map((l) => l.replace(`/org/${org}/secret/`, "//secret/")),
        bindings: [{ permissions: ["reveal"], principalLinks: [`//gvc/${gvc}/identity/${identityNameRef.current}`] }],
      };
      workloadBodyRef.current = {
        name: workloadDraft.name.value,
        kind: "workload",
        spec: {
          identityLink: getShortIdentityLink(),
        },
      };
      // Create identity
      await request({ url: parentLink("identity"), body: identityBodyRef.current, method: "put" });
      // Create policy
      await request({ url: homeLink("policy"), body: policyBodyRef.current, method: "put" });
      workloadDraft.setIdentityLink(getIdentityLink());
      notification.success({ message: "Fixed identity" });
      setIsFixing(false);
      setFixLoading(false);
      workloadDraft.processIdentity(workloadDraft.identityLink);
    } catch (e) {
      if (e.message.includes("403")) {
        setFixLoading(false);
        setFixPermissionError(true);
      }
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setFixLoading(false);
      notification.error({ message: "Failed", description: errorMessage });
    }
  }

  function toYaml(data: any) {
    try {
      return jsYaml.dump(data, { indent: 2, noRefs: true, sortKeys: k8sKeySort });
    } catch (e) {
      console.error("toYaml failed", data, e.message);
      throw e;
    }
  }

  async function onYaml() {
    try {
      const yamls = [identityBodyRef.current, policyBodyRef.current, workloadBodyRef.current].map(toYaml);
      const yaml = yamls.join("\n---\n");
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = workloadDraft.name.value + "-fix-identity.yaml";
      const file = new Blob([yaml], { type: "text/yaml" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      console.error("export yaml failed", e.message);
      throw e;
    }
  }

  const identitySelectValue = ngParseLink(workloadDraft.identityLink, { kind: "identity" }).name;

  function onIdentityChange(value: string) {
    const identityLink = ngParseLink(value, { kind: "identity" }).absolute;
    workloadDraft.setIdentityLink(identityLink);
  }

  return (
    <>
      <div className="flex flex-col">
        {!workloadDraft.identityLink && workloadDraft.hasAnyCloudVolume ? (
          <NGAlert
            className="mb-4"
            render={() => {
              return (
                <>
                  <p>
                    The workload should be assigned an identity. It uses a volume that points to a cloud account
                    resource. The identity should grant access to the resource.{" "}
                  </p>
                  <p>
                    <a
                      className="color-link flex items-center gap-1 underline"
                      target={"_blank"}
                      href={`${DOCS_URL}/concepts/accessing-cloud-resources`}
                    >
                      <span>Learn more</span>
                      <ExternalLink className="h-4" />
                    </a>
                  </p>
                </>
              );
            }}
          />
        ) : null}
        <NGFormLabel
          required={workloadDraft.identityValid === "required"}
          invalid={!["unknown", "unneeded", true].includes(workloadDraft.identityValid)}
        >
          Identity
        </NGFormLabel>
        <div className="flex items-center mb-4">
          <NGKindSelect
            value={identitySelectValue}
            kind={"identity"}
            fetchAll
            placeholder=" "
            showClear
            queries={[{ rel: "gvc", value: ConsoleContext.gvc! }]}
            onChange={(value) => onIdentityChange(value)}
            style={{ width: 450 }}
            invalid={workloadDraft.identityValid === false || workloadDraft.identityValid === "required"}
            disabled={workloadDraft.processingIdentity}
          />
          <InfoTooltip
            title={[
              "The identity link is used as the access scope for 3rd party cloud resources.",
              "A single identity can provide access to multiple cloud providers.",
              "An identity encapsulates several things. First, a workload automatically has all the permissions defined across multiple cloud providers, regardless of where the workload executes. Second, an identity can manage native networking capabilities and cloud wormhole policies, allowing workloads to reach private networks, such as VPCs. Third, policies can refer to an identity to grant a workload fine-grained access to Control Plane resources.",
            ]}
          />
        </div>
        {workloadDraft.processingIdentity ? (
          <div className="flex items-center mb-4">
            <NGAlert type={"info"} message={"Checking validity of the identity."} />
          </div>
        ) : workloadDraft.identityValid === "required" ? (
          <div className="mb-4">
            <NGAlert type={"warning"} message={"An Identity is required since this workload uses secrets."} />
            <NGButton
              disabled={fixLoading || fixPermissionError}
              loading={fixLoading}
              className="mt-4"
              variant={"action"}
              onClick={onFix}
            >
              Fix Identity Requirement Automatically
            </NGButton>
            {fixPermissionError ? (
              <div className="flex items-center gap-2 mt-4">
                <NGAlert type={"warning"} message={"You dont have permission to create required items."} />
                <NGButton onClick={onYaml} variant={"primary"}>
                  Download Generated YAML
                </NGButton>
              </div>
            ) : null}
          </div>
        ) : workloadDraft.identityValid === "unknown" ? (
          <div className="mb-4">
            <NGAlert
              type={"info"}
              message={"We could not determine the validity of identity with your account's permissions."}
            />
            <NGButton
              disabled={fixLoading || fixPermissionError}
              loading={fixLoading}
              className="mt-4"
              variant={"action"}
              onClick={onFix}
            >
              Fix Identity Requirement Automatically
            </NGButton>
            {fixPermissionError ? (
              <div className="flex items-center gap-2 mt-4">
                <NGAlert type={"warning"} message={"You dont have permission to create required items."} />
                <NGButton onClick={onYaml} variant={"primary"}>
                  Download Generated YAML
                </NGButton>
              </div>
            ) : null}
          </div>
        ) : workloadDraft.identityValid === true ? (
          <div className="flex items-center mb-4">
            <NGAlert type={"success"} message={"Identity is valid for this workload."} />
          </div>
        ) : workloadDraft.identityValid === false ? (
          <div className="mb-4">
            <NGAlert
              type={"error"}
              message={
                "Either identity is not valid for this workload, or your account's permissions are not enough to determine validity."
              }
            />
            <NGButton
              disabled={fixLoading || fixPermissionError}
              loading={fixLoading}
              className="mt-4"
              variant={"action"}
              onClick={onFix}
            >
              Fix Identity Requirement Automatically
            </NGButton>
          </div>
        ) : null}
      </div>
      {isFixing ? (
        <Modal
          open={isFixing}
          closable={false}
          maskClosable={false}
          onCancel={() => setIsFixing(false)}
          okButtonProps={{ disabled: !policyNameManualRef.current.isValid || !identityNameManualRef.current.isValid }}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setIsFixing(false)}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                loading={fixLoading}
                disabled={!policyNameManualRef.current.isValid || !identityNameManualRef.current.isValid || fixLoading}
                onClick={() => onFixConfirm()}
              >
                OK
              </NGButton>
            </div>
          }
        >
          <NGFormLabel required invalid={!policyNameManualRef.current.isValid}>
            Policy Name
          </NGFormLabel>
          <NGInput
            value={policyNameManualRef.current.value}
            onChange={(e) => policyNameManualRef.current.setValue(e.target.value)}
            disabled={fixLoading}
            className="mb-4"
          />
          <NGFormLabel required invalid={!identityNameManualRef.current.isValid}>
            Identity Name
          </NGFormLabel>
          <NGInput
            value={identityNameManualRef.current.value}
            onChange={(e) => identityNameManualRef.current.setValue(e.target.value)}
            disabled={fixLoading}
          />
        </Modal>
      ) : null}
    </>
  );
};

export const WorkloadCreateIdentity = observer(WorkloadCreateIdentityRaw);
