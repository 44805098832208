import * as React from "react";
import { observer } from "mobx-react-lite";
import { AuditContextMobx } from "../../mst/kinds/auditContext";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { Table } from "../../newcomponents/table/table";
import { TableNewItemButton } from "../../newcomponents/table/components/tableNewButton";
import { TableExportButton } from "../../newcomponents/table/components/tableExportButton";
import { CustomTableCPLNQuery } from "../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";

const AuditContextListRaw: React.FC = () => {
  const { kind, id } = useTableKindId("auditctx", "auditctx-list");
  const qData = useTableItemQueryData<AuditContextMobx>(kind, { fetchAllPages: true });

  return (
    <Table<AuditContextMobx>
      title={"Audit Contexts"}
      data={qData.visibleItems}
      tableId={id}
      headerRenderer={(table) => {
        const currentVisibleItemLinks = table.getRowModel().rows.map((row) => row.original.selfLink);
        return (
          <>
            <TableNewItemButton kind={kind} />
            <TableExportButton selfLinks={currentVisibleItemLinks} kind={kind} />
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
            <CustomTableCPLNQuery
              tableId={id}
              kind={kind}
              onQuery={qData.setQuery}
              isLoading={qData.isLoadingQueried}
            />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionColumn(),
        {
          id: "origin",
          label: "Origin",
          canGroupByDistinctValues: true,
          groupLabelMap: { default: "Default", builtin: "Builtin" },
          size: 160,
        },
        TagsColumn(),
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};

export const AuditContextList = observer(AuditContextListRaw);
