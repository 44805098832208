import htmlToFormattedText from "html-to-formatted-text";
import DOMPurify from "dompurify";
import AnsiToHTML from "ansi-to-html";
import * as React from "react";
import { Tag } from "../tag/tag";
import { LogEntry } from "../../pages/logs/types";
import { ChevronDown, ChevronUp } from "react-feather";
import {
  ListProps,
  List as _List,
  CellMeasurerProps,
  CellMeasurerCache,
  CellMeasurer as _CellMeasurer,
} from "react-virtualized";
import { Tooltip } from "../Tooltip";
import { DateString } from "../dateString";
import { Timezone } from "../../mobxStores/userData/timezone";
import { formatStrings } from "../../utils/time";

const List = (_List as unknown) as React.FC<ListProps>;
const CellMeasurer = (_CellMeasurer as unknown) as React.FC<CellMeasurerProps>;

// TODO add labels as fixed element
// TODO try to show more labels of the entry
// TODO show common labels somewhere top, and remove from labels per entry

const ansiConverter = new AnsiToHTML();

interface Props {
  cache: CellMeasurerCache;
  logs: LogEntry[];
  prettifyJSON: boolean;
  timestamp: boolean;
  labels: boolean;
  labelsWidth: number;
  width: number;
  height: number;
  setQueryKeyValue: any;
  orderBy: "asc" | "desc";
  setOrderBy: (value: "asc" | "desc") => void;
}

export const LogList: React.FC<Props> = ({
  cache,
  logs,
  prettifyJSON,
  timestamp,
  labels,
  labelsWidth,
  width,
  height,
  setQueryKeyValue,
  orderBy,
  setOrderBy,
}) => {
  const COLUMN_WIDTH_TIMESTAMP = 185;

  const RowRenderer: React.FC<any> = ({ index, key, parent, style }) => {
    const isOdd = index % 2 === 1;
    let log = logs[index];
    if (log.id !== "labels" && orderBy === "asc") {
      log = logs[logs.length - index];
    }

    if (log.id === "labels") {
      return (
        <div style={style} className="flex items-center table-labels pl-2 py-1">
          {timestamp ? (
            <div style={{ minWidth: COLUMN_WIDTH_TIMESTAMP }} className={"flex items-center gap-1"}>
              <span>Timestamp ({Timezone.label})</span>
              {setOrderBy ? (
                <Tooltip title={orderBy === "asc" ? "Ascending (Oldest First)" : "Descending (Newest First)"}>
                  <button
                    onClick={() => setOrderBy(orderBy === "desc" ? "asc" : "desc")}
                    className={`ngfocus color-link`}
                  >
                    {orderBy === "desc" ? <ChevronDown /> : <ChevronUp />}
                  </button>
                </Tooltip>
              ) : null}
            </div>
          ) : null}
          {labels ? <div style={{ minWidth: labelsWidth }}>Labels</div> : null}
          <div>Log</div>
        </div>
      );
    }

    let __html: string = "";
    let prettifiedLog: string = "";
    if (prettifyJSON) {
      try {
        const parsedLog = JSON.parse(log.log);
        prettifiedLog = JSON.stringify(parsedLog, null, 2);
      } catch (e) {}
    }

    const toFormatted = htmlToFormattedText(prettifiedLog || log.log);
    const toHtml = ansiConverter.toHtml(toFormatted);
    const toSanitizedEl = DOMPurify.sanitize(toHtml, { RETURN_DOM: true });

    __html = toSanitizedEl.outerHTML.replaceAll("<body>", "").replaceAll("</body>", "");
    if (prettifiedLog) {
      __html = toSanitizedEl.outerHTML.replace(/\n/g, "<br>").replace(/ /g, "&nbsp;");
    }

    return (
      <CellMeasurer key={key} parent={parent} cache={cache} index={index}>
        <div className={`text-xs flex items-center py-1 pl-2 ${isOdd ? "log-odd-bg" : "log-even-bg"}`} style={style}>
          {timestamp ? (
            <div className="font-mono" style={{ minWidth: COLUMN_WIDTH_TIMESTAMP }}>
              <DateString iso={new Date(log.timestamp).toISOString()} format={formatStrings.log} />
            </div>
          ) : null}
          {labels ? (
            <div
              className={`flex items-start flex-wrap gap-1`}
              style={{ minWidth: labelsWidth, maxWidth: labelsWidth }}
            >
              {log.labels.map(([key, value]: any) => (
                <Tag
                  key={key + value}
                  onClick={(e: React.MouseEvent) => setQueryKeyValue(key, value, e.altKey)}
                  name={key}
                  value={value}
                  small
                  style={{ maxWidth: 295 }}
                  colorize
                />
              ))}
            </div>
          ) : null}
          <div className={`font-mono break-all`} dangerouslySetInnerHTML={{ __html }} />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <List
      width={width}
      height={height}
      deferredMeasurementCache={cache}
      rowCount={logs.length}
      rowHeight={cache.rowHeight}
      rowRenderer={RowRenderer}
    />
  );
};
