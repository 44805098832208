import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderPaperspace } from "./paperspace";
import { ProviderPaperspaceNodePools } from "./nodePools";
import { ProviderPaperspaceNodePoolAdd } from "./nodePoolAdd";
import { ProviderPaperspaceNodePool } from "./nodePool";
import { ProviderPaperspaceUnmanagedNodePools } from "./unmanagedNodePools";
import { ProviderPaperspaceUnmanagedNodePoolAdd } from "./unmanagedNodePoolAdd";
import { ProviderPaperspaceUnmanagedNodePool } from "./unmanagedNodePool";
import { ProviderPaperspaceAdvanced } from "./advanced";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderPaperspaceRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderPaperspace mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderPaperspaceNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<ProviderPaperspaceNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<ProviderPaperspaceNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-unmanaged-node-pools`}
        element={<ProviderPaperspaceUnmanagedNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/-add`}
        element={<ProviderPaperspaceUnmanagedNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/:index`}
        element={<ProviderPaperspaceUnmanagedNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-advanced`} element={<ProviderPaperspaceAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderPaperspaceRoute = observer(ProviderPaperspaceRouteRaw);
