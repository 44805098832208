import { flow, Instance, types, getRoot } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { BrowserDataMobx, BrowserServiceMobx } from "../kinds/browser";
import { AWSIdentityDataSnapshot } from "./identity.aws.data";
import { IdentityDraftMobx } from "./identity.draft";
import { notification } from "antd";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const IdentityDraftAWSIdentityModel = types
  .model("Identity Draft Aws Link", {
    isActive: false,
    useManualInput: false,
    cloudaccountName: types.optional(types.string, ""),
    method: types.optional(types.enumeration(["existing", "new"]), "existing"),
    roleInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Role",
        validationKey: "awsRoleName",
        isRequired: false,
      })
    ),
    policies: types.array(types.string),
    policyInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Policy",
        validationKey: "awsPolicyRef",
      })
    ),
    filterInput: types.optional(StringModel, () => StringModel.create({ label: "Search", transformKey: "lowerCase" })),
  })
  .views((self) => ({
    get cloudaccountLink() {
      const { absolute } = ngParseLink(self.cloudaccountName, { kind: "cloudaccount" });
      return absolute;
    },
  }))
  .actions((self) => ({
    clear() {
      self.isActive = false;
      self.useManualInput = false;
      self.cloudaccountName = "";
      self.method = "existing";
      self.roleInput.reset();
      self.policies.clear();
      self.policyInput.reset();
      self.filterInput.reset();
    },
  }))
  .views((self) => ({
    get browserService(): BrowserServiceMobx {
      const root = getRoot(self) as IdentityDraftMobx;
      return root.browserService;
    },
  }))
  .actions((self) => {
    const fetchRoles: () => Promise<void> = flow(function* () {
      try {
        const browser = self.browserService;
        browser.setCloudaccountLink(self.cloudaccountLink);

        // Get services
        yield browser.fetchServices();

        // Select iam service
        const iamServiceHref = browser.servicesSection.items.find((i) => i.href.endsWith("/iam"))!.href;
        browser.servicesSection.setSelectedHref(iamServiceHref);

        // Get regions
        yield browser.fetchRegions();

        // Select global region
        const globalRegionHref = browser.regionsSection.items.find((i) => i.href.endsWith("/global"))!.href;
        browser.regionsSection.setSelectedHref(globalRegionHref);

        // Get types
        yield browser.fetchTypes();

        // Select Roles type
        const roleTypeHref = browser.typesSection.items.find((i) => i.href.endsWith("/role"))!.href;
        browser.typesSection.setSelectedHref(roleTypeHref);

        // Get data items for the role type
        yield browser.fetchDataItems();

        self.browserService.setCanReach(true);
      } catch (e) {
        self.browserService.setCanReach(false);
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) {
          errorMessage = e.message;
        }
        notification.warning({ message: "Browser Service Failed", description: errorMessage });
      }
    });
    const fetchPolicies: () => Promise<void> = flow(function* () {
      try {
        const browser = self.browserService;
        browser.setCloudaccountLink(self.cloudaccountLink);

        // Get services
        yield browser.fetchServices();

        // Select iam service
        const iamServiceHref = browser.servicesSection.items.find((i) => i.href.endsWith("/iam"))!.href;
        browser.servicesSection.setSelectedHref(iamServiceHref);

        // Get regions
        yield browser.fetchRegions();

        // Select global region
        const globalRegionHref = browser.regionsSection.items.find((i) => i.href.endsWith("/global"))!.href;
        browser.regionsSection.setSelectedHref(globalRegionHref);

        // Get types
        yield browser.fetchTypes();

        // Select Policy type
        const policyTypeHref = browser.typesSection.items.find((i) => i.href.endsWith("/policy"))!.href;
        browser.typesSection.setSelectedHref(policyTypeHref);

        // Get data items for the policy type
        yield browser.fetchDataItems();

        self.browserService.setCanReach(true);
      } catch (e) {
        self.browserService.setCanReach(false);
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) {
          errorMessage = e.message;
        }
        notification.warning({ message: "Browser Service Failed", description: errorMessage });
      }
    });
    return { fetchRoles, fetchPolicies };
  })
  .actions((self) => ({
    setUseManualInput(value: boolean) {
      self.useManualInput = value;
      self.roleInput.setIsRequired(value);
    },
    setCloudaccountName(name: string): void {
      const oldName = self.cloudaccountName;
      if (name !== oldName) {
        self.roleInput.setInitialValue("");
        self.policies.clear();
      }
      self.cloudaccountName = name;
    },
    selectRole(value: string) {
      self.roleInput.setValue(value);
    },
    togglePolicy(value: string) {
      const item = self.policies.find((p) => p === value);
      if (item) {
        self.policies.remove(item);
      } else {
        self.policies.push(value);
      }
    },
    addPolicy() {
      const value = self.policyInput.value;
      const item = self.policies.find((p) => p === value);
      if (!item) {
        self.policies.push(value);
        self.policyInput.reset();
      }
    },
    removePolicy(value: string) {
      const item = self.policies.find((p) => p === value);
      if (item) {
        self.policies.remove(item);
      }
    },
    setMethod(value: "existing" | "new") {
      self.method = value;
    },
  }))
  .views((self) => ({
    get isValidPage_General() {
      let res = true;
      if (!self.cloudaccountName) res = false;
      if (!self.method) res = false;
      return res;
    },
    get isValidPage_Configuration() {
      if (!self.cloudaccountName) {
        return false;
      }
      let res = true;
      if (self.method === "existing") {
        // Forces to click confirm when using manual input
        if (self.useManualInput) return false;
        res = self.roleInput.value.length > 0 && self.roleInput.isValid;
      } else if (self.method === "new") {
        res = self.policies.length > 0;
      }
      return res;
    },
    get filteredRoles(): BrowserDataMobx[] {
      if (self.method !== "existing") return [];
      const browser = self.browserService;
      return browser.dataSection.items.filter((d) => d._cpln.label?.toLowerCase().includes(self.filterInput.value));
    },
    get filteredPolicies() {
      if (self.method !== "new") return [];
      const browser = self.browserService;
      return browser.dataSection.items.filter((d) => d._cpln.label?.toLowerCase().includes(self.filterInput.value));
    },
  }))
  .views((self) => ({
    get isValid() {
      return self.isValidPage_General && self.isValidPage_Configuration;
    },
    get asObject(): AWSIdentityDataSnapshot {
      return {
        cloudAccountLink: self.cloudaccountLink,
        roleName: self.method === "existing" ? self.roleInput.value : "",
        policyRefs: self.method === "new" ? [...self.policies] : [],
      };
    },
  }))
  .actions((self) => {
    const apply = flow(function* (obj: AWSIdentityDataSnapshot) {
      self.isActive = true;
      const { name: cloudaccountName } = ngParseLink(obj.cloudAccountLink, { kind: "cloudaccount" });
      self.cloudaccountName = cloudaccountName;
      if (obj.roleName) {
        self.method = "existing";
        self.roleInput.setInitialValue(obj.roleName!);
      } else {
        self.method = "new";
        self.policies.clear();
        for (let policy of obj.policyRefs!) {
          self.policies.push(policy);
        }
      }
    });
    function setIsActive(value: boolean) {
      self.isActive = value;
    }
    return { apply, setIsActive };
  });
export interface IdentityAWSDraftMobx extends Instance<typeof IdentityDraftAWSIdentityModel> {}
