import { Instance, types } from "mobx-state-tree";
import { DomainDraftModel } from "./domain.draft";
import { DomainMobx } from "../kinds/domain";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const DomainDraftStoreModel = types
  .model({
    edit: types.maybe(DomainDraftModel),
  })
  .actions((self) => ({
    new() {
      self.edit = DomainDraftModel.create();
    },
    start(domain: DomainMobx) {
      let initialRoutingMode: "none" | "subdomainBased" | "pathBased" = "none";
      if (domain.spec?.gvcLink) {
        initialRoutingMode = "subdomainBased";
      } else if (domain.spec?.ports && domain.spec?.ports.length > 0) {
        if (domain.spec?.ports.some((p) => p.routes && p.routes.length > 0)) {
          initialRoutingMode = "pathBased";
        }
      }
      const { name: gvcName } = ngParseLink(domain.spec?.gvcLink || "", { kind: "gvc", useInputCtx: true });
      self.edit = DomainDraftModel.create({
        _name: domain.name,
        _description: domain.description,
        _dnsMode: domain.spec?.dnsMode as any,
        _gvcName: gvcName,
        _ports: domain.spec?.ports ? JSON.parse(JSON.stringify(domain.spec.ports)) : [],
        _routingMode: initialRoutingMode,
        _acceptAllHosts: domain.spec?.acceptAllHosts,
      });
    },
    reset() {
      self.edit?.reset();
    },
  }));
export interface DomainDraftStoreMobx extends Instance<typeof DomainDraftStoreModel> {}
