import { types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";

export const Mk8sDraftProviderEphemeralNodePoolsReadonlyModel = types.model({
  name: types.optional(types.string, ""),
  labels: types.array(
    types.model({
      key: types.string,
      value: types.string,
    })
  ),
  taints: types.array(
    types.model({
      key: types.string,
      value: types.string,
      effect: types.string,
    })
  ),
  count: types.optional(types.number, 1),
  arch: types.optional(types.string, ""),
  flavor: types.optional(types.string, "debian"),
  cpu: types.optional(types.string, ""),
  memory: types.optional(types.string, ""),
});

export const Mk8sDraftProviderEphemeralNodePoolsModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["default", "added"]), "default"),
    _pool: types.optional(Mk8sDraftProviderEphemeralNodePoolsReadonlyModel, () =>
      Mk8sDraftProviderEphemeralNodePoolsReadonlyModel.create()
    ),
    name: types.optional(StringModel, () =>
      StringModel.create({ label: "Name", validationKey: "nodePoolName", isRequired: true })
    ),
    labels: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    taints: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    count: types.optional(StringModel, () => NumberModel.create({ label: "Count", min: 0, max: 4, isRequired: true })),
    arch: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Arch",
        options: [
          { label: "Any", value: "any" },
          { label: "Amd64", value: "amd64" },
          { label: "Arm64", value: "arm64" },
        ],
        initialValue: "",
      })
    ),
    flavor: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Flavor",
        options: [
          { label: "Debian", value: "debian" },
          { label: "Fedora", value: "fedora" },
          { label: "Ubuntu", value: "ubuntu" },
        ],
        initialValue: "debian",
      })
    ),
    cpu: types.optional(StringModel, () => StringModel.create({ label: "CPU", isRequired: true })),
    memory: types.optional(StringModel, () => StringModel.create({ label: "Memory", isRequired: true })),
  })
  .actions((self) => ({
    setStatusAdded() {
      self.status = "added";
    },
    reset() {
      self.name.setInitialValue(self._pool.name);
      self.labels = ListOfItemsModel.create({
        _items: self._pool.labels.map((l) => ({ firstValue: l.key, secondValue: l.value })),
      });
      self.taints = ListOfItemsModel.create({
        _items: self._pool.taints.map((t) => ({ firstValue: t.key, secondValue: t.value, thirdValue: t.effect })),
      });
      self.count.setInitialValue(String(self._pool.count));
      self.arch.setInitialValue(self._pool.arch);
      self.flavor.setInitialValue(self._pool.flavor);
      self.cpu.setInitialValue(self._pool.cpu);
      self.memory.setInitialValue(self._pool.memory);
    },
    confirm() {
      self.name.confirm();
      self.labels.confirm();
      self.taints.confirm();
      // no override image
      self.count.confirm();
      self.arch.confirm();
      self.flavor.confirm();
      self.cpu.confirm();
      self.memory.confirm();

      const _pool: any = {
        name: self.name.value, //
        labels: self.labels.items.map((l) => ({ key: l.firstValue, value: l.secondValue })),
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
        count: Number(self.count.value),
        arch: self.arch.value,
        flavor: self.flavor.value,
        cpu: self.cpu.value,
        memory: self.memory.value,
      };

      self._pool = Mk8sDraftProviderEphemeralNodePoolsReadonlyModel.create(_pool);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isLabelsValid() {
      let res = true;
      if (self.labels.items.some((l) => !l.firstValue || !l.secondValue)) res = false;
      return res;
    },
    get isTaintsValid() {
      let res = true;
      if (self.taints.items.some((t) => !t.firstValue || !t.secondValue || !t.thirdValue)) res = false;
      return res;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.isLabelsValid) res = false;
      if (!self.isTaintsValid) res = false;
      if (!self.count.isValid) res = false;
      if (!self.arch.value) res = false;
      if (!self.flavor.value) res = false;
      if (!self.cpu.isValid) res = false;
      if (!self.memory.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.name.isValid) reason = "name is invalid";
      if (!self.isLabelsValid) reason = "labels not valid";
      if (!self.isTaintsValid) reason = "taints not valid";
      if (!self.count.isValid) reason = "count not valid";
      if (!self.arch.value) reason = "arch not valid";
      if (!self.flavor.value) reason = "flavor not valid";
      if (!self.cpu.isValid) reason = "cpu not valid";
      if (!self.memory.isValid) reason = "memory not valid";
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.labels.isDirty) res = true;
      if (self.taints.isDirty) res = true;
      if (self.count.isDirty) res = true;
      if (self.arch.isDirty) res = true;
      if (self.flavor.isDirty) res = true;
      if (self.cpu.isDirty) res = true;
      if (self.memory.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.name.isDirty) reason = self.name.isDirtyReason;
      if (self.labels.isDirty) reason = "labels are dirty";
      if (self.taints.isDirty) reason = "taints are dirty";
      if (self.count.isDirty) reason = "count are dirty";
      if (self.arch.isDirty) reason = "arch are dirty";
      if (self.flavor.isDirty) reason = "flavor are dirty";
      if (self.cpu.isDirty) reason = "cpu are dirty";
      if (self.memory.isDirty) reason = "memory are dirty";
      return reason;
    },
    get asObject() {
      const labels: { [_: string]: string } = {};
      self.labels.items.forEach((l) => {
        labels[l.firstValue] = l.secondValue;
      });

      const obj: any = {
        name: self.name.value,
        labels: labels,
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
        count: Number(self.count.value),
        arch: self.arch.value,
        flavor: self.flavor.value,
        cpu: self.cpu.value,
        memory: self.memory.value,
      };
      return obj;
    },
  }));
