import { types } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { defaultValues } from "../base";
import { StringModel } from "../../mobxDataModels/stringModel";
import { PortModel } from "../../mobxDataModels/portModel";

export const ContainerPortReadOnly = types.model({
  protocol: types.string,
  number: types.number,
});

export const ContainerPortModel = types
  .model({
    procotol: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Protocol",
        initialValue: defaultValues.workload.container.port.protocol,
        options: [
          { label: "HTTP", value: "http" },
          { label: "HTTP2", value: "http2" },
          { label: "GRPC", value: "grpc" },
          { label: "TCP", value: "tcp" },
        ],
      })
    ),
    number: types.optional(StringModel, () =>
      PortModel.create({ isRequired: true, value: defaultValues.workload.container.port.number })
    ),
  })
  .actions((self) => ({
    reset() {
      self.procotol.reset();
      self.number.reset();
    },
    confirm() {
      self.procotol.confirm();
      self.number.confirm();
    },
  }))
  .views((self) => ({
    get isValid() {
      return self.number.isValid;
    },
    get isDirty() {
      return self.procotol.isDirty || self.number.isDirty;
    },
    get asObject() {
      return {
        protocol: self.procotol.value,
        number: Number(self.number.value),
      };
    },
  }));
