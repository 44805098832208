import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { Copy } from "react-feather";

interface Props {
  column: string;
  value: string;
}
const DNSValueRaw: React.FC<Props> = ({ column, value }) => {
  function handleCopyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({ message: "Copied to clipboard" });
  }

  return (
    <div className={`${column} flex items-center gap-1`}>
      <div>{value}</div>
      <button onClick={() => handleCopyToClipboard(value)} className="ngfocus color-link">
        <Copy className="feather-icon" />
      </button>
    </div>
  );
};

export const DNSValue = observer(DNSValueRaw);
