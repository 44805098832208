import { LoggingElasticMobx } from "../../../../mst/kinds/org";
import { SelectModel } from "../../../../mobxDataModels/selectModel";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { PortModel } from "../../../../mobxDataModels/portModel";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";

export function getLogsElasticData(elastic: LoggingElasticMobx) {
  function getDefaultElasticProvider(): "aws" | "elasticcloud" | "generic" {
    return elastic.aws ? "aws" : elastic.elasticCloud ? "elasticcloud" : elastic.generic ? "generic" : "aws";
  }
  const elastic_provider = SelectModel.create({
    label: "Elastic Provider",
    initialValue: getDefaultElasticProvider(),
    options: [
      { label: "AWS", value: "aws" },
      { label: "Elastic Cloud", value: "elasticcloud" },
      { label: "Generic", value: "generic" },
    ],
  });

  // Elastic - aws provider
  // host port index type credentials (aws) region

  function getDefaultElastic_AWS_Host() {
    return elastic.aws?.host || "";
  }
  const elastic_aws_host = StringModel.create({
    label: "Host",
    isRequired: true,
    initialValue: getDefaultElastic_AWS_Host(),
  });

  function getDefaultElastic_AWS_Port() {
    return String(elastic.aws?.port || "");
  }
  const elastic_aws_port = PortModel.create({
    label: "Port",
    isRequired: true,
    value: getDefaultElastic_AWS_Port(),
  });

  function getDefaultElastic_AWS_Index() {
    return elastic.aws?.index || "";
  }
  const elastic_aws_index = StringModel.create({
    label: "Index",
    isRequired: true,
    initialValue: getDefaultElastic_AWS_Index(),
  });

  function getDefaultElastic_AWS_Type() {
    return elastic.aws?.type || "";
  }
  const elastic_aws_type = StringModel.create({
    label: "Type",
    isRequired: true,
    initialValue: getDefaultElastic_AWS_Type(),
  });

  function getDefaultElastic_AWS_Region() {
    return elastic.aws?.region || "";
  }
  const elastic_aws_region = StringModel.create({
    label: "Region",
    isRequired: true,
    initialValue: getDefaultElastic_AWS_Region(),
  });

  function getDefaultElastic_AWS_Credentials() {
    return ngParseLink(elastic.aws?.credentials || "", { kind: "secret" }).name;
  }
  const elastic_aws_credentials = StringModel.create({
    isRequired: true,
    label: "Credentials",
    initialValue: getDefaultElastic_AWS_Credentials(),
  });

  // Elastic - elastic cloud provider
  // index type credentials (userpass) cloudId

  function getDefaultElastic_ElasticCloud_Index() {
    return elastic.elasticCloud?.index || "";
  }
  const elastic_elasticCloud_index = StringModel.create({
    label: "Index",
    isRequired: true,
    initialValue: getDefaultElastic_ElasticCloud_Index(),
  });

  function getDefaultElastic_ElasticCloud_Type() {
    return elastic.elasticCloud?.type || "";
  }
  const elastic_elasticCloud_type = StringModel.create({
    label: "Type",
    isRequired: true,
    initialValue: getDefaultElastic_ElasticCloud_Type(),
  });

  function getDefaultElastic_ElasticCloud_CloudId() {
    return elastic.elasticCloud?.cloudId || "";
  }
  const elastic_elasticCloud_cloudId = StringModel.create({
    label: "Cloud ID",
    isRequired: true,
    initialValue: getDefaultElastic_ElasticCloud_CloudId(),
  });

  function getDefaultElastic_ElasticCloud_Credentials() {
    return ngParseLink(elastic.elasticCloud?.credentials || "", { kind: "secret" }).name;
  }
  const elastic_elasticCloud_credentials = StringModel.create({
    isRequired: true,
    label: "Credentials",
    initialValue: getDefaultElastic_ElasticCloud_Credentials(),
  });

  // elastic generic
  function getDefaultElastic_Generic_Host() {
    return elastic.generic?.host || "";
  }
  const elastic_generic_host = StringModel.create({
    label: "Host",
    isRequired: true,
    initialValue: getDefaultElastic_Generic_Host(),
  });

  function getDefaultElastic_Generic_Port() {
    return String(elastic.generic?.port || "443");
  }
  const elastic_generic_port = PortModel.create({
    label: "Port",
    value: getDefaultElastic_Generic_Port(),
  });

  function getDefaultElastic_Generic_Path() {
    return elastic.generic?.path || "";
  }
  const elastic_generic_path = StringModel.create({
    label: "Path",
    initialValue: getDefaultElastic_Generic_Path(),
  });

  function getDefaultElastic_Generic_Index() {
    return elastic.generic?.index || "";
  }
  const elastic_generic_index = StringModel.create({
    label: "Index",
    isRequired: true,
    initialValue: getDefaultElastic_Generic_Index(),
  });

  function getDefaultElastic_Generic_Type() {
    return elastic.generic?.type || "";
  }
  const elastic_generic_type = StringModel.create({
    label: "Type",
    isRequired: true,
    initialValue: getDefaultElastic_Generic_Type(),
  });

  function getDefaultElastic_Generic_Credentials() {
    return ngParseLink(elastic.generic?.credentials || "", { kind: "secret" }).name;
  }

  const elastic_generic_credentials = StringModel.create({
    isRequired: true,
    label: "Credentials",
    initialValue: getDefaultElastic_Generic_Credentials(),
  });

  function isDirty() {
    let res = false;
    if (getDefaultElasticProvider() !== elastic_provider.value) {
      res = true;
    }
    if (elastic_provider.value === "aws") {
      if (getDefaultElastic_AWS_Host() !== elastic_aws_host.value) {
        res = true;
      }
      if (getDefaultElastic_AWS_Index() !== elastic_aws_index.value) {
        res = true;
      }
      if (getDefaultElastic_AWS_Port() !== elastic_aws_port.value) {
        res = true;
      }
      if (getDefaultElastic_AWS_Region() !== elastic_aws_region.value) {
        res = true;
      }
      if (getDefaultElastic_AWS_Type() !== elastic_aws_type.value) {
        res = true;
      }
      if (getDefaultElastic_AWS_Credentials() !== elastic_aws_credentials.value) {
        res = true;
      }
    }
    if (elastic_provider.value === "elasticcloud") {
      if (getDefaultElastic_ElasticCloud_CloudId() !== elastic_elasticCloud_cloudId.value) {
        res = true;
      }
      if (getDefaultElastic_ElasticCloud_Index() !== elastic_elasticCloud_index.value) {
        res = true;
      }
      if (getDefaultElastic_ElasticCloud_Type() !== elastic_elasticCloud_type.value) {
        res = true;
      }
      if (getDefaultElastic_ElasticCloud_Credentials() !== elastic_elasticCloud_credentials.value) {
        res = true;
      }
    }
    if (elastic_provider.value === "generic") {
      if (getDefaultElastic_Generic_Host() !== elastic_generic_host.value) {
        res = true;
      }
      if (getDefaultElastic_Generic_Index() !== elastic_generic_index.value) {
        res = true;
      }
      if (getDefaultElastic_Generic_Port() !== elastic_generic_port.value) {
        res = true;
      }
      if (getDefaultElastic_Generic_Path() !== elastic_generic_path.value) {
        res = true;
      }
      if (getDefaultElastic_Generic_Type() !== elastic_generic_type.value) {
        res = true;
      }
      if (getDefaultElastic_Generic_Credentials() !== elastic_generic_credentials.value) {
        res = true;
      }
    }
    return res;
  }

  function isValid() {
    let res = true;
    if (elastic_provider.value === "aws") {
      if (elastic_aws_host.value.length < 1) {
        res = false;
      }
      if (elastic_aws_index.value.length < 1) {
        res = false;
      }
      if (elastic_aws_port.value.length < 1) {
        res = false;
      }
      if (elastic_aws_region.value.length < 1) {
        res = false;
      }
      if (elastic_aws_type.value.length < 1) {
        res = false;
      }
      if (elastic_aws_credentials.value.length < 1) {
        res = false;
      }
    }
    if (elastic_provider.value === "elasticcloud") {
      if (elastic_elasticCloud_cloudId.value.length < 1) {
        res = false;
      }
      if (elastic_elasticCloud_index.value.length < 1) {
        res = false;
      }
      if (elastic_elasticCloud_type.value.length < 1) {
        res = false;
      }
      if (elastic_elasticCloud_credentials.value.length < 1) {
        res = false;
      }
    }
    if (elastic_provider.value === "generic") {
      if (elastic_generic_host.value.length < 1) {
        res = false;
      }
      if (elastic_generic_index.value.length < 1) {
        res = false;
      }
      if (elastic_generic_type.value.length < 1) {
        res = false;
      }
      if (elastic_generic_credentials.value.length < 1) {
        res = false;
      }
    }
    return res;
  }

  function asObject() {
    const elastic: any = {};
    if (elastic_provider.value === "aws") {
      elastic.aws = {
        credentials: ngParseLink(elastic_aws_credentials.value, { kind: "secret" }).absolute,
        host: elastic_aws_host.value,
        index: elastic_aws_index.value,
        port: elastic_aws_port.value,
        region: elastic_aws_region.value,
        type: elastic_aws_type.value,
      };
    } else if (elastic_provider.value === "elasticcloud") {
      elastic.elasticCloud = {
        cloudId: elastic_elasticCloud_cloudId.value,
        credentials: ngParseLink(elastic_elasticCloud_credentials.value, { kind: "secret" }).absolute,
        index: elastic_elasticCloud_index.value,
        type: elastic_elasticCloud_type.value,
      };
    } else if (elastic_provider.value === "generic") {
      elastic.generic = {
        credentials: ngParseLink(elastic_generic_credentials.value, { kind: "secret" }).absolute,
        host: elastic_generic_host.value,
        index: elastic_generic_index.value,
        type: elastic_generic_type.value,
      };

      if (elastic_generic_port.value.length > 0 && !Number.isNaN(elastic_generic_port.value)) {
        elastic.generic.port = Number(elastic_generic_port.value);
      }
      if (elastic_generic_path.value.length > 0) {
        elastic.generic.path = elastic_generic_path.value;
      }
    }
    return { elastic: elastic };
  }

  return {
    logProvider: "elastic",
    provider: elastic_provider,
    aws_host: elastic_aws_host,
    aws_port: elastic_aws_port,
    aws_index: elastic_aws_index,
    aws_type: elastic_aws_type,
    aws_region: elastic_aws_region,
    aws_credentials: elastic_aws_credentials,
    elasticCloud_index: elastic_elasticCloud_index,
    elasticCloud_type: elastic_elasticCloud_type,
    elasticCloud_cloudId: elastic_elasticCloud_cloudId,
    elasticCloud_credentials: elastic_elasticCloud_credentials,
    generic_host: elastic_generic_host,
    generic_port: elastic_generic_port,
    generic_path: elastic_generic_path,
    generic_index: elastic_generic_index,
    generic_type: elastic_generic_type,
    generic_credentials: elastic_generic_credentials,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
