import { types } from "mobx-state-tree";

export const TaintModel = types.model({
  key: types.string, // labelname
  value: types.string, // labelvalue
  effect: types.string, // "NoSchedule", "PreferNoSchedule", "NoExecute"
});

export const UnmanagedPoolModel = types.model("Hetzner Dedicated Server Node Pool", {
  name: types.string, // regex /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/
  labels: types.maybe(types.frozen()), // ask for examples
  taints: types.array(TaintModel),
});
