import { types } from "mobx-state-tree";
import { AzureRoleAssignmentModel } from "../kinds/identity";

export interface AzureIdentityDataSnapshotRoleAssignment {
  scope: string;
  scopeDataItem?: { href: string; ref: string };
  roles: string[];
}
export interface AzureIdentityDataSnapshot {
  cloudAccountLink: string;
  roleAssignments: AzureIdentityDataSnapshotRoleAssignment[];
}

export const AzureIdentityData = types
  .model({
    isActive: false,
    // this was a cloudaccount instance references
    cloudaccountLink: types.optional(types.string, ""),

    roleAssignments: types.array(types.late(() => AzureRoleAssignmentModel)),
  })
  .actions((self) => ({
    clear() {
      self.isActive = false;
      self.cloudaccountLink = "";
      self.roleAssignments.clear();
    },
    apply(obj: AzureIdentityDataSnapshot) {
      self.isActive = true;
      self.cloudaccountLink = obj.cloudAccountLink;
      self.roleAssignments.clear();
      for (let roleAssignment of obj.roleAssignments) {
        const instance = AzureRoleAssignmentModel.create();
        instance.apply(roleAssignment);
        self.roleAssignments.push(instance);
      }
    },
  }))
  .views((self) => ({
    get asObject(): AzureIdentityDataSnapshot {
      return {
        cloudAccountLink: self.cloudaccountLink,
        roleAssignments: self.roleAssignments.map((roleAssignment) => {
          const item: AzureIdentityDataSnapshotRoleAssignment = {
            scope: roleAssignment.forEdit.scopeInput.value,
            roles: [...roleAssignment.forEdit.roles],
          };
          if (roleAssignment.forEdit.scopeDataItem) {
            item.scopeDataItem = {
              href: roleAssignment.forEdit.scopeDataItem.href,
              ref: roleAssignment.forEdit.scopeDataItem.ref,
            };
          }
          return item;
        }),
      };
    },
    get asObjectForHTTP(): AzureIdentityDataSnapshot {
      return {
        cloudAccountLink: self.cloudaccountLink,
        roleAssignments: self.roleAssignments.map((roleAssignment) => ({
          scope: roleAssignment.forEdit.scopeInput.value,
          roles: [...roleAssignment.forEdit.roles],
        })),
      };
    },
  }));
