import * as React from "react";
import { flowResult } from "mobx";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation } from "react-router-dom";
import { useOrgEmitter } from "../../../emitters/org";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { UIData } from "../../../mobxStores/uiData/uiData";
import { UserData } from "../../../mobxStores/userData/userData";
import { OrgIcon } from "../../Icons";
import { LinkWithChange } from "./LinkWithChange";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { MenuLinkProps } from "./menu";

const OrgMenuLinkRaw: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { org, gvc } = ConsoleContext;

  const { AccountUUID } = BillingContext;
  const menuLinkProps: MenuLinkProps = {
    url: `/console/org/${org}`,
    prefix: "Org",
    label: org || "",
    testId: "context-org-menu-item",
    Icon: OrgIcon,
    tooltip: {
      content: org || "no org",
      disable: (org || "").length < 16,
      placement: "top",
    },
    customIsActive: isActive,
  };

  function isActive(): boolean {
    return (
      pathname === `/console/org/${org}` ||
      pathname.includes(`/console/org/${org}/-info`) ||
      pathname.includes(`/console/org/${org}/-auth-config`) ||
      pathname.includes(`/console/org/${org}/-externallogs`) ||
      pathname.includes(`/console/org/${org}/-tracing`) ||
      pathname.includes(`/console/org/${org}/-observability`) ||
      pathname.includes(`/console/org/${org}/-threat-detection`) ||
      pathname.includes(`/console/org/${org}/-tags`) ||
      pathname.includes(`/console/org/${org}/-events`) ||
      pathname.includes(`/console/org/${org}/-audittrail`) ||
      pathname.includes(`/console/org/${org}/-accessreport`)
    );
  }

  function createItem(): void {
    if (AccountUUID) {
      navigate(`/billing/account/${AccountUUID}/-tryorgcreate`);
    } else {
      navigate(`/billing/account/-select?action=tryCreateOrg`);
    }
  }

  async function requestItems(): Promise<void> {
    await flowResult(UserData.requestOrgNames());
  }

  async function handleOrgChange(orgToChange: string) {
    ConsoleContext.setIsChangingContext(true);
    const oldOrg = org!;
    const oldGVC = gvc;
    await flowResult(ConsoleContext.setOrg(orgToChange));
    const localTabGVC = localStorage.getItem(`${UIData.tabId}-${ConsoleContext.org!}`) || "";
    const localGeneralGVC = localStorage.getItem(ConsoleContext.org!) || "";
    let newGVC = "";
    if (UserData.gvcNames.includes(localTabGVC)) {
      newGVC = localTabGVC;
    } else if (UserData.gvcNames.includes(localGeneralGVC)) {
      newGVC = localGeneralGVC;
    } else {
      newGVC = UserData.gvcNames[0];
    }
    if (newGVC) {
      ConsoleContext.setGVC(newGVC);
    } else {
      ConsoleContext.clearGVC();
    }
    // Update url
    let newPathname = pathname;
    newPathname = newPathname.replace(`/${oldOrg}/`, `/${orgToChange}/`);
    if (oldGVC && newGVC) {
      newPathname = newPathname.replace(`/${oldGVC}/`, `/${newGVC}/`);
    }
    if (newPathname.includes("-select")) {
      newPathname = newPathname.split("-select")[0];
    }
    PromptContext.setIsDisabled(true);
    ConsoleContext.setRedirect(newPathname);
  }

  return (
    <LinkWithChange
      kind="org"
      mountElementId="orgChange"
      label={org}
      items={UserData.orgNames.map((name) => ({ value: name, labels: [name] }))}
      value={ConsoleContext.org}
      menuLinkProps={menuLinkProps}
      isActiveFunc={isActive}
      selectItem={(orgToChange) => handleOrgChange(orgToChange)}
      createItem={createItem}
      useEmitter={useOrgEmitter}
      requestItems={requestItems}
      canBePinned={true}
    />
  );
};

export const OrgMenuLink = observer(OrgMenuLinkRaw);
