import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel } from "../base";
import { request } from "../../services/cpln";
import { StringModel } from "../../mobxDataModels/stringModel";
import { AzureIdentityDataSnapshotRoleAssignment } from "../stores/identity.azure.data";
import { GCPIdentityDataSnapshotBinding } from "../stores/identity.gcp.data";
import { IS_TEST } from "../../envVariables";
import { BrowserData } from "./browser";

const AwsPolicyModel = types.model("IdentityAwsPolicy", {
  Version: "2012-10-17",
  Statement: types.array(types.frozen()),
});

const AwsIdentityModel = types.model("AwsIdentity", {
  cloudAccountLink: types.optional(types.string, ""),
  policyRefs: types.array(types.string),
  trustPolicy: types.maybe(AwsPolicyModel),
  roleName: types.maybe(types.string),
  mirrors: types.array(types.frozen()),
});

const GCPBindingEditModel = types
  .model({
    resourceDataItem: types.maybe(types.frozen({ href: "", ref: "" })),
    resourceInput: types.optional(
      types.late(() => StringModel),
      () =>
        StringModel.create({
          label: "Resource",
          isRequired: true,
        })
    ),
    roleInput: types.optional(
      types.late(() => StringModel),
      () => StringModel.create({ label: "Role" })
    ),
    roles: types.array(types.string),
    isSelectingResource: types.optional(types.boolean, false),
    isSelectingRoles: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    removeRole(value: string) {
      const item = self.roles.find((role) => role === value);
      if (item) {
        self.roles.remove(item);
      }
    },
    addRole() {
      if (!self.roleInput.isValid) return;
      const item = self.roles.find((role) => role === self.roleInput.value);
      if (!item) {
        self.roles.push(self.roleInput.value);
        self.roleInput.reset();
      }
    },
    selectResource() {
      self.isSelectingResource = true;
    },
    selectRoles() {
      self.isSelectingRoles = true;
    },
    clearResourceDataItem() {
      self.resourceDataItem = undefined;
    },
    doneSelectResource(selections: BrowserData[]) {
      if (selections.length < 1 || selections.length > 1) {
        self.isSelectingResource = false;
        return;
      }
      const item = selections[0];
      if (self.resourceInput.value !== item._cpln.ref) {
        self.roles.clear();
      }
      self.resourceInput.setValue(item._cpln.ref!);
      self.resourceDataItem = { href: item._cpln.href || "", ref: item._cpln.ref };
      self.isSelectingResource = false;
    },
    doneSelectRoles(selections: string[]) {
      if (selections.length < 1) {
        self.isSelectingRoles = false;
        return;
      }
      self.roles.clear();
      for (let selection of selections) {
        self.roles.push(selection);
      }
      self.isSelectingRoles = false;
    },
  }))
  .actions((self) => {
    const reverseLookup: (caLink: string) => Promise<void> = flow(function* (caLink: string) {
      if (IS_TEST) return;
      if (self.resourceDataItem) return;
      if (!self.resourceInput.value) return;
      const resource = self.resourceInput.value;
      try {
        const lookupUrl = `/browse${caLink}/-resolve?ref=${resource}`;
        const { data } = yield request({ service: "browser", url: lookupUrl });
        self.resourceDataItem = {
          href: data.links.find((l: any) => l.rel === "self").href,
          ref: resource,
        };
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        console.error("Reverse lookup error", errorMessage);
      }
    });
    const apply: (obj: GCPIdentityDataSnapshotBinding) => Promise<void> = flow(function* (
      obj: GCPIdentityDataSnapshotBinding
    ) {
      self.resourceInput.setInitialValue(obj.resource);
      self.roles.clear();
      for (let role of obj.roles) {
        self.roles.push(role);
      }

      if (obj.resourceDataItem) {
        self.resourceDataItem = {
          href: obj.resourceDataItem.href,
          ref: obj.resourceDataItem.ref,
        };
      } else {
        self.resourceDataItem = undefined;
      }
    });
    return { apply, reverseLookup };
  })
  .views((self) => ({
    get isValid() {
      if (self.resourceInput.value && !self.resourceInput.isValid) return false;
      if (self.roleInput.value.length > 0) return false;
      return self.roles.length > 0;
    },
    get resourceIsManual() {
      if (!self.resourceDataItem) return true;
      return self.resourceDataItem.ref !== self.resourceInput.value;
    },
  }));

export const GCPBindingModel = types
  .model("GCPBinding", {
    resource: types.optional(types.string, ""),
    roles: types.array(types.string), // /^roles\/([a-zA-Z0-9])+(\.([a-zA-Z0-9])+)?$/
    forEdit: types.optional(GCPBindingEditModel, () => GCPBindingEditModel.create()),
  })
  .actions((self) => ({
    apply(obj: GCPIdentityDataSnapshotBinding) {
      self.forEdit.apply(obj);
    },
  }));
export interface GCPBindingMobx extends Instance<typeof GCPBindingModel> {}

const GcpIdentityModel = types.model("GcpIdentity", {
  cloudAccountLink: types.optional(types.string, ""),
  scopes: types.optional(types.array(types.string), ["https://www.googleapis.com/auth/cloud-platform"]),
  serviceAccount: types.maybe(types.string), // /^.*\.gserviceaccount\.com$/
  bindings: types.array(GCPBindingModel),
  mirrors: types.array(types.frozen()),
});

const NgsPermModel = types.model({
  allow: types.array(types.string),
  deny: types.array(types.string),
});

const NgsIdentityModel = types.model("NgsIdentity", {
  cloudAccountLink: types.optional(types.string, ""),
  pub: types.optional(NgsPermModel, () => NgsPermModel.create()),
  sub: types.optional(NgsPermModel, () => NgsPermModel.create()),
  resp: types.optional(
    types.model({
      max: types.optional(types.number, 1),
      ttl: types.optional(types.string, ""),
    }),
    {}
  ),
  subs: types.optional(types.number, -1),
  data: types.optional(types.number, -1),
  payload: types.optional(types.number, -1),
});

const AzureRoleAssignmentEditModel = types
  .model({
    scopeDataItem: types.maybe(types.frozen({ href: "", ref: "" })),
    scopeInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Scope",
        isRequired: true,
      })
    ),
    roleInput: types.optional(StringModel, () => StringModel.create({ label: "Role" })),
    roles: types.array(types.string),
    isSelectingScope: types.optional(types.boolean, false),
    isSelectingRoles: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    removeRole(value: string) {
      const item = self.roles.find((role) => role === value);
      if (item) {
        self.roles.remove(item);
      }
    },
    addRole() {
      if (!self.roleInput.isValid) return;
      const item = self.roles.find((role) => role === self.roleInput.value);
      if (!item) {
        self.roles.push(self.roleInput.value);
        self.roleInput.reset();
      }
    },
    selectScope() {
      self.isSelectingScope = true;
    },
    selectRoles() {
      self.isSelectingRoles = true;
    },
    clearScopeDataItem() {
      self.scopeDataItem = undefined;
    },
    doneSelectScope(selections: BrowserData[]) {
      if (selections.length < 1) {
        self.isSelectingScope = false;
        return;
      }
      const item = selections[0];
      if (self.scopeInput.value !== item._cpln.ref) {
        self.roles.clear();
      }
      self.scopeInput.setValue(item._cpln.ref!);
      self.scopeDataItem = { href: item._cpln.href || "", ref: item._cpln.ref };
      self.isSelectingScope = false;
    },
    doneSelectRoles(selections: string[]) {
      if (selections.length < 1) {
        self.isSelectingRoles = false;
        return;
      }
      self.roles.clear();
      for (let selection of selections) {
        self.roles.push(selection);
      }
      self.isSelectingRoles = false;
    },
  }))
  .actions((self) => {
    const reverseLookup: (caLink: string) => Promise<void> = flow(function* (caLink: string) {
      if (IS_TEST) return;
      if (self.scopeDataItem) return;
      if (!self.scopeInput.value) return;
      const scope = self.scopeInput.value;
      const lookupUrl = `/browse${caLink}/-resolve?ref=${scope}`;
      try {
        const { data } = yield request({ service: "browser", url: lookupUrl });
        self.scopeDataItem = { href: data.links.find((l: any) => l.rel === "self").href, ref: scope };
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
      }
    });
    const apply: (obj: AzureIdentityDataSnapshotRoleAssignment) => Promise<void> = flow(function* (
      obj: AzureIdentityDataSnapshotRoleAssignment
    ) {
      self.scopeInput.setInitialValue(obj.scope);
      self.roles.clear();
      for (let role of obj.roles) {
        self.roles.push(role);
      }
      if (obj.scopeDataItem) {
        self.scopeDataItem = {
          href: obj.scopeDataItem.href,
          ref: obj.scopeDataItem.ref,
        };
      } else {
        self.scopeDataItem = undefined;
      }
    });
    return { apply, reverseLookup };
  })
  .views((self) => ({
    get isValid() {
      if (self.scopeInput.value && !self.scopeInput.isValid) return false;
      if (self.roleInput.value.length > 0) return false;
      return self.roles.length > 0;
    },
    get scopeIsManual() {
      if (!self.scopeDataItem) return true;
      return self.scopeDataItem.ref !== self.scopeInput.value;
    },
  }));

export const AzureRoleAssignmentModel = types
  .model("AzureRoleAssignment", {
    scope: types.optional(types.string, ""),
    roles: types.array(types.string),
    forEdit: types.optional(AzureRoleAssignmentEditModel, () => AzureRoleAssignmentEditModel.create()),
  })
  .actions((self) => ({
    apply(obj: AzureIdentityDataSnapshotRoleAssignment) {
      self.forEdit.apply(obj);
    },
  }));
export interface AzureRoleAssignmentMobx extends Instance<typeof AzureRoleAssignmentModel> {}

const AzureIdentityModel = types.model("AzureIdentity", {
  cloudAccountLink: types.optional(types.string, ""),
  roleAssignments: types.array(AzureRoleAssignmentModel),
  mirrors: types.array(types.frozen()),
});

const IdentityStatusProviderModel = types.model("IdentityStatusAws", {
  usable: types.maybe(types.boolean),
  lastError: types.maybe(types.string),
});

const IdentityStatusModel = types.model({
  objectName: types.optional(types.string, ""),
  aws: types.maybe(IdentityStatusProviderModel),
  azr: types.maybe(IdentityStatusProviderModel),
  gcp: types.maybe(IdentityStatusProviderModel),
});

const IdentityNetworkResourceModel = types.model({
  name: types.string,
  agentLink: types.string,
  IPs: types.array(types.string),
  FQDN: types.maybe(types.string),
  resolverIP: types.maybe(types.string),
  ports: types.array(types.number),
});
export interface IdentityNetworkResourceMobx extends Instance<typeof IdentityNetworkResourceModel> {}
export interface IdentityNetworkResource {
  name: string;
  agentLink: string;
  IPs: string[] | undefined;
  FQDN: string | undefined;
  resolverIP: string | undefined;
  ports: number[];
}

const IdentityNativeNetworkResourceModel = types.model({
  name: types.string,
  FQDN: types.optional(types.string, ""),
  ports: types.array(types.number),
  awsPrivateLink: types.maybe(
    types.model({
      endpointServiceName: types.optional(types.string, ""),
    })
  ),
  gcpServiceConnect: types.maybe(
    types.model({
      targetService: types.optional(types.string, ""),
    })
  ),
});
export interface IdentityNativeNetworkResourceMobx extends Instance<typeof IdentityNativeNetworkResourceModel> {}
export interface IdentityNativeNetworkResource {
  name: string;
  FQDN: string;
  ports: number[];
  awsPrivateLink:
    | {
        endpointServiceName: string | undefined;
      }
    | undefined;
  gcpServiceConnect: { targetService: string | undefined } | undefined;
}

export const IdentityModel = types
  .compose(
    BaseModel,
    types.model({
      aws: types.maybe(AwsIdentityModel),
      azure: types.maybe(AzureIdentityModel),
      gcp: types.maybe(GcpIdentityModel),
      ngs: types.maybe(NgsIdentityModel),
      status: types.optional(IdentityStatusModel, () => IdentityStatusModel.create()),
      networkResources: types.array(IdentityNetworkResourceModel),
      nativeNetworkResources: types.array(IdentityNativeNetworkResourceModel),
    })
  )
  .views((self) => ({
    get gvc() {
      return self.selfLink.split("/")[4];
    },
  }))
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: any) => Promise<void> = flow(function* (body: any) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      delete body.events;
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface IdentityStatusProviderMobx extends Instance<typeof IdentityStatusProviderModel> {}
export interface IdentityMobx extends Instance<typeof IdentityModel> {}
