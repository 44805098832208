import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { Mk8sList } from "./list";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Mk8sDetailRoute } from "./detailRoute";
import { Mk8sCreateRoute } from "./create/createRoute";

const Mk8sRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <div className="dryrun mk8s">
      <Layout key={org}>
        <Routes>
          <Route index element={<Mk8sList />} />
          <Route path={`-create/*`} element={<Mk8sCreateRoute />} />
          <Route path={`:mk8s/*`} element={<Mk8sDetailRoute />} />
        </Routes>
      </Layout>
    </div>
  );
};

export default observer(Mk8sRoute);
