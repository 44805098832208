import { TableColumn } from "../../types";
import { Identity, ProviderStatus } from "../../../../schema/types/identity";
import { NGChip } from "../../../chip";

const typeDefault = { id: "providerStatus", label: "Status" };
export function IdentityStatusColumn<TData extends Identity>(column: TableColumn<TData> = typeDefault) {
  const preset: TableColumn<TData> = {
    ...typeDefault,
    size: 130,
    enableHide: true,
    cell: (p) => {
      const identity = p.row.original;
      const status = identity.status;
      if (!status) {
        return null;
      }
      const providerStatusArr: ProviderStatus[] = [status.aws, status.azr, status.gcp].filter(
        Boolean,
      ) as ProviderStatus[];
      const hasError = providerStatusArr.some((s) => s.usable === false);
      const hasSuccess = providerStatusArr.some((s) => s.usable === true);

      if (!hasError && !hasSuccess) {
        return null;
      }

      return (
        <NGChip //
          size="small"
          variant={hasError ? "error" : "success"}
          label={hasError ? "Unusable" : "Usable"}
        />
      );
    },
  };
  return Object.assign(preset, column);
}
