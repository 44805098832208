import { observer } from "mobx-react-lite";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Mk8sCreateAddons } from "./addons";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { Mk8sCreateAddonsInternalDeveloperPlatform } from "./internalDeveloperPlatform";
import { Mk8sCreateAddonsDashboard } from "./dashboard";
import { Mk8sCreateAddonsAWSWorkloadIdentity } from "./awsWorkloadIdentity";
import { Mk8sCreateAddonsLocalPathStorage } from "./localPathStorage";
import { CreateAddonsAzureWorkloadIdentity } from "./azureWorkloadIdentity";
import { CreateAddonsMetrics } from "./metrics";
import { CreateAddonsLogs } from "./logs";
import { CreateAddonsNvidia } from "./nvidia";
import { CreateAddonsAWSEFS } from "./awsEfs";
import { CreateAddonsAWSECR } from "./awsEcr";
import { CreateAddonsAzureACR } from "./azureAcr";
import { CreateAddonsAWSELB } from "./awsElb";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateAddonsRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateAddons mk8sDraft={mk8sDraft} />} />
      <Route path={`-aws-ecr`} element={<CreateAddonsAWSECR mk8sDraft={mk8sDraft} />} />
      <Route path={`-aws-efs`} element={<CreateAddonsAWSEFS mk8sDraft={mk8sDraft} />} />
      <Route path={`-aws-elb`} element={<CreateAddonsAWSELB mk8sDraft={mk8sDraft} />} />
      <Route path={`-aws-workload-identity`} element={<Mk8sCreateAddonsAWSWorkloadIdentity mk8sDraft={mk8sDraft} />} />
      <Route path={`-azure-acr`} element={<CreateAddonsAzureACR mk8sDraft={mk8sDraft} />} />
      <Route path={`-azure-workload-identity`} element={<CreateAddonsAzureWorkloadIdentity mk8sDraft={mk8sDraft} />} />
      <Route path={`-dashboard`} element={<Mk8sCreateAddonsDashboard mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-internal-developer-platform`}
        element={<Mk8sCreateAddonsInternalDeveloperPlatform mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-local-path-storage`} element={<Mk8sCreateAddonsLocalPathStorage mk8sDraft={mk8sDraft} />} />
      <Route path={`-logs`} element={<CreateAddonsLogs mk8sDraft={mk8sDraft} />} />
      <Route path={`-metrics`} element={<CreateAddonsMetrics mk8sDraft={mk8sDraft} />} />
      <Route path={`-nvidia`} element={<CreateAddonsNvidia mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateAddonsRoute = observer(Mk8sCreateAddonsRouteRaw);
