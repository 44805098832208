import { types } from "mobx-state-tree";
import { TaintModel, UnmanagedPoolModel } from "./common";
import { AutoscalerModel } from "./autoscaler";
import { PreInstallScriptProperty } from "./preInstallScript";

const PaperspaceProviderNodePoolModel = types.model("Paperspace Provider Node Pool", {
  name: types.string,
  publicIpType: types.enumeration(["none", "dynamic", "static"]),
  labels: types.frozen(),
  taints: types.array(TaintModel),
  minSize: types.number,
  maxSize: types.number,
  bootDiskSize: types.number, // 50, 100, 250, 500, 1000, 2000
  machineType: types.string,
});

export const PaperspaceProviderModel = types.model("Paperspace Provider", {
  region: types.optional(types.string, ""),
  tokenSecretLink: types.string,
  sharedDrives: types.array(types.string),
  nodePools: types.array(PaperspaceProviderNodePoolModel),
  unmanagedNodePools: types.array(UnmanagedPoolModel),
  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
  preInstallScript: PreInstallScriptProperty,
  userIds: types.array(types.string),
  networkId: types.string,
});
