import { types, Instance, flow, applySnapshot } from "mobx-state-tree";
import { BaseModel } from "../base";
import { request } from "../../services/cpln";

export const AuditContextModel = types
  .compose(
    "Audit Context",
    BaseModel,
    types.model({
      status: types.maybe(types.frozen()),
      origin: types.optional(types.string, "default"),
    })
  )
  .views((self) => ({
    get statusText() {
      if (self.status) {
        const text: string[] = [];
        for (let key of Object.keys(self.status).slice().sort()) {
          const value = self.status[key];
          text.push(`${key}: ${value}`);
        }
        return text;
      }
      return "Status not found";
    },
  }))
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface AuditContextMobx extends Instance<typeof AuditContextModel> {}
