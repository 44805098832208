import * as React from "react";
import { NGFormData } from "../mobxStores/ngFormData";

export interface NGFormElementState {
  focused: boolean;
  visited: boolean;
  touched: boolean;
}

export const NGFormContext = React.createContext<NGFormData>(null as any);

export const useNGFormContext = () => {
  return React.useContext(NGFormContext);
};

// export const useNGFormContext: (name?: string) => NGFormContextTypeBasic = (name) => {
//   if (!name) {
//     const fakeContextValue: NGFormContextTypeBasic = {
//       get: () => ({ focused: false, visited: false, touched: false }),
//       set: (state: Partial<NGFormElementState>) => {},
//       formState: {} as NGFormContextTypeState,
//     };
//     return fakeContextValue;
//   }

//   const { get: getRaw, set: setRaw, formState } = React.useContext(NGFormContext);

//   const get = () => getRaw(name);
//   const set = (state: Partial<NGFormElementState>) => setRaw(name, state);

//   const contextValue: NGFormContextTypeBasic = { get: get, set: set, formState: formState };
//   return contextValue;
// };
