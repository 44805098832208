import * as React from "react";
import { Copy } from "react-feather";
import { NGLabel } from "../../newcomponents/text/label";
import { notification } from "antd";

interface Props {
  label: string;
  value: string;
  canCopy?: boolean;
}
export const FormElement: React.FC<Props> = ({ label, value, canCopy }) => {
  return (
    <>
      <NGLabel>{label}</NGLabel>
      <div className="flex items-center">
        <div>{value}</div>
        {canCopy ? (
          <button onClick={onCopy} className="ngfocus text-sm color-link-hover">
            <Copy className="ml-1 feather-icon inline-block" />
          </button>
        ) : null}
      </div>
    </>
  );

  function onCopy() {
    if (!canCopy) {
      return;
    }

    navigator.clipboard.writeText(value);
    notification.success({ message: `Copied ${label.toLowerCase()} to clipboard` });
  }
};
