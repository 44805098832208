import * as React from "react";

interface Props {
  className?: string;
  size?: number;
}

export const AwsPolicyIcon: React.FC<Props> = ({ className = "", size = 16 }) => {
  return (
    // <Tooltip title={""}>
    <img
      style={{ width: size, height: size }} // up to 24
      className={`inline-block object-contain ${className}`}
      src={`/resources/logos/awsPolicyLogo.png`}
      alt={"AWS Policy Logo"}
    />
    // </Tooltip>
  );
};
