import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NumberModel } from "../../mobxDataModels/numberModel";

export const Mk8sDraftProviderTritonLoadBalancerManualModel = types
  .model({
    _packageId: types.optional(types.string, ""),
    packageId: types.optional(StringModel, () => StringModel.create({ label: "Package Id", isRequired: true })),
    _imageId: types.optional(types.string, ""),
    imageId: types.optional(StringModel, () => StringModel.create({ label: "Image Id", isRequired: true })),
    _publicNetworkId: types.optional(types.string, ""),
    publicNetworkId: types.optional(StringModel, () =>
      StringModel.create({ label: "Public Network Id", isRequired: true }),
    ),
    _count: types.optional(types.number, 1),
    count: types.optional(StringModel, () => NumberModel.create({ label: "Count", min: 1, max: 3 })),
    _cnsInternalDomain: types.optional(types.string, ""),
    cnsInternalDomain: types.optional(StringModel, () =>
      StringModel.create({ label: "CNS Internal Domain", isRequired: true }),
    ),
    _cnsPublicDomain: types.optional(types.string, ""),
    cnsPublicDomain: types.optional(StringModel, () =>
      StringModel.create({ label: "CNS Public Domain", isRequired: true }),
    ),
  })
  .actions((self) => ({
    reset() {
      self.packageId.setInitialValue(self._packageId);
      self.imageId.setInitialValue(self._imageId);
      self.publicNetworkId.setInitialValue(self._publicNetworkId);
      self.count.setInitialValue(String(self._count));
      self.cnsInternalDomain.setInitialValue(self._cnsInternalDomain);
      self.cnsPublicDomain.setInitialValue(self._cnsPublicDomain);
    },
    confirm() {
      self._packageId = self.packageId.value;
      self.packageId.confirm();
      self._imageId = self.imageId.value;
      self.imageId.confirm();
      self._publicNetworkId = self.publicNetworkId.value;
      self.publicNetworkId.confirm();
      self._count = Number(self.count.value);
      self.count.confirm();
      self._cnsInternalDomain = self.cnsInternalDomain.value;
      self.cnsInternalDomain.confirm();
      self._cnsPublicDomain = self.cnsPublicDomain.value;
      self.cnsPublicDomain.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.packageId.isDirty) res = true;
      if (self.imageId.isDirty) res = true;
      if (self.publicNetworkId.isDirty) res = true;
      if (self.count.isDirty) res = true;
      if (self.cnsInternalDomain.isDirty) res = true;
      if (self.cnsPublicDomain.isDirty) res = true;
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.packageId.isValid) res = false;
      if (!self.imageId.isValid) res = false;
      if (!self.publicNetworkId.isValid) res = false;
      if (!self.count.isValid) res = false;
      if (!self.cnsInternalDomain.isValid) res = false;
      if (!self.cnsPublicDomain.isValid) res = false;
      return res;
    },
    get asObject() {
      let obj: any = {
        packageId: self.packageId.value,
        imageId: self.imageId.value,
        publicNetworkId: self.publicNetworkId.value,
        count: Number(self.count.value),
        cnsInternalDomain: self.cnsInternalDomain.value,
        cnsPublicDomain: self.cnsPublicDomain.value,
      };
      return obj;
    },
  }));
export interface Mk8sDraftProviderTritonLoadBalancerManualMobx
  extends Instance<typeof Mk8sDraftProviderTritonLoadBalancerManualModel> {}
