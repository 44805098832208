import * as React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { CloudaccountMobx, CloudaccountModel } from "../../../../mst/kinds/cloudaccount";
import { request } from "../../../../services/cpln";
import { LoadingOutlined } from "@ant-design/icons";
import { FormLabel } from "../../../../components/forms/formLabel";
import { Theme } from "../../../../mobxStores/uiData/theme";
import { IdentityDraftNGSHelper } from "../../../../mst/stores/identity.ngs.helper";
import { Value } from "../../../../components/tag/value";
import { NGButton } from "../../../../newcomponents/button/Button";
import { Tooltip } from "../../../../components/Tooltip";

interface Props {
  ngs: IdentityDraftNGSHelper;
  infoOnly: boolean;
}
const NGSIdentityInfoRaw: React.FC<Props> = ({ ngs, infoOnly }) => {
  const { theme } = Theme;
  const [ngsLogoPath, setNgsLogoPath] = React.useState(() => getNgsLogoPath());
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [cloudaccount, setCloudaccount] = React.useState<CloudaccountMobx>(null as any);
  const cloudaccountLink = ngs.edited.cloudaccountLink;

  React.useEffect(() => {
    if (!ngs.edited.isActive) return;
    request({ url: ngs.edited.cloudaccountLink })
      .then(({ data }) => {
        setCloudaccount(CloudaccountModel.create(data));
        setIsPageReady(true);
      })
      .catch(() => {
        setIsPageReady(true);
      });
  }, [ngs.edited.isActive, ngs.edited.cloudaccountLink]);

  React.useEffect(() => {
    setNgsLogoPath(getNgsLogoPath());
  }, [theme]);

  function getNgsLogoPath() {
    return `/resources/logos/ngsLogo.svg`;
  }

  if (!ngs.edited.isActive) {
    if (infoOnly) return null;
    return (
      <button
        data-testid={`ngs-none`}
        onClick={() => {
          ngs.setup();
        }}
        className={`mb-8 p-4 w-full hover:shadow-lg border bg-identity-ngs-logo-drop flex flex-col items-center focus-big`}
      >
        <div className="py-2 flex items-center" style={{ height: 88 }}>
          <img style={{ height: "100%" }} src={ngsLogoPath} />
          <span className="text-6xl ml-4">NGS</span>
        </div>
      </button>
    );
  }

  if (!isPageReady) {
    return (
      <div
        data-testid={`ngs-loading`}
        className="mb-8 p-4 relative border cursor-wait flex flex-col items-center bg-identity-ngs-logo-drop"
        style={{ height: 122 }}
      >
        <span
          className="absolute flex items-center justify-center"
          style={{ width: 20, height: 20, left: "calc(50% - 10px)", top: "calc(75% - 10px)" }}
        >
          <LoadingOutlined />
        </span>
        <div className="flex items-center py-2" style={{ height: 44 }}>
          <img style={{ height: "100%" }} src={ngsLogoPath} />
          <span className="text-6xl ml-4">NGS</span>
        </div>
      </div>
    );
  }

  const hasCloudaccount = cloudaccount !== null;
  const cloudaccountName = cloudaccount?.name || cloudaccountLink;

  return (
    <div data-testid={`ngs-existing`} className="mb-8 border">
      <div className="flex items-center py-4 px-4 mb-4 border-b-4 border-identity-ngs-logo-accent">
        <img style={{ height: 30 }} src={ngsLogoPath} />
        <span className="text-6xl ml-4">NGS</span>
      </div>
      <div className="mb-2 px-4 flex">
        <div className="w-4/12">
          <FormLabel>Cloud Account</FormLabel>
          <Tooltip title={ngs.edited.cloudaccountLink} open={cloudaccountName.length >= 25}>
            {hasCloudaccount ? (
              <Link
                className={`w-full inline-block truncate color-link font-medium ngfocus`}
                data-testid="ngs-ca"
                to={`/console${ngs.edited.cloudaccountLink}`}
              >
                {cloudaccountName}
              </Link>
            ) : (
              <span className={`w-full inline-block truncate`}>{cloudaccountName}</span>
            )}
          </Tooltip>
        </div>
      </div>
      <div className="flex px-4 mb-4">
        <div className="mb-2 pr-2 w-1/2">
          <FormLabel>Pub Allow</FormLabel>
          {ngs.edited.pubAllow.length < 1 ? <span>Not Set</span> : null}
          <div className="flex flex-wrap gap-1">
            {ngs.edited.pubAllow.map((p) => (
              <Value key={p} value={p} />
            ))}
          </div>
        </div>
        <div className="mb-2 pr-2 w-1/2">
          <FormLabel>Pub Deny</FormLabel>
          {ngs.edited.pubDeny.length < 1 ? <span>Not Set</span> : null}
          <div className="flex flex-wrap gap-1">
            {ngs.edited.pubDeny.map((p) => (
              <Value key={p} value={p} />
            ))}
          </div>
        </div>
      </div>
      <div className="flex px-4 mb-4">
        <div className="mb-2 pr-2 w-1/2">
          <FormLabel>Sub Allow</FormLabel>
          {ngs.edited.subAllow.length < 1 ? <span>Not Set</span> : null}
          <div className="flex flex-wrap gap-1">
            {ngs.edited.subAllow.map((p) => (
              <Value key={p} value={p} />
            ))}
          </div>
        </div>
        <div className="mb-2 pr-2 w-1/2">
          <FormLabel>Sub Deny</FormLabel>
          {ngs.edited.subDeny.length < 1 ? <span>Not Set</span> : null}
          <div className="flex flex-wrap gap-1">
            {ngs.edited.subDeny.map((p) => (
              <Value key={p} value={p} />
            ))}
          </div>
        </div>
      </div>
      <div className="flex mb-2 px-4">
        <div className="w-1/3">
          <FormLabel>Resp Max</FormLabel>
          <div>{!ngs.edited.resp || !ngs.edited.resp.max ? "Not Set" : ngs.edited.resp.max}</div>
        </div>
        <div className="w-1/3">
          <FormLabel>Resp TTL</FormLabel>
          <div>{!ngs.edited.resp || !ngs.edited.resp.ttl ? "Not Set" : ngs.edited.resp.ttl}</div>
        </div>
      </div>
      <div className="flex mb-2 px-4">
        <div className="w-1/3">
          <FormLabel>Subs</FormLabel>
          <div>{!ngs.edited.subs ? "Not Set" : ngs.edited.subs}</div>
        </div>
        <div className="w-1/3">
          <FormLabel>Data</FormLabel>
          <div>{!ngs.edited.data ? "Not Set" : ngs.edited.data}</div>
        </div>
        <div className="w-1/3">
          <FormLabel>Payload</FormLabel>
          <div>{!ngs.edited.payload ? "Not Set" : ngs.edited.payload}</div>
        </div>
      </div>
      {infoOnly ? null : (
        <div className="flex items-center justify-end px-4 pb-4">
          <NGButton
            style={{ width: 77, marginRight: 10 }}
            className="mt-2"
            variant="primary"
            onClick={() => {
              ngs.setup();
            }}
          >
            Edit
          </NGButton>
          <NGButton
            variant={"danger"}
            outlined
            style={{ width: 77, marginLeft: 10 }}
            className="mt-2"
            onClick={() => {
              ngs.remove();
            }}
          >
            Remove
          </NGButton>
        </div>
      )}
    </div>
  );
};

export const NGSIdentityInfo = observer(NGSIdentityInfoRaw);
