import { TableColumn } from "../../types";
import {
  AgentIcon,
  AuditContextIcon,
  CloudAccountIcon,
  DomainIcon,
  GVCIcon,
  GroupIcon,
  IdentityIcon,
  ImageIcon,
  KubernetesIcon,
  LocationIcon,
  OrgIcon,
  PolicyIcon,
  QuotaIcon,
  SecretIcon,
  ServiceAccountIcon,
  UserIcon,
  VolumeSetIcon,
  WorkloadIcon,
} from "../../../../components/Icons";
import { nameOfKind } from "../../../../services/cpln";

const kindGroupOrder: string[] = [
  "org",
  "gvc",
  "workload",
  "deployment",
  "identity",
  "volumeset",
  //
  "accessreport",
  "agent",
  "auditctx",
  "cloudaccount",
  "command",
  "dbcluster",
  "domain",
  "event",
  "group",
  "image",
  "imagesummary",
  "location",
  "memcachecluster",
  "mk8s",
  "permissions",
  "policy",
  "policymembership",
  "quota",
  "resource",
  "resourcepolicy",
  "secret",
  "serviceaccount",
  "spicedbcluster",
  "task",
  "tenant",
  "user",
];

const KindIconMap = {
  org: OrgIcon,
  gvc: GVCIcon,
  workload: WorkloadIcon,
  // "deployment": ,
  identity: IdentityIcon,
  volumeset: VolumeSetIcon,
  //
  // "accessreport": ,
  agent: AgentIcon,
  auditctx: AuditContextIcon,
  cloudaccount: CloudAccountIcon,
  // "command": ,
  // "dbcluster": ,
  domain: DomainIcon,
  // "event": ,
  group: GroupIcon,
  image: ImageIcon,
  imagesummary: ImageIcon,
  location: LocationIcon,
  // "memcachecluster": ,
  mk8s: KubernetesIcon,
  // "permissions": ,
  policy: PolicyIcon,
  // "policymembership": ,
  quota: QuotaIcon,
  // "resource": ,
  // "resourcepolicy": ,
  secret: SecretIcon,
  serviceaccount: ServiceAccountIcon,
  // "spicedbcluster": ,
  // "task": ,
  // "tenant": ,
  user: UserIcon,
};

const kindDefault = { id: "kind", label: "Kind" };
export function KindColumn<TData extends { kind: string }>(column: Partial<TableColumn<TData>> = kindDefault) {
  const preset: TableColumn<TData> = {
    ...kindDefault,
    enableResize: false,
    enableSort: true,
    size: 200,
    canGroupByDistinctValues: true,
    groupOrder: kindGroupOrder,
    cell: (p) => {
      const item = p.row.original;
      if (!item || !item.kind) {
        return <span></span>;
      }
      const KindIcon = KindIconMap[item.kind];
      return (
        <div className="flex items-center">
          <KindIcon style={{ width: 18, height: 18, fill: `var(--color-default)` }} />
          <span className="ml-2 text-sm description">{nameOfKind(item.kind)}</span>
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
