import { observer } from "mobx-react-lite";
import React from "react";
import { AlertTriangle } from "react-feather";
import { User } from "../../../../mobxStores/user/user";
import { dateString } from "../../../dateStringFn";
import { humanize } from "../../../dateString";

const AdminPanelSessionRaw: React.FC = () => {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    const id = setInterval(() => setCount((x) => x + 1), 1000);
    return () => {
      clearInterval(id);
    };
  }, [User.type]);

  return (
    <div className="px-4 py-1 rounded bg-color-danger flex items-center gap-2" style={{ color: "var(--color-white)" }}>
      <div className="absolute invisible">{count}</div>
      <AlertTriangle />
      <div className="flex flex-col text-sm leading-tight">
        <span>{User.type === "inspect" ? `Inspecting Org: ${User.org}` : `Impersonating User: ${User.email}`}</span>
        <span>Session Expires In: {humanize(User.expires * 1000)}</span>
      </div>
    </div>
  );
};

export const AdminPanelSession = observer(AdminPanelSessionRaw);
