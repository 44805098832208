import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { DetailedRadioOption } from "../detailedRadioGroup";
import { Theme } from "../../../mobxStores/uiData/theme";
import "./detailedRadioButton.css";

interface Props {
  id: string;
  group: string;
  isChecked: boolean;
  option: DetailedRadioOption;
  onChange: (option: DetailedRadioOption) => void;
  disabled?: boolean;
}

const DetailedRadioButtonRaw: React.FC<Props> = ({ id, group, isChecked, option, onChange, disabled }) => {
  return (
    <label
      htmlFor={id}
      className={clsx(
        `detailed-radio-button p-3 flex gap-x-3 w-full max-w-xs border rounded shadow-sm cursor-pointer card-content`,
        {
          disabled: disabled,
          cardContentActive: isChecked,
          cardContentActiveLight: isChecked && Theme.theme === "light",
        },
      )}
    >
      <input
        type="radio"
        id={id}
        name={group}
        checked={isChecked}
        disabled={disabled}
        onChange={() => onChange(option)}
        className="hidden"
      />
      <div className={`relative checkIcon ${isChecked ? "checkIconActive" : ""}`} style={{ marginTop: 3 }}>
        {isChecked ? <div className={`absolute top-2/4 left-2/4 innerCircle`}></div> : null}
      </div>
      <div>
        <div className="font-semibold">{option.title}</div>
        <div className="">{option.description}</div>
      </div>
    </label>
  );
};

export const DetailedRadioButton = observer(DetailedRadioButtonRaw);
