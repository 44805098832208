import * as React from "react";
import { useLocation, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { OrgCreateGeneral } from "./general";
import { observer } from "mobx-react-lite";
import { NameModel } from "../../../mobxDataModels/nameModel";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { Loader } from "../../../components/layout/loader";
import { request } from "../../../services/cpln";
import { TagsNewModel } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { AccountSummary, UserData } from "../../../mobxStores/userData/userData";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { NameValue } from "../../../mst/base";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { User } from "../../../mobxStores/user/user";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { ListOfItemsModel } from "../../../mobxDataModels/listOfItemsModel";
import { hasDuplicatedValue } from "../../../services/utils";

export interface CreateFormLink extends NameValue {
  isActive: boolean;
  pushTop?: boolean;
}

const OrgCreateRaw: React.FC = () => {
  const { pathname } = useLocation();
  const formDataRef = React.useRef(new NGFormData());
  const basePath = pathname.includes("/-createfirst")
    ? useBasePath("/org/-createfirst/*", true, "billing")
    : useBasePath("/org/-create/*", true, "billing");
  const navigate = useNavigate();
  const { hasAccount, AccountUUID } = BillingContext;

  const [isPageReady, setIsPageReady] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  const [selectedAccount, setSelectedAccount] = React.useState<AccountSummary>(null as any);
  const [roles, setRoles] = React.useState<string[]>([]);
  const [permissionError, setPermissionError] = React.useState<boolean | "loading">("loading");

  const nameRef = React.useRef(NameModel.create({ label: "Org Name" }));
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));

  const tagsRef = React.useRef(TagsNewModel.create());
  const inviteesRef = React.useRef(ListOfItemsModel.create({ _items: [{ firstValue: User.email }] }));

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const hasInvalidEmail = !!inviteesRef.current.items.map((i) => i.firstValue).find((i) => !emailRegex.test(i));
  const hasDuplicatedEmail = hasDuplicatedValue(inviteesRef.current.items.map((i) => i.firstValue));

  React.useEffect(() => {
    setIsPageReady(false);
    fetchAccounts();
    const startingPage = pathname.includes("-createfirst")
      ? pathname.split("/-createfirst")[0] + "/-createfirst/-general"
      : pathname.split("/-create")[0] + "/-create/-general";
    navigate(startingPage, { replace: true });
  }, []);

  React.useEffect(() => {
    if (!selectedAccount) {
      return;
    }

    setPermissionError("loading");

    request({ service: "billing-ng", url: `/account/${selectedAccount.id}/user/self` }).then((res: any) => {
      if (res.data.admin === true) {
        setRoles(["billing_admin"]);
      } else {
        setRoles(res.data.roles || []);
      }
    });
  }, [selectedAccount]);

  React.useEffect(() => {
    if (!roles.includes("billing_admin") && !roles.includes("org_creator")) {
      setPermissionError(true);
    } else {
      setPermissionError(false);
    }
  }, [roles]);

  React.useEffect(() => {
    let res = true;
    if (permissionError) res = false;
    if (inviteesRef.current.items.map((item) => item.firstValue).length < 1) res = false;
    for (let invitee of inviteesRef.current.items) {
      if (!invitee.firstValue) res = false;
    }
    if (!nameRef.current.isValid) res = false;
    if (!tagsRef.current.isValid) res = false;
    if (hasInvalidEmail) res = false;
    if (hasDuplicatedEmail) res = false;
    setIsValid(res);
  }, [
    hasDuplicatedEmail,
    hasInvalidEmail,
    permissionError,
    nameRef.current.isValid,
    inviteesRef.current.items.map((item) => item.firstValue),
    tagsRef.current.isValid,
  ]);

  async function fetchAccounts() {
    await UserData.requestAccountSummaries();
    if (UserData.accountSummaries.length < 1) {
      notification.warning({ message: "No account found", description: "You need to create an account first" });
      navigate(`/billing`);
      return;
    }

    if (hasAccount && UserData.accountSummaries.some((accountSummary) => accountSummary.id === AccountUUID)) {
      setSelectedAccount(
        JSON.parse(
          JSON.stringify(UserData.accountSummaries.find((accountSummary) => accountSummary.id === AccountUUID)!)
        )
      );
      setIsPageReady(true);
    } else {
      notification.warning({ message: "Cannot access account", description: "Please try again later." });
      navigate(`/billing`);
      return;
    }
  }

  function getOrgObject() {
    const name = nameRef.current.value;
    const description = descriptionRef.current.value || name;
    const body: any = {
      org: {
        name,
        description,
        tags: tagsRef.current.asObject,
      },
      invitees: inviteesRef.current.items.map((item) => item.firstValue),
    };
    return body;
  }

  async function onConfirm() {
    if (!selectedAccount) {
      return;
    }
    try {
      setIsLoading(true);

      if (process.env.REACT_APP_MODE_TEST_LOCAL === "true") {
        await request({
          method: "post",
          url: `/org`,
          body: getOrgObject().org,
        });
      } else {
        await request({
          method: "post",
          service: "billing-ng",
          url: `/account/${selectedAccount.id}/org`,
          body: getOrgObject(),
        });
      }

      notification.success({
        message: "Success",
        description: "Org is created",
      });
      await UserData.requestOrgNames();
      await ConsoleContext.setOrg(nameRef.current.value);
      PromptContext.setIsDisabled(true);
      ConsoleContext.setRedirect("/");
    } catch (e) {
      let errorMessage: any = e?.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      if (errorMessage) {
        try {
          const asObject = JSON.parse(errorMessage);
          if (asObject.message) {
            errorMessage = asObject.message;
          }
        } catch (e) {}
      }
      if (errorMessage.includes(", please contact support")) {
        errorMessage = errorMessage.replace(", please contact support", "");
        errorMessage = (
          <div>
            {errorMessage}, please contact{" "}
            <a className="color-link" href="mailto:support@controlplane.com">
              support@controlplane.com
            </a>
          </div>
        );
      }
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    let res = true;
    const allowedList = ["-general", "-tags"];
    for (let allowItem of allowedList) {
      if (pathname.includes(allowItem)) res = false;
    }
    return res;
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    { name: "Tags", value: `-tags`, isActive: nameRef.current.isValid },
  ];

  let inviteesError = "";

  if (hasInvalidEmail) {
    inviteesError =
      "The 'Org Admins' list contains an invalid email address. Please ensure all email addresses are valid.";
  } else if (hasDuplicatedEmail) {
    inviteesError = "The 'Org Admins' list contains duplicate emails. Please ensure all email addresses are unique.";
  }

  let next = {
    isActive: nameRef.current.isValid,
    label: "Next (Tags)",
    url: "-tags",
  };
  if (pathname.includes("-tags")) {
    next = {
      isActive: false,
      label: "Next",
      url: "",
    };
  }

  if (!isPageReady) {
    return <Loader reason={"Fetching accounts for create org page"} fullScreen />;
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={`Create Org`}
          name={nameRef.current.value}
          canCreate={isValid}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route
              path={`-general`}
              element={
                <OrgCreateGeneral
                  invitees={inviteesRef.current}
                  selectedAccount={selectedAccount}
                  name={nameRef.current}
                  description={descriptionRef.current}
                  permissionError={permissionError}
                  error={inviteesError}
                />
              }
            />
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const OrgCreate = observer(OrgCreateRaw);
