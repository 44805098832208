import { flow, getRoot, Instance, types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { BrowserServiceMobx } from "../kinds/browser";
import { AzureRoleAssignmentModel } from "../kinds/identity";
import { AzureIdentityDataSnapshot, AzureIdentityDataSnapshotRoleAssignment } from "./identity.azure.data";
import { IdentityDraftMobx } from "./identity.draft";
import { notification } from "antd";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const IdentityDraftAzureIdentityModel = types
  .model("Identity Draft Azure Link", {
    isActive: false,
    useManualInput: false,
    cloudaccountName: types.optional(types.string, ""),

    roleAssignments: types.array(AzureRoleAssignmentModel),
    filterInput: types.optional(StringModel, () => StringModel.create({ label: "Search", transformKey: "lowerCase" })),
  })
  .views((self) => ({
    get cloudaccountLink() {
      const { absolute } = ngParseLink(self.cloudaccountName, { kind: "cloudaccount" });
      return absolute;
    },
  }))
  .actions((self) => ({
    clear() {
      self.isActive = false;
      self.useManualInput = false;
      self.cloudaccountName = "";
      self.roleAssignments.clear();
      self.roleAssignments.push(AzureRoleAssignmentModel.create());
      self.filterInput.reset();
    },
  }))
  .views((self) => ({
    get browserService(): BrowserServiceMobx {
      const root = getRoot(self) as IdentityDraftMobx;
      return root.browserService;
    },
    get canAddRoleAssignment() {
      let res = true;
      for (let roleAssignment of self.roleAssignments) {
        if (!roleAssignment.forEdit.isValid) res = false;
      }
      return res;
    },
  }))
  .actions((self) => {
    const fetchDataItems: () => Promise<void> = flow(function* () {
      try {
        const browser = self.browserService;
        browser.setCloudaccountLink(self.cloudaccountLink);
        yield browser.fetchServices();
        self.browserService.setCanReach(true);
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) {
          errorMessage = e.message;
        }
        notification.warning({ message: "Browser Service Failed", description: errorMessage });
        self.browserService.setCanReach(false);
      }
    });
    return { fetchDataItems };
  })
  .actions((self) => ({
    setUseManualInput(_value: boolean) {
      self.useManualInput = false;
    },
    setCloudaccountName(name: string) {
      const oldName = self.cloudaccountName;
      if (name !== oldName) {
        self.roleAssignments.clear();
        self.roleAssignments.push(AzureRoleAssignmentModel.create());
      }
      self.cloudaccountName = name;
    },
    addRoleAssignment() {
      if (!self.canAddRoleAssignment) return;
      self.roleAssignments.unshift(AzureRoleAssignmentModel.create());
    },
    removeRoleAssignment(index: number) {
      if (self.roleAssignments.length - 1 < index) return;
      const item = self.roleAssignments[index];
      self.roleAssignments.remove(item);
    },
  }))
  .views((self) => ({
    get isValidPage_General() {
      let res = true;
      if (!self.cloudaccountName) res = false;
      return res;
    },
    get isValidPage_Configuration() {
      let res = true;
      if (self.roleAssignments.length < 1) res = false;
      for (let roleAssignment of self.roleAssignments) {
        if (!roleAssignment.forEdit.isValid) res = false;
      }
      return res;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.isValidPage_General) res = false;
      if (!self.isValidPage_Configuration) res = false;
      return res;
    },
    get asObject(): AzureIdentityDataSnapshot {
      return {
        cloudAccountLink: self.cloudaccountLink,
        roleAssignments: self.roleAssignments.map((roleAssignment) => {
          const item: AzureIdentityDataSnapshotRoleAssignment = {
            scope: roleAssignment.forEdit.scopeInput.value,
            roles: [...roleAssignment.forEdit.roles],
          };
          if (roleAssignment.forEdit.scopeDataItem) {
            item.scopeDataItem = {
              href: roleAssignment.forEdit.scopeDataItem.href,
              ref: roleAssignment.forEdit.scopeDataItem.ref,
            };
          }
          return item;
        }),
      };
    },
  }))
  .actions((self) => {
    const apply = flow(function* (obj: AzureIdentityDataSnapshot) {
      self.isActive = true;
      const { name: cloudaccountName } = ngParseLink(obj.cloudAccountLink, { kind: "cloudaccount" });
      self.cloudaccountName = cloudaccountName;
      self.roleAssignments.clear();
      for (let roleAssignment of obj.roleAssignments) {
        const instance = AzureRoleAssignmentModel.create();
        instance.apply(roleAssignment);
        self.roleAssignments.push(instance);
      }
    });
    function setIsActive(value: boolean) {
      self.isActive = value;
    }
    return {
      apply,
      setIsActive,
    };
  });
export interface IdentityAzureDraftMobx extends Instance<typeof IdentityDraftAzureIdentityModel> {}
