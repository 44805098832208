import { types, Instance, flow, SnapshotOut } from "mobx-state-tree";
import { request } from "../../services/cpln";

export enum BROWSER_REL {
  service = "service",
  region = "region",
  type = "type",
  item = "item",
  child = "child",
  self = "self",
  aspect = "aspect",
  roles = "roles",
  next = "next",
}

export const BrowserLinkModel = types
  .model({
    _type: types.optional(types.literal("link"), "link"),
    rel: types.enumeration([...Object.values(BROWSER_REL)]),
    href: types.optional(types.string, ""),
    label: types.maybe(types.string),
    dataItems: types.array(types.late(() => BrowserDataModel)),
  })
  .views((self) => ({
    get isChild() {
      return self.rel === BROWSER_REL.child;
    },
  }));
export interface BrowserLinkMobx extends Instance<typeof BrowserLinkModel> {}
export interface BrowserLink extends SnapshotOut<typeof BrowserLinkModel> {}

export const BrowserDataModel = types
  .model({
    _type: types.optional(types.literal("data"), "data"),
    _cpln: types
      .model({
        label: types.optional(types.string, ""),
        ref: types.optional(types.string, ""),
        href: types.maybe(types.string),
        mountUri: types.maybe(types.string),
        hasChildren: types.maybe(types.boolean),
      })
      .views((self) => ({
        get isTarget() {
          return !self.hasChildren;
        },
      })),
  })
  .views((self) => ({
    get asObject(): BrowserData {
      return {
        _type: self._type,
        _cpln: {
          label: self._cpln.label,
          ref: self._cpln.ref,
          href: self._cpln.href,
          mountUri: self._cpln.mountUri,
          hasChildren: self._cpln.hasChildren,
        },
      };
    },
  }));
export interface BrowserDataMobx extends Instance<typeof BrowserDataModel> {}
export interface BrowserData extends SnapshotOut<typeof BrowserDataModel> {}

export interface BrowserRes {
  data?: BrowserData[];
  links: BrowserLink[];
}

// export const DataItemModelForBindings = types.compose(DataItemModel, types.model({ identifier: types.string }));

export const BrowserLinkSectionModel = types
  .model({
    label: types.optional(types.string, ""),
    selectedHref: types.maybe(types.string),
    items: types.array(BrowserLinkModel),
  })
  .views((self) => ({
    get selectedItem() {
      return self.items.find((i) => i.href === self.selectedHref);
    },
  }))
  .actions((self) => ({
    setItems(items: BrowserLinkMobx[] | BrowserDataMobx[]) {
      self.items.clear();
      for (let item of items) {
        self.items.push(item as BrowserLinkMobx);
      }
    },
    clearItems() {
      self.items.clear();
      self.selectedHref = undefined;
    },
    setSelectedHref(href: string) {
      self.selectedHref = href;
    },
  }));

export const BrowserDataSectionModel = types
  .model({
    label: types.optional(types.string, ""),
    items: types.array(BrowserDataModel),
    itemSelection: types.maybe(types.string),
    childItems: types.array(BrowserLinkModel),
    childSelection: types.maybe(types.string),
    items_2: types.array(BrowserDataModel),
    itemSelection_2: types.maybe(types.string),
    childItems_2: types.array(BrowserLinkModel),
    childSelection_2: types.maybe(types.string),
    items_3: types.array(BrowserDataModel),
    itemSelection_3: types.maybe(types.string),
    childItems_3: types.array(BrowserLinkModel),
    childSelection_3: types.maybe(types.string),
    items_4: types.array(BrowserDataModel),
    itemSelection_4: types.maybe(types.string),
  })
  .actions((self) => ({
    clearItems() {
      self.items.clear();
      self.itemSelection = undefined;
      // @ts-ignore
      self.clearChildItems();
    },
    clearChildItems() {
      self.childItems.clear();
      self.childSelection = undefined;
      // @ts-ignore
      self.clearItems_2();
    },
    clearItems_2() {
      self.items_2.clear();
      self.itemSelection_2 = undefined;
      // @ts-ignore
      self.clearChildItems_2();
    },
    clearChildItems_2() {
      self.childItems_2.clear();
      self.childSelection_2 = undefined;
      // @ts-ignore
      self.clearItems_3();
    },
    clearItems_3() {
      self.items_3.clear();
      self.itemSelection_3 = undefined;
      // @ts-ignore
      self.clearChildItems_3();
    },
    clearChildItems_3() {
      self.childItems_3.clear();
      self.childSelection_3 = undefined;
      // @ts-ignore
      self.clearItems_4();
    },
    clearItems_4() {
      self.items_4.clear();
      self.itemSelection_4 = undefined;
    },
    setItems(items: BrowserDataMobx[]) {
      self.items.clear();
      for (let item of items) {
        self.items.push(item as BrowserDataMobx);
      }
    },
    setLabel(label: string) {
      self.label = label;
    },
  }))
  .actions((self) => {
    const onSelectDataItem = flow(function* (href: string) {
      let item: BrowserDataMobx | undefined;
      let childItems = self.childItems_3;
      item = self.items_4.find((i) => i._cpln.href === href);
      if (item) {
        self.itemSelection_4 = item._cpln.href;
      }
      if (!item) {
        self.items_4.clear();
        self.itemSelection_4 = undefined;
        self.childSelection_3 = undefined;
        self.childItems_3.clear();
        childItems = self.childItems_3;
        item = self.items_3.find((i) => i._cpln.href === href);
        if (item) {
          self.itemSelection_3 = item._cpln.href;
        }
      }
      if (!item) {
        self.items_3.clear();
        self.itemSelection_3 = undefined;
        self.childSelection_2 = undefined;
        self.childItems_2.clear();
        childItems = self.childItems_2;
        item = self.items_2.find((i) => i._cpln.href === href);
        if (item) {
          self.itemSelection_2 = item._cpln.href;
        }
      }
      if (!item) {
        self.items_2.clear();
        self.itemSelection_2 = undefined;
        self.childSelection = undefined;
        self.childItems.clear();
        childItems = self.childItems;
        item = self.items.find((i) => i._cpln.href === href);
        if (item) {
          self.itemSelection = item._cpln.href;
        }
      }
      if (!item) return;
      if (!item._cpln.hasChildren) return;
      const { data } = yield request<BrowserRes>({ service: "browser", url: item._cpln.href! });
      const items = data.links
        .filter((l: any) => l.rel === BROWSER_REL.child)
        .map((l: any) => BrowserLinkModel.create({ rel: l.rel, label: l.label, href: l.href }));
      for (let item of items) {
        childItems.push(item);
      }
    });
    const onSelectLinkItem = flow(function* (href: string) {
      let childItem: BrowserLinkMobx | undefined;
      self.items_4.clear();
      let dataItems = self.items_3;
      childItem = self.childItems_3.find((i) => i.href === href);
      if (childItem) {
        self.childSelection_3 = childItem.href;
      }
      if (!childItem) {
        self.items_3.clear();
        self.childSelection_3 = undefined;
        dataItems = self.items_3;
        childItem = self.childItems_2.find((i) => i.href === href);
        if (childItem) {
          self.childSelection_2 = childItem.href;
        }
      }
      if (!childItem) {
        self.items_2.clear();
        self.childSelection = undefined;
        dataItems = self.items_2;
        childItem = self.childItems.find((i) => i.href === href);
        if (childItem) {
          self.childSelection = childItem.href;
        }
      }
      if (!childItem) return;
      dataItems.clear();
      const { data } = yield request<BrowserRes>({ service: "browser", url: childItem.href });
      if (!data.data) {
        return;
      }
      const items = data.data.map((d: any) =>
        BrowserDataModel.create({
          _cpln: {
            hasChildren: d._cpln.hasChildren,
            href: d._cpln.href,
            label: d._cpln.label,
            ref: d._cpln.ref,
            mountUri: d._cpln.mountUri,
          },
        })
      );
      for (let item of items) {
        dataItems.push(item);
      }
    });
    return { onSelectDataItem, onSelectLinkItem };
  });

export const BrowserServiceModel = types
  .model("Browser Services", {
    cloudaccountLink: "",
    canReach: types.optional(types.boolean, false),
    reachError: types.optional(types.string, ""),
    servicesSection: types.optional(BrowserLinkSectionModel, () =>
      BrowserLinkSectionModel.create({ label: "Services" })
    ),
    regionsSection: types.optional(BrowserLinkSectionModel, () => BrowserLinkSectionModel.create({ label: "Regions" })),
    typesSection: types.optional(BrowserLinkSectionModel, () => BrowserLinkSectionModel.create({ label: "Types" })),
    dataSection: types.optional(BrowserDataSectionModel, () => BrowserDataSectionModel.create()),
    isConfirmLoading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get cloudaccountName() {
      if (self.cloudaccountLink.length < 1) {
        return "";
      }
      return self.cloudaccountLink.split("/")[4];
    },
  }))
  .actions((self) => {
    const checkCanReach = flow(function* () {
      try {
        yield request({ service: "browser", url: `/browse${self.cloudaccountLink}` });
        self.canReach = true;
        self.reachError = "";
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        self.reachError = errorMessage;
        self.canReach = false;
      }
    });
    const fetchServices = flow(function* () {
      try {
        self.servicesSection.clearItems();
        self.regionsSection.clearItems();
        self.typesSection.clearItems();
        self.dataSection.clearItems();
        let res: BrowserRes;
        const { data } = yield request<BrowserRes>({ service: "browser", url: `/browse${self.cloudaccountLink}` });
        res = data;
        let services = res.links.filter((l) => l.rel === BROWSER_REL.service);
        let nextLink = res.links.find((l) => l.rel === BROWSER_REL.next)?.href;
        while (nextLink) {
          const { data: nextData } = yield request<BrowserRes>({ service: "browser", url: nextLink });
          res = nextData;
          services = services.concat(res.links.filter((l) => l.rel === BROWSER_REL.service));
          nextLink = res.links.find((l) => l.rel === BROWSER_REL.next)?.href;
        }
        const items: BrowserLinkMobx[] = services.map((s) =>
          BrowserLinkModel.create({ rel: s.rel, label: s.label, href: s.href })
        );
        self.servicesSection.setItems(items);
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        throw new Error(errorMessage);
      }
    });
    const fetchRegions = flow(function* () {
      try {
        self.regionsSection.clearItems();
        self.typesSection.clearItems();
        self.dataSection.clearItems();
        const selectedService = self.servicesSection.selectedItem;
        if (!selectedService) return;
        let res;
        const { data } = yield request<BrowserRes>({ service: "browser", url: selectedService.href });
        res = data;
        let regions = res.links.filter((l: any) => l.rel === BROWSER_REL.region);
        let nextLink = res.links.find((l: any) => l.rel === BROWSER_REL.next)?.href;
        while (nextLink) {
          const { data: nextData } = yield request<BrowserRes>({ service: "browser", url: nextLink });
          res = nextData;
          regions = regions.concat(res.links.filter((l: any) => l.rel === BROWSER_REL.region));
          nextLink = res.links.find((l: any) => l.rel === BROWSER_REL.next)?.href;
        }
        const items: BrowserLinkMobx[] = regions.map((s: any) =>
          BrowserLinkModel.create({ rel: s.rel, label: s.label, href: s.href })
        );
        self.regionsSection.setItems(items);
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        throw new Error(errorMessage);
      }
    });
    const fetchTypes = flow(function* () {
      try {
        self.typesSection.clearItems();
        self.dataSection.clearItems();
        const selectedRegion = self.regionsSection.selectedItem;
        if (!selectedRegion) return;
        let res;
        const { data } = yield request<BrowserRes>({ service: "browser", url: selectedRegion.href });
        res = data;
        let types = res.links.filter((l: any) => l.rel === BROWSER_REL.type);
        let nextLink = res.links.find((l: any) => l.rel === BROWSER_REL.next)?.href;
        while (nextLink) {
          const { data: nextData } = yield request<BrowserRes>({ service: "browser", url: nextLink });
          res = nextData;
          types = types.concat(res.links.filter((l: any) => l.rel === BROWSER_REL.type));
          nextLink = res.links.find((l: any) => l.rel === BROWSER_REL.next)?.href;
        }
        const items: BrowserLinkMobx[] = types.map((s: any) =>
          BrowserLinkModel.create({ rel: s.rel, label: s.label, href: s.href })
        );
        self.typesSection.setItems(items);
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        throw new Error(errorMessage);
      }
    });
    const fetchDataItems = flow(function* () {
      try {
        self.dataSection.clearItems();
        const selectedType = self.typesSection.selectedItem;
        if (!selectedType) return;
        let res;
        const { data } = yield request<BrowserRes>({ service: "browser", url: selectedType.href });
        res = data;
        let dataItems = res.data || [];
        let nextLink = res.links.find((l: any) => l.rel === BROWSER_REL.next)?.href;
        while (nextLink) {
          const { data: nextData } = yield request<BrowserRes>({ service: "browser", url: nextLink });
          res = nextData;
          if (res.data) dataItems = dataItems.concat(res.data || []);
          nextLink = res.links.find((l: any) => l.rel === BROWSER_REL.next)?.href;
        }
        const items: BrowserDataMobx[] = dataItems.map((i: any) =>
          BrowserDataModel.create({
            _cpln: {
              hasChildren: i._cpln.hasChildren,
              href: i._cpln.href,
              label: i._cpln.label,
              ref: i._cpln.ref,
              mountUri: i._cpln.mountUri,
            },
          })
        );
        self.dataSection.setItems(items);
      } catch (e) {
        let errorMessage = e?.response?.data?.message;
        if (!errorMessage) errorMessage = e.message;
        throw new Error(errorMessage);
      }
    });
    function setCanReach(value: boolean) {
      self.canReach = value;
    }
    function clearReachError() {
      self.reachError = "";
    }
    return { checkCanReach, fetchServices, fetchRegions, fetchTypes, fetchDataItems, setCanReach, clearReachError };
  })
  .actions((self) => ({
    setIsConfirmLoading(value: boolean) {
      self.isConfirmLoading = value;
    },
    selectType(href: string) {
      const item = self.typesSection.items.find((i) => i.href === href);
      self.dataSection.setLabel(item?.label || "");
    },
    setCloudaccountLink(link: string) {
      self.cloudaccountLink = link;
    },
  }));
export interface BrowserServiceMobx extends Instance<typeof BrowserServiceModel> {}
