import * as React from "react";
import { observer } from "mobx-react-lite";
import { NGButton } from "../../../newcomponents/button/Button";
import { linksOf } from "../../../services/cpln";
import { Table } from "../../../newcomponents/table/table";
import { TagsColumn } from "../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../newcomponents/table/columns/wellKnown/createdColumn";
import { useTableKindId } from "../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../newcomponents/table/components/TableCplnQuery";
import { TableModal } from "../../../components/antd/TableModal";
import { sortBy } from "lodash";
import { PlusCircle } from "react-feather";
import { LastModifiedColumn } from "../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { ServiceAccount } from "../../../schema/types/serviceaccount";
import { NameDescriptionNoLinkColumn } from "../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { OriginColumn } from "../../../newcomponents/table/columns/wellKnown/originColumn";

interface ServiceAccountTableItem extends ServiceAccount {
  selfLink: string;
}
interface Props {
  setServiceAccountLinks: (links: string[]) => void;
}

const GroupCreateServiceaccountsRaw: React.FC<Props> = ({ setServiceAccountLinks }) => {
  const { kind, id } = useTableKindId("serviceaccount", "group-create-serviceaccount-add");
  const qData = useTableItemQueryData<ServiceAccount>(kind, { fetchAllPages: true });

  const [currentItems, setCurrentItems] = React.useState<ServiceAccountTableItem[]>([]);
  function getCurrentLinks(): string[] {
    return currentItems.map((i) => i.selfLink);
  }
  React.useEffect(() => {
    setServiceAccountLinks(getCurrentLinks());
  }, [currentItems, currentItems.length]);

  const [selectionsToRemove, setSelectionsToRemove] = React.useState<string[]>([]);
  const [selectionsToAdd, setSelectionsToAdd] = React.useState<string[]>([]);
  const [selectionItemsToAdd, setSelectionItemsToAdd] = React.useState<ServiceAccountTableItem[]>([]);
  const [isAddingItem, setIsAddingItem] = React.useState(false);

  function onRemove() {
    setCurrentItems((items) => items.filter((item) => !selectionsToRemove.includes(item.selfLink)));
    setSelectionsToRemove([]);
  }

  function onOkAddModal() {
    setCurrentItems((items) => {
      const newItems: ServiceAccountTableItem[] = [...items];
      const currentLinks = items.map((i) => i.selfLink);

      for (const itemToAdd of selectionItemsToAdd) {
        if (currentLinks.includes(itemToAdd.selfLink)) {
          continue;
        }

        newItems.push(itemToAdd);
      }

      return sortBy(newItems, "email");
    });

    setSelectionsToAdd([]);
    setSelectionItemsToAdd([]);
  }

  const serviceaccountTableItems: ServiceAccountTableItem[] = qData.visibleItems
    .map((item) => ({
      ...item,
      selfLink: linksOf(item).self!,
    }))
    .filter((item) => !getCurrentLinks().includes(item.selfLink));

  return (
    <>
      <Table<ServiceAccountTableItem>
        tableId={"group-create-serviceaccounts"}
        title={"Service Accounts"}
        data={currentItems}
        selectMode={"multi"}
        selectKey="selfLink"
        selections={selectionsToRemove}
        onSelectionsChange={setSelectionsToRemove}
        columns={[
          NameDescriptionNoLinkColumn(), //
          OriginColumn(),
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
        headerRenderer={() => (
          <>
            <NGButton
              variant={"danger"}
              size={"small"}
              outlined
              disabled={selectionsToRemove.length < 1}
              onClick={onRemove}
            >
              Remove
            </NGButton>
            <NGButton variant={"action"} size={"small"} onClick={() => setIsAddingItem(true)}>
              Add
            </NGButton>
          </>
        )}
        noItemsFoundRenderer={(table) => (
          <NGButton
            variant="primary"
            onClick={() => setIsAddingItem(true)}
            renderIcon={(_, props) => <PlusCircle {...props} />}
          >
            Add Service Accounts
          </NGButton>
        )}
      />
      <TableModal
        open={isAddingItem}
        onCancel={() => setIsAddingItem(false)}
        onOk={() => {
          onOkAddModal();
          setIsAddingItem(false);
        }}
      >
        <Table<ServiceAccountTableItem>
          tableId={id}
          title={"Select Service Accounts to Add"}
          selectMode="multi"
          selectKey="selfLink"
          selections={selectionsToAdd}
          onSelectionsChange={(keys, items) => {
            setSelectionsToAdd(keys);
            setSelectionItemsToAdd(items!);
          }}
          data={serviceaccountTableItems}
          headerRenderer={() => {
            return (
              <>
                <TableHeaderRefreshButton onClick={qData.fetchItems} />
                <CustomTableCPLNQuery
                  tableId={id}
                  kind={kind}
                  onQuery={qData.setQuery}
                  isLoading={qData.isLoadingQueried}
                />
              </>
            );
          }}
          enableFilter
          isLoading={qData.isLoading}
          columns={[
            NameDescriptionNoLinkColumn(), //
            OriginColumn(),
            TagsColumn(),
            CreatedColumn(),
            LastModifiedColumn(),
          ]}
        />
      </TableModal>
    </>
  );
};

export const GroupCreateServiceaccounts = observer(GroupCreateServiceaccountsRaw);
