import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import moment from "moment-timezone";
import {
  CreateVolumeSnapshotReducerState,
  RunCreateVolumeSnapshotReducerActionType,
  getExpirationDateFromRetentionDuration,
  useRunCreateVolumeSnapshotReducer,
} from "./reducer_createVolumeSnapshot";
import { VolumeSetMobx } from "../../../mst/kinds/volumeset";
import { CommandTagItem } from "../../../components/detail/commandTagItem";
import { NoTag } from "../../../components/tag/noTag";
import { DatePicker } from "../../../components/antd/DatePicker";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { NGInput } from "../../../newcomponents/input/input";

interface Props {
  volumeSet: VolumeSetMobx;
  initialLocation?: string;
  initialVolumeIndex?: string;
  onClose: any;
  onDone: any;
  visible: boolean;
}

const CommandModal_CreateVolumeSnapshotRaw: React.FC<Props> = ({
  volumeSet,
  initialLocation,
  initialVolumeIndex,
  onClose,
  onDone,
  visible,
}) => {
  const formDataRef = React.useRef(new NGFormData());
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, dispatch] = useRunCreateVolumeSnapshotReducer(initialLocation, initialVolumeIndex);

  async function handleConfirm() {
    try {
      setIsLoading(true);

      const spec: CreateVolumeSnapshotReducerState = JSON.parse(JSON.stringify(state));
      if (!state.snapshotExpirationDate) {
        // This is to not send null data
        // @ts-ignore
        delete spec.snapshotExpirationDate;
      }
      // @ts-ignore
      spec.snapshotTags = state.snapshotTags.map((t) => ({ [t.name]: t.value }));

      await request({
        method: "post",
        url: `${volumeSet.selfLink}/-command`,
        body: {
          type: "createVolumeSnapshot",
          spec: spec,
        },
      });
      onDone();
      notification.success({
        message: "Success",
        description: "Created Command.",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  let isValid = true;
  if (!state.location) isValid = false;
  if (!state.volumeIndex) isValid = false;
  if (!state.snapshotName) isValid = false;

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <Modal
        open={visible}
        closable={false}
        title="Create Volume Snapshot"
        footer={
          <div className="modal-actions">
            <NGButton
              disabled={isLoading}
              onClick={() => {
                onClose();
              }}
              variant={"danger"}
              outlined
            >
              Cancel
            </NGButton>
            <NGButton loading={isLoading} disabled={isLoading || !isValid} onClick={handleConfirm} variant={"action"}>
              Create
            </NGButton>
          </div>
        }
        onCancel={onClose}
        maskClosable={!isLoading}
        destroyOnClose
      >
        <div>
          <NGFormLabel name={"location"} required invalid={!state.location}>
            Location
          </NGFormLabel>
          <NGSelect
            className="mb-4"
            name={"location"}
            value={state.location}
            invalid={!state.location}
            onChange={(value) => dispatch({ type: RunCreateVolumeSnapshotReducerActionType.LOCATION, payload: value })}
            options={volumeSet.status.locations.map(({ name: l }) => ({
              label: l,
              value: l,
              disabled: false,
              isLabel: false,
            }))}
          />
          <NGFormLabel name={"volumeIndex"} required invalid={state.volumeIndex.length < 1}>
            Volume Index
          </NGFormLabel>
          <NGInput
            className="mb-4"
            name={"volumeIndex"}
            value={state.volumeIndex}
            invalid={state.volumeIndex.length < 1}
            onChange={(e) =>
              dispatch({ type: RunCreateVolumeSnapshotReducerActionType.INDEX, payload: e.target.value })
            }
          />
          <NGFormLabel name={"snapshotName"} required invalid={!state.snapshotName}>
            Snapshot Name
          </NGFormLabel>
          <NGInput
            className="mb-4"
            name={"snapshotName"}
            value={state.snapshotName}
            invalid={!state.snapshotName}
            onChange={(e) => dispatch({ type: RunCreateVolumeSnapshotReducerActionType.NAME, payload: e.target.value })}
          />
          {/* TODO make UTC */}
          <NGLabel>Snapshot Expiration Date (Local)</NGLabel>
          <DatePicker
            style={{ width: "100%" }}
            size="large"
            className="mb-4"
            value={state.snapshotExpirationDate ? moment(state.snapshotExpirationDate) : undefined}
            showTime
            onChange={(date, dateString) =>
              dispatch({
                type: RunCreateVolumeSnapshotReducerActionType.DATE,
                payload: date ? (date as moment.Moment).toDate().toISOString() : null,
              })
            }
            presets={
              volumeSet.spec.snapshots?.retentionDuration
                ? [
                    {
                      label: "Retention",
                      value: () => getExpirationDateFromRetentionDuration(volumeSet.spec.snapshots?.retentionDuration)!,
                    },
                  ]
                : undefined
            }
            showNow={false}
          />
          <div className="flex items-center gap-4 mb-2">
            <div className="flex items-center">
              <NGLabel>Snapshot Tags</NGLabel>
            </div>
            <NGButton
              size={"small"}
              onClick={() => dispatch({ type: RunCreateVolumeSnapshotReducerActionType.ADD_TAG, payload: "" })}
              variant={"action"}
            >
              Add
            </NGButton>
          </div>
          {state.snapshotTags.length > 0 ? null : <NoTag override={"No Snapshot Tags"} />}
          <div className="flex flex-col gap-2">
            {state.snapshotTags.map((tag) => (
              <CommandTagItem
                key={tag.index}
                index={tag.index}
                name={tag.name}
                value={tag.value}
                onChange={(index, name, value) =>
                  dispatch({
                    type: RunCreateVolumeSnapshotReducerActionType.CHANGE_TAG,
                    payload: { index, name, value },
                  })
                }
                onRemove={() =>
                  dispatch({ type: RunCreateVolumeSnapshotReducerActionType.REMOVE_TAG, payload: tag.index })
                }
              />
            ))}
          </div>
        </div>
      </Modal>
    </NGFormContext.Provider>
  );
};

export const CommandModal_CreateVolumeSnapshot = observer(CommandModal_CreateVolumeSnapshotRaw);
