import * as React from "react";
import { observer } from "mobx-react-lite";
import { ExternalLink } from "react-feather";
import { DOCS_URL } from "../../envVariables";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { Table } from "../../newcomponents/table/table";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { TableNewItemButton } from "../../newcomponents/table/components/tableNewButton";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { AgentLastActiveTextColumn } from "../../newcomponents/table/columns/agent/lastActiveText";
import { Agent } from "../../schema/types/agent";
import { AgentTableItem, agentHelpers } from "../../mst/kinds/agent.helpers";
import { linksOf } from "../../services/cpln";
import { useAgentInfosReducer } from "../../components/detail/agentInfosReducer";
import { TableActionsDropdown } from "../../newcomponents/table/components/tableActionsDropdown";
import { DeleteModal } from "../../components/modals/deleteModal";

const AgentListRaw: React.FC = () => {
  const { state, fetchAgentInfos } = useAgentInfosReducer();

  const [selectedLinks, setSelectedLinks] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<string>("");

  const { kind, id } = useTableKindId("agent", "agent-list-update1");
  const qData = useTableItemQueryData<Agent>(kind, { fetchAllPages: true });

  React.useEffect(() => {
    const unsubscribe = fetchAgentInfos(qData.visibleItems.map((i) => i.name));
    return () => {
      unsubscribe();
    };
  }, [qData.visibleItems.length]);

  const agentTableItems: AgentTableItem[] = qData.visibleItems.map((agent) => {
    const selfLink = linksOf(agent).self!;
    const agentInfo = state.agentInfoMap[agent.name];
    const isActive = agentHelpers.getIsActive(agent, agentInfo);
    const lastActiveText = agentHelpers.getLastActiveText(agent, agentInfo);

    return {
      ...agent,
      selfLink: selfLink,
      isActive: isActive,
      lastActiveText: lastActiveText,
    };
  });

  return (
    <>
      <div className={`mb-4 flex items-center justify-between border p-8`}>
        <div>You can configure an agent to allow access to resources on other clouds or private networks.</div>
        <a
          className={"ngfocus color-link"}
          style={{ width: 220, marginLeft: 10 }}
          href={`${DOCS_URL}/reference/agent`}
          target={"_blank"}
        >
          How to set up an Agent?
          <ExternalLink className="feather-icon ml-1 inline-block" style={{ transform: "translateY(2px)" }} />
        </a>
      </div>
      <Table<AgentTableItem>
        title={"Agents"}
        data={agentTableItems}
        selectKey="selfLink"
        selectMode="multi"
        selections={selectedLinks}
        onSelectionsChange={setSelectedLinks}
        tableId={id}
        headerRenderer={() => {
          return (
            <>
              <TableNewItemButton kind={kind} />
              <TableActionsDropdown
                kind={kind}
                selectionLinks={selectedLinks}
                options={[
                  {
                    key: "delete",
                    label: "Delete",
                    disabled: selectedLinks.length < 1,
                    danger: true,
                    callback: () => setAction("delete"),
                  },
                ]}
              />
              <TableHeaderRefreshButton onClick={qData.fetchItems} />
              <CustomTableCPLNQuery
                tableId={id}
                kind={kind}
                onQuery={qData.setQuery}
                isLoading={qData.isLoadingQueried}
              />
            </>
          );
        }}
        enableFilter
        isLoading={qData.isLoading}
        columns={[
          NameDescriptionColumn(),
          AgentLastActiveTextColumn(),
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
      />
      {action === "delete" ? (
        <DeleteModal
          kind={kind}
          onCancel={() => setAction("")}
          onComplete={() => {
            setAction("");
            setSelectedLinks([]);
            qData.fetchItems();
          }}
          links={selectedLinks}
        />
      ) : null}
    </>
  );
};

export const AgentList = observer(AgentListRaw);
