import * as React from "react";
import { cplnLog } from "../../../services/utils";
import styles from "./style.module.scss";

interface Props {
  reason: string;
  fullScreen?: boolean;
  className?: string;
  style?: any;
}

export const Loader: React.FC<Props> = ({ reason, fullScreen, className = "", style = {} }) => {
  React.useEffect(() => {
    cplnLog(reason);
  }, []);

  return (
    <div
      data-testid={`${reason} - ${window.location.pathname}`}
      className={`${styles.loader} ${!!fullScreen ? styles.fullScreen : ""} ${className}`}
      style={style}
    />
  );
};
