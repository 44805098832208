import * as React from "react";
import { AgentInfo } from "../../schema/types/agent";
import { request, resourceLink } from "../../services/cpln";

interface AgentInfoMap {
  [_: string]: AgentInfo;
}

interface AgentInfosReducerType {
  state: AgentInfoReducerState;
  dispatch: React.Dispatch<AgentInfosReducerAction>;
  fetchAgentInfos: (agentNames: string[], intervalMs?: number) => () => void;
}

export function useAgentInfosReducer(): AgentInfosReducerType {
  const initialState: AgentInfoReducerState = {
    agentName: "",
    agentInfoMap: {},
    lastModified: new Date().toISOString(),
  };
  const [state, dispatch] = React.useReducer(agentInfosReducer, initialState);

  async function _fetchAgentInfos(agentNames: string[]) {
    const promises: any[] = [];
    for (const agentName of agentNames) {
      const url = resourceLink("agent", agentName) + "/-info";
      promises.push(request({ url: url }));
    }
    const promiseResults = await Promise.all(promises);

    for (let index = 0; index < agentNames.length; index++) {
      const agentName = agentNames[index];
      const promiseResult = promiseResults[index];
      const agentInfo = promiseResult.data;
      if (Object.keys(agentInfo).length < 1) {
        continue;
      }
      dispatch({
        type: AgentInfosReducerActionType.SET_AGENT_INFO,
        payload: { agentName: agentName, agentInfo: agentInfo },
      });
    }
  }

  function fetchAgentInfos(agentNames: string[], intervalMs = 0) {
    _fetchAgentInfos(agentNames);
    if (intervalMs < 1) {
      return () => {};
    }
    const id = setInterval(() => {
      _fetchAgentInfos(agentNames);
    }, intervalMs);
    return () => {
      clearInterval(id);
    };
  }

  return { state, dispatch, fetchAgentInfos };
}

export enum AgentInfosReducerActionType {
  SET_AGENT_INFO = "SET_AGENT_INFO",
  CLEAR_AGENT_INFOS = "CLEAR_AGENT_INFOS",
}

interface AgentInfosReducerAction {
  type: AgentInfosReducerActionType;
  payload: { agentName: string; agentInfo: AgentInfo };
}

interface AgentInfoReducerState {
  agentName: string;
  agentInfoMap: AgentInfoMap;
  lastModified: string;
}

function agentInfosReducer(state: AgentInfoReducerState, action: AgentInfosReducerAction) {
  const { type, payload } = action;

  const now = new Date().toISOString();

  switch (type) {
    case AgentInfosReducerActionType.CLEAR_AGENT_INFOS:
      return {
        ...state,
        agentInfoMap: {},
        lastModified: now,
      };
    case AgentInfosReducerActionType.SET_AGENT_INFO:
      return {
        ...state,
        agentInfoMap: {
          ...state.agentInfoMap,
          [payload.agentName]: payload.agentInfo,
        },
        lastModified: now,
      };
    default:
      return state;
  }
}
