import { observer } from "mobx-react-lite";
import * as React from "react";
import { NGButton } from "../../../newcomponents/button/Button";
import { getLogsFluentdData } from "./data/fluentd";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";

interface Props {
  fluentd: ReturnType<typeof getLogsFluentdData>;
  formPrefix: string;
  onRemove?: any;
}
const FluentdRaw: React.FC<Props> = ({ fluentd, onRemove, formPrefix }) => {
  formPrefix += `-fluentd-`;

  return (
    <>
      <div style={{ width: 450 }}>
        <NGFormElement
          name={`${formPrefix}-host`}
          label={fluentd.host.label}
          onChange={fluentd.host.setValue}
          value={fluentd.host.value}
          error={fluentd.host.error}
          required={fluentd.host.isRequired}
        />
        <NGFormElement
          name={`${formPrefix}-port`}
          label={fluentd.port.label}
          onChange={fluentd.port.setValue}
          value={fluentd.port.value}
          error={fluentd.port.error}
          required={fluentd.port.isRequired}
        />
        {onRemove ? (
          <div className="flex justify-end">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const Fluentd = observer(FluentdRaw);
