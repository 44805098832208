import * as React from "react";
import { SelectModel } from "../../../mobxDataModels/selectModel";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { useOutsideClick } from "../../../reactHooks/useOutsideClick";
import { OPERATORS_REQUIRING_TWO_VALUES, queryProperties } from "../../../services/utils";
import { Check, Edit2, MinusCircle, X } from "react-feather";
import { observer } from "mobx-react-lite";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGInput } from "../../../newcomponents/input/input";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { Kind } from "../../../mst/base";
import { QueryModel, QueryTerm } from "../../../mst/query";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { Table } from "../table";

export interface QueryTableItem {
  id: string;
  term: string;
  key: string;
  operator: string;
  value: string;
}
interface CustomTableCPLNQueryProps {
  tableId: string;
  kind: Kind;
  isLoading?: boolean;
  onQuery: (query: any) => void;
}
function CustomTableCPLNQueryRaw({ tableId, kind, isLoading, onQuery }: CustomTableCPLNQueryProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isApplied, setIsApplied] = React.useState(false);
  const [updateId, setUpdateId] = React.useState<string | undefined>(undefined);

  const currentQueryRef = React.useRef(QueryModel.create());
  const currentQuery = currentQueryRef.current;
  const previousQueryRef = React.useRef(QueryModel.create());
  const previousQuery = previousQueryRef.current;

  function getIsQueryDirty(): boolean {
    if (previousQuery.spec.terms.length < 1 && currentQuery.spec.terms.length < 1 && currentQuery.spec.sort.by === "") {
      return false;
    }
    let res = false;
    if (currentQuery.spec.match !== previousQuery.spec.match) res = true;
    if (currentQuery.spec.terms.length !== previousQuery.spec.terms.length) res = true;
    if (currentQuery.spec.sort.by !== previousQuery.spec.sort.by) res = true;
    if (currentQuery.spec.sort.order !== previousQuery.spec.sort.order) res = true;
    for (let term of currentQuery.spec.terms) {
      const terms = previousQuery.spec.terms;
      if (!terms.some((t) => t.tag === term.tag && t.op === term.op && t.value === term.value)) {
        res = true;
      }
    }
    for (let term of previousQuery.spec.terms) {
      const terms = currentQuery.spec.terms;
      if (!terms.some((t) => t.tag === term.tag && t.op === term.op && t.value === term.value)) {
        res = true;
      }
    }
    return res;
  }
  const isQueryDirty = getIsQueryDirty();

  const allowedProperties: string[] = (queryProperties as any)[kind] || [];
  const hasAllowedProperties = allowedProperties.length > 0;
  const tagKeyRef = React.useRef(StringModel.create({ label: "Tag Key" }));
  const tagValueRef = React.useRef(StringModel.create({ label: "Tag Value" }));
  const propertyKeyRef = React.useRef(StringModel.create({ label: "Property Key" }));
  const propertyKeySelectRef = React.useRef(
    SelectModel.create({
      label: "Property Key",
      options: allowedProperties.map((p) => ({ label: p, value: p })),
      initialValue: allowedProperties[0] || "",
    }),
  );
  const propertyValueRef = React.useRef(StringModel.create({ label: "Property Value" }));
  const relKeyRef = React.useRef(StringModel.create({ label: "Rel Key" }));
  const relValueRef = React.useRef(StringModel.create({ label: "Rel Value" }));
  const operatorRef = React.useRef(
    SelectModel.create({
      label: "Op",
      initialValue: "=",
      options: [
        { label: "Equals", value: "=" },
        { label: "Not Equals", value: "!=" },
        { label: "Exists", value: "!!" },
        { label: "Not Exists", value: "!" },
        { label: "Contains", value: "~" },
        { label: "Greater", value: ">" },
        { label: "Greater or Equals", value: ">=" },
        { label: "Less", value: "<" },
        { label: "Less or Equals", value: "<=" },
      ],
    }),
  );
  const queryModalRef = React.useRef<HTMLDivElement>(null as any);
  const termRef = React.useRef(
    SelectModel.create({
      label: "Term",
      initialValue: "tag",
      options: [
        { label: "Tag", value: "tag" },
        { label: "Property", value: "property" },
        { label: "Rel", value: "rel" },
      ],
    }),
  );

  useOutsideClick([queryModalRef], () => {
    setIsOpen(false);
    formReset();
  });

  const operatorRequiresTwoValues = OPERATORS_REQUIRING_TWO_VALUES.includes(operatorRef.current.value);

  function formReset() {
    tagKeyRef.current.reset();
    tagValueRef.current.reset();
    propertyKeyRef.current.reset();
    propertyKeySelectRef.current.reset();
    propertyValueRef.current.reset();
    relKeyRef.current.reset();
    relValueRef.current.reset();
  }

  React.useEffect(() => {
    const ids = currentQuery.spec.terms.map((term) => term.id);
    if (updateId && !ids.includes(updateId)) {
      setUpdateId(undefined);
      termRef.current.reset();
      formReset();
    }
  }, [currentQuery.spec.terms.length]);

  function getActionDisabled() {
    let res = false;
    switch (termRef.current.value) {
      case "tag":
        if (tagKeyRef.current.value.length < 1 || (operatorRequiresTwoValues && tagValueRef.current.value.length < 1)) {
          res = true;
        }
        break;
      case "property":
        if (
          (hasAllowedProperties && propertyKeySelectRef.current.value.length < 1) ||
          (!hasAllowedProperties && propertyKeyRef.current.value.length < 1) ||
          (operatorRequiresTwoValues && propertyValueRef.current.value.length < 1)
        ) {
          res = true;
        }
        break;
      case "rel":
        if (relKeyRef.current.value.length < 1 || (operatorRequiresTwoValues && relValueRef.current.value.length < 1)) {
          res = true;
        }
        break;
    }
    return res;
  }
  const isActionDisabled = getActionDisabled();

  function onUpdateTerm(term: QueryTableItem) {
    setUpdateId(term.id);
    termRef.current.setValue(term.term);
    operatorRef.current.setValue(term.operator);

    switch (term.term) {
      case "property":
        if (hasAllowedProperties) {
          propertyKeySelectRef.current.setValue(term.key as string);
        } else {
          propertyKeyRef.current.setValue(term.key as string);
        }
        propertyValueRef.current.setValue(term.value as string);
        break;
      case "rel":
        relKeyRef.current.setValue(term.key as string);
        relValueRef.current.setValue(term.value as string);
        break;
      case "tag":
        tagKeyRef.current.setValue(term.key as string);
        tagValueRef.current.setValue(term.value as string);
        break;
    }
  }

  function updateTerm() {
    if (!updateId) return;

    const updatedTerm: Partial<QueryTerm> = {
      op: operatorRef.current.value as "=" | ">" | ">=" | "<" | "<=" | "!=" | "exists" | "!exists" | "~",
    };

    switch (termRef.current.value) {
      case "tag":
        updatedTerm.tag = tagKeyRef.current.value;
        updatedTerm.value = tagValueRef.current.value;
        break;
      case "property":
        updatedTerm.value = propertyValueRef.current.value;
        if (hasAllowedProperties) {
          updatedTerm.property = propertyKeySelectRef.current.value;
        } else {
          updatedTerm.property = propertyKeyRef.current.value;
        }
        break;
      case "rel":
        updatedTerm.rel = relKeyRef.current.value;
        updatedTerm.value = relValueRef.current.value;
        break;
    }

    if (operatorRef.current.value === "!!") {
      updatedTerm.op = "exists";
    } else if (operatorRef.current.value === "!") {
      updatedTerm.op = "!exists";
    }

    termRef.current.reset();
    formReset();
    currentQuery.spec.updateTerm(updateId, updatedTerm);
    setUpdateId(undefined);
  }

  function addTerm() {
    switch (termRef.current.value) {
      case "tag":
        if (tagKeyRef.current.value.length < 1) return;
        if (operatorRequiresTwoValues && tagValueRef.current.value.length < 1) return;
        currentQuery.spec.addTagTerm(
          tagKeyRef.current.value,
          operatorRef.current.value as any,
          tagValueRef.current.value,
        );
        tagKeyRef.current.reset();
        tagValueRef.current.reset();
        break;
      case "property":
        if (hasAllowedProperties) {
          if (propertyKeySelectRef.current.value.length < 1) return;
          if (operatorRequiresTwoValues && propertyKeySelectRef.current.value.length < 1) return;
          currentQuery.spec.addPropertyTerm(
            propertyKeySelectRef.current.value,
            operatorRef.current.value as any,
            propertyValueRef.current.value,
          );
        } else {
          if (propertyKeyRef.current.value.length < 1) return;
          if (operatorRequiresTwoValues && propertyValueRef.current.value.length < 1) return;
          currentQuery.spec.addPropertyTerm(
            propertyKeyRef.current.value,
            operatorRef.current.value as any,
            propertyValueRef.current.value,
          );
        }
        propertyKeySelectRef.current.reset();
        propertyKeyRef.current.reset();
        propertyValueRef.current.reset();
        break;
      case "rel":
        if (relKeyRef.current.value.length < 1) return;
        if (operatorRequiresTwoValues && relValueRef.current.value.length < 1) return;
        currentQuery.spec.addRelTerm(
          relKeyRef.current.value,
          operatorRef.current.value as any,
          relValueRef.current.value,
        );
        relKeyRef.current.reset();
        relValueRef.current.reset();
        break;
    }
    termRef.current.reset();
    formReset();
  }

  function clearQuery() {
    onQuery(null);
    setIsApplied(false);
    currentQuery.clear();
    previousQuery.clear();
    setIsOpen(false);
    localStorage.removeItem(`${tableId}-query`);
  }

  function applyQuery() {
    if (currentQuery.spec.terms.length < 1 && currentQuery.spec.sort.by === "") {
      clearQuery();
      return;
    }
    setIsApplied(true);

    onQuery(currentQuery.toQueryObj);
    const queryStr = JSON.stringify(currentQuery);
    localStorage.setItem(`${tableId}-query`, queryStr);
  }

  return (
    <div
      ref={queryModalRef}
      className={`${isOpen && "query-before"} relative flex items-center justify-center`}
      style={{ width: 110 }}
    >
      <NGButton
        variant={isApplied ? "action" : "secondary"}
        onClick={() => setIsOpen((x) => !x)}
        size={"normal"}
        style={{ width: 110 }}
        renderIcon={(_, props) => (isApplied ? <Check {...props} /> : <></>)}
      >
        {isApplied ? "Queried" : "Query"}
      </NGButton>
      {isOpen && (
        <div className="query-modal absolute flex flex-col p-4 z-10 shadow-lg">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <NGLabel className="mb-2">Sort By</NGLabel>
                  <NGSelect
                    className="basis-0 flex-grow"
                    placeholder={"Sort By"}
                    onChange={(value) => {
                      if (value === "") {
                        currentQuery.spec.sort.setOrder("asc");
                      }
                      currentQuery.spec.sort.setBy(value);
                    }}
                    options={[{ label: "-", value: "" }, ...allowedProperties.map((p) => ({ label: p, value: p }))]}
                    value={currentQuery.spec.sort.by}
                  />
                </div>
                {currentQuery.spec.sort.by ? (
                  <div className="flex flex-col">
                    <NGLabel className="mb-2">Sort Order</NGLabel>
                    <NGSelect
                      style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                      size="small"
                      className="basis-0 flex-grow"
                      placeholder={"Sort Order"}
                      onChange={(value) => currentQuery.spec.sort.setOrder(value as "asc" | "desc")}
                      options={[
                        { label: "Asc", value: "asc" },
                        { label: "Desc", value: "desc" },
                      ]}
                      value={currentQuery.spec.sort.order}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center mb-2">
                <NGLabel>Match Terms By</NGLabel>
                <InfoTooltip
                  style={{ color: "var(--color-label)" }}
                  title={[
                    `'All' means all terms should match`,
                    `'Any' means any of the terms should match`,
                    `'None' means none of these terms should match`,
                  ]}
                />
              </div>
              <div className="flex items-center">
                <NGButton
                  size={"small"}
                  variant={currentQuery.spec.match === "all" ? "primary" : "secondary"}
                  onClick={() => currentQuery.spec.setMatch("all")}
                  style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                >
                  All
                </NGButton>
                <NGButton
                  size={"small"}
                  variant={currentQuery.spec.match === "any" ? "primary" : "secondary"}
                  onClick={() => currentQuery.spec.setMatch("any")}
                  style={{ borderRadius: 0 }}
                >
                  Any
                </NGButton>
                <NGButton
                  size={"small"}
                  variant={currentQuery.spec.match === "none" ? "primary" : "secondary"}
                  onClick={() => currentQuery.spec.setMatch("none")}
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  None
                </NGButton>
              </div>
            </div>
          </div>
          <NGLabel className="mt-4">Terms</NGLabel>
          <Table<QueryTableItem>
            hideSettings
            data={[
              ...currentQuery.spec.tagTerms.map((i) => ({
                id: i.id,
                term: "tag",
                key: i.tag || "",
                operator: i.op || "<",
                value: (i.value as string | undefined) || "",
              })),
              ...currentQuery.spec.propertyTerms.map((i) => ({
                id: i.id,
                term: "property",
                key: i.property || "",
                operator: i.op || "<",
                value: (i.value as string | undefined) || "",
              })),
              ...currentQuery.spec.relTerms.map((i) => ({
                id: i.id,
                term: "rel",
                key: i.rel || "",
                operator: i.op || "<",
                value: (i.value as string | undefined) || "",
              })),
            ]}
            tableId={"query"}
            columns={[
              {
                id: "term",
                label: "Term",
                size: 100,
                cell: (cellProps) => {
                  const value = cellProps.row.original.term;
                  if (!value) return value;
                  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
                },
              },
              {
                id: "key",
                label: "Key",
              },
              {
                id: "operator",
                label: "Operator",
                size: 100,
              },
              {
                id: "value",
                label: "Value",
              },
              {
                id: "actions",
                label: "Actions",
                size: 120,
                cell: (cellProps) => (
                  <div className="flex gap-2 py-1">
                    <NGButton
                      variant="secondary"
                      text
                      renderIcon={(_, props) => <Edit2 {...props} />}
                      onClick={() => onUpdateTerm(cellProps.row.original)}
                    />
                    <NGButton
                      variant="danger"
                      text
                      renderIcon={(_, props) => <MinusCircle {...props} />}
                      onClick={() => currentQuery.spec.removeTerm(cellProps.row.original.id)}
                    />
                  </div>
                ),
              },
            ]}
          />
          <NGLabel className="mt-4 mb-2">{updateId ? "Editing" : "Add"} Term</NGLabel>
          <div className="flex gap-2">
            <NGSelect
              className="basis-0 flex-grow"
              placeholder={termRef.current.label}
              onChange={(value) => {
                termRef.current.setValue(value);
                formReset();
              }}
              options={termRef.current.options}
              value={termRef.current.value}
              style={{ width: 120, minWidth: 120, maxWidth: 120 }}
            />
            {termRef.current.value === "tag" ? (
              <>
                <NGInput
                  className="basis-0 flex-grow"
                  placeholder={tagKeyRef.current.label}
                  value={tagKeyRef.current.value}
                  onChange={(e) => tagKeyRef.current.setValue(e.target.value)}
                />
                <NGSelect
                  className="basis-0 flex-grow"
                  placeholder={operatorRef.current.label}
                  onChange={(value) => operatorRef.current.setValue(value)}
                  options={operatorRef.current.options}
                  value={operatorRef.current.value}
                />
                {operatorRequiresTwoValues && (
                  <NGInput
                    className="basis-0 flex-grow"
                    placeholder={tagValueRef.current.label}
                    value={tagValueRef.current.value}
                    onChange={(e) => tagValueRef.current.setValue(e.target.value)}
                  />
                )}
              </>
            ) : null}
            {termRef.current.value === "property" ? (
              <>
                {hasAllowedProperties ? (
                  <NGSelect
                    className="basis-0 flex-grow"
                    placeholder={propertyKeySelectRef.current.label}
                    onChange={(value) => propertyKeySelectRef.current.setValue(value)}
                    options={propertyKeySelectRef.current.options}
                    value={propertyKeySelectRef.current.value}
                  />
                ) : (
                  <NGInput
                    className="basis-0 flex-grow"
                    placeholder={propertyKeyRef.current.label}
                    value={propertyKeyRef.current.value}
                    onChange={(e) => propertyKeyRef.current.setValue(e.target.value)}
                  />
                )}
                <NGSelect
                  className="basis-0 flex-grow"
                  placeholder={operatorRef.current.label}
                  onChange={(value) => operatorRef.current.setValue(value)}
                  options={operatorRef.current.options}
                  value={operatorRef.current.value}
                />
                {operatorRequiresTwoValues && (
                  <NGInput
                    className="basis-0 flex-grow"
                    placeholder={propertyValueRef.current.label}
                    value={propertyValueRef.current.value}
                    onChange={(e) => propertyValueRef.current.setValue(e.target.value)}
                  />
                )}
              </>
            ) : null}
            {termRef.current.value === "rel" ? (
              <>
                <NGInput
                  className="basis-0 flex-grow"
                  placeholder={relKeyRef.current.label}
                  value={relKeyRef.current.value}
                  onChange={(e) => relKeyRef.current.setValue(e.target.value)}
                />
                <NGSelect
                  className="basis-0 flex-grow"
                  placeholder={operatorRef.current.label}
                  onChange={(value) => operatorRef.current.setValue(value)}
                  options={operatorRef.current.options}
                  value={operatorRef.current.value}
                />
                {operatorRequiresTwoValues && (
                  <NGInput
                    className="basis-0 flex-grow"
                    placeholder={relValueRef.current.label}
                    value={relValueRef.current.value}
                    onChange={(e) => relValueRef.current.setValue(e.target.value)}
                  />
                )}
              </>
            ) : null}
            <NGButton
              disabled={isActionDisabled}
              variant={updateId ? "primary" : "action"}
              outlined
              onClick={updateId ? updateTerm : addTerm}
              className={`w-16`}
            >
              {updateId ? "Save" : "Add"}
            </NGButton>
            {updateId && (
              <NGButton
                variant="secondary"
                outlined
                renderIcon={(_, props) => <X {...props} />}
                onClick={() => {
                  setUpdateId(undefined);
                  termRef.current.reset();
                  formReset();
                }}
              />
            )}
          </div>
          <div className="flex mt-8 gap-4">
            <NGButton
              className="flex-grow"
              disabled={(!isQueryDirty && !isApplied) || isLoading}
              variant={"secondary"}
              onClick={clearQuery}
            >
              Clear
            </NGButton>
            <NGButton
              className="flex-grow"
              disabled={!isQueryDirty}
              loading={isLoading}
              variant={"primary"}
              onClick={applyQuery}
            >
              Apply
            </NGButton>
          </div>
        </div>
      )}
    </div>
  );
}
export const CustomTableCPLNQuery = observer(CustomTableCPLNQueryRaw);
