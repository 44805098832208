import React from "react";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  linkWithPopup,
  unlink,
  GithubAuthProvider,
  OAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { observer } from "mobx-react-lite";
import { FormLabel } from "../../components/forms/formLabel";
import { Modal, notification } from "antd";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Theme } from "../../mobxStores/uiData/theme";
import { User } from "../../mobxStores/user/user";
import { getCancelToken, getLocalToken, request } from "../../services/cpln";
import { CancelTokenSource } from "axios";
import { NoTag } from "../../components/tag/noTag";
import { NavLink, useNavigate } from "react-router-dom";
import { NGButton } from "../../newcomponents/button/Button";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { jwtDecode } from "jwt-decode";
import { ChevronDown, ChevronUp } from "react-feather";
import { CodeEditor } from "../group/identityMatcher/codeEditor";
import { TimezoneDisplay } from "../../components/layout/header/TimezoneDisplay";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { NGLabel } from "../../newcomponents/text/label";
import { NGInput } from "../../newcomponents/input/input";

const githubProvider = new GithubAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com").setCustomParameters({
  prompt: "select_account",
});
const googleProvider = new GoogleAuthProvider().setCustomParameters({ prompt: "select_account" });

const providers = [
  {
    name: "Google",
    id: "google.com",
    firebaseProvider: googleProvider,
    isLinked: function (data: string[]) {
      return data.includes(this.id);
    },
    imagePath: "/resources/logos/login/google.svg",
  },
  {
    name: "Microsoft",
    id: "microsoft.com",
    firebaseProvider: microsoftProvider,
    isLinked: function (data: string[]) {
      return data.includes(this.id);
    },
    imagePath: "/resources/logos/login/microsoft.svg",
  },
  {
    name: "Github",
    id: "github.com",
    firebaseProvider: githubProvider,
    isLinked: function (data: string[]) {
      return data.includes(this.id);
    },
    imagePath: "/resources/logos/login/github.svg",
  },
];

const FirebaseUserModalRaw: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const navigate = useNavigate();
  const { org } = ConsoleContext;
  const [tempOrg, setTempOrg] = React.useState(org || "");
  const [isLoadingMyGroups, setIsLoadingMyGroups] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLinking, setIsLinking] = React.useState("");
  const [isUnlinking, setIsUnlinking] = React.useState("");
  const [data, setData] = React.useState<string[]>(null as any);
  const [triggerCount, setTriggerCount] = React.useState(0);

  const [localToken, setLocalToken] = React.useState("");
  const [showCustomClaims, setShowCustomClaims] = React.useState(false);

  const [sentryMessage, setSentryMessage] = React.useState("");

  React.useEffect(() => {
    setData(null as any);
    const user = getAuth(User.getFirebaseApp()).currentUser;
    const email = user?.email;
    if (email) {
      fetchSignInMethodsForEmail(getAuth(User.getFirebaseApp()), email).then((res: any) => {
        setData(res);
      });
    }
  }, [triggerCount]);

  React.useEffect(() => {
    if (!tempOrg) {
      setMyGroups([]);
      return;
    }
    fetchMyGroups();
  }, [tempOrg]);

  const myGroupsCancelTokenRef = React.useRef<CancelTokenSource | undefined>(undefined);
  const [myGroups, setMyGroups] = React.useState<string[]>([]);

  async function fetchMyGroups() {
    try {
      setIsLoadingMyGroups(true);
      if (myGroupsCancelTokenRef.current) {
        myGroupsCancelTokenRef.current.cancel("Cancelled");
      }
      myGroupsCancelTokenRef.current = getCancelToken();
      const cancelToken = myGroupsCancelTokenRef.current.token;
      const { data } = await request({ url: `/org/${tempOrg}/user/-myGroups`, cancelToken });
      setMyGroups(data.items);
      setIsLoadingMyGroups(false);
    } catch (e) {
      setIsLoadingMyGroups(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to Fetch Groups",
        description: errorMessage,
      });
      setMyGroups([]);
    }
  }

  React.useEffect(() => {
    getLocalToken().then(({ accessToken }) => {
      setLocalToken(accessToken);
    });
  }, []);

  function getCustomClaims() {
    if (!localToken) {
      return {};
    }
    try {
      const customClaims = jwtDecode(localToken);
      for (const property of [
        "name",
        "picture",
        "iss",
        "aud",
        "auth_time",
        "user_id",
        "sub",
        "iat",
        "exp",
        "email",
        "email_verified",
        "firebase",
        "verificationEmailSent",
      ]) {
        delete customClaims[property];
      }
      return customClaims;
    } catch (e) {
      console.error("Failed to get custom claims", e.message);
      return {};
    }
  }

  return (
    <Modal closable open={true} onCancel={onClose} footer={null}>
      <div>
        {IS_DEPLOYMENT_ENV_TEST ? (
          <div className="mb-8">
            <TimezoneDisplay showAll={IS_DEPLOYMENT_ENV_TEST} />
          </div>
        ) : null}
        <div className="mb-8">
          <FormLabel size="large">User Account Info</FormLabel>
          <div className="my-2">
            <FormLabel>Email</FormLabel>
            <div>{User.email}</div>
          </div>
        </div>
        <div className="mb-8">
          <div className="mb-2">
            <FormLabel size="large">Group Memberships</FormLabel>
          </div>
          <div className="mb-1">
            <FormLabel>Org</FormLabel>
          </div>
          <NGKindSelect value={tempOrg} onChange={(_org) => setTempOrg(_org)} kind={"org"} />
          {tempOrg && !isLoadingMyGroups ? (
            <div className="mt-2">
              {myGroups.length < 1 ? (
                <NoTag override={`No Group Memberships for Org '${tempOrg}'`} />
              ) : (
                <div className="border border-b-0" style={{ borderRadius: 6 }}>
                  <div className="px-2 py-1 table-labels" style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}>
                    Group Name
                  </div>
                  {myGroups.map((g, index) => {
                    const lastItem = index === myGroups.length - 1;
                    return (
                      <div
                        className={`px-2 py-1 border-b`}
                        style={{
                          borderBottomLeftRadius: lastItem ? 6 : undefined,
                          borderBottomRightRadius: lastItem ? 6 : undefined,
                        }}
                      >
                        <NavLink className="text-link" to={`/console${g}`}>
                          {g.split("/group/")[1]}
                        </NavLink>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : null}
        </div>
        <div className={`${showCustomClaims ? "mb-8" : "mb-4"}`}>
          <div className="mb-2 flex items-center gap-1">
            <FormLabel size="large">Custom Claims</FormLabel>
            <button className="ngfocus color-link-hover" onClick={() => setShowCustomClaims((x) => !x)}>
              {showCustomClaims ? <ChevronUp className="h-4" /> : <ChevronDown className="h-4" />}
            </button>
          </div>
          {showCustomClaims ? (
            Object.keys(getCustomClaims()).length < 1 ? (
              "None"
            ) : (
              <>
                <CodeEditor
                  language="json"
                  options={{ readOnly: true }}
                  setValue={() => {}}
                  value={JSON.stringify(getCustomClaims(), null, 2)}
                />
              </>
            )
          ) : null}
        </div>
        <div className="mb-8">
          <FormLabel size="large">Account Providers</FormLabel>
          {data === null ? (
            <div>Loading</div>
          ) : data.includes("password") ? (
            <div>Unavailable for email/password accounts</div>
          ) : (
            <div className="flex flex-col gap-2">
              {providers.map((p) => (
                <div className="flex items-center">
                  <div className="flex items-center" style={{ width: "100%" }}>
                    {p.id === "github.com" && Theme.theme === "light" ? (
                      <img
                        className="mr-2"
                        style={{ width: 25, height: 25, backgroundColor: "black", padding: 2, borderRadius: "100%" }}
                        src={p.imagePath}
                      />
                    ) : (
                      <img className="mr-2" style={{ width: 25, height: 25 }} src={p.imagePath} />
                    )}
                    <div>{p.name}</div>
                  </div>
                  {p.isLinked(data) ? (
                    <NGButton
                      size={"normal"}
                      disabled={isLoading || data.length === 1}
                      loading={isUnlinking === p.id}
                      style={{ width: 100 }}
                      onClick={async () => {
                        if (data.length === 1) {
                          return;
                        }
                        setIsLoading(true);
                        setIsUnlinking(p.id);
                        try {
                          const user = getAuth(User.getFirebaseApp()).currentUser;
                          if (user) {
                            await unlink(user, p.id);
                          }
                          notification.success({ message: "Unlinked provider" });
                        } catch (e: any) {
                          notification.warning({ message: "Failed to unlink provider", description: e.message });
                        } finally {
                          setTriggerCount((x) => x + 1);
                          setIsUnlinking("");
                          setIsLoading(false);
                        }
                      }}
                      variant={"danger"}
                      outlined
                    >
                      Unlink
                    </NGButton>
                  ) : (
                    <NGButton
                      size={"normal"}
                      disabled={isLoading}
                      loading={isLinking === p.id}
                      style={{ width: 100 }}
                      onClick={async () => {
                        try {
                          setIsLoading(true);
                          setIsLinking(p.id);
                          const userToLink = getAuth(User.getFirebaseApp()).currentUser;
                          if (userToLink) {
                            await linkWithPopup(userToLink, p.firebaseProvider);
                            notification.success({ message: "Linked provider" });
                          } else {
                            throw new Error("User not found by firebase, try again.");
                          }
                        } catch (e: any) {
                          notification.warning({ message: "Failed to link provider", description: e.message });
                        } finally {
                          setTriggerCount((x) => x + 1);
                          setIsLinking("");
                          setIsLoading(false);
                        }
                      }}
                      variant={"primary"}
                    >
                      Link
                    </NGButton>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {User.isCplnTeam ? (
          <div className="flex flex-col gap-2">
            <NGLabel>Test Sentry</NGLabel>
            <NGInput
              placeholder="Error message"
              value={sentryMessage}
              onChange={(e) => setSentryMessage(e.target.value)}
            />
            <NGButton
              variant="secondary"
              onClick={() => {
                navigate(`/trigger-sentry?message=${sentryMessage}`);
                onClose();
              }}
            >
              Trigger Exception
            </NGButton>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export const FirebaseUserModal = observer(FirebaseUserModalRaw);
