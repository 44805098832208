import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { createGroupInstance, GroupMobx } from "../../mst/kinds/group";
import { GroupDetail } from "./detail";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { captureExc } from "../../errorBoundary";

type RouteParams = "group";
const GroupDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { group: groupName } = useParams<RouteParams>();
  const [group, setGroup] = React.useState<GroupMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [groupName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("group", groupName) });
      setGroup(createGroupInstance(data));
      setIsLoading(false);
    } catch (e) {
      captureExc(e);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching group to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"group"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {group.name} - Group - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: group, fetchItem }}>
        <NGFormContext.Provider value={formDataRef.current}>
          <GroupDetail group={group} />
        </NGFormContext.Provider>
      </DetailContext.Provider>
    </>
  );
};

export const GroupDetailRoute = observer(GroupDetailRouteRaw);
