import { makeAutoObservable } from "mobx";

class UserPreferencesMobx {
  useSplitViewComparison: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.useSplitViewComparison = (localStorage.getItem("useSplitViewComparison") || "false") === "true";
  }

  setUseSplitViewComparison(value: boolean) {
    localStorage.setItem("useSplitViewComparison", String(value));
    this.useSplitViewComparison = value;
  }
}

export const UserPreferences = new UserPreferencesMobx();
