import { types, Instance } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { Mk8sDraftUnmanagedNodePoolModel, Mk8sDraftUnmanagedNodePoolReadOnlyModel } from "./mk8s.draft.provider.common";
import { Mk8sDraftNetworkingModel } from "./mk8s.draft.providerNetworking";

export const Mk8sDraftProviderGenericModel = types
  .model({
    _location: types.optional(types.string, ""),
    location: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Location",
        initialValue: "",
        options: [],
      }),
    ),
    _nodePools: types.array(Mk8sDraftUnmanagedNodePoolReadOnlyModel),
    nodePools: types.array(Mk8sDraftUnmanagedNodePoolModel),
    networking: types.optional(Mk8sDraftNetworkingModel, () => Mk8sDraftNetworkingModel.create()),
    removed: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    reset() {
      self.location.setInitialValue(self._location);

      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftUnmanagedNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          }),
        );
      }
      self.networking.reset();
      self.removed = false;
    },
    confirm() {
      self._location = self.location.value;
      self.location.confirm();

      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftUnmanagedNodePoolReadOnlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })),
            taints: nodePool.asObject.taints,
          }),
        );
      }
      self.networking.confirm();
      self.removed = false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }

      self.nodePools.push(
        Mk8sDraftUnmanagedNodePoolModel.create({
          index,
          status: "added",
        }),
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.nodePools.remove(node);
    },
  }))
  .views((self) => ({
    isNodePoolNameValid(index: number) {
      let res = true;
      const node = self.nodePools.find((np) => np.index === index)!;
      const pools = [...self.nodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyNodePoolNameConflicts() {
      for (const nodePool of self.nodePools) {
        if (
          [...self.nodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value,
          )
        ) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.location.isDirty) res = true;
      if (self._nodePools.length !== self.nodePools.length) res = true;
      if (self.removed) res = true;
      if (self.nodePools.some((n) => n.isDirty)) res = true;
      if (self.networking.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.location.isDirty) reason = self.location.isDirtyReason;
      if (self._nodePools.length !== self.nodePools.length) reason = "node pools length is different";
      if (self.removed) reason = "removed a pool";
      if (self.nodePools.some((n) => n.isDirty))
        reason = self.nodePools.find((n) => n.isDirty)?.dirtyReason || "node pools but not found";
      if (self.networking.isDirty) reason = self.networking.dirtyReason;

      return reason;
    },
    get isValid() {
      let res = true;
      if (self.anyNodePoolNameConflicts) res = false;
      if (!self.location.value) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      if (!self.networking.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.anyNodePoolNameConflicts) reason = "node pool name conflict";
      if (!self.location.value) reason = "no location is selected";
      if (self.nodePools.some((n) => !n.isValid)) reason = self.nodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (!self.networking.isValid) reason = self.networking.invalidReason;
      return reason;
    },
    get ui_isGenericValid() {
      let res = true;
      if (!self.location.value) res = false;
      return res;
    },
    get dryRun_genericPathList() {
      return [
        //
        "spec.provider.generic.location",
      ];
    },
    get ui_isAdvancedValid() {
      let res = true;
      if (!self.networking.isValid) res = false;
      return res;
    },
    get dryRun_advancedPathList() {
      return [
        //
        ...self.networking.dryRun_pathList("generic"),
      ];
    },
    get asObject() {
      return {
        location: self.location.value,
        nodePools: self.nodePools.map((n) => n.asObject),
        networking: self.networking.asObject,
      };
    },
  }));
export interface Mk8sDraftProviderGenericMobx extends Instance<typeof Mk8sDraftProviderGenericModel> {}
