import { LoggingLogzioMobx } from "../../../../mst/kinds/org";
import { defaultValues } from "../../../../mst/base";
import { SelectModel } from "../../../../mobxDataModels/selectModel";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { ngParseLink } from "../../../../utils/linkParser/linkParser";

export function getLogsLogzioData(logzio: LoggingLogzioMobx) {
  function getDefaultLogzioListenerHost() {
    return logzio.listenerHost || defaultValues.org.logging.logzio.listenerHost;
  }
  const logzio_listenerHost = SelectModel.create({
    label: "Listener Host",
    initialValue: getDefaultLogzioListenerHost(),
    options: [
      { label: "listener.logz.io", value: "listener.logz.io" },
      { label: "listener-nl.logz.io", value: "listener-nl.logz.io" },
    ],
  });
  function getDefaultLogzioCredentials() {
    return ngParseLink(logzio.credentials || "", { kind: "secret" }).name;
  }

  const logzio_credentials = StringModel.create({
    isRequired: true,
    label: "Credentials",
    initialValue: getDefaultLogzioCredentials(),
  });
  function isDirty() {
    let res = false;
    if (getDefaultLogzioListenerHost() !== logzio_listenerHost.value) {
      res = true;
    }
    if (getDefaultLogzioCredentials() !== logzio_credentials.value) {
      res = true;
    }
    return res;
  }

  function isValid() {
    let res = true;
    if (logzio_credentials.value.length < 1) {
      res = false;
    }
    return res;
  }

  function asObject() {
    return {
      logzio: {
        listenerHost: logzio_listenerHost.value,
        credentials: ngParseLink(logzio_credentials.value, { kind: "secret" }).absolute,
      },
    };
  }

  return {
    logProvider: "logzio",
    listenerHost: logzio_listenerHost,
    credentials: logzio_credentials,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
