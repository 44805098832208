import * as React from "react";

interface Props {
  override?: string;
  danger?: boolean;
}
export const NoTag: React.FC<Props> = ({ override, danger }) => {
  let overrideStyle = {};
  if (danger) {
    // TODO handle for dark theme and env variables
    overrideStyle = { color: "white", backgroundColor: "#ED4F32" };
  }
  return (
    <div
      className="py-1 px-2 text-sm inline-flex items-center mb-2 old-value"
      style={{ ...overrideStyle, borderRadius: 6 }}
    >
      {override ? override : "No Tags"}
    </div>
  );
};
