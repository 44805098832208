import { PortModel } from "../../../../mobxDataModels/portModel";
import { SelectModel } from "../../../../mobxDataModels/selectModel";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import {
  LoggingSyslogMobx,
  LoggingSyslogModel,
  LoggingSyslogModelFormatOptions,
  LoggingSyslogModelModeOptions,
  LoggingSyslogModelSeverityOptions,
} from "../../../../mst/kinds/org";

export function getLogsSyslogData(syslog: LoggingSyslogMobx) {
  function getDefaultSyslogHost() {
    return syslog.host || "";
  }
  const syslog_host = StringModel.create({
    label: "Host",
    initialValue: getDefaultSyslogHost(),
    isRequired: true,
  });

  function getDefaultSyslogPort() {
    return syslog.port || "";
  }
  const syslog_port = PortModel.create({
    label: "Port",
    value: getDefaultSyslogPort(),
    isRequired: true,
  });

  function getDefaultSyslogMode() {
    return syslog.mode || LoggingSyslogModelModeOptions[0].value;
  }
  const syslog_mode = SelectModel.create({
    label: "Mode",
    initialValue: getDefaultSyslogMode(),
    options: LoggingSyslogModelModeOptions,
  });

  function getDefaultSyslogFormat() {
    return syslog.format || LoggingSyslogModelFormatOptions[0].value;
  }
  const syslog_format = SelectModel.create({
    label: "Format",
    initialValue: getDefaultSyslogFormat(),
    options: LoggingSyslogModelFormatOptions,
  });

  function getDefaultSyslogSeverity() {
    return syslog.severity ?? 6;
  }
  const syslog_severity = SelectModel.create({
    label: "Severity",
    initialValue: String(getDefaultSyslogSeverity()),
    options: LoggingSyslogModelSeverityOptions.map((i) => ({ label: i.label, value: String(i.value) })),
  });

  function isDirty() {
    let res = false;
    if (getDefaultSyslogHost() !== syslog_host.value) res = true;
    if (getDefaultSyslogPort() !== Number(syslog_port.value)) res = true;
    if (getDefaultSyslogMode() !== syslog_mode.value) res = true;
    if (getDefaultSyslogFormat() !== syslog_format.value) res = true;
    if (getDefaultSyslogSeverity() !== Number(syslog_severity.value)) res = true;
    return res;
  }

  function isValid() {
    let res = true;
    if (!syslog_host.isValid) res = false;
    if (!syslog_port.isValid) res = false;
    return res;
  }

  function asObject() {
    return {
      syslog: {
        host: syslog_host.value,
        port: Number(syslog_port.value),
        mode: syslog_mode.value,
        format: syslog_format.value,
        severity: Number(syslog_severity.value),
      },
    };
  }

  return {
    logProvider: "syslog",
    host: syslog_host,
    port: syslog_port,
    mode: syslog_mode,
    format: syslog_format,
    severity: syslog_severity,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
