import * as React from "react";
import { NGButton } from "../../newcomponents/button/Button";
import { useNGFormContext } from "../../reactContexts/ngFormContext";

interface Props {
  onReset: any;
  onSave: any;
  resetDisabled: boolean;
  saveDisabled: boolean;
  loading: boolean;
  disableOutlineOnReset?: boolean;
}
export const FormButtons: React.FC<Props> = ({
  onReset,
  onSave,
  resetDisabled,
  saveDisabled,
  loading,
  disableOutlineOnReset = false,
}) => {
  const formData = useNGFormContext();

  return (
    <div className="flex items-center ngformbuttons" style={{ width: 450 }}>
      <NGButton
        style={{ width: 215, marginRight: 10 }}
        disabled={resetDisabled}
        size="toRemoveLarge"
        data-testid="action-reset"
        variant={"danger"}
        outlined={!disableOutlineOnReset}
        onClick={() => {
          onReset();
          if (formData) {
            formData.reset();
          }
        }}
      >
        Reset
      </NGButton>
      <NGButton
        style={{ width: 215, marginLeft: 10 }}
        disabled={saveDisabled}
        loading={loading}
        size="toRemoveLarge"
        data-testid="action-save"
        variant={"primary"}
        onClick={() => {
          onSave();
          if (formData) {
            formData.reset();
          }
        }}
      >
        Save
      </NGButton>
    </div>
  );
};
