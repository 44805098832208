import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { QuotaList } from "./list";
import QuotaDetail from "./detail";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const QuotaRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<QuotaList />} />
        <Route path={`:idName/*`} element={<QuotaDetail />} />
      </Routes>
    </Layout>
  );
};

export default observer(QuotaRoute);
