import { makeAutoObservable } from "mobx";

const ACCESS_CONTROL = "ACCESS_CONTROL";

export const MenuExpandableCategories = { ACCESS_CONTROL: ACCESS_CONTROL };
type MenuCategories = keyof typeof MenuExpandableCategories;

class SidebarContextMobx {
  scrollTop: number = 0;
  isAccessControlExpanded: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  public Initalize() {
    this.isAccessControlExpanded = this.convertLocalStorageValueToBoolean(
      localStorage.getItem(ACCESS_CONTROL) || "true",
    );
  }
  public setScrollPosition(value: number) {
    this.scrollTop = value;
  }
  public toggleCategoryExpansion(category: MenuCategories): void {
    let value = true;
    switch (category) {
      case ACCESS_CONTROL:
        this.isAccessControlExpanded = !this.isAccessControlExpanded;
        value = this.isAccessControlExpanded;
        break;
      default:
        return;
    }
    localStorage.setItem(category, value.toString());
  }

  private convertLocalStorageValueToBoolean(value: string | null): boolean {
    return value?.toLowerCase() === "true";
  }
}

export const SidebarContext = new SidebarContextMobx();
