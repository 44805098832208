import { observer } from "mobx-react-lite";
import { Theme } from "../../mobxStores/uiData/theme";
import "./style.css";
import { useEffect } from "react";

const CircularThemeSwitchRaw = (props: { theme?: "dark" | "light"; setTheme?: (state: "dark" | "light") => void }) => {
  const { theme } = Theme;

  useEffect(() => {
    if (props.theme !== theme && props.setTheme) props.setTheme(theme);
  }, []);

  return (
    <div className="circular-theme-switch-container mr-4">
      <input
        checked={theme === "dark"}
        onChange={(e) => {
          Theme.setTheme(e.target.checked ? "dark" : "light");
          if (props.setTheme) props.setTheme(e.target.checked ? "dark" : "light");
        }}
        type="checkbox"
        id="theme-toggle"
      />
      <label htmlFor="theme-toggle"></label>
    </div>
  );
};

export const CircularThemeSwitch = observer(CircularThemeSwitchRaw);
