import { DryRunIssue, DryRunResponse } from "../../services/utils";

interface Props {
  dryRunResponse: DryRunResponse;
  path: string;
  paths?: string[];
  onFix?: (value: string) => void;
}
export function getDryRunErrorText({
  dryRunResponse: response,
  path,
  paths = [],
}: Props): { message: string; severity: "error" | "warning" } {
  let issues: DryRunIssue[] = response?.details?.issues || [];

  let filteredIssues = (issues || []).filter((i) => {
    let p = i.path;
    if (!p) {
      return false;
    }
    return p === path || paths.includes(p);
  });
  if (filteredIssues.length < 1) {
    return { message: "", severity: "warning" };
  }

  const issue = filteredIssues[0];

  return { message: issue.message, severity: issue.severity === "error" ? "error" : "warning" };
}
