import * as React from "react";
import { LocationMobx } from "../../mst/kinds/location";
import { observer } from "mobx-react-lite";
import { FormElement } from "../../components/forms/formElement";
import { Value } from "../../components/tag/value";
import { NGLabel } from "../../newcomponents/text/label";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  location: LocationMobx;
}
const InfoRaw: React.FC<Props> = ({ location }) => {
  return (
    <div>
      <FormElement label={"Description"} value={location.description} />
      <div className="flex my-4">
        <div className={`w-3/12`}>
          <FormElement label={"Provider"} value={location.provider} />
        </div>
        <div className={`w-3/12`}>
          <FormElement label={"Region"} value={location.region} />
        </div>
        <div className={`w-3/12`}>
          {/* TODO use colors */}
          <FormElement label={"Enabled"} value={location.spec.enabled ? "Yes" : "No"} />
        </div>
      </div>
      <div className="mb-4">
        <div className="mb-2">
          <NGLabel>IP Ranges</NGLabel>
        </div>
        {location.status.ipRanges.length < 1 ? (
          <div>No value is reported</div>
        ) : (
          <div className="flex flex-col gap-1">
            {location.status.ipRanges.map((ip) => (
              <div>
                <Value value={ip} />
              </div>
            ))}
          </div>
        )}
      </div>
      <TagLinksTable
        tableId="location-info-tag-links"
        tags={Object.entries(location.tags || {})
          .map(([tagKey, tagValue]) => ({ key: tagKey, value: String(tagValue || "") }))
          .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />
    </div>
  );
};

export const Info = observer(InfoRaw);
