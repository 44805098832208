import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTableKindId } from "../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../newcomponents/table/data/useTableItemQueryData";
import { Table } from "../../newcomponents/table/table";
import { TableNewItemButton } from "../../newcomponents/table/components/tableNewButton";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { linksOf } from "../../services/cpln";
import { TableActionsDropdown } from "../../newcomponents/table/components/tableActionsDropdown";
import { DeleteModal } from "../../components/modals/deleteModal";
import { IpSet } from "../../schema/types/ipset";

interface IpSetTableItem extends IpSet {
  selfLink: string;
}

const IpSetListRaw: React.FC = () => {
  const [selectedLinks, setSelectedLinks] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<string>("");

  const { kind, id } = useTableKindId("ipset", "ipset-list");
  const qData = useTableItemQueryData<IpSet>(kind, { fetchAllPages: true });

  const ipsetTableItems: IpSetTableItem[] = qData.visibleItems.map((item) => {
    const selfLink = linksOf(item).self!;
    return { ...item, selfLink: selfLink };
  });

  return (
    <>
      <Table<IpSetTableItem>
        title={"IP Sets"}
        data={ipsetTableItems}
        selectKey="selfLink"
        selectMode="multi"
        selections={selectedLinks}
        onSelectionsChange={setSelectedLinks}
        tableId={id}
        headerRenderer={() => {
          return (
            <>
              <TableNewItemButton kind={kind} />
              <TableActionsDropdown
                kind={kind}
                selectionLinks={selectedLinks}
                options={[
                  {
                    key: "delete",
                    label: "Delete",
                    disabled: selectedLinks.length < 1,
                    danger: true,
                    callback: () => setAction("delete"),
                  },
                ]}
              />
              <TableHeaderRefreshButton onClick={qData.fetchItems} />
              <CustomTableCPLNQuery
                tableId={id}
                kind={kind}
                onQuery={qData.setQuery}
                isLoading={qData.isLoadingQueried}
              />
            </>
          );
        }}
        enableFilter
        isLoading={qData.isLoading}
        columns={[
          NameDescriptionColumn(), //
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
      />
      {action === "delete" ? (
        <DeleteModal
          kind={kind}
          onCancel={() => setAction("")}
          onComplete={() => {
            setAction("");
            setSelectedLinks([]);
            qData.fetchItems();
          }}
          links={selectedLinks}
        />
      ) : null}
    </>
  );
};

export const IpSetList = observer(IpSetListRaw);
