import { types } from "mobx-state-tree";

interface InputTransforms {
  [_: string]: ((value: string) => string)[];
}
export const inputTransforms: InputTransforms = {
  trim: [
    (value) => {
      return value.trim();
    },
  ],
  lowerCase: [
    (value) => {
      return value.toLowerCase();
    },
  ],
  phone: [
    (value) => {
      const allowedCharacters = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", " ", "-", "+"];
      let newValue = "";
      for (let char of value) {
        if (allowedCharacters.includes(char)) {
          newValue += char;
        }
      }
      return newValue;
    },
  ],
  number: [
    (value) => {
      if (!value.trim()) return "";
      let newValue = "";
      for (let char of value) {
        // if it is a number - not not a number
        if (!Number.isNaN(Number(char))) {
          newValue = `${newValue}${char}`;
        }
      }
      if (newValue.startsWith("0")) {
        let hasNonZero = false;
        for (let char of newValue) {
          if (char !== "0") hasNonZero = true;
        }
        if (!hasNonZero) return "0";

        if (newValue.length > 1) {
          let lastZeroIndex = 0;
          for (let charIndex in newValue as any) {
            const char = newValue[Number(charIndex)];
            if (char !== "0") break;
            lastZeroIndex++;
          }
          return newValue.slice(lastZeroIndex);
        }
      }
      return newValue;
    },
  ],
  awsAccountId: [
    (value) => {
      return value.replace(/-/g, "");
    },
  ],
  cpu: [
    (value) => {
      if (!value.trim()) return "";
      let newValue = "";
      for (let char of value) {
        // if it is a number or a dot - not not a number
        if (!Number.isNaN(Number(char)) || char === ".") {
          newValue = `${newValue}${char}`;
        }
      }
      return newValue;
    },
  ],
};
export const TransformKeyMobxProperty = types.maybe(
  types.enumeration(["trim", "lowerCase", "number", "awsAccountId", "phone", "cpu"])
);

export type TransformKey = "trim" | "lowerCase" | "number" | "awsAccountId" | "phone" | "cpu";
