import React from "react";
import { observer } from "mobx-react-lite";
import { DetailedRadioButton } from "../detailedRadioButton";

export interface DetailedRadioOption {
  value: string;
  title: string;
  description: string;
  disabled?: boolean;
}

interface Props {
  className?: string;
  groupName: string;
  selectedValue: string;
  options: DetailedRadioOption[];
  onChange: (value: string) => void;
}

const DetailedRadioGroupRaw: React.FC<Props> = ({
  className = "",
  groupName,
  selectedValue: value,
  options,
  onChange,
}) => {
  // Functions //
  function handleRadioButtonChange(option: DetailedRadioOption): void {
    onChange(option.value);
  }

  return (
    <div className={`flex flex-wrap gap-y-3 gap-x-3 items-stretch ${className}`}>
      {options.map((item, index) => (
        <DetailedRadioButton
          key={index}
          id={"radio-" + item.value + "-" + groupName}
          group={groupName}
          isChecked={value === item.value}
          option={item}
          disabled={item.disabled}
          onChange={handleRadioButtonChange}
        />
      ))}
    </div>
  );
};

export const DetailedRadioGroup = observer(DetailedRadioGroupRaw);
