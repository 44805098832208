import * as React from "react";
import { observer } from "mobx-react-lite";
import { ArrowUpRight } from "react-feather";
import { ViewModal } from "../modals/viewModal";
import { NGButton } from "../../newcomponents/button/Button";
import { Timezone } from "../../mobxStores/userData/timezone";
import { DateString } from "../dateString";
import { formatStrings } from "../../utils/time";
import { request } from "../../services/cpln";
import { Table } from "../../newcomponents/table/table";
import { TableHeaderRefreshButton } from "../../newcomponents/table/components/RefreshButton";
import { DateStringColumn } from "../../newcomponents/table/columns/generic/dateString";

interface Props {
  selfLink: string;
}
const CommandsRaw: React.FC<Props> = ({ selfLink }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [command, setCommand] = React.useState(null);
  const [commands, setCommands] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchCommands();
  }, []);

  async function fetchCommands() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: selfLink + "/-command" });
      setCommands(data.items);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
    }
  }

  return (
    <>
      <Table<any>
        title={"Commands"}
        data={commands}
        tableId={`${selfLink}-commands`}
        headerRenderer={() => {
          return (
            <>
              <TableHeaderRefreshButton onClick={fetchCommands} />
            </>
          );
        }}
        enableFilter
        hideSettings
        isLoading={isLoading}
        columns={[
          { id: "type", label: "Type" },
          { id: "lifecycleStage", label: "Lifecycle Stage" },
          DateStringColumn({ id: "created" }, { format: formatStrings.log }),
          {
            id: "messages",
            label: "Messages",
            cell: ({ row }) => {
              const command = row.original;
              const messages: string[] = command.status?.messages || [];
              return <div> {messages.length > 0 ? `${messages.length} Messages` : "-"}</div>;
            },
          },
          {
            id: "actions",
            label: "",
            cell: ({ row }) => {
              const command = row.original;
              return (
                <div className="flex justify-end">
                  <NGButton
                    variant={`secondary`}
                    size={"small"}
                    onClick={() => {
                      setCommand(command);
                      setIsModalVisible(true);
                    }}
                    renderIcon={(_, props) => <ArrowUpRight {...props} />}
                  >
                    View
                  </NGButton>
                </div>
              );
            },
          },
        ]}
      />
      {isModalVisible && (
        <ViewModal
          title={"Command"}
          object={command}
          onClose={() => setIsModalVisible(false)}
          visible={isModalVisible}
          filename={`command-${(command as any).id}`}
        />
      )}
    </>
  );
};

export const Commands = observer(CommandsRaw);
