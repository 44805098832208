import * as React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { VersionCopyright } from "../components/generic/version";
import { User } from "../mobxStores/user/user";
import { DOCS_URL, MARKETING_URL, OPENAPI_URL } from "../envVariables";

const EducationalVideosLink: React.FC = () => {
  if (!User.isLoggedIn) {
    return null;
  }

  return (
    <Link to={"/console/educational-videos"} className="ngfocus color-link-hover">
      Videos
    </Link>
  );
};
export class EducationalVideosLinkWithError extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, _errorInfo: any) {
    this.setState({ errorMessage: error.message, errorInfo: _errorInfo });
  }

  render() {
    if ((this.state as any).hasError) {
      return null;
    }

    return <EducationalVideosLink />;
  }
}

const FooterRaw = () => {
  return (
    <footer className="footer">
      <div className="footer-copyright">
        <span>From many clouds, one™</span>
        <span>
          Copyright <VersionCopyright /> {new Date().getFullYear()} Control Plane Corporation
        </span>
      </div>
      <div className="footer-links">
        <Link className="ngfocus color-link-hover" to={"/credits"}>
          Credits
        </Link>
        <a className="ngfocus color-link-hover" href={MARKETING_URL} target={"_blank"}>
          Home Page
        </a>
        <EducationalVideosLinkWithError />
        <a className="ngfocus color-link-hover" href={OPENAPI_URL} target={"_blank"}>
          OpenAPI
        </a>
        <a className="ngfocus color-link-hover" href={DOCS_URL} target={"_blank"}>
          Documentation
        </a>
      </div>
    </footer>
  );
};

export const Footer = observer(FooterRaw);
