import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderLambdalabsUnmanagedNodePool } from "./unmanagedNodePool";
import { Mk8sCreateProviderLambdalabsUnmanagedNodePoolAdd } from "./unmanagedNodePoolAdd";
import { Mk8sCreateProviderLambdalabsUnmanagedNodePools } from "./unmanagedNodePools";
import { Mk8sCreateProviderLambdalabs } from "./lambdalabs";
import { Mk8sCreateProviderLambdalabsAdvanced } from "./advanced";
import { Mk8sCreateProviderLambdalabsNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderLambdalabsNodePools } from "./nodePools";
import { Mk8sCreateProviderLambdalabsNodePool } from "./nodePool";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderLambdalabsRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderLambdalabs mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderLambdalabsNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderLambdalabsNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderLambdalabsNodePool mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-unmanaged-node-pools`}
        element={<Mk8sCreateProviderLambdalabsUnmanagedNodePools mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/-add`}
        element={<Mk8sCreateProviderLambdalabsUnmanagedNodePoolAdd mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/:index`}
        element={<Mk8sCreateProviderLambdalabsUnmanagedNodePool mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-advanced`} element={<Mk8sCreateProviderLambdalabsAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderLambdalabsRoute = observer(Mk8sCreateProviderLambdalabsRouteRaw);
