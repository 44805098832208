import * as React from "react";
import { Layout } from "../../layout";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { OrgCreate } from "../org/create/create";
import { AccountRoute } from "./account";
import { BillingAccountSelect } from "./account/select";
import { BillingAccountCreate } from "./account/create/create";
import { UserData } from "../../mobxStores/userData/userData";
import { BillingContext } from "../../mobxStores/billingContext/billingContext";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { Landing } from "./landing";
import { BillingAccountCreateWithId } from "./account/createWithId";
import { BillingAccountCreateFirstWithId } from "./account/createFirstWithId";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const HandleRootRedirectRaw: React.FC = () => {
  if (!UserData.hasAccount) {
    return <Landing />;
  }

  if (ConsoleContext.linkedAccount) {
    return <Navigate to={`account/-select?id=${ConsoleContext.linkedAccount}`} />;
  }

  return <Navigate to={`account/-select`} />;
};
const HandleRootRedirect = observer(HandleRootRedirectRaw);

const BillingRouteRaw: React.FC = () => {
  const { pathname } = useLocation();
  const { AccountError } = BillingContext;

  if (AccountError) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="px-4 py-2">Failed to show billing account. Please try again.</div>
        {IS_DEPLOYMENT_ENV_TEST ? <div className="mt-4 text-lg">{AccountError}</div> : null}
      </div>
    );
  }

  let showLeftMenu = true;
  if (!UserData.hasAccount) {
    showLeftMenu = false;
  }
  if (pathname.includes("/account/-select")) {
    showLeftMenu = false;
  }
  if (pathname.includes("/org/-createfirst")) {
    showLeftMenu = false;
  }
  if (pathname.includes("/account/-createfirst")) {
    showLeftMenu = false;
  }
  if (pathname.includes("/account/-create")) {
    showLeftMenu = false;
  }

  return (
    <Layout showLeftMenu={showLeftMenu}>
      <Routes>
        <Route index element={<HandleRootRedirect />} />
        <Route path={`org/-create/*`} element={<OrgCreate />} />
        <Route path={`org/-createfirst/*`} element={<OrgCreate />} />
        <Route path={`account/-select`} element={<BillingAccountSelect />} />
        <Route path={`account/id/:id/-createfirst`} element={<BillingAccountCreateFirstWithId />} />
        <Route path={`account/id/:id/-create`} element={<BillingAccountCreateWithId />} />
        <Route path={`account/-create/*`} element={<BillingAccountCreate />} />
        <Route path={`account/-createfirst/*`} element={<BillingAccountCreate />} />
        <Route path={`account/*`} element={<AccountRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(BillingRouteRaw);
