import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import { DeleteVolumeSnapshotReducerState, useRunDeleteVolumeSnapshotReducer } from "./reducer_deleteVolumeSnapshot";
import { VolumeSetMobx } from "../../../mst/kinds/volumeset";
import { NGButton } from "../../../newcomponents/button/Button";

interface Props {
  volumeSet: VolumeSetMobx;
  initialLocation?: string;
  initialVolumeIndex?: string;
  initialSnapshotName?: string;
  onClose: any;
  onDone: any;
  visible: boolean;
}

const CommandModal_DeleteVolumeSnapshotRaw: React.FC<Props> = ({
  volumeSet,
  initialLocation,
  initialVolumeIndex,
  initialSnapshotName,
  onClose,
  onDone,
  visible,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, dispatch] = useRunDeleteVolumeSnapshotReducer(initialLocation, initialVolumeIndex, initialSnapshotName);

  async function handleConfirm() {
    try {
      setIsLoading(true);

      const spec: DeleteVolumeSnapshotReducerState = JSON.parse(JSON.stringify(state));

      await request({
        method: "post",
        url: `${volumeSet.selfLink}/-command`,
        body: {
          type: "deleteVolumeSnapshot",
          spec: spec,
        },
      });
      onDone();
      notification.success({
        message: "Success",
        description: "Created Command.",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  let isValid = true;
  if (!state.location) isValid = false;
  if (!state.volumeIndex) isValid = false;

  return (
    <Modal
      open={visible}
      closable={false}
      title="Delete Volume Snapshot"
      footer={
        <div className="modal-actions">
          <NGButton
            disabled={isLoading}
            onClick={() => {
              onClose();
            }}
            variant={"danger"}
            outlined
          >
            Cancel
          </NGButton>
          <NGButton loading={isLoading} disabled={isLoading || !isValid} onClick={handleConfirm} variant={"danger"}>
            Delete
          </NGButton>
        </div>
      }
      onCancel={onClose}
      maskClosable={!isLoading}
      destroyOnClose
    >
      Are you sure you want to delete this volume snapshot?
    </Modal>
  );
};

export const CommandModal_DeleteVolumeSnapshot = observer(CommandModal_DeleteVolumeSnapshotRaw);
