import * as React from "react";

interface Tab {
  onClick: () => void;
  label: string;
  invalid: boolean;
  isActive: boolean;
  hide: boolean;
}

interface LinkProps {
  tab: Tab;
}
const TabLink: React.FC<LinkProps> = ({ tab }) => {
  return (
    <button
      data-testid={tab.label}
      onClick={tab.onClick}
      className={`custom-tab flex items-center truncate focus color-link-hover ${
        tab.isActive ? `color-link border-b-2` : ""
      }`}
      style={{
        marginRight: 22,
        height: 30,
        lineHeight: "28px",
        marginBottom: -2,
        borderColor: tab.isActive ? "var(--color-link)" : "",
      }}
    >
      {tab.label}
      {tab.invalid ? <div className="ml-1 bg-danger text-white rounded-full w-4 h-4 text-xs">!</div> : null}
    </button>
  );
};

interface Props extends React.PropsWithChildren {
  tabs: Tab[];
}

export const Tabs: React.FC<Props> = ({ children, tabs }) => {
  return (
    <div>
      <div className="flex w-full mb-6 border-b-2">
        {tabs
          .filter((t) => !t.hide)
          .map((tab) => (
            <TabLink key={tab.label} tab={tab} />
          ))}
      </div>
      {children}
    </div>
  );
};
