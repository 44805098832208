import { types, Instance } from "mobx-state-tree";
import { defaultValues } from "../base";
import { Mk8sDraftFirewallItemModel, Mk8sDraftFirewallItemReadOnlyModel } from "./mk8s.draft.firewall.item";

export const Mk8sDraftFirewallModel = types
  .model({
    _items: types.array(Mk8sDraftFirewallItemReadOnlyModel),
    items: types.array(Mk8sDraftFirewallItemModel),
    removed: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    reset() {
      self.items.clear();
      for (let index in self._items) {
        const _item = self._items[index];

        self.items.push(
          Mk8sDraftFirewallItemModel.create({
            index: Number(index),
            _item: JSON.parse(JSON.stringify(_item)),
          })
        );
      }
      self.removed = false;
    },
    confirm() {
      self._items.clear();
      for (let item of self.items) {
        item.confirm();
        self._items.push(
          Mk8sDraftFirewallItemReadOnlyModel.create({
            sourceCIDR: item.sourceCIDR.value,
            description: item.description.value,
          })
        );
      }
      self.removed = false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    addItem() {
      let index = 0;
      if (self.items.length > 0) {
        index = self.items[self.items.length - 1].index + 1;
      }

      self.items.push(
        Mk8sDraftFirewallItemModel.create({
          index,
          status: "added",
        })
      );
    },
    removeItemAt(index: number) {
      const node = self.items.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.items.remove(node);
    },
    removeItemWith(value: string) {
      const node = self.items.find((np) => np.sourceCIDR.value === value);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.items.remove(node);
    },
  }))
  .actions((self) => ({
    setDefaults() {
      self.items.clear();
      self.addItem();
      const defaultFirewallItem = defaultValues.mk8s.firewall[0];
      self.items[0].sourceCIDR.setValue(defaultFirewallItem.sourceCIDR);
      self.items[0].description.setValue(defaultFirewallItem.description);
    },
  }))
  .views((self) => ({
    get allowsAll() {
      return self.items.some((i) => i.sourceCIDR.value === "0.0.0.0/0");
    },
    allowsCidr(cidr: string) {
      return self.items.some((i) => i.sourceCIDR.value === cidr);
    },
    get isDefaults() {
      if (self.items.length !== 1) {
        return false;
      }
      const item = self.items[0];
      const defaultFirewallItem = defaultValues.mk8s.firewall[0];
      if (item.sourceCIDR.value !== defaultFirewallItem.sourceCIDR) {
        return false;
      }
      if (item.description.value !== defaultFirewallItem.description) {
        return false;
      }
      return true;
    },
    get isDirty() {
      let res = false;
      if (self._items.length !== self.items.length) res = true;
      if (self.removed) res = true;
      if (self.items.some((i) => i.isDirty)) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self._items.length !== self.items.length) reason = "node pools length is different";
      if (self.removed) reason = "removed a pool";
      if (self.items.some((i) => i.isDirty)) {
        reason = self.items.find((n) => n.isDirty)?.dirtyReason || "node pools but not found";
      }
      return reason;
    },
    get isValid() {
      let res = true;
      if (self.items.some((n) => !n.isValid)) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.items.some((n) => !n.isValid)) reason = self.items.find((n) => !n.isValid)?.invalidReason || "";
      return reason;
    },
    get asObject() {
      if (self._items.length > 0 && self.items.length < 1) {
        return [];
      }
      return self.items.map((i) => i.asObject);
    },
  }));
export interface Mk8sDraftFirewallMobx extends Instance<typeof Mk8sDraftFirewallModel> {}
