import * as React from "react";
import { GVCMobx, GVCModel } from "../../mst/kinds/gvc";
import { homeLink, request } from "../../services/cpln";
import { Table } from "../../newcomponents/table/table";
import { NameDescriptionNoLinkColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";

interface Props {
  locationLink: string;
}
export const Used: React.FC<Props> = ({ locationLink }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [gvcs, setGVCs] = React.useState<GVCMobx[]>([]);

  React.useEffect(() => {
    fetchGVCs();
  }, []);

  async function fetchGVCs() {
    try {
      setIsLoading(true);
      setGVCs([]);
      let _gvcs: GVCMobx[] = [];
      let link = homeLink("gvc");
      while (link) {
        const { data } = await request({ url: link });
        link = data.links.find((l: any) => l.rel === "next")?.href;
        _gvcs = _gvcs.concat(
          data.items.map((item: any) => {
            try {
              return GVCModel.create(item);
            } catch (e) {
              throw e;
            }
          })
        );
      }
      setGVCs(_gvcs);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  const usedGvcs = gvcs.filter((gvc) => gvc.spec.staticPlacement.locationLinks.includes(locationLink));

  return (
    <>
      <Table<GVCMobx>
        title={"Used in GVCs"}
        data={usedGvcs}
        isLoading={isLoading}
        tableId={"location-used-in-gvcs"}
        enableFilter
        columns={[NameDescriptionNoLinkColumn(), TagsColumn(), CreatedColumn(), LastModifiedColumn()]}
      />
    </>
  );
};
