import { types } from "mobx-state-tree";

export const TracingOpenTelemetryModel = types.model("OpenTelemetry", {
  endpoint: types.maybe(types.string),
});
interface TracingOpenTelemetry {
  endpoint: string;
}

export const TracingLightstepModel = types.model("Lightstep", {
  endpoint: types.string,
  credentials: types.maybe(types.string), // opaque secret
});
interface TracingLightstep {
  endpoint: string;
  credentials?: string;
}

export const TracingControlplaneModel = types.model("Controlplane", {});
interface TracingControlplane {}

export const TracingProviderModel = types.model("OrgTracingProvider", {
  otel: types.maybe(TracingOpenTelemetryModel),
  lightstep: types.maybe(TracingLightstepModel),
  controlplane: types.maybe(TracingControlplaneModel),
});

// export const CustomTagModel = types.model({
//   key: types.optional(types.string, ''),
//   literal: types.optional(types.model({
//     value: types.optional(types.string, ''),
//   }), {value: ''})
// })

export const TracingModel = types
  .model("OrgTracing", {
    sampling: types.optional(types.number, 0), // 0 - 100, 0.15
    customTags: types.optional(types.frozen(), {}),
    lightstep: types.maybe(TracingLightstepModel),
    provider: types.maybe(TracingProviderModel),
  })
  .views((self) => ({
    get readLightstep() {
      if (self.provider?.lightstep) {
        return self.provider.lightstep;
      }
      if (self.lightstep) {
        return self.lightstep;
      }
      return null;
    },
  }));
export interface Tracing {
  sampling: number;
  lightstep?: TracingLightstep;
  customTags: { [_: string]: { literal: { value: string } } };
  provider?: {
    otel?: TracingOpenTelemetry;
    lightstep?: TracingLightstep;
    controlplane?: TracingControlplane;
  };
}
