import { observer } from "mobx-react-lite";
import * as React from "react";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { request } from "../../../services/cpln";
import NGAlert from "../../../newcomponents/alert";
import { ExternalLink } from "react-feather";
import { getLogsStackdriverData } from "./data/stackdriver";

interface Props {
  formPrefix: string;
  stackdriver: ReturnType<typeof getLogsStackdriverData>;
  onRemove?: any;
}
const StackdriverRaw: React.FC<Props> = ({ formPrefix, stackdriver, onRemove }) => {
  const [locationOptions, setLocationOptions] = React.useState<string[]>([]);
  async function fetchLocationOptions() {
    const { data } = await request({ url: "/catalog/schema/org" });
    const options = data.keys.spec.keys.logging.keys.stackdriver.keys.location.allow;
    setLocationOptions(options.sort().map((o) => ({ label: o, value: o })));
  }
  React.useEffect(() => {
    fetchLocationOptions();
  }, []);

  formPrefix += "-stackdriver-";

  const locationOptionsToUse = locationOptions.length > 0 ? locationOptions : stackdriver.location.options;

  return (
    <>
      <div style={{ width: 450 }}>
        <NGFormElement
          name={`${formPrefix}location`}
          label={stackdriver.location.label}
          as={"select"}
          value={stackdriver.location.value}
          onChange={stackdriver.location.setValue}
          options={locationOptionsToUse}
          required={true}
        />
        <div className="mb-4">
          <NGFormElement
            name={`${formPrefix}credentials`}
            label={`Credentials (GCP Secret)`}
            as={"kindselect"}
            value={stackdriver.credentials.value}
            onChange={(value) => stackdriver.credentials.setValue(value)}
            kind={"secret"}
            kindSelectProps={{ queries: [{ property: "type", value: "gcp" }] }}
            required={stackdriver.credentials.isRequired}
            error={stackdriver.credentials.error}
          />
          <NGAlert
            type={"info"}
            message={`The serviceaccount need to have these two roles Logging > Logs Writer., Monitoring > Monitoring Metric Writer.`}
          />
          <div className="mt-2">
            See{" "}
            <a
              href={`https://cloud.google.com/logging/docs/agent/logging/authorization#create-service-account`}
              target={`_blank`}
              className="color-link inline-flex"
            >
              <span>this link</span>
              <ExternalLink className="h-4" style={{ transform: `translateY(3px)` }} />
            </a>{" "}
            to learn how to set up credentials.
          </div>
        </div>
        {onRemove ? (
          <div className="flex justify-end">
            <NGButton style={{ width: 215 }} onClick={() => onRemove()} variant={"danger"}>
              Remove Provider
            </NGButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const Stackdriver = observer(StackdriverRaw);
