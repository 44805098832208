import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import { NGButton } from "../../../newcomponents/button/Button";
import { useDetailContext } from "../../../components/detail/detailContext";
import { WorkloadMobx } from "../../../mst/kinds/workload";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  location: string;
  replica: string;
  onClose: any;
  onDone: any;
  visible: boolean;
}

const CommandModal_ConfirmReplicaTerminationRaw: React.FC<Props> = ({
  location,
  replica,
  onClose,
  onDone,
  visible,
}) => {
  const { item: workload } = useDetailContext() as { item: WorkloadMobx };

  const [isLoading, setIsLoading] = React.useState(false);

  async function handleConfirm() {
    setIsLoading(true);
    try {
      await request({
        method: "post",
        url: workload.selfLink + "/-command",
        body: {
          type: "stopReplica",
          spec: { replica, location },
        },
      });
      onDone();
      notification.success({
        message: "Success",
        description: `${workload.isStateful ? "Restarted" : "Stopped"} ${replica}`,
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: `Failed to ${workload.isStateful ? "restart" : "stop"} ${replica}`,
        description: errorMessage,
      });
    }
    setIsLoading(false);
  }

  return (
    <Modal
      width={450}
      open={visible}
      closable={false}
      title={`Are you sure you want to ${workload.isStateful ? "restart" : "stop"} replica?`}
      footer={
        <div className="modal-actions">
          <NGButton disabled={isLoading} onClick={() => onClose()} variant={"danger"} outlined>
            Cancel
          </NGButton>
          <NGButton disabled={isLoading} loading={isLoading} onClick={handleConfirm} variant={"primary"}>
            Confirm
          </NGButton>
        </div>
      }
      onCancel={onClose}
      maskClosable={!isLoading}
      destroyOnClose
    ></Modal>
  );
};

export const CommandModal_ConfirmReplicaTermination = observer(CommandModal_ConfirmReplicaTerminationRaw);
