import { observer } from "mobx-react-lite";
import { Modal } from "antd";
import { CodeSnippet } from "../generic/codeSnippet/codeSnippet";

interface Props {
  command: string;
  onClose?: () => void;
}

const TerraformImportModalRaw: React.FC<Props> = ({ command, onClose }) => {
  return (
    <Modal
      title={<div className="mr-4 text-lg">Terraform Import Commands for GVC Items</div>}
      width={900}
      open={true}
      onCancel={onClose}
      destroyOnClose
      footer=""
    >
      {/* Description */}
      <div>
        <span>
          To prevent Terraform from attempting to create the resources you exported, run the following import command in
          your Terraform project. Learn more about the{" "}
        </span>
        <a
          className="ngfocus color-link font-semibold"
          target="_blank"
          href={`https://developer.hashicorp.com/terraform/cli/commands/import`}
        >
          Terraform Import Command
        </a>
        <span> and how it works with your state.</span>
      </div>

      {/* Import Commands */}
      <CodeSnippet className="mt-4" code={command} />
    </Modal>
  );
};

export const TerraformImportModal = observer(TerraformImportModalRaw);
