import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { DomainDraftPortMobx } from "../../mst/stores/domain.draft.port";
import { NGButton } from "../../newcomponents/button/Button";
import { NGInputListMst } from "../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../newcomponents/input/input";

interface Props {
  port?: DomainDraftPortMobx;
  onClose: () => void;
}
const DomainPortRouteEditHeadersModalRaw: React.FC<Props> = ({ port, onClose }) => {
  if (!port) {
    return null;
  }

  return (
    <Modal
      open={!!port}
      styles={{ body: { height: "70vh", padding: 0 } }}
      width={1000}
      title={`Edit Route Headers for Port ${port.number.value}`}
      onCancel={() => {}}
      maskClosable={false}
      closable={false}
      footer={
        <div className="modal-actions">
          <NGButton
            variant="primary"
            onClick={() => {
              onClose();
            }}
          >
            OK
          </NGButton>
        </div>
      }
    >
      <div className="h-full overflow-auto">
        {port.routes.map((route) => {
          return (
            <div key={route.id}>
              <NGInputListMst
                label={`Workload: ${route.workloadName} ${
                  route.method.value === "prefix" ? `Prefix: ${route.prefix.value}` : `Regex: ${route.regex.value}`
                }`}
                styles={{ header: { width: "unset" } }}
                data={route.headersRequestSet}
                firstLabel="Header Name"
                firstInput={(i) => <NGInput value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />}
                secondLabel="Header Value"
                secondInput={(i) => (
                  <NGInput value={i.secondValue} onChange={(e) => i.setSecondValue(e.target.value)} />
                )}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export const DomainPortRouteEditHeadersModal = observer(DomainPortRouteEditHeadersModalRaw);
