import * as React from "react";
import {
  flexRender,
  Row,
  RowData,
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  VisibilityState,
  RowSelectionState,
  ColumnFiltersState,
  SortingState,
  SortingFn,
  ColumnFilter,
  Cell,
} from "@tanstack/react-table";
import "./table.css";
import {
  ArrowDown,
  ArrowUp,
  ChevronDown,
  ChevronUp,
  ChevronsDown,
  ChevronsUp,
  Columns,
  Filter,
  Layers,
} from "react-feather";
import { NGInput } from "../input/input";
import { Dropdown, Modal } from "antd";
import { ReactSortable } from "react-sortablejs";
import { DragHandle } from "../inputList/inputListMobx";
import clsx from "clsx";
import { NGCheckbox } from "../checkbox";
import { arraysAreEqual } from "../../services/cpln";
import { NGButton } from "../button/Button";
import md5 from "md5";
import { ColumnSetting, ColumnSettings, RowGroup, TableCustomSortingFn, TableProps, TableSelectMode } from "./types";
import {
  TABLE_DEFAULT_COLUMN_FILTER,
  TABLE_DEFAULT_COLUMN_FILTER_TYPE,
  TABLE_DEFAULT_COLUMN_SIZE,
  TABLE_DEFAULT_FILTER,
  TABLE_DEFAULT_HIDE,
  TABLE_DEFAULT_RESIZE,
  TABLE_DEFAULT_SORT,
  cplnFilterFnFuzzy,
  cplnSortingFnDate,
  getTableStorageKeyCollapsedGroups,
  getTableStorageKeyColumnSettings,
  getTableStorageKeyColumnSizing,
  getTableStorageKeyGlobalFilter,
  getTableStorageKeyGroupBy,
  getTableStorageKeySorting,
  valueIsAColumn,
} from "./utils";
import { ColumnResizeHandle } from "./components/ColumnResizeHandle";
import { Loader } from "../../components/layout/loader";
import { Tooltip } from "../../components/Tooltip";

export function Table<TData extends RowData>({
  tableId,
  title,
  selectMode = "none",
  selectKey,
  selections,
  initialSelections,
  onSelectionsChange,
  enableFilter = TABLE_DEFAULT_FILTER,
  globalFilterFn = cplnFilterFnFuzzy,
  isLoading,
  hideSettings = false,
  data,
  columns: rawColumns,
  headerRenderer,
  noItemsFoundRenderer = () => <span className="text-lg">No items found</span>,
  styles = { header: {}, container: {} },
  classNames = { header: "", container: "" },
  maxHeight,
}: TableProps<TData>) {
  /*
  topSection
  container
    table
  */

  // Keep order of hooks up to date because we need it for debugging performance issues
  const topSectionRef = React.useRef<HTMLDivElement>(null); // Hook 1
  const containerRef = React.useRef<HTMLDivElement>(null); // Hook 2
  const tableRef = React.useRef<HTMLTableElement>(null); // Hook 3

  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false); // Hook 4

  function getSortingFn(value?: SortingFn<TData> | TableCustomSortingFn) {
    if (!value) {
      return "text";
    }
    if (value === "cplnDate") {
      return (cplnSortingFnDate as unknown) as SortingFn<TData>;
    }
    return value as SortingFn<TData>;
  }

  function getColumns() {
    const columns: ColumnDef<TData>[] = [];
    for (const column of rawColumns) {
      columns.push({
        id: column.id,
        accessorKey: column.accessorFn ? undefined : column.id,
        accessorFn: column.accessorFn,
        cell: column.cell || ((p) => p.getValue()),
        size: column.size || TABLE_DEFAULT_COLUMN_SIZE,
        minSize: column.size,
        maxSize: column.maxSize,
        enableResizing: column.enableResize || TABLE_DEFAULT_RESIZE,
        enableHiding: column.enableHide || TABLE_DEFAULT_HIDE,
        enableColumnFilter: column.enableFilter || TABLE_DEFAULT_COLUMN_FILTER,
        meta: {
          filterVariant: column.columnFilterType || TABLE_DEFAULT_COLUMN_FILTER_TYPE,
        },
        enableSorting: column.enableSort || TABLE_DEFAULT_SORT,
        sortingFn: getSortingFn(column.sortingFn),
        sortDescFirst: false, // descending, not description

        header: column.label,
      });
    }
    return columns;
  }
  const columns = React.useMemo(getColumns, [rawColumns]); // Hook 5

  function getDefaultGroupBy(): string {
    const localValueString = localStorage.getItem(getTableStorageKeyGroupBy(tableId)) || "";
    if (valueIsAColumn(localValueString, rawColumns)) {
      return localValueString;
    }
    return "";
  }
  const [groupBy, setGroupBy] = React.useState(getDefaultGroupBy()); // Hook 6
  React.useEffect(() => {
    localStorage.setItem(getTableStorageKeyGroupBy(tableId), groupBy);
  }, [groupBy]); // Hook 7

  function getDefaultCollapsedGroups(): string[] {
    const localValueString = localStorage.getItem(getTableStorageKeyCollapsedGroups(tableId)) || "";
    let parsedLocalValue: any = undefined;
    try {
      parsedLocalValue = JSON.parse(localValueString);
      if (parsedLocalValue === null) {
        throw new Error("Saved collapsed groups value cannot be null");
      }
      if (typeof parsedLocalValue !== "object") {
        throw new Error("Saved collapsed groups value must be of type object");
      }
      if (!Array.isArray(parsedLocalValue)) {
        throw new Error("Saved collapsed groups value must be an array");
      }
      return parsedLocalValue;
    } catch (e) {}
    return [];
  }
  const [collapsedGroups, setCollapsedGroups] = React.useState(getDefaultCollapsedGroups()); // Hook 8
  React.useEffect(() => {
    localStorage.setItem(getTableStorageKeyCollapsedGroups(tableId), JSON.stringify(collapsedGroups));
  }, [collapsedGroups]); // Hook 9

  function getInitialColumnSetting(): ColumnSettings {
    const initialColumnSettings: any = [];
    for (const column of rawColumns) {
      let visible = true;
      if (column.hiddenByDefault === true) {
        visible = false;
      }
      initialColumnSettings.push({ id: column.id, visible: visible });
    }
    return initialColumnSettings;
  }

  function getDefaultColumnSettings(): ColumnSettings {
    const defaultColumnSettings = getInitialColumnSetting();
    const localValueString = localStorage.getItem(getTableStorageKeyColumnSettings(tableId)) || "";

    let parsedColumnSettings: ColumnSettings = [];
    try {
      parsedColumnSettings = JSON.parse(localValueString);
      if (parsedColumnSettings === null) {
        throw new Error("Saved column settings value cannot be null");
      }
      if (typeof parsedColumnSettings !== "object") {
        throw new Error("Saved column settings value must be of type object");
      }
      if (parsedColumnSettings.length < 1) {
        throw new Error("Saved column settings does not have any value");
      }

      // handles newly added columns
      for (const defaultColumnSetting of defaultColumnSettings) {
        if (!parsedColumnSettings.find((p) => p.id === defaultColumnSetting.id)) {
          parsedColumnSettings.push(defaultColumnSetting);
        }
      }

      // handles removed columns
      for (const parsedColumnSetting of parsedColumnSettings) {
        if (!defaultColumnSettings.find((d) => d.id === parsedColumnSetting.id)) {
          parsedColumnSettings = parsedColumnSettings.filter((p) => p.id !== parsedColumnSetting.id);
        }
      }

      return parsedColumnSettings;
    } catch (e) {
      console.log("getDefaultColumnSettings error", e.message);
    }
    return defaultColumnSettings;
  }

  const [columnSettings, setColumnSettings] = React.useState<ColumnSetting[]>(getDefaultColumnSettings()); // Hook 10
  const [temporaryColumnSettings, setTemporaryColumnSettings] = React.useState<ColumnSetting[]>( // Hook 11
    getDefaultColumnSettings(),
  );
  React.useEffect(() => {
    const encoded = JSON.stringify(columnSettings);
    localStorage.setItem(getTableStorageKeyColumnSettings(tableId), encoded);
    setTemporaryColumnSettings(columnSettings);
  }, [columnSettings]); // Hook 12

  function getColumnOrder() {
    let columnOrder: string[] = [];
    for (const columnSetting of columnSettings) {
      columnOrder.push(columnSetting.id);
    }
    return columnOrder;
  }
  const [columnOrder, setColumnOrder] = React.useState(getColumnOrder()); // Hook 13
  function getColumnVisibility() {
    let _columnVisibilityState: any = {};
    for (const columnSetting of columnSettings) {
      _columnVisibilityState[columnSetting.id] = columnSetting.visible;
    }
    return _columnVisibilityState;
  }
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(getColumnVisibility()); // Hook 14

  React.useEffect(() => {
    setColumnOrder(getColumnOrder());
    setColumnVisibility(getColumnVisibility());
  }, [columnSettings]); // Hook 15

  function toggleVisibility(id: string, confirm?: boolean) {
    const _columnSettings = JSON.parse(JSON.stringify(temporaryColumnSettings));
    const _columnSetting = _columnSettings.find((__columnSetting) => __columnSetting.id === id)!;
    _columnSetting.visible = !_columnSetting.visible;
    setTemporaryColumnSettings(_columnSettings);
    if (confirm) {
      setColumnSettings(_columnSettings);
    }
  }

  function getDefaultSortingState(): SortingState {
    const defaultSortingState: SortingState = [];
    const localValueString = localStorage.getItem(`table-config-${tableId}-sorting`) || "";
    let parsedLocalValue: SortingState = undefined as any;
    try {
      parsedLocalValue = JSON.parse(localValueString);
      if (parsedLocalValue === null) {
        throw new Error("Saved sorting state value cannot be null");
      }
      if (typeof parsedLocalValue !== "object") {
        throw new Error("Saved sorting state value must be of type object");
      }
      if (!Array.isArray(parsedLocalValue)) {
        throw new Error("Saved sorting state value must be an array");
      }
      for (const item of parsedLocalValue) {
        if (!valueIsAColumn(item.id, rawColumns)) {
          throw new Error(`Column id ${item.id} is not within columns: ${rawColumns.map((c) => c.id)}`);
        }
      }
      return parsedLocalValue;
    } catch (e) {
      console.log(`Table ${tableId} failed to getDefaultSortingState | ${e.message}`);
      return defaultSortingState;
    }
  }
  const [sortingState, setSortingState] = React.useState<SortingState>(getDefaultSortingState()); // Hook 16
  React.useEffect(() => {
    const encoded = JSON.stringify(sortingState);
    localStorage.setItem(getTableStorageKeySorting(tableId), encoded);
  }, [sortingState]); // Hook 17

  function toRowSelectionState(selectionArray: string[]) {
    let _selectionState: RowSelectionState = {};
    for (const selection of selectionArray) {
      _selectionState[selection] = true;
    }
    return _selectionState;
  }
  function getDefaultRowSelection(): RowSelectionState {
    const selectionsToUse = selections || initialSelections;
    if (!selectionsToUse) {
      return {};
    }
    return toRowSelectionState(selectionsToUse);
  }

  const [rowSelection, setRowSelection] = React.useState(getDefaultRowSelection()); // Hook 18
  function getRowSelectionArray(): { keys: string[]; items: TData[] } {
    const _keys: string[] = [];
    const _items: TData[] = [];
    for (const [key, selected] of Object.entries(rowSelection)) {
      if (!selected) {
        continue;
      }
      _keys.push(key);
      if (selectKey !== undefined) {
        const item = data.find((d) => d[selectKey] === key)!;
        _items.push(item);
      } else {
        const item = data[key];
        _items.push(item);
      }
    }
    return { keys: _keys, items: _items };
  }
  React.useEffect(() => {
    if (onSelectionsChange) {
      const { keys, items } = getRowSelectionArray();
      onSelectionsChange(keys, items);
    }
  }, [rowSelection]); // Hook 19
  React.useEffect(() => {
    if (!selections) {
      return;
    }
    const { keys } = getRowSelectionArray();
    if (arraysAreEqual(selections, keys)) {
      return;
    }
    setRowSelection(toRowSelectionState(selections));
  }, [selections]); // Hook 20

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]); // Hook 21

  function getDefaultGlobalFilter(): string {
    const localValueString = localStorage.getItem(getTableStorageKeyGlobalFilter(tableId)) || "";
    return localValueString;
  }
  const [globalFilter, setGlobalFilter] = React.useState(getDefaultGlobalFilter()); // Hook 22
  React.useEffect(() => {
    localStorage.setItem(getTableStorageKeyGlobalFilter(tableId), globalFilter);
  }, [globalFilter]); // Hook 23

  function getDefaultColumnSizing(): { [_: string]: number } {
    try {
      const localValueString = localStorage.getItem(getTableStorageKeyColumnSizing(tableId)) || JSON.stringify({});
      const parsedLocalValue = JSON.parse(localValueString);
      for (const columnId of Object.keys(parsedLocalValue)) {
        if (!valueIsAColumn(columnId, rawColumns)) {
          throw new Error(
            `getDefaultColumnSizing has incorrect column id saved in storage | ${columnId} | Columns: ${rawColumns.map(
              (c) => c.id,
            )}`,
          );
        }
      }
      return parsedLocalValue;
    } catch (e) {
      return {};
    }
  }
  const [columnSizing, setColumnSizing] = React.useState<{ [_: string]: number }>(getDefaultColumnSizing()); // Hook 24
  React.useEffect(() => {
    localStorage.setItem(getTableStorageKeyColumnSizing(tableId), JSON.stringify(columnSizing));
  }, [columnSizing]); // Hook 25

  const table = useReactTable<TData>({
    // Hook 26
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),

    getFilteredRowModel: getFilteredRowModel(),
    manualFiltering: false, // TODO enable when using fuzzy search through table global filter
    enableGlobalFilter: enableFilter,
    globalFilterFn: globalFilterFn,
    onGlobalFilterChange: setGlobalFilter,

    // If sorted column is not a colum which is sortable by query, we need to give all the items at once
    getSortedRowModel: getSortedRowModel(),
    // When sorting with query, make it true
    manualSorting: false,
    onSortingChange: setSortingState,

    enableRowSelection: selectMode !== "none",
    enableMultiRowSelection: selectMode === "multi",
    getRowId: selectKey ? (row) => (row as any)[selectKey] : undefined, // default is index
    onRowSelectionChange: setRowSelection,

    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),

    onColumnSizingChange: setColumnSizing,
    columnResizeMode: "onChange",

    state: {
      columnOrder,
      columnVisibility,
      columnSizing,
      rowSelection,
      globalFilter,
      sorting: sortingState,
    },

    meta: {
      columnFilters: columnFilters,
      setColumnFilters: setColumnFilters,
    },
  });

  function getDistinctGroupValues(): string[] {
    const distinctGroupValues: string[] = [];
    if (groupBy) {
      const columnConfig = rawColumns.find((_c) => _c.id === groupBy);

      const unsortedDistinctGroupValues: string[] = [];
      for (const row of table.getCoreRowModel().rows) {
        const rowValueForGroup = row.getValue(groupBy) as string;
        if (!unsortedDistinctGroupValues.includes(rowValueForGroup)) {
          unsortedDistinctGroupValues.push(rowValueForGroup);
        }
      }
      unsortedDistinctGroupValues.sort();
      for (const groupOrder of columnConfig?.groupOrder || []) {
        distinctGroupValues.push(groupOrder);
      }
      for (const unsortedDistinctGroupValue of unsortedDistinctGroupValues) {
        if (distinctGroupValues.includes(unsortedDistinctGroupValue)) {
          continue;
        }
        distinctGroupValues.push(unsortedDistinctGroupValue);
      }
    }
    return distinctGroupValues;
  }

  function getRowGroups(distinctGroupValues: string[]): RowGroup<TData>[] {
    const rowGroups: RowGroup<TData>[] = [];

    if (groupBy) {
      const columnConfig = rawColumns.find((_c) => _c.id === groupBy);

      for (const distinctValue of distinctGroupValues) {
        let label = distinctValue;
        if (columnConfig?.groupLabelMap) {
          const _label = columnConfig?.groupLabelMap[distinctValue];
          if (_label) {
            label = _label;
          }
        }

        let invisibleGroup = false;
        if (columnConfig?.enableFilter) {
          const columnFilter = columnFilters.find((cf) => cf.id === columnConfig.id);
          if (columnFilter && (columnFilter.value as string).length > 0) {
            switch (columnConfig.columnFilterType) {
              case "single":
                const value = columnFilter.value as string;
                if (value !== distinctValue) {
                  invisibleGroup = true;
                }
                break;
              case "multi":
                const values = (columnFilter.value as string).split(",").filter(Boolean);
                if (!values.includes(distinctValue)) {
                  invisibleGroup = true;
                }
                break;
            }
          }
        }

        rowGroups.push({
          label: label,
          value: distinctValue,
          invisibleGroup: invisibleGroup,
          invisibleRows: [], // filtered out values for this group
          visibleRows: [],
          visibleRowIds: [],
        });
      }

      for (const row of table.getRowModel().rows) {
        // column for the groupBy matches the current distinctGroup value
        const rowValue = row.getValue(groupBy) as string;
        const rowGroup = rowGroups.find((distinctGroup) => distinctGroup.value === rowValue)!;

        // check all columns with filter, and prevent adding to visible if doesn't match any of them
        let invisibleRow = false;
        for (const columnConfig of rawColumns) {
          if (!columnConfig.enableFilter) {
            continue;
          }
          const columnFilter = columnFilters.find((cf) => cf.id === columnConfig.id);
          if (columnFilter && (columnFilter.value as string).length > 0) {
            const rowValue = row.getValue(columnConfig.id) as string;
            switch (columnConfig.columnFilterType) {
              case "single":
                const value = columnFilter.value as string;
                if (value !== rowValue) {
                  invisibleRow = true;
                }
                break;
              case "multi":
                const values = (columnFilter.value as string).split(",").filter(Boolean);
                if (!values.includes(rowValue)) {
                  invisibleRow = true;
                }
                break;
            }
          }
          if (invisibleRow) {
            break;
          }
        }
        if (invisibleRow) {
          continue;
        }

        rowGroup.visibleRows.push(row);
        rowGroup.visibleRowIds.push(row.id);
      }

      for (const row of table.getCoreRowModel().rows) {
        const rowValue = row.getValue(groupBy) as string;
        const rowGroup = rowGroups.find((distinctGroup) => distinctGroup.value === rowValue)!;
        if (rowGroup.visibleRowIds.includes(row.id)) {
          continue;
        }
        rowGroup.invisibleRows.push(row);
      }

      for (const rowGroup of rowGroups) {
        if (!rowGroup.invisibleGroup && rowGroup.visibleRows.length < 1) {
          rowGroup.invisibleGroup = true;
        }
      }
    } else {
      const rowGroup = {
        label: "all",
        value: "all",
        invisibleGroup: false,
        invisibleRows: [],
        visibleRows: [],
        visibleRowIds: [],
      } as {
        label: string;
        value: string;
        invisibleGroup: boolean;
        invisibleRows: Row<TData>[];
        visibleRows: Row<TData>[];
        visibleRowIds: string[];
      };
      rowGroups.push(rowGroup);

      for (const row of table.getRowModel().rows) {
        let invisibleRow = false;
        for (const columnConfig of rawColumns) {
          if (!columnConfig.enableFilter) {
            continue;
          }
          const columnFilter = columnFilters.find((cf) => cf.id === columnConfig.id);
          if (columnFilter && (columnFilter.value as string).length > 0) {
            const rowValue = row.getValue(columnConfig.id) as string;
            switch (columnConfig.columnFilterType) {
              case "single":
                const value = columnFilter.value as string;
                if (value !== rowValue) {
                  invisibleRow = true;
                }
                break;
              case "multi":
                const values = (columnFilter.value as string).split(",").filter(Boolean);
                if (!values.includes(rowValue)) {
                  invisibleRow = true;
                }
                break;
            }
          }
          if (invisibleRow) {
            break;
          }
        }
        if (invisibleRow) {
          continue;
        }

        rowGroup.visibleRows.push(row);
        rowGroup.visibleRowIds.push(row.id);
      }

      for (const row of table.getCoreRowModel().rows) {
        if (rowGroup.visibleRowIds.includes(row.id)) {
          continue;
        }
        rowGroup.invisibleRows.push(row);
      }
    }
    return rowGroups;
  }

  const distinctGroupValues: string[] = React.useMemo(() => getDistinctGroupValues(), [groupBy, rawColumns, data]); // Hook 27
  const rowGroups: RowGroup<TData>[] = React.useMemo(() => getRowGroups(distinctGroupValues), [
    // Hook 28
    distinctGroupValues,
    groupBy,
    selectMode,
    selectKey,
    globalFilter,
    columnFilters,
    sortingState,
    columnOrder,
    columnVisibility,
    data,
    rawColumns,
  ]);

  const [topSectionWidth, setTopSectionWidth] = React.useState(topSectionRef.current?.clientWidth || 0); // Hook 29
  React.useEffect(() => {
    const callback = () => {
      setTopSectionWidth(topSectionRef.current?.clientWidth || 0);
    };
    callback();
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []); // Hook 30

  // to fix jumping on inital open while it calculates the width
  const [initialFramesPassed, setInitialFramesPassed] = React.useState<boolean | null>(null); // Hook 31
  React.useEffect(() => {
    if (initialFramesPassed === null) {
      setInitialFramesPassed(false);
    } else if (!initialFramesPassed) {
      setInitialFramesPassed(true);
    }
  }, [initialFramesPassed]); // Hook 32

  function getTableWidth(): number {
    const tableTotalSize = table.getTotalSize();
    let tableWidth = Math.max(tableTotalSize, topSectionWidth);

    if (isLoading || table.getCoreRowModel().rows.length < 1 || (table.getRowModel().rows.length < 1 && !groupBy)) {
      tableWidth = topSectionWidth;
    }
    if (maxHeight) {
      tableWidth -= 20;
    }
    return tableWidth;
  }
  const tableWidth = getTableWidth();

  return (
    <>
      <div
        ref={topSectionRef}
        className={`flex items-center justify-between mb-4 gap-2 ${classNames.header}`}
        style={styles.header}
      >
        {title ? <>{typeof title === "string" ? <div className="text-2xl">{title}</div> : title("text-2xl")}</> : null}
        <div className="flex-grow" />
        {enableFilter ? (
          <NGInput
            placeholder="Search"
            style={{ width: 240 }}
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            showClear
          />
        ) : null}
        {hideSettings ? null : (
          <Tooltip title={"Column Settings"}>
            <NGButton
              variant="secondary"
              // text
              onClick={() => setIsSettingsOpen(true)}
              renderIcon={(_, props) => <Columns {...props} />}
            />
          </Tooltip>
        )}
        {headerRenderer ? headerRenderer(table) : null}
      </div>
      <div
        ref={containerRef}
        className={clsx(`table-container`, classNames.container)}
        style={{ ...styles.container, maxHeight: styles.container?.maxHeight || maxHeight }}
      >
        <table ref={tableRef} style={{ width: tableWidth }}>
          <thead>
            {/* Headers */}
            <tr className="relative">
              {/* Show headers if it is groups or has any item */}
              {initialFramesPassed &&
                table.getLeafHeaders().map((header, headerIndex) => {
                  const rawColumn = rawColumns.find((rawColumn) => rawColumn.id === header.column.id)!;
                  if (!rawColumn) {
                    console.error(
                      `Need to fix that column config is not found for the column with id: ${header.column.id}`,
                    );
                    console.info(rawColumns);
                    console.info("header");
                    console.info(header);
                    console.error(`-----`);
                  }

                  const totalHeaderCount = table.getLeafHeaders().length;
                  const isLastHeader = totalHeaderCount - 1 === headerIndex;

                  let sortTooltipTitle = "";
                  switch (header.column.getIsSorted()) {
                    case "asc":
                      sortTooltipTitle = "Sorted by Ascending Order";
                      break;
                    case "desc":
                      sortTooltipTitle = "Sorted by Descending Order";
                      break;
                    case false:
                      sortTooltipTitle = "Not Sorted";
                      break;
                  }

                  return (
                    <th
                      className="relative"
                      key={header.id}
                      style={{
                        width: header.getSize(),
                      }}
                      colSpan={header.colSpan}
                    >
                      <div className="flex items-center">
                        {headerIndex === 0 && selectMode !== "none" ? (
                          <div style={{ width: 30, minWidth: 30 }}>
                            {selectMode === "single" ? (
                              <span className="invisible">X</span>
                            ) : (
                              <NGCheckbox
                                aria-label={`table-${tableId}-row-selection-all`}
                                checked={table.getIsAllRowsSelected()}
                                indeterminate={table.getIsSomeRowsSelected()}
                                onChange={() => table.toggleAllRowsSelected()}
                              />
                            )}
                          </div>
                        ) : null}
                        {header.column.getCanSort() ? (
                          <button
                            className={`flex items-center cursor-pointer ngfocus`}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                            <Tooltip title={sortTooltipTitle}>
                              {header.column.getIsSorted() === "desc" ? (
                                <ArrowDown className="h-4 color-link" />
                              ) : header.column.getIsSorted() === "asc" ? (
                                <ArrowUp className="h-4 color-link" />
                              ) : (
                                <ArrowUp className={`h-4 color-link-hover`} />
                              )}
                            </Tooltip>
                          </button>
                        ) : (
                          <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                        )}
                        <div className="flex-grow" />
                        {rawColumn.enableFilter ? (
                          <Dropdown
                            trigger={["click"]}
                            dropdownRender={() => {
                              const uniqueValues = Array.from(header.column.getFacetedUniqueValues().keys());

                              return (
                                <div
                                  className="p-2 shadow-sm border"
                                  style={{ backgroundColor: "var(--color-drop)" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {uniqueValues.map((value) => {
                                    // @ts-expect-error
                                    const columnFilters = table.options.meta!.columnFilters;
                                    // @ts-expect-error
                                    const setColumnFilters = table.options.meta!.setColumnFilters;
                                    const columnFilter = columnFilters.find((x) => x.id === header.column.id);
                                    const currentSelectedValues = ((columnFilter?.value as string) || "").split(",");
                                    const restOfTheColumnFilters = columnFilters.filter(
                                      (x) => x.id !== header.column.id,
                                    );
                                    let newColumnFilters: ColumnFilter[] = [];
                                    return (
                                      <div
                                        className="p-1"
                                        key={`${"label"}---${value}`}
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          switch (rawColumn.columnFilterType) {
                                            case "single":
                                              let currentValue = (columnFilter?.value as string) || "";
                                              newColumnFilters = [
                                                ...restOfTheColumnFilters,
                                                { id: header.column.id, value: currentValue === value ? "" : value },
                                              ];
                                              setColumnFilters(newColumnFilters);
                                              break;
                                            case "multi":
                                              let currentValues = ((columnFilter?.value as string) || "").split(",");
                                              if (currentValues.includes(value)) {
                                                currentValues = currentValues.filter((c) => c !== value);
                                              } else {
                                                currentValues.push(value);
                                              }
                                              newColumnFilters = [
                                                ...restOfTheColumnFilters,
                                                { id: header.column.id, value: currentValues.join(",") },
                                              ];
                                              setColumnFilters(newColumnFilters);
                                              break;
                                          }
                                        }}
                                      >
                                        <NGCheckbox
                                          aria-label={value}
                                          checked={currentSelectedValues.includes(value)}
                                          // TODO fix
                                          onChange={(e) => {}}
                                        >
                                          <span>{value}</span>
                                        </NGCheckbox>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }}
                          >
                            <button className={`cursor-pointer ngfocus color-link-hover`} onClick={() => {}}>
                              <Filter
                                className={`h-4 ${
                                  ((columnFilters.find((x) => x.id === header.column.id)?.value as string) || "")
                                    .length > 0
                                    ? "fill-current color-link"
                                    : ""
                                }`}
                              />
                            </button>
                          </Dropdown>
                        ) : null}
                        {rawColumn.canGroupByDistinctValues && groupBy === rawColumn.id ? (
                          distinctGroupValues.length === collapsedGroups.length ? (
                            <Tooltip title={`Expand All Groups`}>
                              <button
                                className={`cursor-pointer ngfocus color-link-hover`}
                                onClick={() => setCollapsedGroups([])}
                              >
                                <ChevronsDown className={`h-4`} />
                              </button>
                            </Tooltip>
                          ) : (
                            <Tooltip title={`Collapse All Groups`}>
                              <button
                                className={`cursor-pointer ngfocus color-link-hover`}
                                onClick={() => setCollapsedGroups([...distinctGroupValues])}
                              >
                                <ChevronsUp className={`h-4`} />
                              </button>
                            </Tooltip>
                          )
                        ) : null}
                        {rawColumn.canGroupByDistinctValues ? (
                          <Tooltip title={`Group by ${rawColumn.label}`}>
                            <button
                              className={`cursor-pointer ngfocus color-link-hover`}
                              onClick={() => setGroupBy((v) => (v === header.column.id ? "" : header.column.id))}
                            >
                              <Layers className={`h-4 ${groupBy === header.column.id ? "color-link" : ""}`} />
                            </button>
                          </Tooltip>
                        ) : null}
                        <div
                          className={`header-separator ${isLastHeader ? "header-separator-last" : ""} ${
                            header.column.getCanResize() ? "resize-handler" : ""
                          }`}
                          onDoubleClick={header.column.getCanResize() ? () => header.column.resetSize() : undefined}
                          onMouseDown={header.column.getCanResize() ? header.getResizeHandler() : undefined}
                          onTouchStart={header.column.getCanResize() ? header.getResizeHandler() : undefined}
                        >
                          {header.column.getCanResize() ? <ColumnResizeHandle /> : null}
                        </div>
                      </div>
                      {/* 
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} 
                         */}
                      {/* TODO test */}
                      {/* 
                        <ColumnResizeIndicator
                          style={{
                            transform: header.column.getIsResizing()
                              ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                              : '',
                          }}
                        /> 
                        */}
                    </th>
                  );
                })}
            </tr>
          </thead>
          {/* CoreRowModel is without any filtering */}
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={table.getLeafHeaders().length}>
                  <span className="infobox flex items-center justify-center">
                    <Loader
                      style={{
                        width: 100,
                        height: 100,
                        margin: 0,
                        left: "unset",
                        right: "unset",
                        top: "unset",
                        bottom: "unset",
                      }}
                      reason={"loading items"}
                    />
                  </span>
                </td>
              </tr>
            ) : table.getCoreRowModel().rows.length < 1 ? (
              <tr>
                <td colSpan={table.getLeafHeaders().length}>
                  <span className="infobox flex items-center justify-center">{noItemsFoundRenderer(table)}</span>
                </td>
              </tr>
            ) : // RowModel is with all processing applied
            table.getRowModel().rows.length < 1 ? (
              <tr>
                <td colSpan={table.getLeafHeaders().length}>
                  <span className="infobox flex flex-col items-center justify-center">
                    <span className="text-lg mb-2">No filtered items found</span>
                    <NGButton
                      variant="primary"
                      onClick={() => {
                        setGlobalFilter("");
                        setColumnFilters([]);
                      }}
                      size={"small"}
                    >
                      Clear Filter here
                    </NGButton>
                  </span>
                </td>
              </tr>
            ) : null}
          </tbody>
          {rowGroups.map((rowGroup) => {
            return (
              <React.Fragment key={rowGroup.value}>
                {rowGroup.value !== "all" && !rowGroup.invisibleGroup ? (
                  <thead>
                    <tr className="row-group-label">
                      <th colSpan={table.getLeafHeaders().length}>
                        <button
                          className={`flex items-center cursor-pointer ngfocus w-full`}
                          onClick={() => {
                            setCollapsedGroups((_collapsedGroups) => {
                              if (_collapsedGroups.includes(rowGroup.value)) {
                                return _collapsedGroups.filter((_collapsedGroup) => _collapsedGroup !== rowGroup.value);
                              } else {
                                return [..._collapsedGroups, rowGroup.value];
                              }
                            });
                          }}
                        >
                          {collapsedGroups.includes(rowGroup.value) ? (
                            <ChevronDown className={`h-4`} />
                          ) : (
                            <ChevronUp className={`h-4`} />
                          )}
                          <span>{rowGroup.label}</span>
                        </button>
                      </th>
                    </tr>
                  </thead>
                ) : null}
                {rowGroup.invisibleGroup || collapsedGroups.includes(rowGroup.value) ? null : (
                  <tbody>
                    {rowGroup.visibleRows.map((row) => {
                      const selected = getRowSelectionArray().keys.includes(row.id);
                      const visibleCells = row.getVisibleCells();
                      return (
                        <RowRenderer
                          key={row.id}
                          selected={selected}
                          selectMode={selectMode}
                          row={row as any}
                          visibleCells={visibleCells as any}
                          columnOrder={JSON.stringify(columnOrder)}
                          sort={JSON.stringify(sortingState)}
                        />
                      );
                    })}
                    {/* {rowGroup.invisibleRows.length > 0 ? (
                      <tr>
                        <td colSpan={table.getLeafHeaders().length}>
                          <span className="flex items-center justify-center">
                            +{rowGroup.invisibleRows.length} Filtered out items
                          </span>
                        </td>
                      </tr>
                    ) : null} */}
                  </tbody>
                )}
              </React.Fragment>
            );
          })}
          {/* 
            table.getRowModel().rows.map((row) => {
              return <RowRenderer key={row.id} row={row} />;
            }) 
          */}
        </table>
      </div>
      {isSettingsOpen ? (
        <Modal
          open={isSettingsOpen}
          title={"Table Settings"}
          maskClosable={false}
          closable={false}
          footer={() => (
            <div className="flex items-center gap-2 justify-end">
              <NGButton
                variant={"secondary"}
                onClick={() => {
                  setTemporaryColumnSettings(columnSettings);
                  setIsSettingsOpen(false);
                }}
              >
                Cancel
              </NGButton>
              <NGButton
                variant={"primary"}
                onClick={() => {
                  setColumnSettings(temporaryColumnSettings);
                  setIsSettingsOpen(false);
                }}
              >
                Ok
              </NGButton>
            </div>
          )}
        >
          <div className="pl-5">
            <ReactSortable
              handle=".drag-handle"
              className="flex flex-col gap-2"
              list={JSON.parse(JSON.stringify(temporaryColumnSettings))}
              setList={(newState, sortable, state) => {
                setTemporaryColumnSettings(newState as any[]);
              }}
            >
              {temporaryColumnSettings.map((columnSetting) => {
                const column = rawColumns.find((_column) => _column.id === columnSetting.id)!;
                const tableColumn = table.getAllColumns().find((c) => c.id === column.id)!;
                if (!column.label) {
                  return <span />;
                }

                return (
                  <div key={columnSetting.id} className={clsx("flex items-center relative")}>
                    <DragHandle />
                    <NGCheckbox
                      aria-label={`column-visibility-setting-${columnSetting.id}`}
                      checked={columnSetting.visible}
                      isDisabled={!tableColumn.getCanHide()}
                      onChange={() => {
                        toggleVisibility(columnSetting.id);
                      }}
                    />
                    <div className={`basis-0 flex-grow ml-1`}>{column.label}</div>
                  </div>
                );
              })}
            </ReactSortable>
          </div>
          <div className="mt-4">
            <NGButton
              variant="primary"
              size="small"
              onClick={() => {
                setTemporaryColumnSettings(getInitialColumnSetting());
              }}
            >
              Reset to Defaults
            </NGButton>
          </div>
        </Modal>
      ) : null}
    </>
  );
}

interface RowRendererProps<TData> {
  row: Row<TData>;
  selectMode: TableSelectMode;
  selected: boolean;
  columnOrder: string;
  visibleCells: Cell<TData, unknown>[];
  sort: string;
}
const RowRenderer = React.memo(
  function RowRenderer<TData extends RowData>({ row, selectMode, selected, visibleCells }: RowRendererProps<TData>) {
    return (
      <tr className="item-row">
        {visibleCells.map((cell, cellIndex) => (
          <td
            key={cell.id}
            style={{
              width: cell.column.getSize(),
            }}
          >
            <div className="flex items-center">
              {cellIndex === 0 && selectMode !== "none" ? (
                <div style={{ width: 30, minWidth: 30 }}>
                  <NGCheckbox
                    aria-label={`row-selection-row-${row.id}`}
                    checked={selected}
                    onChange={() => row.toggleSelected()}
                  />
                </div>
              ) : null}
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          </td>
        ))}
      </tr>
    );
  },
  (prevProps, nextProps) => {
    const prevObj = prevProps.row.original;
    const prevHash = md5(JSON.stringify(prevObj));
    const prevSelected = prevProps.selected;
    const prevSort = prevProps.sort;
    const prevColumnOrder = prevProps.columnOrder;
    const prevCells = prevProps.visibleCells
      .map((c) => c.id)
      .sort()
      .join();

    const nextObj = nextProps.row.original;
    const nextHash = md5(JSON.stringify(nextObj));
    const nextSelected = nextProps.selected;
    const nextSort = nextProps.sort;
    const nextColumnOrder = nextProps.columnOrder;
    const nextCells = nextProps.visibleCells
      .map((c) => c.id)
      .sort()
      .join();

    return (
      prevHash === nextHash &&
      prevSelected === nextSelected &&
      prevCells === nextCells &&
      prevSort === nextSort &&
      prevColumnOrder === nextColumnOrder
    );
  },
);

/*
table 
  thead
    tr
      th 
      th 
      th
  tbody
    tr
      td 
      td 
      td
*/
