import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { PortModel } from "../../mobxDataModels/portModel";

export const WorkloadDraftContainerMetricsModel = types
  .model({
    _useMetrics: types.optional(types.boolean, false),
    useMetrics: types.optional(types.boolean, false),
    _path: types.optional(types.string, ""),
    _port: types.optional(types.number, 0),
    path: types.optional(StringModel, () => StringModel.create({ label: "Path", isRequired: true })),
    port: types.optional(StringModel, () => PortModel.create({ label: "Port", isRequired: true })),
  })
  .actions((self) => ({
    reset() {
      self.useMetrics = self._useMetrics;
      if (self.useMetrics) {
        self.path.setInitialValue(self._path);
        self.port.setInitialValue(String(self._port));
      } else {
        self.path.reset();
        self.port.reset();
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._useMetrics = self.useMetrics;
      if (self._useMetrics) {
        self._path = self.path.value;
        self.path.confirm();
        self._port = Number(self.port.value);
        self.port.confirm();
      } else {
        self._path = "";
        self._port = 0;
        self.path.setInitialValue("");
        self.port.setInitialValue("");
      }
    },
    setUseMetrics(value: boolean) {
      self.useMetrics = value;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.useMetrics !== self._useMetrics) res = true;
      if (self.useMetrics) {
        if (self.path.isDirty) res = true;
        if (self.port.isDirty) res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (self.useMetrics) {
        if (!self.path.isValid) res = false;
        if (!self.port.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      return { path: self.path.value, port: Number(self.port.value) };
    },
  }))
  .views((self) => ({
    get asPatch() {
      if (!self.useMetrics && !self._useMetrics) {
        return undefined;
      }
      if (self.useMetrics) {
        return self.asObject;
      } else {
        return null;
      }
    },
  }));
export interface WorkloadDraftContainerMetricsMobx extends Instance<typeof WorkloadDraftContainerMetricsModel> {}
