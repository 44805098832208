import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { OrgMobx } from "../../mst/kinds/org";
import { FormButtons } from "../../components/forms/formButtons";
import { StringModel } from "../../mobxDataModels/stringModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { KindSelectModel } from "../../mobxDataModels/kindSelectModel";
import { useDetailContext } from "../../components/detail/detailContext";
import { SelectWorkloadModal } from "./SelectWorkloadModal";
import { NGLabel } from "../../newcomponents/text/label";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { NGFormLabel } from "../../newcomponents/text/formLabel";
import { NGInput } from "../../newcomponents/input/input";
import { NGError } from "../../newcomponents/text/error";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInputListMst } from "../../newcomponents/inputList/inputListMst";
import { InfoTooltip } from "../../components/InfoTooltip";

type TracingType = "none" | "lightstep" | "otel" | "controlplane";
interface Props {
  org: OrgMobx;
}
const TracingRaw: React.FC<Props> = ({ org }) => {
  const { fetchItem } = useDetailContext();
  const formData = useNGFormContext();

  function getDefaultTracingType(): TracingType {
    if (org.spec?.tracing?.provider?.otel) {
      return "otel";
    }
    if (org.spec?.tracing?.readLightstep) {
      return "lightstep";
    }
    if (org.spec?.tracing?.provider?.controlplane) {
      return "controlplane";
    }
    return "none";
  }
  const tracingTypeRef = React.useRef(
    SelectModel.create({
      label: "Tracing Provider",
      initialValue: getDefaultTracingType(),
      options: [
        { label: "None", value: "none" },
        { label: "Lightstep", value: "lightstep" },
        { label: "Open Telemetry", value: "otel" },
        { label: "Control Plane", value: "controlplane" },
      ],
    }),
  );

  function getDefaultSampling(): number {
    return org.spec?.tracing?.sampling || 10;
  }
  const tracingSamplingRef = React.useRef(
    StringModel.create({
      label: "Sampling Percentage",
      initialValue: getDefaultSampling().toString(),
      validationKey: "tracingSampling",
      examples: ["0", "100", "1.15"],
      isRequired: true,
    }),
  );

  function getDefaultCustomTags() {
    const customTags = org.spec?.tracing?.customTags || {};
    return Object.entries(customTags).map(([key, value]) => {
      return {
        firstValue: key,
        secondValue: (value as any)?.literal?.value || "",
      };
    });
  }
  const customTagsRef = React.useRef(ListOfItemsModel.create({ _items: getDefaultCustomTags() as any }));

  const [validDirtyCounter, setValidDirtyCounter] = React.useState(0);

  // Lightstep
  const [isSelectWorkloadModalOpen, setIsSelectWorkloadModalOpen] = React.useState(false);

  function getDefaultLightstepEndpoint() {
    return org?.spec?.tracing?.readLightstep?.endpoint || "";
  }
  const lightstep_endpointRef = React.useRef(
    StringModel.create({
      label: "Endpoint",
      initialValue: getDefaultLightstepEndpoint(),
      isRequired: true,
    }),
  );

  function getDefaultLightstepCredentials() {
    const fullLink = org?.spec?.tracing?.readLightstep?.credentials || "";
    const parsed = ngParseLink(fullLink, { kind: "secret" });
    return parsed.name;
  }
  const lightstep_credentialsRef = React.useRef(
    KindSelectModel.create({
      isRequired: false,
      kind: "secret",
      label: "Credentials (Opaque Secret)",
      initialValue: getDefaultLightstepCredentials(),
      valueType: "link",
    }),
  );

  // otel
  function getDefaultOtelEndpoint() {
    return org?.spec?.tracing?.provider?.otel?.endpoint || "";
  }
  const otel_endpointRef = React.useRef(
    StringModel.create({
      label: "Endpoint",
      initialValue: getDefaultOtelEndpoint(),
      isRequired: true,
    }),
  );

  // providers done

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  // dirty
  React.useEffect(() => {
    let res = false;
    if (getDefaultTracingType() !== tracingTypeRef.current.value) {
      res = true;
    }
    if (tracingTypeRef.current.value !== "none") {
      if (getDefaultSampling().toString() !== tracingSamplingRef.current.value) {
        res = true;
      }
      if (customTagsRef.current.isDirty) {
        res = true;
      }
    }
    if (tracingTypeRef.current.value === "lightstep") {
      if (getDefaultLightstepEndpoint() !== lightstep_endpointRef.current.value) {
        res = true;
      }
      if (getDefaultLightstepCredentials() !== lightstep_credentialsRef.current.value) {
        res = true;
      }
    }
    if (tracingTypeRef.current.value === "otel") {
      if (getDefaultOtelEndpoint() !== otel_endpointRef.current.value) {
        res = true;
      }
    }
    setIsDirty(res);
  }, [
    tracingTypeRef.current.value,
    tracingSamplingRef.current.value,
    tracingSamplingRef.current.isDirty,
    lightstep_endpointRef.current.isDirty,
    lightstep_credentialsRef.current.isDirty,
    otel_endpointRef.current.isDirty,
    customTagsRef.current.isDirty,
    validDirtyCounter,
  ]);

  // valid
  React.useEffect(() => {
    let res = true;
    if (tracingTypeRef.current.value !== "none") {
      if (!tracingSamplingRef.current.isValid) {
        res = false;
      }
    }
    if (tracingTypeRef.current.value === "lightstep") {
      if (!lightstep_endpointRef.current.isValid) {
        res = false;
      }
    }
    if (tracingTypeRef.current.value === "otel") {
      if (!otel_endpointRef.current.isValid) {
        res = false;
      }
    }
    setIsValid(res);
  }, [
    tracingTypeRef.current.value,
    tracingSamplingRef.current.isValid,
    lightstep_endpointRef.current.isValid,
    otel_endpointRef.current.isValid,
    validDirtyCounter,
  ]);

  function reset() {
    tracingTypeRef.current.reset();
    tracingSamplingRef.current.reset();
    lightstep_endpointRef.current.reset();
    lightstep_credentialsRef.current.reset();
    otel_endpointRef.current.reset();
    customTagsRef.current.reset();

    setValidDirtyCounter((x) => x + 1);
  }

  async function save() {
    try {
      setIsLoading(true);

      const customTags: any = {};
      for (const item of customTagsRef.current.items) {
        customTags[item.firstValue] = {
          literal: {
            value: item.secondValue,
          },
        };
      }

      const body: any = {};
      if (getDefaultTracingType() !== "none" && tracingTypeRef.current.value === "none") {
        body.spec = { tracing: null };
      } else if (tracingTypeRef.current.value === "lightstep") {
        const lightstep: any = {
          endpoint: lightstep_endpointRef.current.value,
        };
        if (lightstep_credentialsRef.current.value.length > 0) {
          const { absolute } = ngParseLink(lightstep_credentialsRef.current.value, {
            kind: "secret",
          });
          lightstep.credentials = absolute;
        }
        body.spec = {
          "$replace/tracing": {
            sampling: Number(tracingSamplingRef.current.value),
            customTags: customTags,
            provider: {
              lightstep,
            },
          },
        };
      } else if (tracingTypeRef.current.value === "otel") {
        const otel: any = {
          endpoint: otel_endpointRef.current.value,
        };
        body.spec = {
          "$replace/tracing": {
            sampling: Number(tracingSamplingRef.current.value),
            customTags: customTags,
            provider: {
              otel,
            },
          },
        };
      } else if (tracingTypeRef.current.value === "controlplane") {
        body.spec = {
          "$replace/tracing": {
            sampling: Number(tracingSamplingRef.current.value),
            customTags: customTags,
            provider: {
              controlplane: {},
            },
          },
        };
      }

      await org.patch(body);

      tracingTypeRef.current.confirm();
      tracingSamplingRef.current.confirm();
      lightstep_endpointRef.current.confirm();
      const { name } = ngParseLink(lightstep_credentialsRef.current.value, { kind: "secret" });
      lightstep_credentialsRef.current.setInitialValue(name);
      otel_endpointRef.current.confirm();
      customTagsRef.current.confirm();

      setValidDirtyCounter((x) => x + 1);
      notification.success({
        message: "Success",
        description: "Updated Org Tracing",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div>
        <NGLabel name="tracingProvider">{tracingTypeRef.current.label}</NGLabel>
        <NGSelect
          name="tracingProvider"
          style={{ width: 450 }}
          className="mb-4"
          value={tracingTypeRef.current.value}
          options={tracingTypeRef.current.options}
          onChange={(value) => tracingTypeRef.current.setValue(value)}
        />
        {tracingTypeRef.current.value !== "none" ? (
          <div className="mb-4">
            <NGFormLabel name="tracingSampling" required invalid={!tracingSamplingRef.current.isValid}>
              {tracingSamplingRef.current.label}
            </NGFormLabel>
            <div className="flex items-center">
              <NGInput
                name={"tracingSampling"}
                value={tracingSamplingRef.current.value}
                onChange={(e) => tracingSamplingRef.current.setValue(e.target.value)}
                invalid={!tracingSamplingRef.current.isValid}
                placeholder={tracingSamplingRef.current.exampleText}
                style={{ width: 450 }}
              />
              <InfoTooltip title="Determines what percentage of requests should be traced." />
            </div>
            {formData.get("tracingSampling").touched && !tracingSamplingRef.current.isValid ? (
              <NGError>{tracingSamplingRef.current.error}</NGError>
            ) : null}
          </div>
        ) : null}
        {tracingTypeRef.current.value === "lightstep" ? (
          <div>
            <div className="mb-4">
              <NGFormLabel name="lightstepEndpoint" required invalid={!lightstep_endpointRef.current.isValid}>
                {lightstep_endpointRef.current.label}
              </NGFormLabel>
              <div className="flex items-center">
                <NGInput
                  name={"lightstepEndpoint"}
                  value={lightstep_endpointRef.current.value}
                  onChange={(e) => lightstep_endpointRef.current.setValue(e.target.value)}
                  invalid={!lightstep_endpointRef.current.isValid}
                  placeholder={`test.com, <workload>.<gvc>.cpln.local[:<port>]`}
                  style={{ width: 450 }}
                />
                <InfoTooltip
                  title={[
                    "Can be local endpoint for a workload.",
                    "Can be canonical endpoint of a workload.",
                    "Can be any custom endpoint.",
                  ]}
                />
              </div>
              {formData.get("lightstepEndpoint").touched && !lightstep_endpointRef.current.isValid ? (
                <NGError>{lightstep_endpointRef.current.error}</NGError>
              ) : null}
              <div className="flex justify-end mt-2" style={{ width: 450 }}>
                <NGButton
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setIsSelectWorkloadModalOpen(!isSelectWorkloadModalOpen)}
                >
                  Select Workload and Port
                </NGButton>
              </div>
            </div>
            <div className="mb-4">
              <NGFormLabel name="lightstepCredentials">{lightstep_credentialsRef.current.label}</NGFormLabel>
              <NGKindSelect
                name={"lightstepCredentials"}
                kind={"secret"}
                showClear
                value={lightstep_credentialsRef.current.value}
                onChange={(value) => lightstep_credentialsRef.current.setValue(value)}
                queries={[{ property: "type", value: "opaque" }]}
                style={{ width: 450 }}
              />
            </div>
          </div>
        ) : null}
        {tracingTypeRef.current.value === "otel" ? (
          <div className="mb-4">
            <NGFormLabel name="otelEndpoint" required invalid={!otel_endpointRef.current.isValid}>
              {otel_endpointRef.current.label}
            </NGFormLabel>
            <div className="flex items-center">
              <NGInput
                name={"otelEndpoint"}
                value={otel_endpointRef.current.value}
                onChange={(e) => otel_endpointRef.current.setValue(e.target.value)}
                invalid={!otel_endpointRef.current.isValid}
                placeholder={`test.com, <workload>.<gvc>.cpln.local[:<port>]`}
                style={{ width: 450 }}
              />
              <InfoTooltip
                title={[
                  "Can be local endpoint for a workload.",
                  "Can be canonical endpoint of a workload.",
                  "Can be any custom endpoint.",
                ]}
              />
            </div>
            {formData.get("otelEndpoint").touched && !otel_endpointRef.current.isValid ? (
              <NGError>{otel_endpointRef.current.error}</NGError>
            ) : null}
            <div className="flex justify-end mt-2" style={{ width: 450 }}>
              <NGButton
                size={"small"}
                variant={"secondary"}
                onClick={() => setIsSelectWorkloadModalOpen(!isSelectWorkloadModalOpen)}
              >
                Select Workload and Port
              </NGButton>
            </div>
          </div>
        ) : null}
        {tracingTypeRef.current.value !== "none" ? (
          <NGInputListMst
            data={customTagsRef.current}
            firstLabel="Key"
            firstInput={(item) => (
              <NGInput value={item.firstValue} onChange={(e) => item.setFirstValue(e.target.value)} />
            )}
            secondLabel="Value"
            secondInput={(item) => (
              <NGInput value={item.secondValue} onChange={(e) => item.setSecondValue(e.target.value)} />
            )}
            label="Custom Tags"
          />
        ) : null}
        <div style={{ width: 450 }}>
          <FormButtons
            onReset={reset}
            onSave={save}
            resetDisabled={isLoading || !isDirty}
            saveDisabled={isLoading || !isDirty || !isValid}
            loading={isLoading}
          />
        </div>
      </div>
      {isSelectWorkloadModalOpen ? (
        <SelectWorkloadModal
          kind="org"
          endpoint={
            tracingTypeRef.current.value === "otel"
              ? otel_endpointRef.current.value
              : lightstep_endpointRef.current.value
          }
          setEndpoint={
            tracingTypeRef.current.value === "otel"
              ? otel_endpointRef.current.setValue
              : lightstep_endpointRef.current.setValue
          }
          onClose={() => setIsSelectWorkloadModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export const Tracing = observer(TracingRaw);
