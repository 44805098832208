import { types } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { WithCreated, WithCreatedModel, WithLinks, WithLinksModel } from "../base";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

interface AccessReportPrincipleData {
  permission: string;
  policy: string;
  granted: string;
}

const AccessReportPermissionBindingModel = types
  .model({
    principalLink: types.optional(types.string, ""),
    grantingPolicyLink: types.optional(types.string, ""),
    grantedPermissions: types.array(types.string),
    match: types.optional(types.string, ""), // all link query
  })
  .actions((self) => ({
    setPrincipalLink(newLink: string) {
      self.principalLink = newLink;
    },
  }));
interface AccessReportPermissionBinding {
  grantedPermissions: string[];
  grantingPolicyLink: string;
  match: "all" | "link" | "query" | string;
  principalLink: string;
}

const AccessReportPermissionModel = types.model({
  name: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
  bindings: types.array(AccessReportPermissionBindingModel),
});
interface AccessReportPermission {
  name: string;
  description: string;
  bindings: AccessReportPermissionBinding[];
}

export interface AccessReportTableItem {
  permission: string;
  principal: {
    name: string;
    link: string;
  };
  granted: string;
}

const builtinPrincipals = ["/superusers", "/viewers", "/controlplane", "/-allmembers"];

const builtinPolicies = [
  "/superusers-agent",
  "/superusers-auditctx",
  "/superusers-cloudaccount",
  "/superusers-domain",
  "/superusers-group",
  "/superusers-gvc",
  "/superusers-identity",
  "/superusers-image",
  "/superusers-location",
  "/superusers-org",
  "/superusers-policy",
  "/superusers-quota",
  "/superusers-secret",
  "/superusers-serviceaccount",
  "/superusers-user",
  "/superusers-workload",
  "/viewers-agent",
  "/viewers-auditctx",
  "/viewers-cloudaccount",
  "/viewers-domain",
  "/viewers-group",
  "/viewers-gvc",
  "/viewers-identity",
  "/viewers-image",
  "/viewers-location",
  "/viewers-org",
  "/viewers-policy",
  "/viewers-quota",
  "/viewers-secret",
  "/viewers-serviceaccount",
  "/viewers-user",
  "/viewers-workload",
];

function isBuiltinPrincipal(value: string) {
  for (let principal of builtinPrincipals) {
    if (value.includes(principal)) {
      return true;
    }
  }
  return false;
}

function isBuiltinPolicy(value: string) {
  for (let policy of builtinPolicies) {
    if (value.includes(policy)) {
      return true;
    }
  }
  return false;
}

export const AccessReportModel = types
  .compose(
    "Access Report",
    WithCreatedModel,
    WithLinksModel,
    types.model({
      kind: "accessreport",
      permissions: types.array(AccessReportPermissionModel),
    })
  )
  .views((self) => ({
    get byPermissionWithoutBuiltin() {
      const permissions: AccessReportPermission[] = [];
      for (let permission of self.permissions) {
        const bindings: AccessReportPermissionBinding[] = [];
        for (let binding of permission.bindings) {
          if (isBuiltinPolicy(binding.grantingPolicyLink)) {
            continue;
          }
          if (isBuiltinPrincipal(binding.principalLink)) {
            continue;
          }
          bindings.push(binding);
        }
        if (bindings.length < 1) {
          continue;
        }
        permissions.push({ bindings, name: permission.name, description: permission.description });
      }
      return permissions;
    },
    get byPrincipal() {
      const principals: { [_: string]: AccessReportPrincipleData[] } = {};
      self.permissions.forEach((permission) => {
        // permission policy grant
        permission.bindings.forEach((binding) => {
          if (!principals[binding.principalLink]) {
            principals[binding.principalLink] = [];
          }
          principals[binding.principalLink].push({
            permission: permission.name,
            policy: binding.grantingPolicyLink,
            granted: binding.grantedPermissions.join(", "),
          });
        });
      });
      const userLink = `/org/${ConsoleContext.org}/user/`;
      const groupLink = `/org/${ConsoleContext.org}/group/`;
      const serviceaccountLink = `/org/${ConsoleContext.org}/serviceaccount/`;
      const identityLink = `/org/${ConsoleContext.org}/gvc/${ConsoleContext.gvc}/identity/`;

      const users: { [_: string]: AccessReportPrincipleData[] } = {};
      const groups: { [_: string]: AccessReportPrincipleData[] } = {};
      const serviceaccounts: { [_: string]: AccessReportPrincipleData[] } = {};
      const identities: { [_: string]: AccessReportPrincipleData[] } = {};
      Object.keys(principals).forEach((principal) => {
        const data = principals[principal];
        if (principal.startsWith(userLink)) {
          users[principal] = data;
        } else if (principal.startsWith(groupLink)) {
          groups[principal] = data;
        } else if (principal.startsWith(serviceaccountLink)) {
          serviceaccounts[principal] = data;
        } else if (principal.startsWith(identityLink)) {
          identities[principal] = data;
        }
      });

      return { users, groups, serviceaccounts, identities };
    },
  }))
  .views((self) => ({
    get byPrincipalWithoutBuiltin() {
      const principals: { [_: string]: AccessReportPrincipleData[] } = {};
      for (let permission of self.permissions) {
        for (let binding of permission.bindings) {
          if (isBuiltinPrincipal(binding.principalLink) || isBuiltinPolicy(binding.grantingPolicyLink)) {
            continue;
          }
          if (!principals[binding.principalLink]) {
            principals[binding.principalLink] = [];
          }
          principals[binding.principalLink].push({
            permission: permission.name,
            policy: binding.grantingPolicyLink,
            granted: binding.grantedPermissions.join(", "),
          });
        }
      }
      const userLink = `/org/${ConsoleContext.org}/user/`;
      const groupLink = `/org/${ConsoleContext.org}/group/`;
      const serviceaccountLink = `/org/${ConsoleContext.org}/serviceaccount/`;
      const identityLink = `/org/${ConsoleContext.org}/gvc/${ConsoleContext.gvc}/identity/`;

      const users: { [_: string]: AccessReportPrincipleData[] } = {};
      const groups: { [_: string]: AccessReportPrincipleData[] } = {};
      const serviceaccounts: { [_: string]: AccessReportPrincipleData[] } = {};
      const identities: { [_: string]: AccessReportPrincipleData[] } = {};
      Object.keys(principals).forEach((principal) => {
        const data = principals[principal];
        if (principal.startsWith(userLink)) {
          users[principal] = data;
        } else if (principal.startsWith(groupLink)) {
          groups[principal] = data;
        } else if (principal.startsWith(serviceaccountLink)) {
          serviceaccounts[principal] = data;
        } else if (principal.startsWith(identityLink)) {
          identities[principal] = data;
        }
      });

      return { users, groups, serviceaccounts, identities };
    },
  }))
  .views((self) => ({
    get hasCustomByPrincipal() {
      if (Object.keys(self.byPrincipalWithoutBuiltin.users).length > 0) {
        return true;
      }
      if (Object.keys(self.byPrincipalWithoutBuiltin.groups).length > 0) {
        return true;
      }
      if (Object.keys(self.byPrincipalWithoutBuiltin.serviceaccounts).length > 0) {
        return true;
      }
      if (Object.keys(self.byPrincipalWithoutBuiltin.identities).length > 0) {
        return true;
      }
      return false;
    },
    get hasCustomByPermission() {
      if (self.byPermissionWithoutBuiltin.length > 0) {
        return true;
      }
      return false;
    },
  }))
  .views((self) => {
    function getByPrincipal(hideBuiltin: boolean) {
      if (hideBuiltin) {
        return self.byPrincipalWithoutBuiltin;
      }
      return self.byPrincipal;
    }
    function getByPermission(hideBuiltin: boolean) {
      if (hideBuiltin) {
        return self.byPermissionWithoutBuiltin;
      }
      return self.permissions;
    }
    return { getByPrincipal, getByPermission };
  });

export interface AccessReportMobx extends Instance<typeof AccessReportModel> {}
export interface AccessReport extends WithCreated, WithLinks {
  kind: "accessreport";
  permissions: AccessReportPermission[];
}
