import * as React from "react";
import { Route } from "react-router-dom";
import { CloudaccountMobx } from "../../mst/kinds/cloudaccount";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { observer } from "mobx-react-lite";
import { CloudaccountGcpConfiguration } from "./detailConfiguration/gcp";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

interface Props {
  cloudaccount: CloudaccountMobx;
}
const CloudaccountDetailRaw: React.FC<Props> = ({ cloudaccount }) => {
  const basePath = useBasePath("/cloudaccount/:cloudaccount/*");

  const eventlogHook = useEventlogs(cloudaccount.selfLink);

  const [links] = React.useState(
    [
      { label: "Info", url: "-info" },
      cloudaccount.provider === "gcp" ? { label: "Configuration", url: "-configuration", icon: true } : null,
      { label: "Tags", url: "-tags" },
      { label: "Events", url: "-events" },
      { label: "Audit Trail", url: `-audittrail` },
      { label: "Access Report", url: "-accessreport" },
    ].filter(Boolean) as any,
  );

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links}>
        <Route path={`-info`} element={<Info cloudaccount={cloudaccount} />} />
        {cloudaccount.provider === "gcp" ? (
          <Route
            path={`-configuration`}
            element={
              <CloudaccountGcpConfiguration projectId={(cloudaccount.data as any).projectId} name={cloudaccount.name} />
            }
          />
        ) : null}
        <Route path={`-tags`} element={<Tags link={cloudaccount.selfLink} tags={cloudaccount.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"cloudaccount"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="cloudaccount" resourceId={cloudaccount.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={cloudaccount.selfLink} />} />
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const CloudaccountDetail = observer(CloudaccountDetailRaw);
