import * as React from "react";
import { observer } from "mobx-react-lite";
import { Clock, Copy } from "react-feather";
import { Modal, notification } from "antd";
import { NGButton } from "../../newcomponents/button/Button";
import NGAlert from "../../newcomponents/alert";
import humanizeDuration from "humanize-duration";

export interface InstallLocationData {
  command: string;
  manifestLink: string;
  expires: number;
}

interface Props {
  installLocationData: InstallLocationData;
  onCancel: () => void;
}

const InstallLocationRaw: React.FC<Props> = ({ installLocationData, onCancel }) => {
  const [timeLeft, setTimeLeft] = React.useState<number>(installLocationData.expires - Math.floor(Date.now() / 1000));
  const [isExpired, setIsExpired] = React.useState<boolean>(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeDifference = installLocationData.expires - currentTime;
      if (timeDifference <= 0) {
        setIsExpired(true);
        clearInterval(interval);
      }
      setTimeLeft(timeDifference);
    }, 1000);

    return () => clearInterval(interval);
  }, [installLocationData.expires]);

  function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  return (
    <Modal
      title={
        <div className="flex items-center gap-2">
          Install Location
          <NGAlert
            type="error"
            size="small"
            renderIcon={isExpired ? undefined : () => <Clock size={18} />}
            render={() => (
              <span>{isExpired ? "Expired" : `Expires In: ${humanizeDuration(timeLeft * 1000, { round: true })}`}</span>
            )}
            style={{ width: "fit-content", minWidth: "auto", fontSize: 14, fontWeight: 400 }}
          />
        </div>
      }
      open={true}
      onCancel={() => onCancel()}
      footer={
        <div className="modal-actions">
          <NGButton variant="primary" onClick={() => onCancel()}>
            Ok
          </NGButton>
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <span className="list-decimal">Apply below command in kubectl</span>
          <div className="flex items-center">
            <input
              className="flex-grow border px-4 py-2"
              style={{
                width: 450,
              }}
              disabled={true}
              value={installLocationData.command}
            />
            <button
              onClick={() => copyToClipboard(installLocationData.command)}
              className={`ml-2 ngfocus color-link-hover`}
            >
              <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
            </button>
          </div>
        </div>
        <NGAlert
          type="warning"
          render={() => (
            <span>Manifest link in the command contains sensitive information and is only available temporarily.</span>
          )}
        />
      </div>
    </Modal>
  );
};

export const InstallLocation = observer(InstallLocationRaw);
