import * as React from "react";
import * as ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";
import { Affix } from "antd";
import { IS_DEPLOYMENT_ENV_TEST, IS_HEADLESS } from "../../../envVariables";
import { User } from "../../../mobxStores/user/user";
import NGAlert from "../../../newcomponents/alert";

const disallowedEmails = [
  `001`,
  "002",
  "003",
  `004`,
  "005",
  "006",
  `007`,
  "008",
  "009",
  `010`,
  `011`,
  "022",
  "013",
  `014`,
  "015",
  "016",
  `017`,
  "018",
  "019",
  `020`,
  `1`,
  "2",
  "3",
  `4`,
  "5",
  "6",
  `7`,
  "8",
  "9",
  `10`,
  `11`,
  "12",
  "13",
  `14`,
  "15",
  "16",
  `17`,
  "18",
  "19",
  "20",
].map((n) => `test-${n}@example.com`);

const DisallowedEmailRaw: React.FC = () => {
  const [isUsingDisallowedEmail, setIsUsingDisallowedEmail] = React.useState(false);

  React.useEffect(() => {
    if (disallowedEmails.includes(User.email)) {
      setIsUsingDisallowedEmail(true);
    }
  }, []);

  if (isUsingDisallowedEmail && !IS_HEADLESS && IS_DEPLOYMENT_ENV_TEST) {
    return ReactDOM.createPortal(
      <Affix offsetTop={200}>
        <NGAlert
          type={"error"}
          style={{ fontSize: 40, width: 800, maxWidth: 800 }}
          renderIcon={() => <></>}
          message={`THIS EMAIL ADDRESS IS BEING USED FOR INTEGRATION TESTS. PLEASE LOG OUT AND DONT USE THIS EMAIL.`}
        />
      </Affix>,
      document.getElementById("affix-root-disallowed-email")!
    );
  }

  return null;
};

export const DisallowedEmail = observer(DisallowedEmailRaw);
