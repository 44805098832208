import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import {
  ExpandVolumeReducerState,
  RunExpandVolumeReducerActionType,
  useRunExpandVolumeReducer,
} from "./reducer_expandVolume";
import { VolumeSetMobx } from "../../../mst/kinds/volumeset";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGInput } from "../../../newcomponents/input/input";

interface Props {
  volumeSet: VolumeSetMobx;
  initialLocation?: string;
  initialVolumeIndex?: string;
  initialStorageCapacity?: number;
  onClose: any;
  onDone: any;
  visible: boolean;
}

const CommandModal_ExpandVolumeRaw: React.FC<Props> = ({
  volumeSet,
  initialLocation,
  initialVolumeIndex,
  initialStorageCapacity,
  onClose,
  onDone,
  visible,
}) => {
  const formDataRef = React.useRef(new NGFormData());
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, dispatch] = useRunExpandVolumeReducer(
    initialLocation,
    initialVolumeIndex,
    String(initialStorageCapacity)
  );

  async function handleConfirm() {
    try {
      setIsLoading(true);

      const spec: ExpandVolumeReducerState = JSON.parse(JSON.stringify(state));
      // TODO remove below after fixing number input
      spec.newStorageCapacity = Number(spec.newStorageCapacity) as any;

      await request({
        method: "post",
        url: `${volumeSet.selfLink}/-command`,
        body: {
          type: "expandVolume",
          spec: spec,
        },
      });
      onDone();
      notification.success({
        message: "Success",
        description: "Created Command.",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  let isValid = true;
  if (!state.location) isValid = false;
  if (!state.volumeIndex) isValid = false;
  if (!state.newStorageCapacity) isValid = false;

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <Modal
        width={450}
        open={visible}
        closable={false}
        title="Expand Volume"
        footer={
          <div className="modal-actions">
            <NGButton
              disabled={isLoading}
              onClick={() => {
                onClose();
              }}
              variant={"danger"}
              outlined
            >
              Cancel
            </NGButton>
            <NGButton loading={isLoading} disabled={isLoading || !isValid} onClick={handleConfirm} variant={"action"}>
              Expand
            </NGButton>
          </div>
        }
        onCancel={onClose}
        maskClosable={!isLoading}
        destroyOnClose
      >
        <NGFormLabel name={"location"} required invalid={!state.location}>
          Location
        </NGFormLabel>
        <NGSelect
          className="mb-4"
          name={"location"}
          value={state.location}
          invalid={!state.location}
          onChange={(value) => dispatch({ type: RunExpandVolumeReducerActionType.LOCATION, payload: value })}
          options={volumeSet.status.locations.map(({ name: l }) => ({
            label: l,
            value: l,
            disabled: false,
            isLabel: false,
          }))}
        />
        <NGFormLabel name={"volumeIndex"} required invalid={state.volumeIndex.length < 1}>
          Volume Index
        </NGFormLabel>
        <NGInput
          className="mb-4"
          name={"volumeIndex"}
          value={state.volumeIndex}
          invalid={state.volumeIndex.length < 1}
          onChange={(e) => dispatch({ type: RunExpandVolumeReducerActionType.INDEX, payload: e.target.value })}
        />
        <NGFormLabel name={"storageCapacity"} required invalid={state.newStorageCapacity.length < 1}>
          New Storage Capacity (GiB)
        </NGFormLabel>
        <NGInput
          className="mb-4"
          name={"storageCapacity"}
          value={state.newStorageCapacity}
          invalid={state.newStorageCapacity.length < 1}
          onChange={
            (e) => dispatch({ type: RunExpandVolumeReducerActionType.NEW_STORAGE_CAPACITY, payload: e.target.value }) // TODO send as number?
          }
        />
      </Modal>
    </NGFormContext.Provider>
  );
};

export const CommandModal_ExpandVolume = observer(CommandModal_ExpandVolumeRaw);
