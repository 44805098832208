import { observer } from "mobx-react-lite";
import * as React from "react";
import { GVCMobx } from "../../mst/kinds/gvc";
import { QueryUI } from "../../components/query/queryUI";
import { ItemStoreQueryMobx, ItemStoreQueryModel } from "../../mst/query";
import { objectToArray } from "../../services/utils";
import { FormButtons } from "../../components/forms/formButtons";
import { notification } from "antd";
import { useDetailContext } from "../../components/detail/detailContext";

interface Props {
  gvc: GVCMobx;
}
const GVCLocationQueryRaw: React.FC<Props> = ({ gvc }) => {
  const { fetchItem } = useDetailContext();

  const [isLoading, setIsLoading] = React.useState(false);

  function getDefaultUseQuery() {
    return !!gvc.spec.staticPlacement.locationQuery && gvc.spec.staticPlacement.locationQuery.spec.terms.length > 0;
  }
  const [useQuery, setUseQuery] = React.useState(getDefaultUseQuery());

  function getDefaultQueryModel() {
    if (!gvc.spec.staticPlacement.locationQuery) {
      return;
    }

    return {
      kind: "location",
      spec: JSON.parse(JSON.stringify(gvc.spec.staticPlacement.locationQuery.spec)),
    };
  }
  const [query, setQuery] = React.useState<ItemStoreQueryMobx>(
    ItemStoreQueryModel.create({ previousModel: getDefaultQueryModel() as any, model: getDefaultQueryModel() as any }),
  );

  async function handleSave() {
    try {
      setIsLoading(true);
      const body = {
        spec: {
          staticPlacement: {
            locationQuery: useQuery ? query.model.toQueryObj : null,
          },
        },
      };
      await gvc.patch(body);
      notification.success({ message: "Success", description: "Updated GVC location query" });
      setIsLoading(false);
      handleReset();
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });

      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  async function handleReset() {
    setUseQuery(getDefaultUseQuery());
    setQuery(
      ItemStoreQueryModel.create({
        previousModel: getDefaultQueryModel() as any,
        model: getDefaultQueryModel() as any,
      }),
    );
  }

  let isDirty = false;
  if (useQuery !== getDefaultUseQuery()) isDirty = true;
  if (query.isDirty) isDirty = true;

  return (
    <div>
      <QueryUI
        idPrefix={`gvc-detail-location`}
        kind={"location"}
        query={query}
        useQuery={useQuery}
        setUseQuery={setUseQuery}
        evaluatedItemFn={(item: any) => ({
          id: item.id,
          name: item.name,
          description: "",
          tags: objectToArray(item.tags),
        })}
      />
      <FormButtons
        loading={isLoading}
        onReset={handleReset}
        onSave={handleSave}
        resetDisabled={!isDirty || isLoading}
        saveDisabled={!isDirty || isLoading}
      />
    </div>
  );
};

export const GVCLocationQuery = observer(GVCLocationQueryRaw);
