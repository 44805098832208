import { KubernetesReadyData } from "../../components/detail/kubernetesReadinessReducer";
import { Mk8sCluster } from "../../schema/types/mk8s";

export interface Mk8sClusterTableItem extends Mk8sCluster {
  selfLink: string;
  readiness?: KubernetesReadyData;
  provider: string;
  kubernetesVersion: string;
  dashboardUrl: string;
  isAddonLogsEnabled: boolean;
}

export const mk8sHelpers = {
  getProvider(mk8s: Mk8sCluster) {
    return Object.keys(mk8s.spec.provider)[0];
  },
  getKubernetesVersion(mk8s: Mk8sCluster) {
    return mk8s.spec.version;
  },
  getDashboardUrl(mk8s: Mk8sCluster) {
    return mk8s.status?.addOns?.dashboard?.url || "";
  },
  getIsAddonLogsEnabled(mk8s: Mk8sCluster) {
    return !!mk8s.spec.addOns?.logs;
  },
};
