import * as React from "react";
import { WorkloadMobx } from "../../mst/kinds/workload";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { getLocalToken, request } from "../../services/cpln";
import qs from "qs";
import { Theme } from "../../mobxStores/uiData/theme";
import { ExternalLink } from "react-feather";
import { Discovery } from "../../mobxStores/discovery/discovery";
import { METRICS_TOKEN_UPDATE_INTERVAL_MS } from "../../envVariables";
import { Loader } from "../../components/layout/loader";
import NGAlert from "../../newcomponents/alert";
import { getMetricsUrl } from "../../services/utils";

interface Props {
  workload: WorkloadMobx;
  workloadDraft: WorkloadDraftMobx;
}
const MetricsRaw: React.FC<Props> = ({ workload, workloadDraft }) => {
  const formRef = React.useRef<HTMLFormElement>(null as any);
  const [embedTicket, setEmbedTicket] = React.useState<string | null>(null);
  const [error, setError] = React.useState("");
  const [height, setHeight] = React.useState(500);
  const [logsTokenFromLocal, setLogsTokenFromLocal] = React.useState("");
  React.useEffect(() => {
    getLocalToken().then((res) => {
      if (res.accessToken !== "pass") {
        setLogsTokenFromLocal(res.accessToken);
      }
    });
    const localTokenInterval = setInterval(() => {
      getLocalToken().then((res) => {
        if (res.accessToken !== "pass") {
          setLogsTokenFromLocal(res.accessToken);
        }
      });
    }, METRICS_TOKEN_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(localTokenInterval);
    };
  }, []);
  React.useEffect(() => {
    updateHeight();
    const id = setInterval(() => {
      updateHeight();
    }, 10 * 1000);
    return () => {
      clearInterval(id);
    };
  }, []);
  function updateHeight() {
    const iframeEl = document.querySelector("#iframe-metrics");
    if (iframeEl) {
      const el = (iframeEl as any).contentWindow.document.body.querySelector("main#pageContent");
      if (el) {
        setHeight(el.getBoundingClientRect().height);
      }
    }
  }

  const { name, org, gvc } = ngParseLink(workload.selfLink, { useInputCtx: true });

  const qsObject: any = {
    from: "now-2h",
    to: "now",
    "var-gvc": gvc,
    "var-workload": name,
    "var-container": "$__all",
    "var-location": "$__all",
    "var-percentile": "90",
    "var-grouping": ["gvc", "workload"],
    kiosk: true,
    theme: Theme.theme,
    refresh: "5m",
    embed_ticket: embedTicket,
  };
  const queryString = qs.stringify(qsObject, { arrayFormat: "repeat", encode: true });
  const iframeSrc = `https://${org}.grafana.test.cpln.io/d/cpln-metrics-overview/metrics-overview-cloud?${queryString}`;

  React.useEffect(() => {
    fetchEmbedTicket();
  }, []);

  async function fetchEmbedTicket() {
    try {
      const { accessToken } = await getLocalToken();
      const { data } = await request({
        service: "self",
        method: "post",
        url: `https://${org}.grafana.test.cpln.io/auth/process`,
        body: { embed: true, idToken: accessToken },
      });
      setEmbedTicket(data.embedTicket.encoded);
    } catch (e) {
      setEmbedTicket("");
      setError(e.message);
      console.log("error", e);
    }
  }

  async function handleExploreOnGrafana(e: any) {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.submit();
    }
  }

  return (
    <div>
      <div className="flex justify-between mb-4">
        <div className="flex-grow" />
        <a href={""} onClick={handleExploreOnGrafana} className="ngfocus color-link">
          <span>Explore on Grafana</span>
          <ExternalLink className="feather-icon inline-block ml-1" style={{ transform: "translateY(1px)" }} />
        </a>
      </div>
      <form
        className="hidden"
        ref={formRef}
        action={`${Discovery.endpoints.grafana.replace("{org}", org!)}/auth/process`}
        method="POST"
        target="_blank"
      >
        <input name="redirect" value={getMetricsUrl("workload")} type="hidden" />
        <input name="idToken" value={logsTokenFromLocal} type="hidden" />
        <input name="genericToken" value={logsTokenFromLocal} type="hidden" />
        <button type="submit">explore</button>
      </form>
      {embedTicket === null ? (
        <Loader reason="Fetching embed ticket" />
      ) : embedTicket ? (
        <iframe id="iframe-metrics" src={iframeSrc} width={"100%"} height={height} />
      ) : (
        <NGAlert type={"error"} title="" message={error} />
      )}
    </div>
  );
};

export const Metrics = observer(MetricsRaw);
