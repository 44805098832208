import * as React from "react";
import { PromptContext } from "../mobxStores/prompt/prompt";

export function useSetPromptShouldBlock(
  fn?: (nextLocation: { pathname: string }, currentLocation: { pathname: string }) => boolean
) {
  React.useEffect(() => {
    if (fn) {
      PromptContext.setShouldBlock(fn);
    }
  }, []);
}
