import { observer } from "mobx-react-lite";
import * as React from "react";
import { CellMeasurerCache, ScrollParams } from "react-virtualized";
import { Audit } from "../../pages/auditTrail/types";
import { useNMainHeight } from "../../reactContexts/nMainHeightContext";
import { AuditGrid } from "./auditGrid";

interface Props {
  audits: Audit[];
  queried: boolean;
  emptySpaceValue: number;
  onScroll: (params: ScrollParams) => void;
  onDetail: (id: string) => void;
  diffSupport:
    | false
    | {
        selectedAudits: string[];
        setSelectedAudits: React.Dispatch<React.SetStateAction<string[]>>;
      };
}
const AuditGridWrapperRaw: React.FC<Props> = ({
  audits,
  queried,
  emptySpaceValue,
  onScroll,
  onDetail,
  diffSupport,
}) => {
  const { nMainHeight } = useNMainHeight();
  const emptySpaceHeight = Math.max(nMainHeight - emptySpaceValue, 400);

  const sizerRef = React.useRef<HTMLDivElement>(null as any);
  const [sizerDimensions, setSizerDimensions] = React.useState({ width: 0, height: 0 });
  const cacheRef = React.useRef<CellMeasurerCache>(
    new CellMeasurerCache({
      fixedHeight: true,
      minWidth: 100,
    })
  );

  React.useEffect(() => {
    const onResize = () => {
      setSizerDimensions({
        width: sizerRef.current.clientWidth,
        height: sizerRef.current.clientHeight,
      });
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  React.useEffect(() => {
    cacheRef.current.clearAll();
  }, [audits]);

  const auditsWithLabel: Audit[] = [
    { id: "label", eventTime: "", postedTime: "", receivedTime: "", resource: {} as any },
    ...audits,
  ];

  return (
    <div ref={sizerRef} className={"w-full"} style={{ height: emptySpaceHeight }}>
      {queried && audits.length > 0 ? (
        <AuditGrid
          cache={cacheRef.current}
          audits={auditsWithLabel}
          height={emptySpaceHeight}
          width={sizerDimensions.width}
          onScroll={onScroll}
          onDetail={onDetail}
          diffSupport={diffSupport}
        />
      ) : null}
    </div>
  );
};

export const AuditGridWrapper = observer(AuditGridWrapperRaw);
