import { TableColumn } from "../../types";
import { formatStrings } from "../../../../utils/time";
import { DateString } from "../../../../components/dateString";

const createdDefault = { id: "created", label: "Created" };
export function CreatedColumn<TData extends { created: string }>(column: TableColumn<TData> = createdDefault) {
  const preset: TableColumn<TData> = {
    ...createdDefault,
    size: 180,
    enableHide: true,
    hiddenByDefault: true,
    enableSort: true,
    sortingFn: "cplnDate",
    cell: ({ row }) => {
      const item = row.original;
      if (!item.created) {
        return <span />;
      }
      return <DateString iso={row.original.created} format={formatStrings.humanizeWAgo} />;
    },
  };
  return Object.assign(preset, column);
}
