import * as React from "react";
import { observer } from "mobx-react-lite";
import { VolumeSetDraftMobx } from "../../../mst/stores/volumeset.draft";
import { PerformanceClasses } from "../../../mst/kinds/volumeset";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGCheckbox } from "../../../newcomponents/checkbox";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { AutoScalingDoc } from "../autoScalingDoc";
import cronstrue from "cronstrue";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  draft: VolumeSetDraftMobx;
}
const SpecRaw: React.FC<Props> = ({ draft }) => {
  return (
    <>
      <NGFormElement
        as={"select"}
        name={"fileSystemType"}
        label={draft.fileSystemType.label}
        options={draft.fileSystemType.options}
        onChange={draft.fileSystemType.setValue}
        value={draft.fileSystemType.value}
      />
      <NGFormElement
        name={"initialCapacity"}
        label={draft.initialCapacity.label}
        required={draft.initialCapacity.isRequired}
        error={draft.initialCapacity.error}
        value={draft.initialCapacity.value}
        onChange={draft.setInitialCapacity}
      />
      {draft.fileSystemType.value === "shared" ? null : (
        <>
          <NGLabel className="mb-2">Performance Class</NGLabel>
          <div className="flex gap-4 mb-4">
            {PerformanceClasses.map((pc) => (
              <div
                onClick={() => draft.setPerformanceClass(pc.name)}
                key={pc.name}
                className="flex flex-col border p-4 cursor-pointer hover:shadow-lg"
              >
                <div className="flex items-center justify-between mb-2">
                  <NGCheckbox
                    checked={draft.performanceClass === pc.name}
                    onChange={(value) => {
                      if (value) {
                        draft.setPerformanceClass(pc.name);
                      }
                    }}
                  >
                    <NGLabelText>{pc.readableName}</NGLabelText>
                  </NGCheckbox>
                </div>
                <NGLabel>Min Capacity</NGLabel>
                <div className="mb-1">{pc.minCapacity || 1} GB</div>
                <NGLabel>Max Capacity</NGLabel>
                <div className="mb-1">{pc.maxCapacity} GB</div>
                <div className="flex-grow" />
              </div>
            ))}
          </div>
        </>
      )}
      <NGFormElement
        name={"storageClassSuffix"}
        label={draft.storageClassSuffix.label}
        onChange={draft.storageClassSuffix.setValue}
        value={draft.storageClassSuffix.value}
        error={draft.storageClassSuffix.error}
        info={[
          `For self-hosted locations only. The storage class used for volumes in this set will be {performanceClass}-{fileSystemType}-{storageClassSuffix} if it exists, otherwise it will be {performanceClass}-{fileSystemType}`,
        ]}
      />
      {draft.fileSystemType.value === "shared" ? null : (
        <div className="mb-4 p-4 border rounded min-w" style={{ minWidth: 450 }}>
          <NGLabel size={2} className="mb-4">
            Snapshots
          </NGLabel>
          <div className="flex gap-2">
            <NGFormElement
              name={"retentionDurationAmount"}
              style={{ width: 323 }}
              label={draft.snapshots.retentionDurationAmount.label}
              required={draft.snapshots.retentionDurationAmount.isRequired}
              error={draft.snapshots.retentionDurationAmount.error}
              value={draft.snapshots.retentionDurationAmount.value}
              onChange={draft.snapshots.retentionDurationAmount.setValue}
            />
            <NGFormElement
              style={{ width: 120 }}
              name={"retentionDurationUnit"}
              as={"select"}
              label={draft.snapshots.retentionDurationUnit.label}
              value={draft.snapshots.retentionDurationUnit.value}
              options={draft.snapshots.retentionDurationUnit.options}
              onChange={draft.snapshots.retentionDurationUnit.setValue}
            />
          </div>
          <NGFormElement
            name={"schedule"}
            label={draft.snapshots.schedule.label}
            required={draft.snapshots.schedule.isRequired}
            error={draft.snapshots.schedule.error}
            value={draft.snapshots.schedule.value}
            onChange={draft.snapshots.schedule.setValue}
            placeholder={"0 0 1 * *"}
          />
          {!draft.snapshots.schedule.value ? (
            <NGAlert type={"info"} message="Snapshots will only be taken if a schedule is set." />
          ) : null}
          {draft.snapshots.schedule.value.length > 0 && draft.snapshots.schedule.isValid ? (
            <div>
              {cronstrue.toString(draft.snapshots.schedule.value, {
                throwExceptionOnParseError: false,
                use24HourTimeFormat: true,
              })}
            </div>
          ) : null}
        </div>
      )}
      <div className="p-4 border rounded min-w" style={{ minWidth: 450 }}>
        <NGLabel size={2} className="mb-4">
          Autoscaling
        </NGLabel>
        {!draft._hasAutoscaling ? (
          <NGSwitch
            className="mb-2"
            data-testid="switch-override-autoscaling"
            onChange={(value) => draft.setOverrideAutoscaling(value)}
            value={draft.overrideAutoscaling}
          >
            <NGLabelText>Enable Autoscaling</NGLabelText>
          </NGSwitch>
        ) : null}

        {draft._hasAutoscaling || (!draft._hasAutoscaling && draft.overrideAutoscaling) ? (
          <>
            <NGFormElement
              name={"maxCapacity"}
              label={draft.autoscaling.maxCapacity.label}
              value={draft.autoscaling.maxCapacity.value}
              onChange={draft.autoscaling.maxCapacity.setValue}
            />
            <NGFormElement
              name={"minFreePercentage"}
              label={draft.autoscaling.minFreePercentage.label}
              value={draft.autoscaling.minFreePercentage.value}
              onChange={draft.autoscaling.minFreePercentage.setValue}
            />
            <NGFormElement
              name={"scalingFactor"}
              label={draft.autoscaling.scalingFactor.label}
              value={draft.autoscaling.scalingFactor.value}
              onChange={draft.autoscaling.scalingFactor.setValue}
            />
          </>
        ) : null}
      </div>
    </>
  );
};
export const Spec = observer(SpecRaw);
