import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "./../../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { DryRunAlert } from "./../../../dryRunAlert";
import { MoreHorizontal, PlusCircle } from "react-feather";
import { NGKindSelect } from "./../../../../../newcomponents/select/ngkindselect";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { NGFormLabel } from "../../../../../newcomponents/text/formLabel";
import NGAlert from "../../../../../newcomponents/alert";
import { NGCheckbox } from "../../../../../newcomponents/checkbox";
import { NGLabelText } from "../../../../../newcomponents/text/labelText";
import { getDryRunErrorText } from "../../../getDryRunErrorText";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGProviderCreateOpaqueSecret } from "../../../ngProviderCreateOpaqueSecret";
import { homeLink, request } from "../../../../../services/cpln";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { NGLabel } from "../../../../../newcomponents/text/label";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderTritonRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const t = mk8sDraft.provider_triton!;
  const formData = useNGFormContext();

  const [browserKey, setBrowserKey] = React.useState("");

  React.useEffect(() => {
    fetchSchemaData();
  }, []);

  async function fetchSchemaData() {
    // Locations
    try {
      const { data: schemaRes } = await request({ url: "/catalog/schema/mk8s" });
      const locations: string[] = schemaRes.keys.spec.keys.provider.keys.triton.keys.location.allow;
      t.location.setOptions(locations.map((v) => ({ label: v, value: v })));
    } catch (e) {
      console.error("Failed to get locations from mk8s catalog schema");
    }
  }

  const sshKeysDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: "spec.provider.triton.sshKeys",
    paths: t.sshKeys.items.map((_, idx) => `spec.provider.triton.sshKeys[${idx}]`),
  });

  return (
    <>
      <div>
        <div className="p-4 mb-4 border rounded w-min">
          <NGLabel className="mb-4" size={2}>
            Connection
          </NGLabel>
          <div className="mb-4">
            <NGFormLabel required invalid={!t.connection.tokenSecretName}>
              Triton SSH Private Key Secret
            </NGFormLabel>
            <div className="mb-2 flex items-start gap-2">
              <NGKindSelect
                name={"triton.tokenSecretName"}
                style={{ width: 450 }}
                value={t.connection.tokenSecretName}
                invalid={!t.connection.tokenSecretName}
                onChange={(value) => t.connection.setTokenSecretName(value)}
                kind={"secret"}
                queries={[{ property: "type", value: "opaque" }]}
                buttons={[
                  {
                    title: "Create",
                    render: () => <PlusCircle className="h-4" />,
                    onClick: () => setBrowserKey("createSecret"),
                  },
                ]}
              />
              <DryRunAlert
                key={mk8sDryRun.key}
                canShow={formData.get("triton.tokenSecretName").touched}
                dryRunResponse={mk8sDryRun.response}
                path={"spec.provider.triton.tokenSecretLink"}
              />
            </div>
            <NGAlert
              style={{ width: 450 }}
              render={() => (
                <div className="flex flex-col text-sm">
                  <span>An opaque secret that contains your Triton SSH Private Key.</span>
                  <span>It will be used to manage servers in your Triton account.</span>
                </div>
              )}
            />
          </div>
          <div className="flex gap-2 items-start">
            <NGFormElement
              name={"triton.connection.url"}
              label={t.connection.url.label}
              value={t.connection.url.value}
              onChange={t.connection.url.setValue}
              required={t.connection.url.isRequired}
              error={t.connection.url.error}
              info={["CloudAPI endpoint"]}
            />
            <div className="mt-6">
              <DryRunAlert
                key={mk8sDryRun.key}
                canShow={formData.get("triton.connection.url").touched}
                dryRunResponse={mk8sDryRun.response}
                onFix={t.connection.url.setValue}
                path={"spec.provider.triton.connection.url"}
              />
            </div>
          </div>

          <div className="flex gap-2 items-start">
            <NGFormElement
              name={"triton.connection.account"}
              label={t.connection.account.label}
              value={t.connection.account.value}
              onChange={t.connection.account.setValue}
              required={t.connection.account.isRequired}
              error={t.connection.account.error}
              info={[`Account Owner's username`]}
            />
            <div className="mt-6">
              <DryRunAlert
                key={mk8sDryRun.key}
                canShow={formData.get("triton.connection.account").touched}
                dryRunResponse={mk8sDryRun.response}
                onFix={t.connection.account.setValue}
                path={"spec.provider.triton.connection.account"}
              />
            </div>
          </div>

          <div className="flex gap-2 items-start">
            <NGFormElement
              name={"triton.connection.user"}
              label={t.connection.user.label}
              value={t.connection.user.value}
              onChange={t.connection.user.setValue}
              required={t.connection.user.isRequired}
              error={t.connection.user.error}
              info={[`When you want to connect with a subuser, type subuser username here`]}
            />
            <div className="mt-6">
              <DryRunAlert
                key={mk8sDryRun.key}
                canShow={formData.get("triton.connection.user").touched}
                dryRunResponse={mk8sDryRun.response}
                onFix={t.connection.user.setValue}
                path={"spec.provider.triton.connection.user"}
              />
            </div>
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"triton.location"}
            label={t.location.label}
            value={t.location.value}
            onChange={t.location.setValue}
            required={true}
            as={"select"}
            options={t.location.options}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("triton.location").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={t.location.setValue}
              path={"spec.provider.triton.location"}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"triton.privateNetworkId"}
            label={t.privateNetworkId.label}
            value={t.privateNetworkId.value}
            onChange={t.privateNetworkId.setValue}
            required={t.privateNetworkId.isRequired}
            error={t.privateNetworkId.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("private-networks");
                  formData.set("triton.privateNetworkId", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("triton.privateNetworkId").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={t.privateNetworkId.setValue}
              path={"spec.provider.triton.privateNetworkId"}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGCheckbox className="mb-4" onChange={(value) => t.setFirewallEnabled(value)} checked={t.firewallEnabled}>
            <NGLabelText>Enable Firewall</NGLabelText>
          </NGCheckbox>
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("triton.firewallEnabled").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={(v) => t.setFirewallEnabled(typeof v === "boolean" ? v : v === "true" ? true : false)}
              path={"spec.provider.triton.firewallEnabled"}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"triton.imageId"}
            label={t.imageId.label}
            value={t.imageId.value}
            onChange={t.imageId.setValue}
            required={t.imageId.isRequired}
            error={t.imageId.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => {
                  setBrowserKey("images");
                  formData.set("triton.imageId", { touched: true });
                },
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get("triton.imageId").touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={t.imageId.setValue}
              path={"spec.provider.triton.imageId"}
            />
          </div>
        </div>

        <NGInputListMst
          data={t.sshKeys}
          styles={{ container: { width: 450 } }}
          firstInput={(i, index) => {
            let invalid = false;
            if (sshKeysDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }
            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          label="SSH Keys"
          invalid={sshKeysDryRun.severity === "error"}
          error={sshKeysDryRun.severity === "error" ? sshKeysDryRun.message : ""}
          warning={sshKeysDryRun.severity === "error" ? "" : sshKeysDryRun.message}
        />
      </div>
      {browserKey === "createSecret" ? (
        <NGProviderCreateOpaqueSecret
          onOk={(secretName: string) => t.connection.setTokenSecretName(secretName)}
          onClose={() => setBrowserKey("")}
          title={"Create Triton SSH Private Key Secret"}
          dataLabel={"Triton SSH Private Key"}
        />
      ) : null}
      {browserKey === "private-networks" ? (
        <NGProviderBrowser
          title="Browse Triton Private Networks"
          initialValue={t.privateNetworkId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "private-networks",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => {
            t.privateNetworkId.setValue(value as string);
            formData.set("triton.privateNetworkId", { touched: true });
          }}
          onClose={() => setBrowserKey("")}
          sortable
          labels={["Name", "Id", "Description", "Subnet", "VLAN Id"]}
          getData={(item: any) => [item.id, item.name, item.id, item.description, item.subnet, item.vlan_id]}
        />
      ) : null}
      {browserKey === "images" ? (
        <NGProviderBrowser
          title="Browse Triton Images"
          initialValue={t.imageId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "images",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => {
            t.imageId.setValue(value as string);
            formData.set("triton.imageId", { touched: true });
          }}
          onClose={() => setBrowserKey("")}
          sortable
          distinctFilters={["Type", "Brand"]}
          filteredColumns={["Name"]}
          labels={["Name", "Id", "Size", "Type", "Brand", "Version"]}
          getData={(item: any) => [
            item.id,
            item.name,
            item.id,
            [item.image_size || 0, item.image_size ? `${item.image_size / 1024}Gi` : "-"],
            item.type,
            item.requirements?.brand,
            item.version,
          ]}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderTriton = observer(Mk8sCreateProviderTritonRaw);
