import { linksOf } from "../../../services/cpln";
import { gvcScopedKinds } from "../../../utils/kinds";
import { ngParseLink } from "../../../utils/linkParser/linkParser";
import { InternalLink } from "../components/InternalLink";
import { TableColumn } from "../types";

const nameDescriptionDefault = { id: "name", label: "Name" };
export function ReleaseNameDescriptionColumn<TData extends { name: string; description: string; kind: string }>(
  column: Partial<TableColumn<TData>> = nameDescriptionDefault,
  context: { org: string; gvc: string },
) {
  const preset: TableColumn<TData> = {
    ...nameDescriptionDefault,
    enableResize: true,
    enableSort: true,
    size: 200,
    cell: (p) => {
      const item = p.row.original;
      let selfLink = linksOf(item as any).self || "";
      const { org: itemOrg, gvc: itemGvc, kind: itemKind } = ngParseLink(selfLink, { useInputCtx: true });

      const isSame = item.name === item.description;

      let canLink = itemOrg === context.org;
      if (gvcScopedKinds.includes(itemKind) && canLink) {
        canLink = itemGvc === context.gvc;
      }

      return (
        <div className="flex flex-col justify-center" style={{ height: 56 }}>
          {canLink ? <InternalLink label={item.name} to={`/console${selfLink}`} /> : <span>{item.name}</span>}
          {isSame ? null : <span className="text-sm description">{item.description}</span>}
        </div>
      );
    },
  };
  return Object.assign(preset, column);
}
