import React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { Copy, Download } from "react-feather";
import { Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { CodeEditor } from "../../pages/group/identityMatcher/codeEditor";
import { NGButton } from "../../newcomponents/button/Button";

interface Props {
  title: string;
  initialValue: string;
  open: boolean;
  onOk: (value: string) => void;
  onClose: () => void;
}

const CodeEditorModalRaw: React.FC<Props> = ({ title, initialValue, open, onOk, onClose }) => {
  // States //
  const [isCopied, setIsCopied] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>(initialValue);
  const [file, setFile] = React.useState<RcFile>(null as any);

  // Effects //
  React.useEffect(() => {
    let intervalId: any;
    if (isCopied) {
      intervalId = setInterval(() => {
        setIsCopied(false);
      }, 1500);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isCopied]);

  React.useEffect(() => {
    if (file) {
      try {
        file.text().then((text) => {
          setValue(text);
        });
      } catch (error) {
        notification.error({
          message: "ERROR!",
          description: "We were unable to load the imported file, please try again.",
        });
      }
    }
  }, [file]);

  function handleCopyText() {
    setIsCopied(true);
    navigator.clipboard.writeText(value);
  }

  function handleOnChange(value: string) {
    setValue(value);
  }

  return (
    <Modal
      className="codeEditorModal"
      width={"70%"}
      title={
        <div className="flex items-center justify-between">
          <div className="mr-4 text-lg">{title}</div>
          <div className="flex items-center h-6">
            <div className="flex gap-x-2">
              <NGButton
                iconPosition="left"
                renderIcon={(_, props) => <Copy {...props} />}
                size={"small"}
                variant="secondary"
                onClick={handleCopyText}
              >
                {isCopied ? "Copied!" : "Copy"}
              </NGButton>
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  setFile(file);
                  return false;
                }}
              >
                <NGButton
                  iconPosition="left"
                  renderIcon={(_, props) => <Download {...props} />}
                  size={"small"}
                  variant="primary"
                  className="items-center"
                >
                  Import
                </NGButton>
              </Upload>
            </div>
          </div>
        </div>
      }
      open={open}
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={onClose}>
            Cancel
          </NGButton>
          <NGButton variant="primary" onClick={() => onOk(value)}>
            Set
          </NGButton>
        </div>
      }
      destroyOnClose={true}
    >
      <CodeEditor
        delay
        value={value}
        setValue={handleOnChange}
        options={{ basicSetup: { lineNumbers: true, autocompletion: false } }}
        language={"json"}
      />
    </Modal>
  );
};

export const CodeEditorModal = observer(CodeEditorModalRaw);
