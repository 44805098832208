import * as React from "react";
import { ExternalLink } from "react-feather";
import { Link } from "react-router-dom";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import NGAlert from "../../../newcomponents/alert";
import { DOCS_URL } from "../../../envVariables";

interface CreateOrgProps {
  text: string;
}
const CreateOrg: React.FC<CreateOrgProps> = ({ text }) => {
  return (
    <Link className="color-link" to={`/billing/org/-create`}>
      {text}
    </Link>
  );
};

interface InviteUsersProps {
  text: string;
}
const InviteUsers: React.FC<InviteUsersProps> = ({ text }) => {
  const { AccountUUID } = BillingContext;
  return (
    <Link className="color-link" to={`/billing/account/${AccountUUID}/users`}>
      {text}
    </Link>
  );
};

export const SetupCreateOrg: React.FC = () => {
  const { AccountIsDisabled, Roles } = BillingContext;

  // admin
  // org and user

  // viewer
  // return null

  // org creator
  // creating an org

  if (!Roles.includes("org_creator") && !Roles.includes("billing_admin")) {
    return null;
  }

  return (
    <div className="mb-10">
      {AccountIsDisabled ? (
        <NGAlert
          className="mb-2"
          render={() => (
            <div>
              Your account is not activated yet. Please wait until customer support enables your account, or contact
              support at{" "}
              <a className="color-link" href={"mailto:support@controlplane.com"}>
                support@controlplane.com
              </a>{" "}
              if you need further help.
            </div>
          )}
        />
      ) : null}
      <div className="text-2xl mb-2">
        <span>Start by </span>
        <CreateOrg text={"creating an org"} />
        {Roles.includes("billing_admin") ? (
          <>
            <span> or </span>
            <InviteUsers text={"inviting users"} />
            <span>
              {" "}
              who can create{" "}
              <a href={`${DOCS_URL}/concepts/org`} target={"_blank"} className={`ngfocus color-link`}>
                org(s) <ExternalLink className="feather-icon inline-block" />
              </a>
            </span>
          </>
        ) : null}
        <span>.</span>
      </div>
    </div>
  );
};
