import * as React from "react";
import { observer } from "mobx-react-lite";

interface Props {
  dnsMode: "cname" | "ns";
  routingMode: "subdomainBased" | "pathBased" | "none";
}
const CustomCardRaw: React.FC<Props> = ({ dnsMode, routingMode }) => {
  return (
    <div className={`rounded flex-1`}>
      <div>
        {dnsMode === "cname" ? "CNAME" : "NS"}
        {routingMode === "none"
          ? " & No Routing"
          : routingMode === "pathBased"
          ? " & Path Routing"
          : " & Subdomain Routing"}
      </div>
    </div>
  );
};

export const CustomCard = observer(CustomCardRaw);
