import { observer } from "mobx-react-lite";
import * as React from "react";
import { StringMobx } from "../../mobxDataModels/stringModel";
import { NGLabel } from "../text/label";
import { NGInput } from "./input";
import { InfoTooltip } from "../../components/InfoTooltip";

const CheckMark = () => (
  <span className="formmark text-xs" style={{ color: "transparent", textShadow: "0 0 0 var(--color-action)" }}>
    &#10004;
  </span>
);
const RequiredMark = () => <span className="formmark color-danger">*</span>;

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  data: StringMobx;
  description?: string;
  setValueOverride?: (value: string) => void;
  style?: React.CSSProperties | undefined;
  className?: string;
  infoContents?: string[];
  hideLabel?: boolean;
  disabled?: boolean;
  onClear?: () => void;
}
const NGInputAdapterRaw: React.FC<Props> = ({
  data,
  description,
  style = {},
  setValueOverride,
  className: _className = "",
  infoContents = [],
  hideLabel = false,
  disabled,
  onClear,
  ...inputProps
}) => {
  React.useEffect(() => {
    if (!onClear) {
      return;
    }
    if (data.value.length < 1) {
      onClear();
    }
  }, [data.value]);

  let className = _className
    .split(" ")
    .filter((c) => {
      if (c.startsWith("p-")) return false;
      if (c.startsWith("px-")) return false;
      if (c.startsWith("py-")) return false;
      if (c.startsWith("pl-")) return false;
      if (c.startsWith("pr-")) return false;
      if (c.startsWith("pt-")) return false;
      if (c.startsWith("pb-")) return false;
      if (c.startsWith("m-")) return false;
      if (c.startsWith("mx-")) return false;
      if (c.startsWith("my-")) return false;
      if (c.startsWith("ml-")) return false;
      if (c.startsWith("mr-")) return false;
      if (c.startsWith("mt-")) return false;
      // if (c.startsWith("mb-")) return false;
      return true;
    })
    .join(" ");

  return (
    <>
      {hideLabel ? null : (
        <>
          <NGLabel>
            {data.label} {data.isRequired ? data.isValid ? <CheckMark /> : <RequiredMark /> : null}
          </NGLabel>
          {description ? <div>{description}</div> : null}
        </>
      )}
      <div className={`flex items-center ${className}`} style={{ width: "100%" }}>
        <NGInput
          {...inputProps}
          disabled={disabled}
          value={data.value}
          onChange={(e) => {
            const newValue = e.target.value;
            if (setValueOverride) {
              setValueOverride(newValue);
            } else {
              data.setValue(newValue);
            }
          }}
          style={style}
          placeholder={hideLabel ? data.label : data.examples.length > 0 ? data.examples.join(", ") : undefined}
        />
        {infoContents.length > 0 ? <InfoTooltip title={infoContents} /> : null}
      </div>
    </>
  );
};

export const NGInputAdapter = observer(NGInputAdapterRaw);
