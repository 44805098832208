import * as React from "react";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { SelectMobx } from "../../../mobxDataModels/selectModel";
import { pluralNameOfKind } from "../../../services/cpln";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { observer } from "mobx-react-lite";

interface Props {
  name: StringMobx;
  description: StringMobx;
  targetKind: SelectMobx;
  isAll: boolean;
  setIsAll: (value: boolean) => void;
}

const PolicyCreateGeneralRaw: React.FC<Props> = ({ name, description, targetKind, isAll, setIsAll }) => {
  const { org } = ConsoleContext;
  return (
    <>
      <NGInputAdapter style={{ width: 450 }} className="mb-4" data={name} />
      <NGInputAdapter style={{ width: 450 }} className="mb-4" data={description} />
      <NGLabel>{targetKind.label}</NGLabel>
      <NGSelect
        className="mb-4"
        style={{ width: 450 }}
        onChange={targetKind.setValue}
        options={targetKind.options}
        value={targetKind.value}
      />
      <div className="flex items-center">
        {targetKind.value === "org" ? (
          <span>
            Targets the org item named <span className=" ">{org}</span> only.
          </span>
        ) : targetKind.value === "task" ? (
          <span>Targets all the tasks of the current org.</span>
        ) : (
          <>
            <div className="flex items-center">
              <NGSwitch value={isAll} onChange={setIsAll}>
                <NGLabelText>Target All {pluralNameOfKind(targetKind.value)}</NGLabelText>
              </NGSwitch>
            </div>
            <InfoTooltip title={`Applies this policy to all items of ${targetKind.value} kind`} />
          </>
        )}
      </div>
    </>
  );
};

export const PolicyCreateGeneral = observer(PolicyCreateGeneralRaw);
