import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { HelmReleasesList } from "./list";
import { HelmReleaseDetail } from "./detail";

const HelmReleasesRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<HelmReleasesList />} />
        <Route path={`:release/*`} element={<HelmReleaseDetail />} />
      </Routes>
    </Layout>
  );
};

export default observer(HelmReleasesRoute);
