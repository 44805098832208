import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { DryRunAlert } from "../dryRunAlert";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGInput } from "../../../newcomponents/input/input";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { useNGFormContext } from "../../../reactContexts/ngFormContext";
import { NGError } from "../../../newcomponents/text/error";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateGeneralRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const form = useNGFormContext();

  return (
    <>
      <NGFormLabel required name="name" invalid={!mk8sDraft.name.value}>
        {mk8sDraft.name.label}
      </NGFormLabel>
      <div className="mb-4">
        <NGInput
          name="name"
          invalid={!mk8sDraft.name.value}
          placeholder="my-mk8s, ab1"
          style={{ width: 450 }}
          value={mk8sDraft.name.value}
          onChange={(e) => mk8sDraft.name.setValue(e.target.value)}
        />
        {!mk8sDraft.name.value && form.get("name").touched ? (
          <NGError className="mt-2">Name is required</NGError>
        ) : null}
      </div>
      <NGLabel>{mk8sDraft.description.label}</NGLabel>
      <NGInput
        className="mb-4"
        style={{ width: 450 }}
        value={mk8sDraft.description.value}
        onChange={(e) => mk8sDraft.description.setValue(e.target.value)}
      />
      <div className="mb-4 flex gap-2">
        <div>
          <NGLabel>{mk8sDraft.version.label}</NGLabel>
          <NGSelect
            onChange={mk8sDraft.version.setValue}
            value={mk8sDraft.version.value}
            options={mk8sDraft.version.options}
            style={{ minWidth: 450 }}
          />
        </div>
        <div className="mt-6">
          <DryRunAlert
            canShow={mk8sDraft.version.isTouched}
            dryRunResponse={mk8sDryRun.response}
            path={"spec.version"}
            onFix={mk8sDraft.version.setValue}
          />
        </div>
      </div>
    </>
  );
};

export const Mk8sCreateGeneral = observer(Mk8sCreateGeneralRaw);
