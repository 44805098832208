import { Modal } from "antd";
import * as React from "react";
import { NGButton } from "../../newcomponents/button/Button";

interface Props extends React.PropsWithChildren {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
}
export const TableModal: React.FC<Props> = ({ open, onCancel, onOk, children }) => {
  if (!open) {
    return null;
  }
  return (
    <Modal
      width={"90vw"}
      styles={{ body: { height: "70vh", padding: 0 } }}
      open={open}
      onCancel={onCancel}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={onCancel}>
            Cancel
          </NGButton>
          <NGButton variant="primary" onClick={onOk}>
            OK
          </NGButton>
        </div>
      }
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
    >
      <div className="h-full overflow-auto p-1">{children}</div>
    </Modal>
  );
};
