import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useNavigate } from "react-router-dom";
import { BillingContext } from "../../mobxStores/billingContext/billingContext";
import NGAlert from "../../newcomponents/alert";
import { NGButton } from "../../newcomponents/button/Button";

const TryCreateOrgRaw: React.FC = () => {
  const navigate = useNavigate();
  const { PaymentMethods, Roles } = BillingContext;
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [canCreate, setCanCreate] = React.useState(false);
  const [reason, setReason] = React.useState("");

  React.useEffect(() => {
    check();
  }, []);

  async function check() {
    if (!Roles.includes("billing_admin") && !Roles.includes("org_creator")) {
      setReason("Insufficient permissions for current user.");
      setCanCreate(false);
    }
    // TODO implement this with the right user roles
    /* else if (!isTouAccepted) {
      setReason("Terms of use not accepted.");
      setCanCreate(false);
    } */
    else if (PaymentMethods.length < 1) {
      setReason("No payment method found.");
      setCanCreate(false);
    } else {
      setCanCreate(true);
    }
    setTimeout(() => {
      setIsPageReady(true);
    }, 50);
  }

  if (!isPageReady) {
    return null;
  }

  if (!canCreate) {
    return (
      <div className="flex flex-col gap-4" style={{ width: 500 }}>
        <NGAlert message={`This account is not eligible to create a new org. ${reason}`} />
        <NGButton className="self-end" style={{ width: 250 }} variant={"primary"} onClick={() => navigate(`/billing`)}>
          Continue to Account Page
        </NGButton>
      </div>
    );
  }

  return <Navigate to={`/billing/org/-create`} />;
};

export const TryCreateOrg = observer(TryCreateOrgRaw);
