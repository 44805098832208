import * as React from "react";
import { Table } from "../../../../newcomponents/table/table";
import { useTableKindId } from "../../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../../newcomponents/table/data/useTableItemQueryData";
import { TableHeaderRefreshButton } from "../../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../../newcomponents/table/components/TableCplnQuery";
import { NameDescriptionNoLinkColumn } from "../../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { TagsColumn } from "../../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { linksOf } from "../../../../services/cpln";
import { Location } from "../../../../schema/types/location";
import { ProviderColumn } from "../../../../newcomponents/table/columns/wellKnown/providerColumn";

interface LocationTableItem extends Location {
  selfLink: string;
}

interface Props {
  title: string;
  selections: string[];
  onSelectionsChange: (values: string[]) => void;
}
export const LocationTable: React.FC<Props> = ({ title, selections, onSelectionsChange }) => {
  const { kind, id } = useTableKindId("location", "policy-create-target-list-location");
  const qData = useTableItemQueryData<Location>(kind, { fetchAllPages: true });

  const locationTableItems = qData.visibleItems.map((location) => {
    const selfLink = linksOf(location).self!;

    return {
      ...location,
      selfLink: selfLink,
    };
  });

  return (
    <Table<LocationTableItem>
      title={title}
      data={locationTableItems}
      tableId={id}
      selectMode="multi"
      selectKey={"selfLink"}
      selections={selections}
      onSelectionsChange={onSelectionsChange}
      headerRenderer={() => {
        return (
          <>
            <TableHeaderRefreshButton onClick={qData.fetchItems} />
            <CustomTableCPLNQuery
              tableId={id}
              kind={kind}
              onQuery={qData.setQuery}
              isLoading={qData.isLoadingQueried}
            />
          </>
        );
      }}
      enableFilter
      isLoading={qData.isLoading}
      columns={[
        NameDescriptionNoLinkColumn(), //
        ProviderColumn(),
        TagsColumn(),
      ]}
    />
  );
};
