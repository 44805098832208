import * as React from "react";
import { observer } from "mobx-react-lite";
import { useDetailContext } from "../../../components/detail/detailContext";
import { DomainMobx } from "../../../mst/kinds/domain";
import { DomainEditNew } from "./edit_new";
import { DomainDraftStoreModel } from "../../../mst/stores/domain.draft.store";

const DomainEditNewRouteRaw: React.FC = () => {
  const { item } = useDetailContext();
  const domain = item as DomainMobx;
  const draftStoreRef = React.useRef(DomainDraftStoreModel.create());

  React.useEffect(() => {
    draftStoreRef.current.start(domain);
  }, []);

  if (!draftStoreRef.current.edit) return null;

  return <DomainEditNew draft={draftStoreRef.current.edit} />;
};

export const DomainEditNewRoute = observer(DomainEditNewRouteRaw);
