import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { homeLink, request } from "../../../services/cpln";
import { ChevronDown, ChevronUp, Copy, ExternalLink } from "react-feather";
import { DOCS_URL } from "../../../envVariables";

interface Props {
  name: string;
  projectId: string;
}
const CloudaccountGcpConfigurationRaw: React.FC<Props> = ({ projectId }) => {
  const [continueWith, setContinueWith] = React.useState("none");
  const [instructions, setInstructions] = React.useState<any>(null);

  React.useEffect(() => {
    request({ url: `${homeLink("cloudaccount")}/-instructions/gcp` }).then((res: any) => setInstructions(res.data));
  }, []);

  async function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  function onConsole() {
    if (continueWith === "console") {
      setContinueWith("none");
    } else {
      setContinueWith("console");
    }
  }

  function onCPLN() {
    if (continueWith === "cpln") {
      setContinueWith("none");
    } else {
      setContinueWith("cpln");
    }
  }

  if (!instructions || !instructions?.data) return null;

  return (
    <>
      <div className="my-4">
        <button className="color-link focus font-medium" onClick={onConsole}>
          Configure the cloud account using the GCP Console
          {continueWith === "console" ? (
            <ChevronUp className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          ) : (
            <ChevronDown className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          )}
        </button>
      </div>
      {continueWith === "console" ? (
        <ul className="pl-8">
          <li className="list-decimal my-2 ml-4">
            Sign into the{" "}
            <a
              className="underline hover:underline focus focus-visible:underline color-link"
              href={"https://console.cloud.google.com"}
              target={"_blank"}
            >
              GCP Console
              <ExternalLink className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
            </a>
            .
          </li>
          <li className="list-decimal my-2 ml-4">
            Navigate to the{" "}
            <a
              className="underline hover:underline focus focus-visible:underline color-link"
              href={`https://console.cloud.google.com/iam-admin/iam`}
              target={"_blank"}
            >
              IAM Console
              <ExternalLink className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
            </a>
            .
          </li>
          <li className="list-decimal my-2 ml-4">
            Click <span className="font-semibold">Grant Policy</span> to add a principal.
          </li>
          <li className="list-decimal my-2 ml-4">
            Input <span className="font-semibold">{instructions.data.bridgeServiceAccount}</span>
            <button
              onClick={() => copyToClipboard(instructions.data.bridgeServiceAccount)}
              className={`ml-1 mr-2 ngfocus color-link-hover`}
            >
              <Copy className="feather-icon" style={{ transform: "translateY(2px)" }} />
            </button>
            under <span className="font-semibold">New principals</span>.
          </li>
          <li className="list-decimal my-2 ml-4">Assign the roles:</li>
          <ul className="ml-8">
            {instructions.data.roleNames.map((role: any) => (
              <li className="list-disc my-2 ml-4">
                <span className="font-semibold">{role}</span>{" "}
                <button className={`ml-1 ngfocus color-link-hover`} onClick={() => copyToClipboard(role)}>
                  <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                </button>
              </li>
            ))}
          </ul>
          <li className="list-decimal my-2 ml-4">
            Additional roles will need to be granted based on the GCP resources being accessed. Please review the{" "}
            <a
              className="underline hover:underline ngfocus color-link"
              href={`${DOCS_URL}/reference/cloudaccount#gcp-details`}
              target={"_blank"}
            >
              GCP Cloud Account Reference Page
              <ExternalLink className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
            </a>{" "}
            for additional details
          </li>
          <li className="list-decimal my-2 ml-4">
            Click <span className="font-semibold">Save</span>
          </li>
        </ul>
      ) : null}
      <div className="my-4">
        <button className="ngfocus color-link font-medium" onClick={onCPLN}>
          OPTIONAL: Configure the cloud account using the gcloud cli
          {continueWith === "cpln" ? (
            <ChevronUp className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          ) : (
            <ChevronDown className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
          )}
        </button>
      </div>

      {continueWith === "cpln" ? (
        instructions.data.cli ? (
          <ul className="pl-8">
            <li className="list-decimal my-2 ml-4">Install the Control Plane CLI.</li>
            <div className="flex items-center my-2" style={{ width: 450 }}>
              <input
                className="flex-grow px-4 py-2 border"
                style={{
                  width: 450,
                }}
                disabled={true}
                value={"npm i -g @controlplane/cli"}
              />
              <button
                onClick={() => copyToClipboard("npm i -g @controlplane/cli")}
                className={`ml-2 ngfocus color-link-hover`}
              >
                <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
              </button>
            </div>
            <li className="list-decimal my-2 ml-4     ">
              Install the GCP CLI.
              <a
                target={"_blank"}
                href="https://cloud.google.com/sdk/docs/install"
                className="underline hover:underline focus focus-visible:underline color-link ml-2"
              >
                Get more information
                <ExternalLink
                  className="ml-1 cursor-pointer feather-icon inline-block"
                  style={{ transform: "translateY(2px)" }}
                />
              </a>
            </li>
            {instructions.data.cli.map((step: string, index: number) => {
              if (index === 0) {
                return null;
              }

              step = step.replace("$PROJECT_ID", projectId);

              return (
                <div key={index}>
                  {index === 1 ? (
                    <li className="list-decimal my-2 ml-4">
                      Add the following policy bindings using the <span className="font-semibold">GCP CLI</span>.
                    </li>
                  ) : null}
                  <div className="flex items-center my-2" key={step}>
                    <input
                      className="flex-grow border px-4 py-2"
                      style={{
                        width: 450,
                      }}
                      disabled={true}
                      value={step}
                    />
                    <button onClick={() => copyToClipboard(step)} className={`ml-2 ngfocus color-link-hover`}>
                      <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
                    </button>
                  </div>
                </div>
              );
            })}

            <li className="list-decimal my-2 ml-4">
              Additional roles will need to be granted based on the GCP resources being accessed. Please review the
              <a
                target={"_blank"}
                href={`${DOCS_URL}/reference/cloudaccount#gcp-details`}
                className="underline hover:underline focus focus-visible:underline color-link ml-2"
              >
                GCP Cloud Account Reference Page
                <ExternalLink
                  className="ml-1 cursor-pointer feather-icon inline-block"
                  style={{ transform: "translateY(2px)" }}
                />
              </a>{" "}
              for additional details. Use the same gcloud command as the step above to add the applicable roles.
            </li>
            <li className="list-decimal my-2 ml-4">You're done.</li>
          </ul>
        ) : null
      ) : null}
    </>
  );
};

export const CloudaccountGcpConfiguration = observer(CloudaccountGcpConfigurationRaw);
