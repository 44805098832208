import * as React from "react";
import { PolicyMobx } from "../../../mst/kinds/policy";
import { observer } from "mobx-react-lite";
import { BindingsOverviewTable } from "./bindingsOverviewTable";
import { nameOfKind } from "../../../services/cpln";
import { useNavigate } from "react-router-dom";
import { Modal, notification } from "antd";
import { useDetailContext } from "../../../components/detail/detailContext";
import { NGButton } from "../../../newcomponents/button/Button";

interface Props {
  policy: PolicyMobx;
}
const PolicyBindingsOverviewRaw: React.FC<Props> = ({ policy }) => {
  const { fetchItem } = useDetailContext();

  const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
  const [selectedBindingIndex, setSelectedBindingIndex] = React.useState<number>(-1);

  const navigate = useNavigate();

  function onDeleteBinding(index: number) {
    setSelectedBindingIndex(index);
  }

  async function confirmBindingDeletion(): Promise<void> {
    try {
      setIsLoadingDelete(true);
      let newBindings: any[] = [];
      for (let bindingIndex = 0; bindingIndex < policy.bindings.length; bindingIndex++) {
        if (selectedBindingIndex === bindingIndex) {
          continue;
        }
        newBindings.push(policy.bindings[bindingIndex].asObject);
      }
      await policy.patch({ bindings: newBindings });
      notification.success({
        message: "Success",
        description: "Deleted binding",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    } finally {
      setSelectedBindingIndex(-1);
      setIsLoadingDelete(false);
    }
  }

  function cancelBindingDeletion(): void {
    setSelectedBindingIndex(-1);
    setIsLoadingDelete(false);
  }

  return (
    <>
      <div>
        <div className={`mb-8 flex items-center justify-between p-8 border rounded`}>
          <div>
            A binding gives specific {nameOfKind(policy.targetKind)} permissions to a selection of users, service
            accounts, groups, and identities, for the items this policy targets.
          </div>
          {policy.origin === "builtin" ? null : (
            <NGButton style={{ width: 220, marginLeft: 10 }} variant={"action"} onClick={() => navigate(`new`)}>
              Add Binding
            </NGButton>
          )}
        </div>
        {policy.bindings.length > 0 ? (
          <>
            <div className="mb-4 flex items-center text-2xl">Current Bindings</div>
            {policy.bindings.map((binding, index) => (
              <div className="mb-8" key={index}>
                <BindingsOverviewTable binding={binding} />
                {policy.origin === "builtin" ? null : (
                  <div className="flex justify-end items-center mt-4">
                    <NGButton
                      style={{ width: 220, marginRight: 10 }}
                      onClick={() => navigate(String(index))}
                      variant={"secondary"}
                    >
                      Edit
                    </NGButton>
                    <NGButton style={{ width: 220 }} onClick={() => onDeleteBinding(index)} variant={"danger"} outlined>
                      Delete
                    </NGButton>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : null}
      </div>
      {selectedBindingIndex !== -1 ? (
        <Modal
          title="Do you want to delete this binding?"
          open={true}
          maskClosable={!isLoadingDelete}
          destroyOnClose
          onCancel={cancelBindingDeletion}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={cancelBindingDeletion} disabled={isLoadingDelete}>
                Cancel
              </NGButton>
              <NGButton
                variant="danger"
                loading={isLoadingDelete}
                disabled={isLoadingDelete}
                onClick={confirmBindingDeletion}
              >
                Delete
              </NGButton>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export const PolicyBindingsOverview = observer(PolicyBindingsOverviewRaw);
