import * as React from "react";

type NMainHeightContextType = { nMainHeight: number; setNMainHeight: (x: number) => void };

export function useInitNMainHeight(): NMainHeightContextType {
  const [nMainHeight, setNMainHeight] = React.useState<number>(0);

  return { nMainHeight, setNMainHeight };
}

export const NMainHeightContext = React.createContext<NMainHeightContextType>(null as any);

export function useNMainHeight() {
  return React.useContext(NMainHeightContext);
}
