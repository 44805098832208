import * as React from "react";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { Kind } from "../../mst/base";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { handleDetermineNanosInDuration } from "../../mst/stores/envoy/jwt/duration";
import { CplnJwtDraftMobx, CplnJwtDraftModel, validateProviderName } from "../../mst/stores/cpln.jwt.client.draft";
import { BooleanModel } from "../../mobxDataModels/booleanModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { notification } from "antd";
import { FormButtons } from "../forms/formButtons";
import { AuthContent } from "./authContent";
import { v4 as uuidv4 } from "uuid";

function handleGetCplnHttpAndCluster(
  https: any,
  clusters: any,
): { httpIndex: number; http: any; clusterIndex: number; cluster: any } {
  const prefixedNames: { index: number; name: string }[] = [];

  if (https && clusters) {
    for (const httpIndexStr in https) {
      const httpIndex = Number(httpIndexStr);
      const httpObject = https[httpIndex];
      const httpProvider = Object.keys(httpObject.typed_config?.providers)[0];
      if (httpProvider.startsWith("cpln_")) {
        prefixedNames.push({ index: httpIndex, name: httpProvider });
      }
    }

    for (const prefixedName of prefixedNames) {
      const foundClusterIndex = clusters.findIndex((clusterObj) => clusterObj.name === prefixedName.name);
      if (foundClusterIndex > -1) {
        return {
          httpIndex: prefixedName.index,
          http: https[prefixedName.index],
          clusterIndex: foundClusterIndex,
          cluster: clusters[foundClusterIndex],
        };
      }
    }
  }

  return {
    httpIndex: -1,
    http: undefined,
    clusterIndex: -1,
    cluster: undefined,
  };
}

interface Envoy {
  http?: any[];
  clusters?: any[];
}
interface SidecarConfig {
  sidecar: {
    "$replace/envoy"?: Envoy;
    envoy?: any;
  } | null;
}
interface Props {
  envoy: any;
  kind: Kind;
  patch: (body: any) => Promise<void>;
}
const AuthRaw: React.FC<Props> = ({ envoy, kind, patch }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { httpIndex, http, clusterIndex, cluster } = handleGetCplnHttpAndCluster(envoy?.http, envoy?.clusters);

  function getAuthDraft() {
    let rules: { id?: string; firstValue: string; secondValue: string }[] = [
      {
        firstValue: "/metric",
        secondValue: "true",
      },
      {
        firstValue: "/",
        secondValue: "false",
      },
    ];
    let rulesHeaders: { id?: string; firstValue: string; secondValue: string; thirdValue: string }[] = [];
    if (!!cluster && !!http && !!http?.typed_config?.rules && Array.isArray(http.typed_config.rules)) {
      rules = [];
      for (const ruleIndex in http.typed_config.rules) {
        const rule = http.typed_config.rules[ruleIndex];
        const ruleId = uuidv4();
        rules.push({ id: ruleId, firstValue: rule.match.prefix, secondValue: String(!rule.requires) });
        if (!!rule.match.headers && Array.isArray(rule.match.headers)) {
          for (const header of rule.match.headers) {
            if (!header.name || !header.string_match || !header.string_match.exact) {
              continue;
            }
            rulesHeaders.push({ firstValue: header.name, secondValue: header.string_match.exact, thirdValue: ruleId });
          }
        }
      }
    }
    const rulesInstance = ListOfItemsModel.create({
      _items: rules,
    });
    const rulesHeadersInstance = ListOfItemsModel.create({
      _items: rulesHeaders,
    });

    return CplnJwtDraftModel.create({
      _cluster_type: cluster?.type,
      _transport_socket: cluster?.transport_socket?.name,
      _http_name: http?.name,
      _typed_config_type: http?.typed_config?.["@type"],
      _port_value:
        cluster?.load_assignment?.endpoints?.[0]?.lb_endpoints?.endpoint?.address?.socket_address?.port_value,
      _priority: http?.priority,

      excludedWorkloads: ListOfItemsModel.create({
        _items: http?.excludedWorkloads?.map((i: any) => {
          const workload = ngParseLink(i, { gvc: ConsoleContext.gvc!, kind: "workload" });
          if (!workload.isValid) {
            return i;
          }
          return { firstValue: workload.name };
        }),
      }),
      enableAuthFilter: BooleanModel.create({ initialValue: !!(cluster && http) }),
      name: StringModel.create({ label: "Name", isRequired: true, initialValue: validateProviderName(cluster?.name) }),
      issuer: StringModel.create({
        label: "Issuer",
        isRequired: true,
        initialValue: http?.typed_config?.providers?.[cluster?.name]?.issuer,
        validationKey: "url",
      }),
      audiences: ListOfItemsModel.create({
        _items: http?.typed_config?.providers?.[cluster?.name]?.audiences?.map((i: any) => ({ firstValue: i })),
      }),
      claim_to_headers: ListOfItemsModel.create({
        _items: http?.typed_config?.providers?.[cluster?.name]?.claim_to_headers?.map((i: any) => ({
          firstValue: i.header_name,
          secondValue: i.claim_name,
        })),
      }),
      remote_jwks_http_uri: StringModel.create({
        label: "JWKS HTTP URI",
        isRequired: true,
        initialValue: http?.typed_config?.providers?.[cluster?.name]?.remote_jwks?.http_uri?.uri,
        validationKey: "url",
      }),
      remote_jwks_http_uri_timeout: NumberModel.create({
        label: "Default URI Timeout (seconds)",
        min: 0,
        initialValue: handleDetermineNanosInDuration(
          http?.typed_config?.providers?.[cluster?.name]?.remote_jwks?.http_uri?.timeout,
        )
          ? String(http?.typed_config?.providers?.[cluster?.name]?.remote_jwks?.http_uri?.timeout?.seconds)
          : http?.typed_config?.providers?.[cluster?.name]?.remote_jwks?.http_uri?.timeout?.slice(0, -1),
        isRequired: true,
      }),
      rules: rulesInstance,
      rulesHeaders: rulesHeadersInstance,
    });
  }

  const authDraftRef = React.useRef<CplnJwtDraftMobx>(getAuthDraft());

  function handleReset() {
    authDraftRef.current.reset();
  }
  async function handleSave() {
    setIsLoading(true);

    try {
      const { enableAuthFilter, asObject, confirm } = authDraftRef.current;
      const sidecarConfig: SidecarConfig = { sidecar: null };

      if (envoy) {
        const newEnvoy = _.cloneDeep(envoy);

        if (enableAuthFilter.value) {
          if (httpIndex >= 0 && http && Array.isArray(newEnvoy.http)) {
            newEnvoy.http[httpIndex] = asObject.http?.[0];
          } else {
            newEnvoy.http.push(asObject.http?.[0]);
          }
          if (clusterIndex >= 0 && cluster && Array.isArray(newEnvoy.clusters)) {
            newEnvoy.clusters[clusterIndex] = asObject.clusters?.[0];
          } else {
            newEnvoy.clusters.push(asObject.clusters?.[0]);
          }
        } else {
          if (httpIndex >= 0 && http && Array.isArray(newEnvoy.http)) {
            newEnvoy.http.splice(httpIndex, 1);
          }
          if (clusterIndex >= 0 && cluster && Array.isArray(newEnvoy.clusters)) {
            newEnvoy.clusters.splice(clusterIndex, 1);
          }
        }

        sidecarConfig.sidecar = { "$replace/envoy": newEnvoy };
      } else {
        if (enableAuthFilter.value) {
          sidecarConfig.sidecar = { envoy: asObject };
        }
      }

      await patch({ spec: sidecarConfig });

      confirm();

      notification.success({
        message: "Success",
        description: "Updated Auth",
      });
    } catch (e) {
      const errorMessage = e?.response?.data?.message || e.message;

      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <AuthContent kind={kind} authDraftRef={authDraftRef.current} />
      <div className="flex items-center">
        <FormButtons
          onReset={handleReset}
          onSave={handleSave}
          saveDisabled={
            !authDraftRef.current.isDirty ||
            (authDraftRef.current.enableAuthFilter.value && !authDraftRef.current.isValid) ||
            isLoading
          }
          resetDisabled={!authDraftRef.current.isDirty || isLoading}
          loading={isLoading}
          disableOutlineOnReset
        />
      </div>
    </>
  );
};

export const Auth = observer(AuthRaw);
