import * as React from "react";
import { observer } from "mobx-react-lite";
import { CplnJwtDraftMobx } from "../../mst/stores/cpln.jwt.client.draft";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { NGInputListMst } from "../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../newcomponents/input/input";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { InfoTooltip } from "../InfoTooltip";
import { DOCS_URL } from "../../envVariables";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { Kind } from "../../mst/base";
import { hasDuplicatedValue } from "../../services/utils";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { NGButton } from "../../newcomponents/button/Button";
import { NGLabel } from "../../newcomponents/text/label";
import { Modal } from "antd";
import { NGInputList } from "../../newcomponents/inputList/inputList";

interface Props {
  kind: Kind;
  authDraftRef: CplnJwtDraftMobx;
}
const AuthContentRaw: React.FC<Props> = ({ kind, authDraftRef }) => {
  const [editingHeadersForRuleId, setEditingHeadersForRuleId] = React.useState("");

  return (
    <>
      <div className="gap-4 auth-content" style={{ display: "grid", gridTemplateColumns: "450px 450px" }}>
        <div className="auth-content-card flex items-center" style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <NGCheckbox
            onChange={(value) => authDraftRef.enableAuthFilter.setValue(value)}
            checked={authDraftRef.enableAuthFilter.value}
          >
            <NGLabelText>Enable JWT Auth</NGLabelText>
          </NGCheckbox>
          <InfoTooltip
            title={[
              "Configuring multiple identity providers is supported through the Control Plane [Rest API | CLI | directly editing the Control Plane YAML manifest].",
              <div style={{ display: "inline", whiteSpace: "nowrap" }}>
                Refer to{" "}
                <a
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                  className="color-link"
                  href={`${DOCS_URL}/reference/workload/jwt-auth`}
                >
                  documentation
                </a>{" "}
                for guidance and examples.
              </div>,
            ]}
          />
        </div>
        {authDraftRef.enableAuthFilter.value ? (
          <>
            <div className="auth-content-card">
              <NGFormElement
                required
                label={authDraftRef.name.label}
                name={"name"}
                value={authDraftRef.name.value}
                onChange={authDraftRef.name.setValue}
                style={{ width: "100%" }}
              />
            </div>
            <div className="auth-content-card">
              <NGFormElement
                required
                label={authDraftRef.issuer.label}
                name={"issuer"}
                value={authDraftRef.issuer.value}
                onChange={authDraftRef.issuer.setValue}
                error={authDraftRef.issuer.error}
                style={{ width: "100%" }}
              />
            </div>
            <div className="auth-content-card">
              <NGFormElement
                required
                label={authDraftRef.remote_jwks_http_uri.label}
                name={"remote_jwks_http_uri"}
                value={authDraftRef.remote_jwks_http_uri.value}
                onChange={authDraftRef.remote_jwks_http_uri.setValue}
                error={authDraftRef.remote_jwks_http_uri.error}
                style={{ width: "100%" }}
              />
            </div>
            <div className="auth-content-card">
              <NGFormElement
                required
                label={authDraftRef.remote_jwks_http_uri_timeout.label}
                name={"remote_jwks_http_uri_timeout"}
                value={authDraftRef.remote_jwks_http_uri_timeout.value}
                onChange={authDraftRef.remote_jwks_http_uri_timeout.setValue}
                style={{ width: "100%" }}
              />
            </div>
            {kind === "gvc" ? (
              <div className="auth-content-card">
                <NGInputListMst
                  styles={{ header: { width: "unset" } }}
                  error={
                    hasDuplicatedValue(authDraftRef.excludedWorkloads.items.map((i) => i.firstValue))
                      ? "The 'Excluded Workloads' list contains duplicate entries. Please ensure all values are unique."
                      : undefined
                  }
                  data={authDraftRef.excludedWorkloads}
                  firstInput={(item) => (
                    <NGKindSelect
                      fetchAll
                      kind="workload"
                      name={"excludedWorkloads"}
                      value={item.firstValue}
                      onChange={(value) => item.setFirstValue(value)}
                      queries={[{ rel: "gvc", value: ConsoleContext.gvc! }]}
                    />
                  )}
                  label="Excluded Workloads"
                />
              </div>
            ) : null}
            <div className="auth-content-card">
              <NGInputListMst
                styles={{ header: { width: "unset" } }}
                error={
                  hasDuplicatedValue(authDraftRef.audiences.items.map((i) => i.firstValue))
                    ? "The 'Audiences' list contains duplicate entries. Please ensure all values are unique."
                    : undefined
                }
                data={authDraftRef.audiences}
                firstInput={(item) => (
                  <NGInput
                    placeholder="Audience"
                    value={item.firstValue}
                    onChange={(e) => item.setFirstValue(e.target.value)}
                  />
                )}
                label="Audiences"
              />
            </div>
            <div className="auth-content-card" style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
              <NGInputListMst
                styles={{ header: { width: "100%" } }}
                error={
                  hasDuplicatedValue(authDraftRef.claim_to_headers.items.map((i) => i.firstValue))
                    ? "The 'Header Name' property cannot be duplicated. Please ensure all values are unique."
                    : undefined
                }
                data={authDraftRef.claim_to_headers}
                firstInput={(item) => (
                  <NGInput
                    placeholder="Claim Name"
                    value={item.secondValue}
                    onChange={(e) => item.setSecondValue(e.target.value)}
                  />
                )}
                secondInput={(item) => (
                  <NGInput
                    placeholder="Header Name"
                    value={item.firstValue}
                    onChange={(e) => item.setFirstValue(e.target.value)}
                  />
                )}
                label="Claim To Headers"
              />
            </div>
            <div className="auth-content-card" style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
              <NGInputList<{
                id: string;
                chosen: boolean;
                firstValue: string;
                secondValue: string;
                setFirstValue: (value: string) => void;
                setSecondValue: (value: string) => void;
              }>
                styles={{ header: { width: "100%" } }}
                add={() => authDraftRef.rules.add()}
                remove={authDraftRef.rules.remove}
                items={authDraftRef.rules.items}
                setItems={authDraftRef.rules.setItems}
                draggable={true}
                firstInput={(item) => (
                  <div className="flex items-center gap-2">
                    <NGInput
                      className="flex-grow"
                      required
                      placeholder="Match Prefix"
                      value={item.firstValue}
                      onChange={(e) => item.setFirstValue(e.target.value)}
                    />
                    <NGCheckbox
                      onChange={(e) => item.setSecondValue(e.toString())}
                      checked={item.secondValue === "true"}
                    >
                      <NGLabelText>Exclude Auth</NGLabelText>
                    </NGCheckbox>
                  </div>
                )}
                itemExtra={(item) => {
                  const headers = authDraftRef.rulesHeaders.items.filter((i) => i.thirdValue === item.id);
                  return (
                    <div className="mb-4">
                      <div className="flex justify-between">
                        <div className="flex items-center mt-1">
                          <NGLabel size={3}>Headers</NGLabel>
                          <NGButton
                            className="ml-2"
                            onClick={() => setEditingHeadersForRuleId(item.id)}
                            variant="secondary"
                            size={"small"}
                          >
                            Edit
                          </NGButton>
                        </div>
                      </div>
                      {headers.length > 0 ? (
                        <div className={`flex flex-col border rounded mt-2 overflow-hidden`}>
                          <div className="flex items-center table-labels w-full px-2 py-1">
                            <span className="w-1/2">Name</span>
                            <span className="w-1/2">Value</span>
                          </div>
                          {headers.map((h) => (
                            <div className="flex items-center w-full px-2 py-1 border-t">
                              <span className="w-1/2">{h.firstValue}</span>
                              <span className="w-1/2">{h.secondValue}</span>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  );
                }}
                label="Path-Based Auth Control"
              />
            </div>
          </>
        ) : null}
      </div>
      {!editingHeadersForRuleId ? null : (
        <Modal
          width={800}
          title={null}
          open={!!editingHeadersForRuleId}
          destroyOnClose
          maskClosable={false}
          closable={false}
          onCancel={() => {}}
          onOk={() => {}}
          footer={
            <div className="modal-actions">
              <NGButton
                variant="primary"
                onClick={() => {
                  setEditingHeadersForRuleId("");
                  const emptyHeaderIds = authDraftRef.rulesHeaders.items
                    .filter((h) => h.firstValue === "")
                    .map((h) => h.id);
                  authDraftRef.rulesHeaders.remove(emptyHeaderIds);
                }}
              >
                Done
              </NGButton>
            </div>
          }
        >
          <NGInputList<{
            id: string;
            chosen: boolean;
            firstValue: string;
            secondValue: string;
            setFirstValue: (value: string) => void;
            setSecondValue: (value: string) => void;
          }>
            add={() => {
              authDraftRef.rulesHeaders.add();
              authDraftRef.rulesHeaders.items[authDraftRef.rulesHeaders.items.length - 1].setThirdValue(
                editingHeadersForRuleId,
              );
            }}
            styles={{
              header: {
                width: "100%",
              },
            }}
            remove={authDraftRef.rulesHeaders.remove}
            items={authDraftRef.rulesHeaders.items.filter((h) => h.thirdValue === editingHeadersForRuleId)}
            firstInput={(item) => (
              <NGInput
                required
                placeholder="Name"
                value={item.firstValue}
                onChange={(e) => item.setFirstValue(e.target.value)}
              />
            )}
            secondInput={(item) => (
              <NGInput
                required
                placeholder="Value"
                value={item.secondValue}
                onChange={(e) => item.setSecondValue(e.target.value)}
              />
            )}
            label="Headers"
          />
        </Modal>
      )}
    </>
  );
};

export const AuthContent = observer(AuthContentRaw);
