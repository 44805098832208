import { makeAutoObservable } from "mobx";
import { request } from "../../../services/cpln";

export class CreateNewData {
  isAdvanced = false;
  showMoreDNSRoutingOptions = false;
  gvcAlias = "<gvcAlias>";
  selectedPortId = "";

  constructor(isAdvanced = false) {
    this.isAdvanced = isAdvanced;
    makeAutoObservable(this);
  }

  *fetchGVCAlias(org: string, gvc: string) {
    this.gvcAlias = "<gvcAlias>";
    const { data } = yield request({ url: `/org/${org}/gvc/${gvc}` });
    this.gvcAlias = data.alias;
  }

  get isBasic() {
    return !this.isAdvanced;
  }

  toggleMode() {
    this.isAdvanced = !this.isAdvanced;
    if (!this.isAdvanced) {
      this.showMoreDNSRoutingOptions = false;
    }
  }

  toggleShowMoreDNSRoutingOptions() {
    this.showMoreDNSRoutingOptions = !this.showMoreDNSRoutingOptions;
  }

  setSelectedPortId(value: string) {
    this.selectedPortId = value;
  }
}
