import * as React from "react";
import { AlertCircle, ExternalLink, Eye, Loader, Plus } from "react-feather";
import { NavLink } from "react-router-dom";
import { METRICS_TOKEN_UPDATE_INTERVAL_MS } from "../../envVariables";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Discovery } from "../../mobxStores/discovery/discovery";
import { getLocalToken } from "../../services/cpln";
import { getMetricsUrl, getTracesUrl } from "../../services/utils";
import "./nav.scss";
import clsx from "clsx";
import { BasePathContext } from "../../reactContexts/basePathContext";
import { Tooltip } from "../Tooltip";

export interface DetailNavLink {
  url: string;
  label: string;
  onClick?: any;
  sub?: boolean;
  icon?: {
    class: string;
    color: string;
    isActive: boolean;
  };
}

interface Props {
  links: DetailNavLink[];
}
export const DetailNav: React.FC<Props> = ({ links }) => {
  const basePath = React.useContext(BasePathContext);
  if (basePath === null) {
    throw new Error("All detail pages need to implement BasePathContext");
  }

  const metricsFormRef = React.useRef<HTMLFormElement>(null as any);
  const tracingFormRef = React.useRef<HTMLFormElement>(null as any);
  const { org } = ConsoleContext;
  const [metricsTokenFromLocal, setMetricsTokenFromLocal] = React.useState("");
  React.useEffect(() => {
    getLocalToken().then((res) => {
      if (res.accessToken !== "pass") {
        setMetricsTokenFromLocal(res.accessToken);
      }
    });
    const localTokenInterval = setInterval(() => {
      getLocalToken().then((res) => {
        if (res.accessToken !== "pass") {
          setMetricsTokenFromLocal(res.accessToken);
        }
      });
    }, METRICS_TOKEN_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(localTokenInterval);
    };
  }, []);

  return (
    <ul className="flex flex-col items-start innernav">
      {links.map((link) => {
        if (link.url.includes("-metrics") && link.label === "Metrics") {
          return (
            <span key={link.url} className={"w-full"}>
              <form
                className="hidden"
                ref={metricsFormRef}
                action={`${Discovery.endpoints.grafana.replace("{org}", org!)}/auth/process`}
                method="POST"
                target="_blank"
              >
                <input
                  name="redirect"
                  value={getMetricsUrl(
                    link.url.includes("org") ? "org" : link.url.includes("gvc") ? "gvc" : "workload",
                    { workloadName: window.location.pathname.split("/")[7] }
                  )}
                  type="hidden"
                />
                <input name="idToken" value={metricsTokenFromLocal} type="hidden" />
                <button type="submit">metrics</button>
              </form>
              <a
                data-testid={`detail-${link.label.toLowerCase()}`}
                className={`ngfocus innernavlink px-2 py-1`}
                href={""}
                onClick={(e) => {
                  e.preventDefault();
                  if (metricsFormRef.current) {
                    metricsFormRef.current.submit();
                  }
                }}
                target={"_blank"}
              >
                {link.label}
                <ExternalLink
                  className="inline-block feather-icon color-link"
                  style={{ transform: "translateY(3px)" }}
                />
              </a>
            </span>
          );
        }

        if (link.url.includes("-traces") && link.label === "Traces") {
          return (
            <span key={link.url} className={"w-full"}>
              <form
                className="hidden"
                ref={tracingFormRef}
                action={`${Discovery.endpoints.grafana.replace("{org}", org!)}/auth/process`}
                method="POST"
                target="_blank"
              >
                <input
                  name="redirect"
                  value={getTracesUrl(
                    link.url.includes("workload") ? "workload" : link.url.includes("gvc") ? "gvc" : "org",
                    {
                      gvcName: window.location.pathname.split("/")[5],
                      workloadName: window.location.pathname.split("/")[7],
                    }
                  )}
                  type="hidden"
                />
                <input name="idToken" value={metricsTokenFromLocal} type="hidden" />
                <button type="submit">traces</button>
              </form>
              <a
                data-testid={`detail-${link.label.toLowerCase()}`}
                className={`ngfocus innernavlink px-2 py-1`}
                href={""}
                onClick={(e) => {
                  e.preventDefault();
                  if (tracingFormRef.current) {
                    tracingFormRef.current.submit();
                  }
                }}
                target={"_blank"}
              >
                {link.label}
                <ExternalLink
                  className="inline-block feather-icon color-link"
                  style={{ transform: "translateY(3px)" }}
                />
              </a>
            </span>
          );
        }

        const isAdd = link.label === "Add";
        const isSub = link.sub;

        const ignore = link.url === "ignore";

        return (
          <Tooltip key={link.url} title={link.label} open={!(!isSub || isAdd || link.label.length < 14)}>
            <NavLink
              data-testid={`detail-${link.label.toLowerCase()}`}
              className={({ isActive }) =>
                clsx(
                  `flex items-center ngfocus px-2 py-1 innernavlink ${isAdd ? "action" : ""} ${
                    isSub && "sub truncate pl-4 w-40"
                  }`,
                  { selected: !!isActive && !ignore, sub: isSub, action: isAdd }
                )
              }
              to={ignore ? window.location.pathname : `${basePath}/${link.url}`}
              onClick={link.onClick ? link.onClick : () => {}}
            >
              <span className="truncate">{link.label}</span>
              {link.icon?.isActive === true ? (
                link.icon!.class === "eye" ? (
                  <Eye size={16} className={`${link.icon!.color}`} />
                ) : link.icon!.class === "plusCircle" ? (
                  <Plus size={16} className={`${link.icon!.color}`} />
                ) : link.icon!.class === "loader" ? (
                  <Loader size={16} className={`${link.icon!.color}`} />
                ) : link.icon!.class === "alertCircle" ? (
                  <AlertCircle size={16} className={`${link.icon!.color}`} />
                ) : null
              ) : null}
            </NavLink>
          </Tooltip>
        );
      })}
    </ul>
  );
};
