import * as React from "react";
import { observer } from "mobx-react-lite";
import { IdentityDraftModel } from "../../../mst/stores/identity.draft";
import { IdentityCreate } from "./create";
import { Loader } from "../../../components/layout/loader";

const IdentityCreateRouteRaw: React.FC = () => {
  const draftRef = React.useRef(IdentityDraftModel.create());
  const [isPageReady, setIsPageReady] = React.useState(false);
  const draft = draftRef.current;

  React.useEffect(() => {
    setupIdentityDraft()
      .then(() => {
        setIsPageReady(true);
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  async function setupIdentityDraft() {
    try {
      draft.setAsNew();
      await draftRef.current.reset();
    } catch (e) {
      throw e;
    }
  }

  if (!isPageReady) {
    return <Loader reason={"initing identity draft"} />;
  }

  return <IdentityCreate identity={draft} />;
};

export const IdentityCreateRoute = observer(IdentityCreateRouteRaw);
