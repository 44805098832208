import { createRoot } from "react-dom/client";
import { DetachedConnect } from "./pages/workload/detachedConnect";
import { setupSentry } from "./tools/sentry";
import "xterm/css/xterm.css";
import "./tailwind.output.css";
import "./index.css";
import "./antOverride.scss";
import { EntryPoint } from "./entryPoint";
import { setupHotjar } from "./tools/hotjar";

function executeApp() {
  // External tools
  setupSentry();
  setupHotjar();

  const domNode = document.getElementById("root");
  const root = createRoot(domNode!);

  // Detached Connect
  if (window.location.pathname.startsWith("/connect")) {
    root.render(<DetachedConnect />);
    return;
  }

  root.render(
    <>
      {/* For breakpoints debug */}
      {/* <div className="testing-breakpoints" style={{ position: "fixed", left: 0, top: 0, width: 10, height: 10 }} /> */}
      <EntryPoint />
    </>
  );
}

executeApp();
