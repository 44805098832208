import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderLambdalabsUnmanagedNodePool } from "./unmanagedNodePool";
import { ProviderLambdalabsUnmanagedNodePoolAdd } from "./unmanagedNodePoolAdd";
import { ProviderLambdalabsUnmanagedNodePools } from "./unmanagedNodePools";
import { ProviderLambdalabs } from "./lambdalabs";
import { ProviderLambdalabsAdvanced } from "./advanced";
import { ProviderLambdalabsNodePool } from "./nodePool";
import { ProviderLambdalabsNodePoolAdd } from "./nodePoolAdd";
import { ProviderLambdalabsNodePools } from "./nodePools";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderLambdalabsRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<ProviderLambdalabs mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<ProviderLambdalabsNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<ProviderLambdalabsNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<ProviderLambdalabsNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-unmanaged-node-pools`}
        element={<ProviderLambdalabsUnmanagedNodePools mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/-add`}
        element={<ProviderLambdalabsUnmanagedNodePoolAdd mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-unmanaged-node-pools/:index`}
        element={<ProviderLambdalabsUnmanagedNodePool mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-advanced`} element={<ProviderLambdalabsAdvanced mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const ProviderLambdalabsRoute = observer(ProviderLambdalabsRouteRaw);
