import clsx from "clsx";
import * as React from "react";
import { Loader } from "react-feather";
import "./button.scss";
import { Link } from "react-router-dom";
import NGAlert from "../alert";

// Spec: https://www.w3.org/TR/wai-aria-practices/examples/button/button.html

export interface NGButtonProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, "size"> {
  disabled?: boolean;
  loading?: boolean;
  renderIcon?: (hover: boolean, props: any) => React.ReactElement;
  iconPosition?: "left" | "right";
  className?: string;
  variant: "primary" | "secondary" | "danger" | "action";
  outlined?: boolean;
  text?: boolean;
  // TODO Implement this so it acts as an anchor
  link?: boolean;
  size?: "toRemoveLarge" | "normal" | "small";
  type?: "submit" | "reset" | "button";
  as?: "anchor" | "button";
  to?: string;
}
export const NGButton: React.FC<NGButtonProps> = ({
  children,
  disabled = false,
  loading = false,
  className = "",
  renderIcon,
  iconPosition = "right",
  color,
  variant = "primary",
  size = "normal",
  type = "button",
  outlined: _outlined = false,
  text = false,
  as = "button",
  to,
  ...props
}) => {
  const outlined = text ? false : _outlined;

  const iconSize = size === "small" ? 12 : 16;
  let iconRenderer: () => React.ReactElement | null = () => null;

  const [hover, setHover] = React.useState(false);

  function handleMouseOver() {
    setHover(true);
  }
  function handleMouseOut() {
    setHover(false);
  }

  if (renderIcon) {
    iconRenderer = () => (
      <span className="pointer-events-none">
        {renderIcon.call(undefined, hover, { size: iconSize, color, style: { height: iconSize, width: iconSize } })}
      </span>
    );
  }
  if (loading) {
    iconRenderer = () => <Loader className="pointer-events-none animate-spin" size={iconSize} />;
  }

  const processedClassNames = clsx("ngbutton ngfocus", variant, className, {
    small: size === "small",
    normal: size === "normal",
    large: size === "toRemoveLarge",
    outlined: outlined,
    text: text,
    disabled: disabled,
  });

  if (as === "anchor") {
    if (!to) {
      return <NGAlert title="Incorrect Usage" message="Button component as anchor requires 'to' prop" />;
    }
    return (
      <Link
        to={to}
        className={processedClassNames}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        aria-disabled={disabled ? "true" : "false"}
        onClick={props.onClick as any}
      >
        {!loading && iconPosition === "left" ? iconRenderer() : null}
        {children && <span>{children}</span>}
        {!loading && iconPosition === "right" ? iconRenderer() : null}
        {loading ? iconRenderer() : null}
      </Link>
    );
  }

  return (
    <button
      className={processedClassNames}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      disabled={disabled}
      aria-disabled={disabled ? "true" : "false"}
      type={type}
      {...props}
    >
      {!loading && iconPosition === "left" ? iconRenderer() : null}
      {children && <span>{children}</span>}
      {!loading && iconPosition === "right" ? iconRenderer() : null}
      {loading ? iconRenderer() : null}
    </button>
  );
};

// Can define icons like below
{
  /* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display:none" id="svg_definitions">
  <defs>
    <symbol id="icon-mute" viewBox="0 0 75 75">
      <polygon id="polygon1" points="39.389,13.769 22.235,28.606 6,28.606 6,47.699 21.989,47.699 39.389,62.75 39.389,13.769" style="stroke:currentColor;stroke-width:5;stroke-linejoin:round;fill:currentColor;"></polygon>
      <path id="path3003" d="M 48.651772,50.269646 69.395223,25.971024" style="fill:none;stroke:currentColor;stroke-width:5;stroke-linecap:round"></path>
      <path id="path3003-1" d="M 69.395223,50.269646 48.651772,25.971024" style="fill:none;stroke:currentColor;stroke-width:5;stroke-linecap:round"></path>
    </symbol>
    <symbol id="icon-sound" viewBox="0 0 75 75">
      <polygon points="39.389,13.769 22.235,28.606 6,28.606 6,47.699 21.989,47.699 39.389,62.75 39.389,13.769" style="stroke:currentColor;stroke-width:5;stroke-linejoin:round;fill:currentColor;"></polygon>
      <path d="M 48.128,49.03 C 50.057,45.934 51.19,42.291 51.19,38.377 C 51.19,34.399 50.026,30.703 48.043,27.577" style="fill:none;stroke:currentColor;stroke-width:5;stroke-linecap:round"></path>
      <path d="M 55.082,20.537 C 58.777,25.523 60.966,31.694 60.966,38.377 C 60.966,44.998 58.815,51.115 55.178,56.076" style="fill:none;stroke:currentColor;stroke-width:5;stroke-linecap:round"></path>
      <path d="M 61.71,62.611 C 66.977,55.945 70.128,47.531 70.128,38.378 C 70.128,29.161 66.936,20.696 61.609,14.01" style="fill:none;stroke:currentColor;stroke-width:5;stroke-linecap:round"></path>
    </symbol>
  </defs>
</svg> */
}

// Can used icons as below
{
  /* <svg aria-hidden="true" focusable="false">
  <use xlink:href="#icon-sound"></use>
</svg>  */
}
