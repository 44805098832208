import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../advanced/providerAutoscaler";
import { ProviderNetworking } from "../../advanced/providerNetworking";
import { ProviderPreInstallScript } from "../../advanced/providerPreInstallScript";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../newcomponents/input/input";
import { getDryRunErrorText } from "../../getDryRunErrorText";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { homeLink, request } from "../../../../services/cpln";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderDigitalOceanAdvancedRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const d = mk8sDraft.provider_digitalocean!;

  const [browserKey, setBrowserKey] = React.useState("");

  const digitalOceanTagsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.digitalocean.digitalOceanTags`,
    paths: d.digitalOceanTags.items.map((_, index) => `spec.provider.digitalocean.digitalOceanTags[${index}]`),
  });
  const extraSshKeysDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.digitalocean.extraSshKeys`,
    paths: d.extraSshKeys.items.map((_, index) => `spec.provider.digitalocean.extraSshKeys[${index}]`),
  });
  const reservedIPsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.digitalocean.reservedIPs`,
    paths: d.reservedIPs.items.map((_, index) => `spec.provider.digitalocean.reservedIPs[${index}]`),
  });

  return (
    //
    <>
      <div>
        <NGInputListMst
          data={d.digitalOceanTags}
          className="mb-8"
          label="Digital Ocean Tags"
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && digitalOceanTagsDryRun.message.includes(`[${index}]`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          invalid={digitalOceanTagsDryRun.severity === "error"}
          error={digitalOceanTagsDryRun.severity === "error" ? digitalOceanTagsDryRun.message : ""}
          warning={digitalOceanTagsDryRun.severity === "error" ? "" : digitalOceanTagsDryRun.message}
        />
        <NGInputListMst
          data={d.extraSshKeys}
          className="mb-8"
          label="Extra SSH Keys"
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && extraSshKeysDryRun.message.includes(`[${index}]`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          invalid={extraSshKeysDryRun.severity === "error"}
          error={extraSshKeysDryRun.severity === "error" ? extraSshKeysDryRun.message : ""}
          warning={extraSshKeysDryRun.severity === "error" ? "" : extraSshKeysDryRun.message}
        />
        <NGInputListMst
          data={d.reservedIPs}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="Reserved IPs"
          labelRender={() => {
            return (
              <>
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("reservedIps")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && reservedIPsDryRun.message.includes(`[${index}]`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Key"
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          invalid={reservedIPsDryRun.severity === "error"}
          error={reservedIPsDryRun.severity === "error" ? reservedIPsDryRun.message : ""}
          warning={reservedIPsDryRun.severity === "error" ? "" : reservedIPsDryRun.message}
        />
        <div className="mb-4">
          <ProviderPreInstallScript provider="hetzner" preInstallScript={d.preInstallScript} />
        </div>
        <div className="mb-4">
          <ProviderNetworking clusterDraft={mk8sDraft} networking={d.networking} readOnly />
        </div>
        <div>
          <ProviderAutoscaler provider="hetzner" autoscaler={d.autoscaler} />
        </div>
      </div>
      {browserKey === "ssh-keys" ? (
        <NGProviderBrowser
          key={d.tokenSecretName}
          title="Browse Digital Ocean Extra SSH Keys"
          initialValue={d.extraSshKeys.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ssh_keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.ssh_keys;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (d.extraSshKeys.items.some((i) => i.firstValue === value)) {
                continue;
              }
              d.extraSshKeys.add();
              const i = d.extraSshKeys.items[d.extraSshKeys.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Name", "ID"]}
          getData={(item: any) => [String(item.id), item.name, String(item.id)]}
        />
      ) : null}
      {browserKey === "reservedIps" ? (
        <NGProviderBrowser
          key={d.tokenSecretName}
          title="Browse Digital Ocean Reserved IPs"
          initialValue={d.reservedIPs.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "reserved_ips",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.reserved_ips;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (d.reservedIPs.items.some((i) => i.firstValue === value)) {
                continue;
              }
              d.reservedIPs.add();
              const i = d.reservedIPs.items[d.reservedIPs.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["IP", "Attached To"]}
          getData={(item: any) => [item.ip, item.ip, item.droplet?.name]}
        />
      ) : null}
    </>
  );
};

export const ProviderDigitalOceanAdvanced = observer(ProviderDigitalOceanAdvancedRaw);
