import * as React from "react";
import { Edit, Eye, EyeOff, Trash, X } from "react-feather";

interface Props {
  _key: string;
  _value: string;
  onRemove: (key: string) => void;
  onEdit: (key: string, isHidden: boolean) => void;
  className?: string;
  style?: any;
  isHidden: boolean;
  toggleIsHidden: () => void;
}
export const DictionaryProperty: React.FC<Props> = ({
  _key,
  _value,
  onRemove,
  onEdit,
  className = "",
  style = {},
  isHidden,
  toggleIsHidden,
}) => {
  const value = _value.length < 1 ? "' '" : isHidden ? new Array(_value.length).fill("•").join("") : _value;

  return (
    <div key={_key} className={`${className} flex items-start px-4 py-1 border border-t-0`} style={style}>
      <div className="w-5/12 break-words pr-3">{_key}</div>
      <div className="w-6/12 break-words">{value}</div>
      <div className="w-1/12 flex gap-x-1 justify-end mt-1">
        <button onClick={toggleIsHidden} className={`ngfocus ml-auto mr-1`}>
          {isHidden ? (
            <Eye data-testid={`toggleHidden-${_key}`} className="feather-icon" />
          ) : (
            <EyeOff data-testid={`toggleHidden-${_key}`} className="feather-icon" />
          )}
        </button>
        <button onClick={(_e) => onEdit(_key, isHidden)} className={`ngfocus mr-1`}>
          <Edit data-testid={`edit-${_key}`} className="feather-icon" />
        </button>
        <button onClick={(_e) => onRemove(_key)}>
          <Trash data-testid={`remove-${_key}`} className="feather-icon color-danger" />
        </button>
      </div>
    </div>
  );
};
