import { flow, Instance, types } from "mobx-state-tree";
import { isEqual } from "lodash";
import { GCPIdentityData } from "./identity.gcp.data";
import { IdentityDraftGCPIdentityModel } from "./identity.gcp.draft";

export const IdentityDraftGcpHelperModel = types
  .model({
    saved: types.optional(GCPIdentityData, () => GCPIdentityData.create()),
    edited: types.optional(GCPIdentityData, () => GCPIdentityData.create()),

    draft: types.optional(IdentityDraftGCPIdentityModel, () => IdentityDraftGCPIdentityModel.create()),
    isSettingUp: false,
    isLoading: false,
    step: types.optional(types.enumeration(["general", "config"]), "general"),
  })
  .actions((self) => {
    const next = flow(function* () {
      try {
        self.isLoading = true;
        if (self.draft.method === "existing") {
          yield self.draft.fetchServiceaccounts();
        } else {
          yield self.draft.fetchServices();
        }
      } catch (e) {
        if (self.draft.method === "existing") {
          self.draft.serviceaccountInput.setIsRequired(true);
        }
      } finally {
        self.step = "config";
        self.isLoading = false;
      }
    });
    return { next };
  })
  .actions((self) => ({
    setIsSettingUpFalse() {
      self.isSettingUp = false;
      self.draft.filterInput.reset();
      self.step = "general";
    },
  }))
  .actions((self) => ({
    done() {
      self.draft.setIsActive(true);
      self.draft.setUseManualInput(false);
      self.edited.apply(self.draft.asObject);
      self.setIsSettingUpFalse();
    },
    back() {
      self.step = "general";
    },
    remove() {
      self.draft.clear();
      self.edited.clear();
    },
    onSave() {
      self.saved.apply(self.edited.asObject);
    },
    setup() {
      self.isSettingUp = true;
      self.draft.setBrowserCloudaccount();
    },
  }))
  .actions((self) => {
    const cancel = flow(function* () {
      self.isLoading = true;
      if (self.edited.isActive) {
        yield self.draft.apply(self.edited.asObject);
      } else {
        self.draft.clear();
      }
      self.setIsSettingUpFalse();
      self.isLoading = false;
    });
    const reset = flow(function* () {
      if (self.saved.isActive) {
        self.edited.apply(self.saved.asObject);
        yield self.draft.apply(self.saved.asObject);
      } else {
        self.edited.clear();
        self.draft.clear();
      }
    });
    return { cancel, reset };
  })
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.saved.isActive !== self.edited.isActive) {
        res = true;
      } else if (self.saved.isActive && self.edited.isActive) {
        if (!isEqual(self.saved.asObject, self.edited.asObject)) {
          res = true;
        }
      }
      return res;
    },
  }))
  .views((self) => ({
    get title() {
      switch (self.step) {
        case "general":
          return "GCP Identity - General";
        case "config":
          if (self.draft.method === "existing") {
            return "GCP Identity - Existing GCP Service Account";
          } else {
            return "GCP Identity - Service Account Bindings";
          }
        default:
          return "GCP Identity";
      }
    },
    get modalWidth() {
      if (self.step === "general") return undefined;
      if (self.draft.method === "existing") return undefined;
      return 1000;
    },
    get isRadioDisabled() {
      return !self.draft.cloudaccountName;
    },
  }));
export interface IdentityDraftGcpHelper extends Instance<typeof IdentityDraftGcpHelperModel> {}
