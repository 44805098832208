import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { AgentList } from "./list";
import { AgentCreate } from "./create/create";
import { AgentDetailRoute } from "./detailRoute";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

const AgentRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<AgentList />} />
        <Route path={`-create/*`} element={<AgentCreate />} />
        <Route path={`:agent/*`} element={<AgentDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(AgentRoute);
