import * as React from "react";
import { WorkloadMobx } from "../../mst/kinds/workload";
import qs from "qs";
import { getCancelToken, getLocalToken, getToken, homeLink, linksOf, request, resourceLink } from "../../services/cpln";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Dropdown, notification } from "antd";
import { Loader } from "../../components/layout/loader";
import moment from "moment-timezone";
import { LogList } from "../../components/detail/logList";
import { LogGrid } from "../../components/detail/logGrid";
import { v4 as uuidv4 } from "uuid";
import { Loader as FeatherLoader, Download, ExternalLink, Play, Search, Settings } from "react-feather";
import { LocationMobx, LocationModel } from "../../mst/kinds/location";
import { observer } from "mobx-react-lite";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { LogEntry, LogsLiveResponse, LogsQueryResponse } from "../logs/types";
import { CancelTokenSource } from "axios";
import {
  METRICS_TOKEN_UPDATE_INTERVAL_MS,
  STORAGE_KEY_LOG_ORDER_BY,
  STORAGE_KEY_LOG_TABLE_LABELS,
  STORAGE_KEY_LOG_TABLE_PRETTIFY_JSON,
  STORAGE_KEY_LOG_TABLE_TIMESTAMP,
  STORAGE_KEY_LOG_TABLE_WRAPPED_LINES,
} from "../../envVariables";
import { Discovery } from "../../mobxStores/discovery/discovery";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { useSearchParams } from "react-router-dom";
import { isEqual } from "lodash";
import { CellMeasurerCache, KeyMapper } from "react-virtualized";
import { useNMainHeight } from "../../reactContexts/nMainHeightContext";
import { Tag } from "../../components/tag/tag";
import { getLabelsWidth, getUniqueLabels, stringPairsToObject } from "../../services/utils";
import { getLogsPresets, getLogsPresetsForGrafana } from "../auditTrail/utils";
import { NGButton } from "../../newcomponents/button/Button";
import "./logs.css";
import { NGLabel } from "../../newcomponents/text/label";
import { NGInput } from "../../newcomponents/input/input";
import NGAlert from "../../newcomponents/alert";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { RangePicker } from "../../components/antd/RangePicker";
import { Timezone } from "../../mobxStores/userData/timezone";
import { Deployment } from "../../schema/types/workload/deployment";

export type ProcessedLogEntryItem = { type: "timestamp" | "labels" | "log" | "label"; value: any };
export type ProcessedLogEntry = ProcessedLogEntryItem[];
interface Props {
  workload: WorkloadMobx;
  deployments: Deployment[];
}

const WorkloadLogsRaw: React.FC<Props> = ({ workload, deployments }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  const execute = searchParams.get("execute");
  const live = searchParams.get("live");

  const [isQueryFocused, setIsQueryFocused] = React.useState(false);

  const { nMainHeight } = useNMainHeight();
  const emptySpaceHeight = Math.max(nMainHeight - 490, 400);

  const sizerRef = React.useRef<HTMLDivElement>(null as any);
  const [sizerDimensions, setSizerDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    const onResize = () => {
      setSizerDimensions({
        width: sizerRef.current.clientWidth,
        height: sizerRef.current.clientHeight,
      });
      clearCaches();
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const { org, gvc } = ConsoleContext;

  const DEFAULT_LOG_QUERY = `{gvc="${gvc}", workload="${workload.name}"}`;
  const LOGS_RECORD_NAME = `logs-${org}-${gvc}-${workload.id}`;

  const [mounted, setMounted] = React.useState(false);

  function getSavedOrderBy(): "asc" | "desc" {
    return (localStorage.getItem(STORAGE_KEY_LOG_ORDER_BY) as any) || "desc";
  }
  function setOrderBy(value: "asc" | "desc") {
    localStorage.setItem(STORAGE_KEY_LOG_ORDER_BY, value);
    _setOrderBy(value);
    clearCaches();
  }

  const [orderBy, _setOrderBy] = React.useState<"asc" | "desc">(getSavedOrderBy());
  const [gvcLocationLinks, setGVCLocationLinks] = React.useState<string[]>([]);
  const [orgLocations, setOrgLocations] = React.useState<LocationMobx[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [liveObject, setLiveObject] = React.useState<any>({});
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const socketRef = React.useRef<WebSocket | null>(null);
  const [liveCounter, setLiveCounter] = React.useState(0);
  const liveLogEntriesRef = React.useRef<LogEntry[]>([]);
  const [logEntries, setLogEntries] = React.useState<LogEntry[]>([]);
  const logEntriesRef = React.useRef<LogEntry[]>([]);
  const queryInputRef = React.useRef(
    StringModel.create({
      label: "LogQL Query",
      isRequired: true,
    }),
  );
  const [isPrefetchingLive, setIsPrefetchingLive] = React.useState(false);
  const [fetchedWithLive, setFetchedWithLive] = React.useState(false);
  const [isDisplayingLive, setIsDisplayingLive] = React.useState(false);
  const [isDisplayingQuery, setIsDisplayingQuery] = React.useState(false);
  const [commonLabels, setCommonLabels] = React.useState<string[]>([]);

  const [fromISO, setFromISO] = React.useState<string | null>(null);
  const [toISO, setToISO] = React.useState<string | null>(null);
  const [selectedPreset, setSelectedPreset] = React.useState<string | null>(null);
  React.useEffect(() => {
    const info = getLogsPresetsForGrafana();

    const now = moment();
    const from = moment(fromISO);
    const minuteDiff = now.diff(from, "minute");
    const hourDiff = now.diff(from, "hour");
    const dayDiff = now.diff(from, "day");

    const match = info.find((i) => i.minuteDiff === minuteDiff && i.hourDiff === hourDiff && i.dayDiff === dayDiff);
    setSelectedPreset(match?.presetValue || null);
  }, [fromISO, toISO]);

  const [lastQueries, setLastQueries] = React.useState<string[]>([]);
  const [lastQuery, setLastQuery] = React.useState("");
  const versionSelectRef = React.useRef(
    SelectModel.create({
      initialValue: "any",
      label: "Version",
      options: [{ label: "Any Version", value: "any" }],
    }),
  );
  const locationSelectRef = React.useRef(
    SelectModel.create({
      initialValue: "any",
      label: "Location",
      options: [
        { label: "Any Location", value: "any" },
        // This is required if user doesn't have view access to locations but wanna view logs of them
        { label: "Custom", value: "custom" },
      ],
    }),
  );
  const containerSelectRef = React.useRef(
    SelectModel.create({
      initialValue: "any",
      label: "Container",
      options: [
        { label: "Any Container", value: "any" },
        { label: "Custom", value: "custom", disabled: true },
        { label: "Access Log", value: "_accesslog" },
        ...workload.containerNames.map((name) => ({ label: name, value: name })),
      ],
    }),
  );

  const [redirect, setRedirect] = React.useState("");
  const [showTableSettings, setShowTableSettings] = React.useState(false);
  const formRef = React.useRef<HTMLFormElement>(null as any);
  const manuallyStopped = React.useRef(false);
  const cancelTokenRef = React.useRef<CancelTokenSource | undefined>(undefined);
  const reconnectIntervalIdRef = React.useRef<any>(null);

  const [logsTokenFromLocal, setLogsTokenFromLocal] = React.useState("");
  React.useEffect(() => {
    getLocalToken().then((res) => {
      if (res.accessToken !== "pass") {
        setLogsTokenFromLocal(res.accessToken);
      }
    });
    const localTokenInterval = setInterval(() => {
      getLocalToken().then((res) => {
        if (res.accessToken !== "pass") {
          setLogsTokenFromLocal(res.accessToken);
        }
      });
    }, METRICS_TOKEN_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(localTokenInterval);
    };
  }, []);

  React.useEffect(() => {
    let initialQuery: string | null = "";
    if (query) {
      initialQuery = query.toString();
    } else {
      initialQuery = localStorage.getItem(LOGS_RECORD_NAME);
    }
    if (from) {
      setFromISO(from.toString());
    } else {
      setFromISO(moment().subtract(5, "minute").toISOString());
    }
    if (to) {
      setToISO(to.toString());
    }
    if (initialQuery) {
      queryInputRef.current.setInitialValue(initialQuery);
      queryInputRef.current.reset();
    } else {
      queryInputRef.current.setInitialValue(DEFAULT_LOG_QUERY);
      queryInputRef.current.reset();
    }
    setMounted(true);
  }, []);

  React.useEffect(() => {
    if (!mounted) {
      return;
    }
    if (execute) {
      if (live === "true") {
        onLive();
      } else {
        onQuery();
      }
    }
  }, [mounted]);

  React.useEffect(() => {
    if (!mounted) {
      return;
    }

    if (isQueryFocused) {
      return;
    }

    const _searchParams = new URLSearchParams();
    if (queryInputRef.current.value) {
      _searchParams.set("query", queryInputRef.current.value);
    }
    if (fromISO) {
      _searchParams.set("from", fromISO);
    }
    if (toISO) {
      _searchParams.set("to", toISO);
    }
    setSearchParams(_searchParams);
  }, [queryInputRef.current.value, isQueryFocused, fromISO, toISO]);

  React.useEffect(() => {
    const onBodyClick = function (e: any) {
      const target = e.target as HTMLElement;
      const tableSettingsNode = document.getElementById("table-settings");
      if (tableSettingsNode) {
        const isOutsideClick = !tableSettingsNode.contains(target);
        if (isOutsideClick) {
          setShowTableSettings(false);
        }
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);

  React.useEffect(() => {
    if (location.search.includes("trigger=true")) {
      onQuery();
    }
  }, []);

  React.useEffect(() => {
    const newLiveObject: any = {
      query: queryInputRef.current.value,
    };
    if (fromISO) {
      newLiveObject.start = String(moment(fromISO).unix()) + "000000000";
    }
    const isDirty = !isEqual(liveObject, newLiveObject);
    setIsDirty(isDirty);
  }, [isDisplayingLive, queryInputRef.current.value, fromISO]);

  React.useEffect(() => {
    let maxExpectedDeploymentVersion = Math.max(...deployments.map((d) => d.status?.expectedDeploymentVersion || 0));

    versionSelectRef.current.setOptions([
      { label: "Any Version", value: "any" },
      ...new Array(workload.latestVersion)
        .fill(0)
        .map((_, index) => index + 1)
        .filter((value) => value <= maxExpectedDeploymentVersion)
        .reverse()
        .slice(0, 20)
        .map((value, index) => ({
          label: index === 0 ? String(value) + " (Latest)" : String(value),
          value: String(value),
        })),
      { label: "Other", value: "other" },
    ]);
  }, [workload.latestVersion, queryInputRef.current.value]);

  React.useEffect(() => {
    const disabledLocationLinks = orgLocations.filter((l) => !l.spec.enabled).map((l) => l.selfLink);

    const gvcLocations = orgLocations.filter((l) => gvcLocationLinks.includes(l.selfLink));
    const disabledLocations = orgLocations.filter((l) => !l.spec.enabled);
    const otherLocations = orgLocations.filter(
      (l) => !gvcLocationLinks.includes(l.selfLink) && !disabledLocationLinks.includes(l.selfLink),
    );

    locationSelectRef.current.setOptions(
      [
        { label: "Any Location", value: "any" },

        gvcLocations.length > 0 ? { isLabel: true, label: "GVC Locations", value: "gvcLocations" } : (undefined as any),
        ...gvcLocations.map((loc: any) => ({ label: loc.name, value: loc.name })),

        otherLocations.length > 0
          ? { isLabel: true, label: "Other Locations", value: "otherLocations" }
          : (undefined as any),
        ,
        ...otherLocations.map((loc: any) => ({ label: loc.name, value: loc.name })),

        disabledLocations.length > 0
          ? { isLabel: true, label: "Disabled Locations", value: "disabledLocations" }
          : (undefined as any),
        ,
        ...disabledLocations.map((loc: any) => ({ label: loc.name, value: loc.name })),
        { label: "Custom", value: "custom" },
      ].filter(Boolean),
    );
  }, [orgLocations, queryInputRef.current.value, gvcLocationLinks]);

  const [showTimestampColumn, setShowTimestampColumn] = React.useState<boolean>(() => {
    return window.localStorage.getItem(STORAGE_KEY_LOG_TABLE_TIMESTAMP) === "true";
  });
  const [showLabelsColumn, setShowLabelsColumn] = React.useState<boolean>(() => {
    return window.localStorage.getItem(STORAGE_KEY_LOG_TABLE_LABELS) === "true";
  });
  const [hasAnyUniqueLabels, setHasAnyUniqueLabels] = React.useState<boolean>(true);
  const [wrapLinesRaw, setWrapLines] = React.useState<boolean>(() => {
    return window.localStorage.getItem(STORAGE_KEY_LOG_TABLE_WRAPPED_LINES) === "true";
  });
  const [prettifyJSON, setPrettifyJSON] = React.useState<boolean>(() => {
    return window.localStorage.getItem(STORAGE_KEY_LOG_TABLE_PRETTIFY_JSON) === "true";
  });
  const wrapLines = wrapLinesRaw || prettifyJSON;

  function setQueryKeyValue(key: string, value: string, shouldRemove: boolean) {
    if (!mounted) {
      return;
    }
    let query = queryInputRef.current.value;
    let remainder = "";
    if (query.startsWith("{")) {
      query = query.slice(1);
    }
    if (query.includes("}")) {
      remainder = query.slice(query.indexOf("}") + 1);
      query = query.slice(0, query.indexOf("}"));
    }
    let parts = query.split(",").map((x) => x.trim());

    const keyIndex = parts.findIndex((x) => x.startsWith(`${key}=`));

    if (keyIndex === -1 && !shouldRemove) {
      parts.push(`${key}="${value}"`);
    } else if (keyIndex !== -1) {
      if (shouldRemove) {
        parts.splice(keyIndex, 1);
      } else {
        parts.splice(keyIndex, 1, `${key}="${value}"`);
      }
    }

    const newQuery = `{${parts.filter(Boolean).join(", ")}}${remainder}`;
    queryInputRef.current.setValue(newQuery);
  }

  React.useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY_LOG_TABLE_TIMESTAMP, showTimestampColumn === true ? "true" : "false");
  }, [showTimestampColumn]);

  React.useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY_LOG_TABLE_LABELS, showLabelsColumn === true ? "true" : "false");
  }, [showLabelsColumn]);

  React.useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY_LOG_TABLE_WRAPPED_LINES, wrapLinesRaw === true ? "true" : "false");
  }, [wrapLinesRaw]);

  React.useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY_LOG_TABLE_PRETTIFY_JSON, prettifyJSON === true ? "true" : "false");
  }, [prettifyJSON]);

  React.useEffect(() => {
    return () => {
      if (reconnectIntervalIdRef.current) {
        clearTimeout(reconnectIntervalIdRef.current);
      }
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Cancelled");
      }
      manuallyStopped.current = true;
      onStopLive();
    };
  }, []);

  React.useEffect(() => {
    const from = fromISO ? moment(fromISO).valueOf().toString() : "now-30d";
    const to = toISO ? moment(toISO).valueOf().toString() : "now";

    let range = { from: from, to: to };
    if (selectedPreset) {
      range = { from: `now-${selectedPreset}`, to: "now" };
    }

    const left = encodeURIComponent(
      JSON.stringify({
        queries: [
          {
            expr: queryInputRef.current.value,
            datasource: "logs",
            refId: "A",
          },
        ],
        range: range,
      }),
    );

    const url = `/explore?left=${left}`;
    setRedirect(url);
  }, [queryInputRef.current.value, fromISO, toISO, selectedPreset]);

  // Update query when version dropdown is changed
  React.useEffect(() => {
    if (!mounted) {
      return;
    }
    const versionQuery = versionSelectRef.current.value;
    if (versionQuery === "other") {
      return;
    }
    let query = queryInputRef.current.value;
    let remainder = "";
    if (query.startsWith("{")) {
      query = query.slice(1);
    }
    if (query.includes("}")) {
      remainder = query.slice(query.indexOf("}") + 1);
      query = query.slice(0, query.indexOf("}"));
    }
    let parts = query.split(",").map((x) => x.trim());

    const versionIndex = parts.findIndex((x) => x.startsWith("version="));

    parts = parts.filter((x) => !x.startsWith("version="));
    if (versionQuery !== "any") {
      if (versionIndex === -1) {
        parts.push(`version="${versionQuery}"`);
      } else {
        parts.splice(versionIndex, 0, `version="${versionQuery}"`);
      }
    }

    const newQuery = `{${parts.join(", ")}}${remainder}`;
    queryInputRef.current.setValue(newQuery);
  }, [versionSelectRef.current.value]);

  // Update query when location dropdown is changed
  React.useEffect(() => {
    if (!mounted) {
      return;
    }
    const locationQuery = locationSelectRef.current.value;
    if (locationQuery === "custom") {
      return;
    }
    let query = queryInputRef.current.value;
    let remainder = "";
    if (query.startsWith("{")) {
      query = query.slice(1);
    }
    if (query.includes("}")) {
      remainder = query.slice(query.indexOf("}") + 1);
      query = query.slice(0, query.indexOf("}"));
    }
    let parts = query.split(",").map((x) => x.trim());

    const locationIndex = parts.findIndex((x) => x.startsWith("location="));

    parts = parts.filter((x) => !x.startsWith("location="));
    if (locationQuery !== "any") {
      if (locationIndex === -1) {
        parts.push(`location="${locationQuery}"`);
      } else {
        parts.splice(locationIndex, 0, `location="${locationQuery}"`);
      }
    }

    const newQuery = `{${parts.join(", ")}}${remainder}`;
    queryInputRef.current.setValue(newQuery);
  }, [locationSelectRef.current.value]);

  // Update query when container dropdown or input is changed
  React.useEffect(() => {
    if (!mounted) {
      return;
    }
    const containerQuery = containerSelectRef.current.value;
    if (containerQuery === "custom") {
      return;
    }
    let query = queryInputRef.current.value;
    let remainder = "";
    if (query.startsWith("{")) {
      query = query.slice(1);
    }
    if (query.includes("}")) {
      remainder = query.slice(query.indexOf("}") + 1);
      query = query.slice(0, query.indexOf("}"));
    }
    let parts = query.split(",").map((x) => x.trim());

    const containerIndex = parts.findIndex((x) => x.startsWith("container="));

    parts = parts.filter((x) => !x.startsWith("container="));

    if (containerQuery !== "any") {
      if (containerIndex === -1) {
        parts.push(`container="${containerQuery}"`);
      } else {
        parts.splice(containerIndex, 0, `container="${containerQuery}"`);
      }
    }
    const newQuery = `{${parts.join(", ")}}${remainder}`;
    queryInputRef.current.setValue(newQuery);
  }, [containerSelectRef.current.value]);

  // Update location dropdown when query input is changed, only if dropdown and query is not matched
  React.useEffect(() => {
    let query = queryInputRef.current.value;

    // clear local storage data when it's cleared by user
    if (!query) {
      localStorage.removeItem(LOGS_RECORD_NAME);
    }

    if (query.startsWith("{")) {
      query = query.slice(1);
    }
    if (query.includes("}")) {
      query = query.slice(0, query.indexOf("}"));
    }
    const parts = query.split(",").map((x) => x.trim());
    const locationPart = parts.find((x) => x.startsWith("location="));
    if (!locationPart) {
      locationSelectRef.current.setValue("any");
      return;
    }
    const locationValue = locationPart.split("=")[1].replaceAll('"', "");
    if (locationValue !== locationSelectRef.current.value) {
      const orgLocation = orgLocations.find((l) => l.name === locationValue);
      if (orgLocation) {
        locationSelectRef.current.setValue(orgLocation.name);
      } else {
        locationSelectRef.current.setValue("custom");
      }
    }
  }, [queryInputRef.current.value, orgLocations]);

  // Update version dropdown when query input is changed, only if dropdown and query is not matched
  React.useEffect(() => {
    let query = queryInputRef.current.value;
    if (query.startsWith("{")) {
      query = query.slice(1);
    }
    if (query.includes("}")) {
      query = query.slice(0, query.indexOf("}"));
    }
    const parts = query.split(",").map((x) => x.trim());
    const versionPart = parts.find((x) => x.startsWith("version="));
    if (!versionPart) {
      versionSelectRef.current.setValue("any");
      return;
    }
    const versionValue = versionPart.split("=")[1].replaceAll('"', "");
    const versionNames = versionSelectRef.current.options.map((o) => o.value);
    if (versionValue !== versionSelectRef.current.value) {
      if (versionNames.includes(versionValue)) {
        versionSelectRef.current.setValue(versionValue);
      } else {
        versionSelectRef.current.setValue("other");
      }
    }
  }, [queryInputRef.current.value]);

  // Update container dropdown when query input is changed, only if dropdown and query is not matched
  React.useEffect(() => {
    let query = queryInputRef.current.value;
    if (query.startsWith("{")) {
      query = query.slice(1);
    }
    if (query.includes("}")) {
      query = query.slice(0, query.indexOf("}"));
    }
    const parts = query.split(",").map((x) => x.trim());
    const containerPart = parts.find((x) => x.startsWith("container="));
    if (!containerPart) {
      containerSelectRef.current.setValue("any");
      return;
    }
    const containerValue = containerPart.split("=")[1].replaceAll('"', "");
    const containerNames = [...workload.containerNames, "_accesslog"];
    if (containerValue !== containerSelectRef.current.value) {
      if (containerNames.includes(containerValue)) {
        containerSelectRef.current.setValue(containerValue);
      } else {
        containerSelectRef.current.setValue("custom");
      }
    }
  }, [queryInputRef.current.value]);

  React.useEffect(() => {
    setOrgGVCLocations();
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  async function setOrgGVCLocations() {
    try {
      let orgLocationLink: string | undefined = homeLink("location");
      let locations: LocationMobx[] = [];
      while (orgLocationLink) {
        const { data } = await request({ url: orgLocationLink });
        for (let item of data.items) {
          try {
            locations.push(LocationModel.create(item));
          } catch (e) {
            throw e;
          }
        }
        orgLocationLink = linksOf(data).next;
      }
      setOrgLocations(locations);
    } catch (e) {
      console.error("failed to set org locations");
    }
    try {
      let gvcLink: string | undefined = resourceLink("gvc", gvc!);
      const { data } = await request({ url: gvcLink });
      setGVCLocationLinks(data.spec.staticPlacement?.locationLinks || []);
    } catch (e) {
      console.error("failed to set gvc location links");
    }
  }

  React.useEffect(() => {
    logEntriesRef.current = logEntries;

    const allLabels: string[] = [];
    const _commonLabels: string[] = [];

    for (const log of logEntries) {
      for (const label of log.labels) {
        const l = `${label[0]}=${label[1]}`;
        if (!allLabels.includes(l)) {
          allLabels.push(l);
        }
      }
    }

    for (const label of allLabels) {
      let missing = false;
      for (const log of logEntries) {
        const logLabels = log.labels.map((l) => `${l[0]}=${l[1]}`);
        if (!logLabels.includes(label)) {
          missing = true;
        }
      }
      if (!missing) {
        _commonLabels.push(label);
      }
    }

    _commonLabels.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });

    const logEntriesCopy: LogEntry[] = JSON.parse(JSON.stringify(logEntries));
    for (let logEntry of logEntriesCopy) {
      logEntry.labels = logEntry.labels
        .filter((l) => {
          const ll = `${l[0]}=${l[1]}`;
          if (_commonLabels.includes(ll)) {
            return false;
          }
          return true;
        })
        .sort((a, b) => {
          if (a[0] > b[0]) {
            return 1;
          }
          if (a[0] < b[0]) {
            return -1;
          }
          return 0;
        });
    }

    let _hasAnyUniqueLabels = false;
    for (let logEntry of logEntriesCopy) {
      if (logEntry.labels.length > 0) {
        _hasAnyUniqueLabels = true;
        break;
      }
    }

    setCommonLabels(_commonLabels);
    setHasAnyUniqueLabels(_hasAnyUniqueLabels);
  }, [logEntries]);

  React.useEffect(() => {
    let oldEntries = [...logEntries];
    oldEntries = oldEntries.concat([...liveLogEntriesRef.current]);
    liveLogEntriesRef.current = [];
    setLogEntries(oldEntries.slice(0, 1000));
    clearCaches();
  }, [liveCounter]);

  async function onQuery() {
    setFetchedWithLive(false);
    setIsDisplayingLive(false);
    if (socketRef.current) {
      socketRef.current.removeEventListener("open", socketOnOpen);
      socketRef.current.removeEventListener("close", socketOnClose);
      socketRef.current.removeEventListener("error", socketOnError);
      socketRef.current.removeEventListener("message", socketOnMessage);
      socketRef.current.close();
    }
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Cancelled");
    }
    try {
      setLogEntries([]);
      setIsLoading(true);
      const params: { [_: string]: string } = {
        limit: "1000",
        query: queryInputRef.current.value,
      };
      if (fromISO) {
        params.start = String(moment(fromISO).unix()) + "000000000";
      }
      if (toISO) {
        params.end = String(moment(toISO).unix()) + "000000000";
      }
      const querystring = qs.stringify(params);
      const url = `/logs/org/${org}/loki/api/v1/query_range?${querystring}`;
      cancelTokenRef.current = getCancelToken();
      const cancelToken = cancelTokenRef.current.token;
      const { data } = await request<LogsQueryResponse>({ service: "logs", url, cancelToken });
      localStorage.setItem(LOGS_RECORD_NAME, queryInputRef.current.value);
      const entries: LogEntry[] = [];
      data.data.result.forEach((stream) => {
        stream.values.forEach(([timestampString, log]) => {
          const labels = JSON.parse(JSON.stringify(stream.stream));
          const processedLabels: [string, string][] = [];
          if (labels.location) {
            processedLabels.push(["location", labels.location]);
            delete labels.location;
          }
          if (labels.container) {
            processedLabels.push(["container", labels.container]);
            delete labels.container;
          }
          if (labels.workload) {
            processedLabels.push(["workload", labels.workload]);
            delete labels.workload;
          }
          Object.entries(labels).forEach(([key, value]) => {
            processedLabels.push([key, value as string]);
          });
          entries.push({
            id: uuidv4(),
            labels: processedLabels,
            log,
            timestamp: parseInt(String(Number(timestampString) / 1000000)),
          });
        });
      });
      setLogEntries(entries.slice(0, 1000));
      clearCaches();
      setIsDisplayingQuery(true);
      setIsLoading(false);
    } catch (e) {
      if (e.message === "Cancelled") {
        console.error("onQuery cancelled");
        return;
      }
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage && e.response.data && typeof e.response.data === "string") {
        errorMessage = e.response.data;
      }
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function downloadLogs(format: "json" | "txt") {
    try {
      let blob = "";

      const sortedAndReversedLogEntries = logEntries.sort((a, b) => {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      });

      if (orderBy === "asc") {
        sortedAndReversedLogEntries.reverse();
      }

      if (format === "json") {
        const jsonVersion = sortedAndReversedLogEntries.map((logEntry) => ({
          line: logEntry.log,
          timestamp: String(logEntry.timestamp * 1 * 1000 * 1000), // we lose the precision when we divide by a million
          fields: stringPairsToObject(logEntry.labels.map((l) => l.join("="))),
        }));
        blob = JSON.stringify(jsonVersion, null, 2);
      } else if (format === "txt") {
        // There was also 'Total bytes processed: "822  B"'
        blob += `Common Labels: ${JSON.stringify(stringPairsToObject(commonLabels))} \n`;
        blob += `Line limit: "1000 (${sortedAndReversedLogEntries.length} returned)"\n`;
        blob += `\n\n"`;
        sortedAndReversedLogEntries.forEach((logEntry) => {
          blob += `${new Date(logEntry.timestamp).toISOString()} ${logEntry.log}\n`;
        });
      }

      autoDownloadWithElementA(blob, format);
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  function autoDownloadWithElementA(blobData: any, format: "json" | "txt") {
    const file = new Blob([blobData], { type: `text/${format === "txt" ? "plain" : "json"}` });
    const href = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style.display = "none";
    a.classList.add("cpln-temp-a");
    a.download = `${workload.name}-logs-${new Date().toISOString()}.${format}`;
    a.href = href;
    a.click();
  }

  function clearCaches() {
    Object.values(cachesRef.current).map((cache) => cache.clearAll());
  }

  const socketOnOpen = () => {};
  const socketOnClose = () => {
    if (manuallyStopped.current === false) {
      const id = setTimeout(() => {
        onLive(true);
      }, 3000);
      reconnectIntervalIdRef.current = id;
    } else {
      manuallyStopped.current = false;
    }
  };
  const socketOnError = () => {
    manuallyStopped.current = true;
    onStopLive();
    notification.warning({
      message: "Failed",
      description: "Unable to to make websocket connection to read logs. You might not have access to read logs",
    });
  };
  const socketOnMessage = (event: any) => {
    try {
      const res: LogsLiveResponse = JSON.parse(event.data);
      const entries: LogEntry[] = [];
      res.streams.forEach((stream) => {
        stream.values.forEach(([timestampString, log]) => {
          const labels = JSON.parse(JSON.stringify(stream.stream));
          const processedLabels: [string, string][] = [];
          if (labels.location) {
            processedLabels.push(["location", labels.location]);
            delete labels.location;
          }
          if (labels.container) {
            processedLabels.push(["container", labels.container]);
            delete labels.container;
          }
          if (labels.workload) {
            processedLabels.push(["workload", labels.workload]);
            delete labels.workload;
          }
          Object.entries(labels).forEach(([key, value]) => {
            processedLabels.push([key, value as string]);
          });
          entries.push({
            id: uuidv4(),
            labels: processedLabels,
            log,
            timestamp: parseInt(String(Number(timestampString) / 1000000)),
          });
        });
      });
      liveLogEntriesRef.current = entries;
      setLiveCounter((x) => x + 1);
    } catch (e) {}
  };

  async function onLive(reconnect = false) {
    setIsPrefetchingLive(true);
    let wsToken = "";
    if (reconnect) {
      let _accessToken = "pass";
      while (_accessToken === "pass") {
        const { accessToken } = await getLocalToken();
        _accessToken = accessToken;
      }
      wsToken = _accessToken;
    } else {
      wsToken = await getToken();
    }

    const params: { [_: string]: string } = {
      limit: "1000",
      query: reconnect ? lastQuery : queryInputRef.current.value,
      token: wsToken,
    };
    const liveObject: any = {
      query: params.query,
    };
    if (fromISO) {
      params.start = String(moment(fromISO).unix()) + "000000000";
      liveObject.start = String(moment(fromISO).unix()) + "000000000";
    }
    if (reconnect) {
      params.start = String(logEntries[0].timestamp) + "000000";
      liveObject.start = String(logEntries[0].timestamp) + "000000";
    }
    const querystring = qs.stringify(params);

    if (!lastQueries.includes(params.query)) {
      try {
        const url = `/logs/org/${org}/loki/api/v1/query?${querystring}`;
        cancelTokenRef.current = getCancelToken();
        const cancelToken = cancelTokenRef.current.token;
        await request<LogsQueryResponse>({ service: "logs", url, cancelToken });
        setLastQueries((lq) => [params.query, ...lq.slice(0, 9)]);
        setIsPrefetchingLive(false);
      } catch (e) {
        let errorMessage = e?.response?.data;
        if (!errorMessage) {
          errorMessage = e.message;
        }
        notification.warning({ message: "Failed", description: errorMessage });
        setIsPrefetchingLive(false);
        return;
      }
    }

    setFetchedWithLive(true);
    if (!reconnect) {
      if (queryInputRef.current.value.length < 1) {
        return;
      }
      setLastQuery(queryInputRef.current.value);
    }
    setIsDisplayingQuery(false);
    if (!reconnect) {
      setLogEntries([]);
    }
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Cancelled");
    }
    if (socketRef.current) {
      socketRef.current.removeEventListener("open", socketOnOpen);
      socketRef.current.removeEventListener("close", socketOnClose);
      socketRef.current.removeEventListener("error", socketOnError);
      socketRef.current.removeEventListener("message", socketOnMessage);
      socketRef.current.close();
      if (!reconnect) {
        setIsDisplayingLive(false);
      }
    }
    try {
      setIsLoading(true);
      const baseUrl = `${Discovery.endpoints.logs.replace("https://", "wss://")}/logs/org/${org}`;
      setLiveObject(liveObject);
      const url = `${baseUrl}/loki/api/v1/tail?${querystring}`;
      socketRef.current = new WebSocket(url);
      socketRef.current.addEventListener("open", socketOnOpen);
      socketRef.current.addEventListener("close", socketOnClose);
      socketRef.current.addEventListener("error", socketOnError);
      socketRef.current.addEventListener("message", socketOnMessage);
      setIsDisplayingLive(true);
      localStorage.setItem(LOGS_RECORD_NAME, queryInputRef.current.value);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }

  function onStopLive() {
    if (socketRef.current) {
      socketRef.current.removeEventListener("open", socketOnOpen);
      socketRef.current.removeEventListener("close", socketOnClose);
      socketRef.current.removeEventListener("error", socketOnError);
      socketRef.current.removeEventListener("message", socketOnMessage);
      socketRef.current.close();
    }
    setIsDisplayingLive(false);
  }

  function onRestartLive() {
    manuallyStopped.current = true;
    onStopLive();
    onLive();
  }

  function handleExploreOnGrafana(e: any) {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.submit();
    }
  }

  function toggleTableSetting(e: any) {
    if (e.target.nodeName.includes("LABEL") || e.target.nodeName.includes("INPUT")) {
      return;
    }
    setShowTableSettings((x) => !x);
  }

  const keyMapperList: KeyMapper = (rowIndex: number) => {
    const log = logEntriesRef.current[rowIndex];
    if (log) {
      return log.id;
    } else {
      return rowIndex;
    }
  };

  const keyMapperGrid: KeyMapper = (rowIndex: number, columnIndex: number) => {
    return rowIndex + "-" + columnIndex;
  };

  let cacheKey = `log`;
  if (showTimestampColumn) {
    cacheKey += "-ts";
  }
  if (showLabelsColumn && hasAnyUniqueLabels) {
    cacheKey += "-l";
  }
  if (wrapLines) {
    cacheKey += "-w";
  }
  if (prettifyJSON) {
    cacheKey += "-p";
  }

  // log // width
  // log-ts // width
  // log-l // width
  // log-w // height
  // log-ts-l // width
  // log-ts-w // height
  // log-l-w // height
  // log-w-p // height
  // log-ts-l-w // height
  // log-ts-w-p // height
  // log-l-w-p // height
  // log-ts-l-w-p // height
  const cachesRef = React.useRef<{ [_: string]: CellMeasurerCache }>({
    log: new CellMeasurerCache({
      fixedHeight: true,
      minWidth: 185,
      keyMapper: keyMapperGrid,
    }),
    "log-ts": new CellMeasurerCache({
      fixedHeight: true,
      minWidth: 185,
      keyMapper: keyMapperGrid,
    }),
    "log-l": new CellMeasurerCache({
      fixedHeight: true,
      minWidth: 185,
      keyMapper: keyMapperGrid,
    }),
    "log-w": new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 33,
      keyMapper: keyMapperList,
    }),
    "log-ts-l": new CellMeasurerCache({
      fixedHeight: true,
      minWidth: 185,
      keyMapper: keyMapperGrid,
    }),
    "log-ts-w": new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 33,
      keyMapper: keyMapperList,
    }),
    "log-l-w": new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 33,
      keyMapper: keyMapperList,
    }),
    "log-w-p": new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 33,
      keyMapper: keyMapperList,
    }),
    "log-ts-l-w": new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 33,
      keyMapper: keyMapperList,
    }),
    "log-ts-w-p": new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 33,
      keyMapper: keyMapperList,
    }),
    "log-l-w-p": new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 33,
      keyMapper: keyMapperList,
    }),
    "log-ts-l-w-p": new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 33,
      keyMapper: keyMapperList,
    }),
  });

  const _logEntries = [...logEntries];
  if (fetchedWithLive) {
    _logEntries.reverse();
  }
  const logs: LogEntry[] = [
    { id: "labels", labels: [], log: "", timestamp: 0 },
    ..._logEntries
      .map((logEntry) => ({ ...logEntry, labels: getUniqueLabels(logEntry, commonLabels) }))
      .sort((a, b) => {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }),
  ];

  const labelsWidth = getLabelsWidth(logs);

  return (
    <div>
      <form
        className="hidden"
        ref={formRef}
        action={`${Discovery.endpoints.grafana.replace("{org}", org!)}/auth/process`}
        method="POST"
        target="_blank"
      >
        <input name="redirect" value={redirect} type="hidden" />
        <input name="idToken" value={logsTokenFromLocal} type="hidden" />
        <input name="genericToken" value={logsTokenFromLocal} type="hidden" />
        <button type="submit">explore</button>
      </form>
      <div className="flex items-center mb-4">
        <div className="flex-grow">
          <NGLabel>LogQL Query</NGLabel>
          <NGInput
            value={queryInputRef.current.value}
            onChange={(e) => queryInputRef.current.setValue(e.target.value)}
            showClear
            onFocus={() => setIsQueryFocused(true)}
            onBlur={() => setIsQueryFocused(false)}
            onKeyDown={(e) => {
              if (e.shiftKey && e.key === "Enter") {
                onQuery();
              }
            }}
          />
        </div>
        <NGAlert
          type={"info"}
          style={{ width: 355 }}
          size="small"
          className="ml-4 mt-6"
          render={() => (
            <a href={"https://grafana.com/docs/loki/latest/logql/"} target={"_blank"} className={`ngfocus color-link`}>
              <ExternalLink
                className="inline color-link"
                style={{ width: 20, height: 15 }}
                onClick={() => window.open("https://grafana.com/docs/loki/latest/logql/", "_blank")}
              />
              Use the full power of LogQL
            </a>
          )}
        />
      </div>
      <div className="row-1 flex items-center mb-4">
        <div>
          <NGLabel>Time Range ({Timezone.label})</NGLabel>
          <RangePicker
            className="mr-4"
            presets={getLogsPresets()}
            size={"small"}
            placeholder={["From", "To"]}
            showTime
            allowEmpty={[true, true]}
            fromISO={fromISO}
            setFromISO={setFromISO}
            toISO={toISO}
            setToISO={setToISO}
            timezoneValue={Timezone.value}
          />
        </div>
        <div className="mr-4 flex-grow basis-0">
          <NGLabel>{locationSelectRef.current.label}</NGLabel>
          <NGSelect
            onChange={locationSelectRef.current.setValue}
            options={locationSelectRef.current.options}
            value={locationSelectRef.current.value}
            labelOptions={["gvcLocations", "otherLocations", "disabledLocations"]}
            disabledOptions={["disabled2Locations"]}
          />
        </div>
        <div className="mr-4 flex-grow basis-0">
          <NGLabel>{containerSelectRef.current.label}</NGLabel>
          <NGSelect
            onChange={containerSelectRef.current.setValue}
            options={containerSelectRef.current.options}
            value={containerSelectRef.current.value}
          />
        </div>
        <div className="flex-grow basis-0">
          <NGLabel>{versionSelectRef.current.label}</NGLabel>
          <NGSelect
            onChange={versionSelectRef.current.setValue}
            options={versionSelectRef.current.options}
            value={versionSelectRef.current.value}
          />
        </div>
      </div>
      <div className="flex items-center justify-end mb-4">
        <button id="table-settings" className={`flex items-center relative ngfocus`} onClick={toggleTableSetting}>
          <span>Settings </span>
          <Settings className="feather-icon ml-2" />
          {showTableSettings ? (
            <div
              className="border absolute right-0 flex flex-col shadow-lg"
              style={{ left: 0, top: "120%", width: "200%", zIndex: 100, backgroundColor: "var(--color-drop)" }}
            >
              <label htmlFor={"table-settings-timestamp"} className="px-4 py-2 border-b flex items-center">
                <input
                  className="mr-1"
                  id={"table-settings-timestamp"}
                  type={"checkbox"}
                  checked={showTimestampColumn}
                  onChange={() => setShowTimestampColumn((x) => !x)}
                />
                Timestamp
              </label>
              <label htmlFor={"table-settings-labels"} className="px-4 py-2 border-b flex items-center">
                <input
                  className="mr-1"
                  id={"table-settings-labels"}
                  type={"checkbox"}
                  checked={showLabelsColumn}
                  onChange={() => setShowLabelsColumn((x) => !x)}
                />
                Labels
              </label>
              <label htmlFor={"table-settings-wrapped-lines"} className="px-4 py-2 border-b flex items-center">
                <input
                  className="mr-1"
                  id={"table-settings-wrapped-lines"}
                  type={"checkbox"}
                  checked={wrapLines}
                  disabled={prettifyJSON}
                  onChange={() => setWrapLines((x) => !x)}
                />
                Wrapped Lines
              </label>
              <label htmlFor={"table-settings-prettify-json"} className="px-4 py-2 border-b flex items-center">
                <input
                  className="mr-1"
                  id={"table-settings-prettify-json"}
                  type={"checkbox"}
                  checked={prettifyJSON}
                  onChange={() => setPrettifyJSON((x) => !x)}
                />
                Prettify JSON
              </label>
            </div>
          ) : null}
        </button>
        <div className="flex items-center flex-wrap gap-1 ml-4" style={{ maxWidth: 500 }}>
          {commonLabels.map((l) => (
            <Tag
              small
              name={l.split("=")[0]}
              value={l.split("=")[1]}
              style={{ padding: "2px 4px" }}
              colorize
              onClick={(e: React.MouseEvent) => setQueryKeyValue(l.split("=")[0], l.split("=")[1], e.altKey)}
            />
          ))}
        </div>
        <div className="flex-grow" />
        <a href={""} onClick={handleExploreOnGrafana} className="ngfocus color-link mr-4">
          <span>Explore on Grafana</span>
          <ExternalLink className="feather-icon ml-1 inline-block" style={{ transform: "translateY(2px)" }} />
        </a>
        {isDisplayingLive ? (
          isDirty ? (
            <div
              className="live-restart flex items-center justify-center mr-2 border border-danger color-danger rounded font-medium focus"
              style={{ width: 130, height: 34 }}
            >
              <button
                style={{ width: "70%", borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
                className="restart h-full inline-flex items-center justify-center"
                onClick={onRestartLive}
              >
                <span className="rounded-full mr-1 fast-pulse inline-block" style={{ width: 10, height: 10 }} />
                Restart
              </button>
              <button
                style={{ width: "30%", borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
                className="stop inline-flex items-center justify-center border-l border-danger h-full"
                onClick={() => {
                  manuallyStopped.current = true;
                  onStopLive();
                }}
              >
                <span style={{ width: 10, height: 10 }} />
              </button>
            </div>
          ) : (
            <button
              className="live-stop flex items-center justify-center px-4 mr-2 border rounded font-medium"
              style={{ width: 130, height: 34 }}
              onClick={() => {
                manuallyStopped.current = true;
                onStopLive();
              }}
            >
              <span className="rounded-full mr-1 fast-pulse" style={{ width: 10, height: 10 }} />
              Stop
            </button>
          )
        ) : (
          <button
            className={`flex items-center justify-center px-4 mr-2 border logs-live ${
              isPrefetchingLive || queryInputRef.current.value.length < 1 ? "logs-live-disabled" : "logs-live-active"
            }`}
            disabled={isPrefetchingLive || queryInputRef.current.value.length < 1}
            style={{ width: 130, height: 34 }}
            onClick={() => onLive()}
          >
            {isPrefetchingLive ? <FeatherLoader className="spin h-4 w-4 mr-1" /> : <Play className="h-4 w-4 mr-1" />}
            Live
          </button>
        )}
        <div className="flex items-center" style={{ width: 170 }}>
          <NGButton
            disabled={queryInputRef.current.value.length < 1 || isDisplayingLive}
            style={{ width: logEntries.length < 1 || isDisplayingLive ? 170 : 120 }}
            variant={"primary"}
            onClick={onQuery}
          >
            Query
          </NGButton>
          {!isDisplayingLive && logEntries.length > 0 ? (
            <Dropdown
              trigger={["click"]}
              menu={{
                onClick: ({ key }: any) => {
                  switch (key) {
                    case "export-json":
                      downloadLogs("json");
                      break;
                    case "export-txt":
                      downloadLogs("txt");
                      break;
                    default:
                      break;
                  }
                },
                items: [
                  { key: "export-json", label: "JSON" },
                  { key: "export-txt", label: "TXT" },
                ],
              }}
            >
              <NGButton
                style={{ width: 45, marginLeft: 5 }}
                variant={"secondary"}
                renderIcon={(_, props) => <Download {...props} />}
              />
            </Dropdown>
          ) : null}
        </div>
      </div>
      {isLoading ? (
        <div className="mt-16 relative">
          <Loader reason={"Fetching logs"} />
        </div>
      ) : (
        <>
          {logEntries.length === 0 ? (
            isDisplayingQuery ? (
              <div className="flex flex-col items-center p-4">
                <Search className="mb-2" />
                <p className="text-center">No Logs Found</p>
              </div>
            ) : isDisplayingLive ? (
              <div className="flex flex-col items-center p-4">
                <Search className="mb-2" />
                <p className="text-center">Awaiting Logs</p>
              </div>
            ) : null
          ) : null}

          <div
            ref={sizerRef}
            className={`w-full ${logs.length > 1 ? "border" : ""} logs-loading`}
            style={{ height: emptySpaceHeight }}
          >
            {logs.length < 2 ? null : wrapLines ? (
              <LogList
                key={cacheKey}
                cache={cachesRef.current[cacheKey]}
                width={sizerDimensions.width}
                height={emptySpaceHeight}
                logs={logs}
                timestamp={showTimestampColumn}
                labels={showLabelsColumn && hasAnyUniqueLabels}
                labelsWidth={labelsWidth}
                setQueryKeyValue={setQueryKeyValue}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                prettifyJSON={prettifyJSON}
              />
            ) : (
              <LogGrid
                key={cacheKey}
                cache={cachesRef.current[cacheKey]}
                width={sizerDimensions.width}
                height={emptySpaceHeight}
                logs={logs}
                timestamp={showTimestampColumn}
                labels={showLabelsColumn && hasAnyUniqueLabels}
                setQueryKeyValue={setQueryKeyValue}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const WorkloadLogs = observer(WorkloadLogsRaw);
