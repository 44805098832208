import * as React from "react";
import { homeLink, linksOf, request } from "../../services/cpln";
import { Table } from "../../newcomponents/table/table";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { Agent } from "../../schema/types/agent";
import { Identity } from "../../schema/types/identity";
import { WorkloadGvcColumn } from "../../newcomponents/table/columns/workload/gvcColumn";
import { NameDescriptionColumn } from "../../newcomponents/table/columns/wellKnown/nameDescriptionColumn";
import { ngParseLink } from "../../utils/linkParser/linkParser";

interface IdentityTableItem extends Identity {
  gvc: string;
}

interface Props {
  agent: Agent;
  gvc: string;
}
export const Used: React.FC<Props> = ({ agent, gvc }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [identities, setIdentities] = React.useState<Identity[]>([]);

  React.useEffect(() => {
    fetchIdentities();
  }, []);

  async function fetchIdentities() {
    try {
      setIsLoading(true);
      setIdentities([]);
      let _identities: Identity[] = [];
      let link = homeLink("identity");
      while (link) {
        const { data } = await request({ url: link });
        link = data.links.find((l: any) => l.rel === "next")?.href;
        _identities = _identities.concat(data.items);
      }
      setIdentities(_identities);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  const usedIdentities: IdentityTableItem[] = identities
    .map((identity) => {
      const gvc = linksOf(identity).self!.split("/")[4];
      return { ...identity, gvc: gvc };
    })
    .filter((identity) => identity.networkResources?.some((nr) => nr.agentLink === linksOf(agent).self!));

  return (
    <Table<IdentityTableItem>
      title={"Used in Identities"}
      data={usedIdentities}
      isLoading={isLoading}
      tableId={"location-used-in-gvcs"}
      enableFilter
      columns={[
        NameDescriptionColumn(undefined, {
          shouldSkipLink: (item) => {
            try {
              const gvcLink = linksOf(item).gvc!;
              const { name } = ngParseLink(gvcLink, { useInputCtx: true });
              return gvc !== name;
            } catch (e) {
              return true;
            }
          },
        }),
        WorkloadGvcColumn() as any,
        TagsColumn(),
        CreatedColumn(),
        LastModifiedColumn(),
      ]}
    />
  );
};
