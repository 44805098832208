import { observer } from "mobx-react-lite";
import * as React from "react";
import { Table } from "../../../newcomponents/table/table";
import { useTableKindId } from "../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../newcomponents/table/data/useTableItemQueryData";
import { NameDescriptionNoLinkColumn } from "../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { ProviderColumn } from "../../../newcomponents/table/columns/wellKnown/providerColumn";
import { TagsColumn } from "../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../newcomponents/table/columns/wellKnown/createdColumn";
import { Location } from "./../../../schema/types/location";
import { TableHeaderRefreshButton } from "../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../newcomponents/table/components/TableCplnQuery";
import { linksOf } from "../../../services/cpln";
import { LocationEnabledColumn } from "../../../newcomponents/table/columns/location/enabledColumn";
import { NGButton } from "../../../newcomponents/button/Button";
import { PlusCircle } from "react-feather";
import { TableModal } from "../../../components/antd/TableModal";
import { sortBy } from "lodash";
import { LastModifiedColumn } from "../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";

interface LocationTableItem extends Location {
  selfLink: string;
}

interface Props {
  setLocationLinks: React.Dispatch<React.SetStateAction<string[]>>;
}
const GVCCreateLocationsRaw: React.FC<Props> = ({ setLocationLinks }) => {
  const { kind, id } = useTableKindId("location", "gvc-create-location-add-update-1");
  const qData = useTableItemQueryData<Location>(kind, { fetchAllPages: true });

  const [currentItems, setCurrentItems] = React.useState<LocationTableItem[]>([]);
  function getCurrentLinks(): string[] {
    return currentItems.map((i) => i.selfLink);
  }
  React.useEffect(() => {
    setLocationLinks(getCurrentLinks());
  }, [currentItems, currentItems.length]);

  const [selectionsToRemove, setSelectionsToRemove] = React.useState<string[]>([]);
  const [selectionsToAdd, setSelectionsToAdd] = React.useState<string[]>([]);
  const [selectionItemsToAdd, setSelectionItemsToAdd] = React.useState<LocationTableItem[]>([]);
  const [isAddingItem, setIsAddingItem] = React.useState(false);

  function onRemove() {
    setCurrentItems((items) => items.filter((item) => !selectionsToRemove.includes(item.selfLink)));
    setSelectionsToRemove([]);
  }

  function onOkAddModal() {
    setCurrentItems((items) => {
      const newItems: LocationTableItem[] = [...items];
      const currentLinks = items.map((i) => i.selfLink);

      for (const itemToAdd of selectionItemsToAdd) {
        if (currentLinks.includes(itemToAdd.selfLink)) {
          continue;
        }

        newItems.push(itemToAdd);
      }

      return sortBy(newItems, "name");
    });

    setSelectionsToAdd([]);
    setSelectionItemsToAdd([]);
  }

  const locationTableItems: LocationTableItem[] = qData.visibleItems
    .map((item) => ({
      ...item,
      selfLink: linksOf(item).self!,
    }))
    .filter((item) => !getCurrentLinks().includes(item.selfLink))
    .filter((item) => item.spec?.enabled === true);

  return (
    <>
      <Table<LocationTableItem>
        tableId={"gvc-create-locations"}
        title={"GVC Locations"}
        data={currentItems}
        selectMode="multi"
        selectKey="selfLink"
        selections={selectionsToRemove}
        onSelectionsChange={setSelectionsToRemove}
        columns={[
          NameDescriptionNoLinkColumn(), //
          ProviderColumn(),
          LocationEnabledColumn(),
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
        headerRenderer={() => {
          return (
            <>
              <NGButton
                variant={"danger"}
                size={"small"}
                outlined
                disabled={selectionsToRemove.length < 1}
                onClick={onRemove}
              >
                Remove
              </NGButton>
              <NGButton variant={"action"} size={"small"} onClick={() => setIsAddingItem(true)}>
                Add
              </NGButton>
            </>
          );
        }}
        noItemsFoundRenderer={(table) => (
          <NGButton
            variant="primary"
            onClick={() => setIsAddingItem(true)}
            renderIcon={(_, props) => <PlusCircle {...props} />}
          >
            Add GVC Locations
          </NGButton>
        )}
      />
      <TableModal
        open={isAddingItem}
        onCancel={() => setIsAddingItem(false)}
        onOk={() => {
          onOkAddModal();
          setIsAddingItem(false);
        }}
      >
        <Table<LocationTableItem>
          tableId={id}
          title={"Select Locations to Add"}
          selectMode="multi"
          selectKey="selfLink"
          selections={selectionsToAdd}
          onSelectionsChange={(keys, items) => {
            setSelectionsToAdd(keys);
            setSelectionItemsToAdd(items!);
          }}
          data={locationTableItems}
          headerRenderer={() => {
            return (
              <>
                <TableHeaderRefreshButton onClick={qData.fetchItems} />
                <CustomTableCPLNQuery
                  tableId={id}
                  kind={kind}
                  onQuery={qData.setQuery}
                  isLoading={qData.isLoadingQueried}
                />
              </>
            );
          }}
          enableFilter
          isLoading={qData.isLoading}
          columns={[
            NameDescriptionNoLinkColumn(),
            ProviderColumn(),
            TagsColumn(),
            CreatedColumn(),
            LastModifiedColumn(),
          ]}
        />
      </TableModal>
    </>
  );
};

export const GVCCreateLocations = observer(GVCCreateLocationsRaw);
