import * as React from "react";
import { AgentTable } from "./agent";
import { AuditContextTable } from "./auditctx";
import { CloudaccountTable } from "./cloudaccount";
import { DomainTable } from "./domain";
import { GroupTable } from "./group";
import { GvcTable } from "./gvc";
import { IdentityTable } from "./identity";
import { ImageTable } from "./image";
import { KubernetesTable } from "./mk8s";
import { LocationTable } from "./location";
import { PolicyTable } from "./policy";
import { QuotaTable } from "./quota";
import { SecretTable } from "./secret";
import { ServiceaccountTable } from "./serviceaccount";
import { UserTable } from "./user";
import { VolumesetTable } from "./volumeset";
import { WorkloadTable } from "./workload";
import { Base, Kind } from "../../../schema/types/base";

interface TargetTableItem extends Base {
  selfLink: string;
}

interface Props {
  kind: Kind;
  selectionsToRemove: string[];
  onSelectionsToRemoveChange: (values: string[], items?: TargetTableItem[]) => void;
  selectionsToAdd: string[];
  onSelectionsToAddChange: (values: string[], items?: any[]) => void;
  // Make it generic and customizable per table
  targets: TargetTableItem[];
  onRemove: () => void;
  onOkAddModal: () => void;
}
export const TargetTable: React.FC<Props> = ({
  kind, //
  selectionsToRemove,
  onSelectionsToRemoveChange,
  selectionsToAdd,
  onSelectionsToAddChange,
  targets,
  onRemove,
  onOkAddModal,
}) => {
  const title = "Target Items";

  if (kind === "agent") {
    return (
      <AgentTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "auditctx") {
    return (
      <AuditContextTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "cloudaccount") {
    return (
      <CloudaccountTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "domain") {
    return (
      <DomainTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "group") {
    return (
      <GroupTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "gvc") {
    return (
      <GvcTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "identity") {
    return (
      <IdentityTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "image") {
    return (
      <ImageTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "mk8s") {
    return (
      <KubernetesTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "location") {
    return (
      <LocationTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "policy") {
    return (
      <PolicyTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "quota") {
    return (
      <QuotaTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "secret") {
    return (
      <SecretTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "serviceaccount") {
    return (
      <ServiceaccountTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "user") {
    return (
      <UserTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "volumeset") {
    return (
      <VolumesetTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }
  if (kind === "workload") {
    return (
      <WorkloadTable
        title={title} //
        targets={targets}
        selectionsToRemove={selectionsToRemove}
        onSelectionsToRemoveChange={onSelectionsToRemoveChange}
        selectionsToAdd={selectionsToAdd}
        onSelectionsToAddChange={onSelectionsToAddChange}
        onRemove={onRemove}
        onOkAddModal={onOkAddModal}
      />
    );
  }

  return <span>Not supported</span>;
};
