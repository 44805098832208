import { types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";

export const Mk8sDraftProviderAWSAMIReadOnlyModel = types.model({
  recommended: types.optional(types.string, ""),
  exact: types.optional(types.string, ""),
});

export const Mk8sDraftProviderAWSAMIModel = types
  .model({
    recommended: types.optional(types.string, ""),
    exact: types.optional(StringModel, () =>
      StringModel.create({ label: "Exact Image", validationKey: "mk8sAwsAmiExact" })
    ),
    type: types.optional(types.enumeration(["recommended", "exact"]), "recommended"),
  })
  .actions((self) => ({
    setType(newType: "recommended" | "exact") {
      self.type = newType;
    },
    setRecommended(value: string) {
      self.recommended = value;
    },
  }));
