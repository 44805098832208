import { flow, Instance, types } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NGSIdentityDataSnapshot } from "./identity.ngs.data";
import { defaultValues } from "../base";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const IdentityDraftNGSIdentityModel = types
  .model("Identity Draft NGS Link", {
    isActive: false,
    cloudaccountName: types.optional(types.string, ""),
    pubAllow: types.array(types.string),
    pubDeny: types.array(types.string),
    pubAllowInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Pub Allow",
        validationKey: "ngsPubSub",
      })
    ),
    pubDenyInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Pub Deny",
        validationKey: "ngsPubSub",
      })
    ),
    subAllow: types.array(types.string),
    subDeny: types.array(types.string),
    subAllowInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Sub Allow",
        validationKey: "ngsPubSub",
      })
    ),
    subDenyInput: types.optional(StringModel, () =>
      StringModel.create({
        label: "Sub Deny",
        validationKey: "ngsPubSub",
      })
    ),
    resp_Max: types.optional(StringModel, () =>
      StringModel.create({
        label: "Resp Max",
        initialValue: String(defaultValues.identity.ngs.resp.max.default),
        validationKey: "number",
      })
    ),
    resp_TTL: types.optional(StringModel, () =>
      StringModel.create({
        label: "Resp TTL",
        validationKey: "ngsTTL",
      })
    ),
    subs: types.optional(StringModel, () =>
      StringModel.create({
        label: "Subs",
        initialValue: String(defaultValues.identity.ngs.subs.default),
        validationKey: "number",
      })
    ),
    data: types.optional(StringModel, () =>
      StringModel.create({
        label: "Data (bytes)",
        initialValue: String(defaultValues.identity.ngs.data.default),
        validationKey: "number",
      })
    ),
    payload: types.optional(StringModel, () =>
      StringModel.create({
        label: "Payload (bytes)",
        initialValue: String(defaultValues.identity.ngs.payload.default),
        validationKey: "number",
      })
    ),
  })
  .actions((self) => ({
    clear() {
      self.isActive = false;
      self.cloudaccountName = "";
      self.pubAllow.clear();
      self.pubDeny.clear();
      self.pubAllowInput.reset();
      self.pubDenyInput.reset();
      self.subAllow.clear();
      self.subDeny.clear();
      self.subAllowInput.reset();
      self.subDenyInput.reset();
      self.resp_Max.reset();
      self.resp_TTL.reset();
      self.subs.reset();
      self.data.reset();
      self.payload.reset();
    },
  }))
  .views((self) => ({
    get canAddPubAllow() {
      return self.pubAllowInput.isValid && self.pubAllowInput.value.length > 0;
    },
    get canAddPubDeny() {
      return self.pubDenyInput.isValid && self.pubDenyInput.value.length > 0;
    },
    get canAddSubAllow() {
      return self.subAllowInput.isValid && self.subAllowInput.value.length > 0;
    },
    get canAddSubDeny() {
      return self.subDenyInput.isValid && self.subDenyInput.value.length > 0;
    },
  }))
  .views((self) => ({
    get cloudaccountLink() {
      const { absolute } = ngParseLink(self.cloudaccountName, { kind: "cloudaccount" });
      return absolute;
    },
  }))
  .actions((self) => ({
    setCloudaccountName(name: string) {
      self.cloudaccountName = name;
    },
    // add(f: 'sub' | 'pub', s: 'allow' | 'deny') {
    //   const fc = f.slice(0, 1).toUpperCase() + f.slice(1);
    //   const sc = s.slice(0, 1).toUpperCase() + s.slice(1);
    //   const cc = f + sc;
    //   const _self: any = self as any;
    //   if (!_self[`canAdd${fc}${sc}`]) {
    //     return;
    //   }
    //   if (_self[cc].includes(_self[`${cc}Input`].value)) {
    //     return;
    //   }
    //   _self[cc].push(_self[`${cc}Input`].value);
    //   _self[`${cc}Input`].reset();
    // },
    addPubAllow() {
      if (!self.canAddPubAllow) {
        return;
      }
      if (self.pubAllow.includes(self.pubAllowInput.value)) {
        return;
      }
      self.pubAllow.push(self.pubAllowInput.value);
      self.pubAllowInput.reset();
    },
    removePubAllow(value: string) {
      const item = self.pubAllow.find((p) => p === value);
      if (!item) {
        return;
      }
      self.pubAllow.remove(item);
    },
    addPubDeny() {
      if (!self.canAddPubDeny) {
        return;
      }
      if (self.pubDeny.includes(self.pubDenyInput.value)) {
        return;
      }
      self.pubDeny.push(self.pubDenyInput.value);
      self.pubDenyInput.reset();
    },
    removePubDeny(value: string) {
      const item = self.pubDeny.find((p) => p === value);
      if (!item) {
        return;
      }
      self.pubDeny.remove(item);
    },
    addSubAllow() {
      if (!self.canAddSubAllow) {
        return;
      }
      if (self.subAllow.includes(self.subAllowInput.value)) {
        return;
      }
      self.subAllow.push(self.subAllowInput.value);
      self.subAllowInput.reset();
    },
    removeSubAllow(value: string) {
      const item = self.subAllow.find((s) => s === value);
      if (!item) {
        return;
      }
      self.subAllow.remove(item);
    },
    addSubDeny() {
      if (!self.canAddSubDeny) {
        return;
      }
      if (self.subDeny.includes(self.subDenyInput.value)) {
        return;
      }
      self.subDeny.push(self.subDenyInput.value);
      self.subDenyInput.reset();
    },
    removeSubDeny(value: string) {
      const item = self.subDeny.find((s) => s === value);
      if (!item) {
        return;
      }
      self.subDeny.remove(item);
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.cloudaccountName) res = false;
      if (!self.resp_Max.isValid) res = false;
      if (!self.resp_TTL.isValid) res = false;
      if (!self.subs.isValid) res = false;
      if (!self.data.isValid) res = false;
      if (!self.payload.isValid) res = false;
      if (self.pubAllowInput.value.length > 0) res = false;
      if (self.pubDenyInput.value.length > 0) res = false;
      if (self.subAllowInput.value.length > 0) res = false;
      if (self.subDenyInput.value.length > 0) res = false;
      return res;
    },
  }))
  .views((self) => ({
    get asObject(): NGSIdentityDataSnapshot {
      const res: NGSIdentityDataSnapshot = {
        cloudAccountLink: self.cloudaccountLink,
      };
      if (self.pubAllow.length > 0) {
        if (!res.pub) {
          res.pub = {};
        }
        res.pub.allow = self.pubAllow;
      }
      if (self.pubDeny.length > 0) {
        if (!res.pub) {
          res.pub = {};
        }
        res.pub.deny = self.pubDeny;
      }
      if (self.subAllow.length > 0) {
        if (!res.sub) {
          res.sub = {};
        }
        res.sub.allow = self.subAllow;
      }
      if (self.subDeny.length > 0) {
        if (!res.sub) {
          res.sub = {};
        }
        res.sub.deny = self.subDeny;
      }
      if (self.resp_Max.value.length > 0) {
        if (!res.resp) {
          res.resp = {};
        }
        res.resp.max = Number(self.resp_Max.value);
      }
      if (self.resp_TTL.value.length > 0) {
        if (!res.resp) {
          res.resp = {};
        }
        res.resp.ttl = self.resp_TTL.value;
      }
      if (self.subs.value.length > 0) {
        res.subs = Number(self.subs.value);
      }
      if (self.data.value.length > 0) {
        res.data = Number(self.data.value);
      }
      if (self.payload.value.length > 0) {
        res.payload = Number(self.payload.value);
      }
      return res;
    },
  }))
  .actions((self) => {
    const apply = flow(function* (obj: NGSIdentityDataSnapshot) {
      self.isActive = true;
      const { name: cloudaccountName } = ngParseLink(obj.cloudAccountLink, { kind: "cloudaccount" });
      self.cloudaccountName = cloudaccountName;
      self.pubAllow.clear();
      if (obj.pub !== undefined && obj.pub.allow !== undefined && obj.pub.allow.length > 0) {
        for (let p of obj.pub.allow) {
          self.pubAllow.push(p);
        }
      }
      self.pubDeny.clear();
      if (obj.pub !== undefined && obj.pub.deny !== undefined && obj.pub.deny.length > 0) {
        for (let p of obj.pub.deny) {
          self.pubDeny.push(p);
        }
      }
      self.subAllow.clear();
      if (obj.sub !== undefined && obj.sub.allow !== undefined && obj.sub.allow.length > 0) {
        for (let p of obj.sub.allow) {
          self.subAllow.push(p);
        }
      }
      self.subDeny.clear();
      if (obj.sub !== undefined && obj.sub.deny !== undefined && obj.sub.deny.length > 0) {
        for (let p of obj.sub.deny) {
          self.subDeny.push(p);
        }
      }
      if (obj.resp !== undefined && obj.resp.max !== undefined) {
        self.resp_Max.setInitialValue(String(obj.resp.max));
      }
      if (obj.resp !== undefined && obj.resp.ttl !== undefined) {
        self.resp_TTL.setInitialValue(obj.resp.ttl);
      }
      if (obj.subs !== undefined) {
        self.subs.setInitialValue(String(obj.subs));
      }
      if (obj.data !== undefined) {
        self.data.setInitialValue(String(obj.data));
      }
      if (obj.payload !== undefined) {
        self.payload.setInitialValue(String(obj.payload));
      }
    });
    function setIsActive(value: boolean) {
      self.isActive = value;
    }
    return { apply, setIsActive };
  });

export interface IdentityNGSDraftMobx extends Instance<typeof IdentityDraftNGSIdentityModel> {}
