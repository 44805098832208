import * as React from "react";
import clsx from "clsx";
import "./error.css";

interface Props extends React.PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}
export const NGError: React.FC<Props> = ({ children, className = "", style = {} }) => {
  return (
    <div className={clsx("ngerror", className)} style={style}>
      {children}
    </div>
  );
};
