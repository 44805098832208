import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { RepositoryList } from "./repositoryList";
import { RepositoryDetailRoute } from "./repositoryDetailRoute";

const RepositoryRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route index element={<RepositoryList />} />
        <Route path={`*`} element={<RepositoryDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(RepositoryRoute);
