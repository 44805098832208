import { LoggingFluentdMobx } from "../../../../mst/kinds/org";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { NumberModel } from "../../../../mobxDataModels/numberModel";
import { defaultValues } from "../../../../mst/base";

export function getLogsFluentdData(fluentd: LoggingFluentdMobx) {
  function getDefaultFluentdHost() {
    return fluentd.host || "";
  }
  const fluentd_host = StringModel.create({
    label: "Host",
    initialValue: getDefaultFluentdHost(),
    isRequired: true,
  });

  function getDefaultFluentdPort() {
    return fluentd.port;
  }
  const fluentd_port = NumberModel.create({
    isRequired: true,
    label: "Port",
    initialValue: getDefaultFluentdPort() || defaultValues.org.logging.fluentd.port,
    min: 1,
    max: 65545,
  });

  function isDirty() {
    let res = false;
    if (getDefaultFluentdHost() !== fluentd_host.value) {
      res = true;
    }
    if (getDefaultFluentdPort() !== Number(fluentd_port.value)) {
      res = true;
    }
    return res;
  }

  function isValid() {
    let res = true;
    if (!fluentd_host.isValid) {
      res = false;
    }
    if (!fluentd_port.isValid) {
      res = false;
    }
    if (Number.isNaN(fluentd_port.value)) {
      res = false;
    }
    return res;
  }

  function asObject() {
    return {
      fluentd: {
        host: fluentd_host.value,
        port: Number(fluentd_port.value),
      },
    };
  }

  return {
    logProvider: "fluentd",
    host: fluentd_host,
    port: fluentd_port,
    isDirty: isDirty,
    isValid: isValid,
    asObject: asObject,
  };
}
