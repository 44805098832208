import * as React from "react";
import { FormLabel } from "../../../../components/forms/formLabel";
import { Loader } from "../../../../components/layout/loader";
import { LOCATION_SELECTION_ON_ACCOUNT_CREATE_ENABLED } from "../../../../envVariables";
import { StringMobx } from "../../../../mobxDataModels/stringModel";
import { nameOfCloudaccountProvider, request } from "../../../../services/cpln";
import { NGCheckbox } from "../../../../newcomponents/checkbox";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { observer } from "mobx-react-lite";
import { Location } from "../../../../schema/types/location";

interface Props {
  orgName: StringMobx;
  gvcName: StringMobx;
  locations: string[];
  setLocations: React.Dispatch<React.SetStateAction<string[]>>;
}
const AccountCreateOrgGVCRaw: React.FC<Props> = ({ orgName, gvcName, locations, setLocations }) => {
  const [allLocations, setAllLocations] = React.useState<Location[]>([]);
  const [allLocationsFetched, setAllLocationsFetched] = React.useState<boolean>(false);
  React.useEffect(() => {
    fetchAllLocations();
  }, []);

  async function fetchAllLocations() {
    if (!LOCATION_SELECTION_ON_ACCOUNT_CREATE_ENABLED) {
      setAllLocationsFetched(true);
      return;
    }
    try {
      const { data } = await request({ url: "/admin/locations/enabled" });
      setAllLocations(data.items);
      setAllLocationsFetched(true);
    } catch (e) {
      setAllLocationsFetched(true);
    }
  }

  function onChange(location: string, value: boolean) {
    if (locations.includes(location) && !value) {
      // remove
      setLocations(locations.filter((currentLocation) => currentLocation !== location));
    } else if (!locations.includes(location) && !!value) {
      // add
      setLocations([...locations, location]);
    }
  }

  if (!allLocationsFetched) {
    return <Loader reason={"fetching all locations"} />;
  }

  return (
    <div className="account-create-org-gvc">
      <NGFormElement
        name="orgName"
        label={orgName.label}
        value={orgName.value}
        onChange={orgName.setValue}
        required={orgName.isRequired}
        error={orgName.error}
      />
      <NGFormElement
        name="gvcName"
        label={gvcName.label}
        value={gvcName.value}
        onChange={gvcName.setValue}
        required={gvcName.isRequired}
        error={gvcName.error}
      />
      {allLocations.length > 0 ? (
        <div>
          <div>
            <FormLabel size={"base"}>Select GVC Locations</FormLabel>
          </div>
          <div className="mt-2 border flex items-center bg-table-label-drop text-table-label-text px-2 py-1">
            <div className="w-8/12 flex items-center gap-1">
              <NGCheckbox
                aria-label="Toggle select all locations"
                checked={locations.length === allLocations.length}
                indeterminate={locations.length !== allLocations.length && locations.length > 0}
                onChange={(value) => {
                  if (value) {
                    setLocations(allLocations.map((location) => location.name));
                  } else {
                    setLocations([]);
                  }
                }}
              />
              <div>Name</div>
            </div>
            <div className="w-4/12">Provider</div>
          </div>
          {allLocations.map((location) => {
            const isSelected = locations.includes(location.name);

            function onClick() {
              onChange(location.name, !isSelected);
            }

            return (
              <div className={`flex items-center px-2 py-1 border border-t-0`}>
                <div className="w-8/12 flex items-center gap-1">
                  <NGCheckbox
                    aria-label={`toggle select for location ${location.name}`}
                    checked={isSelected}
                    onChange={() => onClick()}
                  />
                  <div onClick={onClick} className="flex flex-col items-start gap-1 cursor-pointer">
                    <span>{location.name}</span>
                    <div className="text-xs">{location.description}</div>
                  </div>
                </div>
                <div className="w-4/12">{nameOfCloudaccountProvider(location.provider)}</div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export const AccountCreateOrgGVC = observer(AccountCreateOrgGVCRaw);
