import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { StringModel, StringMobx } from "../../../mobxDataModels/stringModel";
import { updateLastDeploymentTimeOnHubspot } from "../../../services/utils";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";

interface Props {
  containerName: string;
  onClose: () => void;
  deleteContainer: (containerName: string) => Promise<void>;
  fetchItem?: any;
}

const ContainerDeleteModalRaw: React.FC<Props> = ({ containerName, onClose, deleteContainer, fetchItem }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const confirmationRef = React.useRef<StringMobx>(
    StringModel.create({ label: "Confirmation", validationKey: "deleteString", isRequired: true })
  );

  async function confirmDelete() {
    try {
      setIsLoading(true);
      if (!confirmationRef.current.isValid) {
        notification.warning({
          message: "Failed",
          description: "Confirmation message is incorrect",
        });
        return;
      }
      await deleteContainer(containerName);
      notification.success({
        message: "Success",
        description: `Deleted container`,
      });
      updateLastDeploymentTimeOnHubspot();
      onClose();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to delete",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      closable={false}
      maskClosable={!isLoading}
      destroyOnClose
      open={true}
      onCancel={onClose}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={() => onClose()} disabled={isLoading}>
            Cancel
          </NGButton>
          <NGButton
            variant="danger"
            onClick={confirmDelete}
            loading={isLoading}
            disabled={isLoading || !confirmationRef.current.isValid}
          >
            Delete
          </NGButton>
        </div>
      }
    >
      <div className="flex flex-col">
        <div className="text-xl">Delete Container</div>
        <p className="mb-4">This action cannot be undone</p>
        <NGAlert
          className="mb-4"
          type={"error"}
          render={() => (
            <span>
              Type <span className="font-medium">delete</span>
            </span>
          )}
        />
        <NGFormElement
          name={"containerDeleteConfirmation"}
          label={"Confirmation"}
          value={confirmationRef.current.value}
          onChange={confirmationRef.current.setValue}
          required
          error={confirmationRef.current.value !== "delete" ? 'You must type "delete"' : ""}
        />
      </div>
    </Modal>
  );
};

export const ContainerDeleteModal = observer(ContainerDeleteModalRaw);
