import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderTriton } from "./triton";
import { Mk8sCreateProviderTritonAdvanced } from "./advanced";
import { Mk8sCreateProviderTritonNodePool } from "./nodePool";
import { Mk8sCreateProviderTritonNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderTritonNodePools } from "./nodePools";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderTritonRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderTriton mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderTritonNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderTritonNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderTritonNodePool mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<Mk8sCreateProviderTritonAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderTritonRoute = observer(Mk8sCreateProviderTritonRouteRaw);
