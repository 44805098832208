import * as React from "react";
import { AlertCircle } from "react-feather";
import { pluralNameOfKind } from "../../services/cpln";

interface Props {
  type: "items" | "query";
  targetKind: string;
}
export const Warning: React.FC<Props> = ({ type, targetKind }) => {
  return (
    <div className="flex flex-col items-center border rounded p-8 mb-6">
      <AlertCircle className="mb-2" />
      <div className="text-center text-2xl">
        You cannot {type === "items" ? "select items" : "set a query"} because you have selected{" "}
        <span>Target All {pluralNameOfKind(targetKind)}</span> option.
      </div>
    </div>
  );
};
