import { CheckCircleFilled } from "@ant-design/icons";
import * as React from "react";
import styles from "./marketing-side.module.scss";

export const MarketingSide: React.FC = () => {
  return (
    <div className={styles.marketingSide}>
      <div className={styles.items}>
        <div className={styles.heading}>The most powerful operating environment for microservices</div>
        <div className={styles.globe}>
          <img src={"/resources/images/globe.svg"} />
        </div>
        <div className={styles.item}>
          <div />
          <CheckCircleFilled />
          <div>Ultra-low latency, redundancy, and 99.999% availability</div>
        </div>
        <div className={styles.item}>
          <div />
          <CheckCircleFilled />
          <div>Instantly deploy multi-region without infrastructure investment</div>
        </div>
        <div className={styles.item}>
          <div />
          <CheckCircleFilled />
          <div>Patented AI optimizes consumption delivering enormous cost savings</div>
        </div>
        <div className={styles.item}>
          <div />
          <CheckCircleFilled />
          <div>Enable resilient multi-cloud native maturity in minutes</div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.quote}>
        <div className={styles.text}>"We've reduced our AWS spend by 75%"</div>
        <div className={styles.owner}>
          <img src="/resources/images/dimy.jpeg" />
          <div>
            <div>DIMY JEANNOT</div>
            <div>CTO Safe Health</div>
          </div>
        </div>
      </div>
    </div>
  );
};
