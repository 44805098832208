import * as React from "react";
import { Modal, Alert, notification } from "antd";
import { observer } from "mobx-react-lite";
import { GVCMobx } from "../../mst/kinds/gvc";
import { listAndDelete, request } from "../../services/cpln";
import { UserData } from "../../mobxStores/userData/userData";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { useNavigate } from "react-router-dom";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { NGFormData } from "../../mobxStores/ngFormData";
import { UIData } from "../../mobxStores/uiData/uiData";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { InfoTooltip } from "../../components/InfoTooltip";
import { NGCheckbox } from "../../newcomponents/checkbox";

interface Props {
  gvc: GVCMobx;
  onClose: any;
  onDone: any;
  visible: boolean;
}

const DeleteModalRaw: React.FC<Props> = ({ gvc, onClose, onDone, visible }) => {
  const formDataRef = React.useRef(new NGFormData());
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isForceDelete, setIsForceDelete] = React.useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = React.useState(false);

  async function confirmDelete() {
    try {
      setIsLoading(true);

      // Delete resources related to GVC
      if (isForceDelete) {
        const context = { org: ConsoleContext.org, gvc: gvc.name };

        await listAndDelete("workload", context);
        await listAndDelete("volumeset", context);
        await listAndDelete("identity", context);
      }

      // Delete GVC
      await request({ method: "delete", url: gvc.selfLink });

      await UserData.requestGVCNames();
      ConsoleContext.clearGVC();
      PromptContext.setIsDisabled(true);
      UIData.setIsGVCPinned(false);
      navigate(`/console/org/${ConsoleContext.org}/gvc/-select`);
      onDone();
      notification.success({
        message: "Success",
        description: `Deleted`,
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to delete",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title={
        <>
          Delete GVC:{" "}
          <span className="font-bold" style={{ color: "var(--color-danger)" }}>
            {gvc.name}
          </span>
        </>
      }
      open={visible}
      closable={false}
      maskClosable={!isLoading}
      onCancel={onClose}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </NGButton>
          <NGButton
            variant="danger"
            onClick={confirmDelete}
            loading={isLoading}
            disabled={isLoading || !isConfirmDelete}
          >
            Delete
          </NGButton>
        </div>
      }
      destroyOnClose
    >
      <div className="flex flex-col gap-2">
        <span>Are you sure you want to delete this GVC? This action cannot be undone.</span>
        <NGCheckbox checked={isConfirmDelete} onChange={(value) => setIsConfirmDelete(value)}>
          <NGLabelText>I understand that this action cannot be undone.</NGLabelText>
        </NGCheckbox>
        <div className={`flex items-center`}>
          <NGCheckbox
            checked={isForceDelete}
            onChange={(value) => setIsForceDelete(value)}
            data-testid="gvc-force-delete"
          >
            <NGLabelText>Force Delete</NGLabelText>
          </NGCheckbox>
          <InfoTooltip
            style={{ color: "var(--color-label)" }}
            title="If this is enabled, Control Plane will delete identities, workloads and volume sets before deleting the GVC."
          />
        </div>
      </div>
    </Modal>
  );
};

export const DeleteModal = observer(DeleteModalRaw);
