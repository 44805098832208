import { Instance, types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";

export const WorkloadDraftJobModel = types
  .model({
    _schedule: types.optional(types.string, "* * * * *"),
    _concurrencyPolicy: types.optional(types.string, "Forbid"),
    _historyLimit: types.optional(types.number, 5),
    _restartPolicy: types.optional(types.string, "Never"),
    _activeDeadlineSeconds: types.maybe(types.number),

    schedule: types.optional(StringModel, () =>
      StringModel.create({ label: "Schedule", isRequired: true, validationKey: "jobSchedule" }),
    ),
    concurrencyPolicy: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Concurrency Policy",
        initialValue: "Forbid",
        options: [
          { label: "Forbid", value: "Forbid" },
          { label: "Replace", value: "Replace" },
        ],
      }),
    ),
    historyLimit: types.optional(StringModel, () => NumberModel.create({ label: "History Limit", min: 1, max: 10 })),
    restartPolicy: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Restart Policy",
        initialValue: "Never",
        options: [
          { label: "Never", value: "Never" },
          { label: "On Failure", value: "OnFailure" },
        ],
      }),
    ),
    activeDeadlineSeconds: types.optional(StringModel, () =>
      NumberModel.create({ label: "Active Deadline Seconds", min: 1 }),
    ),
  })
  .actions((self) => ({
    reset() {
      self.schedule.setInitialValue(self._schedule);
      self.concurrencyPolicy.setInitialValue(self._concurrencyPolicy);
      self.historyLimit.setInitialValue(String(self._historyLimit));
      self.restartPolicy.setInitialValue(self._restartPolicy);
      self.activeDeadlineSeconds.setInitialValue(
        self._activeDeadlineSeconds === undefined ? "" : String(self._activeDeadlineSeconds),
      );
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._schedule = self.schedule.value;
      self.schedule.confirm();
      self._concurrencyPolicy = self.concurrencyPolicy.value;
      self.concurrencyPolicy.confirm();
      self._historyLimit = Number(self.historyLimit.value);
      self.historyLimit.confirm();
      self._restartPolicy = self.restartPolicy.value;
      self.restartPolicy.confirm();
      self._activeDeadlineSeconds =
        self.activeDeadlineSeconds.value.length > 0 ? Number(self.activeDeadlineSeconds.value) : undefined;
      self.activeDeadlineSeconds.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.schedule.isDirty) res = true;
      if (self.concurrencyPolicy.isDirty) res = true;
      if (self.historyLimit.isDirty) res = true;
      if (self.restartPolicy.isDirty) res = true;
      if (self.activeDeadlineSeconds.isDirty) res = true;
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.schedule.isValid) res = false;
      if (!self.historyLimit.isValid) res = false;
      if (!self.activeDeadlineSeconds.isValid) res = false;
      return res;
    },
    get asObject() {
      let res: any = {
        schedule: self.schedule.value,
        concurrencyPolicy: self.concurrencyPolicy.value,
        historyLimit: Number(self.historyLimit.value),
        restartPolicy: self.restartPolicy.value,
      };
      if (self.activeDeadlineSeconds.value.length > 0) {
        res.activeDeadlineSeconds = Number(self.activeDeadlineSeconds.value);
      }
      return res;
    },
  }))
  .views((self) => ({
    get isDirtyReason() {
      if (!self.isDirty) {
        return "WorkloadDraftJob - Not Dirty";
      }
      let reason = "";
      if (self.schedule.isDirty) reason = self.schedule.isDirtyReason;
      if (self.concurrencyPolicy.isDirty) reason = self.concurrencyPolicy.isDirtyReason;
      if (self.historyLimit.isDirty) reason = self.historyLimit.isDirtyReason;
      if (self.restartPolicy.isDirty) reason = self.restartPolicy.isDirtyReason;
      if (self.activeDeadlineSeconds.isDirty) reason = self.activeDeadlineSeconds.isDirtyReason;
      return "WorkloadDraftJob - " + reason;
    },
  }));
export interface WorkloadDraftJobMobx extends Instance<typeof WorkloadDraftJobModel> {}
