import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { CreateFormLink } from "../../org/create/create";
import { NameModel } from "../../../mobxDataModels/nameModel";
import { SelectModel } from "../../../mobxDataModels/selectModel";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { CloudaccountCreateAwsConfiguration } from "./configuration/aws";
import { CloudaccountCreateAzureConfiguration } from "./configuration/azure";
import { RcFile } from "antd/lib/upload/interface";
import { homeLink, request, resourceLink } from "../../../services/cpln";
import { randomName } from "../../../services/utils";
import { TagsNewModel } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { ChevronDown, ChevronUp, ExternalLink } from "react-feather";
import { CloudaccountCreateNgsConfiguration } from "./configuration/ngs";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import NGAlert from "../../../newcomponents/alert";
import { NGButton } from "../../../newcomponents/button/Button";

// general
// configuration // azure and ngs
// tags

const CloudaccountCreateRaw: React.FC = () => {
  const basePath = useBasePath("/cloudaccount/-create/*");
  const formDataRef = React.useRef(new NGFormData());

  const [showAzureSubscriptionIdGuide, setShowAzureSubscriptionIdGuide] = React.useState(false);
  const [showGcpProjectIdGuide, setShowGcpProjectIdGuide] = React.useState(false);
  const [awsUseGuide, setAwsUseGuide] = React.useState(false);

  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));

  const aws_accountIdRef = React.useRef(
    StringModel.create({
      label: "AWS Account ID",
      isRequired: true,
      validationKey: "awsAccountId",
      transformKey: "awsAccountId",
    })
  );
  const aws_roleArnRef = React.useRef(StringModel.create({ label: "Role Arn", isRequired: true }));

  const typeRef = React.useRef(
    SelectModel.create({
      label: "Cloud Provider",
      initialValue: "aws",
      options: [
        { label: "AWS", value: "aws" },
        { label: "Azure", value: "azure" },
        { label: "GCP", value: "gcp" },
        { label: "NGS", value: "ngs" },
      ],
    })
  );

  const gcp_projectIdRef = React.useRef(
    StringModel.create({ label: "Project Id", isRequired: true, validationKey: "gcpProjectId" })
  );

  const azure_subscriptionIdRef = React.useRef(
    StringModel.create({
      label: "Subscription Id",
      isRequired: true,
      validationKey: "uuid",
      transformKey: "trim",
    })
  );
  const azure_locationSelectRef = React.useRef(
    SelectModel.create({
      label: "Location Name",
      initialValue: "centralus",
      options: [
        {
          label: "Central US",
          value: "centralus",
        },
        {
          label: "East US",
          value: "eastus",
        },
        {
          label: "East US 2",
          value: "eastus2",
        },
        {
          label: "France Central",
          value: "francecentral",
        },
        {
          label: "Germany West Central",
          value: "germanywestcentral",
        },
        {
          label: "North Central US",
          value: "northcentralus",
        },
        {
          label: "North Europe",
          value: "northeurope",
        },
        {
          label: "Norway East",
          value: "norwayeast",
        },
        {
          label: "Switzerland North",
          value: "switzerlandnorth",
        },
        {
          label: "UK South",
          value: "uksouth",
        },
        {
          label: "UK West",
          value: "ukwest",
        },
        {
          label: "West Central US",
          value: "westcentralus",
        },
        {
          label: "West Europe",
          value: "westeurope",
        },
        {
          label: "West US",
          value: "westus",
        },
        {
          label: "West US 2",
          value: "westus2",
        },
        {
          label: "West US 3",
          value: "westus3",
        },
      ],
    })
  );
  const azure_storageAccountNameRef = React.useRef(
    StringModel.create({ label: "Storage Account Name", isRequired: true, validationKey: "azureStorageAccountName" })
  );
  const azure_resourceGroupNameRef = React.useRef(
    StringModel.create({ label: "Resource Group Name", isRequired: true, validationKey: "azureResourceGroupName" })
  );
  const azure_functionAppNameRef = React.useRef(
    StringModel.create({ label: "Function App Name", isRequired: true, initialValue: randomName() })
  );
  const azure_urlRef = React.useRef(StringModel.create({ label: "URL", isRequired: true, validationKey: "https" }));
  const azure_codeRef = React.useRef(StringModel.create({ label: "Code", isRequired: true }));
  const [azureMethod, setAzureMethod] = React.useState<"new-azure-connector" | "old-secret">("new-azure-connector");
  const [azureServicePrincipalFile, setAzureServicePrincipalFile] = React.useState<RcFile>(null as any);
  const [azureServicePrincipalFileOriginalText, setAzureServicePrincipalFileOriginalText] = React.useState("");
  const [azureServicePrincipalFileText, setAzureServicePrincipalFileText] = React.useState("");
  const [azureSelections, setAzureSelections] = React.useState<string[]>([]);

  const [ngsSelections, setNgsSelections] = React.useState<string[]>([]);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  React.useEffect(() => {
    const url = azure_urlRef.current.value;
    if (!url.includes("?code=")) {
      return;
    }
    const [newUrl, newCode] = url.split("?code=");
    if (newUrl && newCode) {
      azure_urlRef.current.setValue(newUrl);
      azure_codeRef.current.setValue(newCode);
    }
  }, [azure_urlRef.current.value]);

  React.useEffect(() => {
    if (azureServicePrincipalFile) {
      azureServicePrincipalFile.text().then((text) => {
        setAzureServicePrincipalFileOriginalText(text);
        setAzureServicePrincipalFileText(text);
      });
    } else {
      setAzureServicePrincipalFileOriginalText("");
      setAzureServicePrincipalFileText("");
    }
  }, [azureServicePrincipalFile]);

  React.useEffect(() => {
    if (azureServicePrincipalFileText !== azureServicePrincipalFileOriginalText) {
      setAzureServicePrincipalFile(null as any);
    }
  }, [azureServicePrincipalFileText]);

  const tagsRef = React.useRef(TagsNewModel.create());
  const [isLoading, setIsLoading] = React.useState(false);

  const [isGeneralValid, setIsGeneralValid] = React.useState(false);
  const [isConfigurationValid, setIsConfigurationValid] = React.useState(false);
  const [isTagsValid, setIsTagsValid] = React.useState(false);

  const [instructions, setInstructions] = React.useState({});
  React.useEffect(() => {
    if (typeRef.current.value === "aws") {
      request({ url: `${homeLink("cloudaccount")}/-instructions/aws` }).then((res) => setInstructions(res.data as any));
    } else if (typeRef.current.value === "gcp") {
      request({ url: `${homeLink("cloudaccount")}/-instructions/gcp` }).then((res) => setInstructions(res.data as any));
    }
  }, [typeRef.current.value]);

  React.useEffect(() => {
    let _isGeneralValid = true;
    if (!nameRef.current.isValid) _isGeneralValid = false;
    if (!descriptionRef.current.isValid) _isGeneralValid = false;

    if (typeRef.current.value === "aws") {
      if (!awsUseGuide) {
        if (!aws_roleArnRef.current.isValid) _isGeneralValid = false;
      }
    }
    if (typeRef.current.value === "azure") {
      if (!azure_subscriptionIdRef.current.isValid) _isGeneralValid = false;
    }
    if (typeRef.current.value === "gcp") {
      if (!gcp_projectIdRef.current.isValid) _isGeneralValid = false;
    }

    let _isConfigurationValid = _isGeneralValid;

    if (typeRef.current.value === "aws") {
      if (awsUseGuide) {
        if (!aws_accountIdRef.current.isValid) _isConfigurationValid = false;
      }
    }
    if (typeRef.current.value === "azure") {
      if (azureMethod === "old-secret") {
        if (azureSelections.length !== 1) _isConfigurationValid = false;
      } else if (azureMethod === "new-azure-connector") {
        if (!azure_urlRef.current.isValid) _isConfigurationValid = false;
        if (!azure_codeRef.current.isValid) _isConfigurationValid = false;
      }
    }
    if (typeRef.current.value === "ngs") {
      if (ngsSelections.length !== 1) _isConfigurationValid = false;
    }

    let _isTagsValid = _isConfigurationValid;
    if (!tagsRef.current.isValid) _isTagsValid = false;

    setIsGeneralValid(_isGeneralValid);
    setIsConfigurationValid(_isConfigurationValid);
    setIsTagsValid(_isTagsValid);
  }, [
    nameRef.current.isValid,
    tagsRef.current.isValid,
    descriptionRef.current.isValid,
    typeRef.current.value,
    gcp_projectIdRef.current.isValid,
    awsUseGuide,
    aws_accountIdRef.current.isValid,
    aws_roleArnRef.current.isValid,
    azure_subscriptionIdRef.current.isValid,
    azure_urlRef.current.isValid,
    azure_codeRef.current.isValid,
    azureSelections.length,
    ngsSelections.length,
    azureServicePrincipalFileText,
    azureMethod,
  ]);

  function toggleShowAzureSubscriptionIdGuide() {
    setShowAzureSubscriptionIdGuide((x) => !x);
  }

  function toggleShowGcpProjectIdGuide() {
    setShowGcpProjectIdGuide((x) => !x);
  }

  async function getAsyncCloudAccountObject() {
    let secretLink = "";
    const body: any = {
      name: nameRef.current.value,
      description: descriptionRef.current.value || nameRef.current.value,
      provider: typeRef.current.value,
      tags: tagsRef.current.asObject,
    };

    if (typeRef.current.value === "aws") {
      body.data = {
        roleArn: awsUseGuide
          ? `arn:aws:iam::${aws_accountIdRef.current.value}:role/${(instructions as any).data.bridgeRole}`
          : aws_roleArnRef.current.value,
      };
    } else if (typeRef.current.value === "gcp") {
      body.data = {
        projectId: gcp_projectIdRef.current.value,
      };
    } else if (typeRef.current.value === "azure") {
      if (azureMethod === "new-azure-connector") {
        const azureSecretName = `${nameRef.current.value}-access`;
        const secretBody: any = {
          name: azureSecretName,
          type: "azure-connector",
          data: {
            url: azure_urlRef.current.value,
            code: azure_codeRef.current.value,
          },
        };
        await request({ method: "put", body: secretBody, url: homeLink("secret") });
        secretLink = resourceLink("secret", azureSecretName);
      } else if (azureMethod === "old-secret") {
        secretLink = azureSelections[0];
      }
      body.data = {
        secretLink: secretLink,
      };
    } else if (typeRef.current.value === "ngs") {
      body.data = {
        secretLink: ngsSelections[0],
      };
    }
    return { body, secretLink };
  }

  async function onConfirm() {
    let secretLink = "";
    try {
      setIsLoading(true);
      const { body, secretLink: updatedSecretLink } = await getAsyncCloudAccountObject();
      secretLink = updatedSecretLink;
      await request({ method: "post", body: body, url: homeLink("cloudaccount") });
      const itemLink = resourceLink("cloudaccount", body.name);
      notification.success({
        message: "Success",
        description: "Cloud Account is created",
      });
      setIsLoading(false);
      PromptContext.setIsDisabled(true);
      if (typeRef.current.value === "gcp") {
        navigate(`/console${itemLink}/-configuration`);
      } else {
        navigate(`/console${itemLink}`);
      }
    } catch (e) {
      try {
        if (secretLink) {
          await request({ method: "delete", url: secretLink });
        }
      } catch (e) {
        let secretCleanUpErrorMessage = e?.response?.data?.message;
        if (!secretCleanUpErrorMessage) secretCleanUpErrorMessage = e.message;
        notification.warning({
          message: "Failed to cleanup secret",
          description: secretCleanUpErrorMessage,
        });
      }
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/-create");
  }

  const [links, setLinks] = React.useState<CreateFormLink[]>([]);
  React.useEffect(() => {
    const links = [{ name: "General", value: `-general`, isActive: true }];
    if (typeRef.current.value === "aws") {
      if (awsUseGuide) {
        links.push({
          name: "Configuration",
          value: `-configuration`,
          isActive: isGeneralValid,
        });
      }
      links.push({
        name: "Tags",
        value: `-tags`,
        isActive: isConfigurationValid,
      });
    } else if (typeRef.current.value === "gcp") {
      links.push({
        name: "Tags",
        value: `-tags`,
        isActive: isConfigurationValid,
      });
    } else if (typeRef.current.value === "azure") {
      links.push({
        name: "Configuration",
        value: `-configuration`,
        isActive: isGeneralValid,
      });
      links.push({
        name: "Tags",
        value: `-tags`,
        isActive: isConfigurationValid,
      });
    } else if (typeRef.current.value === "ngs") {
      links.push({
        name: "Configuration",
        value: `-configuration`,
        isActive: isGeneralValid,
      });
      links.push({
        name: "Tags",
        value: `-tags`,
        isActive: isConfigurationValid,
      });
    }
    setLinks(links);
  }, [isGeneralValid, isConfigurationValid, isTagsValid]);

  const [next, setNext] = React.useState<any>({
    isActive: false,
    label: "Next",
    url: "/",
  });

  React.useEffect(() => {
    const next = {
      isActive: false,
      label: "Next",
      url: "/",
    };
    if (typeRef.current.value === "aws") {
      if (pathname.includes("-general")) {
        if (awsUseGuide) {
          next.label = "Next (Configuration)";
          next.url = `-configuration`;
          next.isActive = isGeneralValid;
        } else {
          next.label = "Next (Tags)";
          next.url = `-tags`;
          next.isActive = isGeneralValid;
        }
      } else if (pathname.includes("-configuration")) {
        next.label = "Next (Tags)";
        next.url = `-tags`;
        next.isActive = isConfigurationValid;
      }
    } else if (typeRef.current.value === "gcp") {
      if (pathname.includes("-general")) {
        next.label = "Next (Tags)";
        next.url = `-tags`;
        next.isActive = isConfigurationValid;
      }
    } else if (typeRef.current.value === "azure") {
      if (pathname.includes("-general")) {
        next.label = "Next (Configuration)";
        next.url = `-configuration`;
        next.isActive = isGeneralValid;
      } else if (pathname.includes("-configuration")) {
        next.label = "Next (Tags)";
        next.url = `-tags`;
        next.isActive = isConfigurationValid;
      }
    } else if (typeRef.current.value === "ngs") {
      if (pathname.includes("-general")) {
        next.label = "Next (Configuration)";
        next.url = `-configuration`;
        next.isActive = isGeneralValid;
      } else if (pathname.includes("-configuration")) {
        next.label = "Next (Tags)";
        next.url = `-tags`;
        next.isActive = isConfigurationValid;
      }
    }
    setNext(next);
  }, [pathname, isGeneralValid, isConfigurationValid, isTagsValid]);

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <BasePathContext.Provider value={basePath}>
        <CreateLayout
          // TODO revisit
          getPreview={async () => {
            try {
              const { body } = await getAsyncCloudAccountObject();
              return { kind: "cloudaccount", ...body };
            } catch (error) {
              return { kind: "cloudaccount" };
            }
          }}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Cloud Account"}
          name={nameRef.current.value}
          canCreate={isTagsValid}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route
              path={`-general`}
              element={
                <div style={{ width: 450 }}>
                  <NGFormElement
                    name="name"
                    required={nameRef.current.isRequired}
                    error={nameRef.current.error}
                    label={nameRef.current.label}
                    value={nameRef.current.value}
                    onChange={nameRef.current.setValue}
                  />
                  <NGFormElement
                    name="description"
                    required={nameRef.current.isRequired}
                    error={nameRef.current.error}
                    label={descriptionRef.current.label}
                    value={descriptionRef.current.value}
                    onChange={descriptionRef.current.setValue}
                  />
                  <NGFormElement
                    as="select"
                    name="type"
                    label={typeRef.current.label}
                    required={true}
                    value={typeRef.current.value}
                    onChange={typeRef.current.setValue}
                    options={typeRef.current.options}
                  />
                  {typeRef.current.value === "aws" ? (
                    <>
                      {awsUseGuide ? null : (
                        <>
                          <NGFormElement
                            name="roleArn"
                            label={aws_roleArnRef.current.label}
                            value={aws_roleArnRef.current.value}
                            onChange={aws_roleArnRef.current.setValue}
                            required={aws_roleArnRef.current.isRequired}
                            error={aws_roleArnRef.current.error}
                          />
                          <NGAlert
                            render={() => {
                              return (
                                <div>
                                  <div className="mb-1">Don't have the Role ARN yet? </div>
                                  {nameRef.current.isValid ? null : (
                                    <span>
                                      Type a {nameRef.current.value ? "valid " : ""} name for your cloud account to
                                      enable the guide.
                                    </span>
                                  )}
                                  {nameRef.current.isValid ? (
                                    <>
                                      <NGButton
                                        onClick={() => {
                                          setAwsUseGuide(true);
                                          setTimeout(() => {
                                            navigate("-configuration");
                                          }, 0);
                                        }}
                                        variant={"primary"}
                                        link
                                        size={"small"}
                                        className="inline-block"
                                      >
                                        Click
                                      </NGButton>
                                      <span> to follow the guide for creating it.</span>
                                    </>
                                  ) : null}
                                </div>
                              );
                            }}
                          />
                        </>
                      )}
                    </>
                  ) : null}
                  {typeRef.current.value === "gcp" ? (
                    <>
                      <div className="mb-2">
                        <button className="ngfocus color-link" onClick={toggleShowGcpProjectIdGuide}>
                          How do I get my GCP Project ID?{" "}
                          {showGcpProjectIdGuide ? (
                            <ChevronUp className="ml-1 feather-icon inline-block" />
                          ) : (
                            <ChevronDown className="ml-1 feather-icon inline-block" />
                          )}
                        </button>
                        {showGcpProjectIdGuide ? (
                          <ul className="mt-2 mb-4 px-4 py-2">
                            <li className="my-2 list-decimal ml-4">
                              Sign into the{" "}
                              <a
                                className="underline hover:underline focus:underline ngfocus color-link"
                                href={"https://console.cloud.google.com"}
                                target={"_blank"}
                              >
                                GCP Console
                                <ExternalLink
                                  className="ml-1 feather-icon inline-block"
                                  style={{ transform: "translateY(2px)" }}
                                />
                              </a>
                              .
                            </li>
                            <li className="my-2 list-decimal ml-4  ">
                              Copy the <span className=" ">Project ID</span> from the Project info card.
                            </li>
                            <div
                              className="flex items-center justify-center      my-2"
                              style={{ width: 264, height: 235, border: "1px solid rgb(223, 223, 223)" }}
                            >
                              <img
                                className="object-container"
                                style={{ width: 264, height: 235 }}
                                src={"/resources/howto/gcp_projectIdSteps.png"}
                                alt={"how do I get my gcp project id?"}
                              />
                            </div>
                          </ul>
                        ) : null}
                      </div>
                      <NGFormElement
                        name="gcp_projectId"
                        required={gcp_projectIdRef.current.isRequired}
                        error={gcp_projectIdRef.current.error}
                        label={gcp_projectIdRef.current.label}
                        value={gcp_projectIdRef.current.value}
                        onChange={gcp_projectIdRef.current.setValue}
                      />
                    </>
                  ) : null}
                  {typeRef.current.value === "azure" ? (
                    <>
                      <div className="mb-2">
                        <button className="ngfocus color-link" onClick={toggleShowAzureSubscriptionIdGuide}>
                          How do I get my Azure Subscription ID?{" "}
                          {showAzureSubscriptionIdGuide ? (
                            <ChevronUp className="ml-1 feather-icon inline-block" />
                          ) : (
                            <ChevronDown className="ml-1 feather-icon inline-block" />
                          )}
                        </button>
                        {showAzureSubscriptionIdGuide ? (
                          <ul className="mt-2 mb-4 px-4 py-2">
                            <li className="my-2 list-decimal ml-4  ">
                              Sign into the{" "}
                              <a
                                className="underline hover:underline ngfocus color-link"
                                href={"https://portal.azure.com/"}
                                target={"_blank"}
                              >
                                Azure Portal
                                <ExternalLink
                                  className="ml-1 feather-icon inline-block"
                                  style={{ transform: "translateY(2px)" }}
                                />
                              </a>
                              .
                            </li>
                            <li className="my-2 list-decimal ml-4  ">
                              Navigate to the <span className=" ">Subscriptions</span> blade.
                            </li>
                            <li className="my-2 list-decimal ml-4  ">
                              Copy the <span className=" ">Subscription ID</span> from the list.
                            </li>
                            <div
                              className="flex items-center justify-center      my-2"
                              style={{ width: 672, height: 108, border: "1px solid rgb(223, 223, 223)" }}
                            >
                              <img
                                className="object-container"
                                style={{ width: 672, height: 108 }}
                                src={"/resources/howto/azure_subscriptionIdSteps.png"}
                                alt={"how do I get my azure subscription id?"}
                              />
                            </div>
                          </ul>
                        ) : null}
                      </div>
                      <NGFormElement
                        name="azure_subscriptionId"
                        required={azure_subscriptionIdRef.current.isRequired}
                        error={azure_subscriptionIdRef.current.error}
                        label={azure_subscriptionIdRef.current.label}
                        value={azure_subscriptionIdRef.current.value}
                        onChange={azure_subscriptionIdRef.current.setValue}
                      />
                    </>
                  ) : null}
                </div>
              }
            />
            {typeRef.current.value === "aws" ? (
              <Route
                path={`-configuration`}
                element={
                  <CloudaccountCreateAwsConfiguration
                    name={nameRef.current.value}
                    accountId={aws_accountIdRef.current.value}
                    setAccountId={aws_accountIdRef.current.setValue}
                    instructions={instructions}
                  />
                }
              />
            ) : null}
            {typeRef.current.value === "azure" ? (
              <Route
                path={`-configuration`}
                element={
                  <CloudaccountCreateAzureConfiguration
                    name={nameRef.current.value}
                    azureMethod={azureMethod}
                    setAzureMethod={setAzureMethod}
                    subscriptionId={azure_subscriptionIdRef.current.value}
                    locationRef={azure_locationSelectRef.current}
                    resourceGroupRef={azure_resourceGroupNameRef.current}
                    storageAccountRef={azure_storageAccountNameRef.current}
                    functionAppNameRef={azure_functionAppNameRef.current}
                    urlRef={azure_urlRef.current}
                    codeRef={azure_codeRef.current}
                    selections={azureSelections}
                    onSelectionsChange={setAzureSelections}
                  />
                }
              />
            ) : null}
            {typeRef.current.value === "ngs" ? (
              <Route
                path={`-configuration`}
                element={
                  <CloudaccountCreateNgsConfiguration
                    selections={ngsSelections}
                    onSelectionsChange={setNgsSelections}
                  />
                }
              />
            ) : null}
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </BasePathContext.Provider>
    </NGFormContext.Provider>
  );
};

export const CloudaccountCreate = observer(CloudaccountCreateRaw);
