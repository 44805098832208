import { observer } from "mobx-react-lite";
import * as React from "react";
import { ConsoleContext } from "./mobxStores/consoleContext/consoleContext";
import { Navigate } from "react-router-dom";
import { useCleanPrompt } from "./reactHooks/useCleanPrompt";

const RedirectRaw: React.FC = () => {
  useCleanPrompt();

  React.useEffect(() => {
    return () => {
      ConsoleContext.setIsChangingContext(false);
      ConsoleContext.clearRedirect();
    };
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh", backgroundColor: "var(--color-drop)" }}>
      <Navigate to={ConsoleContext.redirect || "/"} />
    </div>
  );
};

export const Redirect = observer(RedirectRaw);
