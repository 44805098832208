import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { notification, Modal } from "antd";
import { parentLink, nameOfKind, request, getName, pluralNameOfKind } from "../../services/cpln";
import { Kind } from "../../mst/base";
import { UserData } from "../../mobxStores/userData/userData";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";

interface Props {
  kind: string;
  links: string[];
  onCancel: any;
  onComplete: any;
}

const DeleteModalRaw: React.FC<Props> = ({ kind, links, onCancel, onComplete }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  async function confirmDelete() {
    try {
      setIsLoading(true);
      const navigation = parentLink(kind as Kind);
      const promises: Promise<any>[] = [];
      for (const link of links) {
        promises.push(request({ method: "delete", url: link }));
      }
      await Promise.all(promises);
      if (kind === "gvc" || kind === "workload") {
        await UserData.requestHasWorkload();
      }
      notification.success({
        message: "Success",
        description: `Deleted Item${links.length > 1 ? "s" : ""}`,
      });
      onComplete();
      PromptContext.setIsDisabled(true);
      navigate(`/console${navigation}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      const hasDetails = e?.response?.data?.details?.usedBy && e.response.data.details.usedBy.length > 0;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to delete",
        description: hasDetails ? (
          <div>
            <span>{errorMessage}</span>
            <ul>
              {e.response.data.details.usedBy.map((link: string) => (
                <li className="cursor-pointer  " onClick={() => navigate(`/console${link}`)}>
                  {link}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <span>{errorMessage}</span>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title={
        <>
          Delete {nameOfKind(kind)}:{" "}
          <span className="font-bold" style={{ color: "var(--color-danger)" }}>
            {links.length < 2 ? getName(links[0]) : `${links.length} Items`}
          </span>
        </>
      }
      open={true}
      closable={false}
      maskClosable={!isLoading}
      onCancel={onCancel}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={onCancel} disabled={isLoading}>
            Cancel
          </NGButton>
          <NGButton variant="danger" onClick={confirmDelete} loading={isLoading} disabled={isLoading}>
            Delete
          </NGButton>
        </div>
      }
      destroyOnClose
    >
      <span>
        Are you sure you want to delete {links.length > 1 ? "these" : "this"}{" "}
        {links.length > 1 ? pluralNameOfKind(kind) : nameOfKind(kind)}? This action cannot be undone.
      </span>
    </Modal>
  );
};

export const DeleteModal = observer(DeleteModalRaw);
