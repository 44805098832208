import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderAWS } from "./aws";
import { Mk8sCreateProviderAWSAdvanced } from "./advanced";
import { Mk8sCreateProviderAWSNodePool } from "./nodePool";
import { Mk8sCreateProviderAWSNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderAWSNodePools } from "./nodePools";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderAWSRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderAWS mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderAWSNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderAWSNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderAWSNodePool mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<Mk8sCreateProviderAWSAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderAWSRoute = observer(Mk8sCreateProviderAWSRouteRaw);
