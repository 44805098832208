import { StringModel, StringMobx } from "./stringModel";

interface Props {
  label: string;
  examples?: string[];
  isRequired?: boolean;
  initialValue?: number | string;
  min?: number;
  max?: number;
  allowDot?: boolean;
}
export const NumberModel = {
  create: (props: Props): StringMobx => {
    let initialValue =
      props.initialValue !== undefined && props.initialValue !== null ? String(props.initialValue) : undefined;
    return StringModel.create({
      label: props.label,
      examples: props.examples,
      isRequired: props.isRequired,
      initialValue: initialValue,
      transformKey: props.allowDot ? "cpu" : "number",
      min: props.min,
      max: props.max,
    });
  },
};
