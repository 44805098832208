import { Instance, types } from "mobx-state-tree";

export const AutoscalerModel = types.model({
  expander: types.optional(
    types.array(types.enumeration(["random", "most-pods", "least-waste", "price", "priority"])),
    () => ["most-pods"]
  ), // max item count is 3
  unneededTime: types.optional(types.string, "10m"),
  unreadyTime: types.optional(types.string, "20m"),
  utilizationThreshold: types.optional(types.number, 0.7), // min: 0.1 - max: 1
});
export interface AutoscalerMobx extends Instance<typeof AutoscalerModel> {}
export type AutoscalingExpander = "random" | "most-pods" | "least-waste" | "price" | "priority";
export interface Autoscaler {
  expander: AutoscalingExpander[];
  unneededTime: string;
  unreadyTime: string;
  utilizationThreshold: number;
}
