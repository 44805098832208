import * as React from "react";
import clsx from "clsx";
import "./label.css";
import { useNGFormContext } from "../../reactContexts/ngFormContext";

// TODO might use aria required too
export interface NGLabelProps extends React.HTMLProps<HTMLLabelElement> {
  size?: 1 | 2 | 3 | 4;
  name?: string;
}

export const NGLabel: React.FC<NGLabelProps> = ({ children, size = 3, className, name, ...props }) => {
  const formData = useNGFormContext();

  return (
    <label
      className={clsx("nglabel", `nglabel-size-${size}`, { cursorPointer: formData && name }, className)}
      htmlFor={formData && name ? `${formData.id}${name}` : undefined}
      {...props}
    >
      {children}
    </label>
  );
};
