import { observer } from "mobx-react-lite";
import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { DryRunAlert } from "../dryRunAlert";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";
import NGAlert from "../../../newcomponents/alert";
import { ExternalLink } from "../../../newcomponents/table/components/ExternalLink";
import { DOCS_URL } from "../../../envVariables";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsAWSEFSRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const v = mk8sDraft.addon_awsEfs.roleArn;
  return (
    <div>
      <div className="mb-2">AWS EFS</div>
      <div className="mb-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.awsEFS"}
        />
      </div>
      <NGAlert
        className="mb-4"
        type={"info"}
        title="Documentation"
        render={() => {
          return (
            <div className="flex items-end gap-1">
              <ExternalLink url={`${DOCS_URL}/mk8s/add-ons/aws_efs`} label={`Click here`} disableCopy />
              <div>for learning how to configure the addon.</div>
            </div>
          );
        }}
      />
      <div className="flex gap-2 items-start">
        <NGFormElement name={"awsEfs.roleArn"} label={v.label} value={v.value} onChange={v.setValue} error={v.error} />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={v.setValue}
            path={"spec.addOns.awsEFS.roleArn"}
          />
        </div>
      </div>
    </div>
  );
};

export const AddonsAWSEFS = observer(AddonsAWSEFSRaw);
