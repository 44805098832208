import * as React from "react";
import { NGLabel } from "../../newcomponents/text/label";
import { Slash } from "react-feather";
import { Tooltip } from "../../components/Tooltip";
import { Table } from "../../newcomponents/table/table";
import { KindColumn } from "../../newcomponents/table/columns/wellKnown/kindColumn";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { TagsColumn } from "../../newcomponents/table/columns/wellKnown/tagsColumn";
import { GvcColumn } from "../../newcomponents/table/columns/wellKnown/gvcColumn";
import { ReleaseNameDescriptionColumn } from "../../newcomponents/table/marketplace/releaseNameDescriptionColumn";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { useDeploymentsReducer } from "../../components/detail/deploymentsReducer";
import { DEPLOYMENT_UPDATE_INTERVAL_MS } from "../../envVariables";
import { workloadHelpers } from "../../mst/kinds/workload.helpers";
import { WorkloadReadinessColumn } from "../../newcomponents/table/columns/workload/readinessColumn";
import { linksOf } from "../../services/cpln";
import { HelmReleaseInstance, ReleaseTableItem } from "../marketplace/types/release";

interface Props {
  release: HelmReleaseInstance;
  items: any[];
  itemsNotFound: any[];
}
export const ReleaseInfo: React.FC<Props> = ({ release, items, itemsNotFound }) => {
  const { state, fetchDeployments } = useDeploymentsReducer();
  React.useEffect(() => {
    const unsubscribe = fetchDeployments(
      items.filter((i) => i.kind === "workload").map((i) => linksOf(i).self!),
      DEPLOYMENT_UPDATE_INTERVAL_MS,
    );
    return () => {
      unsubscribe();
    };
  }, [items.length]);

  const releaseTableItems = [...items, ...itemsNotFound].map((item) => {
    let health = workloadHelpers.getHealth([], item);
    let fetchedReadiness = false;
    const selfLink = linksOf(item).self!;
    if (item.kind === "workload") {
      health = workloadHelpers.getHealth(state.deploymentsMap[selfLink], item);
      if (state.requestedLinks.includes(selfLink)) {
        fetchedReadiness = true;
      }
    }

    return {
      ...item,
      health: health,
      readiness: health.text,
      fetchedReadiness: fetchedReadiness,
    };
  });

  return (
    //
    <>
      <div className="release-detail">
        <NGLabel className="mb-1">Items</NGLabel>
        <Table<ReleaseTableItem>
          tableId="helm-release-detail-items"
          data={releaseTableItems}
          hideSettings
          columns={[
            ReleaseNameDescriptionColumn(undefined, { org: ConsoleContext.org || "", gvc: ConsoleContext.gvc || "" }),
            {
              id: "customInfo",
              label: "Info",
              cell: (p) => {
                const item = p.row.original;
                if (!item || !item.kind) {
                  return <span />;
                }

                if (item.notFound === true) {
                  return (
                    <Tooltip title={"Item not found"}>
                      <div className="flex items-center gap-2">
                        <Slash className={`feather-icon color-danger`} />
                        <span>Item not found</span>
                      </div>
                    </Tooltip>
                  );
                }

                if (item.kind === "workload") {
                  const _cell = WorkloadReadinessColumn().cell!;
                  return _cell(p as any);
                }

                return <span />;
              },
            },
            KindColumn(),
            GvcColumn(),
            TagsColumn(),
            CreatedColumn(),
            LastModifiedColumn(),
          ]}
        />
      </div>
    </>
  );
};
