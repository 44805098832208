import * as React from "react";
import { observer } from "mobx-react-lite";
import { DomainCreateNew } from "./create_new";
import { DomainDraftStoreModel } from "../../../mst/stores/domain.draft.store";

const DomainCreateNewRouteRaw: React.FC = () => {
  const draftStoreRef = React.useRef(DomainDraftStoreModel.create());

  React.useEffect(() => {
    draftStoreRef.current.new();
  }, []);

  if (!draftStoreRef.current.edit) return null;

  return <DomainCreateNew draft={draftStoreRef.current.edit} />;
};

export const DomainCreateNewRoute = observer(DomainCreateNewRouteRaw);
