import { observer } from "mobx-react-lite";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderGeneric } from "./generic";
import { Mk8sCreateProviderGenericNodePools } from "./nodePools";
import { Mk8sCreateProviderGenericNodePoolAdd } from "./nodePoolAdd";
import { Mk8sCreateProviderGenericNodePool } from "./nodePool";
import { Mk8sCreateProviderGenericAdvanced } from "./advanced";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderGenericRouteRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Mk8sCreateProviderGeneric mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools`} element={<Mk8sCreateProviderGenericNodePools mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/-add`} element={<Mk8sCreateProviderGenericNodePoolAdd mk8sDraft={mk8sDraft} />} />
      <Route path={`-node-pools/:index`} element={<Mk8sCreateProviderGenericNodePool mk8sDraft={mk8sDraft} />} />
      <Route path={`-advanced`} element={<Mk8sCreateProviderGenericAdvanced mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const Mk8sCreateProviderGenericRoute = observer(Mk8sCreateProviderGenericRouteRaw);
