import * as React from "react";
import clsx from "clsx";
import { RadioGroupState, useRadioGroupState } from "react-stately";
import { AriaRadioGroupProps, AriaRadioProps, VisuallyHidden, useFocusRing, useRadio, useRadioGroup } from "react-aria";
import "./index.scss";

// RadioGroup is the same as in the previous example
const RadioContext: any = React.createContext(null);

const NGRadio: React.FC<
  Omit<AriaRadioProps, "aria-label"> & { label: string; groupReadonly?: boolean; groupDisabled?: boolean }
> = (_props) => {
  const { children, label } = _props;
  const props = { ..._props, "aria-label": label };

  let state = React.useContext(RadioContext);
  let ref = React.useRef(null);
  let { inputProps, isSelected, isDisabled } = useRadio(props, state as RadioGroupState, ref);
  let { isFocusVisible, focusProps } = useFocusRing();
  let strokeWidth = isSelected ? 6 : 1;

  return (
    <label
      data-testid={typeof children === "string" ? `${children}-radio` : "radio"}
      className={clsx("ngradio", {
        notAllowed: props.isDisabled || props.groupReadonly || props.groupDisabled,
        disabled: props.isDisabled || isDisabled,
      })}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={24} height={24} aria-hidden="true">
        <circle
          cx={12}
          cy={12}
          r={8 - strokeWidth / 2}
          fill={isSelected ? "white" : "none"}
          className={clsx("ngradio-circle", { selected: isSelected })}
          strokeWidth={strokeWidth}
        />
        {/* TODO move to global */}
        {isFocusVisible && <circle cx={12} cy={12} r={11} fill="none" stroke="var(--color-blue-200)" strokeWidth={2} />}
      </svg>
      {children}
    </label>
  );
};

interface Props extends AriaRadioGroupProps, React.PropsWithChildren {}
const RadioGroup: React.FC<Props> = (props) => {
  let { children, label, description, errorMessage } = props;
  let state = useRadioGroupState(props);
  let { radioGroupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(props, state);

  return (
    <div {...radioGroupProps} className="flex flex-col gap2">
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
      {description && (
        <div {...descriptionProps} style={{ fontSize: 12 }}>
          {description}
        </div>
      )}
      {errorMessage && state.isInvalid && (
        <div
          className="text-sm mt-2 font-semibold leading-snug"
          style={{ color: "var(--color-red-400)" }}
          {...errorMessageProps}
        >
          {String(errorMessage)}
        </div>
      )}
    </div>
  );
};

interface RadioGroupOption extends Omit<AriaRadioProps, "aria-label"> {
  label: React.ReactNode;
  labelString?: string;
}

interface Props extends AriaRadioGroupProps {
  isVertical?: boolean;
  options: RadioGroupOption[];
  className?: string;
}

export const NGRadioGroup: React.FC<Props> = ({ isVertical = false, options, className, ...radioGroupProps }) => {
  return (
    <RadioGroup {...radioGroupProps} options={[]}>
      <div
        className={clsx(
          "ngradio-group",
          {
            vertical: isVertical,
          },
          className
        )}
      >
        {options.map((option) => (
          <NGRadio
            key={option.value}
            groupDisabled={radioGroupProps.isDisabled}
            groupReadonly={radioGroupProps.isReadOnly}
            {...option}
            label={(option.labelString || option.label) as any}
          >
            {option.label}
          </NGRadio>
        ))}
      </div>
    </RadioGroup>
  );
};
