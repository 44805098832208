import * as React from "react";

export function useOutsideClick(refs: React.MutableRefObject<HTMLElement>[], onClick: any) {
  React.useEffect(() => {
    const onDocumentPointerUp = (e: PointerEvent) => {
      const target = e.target as HTMLElement;
      for (const ref of refs) {
        if (ref.current?.contains(target)) {
          return;
        }
      }
      onClick();
    };
    document.addEventListener("pointerup", onDocumentPointerUp);
    return () => {
      document.removeEventListener("pointerup", onDocumentPointerUp);
    };
  }, []);
}
