import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { memoryUnitOptions } from "../../mobxDataModels/memoryModel";
import { cpuUnitOptions } from "../../mobxDataModels/cpuModel";

// An enum with all the types of actions to use in our reducer
export enum RunCronReducerActionType {
  SELECT_CONTAINER = "SELECT_CONTAINER",
  OVERRIDE = "OVERRIDE",
  COMMAND = "COMMAND",
  ARGS = "ARGS",
  ADD_ARG = "ADD_ARG",
  REMOVE_ARG = "REMOVE_ARG",
  CHANGE_ARG = "CHANGE_ARG",
  ADD_ENV = "ADD_ENV",
  REMOVE_ENV = "REMOVE_ENV",
  CHANGE_ENV = "CHANGE_ENV",
  REMOVE_SELECTIONS = "REMOVE_SELECTIONS",
  TOGGLE_ALL_SELECT = "TOGGLE_ALL_SELECT",
  TOGGLE_SELECT = "TOGGLE_SELECT",
  SET_MEMORY = "SET_MEMORY",
  SET_CPU = "SET_CPU",
  SET_IMAGE = "SET_IMAGE",
}

// An interface for our actions
interface RunCronReducerAction {
  type: RunCronReducerActionType;
  payload: any;
}

// An interface for our state
interface State {
  selectedName: string;
  containerNames: string[];
  overrides: {
    isOverridden: boolean;
    name: string;
    command: string;
    args: { id: string; chosen: boolean; name: string }[];
    argSelections: string[];
    isAllArgsSelected: boolean; // actually a getter
    env: { index: number; name: string; value: string }[];
    memory: { value: string; suffix: string; isValid: boolean };
    cpu: { value: string; suffix: string; isValid: boolean };
    image: string;
  }[];
}

// Our reducer function that uses a switch statement to handle our actions
function runCronReducer(state: State, action: RunCronReducerAction) {
  const { type, payload } = action;
  const selectedOverride = state.overrides.find((o) => o.name === state.selectedName)!;
  const restOfTheOverrides = state.overrides.filter((o) => o.name !== state.selectedName);
  switch (type) {
    case RunCronReducerActionType.SELECT_CONTAINER:
      return {
        ...state,
        selectedName: payload,
      };
    case RunCronReducerActionType.OVERRIDE:
      selectedOverride.isOverridden = payload;

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.COMMAND:
      selectedOverride.command = payload;

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.ARGS:
      selectedOverride.args = payload;

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.ADD_ARG:
      selectedOverride.args = [...selectedOverride.args, { id: uuidv4(), chosen: false, name: "" }];

      return {
        ...state,
        argInput: "",
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.REMOVE_ARG:
      selectedOverride.args = selectedOverride.args.filter((a) => a.id !== payload);

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.CHANGE_ARG:
      selectedOverride.args = selectedOverride.args.map((arg) => {
        if (arg.id === payload.id) {
          return payload;
        }
        return arg;
      });

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.ADD_ENV:
      selectedOverride.env = [...selectedOverride.env, { index: selectedOverride.env.length, name: "", value: "" }];

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.REMOVE_ENV:
      selectedOverride.env = selectedOverride.env.filter((e) => e.index !== payload);

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.CHANGE_ENV:
      selectedOverride.env = selectedOverride.env.map((env) => {
        if (env.index === payload.index) {
          return payload;
        }
        return env;
      });

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.TOGGLE_SELECT:
      let selections = [...selectedOverride.argSelections];
      if (selections.includes(payload.id)) {
        selections = selections.filter((selection) => selection !== payload.id);
      } else {
        selections.push(payload.id);
      }
      selectedOverride.argSelections = selections;
      selectedOverride.isAllArgsSelected = selectedOverride.args.length === selections.length;
      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.SET_MEMORY:
      let isMemoryValid = true;
      const memoryRegex = /^[0-9]+(\.[0-9]{1,3})?(G|M|k|Gi|Mi|Ki)?$/i;
      if (payload.value && !payload.value.match(memoryRegex)) {
        isMemoryValid = false;
      }
      selectedOverride.memory = { ...payload, isValid: isMemoryValid };

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.SET_CPU:
      let isCpuValid = true;
      const cpuRegex = /^([0-9]+)(m|(\.[0-9]{1,3}))?$/;
      if (payload.value && !payload.value.match(cpuRegex)) {
        isCpuValid = false;
      }
      selectedOverride.cpu = { ...payload, isValid: isCpuValid };

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.SET_IMAGE:
      selectedOverride.image = payload;

      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.TOGGLE_ALL_SELECT:
      if (selectedOverride.isAllArgsSelected) {
        selectedOverride.isAllArgsSelected = false;
        selectedOverride.argSelections = [];
      } else {
        selectedOverride.isAllArgsSelected = true;
        selectedOverride.argSelections = selectedOverride.args.map((arg) => arg.id);
      }
      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    case RunCronReducerActionType.REMOVE_SELECTIONS:
      selectedOverride.args = selectedOverride.args.filter((arg) => !selectedOverride.argSelections.includes(arg.id));
      selectedOverride.argSelections = [];
      selectedOverride.isAllArgsSelected = false;
      return {
        ...state,
        overrides: [...restOfTheOverrides, selectedOverride],
      };
    default:
      return state;
  }
}

export function useRunCronReducer(containerNames: string[]) {
  const initialState: State = {
    selectedName: containerNames[0],
    containerNames: containerNames,
    overrides: containerNames.map((name) => ({
      isOverridden: false,
      name: name,
      command: "",
      args: [],
      argSelections: [],
      isAllArgsSelected: false,
      env: [],
      memory: { value: "", suffix: memoryUnitOptions[2].value, isValid: true },
      cpu: { value: "", suffix: cpuUnitOptions[0].value, isValid: true },
      image: "",
    })),
  };
  return React.useReducer(runCronReducer, initialState);
}
