import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import {
  RestoreVolumeReducerState,
  RunRestoreVolumeReducerActionType,
  useRunRestoreVolumeReducer,
} from "./reducer_restoreVolume";
import { VolumeSetMobx } from "../../../mst/kinds/volumeset";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGInput } from "../../../newcomponents/input/input";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";

interface Props {
  volumeSet: VolumeSetMobx;
  initialLocation?: string;
  initialVolumeIndex?: string;
  initialSnapshotName?: string;
  onClose: any;
  onDone: any;
  visible: boolean;
}

// Extract snapshot names for a specific volume in a specific location
export function getSnapshotNames(volumeSet: VolumeSetMobx, locationName: string, volumeIndex: number): string[] {
  const snapshotNames: string[] = [];
  const location = volumeSet.status.locations.find(({ name: l }) => l === locationName);

  if (location) {
    if (location.volumes[volumeIndex]) {
      const volume = location.volumes[volumeIndex];
      volume.volumeSnapshots.forEach((snapshot) => {
        snapshotNames.push(snapshot.name);
      });
    }
  }

  return snapshotNames;
}

const CommandModal_RestoreVolumeRaw: React.FC<Props> = ({
  volumeSet,
  initialLocation,
  initialVolumeIndex,
  initialSnapshotName,
  onClose,
  onDone,
  visible,
}) => {
  const formDataRef = React.useRef(new NGFormData());
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, dispatch] = useRunRestoreVolumeReducer(initialLocation, initialVolumeIndex, initialSnapshotName);

  async function handleConfirm() {
    try {
      setIsLoading(true);

      const spec: RestoreVolumeReducerState = JSON.parse(JSON.stringify(state));

      await request({
        method: "post",
        url: `${volumeSet.selfLink}/-command`,
        body: {
          type: "restoreVolume",
          spec: spec,
        },
      });
      onDone();
      notification.success({
        message: "Success",
        description: "Created Command.",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  let isValid = true;
  if (!state.location) isValid = false;
  if (!state.volumeIndex) isValid = false;
  if (!state.snapshotName) isValid = false;

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <Modal
        width={450}
        open={visible}
        closable={false}
        title="Restore Volume To Snapshot"
        footer={
          <div className="modal-actions">
            <NGButton
              disabled={isLoading}
              onClick={() => {
                onClose();
              }}
              variant={"danger"}
              outlined
            >
              Cancel
            </NGButton>
            <NGButton loading={isLoading} disabled={isLoading || !isValid} onClick={handleConfirm} variant={"action"}>
              Restore
            </NGButton>
          </div>
        }
        onCancel={onClose}
        maskClosable={!isLoading}
        destroyOnClose
      >
        <NGFormLabel name={"location"} required invalid={!state.location}>
          Location
        </NGFormLabel>
        <NGSelect
          className="mb-4"
          name={"location"}
          invalid={!state.location}
          value={state.location}
          onChange={(value) => dispatch({ type: RunRestoreVolumeReducerActionType.LOCATION, payload: value })}
          options={volumeSet.status.locations.map(({ name: l }) => ({
            label: l,
            value: l,
            disabled: false,
            isLabel: false,
          }))}
        />
        <NGFormLabel name={"volumeIndex"} required invalid={state.volumeIndex.length < 1}>
          Volume Index
        </NGFormLabel>
        <NGInput
          name={"volumeIndex"}
          className="mb-4"
          value={state.volumeIndex}
          invalid={state.volumeIndex.length < 1}
          onChange={(e) => dispatch({ type: RunRestoreVolumeReducerActionType.INDEX, payload: e.target.value })}
        />
        <NGFormLabel name={"snapshotName"} required invalid={!state.snapshotName}>
          Snapshot Name
        </NGFormLabel>
        <NGSelect
          name={"snapshotName"}
          className="mb-4"
          value={state.snapshotName}
          invalid={!state.snapshotName}
          onChange={(value) => dispatch({ type: RunRestoreVolumeReducerActionType.NAME, payload: value })}
          options={getSnapshotNames(volumeSet, state.location, state.volumeIndex).map((name) => ({
            label: name,
            value: name,
            disabled: false,
            isLabel: false,
          }))}
        />
      </Modal>
    </NGFormContext.Provider>
  );
};

export const CommandModal_RestoreVolume = observer(CommandModal_RestoreVolumeRaw);
