import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateRolloutOptionsRaw: React.FC<Props> = ({ workloadDraft }) => {
  const options = workloadDraft.rolloutOptions;

  return (
    <>
      <div className="mb-4">
        <NGSwitch value={options.configure} onChange={options.setConfigure}>
          <NGLabelText>Configure Rollout Options</NGLabelText>
        </NGSwitch>
      </div>
      {options.configure ? (
        <>
          <NGFormElement
            label={options.minReadySeconds.label}
            name={`minReadySeconds`}
            value={options.minReadySeconds.value}
            onChange={options.minReadySeconds.setValue}
          />
          <NGFormElement
            label={options.maxSurgeReplicas.label}
            name={`maxSurgeReplicas`}
            value={options.maxSurgeReplicas.value}
            onChange={options.maxSurgeReplicas.setValue}
          />
          <NGFormElement
            name={"scalingPolicy"}
            as={"select"}
            label={options.scalingPolicy.label}
            options={options.scalingPolicy.options}
            value={options.scalingPolicy.value}
            onChange={options.scalingPolicy.setValue}
          />
        </>
      ) : null}
    </>
  );
};

export const WorkloadCreateRolloutOptions = observer(WorkloadCreateRolloutOptionsRaw);
