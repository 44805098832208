import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { Routes, Route } from "react-router-dom";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { ImageDetailRoute } from "./imageDetailRoute";

const ImageRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org}>
      <Routes>
        <Route path={`*`} element={<ImageDetailRoute />} />
      </Routes>
    </Layout>
  );
};

export default observer(ImageRoute);
