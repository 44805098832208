import { types } from "mobx-state-tree";
import { TaintModel } from "./common";

const EphemeralProviderNodePoolModel = types.model("AWS Provider Node Pool", {
  name: types.string,
  labels: types.frozen(),
  taints: types.array(TaintModel),
  count: types.number, // 0 to 4
  arch: types.optional(types.string, "arm64"), // 'any', 'arm64', 'amd64'
  flavor: types.optional(types.string, "debian"), // 'debian', 'ubuntu', 'fedora'
  cpu: types.string,
  memory: types.string,
});

export const EphemeralProviderModel = types.model("AWS Provider", {
  location: types.string, // 'aws-eu-central-1', 'aws-us-east-2', 'aws-us-west-2', 'gcp-me-west1', 'gcp-us-east1'
  nodePools: types.array(EphemeralProviderNodePoolModel),
});
