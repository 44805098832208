import * as React from "react";
import { DetailHeader } from "./header";
import { DetailNav, DetailNavLink } from "./nav";
import { Route, Navigate, Routes, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { InnerNavLink, NGDetailNav } from "./ngNav";
import { Deployment } from "../../schema/types/workload/deployment";

interface Props extends React.PropsWithChildren {
  links: DetailNavLink[];
  ngLinks?: InnerNavLink[];
  defaultPath?: string;
  contentStyle?: any;
  useNewNav?: boolean;
  renderButtons?: () => React.ReactElement;
  hideButtons?: string[];
  deployments?: Deployment[];
}
const DetailLayoutRaw: React.FC<Props> = ({
  children,
  links,
  ngLinks,
  defaultPath = "-info",
  contentStyle = {},
  useNewNav = false,
  renderButtons = () => null,
  hideButtons = [],
  deployments = [],
}) => {
  const { pathname } = useLocation();

  let noButtons = false;
  for (const hideButton of hideButtons) {
    if (pathname.endsWith(hideButton)) {
      noButtons = true;
      break;
    }
  }

  return (
    <div className="relative n-detail">
      <DetailHeader deployments={deployments} />
      <div className="flex n-detail-body">
        <div className="n-detail-nav-parent" style={{ marginTop: 32 }}>
          {useNewNav ? <NGDetailNav links={ngLinks || []} /> : <DetailNav links={links} />}
        </div>
        <div className="n-detail-content relative" style={contentStyle}>
          <Routes>
            <Route index element={<Navigate to={defaultPath} replace />} />
            {children}
          </Routes>
          {noButtons ? null : renderButtons()}
        </div>
      </div>
    </div>
  );
};

export const DetailLayout = observer(DetailLayoutRaw);
