import * as React from "react";
import { FormElement } from "../../components/forms/formElement";
import { QuotaMobx } from "../../mst/kinds/quota";

interface Props {
  quota: QuotaMobx;
}
export const Info: React.FC<Props> = ({ quota }) => {
  return (
    <div>
      <div className="mb-4">
        <FormElement label={"Description"} value={quota.description} />
      </div>
      <div className="flex">
        <div className={`w-3/12`}>
          <FormElement label={"Current / Max Limit"} value={`${quota.current} / ${quota.max}`} />
        </div>
        {quota.gvcLink ? (
          <div className={`w-3/12`}>
            <FormElement label={"GVC"} value={quota.gvcLink} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
