import { WorkloadTableItem } from "../../../schema/types/workload/workloadTableItem";
import { CreatedColumn } from "../columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../columns/wellKnown/lastModifiedColumn";
import { NameDescriptionColumn } from "../columns/wellKnown/nameDescriptionColumn";
import { TagsColumn } from "../columns/wellKnown/tagsColumn";
import { WorkloadCapacityAiColumn } from "../columns/workload/capacityAiColumn";
import { WorkloadContainersColumn } from "../columns/workload/containersColumn";
import { WorkloadMaxScaleColumn } from "../columns/workload/maxScaleColumn";
import { WorkloadMetricColumn } from "../columns/workload/metricColumn";
import { WorkloadMinScaleColumn } from "../columns/workload/minScaleColumn";
import { WorkloadReadinessColumn } from "../columns/workload/readinessColumn";
import { WorkloadReadyLocationsColumn } from "../columns/workload/readyLocationsColumn";
import { WorkloadReadyReplicasColumn } from "../columns/workload/readyReplicasColumn";
import { WorkloadSuspendedColumn } from "../columns/workload/suspendedColumn";
import { WorkloadTypeColumn } from "../columns/workload/typeColumn";
import { TableColumn } from "../types";

export const WorkloadColumnGroup: TableColumn<WorkloadTableItem>[] = [
  NameDescriptionColumn(),
  WorkloadTypeColumn(),
  WorkloadReadinessColumn(),
  WorkloadSuspendedColumn(),
  WorkloadReadyLocationsColumn(),
  WorkloadReadyReplicasColumn(),
  WorkloadContainersColumn(),
  WorkloadCapacityAiColumn(),
  WorkloadMetricColumn(),
  WorkloadMinScaleColumn(),
  WorkloadMaxScaleColumn(),
  TagsColumn(),
  CreatedColumn(),
  LastModifiedColumn(),
];
