import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { defaultValues } from "../base";

export const Mk8sDraftNetworkingModel = types
  .model({
    _serviceNetwork: types.optional(types.string, ""),
    _podNetwork: types.optional(types.string, ""),
    serviceNetwork: types.optional(StringModel, () =>
      StringModel.create({
        label: "Service Network",
      }),
    ),
    podNetwork: types.optional(StringModel, () =>
      StringModel.create({
        label: "Pod Network",
      }),
    ),
  })
  .actions((self) => ({
    reset() {
      self.serviceNetwork.setInitialValue(self._serviceNetwork);
      self.podNetwork.setInitialValue(self._podNetwork);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._serviceNetwork = self.serviceNetwork.value;
      self._podNetwork = self.podNetwork.value;
      self.reset();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.serviceNetwork.isDirty) res = true;
      if (self.podNetwork.isDirty) res = true;

      return res;
    },
    get dirtyReason() {
      let reason = "";

      if (self.serviceNetwork.isDirty) reason = self.serviceNetwork.isDirtyReason;
      if (self.podNetwork.isDirty) reason = self.podNetwork.isDirtyReason;

      return reason;
    },
    get isValid() {
      let res = true;
      // Not available
      // if (!self.serviceNetwork.isValid) res = false;
      // if (!self.podNetwork.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      // Not available
      // if (!self.serviceNetwork.isValid) reason = self.serviceNetwork.invalidReason;
      // if (!self.podNetwork.isValid) reason = self.podNetwork.invalidReason;
      return reason;
    },
    dryRun_pathList(provider: string) {
      return [
        //
        `spec.provider.${provider}.networking`,
        `spec.provider.${provider}.networking.serviceNetwork`,
        `spec.provider.${provider}.networking.podNetwork`,
      ];
    },
    get asObject() {
      let obj: any = {};
      if (self.serviceNetwork.value) {
        obj.serviceNetwork = self.serviceNetwork.value;
      }
      if (self.podNetwork.value) {
        obj.podNetwork = self.podNetwork.value;
      }
      return obj;
    },
  }));
export interface Mk8sDraftNetworkingMobx extends Instance<typeof Mk8sDraftNetworkingModel> {}
