import * as React from "react";
import "./index.css";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { VersionCopyright } from "../../../generic/version";
import { IS_DEPLOYMENT_ENV_TEST, MARKETING_URL, STORAGE_KEY_LOGGED_OUT_STATE } from "../../../../envVariables";
import { notification } from "antd";
import { User } from "../../../../mobxStores/user/user";
import { CircularThemeSwitch } from "../../../CircularThemeSwitch/CircularThemeSwitch";
import { Theme } from "../../../../mobxStores/uiData/theme";
import { flowResult } from "mobx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Globe, Lock } from "react-feather";
import { TestimonialHorizontal } from "./../testimonial-horizontal/testimonial-horizontal";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGFormData } from "../../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import NGAlert from "../../../../newcomponents/alert";

const STORAGE_KEY_LOGIN_EMAIL = "LOGIN_EMAIL";
const STORAGE_KEY_LOGIN_TYPE = "LOGIN_TYPE";

const SignInRaw: React.FC = () => {
  const { theme } = Theme;
  const { search } = useLocation();
  const navigate = useNavigate();
  const formDataRef = React.useRef(new NGFormData());

  const page = window.location.pathname.includes("/signup") ? "signup" : "signin";
  const showLoggedOutAlert: boolean = localStorage.getItem(STORAGE_KEY_LOGGED_OUT_STATE) === "true";

  const [loginType, setLoginType] = React.useState<"saml" | "email" | "provider">("provider");
  const emailInputRef = React.useRef(StringModel.create({ label: "Email", isRequired: true, validationKey: "email" }));
  const passwordInputRef = React.useRef(StringModel.create({ label: "Password", isRequired: true, isSensitive: true }));

  const loadedFromQueryRef = React.useRef<boolean>(false);
  const loadedFromStorageRef = React.useRef<boolean>(false);

  // TODO remove, only used by cypress test
  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key == "q") {
        setLoginType((v) => (v === "email" ? "provider" : "email"));
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  // Load login type and email from query params
  React.useEffect(() => {
    if (!search.startsWith("?loginType=")) {
      return;
    }

    try {
      const loginType = search.split("?loginType=")[1].split("&email=")[0];
      if (!["email", "saml"].includes(loginType)) {
        throw new Error("Saved query param is not of email or saml");
      }
      setLoginType(loginType as any);

      const email = search.split("?loginType=")[1].split("&email=")[1];
      emailInputRef.current.setValue(email || "");

      loadedFromQueryRef.current = true;
    } catch (e) {
      setLoginType("provider");
    }
  }, []);

  // Load saved login type and email
  React.useEffect(() => {
    if (loginType !== "saml" && loginType !== "email") {
      return;
    }

    if (!loadedFromQueryRef.current && !loadedFromStorageRef.current) {
      const localLoginType = localStorage.getItem(STORAGE_KEY_LOGIN_TYPE);
      if (localLoginType === loginType) {
        const email = localStorage.getItem(STORAGE_KEY_LOGIN_EMAIL);
        emailInputRef.current.setValue(email || "");
      }
      loadedFromStorageRef.current = true;
    }
  }, [loginType]);

  // Save login type and email
  React.useEffect(() => {
    if (!loadedFromStorageRef.current) {
      return;
    }

    if (loginType !== "saml" && loginType !== "email") {
      return;
    }

    localStorage.setItem(STORAGE_KEY_LOGIN_TYPE, loginType);

    const email = emailInputRef.current.value;
    if (!!email) {
      localStorage.setItem(STORAGE_KEY_LOGIN_EMAIL, email);
      navigate({ search: `?loginType=${loginType}&email=${email}` });
    } else {
      navigate({ search: `` });
    }
  }, [loginType, emailInputRef.current.value]);

  async function tryAuthenticate(
    provider: "google" | "microsoft" | "github" | "email" | "saml" | "samlLogin",
    email?: string,
    password?: string
  ) {
    try {
      switch (provider) {
        case "saml":
          await flowResult(User.signInSaml(email!));
          break;
        case "email":
          await flowResult(User.signInEmail(email!, password!));
          break;
        case "google":
          await flowResult(User.signInByProvider("google"));
          break;
        case "github":
          await flowResult(User.signInByProvider("github"));
          break;
        case "microsoft":
          await flowResult(User.signInByProvider("microsoft"));
          break;
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to Login", description: e.message });
      setLoginType("provider");
    }
  }

  return (
    <>
      <NGFormContext.Provider value={formDataRef.current}>
        <Helmet>
          <title>
            {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
            {page === "signin" ? "Sign In" : "Sign up"} - From many clouds, one™
          </title>
        </Helmet>
        <div
          className="signin-page flex text-normal w-full min-h-screen items-stretch p-16 flex-col"
          style={{ backgroundColor: "var(--color-drop)" }}
        >
          <div className="w-full flex justify-center mb-4">
            <a href={`${MARKETING_URL}`} target={"_blank"}>
              <img
                className="controlplane-logo"
                height={"auto"}
                src={`/resources/logos/controlPlaneLogo${theme === "dark" ? "White" : ""}.svg`}
                alt="Control Plane Logo"
              />
            </a>
          </div>{" "}
          {showLoggedOutAlert && (
            <NGAlert
              message="You have been automatically logged out of the application due to inactivity. You can configure session timeout from Org info page."
              type="warning"
              className="w-fit mx-auto my-2"
            />
          )}
          {page === "signin" ? (
            <div className="w-full flex justify-center mb-12 mt-2">
              <div className="text-4xl">Sign In to Control Plane</div>
            </div>
          ) : (
            <>
              <div className="w-full flex justify-center mb-4 mt-2">
                <div className="text-4xl">Create a Control Plane Account</div>
              </div>
              <div className="w-full flex justify-center mb-12 mt-2">
                <div className="text-2xl">Access The Enterprise Platform to Run Unbreakable Cloud-Native Apps</div>
              </div>
            </>
          )}
          {loginType !== "provider" ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (loginType === "email") {
                  tryAuthenticate("email", emailInputRef.current.value, passwordInputRef.current.value);
                }
                if (loginType === "saml") {
                  tryAuthenticate("saml", emailInputRef.current.value);
                }
              }}
              className="flex flex-col items-center w-full"
            >
              <div style={{ width: 300 }} className="flex flex-col">
                <NGFormElement
                  name={"email"}
                  label={emailInputRef.current.label}
                  value={emailInputRef.current.value}
                  onChange={emailInputRef.current.setValue}
                  className="mb-2"
                  style={{ width: 300 }}
                />
                {loginType === "email" ? (
                  <NGFormElement
                    name={"password"}
                    label={passwordInputRef.current.label}
                    value={passwordInputRef.current.value}
                    onChange={passwordInputRef.current.setValue}
                    className="mb-2"
                    style={{ width: 300 }}
                  />
                ) : null}
                <NGButton className="mt-4" style={{ width: 300 }} type={"submit"} variant={"primary"} color={"blue"}>
                  Log In
                </NGButton>
                <NGButton
                  style={{ width: 300 }}
                  className="my-2"
                  onClick={() => {
                    setLoginType("provider");
                  }}
                  variant={"secondary"}
                >
                  Back
                </NGButton>
              </div>
            </form>
          ) : (
            <div className="flex flex-col items-center w-full text-sm font-medium">
              <button
                onClick={() => tryAuthenticate("google")}
                className={`mb-4 flex items-center pl-4`}
                style={{
                  width: 220,
                  height: 40,
                  backgroundColor: "var(--color-white)",
                  boxShadow: `0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)`,
                }}
              >
                <span>
                  <img width={18} height={18} src="/resources/logos/login/google.svg" />
                </span>
                <span className="pl-4 font-medium" style={{ color: "#757575" }}>
                  Continue with Google
                </span>
              </button>
              <button
                onClick={() => tryAuthenticate("github")}
                className={`mb-4 flex items-center pl-4`} //
                style={{
                  width: 220,
                  height: 40,
                  boxShadow: `0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)`,
                  backgroundColor: "#333",
                  color: "white",
                }}
              >
                <span>
                  <img width={18} height={18} src="/resources/logos/login/github.svg" />
                </span>
                <span className="pl-4 font-medium">Continue with Github</span>
              </button>
              <button
                onClick={() => tryAuthenticate("microsoft")}
                className={`mb-4 flex items-center pl-4`} //
                style={{
                  width: 220,
                  height: 40,
                  boxShadow: `0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)`,
                  backgroundColor: "#2f2f2f",
                  color: "white",
                }}
              >
                <span>
                  <img width={18} height={18} src="/resources/logos/login/microsoft.svg" />
                </span>
                <span className="pl-4">Continue with Microsoft</span>
              </button>
              <button
                onClick={() => setLoginType("saml")}
                className={`mb-4 flex items-center pl-4`} //
                style={{
                  width: 220,
                  height: 40,
                  boxShadow: `0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)`,
                  backgroundColor: "#2f2f2f",
                  color: "white",
                }}
              >
                <span style={{ width: 18, height: 18 }} className="relative">
                  <span className="absolute inset-0">
                    <Globe style={{ width: "100%", height: "100%" }} />
                  </span>
                  <span className="absolute" style={{ top: -4, right: -6 }}>
                    <Lock style={{ width: 10, height: 10 }} />
                  </span>
                </span>
                <span className="pl-4">Continue with SAML</span>
              </button>
            </div>
          )}
          {page === "signin" ? (
            <div className="flex items-center w-full justify-center gap-2">
              <div>New to Control Plane?</div>
              <Link to={"/signup"} style={{ color: "var(--color-blue-300)" }}>
                Sign Up
              </Link>
            </div>
          ) : (
            <>
              <div className="flex items-center w-full justify-center gap-2">
                <div>Already have an account?</div>
                <Link to={"/signin"} style={{ color: "var(--color-blue-300)" }}>
                  Sign In
                </Link>
              </div>
              <TestimonialHorizontal
                quote="With Control Plane, we can go from concept to production so much faster. What used to take weeks or months now takes an hour."
                company="CEO at Verdova"
                photo={"/resources/images/maxFeinberg-verdova.jpeg"}
                name={"Max Feinberg"}
              />
            </>
          )}
          <div className="flex-grow" />
          <div className="mt-16">
            <div className={"flex items-center justify-center mb-2"}>
              <CircularThemeSwitch />
            </div>
            <div className="text-footer text-center">
              <VersionCopyright /> {new Date().getFullYear()} Control Plane. All rights reserved.
            </div>
          </div>
        </div>
      </NGFormContext.Provider>
    </>
  );
};

export const SignIn = observer(SignInRaw);
