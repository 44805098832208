import * as React from "react";
import { observer } from "mobx-react-lite";
import { StringMobx } from "../../mobxDataModels/stringModel";
import { AlertTriangle, Eye, EyeOff } from "react-feather";
interface Props {
  data: StringMobx;
  autoFocus?: boolean;
  onClear?: Function;
  className?: string;
  disabled?: boolean;
  style?: any;
  setValue?: (value: string) => void;
  textArea?: boolean;
  dataTestId?: string;
  idSuffix?: string;
  onBlur?: any;
  hideErrorMessage?: boolean;
  disableFocusRing?: boolean;
}

const CheckMark = () => (
  <span className="formmark ml-1 text-xs" style={{ color: "transparent", textShadow: "0 0 0 var(--color-action)" }}>
    &#10004;
  </span>
);
const RequiredMark = () => <span className="formmark ml-1 color-danger">*</span>;
const InvalidMark = () => (
  <span className="formmark ml-1 color-danger transform" style={{ transform: "translateY(-5px)" }}>
    x
  </span>
);

const MobxInputRaw: React.FC<Props> = ({
  data,
  onClear = () => {},
  className = "",
  autoFocus = false,
  disabled = false,
  style = {},
  setValue,
  textArea = false,
  dataTestId,
  idSuffix = "",
  onBlur,
  hideErrorMessage = false,
  disableFocusRing = false,
}) => {
  /* Constants */
  const inputRef = React.useRef<HTMLInputElement>(null as any);
  const hasInput = !!data.value;
  const isInvalid = !!data.error && !data.isValid;

  /* Effects */
  React.useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  React.useEffect(() => {
    if (data.value.length < 1) {
      onClear();
    }
  }, [data.value]);

  /* Functions */
  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setValue ? setValue(e.target.value) : data.setValue(e.target.value);
  }

  return (
    <div className={`relative ${className}`} style={style}>
      <div className={`relative w-full flex flex-col rounded ${isInvalid && "invalid"}`}>
        <input
          data-testid={dataTestId}
          type={data.isSensitive ? (data._isHidden ? "password" : "text") : textArea ? "textarea" : "text"}
          ref={inputRef}
          disabled={disabled}
          id={`${data.label.toLowerCase()}${idSuffix}`}
          className={`cpln-input ${
            disableFocusRing ? "focus:outline-none" : "focus"
          } inline-block border rounded flex-grow h-12 w-full ${hasInput ? "pt-5 pb-1" : ""} ${
            data.isSensitive ? "pr-8" : "pr-4"
          } ${disabled ? "cursor-not-allowed" : "cursor-text"} ${isInvalid && "border-danger"}`}
          style={{
            paddingLeft: hasInput ? 14.5 : 16,
            color: "var(--color-input)",
            backgroundColor: "var(--color-input-bg)",
            borderColor: "var(--color-input-border)",
          }}
          onChange={handleChange}
          value={data.value}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
          }}
        />
        <label
          htmlFor={`${data.label.toLowerCase()}${idSuffix}`}
          className={`flex absolute left-4 transition-all ${hasInput ? "top-1" : "top-3"} ${
            disabled ? "cursor-not-allowed" : "cursor-text"
          }`}
          onClick={(_) => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          <span className={`${hasInput ? "font-medium text-xs" : "text-light"} truncate`}>{data.label}</span>
          {data.infoMark === "check" && <CheckMark />}
          {data.infoMark === "required" && <RequiredMark />}
          {data.infoMark === "invalid" && <InvalidMark />}
        </label>
        {data.isSensitive ? (
          <button
            type={"button"}
            className={`focus absolute top-4 right-2`}
            onClick={() => {
              data.setIsHidden(!data._isHidden);
            }}
          >
            {data._isHidden ? (
              <Eye className="feather-icon" data-testid="toggle-visible" />
            ) : (
              <EyeOff className="feather-icon" data-testid="toggle-visible" />
            )}
          </button>
        ) : null}
      </div>
      {!hideErrorMessage && data.error ? (
        <div className="pl-4 mt-1 color-danger text-sm">{data.error}</div>
      ) : data.examples.length > 0 ? (
        <div className="pl-4 mt-1 text-sm">
          Example{data.examples.length > 1 ? "s" : ""}: {data.examples.join(", ")}
        </div>
      ) : null}
    </div>
  );
};

export const MobxInput = observer(MobxInputRaw);
