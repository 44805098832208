import { types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Mk8sDraftProviderAWSAMIModel, Mk8sDraftProviderAWSAMIReadOnlyModel } from "./mk8s.draft.provider.aws.ami";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";

export const Mk8sDraftProviderAWSNodePoolsReadonlyModel = types.model({
  name: types.optional(types.string, ""),
  labels: types.array(
    types.model({
      key: types.string,
      value: types.string,
    })
  ),
  taints: types.array(
    types.model({
      key: types.string,
      value: types.string,
      effect: types.string,
    })
  ),
  instanceTypes: types.array(types.string),
  overrideImage: types.optional(Mk8sDraftProviderAWSAMIReadOnlyModel, () =>
    Mk8sDraftProviderAWSAMIReadOnlyModel.create()
  ),
  bootDiskSize: types.optional(types.number, 20),
  minSize: types.maybe(types.number),
  maxSize: types.maybe(types.number),
  onDemandBaseCapacity: types.maybe(types.number),
  onDemandPercentageAboveBaseCapacity: types.optional(types.number, 0),
  spotAllocationStrategy: types.optional(types.string, "lowest-price"),
  subnetIds: types.array(types.string),
  extraSecurityGroupIds: types.array(types.string),
});

export const Mk8sDraftProviderAWSNodePoolsModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["default", "added"]), "default"),
    _pool: types.optional(Mk8sDraftProviderAWSNodePoolsReadonlyModel, () =>
      Mk8sDraftProviderAWSNodePoolsReadonlyModel.create()
    ),
    name: types.optional(StringModel, () =>
      StringModel.create({ label: "Name", validationKey: "nodePoolName", isRequired: true })
    ),
    labels: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    taints: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    instanceTypes: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    overrideImage: types.optional(Mk8sDraftProviderAWSAMIModel, () => Mk8sDraftProviderAWSAMIModel.create()),
    bootDiskSize: types.optional(StringModel, () =>
      NumberModel.create({ label: "Boot Disk Size", initialValue: 20, isRequired: true })
    ),
    minSize: types.optional(StringModel, () => NumberModel.create({ label: "Min Size", min: 0, isRequired: true })),
    maxSize: types.optional(StringModel, () => NumberModel.create({ label: "Max Size", min: 0, isRequired: true })),
    onDemandBaseCapacity: types.optional(StringModel, () =>
      NumberModel.create({ label: "On Demand Base Capacity", isRequired: true })
    ),
    onDemandPercentageAboveBaseCapacity: types.optional(StringModel, () =>
      NumberModel.create({
        label: "On Demand Percentage Above Base Capacity",
        initialValue: 0,
        min: 0,
        max: 100,
        isRequired: true,
      })
    ),
    spotAllocationStrategy: types.optional(SelectModel, () =>
      SelectModel.create({
        options: [
          //
          { label: "lowest-price", value: "lowest-price" },
          { label: "capacity-optimized", value: "capacity-optimized" },
          { label: "capacity-optimized-prioritized", value: "capacity-optimized-prioritized" },
          { label: "price-capacity-optimized", value: "price-capacity-optimized" },
        ],
        initialValue: "lowest-price",
        label: "Spot Allocation Strategy",
      })
    ),
    subnetIds: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    extraSecurityGroupIds: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
  })
  .actions((self) => ({
    setStatusAdded() {
      self.status = "added";
    },
    reset() {
      self.name.setInitialValue(self._pool.name);
      self.labels = ListOfItemsModel.create({
        _items: self._pool.labels.map((l) => ({ firstValue: l.key, secondValue: l.value })),
      });
      self.taints = ListOfItemsModel.create({
        _items: self._pool.taints.map((t) => ({ firstValue: t.key, secondValue: t.value, thirdValue: t.effect })),
      });
      self.instanceTypes = ListOfItemsModel.create({
        _items: self._pool.instanceTypes.map((i) => ({ firstValue: i })),
      });

      const imageType = !!self._pool.overrideImage.exact ? "exact" : "recommended";
      self.overrideImage = Mk8sDraftProviderAWSAMIModel.create({
        exact: StringModel.create({ label: "Exact Image", initialValue: self._pool.overrideImage.exact }),
        recommended: self._pool.overrideImage.recommended,
        type: imageType,
      });
      self.bootDiskSize.setInitialValue(
        String(self._pool.bootDiskSize).length > 0 ? String(self._pool.bootDiskSize) : ""
      );
      self.minSize.setInitialValue(String(self._pool.minSize).length > 0 ? String(self._pool.minSize) : "");
      self.maxSize.setInitialValue(String(self._pool.maxSize).length > 0 ? String(self._pool.maxSize) : "");
      self.onDemandBaseCapacity.setInitialValue(
        String(self._pool.onDemandBaseCapacity).length > 0 ? String(self._pool.onDemandBaseCapacity) : ""
      );
      self.onDemandPercentageAboveBaseCapacity.setInitialValue(
        String(self._pool.onDemandPercentageAboveBaseCapacity).length > 0
          ? String(self._pool.onDemandPercentageAboveBaseCapacity)
          : ""
      );

      self.spotAllocationStrategy.setInitialValue(self._pool.spotAllocationStrategy);

      self.subnetIds = ListOfItemsModel.create({
        _items: self._pool.subnetIds.map((i) => ({ firstValue: i })),
      });
      self.extraSecurityGroupIds = ListOfItemsModel.create({
        _items: self._pool.extraSecurityGroupIds.map((i) => ({ firstValue: i })),
      });
    },
    confirm() {
      self.name.confirm();
      self.labels.confirm();
      self.taints.confirm();
      // no override image
      self.bootDiskSize.confirm();
      self.minSize.confirm();
      self.maxSize.confirm();
      self.onDemandBaseCapacity.confirm();
      self.onDemandPercentageAboveBaseCapacity.confirm();
      self.spotAllocationStrategy.confirm();
      self.subnetIds.confirm();
      self.extraSecurityGroupIds.confirm();

      const _pool: any = {
        name: self.name.value, //
        labels: self.labels.items.map((l) => ({ key: l.firstValue, value: l.secondValue })),
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
        instanceTypes: self.instanceTypes.items.map((i) => i.firstValue),
        overrideImage: {
          recommended: self.overrideImage.recommended,
          exact: self.overrideImage.exact.value,
        },
        bootDiskSize: Number(self.bootDiskSize.value),
        minSize: String(self.minSize.value).length > 0 ? Number(self.minSize.value) : undefined,
        maxSize: String(self.maxSize.value).length > 0 ? Number(self.maxSize.value) : undefined,
        onDemandBaseCapacity:
          String(self.onDemandBaseCapacity.value).length > 0 ? Number(self.onDemandBaseCapacity.value) : undefined,
        onDemandPercentageAboveBaseCapacity: Number(self.onDemandPercentageAboveBaseCapacity.value),
        spotAllocationStrategy: self.spotAllocationStrategy.value,
        subnetIds: self.subnetIds.items.map((i) => i.firstValue),
        extraSecurityGroupIds: self.extraSecurityGroupIds.items.map((i) => i.firstValue),
      };

      self._pool = Mk8sDraftProviderAWSNodePoolsReadonlyModel.create(_pool);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isLabelsValid() {
      let res = true;
      if (self.labels.items.some((l) => !l.firstValue || !l.secondValue)) res = false;
      return res;
    },
    get isTaintsValid() {
      let res = true;
      if (self.taints.items.some((t) => !t.firstValue || !t.secondValue || !t.thirdValue)) res = false;
      return res;
    },
    get isInstanceTypesValid() {
      let res = true;
      if (self.instanceTypes.items.length < 1) res = false;
      if (self.instanceTypes.items.some((i) => !i.firstValue)) res = false;
      return res;
    },
    get isSubnetIdsValid() {
      let res = true;
      if (self.subnetIds.items.length < 1) res = false;
      if (self.subnetIds.items.some((i) => !i.firstValue)) res = false;
      return res;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.isLabelsValid) res = false;
      if (!self.isTaintsValid) res = false;
      if (!self.bootDiskSize.isValid) res = false;
      if (!self.minSize.isValid) res = false;
      if (!self.maxSize.isValid) res = false;
      if (!self.isInstanceTypesValid) res = false;
      if (!self.isSubnetIdsValid) res = false;
      if (!self.onDemandBaseCapacity.isValid) res = false;
      if (!self.onDemandPercentageAboveBaseCapacity.isValid) res = false;
      if (!self.spotAllocationStrategy.value) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.name.isValid) reason = "name is invalid";
      if (!self.isLabelsValid) reason = "labels not valid";
      if (!self.isTaintsValid) reason = "taints not valid";
      if (!self.bootDiskSize.isValid) reason = "bootDiskSize is invalid";
      if (!self.minSize.isValid) reason = "minSize is invalid";
      if (!self.maxSize.isValid) reason = "maxSize is invalid";
      if (!self.isInstanceTypesValid) reason = "instance types are required";
      if (!self.isSubnetIdsValid) reason = "subnet ids are required";
      if (!self.onDemandBaseCapacity.isValid) reason = "onDemandBaseCapacity is invalid";
      if (!self.onDemandPercentageAboveBaseCapacity.isValid) reason = "onDemandPercentageAboveBaseCapacity is invalid";
      if (!self.spotAllocationStrategy.value) reason = "spotAllocationStrategy is invalid";
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.labels.isDirty) res = true;
      if (self.taints.isDirty) res = true;
      if (self.instanceTypes.isDirty) res = true;

      const initialImageType = !!self._pool.overrideImage.exact ? "exact" : "recommended";
      const anyImage = self.overrideImage.exact.value || self.overrideImage.recommended;
      if (!!anyImage && initialImageType !== self.overrideImage.type) res = true;
      if (self.overrideImage.type === "exact") {
        if (self.overrideImage.exact.value !== self._pool.overrideImage.exact) res = true;
      } else if (self.overrideImage.type === "recommended") {
        if (self.overrideImage.recommended !== self._pool.overrideImage.recommended) res = true;
      }

      if (self.bootDiskSize.isDirty) res = true;
      if (self.minSize.isDirty) res = true;
      if (self.maxSize.isDirty) res = true;
      if (self.onDemandBaseCapacity.isDirty) res = true;
      if (self.onDemandPercentageAboveBaseCapacity.isDirty) res = true;
      if (self.spotAllocationStrategy.isDirty) res = true;
      if (self.subnetIds.isDirty) res = true;
      if (self.extraSecurityGroupIds.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.name.isDirty) reason = self.name.isDirtyReason;
      if (self.labels.isDirty) reason = "labels are dirty";
      if (self.taints.isDirty) reason = "taints are dirty";

      if (self.instanceTypes.isDirty) reason = "instance types are dirty";

      const initialImageType = !!self._pool.overrideImage.exact ? "exact" : "recommended";
      const anyImage = self.overrideImage.exact.value || self.overrideImage.recommended;
      if (!!anyImage && initialImageType !== self.overrideImage.type) reason = "self.overrideImage.type";
      if (self.overrideImage.type === "exact") {
        if (self.overrideImage.exact.value !== self._pool.overrideImage.exact) reason = "self.overrideImage.exact";
      } else if (self.overrideImage.type === "recommended") {
        if (self.overrideImage.recommended !== self._pool.overrideImage.recommended)
          reason = "self.overrideImage.recommended";
      }

      if (self.bootDiskSize.isDirty) reason = self.bootDiskSize.isDirtyReason;
      if (self.minSize.isDirty) reason = self.minSize.isDirtyReason;
      if (self.maxSize.isDirty) reason = self.maxSize.isDirtyReason;
      if (self.onDemandBaseCapacity.isDirty) reason = self.onDemandBaseCapacity.isDirtyReason;
      if (self.onDemandPercentageAboveBaseCapacity.isDirty)
        reason = self.onDemandPercentageAboveBaseCapacity.isDirtyReason;
      if (self.spotAllocationStrategy.isDirty) reason = self.spotAllocationStrategy.isDirtyReason;
      if (self.subnetIds.isDirty) reason = "subnet ids are dirty";
      if (self.extraSecurityGroupIds.isDirty) reason = "extra Security Group Ids are dirty";
      return reason;
    },
    get asObject() {
      const labels: { [_: string]: string } = {};
      self.labels.items.forEach((l) => {
        labels[l.firstValue] = l.secondValue;
      });

      const obj: any = {
        name: self.name.value,
        labels: labels,
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
        instanceTypes: self.instanceTypes.items.map((i) => i.firstValue),
        spotAllocationStrategy: self.spotAllocationStrategy.value,
        subnetIds: self.subnetIds.items.map((i) => i.firstValue),
        extraSecurityGroupIds: self.extraSecurityGroupIds.items.map((i) => i.firstValue),
      };

      if (self.overrideImage.type === "recommended" && !!self.overrideImage.recommended) {
        obj.overrideImage = { recommended: self.overrideImage.recommended };
      } else if (self.overrideImage.type === "exact" && !!self.overrideImage.exact.value) {
        obj.overrideImage = { exact: self.overrideImage.exact.value };
      }

      if (self.bootDiskSize.value.length > 0) {
        obj.bootDiskSize = Number(self.bootDiskSize.value);
      }
      if (self.minSize.value.length > 0) {
        obj.minSize = Number(self.minSize.value);
      }
      if (self.maxSize.value.length > 0) {
        obj.maxSize = Number(self.maxSize.value);
      }
      if (self.onDemandBaseCapacity.value.length > 0) {
        obj.onDemandBaseCapacity = Number(self.onDemandBaseCapacity.value);
      }
      if (self.onDemandPercentageAboveBaseCapacity.value.length > 0) {
        obj.onDemandPercentageAboveBaseCapacity = Number(self.onDemandPercentageAboveBaseCapacity.value);
      }
      return obj;
    },
  }));
