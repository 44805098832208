import * as React from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "antd";
import { Edit2, Trash } from "react-feather";
import { NGButton } from "../../newcomponents/button/Button";
import { NGInput } from "../../newcomponents/input/input";

export type NetworkResourceModalType = "new" | "edit";

export interface NetworkResourcesTableHeaderItem {
  widthProportion: string;
  content: React.ReactNode;
  className?: string;
  isHidden?: boolean;
}

export interface NetworkResourcesTableProps {
  typeName: string;
  filterPlaceholder: string;
  header: NetworkResourcesTableHeaderItem[];
  items: any[];
  onOpenModalForAddition: (setItemToCreate: (item: any) => void) => void;
  onOpenModalForEdit: (setItemToCreate: (item: any) => void, itemToEdit: any) => void;
  onConfirmModal: (modalState: NetworkResourceModalType, item: any, itemToEdit: any) => void;
  isOkButtonDisabled: (item: any) => boolean;
  isSearchedItemFound: (item: any, searchContent: string) => boolean;
  buildModal: (item: any) => React.ReactNode;
  buildItem: (item: any, index: number) => React.ReactNode;
  onItemRemoval: (item: any) => void;
}

const NetworkResourcesTableRaw: React.FC<NetworkResourcesTableProps> = ({
  typeName,
  filterPlaceholder,
  header,
  items,
  onOpenModalForAddition,
  onOpenModalForEdit,
  onConfirmModal,
  isOkButtonDisabled,
  isSearchedItemFound,
  buildItem,
  buildModal,
  onItemRemoval,
}) => {
  // Use States //
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>("");
  const [modalType, setModalType] = React.useState<NetworkResourceModalType>("new");
  const [itemToCreate, setItemToCreate] = React.useState<any>();
  const [itemToEdit, setItemToEdit] = React.useState<any>();

  // Use Effects //
  React.useEffect(() => {
    setFilter("");
  }, [typeName]);

  // Functions //
  function openModalForAddition(): void {
    setItemToEdit(undefined);
    onOpenModalForAddition(setItemToCreate);
    setModalType("new");
    setIsModalOpen(true);
    setFilter("");
  }

  function openModalForEdit(item: any): void {
    setItemToEdit(item);
    onOpenModalForEdit(setItemToCreate, item);
    setModalType("edit");
    setIsModalOpen(true);
    setFilter("");
  }

  function handleItemRemoval(item: any): void {
    onItemRemoval(item);
  }

  function handleConfirmModal(): void {
    onConfirmModal(modalType, itemToCreate, itemToEdit);
    setIsModalOpen(false);
  }

  function handleCancelModal(): void {
    setIsModalOpen(false);
  }

  let filteredItems = items;
  if (filter) {
    filteredItems = items.filter((item) => filter === "" || isSearchedItemFound(item, filter));
  }

  return (
    <>
      <div className="flex items-center gap-4">
        <NGInput
          placeholder={filterPlaceholder}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ width: 300, fontSize: 16 }}
        />
        <NGButton variant={"action"} onClick={openModalForAddition}>
          Add {typeName}
        </NGButton>
      </div>
      <div className="mt-5 border">
        <div
          className="flex items-center h-8 w-full table-labels border-b px-4 py-1"
          style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
        >
          {header.map((item, index) =>
            item.isHidden ? null : (
              <div key={index} className={`w-${item.widthProportion} ${item.className}`}>
                {item.content}
              </div>
            )
          )}
        </div>
        {filteredItems.length > 0 ? (
          filteredItems.map((item, index) => (
            <div
              key={index}
              className={`flex w-full items-start ${index === filteredItems.length - 1 ? "" : "border-b"} px-4 py-3`}
              style={{ minHeight: 69 }}
            >
              {buildItem(item, index)}
              <div className="w-1/12 flex gap-x-2">
                <Edit2 size={16} className="ml-auto cursor-pointer color-link" onClick={() => openModalForEdit(item)} />
                <Trash size={16} className="cursor-pointer color-danger" onClick={() => handleItemRemoval(item)} />
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center text-base font-semibold" style={{ height: 69 }}>
            {!!filter ? (
              <span className="color-danger">No matching values found</span>
            ) : (
              <span>{typeName} list is empty</span>
            )}
          </div>
        )}
      </div>
      {isModalOpen ? (
        <Modal
          title={modalType === "new" ? `Add ${typeName}` : `Edit ${typeName}`}
          open={isModalOpen}
          onCancel={handleCancelModal}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={handleCancelModal}>
                Cancel
              </NGButton>
              <NGButton variant="primary" onClick={handleConfirmModal} disabled={isOkButtonDisabled(itemToCreate)}>
                {modalType === "new" ? "Add" : "Save"}
              </NGButton>
            </div>
          }
        >
          {buildModal(itemToCreate)}
        </Modal>
      ) : null}
    </>
  );
};

export const NetworkResourcesTable = observer(NetworkResourcesTableRaw);
