import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import { useNavigate } from "react-router-dom";
import { NumberModel } from "../../../mobxDataModels/numberModel";
import { FormButtons } from "../../../components/forms/formButtons";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { flowResult } from "mobx";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGInput } from "../../../newcomponents/input/input";
import { NGSwitch } from "../../../newcomponents/switch";
import NGAlert from "../../../newcomponents/alert";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGLabelText } from "../../../newcomponents/text/labelText";

const AlertRaw: React.FC = () => {
  const navigate = useNavigate();
  const { AccountUUID, AccountName, FullName, Roles, Alert } = BillingContext;

  const amountRef = React.useRef(NumberModel.create({ label: "Amount USD" }));
  const [isEnabled, setIsEnabled] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);

  React.useEffect(() => {
    if (!Roles.includes("billing_admin")) {
      navigate(`/account/${AccountUUID}`);
      return;
    }
    BillingContext.fetchAlert();
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    reset();
  }, [Alert, Alert.amount, Alert.enabled]);

  React.useEffect(() => {
    let res = false;
    if (amountRef.current.isDirty) {
      res = true;
    }
    if (Alert.enabled !== isEnabled) {
      res = true;
    }
    setIsDirty(res);
  }, [amountRef.current.isDirty, Alert.enabled, isEnabled]);

  function reset() {
    amountRef.current.setInitialValue(Math.ceil(Alert.amount).toString());
    setIsEnabled(Alert.enabled);
  }

  async function save() {
    try {
      setIsLoading(true);
      const alertBody = {
        amount: Number(amountRef.current.value),
        enabled: isEnabled,
      };

      try {
        await request({
          method: "post",
          service: "billing-ng",
          url: `/account/${AccountUUID}/alert`,
          body: {
            alert: alertBody,
          },
        });
      } catch (e) {
        if (e.response?.status === 409) {
          await request({
            method: "put",
            service: "billing-ng",
            url: `/account/${AccountUUID}/alert`,
            body: {
              alert: alertBody,
            },
          });
        } else {
          throw e;
        }
      }
      await flowResult(BillingContext.fetchAlert());
      amountRef.current.confirm();
      notification.success({
        message: "Success",
        description: "Updated Alert",
      });
    } catch (e) {
      console.error("Account Alert", e);
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      if (errorMessage) {
        try {
          const asObject = JSON.parse(errorMessage);
          if (asObject.message) {
            errorMessage = asObject.message;
          }
        } catch (e) {}
      }
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function add() {
    try {
      setIsLoading(true);
      const alertBody = {
        amount: Number(amountRef.current.value),
        enabled: true,
      };

      await request({
        method: "post",
        service: "billing-ng",
        url: `/account/${AccountUUID}/alert`,
        body: {
          alert: alertBody,
        },
      });
      await flowResult(BillingContext.fetchAlert());
      amountRef.current.confirm();
      notification.success({
        message: "Success",
        description: "Updated Alert",
      });
    } catch (e) {
      console.error("Account Alert", e);
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      if (errorMessage) {
        try {
          const asObject = JSON.parse(errorMessage);
          if (asObject.message) {
            errorMessage = asObject.message;
          }
        } catch (e) {}
      }
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div key={AccountName || FullName} className={`  mb-4`}>
        <div style={{ width: 450 }}>
          <NGLabel className="mb-4">Monthly Spend Alert</NGLabel>
          <NGLabel>{amountRef.current.label}</NGLabel>
          <NGInput
            value={amountRef.current.value}
            onChange={(e) => amountRef.current.setValue(e.target.value)}
            className="mb-4"
          />
          {Alert ? (
            <NGSwitch className="mb-4" value={isEnabled} onChange={setIsEnabled}>
              <NGLabelText>Enable</NGLabelText>
            </NGSwitch>
          ) : null}
          <NGAlert type={"info"} message={`Get notified when your monthly spend reaches this amount`} />
          {Alert ? (
            <FormButtons
              onReset={reset}
              onSave={save}
              loading={isLoading}
              saveDisabled={isLoading || !isDirty}
              resetDisabled={isLoading || !isDirty}
            />
          ) : (
            <div className="mt-8 flex items-center">
              <NGButton
                style={{ width: 215, marginRight: 10 }}
                variant="action"
                disabled={isLoading || !isDirty}
                onClick={add}
              >
                Add
              </NGButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const Alert = observer(AlertRaw);
