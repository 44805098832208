import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { Copy, Download, X } from "react-feather";
import { CodeEditor } from "../group/identityMatcher/codeEditor";

interface Props {
  clusterName: string;
  nodePoolName: string;
  script: string;
  extension: "sh" | "yaml";
  scriptName: string;
  poolType: string;
  onDone: any;
  visible: boolean;
}

const Mk8sScriptModalRaw: React.FC<Props> = ({
  clusterName,
  nodePoolName,
  poolType,
  script,
  extension,
  scriptName,
  onDone,
  visible,
}) => {
  async function copyToClipboard() {
    navigator.clipboard.writeText(script);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  function getHref() {
    // TODO control this
    const mimeType = extension === "sh" ? "text/x-shellscript" : "text/yaml";
    const file = new Blob([script], { type: mimeType });
    return URL.createObjectURL(file);
  }

  return (
    <Modal
      className="mk8sscriptmodal"
      styles={{ body: { height: "70vh", padding: 0 } }}
      width={1000}
      title={
        <div className="flex items-baseline justify-between">
          <div className="mr-4">{`${poolType} ${scriptName}`}</div>
          <div className="flex items-center gap-2 pr-12 text-sm">
            <button
              autoFocus
              onClick={copyToClipboard}
              className={`border px-2 py-1 rounded focus:outline-none focus-visible:ring-2 hover:bg-input-hover flex items-center`}
            >
              <Copy className="inline-block feather-icon" />
              <span className="ml-1">Copy</span>
            </button>
            <div
              className={`border px-2 py-1 rounded focus:outline-none focus-visible:ring-2 hover:bg-input-hover flex items-center`}
            >
              <Download className="inline-block feather-icon" />
              <a
                className="ml-2 hover:text-normal"
                href={getHref()}
                download={`${clusterName}-${nodePoolName}-joinScript.${extension}`}
              >
                <span style={{ fontWeight: 400 }}>Download Join Script</span>
              </a>
            </div>
            <button
              onClick={onDone}
              className={`border px-2 py-1 rounded focus:outline-none focus-visible:ring-2 flex items-center hover:bg-input-hover`}
            >
              <X className="inline-block feather-icon" />
              <span className="ml-1">Close</span>
            </button>
          </div>
        </div>
      }
      open={visible}
      closable={false}
      destroyOnClose
      footer={null}
    >
      {/* TODO also use new code editor for bootstrapConfigModal component */}
      <CodeEditor
        delay
        minHeight="100%"
        options={{
          style: {
            height: "100%",
            overflow: "auto",
          },
          basicSetup: { lineNumbers: true },
          readOnly: true,
        }}
        value={script}
        setValue={() => {}}
        language={"json"}
      />
    </Modal>
  );
};

export const Mk8sScriptModal = observer(Mk8sScriptModalRaw);
