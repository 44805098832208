import { TableColumn } from "../../types";

const providerLabelMap = {
  // Location
  aws: "AWS",
  gcp: "GCP",
  azure: "Azure",
  equinix: "Equinix",
  byok: "BYOK",
  // Cloud account
  ngs: "NGS",
  // Mk8s
  generic: "Generic",
  hetzner: "Hetzner",
  ephemeral: "Ephemeral",
  linode: "Linode",
  oblivus: "Oblivus",
  lambdalabs: "Lambdalabs",
  paperspace: "Paperspace",
};
const providerDefault = { id: "provider", label: "Provider" };
export function ProviderColumn<TData extends { provider: string }>(column: TableColumn<TData> = providerDefault) {
  const preset: TableColumn<TData> = {
    ...providerDefault,
    enableResize: false,
    enableHide: true,
    canGroupByDistinctValues: true,
    groupLabelMap: providerLabelMap,
    groupOrder: [
      "aws", //
      "azure",
      "byok",
      "ephemeral",
      "equinix",
      "gcp",
      "generic",
      "hetzner",
      "lambdalabs",
      "linode",
      "ngs",
      "oblivus",
      "paperspace",
    ],
    size: 210,
    cell: (p) => {
      const item = p.row.original;
      return <span>{providerLabelMap[item.provider]}</span>;
    },
  };
  return Object.assign(preset, column);
}
