import { getParent, Instance, types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { defaultValues } from "../base";
import { WorkloadDraftMobx } from "./workload.draft";
import { SelectModel } from "../../mobxDataModels/selectModel";

export const WorkloadDraftRolloutOptionsModel = types
  .model({
    _configure: types.optional(types.boolean, false),
    _minReadySeconds: types.optional(types.number, defaultValues.workload.rolloutOptions.minReadySeconds.default),
    _maxSurgeReplicas: types.optional(types.string, defaultValues.workload.rolloutOptions.maxSurgeReplicas.default),
    _scalingPolicy: types.optional(types.string, defaultValues.workload.rolloutOptions.scalingPolicy.default),
    configure: types.optional(types.boolean, false),
    minReadySeconds: types.optional(StringModel, () =>
      NumberModel.create({ label: "Min Ready Seconds", isRequired: true, min: 0 }),
    ),
    maxSurgeReplicas: types.optional(StringModel, () =>
      StringModel.create({
        label: "Max Surge Replicas",
        initialValue: defaultValues.workload.rolloutOptions.maxSurgeReplicas.default,
        // TODO add the regex
      }),
    ),
    scalingPolicy: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Scaling Policy",
        initialValue: defaultValues.workload.rolloutOptions.scalingPolicy.default,
        options: [
          {
            label: "OrderedReady",
            value: "OrderedReady",
          },
          {
            label: "Parallel",
            value: "Parallel",
          },
        ],
      }),
    ),
  })
  .actions((self) => ({
    reset() {
      self.configure = self._configure;
      self.minReadySeconds.setInitialValue(String(self._minReadySeconds));
      self.maxSurgeReplicas.setInitialValue(self._maxSurgeReplicas);
      self.scalingPolicy.setInitialValue(self._scalingPolicy);
    },
    setConfigure(value: boolean) {
      self.configure = value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._configure = self.configure;
      self._minReadySeconds = Number(self.minReadySeconds.value);
      self.minReadySeconds.confirm();
      self._maxSurgeReplicas = self.maxSurgeReplicas.value;
      self.maxSurgeReplicas.confirm();
      self._scalingPolicy = self.scalingPolicy.value;
      self.scalingPolicy.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      const parent: WorkloadDraftMobx = getParent(self);
      if (parent.isStateful || parent.isStandard) {
        if (self._configure !== self.configure) res = true;
        if (self.configure) {
          if (self.minReadySeconds.isDirty) res = true;
          if (self.maxSurgeReplicas.isDirty) res = true;
          if (self.scalingPolicy.isDirty) res = true;
        }
      }
      return res;
    },
    get isDirtyReason() {
      let reason = "";
      if (self._configure !== self.configure) reason = "configure changed";
      if (self.configure) {
        if (self.minReadySeconds.isDirty) reason = "minreadyseconds";
        if (self.maxSurgeReplicas.isDirty) reason = "maxsurge";
        if (self.scalingPolicy.isDirty) reason = "scalingpolicy";
      }
      return reason;
    },
    get isValid() {
      let res = true;
      const parent: WorkloadDraftMobx = getParent(self);
      if (
        //
        (parent.isStateful || parent.isStandard) &&
        self.configure
      ) {
        if (!self.minReadySeconds.isValid) res = false;
        if (!self.maxSurgeReplicas.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      return {
        minReadySeconds: Number(self.minReadySeconds.value),
        maxSurgeReplicas: self.maxSurgeReplicas.value,
        scalingPolicy: self.scalingPolicy.value,
      };
    },
  }));
export interface WorkloadDraftRolloutOptionsMobx extends Instance<typeof WorkloadDraftRolloutOptionsModel> {}
